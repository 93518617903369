/* eslint-disable no-tabs */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { formatCnpj, formatCpf } from "@brazilian-utils/formatters";
import { AccountsUsersCompanys, SubType, UserStatus } from "api/manager-api";

import { AccountsStore, RouterStore } from "../../../stores";
import { Table } from "../../../components/Table";
import {
  colors,
  legalEntityMEIProfileManagerPath,
  legalEntityPJOldProfileManagerPath,
  legalEntityProfileManagerPath,
  prettierAccountName,
  prettierAccountType,
  userProfileManagerPath,
} from "../../../utils";

interface IProps {
  results: AccountsUsersCompanys;
  routerStore?: RouterStore;
  accountsStore?: AccountsStore;
}

@inject("routerStore", "accountsStore")
@observer
export class ResultsList extends React.Component<IProps> {
  private handleAccountClick = (cpfCnpj: string) => {
    const { accounts } = this.props.results;
    const account = accounts.find(({ documentNumber }) => cpfCnpj === documentNumber);
    const isSimple = account!.subType === SubType.simple;

    if (account!.type === "pf") {
      const url = userProfileManagerPath(cpfCnpj, isSimple ? "simples" : "full");

      this.props.routerStore!.push(url);

      return;
    }

    if (account!.type === "mei") {
      this.props.routerStore!.push(legalEntityMEIProfileManagerPath(cpfCnpj));

      return;
    }

    const hasOldCompanyAccount = this.props.results.companys.find(x => x.documentNumber === account!.documentNumber);

    if (account!.type === "pj" && hasOldCompanyAccount) {
      this.props.routerStore!.push(legalEntityPJOldProfileManagerPath(cpfCnpj));

      return;
    }

    this.props.routerStore!.push(legalEntityProfileManagerPath(cpfCnpj));
  };

  private handleCompanyClick = (cnpj: string) => {
    const company = this.props.results.companys.find(x => x.documentNumber === cnpj);
    const fepwebCompany = this.props.results.fepwebCompanies.find(x => x.documentNumber === cnpj);
    const isMei = company && company.isMEI;

    if (isMei) {
      this.props.routerStore!.push(legalEntityMEIProfileManagerPath(cnpj));

      return;
    }

    if (fepwebCompany) {
      this.props.routerStore!.push(legalEntityProfileManagerPath(cnpj));

      return;
    }

    this.props.routerStore!.push(legalEntityPJOldProfileManagerPath(cnpj));
  };

  private handleUserClick = (cpf: string, isSimpleAccount: boolean) => {
    this.props.routerStore!.push(userProfileManagerPath(cpf, isSimpleAccount ? "simples" : "full"));
  };

  public render() {
    return (
      <Container>
        {this.renderAccounts()}
        {this.renderCompanies()}
        {this.renderFepWebCompanies()}
        {this.renderUsers()}
      </Container>
    );
  }

  private renderAccounts = () => {
    const { accounts } = this.props.results;

    if (!accounts.length) {
      return;
    }

    return (
      <>
        <Title>Contas</Title>
        <Table
          titles={["Número da conta", "Tipo", "Titular", "Status"]}
          ids={accounts.map(({ documentNumber }) => documentNumber)}
          data={accounts.map(row => [
            prettierAccountName(row.account),
            prettierAccountType(row.type),
            row.name,
            row.status === "active" ? "Ativa" : "Encerrada",
          ])}
          onClickRow={this.handleAccountClick}
        />
      </>
    );
  };

  private renderCompanies = () => {
    const { companys } = this.props.results;

    if (!companys.length) {
      return;
    }

    return (
      <>
        <Title>Empresas</Title>
        <Table
          titles={["Razão Social", "CNPJ"]}
          ids={companys.map(({ documentNumber }) => documentNumber)}
          data={companys.map(row => [row.name, formatCnpj(row.documentNumber)])}
          onClickRow={this.handleCompanyClick}
        />
      </>
    );
  };

  private renderFepWebCompanies = () => {
    const { fepwebCompanies } = this.props.results;

    if (!fepwebCompanies.length) {
      return;
    }

    return (
      <>
        <Title>Empresas - Fepweb</Title>
        <Table
          titles={["Razão Social", "CNPJ"]}
          ids={fepwebCompanies.map(({ documentNumber }) => documentNumber)}
          data={fepwebCompanies.map(row => [row.name, formatCnpj(row.documentNumber)])}
          onClickRow={this.handleCompanyClick}
        />
      </>
    );
  };

  private renderUsers = () => {
    const { users } = this.props.results;

    if (!users.length) {
      return;
    }

    return (
      <>
        <Title>Usuários</Title>
        <Table
          titles={["Nome", "CPF"]}
          ids={users.map(({ cpf }) => cpf)}
          data={users.map(row => [
            row.name,
            formatCpf(row.cpf),
            // <UserType key={row.id} user={row} />,
          ])}
          onClickRow={e => {
            const user = users.find(el => el.cpf === e)!;

            this.handleUserClick(
              e,
              user.status === UserStatus.approvePending ||
                user.status === UserStatus.approvePendingWithDocument ||
                user.status === UserStatus.approvePendingWithoutDocument ||
                (user.status === UserStatus.active && user.journeyType !== null && user.subType === SubType.simple),
            );
          }}
        />
      </>
    );
  };
}

/*
 * Const UserType = ({ user }: { user: User }) => (
 * 	<Tag>{JSON.stringify(user)}</Tag>
 * );
 */

/*
 * Const Tag = styled.div`
 * 	display: flex;
 * 	justify-content: center;
 * 	align-items: center;
 * 	font-weight: bold;
 * 	font-size: 11px;
 * 	font-family: Lato;
 * 	color: white;
 * 	background-color: ${colors.themeBrown};
 * 	height: 24px;
 * 	width: 26px;
 * 	border-radius: 5px;
 * `;
 */

const Container = styled.div`
  margin-top: -32px;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
  margin-top: 32px;
`;
