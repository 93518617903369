import * as React from "react";
import styled from "styled-components";
import { Switch } from "../Switch";
import { TextInput, ITextInputProps } from "legacy/components/TextInput";
import { colors } from "utils";

interface IProps extends ITextInputProps {
  checked: boolean;
  inputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  switchChange?: () => void;
}

export class SwitchWithTextInput extends React.Component<IProps> {
  public render() {
    const { label, checked, inputChange, switchChange, ...inputProps } = this.props;

    return (
      <Container>
        <>
          <Label>{label}</Label>
          <StyledTextInput
            key={!checked ? "swti-control" : "swti-uncontrol"}
            disabled={!checked}
            {...inputProps}
            onChange={inputChange}
          />
        </>
        <Switch checked={checked} handleChange={switchChange} />
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StyledTextInput = styled(TextInput)`
  width: 160px;
`;

const Label = styled.label`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  margin-right: 10px;
`;
