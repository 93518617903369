exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__switchCard__ESJbX{display:flex;justify-content:flex-start;align-items:center;width:100%;background:rgba(196,196,196,.2);border-radius:4px;padding:16px 16px 16px 8px;margin-bottom:16px}.style__switch__2g5v_{margin-right:8px}.style__title__2vrqq{font-size:14px;line-height:17px;color:#525051;margin-bottom:4px}.style__description__2jGzg{font-size:14px;line-height:17px;color:#a6a6a6}", ""]);

// Exports
exports.locals = {
	"switchCard": "style__switchCard__ESJbX",
	"switch": "style__switch__2g5v_",
	"title": "style__title__2vrqq",
	"description": "style__description__2jGzg"
};