import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  color: string;
  size: number;
}

export const SvgLeftArrow = (props: IProps) => {
  const { color, size, viewBox, width, height, ...restProps } = props;
  return (
    <svg width={size} height={size} {...restProps}>
      <path fill="#C9C9C9" fillRule="evenodd" d="M10 20l1.435-1.393L3.828 11H20V9H3.828l7.586-7.586L10 0 0 10l10 10" />
    </svg>
  );
};
