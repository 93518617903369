import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";

import { OutlinedButton } from "../../../components/OutlinedButton";
import { colors } from "../../../utils";

export const Box = styled.form`
  padding: 16px 24px;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

export const NextButtonWrapper = styled(RaisedButton)`
  min-width: 145px;
  width: fit-content;
  font-family: Lato;
  margin: 15px 0 15px 15px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

export const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;

export const Info = styled.div`
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.black};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  padding: 0;
  margin: 0;
`;
