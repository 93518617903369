/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { formatCnpj, formatCpf } from "@brazilian-utils/formatters";
import { isValidCpf } from "@brazilian-utils/validators";
import { colors } from "utils";

import { Loader } from "../../../../components/Loader";
import {
  BeneficiaryDataWrapper,
  Centralizer,
  Column,
  DoubleRowContainer,
  Text,
  Title,
  Typography,
  WrapperTextShowWithBureauMainInfo,
} from "./styled";
import { Table } from "../../../../components/Table";
import { StatusAproved, StatusPending, StatusRefused } from "../../../../components/Icons/status";
import { Compliance } from "../Compliance";
import { InnerAlert } from "../../../../components/InnerAlert";

interface IComplianceProps {
  onSelectedPj: () => Promise<api.BeneficiaryAnalisys[]>;
}

export function Beneficiary({ onSelectedPj: onChoosePj }: IComplianceProps) {
  const [beneficiaryData, setBeneficiaryData] = useState<api.ComplianceData | null>(null);
  const [selectedRow, setSelectedRow] = useState("");
  const [rowData, setRowData] = useState<api.BeneficiaryAnalisys[] | null>(null);
  const [apiError, setApiError] = useState("");

  useEffect(() => {
    if (selectedRow && rowData) {
      const findCompliance = rowData.find(element => element.id === selectedRow);

      if (findCompliance) {
        setBeneficiaryData(findCompliance.complianceData);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    fetchRowData();
    setRowData(null);
  }, [onChoosePj]);

  function formatCpfCnpj(cpfCnpj: string) {
    if (isValidCpf(cpfCnpj)) {
      return formatCpf(cpfCnpj);
    }

    return formatCnpj(cpfCnpj);
  }

  async function fetchRowData() {
    setApiError("");
    try {
      const result = await onChoosePj();

      setRowData(result);
    } catch (err) {
      setApiError(err.message);
    }
  }

  function renderUser() {
    const titles = ["Nome", "Status"];

    if (!rowData) {
      return null;
    }

    return (
      <React.Fragment>
        <Table
          titles={titles}
          ids={rowData.map(e => e.id)}
          onClickRow={e => setSelectedRow(e)}
          selectedRow={selectedRow}
          data={rowData.map(result => {
            const status = StatusBuilder(result.statusCompliance);
            const tableData: any = [result.name, status];

            return tableData;
          })}
        />
      </React.Fragment>
    );
  }

  function renderBeneficiaryData() {
    if (!selectedRow || !rowData) {
      return null;
    }

    const findBeneficiaryData = rowData.find(element => element.id === selectedRow);

    if (!findBeneficiaryData) {
      return null;
    }

    return (
      <>
        <Title>Informações pessoais</Title>
        <WrapperTextShowWithBureauMainInfo
          title={"Nome"}
          content={[
            {
              data: findBeneficiaryData.name,
              source: "Declarado",
            },
          ]}
        />
        <WrapperTextShowWithBureauMainInfo
          title={"Número do documento"}
          content={[
            {
              data: formatCpfCnpj(findBeneficiaryData.cpfCnpj),
              source: "Declarado",
            },
          ]}
        />
        <WrapperTextShowWithBureauMainInfo
          title={"Porcentagem na empresa"}
          content={[
            {
              data: `${findBeneficiaryData.percentage}%`,
              source: "Declarado",
            },
          ]}
        />
      </>
    );
  }

  function StatusBuilder(status: string) {
    if (status === "approved") {
      return (
        <>
          <StatusAproved />
          <Text color={colors.lightGreen} marginLeft={"11px"} width={"min-content"}>
            Aprovado
          </Text>
        </>
      );
    } else if (status === "waiting") {
      return (
        <>
          <StatusPending />
          <Text color={colors.gray} marginLeft="11px" width="min-content">
            Pendente
          </Text>
        </>
      );
    } else if (status === "manualAnalysis") {
      return (
        <>
          <Text color={colors.gray} marginLeft="0px" style={{ marginTop: "-9px" }} width="max-content">
            Análise Manual
          </Text>
        </>
      );
    }

    return (
      <>
        <StatusRefused />
        <Text color={colors.darkRed} marginLeft="11px" width="min-content">
          Reprovado
        </Text>
      </>
    );
  }

  if (rowData) {
    return (
      <DoubleRowContainer>
        <Column>{renderUser()}</Column>
        <Column>
          {selectedRow && beneficiaryData && (
            <BeneficiaryDataWrapper>
              {renderBeneficiaryData()}
              <Compliance beneficiaryData={beneficiaryData} />
            </BeneficiaryDataWrapper>
          )}
        </Column>
      </DoubleRowContainer>
    );
  } else if (apiError) {
    return (
      <Centralizer>
        <InnerAlert message={apiError} buttonLabel="Tentar novamente" buttonClick={async () => fetchRowData()} />
      </Centralizer>
    );
  }

  return (
    <Centralizer>
      <Loader />
      <Typography>Aguarde, a resposta dos serviços pode demorar.</Typography>
    </Centralizer>
  );
}
