import { useStores } from "@manager/stores/RootStore";
import { maskValidateCelDDI } from "@manager/utils";
import { PartnerFileLot } from "api/manager-api";
import { observer } from "mobx-react";
import * as React from "react";
import { removeSpecialChar } from "utils";

import { InputLabel } from "components/InputLabel";

import { Option } from "..";

import { IActivationCodeGeneration } from "../";
import { TextInput } from "../../../components/TextInput";
import { BackButtonWrapper, Box, Info, InputArea, NextButtonWrapper, StyledSelect } from "./styles";

type FieldError = "partner" | "phone";

interface IData {
  phone: string;
  sms: ISMSOption;
  partner: IPartnerOption | null;
}

const initialValues: IData = {
  phone: "",
  sms: { label: "Não", value: false },
  partner: null,
};

interface IProps {
  onClose: () => void;
  onSubmit: (option: Option, data: IActivationCodeGeneration) => Promise<void>;
}

interface IPartnerOption {
  value: string | null;
  label: string | null;
}

interface ISMSOption {
  value: boolean;
  label: string | null;
}

export const ActivationCodeGenerationDialog = observer(({ onClose, onSubmit }: IProps) => {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [optionsPartners, setOptionsPartners] = React.useState<IPartnerOption[]>([]);
  const { partnerStore } = useStores();
  const [data, setData] = React.useState(initialValues);

  async function getDataLot(partnerId: string) {
    await partnerStore.getPartnerPositivize(partnerId);
    if (partnerStore.partnersDataPositivize) {
      const partners: PartnerFileLot[] = partnerStore.partnersDataPositivize;

      let list: IPartnerOption[] = [];

      partners.forEach(item => {
        list.push({ label: item.partnerName, value: item.partnerId });
      });

      list = list.filter(
        (item, index, self) => index === self.findIndex(t => t.label === item.label && t.value === item.value),
      );

      setOptionsPartners(list);
    }
  }

  React.useEffect(() => {
    getDataLot("all");
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const newErrors: FieldError[] = [];

    if (!data.partner) {
      newErrors.push("partner");
    }

    if (!data.phone) {
      newErrors.push("phone");
    }

    setErrors(newErrors);
    if (newErrors.length > 0) {
      setLoading(false);
      return;
    }

    const phone = removeSpecialChar(data.phone);

    setLoading(false);
    if (data.partner && data.partner.value) {
      onSubmit("activationCodeGeneration", {
        partnerId: data.partner.value,
        ddi: phone.substring(0, 2),
        ddd: phone.substring(2, 4),
        phone: phone.substring(4),
        sms: data.sms.value,
      });
    }

    onClose();
  }

  function changePartner(event: any) {
    let auxValue = event;

    if (event.target) {
      auxValue = event.target.value;
    }

    setData({ ...data, partner: auxValue });
  }

  function changeSMS(event: any) {
    let auxValue = event;

    if (event.target) {
      auxValue = event.target.value;
    }

    setData({ ...data, sms: auxValue });
  }

  return (
    <Box onSubmit={handleSubmit}>
      <Info>
        <label>Parceiro *</label>
        <StyledSelect
          onChange={(e: any) => changePartner(e)}
          placeholder="Selecione"
          value={data.partner}
          options={optionsPartners}
          error={Boolean(errors.find(e => e === "partner"))}
        />
        <InputLabel label="Telefone *">
          <TextInput
            placeholder="+99 (99) 99999-9999"
            mask={maskValidateCelDDI}
            error={Boolean(errors.find(e => e === "phone"))}
            value={data.phone}
            onChange={e => setData({ ...data, phone: e.target.value })}
          />
        </InputLabel>
        <label>SMS *</label>
        <StyledSelect
          onChange={(e: any) => changeSMS(e)}
          placeholder="Selecione"
          value={data.sms}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
        />
      </Info>
      <InputArea>
        <BackButtonWrapper disabled={loading} type="button" label="Voltar" onClick={onClose} />
        <NextButtonWrapper loading={loading} type="submit" label="Confirmar" />
      </InputArea>
    </Box>
  );
});
