import React from "react";
import classnames from "classnames";

import { Dropdown } from "components/Dropdown";

import { Button } from "../../components/Button";
import { ReactComponent as DotsIcon } from "../../assets/svgs/dots.svg";

import * as s from "./style.scss";

export interface IOption {
  label: string;
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

interface IProps {
  options: IOption[];
  menuClassName?: string;
  overlayWidth?: string;
  children?: React.ReactElement;
}

export function DropdownActions(props: IProps) {
  return (
    <Dropdown
      overlay={
        <ul className={classnames([s.menu, props.menuClassName])} style={{ width: props.overlayWidth }}>
          {props.options.map((option, index) => (
            <li className={s.menu_item} key={`${index}`} tabIndex={-1} onClick={option.onClick}>
              {option.icon && option.icon}
              <span>{option.label}</span>
            </li>
          ))}
        </ul>
      }
      onOverlayClick={e => e.stopPropagation()}
    >
      {props.children || (
        <Button theme="neutral" className={s.btn_action} onClick={x => x.stopPropagation()}>
          <DotsIcon />
        </Button>
      )}
    </Dropdown>
  );
}
