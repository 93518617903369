import React from "react";
import classnames from "classnames";

import { ReactComponent as CheckIcon } from "assets/svgs/done-mini.svg";

import { CheckboxGroupContext } from "./..";

import * as s from "./style.scss";

interface IProps {
  children?: React.ReactNode;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  name?: string;
  value?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
}

export function Checkbox(props: IProps) {
  const ctx = React.useContext(CheckboxGroupContext);
  const [checked, setChecked] = React.useState(false);

  const isChecked = React.useCallback(() => {
    if (ctx) {
      return props.value ? ctx.value.includes(props.value) : false;
    }

    return props.checked ?? checked;
  }, [props.checked, props.value, checked, ctx?.value]);

  const classNames = [
    s.checkbox_wrapper,
    isChecked() ? s.checkbox_checked : null,
    props.disabled || ctx?.disabled ? s.checkbox_disabled : null,
    props.className,
  ];

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(event);
    }

    if (ctx && props.value) {
      ctx.onChange(props.value);
    }

    if (!ctx && props.checked === undefined) {
      setChecked(!checked);
    }
  }

  return (
    <label className={classnames(...classNames)} style={props.style}>
      <span className={s.checkbox}>
        <input
          type="checkbox"
          name={props.name}
          className={s.checkbox_input}
          onClick={props.onClick}
          value={props.value}
          onChange={onChange}
          checked={isChecked()}
          disabled={props.disabled || ctx?.disabled}
        />
        <span className={s.checkbox_inner}>
          <CheckIcon />
        </span>
      </span>

      <span className={s.checkbox_label}>{props.children}</span>
    </label>
  );
}
