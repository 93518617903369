import * as api from "api/manager-api";
import { isValidCpf, isValidCnpj } from "@brazilian-utils/validators";
import { formatCpf, formatCnpj } from "@brazilian-utils/formatters";
import { IFilterAccountsPath } from "../stores";
import moment from "moment";

export const prettierStatusPendingUser = (status: IFilterAccountsPath) => {
  switch (status) {
    case "approved":
      return "aprovado";
    case "pending":
      return "pendente";
    case "canceled":
      return "cancelado";
    case "reproved":
      return "reprovado";
    case "notFinished":
      return "não finalizado";
    case "workflow":
      return "workflow";
    case "processing":
      return "em processamento";
    default:
      return status;
  }
};

export const prettierBranchName = (branch: string) => {
  return branch.slice(0, branch.length - 1) + "-" + branch[branch.length - 1];
};

export const prettierAccountName = (account: string) => {
  return account.slice(0, account.length - 1) + "-" + account[account.length - 1];
};

export const prettierCategoryAccount = (categoryAccount: api.CategoryAccount | null) => {
  switch (categoryAccount) {
    case "exchange":
      return "Câmbio";
    case "credit":
      return "Crédito";
    case "investment":
      return "Investimento";
    case "payment":
      return "Pagamento";
    case "checking":
      return "Corrente";
    case null:
      return "";
    default:
      return categoryAccount;
  }
};

export const prettierAccountType = (type: api.PfPj) => {
  switch (type) {
    case "pf":
      return "Pessoa Física";
    case "pj":
      return "Pessoa Jurídica";
    case "mei":
      return "MEI";
    default:
      return type;
  }
};

export const prettierAbvAccountType = (type: api.PfPj) => {
  switch (type) {
    case "pf":
      return "PF";
    case "pj":
      return "PJ";
    case "mei":
      return "MEI";
    default:
      return type;
  }
};

export const prettierOperation = (operation: keyof api.Operations) => {
  switch (operation) {
    case "doc":
      return "DOC";
    case "titleInclusion":
      return "Inclusão de Titulo";
    case "investment":
      return "Investimento";
    case "paymentAgreement":
      return "Pagamento de convênio";
    case "paymentBankSlip":
      return "Pagamento de boleto";
    case "ted":
      return "TED";
    case "internalTransfer":
      return "Transferência Interna";
    case "withdraw":
      return "Saque";
    case "recharge":
      return "Recarga";
    default:
      return operation;
  }
};

export const prettierDocumentNumber = (documentNumber: string): string => {
  if (isValidCpf(documentNumber)) {
    return formatCpf(documentNumber);
  } else if (isValidCnpj(documentNumber)) {
    return formatCnpj(documentNumber);
  } else {
    return documentNumber;
  }
};

export const prettierPhone = (phone: string) => {
  if (phone.length === 11) {
    return "(" + phone.slice(0, 2) + ") " + phone.slice(2, 7) + "-" + phone.slice(7, 11);
  } else if (phone.length === 10) {
    return "(" + phone.slice(0, 2) + ") " + phone.slice(2, 6) + "-" + phone.slice(6, 10);
  } else {
    return phone;
  }
};

export const prettierCEP = (cep: string) => {
  if (cep.length === 8) {
    return `${cep.slice(0, 5)}-${cep.slice(5, 9)}`;
  } else {
    return cep;
  }
};

export const prettierDate = (date: Date) => moment(date).format("DD/MM/YYYY");

export const prettierRegion = (region: api.Region) => {
  switch (region) {
    case api.Region.norte:
      return "Norte";
    case api.Region.nordeste:
      return "Nordeste";
    case api.Region.centroOeste:
      return "Centro-Oeste";
    case api.Region.sudeste:
      return "Sudeste";
    case api.Region.sul:
      return "Sul";
    default:
      return region;
  }
};

export const prettierUserStatus = (status: api.UserStatus, reduced: any) => {
  switch (status) {
    case api.UserStatus.active:
      if (reduced) {
        switch (reduced) {
          case "preApproved":
            return "Aprovada C. Pre-cadastro";
          case "noRecord":
            return "Aprovada S. Pre-cadastro";
        }
      }
      return "Aprovada";
    case api.UserStatus.canceled:
      return "Cancelada";
    case api.UserStatus.repproved:
      return "Reprovada";
    case api.UserStatus.notFinished:
      return "Não-finalizada";
    case api.UserStatus.pending:
      return "Pendente";
    case api.UserStatus.finished:
      return "Em análise";
    case api.UserStatus.processing:
      return "Em processamento";
    case api.UserStatus.approvePending:
    case api.UserStatus.approvePendingWithDocument:
    case api.UserStatus.approvePendingWithoutDocument:
      return "Aprovada - C. Simples";
    default:
      return status;
  }
};

export const prettierComplianceStatus = (status: api.ComplianceStatus) => {
  switch (status) {
    case api.ComplianceStatus.approved:
      return "Aprovado";
    case api.ComplianceStatus.repproved:
      return "Reprovado";
    case api.ComplianceStatus.waiting:
      return "Aguardando";
    case api.ComplianceStatus.manualAnalysis:
      return "Análise Manual";
    default:
      return status;
  }
};

export const prettierGender = (gender?: string) => (gender ? gender.charAt(0).toUpperCase() + gender.slice(1) : "");

export const prettierBlockStatusAccount = (blockStatus: api.BlockStatusAccount) => {
  switch (blockStatus) {
    case api.BlockStatusAccount.automatic:
      return "Automático";
    case api.BlockStatusAccount.blocked:
      return "Bloqueado";
    case api.BlockStatusAccount.unlocked:
      return "Desbloqueado";
    default:
      return blockStatus;
  }
};

export const prettifyCardType = (type: string) => {
  switch (type) {
    case "virtual":
      return "Virtual";
    default:
      return "Físico";
  }
};

export const prettifyRequestStatus = (status: string) => {
  switch (status) {
    case "waiting":
      return "Aguardando";
    case "processing":
      return "Processando";
    case "processed":
      return "Solicitação aprovada";
    default:
      return "Falha";
  }
};

export const prettifyCardStatus = (status: string) => {
  switch (status) {
    case "inserted":
      return "Esperando desbloqueio";
    case "deactivated":
      return "Desativado";
    case "activated":
      return "Ativo";
    case "invalidated":
      return "Invalidado";
    case "blocked":
      return "Bloqueado";
    case "canceled":
      return "Cancelado";
    case "expired":
      return "Expirado";
    case "queue":
      return "Em fila";
    case "inProduction":
      return "Em produção";
    default:
      return "Falha";
  }
};
