import { StatusUserProofOfLife } from "api/manager-api";

export const allStatus = [
  {
    key: StatusUserProofOfLife.APPROVED,
    label: "Aprovado",
    value: StatusUserProofOfLife.APPROVED,
  },
  {
    key: StatusUserProofOfLife.PROCESSING,
    label: "Pendente",
    value: StatusUserProofOfLife.PROCESSING,
  },
  {
    key: StatusUserProofOfLife.REFUSED,
    label: "Reprovado",
    value: StatusUserProofOfLife.REFUSED,
  },
  {
    key: StatusUserProofOfLife.CANCELED,
    label: "Cancelado",
    value: StatusUserProofOfLife.CANCELED,
  },
  {
    key: StatusUserProofOfLife.REFUSEDACERTID,
    label: "Reprovado pelo acertid",
    value: StatusUserProofOfLife.REFUSEDACERTID,
  },
  {
    key: StatusUserProofOfLife.ANALYSISMANUALACERTID,
    label: "Aguardando Análise",
    value: StatusUserProofOfLife.ANALYSISMANUALACERTID,
  },
];
