import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";

import { Header, Title, TitleContainer } from "./style";
import { LogsByClient } from "./LogsByClient";
import { InternalLogs } from "./InternalLogs";
import { GeneralStore } from "../../../stores";
import { TabNavigator } from "../../../components/TabNavigator";

type Tab = "logsByClient" | "InternalLogs";

interface IProps {
  generalStore: GeneralStore;
}

interface IState {
  selectedTab: Tab;
}

@inject("generalStore", "platformManagerStore")
@observer
export class History extends React.Component<IProps, IState> {
  @observable private selectedTab: Tab = "logsByClient";

  @action
  private onChangeTab = (tabValue: Tab) => {
    this.selectedTab = tabValue;
  };

  public render() {
    return (
      <>
        <Header>
          <TitleContainer>
            <Title>Histórico de Alterações</Title>
          </TitleContainer>
        </Header>
        <TabNavigator
          onChangeTab={tabValue => this.onChangeTab(tabValue as Tab)}
          selected={this.selectedTab}
          tabs={this.tabs()}
        />
      </>
    );
  }

  private tabs() {
    return [
      {
        child: <LogsByClient />,
        label: "Alteração por CPF/CNPJ",
        value: "logsByClient",
      },
      {
        child: <InternalLogs />,
        label: "Logs Internos",
        value: "internalLogs",
      },
    ];
  }
}
