/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { AdminUserPermission, Profile } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { Dialog } from "../../../components/Dialog";
import { Table } from "../../../components/Table";
import { ActionsDropdown } from "../../../components/ActionsDropdown";
import { ProfileWizard } from "../DialogsContent";
import { GeneralStore, LegalEntityStore } from "../../../stores";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  legalEntityStore?: LegalEntityStore;
  generalStore?: GeneralStore;
}

@inject("legalEntityStore", "generalStore")
@observer
export class LegalEntityAccountProfiles extends React.Component<IProps> {
  @observable private isDialogOpen = false;

  @observable private currentProfile: Profile | null = null;

  @action
  private handleClose = () => {
    this.isDialogOpen = false;
  };

  @action
  private createProfile = () => {
    this.currentProfile = null;
    this.isDialogOpen = true;
  };

  @action
  private editProfile(profile: Profile) {
    this.currentProfile = profile;
    this.isDialogOpen = true;
  }

  public render() {
    const { company } = this.props.legalEntityStore!;

    return (
      <>
        <Dialog
          title={this.currentProfile ? "Editar perfil" : "Adicionar perfil"}
          isOpen={this.isDialogOpen}
          onClose={this.handleClose}
          padding={"0 20px 20px"}
          maxWidth={false}
          showCloseButton
        >
          <ProfileWizard
            profile={this.currentProfile}
            company={{
              ...company!,
              accounts: [],
            }}
            close={this.handleClose}
          />
        </Dialog>
        <Container>
          {this.props.generalStore!.hasPermission(AdminUserPermission.editRepresentativeCompany) && (
            <AddButtonWrapper
              type="button"
              label="Adicionar perfil"
              customColor={colors.violetRed}
              lowerCase
              onClick={this.createProfile}
            />
          )}
          {this.renderData()}
        </Container>
      </>
    );
  }

  private renderData() {
    const { profiles } = this.props.legalEntityStore!;

    if (!profiles.length) {
      return <InnerAlert compact message="Não há perfis cadastrados." />;
    }

    const titles = ["Nome"];

    if (this.props.generalStore!.hasPermission(AdminUserPermission.recoveryUser)) {
      titles.push("Ações");
    }

    return (
      <Table
        titles={titles}
        ids={profiles.map(e => e.id!)}
        data={profiles.map(row => {
          const data: any = [row.name];

          if (this.props.generalStore!.hasPermission(AdminUserPermission.recoveryUser)) {
            data.push(<ActionsDropdown key={row.id!} options={this.renderActions(row)} />);
          }

          return data;
        })}
      />
    );
  }

  private renderActions(profile: Profile) {
    return [
      {
        label: "Editar dados",
        onClick: () => this.editProfile(profile),
      },
      { label: "Excluir" },
    ];
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const AddButtonWrapper = styled(RaisedButton)`
  width: fit-content;
  font-weight: bold;
  font-family: Lato;
  font-size: 14px;
  margin: 10px;
  padding: 12px 20px;
`;
