let baseUrl = "";
let partnerId: string | null = null;
let useSessionStorage = false;

interface CustomWindow extends Window {
  SDKGEN_SESSION_ID: string | null;
  SDKGEN_DEVICE_ID: string | null;
}

declare var window: CustomWindow;

export function setUseSessionStorage(bool: boolean) {
  useSessionStorage = bool;
}

export function setUrl(url: string) {
  baseUrl = url;
}

export function setPartnerId(newPartnerId: string) {
  partnerId = newPartnerId;
}

export function setSessionId(newSessionId: string | null) {
  window.SDKGEN_SESSION_ID = newSessionId;
}

export interface BankSlip {
    nameBank: string;
    codeBankDV: string;
    digitableLine: string;
    codigoDeBarras: string;
    paymentPlace: string;
    dueDate: Date;
    beneficiary: BankSlipBeneficiary;
    documentDate: Date;
    documentNumber: string;
    documentSpecies: string;
    accept: string;
    proccessDate: Date;
    ourNumber: string;
    walet: string;
    specie: string;
    documentValue: number;
    instructions: string;
    payer: BankSlipPayer;
    barCode: string;
    clientCode: string;
    yourNumber: string;
    typePerson: string;
    link: string;
}

export interface BankSlipBeneficiary {
    name: string;
    agencyCode: string;
}

export interface BankSlipPayer {
    name: string;
    documentNumber: string;
    address: Address;
}

export interface Address {
    city: string;
    state: string;
    street: string;
    postalCode: string;
    neighborhood: string;
}

export interface BankSlipInfoV9 {
    code: string;
    beneficiary: string | null;
    payer: string | null;
    organization: string | null;
    dueDate: Date | null;
    payDate: Date | null;
    amount: number;
    paymentType: PaymentType;
    baseAmount: number;
    interest: number | null;
    penalty: number | null;
    discount: number | null;
}

export interface BankSlipInfo {
    payerDocument: string | null;
    beneficiaryBusinessName: string | null;
    beneficiaryDocument: string | null;
    totalCharges: number | null;
    digitableLine: string | null;
    discountByDate: ByDates[] | null;
    maxPayDate: Date | null;
    code: string;
    beneficiary: string | null;
    payer: string | null;
    organization: string | null;
    dueDate: Date | null;
    payDate: Date | null;
    amount: number;
    paymentType: PaymentType;
    baseAmount: number;
    interest: number | null;
    penalty: number | null;
    discount: number | null;
}

export interface ByDates {
    date: Date;
    discount: number;
    amount: number;
}

export interface PaymentSlip {
    branch: string;
    account: string;
    barcode: string;
    date: Date;
    dueDateTime: Date | null;
    description: string;
    type: PaymentType;
}

export interface ActionPayment {
    payerDocument: string | null;
    beneficiaryBusinessName: string | null;
    beneficiaryDocument: string | null;
    totalCharges: number | null;
    digitableLine: string | null;
    discountByDate: ByDates[] | null;
    maxPayDate: Date | null;
    code: string;
    beneficiary: string | null;
    payer: string | null;
    organization: string | null;
    dueDate: Date | null;
    payDate: Date | null;
    amount: number;
    paymentType: PaymentType;
    baseAmount: number;
    interest: number | null;
    penalty: number | null;
    discount: number | null;
    branch: string;
    account: string;
    barcode: string;
    date: Date;
    dueDateTime: Date | null;
    description: string;
    type: PaymentType;
}

export interface ActionWithdraw {
    branch: string;
    account: string;
    amount: number;
}

export interface NewDevice {
    branch: string;
    account: string;
    typeDevice: string;
    brandDevice: string | null;
    modelDevice: string | null;
}

export interface EconomicAgent {
    name: string;
    email: string;
    cpfCnpj: string;
    address: string;
    district: string;
    cep: string;
    uf: string;
    city: string;
}

export interface AccountDetailsBank {
    code: string;
    name: string;
}

export interface TransferTo {
    bank: string;
    branch: string;
    account: string;
    cpfCnpj: string | null;
    name: string;
    type: CounterpartTransferType;
}

export interface Transfer {
    date: Date;
    description: string | null;
    amount: number;
    finality: string | null;
    type: TransactionType;
    from: TransferFrom;
    to: TransferTo;
    partnerId: string;
    recurrent: number | null;
}

export interface TransferFrom {
    branch: string;
    account: string;
    cpfCnpj: string;
}

export interface OldTransfer {
    date: Date;
    description: string | null;
    amount: number;
    finality: string | null;
    type: TransactionType;
    from: OldTransferFrom;
    to: TransferTo;
    recurrent: number | null;
}

export interface OldTransferFrom {
    branch: string;
    account: string;
}

export interface TransferToV9 {
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    cpfCnpj: string | null;
    name: string;
    type: CounterpartTransferType;
}

export interface TransferV9 {
    date: Date;
    description: string | null;
    amount: number;
    finality: string | null;
    type: TransactionType;
    from: TransferV9From;
    to: TransferToV9;
    recurrent: number | null;
}

export interface TransferV9From {
    branch: string;
    account: string;
}

export interface Investment {
    amount: number;
    account: string;
    branch: string;
}

export interface EntryStatement {
    id: string;
    movementNumber: string;
    type: TransactionType;
    description: string;
    finality: string;
    nature: EntryStatementNature;
    prevBalance: number;
    currentBalance: number;
    movementDate: Date;
    amount: number;
    part: Part;
    counterPart: Part | null;
    devolutionReason: string | null;
    hasReceipt: boolean;
}

export interface EntryStatementWithUpdateDate {
    updateDate: Date | null;
    id: string;
    movementNumber: string;
    type: TransactionType;
    description: string;
    finality: string;
    nature: EntryStatementNature;
    prevBalance: number;
    currentBalance: number;
    movementDate: Date;
    amount: number;
    part: Part;
    counterPart: Part | null;
    devolutionReason: string | null;
    hasReceipt: boolean;
}

export interface CreateTransfer {
    status: CreateTransferStatus;
    number: string;
    type: CreateTransferType;
    entryStatement: EntryStatement | null;
}

export interface Part {
    name: string;
    cpfCnpj: string | null;
    branch: string;
    bank: string | null;
    account: string | null;
}

export interface Schedule {
    id: string;
    actionId: string;
    date: Date;
    recurrent: number | null;
    processed: boolean;
    type: ScheduleType;
}

export interface ActionSchedule {
    id: string;
    scheduleId: string;
    date: Date;
    processedDate: Date | null;
    count: number;
    status: ScheduleType;
}

export interface RGBA {
    r: number;
    g: number;
    b: number;
    a: number;
}

export interface Color {
    primary: RGBA;
    secondary: RGBA;
    textPrimary: RGBA;
    textSecondary: RGBA;
    backgroundPrimary: RGBA;
    backgroundSecondary: RGBA;
    detail: RGBA;
    creditLabel: RGBA;
    debitLabel: RGBA;
}

export interface Font {
    name: string;
    href: string;
}

export interface Customize {
    color: Color;
    font: Font;
}

export interface Covenant {
    number: string;
    value: number | null;
}

export interface Shop {
    name: string;
    code: string;
    address: string;
    city: string;
    latitude: number;
    longitude: number;
    distance: number;
    cashBackPercLoy: string;
    descErpCategory: string;
    codeShop: string;
    codeCategory: string;
    codeMacroCategory: string;
    active: boolean;
    onLine: boolean;
}

export interface PreRegister {
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    documentNumber: string;
    street: string;
    streetNumber: string;
    district: string;
    province: string;
    state: string;
    country: string;
    complement: string | null;
    zipCode: string;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface AuditInfo {
    id: string;
    cpf: string;
    name: string;
    userId: string;
    dateTime: Date;
    geoIp: boolean;
    partnerId: string | null;
    partnerName: string | null;
    ip: string;
    requestId: string;
    device: DeviceInfo;
    auditType: AuditAction;
    transfer: Transfer | null;
    bankSlip: BankSlip | null;
    covenant: Covenant | null;
    paymentSlip: PaymentSlip | null;
    genericBody: string | null;
    prevState: string | null;
    resume: string | null;
    permissionType: PermissionType | null;
    amount: number | null;
    requestedBy: string | null;
}

export interface AuditAdmin {
    id: string;
    cpf: string;
    name: string;
    userId: string;
    dateTime: Date;
    geoIp: boolean;
    ip: string;
    requestId: string;
    auditType: AuditActionsAdmin;
    genericBody: string | null;
    prevState: string | null;
    resume: string | null;
    device: DeviceInfo | null;
}

export interface DeviceInfo {
    deviceId: string;
    deviceType: DeviceInfoDeviceType;
    version: string | null;
    browserUserAgent: string | null;
    hardwareBrand: string | null;
    hardwareModel: string | null;
    location: LatLng | null;
    tokenNotification: string | null;
}

export interface FilterAudit {
    cpf: string | null;
    userId: string | null;
    start: Date | null;
    end: Date | null;
    auditAction: AuditAction | null;
    limit: number;
}

export interface FilterAuditAdmin {
    cpf: string | null;
    start: Date | null;
    end: Date | null;
    auditAction: AuditActionsAdmin | null;
    limit: number;
}

export interface AuditData {
    data: AuditInfo[];
    total: number;
}

export interface AuditDataAdmin {
    data: AuditAdmin[];
    total: number;
}

export interface OperatorPay {
    operatorId: number;
    ddi: number;
    ddd: number;
    numberPhone: number;
    value: number;
    branch: string;
    account: string;
}

export interface BasicUserAudit {
    id: string;
    cpf: string;
    name: string;
    email: string;
    phone: string;
    partnerId: string;
    publicKey: string | null;
}

export interface PartnerRules {
    permission: PermissionsPartner;
    inputs: PermissionsPartnerInput[];
}

export interface PartnerConfig {
    id: string;
    flag: CardBrand;
    rules: PartnerRules[];
    partnerId: string;
    productId: string | null;
    plasticId: string | null;
    imageId: string | null;
    imageDescription: string | null;
    commercialOrigin: string | null;
    showCard: boolean;
    virtualCardFlag: boolean;
    physicalCardFlag: boolean;
}

export interface Card {
    cvv: string;
    expirationDate: string;
    number: string;
    branch: string;
    account: string;
}

export interface InfoCard {
    id: string;
    codeRequest: string;
    branch: string;
    account: string;
    fourLastDigits: string;
    blockEvent: BlockEvent | null;
    status: CardStatus;
    validUntil: InfoCardValidUntil;
}

export interface InfoCardValidUntil {
    month: number;
    year: number;
}

export interface InfoCardV2 {
    id: string;
    codeRequest: string;
    branch: string;
    account: string;
    imageId: string | null;
    imageDescription: string | null;
    fourLastDigits: string;
    blockEvent: BlockEvent | null;
    status: CardStatus;
    validUntil: InfoCardV2ValidUntil;
}

export interface InfoCardV2ValidUntil {
    month: number;
    year: number;
}

export interface VirtualCard {
    id: string;
    number: string;
    cvv: string;
    printedName: string;
    createdAt: Date;
    dueDate: Date;
    dueDateText: string;
    status: VirtualCardStatus;
    statusMessage: string | null;
}

export interface Customer {
    name: string;
    nameMother: string;
    gender: GenderCustomer;
    documentNumber: string;
    otherDocument: CustomerOtherDocument;
    address: string;
    streetNumber: string;
    proviceResidence: CustomerProviceResidence;
    nationalResidence: string;
    district: string;
    complement: string;
    zip: string;
    shipmentAddress: string;
    shipmentZip: string;
    mobile: string;
    email: string;
    birthDate: string;
    birthNacionName: string;
}

export interface CustomerOtherDocument {
    number: string;
    type: string;
}

export interface CustomerProviceResidence {
    stateName: string;
    stateInicials: string;
    name: string;
}

export interface CheckVirtualCard {
    hasCard: boolean;
    isFirstCard: boolean;
}

export interface CardReportResult {
    cardId: string;
    name: string;
    account: string;
    cpf: string;
    requestedDate: Date;
    gatewayAccountId: string | null;
    gatewayCardId: string | null;
    attempts: number;
    partnerName: string;
    cardType: CardType;
    requestStatus: VisaCardQueueStatus;
    cardStatus: CardStatusReport;
}

export interface CardReport {
    result: CardReportResult[];
    limit: number;
    offset: number;
    total: number;
}

export interface CardProductImage {
    productId: string;
    imageId: string;
    description: string;
}

export interface ProspectStatus {
    statusId: string | null;
    statusName: string | null;
}

export interface Prospect {
    id: number;
    date: Date | null;
    doc: string | null;
    birthdate: Date | null;
    name: string | null;
    mother: string | null;
    zipCode: string | null;
    address: string | null;
    addressNumber: string | null;
    complementAddress: string | null;
    neighborhood: string | null;
    city: string | null;
    state: string | null;
    DDD: string | null;
    cellNumber: string | null;
    email: string | null;
    gender: string | null;
    grossIncome: string | null;
    equityValue: string | null;
    obs1: string | null;
    obs2: string | null;
    partnerFileId: number;
    statusId: string | null;
    status: ProspectStatus;
    partnerId: string | null;
    partnerName: string | null;
    partnerFileStatusDescription: string | null;
    partnerFileDescription: string | null;
    partnerFileStatusId: string | null;
    partnerFileStatusName: string | null;
}

export interface CreateReducedJourneyAccount {
    partnerId: string;
    name: string;
    motherName: string;
    cpf: string;
    birthDate: Date;
    gender: Gender | null;
    income: number;
    equity: number;
    codeArea: string;
    phone: string;
    email: string;
    zipCode: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    addressNumber: string;
    addressComplement: string | null;
    selfie: Buffer;
    acceptTermsOfUse: boolean;
    acceptContract: boolean;
    acceptLGPD: boolean;
    password: string;
    document: DocumentImage;
    ppe: boolean | null;
    ppeInfo: PpeInfo | null;
    fatca: boolean | null;
}

export interface RelatedPpeInfo {
    name: string;
    relation: string;
}

export interface PpeInfo {
    relatedPpe: boolean;
    relatedPpeInfo: RelatedPpeInfo | null;
    start: Date;
    end: Date;
    role: string;
}

export interface DocumentImage {
    documentType: DocumentType;
    imageFront: Buffer;
    imageBack: Buffer | null;
}

export interface ReducedJourneyAccountResponse {
    message: string;
    complienceResponse: string;
}

export interface Partner {
    urlApp: UrlApp | null;
    tokenUrlApp: UrlApp | null;
    blocked: boolean;
    managerId: string;
    publicKey: string;
    partnerSecret: string;
    partnerUserName: string | null;
    allowAnyPartnerLogin: boolean;
    openAccountsAttempts: number;
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
    managerIdLot: string | null;
    allowPartnerLot: boolean;
    allowReduceJourneyWithoutLot: boolean;
    showWiz: boolean | null;
}

export interface PartnerDetails {
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
    managerIdLot: string | null;
    allowPartnerLot: boolean;
    allowReduceJourneyWithoutLot: boolean;
    showWiz: boolean | null;
}

export interface PartnerV13 {
    urlApp: UrlApp | null;
    tokenUrlApp: UrlApp | null;
    blocked: boolean;
    managerId: string;
    publicKey: string;
    partnerSecret: string;
    partnerUserName: string | null;
    allowAnyPartnerLogin: boolean;
    openAccountsAttempts: number;
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
}

export interface PartnerDetailsV13 {
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
}

export interface Cobrander {
    codeCobrander: string;
    codeCardCategory: string;
    autoEmitCard: boolean;
    isActivate: boolean;
    username: string | null;
    password: string | null;
}

export interface PartnerHookDetails {
    url: string;
    token: string;
}

export interface UrlApp {
    android: string;
    ios: string;
}

export interface AccountDetails {
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    createdAt: Date;
}

export interface Account {
    name: string;
    type: PfPj;
    documentNumber: string;
    permissions: Permission[];
    maxAmountDaily: number | null;
    yourNumber: number;
    subType: SubType | null;
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    createdAt: Date;
}

export interface AccountCompany {
    profile: Profile;
    name: string;
    type: PfPj;
    documentNumber: string;
    permissions: Permission[];
    maxAmountDaily: number | null;
    yourNumber: number;
    subType: SubType | null;
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    createdAt: Date;
}

export interface Permission {
    permissionType: PermissionType;
    maxAmountDaily: number | null;
    maxAmountOperation: number | null;
    isSpecial: boolean;
}

export interface User {
    id: string;
    name: string;
    email: string;
    cpf: string;
    address: UserAddress | null;
    profilePhoto: string | null;
    status: UserStatus;
    publicKey: string | null;
    partnerId: string | null;
    accounts: Account[];
    companies: Company[];
    urlTerm: string | null;
    urlContract: string | null;
    socialName: string | null;
    phone: string | null;
    blocked: boolean | null;
    isMEI: boolean;
    showCard: boolean | null;
    help: string | null;
    mobileHelp: string | null;
    pendingDocument: boolean | null;
}

export interface UserAddress {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface UserV13 {
    id: string;
    name: string;
    email: string;
    cpf: string;
    cnpj: string | null;
    address: UserV13Address | null;
    profilePhoto: string | null;
    status: UserStatus;
    publicKey: string | null;
    partnerId: string | null;
    accounts: Account[];
    companies: CompanyV13[];
    urlTerm: string | null;
    urlContract: string | null;
    urlFarePackages: string | null;
    socialName: string | null;
    phone: string | null;
    blocked: boolean | null;
    isMEI: boolean;
    showCard: boolean | null;
    help: string | null;
    mobileHelp: string | null;
    pendingDocument: boolean | null;
    isMaster: boolean | null;
}

export interface UserV13Address {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface RepresentativeUser {
    id: string | null;
    name: string;
    email: string;
    cpf: string | null;
    cnpj: string | null;
    status: string;
    partnerId: string;
}

export interface Company {
    accounts: Account[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string;
    createdAt: Date;
    updatedAt: Date | null;
    status: Status;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoAddress | null;
}

export interface CompanyV13 {
    accounts: Account[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: StatusV13;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoV13Address | null;
    isPPE: boolean | null;
    mailingAddress: CompanyInfoV13MailingAddress | null;
    hasTaxDomain: boolean | null;
    taxDomainCountry: string | null;
    taxDomainNumber: string | null;
    isAmericanCitizen: boolean | null;
    americanCitizenNIF: string | null;
    isPPERelationship: boolean | null;
    giinNumber: string | null;
    ppe: PPEPayload[] | null;
    ppeRelationship: PPEPayload[] | null;
}

export interface CompanyInfo {
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string;
    createdAt: Date;
    updatedAt: Date | null;
    status: Status;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoAddress | null;
}

export interface CompanyInfoAddress {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface PPEPayload {
    name: string;
    reason: string;
}

export interface CompanyInfoV13 {
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: StatusV13;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoV13Address | null;
    isPPE: boolean | null;
    mailingAddress: CompanyInfoV13MailingAddress | null;
    hasTaxDomain: boolean | null;
    taxDomainCountry: string | null;
    taxDomainNumber: string | null;
    isAmericanCitizen: boolean | null;
    americanCitizenNIF: string | null;
    isPPERelationship: boolean | null;
    giinNumber: string | null;
    ppe: PPEPayload[] | null;
    ppeRelationship: PPEPayload[] | null;
}

export interface CompanyInfoV13Address {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface CompanyInfoV13MailingAddress {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface CompanyManager {
    accounts: CompanyManagerAccounts[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string;
    createdAt: Date;
    updatedAt: Date | null;
    status: Status;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoAddress | null;
}

export interface CompanyManagerAccounts {
    branch: string;
    account: string;
}

export interface CompanyManagerV13 {
    accounts: CompanyManagerV13Accounts[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: StatusV13;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoV13Address | null;
    isPPE: boolean | null;
    mailingAddress: CompanyInfoV13MailingAddress | null;
    hasTaxDomain: boolean | null;
    taxDomainCountry: string | null;
    taxDomainNumber: string | null;
    isAmericanCitizen: boolean | null;
    americanCitizenNIF: string | null;
    isPPERelationship: boolean | null;
    giinNumber: string | null;
    ppe: PPEPayload[] | null;
    ppeRelationship: PPEPayload[] | null;
}

export interface CompanyManagerV13Accounts {
    branch: string;
    account: string;
    status: AccountStatusV13;
}

export interface Profile {
    id: string | null;
    name: string;
    companyId: string;
    permissions: Permission[];
}

export interface UserCompany {
    id: string;
    name: string;
    email: string;
    phone: string | null;
    cpf: string;
    blocked: boolean | null;
    accounts: AccountCompany[];
}

export interface CreateAccountResponse {
    citizenship: CreateAccountResponseCitizenship | null;
    usaCitizenship: boolean;
    crsCitizenship: boolean;
    socialSecurityNumber: string | null;
    phoneAddress: string | null;
    abdication: string | null;
    publicAgent: boolean;
    ppe: CreateAccountResponsePpe | null;
    relatedPpe: CreateAccountResponseRelatedPpe | null;
}

export interface CreateAccountResponseCitizenship {
    key: string;
    value: string;
}

export interface CreateAccountResponsePpe {
    role: string | null;
    start: Date | null;
    end: Date | null;
}

export interface CreateAccountResponseRelatedPpe {
    name: string | null;
    relation: string | null;
}

export interface Image {
    name: string;
    url: string;
}

export interface Document {
    type: DocumentType;
    number: string;
    dispatcher: DocumentDispatcher;
    state: string;
}

export interface AddressInfo {
    cep: string;
    street: string;
    neighborhood: string;
    number: string;
    complement: string | null;
    city: string;
    state: string;
    country: string;
}

export interface AddressInfoComercial {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface CommercialInfo {
    address: AddressInfoComercial | null;
    cnpj: string;
    phone: string | null;
}

export interface Bank {
    code: string;
    name: string;
}

export interface AccountInfo {
    accountNumber: string;
    branch: string;
    bank: Bank;
}

export interface RegisterSourceString {
    data: string;
    source: RegisterSourceType;
}

export interface RegisterUser {
    userId: string;
    cpf: string;
    email: string;
    phone: string | null;
    name: string;
    images: Image[] | null;
    birthDate: Date | null;
    document: Document | null;
    gender: Gender | null;
    filiacao1: string | null;
    filiacao2: string | null;
    nacionalidade: string | null;
    createdAt: Date;
    address: AddressInfo | null;
    comercialInfo: CommercialInfo | null;
    socialName: string | null;
    accountInfo: AccountInfo | null;
    occupation: string | null;
    monthlyIncome: string | null;
    patrimony: string | null;
    civilStatus: CivilStatus | null;
    categoryAccount: CategoryAccount | null;
    partnerId: string | null;
    partnerName: string | null;
    complianceStatus: ComplianceStatus;
    status: UserStatus;
    naturalness: string | null;
    spouseName: string | null;
    weddingType: WeddingType | null;
    tokenNotification: string | null;
    isTerrorist: boolean;
    isMEI: boolean;
    cnpj: string | null;
    fantasyName: string | null;
    foundationDate: Date | null;
    mainActivity: string | null;
    codeSMS: string | null;
    companyName: string | null;
}

export interface ManagerAccount {
    id: string;
    cpfCnpj: string;
    name: string;
    accountType: PfPj;
    createdAt: Date;
    updatedAt: Date | null;
    categoryAccount: CategoryAccount;
    status: UserStatus | null;
    statusCompliance: ComplianceStatus | null;
    value: number | null;
    blockStatus: BlockStatusAccount | null;
    fepweb: boolean;
    journeyType: JourneyType | null;
    subType: SubType | null;
}

export interface RecoverUser {
    action: ActionRecoverUser;
    name: string;
    documentNumber: string;
    partner: Partner | null;
}

export interface PasswordLinkData {
    userId: string;
    createdAt: Date;
    expirationDate: Date;
    action: ActionRecoverUser;
    name: string;
    documentNumber: string;
    partner: Partner | null;
}

export interface Signature {
    signature: string;
    jsonObject: string;
}

export interface RegisterFieldsPF {
    informationFields: RegisterFieldsPFInformationFields[];
    documents: RegisterFieldsPFDocuments[];
}

export interface RegisterFieldsPFInformationFields {
    key: RegisterField;
    value: string;
}

export interface RegisterFieldsPFDocuments {
    key: RegisterField;
    value: string;
}

export interface PendingUser {
    name: string;
    documentNumber: string;
    partner: Partner | null;
}

export interface PendingRegisterResponseV10 {
    fields: RegisterDataRevalidate;
    revalidateFields: RegisterField[];
}

export interface RegisterDataRevalidateMEI {
    cnpj: string;
    foundationDate: Date;
    companyName: string;
    fantasyName: string | null;
    cpf: string;
    ownerBirthday: Date;
    ownerName: string;
    email: string;
    phone: string;
    acceptTermsOfUse: boolean;
    acceptTermsOfService: boolean;
    gender: Gender;
    affiliation1: string;
    affiliation2: string | null;
    nationality: string;
    zipCode: string;
    street: string;
    numberAddress: string;
    additionalAddressData: string | null;
    neighborhood: string;
    city: string;
    uf: string;
    proofOfAddress: string | null;
    certificateMEI: string | null;
    documentType: RegisterDataDocumentType;
    documentImageFront: string;
    documentImageBack: string | null;
    selfieImage: string;
    confirmTermsOfService: boolean;
    ppeData: PPEData | null;
    partnerId: string;
}

export interface PendingRegisterResponseMEI {
    fields: RegisterDataRevalidateMEI;
    revalidateFields: RegisterField[];
}

export interface Representative {
    cpf: string | null;
    docs: RepresentativeDocs[];
    errorMessage: string | null;
}

export interface RepresentativeDocs {
    label: string | null;
    file: Buffer | null;
    fileName: string | null;
    url: string | null;
    error: string | null;
    required: boolean;
}

export interface Benefit {
    id: string;
    name: string;
    description: string | null;
    url: string;
    urlActive: string;
}

export interface PPEData {
    isBrazilian: boolean;
    hasTaxRegion: boolean;
    isCRS: boolean;
    isFATCA: boolean;
    isPPE: boolean;
    isPublicOfficer: boolean;
    isResponsible: boolean;
    anotherCitizenship: boolean;
    isFATCADeclaration: boolean;
    isCRSDeclaration: boolean;
    hasCriminalProceedings: boolean;
    isPublicOfficerDeclaration: boolean;
    isPPEDeclaration: IsPPEDeclaration | null;
    country: string | null;
    documentNumber: string | null;
    addressCel: string | null;
    resignation: string | null;
    nameJob: string | null;
    startJob: Date | null;
    endJob: Date | null;
    politicallyExposedPersonName: string | null;
    relationshipNature: string | null;
}

export interface PendingAddressData {
    proofOfAddress: Buffer;
    address: PendingAddressDataAddress | null;
}

export interface PendingAddressDataAddress {
    cep: string;
    street: string;
    neighborhood: string;
    number: string;
    complement: string | null;
    city: string;
    state: string;
    country: string | null;
}

export interface RegisterData {
    cpf: string;
    birthday: Date;
    name: string;
    email: string;
    cel: string;
    acceptTermsOfUse: boolean;
    acceptTermsOfService: boolean;
    socialName: string | null;
    gender: Gender;
    affiliation1: string;
    affiliation2: string | null;
    nationality: string;
    zipCode: string;
    street: string;
    numberAddress: string;
    additionalAddressData: string | null;
    neighborhood: string;
    city: string;
    uf: string;
    patrimony: string | null;
    monthlyIncome: string | null;
    proofOfAddress: Buffer | null;
    documentType: RegisterDataDocumentType;
    documentImageFront: Buffer | null;
    documentImageBack: Buffer | null;
    selfieImage: Buffer | null;
    confirmTermsOfService: boolean;
    ppeData: PPEData | null;
    partnerId: string;
    createdByApi: boolean | null;
}

export interface RegisterDataRevalidate {
    cpf: string;
    birthday: Date;
    name: string;
    email: string;
    cel: string;
    acceptTermsOfUse: boolean;
    acceptTermsOfService: boolean;
    socialName: string;
    gender: Gender;
    affiliation1: string;
    affiliation2: string;
    nationality: string;
    zipCode: string;
    street: string;
    numberAddress: string;
    additionalAddressData: string;
    neighborhood: string;
    city: string;
    uf: string;
    patrimony: string | null;
    monthlyIncome: string | null;
    proofOfAddress: string | null;
    documentType: RegisterDataDocumentType;
    documentImageFront: string | null;
    documentImageBack: string | null;
    selfieImage: string;
    confirmTermsOfService: boolean;
    ppeData: PPEData | null;
    partnerId: string;
}

export interface InfoByCPFAndBirthday {
    name: string | null;
    mothername: string | null;
    token: string | null;
}

export interface InfoByCnpj {
    name: string | null;
}

export interface AddressVerification {
    cep: string;
    street: string;
    neighborhood: string;
    number: string;
    city: string;
    state: string;
}

export interface CEPAddress {
    street: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    number: string;
}

export interface BasicState {
    slug: string;
    name: string;
}

export interface BasicCity {
    slug: string;
    name: string;
}

export interface Nationality {
    slug: string;
    name: string;
}

export interface ReprovedUser {
    registerData: RegisterData;
    reprovedField: string[];
}

export interface CompanyMEI {
    cnpj: string;
    foundationDate: Date;
    companyName: string;
    fantasyName: string | null;
    proofOfAddress: Buffer | null;
    certificateMEI: Buffer | null;
    zipCode: string;
    street: string;
    numberAddress: string;
    additionalAddressData: string | null;
    neighborhood: string;
    city: string;
    uf: string;
}

export interface RegisterDataMEI {
    cpf: string;
    birthday: Date;
    name: string;
    email: string;
    phone: string;
    acceptTermsOfUse: boolean;
    acceptTermsOfService: boolean;
    gender: Gender;
    affiliation1: string;
    affiliation2: string | null;
    nationality: string;
    documentType: RegisterDataDocumentType;
    documentImageFront: Buffer | null;
    documentImageBack: Buffer | null;
    selfieImage: Buffer | null;
    confirmTermsOfService: boolean;
    ppeData: PPEData | null;
    partnerId: string;
    companyMEI: CompanyMEI;
}

export interface InfoRegistrationMEI {
    ownerName: string | null;
    ownerMotherName: string | null;
    companyName: string | null;
    fantasyName: string | null;
    token: string | null;
}

export interface AddressCertificateMEIInfo {
    address: boolean;
    certificateMEI: boolean;
}

export interface RegisterDataPJ {
    documentType: RegisterDataDocumentType;
    documentImageFront: Buffer | null;
    documentImageBack: Buffer | null;
    selfieImage: Buffer;
    partnerId: string;
}

export interface DocumentTypeId {
    docType: string | null;
    docNumber: string | null;
    emission: Date | null;
    consignor: string | null;
}

export interface IRepresentative {
    id: string;
    companyId: string;
    userId: string | null;
    formId: string | null;
    name: string;
    documentNumber: string;
    email: string;
    dateOfBirth: string;
    occupation: string | null;
    phone: string | null;
    cellPhone: string | null;
    motherName: string | null;
    nationality: string | null;
    address: AddressInfo | null;
    shortAddress: string | null;
    isMaster: boolean;
    isOwner: boolean;
    percentage: string | null;
    proofOfLifeStatus: StatusProofOfLife;
    acertIdKey: string | null;
    imagesUrl: Image[] | null;
    tokenLinkProofOfLife: string | null;
    isDeleted: boolean;
    acertIdStatus: number | null;
    acertIdBiometryStatus: number | null;
    acertIdResponse: string | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface ISubscriber {
    id: string;
    companyId: string;
    formId: string | null;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    address: AddressInfo | null;
    proofOfLifeStatus: StatusProofOfLife;
    acertIdKey: string | null;
    imagesUrl: Image[] | null;
    tokenLinkProofOfLife: string | null;
    isDeleted: boolean;
    acertIdStatus: number | null;
    acertIdBiometryStatus: number | null;
    acertIdResponse: string | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface VerifyCreateUserPassword {
    havePassword: boolean;
    isOnlyRepresentative: boolean;
}

export interface ChangePasswordV2 {
    userId: string;
    token: string;
}

export interface PartnerRegisterData {
    partnerId: string;
    name: string;
    cnpj: string | null;
    managerId: string;
    urlIB: string;
    urlApp: UrlApp | null;
    tokenUrlApp: UrlApp | null;
    hibotlink: string | null;
    publicKey: string;
    emailFrom: string | null;
    urlFarePackages: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    blocked: boolean;
    hibotmobilelink: string | null;
    partnerSecret: string;
    phone: string | null;
    email: string | null;
    site: string | null;
    partnerHook: PartnerHookDetails | null;
    partnerUserName: string | null;
    webhookUrl: string | null;
    term: Buffer | null;
    contract: Buffer | null;
    termPj: Buffer | null;
    contractPj: Buffer | null;
    farePackages: Buffer | null;
}

export interface PartnerStyles {
    partnerId: string;
    primaryColor: string | null;
    secondaryColor: string | null;
    backgroundColor: string | null;
    errorColor: string | null;
    logo: string;
    font: string | null;
    fontHref: string | null;
    buffer: Buffer | null;
}

export interface PartnerCard {
    partnerId: string;
    cardConfig: CardConfig[];
}

export interface UpdatePartnerCard {
    partnerId: string;
    productId: string;
    imageId: string;
    imageDescr: string;
    multiCard: MultiCard;
    bin: string;
    typeBin: string;
    cardTypeName: CardTypeName;
    activateCards: ActiveCards;
    plasticId: string;
    commercialOrigin: string;
}

export interface RemovePartnerCard {
    partnerId: string;
    productId: string;
    imageId: string;
}

export interface CustomButton {
    buffer: Buffer | null;
    image: string | null;
    url: string | null;
    text: string | null;
    show: boolean | null;
}

export interface CustomContact {
    show: boolean;
    phone: string | null;
    email: string | null;
}

export interface PartnerGeneralConfig {
    partnerId: string;
    allowAnyPartnerLogin: boolean;
    blocked: boolean;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    accountOpeningAttemptLimit: number | null;
    openAccountsAttempts: number;
    showCard: boolean;
    allowPartnerLot: boolean | null;
    allowReduceJourneyWithoutLot: boolean | null;
    showWiz: boolean;
    customButton: CustomButton | null;
    customContact: CustomContact | null;
    managerIdLot: string | null;
    managerIdWithoutLot: string | null;
}

export interface ActiveCards {
    physical: boolean;
    virtual: boolean;
}

export interface CardImage {
    id: string;
    descr: string;
}

export interface CardTypeName {
    name: boolean;
    noname: boolean;
}

export interface CardConfig {
    broadcaster: BroadCaster;
    brand: CardBrand;
    productId: string;
    plasticId: string;
    activateCards: ActiveCards;
    commercialOrigin: string;
    cardTypeName: CardTypeName;
    numberBin: string;
    typeBin: string;
    cardImage: CardImage[];
    multiCards: MultiCard;
}

export interface DatePeriod {
    start: string | null;
    end: string | null;
}

export interface Operations {
    ted: DatePeriod | null;
    doc: DatePeriod | null;
    internalTransfer: DatePeriod | null;
    investment: DatePeriod | null;
    titleInclusion: DatePeriod | null;
    withdraw: DatePeriod | null;
    paymentBankSlip: DatePeriod | null;
    paymentAgreement: DatePeriod | null;
    recharge: DatePeriod | null;
}

export interface OperationsDays {
    days: Day[];
    operations: Operations;
}

export interface ExceptionOperationsDate {
    date: Date;
    operations: Operations;
    obs: string;
    isAutomatic: boolean;
}

export interface Service {
    key: ServiceNames;
    blocked: boolean;
    value: string;
}

export interface ServiceV13 {
    key: ServiceNamesV13;
    blocked: boolean;
    value: string;
}

export interface AvailableServices {
    operation: ServiceNames;
    available: boolean;
}

export interface Feature {
    id: string;
    title: string;
    slug: FeatureSlug;
    isEnabled: boolean;
}

export interface UserPermissionNotification {
    permissions: NotificationPermissions[] | null;
}

export interface TransactionTypeFilter {
    transactionType: TransactionType;
    name: string;
}

export interface FepWebResponse {
    response: string;
    code: number;
}

export interface ClientAddress {
    zipCode: string | null;
    streetType: string | null;
    streetName: string | null;
    streetNumber: string | null;
    streetExtraInformation: string | null;
    districtName: string | null;
    cityName: string | null;
    stateCode: string | null;
}

export interface Children {
    firstName: string | null;
    lastName: string | null;
    identificationNumber: string | null;
    birthdate: string | null;
    gender: number | null;
}

export interface ClientDocument {
    type: string | null;
    number: string | null;
    dispatcherEntity: string | null;
    dispatcherState: string | null;
    dispatchDate: string | null;
}

export interface ClientUser {
    id: string;
    identificationNumber: string;
    firstName: string;
    lastName: string;
    birthdate: string;
    income: number | null;
    familyIncome: number | null;
    email: string;
    mobilePhone: string | null;
    phone: string | null;
    gender: number | null;
    maritalStatus: number | null;
    isBrazilResident: number | null;
    address: ClientAddress;
    children: Children[] | null;
    documents: ClientDocument[] | null;
}

export interface ResponseAuthentication {
    tokenClientId: string;
    webAppUrl: string;
}

export interface Receipt {
    html: string;
    image: string;
}

export interface Balance {
    available: number;
    blocked: number;
}

export interface Filter {
    period: Period | null;
    entryTypes: TransactionType[] | null;
    entryNature: EntryStatementNature[] | null;
}

export interface FilterStatement {
    amount: AmountFilter | null;
    period: Period | null;
    entryTypes: TransactionType[] | null;
    entryNature: EntryStatementNature[] | null;
}

export interface AmountFilter {
    min: number;
    max: number;
}

export interface Period {
    start: Date | null;
    end: Date | null;
}

export interface Finality {
    code: string;
    description: string;
}

export interface YieldReport {
    companyName: string | null;
    year: string;
    graphicNature: string;
    nameHolder: string;
    cpfHolder: string;
    nameSecondHolder: string | null;
    cpfSecondHolder: string | null;
    address: string;
    number: string | null;
    complement: string | null;
    neighborhood: string;
    city: string;
    state: string;
    zipCode: string;
    companyAddress: string;
    companyNumber: string | null;
    companyComplement: string | null;
    companyNeighborhood: string | null;
    companyCity: string;
    companyState: string;
    companyZipCode: string;
    declarantCompany: string;
    cnpjDeclarantCompany: string;
    declarantInfo: string | null;
    prevBalance: number;
    currentBalance: number;
    total: number;
}

export interface City {
    code: string;
    name: string;
}

export interface Lotery {
    name: string;
    address: string;
    district: string;
    number: string | null;
    complement: string | null;
    city: string;
    state: string;
    postalCode: string;
    location: LatLng;
}

export interface Recharge {
    ddi: number;
    ddd: number;
    numberPhone: number;
}

export interface Operator {
    operatorId: number;
    name: string;
    valueMin: number;
    valueMax: number;
    categoryRecharge: string;
    typeRecharge: string;
}

export interface OperatorValues {
    valueMin: number;
    valueMax: number;
    costRecharge: number;
    detailProduct: string;
    nameProduct: string;
    validityProduct: number;
    valueBonus: number;
}

export interface AccountHistory {
    date: Date;
    history: string;
    grossMovement: number;
    liquidMovement: number;
    grossBalance: number;
    liquidBalance: number;
}

export interface ApplicationPosition {
    paper: string;
    active: string;
    emission: Date;
    dueDate: Date;
    emitter: string;
    tax: string;
    appliedValue: number;
    grossValue: number;
    ir: number;
    iof: number;
    liquidValue: number;
}

export interface InvestmentStatement {
    clientName: string;
    address: string;
    district: string;
    city: string;
    state: string;
    zipCode: string;
    accountHistory: AccountHistory[];
    applicationPosition: ApplicationPosition[];
    withholdingIrPeriod: string | null;
    withholdingIofPeriod: string | null;
}

export interface NegotiationNote {
    numOperation: string;
    numStock: string;
    emissionDate: string;
    emitter: string;
    operationDate: string;
    dueDate: string;
    product: string | null;
    clientName: string;
    numNote: string;
    revenueType: string;
    puApplication: string;
    amountApplication: number;
    liqTypeFin: string;
    clientPhone: string;
    custody: string;
    withholdingIr: number;
    withholdingIof: number;
    grossAmount: number;
    liquidAmount: number;
    indexer: string | null;
    operationType: string;
    nominalRate: string | null;
    commitmentFee: string | null;
    commitmentDate: string | null;
    puRecurrenceComp: string | null;
    commitmentDueDate: string | null;
    recurrenceGrossAmountComp: number | null;
    withholdingIrComp: number | null;
    recurrenceLiquidAmountComp: number | null;
    indexerCommitment: string | null;
}

export interface MonthlyInvestmentBalance {
    month: number;
    balance: number;
}

export interface NewSingleAccountNotification {
    coligadaCode: string;
    branch: string;
    account: string;
    movementNumber: string;
    nature: string;
    homeSystem: string;
    historyCode: string;
    movedAt: Date;
    value: number;
    identity: number;
    partnerId: string;
}

export interface SingleAccountNotification {
    id: number;
    createdAt: Date;
    coligadaCode: string;
    branch: string;
    account: string;
    movementNumber: string;
    nature: string;
    homeSystem: string;
    historyCode: string;
    movedAt: Date;
    value: number;
    identity: number;
    partnerId: string;
}

export interface State {
    key: string;
    name: string;
}

export interface DiscountDate {
    discount: number | null;
    date: Date | null;
}

export interface ElectronicTitle {
    digitableLine: string;
    barCode: string;
    payerDocument: string;
    payerName: string;
    recipientDocument: string;
    recipientName: string;
    bankCode: string;
    bankName: string;
    nominalValue: number;
    charges: number | null;
    discounts: DiscountDate[] | null;
    paymentMethod: string | null;
    aggregate: boolean;
    situation: string;
    dueDate: Date;
    guarantorSocialReason: string | null;
    guarantorDocument: string | null;
    recipientFantasyName: string | null;
    titleDocument: string | null;
}

export interface ElectronicTitleFilter {
    payersDocuments: string[] | null;
    initialDueDate: Date | null;
    endDueDate: Date | null;
    minValue: number | null;
    maxValue: number | null;
    situation: string[] | null;
}

export interface DdaProfileAggregate {
    documentNumber: string;
    document: IndentityDocType;
    email: string | null;
    registeredBy: string;
    isActive: boolean;
    activatedAt: Date | null;
    canceledAt: Date | null;
    documentNumberAggregator: string;
    createdAt: Date;
    name: string;
}

export interface PersonEmails {
    name: string;
    emails: string[];
}

export interface UserLimits {
    operationType: string;
    dailyLimit: number;
    dailyLimitAvailable: number;
    operationLimit: number;
    amountPerDay: string;
    amountPerDayAvailable: string;
}

export interface Action {
    id: string;
    account: string;
    branch: string;
    requestedBy: string;
    approvedBy: string | null;
    disapprovedBy: string | null;
    partnerId: string | null;
    body: string;
    html: string;
    createdAt: Date;
    expired: boolean;
    type: TransactionType;
    movementNumber: string | null;
    originAccount: Account;
    schedule: boolean;
}

export interface Scheduling {
    scheduleId: string;
    actionId: string;
    type: TransactionType;
    date: Date;
    favoredName: string;
    body: string;
    schedule: boolean;
    recurrent: number | null;
    amount: number;
    bankName: string;
    descriptionType: string;
}

export interface Favored {
    name: string;
    documentNumber: string;
    accounts: FavoredAccounts[];
    image: string | null;
}

export interface FavoredAccounts {
    bank: FavoredAccountsBank;
    branch: string;
    account: string;
    type: CounterpartTransferType;
}

export interface FavoredAccountsBank {
    code: string;
    name: string;
}

export interface ScheduleRecurrency {
    id: string;
    idSchedule: string;
    date: Date;
    amount: number;
    status: ScheduleType;
}

export interface CaixaWithdraw {
    id: string;
    branch: string;
    account: string;
    amount: number;
    info: string[];
    expirationDate: Date;
    agreementNumber: string;
    agreementName: string;
    withdrawPassword: string;
    withdrawCode: string;
    createdAt: Date | null;
    status: CaixaWithdrawStatus;
    keyBlock: string;
    transactionId: string;
}

export interface CaixaWithdrawInfo {
    id: string;
    branch: string;
    account: string;
    amount: number;
    info: string[];
    expirationDate: Date;
    agreementNumber: string;
    agreementName: string;
    withdrawPassword: string;
    withdrawCode: string;
    createdAt: Date | null;
    status: CaixaWithdrawStatus;
}

export interface InfoWithdraw {
    limit: number;
    maxWithdraw: number;
    availableWithdraw: number;
    limitDay: number;
    withdrawEnabled: boolean;
    withdrawAmountAvailable: number;
}

export interface RemovedFavored {
    branch: string;
    account: string;
    bankCode: string;
}

export interface SummaryTransferParams {
    type: TransactionType;
    date: Date | null;
    bank: string;
    branch: string;
    account: string | null;
}

export interface SummaryTransfer {
    name: string | null;
    cpfCnpj: string | null;
    type: TransactionType;
    date: Date | null;
    bank: string;
    branch: string;
    account: string | null;
}

export interface ImageNews {
    url: string;
    width: number;
    height: number;
}

export interface Audio {
    id: string;
    audioUrl: string;
    createdAt: Date;
    radioId: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
}

export interface AudioDetails {
    radioId: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
}

export interface News {
    id: string;
    content: NewsContent;
    thumbnail: string | null;
    coverImage: string | null;
    date: Date | null;
    isPublished: boolean;
    createdAt: Date;
    deletedAt: Date | null;
    updatedAt: Date | null;
    partnerId: string;
    type: NewsType;
    title: string;
    headline: string;
    body: string;
}

export interface NewsContent {
    audio: string | null;
    video: string | null;
    images: string[];
}

export interface NewsDetails {
    type: NewsType;
    title: string;
    headline: string;
    body: string;
}

export interface Banner {
    id: string;
    name: string;
    createdAt: Date;
    url: string | null;
    newsId: string | null;
    image: string;
    partnerId: string;
    isActive: boolean;
}

export interface BannerResponse {
    id: string;
    name: string;
    createdAt: Date;
    url: string | null;
    news: News | null;
    image: string;
    partnerId: string;
}

export interface AdminNews {
    id: string;
    content: AdminNewsContent;
    thumbnail: string | null;
    coverImage: string | null;
    type: NewsType | null;
    title: string;
    headline: string;
    body: string;
    date: Date | null;
    isPublished: boolean;
    createdAt: Date;
    deletedAt: Date | null;
    updatedAt: Date | null;
    partnerId: string;
}

export interface AdminNewsContent {
    audio: string | null;
    video: string | null;
    images: string[];
}

export interface CreateNewsPartner {
    type: NewsType | null;
    title: string;
    headline: string;
    body: string;
    audio: Buffer | null;
    images: Buffer[] | null;
    coverImage: Buffer | null;
    youtubeUrl: string | null;
    isPublished: boolean;
}

export interface CreateNewsSchedulerPartner {
    audio: Buffer | null;
    images: Buffer[] | null;
    coverImage: Buffer | null;
    youtubeUrl: string | null;
    date: Date;
    type: NewsType;
    title: string;
    headline: string;
    body: string;
}

export interface EditNewsPartner {
    type: NewsType | null;
    title: string;
    headline: string;
    body: string;
    audio: Buffer | null;
    images: EditNewsPartnerImages[];
    coverImage: Buffer | null;
    youtubeUrl: string | null;
    date: Date | null;
}

export interface EditNewsPartnerImages {
    url: string | null;
    bytes: Buffer | null;
}

export interface AdminPaginated {
    news: AdminNews[];
    offset: number;
    limit: number;
    count: number;
    pages: number;
}

export interface CreateBannerPartner {
    name: string;
    image: Buffer;
    newsId: string | null;
    url: string | null;
}

export interface EditBannerPartner {
    id: string;
    name: string;
    image: Buffer | null;
    newsId: string | null;
    url: string | null;
}

export interface PartnerUser {
    username: string;
    password: string;
}

export interface FilterFields {
    idPartner: string[] | null;
    startRegisterDate: Date | null;
    endRegisterDate: Date | null;
    typePerson: PfPj | null;
    accountType: CategoryAccount[] | null;
    status: UserStatus[] | null;
    gender: Gender | null;
    region: Region[] | null;
    startAge: number | null;
    endAge: number | null;
    approvation: ApprovationType | null;
    closedAt: Date | null;
    closingReason: string | null;
    subType: SubType | null;
    journeyType: JourneyType | null;
    accountStatus: boolean | null;
}

export interface FilterFieldsByPartner {
    idPartner: string[] | null;
    partnerName: string | null;
    type: string | null;
    userName: string | null;
    documentNumber: string | null;
    account: string | null;
    birthDate: Date | null;
    email: string | null;
    phone: string | null;
    address: AddressInfo | null;
    affiliation1: string | null;
    affiliation2: string | null;
    monthlyIncome: string | null;
}

export interface ReportFilter {
    users: FilteredUsers[];
    paged: number;
    offset: number;
    total: number;
}

export interface ReportFilterByPartner {
    users: FilteredUsersByPartner[];
    paged: number;
    offset: number;
    total: number;
}

export interface ManagerReportFilter {
    limit: number;
    offset: number;
}

export interface RegisterUserByFilter {
    id: string;
    name: string;
    status: UserStatus;
    createdAt: Date;
    partner: string | null;
    gender: Gender | null;
    documentNumber: string;
    categoryAccount: CategoryAccount | null;
    address: AddressInfo | null;
    age: number | null;
    approvedAt: Date | null;
    type: PfPj | null;
    closedAt: Date | null;
    closingReason: string | null;
    subType: SubType | null;
    journeyType: JourneyType | null;
    accountStatus: boolean | null;
}

export interface RegisterPartnerByFilter {
    partnerName: string | null;
    type: string | null;
    userName: string | null;
    documentNumber: string | null;
    account: string | null;
    birthDate: Date | null;
    email: string | null;
    phone: string | null;
    address: AddressInfo | null;
    affiliation1: string | null;
    affiliation2: string | null;
    monthlyIncome: string | null;
}

export interface FilteredUsers {
    id: string;
    name: string;
    status: UserStatus;
    complianceStatus: ComplianceStatus;
    createdAt: Date;
    closedAt: Date | null;
    closingReason: string | null;
    partner: string | null;
    gender: Gender | null;
    documentNumber: string;
    categoryAccount: CategoryAccount | null;
    region: Region | null;
    age: number | null;
    approvedAt: Date | null;
    type: PfPj | null;
    account: string | null;
    pendingFields: RegisterField[] | null;
    pastPendingActions: FilteredUsersPastPendingActions[];
    approvation: ApprovationType | null;
    subType: SubType | null;
    journeyType: JourneyType | null;
    accountStatus: boolean | null;
}

export interface FilteredUsersPastPendingActions {
    requestedAt: Date;
    answeredAt: Date;
    fields: RegisterField[];
}

export interface FilteredUsersByPartner {
    partnerName: string | null;
    type: string | null;
    userName: string | null;
    documentNumber: string | null;
    account: string | null;
    birthDate: Date | null;
    email: string | null;
    phone: string | null;
    address: AddressInfo | null;
    affiliation1: string | null;
    affiliation2: string | null;
    monthlyIncome: string | null;
}

export interface PartnerCardUser {
    id: string;
    name: string;
    document: string;
}

export interface PartnerCardQueue {
    id: string;
    partnerId: string;
    status: PartnerCardQueueStatus;
    note: string;
    createdAt: Date;
    updatedAt: Date | null;
    user: PartnerCardUser;
}

export interface CardPartnerQueuePaginated {
    result: PartnerCardQueue[];
    limit: number;
    offset: number;
    total: number;
}

export interface PushMessage {
    id: string;
    message: string;
    partnerId: string;
    createdAt: Date;
    scheduleTo: Date | null;
}

export interface CreatePushMessage {
    message: string;
    scheduleTo: Date | null;
}

export enum PaymentType {
    agreement = "agreement",
    bankSlip = "bankSlip",
}

export enum ExternalTransferType {
    ted = "ted",
    doc = "doc",
}

export enum CounterpartTransferType {
    cc = "cc",
    pp = "pp",
}

export enum CreateTransferType {
    nsu = "nsu",
    numeroMovimento = "numeroMovimento",
    numeroOperacao = "numeroOperacao",
}

export enum CreateTransferStatus {
    ok = "ok",
    error = "error",
}

export enum TransactionType {
    ted = "ted",
    doc = "doc",
    internalTransfer = "internalTransfer",
    bankSlip = "bankSlip",
    investment = "investment",
    bankRate = "bankRate",
    paymentBankSlip = "paymentBankSlip",
    paymentAgreement = "paymentAgreement",
    tax = "tax",
    statement = "statement",
    createAccount = "createAccount",
    activeAccount = "activeAccount",
    withdraw = "withdraw",
    transaction = "transaction",
    recharge = "recharge",
    card = "card",
    includeDda = "includeDda",
    excludeDda = "excludeDda",
    excludeDdaAggregate = "excludeDdaAggregate",
    includeDdaAggregate = "includeDdaAggregate",
    newDevice = "newDevice",
}

export enum EntryStatementNature {
    credit = "credit",
    debit = "debit",
}

export enum ScheduleType {
    pending = "pending",
    processing = "processing",
    finished = "finished",
    cancelled = "cancelled",
    notPermited = "notPermited",
    waiting = "waiting",
}

export enum AuditAction {
    login = "login",
    requestTransfer = "requestTransfer",
    requestPaymentBankSlip = "requestPaymentBankSlip",
    requestPaymantCovenant = "requestPaymantCovenant",
    requestInvestment = "requestInvestment",
    createTransfer = "createTransfer",
    createPaymentBankSlip = "createPaymentBankSlip",
    createPaymantCovenant = "createPaymantCovenant",
    createInvestment = "createInvestment",
    createSelfInvoice = "createSelfInvoice",
    approve = "approve",
    approveBankslipPayment = "approveBankslipPayment",
    approveCovenantPayment = "approveCovenantPayment",
    reprove = "reprove",
    reproveBankslipPayments = "reproveBankslipPayments",
    reproveCovenantPayment = "reproveCovenantPayment",
    deleteScheduling = "deleteScheduling",
    setPermission = "setPermission",
    setPasswordPublicKey = "setPasswordPublicKey",
    finishCreateAccount = "finishCreateAccount",
    finishCreateAccountPJ = "finishCreateAccountPJ",
    finishCreateAccountPJMEI = "finishCreateAccountPJMEI",
    resetByRecoveryToken = "resetByRecoveryToken",
    sendLinkToRecovery = "sendLinkToRecovery",
    createProfile = "createProfile",
    createCaixaWithdraw = "createCaixaWithdraw",
    requestCaixaWithdraw = "requestCaixaWithdraw",
    recharge = "recharge",
    requestRecharge = "requestRecharge",
    requestActivateCard = "requestActivateCard",
    requestBlockCard = "requestBlockCard",
    requestUnblockCard = "requestUnblockCard",
    requestNewCard = "requestNewCard",
    requestLockCard = "requestLockCard",
    requestUnlockCard = "requestUnlockCard",
    requestChangePassword = "requestChangePassword",
    requestCreatePassword = "requestCreatePassword",
    requestResetPassword = "requestResetPassword",
    activateCard = "activateCard",
    blockCard = "blockCard",
    newCard = "newCard",
    lockCard = "lockCard",
    unlockCard = "unlockCard",
    cancelCard = "cancelCard",
    changePassword = "changePassword",
    changePassword6 = "changePassword6",
    createPassword = "createPassword",
    resetPassword = "resetPassword",
    userBodySignature = "userBodySignature",
    sendEmail = "sendEmail",
    sendSMS = "sendSMS",
    verifySMSCodeToChangePassword = "verifySMSCodeToChangePassword",
    updatePasswordByToken = "updatePasswordByToken",
    createPasswordByToken = "createPasswordByToken",
    setProfilePhoto = "setProfilePhoto",
    updateClient = "updateClient",
    includePartnerSingleAccountDDA = "includePartnerSingleAccountDDA",
    createCancellationDDAElectronicPayer = "createCancellationDDAElectronicPayer",
    requestIncludeDDAElectronicPayerAggregate = "requestIncludeDDAElectronicPayerAggregate",
    createCancellationDDAElectronicPayerAggregate = "createCancellationDDAElectronicPayerAggregate",
    applyDdaAggregateRequestApprove = "applyDdaAggregateRequestApprove",
    applyDdaAggregateRequestRepprove = "applyDdaAggregateRequestRepprove",
    removePassword4 = "removePassword4",
    sendNewProofOfLife = "sendNewProofOfLife",
    requestNewDevice = "requestNewDevice",
    approveNewDevice = "approveNewDevice",
    updateStatusReducedJourney = "updateStatusReducedJourney",
}

export enum DeviceInfoDeviceType {
    ios = "ios",
    android = "android",
    browser = "browser",
    api = "api",
}

export enum AuditActionsAdmin {
    adminLogin = "adminLogin",
    changeStatusUser = "changeStatusUser",
    setOperations = "setOperations",
    setExceptionDate = "setExceptionDate",
    setAdminUser = "setAdminUser",
    manageServices = "manageServices",
    generate6Pass = "generate6Pass",
    resetUser4Pass = "resetUser4Pass",
    deleteAdminUser = "deleteAdminUser",
    deleteExceptionDay = "deleteExceptionDay",
    setPermission = "setPermission",
    setUser = "setUser",
    createUserInCompany = "createUserInCompany",
    setUserInCompany = "setUserInCompany",
    createProfile = "createProfile",
    setPartner = "setPartner",
    blockPartner = "blockPartner",
    sendLinkToRecovery = "sendLinkToRecovery",
    changeStatusWorkflowPF = "changeStatusWorkflowPF",
    changeStatusWorkflowPJ = "changeStatusWorkflowPJ",
    changeStatusWorkflowPJMEI = "changeStatusWorkflowPJMEI",
    changeErrorMessage = "changeErrorMessage",
    changePartnerId = "changePartnerId",
    changeUserAddress = "changeUserAddress",
    approveProofOfLife = "approveProofOfLife",
    reproveProofOfLife = "reproveProofOfLife",
    updateStatusReducedJourney = "updateStatusReducedJourney",
}

export enum PermissionType {
    admin = "admin",
    listUsers = "listUsers",
    connectPartners = "connectPartners",
    statement = "statement",
    investmentStatement = "investmentStatement",
    balance = "balance",
    requestTransferenceSameTitularity = "requestTransferenceSameTitularity",
    requestTransferenceAnyTitularity = "requestTransferenceAnyTitularity",
    approveTransferenceSameTitularity = "approveTransferenceSameTitularity",
    approveTransferenceAnyTitularity = "approveTransferenceAnyTitularity",
    requestPayment = "requestPayment",
    approvePayment = "approvePayment",
    requestInvestmentApplication = "requestInvestmentApplication",
    approveInvestmentApplication = "approveInvestmentApplication",
    requestIncludeInstructionTitle = "requestIncludeInstructionTitle",
    approveIncludeInstructionTitle = "approveIncludeInstructionTitle",
    requestBankslipPayment = "requestBankslipPayment",
    approveBankslipPayment = "approveBankslipPayment",
    requestAgreementPayment = "requestAgreementPayment",
    approveAgreementPayment = "approveAgreementPayment",
    registerUser = "registerUser",
    editProfiles = "editProfiles",
    changeUserPermissions = "changeUserPermissions",
    approveWithdraw = "approveWithdraw",
    requestWithdraw = "requestWithdraw",
    requestRecharge = "requestRecharge",
    approveRecharge = "approveRecharge",
    viewCard = "viewCard",
    managerCard = "managerCard",
    requestManagerCard = "requestManagerCard",
}

export enum CardBrand {
    visa = "visa",
    mastercard = "mastercard",
}

export enum PermissionsPartner {
    request = "request",
    statement = "statement",
    cashback = "cashback",
    duplicate = "duplicate",
    lossOrTheft = "lossOrTheft",
    activate = "activate",
    cancel = "cancel",
    block = "block",
    unblock = "unblock",
    changePassword = "changePassword",
    virtualCard = "virtualCard",
    forgotPassword = "forgotPassword",
}

export enum PermissionsPartnerInput {
    password = "password",
    address = "address",
    last4Digits = "last4Digits",
    dueDate = "dueDate",
    userTerms = "userTerms",
}

export enum CardStatus {
    inProduction = "inProduction",
    actived = "actived",
    pending = "pending",
    blocked = "blocked",
    processing = "processing",
    queue = "queue",
    inserted = "inserted",
}

export enum VirtualCardStatus {
    activated = "activated",
    blocked = "blocked",
    canceled = "canceled",
    expired = "expired",
}

export enum GenderCustomer {
    M = "M",
    F = "F",
}

export enum CardType {
    physical = "physical",
    virtual = "virtual",
}

export enum CardStatusReport {
    inProduction = "inProduction",
    inserted = "inserted",
    deactivated = "deactivated",
    activated = "activated",
    invalidated = "invalidated",
    blocked = "blocked",
    canceled = "canceled",
    expired = "expired",
    queue = "queue",
}

export enum VisaCardQueueStatus {
    waiting = "waiting",
    processing = "processing",
    processed = "processed",
    failed = "failed",
}

export enum JourneyType {
    preApproved = "preApproved",
    noRecord = "noRecord",
}

export enum PfPj {
    pf = "pf",
    pj = "pj",
    mei = "mei",
}

export enum ApprovationType {
    manual = "manual",
    automatic = "automatic",
    unknown = "unknown",
}

export enum SubType {
    simple = "simple",
    full = "full",
}

export enum BlockStatusAccount {
    blocked = "blocked",
    unlocked = "unlocked",
    automatic = "automatic",
}

export enum UserStatus {
    pending = "pending",
    active = "active",
    repproved = "repproved",
    canceled = "canceled",
    finished = "finished",
    notFinished = "notFinished",
    processing = "processing",
    approvePending = "approvePending",
    approvePendingWithDocument = "approvePendingWithDocument",
    approvePendingWithoutDocument = "approvePendingWithoutDocument",
}

export enum Status {
    pending = "pending",
    active = "active",
    repproved = "repproved",
    canceled = "canceled",
    finished = "finished",
    notFinished = "notFinished",
    processing = "processing",
    approvePending = "approvePending",
    approvePendingWithDocument = "approvePendingWithDocument",
    approvePendingWithoutDocument = "approvePendingWithoutDocument",
}

export enum StatusV13 {
    pending = "pending",
    active = "active",
    repproved = "repproved",
    canceled = "canceled",
    finished = "finished",
    notFinished = "notFinished",
    processing = "processing",
    approvePending = "approvePending",
    approvePendingWithDocument = "approvePendingWithDocument",
    approvePendingWithoutDocument = "approvePendingWithoutDocument",
    waitingSignature = "waitingSignature",
    undefined = "undefined",
}

export enum ComplianceStatus {
    waiting = "waiting",
    approved = "approved",
    repproved = "repproved",
    manualAnalysis = "manualAnalysis",
    canceled = "canceled",
    processing = "processing",
}

export enum AccountStatusV13 {
    active = "active",
    closed = "closed",
}

export enum DocumentType {
    CNH = "CNH",
    RG = "RG",
    RNE = "RNE",
}

export enum Gender {
    masculino = "masculino",
    feminino = "feminino",
    outro = "outro",
}

export enum DocumentDispatcher {
    DETRAN = "DETRAN",
    CRM = "CRM",
    CRO = "CRO",
    OAB = "OAB",
    SSP = "SSP",
    OUTROS = "OUTROS",
}

export enum RegisterSourceType {
    user = "user",
    acertId = "acertId",
    assertiva = "assertiva",
    serpro = "serpro",
    cep = "cep",
}

export enum WeddingType {
    partialCommunion = "partialCommunion",
    universalCommunion = "universalCommunion",
    totalSeparation = "totalSeparation",
    compulsorySeparation = "compulsorySeparation",
    finalParticipation = "finalParticipation",
}

export enum CivilStatus {
    single = "single",
    married = "married",
    divorced = "divorced",
    separate = "separate",
    widower = "widower",
}

export enum CreateAccountType {
    number = "number",
    email = "email",
    text = "text",
    password = "password",
    currency = "currency",
    select = "select",
    checkbox = "checkbox",
    radio = "radio",
    button = "button",
    date = "date",
    photo = "photo",
    cpf = "cpf",
    phone = "phone",
    securityCode = "securityCode",
    cep = "cep",
    disclaimer = "disclaimer",
    agreement = "agreement",
    declaration = "declaration",
    question = "question",
}

export enum CategoryAccount {
    exchange = "exchange",
    credit = "credit",
    investment = "investment",
    payment = "payment",
    checking = "checking",
}

export enum ActionRecoverUser {
    blockPassword6 = "blockPassword6",
    resetPassword6 = "resetPassword6",
    createPassword6 = "createPassword6",
    recoverPassword6 = "recoverPassword6",
    recoverPassword4 = "recoverPassword4",
    removeToken = "removeToken",
    blockUser = "blockUser",
}

export enum RegisterField {
    name = "name",
    email = "email",
    phone = "phone",
    socialName = "socialName",
    gender = "gender",
    filiacao1 = "filiacao1",
    filiacao2 = "filiacao2",
    nacionality = "nacionality",
    address = "address",
    commercialAddress = "commercialAddress",
    patrimony = "patrimony",
    monthlyIncome = "monthlyIncome",
    occupation = "occupation",
    naturalness = "naturalness",
    birthDate = "birthDate",
    civilStatus = "civilStatus",
    spouseName = "spouseName",
    weddingType = "weddingType",
    accountInfo = "accountInfo",
    document = "document",
    selfie = "selfie",
    proofOfAddress = "proofOfAddress",
    certificateMEI = "certificateMEI",
    companyName = "companyName",
    fantasyName = "fantasyName",
    foundationDate = "foundationDate",
}

export enum BlockEvent {
    lost = "lost",
    theft = "theft",
    locked = "locked",
}

export enum ManagerCardAction {
    newCard = "newCard",
    activate = "activate",
    lock = "lock",
    unlock = "unlock",
    block = "block",
    unblock = "unblock",
    createPassword = "createPassword",
    changePassword = "changePassword",
    resetPassword = "resetPassword",
    cancel = "cancel",
}

export enum RegisterDataDocumentType {
    rg = "rg",
    cnh = "cnh",
}

export enum IsPPEDeclaration {
    iam = "iam",
    IHaveRelationship = "IHaveRelationship",
}

export enum StatusProofOfLife {
    blocked = "blocked",
    waitingEmail = "waitingEmail",
    sendingEmail = "sendingEmail",
    sendedEmail = "sendedEmail",
    waitingAcertId = "waitingAcertId",
    sendingAcertId = "sendingAcertId",
    sendedAcertId = "sendedAcertId",
    approved = "approved",
    invalid = "invalid",
    reproved = "reproved",
    approvedManual = "approvedManual",
    failed = "failed",
    pending = "pending",
}

export enum BroadCaster {
    dock = "dock",
}

export enum MultiCard {
    without = "without",
    free = "free",
    restricted = "restricted",
}

export enum Day {
    sunday = "sunday",
    monday = "monday",
    tuesday = "tuesday",
    wednesday = "wednesday",
    thursday = "thursday",
    friday = "friday",
    satuday = "satuday",
}

export enum ServiceNames {
    ted = "ted",
    doc = "doc",
    internalTransfer = "internalTransfer",
    scheduling = "scheduling",
    applyInvestment = "applyInvestment",
    viewInvestment = "viewInvestment",
    viewBalanceStatement = "viewBalanceStatement",
    generateBankslip = "generateBankslip",
    paymentBankSlip = "paymentBankSlip",
    paymentBankslip = "paymentBankslip",
    paymentAgreement = "paymentAgreement",
    accessInternetBanking = "accessInternetBanking",
    accessApps = "accessApps",
    openBanking = "openBanking",
    viewEditPackageService = "viewEditPackageService",
    createAccountPj = "createAccountPj",
    createAccountPf = "createAccountPf",
    createAccountPjPartner = "createAccountPjPartner",
    createAccountPfPartner = "createAccountPfPartner",
    revenueReportPf = "revenueReportPf",
    revenueReportPj = "revenueReportPj",
    withdraw = "withdraw",
    sendEmail = "sendEmail",
    sendNotification = "sendNotification",
    createAccountAuto = "createAccountAuto",
    createAccountAutoMEI = "createAccountAutoMEI",
    recharge = "recharge",
}

export enum ServiceNamesV13 {
    ted = "ted",
    doc = "doc",
    internalTransfer = "internalTransfer",
    scheduling = "scheduling",
    applyInvestment = "applyInvestment",
    viewInvestment = "viewInvestment",
    viewBalanceStatement = "viewBalanceStatement",
    generateBankslip = "generateBankslip",
    paymentBankSlip = "paymentBankSlip",
    paymentBankslip = "paymentBankslip",
    paymentAgreement = "paymentAgreement",
    accessInternetBanking = "accessInternetBanking",
    accessApps = "accessApps",
    openBanking = "openBanking",
    viewEditPackageService = "viewEditPackageService",
    createAccountPj = "createAccountPj",
    createAccountPf = "createAccountPf",
    createAccountPjPartner = "createAccountPjPartner",
    createAccountPfPartner = "createAccountPfPartner",
    revenueReportPf = "revenueReportPf",
    revenueReportPj = "revenueReportPj",
    withdraw = "withdraw",
    sendEmail = "sendEmail",
    sendNotification = "sendNotification",
    createAccountAuto = "createAccountAuto",
    createAccountAutoMEI = "createAccountAutoMEI",
    recharge = "recharge",
    fepwebLoginCheck = "fepwebLoginCheck",
    proofOfLifePJ = "proofOfLifePJ",
    insurance = "insurance",
}

export enum OperationType {
    ted = "ted",
    doc = "doc",
    internalTransfer = "internalTransfer",
    investment = "investment",
    titleInclusion = "titleInclusion",
    withdraw = "withdraw",
    recharge = "recharge",
    payment = "payment",
    paymentBankSlip = "paymentBankSlip",
    paymentAgreement = "paymentAgreement",
    createAccountAuto = "createAccountAuto",
    createAccountAutoMEI = "createAccountAutoMEI",
    fepwebLoginCheck = "fepwebLoginCheck",
    proofOfLifePJ = "proofOfLifePJ",
    insurance = "insurance",
}

export enum FeatureSlug {
    CHARGE = "CHARGE",
    REGISTER = "REGISTER",
    NEWS = "NEWS",
}

export enum NotificationPermissions {
    actions = "actions",
    movimentation = "movimentation",
}

export enum DdaProfileStatus {
    nonexistent = "nonexistent",
    active = "active",
    deactivated = "deactivated",
    pending = "pending",
    processing = "processing",
}

export enum ElectronicTitleSituation {
    vencido = "vencido",
    avencer = "avencer",
    agendado = "agendado",
    pago = "pago",
    desconhecido = "desconhecido",
}

export enum IndentityDocType {
    cpf = "cpf",
    cnpj = "cnpj",
}

export enum TokenPermissions {
    approveTransferenceSameTitularity = "approveTransferenceSameTitularity",
    approveTransferenceAnyTitularity = "approveTransferenceAnyTitularity",
    approvePayment = "approvePayment",
    approveInvestmentApplication = "approveInvestmentApplication",
    approveIncludeInstructionTitle = "approveIncludeInstructionTitle",
}

export enum CaixaWithdrawStatus {
    unreceived = "unreceived",
    received = "received",
    canceled = "canceled",
    expired = "expired",
    processing = "processing",
}

export enum NewsType {
    video = "video",
    image = "image",
    audio = "audio",
}

export enum Region {
    sul = "sul",
    norte = "norte",
    sudeste = "sudeste",
    nordeste = "nordeste",
    centroOeste = "centroOeste",
}

export enum PartnerCardQueueStatus {
    processing = "processing",
    processed = "processed",
}

export enum ErrorType {
    WrongPassword = "WrongPassword",
    PasswordBlocked = "PasswordBlocked",
    ExpiredPassword = "ExpiredPassword",
    ExpiredPasswordToken = "ExpiredPasswordToken",
    MissingOldPassword = "MissingOldPassword",
    MissingNewPassword = "MissingNewPassword",
    SessionNotFound = "SessionNotFound",
    InvalidSession = "InvalidSession",
    ExpiredSession = "ExpiredSession",
    TerminateSession = "TerminateSession",
    InvalidPartner = "InvalidPartner",
    AuthTokenAlreadyUsed = "AuthTokenAlreadyUsed",
    AccessTokenNotFound = "AccessTokenNotFound",
    AuthTokenNotFound = "AuthTokenNotFound",
    InvalidAccessToken = "InvalidAccessToken",
    InvalidAuthToken = "InvalidAuthToken",
    ExpiredAuthToken = "ExpiredAuthToken",
    MissingPublicKey = "MissingPublicKey",
    MissingPermission = "MissingPermission",
    NotFound = "NotFound",
    Bridge = "Bridge",
    UnavailableService = "UnavailableService",
    PartnerNotFound = "PartnerNotFound",
    AccessNotAllowed = "AccessNotAllowed",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidSignature = "InvalidSignature",
    MissingSignature = "MissingSignature",
    AdminUserPermissionError = "AdminUserPermissionError",
    ExceptionAlreadyExists = "ExceptionAlreadyExists",
    MissingAdminPassword = "MissingAdminPassword",
    InsufficientFunds = "InsufficientFunds",
    NotPermited = "NotPermited",
    UnavailableDay = "UnavailableDay",
    PostponedSchedule = "PostponedSchedule",
    Timeout = "Timeout",
    PartnerBaseError = "PartnerBaseError",
    Fatal = "Fatal",
    Connection = "Connection",
}

export async function getPartnerConfig(partnerId: string | null): Promise<PartnerConfig | null> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getPartnerConfig", args});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        flag: ret.flag,
        rules: ret.rules.map((e: any) => ({
            permission: e.permission,
            inputs: e.inputs.map((e: any) => e),
        })),
        partnerId: ret.partnerId,
        productId: ret.productId === null || ret.productId === undefined ? null : ret.productId,
        plasticId: ret.plasticId === null || ret.plasticId === undefined ? null : ret.plasticId,
        imageId: ret.imageId === null || ret.imageId === undefined ? null : ret.imageId,
        imageDescription: ret.imageDescription === null || ret.imageDescription === undefined ? null : ret.imageDescription,
        commercialOrigin: ret.commercialOrigin === null || ret.commercialOrigin === undefined ? null : ret.commercialOrigin,
        showCard: !!ret.showCard,
        virtualCardFlag: !!ret.virtualCardFlag,
        physicalCardFlag: !!ret.physicalCardFlag,
    };
}

export async function getCardsInfo(branch: string, account: string): Promise<InfoCard[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getCardsInfo", args});
    return ret.map((e: any) => ({
        id: e.id,
        codeRequest: e.codeRequest,
        branch: e.branch,
        account: e.account,
        fourLastDigits: e.fourLastDigits,
        blockEvent: e.blockEvent === null || e.blockEvent === undefined ? null : e.blockEvent,
        status: e.status,
        validUntil: {
            month: e.validUntil.month | 0,
            year: e.validUntil.year | 0,
        },
    }));
}

export async function getCardsInfoV2(branch: string, account: string): Promise<InfoCard[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getCardsInfoV2", args});
    return ret.map((e: any) => ({
        id: e.id,
        codeRequest: e.codeRequest,
        branch: e.branch,
        account: e.account,
        fourLastDigits: e.fourLastDigits,
        blockEvent: e.blockEvent === null || e.blockEvent === undefined ? null : e.blockEvent,
        status: e.status,
        validUntil: {
            month: e.validUntil.month | 0,
            year: e.validUntil.year | 0,
        },
    }));
}

export async function getCardsInfoV3(branch: string, account: string): Promise<InfoCard[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getCardsInfoV3", args});
    return ret.map((e: any) => ({
        id: e.id,
        codeRequest: e.codeRequest,
        branch: e.branch,
        account: e.account,
        fourLastDigits: e.fourLastDigits,
        blockEvent: e.blockEvent === null || e.blockEvent === undefined ? null : e.blockEvent,
        status: e.status,
        validUntil: {
            month: e.validUntil.month | 0,
            year: e.validUntil.year | 0,
        },
    }));
}

export async function getCardsInfoV4(branch: string, account: string): Promise<InfoCardV2[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getCardsInfoV4", args});
    return ret.map((e: any) => ({
        id: e.id,
        codeRequest: e.codeRequest,
        branch: e.branch,
        account: e.account,
        imageId: e.imageId === null || e.imageId === undefined ? null : e.imageId,
        imageDescription: e.imageDescription === null || e.imageDescription === undefined ? null : e.imageDescription,
        fourLastDigits: e.fourLastDigits,
        blockEvent: e.blockEvent === null || e.blockEvent === undefined ? null : e.blockEvent,
        status: e.status,
        validUntil: {
            month: e.validUntil.month | 0,
            year: e.validUntil.year | 0,
        },
    }));
}

export async function requestCard(account: string, branch: string, address: AddressInfo | null, location: LatLng | null): Promise<string> {
  const args = {
        account: account,
        branch: branch,
        address: address === null || address === undefined ? null : {
            cep: address.cep,
            street: address.street,
            neighborhood: address.neighborhood,
            number: address.number,
            complement: address.complement === null || address.complement === undefined ? null : address.complement,
            city: address.city,
            state: address.state,
            country: address.country,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestCard", args});
    return ret;
}

export async function requestCardV2(account: string, branch: string, address: AddressInfo | null, passwordCard: string | null, location: LatLng | null): Promise<string> {
  const args = {
        account: account,
        branch: branch,
        address: address === null || address === undefined ? null : {
            cep: address.cep,
            street: address.street,
            neighborhood: address.neighborhood,
            number: address.number,
            complement: address.complement === null || address.complement === undefined ? null : address.complement,
            city: address.city,
            state: address.state,
            country: address.country,
        },
        passwordCard: passwordCard === null || passwordCard === undefined ? null : passwordCard,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestCardV2", args});
    return ret;
}

export async function requestCardV3(account: string, branch: string, address: AddressInfo | null, passwordCard: string | null, location: LatLng | null, productId: string, imageId: string): Promise<string> {
  const args = {
        account: account,
        branch: branch,
        address: address === null || address === undefined ? null : {
            cep: address.cep,
            street: address.street,
            neighborhood: address.neighborhood,
            number: address.number,
            complement: address.complement === null || address.complement === undefined ? null : address.complement,
            city: address.city,
            state: address.state,
            country: address.country,
        },
        passwordCard: passwordCard === null || passwordCard === undefined ? null : passwordCard,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        productId: productId,
        imageId: imageId,
  };
  const ret = await makeRequest({name: "requestCardV3", args});
    return ret;
}

export async function blockCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "blockCard", args});
    return undefined;
}

export async function blockCardV2(infoCard: InfoCardV2, location: LatLng | null, productId: string, imageId: string): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        productId: productId,
        imageId: imageId,
  };
  await makeRequest({name: "blockCardV2", args});
    return undefined;
}

export async function cancelCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "cancelCard", args});
    return undefined;
}

export async function cancelCardV2(infoCard: InfoCardV2, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "cancelCardV2", args});
    return undefined;
}

export async function createPasswordCard(infoCard: InfoCard, passwordCard: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        passwordCard: passwordCard,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "createPasswordCard", args});
    return undefined;
}

export async function createPasswordCardV2(infoCard: InfoCardV2, passwordCard: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        passwordCard: passwordCard,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "createPasswordCardV2", args});
    return undefined;
}

export async function changePasswordCard(infoCard: InfoCard, currentPassword: string, newPassword: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        currentPassword: currentPassword,
        newPassword: newPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "changePasswordCard", args});
    return undefined;
}

export async function changePasswordCardV2(infoCard: InfoCardV2, currentPassword: string, newPassword: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        currentPassword: currentPassword,
        newPassword: newPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "changePasswordCardV2", args});
    return undefined;
}

export async function checksPhysicCardExists(branch: string, account: string): Promise<boolean> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "checksPhysicCardExists", args});
    return !!ret;
}

export async function forgotCardPassword(infoCard: InfoCard, password4: string, newPassword: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        password4: password4,
        newPassword: newPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "forgotCardPassword", args});
    return undefined;
}

export async function forgotCardPasswordV2(infoCard: InfoCardV2, password4: string, newPassword: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        password4: password4,
        newPassword: newPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "forgotCardPasswordV2", args});
    return undefined;
}

export async function activeCard(infoCard: InfoCard, passwordCard: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        passwordCard: passwordCard,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "activeCard", args});
    return undefined;
}

export async function activeCardV2(infoCard: InfoCardV2, passwordCard: string, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        passwordCard: passwordCard,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "activeCardV2", args});
    return undefined;
}

export async function resetPasswordCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "resetPasswordCard", args});
    return undefined;
}

export async function resetPasswordCardV2(infoCard: InfoCardV2, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "resetPasswordCardV2", args});
    return undefined;
}

export async function lockCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "lockCard", args});
    return undefined;
}

export async function lockCardV2(infoCard: InfoCardV2, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "lockCardV2", args});
    return undefined;
}

export async function unLockCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "unLockCard", args});
    return undefined;
}

export async function unLockCardV2(infoCard: InfoCard, location: LatLng | null, cvv: string | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        cvv: cvv === null || cvv === undefined ? null : cvv,
  };
  await makeRequest({name: "unLockCardV2", args});
    return undefined;
}

export async function unLockCardV3(infoCard: InfoCardV2, location: LatLng | null, cvv: string | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            imageId: infoCard.imageId === null || infoCard.imageId === undefined ? null : infoCard.imageId,
            imageDescription: infoCard.imageDescription === null || infoCard.imageDescription === undefined ? null : infoCard.imageDescription,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        cvv: cvv === null || cvv === undefined ? null : cvv,
  };
  await makeRequest({name: "unLockCardV3", args});
    return undefined;
}

export async function getCardCustomer(cardId: string): Promise<Customer> {
  const args = {
        cardId: cardId,
  };
  const ret = await makeRequest({name: "getCardCustomer", args});
    return {
        name: ret.name,
        nameMother: ret.nameMother,
        gender: ret.gender,
        documentNumber: ret.documentNumber,
        otherDocument: {
            number: ret.otherDocument.number,
            type: ret.otherDocument.type,
        },
        address: ret.address,
        streetNumber: ret.streetNumber,
        proviceResidence: {
            stateName: ret.proviceResidence.stateName,
            stateInicials: ret.proviceResidence.stateInicials,
            name: ret.proviceResidence.name,
        },
        nationalResidence: ret.nationalResidence,
        district: ret.district,
        complement: ret.complement,
        zip: ret.zip,
        shipmentAddress: ret.shipmentAddress,
        shipmentZip: ret.shipmentZip,
        mobile: ret.mobile,
        email: ret.email,
        birthDate: ret.birthDate,
        birthNacionName: ret.birthNacionName,
    };
}

export async function getCardTerms(): Promise<string> {
  const ret = await makeRequest({name: "getCardTerms", args: {}});
    return ret;
}

export async function getCardReport(limit: number, offset: number, includeVirtualCards: boolean): Promise<CardReport> {
  const args = {
        limit: limit | 0,
        offset: offset | 0,
        includeVirtualCards: !!includeVirtualCards,
  };
  const ret = await makeRequest({name: "getCardReport", args});
    return {
        result: ret.result.map((e: any) => ({
            cardId: e.cardId,
            name: e.name,
            account: e.account,
            cpf: e.cpf,
            requestedDate: new Date(parseInt(e.requestedDate.split("-")[0], 10), parseInt(e.requestedDate.split("-")[1], 10) - 1, parseInt(e.requestedDate.split("-")[2], 10)),
            gatewayAccountId: e.gatewayAccountId === null || e.gatewayAccountId === undefined ? null : e.gatewayAccountId,
            gatewayCardId: e.gatewayCardId === null || e.gatewayCardId === undefined ? null : e.gatewayCardId,
            attempts: e.attempts | 0,
            partnerName: e.partnerName,
            cardType: e.cardType,
            requestStatus: e.requestStatus,
            cardStatus: e.cardStatus,
        })),
        limit: ret.limit | 0,
        offset: ret.offset | 0,
        total: ret.total | 0,
    };
}

export async function getExcelCardReport(includeVirtualCards: boolean): Promise<Buffer> {
  const args = {
        includeVirtualCards: !!includeVirtualCards,
  };
  const ret = await makeRequest({name: "getExcelCardReport", args});
    return Buffer.from(ret, "base64");
}

export async function checkVirtualCardExists(infoCard: InfoCard, location: LatLng | null): Promise<boolean> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "checkVirtualCardExists", args});
    return !!ret;
}

export async function generateVirtualCard(infoCard: InfoCard, password4: string, location: LatLng | null): Promise<VirtualCard> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        password4: password4,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "generateVirtualCard", args});
    return {
        id: ret.id,
        number: ret.number,
        cvv: ret.cvv,
        printedName: ret.printedName,
        createdAt: new Date(ret.createdAt + "Z"),
        dueDate: new Date(ret.dueDate + "Z"),
        dueDateText: ret.dueDateText,
        status: ret.status,
        statusMessage: ret.statusMessage === null || ret.statusMessage === undefined ? null : ret.statusMessage,
    };
}

export async function getVirtualCard(infoCard: InfoCard, password4: string, location: LatLng | null): Promise<VirtualCard> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        password4: password4,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "getVirtualCard", args});
    return {
        id: ret.id,
        number: ret.number,
        cvv: ret.cvv,
        printedName: ret.printedName,
        createdAt: new Date(ret.createdAt + "Z"),
        dueDate: new Date(ret.dueDate + "Z"),
        dueDateText: ret.dueDateText,
        status: ret.status,
        statusMessage: ret.statusMessage === null || ret.statusMessage === undefined ? null : ret.statusMessage,
    };
}

export async function cancelVirtualCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "cancelVirtualCard", args});
    return undefined;
}

export async function lockVirtualCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "lockVirtualCard", args});
    return undefined;
}

export async function unLockVirtualCard(infoCard: InfoCard, location: LatLng | null): Promise<void> {
  const args = {
        infoCard: {
            id: infoCard.id,
            codeRequest: infoCard.codeRequest,
            branch: infoCard.branch,
            account: infoCard.account,
            fourLastDigits: infoCard.fourLastDigits,
            blockEvent: infoCard.blockEvent === null || infoCard.blockEvent === undefined ? null : infoCard.blockEvent,
            status: infoCard.status,
            validUntil: {
                month: infoCard.validUntil.month | 0,
                year: infoCard.validUntil.year | 0,
            },
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "unLockVirtualCard", args});
    return undefined;
}

export async function checkVirtualCardExistsV3(branch: string, account: string): Promise<CheckVirtualCard> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "checkVirtualCardExistsV3", args});
    return {
        hasCard: !!ret.hasCard,
        isFirstCard: !!ret.isFirstCard,
    };
}

export async function checkVirtualCardExistsV2(branch: string, account: string): Promise<boolean> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "checkVirtualCardExistsV2", args});
    return !!ret;
}

export async function generateVirtualCardV2(branch: string, account: string, password4: string | null, sign: Signature | null, location: LatLng | null): Promise<VirtualCard> {
  const args = {
        branch: branch,
        account: account,
        password4: password4 === null || password4 === undefined ? null : password4,
        sign: sign === null || sign === undefined ? null : {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "generateVirtualCardV2", args});
    return {
        id: ret.id,
        number: ret.number,
        cvv: ret.cvv,
        printedName: ret.printedName,
        createdAt: new Date(ret.createdAt + "Z"),
        dueDate: new Date(ret.dueDate + "Z"),
        dueDateText: ret.dueDateText,
        status: ret.status,
        statusMessage: ret.statusMessage === null || ret.statusMessage === undefined ? null : ret.statusMessage,
    };
}

export async function getVirtualCardV2(branch: string, account: string, password4: string | null, sign: Signature | null): Promise<VirtualCard> {
  const args = {
        branch: branch,
        account: account,
        password4: password4 === null || password4 === undefined ? null : password4,
        sign: sign === null || sign === undefined ? null : {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
  };
  const ret = await makeRequest({name: "getVirtualCardV2", args});
    return {
        id: ret.id,
        number: ret.number,
        cvv: ret.cvv,
        printedName: ret.printedName,
        createdAt: new Date(ret.createdAt + "Z"),
        dueDate: new Date(ret.dueDate + "Z"),
        dueDateText: ret.dueDateText,
        status: ret.status,
        statusMessage: ret.statusMessage === null || ret.statusMessage === undefined ? null : ret.statusMessage,
    };
}

export async function cancelVirtualCardV2(branch: string, account: string, location: LatLng | null): Promise<void> {
  const args = {
        branch: branch,
        account: account,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "cancelVirtualCardV2", args});
    return undefined;
}

export async function lockVirtualCardV2(branch: string, account: string, location: LatLng | null): Promise<void> {
  const args = {
        branch: branch,
        account: account,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "lockVirtualCardV2", args});
    return undefined;
}

export async function unlockVirtualCardV2(branch: string, account: string, location: LatLng | null): Promise<void> {
  const args = {
        branch: branch,
        account: account,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "unlockVirtualCardV2", args});
    return undefined;
}

export async function getCardProductImagesByPartnerId(): Promise<CardProductImage[]> {
  const ret = await makeRequest({name: "getCardProductImagesByPartnerId", args: {}});
    return ret.map((e: any) => ({
        productId: e.productId,
        imageId: e.imageId,
        description: e.description,
    }));
}

export async function prospectClearText(text: string): Promise<string> {
  const args = {
        text: text,
  };
  const ret = await makeRequest({name: "prospectClearText", args});
    return ret;
}

export async function getRecordPreApprovedInfoByCpfAndBirthday(partnerId: string, positiveDoc: string, positiveBirthDate: Date): Promise<Prospect[]> {
  const args = {
        partnerId: partnerId,
        positiveDoc: positiveDoc,
        positiveBirthDate: positiveBirthDate.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getRecordPreApprovedInfoByCpfAndBirthday", args});
    return ret.map((e: any) => ({
        id: e.id | 0,
        date: e.date === null || e.date === undefined ? null : new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        doc: e.doc === null || e.doc === undefined ? null : e.doc,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        name: e.name === null || e.name === undefined ? null : e.name,
        mother: e.mother === null || e.mother === undefined ? null : e.mother,
        zipCode: e.zipCode === null || e.zipCode === undefined ? null : e.zipCode,
        address: e.address === null || e.address === undefined ? null : e.address,
        addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
        complementAddress: e.complementAddress === null || e.complementAddress === undefined ? null : e.complementAddress,
        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
        city: e.city === null || e.city === undefined ? null : e.city,
        state: e.state === null || e.state === undefined ? null : e.state,
        DDD: e.DDD === null || e.DDD === undefined ? null : e.DDD,
        cellNumber: e.cellNumber === null || e.cellNumber === undefined ? null : e.cellNumber,
        email: e.email === null || e.email === undefined ? null : e.email,
        gender: e.gender === null || e.gender === undefined ? null : e.gender,
        grossIncome: e.grossIncome === null || e.grossIncome === undefined ? null : e.grossIncome,
        equityValue: e.equityValue === null || e.equityValue === undefined ? null : e.equityValue,
        obs1: e.obs1 === null || e.obs1 === undefined ? null : e.obs1,
        obs2: e.obs2 === null || e.obs2 === undefined ? null : e.obs2,
        partnerFileId: e.partnerFileId | 0,
        statusId: e.statusId === null || e.statusId === undefined ? null : e.statusId,
        status: {
            statusId: e.status.statusId === null || e.status.statusId === undefined ? null : e.status.statusId,
            statusName: e.status.statusName === null || e.status.statusName === undefined ? null : e.status.statusName,
        },
        partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
        partnerName: e.partnerName === null || e.partnerName === undefined ? null : e.partnerName,
        partnerFileStatusDescription: e.partnerFileStatusDescription === null || e.partnerFileStatusDescription === undefined ? null : e.partnerFileStatusDescription,
        partnerFileDescription: e.partnerFileDescription === null || e.partnerFileDescription === undefined ? null : e.partnerFileDescription,
        partnerFileStatusId: e.partnerFileStatusId === null || e.partnerFileStatusId === undefined ? null : e.partnerFileStatusId,
        partnerFileStatusName: e.partnerFileStatusName === null || e.partnerFileStatusName === undefined ? null : e.partnerFileStatusName,
    }));
}

export async function setPositiveToPreApprovedRecordByCpfAndBirthday(partnerId: string, positiveDoc: string, positiveBirthDate: Date): Promise<void> {
  const args = {
        partnerId: partnerId,
        positiveDoc: positiveDoc,
        positiveBirthDate: positiveBirthDate.toISOString().split("T")[0],
  };
  await makeRequest({name: "setPositiveToPreApprovedRecordByCpfAndBirthday", args});
    return undefined;
}

export async function getJourneyInfoByPartnerAndCPFAndBirthday(cpf: string, birthday: Date, partnerId: string): Promise<string> {
  const args = {
        cpf: cpf,
        birthday: birthday.toISOString().split("T")[0],
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getJourneyInfoByPartnerAndCPFAndBirthday", args});
    return ret;
}

export async function getJourneyInfoByPartner(partnerId: string): Promise<boolean> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getJourneyInfoByPartner", args});
    return !!ret;
}

export async function createReducedJourneyAccount(info: CreateReducedJourneyAccount, journeyType: JourneyType, deviceInfo: DeviceInfo): Promise<ReducedJourneyAccountResponse> {
  const args = {
        info: {
            partnerId: info.partnerId,
            name: info.name,
            motherName: info.motherName,
            cpf: info.cpf,
            birthDate: info.birthDate.toISOString().split("T")[0],
            gender: info.gender === null || info.gender === undefined ? null : info.gender,
            income: info.income,
            equity: info.equity,
            codeArea: info.codeArea,
            phone: info.phone,
            email: info.email,
            zipCode: info.zipCode,
            state: info.state,
            city: info.city,
            neighborhood: info.neighborhood,
            street: info.street,
            addressNumber: info.addressNumber,
            addressComplement: info.addressComplement === null || info.addressComplement === undefined ? null : info.addressComplement,
            selfie: info.selfie.toString("base64"),
            acceptTermsOfUse: !!info.acceptTermsOfUse,
            acceptContract: !!info.acceptContract,
            acceptLGPD: !!info.acceptLGPD,
            password: info.password,
            document: {
                documentType: info.document.documentType,
                imageFront: info.document.imageFront.toString("base64"),
                imageBack: info.document.imageBack === null || info.document.imageBack === undefined ? null : info.document.imageBack.toString("base64"),
            },
            ppe: info.ppe === null || info.ppe === undefined ? null : !!info.ppe,
            ppeInfo: info.ppeInfo === null || info.ppeInfo === undefined ? null : {
                relatedPpe: !!info.ppeInfo.relatedPpe,
                relatedPpeInfo: info.ppeInfo.relatedPpeInfo === null || info.ppeInfo.relatedPpeInfo === undefined ? null : {
                    name: info.ppeInfo.relatedPpeInfo.name,
                    relation: info.ppeInfo.relatedPpeInfo.relation,
                },
                start: info.ppeInfo.start.toISOString().split("T")[0],
                end: info.ppeInfo.end.toISOString().split("T")[0],
                role: info.ppeInfo.role,
            },
            fatca: info.fatca === null || info.fatca === undefined ? null : !!info.fatca,
        },
        journeyType: journeyType,
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
  };
  const ret = await makeRequest({name: "createReducedJourneyAccount", args});
    return {
        message: ret.message,
        complienceResponse: ret.complienceResponse,
    };
}

export async function createReducedJourneyAccountIOS(info: CreateReducedJourneyAccount, journeyType: JourneyType, deviceInfo: DeviceInfo): Promise<ReducedJourneyAccountResponse> {
  const args = {
        info: {
            partnerId: info.partnerId,
            name: info.name,
            motherName: info.motherName,
            cpf: info.cpf,
            birthDate: info.birthDate.toISOString().split("T")[0],
            gender: info.gender === null || info.gender === undefined ? null : info.gender,
            income: info.income,
            equity: info.equity,
            codeArea: info.codeArea,
            phone: info.phone,
            email: info.email,
            zipCode: info.zipCode,
            state: info.state,
            city: info.city,
            neighborhood: info.neighborhood,
            street: info.street,
            addressNumber: info.addressNumber,
            addressComplement: info.addressComplement === null || info.addressComplement === undefined ? null : info.addressComplement,
            selfie: info.selfie.toString("base64"),
            acceptTermsOfUse: !!info.acceptTermsOfUse,
            acceptContract: !!info.acceptContract,
            acceptLGPD: !!info.acceptLGPD,
            password: info.password,
            document: {
                documentType: info.document.documentType,
                imageFront: info.document.imageFront.toString("base64"),
                imageBack: info.document.imageBack === null || info.document.imageBack === undefined ? null : info.document.imageBack.toString("base64"),
            },
            ppe: info.ppe === null || info.ppe === undefined ? null : !!info.ppe,
            ppeInfo: info.ppeInfo === null || info.ppeInfo === undefined ? null : {
                relatedPpe: !!info.ppeInfo.relatedPpe,
                relatedPpeInfo: info.ppeInfo.relatedPpeInfo === null || info.ppeInfo.relatedPpeInfo === undefined ? null : {
                    name: info.ppeInfo.relatedPpeInfo.name,
                    relation: info.ppeInfo.relatedPpeInfo.relation,
                },
                start: info.ppeInfo.start.toISOString().split("T")[0],
                end: info.ppeInfo.end.toISOString().split("T")[0],
                role: info.ppeInfo.role,
            },
            fatca: info.fatca === null || info.fatca === undefined ? null : !!info.fatca,
        },
        journeyType: journeyType,
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
  };
  const ret = await makeRequest({name: "createReducedJourneyAccountIOS", args});
    return {
        message: ret.message,
        complienceResponse: ret.complienceResponse,
    };
}

export async function verifyCPFList(cpf: string): Promise<boolean> {
  const args = {
        cpf: cpf,
  };
  const ret = await makeRequest({name: "verifyCPFList", args});
    return !!ret;
}

export async function sendLinkToRecovery(cpf: string | null, actionRecovery: ActionRecoverUser, location: LatLng | null): Promise<string> {
  const args = {
        cpf: cpf === null || cpf === undefined ? null : cpf,
        actionRecovery: actionRecovery,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "sendLinkToRecovery", args});
    return ret;
}

export async function sendLinkToRecoveryV13(userId: string | null, actionRecovery: ActionRecoverUser, location: LatLng | null, isFromAdmin: boolean): Promise<string> {
  const args = {
        userId: userId === null || userId === undefined ? null : userId,
        actionRecovery: actionRecovery,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        isFromAdmin: !!isFromAdmin,
  };
  const ret = await makeRequest({name: "sendLinkToRecoveryV13", args});
    return ret;
}

export async function recoveryByLink(cpf: string, cnpj: string | null, isMei: boolean, actionRecovery: ActionRecoverUser, location: LatLng | null): Promise<string> {
  const args = {
        cpf: cpf,
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
        isMei: !!isMei,
        actionRecovery: actionRecovery,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "recoveryByLink", args});
    return ret;
}

export async function resetByRecoveryToken(tokenOnLink: string, oldPassword: string | null, newPassword: string | null, location: LatLng | null): Promise<void> {
  const args = {
        tokenOnLink: tokenOnLink,
        oldPassword: oldPassword === null || oldPassword === undefined ? null : oldPassword,
        newPassword: newPassword === null || newPassword === undefined ? null : newPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "resetByRecoveryToken", args});
    return undefined;
}

export async function createPasswordByToken(tokenOnLink: string, newPassword: string, confirmNewPassword: string, location: LatLng | null): Promise<boolean> {
  const args = {
        tokenOnLink: tokenOnLink,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "createPasswordByToken", args});
    return !!ret;
}

export async function updatePasswordByToken(tokenOnLink: string, newPassword: string, confirmNewPassword: string, location: LatLng | null): Promise<boolean> {
  const args = {
        tokenOnLink: tokenOnLink,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "updatePasswordByToken", args});
    return !!ret;
}

export async function verifyDataToChangePassword(cpf: string, birthDate: Date, cep: string, tokenOnLink: string, location: LatLng | null, fromLinkSMS: boolean): Promise<string> {
  const args = {
        cpf: cpf,
        birthDate: birthDate.toISOString().split("T")[0],
        cep: cep,
        tokenOnLink: tokenOnLink,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        fromLinkSMS: !!fromLinkSMS,
  };
  const ret = await makeRequest({name: "verifyDataToChangePassword", args});
    return ret;
}

export async function verifySMSCodeToChangePassword(codeSMS: string, tokenOnLink: string, location: LatLng | null): Promise<boolean> {
  const args = {
        codeSMS: codeSMS,
        tokenOnLink: tokenOnLink,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "verifySMSCodeToChangePassword", args});
    return !!ret;
}

export async function verifyRecoveryToken(tokenOnLink: string): Promise<RecoverUser> {
  const args = {
        tokenOnLink: tokenOnLink,
  };
  const ret = await makeRequest({name: "verifyRecoveryToken", args});
    return {
        action: ret.action,
        name: ret.name,
        documentNumber: ret.documentNumber,
        partner: ret.partner === null || ret.partner === undefined ? null : {
            urlApp: ret.partner.urlApp === null || ret.partner.urlApp === undefined ? null : {
                android: ret.partner.urlApp.android,
                ios: ret.partner.urlApp.ios,
            },
            tokenUrlApp: ret.partner.tokenUrlApp === null || ret.partner.tokenUrlApp === undefined ? null : {
                android: ret.partner.tokenUrlApp.android,
                ios: ret.partner.tokenUrlApp.ios,
            },
            blocked: !!ret.partner.blocked,
            managerId: ret.partner.managerId,
            publicKey: ret.partner.publicKey,
            partnerSecret: ret.partner.partnerSecret,
            partnerUserName: ret.partner.partnerUserName === null || ret.partner.partnerUserName === undefined ? null : ret.partner.partnerUserName,
            allowAnyPartnerLogin: !!ret.partner.allowAnyPartnerLogin,
            openAccountsAttempts: ret.partner.openAccountsAttempts | 0,
            id: ret.partner.id,
            name: ret.partner.name,
            logo: ret.partner.logo,
            backgroundColor: ret.partner.backgroundColor === null || ret.partner.backgroundColor === undefined ? null : ret.partner.backgroundColor,
            secondaryColor: ret.partner.secondaryColor === null || ret.partner.secondaryColor === undefined ? null : ret.partner.secondaryColor,
            primaryColor: ret.partner.primaryColor === null || ret.partner.primaryColor === undefined ? null : ret.partner.primaryColor,
            font: ret.partner.font === null || ret.partner.font === undefined ? null : ret.partner.font,
            fontHref: ret.partner.fontHref === null || ret.partner.fontHref === undefined ? null : ret.partner.fontHref,
            errorColor: ret.partner.errorColor === null || ret.partner.errorColor === undefined ? null : ret.partner.errorColor,
            phone: ret.partner.phone === null || ret.partner.phone === undefined ? null : ret.partner.phone,
            email: ret.partner.email === null || ret.partner.email === undefined ? null : ret.partner.email,
            site: ret.partner.site === null || ret.partner.site === undefined ? null : ret.partner.site,
            urlTerm: ret.partner.urlTerm === null || ret.partner.urlTerm === undefined ? null : ret.partner.urlTerm,
            urlContract: ret.partner.urlContract === null || ret.partner.urlContract === undefined ? null : ret.partner.urlContract,
            urlTermPj: ret.partner.urlTermPj === null || ret.partner.urlTermPj === undefined ? null : ret.partner.urlTermPj,
            urlContractPj: ret.partner.urlContractPj === null || ret.partner.urlContractPj === undefined ? null : ret.partner.urlContractPj,
            urlFarePackages: ret.partner.urlFarePackages === null || ret.partner.urlFarePackages === undefined ? null : ret.partner.urlFarePackages,
            urlIB: ret.partner.urlIB,
            partnerHook: ret.partner.partnerHook === null || ret.partner.partnerHook === undefined ? null : {
                url: ret.partner.partnerHook.url,
                token: ret.partner.partnerHook.token,
            },
            cobrander: ret.partner.cobrander === null || ret.partner.cobrander === undefined ? null : {
                codeCobrander: ret.partner.cobrander.codeCobrander,
                codeCardCategory: ret.partner.cobrander.codeCardCategory,
                autoEmitCard: !!ret.partner.cobrander.autoEmitCard,
                isActivate: !!ret.partner.cobrander.isActivate,
                username: ret.partner.cobrander.username === null || ret.partner.cobrander.username === undefined ? null : ret.partner.cobrander.username,
                password: ret.partner.cobrander.password === null || ret.partner.cobrander.password === undefined ? null : ret.partner.cobrander.password,
            },
            hibotlink: ret.partner.hibotlink === null || ret.partner.hibotlink === undefined ? null : ret.partner.hibotlink,
            hibotmobilelink: ret.partner.hibotmobilelink === null || ret.partner.hibotmobilelink === undefined ? null : ret.partner.hibotmobilelink,
            cnpj: ret.partner.cnpj === null || ret.partner.cnpj === undefined ? null : ret.partner.cnpj,
            webhookUrl: ret.partner.webhookUrl === null || ret.partner.webhookUrl === undefined ? null : ret.partner.webhookUrl,
            emailResendTime: ret.partner.emailResendTime | 0,
            emailResendTimeFrequency: ret.partner.emailResendTimeFrequency | 0,
            emailFrom: ret.partner.emailFrom === null || ret.partner.emailFrom === undefined ? null : ret.partner.emailFrom,
            accountOpeningAttemptLimit: ret.partner.accountOpeningAttemptLimit === null || ret.partner.accountOpeningAttemptLimit === undefined ? null : ret.partner.accountOpeningAttemptLimit | 0,
            managerIdLot: ret.partner.managerIdLot === null || ret.partner.managerIdLot === undefined ? null : ret.partner.managerIdLot,
            allowPartnerLot: !!ret.partner.allowPartnerLot,
            allowReduceJourneyWithoutLot: !!ret.partner.allowReduceJourneyWithoutLot,
            showWiz: ret.partner.showWiz === null || ret.partner.showWiz === undefined ? null : !!ret.partner.showWiz,
        },
    };
}

export async function validateTokenOfPassword(tokenOnLink: string): Promise<boolean> {
  const args = {
        tokenOnLink: tokenOnLink,
  };
  const ret = await makeRequest({name: "validateTokenOfPassword", args});
    return !!ret;
}

export async function removeToken(password4: string, cpf: string, birthDate: Date, cep: string, location: LatLng | null, signature: Signature): Promise<void> {
  const args = {
        password4: password4,
        cpf: cpf,
        birthDate: birthDate.toISOString().split("T")[0],
        cep: cep,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        signature: {
            signature: signature.signature,
            jsonObject: signature.jsonObject,
        },
  };
  await makeRequest({name: "removeToken", args});
    return undefined;
}

export async function getUser(): Promise<User> {
  const ret = await makeRequest({name: "getUser", args: {}});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        cpf: ret.cpf,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
        profilePhoto: ret.profilePhoto === null || ret.profilePhoto === undefined ? null : ret.profilePhoto,
        status: ret.status,
        publicKey: ret.publicKey === null || ret.publicKey === undefined ? null : ret.publicKey,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        companies: ret.companies.map((e: any) => ({
            accounts: e.accounts.map((e: any) => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: new Date(e.createdAt + "Z"),
            })),
            id: e.id,
            name: e.name,
            documentNumber: e.documentNumber,
            email: e.email,
            phone: e.phone,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            status: e.status,
            isMEI: !!e.isMEI,
            fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
            foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : new Date(parseInt(e.foundationDate.split("-")[0], 10), parseInt(e.foundationDate.split("-")[1], 10) - 1, parseInt(e.foundationDate.split("-")[2], 10)),
            foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
            socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
            mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
            },
        })),
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        socialName: ret.socialName === null || ret.socialName === undefined ? null : ret.socialName,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        isMEI: !!ret.isMEI,
        showCard: ret.showCard === null || ret.showCard === undefined ? null : !!ret.showCard,
        help: ret.help === null || ret.help === undefined ? null : ret.help,
        mobileHelp: ret.mobileHelp === null || ret.mobileHelp === undefined ? null : ret.mobileHelp,
        pendingDocument: ret.pendingDocument === null || ret.pendingDocument === undefined ? null : !!ret.pendingDocument,
    };
}

export async function getUserV7(): Promise<User> {
  const ret = await makeRequest({name: "getUserV7", args: {}});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        cpf: ret.cpf,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
        profilePhoto: ret.profilePhoto === null || ret.profilePhoto === undefined ? null : ret.profilePhoto,
        status: ret.status,
        publicKey: ret.publicKey === null || ret.publicKey === undefined ? null : ret.publicKey,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        companies: ret.companies.map((e: any) => ({
            accounts: e.accounts.map((e: any) => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: new Date(e.createdAt + "Z"),
            })),
            id: e.id,
            name: e.name,
            documentNumber: e.documentNumber,
            email: e.email,
            phone: e.phone,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            status: e.status,
            isMEI: !!e.isMEI,
            fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
            foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : new Date(parseInt(e.foundationDate.split("-")[0], 10), parseInt(e.foundationDate.split("-")[1], 10) - 1, parseInt(e.foundationDate.split("-")[2], 10)),
            foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
            socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
            mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
            },
        })),
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        socialName: ret.socialName === null || ret.socialName === undefined ? null : ret.socialName,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        isMEI: !!ret.isMEI,
        showCard: ret.showCard === null || ret.showCard === undefined ? null : !!ret.showCard,
        help: ret.help === null || ret.help === undefined ? null : ret.help,
        mobileHelp: ret.mobileHelp === null || ret.mobileHelp === undefined ? null : ret.mobileHelp,
        pendingDocument: ret.pendingDocument === null || ret.pendingDocument === undefined ? null : !!ret.pendingDocument,
    };
}

export async function getUserV13(): Promise<UserV13> {
  const ret = await makeRequest({name: "getUserV13", args: {}});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        cpf: ret.cpf,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
        profilePhoto: ret.profilePhoto === null || ret.profilePhoto === undefined ? null : ret.profilePhoto,
        status: ret.status,
        publicKey: ret.publicKey === null || ret.publicKey === undefined ? null : ret.publicKey,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        companies: ret.companies.map((e: any) => ({
            accounts: e.accounts.map((e: any) => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: new Date(e.createdAt + "Z"),
            })),
            id: e.id,
            name: e.name,
            documentNumber: e.documentNumber,
            email: e.email,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            status: e.status,
            isMEI: !!e.isMEI,
            fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
            foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : new Date(parseInt(e.foundationDate.split("-")[0], 10), parseInt(e.foundationDate.split("-")[1], 10) - 1, parseInt(e.foundationDate.split("-")[2], 10)),
            foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
            socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
            mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
            },
            isPPE: e.isPPE === null || e.isPPE === undefined ? null : !!e.isPPE,
            mailingAddress: e.mailingAddress === null || e.mailingAddress === undefined ? null : {
                cep: e.mailingAddress.cep === null || e.mailingAddress.cep === undefined ? null : e.mailingAddress.cep,
                street: e.mailingAddress.street === null || e.mailingAddress.street === undefined ? null : e.mailingAddress.street,
                neighborhood: e.mailingAddress.neighborhood === null || e.mailingAddress.neighborhood === undefined ? null : e.mailingAddress.neighborhood,
                number: e.mailingAddress.number === null || e.mailingAddress.number === undefined ? null : e.mailingAddress.number,
                complement: e.mailingAddress.complement === null || e.mailingAddress.complement === undefined ? null : e.mailingAddress.complement,
                city: e.mailingAddress.city === null || e.mailingAddress.city === undefined ? null : e.mailingAddress.city,
                state: e.mailingAddress.state === null || e.mailingAddress.state === undefined ? null : e.mailingAddress.state,
                country: e.mailingAddress.country === null || e.mailingAddress.country === undefined ? null : e.mailingAddress.country,
            },
            hasTaxDomain: e.hasTaxDomain === null || e.hasTaxDomain === undefined ? null : !!e.hasTaxDomain,
            taxDomainCountry: e.taxDomainCountry === null || e.taxDomainCountry === undefined ? null : e.taxDomainCountry,
            taxDomainNumber: e.taxDomainNumber === null || e.taxDomainNumber === undefined ? null : e.taxDomainNumber,
            isAmericanCitizen: e.isAmericanCitizen === null || e.isAmericanCitizen === undefined ? null : !!e.isAmericanCitizen,
            americanCitizenNIF: e.americanCitizenNIF === null || e.americanCitizenNIF === undefined ? null : e.americanCitizenNIF,
            isPPERelationship: e.isPPERelationship === null || e.isPPERelationship === undefined ? null : !!e.isPPERelationship,
            giinNumber: e.giinNumber === null || e.giinNumber === undefined ? null : e.giinNumber,
            ppe: e.ppe === null || e.ppe === undefined ? null : e.ppe.map((e: any) => ({
                name: e.name,
                reason: e.reason,
            })),
            ppeRelationship: e.ppeRelationship === null || e.ppeRelationship === undefined ? null : e.ppeRelationship.map((e: any) => ({
                name: e.name,
                reason: e.reason,
            })),
        })),
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        socialName: ret.socialName === null || ret.socialName === undefined ? null : ret.socialName,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        isMEI: !!ret.isMEI,
        showCard: ret.showCard === null || ret.showCard === undefined ? null : !!ret.showCard,
        help: ret.help === null || ret.help === undefined ? null : ret.help,
        mobileHelp: ret.mobileHelp === null || ret.mobileHelp === undefined ? null : ret.mobileHelp,
        pendingDocument: ret.pendingDocument === null || ret.pendingDocument === undefined ? null : !!ret.pendingDocument,
        isMaster: ret.isMaster === null || ret.isMaster === undefined ? null : !!ret.isMaster,
    };
}

export async function getPartner(partnerId: string | null): Promise<Partner> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getPartner", args});
    return {
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        blocked: !!ret.blocked,
        managerId: ret.managerId,
        publicKey: ret.publicKey,
        partnerSecret: ret.partnerSecret,
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        id: ret.id,
        name: ret.name,
        logo: ret.logo,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlIB: ret.urlIB,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        cobrander: ret.cobrander === null || ret.cobrander === undefined ? null : {
            codeCobrander: ret.cobrander.codeCobrander,
            codeCardCategory: ret.cobrander.codeCardCategory,
            autoEmitCard: !!ret.cobrander.autoEmitCard,
            isActivate: !!ret.cobrander.isActivate,
            username: ret.cobrander.username === null || ret.cobrander.username === undefined ? null : ret.cobrander.username,
            password: ret.cobrander.password === null || ret.cobrander.password === undefined ? null : ret.cobrander.password,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        allowPartnerLot: !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!ret.allowReduceJourneyWithoutLot,
        showWiz: ret.showWiz === null || ret.showWiz === undefined ? null : !!ret.showWiz,
    };
}

export async function getCustomButton(partnerId: string): Promise<CustomButton | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getCustomButton", args});
    return ret === null || ret === undefined ? null : {
        buffer: ret.buffer === null || ret.buffer === undefined ? null : Buffer.from(ret.buffer, "base64"),
        image: ret.image === null || ret.image === undefined ? null : ret.image,
        url: ret.url === null || ret.url === undefined ? null : ret.url,
        text: ret.text === null || ret.text === undefined ? null : ret.text,
        show: ret.show === null || ret.show === undefined ? null : !!ret.show,
    };
}

export async function getCustomContact(partnerId: string): Promise<CustomContact | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getCustomContact", args});
    return ret === null || ret === undefined ? null : {
        show: !!ret.show,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
    };
}

export async function getPartnerV13(partnerId: string | null): Promise<PartnerV13> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getPartnerV13", args});
    return {
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        blocked: !!ret.blocked,
        managerId: ret.managerId,
        publicKey: ret.publicKey,
        partnerSecret: ret.partnerSecret,
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        id: ret.id,
        name: ret.name,
        logo: ret.logo,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlIB: ret.urlIB,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        cobrander: ret.cobrander === null || ret.cobrander === undefined ? null : {
            codeCobrander: ret.cobrander.codeCobrander,
            codeCardCategory: ret.cobrander.codeCardCategory,
            autoEmitCard: !!ret.cobrander.autoEmitCard,
            isActivate: !!ret.cobrander.isActivate,
            username: ret.cobrander.username === null || ret.cobrander.username === undefined ? null : ret.cobrander.username,
            password: ret.cobrander.password === null || ret.cobrander.password === undefined ? null : ret.cobrander.password,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
    };
}

export async function getProfiles(cnpj: string): Promise<Profile[]> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "getProfiles", args});
    return ret.map((e: any) => ({
        id: e.id === null || e.id === undefined ? null : e.id,
        name: e.name,
        companyId: e.companyId,
        permissions: e.permissions.map((e: any) => ({
            permissionType: e.permissionType,
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
            isSpecial: !!e.isSpecial,
        })),
    }));
}

export async function getUsersByCompany(cnpj: string): Promise<UserCompany[]> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "getUsersByCompany", args});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        cpf: e.cpf,
        blocked: e.blocked === null || e.blocked === undefined ? null : !!e.blocked,
        accounts: e.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    }));
}

export async function renewSession(): Promise<number> {
  const ret = await makeRequest({name: "renewSession", args: {}});
    return ret;
}

export async function verifyUser(cpf: string, cnpj: string): Promise<UserCompany | null> {
  const args = {
        cpf: cpf,
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "verifyUser", args});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        accounts: ret.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    };
}

export async function setPermission(userId: string, branch: string, account: string, maxAmountDaily: number, profile: Profile, saveProfile: boolean, location: LatLng | null): Promise<UserCompany> {
  const args = {
        userId: userId,
        branch: branch,
        account: account,
        maxAmountDaily: maxAmountDaily,
        profile: {
            id: profile.id === null || profile.id === undefined ? null : profile.id,
            name: profile.name,
            companyId: profile.companyId,
            permissions: profile.permissions.map(e => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
        },
        saveProfile: !!saveProfile,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "setPermission", args});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        accounts: ret.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    };
}

export async function createUserInCompany(name: string, email: string, phone: string, cpf: string, cnpj: string): Promise<UserCompany> {
  const args = {
        name: name,
        email: email,
        phone: phone,
        cpf: cpf,
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "createUserInCompany", args});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        accounts: ret.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    };
}

export async function removeUserInCompany(cpf: string, cnpj: string, removeInAll: boolean): Promise<boolean> {
  const args = {
        cpf: cpf,
        cnpj: cnpj,
        removeInAll: !!removeInAll,
  };
  const ret = await makeRequest({name: "removeUserInCompany", args});
    return !!ret;
}

export async function createProfile(profile: Profile, location: LatLng | null): Promise<Profile> {
  const args = {
        profile: {
            id: profile.id === null || profile.id === undefined ? null : profile.id,
            name: profile.name,
            companyId: profile.companyId,
            permissions: profile.permissions.map(e => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "createProfile", args});
    return {
        id: ret.id === null || ret.id === undefined ? null : ret.id,
        name: ret.name,
        companyId: ret.companyId,
        permissions: ret.permissions.map((e: any) => ({
            permissionType: e.permissionType,
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
            isSpecial: !!e.isSpecial,
        })),
    };
}

export async function filterAccounts(query: string, page: number, limit: number): Promise<Account[]> {
  const args = {
        query: query,
        page: page | 0,
        limit: limit | 0,
  };
  const ret = await makeRequest({name: "filterAccounts", args});
    return ret.map((e: any) => ({
        name: e.name,
        type: e.type,
        documentNumber: e.documentNumber,
        permissions: e.permissions.map((e: any) => ({
            permissionType: e.permissionType,
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
            isSpecial: !!e.isSpecial,
        })),
        maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
        yourNumber: e.yourNumber | 0,
        subType: e.subType === null || e.subType === undefined ? null : e.subType,
        bank: {
            code: e.bank.code,
            name: e.bank.name,
        },
        branch: e.branch,
        account: e.account,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function sendSMS(phone: string): Promise<void> {
  const args = {
        phone: phone,
  };
  await makeRequest({name: "sendSMS", args});
    return undefined;
}

export async function sendLinkPendingRegister(cpfCnpj: string, fields: RegisterField[], obs: string): Promise<boolean> {
  const args = {
        cpfCnpj: cpfCnpj,
        fields: fields.map(e => e),
        obs: obs,
  };
  const ret = await makeRequest({name: "sendLinkPendingRegister", args});
    return !!ret;
}

export async function sendLinkPendingRegisterMEI(id: string, fields: RegisterField[], obs: string): Promise<boolean> {
  const args = {
        id: id,
        fields: fields.map(e => e),
        obs: obs,
  };
  const ret = await makeRequest({name: "sendLinkPendingRegisterMEI", args});
    return !!ret;
}

export async function sendMailPendingRegister(userId: string): Promise<void> {
  const args = {
        userId: userId,
  };
  await makeRequest({name: "sendMailPendingRegister", args});
    return undefined;
}

export async function getPendingFieldsDataByTokenV10(accessToken: string): Promise<PendingRegisterResponseV10> {
  const args = {
        accessToken: accessToken,
  };
  const ret = await makeRequest({name: "getPendingFieldsDataByTokenV10", args});
    return {
        fields: {
            cpf: ret.fields.cpf,
            birthday: new Date(parseInt(ret.fields.birthday.split("-")[0], 10), parseInt(ret.fields.birthday.split("-")[1], 10) - 1, parseInt(ret.fields.birthday.split("-")[2], 10)),
            name: ret.fields.name,
            email: ret.fields.email,
            cel: ret.fields.cel,
            acceptTermsOfUse: !!ret.fields.acceptTermsOfUse,
            acceptTermsOfService: !!ret.fields.acceptTermsOfService,
            socialName: ret.fields.socialName,
            gender: ret.fields.gender,
            affiliation1: ret.fields.affiliation1,
            affiliation2: ret.fields.affiliation2,
            nationality: ret.fields.nationality,
            zipCode: ret.fields.zipCode,
            street: ret.fields.street,
            numberAddress: ret.fields.numberAddress,
            additionalAddressData: ret.fields.additionalAddressData,
            neighborhood: ret.fields.neighborhood,
            city: ret.fields.city,
            uf: ret.fields.uf,
            patrimony: ret.fields.patrimony === null || ret.fields.patrimony === undefined ? null : ret.fields.patrimony,
            monthlyIncome: ret.fields.monthlyIncome === null || ret.fields.monthlyIncome === undefined ? null : ret.fields.monthlyIncome,
            proofOfAddress: ret.fields.proofOfAddress === null || ret.fields.proofOfAddress === undefined ? null : ret.fields.proofOfAddress,
            documentType: ret.fields.documentType,
            documentImageFront: ret.fields.documentImageFront === null || ret.fields.documentImageFront === undefined ? null : ret.fields.documentImageFront,
            documentImageBack: ret.fields.documentImageBack === null || ret.fields.documentImageBack === undefined ? null : ret.fields.documentImageBack,
            selfieImage: ret.fields.selfieImage,
            confirmTermsOfService: !!ret.fields.confirmTermsOfService,
            ppeData: ret.fields.ppeData === null || ret.fields.ppeData === undefined ? null : {
                isBrazilian: !!ret.fields.ppeData.isBrazilian,
                hasTaxRegion: !!ret.fields.ppeData.hasTaxRegion,
                isCRS: !!ret.fields.ppeData.isCRS,
                isFATCA: !!ret.fields.ppeData.isFATCA,
                isPPE: !!ret.fields.ppeData.isPPE,
                isPublicOfficer: !!ret.fields.ppeData.isPublicOfficer,
                isResponsible: !!ret.fields.ppeData.isResponsible,
                anotherCitizenship: !!ret.fields.ppeData.anotherCitizenship,
                isFATCADeclaration: !!ret.fields.ppeData.isFATCADeclaration,
                isCRSDeclaration: !!ret.fields.ppeData.isCRSDeclaration,
                hasCriminalProceedings: !!ret.fields.ppeData.hasCriminalProceedings,
                isPublicOfficerDeclaration: !!ret.fields.ppeData.isPublicOfficerDeclaration,
                isPPEDeclaration: ret.fields.ppeData.isPPEDeclaration === null || ret.fields.ppeData.isPPEDeclaration === undefined ? null : ret.fields.ppeData.isPPEDeclaration,
                country: ret.fields.ppeData.country === null || ret.fields.ppeData.country === undefined ? null : ret.fields.ppeData.country,
                documentNumber: ret.fields.ppeData.documentNumber === null || ret.fields.ppeData.documentNumber === undefined ? null : ret.fields.ppeData.documentNumber,
                addressCel: ret.fields.ppeData.addressCel === null || ret.fields.ppeData.addressCel === undefined ? null : ret.fields.ppeData.addressCel,
                resignation: ret.fields.ppeData.resignation === null || ret.fields.ppeData.resignation === undefined ? null : ret.fields.ppeData.resignation,
                nameJob: ret.fields.ppeData.nameJob === null || ret.fields.ppeData.nameJob === undefined ? null : ret.fields.ppeData.nameJob,
                startJob: ret.fields.ppeData.startJob === null || ret.fields.ppeData.startJob === undefined ? null : new Date(parseInt(ret.fields.ppeData.startJob.split("-")[0], 10), parseInt(ret.fields.ppeData.startJob.split("-")[1], 10) - 1, parseInt(ret.fields.ppeData.startJob.split("-")[2], 10)),
                endJob: ret.fields.ppeData.endJob === null || ret.fields.ppeData.endJob === undefined ? null : new Date(parseInt(ret.fields.ppeData.endJob.split("-")[0], 10), parseInt(ret.fields.ppeData.endJob.split("-")[1], 10) - 1, parseInt(ret.fields.ppeData.endJob.split("-")[2], 10)),
                politicallyExposedPersonName: ret.fields.ppeData.politicallyExposedPersonName === null || ret.fields.ppeData.politicallyExposedPersonName === undefined ? null : ret.fields.ppeData.politicallyExposedPersonName,
                relationshipNature: ret.fields.ppeData.relationshipNature === null || ret.fields.ppeData.relationshipNature === undefined ? null : ret.fields.ppeData.relationshipNature,
            },
            partnerId: ret.fields.partnerId,
        },
        revalidateFields: ret.revalidateFields.map((e: any) => e),
    };
}

export async function getPendingFieldsDataByTokenMEI(accessToken: string): Promise<PendingRegisterResponseMEI> {
  const args = {
        accessToken: accessToken,
  };
  const ret = await makeRequest({name: "getPendingFieldsDataByTokenMEI", args});
    return {
        fields: {
            cnpj: ret.fields.cnpj,
            foundationDate: new Date(parseInt(ret.fields.foundationDate.split("-")[0], 10), parseInt(ret.fields.foundationDate.split("-")[1], 10) - 1, parseInt(ret.fields.foundationDate.split("-")[2], 10)),
            companyName: ret.fields.companyName,
            fantasyName: ret.fields.fantasyName === null || ret.fields.fantasyName === undefined ? null : ret.fields.fantasyName,
            cpf: ret.fields.cpf,
            ownerBirthday: new Date(parseInt(ret.fields.ownerBirthday.split("-")[0], 10), parseInt(ret.fields.ownerBirthday.split("-")[1], 10) - 1, parseInt(ret.fields.ownerBirthday.split("-")[2], 10)),
            ownerName: ret.fields.ownerName,
            email: ret.fields.email,
            phone: ret.fields.phone,
            acceptTermsOfUse: !!ret.fields.acceptTermsOfUse,
            acceptTermsOfService: !!ret.fields.acceptTermsOfService,
            gender: ret.fields.gender,
            affiliation1: ret.fields.affiliation1,
            affiliation2: ret.fields.affiliation2 === null || ret.fields.affiliation2 === undefined ? null : ret.fields.affiliation2,
            nationality: ret.fields.nationality,
            zipCode: ret.fields.zipCode,
            street: ret.fields.street,
            numberAddress: ret.fields.numberAddress,
            additionalAddressData: ret.fields.additionalAddressData === null || ret.fields.additionalAddressData === undefined ? null : ret.fields.additionalAddressData,
            neighborhood: ret.fields.neighborhood,
            city: ret.fields.city,
            uf: ret.fields.uf,
            proofOfAddress: ret.fields.proofOfAddress === null || ret.fields.proofOfAddress === undefined ? null : ret.fields.proofOfAddress,
            certificateMEI: ret.fields.certificateMEI === null || ret.fields.certificateMEI === undefined ? null : ret.fields.certificateMEI,
            documentType: ret.fields.documentType,
            documentImageFront: ret.fields.documentImageFront,
            documentImageBack: ret.fields.documentImageBack === null || ret.fields.documentImageBack === undefined ? null : ret.fields.documentImageBack,
            selfieImage: ret.fields.selfieImage,
            confirmTermsOfService: !!ret.fields.confirmTermsOfService,
            ppeData: ret.fields.ppeData === null || ret.fields.ppeData === undefined ? null : {
                isBrazilian: !!ret.fields.ppeData.isBrazilian,
                hasTaxRegion: !!ret.fields.ppeData.hasTaxRegion,
                isCRS: !!ret.fields.ppeData.isCRS,
                isFATCA: !!ret.fields.ppeData.isFATCA,
                isPPE: !!ret.fields.ppeData.isPPE,
                isPublicOfficer: !!ret.fields.ppeData.isPublicOfficer,
                isResponsible: !!ret.fields.ppeData.isResponsible,
                anotherCitizenship: !!ret.fields.ppeData.anotherCitizenship,
                isFATCADeclaration: !!ret.fields.ppeData.isFATCADeclaration,
                isCRSDeclaration: !!ret.fields.ppeData.isCRSDeclaration,
                hasCriminalProceedings: !!ret.fields.ppeData.hasCriminalProceedings,
                isPublicOfficerDeclaration: !!ret.fields.ppeData.isPublicOfficerDeclaration,
                isPPEDeclaration: ret.fields.ppeData.isPPEDeclaration === null || ret.fields.ppeData.isPPEDeclaration === undefined ? null : ret.fields.ppeData.isPPEDeclaration,
                country: ret.fields.ppeData.country === null || ret.fields.ppeData.country === undefined ? null : ret.fields.ppeData.country,
                documentNumber: ret.fields.ppeData.documentNumber === null || ret.fields.ppeData.documentNumber === undefined ? null : ret.fields.ppeData.documentNumber,
                addressCel: ret.fields.ppeData.addressCel === null || ret.fields.ppeData.addressCel === undefined ? null : ret.fields.ppeData.addressCel,
                resignation: ret.fields.ppeData.resignation === null || ret.fields.ppeData.resignation === undefined ? null : ret.fields.ppeData.resignation,
                nameJob: ret.fields.ppeData.nameJob === null || ret.fields.ppeData.nameJob === undefined ? null : ret.fields.ppeData.nameJob,
                startJob: ret.fields.ppeData.startJob === null || ret.fields.ppeData.startJob === undefined ? null : new Date(parseInt(ret.fields.ppeData.startJob.split("-")[0], 10), parseInt(ret.fields.ppeData.startJob.split("-")[1], 10) - 1, parseInt(ret.fields.ppeData.startJob.split("-")[2], 10)),
                endJob: ret.fields.ppeData.endJob === null || ret.fields.ppeData.endJob === undefined ? null : new Date(parseInt(ret.fields.ppeData.endJob.split("-")[0], 10), parseInt(ret.fields.ppeData.endJob.split("-")[1], 10) - 1, parseInt(ret.fields.ppeData.endJob.split("-")[2], 10)),
                politicallyExposedPersonName: ret.fields.ppeData.politicallyExposedPersonName === null || ret.fields.ppeData.politicallyExposedPersonName === undefined ? null : ret.fields.ppeData.politicallyExposedPersonName,
                relationshipNature: ret.fields.ppeData.relationshipNature === null || ret.fields.ppeData.relationshipNature === undefined ? null : ret.fields.ppeData.relationshipNature,
            },
            partnerId: ret.fields.partnerId,
        },
        revalidateFields: ret.revalidateFields.map((e: any) => e),
    };
}

export async function finishPendingAccountV10(accessToken: string, form: RegisterData, deviceInfo: DeviceInfo): Promise<void> {
  const args = {
        accessToken: accessToken,
        form: {
            cpf: form.cpf,
            birthday: form.birthday.toISOString().split("T")[0],
            name: form.name,
            email: form.email,
            cel: form.cel,
            acceptTermsOfUse: !!form.acceptTermsOfUse,
            acceptTermsOfService: !!form.acceptTermsOfService,
            socialName: form.socialName === null || form.socialName === undefined ? null : form.socialName,
            gender: form.gender,
            affiliation1: form.affiliation1,
            affiliation2: form.affiliation2 === null || form.affiliation2 === undefined ? null : form.affiliation2,
            nationality: form.nationality,
            zipCode: form.zipCode,
            street: form.street,
            numberAddress: form.numberAddress,
            additionalAddressData: form.additionalAddressData === null || form.additionalAddressData === undefined ? null : form.additionalAddressData,
            neighborhood: form.neighborhood,
            city: form.city,
            uf: form.uf,
            patrimony: form.patrimony === null || form.patrimony === undefined ? null : form.patrimony,
            monthlyIncome: form.monthlyIncome === null || form.monthlyIncome === undefined ? null : form.monthlyIncome,
            proofOfAddress: form.proofOfAddress === null || form.proofOfAddress === undefined ? null : form.proofOfAddress.toString("base64"),
            documentType: form.documentType,
            documentImageFront: form.documentImageFront === null || form.documentImageFront === undefined ? null : form.documentImageFront.toString("base64"),
            documentImageBack: form.documentImageBack === null || form.documentImageBack === undefined ? null : form.documentImageBack.toString("base64"),
            selfieImage: form.selfieImage === null || form.selfieImage === undefined ? null : form.selfieImage.toString("base64"),
            confirmTermsOfService: !!form.confirmTermsOfService,
            ppeData: form.ppeData === null || form.ppeData === undefined ? null : {
                isBrazilian: !!form.ppeData.isBrazilian,
                hasTaxRegion: !!form.ppeData.hasTaxRegion,
                isCRS: !!form.ppeData.isCRS,
                isFATCA: !!form.ppeData.isFATCA,
                isPPE: !!form.ppeData.isPPE,
                isPublicOfficer: !!form.ppeData.isPublicOfficer,
                isResponsible: !!form.ppeData.isResponsible,
                anotherCitizenship: !!form.ppeData.anotherCitizenship,
                isFATCADeclaration: !!form.ppeData.isFATCADeclaration,
                isCRSDeclaration: !!form.ppeData.isCRSDeclaration,
                hasCriminalProceedings: !!form.ppeData.hasCriminalProceedings,
                isPublicOfficerDeclaration: !!form.ppeData.isPublicOfficerDeclaration,
                isPPEDeclaration: form.ppeData.isPPEDeclaration === null || form.ppeData.isPPEDeclaration === undefined ? null : form.ppeData.isPPEDeclaration,
                country: form.ppeData.country === null || form.ppeData.country === undefined ? null : form.ppeData.country,
                documentNumber: form.ppeData.documentNumber === null || form.ppeData.documentNumber === undefined ? null : form.ppeData.documentNumber,
                addressCel: form.ppeData.addressCel === null || form.ppeData.addressCel === undefined ? null : form.ppeData.addressCel,
                resignation: form.ppeData.resignation === null || form.ppeData.resignation === undefined ? null : form.ppeData.resignation,
                nameJob: form.ppeData.nameJob === null || form.ppeData.nameJob === undefined ? null : form.ppeData.nameJob,
                startJob: form.ppeData.startJob === null || form.ppeData.startJob === undefined ? null : form.ppeData.startJob.toISOString().split("T")[0],
                endJob: form.ppeData.endJob === null || form.ppeData.endJob === undefined ? null : form.ppeData.endJob.toISOString().split("T")[0],
                politicallyExposedPersonName: form.ppeData.politicallyExposedPersonName === null || form.ppeData.politicallyExposedPersonName === undefined ? null : form.ppeData.politicallyExposedPersonName,
                relationshipNature: form.ppeData.relationshipNature === null || form.ppeData.relationshipNature === undefined ? null : form.ppeData.relationshipNature,
            },
            partnerId: form.partnerId,
            createdByApi: form.createdByApi === null || form.createdByApi === undefined ? null : !!form.createdByApi,
        },
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
  };
  await makeRequest({name: "finishPendingAccountV10", args});
    return undefined;
}

export async function finishPendingAccountMEI(accessToken: string, fields: RegisterDataMEI, deviceInfo: DeviceInfo): Promise<boolean> {
  const args = {
        accessToken: accessToken,
        fields: {
            cpf: fields.cpf,
            birthday: fields.birthday.toISOString().split("T")[0],
            name: fields.name,
            email: fields.email,
            phone: fields.phone,
            acceptTermsOfUse: !!fields.acceptTermsOfUse,
            acceptTermsOfService: !!fields.acceptTermsOfService,
            gender: fields.gender,
            affiliation1: fields.affiliation1,
            affiliation2: fields.affiliation2 === null || fields.affiliation2 === undefined ? null : fields.affiliation2,
            nationality: fields.nationality,
            documentType: fields.documentType,
            documentImageFront: fields.documentImageFront === null || fields.documentImageFront === undefined ? null : fields.documentImageFront.toString("base64"),
            documentImageBack: fields.documentImageBack === null || fields.documentImageBack === undefined ? null : fields.documentImageBack.toString("base64"),
            selfieImage: fields.selfieImage === null || fields.selfieImage === undefined ? null : fields.selfieImage.toString("base64"),
            confirmTermsOfService: !!fields.confirmTermsOfService,
            ppeData: fields.ppeData === null || fields.ppeData === undefined ? null : {
                isBrazilian: !!fields.ppeData.isBrazilian,
                hasTaxRegion: !!fields.ppeData.hasTaxRegion,
                isCRS: !!fields.ppeData.isCRS,
                isFATCA: !!fields.ppeData.isFATCA,
                isPPE: !!fields.ppeData.isPPE,
                isPublicOfficer: !!fields.ppeData.isPublicOfficer,
                isResponsible: !!fields.ppeData.isResponsible,
                anotherCitizenship: !!fields.ppeData.anotherCitizenship,
                isFATCADeclaration: !!fields.ppeData.isFATCADeclaration,
                isCRSDeclaration: !!fields.ppeData.isCRSDeclaration,
                hasCriminalProceedings: !!fields.ppeData.hasCriminalProceedings,
                isPublicOfficerDeclaration: !!fields.ppeData.isPublicOfficerDeclaration,
                isPPEDeclaration: fields.ppeData.isPPEDeclaration === null || fields.ppeData.isPPEDeclaration === undefined ? null : fields.ppeData.isPPEDeclaration,
                country: fields.ppeData.country === null || fields.ppeData.country === undefined ? null : fields.ppeData.country,
                documentNumber: fields.ppeData.documentNumber === null || fields.ppeData.documentNumber === undefined ? null : fields.ppeData.documentNumber,
                addressCel: fields.ppeData.addressCel === null || fields.ppeData.addressCel === undefined ? null : fields.ppeData.addressCel,
                resignation: fields.ppeData.resignation === null || fields.ppeData.resignation === undefined ? null : fields.ppeData.resignation,
                nameJob: fields.ppeData.nameJob === null || fields.ppeData.nameJob === undefined ? null : fields.ppeData.nameJob,
                startJob: fields.ppeData.startJob === null || fields.ppeData.startJob === undefined ? null : fields.ppeData.startJob.toISOString().split("T")[0],
                endJob: fields.ppeData.endJob === null || fields.ppeData.endJob === undefined ? null : fields.ppeData.endJob.toISOString().split("T")[0],
                politicallyExposedPersonName: fields.ppeData.politicallyExposedPersonName === null || fields.ppeData.politicallyExposedPersonName === undefined ? null : fields.ppeData.politicallyExposedPersonName,
                relationshipNature: fields.ppeData.relationshipNature === null || fields.ppeData.relationshipNature === undefined ? null : fields.ppeData.relationshipNature,
            },
            partnerId: fields.partnerId,
            companyMEI: {
                cnpj: fields.companyMEI.cnpj,
                foundationDate: fields.companyMEI.foundationDate.toISOString().split("T")[0],
                companyName: fields.companyMEI.companyName,
                fantasyName: fields.companyMEI.fantasyName === null || fields.companyMEI.fantasyName === undefined ? null : fields.companyMEI.fantasyName,
                proofOfAddress: fields.companyMEI.proofOfAddress === null || fields.companyMEI.proofOfAddress === undefined ? null : fields.companyMEI.proofOfAddress.toString("base64"),
                certificateMEI: fields.companyMEI.certificateMEI === null || fields.companyMEI.certificateMEI === undefined ? null : fields.companyMEI.certificateMEI.toString("base64"),
                zipCode: fields.companyMEI.zipCode,
                street: fields.companyMEI.street,
                numberAddress: fields.companyMEI.numberAddress,
                additionalAddressData: fields.companyMEI.additionalAddressData === null || fields.companyMEI.additionalAddressData === undefined ? null : fields.companyMEI.additionalAddressData,
                neighborhood: fields.companyMEI.neighborhood,
                city: fields.companyMEI.city,
                uf: fields.companyMEI.uf,
            },
        },
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
  };
  const ret = await makeRequest({name: "finishPendingAccountMEI", args});
    return !!ret;
}

export async function finishPendingAddressInfo(data: PendingAddressData): Promise<boolean> {
  const args = {
        data: {
            proofOfAddress: data.proofOfAddress.toString("base64"),
            address: data.address === null || data.address === undefined ? null : {
                cep: data.address.cep,
                street: data.address.street,
                neighborhood: data.address.neighborhood,
                number: data.address.number,
                complement: data.address.complement === null || data.address.complement === undefined ? null : data.address.complement,
                city: data.address.city,
                state: data.address.state,
                country: data.address.country === null || data.address.country === undefined ? null : data.address.country,
            },
        },
  };
  const ret = await makeRequest({name: "finishPendingAddressInfo", args});
    return !!ret;
}

export async function revalidateAddressPendingByToken(tokenOnLink: string, data: PendingAddressData): Promise<boolean> {
  const args = {
        tokenOnLink: tokenOnLink,
        data: {
            proofOfAddress: data.proofOfAddress.toString("base64"),
            address: data.address === null || data.address === undefined ? null : {
                cep: data.address.cep,
                street: data.address.street,
                neighborhood: data.address.neighborhood,
                number: data.address.number,
                complement: data.address.complement === null || data.address.complement === undefined ? null : data.address.complement,
                city: data.address.city,
                state: data.address.state,
                country: data.address.country === null || data.address.country === undefined ? null : data.address.country,
            },
        },
  };
  const ret = await makeRequest({name: "revalidateAddressPendingByToken", args});
    return !!ret;
}

export async function getAddressUserByToken(tokenOnLink: string): Promise<AddressInfo> {
  const args = {
        tokenOnLink: tokenOnLink,
  };
  const ret = await makeRequest({name: "getAddressUserByToken", args});
    return {
        cep: ret.cep,
        street: ret.street,
        neighborhood: ret.neighborhood,
        number: ret.number,
        complement: ret.complement === null || ret.complement === undefined ? null : ret.complement,
        city: ret.city,
        state: ret.state,
        country: ret.country,
    };
}

export async function getPartnerRegisterData(partnerId: string): Promise<PartnerRegisterData | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerRegisterData", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        name: ret.name,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        managerId: ret.managerId,
        urlIB: ret.urlIB,
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        publicKey: ret.publicKey,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        blocked: !!ret.blocked,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        partnerSecret: ret.partnerSecret,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        term: ret.term === null || ret.term === undefined ? null : Buffer.from(ret.term, "base64"),
        contract: ret.contract === null || ret.contract === undefined ? null : Buffer.from(ret.contract, "base64"),
        termPj: ret.termPj === null || ret.termPj === undefined ? null : Buffer.from(ret.termPj, "base64"),
        contractPj: ret.contractPj === null || ret.contractPj === undefined ? null : Buffer.from(ret.contractPj, "base64"),
        farePackages: ret.farePackages === null || ret.farePackages === undefined ? null : Buffer.from(ret.farePackages, "base64"),
    };
}

export async function getPartnerStyles(partnerId: string): Promise<PartnerStyles | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerStyles", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        logo: ret.logo,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        buffer: ret.buffer === null || ret.buffer === undefined ? null : Buffer.from(ret.buffer, "base64"),
    };
}

export async function getPartnerCards(partnerId: string): Promise<PartnerCard | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerCards", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        cardConfig: ret.cardConfig.map((e: any) => ({
            broadcaster: e.broadcaster,
            brand: e.brand,
            productId: e.productId,
            plasticId: e.plasticId,
            activateCards: {
                physical: !!e.activateCards.physical,
                virtual: !!e.activateCards.virtual,
            },
            commercialOrigin: e.commercialOrigin,
            cardTypeName: {
                name: !!e.cardTypeName.name,
                noname: !!e.cardTypeName.noname,
            },
            numberBin: e.numberBin,
            typeBin: e.typeBin,
            cardImage: e.cardImage.map((e: any) => ({
                id: e.id,
                descr: e.descr,
            })),
            multiCards: e.multiCards,
        })),
    };
}

export async function getPartnerGeneralConfig(partnerId: string): Promise<PartnerGeneralConfig | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerGeneralConfig", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        blocked: !!ret.blocked,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        showCard: !!ret.showCard,
        allowPartnerLot: ret.allowPartnerLot === null || ret.allowPartnerLot === undefined ? null : !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: ret.allowReduceJourneyWithoutLot === null || ret.allowReduceJourneyWithoutLot === undefined ? null : !!ret.allowReduceJourneyWithoutLot,
        showWiz: !!ret.showWiz,
        customButton: ret.customButton === null || ret.customButton === undefined ? null : {
            buffer: ret.customButton.buffer === null || ret.customButton.buffer === undefined ? null : Buffer.from(ret.customButton.buffer, "base64"),
            image: ret.customButton.image === null || ret.customButton.image === undefined ? null : ret.customButton.image,
            url: ret.customButton.url === null || ret.customButton.url === undefined ? null : ret.customButton.url,
            text: ret.customButton.text === null || ret.customButton.text === undefined ? null : ret.customButton.text,
            show: ret.customButton.show === null || ret.customButton.show === undefined ? null : !!ret.customButton.show,
        },
        customContact: ret.customContact === null || ret.customContact === undefined ? null : {
            show: !!ret.customContact.show,
            phone: ret.customContact.phone === null || ret.customContact.phone === undefined ? null : ret.customContact.phone,
            email: ret.customContact.email === null || ret.customContact.email === undefined ? null : ret.customContact.email,
        },
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        managerIdWithoutLot: ret.managerIdWithoutLot === null || ret.managerIdWithoutLot === undefined ? null : ret.managerIdWithoutLot,
    };
}

export async function setPartnerRegisterData(partnerRegisterData: PartnerRegisterData): Promise<void> {
  const args = {
        partnerRegisterData: {
            partnerId: partnerRegisterData.partnerId,
            name: partnerRegisterData.name,
            cnpj: partnerRegisterData.cnpj === null || partnerRegisterData.cnpj === undefined ? null : partnerRegisterData.cnpj,
            managerId: partnerRegisterData.managerId,
            urlIB: partnerRegisterData.urlIB,
            urlApp: partnerRegisterData.urlApp === null || partnerRegisterData.urlApp === undefined ? null : {
                android: partnerRegisterData.urlApp.android,
                ios: partnerRegisterData.urlApp.ios,
            },
            tokenUrlApp: partnerRegisterData.tokenUrlApp === null || partnerRegisterData.tokenUrlApp === undefined ? null : {
                android: partnerRegisterData.tokenUrlApp.android,
                ios: partnerRegisterData.tokenUrlApp.ios,
            },
            hibotlink: partnerRegisterData.hibotlink === null || partnerRegisterData.hibotlink === undefined ? null : partnerRegisterData.hibotlink,
            publicKey: partnerRegisterData.publicKey,
            emailFrom: partnerRegisterData.emailFrom === null || partnerRegisterData.emailFrom === undefined ? null : partnerRegisterData.emailFrom,
            urlFarePackages: partnerRegisterData.urlFarePackages === null || partnerRegisterData.urlFarePackages === undefined ? null : partnerRegisterData.urlFarePackages,
            urlTerm: partnerRegisterData.urlTerm === null || partnerRegisterData.urlTerm === undefined ? null : partnerRegisterData.urlTerm,
            urlContract: partnerRegisterData.urlContract === null || partnerRegisterData.urlContract === undefined ? null : partnerRegisterData.urlContract,
            urlTermPj: partnerRegisterData.urlTermPj === null || partnerRegisterData.urlTermPj === undefined ? null : partnerRegisterData.urlTermPj,
            urlContractPj: partnerRegisterData.urlContractPj === null || partnerRegisterData.urlContractPj === undefined ? null : partnerRegisterData.urlContractPj,
            blocked: !!partnerRegisterData.blocked,
            hibotmobilelink: partnerRegisterData.hibotmobilelink === null || partnerRegisterData.hibotmobilelink === undefined ? null : partnerRegisterData.hibotmobilelink,
            partnerSecret: partnerRegisterData.partnerSecret,
            phone: partnerRegisterData.phone === null || partnerRegisterData.phone === undefined ? null : partnerRegisterData.phone,
            email: partnerRegisterData.email === null || partnerRegisterData.email === undefined ? null : partnerRegisterData.email,
            site: partnerRegisterData.site === null || partnerRegisterData.site === undefined ? null : partnerRegisterData.site,
            partnerHook: partnerRegisterData.partnerHook === null || partnerRegisterData.partnerHook === undefined ? null : {
                url: partnerRegisterData.partnerHook.url,
                token: partnerRegisterData.partnerHook.token,
            },
            partnerUserName: partnerRegisterData.partnerUserName === null || partnerRegisterData.partnerUserName === undefined ? null : partnerRegisterData.partnerUserName,
            webhookUrl: partnerRegisterData.webhookUrl === null || partnerRegisterData.webhookUrl === undefined ? null : partnerRegisterData.webhookUrl,
            term: partnerRegisterData.term === null || partnerRegisterData.term === undefined ? null : partnerRegisterData.term.toString("base64"),
            contract: partnerRegisterData.contract === null || partnerRegisterData.contract === undefined ? null : partnerRegisterData.contract.toString("base64"),
            termPj: partnerRegisterData.termPj === null || partnerRegisterData.termPj === undefined ? null : partnerRegisterData.termPj.toString("base64"),
            contractPj: partnerRegisterData.contractPj === null || partnerRegisterData.contractPj === undefined ? null : partnerRegisterData.contractPj.toString("base64"),
            farePackages: partnerRegisterData.farePackages === null || partnerRegisterData.farePackages === undefined ? null : partnerRegisterData.farePackages.toString("base64"),
        },
  };
  await makeRequest({name: "setPartnerRegisterData", args});
    return undefined;
}

export async function setPartnerStyles(partnerStyles: PartnerStyles): Promise<void> {
  const args = {
        partnerStyles: {
            partnerId: partnerStyles.partnerId,
            primaryColor: partnerStyles.primaryColor === null || partnerStyles.primaryColor === undefined ? null : partnerStyles.primaryColor,
            secondaryColor: partnerStyles.secondaryColor === null || partnerStyles.secondaryColor === undefined ? null : partnerStyles.secondaryColor,
            backgroundColor: partnerStyles.backgroundColor === null || partnerStyles.backgroundColor === undefined ? null : partnerStyles.backgroundColor,
            errorColor: partnerStyles.errorColor === null || partnerStyles.errorColor === undefined ? null : partnerStyles.errorColor,
            logo: partnerStyles.logo,
            font: partnerStyles.font === null || partnerStyles.font === undefined ? null : partnerStyles.font,
            fontHref: partnerStyles.fontHref === null || partnerStyles.fontHref === undefined ? null : partnerStyles.fontHref,
            buffer: partnerStyles.buffer === null || partnerStyles.buffer === undefined ? null : partnerStyles.buffer.toString("base64"),
        },
  };
  await makeRequest({name: "setPartnerStyles", args});
    return undefined;
}

export async function setPartnerCards(partnerCard: PartnerCard): Promise<void> {
  const args = {
        partnerCard: {
            partnerId: partnerCard.partnerId,
            cardConfig: partnerCard.cardConfig.map(e => ({
                broadcaster: e.broadcaster,
                brand: e.brand,
                productId: e.productId,
                plasticId: e.plasticId,
                activateCards: {
                    physical: !!e.activateCards.physical,
                    virtual: !!e.activateCards.virtual,
                },
                commercialOrigin: e.commercialOrigin,
                cardTypeName: {
                    name: !!e.cardTypeName.name,
                    noname: !!e.cardTypeName.noname,
                },
                numberBin: e.numberBin,
                typeBin: e.typeBin,
                cardImage: e.cardImage.map(e => ({
                    id: e.id,
                    descr: e.descr,
                })),
                multiCards: e.multiCards,
            })),
        },
  };
  await makeRequest({name: "setPartnerCards", args});
    return undefined;
}

export async function setPartnerGeneralConfig(partnerGeneralConfig: PartnerGeneralConfig): Promise<void> {
  const args = {
        partnerGeneralConfig: {
            partnerId: partnerGeneralConfig.partnerId,
            allowAnyPartnerLogin: !!partnerGeneralConfig.allowAnyPartnerLogin,
            blocked: !!partnerGeneralConfig.blocked,
            emailResendTime: partnerGeneralConfig.emailResendTime | 0,
            emailResendTimeFrequency: partnerGeneralConfig.emailResendTimeFrequency | 0,
            accountOpeningAttemptLimit: partnerGeneralConfig.accountOpeningAttemptLimit === null || partnerGeneralConfig.accountOpeningAttemptLimit === undefined ? null : partnerGeneralConfig.accountOpeningAttemptLimit | 0,
            openAccountsAttempts: partnerGeneralConfig.openAccountsAttempts | 0,
            showCard: !!partnerGeneralConfig.showCard,
            allowPartnerLot: partnerGeneralConfig.allowPartnerLot === null || partnerGeneralConfig.allowPartnerLot === undefined ? null : !!partnerGeneralConfig.allowPartnerLot,
            allowReduceJourneyWithoutLot: partnerGeneralConfig.allowReduceJourneyWithoutLot === null || partnerGeneralConfig.allowReduceJourneyWithoutLot === undefined ? null : !!partnerGeneralConfig.allowReduceJourneyWithoutLot,
            showWiz: !!partnerGeneralConfig.showWiz,
            customButton: partnerGeneralConfig.customButton === null || partnerGeneralConfig.customButton === undefined ? null : {
                buffer: partnerGeneralConfig.customButton.buffer === null || partnerGeneralConfig.customButton.buffer === undefined ? null : partnerGeneralConfig.customButton.buffer.toString("base64"),
                image: partnerGeneralConfig.customButton.image === null || partnerGeneralConfig.customButton.image === undefined ? null : partnerGeneralConfig.customButton.image,
                url: partnerGeneralConfig.customButton.url === null || partnerGeneralConfig.customButton.url === undefined ? null : partnerGeneralConfig.customButton.url,
                text: partnerGeneralConfig.customButton.text === null || partnerGeneralConfig.customButton.text === undefined ? null : partnerGeneralConfig.customButton.text,
                show: partnerGeneralConfig.customButton.show === null || partnerGeneralConfig.customButton.show === undefined ? null : !!partnerGeneralConfig.customButton.show,
            },
            customContact: partnerGeneralConfig.customContact === null || partnerGeneralConfig.customContact === undefined ? null : {
                show: !!partnerGeneralConfig.customContact.show,
                phone: partnerGeneralConfig.customContact.phone === null || partnerGeneralConfig.customContact.phone === undefined ? null : partnerGeneralConfig.customContact.phone,
                email: partnerGeneralConfig.customContact.email === null || partnerGeneralConfig.customContact.email === undefined ? null : partnerGeneralConfig.customContact.email,
            },
            managerIdLot: partnerGeneralConfig.managerIdLot === null || partnerGeneralConfig.managerIdLot === undefined ? null : partnerGeneralConfig.managerIdLot,
            managerIdWithoutLot: partnerGeneralConfig.managerIdWithoutLot === null || partnerGeneralConfig.managerIdWithoutLot === undefined ? null : partnerGeneralConfig.managerIdWithoutLot,
        },
  };
  await makeRequest({name: "setPartnerGeneralConfig", args});
    return undefined;
}

export async function updatePartnerCard(updatePartnerCard: UpdatePartnerCard): Promise<void> {
  const args = {
        updatePartnerCard: {
            partnerId: updatePartnerCard.partnerId,
            productId: updatePartnerCard.productId,
            imageId: updatePartnerCard.imageId,
            imageDescr: updatePartnerCard.imageDescr,
            multiCard: updatePartnerCard.multiCard,
            bin: updatePartnerCard.bin,
            typeBin: updatePartnerCard.typeBin,
            cardTypeName: {
                name: !!updatePartnerCard.cardTypeName.name,
                noname: !!updatePartnerCard.cardTypeName.noname,
            },
            activateCards: {
                physical: !!updatePartnerCard.activateCards.physical,
                virtual: !!updatePartnerCard.activateCards.virtual,
            },
            plasticId: updatePartnerCard.plasticId,
            commercialOrigin: updatePartnerCard.commercialOrigin,
        },
  };
  await makeRequest({name: "updatePartnerCard", args});
    return undefined;
}

export async function removePartnerCard(removePartnerCard: RemovePartnerCard): Promise<void> {
  const args = {
        removePartnerCard: {
            partnerId: removePartnerCard.partnerId,
            productId: removePartnerCard.productId,
            imageId: removePartnerCard.imageId,
        },
  };
  await makeRequest({name: "removePartnerCard", args});
    return undefined;
}

export async function getHibotLink(partnerId: string): Promise<string | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getHibotLink", args});
    return ret === null || ret === undefined ? null : ret;
}

export async function getHibotMobileLink(partnerId: string): Promise<string | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getHibotMobileLink", args});
    return ret === null || ret === undefined ? null : ret;
}

export async function checkUserBenefit(user: User, benefitId: string): Promise<void> {
  const args = {
        user: {
            id: user.id,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            address: user.address === null || user.address === undefined ? null : {
                cep: user.address.cep === null || user.address.cep === undefined ? null : user.address.cep,
                street: user.address.street === null || user.address.street === undefined ? null : user.address.street,
                neighborhood: user.address.neighborhood === null || user.address.neighborhood === undefined ? null : user.address.neighborhood,
                number: user.address.number === null || user.address.number === undefined ? null : user.address.number,
                complement: user.address.complement === null || user.address.complement === undefined ? null : user.address.complement,
                city: user.address.city === null || user.address.city === undefined ? null : user.address.city,
                state: user.address.state === null || user.address.state === undefined ? null : user.address.state,
                country: user.address.country === null || user.address.country === undefined ? null : user.address.country,
            },
            profilePhoto: user.profilePhoto === null || user.profilePhoto === undefined ? null : user.profilePhoto,
            status: user.status,
            publicKey: user.publicKey === null || user.publicKey === undefined ? null : user.publicKey,
            partnerId: user.partnerId === null || user.partnerId === undefined ? null : user.partnerId,
            accounts: user.accounts.map(e => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map(e => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: (typeof e.createdAt === "string" && (e.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.createdAt as any).replace("Z", "") : e.createdAt.toISOString().replace("Z", "")),
            })),
            companies: user.companies.map(e => ({
                accounts: e.accounts.map(e => ({
                    name: e.name,
                    type: e.type,
                    documentNumber: e.documentNumber,
                    permissions: e.permissions.map(e => ({
                        permissionType: e.permissionType,
                        maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                        maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                        isSpecial: !!e.isSpecial,
                    })),
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    yourNumber: e.yourNumber | 0,
                    subType: e.subType === null || e.subType === undefined ? null : e.subType,
                    bank: {
                        code: e.bank.code,
                        name: e.bank.name,
                    },
                    branch: e.branch,
                    account: e.account,
                    createdAt: (typeof e.createdAt === "string" && (e.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.createdAt as any).replace("Z", "") : e.createdAt.toISOString().replace("Z", "")),
                })),
                id: e.id,
                name: e.name,
                documentNumber: e.documentNumber,
                email: e.email,
                phone: e.phone,
                createdAt: (typeof e.createdAt === "string" && (e.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.createdAt as any).replace("Z", "") : e.createdAt.toISOString().replace("Z", "")),
                updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : (typeof e.updatedAt === "string" && (e.updatedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.updatedAt as any).replace("Z", "") : e.updatedAt.toISOString().replace("Z", "")),
                status: e.status,
                isMEI: !!e.isMEI,
                fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
                foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : e.foundationDate.toISOString().split("T")[0],
                foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
                socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
                mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
                address: e.address === null || e.address === undefined ? null : {
                    cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                    street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                    neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                    number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                    complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                    city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                    state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                    country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
                },
            })),
            urlTerm: user.urlTerm === null || user.urlTerm === undefined ? null : user.urlTerm,
            urlContract: user.urlContract === null || user.urlContract === undefined ? null : user.urlContract,
            socialName: user.socialName === null || user.socialName === undefined ? null : user.socialName,
            phone: user.phone === null || user.phone === undefined ? null : user.phone,
            blocked: user.blocked === null || user.blocked === undefined ? null : !!user.blocked,
            isMEI: !!user.isMEI,
            showCard: user.showCard === null || user.showCard === undefined ? null : !!user.showCard,
            help: user.help === null || user.help === undefined ? null : user.help,
            mobileHelp: user.mobileHelp === null || user.mobileHelp === undefined ? null : user.mobileHelp,
            pendingDocument: user.pendingDocument === null || user.pendingDocument === undefined ? null : !!user.pendingDocument,
        },
        benefitId: benefitId,
  };
  await makeRequest({name: "checkUserBenefit", args});
    return undefined;
}

export async function checkUserBenefitV13(user: UserV13, benefitId: string): Promise<void> {
  const args = {
        user: {
            id: user.id,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            cnpj: user.cnpj === null || user.cnpj === undefined ? null : user.cnpj,
            address: user.address === null || user.address === undefined ? null : {
                cep: user.address.cep === null || user.address.cep === undefined ? null : user.address.cep,
                street: user.address.street === null || user.address.street === undefined ? null : user.address.street,
                neighborhood: user.address.neighborhood === null || user.address.neighborhood === undefined ? null : user.address.neighborhood,
                number: user.address.number === null || user.address.number === undefined ? null : user.address.number,
                complement: user.address.complement === null || user.address.complement === undefined ? null : user.address.complement,
                city: user.address.city === null || user.address.city === undefined ? null : user.address.city,
                state: user.address.state === null || user.address.state === undefined ? null : user.address.state,
                country: user.address.country === null || user.address.country === undefined ? null : user.address.country,
            },
            profilePhoto: user.profilePhoto === null || user.profilePhoto === undefined ? null : user.profilePhoto,
            status: user.status,
            publicKey: user.publicKey === null || user.publicKey === undefined ? null : user.publicKey,
            partnerId: user.partnerId === null || user.partnerId === undefined ? null : user.partnerId,
            accounts: user.accounts.map(e => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map(e => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: (typeof e.createdAt === "string" && (e.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.createdAt as any).replace("Z", "") : e.createdAt.toISOString().replace("Z", "")),
            })),
            companies: user.companies.map(e => ({
                accounts: e.accounts.map(e => ({
                    name: e.name,
                    type: e.type,
                    documentNumber: e.documentNumber,
                    permissions: e.permissions.map(e => ({
                        permissionType: e.permissionType,
                        maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                        maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                        isSpecial: !!e.isSpecial,
                    })),
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    yourNumber: e.yourNumber | 0,
                    subType: e.subType === null || e.subType === undefined ? null : e.subType,
                    bank: {
                        code: e.bank.code,
                        name: e.bank.name,
                    },
                    branch: e.branch,
                    account: e.account,
                    createdAt: (typeof e.createdAt === "string" && (e.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.createdAt as any).replace("Z", "") : e.createdAt.toISOString().replace("Z", "")),
                })),
                id: e.id,
                name: e.name,
                documentNumber: e.documentNumber,
                email: e.email,
                phone: e.phone === null || e.phone === undefined ? null : e.phone,
                createdAt: (typeof e.createdAt === "string" && (e.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.createdAt as any).replace("Z", "") : e.createdAt.toISOString().replace("Z", "")),
                updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : (typeof e.updatedAt === "string" && (e.updatedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e.updatedAt as any).replace("Z", "") : e.updatedAt.toISOString().replace("Z", "")),
                status: e.status,
                isMEI: !!e.isMEI,
                fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
                foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : e.foundationDate.toISOString().split("T")[0],
                foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
                socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
                mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
                address: e.address === null || e.address === undefined ? null : {
                    cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                    street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                    neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                    number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                    complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                    city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                    state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                    country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
                },
                isPPE: e.isPPE === null || e.isPPE === undefined ? null : !!e.isPPE,
                mailingAddress: e.mailingAddress === null || e.mailingAddress === undefined ? null : {
                    cep: e.mailingAddress.cep === null || e.mailingAddress.cep === undefined ? null : e.mailingAddress.cep,
                    street: e.mailingAddress.street === null || e.mailingAddress.street === undefined ? null : e.mailingAddress.street,
                    neighborhood: e.mailingAddress.neighborhood === null || e.mailingAddress.neighborhood === undefined ? null : e.mailingAddress.neighborhood,
                    number: e.mailingAddress.number === null || e.mailingAddress.number === undefined ? null : e.mailingAddress.number,
                    complement: e.mailingAddress.complement === null || e.mailingAddress.complement === undefined ? null : e.mailingAddress.complement,
                    city: e.mailingAddress.city === null || e.mailingAddress.city === undefined ? null : e.mailingAddress.city,
                    state: e.mailingAddress.state === null || e.mailingAddress.state === undefined ? null : e.mailingAddress.state,
                    country: e.mailingAddress.country === null || e.mailingAddress.country === undefined ? null : e.mailingAddress.country,
                },
                hasTaxDomain: e.hasTaxDomain === null || e.hasTaxDomain === undefined ? null : !!e.hasTaxDomain,
                taxDomainCountry: e.taxDomainCountry === null || e.taxDomainCountry === undefined ? null : e.taxDomainCountry,
                taxDomainNumber: e.taxDomainNumber === null || e.taxDomainNumber === undefined ? null : e.taxDomainNumber,
                isAmericanCitizen: e.isAmericanCitizen === null || e.isAmericanCitizen === undefined ? null : !!e.isAmericanCitizen,
                americanCitizenNIF: e.americanCitizenNIF === null || e.americanCitizenNIF === undefined ? null : e.americanCitizenNIF,
                isPPERelationship: e.isPPERelationship === null || e.isPPERelationship === undefined ? null : !!e.isPPERelationship,
                giinNumber: e.giinNumber === null || e.giinNumber === undefined ? null : e.giinNumber,
                ppe: e.ppe === null || e.ppe === undefined ? null : e.ppe.map(e => ({
                    name: e.name,
                    reason: e.reason,
                })),
                ppeRelationship: e.ppeRelationship === null || e.ppeRelationship === undefined ? null : e.ppeRelationship.map(e => ({
                    name: e.name,
                    reason: e.reason,
                })),
            })),
            urlTerm: user.urlTerm === null || user.urlTerm === undefined ? null : user.urlTerm,
            urlContract: user.urlContract === null || user.urlContract === undefined ? null : user.urlContract,
            urlFarePackages: user.urlFarePackages === null || user.urlFarePackages === undefined ? null : user.urlFarePackages,
            socialName: user.socialName === null || user.socialName === undefined ? null : user.socialName,
            phone: user.phone === null || user.phone === undefined ? null : user.phone,
            blocked: user.blocked === null || user.blocked === undefined ? null : !!user.blocked,
            isMEI: !!user.isMEI,
            showCard: user.showCard === null || user.showCard === undefined ? null : !!user.showCard,
            help: user.help === null || user.help === undefined ? null : user.help,
            mobileHelp: user.mobileHelp === null || user.mobileHelp === undefined ? null : user.mobileHelp,
            pendingDocument: user.pendingDocument === null || user.pendingDocument === undefined ? null : !!user.pendingDocument,
            isMaster: user.isMaster === null || user.isMaster === undefined ? null : !!user.isMaster,
        },
        benefitId: benefitId,
  };
  await makeRequest({name: "checkUserBenefitV13", args});
    return undefined;
}

export async function getBenefitById(benefitId: string): Promise<Benefit> {
  const args = {
        benefitId: benefitId,
  };
  const ret = await makeRequest({name: "getBenefitById", args});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        url: ret.url,
        urlActive: ret.urlActive,
    };
}

export async function getBenefits(): Promise<Benefit[]> {
  const ret = await makeRequest({name: "getBenefits", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description === null || e.description === undefined ? null : e.description,
        url: e.url,
        urlActive: e.urlActive,
    }));
}

export async function resendEmailPending(documentNumber: string): Promise<void> {
  const args = {
        documentNumber: documentNumber,
  };
  await makeRequest({name: "resendEmailPending", args});
    return undefined;
}

export async function checkUserInfo(cpf: string, birthday: Date, partnerId: string, recaptchaToken: string): Promise<InfoByCPFAndBirthday> {
  const args = {
        cpf: cpf,
        birthday: birthday.toISOString().split("T")[0],
        partnerId: partnerId,
        recaptchaToken: recaptchaToken,
  };
  const ret = await makeRequest({name: "checkUserInfo", args});
    return {
        name: ret.name === null || ret.name === undefined ? null : ret.name,
        mothername: ret.mothername === null || ret.mothername === undefined ? null : ret.mothername,
        token: ret.token === null || ret.token === undefined ? null : ret.token,
    };
}

export async function getUserInfoByCPFAndBirthday(cpf: string, birthday: Date, partnerId: string, recaptchaToken: string): Promise<InfoByCPFAndBirthday> {
  const args = {
        cpf: cpf,
        birthday: birthday.toISOString().split("T")[0],
        partnerId: partnerId,
        recaptchaToken: recaptchaToken,
  };
  const ret = await makeRequest({name: "getUserInfoByCPFAndBirthday", args});
    return {
        name: ret.name === null || ret.name === undefined ? null : ret.name,
        mothername: ret.mothername === null || ret.mothername === undefined ? null : ret.mothername,
        token: ret.token === null || ret.token === undefined ? null : ret.token,
    };
}

export async function getUserNameReducedJourney(cpf: string, birthday: Date, partnerId: string): Promise<string | null> {
  const args = {
        cpf: cpf,
        birthday: birthday.toISOString().split("T")[0],
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getUserNameReducedJourney", args});
    return ret === null || ret === undefined ? null : ret;
}

export async function getRegisterUserInfoByCnpj(cnpj: string, partnerId: string): Promise<InfoByCnpj> {
  const args = {
        cnpj: cnpj,
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getRegisterUserInfoByCnpj", args});
    return {
        name: ret.name === null || ret.name === undefined ? null : ret.name,
    };
}

export async function getAddressByCEPFromCompliance(cpf: string, cep: string, birthday: Date): Promise<CEPAddress> {
  const args = {
        cpf: cpf,
        cep: cep,
        birthday: birthday.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getAddressByCEPFromCompliance", args});
    return {
        street: ret.street,
        neighborhood: ret.neighborhood,
        city: ret.city,
        state: ret.state,
        country: ret.country,
        number: ret.number,
    };
}

export async function finishCreateAccountV10(data: RegisterData, deviceInfo: DeviceInfo, token: string | null): Promise<boolean> {
  const args = {
        data: {
            cpf: data.cpf,
            birthday: data.birthday.toISOString().split("T")[0],
            name: data.name,
            email: data.email,
            cel: data.cel,
            acceptTermsOfUse: !!data.acceptTermsOfUse,
            acceptTermsOfService: !!data.acceptTermsOfService,
            socialName: data.socialName === null || data.socialName === undefined ? null : data.socialName,
            gender: data.gender,
            affiliation1: data.affiliation1,
            affiliation2: data.affiliation2 === null || data.affiliation2 === undefined ? null : data.affiliation2,
            nationality: data.nationality,
            zipCode: data.zipCode,
            street: data.street,
            numberAddress: data.numberAddress,
            additionalAddressData: data.additionalAddressData === null || data.additionalAddressData === undefined ? null : data.additionalAddressData,
            neighborhood: data.neighborhood,
            city: data.city,
            uf: data.uf,
            patrimony: data.patrimony === null || data.patrimony === undefined ? null : data.patrimony,
            monthlyIncome: data.monthlyIncome === null || data.monthlyIncome === undefined ? null : data.monthlyIncome,
            proofOfAddress: data.proofOfAddress === null || data.proofOfAddress === undefined ? null : data.proofOfAddress.toString("base64"),
            documentType: data.documentType,
            documentImageFront: data.documentImageFront === null || data.documentImageFront === undefined ? null : data.documentImageFront.toString("base64"),
            documentImageBack: data.documentImageBack === null || data.documentImageBack === undefined ? null : data.documentImageBack.toString("base64"),
            selfieImage: data.selfieImage === null || data.selfieImage === undefined ? null : data.selfieImage.toString("base64"),
            confirmTermsOfService: !!data.confirmTermsOfService,
            ppeData: data.ppeData === null || data.ppeData === undefined ? null : {
                isBrazilian: !!data.ppeData.isBrazilian,
                hasTaxRegion: !!data.ppeData.hasTaxRegion,
                isCRS: !!data.ppeData.isCRS,
                isFATCA: !!data.ppeData.isFATCA,
                isPPE: !!data.ppeData.isPPE,
                isPublicOfficer: !!data.ppeData.isPublicOfficer,
                isResponsible: !!data.ppeData.isResponsible,
                anotherCitizenship: !!data.ppeData.anotherCitizenship,
                isFATCADeclaration: !!data.ppeData.isFATCADeclaration,
                isCRSDeclaration: !!data.ppeData.isCRSDeclaration,
                hasCriminalProceedings: !!data.ppeData.hasCriminalProceedings,
                isPublicOfficerDeclaration: !!data.ppeData.isPublicOfficerDeclaration,
                isPPEDeclaration: data.ppeData.isPPEDeclaration === null || data.ppeData.isPPEDeclaration === undefined ? null : data.ppeData.isPPEDeclaration,
                country: data.ppeData.country === null || data.ppeData.country === undefined ? null : data.ppeData.country,
                documentNumber: data.ppeData.documentNumber === null || data.ppeData.documentNumber === undefined ? null : data.ppeData.documentNumber,
                addressCel: data.ppeData.addressCel === null || data.ppeData.addressCel === undefined ? null : data.ppeData.addressCel,
                resignation: data.ppeData.resignation === null || data.ppeData.resignation === undefined ? null : data.ppeData.resignation,
                nameJob: data.ppeData.nameJob === null || data.ppeData.nameJob === undefined ? null : data.ppeData.nameJob,
                startJob: data.ppeData.startJob === null || data.ppeData.startJob === undefined ? null : data.ppeData.startJob.toISOString().split("T")[0],
                endJob: data.ppeData.endJob === null || data.ppeData.endJob === undefined ? null : data.ppeData.endJob.toISOString().split("T")[0],
                politicallyExposedPersonName: data.ppeData.politicallyExposedPersonName === null || data.ppeData.politicallyExposedPersonName === undefined ? null : data.ppeData.politicallyExposedPersonName,
                relationshipNature: data.ppeData.relationshipNature === null || data.ppeData.relationshipNature === undefined ? null : data.ppeData.relationshipNature,
            },
            partnerId: data.partnerId,
            createdByApi: data.createdByApi === null || data.createdByApi === undefined ? null : !!data.createdByApi,
        },
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
        token: token === null || token === undefined ? null : token,
  };
  const ret = await makeRequest({name: "finishCreateAccountV10", args});
    return !!ret;
}

export async function validateAddress(cpf: string, address: AddressVerification): Promise<boolean> {
  const args = {
        cpf: cpf,
        address: {
            cep: address.cep,
            street: address.street,
            neighborhood: address.neighborhood,
            number: address.number,
            city: address.city,
            state: address.state,
        },
  };
  const ret = await makeRequest({name: "validateAddress", args});
    return !!ret;
}

export async function validateAddressAndCertificateMEI(ownerName: string, documentNumber: string, foundationDate: Date, address: AddressVerification): Promise<AddressCertificateMEIInfo> {
  const args = {
        ownerName: ownerName,
        documentNumber: documentNumber,
        foundationDate: foundationDate.toISOString().split("T")[0],
        address: {
            cep: address.cep,
            street: address.street,
            neighborhood: address.neighborhood,
            number: address.number,
            city: address.city,
            state: address.state,
        },
  };
  const ret = await makeRequest({name: "validateAddressAndCertificateMEI", args});
    return {
        address: !!ret.address,
        certificateMEI: !!ret.certificateMEI,
    };
}

export async function getStateList(): Promise<BasicState[]> {
  const ret = await makeRequest({name: "getStateList", args: {}});
    return ret.map((e: any) => ({
        slug: e.slug,
        name: e.name,
    }));
}

export async function getNationalityList(): Promise<Nationality[]> {
  const ret = await makeRequest({name: "getNationalityList", args: {}});
    return ret.map((e: any) => ({
        slug: e.slug,
        name: e.name,
    }));
}

export async function getBasicByState(stateSlug: string): Promise<BasicCity[]> {
  const args = {
        stateSlug: stateSlug,
  };
  const ret = await makeRequest({name: "getBasicByState", args});
    return ret.map((e: any) => ({
        slug: e.slug,
        name: e.name,
    }));
}

export async function getReprovedFields(tokenOnLink: string): Promise<ReprovedUser> {
  const args = {
        tokenOnLink: tokenOnLink,
  };
  const ret = await makeRequest({name: "getReprovedFields", args});
    return {
        registerData: {
            cpf: ret.registerData.cpf,
            birthday: new Date(parseInt(ret.registerData.birthday.split("-")[0], 10), parseInt(ret.registerData.birthday.split("-")[1], 10) - 1, parseInt(ret.registerData.birthday.split("-")[2], 10)),
            name: ret.registerData.name,
            email: ret.registerData.email,
            cel: ret.registerData.cel,
            acceptTermsOfUse: !!ret.registerData.acceptTermsOfUse,
            acceptTermsOfService: !!ret.registerData.acceptTermsOfService,
            socialName: ret.registerData.socialName === null || ret.registerData.socialName === undefined ? null : ret.registerData.socialName,
            gender: ret.registerData.gender,
            affiliation1: ret.registerData.affiliation1,
            affiliation2: ret.registerData.affiliation2 === null || ret.registerData.affiliation2 === undefined ? null : ret.registerData.affiliation2,
            nationality: ret.registerData.nationality,
            zipCode: ret.registerData.zipCode,
            street: ret.registerData.street,
            numberAddress: ret.registerData.numberAddress,
            additionalAddressData: ret.registerData.additionalAddressData === null || ret.registerData.additionalAddressData === undefined ? null : ret.registerData.additionalAddressData,
            neighborhood: ret.registerData.neighborhood,
            city: ret.registerData.city,
            uf: ret.registerData.uf,
            patrimony: ret.registerData.patrimony === null || ret.registerData.patrimony === undefined ? null : ret.registerData.patrimony,
            monthlyIncome: ret.registerData.monthlyIncome === null || ret.registerData.monthlyIncome === undefined ? null : ret.registerData.monthlyIncome,
            proofOfAddress: ret.registerData.proofOfAddress === null || ret.registerData.proofOfAddress === undefined ? null : Buffer.from(ret.registerData.proofOfAddress, "base64"),
            documentType: ret.registerData.documentType,
            documentImageFront: ret.registerData.documentImageFront === null || ret.registerData.documentImageFront === undefined ? null : Buffer.from(ret.registerData.documentImageFront, "base64"),
            documentImageBack: ret.registerData.documentImageBack === null || ret.registerData.documentImageBack === undefined ? null : Buffer.from(ret.registerData.documentImageBack, "base64"),
            selfieImage: ret.registerData.selfieImage === null || ret.registerData.selfieImage === undefined ? null : Buffer.from(ret.registerData.selfieImage, "base64"),
            confirmTermsOfService: !!ret.registerData.confirmTermsOfService,
            ppeData: ret.registerData.ppeData === null || ret.registerData.ppeData === undefined ? null : {
                isBrazilian: !!ret.registerData.ppeData.isBrazilian,
                hasTaxRegion: !!ret.registerData.ppeData.hasTaxRegion,
                isCRS: !!ret.registerData.ppeData.isCRS,
                isFATCA: !!ret.registerData.ppeData.isFATCA,
                isPPE: !!ret.registerData.ppeData.isPPE,
                isPublicOfficer: !!ret.registerData.ppeData.isPublicOfficer,
                isResponsible: !!ret.registerData.ppeData.isResponsible,
                anotherCitizenship: !!ret.registerData.ppeData.anotherCitizenship,
                isFATCADeclaration: !!ret.registerData.ppeData.isFATCADeclaration,
                isCRSDeclaration: !!ret.registerData.ppeData.isCRSDeclaration,
                hasCriminalProceedings: !!ret.registerData.ppeData.hasCriminalProceedings,
                isPublicOfficerDeclaration: !!ret.registerData.ppeData.isPublicOfficerDeclaration,
                isPPEDeclaration: ret.registerData.ppeData.isPPEDeclaration === null || ret.registerData.ppeData.isPPEDeclaration === undefined ? null : ret.registerData.ppeData.isPPEDeclaration,
                country: ret.registerData.ppeData.country === null || ret.registerData.ppeData.country === undefined ? null : ret.registerData.ppeData.country,
                documentNumber: ret.registerData.ppeData.documentNumber === null || ret.registerData.ppeData.documentNumber === undefined ? null : ret.registerData.ppeData.documentNumber,
                addressCel: ret.registerData.ppeData.addressCel === null || ret.registerData.ppeData.addressCel === undefined ? null : ret.registerData.ppeData.addressCel,
                resignation: ret.registerData.ppeData.resignation === null || ret.registerData.ppeData.resignation === undefined ? null : ret.registerData.ppeData.resignation,
                nameJob: ret.registerData.ppeData.nameJob === null || ret.registerData.ppeData.nameJob === undefined ? null : ret.registerData.ppeData.nameJob,
                startJob: ret.registerData.ppeData.startJob === null || ret.registerData.ppeData.startJob === undefined ? null : new Date(parseInt(ret.registerData.ppeData.startJob.split("-")[0], 10), parseInt(ret.registerData.ppeData.startJob.split("-")[1], 10) - 1, parseInt(ret.registerData.ppeData.startJob.split("-")[2], 10)),
                endJob: ret.registerData.ppeData.endJob === null || ret.registerData.ppeData.endJob === undefined ? null : new Date(parseInt(ret.registerData.ppeData.endJob.split("-")[0], 10), parseInt(ret.registerData.ppeData.endJob.split("-")[1], 10) - 1, parseInt(ret.registerData.ppeData.endJob.split("-")[2], 10)),
                politicallyExposedPersonName: ret.registerData.ppeData.politicallyExposedPersonName === null || ret.registerData.ppeData.politicallyExposedPersonName === undefined ? null : ret.registerData.ppeData.politicallyExposedPersonName,
                relationshipNature: ret.registerData.ppeData.relationshipNature === null || ret.registerData.ppeData.relationshipNature === undefined ? null : ret.registerData.ppeData.relationshipNature,
            },
            partnerId: ret.registerData.partnerId,
            createdByApi: ret.registerData.createdByApi === null || ret.registerData.createdByApi === undefined ? null : !!ret.registerData.createdByApi,
        },
        reprovedField: ret.reprovedField.map((e: any) => e),
    };
}

export async function getUserInfoMEI(cpf: string, birthday: Date, cnpj: string, foundationDate: Date, recaptchaToken: string | null): Promise<InfoRegistrationMEI> {
  const args = {
        cpf: cpf,
        birthday: birthday.toISOString().split("T")[0],
        cnpj: cnpj,
        foundationDate: foundationDate.toISOString().split("T")[0],
        recaptchaToken: recaptchaToken === null || recaptchaToken === undefined ? null : recaptchaToken,
  };
  const ret = await makeRequest({name: "getUserInfoMEI", args});
    return {
        ownerName: ret.ownerName === null || ret.ownerName === undefined ? null : ret.ownerName,
        ownerMotherName: ret.ownerMotherName === null || ret.ownerMotherName === undefined ? null : ret.ownerMotherName,
        companyName: ret.companyName === null || ret.companyName === undefined ? null : ret.companyName,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        token: ret.token === null || ret.token === undefined ? null : ret.token,
    };
}

export async function getAddressByCEPFromComplianceMEI(cnpj: string, cep: string, foundationDate: Date): Promise<CEPAddress> {
  const args = {
        cnpj: cnpj,
        cep: cep,
        foundationDate: foundationDate.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getAddressByCEPFromComplianceMEI", args});
    return {
        street: ret.street,
        neighborhood: ret.neighborhood,
        city: ret.city,
        state: ret.state,
        country: ret.country,
        number: ret.number,
    };
}

export async function finishCreateAccountMEI(data: RegisterDataMEI, deviceInfo: DeviceInfo, token: string | null): Promise<boolean> {
  const args = {
        data: {
            cpf: data.cpf,
            birthday: data.birthday.toISOString().split("T")[0],
            name: data.name,
            email: data.email,
            phone: data.phone,
            acceptTermsOfUse: !!data.acceptTermsOfUse,
            acceptTermsOfService: !!data.acceptTermsOfService,
            gender: data.gender,
            affiliation1: data.affiliation1,
            affiliation2: data.affiliation2 === null || data.affiliation2 === undefined ? null : data.affiliation2,
            nationality: data.nationality,
            documentType: data.documentType,
            documentImageFront: data.documentImageFront === null || data.documentImageFront === undefined ? null : data.documentImageFront.toString("base64"),
            documentImageBack: data.documentImageBack === null || data.documentImageBack === undefined ? null : data.documentImageBack.toString("base64"),
            selfieImage: data.selfieImage === null || data.selfieImage === undefined ? null : data.selfieImage.toString("base64"),
            confirmTermsOfService: !!data.confirmTermsOfService,
            ppeData: data.ppeData === null || data.ppeData === undefined ? null : {
                isBrazilian: !!data.ppeData.isBrazilian,
                hasTaxRegion: !!data.ppeData.hasTaxRegion,
                isCRS: !!data.ppeData.isCRS,
                isFATCA: !!data.ppeData.isFATCA,
                isPPE: !!data.ppeData.isPPE,
                isPublicOfficer: !!data.ppeData.isPublicOfficer,
                isResponsible: !!data.ppeData.isResponsible,
                anotherCitizenship: !!data.ppeData.anotherCitizenship,
                isFATCADeclaration: !!data.ppeData.isFATCADeclaration,
                isCRSDeclaration: !!data.ppeData.isCRSDeclaration,
                hasCriminalProceedings: !!data.ppeData.hasCriminalProceedings,
                isPublicOfficerDeclaration: !!data.ppeData.isPublicOfficerDeclaration,
                isPPEDeclaration: data.ppeData.isPPEDeclaration === null || data.ppeData.isPPEDeclaration === undefined ? null : data.ppeData.isPPEDeclaration,
                country: data.ppeData.country === null || data.ppeData.country === undefined ? null : data.ppeData.country,
                documentNumber: data.ppeData.documentNumber === null || data.ppeData.documentNumber === undefined ? null : data.ppeData.documentNumber,
                addressCel: data.ppeData.addressCel === null || data.ppeData.addressCel === undefined ? null : data.ppeData.addressCel,
                resignation: data.ppeData.resignation === null || data.ppeData.resignation === undefined ? null : data.ppeData.resignation,
                nameJob: data.ppeData.nameJob === null || data.ppeData.nameJob === undefined ? null : data.ppeData.nameJob,
                startJob: data.ppeData.startJob === null || data.ppeData.startJob === undefined ? null : data.ppeData.startJob.toISOString().split("T")[0],
                endJob: data.ppeData.endJob === null || data.ppeData.endJob === undefined ? null : data.ppeData.endJob.toISOString().split("T")[0],
                politicallyExposedPersonName: data.ppeData.politicallyExposedPersonName === null || data.ppeData.politicallyExposedPersonName === undefined ? null : data.ppeData.politicallyExposedPersonName,
                relationshipNature: data.ppeData.relationshipNature === null || data.ppeData.relationshipNature === undefined ? null : data.ppeData.relationshipNature,
            },
            partnerId: data.partnerId,
            companyMEI: {
                cnpj: data.companyMEI.cnpj,
                foundationDate: data.companyMEI.foundationDate.toISOString().split("T")[0],
                companyName: data.companyMEI.companyName,
                fantasyName: data.companyMEI.fantasyName === null || data.companyMEI.fantasyName === undefined ? null : data.companyMEI.fantasyName,
                proofOfAddress: data.companyMEI.proofOfAddress === null || data.companyMEI.proofOfAddress === undefined ? null : data.companyMEI.proofOfAddress.toString("base64"),
                certificateMEI: data.companyMEI.certificateMEI === null || data.companyMEI.certificateMEI === undefined ? null : data.companyMEI.certificateMEI.toString("base64"),
                zipCode: data.companyMEI.zipCode,
                street: data.companyMEI.street,
                numberAddress: data.companyMEI.numberAddress,
                additionalAddressData: data.companyMEI.additionalAddressData === null || data.companyMEI.additionalAddressData === undefined ? null : data.companyMEI.additionalAddressData,
                neighborhood: data.companyMEI.neighborhood,
                city: data.companyMEI.city,
                uf: data.companyMEI.uf,
            },
        },
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
        token: token === null || token === undefined ? null : token,
  };
  const ret = await makeRequest({name: "finishCreateAccountMEI", args});
    return !!ret;
}

export async function sendRepresentativeDocuments(linkToken: string, fields: RegisterDataPJ): Promise<boolean> {
  const args = {
        linkToken: linkToken,
        fields: {
            documentType: fields.documentType,
            documentImageFront: fields.documentImageFront === null || fields.documentImageFront === undefined ? null : fields.documentImageFront.toString("base64"),
            documentImageBack: fields.documentImageBack === null || fields.documentImageBack === undefined ? null : fields.documentImageBack.toString("base64"),
            selfieImage: fields.selfieImage.toString("base64"),
            partnerId: fields.partnerId,
        },
  };
  const ret = await makeRequest({name: "sendRepresentativeDocuments", args});
    return !!ret;
}

export async function sendSubscriberDocuments(linkToken: string, fields: RegisterDataPJ): Promise<boolean> {
  const args = {
        linkToken: linkToken,
        fields: {
            documentType: fields.documentType,
            documentImageFront: fields.documentImageFront === null || fields.documentImageFront === undefined ? null : fields.documentImageFront.toString("base64"),
            documentImageBack: fields.documentImageBack === null || fields.documentImageBack === undefined ? null : fields.documentImageBack.toString("base64"),
            selfieImage: fields.selfieImage.toString("base64"),
            partnerId: fields.partnerId,
        },
  };
  const ret = await makeRequest({name: "sendSubscriberDocuments", args});
    return !!ret;
}

export async function getLastRepresentativeDataByToken(linkToken: string): Promise<IRepresentative> {
  const args = {
        linkToken: linkToken,
  };
  const ret = await makeRequest({name: "getLastRepresentativeDataByToken", args});
    return {
        id: ret.id,
        companyId: ret.companyId,
        userId: ret.userId === null || ret.userId === undefined ? null : ret.userId,
        formId: ret.formId === null || ret.formId === undefined ? null : ret.formId,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        dateOfBirth: ret.dateOfBirth,
        occupation: ret.occupation === null || ret.occupation === undefined ? null : ret.occupation,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cellPhone: ret.cellPhone === null || ret.cellPhone === undefined ? null : ret.cellPhone,
        motherName: ret.motherName === null || ret.motherName === undefined ? null : ret.motherName,
        nationality: ret.nationality === null || ret.nationality === undefined ? null : ret.nationality,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            number: ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
        },
        shortAddress: ret.shortAddress === null || ret.shortAddress === undefined ? null : ret.shortAddress,
        isMaster: !!ret.isMaster,
        isOwner: !!ret.isOwner,
        percentage: ret.percentage === null || ret.percentage === undefined ? null : ret.percentage,
        proofOfLifeStatus: ret.proofOfLifeStatus,
        acertIdKey: ret.acertIdKey === null || ret.acertIdKey === undefined ? null : ret.acertIdKey,
        imagesUrl: ret.imagesUrl === null || ret.imagesUrl === undefined ? null : ret.imagesUrl.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        tokenLinkProofOfLife: ret.tokenLinkProofOfLife === null || ret.tokenLinkProofOfLife === undefined ? null : ret.tokenLinkProofOfLife,
        isDeleted: !!ret.isDeleted,
        acertIdStatus: ret.acertIdStatus === null || ret.acertIdStatus === undefined ? null : ret.acertIdStatus | 0,
        acertIdBiometryStatus: ret.acertIdBiometryStatus === null || ret.acertIdBiometryStatus === undefined ? null : ret.acertIdBiometryStatus | 0,
        acertIdResponse: ret.acertIdResponse === null || ret.acertIdResponse === undefined ? null : ret.acertIdResponse,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        updatedAt: new Date(parseInt(ret.updatedAt.split("-")[0], 10), parseInt(ret.updatedAt.split("-")[1], 10) - 1, parseInt(ret.updatedAt.split("-")[2], 10)),
    };
}

export async function getLastSubscriberDataByToken(linkToken: string): Promise<ISubscriber> {
  const args = {
        linkToken: linkToken,
  };
  const ret = await makeRequest({name: "getLastSubscriberDataByToken", args});
    return {
        id: ret.id,
        companyId: ret.companyId,
        formId: ret.formId === null || ret.formId === undefined ? null : ret.formId,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            number: ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
        },
        proofOfLifeStatus: ret.proofOfLifeStatus,
        acertIdKey: ret.acertIdKey === null || ret.acertIdKey === undefined ? null : ret.acertIdKey,
        imagesUrl: ret.imagesUrl === null || ret.imagesUrl === undefined ? null : ret.imagesUrl.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        tokenLinkProofOfLife: ret.tokenLinkProofOfLife === null || ret.tokenLinkProofOfLife === undefined ? null : ret.tokenLinkProofOfLife,
        isDeleted: !!ret.isDeleted,
        acertIdStatus: ret.acertIdStatus === null || ret.acertIdStatus === undefined ? null : ret.acertIdStatus | 0,
        acertIdBiometryStatus: ret.acertIdBiometryStatus === null || ret.acertIdBiometryStatus === undefined ? null : ret.acertIdBiometryStatus | 0,
        acertIdResponse: ret.acertIdResponse === null || ret.acertIdResponse === undefined ? null : ret.acertIdResponse,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        updatedAt: new Date(parseInt(ret.updatedAt.split("-")[0], 10), parseInt(ret.updatedAt.split("-")[1], 10) - 1, parseInt(ret.updatedAt.split("-")[2], 10)),
    };
}

export async function validate4PassCard(password: string): Promise<void> {
  const args = {
        password: password,
  };
  await makeRequest({name: "validate4PassCard", args});
    return undefined;
}

export async function verifyUserHavePassword(cpf: string): Promise<boolean> {
  const args = {
        cpf: cpf,
  };
  const ret = await makeRequest({name: "verifyUserHavePassword", args});
    return !!ret;
}

export async function verifyUserHavePasswordV2(cpf: string): Promise<VerifyCreateUserPassword> {
  const args = {
        cpf: cpf,
  };
  const ret = await makeRequest({name: "verifyUserHavePasswordV2", args});
    return {
        havePassword: !!ret.havePassword,
        isOnlyRepresentative: !!ret.isOnlyRepresentative,
    };
}

export async function verifyDataToChangePasswordV2(cpf: string, birthDate: Date, cep: string, location: LatLng | null, createPassword: boolean): Promise<void> {
  const args = {
        cpf: cpf,
        birthDate: birthDate.toISOString().split("T")[0],
        cep: cep,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
        createPassword: !!createPassword,
  };
  await makeRequest({name: "verifyDataToChangePasswordV2", args});
    return undefined;
}

export async function verifySMSCodeToChangePasswordV2(cpf: string, smsCode: string, location: LatLng | null): Promise<ChangePasswordV2> {
  const args = {
        cpf: cpf,
        smsCode: smsCode,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "verifySMSCodeToChangePasswordV2", args});
    return {
        userId: ret.userId,
        token: ret.token,
    };
}

export async function createPasswordByTokenV2(cpf: string, token: string, newPassword: string, confirmNewPassword: string, location: LatLng | null): Promise<void> {
  const args = {
        cpf: cpf,
        token: token,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "createPasswordByTokenV2", args});
    return undefined;
}

export async function verifyAppToken(userId: string, sign: Signature): Promise<void> {
  const args = {
        userId: userId,
        sign: {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
  };
  await makeRequest({name: "verifyAppToken", args});
    return undefined;
}

export async function updatePushToken(pushToken: string, sign: Signature): Promise<void> {
  const args = {
        pushToken: pushToken,
        sign: {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
  };
  await makeRequest({name: "updatePushToken", args});
    return undefined;
}

export async function getOperationDate(date: Date): Promise<Operations> {
  const args = {
        date: date.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getOperationDate", args});
    return {
        ted: ret.ted === null || ret.ted === undefined ? null : {
            start: ret.ted.start === null || ret.ted.start === undefined ? null : ret.ted.start,
            end: ret.ted.end === null || ret.ted.end === undefined ? null : ret.ted.end,
        },
        doc: ret.doc === null || ret.doc === undefined ? null : {
            start: ret.doc.start === null || ret.doc.start === undefined ? null : ret.doc.start,
            end: ret.doc.end === null || ret.doc.end === undefined ? null : ret.doc.end,
        },
        internalTransfer: ret.internalTransfer === null || ret.internalTransfer === undefined ? null : {
            start: ret.internalTransfer.start === null || ret.internalTransfer.start === undefined ? null : ret.internalTransfer.start,
            end: ret.internalTransfer.end === null || ret.internalTransfer.end === undefined ? null : ret.internalTransfer.end,
        },
        investment: ret.investment === null || ret.investment === undefined ? null : {
            start: ret.investment.start === null || ret.investment.start === undefined ? null : ret.investment.start,
            end: ret.investment.end === null || ret.investment.end === undefined ? null : ret.investment.end,
        },
        titleInclusion: ret.titleInclusion === null || ret.titleInclusion === undefined ? null : {
            start: ret.titleInclusion.start === null || ret.titleInclusion.start === undefined ? null : ret.titleInclusion.start,
            end: ret.titleInclusion.end === null || ret.titleInclusion.end === undefined ? null : ret.titleInclusion.end,
        },
        withdraw: ret.withdraw === null || ret.withdraw === undefined ? null : {
            start: ret.withdraw.start === null || ret.withdraw.start === undefined ? null : ret.withdraw.start,
            end: ret.withdraw.end === null || ret.withdraw.end === undefined ? null : ret.withdraw.end,
        },
        paymentBankSlip: ret.paymentBankSlip === null || ret.paymentBankSlip === undefined ? null : {
            start: ret.paymentBankSlip.start === null || ret.paymentBankSlip.start === undefined ? null : ret.paymentBankSlip.start,
            end: ret.paymentBankSlip.end === null || ret.paymentBankSlip.end === undefined ? null : ret.paymentBankSlip.end,
        },
        paymentAgreement: ret.paymentAgreement === null || ret.paymentAgreement === undefined ? null : {
            start: ret.paymentAgreement.start === null || ret.paymentAgreement.start === undefined ? null : ret.paymentAgreement.start,
            end: ret.paymentAgreement.end === null || ret.paymentAgreement.end === undefined ? null : ret.paymentAgreement.end,
        },
        recharge: ret.recharge === null || ret.recharge === undefined ? null : {
            start: ret.recharge.start === null || ret.recharge.start === undefined ? null : ret.recharge.start,
            end: ret.recharge.end === null || ret.recharge.end === undefined ? null : ret.recharge.end,
        },
    };
}

export async function getExceptionDate(start: Date, end: Date): Promise<ExceptionOperationsDate[]> {
  const args = {
        start: start.toISOString().split("T")[0],
        end: end.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getExceptionDate", args});
    return ret.map((e: any) => ({
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        operations: {
            ted: e.operations.ted === null || e.operations.ted === undefined ? null : {
                start: e.operations.ted.start === null || e.operations.ted.start === undefined ? null : e.operations.ted.start,
                end: e.operations.ted.end === null || e.operations.ted.end === undefined ? null : e.operations.ted.end,
            },
            doc: e.operations.doc === null || e.operations.doc === undefined ? null : {
                start: e.operations.doc.start === null || e.operations.doc.start === undefined ? null : e.operations.doc.start,
                end: e.operations.doc.end === null || e.operations.doc.end === undefined ? null : e.operations.doc.end,
            },
            internalTransfer: e.operations.internalTransfer === null || e.operations.internalTransfer === undefined ? null : {
                start: e.operations.internalTransfer.start === null || e.operations.internalTransfer.start === undefined ? null : e.operations.internalTransfer.start,
                end: e.operations.internalTransfer.end === null || e.operations.internalTransfer.end === undefined ? null : e.operations.internalTransfer.end,
            },
            investment: e.operations.investment === null || e.operations.investment === undefined ? null : {
                start: e.operations.investment.start === null || e.operations.investment.start === undefined ? null : e.operations.investment.start,
                end: e.operations.investment.end === null || e.operations.investment.end === undefined ? null : e.operations.investment.end,
            },
            titleInclusion: e.operations.titleInclusion === null || e.operations.titleInclusion === undefined ? null : {
                start: e.operations.titleInclusion.start === null || e.operations.titleInclusion.start === undefined ? null : e.operations.titleInclusion.start,
                end: e.operations.titleInclusion.end === null || e.operations.titleInclusion.end === undefined ? null : e.operations.titleInclusion.end,
            },
            withdraw: e.operations.withdraw === null || e.operations.withdraw === undefined ? null : {
                start: e.operations.withdraw.start === null || e.operations.withdraw.start === undefined ? null : e.operations.withdraw.start,
                end: e.operations.withdraw.end === null || e.operations.withdraw.end === undefined ? null : e.operations.withdraw.end,
            },
            paymentBankSlip: e.operations.paymentBankSlip === null || e.operations.paymentBankSlip === undefined ? null : {
                start: e.operations.paymentBankSlip.start === null || e.operations.paymentBankSlip.start === undefined ? null : e.operations.paymentBankSlip.start,
                end: e.operations.paymentBankSlip.end === null || e.operations.paymentBankSlip.end === undefined ? null : e.operations.paymentBankSlip.end,
            },
            paymentAgreement: e.operations.paymentAgreement === null || e.operations.paymentAgreement === undefined ? null : {
                start: e.operations.paymentAgreement.start === null || e.operations.paymentAgreement.start === undefined ? null : e.operations.paymentAgreement.start,
                end: e.operations.paymentAgreement.end === null || e.operations.paymentAgreement.end === undefined ? null : e.operations.paymentAgreement.end,
            },
            recharge: e.operations.recharge === null || e.operations.recharge === undefined ? null : {
                start: e.operations.recharge.start === null || e.operations.recharge.start === undefined ? null : e.operations.recharge.start,
                end: e.operations.recharge.end === null || e.operations.recharge.end === undefined ? null : e.operations.recharge.end,
            },
        },
        obs: e.obs,
        isAutomatic: !!e.isAutomatic,
    }));
}

export async function getUnavailableDates(start: Date, end: Date, operationType: OperationType): Promise<Date[]> {
  const args = {
        start: start.toISOString().split("T")[0],
        end: end.toISOString().split("T")[0],
        operationType: operationType,
  };
  const ret = await makeRequest({name: "getUnavailableDates", args});
    return ret.map((e: any) => new Date(parseInt(e.split("-")[0], 10), parseInt(e.split("-")[1], 10) - 1, parseInt(e.split("-")[2], 10)));
}

export async function getNextAvailableDate(date: Date, type: OperationType, limitDays: number): Promise<Date> {
  const args = {
        date: (typeof date === "string" && (date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (date as any).replace("Z", "") : date.toISOString().replace("Z", "")),
        type: type,
        limitDays: limitDays | 0,
  };
  const ret = await makeRequest({name: "getNextAvailableDate", args});
    return new Date(ret + "Z");
}

export async function getAvailableServices(): Promise<Service[]> {
  const ret = await makeRequest({name: "getAvailableServices", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function checkServiceByOperation(operationType: OperationType, isSchedule: boolean): Promise<boolean> {
  const args = {
        operationType: operationType,
        isSchedule: !!isSchedule,
  };
  const ret = await makeRequest({name: "checkServiceByOperation", args});
    return !!ret;
}

export async function getAvailableServicesV7(): Promise<Service[]> {
  const ret = await makeRequest({name: "getAvailableServicesV7", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function getAvailableServicesV9(): Promise<Service[]> {
  const ret = await makeRequest({name: "getAvailableServicesV9", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function getAvailableServicesV10(): Promise<Service[]> {
  const ret = await makeRequest({name: "getAvailableServicesV10", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function getAvailableServicesV13(): Promise<ServiceV13[]> {
  const ret = await makeRequest({name: "getAvailableServicesV13", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function newGetAvailableServices(): Promise<Service[]> {
  const ret = await makeRequest({name: "newGetAvailableServices", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function getFeaturesByPartnerFromContext(): Promise<Feature[]> {
  const ret = await makeRequest({name: "getFeaturesByPartnerFromContext", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        slug: e.slug,
        isEnabled: !!e.isEnabled,
    }));
}

export async function getFeatures(userId: string | null, partnerId: string): Promise<Feature[]> {
  const args = {
        userId: userId === null || userId === undefined ? null : userId,
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getFeatures", args});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        slug: e.slug,
        isEnabled: !!e.isEnabled,
    }));
}

export async function getFeaturesV2(userId: string | null, partnerId: string): Promise<Feature[]> {
  const args = {
        userId: userId === null || userId === undefined ? null : userId,
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getFeaturesV2", args});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        slug: e.slug,
        isEnabled: !!e.isEnabled,
    }));
}

export async function shouldUpgradeApp(version: string): Promise<boolean> {
  const args = {
        version: version,
  };
  const ret = await makeRequest({name: "shouldUpgradeApp", args});
    return !!ret;
}

export async function sendEmailTest(url: string): Promise<boolean> {
  const args = {
        url: url,
  };
  const ret = await makeRequest({name: "sendEmailTest", args});
    return !!ret;
}

export async function getEarningsInfo(year: string): Promise<string> {
  const args = {
        year: year,
  };
  const ret = await makeRequest({name: "getEarningsInfo", args});
    return ret;
}

export async function getEarningsInfoV2(year: string): Promise<string> {
  const args = {
        year: year,
  };
  const ret = await makeRequest({name: "getEarningsInfoV2", args});
    return ret;
}

export async function getAddressByCep(cep: string): Promise<AddressInfo> {
  const args = {
        cep: cep,
  };
  const ret = await makeRequest({name: "getAddressByCep", args});
    return {
        cep: ret.cep,
        street: ret.street,
        neighborhood: ret.neighborhood,
        number: ret.number,
        complement: ret.complement === null || ret.complement === undefined ? null : ret.complement,
        city: ret.city,
        state: ret.state,
        country: ret.country,
    };
}

export async function authenticationWiz(): Promise<ResponseAuthentication> {
  const ret = await makeRequest({name: "authenticationWiz", args: {}});
    return {
        tokenClientId: ret.tokenClientId,
        webAppUrl: ret.webAppUrl,
    };
}

export async function setUserPermissionsNotification(userPermissions: UserPermissionNotification): Promise<UserPermissionNotification> {
  const args = {
        userPermissions: {
            permissions: userPermissions.permissions === null || userPermissions.permissions === undefined ? null : userPermissions.permissions.map(e => e),
        },
  };
  const ret = await makeRequest({name: "setUserPermissionsNotification", args});
    return {
        permissions: ret.permissions === null || ret.permissions === undefined ? null : ret.permissions.map((e: any) => e),
    };
}

export async function getUserPermissionsNotification(userId: string): Promise<UserPermissionNotification | null> {
  const args = {
        userId: userId,
  };
  const ret = await makeRequest({name: "getUserPermissionsNotification", args});
    return ret === null || ret === undefined ? null : {
        permissions: ret.permissions === null || ret.permissions === undefined ? null : ret.permissions.map((e: any) => e),
    };
}

export async function getTransactionTypeFilter(): Promise<TransactionTypeFilter[]> {
  const ret = await makeRequest({name: "getTransactionTypeFilter", args: {}});
    return ret.map((e: any) => ({
        transactionType: e.transactionType,
        name: e.name,
    }));
}

export async function getDdaProfileStatus(documentNumber: string): Promise<DdaProfileStatus> {
  const args = {
        documentNumber: documentNumber,
  };
  const ret = await makeRequest({name: "getDdaProfileStatus", args});
    return ret;
}

export async function getTermsOfUse(): Promise<string> {
  const ret = await makeRequest({name: "getTermsOfUse", args: {}});
    return ret;
}

export async function getTermsOfService(): Promise<string> {
  const ret = await makeRequest({name: "getTermsOfService", args: {}});
    return ret;
}

export async function createActivateCodeByPhone(partnerId: string, areaCode: string, phone: string, cpf: string): Promise<void> {
  const args = {
        partnerId: partnerId,
        areaCode: areaCode,
        phone: phone,
        cpf: cpf,
  };
  await makeRequest({name: "createActivateCodeByPhone", args});
    return undefined;
}

export async function compareActivateCode(partnerId: string, cpf: string, code: number): Promise<boolean> {
  const args = {
        partnerId: partnerId,
        cpf: cpf,
        code: code | 0,
  };
  const ret = await makeRequest({name: "compareActivateCode", args});
    return !!ret;
}

export async function createWorkflowReducedJourney(info: CreateReducedJourneyAccount, images: Image[], isTerrorist: boolean, status: UserStatus, journeyType: JourneyType, compliceStatus: ComplianceStatus): Promise<string> {
  const args = {
        info: {
            partnerId: info.partnerId,
            name: info.name,
            motherName: info.motherName,
            cpf: info.cpf,
            birthDate: info.birthDate.toISOString().split("T")[0],
            gender: info.gender === null || info.gender === undefined ? null : info.gender,
            income: info.income,
            equity: info.equity,
            codeArea: info.codeArea,
            phone: info.phone,
            email: info.email,
            zipCode: info.zipCode,
            state: info.state,
            city: info.city,
            neighborhood: info.neighborhood,
            street: info.street,
            addressNumber: info.addressNumber,
            addressComplement: info.addressComplement === null || info.addressComplement === undefined ? null : info.addressComplement,
            selfie: info.selfie.toString("base64"),
            acceptTermsOfUse: !!info.acceptTermsOfUse,
            acceptContract: !!info.acceptContract,
            acceptLGPD: !!info.acceptLGPD,
            password: info.password,
            document: {
                documentType: info.document.documentType,
                imageFront: info.document.imageFront.toString("base64"),
                imageBack: info.document.imageBack === null || info.document.imageBack === undefined ? null : info.document.imageBack.toString("base64"),
            },
            ppe: info.ppe === null || info.ppe === undefined ? null : !!info.ppe,
            ppeInfo: info.ppeInfo === null || info.ppeInfo === undefined ? null : {
                relatedPpe: !!info.ppeInfo.relatedPpe,
                relatedPpeInfo: info.ppeInfo.relatedPpeInfo === null || info.ppeInfo.relatedPpeInfo === undefined ? null : {
                    name: info.ppeInfo.relatedPpeInfo.name,
                    relation: info.ppeInfo.relatedPpeInfo.relation,
                },
                start: info.ppeInfo.start.toISOString().split("T")[0],
                end: info.ppeInfo.end.toISOString().split("T")[0],
                role: info.ppeInfo.role,
            },
            fatca: info.fatca === null || info.fatca === undefined ? null : !!info.fatca,
        },
        images: images.map(e => ({
            name: e.name,
            url: e.url,
        })),
        isTerrorist: !!isTerrorist,
        status: status,
        journeyType: journeyType,
        compliceStatus: compliceStatus,
  };
  const ret = await makeRequest({name: "createWorkflowReducedJourney", args});
    return ret;
}

export async function getReceiptSchedule(actionId: string | null, schedulerId: string | null, customize: Customize): Promise<Receipt | null> {
  const args = {
        actionId: actionId === null || actionId === undefined ? null : actionId,
        schedulerId: schedulerId === null || schedulerId === undefined ? null : schedulerId,
        customize: {
            color: {
                primary: {
                    r: customize.color.primary.r | 0,
                    g: customize.color.primary.g | 0,
                    b: customize.color.primary.b | 0,
                    a: customize.color.primary.a | 0,
                },
                secondary: {
                    r: customize.color.secondary.r | 0,
                    g: customize.color.secondary.g | 0,
                    b: customize.color.secondary.b | 0,
                    a: customize.color.secondary.a | 0,
                },
                textPrimary: {
                    r: customize.color.textPrimary.r | 0,
                    g: customize.color.textPrimary.g | 0,
                    b: customize.color.textPrimary.b | 0,
                    a: customize.color.textPrimary.a | 0,
                },
                textSecondary: {
                    r: customize.color.textSecondary.r | 0,
                    g: customize.color.textSecondary.g | 0,
                    b: customize.color.textSecondary.b | 0,
                    a: customize.color.textSecondary.a | 0,
                },
                backgroundPrimary: {
                    r: customize.color.backgroundPrimary.r | 0,
                    g: customize.color.backgroundPrimary.g | 0,
                    b: customize.color.backgroundPrimary.b | 0,
                    a: customize.color.backgroundPrimary.a | 0,
                },
                backgroundSecondary: {
                    r: customize.color.backgroundSecondary.r | 0,
                    g: customize.color.backgroundSecondary.g | 0,
                    b: customize.color.backgroundSecondary.b | 0,
                    a: customize.color.backgroundSecondary.a | 0,
                },
                detail: {
                    r: customize.color.detail.r | 0,
                    g: customize.color.detail.g | 0,
                    b: customize.color.detail.b | 0,
                    a: customize.color.detail.a | 0,
                },
                creditLabel: {
                    r: customize.color.creditLabel.r | 0,
                    g: customize.color.creditLabel.g | 0,
                    b: customize.color.creditLabel.b | 0,
                    a: customize.color.creditLabel.a | 0,
                },
                debitLabel: {
                    r: customize.color.debitLabel.r | 0,
                    g: customize.color.debitLabel.g | 0,
                    b: customize.color.debitLabel.b | 0,
                    a: customize.color.debitLabel.a | 0,
                },
            },
            font: {
                name: customize.font.name,
                href: customize.font.href,
            },
        },
  };
  const ret = await makeRequest({name: "getReceiptSchedule", args});
    return ret === null || ret === undefined ? null : {
        html: ret.html,
        image: ret.image,
    };
}

export async function getReceipt(branch: string, account: string, movementNumber: string, date: Date, customize: Customize): Promise<Receipt | null> {
  const args = {
        branch: branch,
        account: account,
        movementNumber: movementNumber,
        date: date.toISOString().split("T")[0],
        customize: {
            color: {
                primary: {
                    r: customize.color.primary.r | 0,
                    g: customize.color.primary.g | 0,
                    b: customize.color.primary.b | 0,
                    a: customize.color.primary.a | 0,
                },
                secondary: {
                    r: customize.color.secondary.r | 0,
                    g: customize.color.secondary.g | 0,
                    b: customize.color.secondary.b | 0,
                    a: customize.color.secondary.a | 0,
                },
                textPrimary: {
                    r: customize.color.textPrimary.r | 0,
                    g: customize.color.textPrimary.g | 0,
                    b: customize.color.textPrimary.b | 0,
                    a: customize.color.textPrimary.a | 0,
                },
                textSecondary: {
                    r: customize.color.textSecondary.r | 0,
                    g: customize.color.textSecondary.g | 0,
                    b: customize.color.textSecondary.b | 0,
                    a: customize.color.textSecondary.a | 0,
                },
                backgroundPrimary: {
                    r: customize.color.backgroundPrimary.r | 0,
                    g: customize.color.backgroundPrimary.g | 0,
                    b: customize.color.backgroundPrimary.b | 0,
                    a: customize.color.backgroundPrimary.a | 0,
                },
                backgroundSecondary: {
                    r: customize.color.backgroundSecondary.r | 0,
                    g: customize.color.backgroundSecondary.g | 0,
                    b: customize.color.backgroundSecondary.b | 0,
                    a: customize.color.backgroundSecondary.a | 0,
                },
                detail: {
                    r: customize.color.detail.r | 0,
                    g: customize.color.detail.g | 0,
                    b: customize.color.detail.b | 0,
                    a: customize.color.detail.a | 0,
                },
                creditLabel: {
                    r: customize.color.creditLabel.r | 0,
                    g: customize.color.creditLabel.g | 0,
                    b: customize.color.creditLabel.b | 0,
                    a: customize.color.creditLabel.a | 0,
                },
                debitLabel: {
                    r: customize.color.debitLabel.r | 0,
                    g: customize.color.debitLabel.g | 0,
                    b: customize.color.debitLabel.b | 0,
                    a: customize.color.debitLabel.a | 0,
                },
            },
            font: {
                name: customize.font.name,
                href: customize.font.href,
            },
        },
  };
  const ret = await makeRequest({name: "getReceipt", args});
    return ret === null || ret === undefined ? null : {
        html: ret.html,
        image: ret.image,
    };
}

export async function getNegotiationNote(branch: string, account: string, period: Period): Promise<NegotiationNote[]> {
  const args = {
        branch: branch,
        account: account,
        period: {
            start: period.start === null || period.start === undefined ? null : period.start.toISOString().split("T")[0],
            end: period.end === null || period.end === undefined ? null : period.end.toISOString().split("T")[0],
        },
  };
  const ret = await makeRequest({name: "getNegotiationNote", args});
    return ret.map((e: any) => ({
        numOperation: e.numOperation,
        numStock: e.numStock,
        emissionDate: e.emissionDate,
        emitter: e.emitter,
        operationDate: e.operationDate,
        dueDate: e.dueDate,
        product: e.product === null || e.product === undefined ? null : e.product,
        clientName: e.clientName,
        numNote: e.numNote,
        revenueType: e.revenueType,
        puApplication: e.puApplication,
        amountApplication: e.amountApplication,
        liqTypeFin: e.liqTypeFin,
        clientPhone: e.clientPhone,
        custody: e.custody,
        withholdingIr: e.withholdingIr,
        withholdingIof: e.withholdingIof,
        grossAmount: e.grossAmount,
        liquidAmount: e.liquidAmount,
        indexer: e.indexer === null || e.indexer === undefined ? null : e.indexer,
        operationType: e.operationType,
        nominalRate: e.nominalRate === null || e.nominalRate === undefined ? null : e.nominalRate,
        commitmentFee: e.commitmentFee === null || e.commitmentFee === undefined ? null : e.commitmentFee,
        commitmentDate: e.commitmentDate === null || e.commitmentDate === undefined ? null : e.commitmentDate,
        puRecurrenceComp: e.puRecurrenceComp === null || e.puRecurrenceComp === undefined ? null : e.puRecurrenceComp,
        commitmentDueDate: e.commitmentDueDate === null || e.commitmentDueDate === undefined ? null : e.commitmentDueDate,
        recurrenceGrossAmountComp: e.recurrenceGrossAmountComp === null || e.recurrenceGrossAmountComp === undefined ? null : e.recurrenceGrossAmountComp,
        withholdingIrComp: e.withholdingIrComp === null || e.withholdingIrComp === undefined ? null : e.withholdingIrComp,
        recurrenceLiquidAmountComp: e.recurrenceLiquidAmountComp === null || e.recurrenceLiquidAmountComp === undefined ? null : e.recurrenceLiquidAmountComp,
        indexerCommitment: e.indexerCommitment === null || e.indexerCommitment === undefined ? null : e.indexerCommitment,
    }));
}

export async function getInvestmentStatement(branch: string, account: string, period: Period | null): Promise<InvestmentStatement> {
  const args = {
        branch: branch,
        account: account,
        period: period === null || period === undefined ? null : {
            start: period.start === null || period.start === undefined ? null : period.start.toISOString().split("T")[0],
            end: period.end === null || period.end === undefined ? null : period.end.toISOString().split("T")[0],
        },
  };
  const ret = await makeRequest({name: "getInvestmentStatement", args});
    return {
        clientName: ret.clientName,
        address: ret.address,
        district: ret.district,
        city: ret.city,
        state: ret.state,
        zipCode: ret.zipCode,
        accountHistory: ret.accountHistory.map((e: any) => ({
            date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
            history: e.history,
            grossMovement: e.grossMovement,
            liquidMovement: e.liquidMovement,
            grossBalance: e.grossBalance,
            liquidBalance: e.liquidBalance,
        })),
        applicationPosition: ret.applicationPosition.map((e: any) => ({
            paper: e.paper,
            active: e.active,
            emission: new Date(parseInt(e.emission.split("-")[0], 10), parseInt(e.emission.split("-")[1], 10) - 1, parseInt(e.emission.split("-")[2], 10)),
            dueDate: new Date(parseInt(e.dueDate.split("-")[0], 10), parseInt(e.dueDate.split("-")[1], 10) - 1, parseInt(e.dueDate.split("-")[2], 10)),
            emitter: e.emitter,
            tax: e.tax,
            appliedValue: e.appliedValue,
            grossValue: e.grossValue,
            ir: e.ir,
            iof: e.iof,
            liquidValue: e.liquidValue,
        })),
        withholdingIrPeriod: ret.withholdingIrPeriod === null || ret.withholdingIrPeriod === undefined ? null : ret.withholdingIrPeriod,
        withholdingIofPeriod: ret.withholdingIofPeriod === null || ret.withholdingIofPeriod === undefined ? null : ret.withholdingIofPeriod,
    };
}

export async function getHistoryInvestments(branch: string, account: string): Promise<AccountHistory[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getHistoryInvestments", args});
    return ret.map((e: any) => ({
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        history: e.history,
        grossMovement: e.grossMovement,
        liquidMovement: e.liquidMovement,
        grossBalance: e.grossBalance,
        liquidBalance: e.liquidBalance,
    }));
}

export async function getActiveInvestments(branch: string, account: string): Promise<ApplicationPosition[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getActiveInvestments", args});
    return ret.map((e: any) => ({
        paper: e.paper,
        active: e.active,
        emission: new Date(parseInt(e.emission.split("-")[0], 10), parseInt(e.emission.split("-")[1], 10) - 1, parseInt(e.emission.split("-")[2], 10)),
        dueDate: new Date(parseInt(e.dueDate.split("-")[0], 10), parseInt(e.dueDate.split("-")[1], 10) - 1, parseInt(e.dueDate.split("-")[2], 10)),
        emitter: e.emitter,
        tax: e.tax,
        appliedValue: e.appliedValue,
        grossValue: e.grossValue,
        ir: e.ir,
        iof: e.iof,
        liquidValue: e.liquidValue,
    }));
}

export async function getInvestmentBalance(branch: string, account: string): Promise<number> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getInvestmentBalance", args});
    return ret;
}

export async function getBalance(branch: string, account: string): Promise<Balance> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getBalance", args});
    return {
        available: ret.available,
        blocked: ret.blocked,
    };
}

export async function getEntryStatement(branch: string, account: string, movementNumber: string, date: Date): Promise<EntryStatement | null> {
  const args = {
        branch: branch,
        account: account,
        movementNumber: movementNumber,
        date: date.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getEntryStatement", args});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        movementNumber: ret.movementNumber,
        type: ret.type,
        description: ret.description,
        finality: ret.finality,
        nature: ret.nature,
        prevBalance: ret.prevBalance,
        currentBalance: ret.currentBalance,
        movementDate: new Date(ret.movementDate + "Z"),
        amount: ret.amount,
        part: {
            name: ret.part.name,
            cpfCnpj: ret.part.cpfCnpj === null || ret.part.cpfCnpj === undefined ? null : ret.part.cpfCnpj,
            branch: ret.part.branch,
            bank: ret.part.bank === null || ret.part.bank === undefined ? null : ret.part.bank,
            account: ret.part.account === null || ret.part.account === undefined ? null : ret.part.account,
        },
        counterPart: ret.counterPart === null || ret.counterPart === undefined ? null : {
            name: ret.counterPart.name,
            cpfCnpj: ret.counterPart.cpfCnpj === null || ret.counterPart.cpfCnpj === undefined ? null : ret.counterPart.cpfCnpj,
            branch: ret.counterPart.branch,
            bank: ret.counterPart.bank === null || ret.counterPart.bank === undefined ? null : ret.counterPart.bank,
            account: ret.counterPart.account === null || ret.counterPart.account === undefined ? null : ret.counterPart.account,
        },
        devolutionReason: ret.devolutionReason === null || ret.devolutionReason === undefined ? null : ret.devolutionReason,
        hasReceipt: !!ret.hasReceipt,
    };
}

export async function getStatement(branch: string, account: string, filter: Filter): Promise<EntryStatement[]> {
  const args = {
        branch: branch,
        account: account,
        filter: {
            period: filter.period === null || filter.period === undefined ? null : {
                start: filter.period.start === null || filter.period.start === undefined ? null : filter.period.start.toISOString().split("T")[0],
                end: filter.period.end === null || filter.period.end === undefined ? null : filter.period.end.toISOString().split("T")[0],
            },
            entryTypes: filter.entryTypes === null || filter.entryTypes === undefined ? null : filter.entryTypes.map(e => e),
            entryNature: filter.entryNature === null || filter.entryNature === undefined ? null : filter.entryNature.map(e => e),
        },
  };
  const ret = await makeRequest({name: "getStatement", args});
    return ret.map((e: any) => ({
        id: e.id,
        movementNumber: e.movementNumber,
        type: e.type,
        description: e.description,
        finality: e.finality,
        nature: e.nature,
        prevBalance: e.prevBalance,
        currentBalance: e.currentBalance,
        movementDate: new Date(e.movementDate + "Z"),
        amount: e.amount,
        part: {
            name: e.part.name,
            cpfCnpj: e.part.cpfCnpj === null || e.part.cpfCnpj === undefined ? null : e.part.cpfCnpj,
            branch: e.part.branch,
            bank: e.part.bank === null || e.part.bank === undefined ? null : e.part.bank,
            account: e.part.account === null || e.part.account === undefined ? null : e.part.account,
        },
        counterPart: e.counterPart === null || e.counterPart === undefined ? null : {
            name: e.counterPart.name,
            cpfCnpj: e.counterPart.cpfCnpj === null || e.counterPart.cpfCnpj === undefined ? null : e.counterPart.cpfCnpj,
            branch: e.counterPart.branch,
            bank: e.counterPart.bank === null || e.counterPart.bank === undefined ? null : e.counterPart.bank,
            account: e.counterPart.account === null || e.counterPart.account === undefined ? null : e.counterPart.account,
        },
        devolutionReason: e.devolutionReason === null || e.devolutionReason === undefined ? null : e.devolutionReason,
        hasReceipt: !!e.hasReceipt,
    }));
}

export async function getStatementV9(branch: string, account: string, filter: FilterStatement): Promise<EntryStatement[]> {
  const args = {
        branch: branch,
        account: account,
        filter: {
            amount: filter.amount === null || filter.amount === undefined ? null : {
                min: filter.amount.min,
                max: filter.amount.max,
            },
            period: filter.period === null || filter.period === undefined ? null : {
                start: filter.period.start === null || filter.period.start === undefined ? null : filter.period.start.toISOString().split("T")[0],
                end: filter.period.end === null || filter.period.end === undefined ? null : filter.period.end.toISOString().split("T")[0],
            },
            entryTypes: filter.entryTypes === null || filter.entryTypes === undefined ? null : filter.entryTypes.map(e => e),
            entryNature: filter.entryNature === null || filter.entryNature === undefined ? null : filter.entryNature.map(e => e),
        },
  };
  const ret = await makeRequest({name: "getStatementV9", args});
    return ret.map((e: any) => ({
        id: e.id,
        movementNumber: e.movementNumber,
        type: e.type,
        description: e.description,
        finality: e.finality,
        nature: e.nature,
        prevBalance: e.prevBalance,
        currentBalance: e.currentBalance,
        movementDate: new Date(e.movementDate + "Z"),
        amount: e.amount,
        part: {
            name: e.part.name,
            cpfCnpj: e.part.cpfCnpj === null || e.part.cpfCnpj === undefined ? null : e.part.cpfCnpj,
            branch: e.part.branch,
            bank: e.part.bank === null || e.part.bank === undefined ? null : e.part.bank,
            account: e.part.account === null || e.part.account === undefined ? null : e.part.account,
        },
        counterPart: e.counterPart === null || e.counterPart === undefined ? null : {
            name: e.counterPart.name,
            cpfCnpj: e.counterPart.cpfCnpj === null || e.counterPart.cpfCnpj === undefined ? null : e.counterPart.cpfCnpj,
            branch: e.counterPart.branch,
            bank: e.counterPart.bank === null || e.counterPart.bank === undefined ? null : e.counterPart.bank,
            account: e.counterPart.account === null || e.counterPart.account === undefined ? null : e.counterPart.account,
        },
        devolutionReason: e.devolutionReason === null || e.devolutionReason === undefined ? null : e.devolutionReason,
        hasReceipt: !!e.hasReceipt,
    }));
}

export async function getFinalities(transferType: ExternalTransferType): Promise<Finality[]> {
  const args = {
        transferType: transferType,
  };
  const ret = await makeRequest({name: "getFinalities", args});
    return ret.map((e: any) => ({
        code: e.code,
        description: e.description,
    }));
}

export async function getYieldReport(documentNumber: string, year: string): Promise<YieldReport[]> {
  const args = {
        documentNumber: documentNumber,
        year: year,
  };
  const ret = await makeRequest({name: "getYieldReport", args});
    return ret.map((e: any) => ({
        companyName: e.companyName === null || e.companyName === undefined ? null : e.companyName,
        year: e.year,
        graphicNature: e.graphicNature,
        nameHolder: e.nameHolder,
        cpfHolder: e.cpfHolder,
        nameSecondHolder: e.nameSecondHolder === null || e.nameSecondHolder === undefined ? null : e.nameSecondHolder,
        cpfSecondHolder: e.cpfSecondHolder === null || e.cpfSecondHolder === undefined ? null : e.cpfSecondHolder,
        address: e.address,
        number: e.number === null || e.number === undefined ? null : e.number,
        complement: e.complement === null || e.complement === undefined ? null : e.complement,
        neighborhood: e.neighborhood,
        city: e.city,
        state: e.state,
        zipCode: e.zipCode,
        companyAddress: e.companyAddress,
        companyNumber: e.companyNumber === null || e.companyNumber === undefined ? null : e.companyNumber,
        companyComplement: e.companyComplement === null || e.companyComplement === undefined ? null : e.companyComplement,
        companyNeighborhood: e.companyNeighborhood === null || e.companyNeighborhood === undefined ? null : e.companyNeighborhood,
        companyCity: e.companyCity,
        companyState: e.companyState,
        companyZipCode: e.companyZipCode,
        declarantCompany: e.declarantCompany,
        cnpjDeclarantCompany: e.cnpjDeclarantCompany,
        declarantInfo: e.declarantInfo === null || e.declarantInfo === undefined ? null : e.declarantInfo,
        prevBalance: e.prevBalance,
        currentBalance: e.currentBalance,
        total: e.total,
    }));
}

export async function getLastStatement(branch: string, account: string): Promise<EntryStatement> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getLastStatement", args});
    return {
        id: ret.id,
        movementNumber: ret.movementNumber,
        type: ret.type,
        description: ret.description,
        finality: ret.finality,
        nature: ret.nature,
        prevBalance: ret.prevBalance,
        currentBalance: ret.currentBalance,
        movementDate: new Date(ret.movementDate + "Z"),
        amount: ret.amount,
        part: {
            name: ret.part.name,
            cpfCnpj: ret.part.cpfCnpj === null || ret.part.cpfCnpj === undefined ? null : ret.part.cpfCnpj,
            branch: ret.part.branch,
            bank: ret.part.bank === null || ret.part.bank === undefined ? null : ret.part.bank,
            account: ret.part.account === null || ret.part.account === undefined ? null : ret.part.account,
        },
        counterPart: ret.counterPart === null || ret.counterPart === undefined ? null : {
            name: ret.counterPart.name,
            cpfCnpj: ret.counterPart.cpfCnpj === null || ret.counterPart.cpfCnpj === undefined ? null : ret.counterPart.cpfCnpj,
            branch: ret.counterPart.branch,
            bank: ret.counterPart.bank === null || ret.counterPart.bank === undefined ? null : ret.counterPart.bank,
            account: ret.counterPart.account === null || ret.counterPart.account === undefined ? null : ret.counterPart.account,
        },
        devolutionReason: ret.devolutionReason === null || ret.devolutionReason === undefined ? null : ret.devolutionReason,
        hasReceipt: !!ret.hasReceipt,
    };
}

export async function getBanks(): Promise<Bank[]> {
  const ret = await makeRequest({name: "getBanks", args: {}});
    return ret.map((e: any) => ({
        code: e.code,
        name: e.name,
    }));
}

export async function getBankByCode(code: string): Promise<Bank> {
  const args = {
        code: code,
  };
  const ret = await makeRequest({name: "getBankByCode", args});
    return {
        code: ret.code,
        name: ret.name,
    };
}

export async function checkBankSlip(branch: string, barcode: string): Promise<BankSlipInfo> {
  const args = {
        branch: branch,
        barcode: barcode,
  };
  const ret = await makeRequest({name: "checkBankSlip", args});
    return {
        payerDocument: ret.payerDocument === null || ret.payerDocument === undefined ? null : ret.payerDocument,
        beneficiaryBusinessName: ret.beneficiaryBusinessName === null || ret.beneficiaryBusinessName === undefined ? null : ret.beneficiaryBusinessName,
        beneficiaryDocument: ret.beneficiaryDocument === null || ret.beneficiaryDocument === undefined ? null : ret.beneficiaryDocument,
        totalCharges: ret.totalCharges === null || ret.totalCharges === undefined ? null : ret.totalCharges,
        digitableLine: ret.digitableLine === null || ret.digitableLine === undefined ? null : ret.digitableLine,
        discountByDate: ret.discountByDate === null || ret.discountByDate === undefined ? null : ret.discountByDate.map((e: any) => ({
            date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
            discount: e.discount,
            amount: e.amount,
        })),
        maxPayDate: ret.maxPayDate === null || ret.maxPayDate === undefined ? null : new Date(parseInt(ret.maxPayDate.split("-")[0], 10), parseInt(ret.maxPayDate.split("-")[1], 10) - 1, parseInt(ret.maxPayDate.split("-")[2], 10)),
        code: ret.code,
        beneficiary: ret.beneficiary === null || ret.beneficiary === undefined ? null : ret.beneficiary,
        payer: ret.payer === null || ret.payer === undefined ? null : ret.payer,
        organization: ret.organization === null || ret.organization === undefined ? null : ret.organization,
        dueDate: ret.dueDate === null || ret.dueDate === undefined ? null : new Date(parseInt(ret.dueDate.split("-")[0], 10), parseInt(ret.dueDate.split("-")[1], 10) - 1, parseInt(ret.dueDate.split("-")[2], 10)),
        payDate: ret.payDate === null || ret.payDate === undefined ? null : new Date(parseInt(ret.payDate.split("-")[0], 10), parseInt(ret.payDate.split("-")[1], 10) - 1, parseInt(ret.payDate.split("-")[2], 10)),
        amount: ret.amount,
        paymentType: ret.paymentType,
        baseAmount: ret.baseAmount,
        interest: ret.interest === null || ret.interest === undefined ? null : ret.interest,
        penalty: ret.penalty === null || ret.penalty === undefined ? null : ret.penalty,
        discount: ret.discount === null || ret.discount === undefined ? null : ret.discount,
    };
}

export async function checkBankSlipV9(branch: string, barcode: string): Promise<BankSlipInfoV9> {
  const args = {
        branch: branch,
        barcode: barcode,
  };
  const ret = await makeRequest({name: "checkBankSlipV9", args});
    return {
        code: ret.code,
        beneficiary: ret.beneficiary === null || ret.beneficiary === undefined ? null : ret.beneficiary,
        payer: ret.payer === null || ret.payer === undefined ? null : ret.payer,
        organization: ret.organization === null || ret.organization === undefined ? null : ret.organization,
        dueDate: ret.dueDate === null || ret.dueDate === undefined ? null : new Date(parseInt(ret.dueDate.split("-")[0], 10), parseInt(ret.dueDate.split("-")[1], 10) - 1, parseInt(ret.dueDate.split("-")[2], 10)),
        payDate: ret.payDate === null || ret.payDate === undefined ? null : new Date(parseInt(ret.payDate.split("-")[0], 10), parseInt(ret.payDate.split("-")[1], 10) - 1, parseInt(ret.payDate.split("-")[2], 10)),
        amount: ret.amount,
        paymentType: ret.paymentType,
        baseAmount: ret.baseAmount,
        interest: ret.interest === null || ret.interest === undefined ? null : ret.interest,
        penalty: ret.penalty === null || ret.penalty === undefined ? null : ret.penalty,
        discount: ret.discount === null || ret.discount === undefined ? null : ret.discount,
    };
}

export async function checkAgreement(digitableLine: string): Promise<BankSlipInfo> {
  const args = {
        digitableLine: digitableLine,
  };
  const ret = await makeRequest({name: "checkAgreement", args});
    return {
        payerDocument: ret.payerDocument === null || ret.payerDocument === undefined ? null : ret.payerDocument,
        beneficiaryBusinessName: ret.beneficiaryBusinessName === null || ret.beneficiaryBusinessName === undefined ? null : ret.beneficiaryBusinessName,
        beneficiaryDocument: ret.beneficiaryDocument === null || ret.beneficiaryDocument === undefined ? null : ret.beneficiaryDocument,
        totalCharges: ret.totalCharges === null || ret.totalCharges === undefined ? null : ret.totalCharges,
        digitableLine: ret.digitableLine === null || ret.digitableLine === undefined ? null : ret.digitableLine,
        discountByDate: ret.discountByDate === null || ret.discountByDate === undefined ? null : ret.discountByDate.map((e: any) => ({
            date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
            discount: e.discount,
            amount: e.amount,
        })),
        maxPayDate: ret.maxPayDate === null || ret.maxPayDate === undefined ? null : new Date(parseInt(ret.maxPayDate.split("-")[0], 10), parseInt(ret.maxPayDate.split("-")[1], 10) - 1, parseInt(ret.maxPayDate.split("-")[2], 10)),
        code: ret.code,
        beneficiary: ret.beneficiary === null || ret.beneficiary === undefined ? null : ret.beneficiary,
        payer: ret.payer === null || ret.payer === undefined ? null : ret.payer,
        organization: ret.organization === null || ret.organization === undefined ? null : ret.organization,
        dueDate: ret.dueDate === null || ret.dueDate === undefined ? null : new Date(parseInt(ret.dueDate.split("-")[0], 10), parseInt(ret.dueDate.split("-")[1], 10) - 1, parseInt(ret.dueDate.split("-")[2], 10)),
        payDate: ret.payDate === null || ret.payDate === undefined ? null : new Date(parseInt(ret.payDate.split("-")[0], 10), parseInt(ret.payDate.split("-")[1], 10) - 1, parseInt(ret.payDate.split("-")[2], 10)),
        amount: ret.amount,
        paymentType: ret.paymentType,
        baseAmount: ret.baseAmount,
        interest: ret.interest === null || ret.interest === undefined ? null : ret.interest,
        penalty: ret.penalty === null || ret.penalty === undefined ? null : ret.penalty,
        discount: ret.discount === null || ret.discount === undefined ? null : ret.discount,
    };
}

export async function checkAgreementV9(digitableLine: string): Promise<BankSlipInfoV9> {
  const args = {
        digitableLine: digitableLine,
  };
  const ret = await makeRequest({name: "checkAgreementV9", args});
    return {
        code: ret.code,
        beneficiary: ret.beneficiary === null || ret.beneficiary === undefined ? null : ret.beneficiary,
        payer: ret.payer === null || ret.payer === undefined ? null : ret.payer,
        organization: ret.organization === null || ret.organization === undefined ? null : ret.organization,
        dueDate: ret.dueDate === null || ret.dueDate === undefined ? null : new Date(parseInt(ret.dueDate.split("-")[0], 10), parseInt(ret.dueDate.split("-")[1], 10) - 1, parseInt(ret.dueDate.split("-")[2], 10)),
        payDate: ret.payDate === null || ret.payDate === undefined ? null : new Date(parseInt(ret.payDate.split("-")[0], 10), parseInt(ret.payDate.split("-")[1], 10) - 1, parseInt(ret.payDate.split("-")[2], 10)),
        amount: ret.amount,
        paymentType: ret.paymentType,
        baseAmount: ret.baseAmount,
        interest: ret.interest === null || ret.interest === undefined ? null : ret.interest,
        penalty: ret.penalty === null || ret.penalty === undefined ? null : ret.penalty,
        discount: ret.discount === null || ret.discount === undefined ? null : ret.discount,
    };
}

export async function exportStatement(branch: string, account: string, filter: Filter): Promise<Buffer> {
  const args = {
        branch: branch,
        account: account,
        filter: {
            period: filter.period === null || filter.period === undefined ? null : {
                start: filter.period.start === null || filter.period.start === undefined ? null : filter.period.start.toISOString().split("T")[0],
                end: filter.period.end === null || filter.period.end === undefined ? null : filter.period.end.toISOString().split("T")[0],
            },
            entryTypes: filter.entryTypes === null || filter.entryTypes === undefined ? null : filter.entryTypes.map(e => e),
            entryNature: filter.entryNature === null || filter.entryNature === undefined ? null : filter.entryNature.map(e => e),
        },
  };
  const ret = await makeRequest({name: "exportStatement", args});
    return Buffer.from(ret, "base64");
}

export async function createSelfInvoice(branch: string, account: string, value: number, location: LatLng | null): Promise<BankSlip> {
  const args = {
        branch: branch,
        account: account,
        value: value,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "createSelfInvoice", args});
    return {
        nameBank: ret.nameBank,
        codeBankDV: ret.codeBankDV,
        digitableLine: ret.digitableLine,
        codigoDeBarras: ret.codigoDeBarras,
        paymentPlace: ret.paymentPlace,
        dueDate: new Date(ret.dueDate + "Z"),
        beneficiary: {
            name: ret.beneficiary.name,
            agencyCode: ret.beneficiary.agencyCode,
        },
        documentDate: new Date(ret.documentDate + "Z"),
        documentNumber: ret.documentNumber,
        documentSpecies: ret.documentSpecies,
        accept: ret.accept,
        proccessDate: new Date(ret.proccessDate + "Z"),
        ourNumber: ret.ourNumber,
        walet: ret.walet,
        specie: ret.specie,
        documentValue: ret.documentValue,
        instructions: ret.instructions,
        payer: {
            name: ret.payer.name,
            documentNumber: ret.payer.documentNumber,
            address: {
                city: ret.payer.address.city,
                state: ret.payer.address.state,
                street: ret.payer.address.street,
                postalCode: ret.payer.address.postalCode,
                neighborhood: ret.payer.address.neighborhood,
            },
        },
        barCode: ret.barCode,
        clientCode: ret.clientCode,
        yourNumber: ret.yourNumber,
        typePerson: ret.typePerson,
        link: ret.link,
    };
}

export async function getCities(uf: string, name: string | null): Promise<City[]> {
  const args = {
        uf: uf,
        name: name === null || name === undefined ? null : name,
  };
  const ret = await makeRequest({name: "getCities", args});
    return ret.map((e: any) => ({
        code: e.code,
        name: e.name,
    }));
}

export async function getStates(): Promise<State[]> {
  const ret = await makeRequest({name: "getStates", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        name: e.name,
    }));
}

export async function sendEmailToPDF(digitableLine: string): Promise<boolean> {
  const args = {
        digitableLine: digitableLine,
  };
  const ret = await makeRequest({name: "sendEmailToPDF", args});
    return !!ret;
}

export async function getLoteriesByLocation(location: LatLng): Promise<Lotery[]> {
  const args = {
        location: {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "getLoteriesByLocation", args});
    return ret.map((e: any) => ({
        name: e.name,
        address: e.address,
        district: e.district,
        number: e.number === null || e.number === undefined ? null : e.number,
        complement: e.complement === null || e.complement === undefined ? null : e.complement,
        city: e.city,
        state: e.state,
        postalCode: e.postalCode,
        location: {
            lat: e.location.lat,
            lng: e.location.lng,
        },
    }));
}

export async function getOperator(ddd: number): Promise<Operator[]> {
  const args = {
        ddd: ddd | 0,
  };
  const ret = await makeRequest({name: "getOperator", args});
    return ret.map((e: any) => ({
        operatorId: e.operatorId | 0,
        name: e.name,
        valueMin: e.valueMin,
        valueMax: e.valueMax,
        categoryRecharge: e.categoryRecharge,
        typeRecharge: e.typeRecharge,
    }));
}

export async function getRecentRecharge(limit: number): Promise<Recharge[]> {
  const args = {
        limit: limit | 0,
  };
  const ret = await makeRequest({name: "getRecentRecharge", args});
    return ret.map((e: any) => ({
        ddi: e.ddi | 0,
        ddd: e.ddd | 0,
        numberPhone: e.numberPhone | 0,
    }));
}

export async function getOperatorValues(ddd: number, operatorId: number): Promise<OperatorValues[]> {
  const args = {
        ddd: ddd | 0,
        operatorId: operatorId | 0,
  };
  const ret = await makeRequest({name: "getOperatorValues", args});
    return ret.map((e: any) => ({
        valueMin: e.valueMin,
        valueMax: e.valueMax,
        costRecharge: e.costRecharge,
        detailProduct: e.detailProduct,
        nameProduct: e.nameProduct,
        validityProduct: e.validityProduct | 0,
        valueBonus: e.valueBonus,
    }));
}

export async function operatorPay(model: OperatorPay): Promise<boolean> {
  const args = {
        model: {
            operatorId: model.operatorId | 0,
            ddi: model.ddi | 0,
            ddd: model.ddd | 0,
            numberPhone: model.numberPhone | 0,
            value: model.value,
            branch: model.branch,
            account: model.account,
        },
  };
  const ret = await makeRequest({name: "operatorPay", args});
    return !!ret;
}

export async function getMonthlyInvestmentBalance(branch: string, account: string, period: Period | null): Promise<MonthlyInvestmentBalance[]> {
  const args = {
        branch: branch,
        account: account,
        period: period === null || period === undefined ? null : {
            start: period.start === null || period.start === undefined ? null : period.start.toISOString().split("T")[0],
            end: period.end === null || period.end === undefined ? null : period.end.toISOString().split("T")[0],
        },
  };
  const ret = await makeRequest({name: "getMonthlyInvestmentBalance", args});
    return ret.map((e: any) => ({
        month: e.month | 0,
        balance: e.balance,
    }));
}

export async function getShops(address: string, radius: number): Promise<Shop[]> {
  const args = {
        address: address,
        radius: radius | 0,
  };
  const ret = await makeRequest({name: "getShops", args});
    return ret.map((e: any) => ({
        name: e.name,
        code: e.code,
        address: e.address,
        city: e.city,
        latitude: e.latitude,
        longitude: e.longitude,
        distance: e.distance,
        cashBackPercLoy: e.cashBackPercLoy,
        descErpCategory: e.descErpCategory,
        codeShop: e.codeShop,
        codeCategory: e.codeCategory,
        codeMacroCategory: e.codeMacroCategory,
        active: !!e.active,
        onLine: !!e.onLine,
    }));
}

export async function getPreRegister(documentNumber: string): Promise<PreRegister> {
  const args = {
        documentNumber: documentNumber,
  };
  const ret = await makeRequest({name: "getPreRegister", args});
    return {
        createdAt: ret.createdAt,
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : ret.updatedAt,
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : ret.deletedAt,
        documentNumber: ret.documentNumber,
        street: ret.street,
        streetNumber: ret.streetNumber,
        district: ret.district,
        province: ret.province,
        state: ret.state,
        country: ret.country,
        complement: ret.complement === null || ret.complement === undefined ? null : ret.complement,
        zipCode: ret.zipCode,
    };
}

export async function getElectronicTitles(filter: ElectronicTitleFilter, cnpj: string | null): Promise<ElectronicTitle[]> {
  const args = {
        filter: {
            payersDocuments: filter.payersDocuments === null || filter.payersDocuments === undefined ? null : filter.payersDocuments.map(e => e),
            initialDueDate: filter.initialDueDate === null || filter.initialDueDate === undefined ? null : filter.initialDueDate.toISOString().split("T")[0],
            endDueDate: filter.endDueDate === null || filter.endDueDate === undefined ? null : filter.endDueDate.toISOString().split("T")[0],
            minValue: filter.minValue === null || filter.minValue === undefined ? null : filter.minValue,
            maxValue: filter.maxValue === null || filter.maxValue === undefined ? null : filter.maxValue,
            situation: filter.situation === null || filter.situation === undefined ? null : filter.situation.map(e => e),
        },
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
  };
  const ret = await makeRequest({name: "getElectronicTitles", args});
    return ret.map((e: any) => ({
        digitableLine: e.digitableLine,
        barCode: e.barCode,
        payerDocument: e.payerDocument,
        payerName: e.payerName,
        recipientDocument: e.recipientDocument,
        recipientName: e.recipientName,
        bankCode: e.bankCode,
        bankName: e.bankName,
        nominalValue: e.nominalValue,
        charges: e.charges === null || e.charges === undefined ? null : e.charges,
        discounts: e.discounts === null || e.discounts === undefined ? null : e.discounts.map((e: any) => ({
            discount: e.discount === null || e.discount === undefined ? null : e.discount,
            date: e.date === null || e.date === undefined ? null : new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        })),
        paymentMethod: e.paymentMethod === null || e.paymentMethod === undefined ? null : e.paymentMethod,
        aggregate: !!e.aggregate,
        situation: e.situation,
        dueDate: new Date(parseInt(e.dueDate.split("-")[0], 10), parseInt(e.dueDate.split("-")[1], 10) - 1, parseInt(e.dueDate.split("-")[2], 10)),
        guarantorSocialReason: e.guarantorSocialReason === null || e.guarantorSocialReason === undefined ? null : e.guarantorSocialReason,
        guarantorDocument: e.guarantorDocument === null || e.guarantorDocument === undefined ? null : e.guarantorDocument,
        recipientFantasyName: e.recipientFantasyName === null || e.recipientFantasyName === undefined ? null : e.recipientFantasyName,
        titleDocument: e.titleDocument === null || e.titleDocument === undefined ? null : e.titleDocument,
    }));
}

export async function getEmailsOnAssertivaByDocument(documentNumber: string): Promise<PersonEmails> {
  const args = {
        documentNumber: documentNumber,
  };
  const ret = await makeRequest({name: "getEmailsOnAssertivaByDocument", args});
    return {
        name: ret.name,
        emails: ret.emails.map((e: any) => e),
    };
}

export async function isDdaProfileActive(cpfCnpj: string): Promise<boolean> {
  const args = {
        cpfCnpj: cpfCnpj,
  };
  const ret = await makeRequest({name: "isDdaProfileActive", args});
    return !!ret;
}

export async function getDdaProfileAggregate(cnpjAggregator: string | null, cpfCnpjAggregate: string | null): Promise<DdaProfileAggregate[]> {
  const args = {
        cnpjAggregator: cnpjAggregator === null || cnpjAggregator === undefined ? null : cnpjAggregator,
        cpfCnpjAggregate: cpfCnpjAggregate === null || cpfCnpjAggregate === undefined ? null : cpfCnpjAggregate,
  };
  const ret = await makeRequest({name: "getDdaProfileAggregate", args});
    return ret.map((e: any) => ({
        documentNumber: e.documentNumber,
        document: e.document,
        email: e.email === null || e.email === undefined ? null : e.email,
        registeredBy: e.registeredBy,
        isActive: !!e.isActive,
        activatedAt: e.activatedAt === null || e.activatedAt === undefined ? null : new Date(e.activatedAt + "Z"),
        canceledAt: e.canceledAt === null || e.canceledAt === undefined ? null : new Date(e.canceledAt + "Z"),
        documentNumberAggregator: e.documentNumberAggregator,
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
    }));
}

export async function requestIncludeDDAElectronicPayer(cnpj: string | null): Promise<string> {
  const args = {
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
  };
  const ret = await makeRequest({name: "requestIncludeDDAElectronicPayer", args});
    return ret;
}

export async function requestCancellationDDAElectronicPayer(cpfCnpjAggregate: string): Promise<string> {
  const args = {
        cpfCnpjAggregate: cpfCnpjAggregate,
  };
  const ret = await makeRequest({name: "requestCancellationDDAElectronicPayer", args});
    return ret;
}

export async function requestCancellationDDAElectronicPayerAggregate(cpfCnpjAggregate: string, cnpj: string | null): Promise<string> {
  const args = {
        cpfCnpjAggregate: cpfCnpjAggregate,
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
  };
  const ret = await makeRequest({name: "requestCancellationDDAElectronicPayerAggregate", args});
    return ret;
}

export async function requestIncludeDDAElectronicPayerAggregate(cpfCnpjAggregate: string, emailAggregate: string, cnpj: string | null): Promise<string> {
  const args = {
        cpfCnpjAggregate: cpfCnpjAggregate,
        emailAggregate: emailAggregate,
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
  };
  const ret = await makeRequest({name: "requestIncludeDDAElectronicPayerAggregate", args});
    return ret;
}

export async function createIncludeDDAElectronicPayerAggregate(cpfCnpjAggregate: string, emailAggregate: string, cnpj: string | null, password4: string, sign: Signature, location: LatLng | null): Promise<boolean> {
  const args = {
        cpfCnpjAggregate: cpfCnpjAggregate,
        emailAggregate: emailAggregate,
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
        password4: password4,
        sign: {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "createIncludeDDAElectronicPayerAggregate", args});
    return !!ret;
}

export async function verifyDdaAggregateRequest(actionId: string): Promise<boolean> {
  const args = {
        actionId: actionId,
  };
  const ret = await makeRequest({name: "verifyDdaAggregateRequest", args});
    return !!ret;
}

export async function applyDdaAggregateRequest(userAction: boolean, documentNumber: string | null, actionId: string): Promise<string> {
  const args = {
        userAction: !!userAction,
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
        actionId: actionId,
  };
  const ret = await makeRequest({name: "applyDdaAggregateRequest", args});
    return ret;
}

export async function createIncludeDDAElectronicPayer(cpfCnpj: string, password4: string, sign: Signature, location: LatLng | null): Promise<void> {
  const args = {
        cpfCnpj: cpfCnpj,
        password4: password4,
        sign: {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "createIncludeDDAElectronicPayer", args});
    return undefined;
}

export async function createCancellationDDAElectronicPayer(cpfCnpj: string, password4: string, sign: Signature, location: LatLng | null): Promise<void> {
  const args = {
        cpfCnpj: cpfCnpj,
        password4: password4,
        sign: {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "createCancellationDDAElectronicPayer", args});
    return undefined;
}

export async function createCancellationDDAElectronicPayerAggregate(cpfCnpjAggregate: string, cpfCnpj: string, password4: string, sign: Signature, location: LatLng | null): Promise<void> {
  const args = {
        cpfCnpjAggregate: cpfCnpjAggregate,
        cpfCnpj: cpfCnpj,
        password4: password4,
        sign: {
            signature: sign.signature,
            jsonObject: sign.jsonObject,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  await makeRequest({name: "createCancellationDDAElectronicPayerAggregate", args});
    return undefined;
}

export async function getUserLimits(cpfCnpj: string, branch: string, account: string, partnerId: string | null): Promise<UserLimits[]> {
  const args = {
        cpfCnpj: cpfCnpj,
        branch: branch,
        account: account,
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getUserLimits", args});
    return ret.map((e: any) => ({
        operationType: e.operationType,
        dailyLimit: e.dailyLimit,
        dailyLimitAvailable: e.dailyLimitAvailable,
        operationLimit: e.operationLimit,
        amountPerDay: e.amountPerDay,
        amountPerDayAvailable: e.amountPerDayAvailable,
    }));
}

export async function getAccessToken(): Promise<string> {
  const ret = await makeRequest({name: "getAccessToken", args: {}});
    return ret;
}

export async function getInfoCaixaWithdraw(branch: string, account: string): Promise<InfoWithdraw> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getInfoCaixaWithdraw", args});
    return {
        limit: ret.limit,
        maxWithdraw: ret.maxWithdraw,
        availableWithdraw: ret.availableWithdraw,
        limitDay: ret.limitDay,
        withdrawEnabled: !!ret.withdrawEnabled,
        withdrawAmountAvailable: ret.withdrawAmountAvailable,
    };
}

export async function requestCaixaWithdraw(branch: string, account: string, amount: number, location: LatLng | null): Promise<string> {
  const args = {
        branch: branch,
        account: account,
        amount: amount,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestCaixaWithdraw", args});
    return ret;
}

export async function requestTransferV9(transfer: TransferV9, saveFavored: boolean, location: LatLng | null): Promise<string> {
  const args = {
        transfer: {
            date: (typeof transfer.date === "string" && (transfer.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (transfer.date as any).replace("Z", "") : transfer.date.toISOString().replace("Z", "")),
            description: transfer.description === null || transfer.description === undefined ? null : transfer.description,
            amount: transfer.amount,
            finality: transfer.finality === null || transfer.finality === undefined ? null : transfer.finality,
            type: transfer.type,
            from: {
                branch: transfer.from.branch,
                account: transfer.from.account,
            },
            to: {
                bank: {
                    code: transfer.to.bank.code,
                    name: transfer.to.bank.name,
                },
                branch: transfer.to.branch,
                account: transfer.to.account,
                cpfCnpj: transfer.to.cpfCnpj === null || transfer.to.cpfCnpj === undefined ? null : transfer.to.cpfCnpj,
                name: transfer.to.name,
                type: transfer.to.type,
            },
            recurrent: transfer.recurrent === null || transfer.recurrent === undefined ? null : transfer.recurrent | 0,
        },
        saveFavored: !!saveFavored,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestTransferV9", args});
    return ret;
}

export async function requestTransfer(transfer: OldTransfer, saveFavored: boolean, location: LatLng | null): Promise<string> {
  const args = {
        transfer: {
            date: (typeof transfer.date === "string" && (transfer.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (transfer.date as any).replace("Z", "") : transfer.date.toISOString().replace("Z", "")),
            description: transfer.description === null || transfer.description === undefined ? null : transfer.description,
            amount: transfer.amount,
            finality: transfer.finality === null || transfer.finality === undefined ? null : transfer.finality,
            type: transfer.type,
            from: {
                branch: transfer.from.branch,
                account: transfer.from.account,
            },
            to: {
                bank: transfer.to.bank,
                branch: transfer.to.branch,
                account: transfer.to.account,
                cpfCnpj: transfer.to.cpfCnpj === null || transfer.to.cpfCnpj === undefined ? null : transfer.to.cpfCnpj,
                name: transfer.to.name,
                type: transfer.to.type,
            },
            recurrent: transfer.recurrent === null || transfer.recurrent === undefined ? null : transfer.recurrent | 0,
        },
        saveFavored: !!saveFavored,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestTransfer", args});
    return ret;
}

export async function requestPayment(payment: PaymentSlip, location: LatLng | null): Promise<string> {
  const args = {
        payment: {
            branch: payment.branch,
            account: payment.account,
            barcode: payment.barcode,
            date: (typeof payment.date === "string" && (payment.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (payment.date as any).replace("Z", "") : payment.date.toISOString().replace("Z", "")),
            dueDateTime: payment.dueDateTime === null || payment.dueDateTime === undefined ? null : (typeof payment.dueDateTime === "string" && (payment.dueDateTime as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (payment.dueDateTime as any).replace("Z", "") : payment.dueDateTime.toISOString().replace("Z", "")),
            description: payment.description,
            type: payment.type,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestPayment", args});
    return ret;
}

export async function requestPaymentV9(payment: PaymentSlip, amount: number, location: LatLng | null): Promise<string> {
  const args = {
        payment: {
            branch: payment.branch,
            account: payment.account,
            barcode: payment.barcode,
            date: (typeof payment.date === "string" && (payment.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (payment.date as any).replace("Z", "") : payment.date.toISOString().replace("Z", "")),
            dueDateTime: payment.dueDateTime === null || payment.dueDateTime === undefined ? null : (typeof payment.dueDateTime === "string" && (payment.dueDateTime as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (payment.dueDateTime as any).replace("Z", "") : payment.dueDateTime.toISOString().replace("Z", "")),
            description: payment.description,
            type: payment.type,
        },
        amount: amount,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestPaymentV9", args});
    return ret;
}

export async function requestRecharge(recharge: OperatorPay, location: LatLng | null): Promise<string> {
  const args = {
        recharge: {
            operatorId: recharge.operatorId | 0,
            ddi: recharge.ddi | 0,
            ddd: recharge.ddd | 0,
            numberPhone: recharge.numberPhone | 0,
            value: recharge.value,
            branch: recharge.branch,
            account: recharge.account,
        },
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestRecharge", args});
    return ret;
}

export async function requestInvestment(investment: Investment): Promise<string> {
  const args = {
        investment: {
            amount: investment.amount,
            account: investment.account,
            branch: investment.branch,
        },
  };
  const ret = await makeRequest({name: "requestInvestment", args});
    return ret;
}

export async function getAction(id: string, customize: Customize): Promise<Action> {
  const args = {
        id: id,
        customize: {
            color: {
                primary: {
                    r: customize.color.primary.r | 0,
                    g: customize.color.primary.g | 0,
                    b: customize.color.primary.b | 0,
                    a: customize.color.primary.a | 0,
                },
                secondary: {
                    r: customize.color.secondary.r | 0,
                    g: customize.color.secondary.g | 0,
                    b: customize.color.secondary.b | 0,
                    a: customize.color.secondary.a | 0,
                },
                textPrimary: {
                    r: customize.color.textPrimary.r | 0,
                    g: customize.color.textPrimary.g | 0,
                    b: customize.color.textPrimary.b | 0,
                    a: customize.color.textPrimary.a | 0,
                },
                textSecondary: {
                    r: customize.color.textSecondary.r | 0,
                    g: customize.color.textSecondary.g | 0,
                    b: customize.color.textSecondary.b | 0,
                    a: customize.color.textSecondary.a | 0,
                },
                backgroundPrimary: {
                    r: customize.color.backgroundPrimary.r | 0,
                    g: customize.color.backgroundPrimary.g | 0,
                    b: customize.color.backgroundPrimary.b | 0,
                    a: customize.color.backgroundPrimary.a | 0,
                },
                backgroundSecondary: {
                    r: customize.color.backgroundSecondary.r | 0,
                    g: customize.color.backgroundSecondary.g | 0,
                    b: customize.color.backgroundSecondary.b | 0,
                    a: customize.color.backgroundSecondary.a | 0,
                },
                detail: {
                    r: customize.color.detail.r | 0,
                    g: customize.color.detail.g | 0,
                    b: customize.color.detail.b | 0,
                    a: customize.color.detail.a | 0,
                },
                creditLabel: {
                    r: customize.color.creditLabel.r | 0,
                    g: customize.color.creditLabel.g | 0,
                    b: customize.color.creditLabel.b | 0,
                    a: customize.color.creditLabel.a | 0,
                },
                debitLabel: {
                    r: customize.color.debitLabel.r | 0,
                    g: customize.color.debitLabel.g | 0,
                    b: customize.color.debitLabel.b | 0,
                    a: customize.color.debitLabel.a | 0,
                },
            },
            font: {
                name: customize.font.name,
                href: customize.font.href,
            },
        },
  };
  const ret = await makeRequest({name: "getAction", args});
    return {
        id: ret.id,
        account: ret.account,
        branch: ret.branch,
        requestedBy: ret.requestedBy,
        approvedBy: ret.approvedBy === null || ret.approvedBy === undefined ? null : ret.approvedBy,
        disapprovedBy: ret.disapprovedBy === null || ret.disapprovedBy === undefined ? null : ret.disapprovedBy,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        body: ret.body,
        html: ret.html,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        expired: !!ret.expired,
        type: ret.type,
        movementNumber: ret.movementNumber === null || ret.movementNumber === undefined ? null : ret.movementNumber,
        originAccount: {
            name: ret.originAccount.name,
            type: ret.originAccount.type,
            documentNumber: ret.originAccount.documentNumber,
            permissions: ret.originAccount.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: ret.originAccount.maxAmountDaily === null || ret.originAccount.maxAmountDaily === undefined ? null : ret.originAccount.maxAmountDaily,
            yourNumber: ret.originAccount.yourNumber | 0,
            subType: ret.originAccount.subType === null || ret.originAccount.subType === undefined ? null : ret.originAccount.subType,
            bank: {
                code: ret.originAccount.bank.code,
                name: ret.originAccount.bank.name,
            },
            branch: ret.originAccount.branch,
            account: ret.originAccount.account,
            createdAt: new Date(ret.originAccount.createdAt + "Z"),
        },
        schedule: !!ret.schedule,
    };
}

export async function getFavored(branch: string, account: string): Promise<Favored[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getFavored", args});
    return ret.map((e: any) => ({
        name: e.name,
        documentNumber: e.documentNumber,
        accounts: e.accounts.map((e: any) => ({
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            type: e.type,
        })),
        image: e.image === null || e.image === undefined ? null : e.image,
    }));
}

export async function removeFavoredByAccount(documentNumber: string, branch: string, account: string, removedFavored: RemovedFavored): Promise<boolean> {
  const args = {
        documentNumber: documentNumber,
        branch: branch,
        account: account,
        removedFavored: {
            branch: removedFavored.branch,
            account: removedFavored.account,
            bankCode: removedFavored.bankCode,
        },
  };
  const ret = await makeRequest({name: "removeFavoredByAccount", args});
    return !!ret;
}

export async function removeFavoredByDocumentNumber(branch: string, account: string, documentNumber: string): Promise<boolean> {
  const args = {
        branch: branch,
        account: account,
        documentNumber: documentNumber,
  };
  const ret = await makeRequest({name: "removeFavoredByDocumentNumber", args});
    return !!ret;
}

export async function getScheduleActions(account: string, branch: string): Promise<Scheduling[]> {
  const args = {
        account: account,
        branch: branch,
  };
  const ret = await makeRequest({name: "getScheduleActions", args});
    return ret.map((e: any) => ({
        scheduleId: e.scheduleId,
        actionId: e.actionId,
        type: e.type,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        favoredName: e.favoredName,
        body: e.body,
        schedule: !!e.schedule,
        recurrent: e.recurrent === null || e.recurrent === undefined ? null : e.recurrent | 0,
        amount: e.amount,
        bankName: e.bankName,
        descriptionType: e.descriptionType,
    }));
}

export async function getScheduling(scheduleId: string): Promise<Scheduling> {
  const args = {
        scheduleId: scheduleId,
  };
  const ret = await makeRequest({name: "getScheduling", args});
    return {
        scheduleId: ret.scheduleId,
        actionId: ret.actionId,
        type: ret.type,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        favoredName: ret.favoredName,
        body: ret.body,
        schedule: !!ret.schedule,
        recurrent: ret.recurrent === null || ret.recurrent === undefined ? null : ret.recurrent | 0,
        amount: ret.amount,
        bankName: ret.bankName,
        descriptionType: ret.descriptionType,
    };
}

export async function deleteScheduling(scheduleId: string, location: LatLng | null): Promise<boolean> {
  const args = {
        scheduleId: scheduleId,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "deleteScheduling", args});
    return !!ret;
}

export async function getRecurrencyByScheduleId(scheduleId: string): Promise<ScheduleRecurrency[]> {
  const args = {
        scheduleId: scheduleId,
  };
  const ret = await makeRequest({name: "getRecurrencyByScheduleId", args});
    return ret.map((e: any) => ({
        id: e.id,
        idSchedule: e.idSchedule,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        amount: e.amount,
        status: e.status,
    }));
}

export async function getFutureEntries(branch: string, account: string): Promise<EntryStatement[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getFutureEntries", args});
    return ret.map((e: any) => ({
        id: e.id,
        movementNumber: e.movementNumber,
        type: e.type,
        description: e.description,
        finality: e.finality,
        nature: e.nature,
        prevBalance: e.prevBalance,
        currentBalance: e.currentBalance,
        movementDate: new Date(e.movementDate + "Z"),
        amount: e.amount,
        part: {
            name: e.part.name,
            cpfCnpj: e.part.cpfCnpj === null || e.part.cpfCnpj === undefined ? null : e.part.cpfCnpj,
            branch: e.part.branch,
            bank: e.part.bank === null || e.part.bank === undefined ? null : e.part.bank,
            account: e.part.account === null || e.part.account === undefined ? null : e.part.account,
        },
        counterPart: e.counterPart === null || e.counterPart === undefined ? null : {
            name: e.counterPart.name,
            cpfCnpj: e.counterPart.cpfCnpj === null || e.counterPart.cpfCnpj === undefined ? null : e.counterPart.cpfCnpj,
            branch: e.counterPart.branch,
            bank: e.counterPart.bank === null || e.counterPart.bank === undefined ? null : e.counterPart.bank,
            account: e.counterPart.account === null || e.counterPart.account === undefined ? null : e.counterPart.account,
        },
        devolutionReason: e.devolutionReason === null || e.devolutionReason === undefined ? null : e.devolutionReason,
        hasReceipt: !!e.hasReceipt,
    }));
}

export async function listCaixaWithdrawals(branch: string, account: string): Promise<CaixaWithdrawInfo[]> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "listCaixaWithdrawals", args});
    return ret.map((e: any) => ({
        id: e.id,
        branch: e.branch,
        account: e.account,
        amount: e.amount,
        info: e.info.map((e: any) => e),
        expirationDate: new Date(e.expirationDate + "Z"),
        agreementNumber: e.agreementNumber,
        agreementName: e.agreementName,
        withdrawPassword: e.withdrawPassword,
        withdrawCode: e.withdrawCode,
        createdAt: e.createdAt === null || e.createdAt === undefined ? null : new Date(e.createdAt + "Z"),
        status: e.status,
    }));
}

export async function cancelCaixaWithdraw(caixaWithdrawId: string): Promise<boolean> {
  const args = {
        caixaWithdrawId: caixaWithdrawId,
  };
  const ret = await makeRequest({name: "cancelCaixaWithdraw", args});
    return !!ret;
}

export async function requestManagerCard(managerCardAction: ManagerCardAction, fourLastDigits: string | null, branch: string, account: string, location: LatLng | null): Promise<string> {
  const args = {
        managerCardAction: managerCardAction,
        fourLastDigits: fourLastDigits === null || fourLastDigits === undefined ? null : fourLastDigits,
        branch: branch,
        account: account,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "requestManagerCard", args});
    return ret;
}

export async function getPreviousTransf(userId: string, branch: string, account: string, location: LatLng | null): Promise<TransferV9[]> {
  const args = {
        userId: userId,
        branch: branch,
        account: account,
        location: location === null || location === undefined ? null : {
            lat: location.lat,
            lng: location.lng,
        },
  };
  const ret = await makeRequest({name: "getPreviousTransf", args});
    return ret.map((e: any) => ({
        date: new Date(e.date + "Z"),
        description: e.description === null || e.description === undefined ? null : e.description,
        amount: e.amount,
        finality: e.finality === null || e.finality === undefined ? null : e.finality,
        type: e.type,
        from: {
            branch: e.from.branch,
            account: e.from.account,
        },
        to: {
            bank: {
                code: e.to.bank.code,
                name: e.to.bank.name,
            },
            branch: e.to.branch,
            account: e.to.account,
            cpfCnpj: e.to.cpfCnpj === null || e.to.cpfCnpj === undefined ? null : e.to.cpfCnpj,
            name: e.to.name,
            type: e.to.type,
        },
        recurrent: e.recurrent === null || e.recurrent === undefined ? null : e.recurrent | 0,
    }));
}

export async function getDdaInclusionTerms(): Promise<string> {
  const ret = await makeRequest({name: "getDdaInclusionTerms", args: {}});
    return ret;
}

export async function getDdaCancelTerms(): Promise<string> {
  const ret = await makeRequest({name: "getDdaCancelTerms", args: {}});
    return ret;
}

export async function getSummaryTransfer(summaryTransferParams: SummaryTransferParams): Promise<SummaryTransfer> {
  const args = {
        summaryTransferParams: {
            type: summaryTransferParams.type,
            date: summaryTransferParams.date === null || summaryTransferParams.date === undefined ? null : (typeof summaryTransferParams.date === "string" && (summaryTransferParams.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (summaryTransferParams.date as any).replace("Z", "") : summaryTransferParams.date.toISOString().replace("Z", "")),
            bank: summaryTransferParams.bank,
            branch: summaryTransferParams.branch,
            account: summaryTransferParams.account === null || summaryTransferParams.account === undefined ? null : summaryTransferParams.account,
        },
  };
  const ret = await makeRequest({name: "getSummaryTransfer", args});
    return {
        name: ret.name === null || ret.name === undefined ? null : ret.name,
        cpfCnpj: ret.cpfCnpj === null || ret.cpfCnpj === undefined ? null : ret.cpfCnpj,
        type: ret.type,
        date: ret.date === null || ret.date === undefined ? null : new Date(ret.date + "Z"),
        bank: ret.bank,
        branch: ret.branch,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
    };
}

export async function getBannerDashboard(): Promise<BannerResponse> {
  const ret = await makeRequest({name: "getBannerDashboard", args: {}});
    return {
        id: ret.id,
        name: ret.name,
        createdAt: new Date(ret.createdAt + "Z"),
        url: ret.url === null || ret.url === undefined ? null : ret.url,
        news: ret.news === null || ret.news === undefined ? null : {
            id: ret.news.id,
            content: {
                audio: ret.news.content.audio === null || ret.news.content.audio === undefined ? null : ret.news.content.audio,
                video: ret.news.content.video === null || ret.news.content.video === undefined ? null : ret.news.content.video,
                images: ret.news.content.images.map((e: any) => e),
            },
            thumbnail: ret.news.thumbnail === null || ret.news.thumbnail === undefined ? null : ret.news.thumbnail,
            coverImage: ret.news.coverImage === null || ret.news.coverImage === undefined ? null : ret.news.coverImage,
            date: ret.news.date === null || ret.news.date === undefined ? null : new Date(ret.news.date + "Z"),
            isPublished: !!ret.news.isPublished,
            createdAt: new Date(ret.news.createdAt + "Z"),
            deletedAt: ret.news.deletedAt === null || ret.news.deletedAt === undefined ? null : new Date(ret.news.deletedAt + "Z"),
            updatedAt: ret.news.updatedAt === null || ret.news.updatedAt === undefined ? null : new Date(ret.news.updatedAt + "Z"),
            partnerId: ret.news.partnerId,
            type: ret.news.type,
            title: ret.news.title,
            headline: ret.news.headline,
            body: ret.news.body,
        },
        image: ret.image,
        partnerId: ret.partnerId,
    };
}

export async function getNewsDashboard(offset: number): Promise<AdminPaginated> {
  const args = {
        offset: offset | 0,
  };
  const ret = await makeRequest({name: "getNewsDashboard", args});
    return {
        news: ret.news.map((e: any) => ({
            id: e.id,
            content: {
                audio: e.content.audio === null || e.content.audio === undefined ? null : e.content.audio,
                video: e.content.video === null || e.content.video === undefined ? null : e.content.video,
                images: e.content.images.map((e: any) => e),
            },
            thumbnail: e.thumbnail === null || e.thumbnail === undefined ? null : e.thumbnail,
            coverImage: e.coverImage === null || e.coverImage === undefined ? null : e.coverImage,
            type: e.type === null || e.type === undefined ? null : e.type,
            title: e.title,
            headline: e.headline,
            body: e.body,
            date: e.date === null || e.date === undefined ? null : new Date(e.date + "Z"),
            isPublished: !!e.isPublished,
            createdAt: new Date(e.createdAt + "Z"),
            deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            partnerId: e.partnerId,
        })),
        offset: ret.offset | 0,
        limit: ret.limit | 0,
        count: ret.count | 0,
        pages: ret.pages | 0,
    };
}

export async function createNews(newsData: CreateNewsPartner): Promise<AdminNews> {
  const args = {
        newsData: {
            type: newsData.type === null || newsData.type === undefined ? null : newsData.type,
            title: newsData.title,
            headline: newsData.headline,
            body: newsData.body,
            audio: newsData.audio === null || newsData.audio === undefined ? null : newsData.audio.toString("base64"),
            images: newsData.images === null || newsData.images === undefined ? null : newsData.images.map(e => e.toString("base64")),
            coverImage: newsData.coverImage === null || newsData.coverImage === undefined ? null : newsData.coverImage.toString("base64"),
            youtubeUrl: newsData.youtubeUrl === null || newsData.youtubeUrl === undefined ? null : newsData.youtubeUrl,
            isPublished: !!newsData.isPublished,
        },
  };
  const ret = await makeRequest({name: "createNews", args});
    return {
        id: ret.id,
        content: {
            audio: ret.content.audio === null || ret.content.audio === undefined ? null : ret.content.audio,
            video: ret.content.video === null || ret.content.video === undefined ? null : ret.content.video,
            images: ret.content.images.map((e: any) => e),
        },
        thumbnail: ret.thumbnail === null || ret.thumbnail === undefined ? null : ret.thumbnail,
        coverImage: ret.coverImage === null || ret.coverImage === undefined ? null : ret.coverImage,
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        title: ret.title,
        headline: ret.headline,
        body: ret.body,
        date: ret.date === null || ret.date === undefined ? null : new Date(ret.date + "Z"),
        isPublished: !!ret.isPublished,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        partnerId: ret.partnerId,
    };
}

export async function createNewsScheduler(newsData: CreateNewsSchedulerPartner): Promise<News> {
  const args = {
        newsData: {
            audio: newsData.audio === null || newsData.audio === undefined ? null : newsData.audio.toString("base64"),
            images: newsData.images === null || newsData.images === undefined ? null : newsData.images.map(e => e.toString("base64")),
            coverImage: newsData.coverImage === null || newsData.coverImage === undefined ? null : newsData.coverImage.toString("base64"),
            youtubeUrl: newsData.youtubeUrl === null || newsData.youtubeUrl === undefined ? null : newsData.youtubeUrl,
            date: (typeof newsData.date === "string" && (newsData.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newsData.date as any).replace("Z", "") : newsData.date.toISOString().replace("Z", "")),
            type: newsData.type,
            title: newsData.title,
            headline: newsData.headline,
            body: newsData.body,
        },
  };
  const ret = await makeRequest({name: "createNewsScheduler", args});
    return {
        id: ret.id,
        content: {
            audio: ret.content.audio === null || ret.content.audio === undefined ? null : ret.content.audio,
            video: ret.content.video === null || ret.content.video === undefined ? null : ret.content.video,
            images: ret.content.images.map((e: any) => e),
        },
        thumbnail: ret.thumbnail === null || ret.thumbnail === undefined ? null : ret.thumbnail,
        coverImage: ret.coverImage === null || ret.coverImage === undefined ? null : ret.coverImage,
        date: ret.date === null || ret.date === undefined ? null : new Date(ret.date + "Z"),
        isPublished: !!ret.isPublished,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        partnerId: ret.partnerId,
        type: ret.type,
        title: ret.title,
        headline: ret.headline,
        body: ret.body,
    };
}

export async function getNewsSchedulers(partnerId: string): Promise<News[]> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getNewsSchedulers", args});
    return ret.map((e: any) => ({
        id: e.id,
        content: {
            audio: e.content.audio === null || e.content.audio === undefined ? null : e.content.audio,
            video: e.content.video === null || e.content.video === undefined ? null : e.content.video,
            images: e.content.images.map((e: any) => e),
        },
        thumbnail: e.thumbnail === null || e.thumbnail === undefined ? null : e.thumbnail,
        coverImage: e.coverImage === null || e.coverImage === undefined ? null : e.coverImage,
        date: e.date === null || e.date === undefined ? null : new Date(e.date + "Z"),
        isPublished: !!e.isPublished,
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
        updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
        partnerId: e.partnerId,
        type: e.type,
        title: e.title,
        headline: e.headline,
        body: e.body,
    }));
}

export async function cancelScheduler(newsId: string): Promise<void> {
  const args = {
        newsId: newsId,
  };
  await makeRequest({name: "cancelScheduler", args});
    return undefined;
}

export async function editNews(newsId: string, editNews: EditNewsPartner): Promise<AdminNews> {
  const args = {
        newsId: newsId,
        editNews: {
            type: editNews.type === null || editNews.type === undefined ? null : editNews.type,
            title: editNews.title,
            headline: editNews.headline,
            body: editNews.body,
            audio: editNews.audio === null || editNews.audio === undefined ? null : editNews.audio.toString("base64"),
            images: editNews.images.map(e => ({
                url: e.url === null || e.url === undefined ? null : e.url,
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
            })),
            coverImage: editNews.coverImage === null || editNews.coverImage === undefined ? null : editNews.coverImage.toString("base64"),
            youtubeUrl: editNews.youtubeUrl === null || editNews.youtubeUrl === undefined ? null : editNews.youtubeUrl,
            date: editNews.date === null || editNews.date === undefined ? null : (typeof editNews.date === "string" && (editNews.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editNews.date as any).replace("Z", "") : editNews.date.toISOString().replace("Z", "")),
        },
  };
  const ret = await makeRequest({name: "editNews", args});
    return {
        id: ret.id,
        content: {
            audio: ret.content.audio === null || ret.content.audio === undefined ? null : ret.content.audio,
            video: ret.content.video === null || ret.content.video === undefined ? null : ret.content.video,
            images: ret.content.images.map((e: any) => e),
        },
        thumbnail: ret.thumbnail === null || ret.thumbnail === undefined ? null : ret.thumbnail,
        coverImage: ret.coverImage === null || ret.coverImage === undefined ? null : ret.coverImage,
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        title: ret.title,
        headline: ret.headline,
        body: ret.body,
        date: ret.date === null || ret.date === undefined ? null : new Date(ret.date + "Z"),
        isPublished: !!ret.isPublished,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        partnerId: ret.partnerId,
    };
}

export async function deleteNews(newsId: string): Promise<void> {
  const args = {
        newsId: newsId,
  };
  await makeRequest({name: "deleteNews", args});
    return undefined;
}

export async function publishNews(newsId: string, publish: boolean): Promise<boolean> {
  const args = {
        newsId: newsId,
        publish: !!publish,
  };
  const ret = await makeRequest({name: "publishNews", args});
    return !!ret;
}

export async function createBanner(createBanner: CreateBannerPartner): Promise<Banner> {
  const args = {
        createBanner: {
            name: createBanner.name,
            image: createBanner.image.toString("base64"),
            newsId: createBanner.newsId === null || createBanner.newsId === undefined ? null : createBanner.newsId,
            url: createBanner.url === null || createBanner.url === undefined ? null : createBanner.url,
        },
  };
  const ret = await makeRequest({name: "createBanner", args});
    return {
        id: ret.id,
        name: ret.name,
        createdAt: new Date(ret.createdAt + "Z"),
        url: ret.url === null || ret.url === undefined ? null : ret.url,
        newsId: ret.newsId === null || ret.newsId === undefined ? null : ret.newsId,
        image: ret.image,
        partnerId: ret.partnerId,
        isActive: !!ret.isActive,
    };
}

export async function editBanner(editBanner: EditBannerPartner): Promise<Banner> {
  const args = {
        editBanner: {
            id: editBanner.id,
            name: editBanner.name,
            image: editBanner.image === null || editBanner.image === undefined ? null : editBanner.image.toString("base64"),
            newsId: editBanner.newsId === null || editBanner.newsId === undefined ? null : editBanner.newsId,
            url: editBanner.url === null || editBanner.url === undefined ? null : editBanner.url,
        },
  };
  const ret = await makeRequest({name: "editBanner", args});
    return {
        id: ret.id,
        name: ret.name,
        createdAt: new Date(ret.createdAt + "Z"),
        url: ret.url === null || ret.url === undefined ? null : ret.url,
        newsId: ret.newsId === null || ret.newsId === undefined ? null : ret.newsId,
        image: ret.image,
        partnerId: ret.partnerId,
        isActive: !!ret.isActive,
    };
}

export async function removeBanner(bannerId: string): Promise<void> {
  const args = {
        bannerId: bannerId,
  };
  await makeRequest({name: "removeBanner", args});
    return undefined;
}

export async function getNewsDashboardById(newsId: string): Promise<AdminNews> {
  const args = {
        newsId: newsId,
  };
  const ret = await makeRequest({name: "getNewsDashboardById", args});
    return {
        id: ret.id,
        content: {
            audio: ret.content.audio === null || ret.content.audio === undefined ? null : ret.content.audio,
            video: ret.content.video === null || ret.content.video === undefined ? null : ret.content.video,
            images: ret.content.images.map((e: any) => e),
        },
        thumbnail: ret.thumbnail === null || ret.thumbnail === undefined ? null : ret.thumbnail,
        coverImage: ret.coverImage === null || ret.coverImage === undefined ? null : ret.coverImage,
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        title: ret.title,
        headline: ret.headline,
        body: ret.body,
        date: ret.date === null || ret.date === undefined ? null : new Date(ret.date + "Z"),
        isPublished: !!ret.isPublished,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        partnerId: ret.partnerId,
    };
}

export async function getDeletedNews(): Promise<AdminNews[]> {
  const ret = await makeRequest({name: "getDeletedNews", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        content: {
            audio: e.content.audio === null || e.content.audio === undefined ? null : e.content.audio,
            video: e.content.video === null || e.content.video === undefined ? null : e.content.video,
            images: e.content.images.map((e: any) => e),
        },
        thumbnail: e.thumbnail === null || e.thumbnail === undefined ? null : e.thumbnail,
        coverImage: e.coverImage === null || e.coverImage === undefined ? null : e.coverImage,
        type: e.type === null || e.type === undefined ? null : e.type,
        title: e.title,
        headline: e.headline,
        body: e.body,
        date: e.date === null || e.date === undefined ? null : new Date(e.date + "Z"),
        isPublished: !!e.isPublished,
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
        updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
        partnerId: e.partnerId,
    }));
}

export async function getPartnerCardListPaginated(limit: number, offset: number): Promise<CardPartnerQueuePaginated> {
  const args = {
        limit: limit | 0,
        offset: offset | 0,
  };
  const ret = await makeRequest({name: "getPartnerCardListPaginated", args});
    return {
        result: ret.result.map((e: any) => ({
            id: e.id,
            partnerId: e.partnerId,
            status: e.status,
            note: e.note,
            createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(parseInt(e.updatedAt.split("-")[0], 10), parseInt(e.updatedAt.split("-")[1], 10) - 1, parseInt(e.updatedAt.split("-")[2], 10)),
            user: {
                id: e.user.id,
                name: e.user.name,
                document: e.user.document,
            },
        })),
        limit: ret.limit | 0,
        offset: ret.offset | 0,
        total: ret.total | 0,
    };
}

export async function partnerLogin(username: string, password: string): Promise<boolean> {
  const args = {
        username: username,
        password: password,
  };
  const ret = await makeRequest({name: "partnerLogin", args});
    return !!ret;
}

export async function getValidPartnerIdBySession(): Promise<string> {
  const ret = await makeRequest({name: "getValidPartnerIdBySession", args: {}});
    return ret;
}

export async function partnerRenewSession(): Promise<number> {
  const ret = await makeRequest({name: "partnerRenewSession", args: {}});
    return ret;
}

export async function partnerLogout(): Promise<void> {
  await makeRequest({name: "partnerLogout", args: {}});
    return undefined;
}

export async function getPartnerFilteredAccounts(filter: FilterFields, managerFilter: ManagerReportFilter): Promise<ReportFilter> {
  const args = {
        filter: {
            idPartner: filter.idPartner === null || filter.idPartner === undefined ? null : filter.idPartner.map(e => e),
            startRegisterDate: filter.startRegisterDate === null || filter.startRegisterDate === undefined ? null : (typeof filter.startRegisterDate === "string" && (filter.startRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.startRegisterDate as any).replace("Z", "") : filter.startRegisterDate.toISOString().replace("Z", "")),
            endRegisterDate: filter.endRegisterDate === null || filter.endRegisterDate === undefined ? null : (typeof filter.endRegisterDate === "string" && (filter.endRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.endRegisterDate as any).replace("Z", "") : filter.endRegisterDate.toISOString().replace("Z", "")),
            typePerson: filter.typePerson === null || filter.typePerson === undefined ? null : filter.typePerson,
            accountType: filter.accountType === null || filter.accountType === undefined ? null : filter.accountType.map(e => e),
            status: filter.status === null || filter.status === undefined ? null : filter.status.map(e => e),
            gender: filter.gender === null || filter.gender === undefined ? null : filter.gender,
            region: filter.region === null || filter.region === undefined ? null : filter.region.map(e => e),
            startAge: filter.startAge === null || filter.startAge === undefined ? null : filter.startAge | 0,
            endAge: filter.endAge === null || filter.endAge === undefined ? null : filter.endAge | 0,
            approvation: filter.approvation === null || filter.approvation === undefined ? null : filter.approvation,
            closedAt: filter.closedAt === null || filter.closedAt === undefined ? null : (typeof filter.closedAt === "string" && (filter.closedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.closedAt as any).replace("Z", "") : filter.closedAt.toISOString().replace("Z", "")),
            closingReason: filter.closingReason === null || filter.closingReason === undefined ? null : filter.closingReason,
            subType: filter.subType === null || filter.subType === undefined ? null : filter.subType,
            journeyType: filter.journeyType === null || filter.journeyType === undefined ? null : filter.journeyType,
            accountStatus: filter.accountStatus === null || filter.accountStatus === undefined ? null : !!filter.accountStatus,
        },
        managerFilter: {
            limit: managerFilter.limit | 0,
            offset: managerFilter.offset | 0,
        },
  };
  const ret = await makeRequest({name: "getPartnerFilteredAccounts", args});
    return {
        users: ret.users.map((e: any) => ({
            id: e.id,
            name: e.name,
            status: e.status,
            complianceStatus: e.complianceStatus,
            createdAt: new Date(e.createdAt + "Z"),
            closedAt: e.closedAt === null || e.closedAt === undefined ? null : new Date(e.closedAt + "Z"),
            closingReason: e.closingReason === null || e.closingReason === undefined ? null : e.closingReason,
            partner: e.partner === null || e.partner === undefined ? null : e.partner,
            gender: e.gender === null || e.gender === undefined ? null : e.gender,
            documentNumber: e.documentNumber,
            categoryAccount: e.categoryAccount === null || e.categoryAccount === undefined ? null : e.categoryAccount,
            region: e.region === null || e.region === undefined ? null : e.region,
            age: e.age === null || e.age === undefined ? null : e.age | 0,
            approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
            type: e.type === null || e.type === undefined ? null : e.type,
            account: e.account === null || e.account === undefined ? null : e.account,
            pendingFields: e.pendingFields === null || e.pendingFields === undefined ? null : e.pendingFields.map((e: any) => e),
            pastPendingActions: e.pastPendingActions.map((e: any) => ({
                requestedAt: new Date(e.requestedAt + "Z"),
                answeredAt: new Date(e.answeredAt + "Z"),
                fields: e.fields.map((e: any) => e),
            })),
            approvation: e.approvation === null || e.approvation === undefined ? null : e.approvation,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            journeyType: e.journeyType === null || e.journeyType === undefined ? null : e.journeyType,
            accountStatus: e.accountStatus === null || e.accountStatus === undefined ? null : !!e.accountStatus,
        })),
        paged: ret.paged | 0,
        offset: ret.offset | 0,
        total: ret.total | 0,
    };
}

export async function getExcelFromPartnerFilteredAccounts(filter: FilterFields): Promise<Buffer> {
  const args = {
        filter: {
            idPartner: filter.idPartner === null || filter.idPartner === undefined ? null : filter.idPartner.map(e => e),
            startRegisterDate: filter.startRegisterDate === null || filter.startRegisterDate === undefined ? null : (typeof filter.startRegisterDate === "string" && (filter.startRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.startRegisterDate as any).replace("Z", "") : filter.startRegisterDate.toISOString().replace("Z", "")),
            endRegisterDate: filter.endRegisterDate === null || filter.endRegisterDate === undefined ? null : (typeof filter.endRegisterDate === "string" && (filter.endRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.endRegisterDate as any).replace("Z", "") : filter.endRegisterDate.toISOString().replace("Z", "")),
            typePerson: filter.typePerson === null || filter.typePerson === undefined ? null : filter.typePerson,
            accountType: filter.accountType === null || filter.accountType === undefined ? null : filter.accountType.map(e => e),
            status: filter.status === null || filter.status === undefined ? null : filter.status.map(e => e),
            gender: filter.gender === null || filter.gender === undefined ? null : filter.gender,
            region: filter.region === null || filter.region === undefined ? null : filter.region.map(e => e),
            startAge: filter.startAge === null || filter.startAge === undefined ? null : filter.startAge | 0,
            endAge: filter.endAge === null || filter.endAge === undefined ? null : filter.endAge | 0,
            approvation: filter.approvation === null || filter.approvation === undefined ? null : filter.approvation,
            closedAt: filter.closedAt === null || filter.closedAt === undefined ? null : (typeof filter.closedAt === "string" && (filter.closedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.closedAt as any).replace("Z", "") : filter.closedAt.toISOString().replace("Z", "")),
            closingReason: filter.closingReason === null || filter.closingReason === undefined ? null : filter.closingReason,
            subType: filter.subType === null || filter.subType === undefined ? null : filter.subType,
            journeyType: filter.journeyType === null || filter.journeyType === undefined ? null : filter.journeyType,
            accountStatus: filter.accountStatus === null || filter.accountStatus === undefined ? null : !!filter.accountStatus,
        },
  };
  const ret = await makeRequest({name: "getExcelFromPartnerFilteredAccounts", args});
    return Buffer.from(ret, "base64");
}

export async function getExcelFromPartnerFilteredToPartner(filter: FilterFields): Promise<Buffer> {
  const args = {
        filter: {
            idPartner: filter.idPartner === null || filter.idPartner === undefined ? null : filter.idPartner.map(e => e),
            startRegisterDate: filter.startRegisterDate === null || filter.startRegisterDate === undefined ? null : (typeof filter.startRegisterDate === "string" && (filter.startRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.startRegisterDate as any).replace("Z", "") : filter.startRegisterDate.toISOString().replace("Z", "")),
            endRegisterDate: filter.endRegisterDate === null || filter.endRegisterDate === undefined ? null : (typeof filter.endRegisterDate === "string" && (filter.endRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.endRegisterDate as any).replace("Z", "") : filter.endRegisterDate.toISOString().replace("Z", "")),
            typePerson: filter.typePerson === null || filter.typePerson === undefined ? null : filter.typePerson,
            accountType: filter.accountType === null || filter.accountType === undefined ? null : filter.accountType.map(e => e),
            status: filter.status === null || filter.status === undefined ? null : filter.status.map(e => e),
            gender: filter.gender === null || filter.gender === undefined ? null : filter.gender,
            region: filter.region === null || filter.region === undefined ? null : filter.region.map(e => e),
            startAge: filter.startAge === null || filter.startAge === undefined ? null : filter.startAge | 0,
            endAge: filter.endAge === null || filter.endAge === undefined ? null : filter.endAge | 0,
            approvation: filter.approvation === null || filter.approvation === undefined ? null : filter.approvation,
            closedAt: filter.closedAt === null || filter.closedAt === undefined ? null : (typeof filter.closedAt === "string" && (filter.closedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.closedAt as any).replace("Z", "") : filter.closedAt.toISOString().replace("Z", "")),
            closingReason: filter.closingReason === null || filter.closingReason === undefined ? null : filter.closingReason,
            subType: filter.subType === null || filter.subType === undefined ? null : filter.subType,
            journeyType: filter.journeyType === null || filter.journeyType === undefined ? null : filter.journeyType,
            accountStatus: filter.accountStatus === null || filter.accountStatus === undefined ? null : !!filter.accountStatus,
        },
  };
  const ret = await makeRequest({name: "getExcelFromPartnerFilteredToPartner", args});
    return Buffer.from(ret, "base64");
}

export async function sendNotificationToPartnerUsers(message: CreatePushMessage): Promise<PushMessage> {
  const args = {
        message: {
            message: message.message,
            scheduleTo: message.scheduleTo === null || message.scheduleTo === undefined ? null : (typeof message.scheduleTo === "string" && (message.scheduleTo as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (message.scheduleTo as any).replace("Z", "") : message.scheduleTo.toISOString().replace("Z", "")),
        },
  };
  const ret = await makeRequest({name: "sendNotificationToPartnerUsers", args});
    return {
        id: ret.id,
        message: ret.message,
        partnerId: ret.partnerId,
        createdAt: new Date(ret.createdAt + "Z"),
        scheduleTo: ret.scheduleTo === null || ret.scheduleTo === undefined ? null : new Date(ret.scheduleTo + "Z"),
    };
}

export async function getPushMessagesHistory(): Promise<PushMessage[]> {
  const ret = await makeRequest({name: "getPushMessagesHistory", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        message: e.message,
        partnerId: e.partnerId,
        createdAt: new Date(e.createdAt + "Z"),
        scheduleTo: e.scheduleTo === null || e.scheduleTo === undefined ? null : new Date(e.scheduleTo + "Z"),
    }));
}

export async function removeMessageSchedule(messageId: string): Promise<void> {
  const args = {
        messageId: messageId,
  };
  await makeRequest({name: "removeMessageSchedule", args});
    return undefined;
}

export async function partnerDeleteMessageSchedule(messageId: string): Promise<void> {
  const args = {
        messageId: messageId,
  };
  await makeRequest({name: "partnerDeleteMessageSchedule", args});
    return undefined;
}

export async function isPartnerSoccer(): Promise<boolean> {
  const ret = await makeRequest({name: "isPartnerSoccer", args: {}});
    return !!ret;
}

export async function ping(): Promise<string> {
  const ret = await makeRequest({name: "ping", args: {}});
    return ret;
}

//////////////////////////////////////////////////////

function setDeviceId(deviceId: string) {
  try {
    localStorage.setItem("deviceId", deviceId);
    window.SDKGEN_DEVICE_ID = deviceId;
  } catch (e) {}
}

function getDeviceId() {
  try {
    return window.SDKGEN_DEVICE_ID || localStorage.getItem("deviceId");
  } catch (e) {}
  return randomBytesHex(16);
}

function randomBytesHex(len: number) {
  let hex = "";
  for (let i = 0; i < 2 * len; ++i)
    hex += "0123456789abcdef"[Math.floor(Math.random()*16)];
  return hex;
}

try {
  window.SDKGEN_DEVICE_ID = getDeviceId();
} catch (e) {}

if (!window.SDKGEN_DEVICE_ID) {
  window.SDKGEN_DEVICE_ID = randomBytesHex(16);
}

try {
  if (useSessionStorage) {
    window.SDKGEN_SESSION_ID = sessionStorage.getItem("sessionId") || null;
  } else {
    window.SDKGEN_SESSION_ID = localStorage.getItem("sessionId") || null;
  }
} catch (e) {}

export type ApiError = Error & { type: ErrorType };

export interface ListenerTypes {
  fail: (e: ApiError, name: string, args: any) => void;
  fatal: (e: Error, name: string, args: any) => void;
  success: (res: string, name: string, args: any) => void;
}

type HookArray = Array<Function>;
export type Listenables = keyof ListenerTypes;
type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
  fail: [],
  fatal: [],
  success: [],
};

export function addEventListener<K extends Listenables>(listenable: K, hook: ListenerTypes[K]) {
  const listeners: HookArray = listenersDict[listenable];
  listeners.push(hook);
}

export function removeEventListener<K extends Listenables>(listenable: K, hook: ListenerTypes[K]) {
  const listeners: HookArray = listenersDict[listenable];
  listenersDict[listenable] = listeners.filter(h => h !== hook) as any;
}

async function makeRequest({name, args}: {name: string, args: any}) {
  return new Promise<any>((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("POST", baseUrl + "/" + name);

    const body = {
      deviceId: window.SDKGEN_DEVICE_ID,
      requestId: randomBytesHex(8),
      deviceFingerprint: null,
      sessionId: window.SDKGEN_SESSION_ID,
      partnerId: partnerId,
      name: name,
      args: args,
      info: {
        type: "browser",
        browserUserAgent: navigator.userAgent,
        language: navigator.language
      }
    };

    req.onreadystatechange = () => {
      if (req.readyState !== 4) return;
      try {
        const response = JSON.parse(req.responseText);

        try {
          window.SDKGEN_SESSION_ID = response.sessionId;
          setDeviceId(response.deviceId);

          try {
            if (useSessionStorage) {
              sessionStorage.setItem("sessionId", window.SDKGEN_SESSION_ID || "");
            } else {
              localStorage.setItem("sessionId", window.SDKGEN_SESSION_ID || "");
            }
          } catch (e) {}

          if (response.ok) {
            resolve(response.result);
            listenersDict["success"].forEach(hook => hook(response.result, name, args));
          } else {
            reject(response.error);
            listenersDict["fail"].forEach(hook => hook(response.error, name, args));
          }
        } catch (e) {
          console.error(e);
          reject({type: "Fatal", message: e.toString()});
          listenersDict["fatal"].forEach(hook => hook(e, name, args));
        }
      } catch (e) {
        console.error(e);
        if (!req.responseText) {
          reject({type: "NotConnect", message: `VocÃª nÃ£o estÃ¡ conectado ou o servidor nÃ£o responde`});
        } else {
          reject({type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}`});
        }
        listenersDict["fatal"].forEach(hook => hook(e, name, args));
      }
    };
    req.send(JSON.stringify(body));
  });
}