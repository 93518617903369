/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sort-keys */
import * as React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { AdminUser, AdminUserPermission } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";

import { EmployeeManagerStore, GeneralStore } from "../../../stores";
import { EmployeeWizardData } from "../EmployeeWizardData";
import { TabNavigator } from "../../../components/TabNavigator";
import { EmployeeWizardPermissions } from "../EmployeeWizardPermissions";

interface IProps {
  adminUser: AdminUser | null;
  employeeManagerStore?: EmployeeManagerStore;
  generalStore?: GeneralStore;
  close: () => void;
  getAdminUsers: () => void;
}

export enum Error {
  email,
  password,
  confirmPassword,
}

@inject("employeeManagerStore", "generalStore")
@observer
export class EmployeeWizard extends React.Component<IProps> {
  @observable private adminUser = this.props.adminUser || {
    id: null,
    cpf: "",
    name: "",
    email: "",
    password: "",
    blocked: false,
    adminUserPermissions: [],
  };

  @observable private loading = false;

  @observable private errors = observable.map<Error, string>();

  @observable private selectedTab = "employeeData";

  @action
  private handleChange = (adminUser: AdminUser) => {
    this.adminUser = adminUser;
  };

  private handlePermissions = (adminUserPermissions: AdminUserPermission[]) => {
    this.adminUser.adminUserPermissions = adminUserPermissions;
  };

  @action
  private save = async (event: React.MouseEvent) => {
    event.preventDefault();

    const { setAdminUser } = this.props.employeeManagerStore!;

    try {
      this.loading = true;
      await setAdminUser(this.adminUser);

      this.props.generalStore!.showToast("Funcionário salvo com sucesso.");

      await this.props.getAdminUsers();

      this.props.close();
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao salvar funcionário.");
    } finally {
      this.loading = false;
    }
  };

  @action
  private onChangeTab = (value: string) => {
    this.selectedTab = value;
  };

  private get tabs() {
    return [
      {
        label: "Dados cadastrais",
        value: "employeeData",
        child: <EmployeeWizardData adminUser={this.adminUser} onChange={this.handleChange} errors={this.errors} />,
      },
      {
        label: "Permissões",
        value: "employeePermissions",
        child: (
          <EmployeeWizardPermissions data={this.adminUser.adminUserPermissions} onChange={this.handlePermissions} />
        ),
      },
    ];
  }

  public render() {
    return (
      <Container autoComplete="off">
        <TabNavigator onChangeTab={this.onChangeTab} selected={this.selectedTab} tabs={this.tabs} />
        <SaveButton
          label={this.adminUser.id ? "Salvar" : "Criar Funcionário"}
          disabled={Boolean(this.errors.size)}
          loading={this.loading}
          onClick={this.save}
        />
      </Container>
    );
  }
}

const SaveButton = styled(RaisedButton)`
  font-family: Lato;
  margin: 15px 0;
  width: fit-content;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const Container = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
