import * as React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { colors } from "../../../utils";

interface IProps {
  children?: React.ReactNode;
  compact?: boolean;
  isSoccer?: boolean;
}

export const Loader: React.SFC<IProps> = ({ compact, children, isSoccer }) => {
  if (compact) {
    return (
      <CircularProgress
        data-testid="loader"
        thickness={7}
        style={{ color: isSoccer ? colors.darkGreen : colors.violetRed }}
      />
    );
  }

  return (
    <LoadingContainer>
      <CircularProgress
        data-testid="loader"
        thickness={7}
        style={{ color: isSoccer ? colors.darkGreen : colors.violetRed }}
      />
      {children}
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
