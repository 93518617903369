import * as React from "react";
import { observer } from "mobx-react";

import { Fetch, Fetchable } from "../Fetchable";
import { Loader } from "../Loader";

import { InnerAlert } from "..";

type IProps<T, S extends React.ComponentProps<X>, X extends React.FunctionComponent<any>> = {
  fetch: Fetch<T>;
  errorMessage?: string;
  component: X;
} & S;

export function Fetcher<T, S extends React.ComponentProps<X>, X extends React.FunctionComponent<any>>({
  fetch,
  errorMessage,
  component,
  ...props
}: IProps<T, S, X>): JSX.Element {
  const loading = () => <Loader />;

  const error = (err: Error) => <InnerAlert message={errorMessage || err.message || "Erro ao obter os dados."} />;

  return (
    <Fetchable fetch={fetch} loading={loading} error={error}>
      {() => {
        const Component = observer(component);

        return <Component {...props} />;
      }}
    </Fetchable>
  );
}
