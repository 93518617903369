/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import { observable } from "mobx";

import { AuthStore, GeneralStore } from "../../stores";
import { FormChangePassword } from "./FormChangePassword";

interface IProps {
  authStore?: AuthStore;
  routerStore?: RouterStore;
  generalStore?: GeneralStore;
  requestCloseDialog: () => void;
}

// Interface IState

@inject("authStore", "routerStore", "generalStore")
@observer
export class ChangePassword extends React.Component<IProps, any> {
  @observable private loadingChangePassword = false;

  private onSubmit = async (data: { oldPassword: string; newPassword: string; newPasswordConfirm: string }) => {
    try {
      this.loadingChangePassword = true;
      await this.props.authStore!.changeAdminPassword(data.oldPassword, data.newPassword, data.newPasswordConfirm);
      this.props.generalStore!.showToast("No próximo login, você usará a nova senha");
      this.props.requestCloseDialog();
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um erro ocorreu");
    } finally {
      this.loadingChangePassword = false;
    }
  };

  public render() {
    return (
      <FormChangePassword
        isLoading={this.loadingChangePassword}
        onSubmit={(data: { oldPassword: string; newPassword: string; newPasswordConfirm: string }) =>
          this.onSubmit(data)
        }
      />
    );
  }
}
