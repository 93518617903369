import momentGenerateConfig from "rc-picker/lib/generate/moment";
import ptBR from "rc-picker/lib/locale/pt_BR";

const format = "D [de] MMMM YYYY";

export const defaultProps = {
  format: [format, format],
  generateConfig: momentGenerateConfig,
  inputReadOnly: true,
  locale: ptBR,
};
