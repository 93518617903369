/* eslint-disable implicit-arrow-linebreak */

import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useStores } from "@manager/stores/RootStore";
import { observer } from "mobx-react";
import { RaisedButton } from "legacy/components/RaisedButton";

import { Input, InputLabel, InputMask, Modal, Select } from "@components/";
import { IOption } from "components/Select";

import s from "./styled.scss";

const optionsStates = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AM", label: "Amazonas" },
  { value: "AP", label: "Amapá" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RO", label: "Rondônia" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SE", label: "Sergipe" },
  { value: "SP", label: "São Paulo" },
  { value: "TO", label: "Tocantins" },
];

interface IProps {
  user: api.RegisterUserFields | null;
  userAdress: api.AddressInfo | null;
  isOpen: boolean;
  onClose: () => void;
  reloadPage: () => void;
  showToast: (msg: string) => void;
}

export const AddressDialog = observer(
  ({ isOpen, onClose, reloadPage, showToast, user, userAdress: userAddress }: IProps) => {
    const { usersStore, internalAPIsStore } = useStores();
    const [loading, setLoading] = useState(false);
    const [cityList, setCityList] = useState<IOption[]>([]);
    const [defaultCity, setDefaultCity] = useState<IOption>({
      label: "",
      value: "",
    });

    const loadCities = async (uf: string) => {
      if (uf) {
        const cities = await api.getCities(uf);

        setCityList(
          cities.map(city => ({
            value: city.code,
            label: city.name,
          })),
        );

        return cities;
      }

      return [];
    };

    useEffect(() => {
      (async () => {
        await getDefaultCityOption();
      })();
    }, []);

    const handleInputAddress = async (zipCode: string | null) => {
      if (zipCode) {
        formik.setFieldValue("cep", zipCode);

        const address = await internalAPIsStore.getAddressByCEP(zipCode.replace(/\D/gu, ""));

        const state = optionsStates.find(option => option.value === address.state) || {
          label: "",
          value: "",
        };

        setNewUf(state);

        const cities = await loadCities(address.state);

        const selectedCity = cities.find(
          c =>
            address.city
              .toUpperCase()
              .normalize("NFD")
              .replace(/[^a-zA-Z\s]/gu, "") === c.name,
        ) || {
          code: "",
          name: "",
        };

        formik.setFieldValue("city", {
          label: selectedCity.name,
          value: selectedCity.code,
        });
        formik.setFieldValue("street", address.street);
        formik.setFieldValue("neighborhood", address.neighborhood);
      }
    };

    const getStateOption = (): IOption => {
      if (userAddress) {
        const state = optionsStates.find(option => option.value === userAddress.state);

        if (state) {
          return state;
        }

        return {
          value: "",
          label: "",
        };
      }

      return {
        value: "",
        label: "",
      };
    };

    const getDefaultCityOption = async () => {
      if (userAddress) {
        const cities = await loadCities(userAddress.state);

        const selectedCity = cities.find(
          c =>
            userAddress.city
              .toUpperCase()
              .normalize("NFD")
              .replace(/[^a-zA-Z\s]/gu, "") === c.name,
        );

        if (selectedCity) {
          setDefaultCity({
            label: selectedCity.name,
            value: selectedCity.code,
          });

          formik.setFieldValue("city", {
            label: selectedCity.name,
            value: selectedCity.code,
          });
        } else {
          setDefaultCity({
            label: "",
            value: "",
          });
        }
      }
    };

    const formik = useFormik({
      initialValues: {
        uf: getStateOption(),
        city: defaultCity,
        cep: userAddress ? userAddress.cep : "",
        complement: userAddress && userAddress.complement ? userAddress.complement : "",
        neighborhood: userAddress ? userAddress.neighborhood : "",
        number: userAddress ? userAddress.number : "",
        street: userAddress ? userAddress.street : "",
      },
      onSubmit: async values => {
        if (user?.workflowId) {
          try {
            setLoading(true);
            await usersStore.updateAccountAddress(user.workflowId, {
              cep: values.cep,
              street: values.street,
              neighborhood: values.neighborhood,
              number: values.number,
              complement: values.complement,
              city: values.city.label,
              state: values.uf.label,
              country: "Brasil",
            });

            onClose();

            showToast("Endereço atualizado com sucesso.");

            reloadPage();
          } catch (err) {
            showToast(err.message || "Ocorreu um erro ao salvar a mensagem.");
          } finally {
            setLoading(false);
          }
        } else {
          showToast("Ocorreu um erro ao acessar as informações do usuário.");
        }
      },
      validationSchema: Yup.object({
        cep: Yup.string().required("Este campo é obrigatório."),
        uf: Yup.object()
          .shape({
            value: Yup.string().required("Este campo é obrigatório."),
            label: Yup.string().required("Este campo é obrigatório."),
          })
          .required("Este campo é obrigatório.")
          .nullable(),
        city: Yup.object()
          .shape({
            value: Yup.string().required("Este campo é obrigatório."),
            label: Yup.string().required("Este campo é obrigatório."),
          })
          .required("Este campo é obrigatório.")
          .nullable(),
        neighborhood: Yup.string().required("Este campo é obrigatório."),
        street: Yup.string().required("Este campo é obrigatório."),
        number: Yup.string().required("Este campo é obrigatório."),
      }),
    });

    const setNewUf = async (uf: IOption | null) => {
      formik.setFieldValue("uf", uf);

      if (uf) {
        const cities = await api.getCities(uf.value);

        setCityList(
          cities.map(city => ({
            value: city.code,
            label: city.name,
          })),
        );
      }
    };

    return (
      <Modal visible={isOpen} title="Atualizar endereço vinculado" onClose={onClose}>
        <div className={s.box}>
          <form onSubmit={formik.handleSubmit}>
            <div className={s.info}>
              <InputLabel
                label="*CEP"
                htmlFor="cep"
                error={formik.touched.cep && formik.errors.cep ? formik.errors.cep : undefined}
              >
                <InputMask
                  mask="99.999-999"
                  maskChar="0"
                  placeholder="CEP"
                  name="cep"
                  onChange={e => {
                    console.log(e);
                    handleInputAddress(e.target.value);
                  }}
                  value={formik.values.cep}
                  error={formik.touched.cep && formik.errors.cep ? true : undefined}
                />
              </InputLabel>
              <InputLabel
                label="*Estado"
                htmlFor="uf"
                className={s.select}
                error={formik.touched.uf && formik.errors.uf ? formik.errors.uf.label : undefined}
              >
                <Select
                  options={optionsStates}
                  isSearchable
                  placeholder="Selecionar estado"
                  noOptionsMessage={() => "Estado não encontrado"}
                  onChange={setNewUf}
                  value={formik.values.uf}
                  error={formik.touched.uf && formik.errors.uf ? true : undefined}
                />
              </InputLabel>
              <InputLabel
                label="*Cidade"
                htmlFor="city"
                className={s.select}
                error={formik.touched.city && formik.errors.city ? formik.errors.city.label : undefined}
              >
                <Select
                  options={cityList}
                  isSearchable
                  placeholder="Selecionar cidade"
                  noOptionsMessage={() => "Cidade não encontrada"}
                  onChange={(e: IOption) => {
                    formik.setFieldValue("city", e);
                    console.log(e);
                  }}
                  value={formik.values.city}
                  error={formik.touched.city && formik.errors.city ? true : undefined}
                />
              </InputLabel>
              <InputLabel
                label="*Bairro"
                htmlFor="neighborhood"
                error={
                  formik.touched.neighborhood && formik.errors.neighborhood ? formik.errors.neighborhood : undefined
                }
              >
                <Input
                  placeholder="Bairro"
                  id="neighborhood"
                  name="neighborhood"
                  onChange={formik.handleChange}
                  value={formik.values.neighborhood}
                  error={formik.touched.neighborhood && formik.errors.neighborhood ? true : undefined}
                />
              </InputLabel>
              <InputLabel
                label="*Rua"
                htmlFor="street"
                error={formik.touched.street && formik.errors.street ? formik.errors.street : undefined}
              >
                <Input
                  placeholder="Rua"
                  name="street"
                  id="street"
                  onChange={formik.handleChange}
                  value={formik.values.street}
                  error={formik.touched.street && formik.errors.street ? true : undefined}
                />
              </InputLabel>
              <InputLabel
                label="*Número"
                htmlFor="number"
                error={formik.touched.number && formik.errors.number ? formik.errors.number : undefined}
              >
                <Input
                  placeholder="Número"
                  name="number"
                  id="number"
                  onChange={formik.handleChange}
                  value={formik.values.number}
                  error={formik.touched.number && formik.errors.number ? true : undefined}
                />
              </InputLabel>
              <InputLabel label="Complemento" htmlFor="complement">
                <Input
                  placeholder="Complemento"
                  name="complement"
                  id="complement"
                  onChange={formik.handleChange}
                  value={formik.values.complement}
                />
              </InputLabel>
            </div>

            <div className={s.warning}>
              <p>
                Essa funcionalidade altera o endereço do cliente apenas no dashboard, não sincronizando com os demais
                sistemas.
              </p>
            </div>

            <div className={s.inputArea}>
              <RaisedButton type="submit" className={s.NextWrapperButton} loading={loading} label="Salvar" />
            </div>
          </form>
        </div>
      </Modal>
    );
  },
);
