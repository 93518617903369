import * as React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { action, observable, ObservableMap, reaction } from "mobx";
import { AdminUser } from "api/manager-api";
import { TextInput } from "legacy/components/TextInput";
import { colors } from "utils";

import { maskValidateCpf } from "../../../utils";
import { Error } from "../EmployeeWizard";
import { passwordRegex } from "../../../utils/regex";
import { validateEmail } from "../../../utils/validateEmail";

interface IProps {
  adminUser: AdminUser;
  errors: ObservableMap<Error, string>;
  onChange: (AdminUser: AdminUser) => void;
}

@observer
export class EmployeeWizardData extends React.Component<IProps> {
  @observable private confirmPassword = "";

  constructor(props: IProps) {
    super(props);
    const { errors } = props;

    reaction(
      () => this.props.adminUser.password,
      password => {
        if (!password) {
          this.confirmPassword = "";
        }

        if (password) {
          if (password.length < 10) {
            errors.set(Error.password, "A senha precisa ter no mínimo 10 caracteres.");
          } else if (password.match(passwordRegex)) {
            errors.delete(Error.password);
          } else {
            errors.set(
              Error.password,
              "A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais.",
            );
          }
        }
      },
    );

    reaction(
      () => this.confirmPassword,
      confirmPassword => {
        const { password } = this.props.adminUser;

        if ((password && !confirmPassword) || (confirmPassword && confirmPassword !== password)) {
          errors.set(Error.confirmPassword, "As senhas não conferem.");
        } else {
          errors.delete(Error.confirmPassword);
        }
      },
    );

    reaction(
      () => this.props.adminUser.email,
      email => {
        if (validateEmail(email)) {
          errors.delete(Error.email);
        } else {
          errors.set(Error.email, "E-mail inválido.");
        }
      },
    );
  }

  @action
  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: HTMLInputElement = event.currentTarget;

    this.props.onChange({
      ...this.props.adminUser,
      [name]: value,
    });
  };

  @action
  private handlePassword = (event: React.FormEvent<HTMLInputElement>) => {
    const password = event.currentTarget.value || null;

    this.props.onChange({
      ...this.props.adminUser,
      password,
    });
  };

  @action
  private handleConfirmPassword = (event: React.FormEvent<HTMLInputElement>) => {
    const confirmPassword = event.currentTarget.value;

    this.confirmPassword = confirmPassword;
  };

  public render() {
    const { adminUser, errors } = this.props;

    return (
      <StyledContainer>
        <TextInput
          name="name"
          value={adminUser.name}
          onChange={this.handleChange}
          required
          label={<Title>Nome</Title>}
          style={{ width: "482px" }}
        />
        <Spacing />
        <TextInput
          name="cpf"
          value={adminUser.cpf}
          onChange={this.handleChange}
          required
          style={{ width: "198px" }}
          label={<Title>CPF</Title>}
          mask={maskValidateCpf}
        />
        <Spacing />
        <TextInput
          name="email"
          type="email"
          value={adminUser.email}
          onChange={this.handleChange}
          required
          style={{
            width: "412px",
            ...(errors.has(Error.password)
              ? {
                  borderColor: colors.red,
                }
              : {}),
          }}
          {...(errors.has(Error.email) && {
            error: true,
            errorText: errors.get(Error.email),
          })}
          label={<Title>E-mail</Title>}
          autoComplete="off"
        />
        <Spacing />
        <TextInput
          name="password"
          value={adminUser.password || ""}
          placeholder="Digite a nova senha"
          onChange={this.handlePassword}
          required
          style={{
            width: "236px",
            ...(errors.has(Error.password)
              ? {
                  borderColor: colors.red,
                }
              : {}),
          }}
          {...(errors.has(Error.password) && {
            error: true,
            errorText: errors.get(Error.password),
          })}
          autoComplete="new-password"
          label={<Title>Senha</Title>}
          isSecret
        />
        <Spacing />
        {this.renderConfirmPassword()}
      </StyledContainer>
    );
  }

  private renderConfirmPassword = () => {
    const { adminUser, errors } = this.props;

    if (!adminUser.password || errors.has(Error.password)) {
      return null;
    }

    return (
      <>
        <TextInput
          name="confirmPassword"
          value={this.confirmPassword}
          placeholder="Confirme a nova senha"
          onChange={this.handleConfirmPassword}
          required
          style={{
            width: "236px",
            ...(this.props.errors.has(Error.confirmPassword)
              ? {
                  borderColor: colors.red,
                }
              : {}),
          }}
          {...(errors.has(Error.confirmPassword) && {
            error: true,
            errorText: errors.get(Error.confirmPassword),
          })}
          autoComplete="new-password"
          label={<Title>Confirme senha</Title>}
          readOnly
          onFocus={e => (e.currentTarget.readOnly = false)}
          isSecret
        />
        <Spacing />
      </>
    );
  };
}

const StyledContainer = styled.div`
  padding-top: 15px;
  margin: 5px;
`;

const Title = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
`;

const Spacing = styled.div`
  margin-bottom: 15px;
`;
