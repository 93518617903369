import * as React from "react";
import * as api from "api/manager-api";
import { observer } from "mobx-react";
import { Loader } from "legacy/components/Loader";
import { InnerAlert } from "legacy/components";
import { TextInput } from "legacy/components/TextInput";
import { RaisedButton } from "legacy/components/RaisedButton";
import { OutlinedButton } from "legacy/components/OutlinedButton";

import { Table } from "../../../components/Table";
import { ActionsDropdown } from "../../../components/ActionsDropdown";
import { useStores } from "../../../stores/RootStore";
import { Container, Header, HeaderWrapper, Label, TabNavigator } from "./styled";

const isDev =
  process.env.BRANCH &&
  (process.env.BRANCH.startsWith("hml") ||
    process.env.BRANCH.startsWith("dev") ||
    process.env.BRANCH.startsWith("homolog"));

export const Whitelist = observer(() => {
  const { internalAPIsStore, generalStore } = useStores();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [whitelistEmail, setWhitelistDataEmail] = React.useState<api.WhitelistEmail[]>([]);
  const [whitelistSms, setWhitelistDataSms] = React.useState<api.WhiteListSms[]>([]);
  const [whitelistDomain, setWhitelistDataDomain] = React.useState<api.WhitelistDomain[]>([]);
  const [whitelistLogin, setWhitelistDataLogin] = React.useState<api.WhitelistLogin[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<"email" | "sms" | "domain" | "login">(isDev ? "email" : "login");
  const [whitelistData, setWhitelistData] = React.useState("");
  const [currentId, setCurrentId] = React.useState("");

  const getEmail = async () => {
    setLoading(true);
    try {
      const result = await internalAPIsStore.getWhitelistEmail();

      setWhitelistDataEmail(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getSms = async () => {
    setLoading(true);
    try {
      const result = await internalAPIsStore.getWhiteListSms();

      setWhitelistDataSms(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getDomain = async () => {
    setLoading(true);
    try {
      const result = await internalAPIsStore.getWhitelistDomain();

      setWhitelistDataDomain(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getLogin = async () => {
    setLoading(true);
    try {
      const result = await internalAPIsStore.getWhitelistLogin();

      setWhitelistDataLogin(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const setEmail = async (name: string, id: string | null, isDeleted: boolean) => {
    setLoading(true);
    try {
      await internalAPIsStore.setWhitelistEmail(name, id, isDeleted);
      setWhitelistData("");
      setCurrentId("");
      generalStore.showToast("Email salvo com sucesso");
      getEmail();
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const setDomain = async (name: string, id: string | null, isDeleted: boolean) => {
    setLoading(true);
    try {
      await internalAPIsStore.setWhitelistDomain(name, id, isDeleted);
      setWhitelistData("");
      setCurrentId("");
      generalStore.showToast("Domínio salvo com sucesso");
      getDomain();
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const setSms = async (name: string, id: string | null, isDeleted: boolean) => {
    setLoading(true);
    try {
      await internalAPIsStore.setWhiteListSms(name, id, isDeleted);
      setWhitelistData("");
      setCurrentId("");
      generalStore.showToast("SMS salvo com sucesso");
      getSms();
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const setLogin = async (name: string, id: string | null, isDeleted: boolean) => {
    setLoading(true);
    try {
      await internalAPIsStore.setWhitelistLogin(name, id, isDeleted);
      setWhitelistData("");
      setCurrentId("");
      generalStore.showToast("Login salvo com sucesso");
      getLogin();
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getList = async () => {
    switch (selectedTab) {
      case "email":
        getEmail();
        break;
      case "sms":
        getSms();
        break;
      case "domain":
        getDomain();
        break;
      case "login":
        getLogin();
        break;
      default:
        getEmail();
        break;
    }
  };

  const setList = async () => {
    if (!whitelistData) {
      return;
    }

    switch (selectedTab) {
      case "email":
        setEmail(whitelistData, currentId || null, false);
        break;
      case "sms":
        setSms(whitelistData, currentId || null, false);
        break;
      case "domain":
        setDomain(whitelistData, currentId || null, false);
        break;
      case "login":
        setLogin(whitelistData, currentId || null, false);
        break;
      default:
        setEmail(whitelistData, currentId || null, false);
        break;
    }
  };

  React.useEffect(() => {
    getList();
  }, [selectedTab]);

  const tabs = () => {
    const showInMaster = ["login"];
    const tabsSructure = [
      {
        child: <RenderData />,
        label: "E-mails",
        value: "email",
      },
      {
        child: <RenderData />,
        label: "SMS´s",
        value: "sms",
      },
      {
        child: <RenderData />,
        label: "Domínios",
        value: "domain",
      },
      {
        child: <RenderData />,
        label: "Login",
        value: "login",
      },
    ];

    return isDev ? tabsSructure : tabsSructure.filter(({ value }) => showInMaster.includes(value));
  };

  const renderOptions = (
    name: string,
    id: string,
    setFunction: (a: string, b: string | null, c: boolean) => Promise<void>,
  ) => {
    return [
      {
        label: "Editar",
        onClick: () => {
          setWhitelistData(name);
          setCurrentId(id);
        },
      },
      {
        label: "Remover",
        onClick: async () => setFunction(name, id, true),
      },
    ];
  };

  const renderHeader = () => {
    return (
      <HeaderWrapper>
        <Label>Digite um dado para inserção na lista</Label>
        <Header>
          <TextInput value={whitelistData} onChange={e => setWhitelistData(e.currentTarget.value)} />
          <RaisedButton label={"Salvar"} onClick={async () => setList()} style={{ marginRight: "10px" }} />
          {currentId && (
            <OutlinedButton
              label={"Cancelar"}
              onClick={() => {
                setCurrentId("");
                setWhitelistData("");
              }}
            />
          )}
        </Header>
      </HeaderWrapper>
    );
  };

  const renderTable = (titles: string[], ids: string[], data: any) => {
    return (
      <Table
        titles={titles}
        ids={ids}
        data={data}
        trStyles={[
          {
            boxSizing: "content-box",
            minWidth: "111px",
          },
        ]}
      />
    );
  };

  const RenderData = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <InnerAlert message={error} buttonLabel="Tentar novamente" buttonClick={async () => getList()} />;
    }

    if (!whitelistEmail.length && !whitelistSms.length && !whitelistDomain.length && !whitelistLogin.length) {
      return (
        <InnerAlert
          customMarginTop={"25px"}
          message="Nenhum dado para mostrar"
          buttonLabel="Buscar novamente"
          buttonClick={getList}
        />
      );
    }

    if (selectedTab === "email") {
      return renderTable(
        ["Email", "Ação"],
        whitelistEmail.map(info => info.id),
        whitelistEmail.map(info => [
          info.email,
          <ActionsDropdown key={info.id} options={renderOptions(info.email, info.id, setEmail)} />,
        ]),
      );
    } else if (selectedTab === "sms") {
      return renderTable(
        ["SMS", "Ação"],
        whitelistSms.map(info => info.id),
        whitelistSms.map(info => [
          info.cellNumber,
          <ActionsDropdown key={info.id} options={renderOptions(info.cellNumber, info.id, setSms)} />,
        ]),
      );
    } else if (selectedTab === "domain") {
      return renderTable(
        ["Domínio", "Ação"],
        whitelistDomain.map(info => info.id),
        whitelistDomain.map(info => [
          info.domain,
          <ActionsDropdown key={info.id} options={renderOptions(info.domain, info.id, setDomain)} />,
        ]),
      );
    }

    return renderTable(
      ["Login", "Ação"],
      whitelistLogin.map(info => info.id),
      whitelistLogin.map(info => [
        info.cpf,
        <ActionsDropdown key={info.id} options={renderOptions(info.cpf, info.id, setLogin)} />,
      ]),
    );
  };

  return (
    <Container>
      {!loading && renderHeader()}
      <TabNavigator
        onChangeTab={tabValue => {
          setSelectedTab(tabValue as any);
          setWhitelistData("");
          setCurrentId("");
        }}
        selected={selectedTab}
        tabs={tabs()}
      />
    </Container>
  );
});
