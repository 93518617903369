import React from "react";
import * as api from "api/manager-api";
import { colors } from "utils";

import { StatusAproved, StatusPending, StatusRefused } from "../../../../components/Icons/status";
import { Text } from "./styled";

export function StatusBuilder(status: api.StatusProofOfLife) {
  switch (status) {
    case api.StatusProofOfLife.approved:
      return (
        <>
          <StatusAproved />
          <Text color={colors.lightGreen} marginLeft={"11px"} width={"min-content"}>
            Aprovado
          </Text>
        </>
      );
    case api.StatusProofOfLife.approvedManual:
      return (
        <>
          <StatusAproved />
          <Text color={colors.lightGreen} marginLeft={"11px"} width={"min-content"}>
            Aprovado
          </Text>
        </>
      );
    case api.StatusProofOfLife.failed:
      return (
        <>
          <StatusRefused />
          <Text color={colors.darkRed} marginLeft={"11px"} width={"min-content"}>
            Inválido
          </Text>
        </>
      );

    case api.StatusProofOfLife.invalid:
      return (
        <>
          <StatusRefused />
          <Text color={colors.darkRed} marginLeft={"11px"} width={"min-content"}>
            Inválido
          </Text>
        </>
      );
    case api.StatusProofOfLife.reproved:
      return (
        <>
          <StatusRefused />
          <Text color={colors.darkRed} marginLeft={"11px"} width={"min-content"}>
            Reprovado
          </Text>
        </>
      );

    case api.StatusProofOfLife.sendingAcertId:
      return (
        <>
          <StatusPending />
          <Text color={colors.gray} marginLeft={"11px"} width={"min-content"}>
            Processando
          </Text>
        </>
      );

    case api.StatusProofOfLife.sendedAcertId:
      return (
        <>
          <StatusPending />
          <Text color={colors.gray} marginLeft={"11px"} width={"min-content"}>
            Processando
          </Text>
        </>
      );

    case api.StatusProofOfLife.waitingAcertId:
      return (
        <>
          <StatusPending />
          <Text color={colors.gray} marginLeft={"11px"} width={"min-content"}>
            Processando
          </Text>
        </>
      );

    default:
      return (
        <>
          <StatusPending />
          <Text color={colors.gray} marginLeft={"11px"} width={"min-content"}>
            Pendente
          </Text>
        </>
      );
  }
}
