import React, { useEffect, useState } from "react";
import { UserProofOfLife } from "api/manager-api";
import { Loader } from "legacy/components/Loader";

import { SelfContainer } from "./SelfContainer";
import { useStores } from "../../../stores/RootStore";

import s from "./styles.scss";

interface IProps {
  userId?: string;
}

export function UserChangePass({ userId }: IProps) {
  const [usersSelfs, setUserSelfs] = useState<UserProofOfLife[]>([]);
  const [loading, setLoading] = useState(true);

  const { usersStore } = useStores();

  useEffect(() => {
    let isMounted = true;
    const loadUserSelfs = async () => {
      if (!usersStore.userFields && !userId) {
        return;
      }

      const id = usersStore.userFields ? usersStore.userFields.workflowId : userId || "";
      const selfs = await usersStore.getUserPassChangesSelfs(id);

      setLoading(false);

      if (isMounted) {
        setUserSelfs(selfs);
      }
    };

    loadUserSelfs();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <h2 className={s.sectionTitle}>Fotos e Informações</h2>
      {loading ? (
        <div className={s.loaderContainer}>
          <Loader />
        </div>
      ) : (
        usersSelfs.map(self => (
          <div key={self.acertIdKey || self.selfie}>
            <h3 className={s.selfTitle}>Selfie</h3>
            <SelfContainer self={self} />
          </div>
        ))
      )}
    </>
  );
}
