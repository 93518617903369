import React from "react";
import classnames from "classnames";

import { Switch } from "../Switch";

import s from "./style.scss";

interface IProps {
  title: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  description?: string;
  className?: string;
  disabled?: boolean;
}

export function SwitchCard(props: IProps) {
  return (
    <div className={classnames(s.switchCard, props.className)}>
      <div className={s.switch}>
        <Switch checked={props.checked} onChange={() => props.onChange(!props.checked)} disabled={props.disabled} />
      </div>

      <div>
        <div className={s.title}>{props.title}</div>
        <div className={s.description}>{props.description}</div>
      </div>
    </div>
  );
}
