import * as React from "react";
import styled from "styled-components";

import { colors } from "../../utils";
import { OutlinedButton } from "./OutlinedButton";

interface IProps {
  compact?: boolean;
  customMarginTop?: string;
  message: string;
  buttonLabel?: string;
  buttonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const InnerAlert: React.FunctionComponent<IProps> = ({
  compact,
  message,
  buttonLabel,
  buttonClick,
  customMarginTop,
}) => (
  <WarningContainer compact={Boolean(compact)} customMarginTop={customMarginTop}>
    <Warning>{message}</Warning>
    {buttonLabel ? (
      <OutlinedButtonWrapper>
        <OutlinedButton onClick={buttonClick} label={buttonLabel} />
      </OutlinedButtonWrapper>
    ) : (
      undefined
    )}
  </WarningContainer>
);

const WarningContainer = styled.div<{ compact: boolean; customMarginTop?: string }>`
  margin-top: ${({ compact, customMarginTop }) => (customMarginTop ? customMarginTop : compact ? "50px" : "100px")};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Warning = styled.h4`
  font-size: 16px;
  color: ${colors.gray};
  font-family: Lato;
`;

const OutlinedButtonWrapper = styled.div`
  /* max-width: 300px;
	width: 100%; */
`;
