/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as api from "api/manager-api";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

import { DateWithObservationList } from "./DateWithObservationList";
import { DateWithObservationPicker } from "./DateWithObservationPicker";
import { InnerAlert } from "../../../components/InnerAlert";
import { GeneralStore, PlatformManagerStore } from "../../../stores";
import { skeleton as skeletonOperation } from "../Operations";
import { ConfigService } from "./ConfigService";

interface IProps {
  platformManagerStore?: PlatformManagerStore;
  generalStore?: GeneralStore;
}

const skeleton: api.ExceptionOperationsDate = {
  date: moment().toDate(),
  isAutomatic: false,
  obs: "",
  operations: {
    doc: null,
    internalTransfer: null,
    investment: null,
    paymentAgreement: null,
    paymentBankSlip: null,
    recharge: null,
    ted: null,
    titleInclusion: null,
    withdraw: null,
  },
};

@inject("platformManagerStore", "generalStore")
@observer
export class SpecialDates extends React.Component<IProps> {
  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private loadingEditing = false;

  @observable private inputDate: moment.Moment | null = null;

  @observable private inputObs = "";

  @observable private isDialogOpen = false;

  @observable
  private currentSpecialDate: api.ExceptionOperationsDate | null = null;

  public componentDidMount() {
    this.getData();
  }

  @action
  private getData = async () => {
    try {
      this.loading = true;
      this.props.platformManagerStore?.getSpecialDates();
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter as datas especiais.";
    } finally {
      this.loading = false;
    }
  };

  @action
  private save = async (): Promise<boolean> => {
    try {
      this.loadingEditing = true;

      if (!this.currentSpecialDate) {
        throw new Error();
      }

      await this.props.platformManagerStore?.setSpecialDate(this.currentSpecialDate);
      return true;
    } catch (err) {
      this.props.generalStore?.showToast(err.message || "Ocorreu um erro ao salvar a data especial.");
      return false;
    } finally {
      this.loadingEditing = false;
      this.getData();
    }
  };

  @action
  private delete = async (date: Date) => {
    try {
      this.loadingEditing = true;
      await this.props.platformManagerStore?.deleteSpecialDate(date);
    } catch (err) {
      this.props.generalStore?.showToast(err.message || "Ocorreu um erro ao remover a data especial.");
    } finally {
      this.loadingEditing = false;
      this.getData();
    }
  };

  private getIndex = (inputDate: Date) => {
    return this.props.platformManagerStore!.specialDates!.findIndex(
      ({ date }) => date.toISOString() === inputDate.toISOString(),
    );
  };

  private removeItem = (index: number) => {
    return this.props.platformManagerStore!.specialDates!.splice(index, index === -1 ? 0 : 1);
  };

  @action
  private handleInputDate = (date: moment.Moment | null) => {
    this.inputDate = date;
  };

  @action
  private handleInputObs = (obs: string) => {
    this.inputObs = obs;
  };

  @action
  private handleAdd = () => {
    const specialDates = this.props.platformManagerStore?.specialDates;

    this.currentSpecialDate = {
      ...skeleton,
      date: this.inputDate!.toDate(),
      obs: this.inputObs,
    };

    // Improve UX by updating things locally
    const existing = this.getIndex(this.currentSpecialDate.date);

    this.removeItem(existing);
    specialDates?.push(this.currentSpecialDate);

    this.save();
  };

  @action
  private handleEdit = (dateString: string) => {
    const { specialDates } = this.props.platformManagerStore!;

    this.currentSpecialDate = specialDates![this.getIndex(new Date(dateString))];
    this.isDialogOpen = true;
  };

  @action
  private handleRemove = async (date: Date) => {
    // Improve UX by updating things locally
    const existing = this.getIndex(date);

    this.removeItem(existing);

    await this.delete(date);
  };

  @action
  private handleYear = (year: number) => {
    this.props.platformManagerStore!.specialDatesYear = year;
    this.getData();
  };

  @action
  private handleDialogCancel = (oldOperations: api.Operations, oldObs: string) => {
    this.currentSpecialDate!.operations = oldOperations;
    this.currentSpecialDate!.obs = oldObs;
    this.isDialogOpen = false;
    this.currentSpecialDate = null;
  };

  @action
  private handleDialogSubmit = async () => {
    if (await this.save()) {
      this.isDialogOpen = false;
      this.currentSpecialDate = null;
    }
  };

  @action
  private handleDialogObservation = (obs: string) => {
    this.currentSpecialDate!.obs = obs;
  };

  @action
  private handleDialogActiveOperation = (operation: keyof api.Operations, active: boolean) => {
    this.currentSpecialDate!.operations[operation] = active ? skeletonOperation : null;
  };

  @action
  private handleDialogTimeOperation = (operation: keyof api.Operations, value: api.DatePeriod) => {
    this.currentSpecialDate!.operations[operation] = value;
  };

  public render() {
    return (
      <Container>
        {this.renderDialog()}
        {this.renderEdit()}
        {this.renderData()}
      </Container>
    );
  }

  private renderDialog = () => {
    if (!this.currentSpecialDate) {
      return null;
    }

    return (
      <ConfigService
        isDialogOpen={this.isDialogOpen}
        isLoading={this.loadingEditing}
        specialDate={this.currentSpecialDate}
        onCancel={this.handleDialogCancel}
        onSubmit={this.handleDialogSubmit}
        handleObservation={this.handleDialogObservation}
        handleActiveOperation={this.handleDialogActiveOperation}
        handleTimeOperationSpecialDate={this.handleDialogTimeOperation}
      />
    );
  };

  private renderEdit = () => {
    if (!this.props.generalStore?.hasPermission(api.AdminUserPermission.editServiceHours)) {
      return null;
    }

    return (
      <>
        <Title>Feriados e/ou Datas Excepcionais para o Banco</Title>
        <InputRow>
          <DateWithObservationPicker
            data={this.inputDate}
            onChangeData={this.handleInputDate}
            obs={this.inputObs}
            onChangeObs={this.handleInputObs}
            disabled={this.loading}
            onSubmit={this.handleAdd}
            isLoading={this.loadingEditing}
          />
        </InputRow>
      </>
    );
  };

  private renderData = () => {
    const { getSpecialDates, specialDates, specialDatesYear } = this.props.platformManagerStore!;

    if (this.error) {
      return <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={getSpecialDates} />;
    }

    return (
      <ListContainer>
        <DateWithObservationList
          onRemoveSpecialDate={this.handleRemove}
          isLoadindDeleteSpecialDate={this.loadingEditing}
          setYearToViewSpecialDates={this.handleYear}
          yearToViewSpecialDates={specialDatesYear}
          disabled={this.loadingEditing}
          onClickConfig={this.handleEdit}
          isLoadingGetData={this.loading}
          data={specialDates!}
        />
      </ListContainer>
    );
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  margin: 30px 0 0 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0;
`;

const InputRow = styled.div``;

const ListContainer = styled.div`
  margin-top: 40px;
`;
