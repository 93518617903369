/* eslint-disable no-negated-condition */
/* eslint-disable sort-keys */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { action, computed, observable, toJS } from "mobx";
import { AccountCompany, CompanyManagerAccounts, PfPj } from "api/manager-api";

import { LegalEntityStore } from "../../../../stores";
import { Select } from "../../../../components/Select";
import { Row, Title } from "../components";
import { UserPermissions } from "./UserPermissions";

interface IProps {
  legalEntityStore?: LegalEntityStore;
  accounts: AccountCompany[];
  onChange: (accounts: AccountCompany[]) => void;
}

@inject("legalEntityStore")
@observer
export class UserAccounts extends React.Component<IProps> {
  @observable private currentAccount: CompanyManagerAccounts = this.props.legalEntityStore!.company!.accounts[0];

  constructor(props: IProps) {
    super(props);
    this.checkAccounts();
  }

  private getIndexCurrentAccount = () => {
    const { accounts } = this.props;

    const index = accounts.findIndex(
      account => account.branch === this.currentAccount.branch && account.account === this.currentAccount.account,
    );

    return index;
  };

  @action
  private checkAccounts = () => {
    const { accounts } = this.props;

    const index = this.getIndexCurrentAccount();

    if (index === -1) {
      this.props.onChange([
        ...toJS(accounts),
        {
          ...skeleton,
          branch: this.currentAccount.branch,
          account: this.currentAccount.account,
        },
      ]);
    }
  };

  @computed
  private get account(): AccountCompany | undefined {
    const { accounts } = this.props;

    const idx = this.getIndexCurrentAccount();

    return this.props.accounts[idx !== -1 ? idx : toJS(accounts).length];
  }

  @action
  private handleAccount = ({ value }: { value: CompanyManagerAccounts }) => {
    this.currentAccount = value;
    this.checkAccounts();
  };

  private handleChange = (account: AccountCompany) => {
    const accounts = [...this.props.accounts];

    const idx = this.getIndexCurrentAccount();

    accounts[idx !== -1 ? idx : toJS(accounts).length] = account;
    this.props.onChange(accounts);
  };

  public render() {
    const { company } = this.props.legalEntityStore!;
    const options = company!.accounts.map(account => ({
      label: account.account,
      value: account,
    }));

    return (
      <div style={{ width: "650px" }}>
        <Row>
          <Title>Conta</Title>
          <StyledSelect
            value={{
              label: this.account ? this.account.account : "",
              value: this.account,
            }}
            options={options}
            onChange={this.handleAccount}
          />
        </Row>
        <UserPermissions account={this.account} onChange={this.handleChange} />
      </div>
    );
  }
}

const skeleton = {
  profile: {
    id: null,
    name: "",
    companyId: "",
    permissions: [],
  },
  permissions: [],
  name: "",
  type: PfPj.pj,
  documentNumber: "",
  maxAmountDaily: 0,
  yourNumber: 0,
  bank: {
    code: "",
    name: "",
  },
  branch: "",
  account: "",
  subType: null,
  createdAt: new Date(),
};

export const StyledSelect = styled(Select)`
  min-width: 180px;
`;
