/* eslint-disable react/display-name */
import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { useStores } from "@manager/stores/RootStore";
import { ICardParsedData } from "@manager/stores";
import * as api from "api/manager-api";

import { Table } from "components/Table";
import { Modal } from "components/Modal";
import { DropdownActions } from "components/DropdownActions";
import { ReactComponent as AddIcon } from "@assets/svgs/add.svg";

import { IFields } from "../index";
import { ModalCard } from "./ModalCard";

import s from "./style.scss";

export interface IProps {
  values: IFields;
  errors: FormikErrors<IFields>;
  touched: FormikTouched<IFields>;
  partnerId?: string;
  setValues: (values: IFields) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  onSubmit: (value?: boolean) => void;
}

export function ListCardsConfigs(props: IProps) {
  const { generalStore, partnerStore } = useStores();
  const [cardData, setCardData] = React.useState<ICardParsedData[]>();
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [editProduct, setEditProduct] = React.useState(false);
  const [disableFields, setDisableFields] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [shouldBlockProductFields, setShouldBlockProductFields] = React.useState(false);

  async function onSave() {
    setVisibleModal(false);
    props.onSubmit(editProduct);
    setLoading(true);
    setTimeout(() => {
      setCardData(partnerStore.parsedPartnerCardData);
      setLoading(false);
    }, 2000);
  }

  async function removePartnerCard(data: ICardParsedData) {
    if (!props.partnerId) {
      return;
    }

    setLoading(true);
    try {
      const removePartnerData: api.RemovePartnerCard = {
        partnerId: props.partnerId,
        productId: data.productId,
        imageId: data.imageId,
      };

      await partnerStore.removePartnerCard(removePartnerData);
      await partnerStore.getPartnerCards(props.partnerId);
      partnerStore.parseCardData();

      setCardData(partnerStore.parsedPartnerCardData);
    } catch (error) {
      generalStore.showToast(error);
    } finally {
      setLoading(false);
    }
  }

  function fillProductFields(data: ICardParsedData | null, isEdit: boolean) {
    let parsedData: Partial<IFields> = {
      cardBrandDisabled: false,
      broadcaster: null,
      cardBrand: "nenhum",
      visaIdProduct: "",
      visaIdPlastic: "",
      hasFisicCard: false,
      hasVirtualCard: false,
      visaIdComercial: "",
      hasName: false,
      hasNoName: false,
      numberBin: "",
      typeBin: "",
      visaIdImage: "",
      visaImageDescription: "",
      multicards: null,
    };

    if (data) {
      parsedData = {
        cardBrandDisabled: Boolean(data.brand),
        broadcaster: data.broadcaster,
        cardBrand: data.brand,
        visaIdProduct: data.productId,
        visaIdPlastic: data.plasticId,
        hasFisicCard: data.activateCards.physical,
        hasVirtualCard: data.activateCards.virtual,
        visaIdComercial: data.commercialOrigin,
        hasName: data.cardTypeName.name,
        hasNoName: data.cardTypeName.noname,
        numberBin: data.numberBin,
        typeBin: data.typeBin,
        visaIdImage: isEdit ? data.imageId : "",
        visaImageDescription: isEdit ? data.imageDescription : "",
        multicards: data.multicards,
      };
    }

    props.setValues({ ...props.values, ...parsedData });
  }

  function onSelectRow(record: any) {
    const result = cardData?.find(data => {
      return data.id === record.key;
    });

    if (!result) {
      return;
    }

    fillProductFields(result, true);
    setShouldBlockProductFields(false);
    setDisableFields(true);
    setEditProduct(true);
    setVisibleModal(true);
  }

  function onAddNewPartner() {
    if (partnerStore.parsedPartnerCardData.length > 0) {
      setShouldBlockProductFields(true);
      fillProductFields(partnerStore.parsedPartnerCardData[0], false);
    } else {
      setShouldBlockProductFields(false);
      fillProductFields(null, false);
    }

    setDisableFields(false);
    setEditProduct(false);
    setVisibleModal(true);
  }

  React.useEffect(() => {
    setCardData(partnerStore.parsedPartnerCardData);
  }, []);

  return (
    <div>
      <div className={s.title}>Configurações de cartão</div>
      <div className={s.newProduct} onClick={onAddNewPartner}>
        <AddIcon />
        <span className={s.buttonLabel}>
          {cardData && cardData.length > 0 ? "Adicionar novo ID Imagem" : "Adicionar novo ID Produto"}
        </span>
      </div>
      <div className={s.subtitle}>ID Produtos adicionados</div>
      <Table
        className={s.table}
        columns={[
          { align: "left", dataIndex: "description", title: "" },
          // { align: "center", dataIndex: "config", title: "" },
          { align: "center", dataIndex: "product", title: "" },
          { align: "center", dataIndex: "image", title: "" },
          {
            align: "center",
            dataIndex: "options",
            render: (data: ICardParsedData) => (
              <DropdownActions options={[{ label: "Excluir", onClick: async () => removePartnerCard(data) }]} />
            ),
            title: "",
            width: "80px",
          },
        ]}
        data={
          cardData &&
          cardData.map((data: ICardParsedData) => ({
            key: data.id,
            description: data.imageDescription,
            product: `ID Produto ${data.productId}`,
            // Config: `Configuração ID ${data.productId}`,
            image: `ID Imagem ${data.imageId}`,
            options: data,
          }))
        }
        empty={{
          hideIcon: false,
          message: "Nenhum produto cadastrado.",
        }}
        onRow={record => ({
          onClick: () => onSelectRow(record),
        })}
        loading={loading}
        disablePagination
        rowClickableStyle
      />
      {visibleModal && (
        <Modal
          visible={visibleModal}
          title={editProduct ? "Editar ID Produto para o parceiro" : "Novo ID Produto para o parceiro"}
          width={1200}
          onClose={() => {
            setVisibleModal(false);
            setEditProduct(false);
          }}
        >
          <ModalCard
            values={props.values}
            touched={props.touched}
            errors={props.errors}
            blockFields={shouldBlockProductFields}
            disabled={disableFields}
            isEdit={editProduct}
            setFieldValue={props.setFieldValue}
            onCancel={() => setVisibleModal(false)}
            onSave={onSave}
            onEditFields={() => setDisableFields(!disableFields)}
          />
        </Modal>
      )}
    </div>
  );
}
