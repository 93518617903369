import * as api from "api/manager-api";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { colors, listToMatrix } from "utils";
import { Accordion } from "../../Accordion";
import { Dialog } from "../../Dialog";
import { ImagePreview } from "../../ImagePreview";
import { TextShowWithBureau } from "../../TextShowWithBureau";
import { imagePathWithUrl } from "../../../utils";

interface IProps {
  user: api.RegisterUserFields | null;
}

interface IState {
  imageToDialog: any;
  imageDialogTitle: string;
}

@observer
export class Pf extends React.Component<IProps, IState> {
  public state: IState = {
    imageToDialog: null,
    imageDialogTitle: "",
  };

  private openPopup = (image: any, title: string) => {
    const img = new Image();
    img.src = image;

    const width = img.width / 2;
    const height = img.height / 2;

    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;

    const urlInBase64 = btoa(image);

    window.open(
      imagePathWithUrl(`#${urlInBase64}`),
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left,
    );
  };

  public render() {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return (
      <>
        <Dialog
          title={this.state.imageDialogTitle}
          isOpen={!!this.state.imageToDialog}
          onClose={() => this.setState({ imageToDialog: null })}
        >
          <ImageComponent url={this.state.imageToDialog} />
        </Dialog>
        <Container>
          <Column>{this.renderUser()}</Column>
          <Column>
            {this.renderImages()}
            {this.renderBureau()}
          </Column>
        </Container>
      </>
    );
  }

  private renderUser() {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return user.fieldGroups.map((group, index) => (
      <React.Fragment key={index}>
        <Title>{group.name}</Title>
        {group.fields.map((field, index2) => (
          <WrapperTextShowWithBureauMainInfo
            key={index2}
            title={field.label}
            content={field.values.map(value => ({
              data: value.value,
              source: value.source,
            }))}
          />
        ))}
      </React.Fragment>
    ));
  }

  private renderImages = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    const imagesToMap = user && user.documents ? listToMatrix(toJS(user.documents), 2) : [];

    if (!imagesToMap.length) {
      return <SubTitle>Não há documentos para serem mostrados</SubTitle>;
    }

    return (
      <>
        <Title>Documentos e Fotos</Title>
        {imagesToMap.map((row, idx) => (
          <Block key={idx}>
            {row.map(image => (
              <ContentImage key={image.name}>
                <RowContentImage>
                  <TitleContentImage>{image.name}</TitleContentImage>
                </RowContentImage>
                <ImagePreview url={image.url} onClick={() => this.openPopup(image.url, image.name)} />
              </ContentImage>
            ))}
          </Block>
        ))}
      </>
    );
  };

  private renderBureau = () => {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return user.bureaus.map((bureau, index) => (
      <Accordion title={bureau.name} key={index}>
        <ContainerBureau>
          {bureau.fields.map((field, index2) => (
            <RowBureau key={index2}>
              <TitleBureau>{`${field.label}:`}</TitleBureau>
              <MsgBureau>
                {field.url ? (
                  <StyledLink target="_blank" href={field.url}>
                    {field.value}
                  </StyledLink>
                ) : (
                  field.value
                )}
              </MsgBureau>
            </RowBureau>
          ))}
        </ContainerBureau>
      </Accordion>
    ));
  };
}

const StyledLink = styled.a`
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.black};
  padding-left: 3px;
`;

const ContainerBureau = styled.div``;

const RowBureau = styled.div`
  display: flex;
  margin-left: 30px;
  border: solid 1px ${colors.borderGray};
  border-radius: 6px;
  width: 400px;
  height: 48px;
  padding: 20px;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const TitleBureau = styled.span`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray};
  margin-right: 5px;
`;

const MsgBureau = styled.span`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.black};
`;

const Container = styled.main`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child) {
    border-right: 1px solid ${colors.borderGray};
    margin-right: 12px;
  }
`;

const Title = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
`;

const SubTitle = styled.h4`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.black};
`;

const WrapperTextShowWithBureauMainInfo = styled(TextShowWithBureau)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const Block = styled.div`
  display: flex;
  border-left: 3px solid ${colors.lightGray};
  padding-left: 20px;
  /* flex: 1; */
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ContentImage = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1.5;
  max-width: 50%;
  flex-wrap: wrap;
  padding: 5px;
`;

const RowContentImage = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleContentImage = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.black};
  margin-right: 40px;
  margin-bottom: 10px;
  overflow: hidden;
`;

const ImageComponent = styled.div<{ url: any }>`
  width: 400px;
  height: 400px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
