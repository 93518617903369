import * as React from "react";
import styled from "styled-components";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { RaisedButton } from "legacy/components/RaisedButton";

import { OutlinedButton } from "../../../components/OutlinedButton";
import { colors, maskValidateAccount } from "../../../utils";
import { TextInput } from "../../../components/TextInput";

import { IAddMoney, Option } from "..";

interface IProps {
  onClose: () => void;
  onSubmit: (option: Option, data: IAddMoney) => Promise<void>;
}

type FieldError = "account" | "amount";

const numberMask = createNumberMask({
  allowDecimal: true,
  decimalSymbol: ",",
  integerLimit: 9,
  prefix: "R$ ",
  requireDecimal: true,
  suffix: "00",
  thousandsSeparatorSymbol: ".",
});

export function AddMoneyDialog({ onClose, onSubmit }: IProps) {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [branch, setBranch] = React.useState("0001-9");
  const [account, setAccount] = React.useState("");
  const [amount, setAmount] = React.useState("");

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);

    const newErrors: FieldError[] = [];

    if (!account) {
      newErrors.push("account");
    }

    if (!amount) {
      newErrors.push("amount");
    }

    setErrors(newErrors);
    if (newErrors.length > 0) {
      setLoading(false);
      return;
    }

    await onSubmit("addMoney", { account, amount, branch });
    setLoading(false);

    onClose();
  }

  return (
    <Box onSubmit={handleSubmit}>
      <Info>
        <TextInput placeholder="Agência" disabled value={branch} onChange={e => setBranch(e.target.value)} />
        <TextInput
          placeholder="Conta"
          mask={maskValidateAccount}
          value={account}
          onChange={e => setAccount(e.target.value)}
          error={Boolean(errors.find(e => e === "account"))}
        />
        <TextInput
          placeholder="Valor"
          mask={numberMask}
          value={amount}
          onChange={e => setAmount(e.target.value)}
          error={Boolean(errors.find(e => e === "amount"))}
        />
      </Info>
      <InputArea>
        <BackButtonWrapper disabled={loading} type="button" label="Voltar" onClick={onClose} />
        <NextButtonWrapper loading={loading} type="submit" label="Adicionar" />
      </InputArea>
    </Box>
  );
}

const Box = styled.form`
  padding: 16px 24px;
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

const NextButtonWrapper = styled(RaisedButton)`
  min-width: 145px;
  width: fit-content;
  font-family: Lato;
  margin: 15px 0 15px 15px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;

const Info = styled.div`
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.black};
`;
