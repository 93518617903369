/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as api from "api/manager-api";
import { action, observable } from "mobx";
import { fromEvent, merge } from "rxjs";
import { filter, throttleTime, timeout } from "rxjs/operators";

import { StorageFn } from "../utils/StorageFn";
import { ADMIN_USER_TOKEN, RequestFn } from "../utils";
import { RootStore } from "./RootStore";

const SESSION_TIMEOUT = 3.6e6;
const SESSION_THROTTLE = 1.8e6;

export class AuthStore {
  protected rootStore: RootStore;

  private static adminUserStorage: StorageFn<api.AdminUser>;

  @observable public adminUser: api.AdminUser | null = null;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    AuthStore.adminUserStorage = new StorageFn(ADMIN_USER_TOKEN);
    this.initRenewSession();
  }

  @action
  private initRenewSession = async () => {
    merge(
      fromEvent(window, "mousemove"),
      fromEvent(window, "mousedown"),
      fromEvent(window, "keydown"),
      fromEvent(window, "scroll"),
      fromEvent(window, "visibilitychange").pipe(filter(() => !document.hidden)),
    )
      .pipe(throttleTime(SESSION_THROTTLE))
      .pipe(timeout(SESSION_TIMEOUT))
      .subscribe({
        error: async () => {
          if (this.adminUser) {
            await this.onLogout();
            location.reload();
          }
        },
        next: async () => {
          if (this.adminUser) {
            try {
              await api.adminRenewSession();
            } catch (err) {
              await this.onLogout();
              location.reload();
            }
          }
        },
      });
  };

  @action
  public getCurrentUser = async () => {
    this.adminUser = AuthStore.adminUserStorage.load();

    async function request() {
      return api.adminRenewSession();
    }

    const resp = await RequestFn(request);

    if (resp.status === "error") {
      this.adminUser = null;
    }

    return resp;
  };

  @action
  public getAdminUser = async () => {
    const resp = await RequestFn(api.getAdminUser);

    if (resp.data) {
      this.adminUser = resp.data;
      AuthStore.adminUserStorage.save(resp.data);
    }
  };

  @action
  public onLogin = async (cpf: string, password: string) => {
    async function request() {
      return api.adminLogin(cpf, password);
    }

    const resp = await RequestFn(request);

    if (resp.status === "success") {
      this.adminUser = resp.data;
      AuthStore.adminUserStorage.save(resp.data!);
    }

    return resp;
  };

  @action
  public onLogout = async () => {
    try {
      AuthStore.adminUserStorage.remove();
      this.adminUser = null;
      await api.adminLogout();
    } catch (err) {
      throw new Error(err.message);
    }
  };

  @action
  public changeAdminPassword = async (lastPassword: string, newPassword: string, newPasswordConfirm: string) => {
    try {
      await api.changeAdminPassword(lastPassword, newPassword, newPasswordConfirm);
    } catch (err) {
      throw new Error(err.message);
    }
  };
}
