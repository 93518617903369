import * as React from "react";
import CheckboxComponent from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

interface IStyleMaterialUi {
  classes?: any;
  style?: any;
}

interface IProps extends IStyleMaterialUi {
  checked: boolean;
  handleChange: () => void;
  disabled?: boolean;
  color?: string;
}

const styles = () => ({
  root: {
    "&$checked": {
      color: "#c7ab71",
    },
  },
  checked: {},
});

class CheckBoxStyled extends React.Component<IProps, {}> {
  public render() {
    return (
      <CheckboxComponent
        style={this.props.style}
        checked={this.props.checked}
        onChange={this.props.handleChange}
        disabled={this.props.disabled}
        classes={{
          root: this.props.classes.root,
          checked: this.props.classes.checked,
        }}
      />
    );
  }
}

export const Checkbox = withStyles(styles)(CheckBoxStyled);
