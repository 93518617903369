import styled from "styled-components";

import { colors } from "../../../../../../../utils";

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  width: 100%;
`;

export const FieldDateRangePicker = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 160px;
  max-width: 240px;
  color: ${colors.black};

  .DateInput {
    width: 90px;
    height: 40px;
    margin-left: 10px;
  }
`;

export const WrapperTable = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WrapperSwitch = styled.div`
  display: flex;
  flex-direction: inherit;
  align-items: center;
  margin-left: 20px;
`;

export const InfoLabelWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 42px;
`;

export const ContainerInput = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;

export const InfoTitle = styled.span`
  width: 196px;
  text-align: left;
  font-size: 14px;
  color: ${colors.gray};
`;

export const InfoData = styled.span`
  text-align: left;
  font-size: 14px;
  color: ${colors.black};
`;

export const Label = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.violetRed};
  margin: 12px 32px 0 0;
`;

export const ErrorMessage = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #e2574c;
  display: flex;
  margin-top: 43px;
`;

export const LoadingWrapper = styled.div`
  margin-bottom: 64px;
  align-items: center;
`;
