import React from "react";
import classnames from "classnames";
import { positionValues, Scrollbars } from "react-custom-scrollbars";
import RcSelect, {
  components,
  ActionMeta as IActionMeta,
  IndicatorProps,
  MenuListComponentProps,
  OptionProps,
} from "react-select";
import { Option as RcOption } from "react-select/src/filters";

import { ReactComponent as RectangleMini } from "assets/svgs/rectangle-mini.svg";
import { ReactComponent as CheckIcon } from "assets/svgs/done-mini.svg";

import * as s from "./style.scss";

export interface IOption {
  label: string;
  value: string;
  isDisabled?: boolean;
}

interface IProps {
  value: IOption | null;
  options: IOption[];
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  isSearchable?: boolean;
  onChange: (value: IOption) => void;
  filterOption?: (option: RcOption, inputValue: string) => boolean;
  noOptionsMessage?: (value: { inputValue: string }) => string;
  menuIsOpen?: boolean;
  inputId?: string;
}

function IndicatorSeparator() {
  return <></>;
}

function DropdownIndicator(props: IndicatorProps<{}>) {
  return (
    <components.DropdownIndicator {...props}>
      <RectangleMini />
    </components.DropdownIndicator>
  );
}

function MenuList(props: MenuListComponentProps<{}>) {
  const [hasScrollbar, setHasScrollbar] = React.useState(false);

  function calculeScrollbar(position: positionValues) {
    if (position.clientHeight < position.scrollHeight) {
      setHasScrollbar(true);

      return;
    }

    setHasScrollbar(false);
  }

  return (
    <components.MenuList {...props}>
      <Scrollbars
        onUpdate={calculeScrollbar}
        autoHeight
        autoHeightMax={205}
        className={classnames([s.select_custom_scrollbar, hasScrollbar ? s.has_scrollbar : null])}
      >
        {props.children}
      </Scrollbars>
    </components.MenuList>
  );
}

function Option(props: OptionProps<{}>) {
  return (
    <div className={s.select_option}>
      <components.Option {...props} />

      <span className={s.select_option_icon}>
        <CheckIcon />
      </span>
    </div>
  );
}

export function Select(props: IProps) {
  const selectRef = React.useRef(null);

  function onChange(v: any, actionMeta: IActionMeta<any>) {
    props.onChange(v);

    if (actionMeta.action === "select-option") {
      (selectRef.current as any).blur();
    }
  }

  return (
    <RcSelect
      classNamePrefix="bbnk-select"
      menuIsOpen={props.menuIsOpen}
      className={classnames([s.select], props.error ? s.select_error : null)}
      components={{
        DropdownIndicator,
        IndicatorSeparator,
        MenuList,
        Option,
      }}
      inputId={props.inputId}
      isClearable={false}
      isMulti={false}
      ref={selectRef}
      placeholder={props.placeholder}
      isDisabled={props.disabled}
      options={props.options}
      onChange={onChange}
      noOptionsMessage={props.noOptionsMessage}
      value={props.value}
      isSearchable={props.isSearchable}
      filterOption={props.filterOption}
      isOptionDisabled={(opt: any): boolean => opt.isDisabled}
    />
  );
}

Select.defaultProps = {
  noOptionsMessage: () => "Nenhuma opção encontrada",
  placeholder: "",
};
