import * as React from "react";
import { inject, observer } from "mobx-react";
import { requireAuth } from "../PrivateRouteHook";
import { AuthStore } from "../../stores/";
import styled from "styled-components";
import Viewer from "react-viewer";
import "react-viewer/dist/index.css";
import { ToolbarConfig } from "react-viewer/lib/ViewerProps";
import { ActionType } from "react-viewer/lib/Icon";

interface IProps {
  authStore?: AuthStore;
}

export const defaultToolbars: ToolbarConfig[] = [
  {
    key: "zoomIn",
    actionType: ActionType.zoomIn,
  },
  {
    key: "zoomOut",
    actionType: ActionType.zoomOut,
  },
  {
    key: "rotateLeft",
    actionType: ActionType.rotateLeft,
  },
  {
    key: "rotateRight",
    actionType: ActionType.rotateRight,
  },
];

@inject("authStore")
@observer
class ImageRotateRaw extends React.Component<IProps, {}> {
  private formatImageUrl = () => atob(window.location.hash.substring(1));

  private cloneWindow = () => {
    window.close();
  };

  public render() {
    return (
      <>
        <Container>
          <Viewer
            visible
            zoomSpeed={1}
            customToolbar={() => defaultToolbars}
            onClose={() => this.cloneWindow()}
            images={[{ src: this.formatImageUrl(), alt: "" }]}
          />
        </Container>
      </>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageRotate = requireAuth(ImageRotateRaw, true);
