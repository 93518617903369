/* eslint-disable no-nested-ternary */
/* eslint-disable-next-line implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";
import { useStores } from "@manager/stores/RootStore";
import * as api from "api/manager-api";
import { formatDate, openingAccountsSectionWithId } from "@manager/utils";
import { SvgArrowRepeat } from "legacy/icons/SvgArrowRepeat";
import { dateFormatter, timeFormatterOriginal } from "utils";

import { CheckboxList } from "../../components/CheckboxList";
import { Loader } from "../../components/Loader";
import { Table } from "../../components/Table";
import {
  Box,
  ClearOutlinedButton,
  Content,
  Header,
  Line,
  MergeRow,
  Row,
  SecondMergeSpan,
  Spacing,
  StyledSelect,
  Title,
  TitleContainer,
  TitleInputModal,
} from "../../assets/styles/global";

interface IControlTable {
  offset: number;
  limit: number;
  page: number;
}

export interface IFilter {
  partner: ITypeFilterOption | null;
  cpfCnpj: string | number | null;
  lastNotification: ITypeFilterOption | null;
  typesFilter: string[];
}

interface ITypeFilterOption {
  value: string | number | null;
  label: string | null;
}

const initialStateFilter: IFilter = {
  partner: { label: "Todos", value: "all" },
  cpfCnpj: null,
  lastNotification: null,
  typesFilter: ["criticized"],
};

const initialStateTable: IControlTable = {
  offset: 0,
  limit: 10,
  page: 0,
};

export const Positivize: React.FC = () => {
  const { generalStore, partnerStore, routerStore } = useStores();
  const [filter, setFilter] = useState<IFilter>(initialStateFilter);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<api.PartnerFileLot[]>([]);
  const [partnerDataLot, setPartnerDataLot] = useState<api.PartnerFileLot[]>([]);
  const [partnerOption, setPartnerOption] = useState<ITypeFilterOption[]>([]);
  const lastNotificationOption: ITypeFilterOption[] = [
    { label: "Sem notificações", value: null },
    { label: "até 30 dias", value: 30 },
    { label: "até 60 dias", value: 60 },
    { label: "até 90 dias", value: 90 },
    { label: "acima de 90 dias", value: "more" },
  ];
  const [controlTable, setControlTable] = useState<IControlTable>(initialStateTable);

  useEffect(() => {
    setControlTable(controlTable);
  }, [controlTable]);

  async function getPartners() {
    let list: ITypeFilterOption[] = [];

    list.push({ label: "Todos", value: "all" });

    partnerDataLot.forEach(partner => {
      list.push({ label: partner.partnerName, value: partner.partnerId });
    });

    list = list.filter(
      (item, index, self) => index === self.findIndex(t => t.label === item.label && t.value === item.value),
    );
    setPartnerOption(list);
  }

  async function getDataLot(partnerId: string) {
    try {
      setLoading(true);
      await partnerStore.getPartnerPositivize(partnerId);
      if (partnerStore.partnersDataPositivize) {
        setPartnerDataLot(partnerStore.partnersDataPositivize);
      }
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let list: api.PartnerFileLot[] = partnerDataLot;

    if (filter.partner && filter.partner.value && filter.partner.value !== "all") {
      list = list.filter(rowFilt => rowFilt.partnerId === filter.partner?.value);
    }

    if (filter.typesFilter.length > 0) {
      list = list.filter(rowFilt => filter.typesFilter.includes(rowFilt.statusId));
    }

    if (filter.lastNotification && filter.lastNotification.value) {
      list = list.filter(rowFilt => {
        if (filter.lastNotification && filter.lastNotification.value && filter.lastNotification.value === "-") {
          return rowFilt.messageDaysNotification === null;
        }

        if (filter.lastNotification && filter.lastNotification.value && filter.lastNotification.value === "more") {
          return rowFilt.messageDaysNotification && parseInt(rowFilt.messageDaysNotification, 8) > 90;
        }

        if (filter.lastNotification && filter.lastNotification.value) {
          return (
            rowFilt.messageDaysNotification &&
            parseInt(rowFilt.messageDaysNotification, 8) <= filter.lastNotification.value
          );
        }

        return false;
      });
    }

    setResults(list);
  }, [filter]);

  useEffect(() => {
    setResults(partnerDataLot);
    getPartners();
    // SetFilter({ ...filter });
    setFilter({ ...filter, lastNotification: lastNotificationOption[0] });
  }, [partnerDataLot]);

  useEffect(() => {
    getDataLot("all");
  }, []);

  const clearFilter = () => {
    setFilter(initialStateFilter);
  };

  function renderClear() {
    return (
      <ClearOutlinedButton
        label="Limpar"
        onClick={() => clearFilter()}
        iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
      />
    );
  }

  function renderData() {
    if (loading) {
      return <Loader />;
    }

    return getTable();
  }

  function handleFilter(event: any, type: string) {
    let auxValue = event;

    if (event.target) {
      auxValue = event.target.value;
    }

    setFilter({ ...filter, [type]: auxValue });
  }

  function renderPartnerFilter() {
    return (
      <>
        <StyledSelect
          onChange={(e: any) => handleFilter(e, "partner")}
          placeholder="Parceiro"
          value={filter.partner}
          options={partnerOption}
        />
        {/* <Input
          onChange={(e: any) => handleFilter(e, "cpfCnpj")}
          value={filter.cpfCnpj ? filter.cpfCnpj : ''}
          placeholder="Pesquise por CPF/CNPJ ou número da conta"
        /> */}
      </>
    );
  }

  function renderLastNotification() {
    return (
      <StyledSelect
        onChange={(e: any) => handleFilter(e, "lastNotification")}
        placeholder="Última notificação"
        value={filter.lastNotification}
        options={lastNotificationOption}
      />
    );
  }

  function handleSelectOption(typesFilter: string[]) {
    setFilter({ ...filter, typesFilter });
  }

  function renderCheckFilter() {
    const data = [
      {
        label: "Criticado",
        value: "criticized",
      },
      {
        label: "Cancelado",
        value: "canceled",
      },
    ];

    return <CheckboxList items={data} selected={filter.typesFilter} onChange={handleSelectOption} />;
  }

  function onClickRow(id: string) {
    let control = false;

    if (results.length > 0) {
      results.forEach(item => {
        if (item.partnerFileId === parseInt(id, 10)) {
          control = true;
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (control) {
        routerStore.push(`${openingAccountsSectionWithId("positivize")}/historico/${id}`);
      }
    }
  }

  function getTable() {
    function handleChangePage(page: number) {
      setControlTable({ ...controlTable, offset: controlTable.limit * page, page });
    }

    const getStatusFromArray = (type: string[], listProspectStatus: api.PartnerFileStatus[]) => {
      let qty = 0;

      listProspectStatus.forEach(item => {
        if (type.includes(item.statusId)) {
          qty += item.total;
        }
      });
      return qty;
    };

    const columns = [
      "Data Tombamento",
      "Descrição Tombamento",
      "Parceiros",
      "Status",
      "Pendentes",
      "Positivados",
      "Última Campanha",
      "Data Última Campanha",
    ];

    return (
      <>
        <Table
          titles={columns}
          ids={results.map(item => item.partnerFileId.toString())}
          data={results.map((item, index) => {
            if (index >= controlTable.offset && index < controlTable.limit * (controlTable.page + 1)) {
              let days = "-";

              if (item.messageDaysNotification !== "-") {
                days =
                  item.messageDaysNotification && parseInt(item.messageDaysNotification, 8) === 0
                    ? days
                    : item.messageDaysNotification && parseInt(item.messageDaysNotification, 8) > 1
                    ? "dias"
                    : "dia";
                days = `${item.messageDaysNotification} ${days}`;
              }

              return [
                <MergeRow key={index}>
                  <span>{formatDate(item.auditDateTimeRegistration)}</span>
                  <SecondMergeSpan>
                    {timeFormatterOriginal.format(Date.parse((item.auditDateTimeRegistration as any) as string))}
                  </SecondMergeSpan>
                </MergeRow>,
                item.partnerFileShortDescription,
                item.partnerName,
                item.statusName,
                getStatusFromArray(["validated", "briefing"], item.listProspectStatus),
                getStatusFromArray(["positive"], item.listProspectStatus),
                `${days}`,
                item.campaignPositiveDate === "" || item.campaignPositiveDate === null
                  ? "-"
                  : item.campaignPositiveDate,
              ];
            }

            return [];
          })}
          isPagination
          onClickRow={onClickRow}
          totalRows={results.length}
          rowsPerPage={controlTable.limit}
          rowsPerPageOptions={[controlTable.limit]}
          page={controlTable.offset / controlTable.limit}
          handleChangePage={handleChangePage}
        />
      </>
    );
  }

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>Tombamento de Base - Promoção</Title>
        </TitleContainer>
      </Header>
      <Box>
        <Row>
          <Row>
            {renderPartnerFilter()}
            {renderLastNotification()}
          </Row>
          {renderClear()}
        </Row>
        <Spacing />
        <Line />
        <Spacing />
        <Content>
          <Row>{renderCheckFilter()}</Row>
        </Content>
      </Box>
      {renderData()}
      {/* {results.length > 0 && (
        <BackButtonWrapper
          label="Enviar Mensagem"
          disabled={!generalStore.hasPermission(api.AdminUserPermission.sendMensPositivize)}
          style={{ float: "left", marginTop: "20px" }}
          onClick={() => setModalVisibleMessage(true)}
        />
      )}
      */}
      {/* <Historic visible={modalVisible} closeModal={closeModal} data={historic} /> */}
      {/*
      <SendMessage
        visible={modalVisibleMessage}
        closeModal={closeModal}
        data={results}
        dataChecked={checkedRows}
        filter={filter}
        clearSelecteds={clearSelecteds}
      /> */}
    </>
  );
};
