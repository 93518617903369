/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import styled from "styled-components";
import save from "save-file";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { SvgRightArrow } from "../../assets/icons/SvgRightArrow";
import { ConciliationStore, GeneralStore } from "../../stores";

interface IProps {
  generalStore: GeneralStore;
  conciliationStore: ConciliationStore;
}

interface IDates {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

type IFocus = "startDate" | "endDate" | null;

@inject("generalStore", "conciliationStore")
@observer
export class ConciliationManager extends React.Component<IProps> {
  @observable private startDate: moment.Moment | null = moment()
    .subtract(1, "days")
    .startOf("day");

  @observable private endDate: moment.Moment = moment();

  @observable private loading = false;

  @observable private focusedInput: IFocus = null;

  @action
  private handleFocus = (focus: IFocus) => {
    this.focusedInput = focus;
  };

  @action
  private handleDates = ({ startDate, endDate }: IDates) => {
    this.startDate = startDate;
    this.endDate = endDate || moment();
  };

  @action
  private handleDownload = async () => {
    try {
      this.loading = true;
      const buffer = await this.props.conciliationStore!.exportConvenantConciliation(
        this.startDate ? this.startDate.toDate() : null,
        this.endDate.toDate(),
      );

      this.sendDownload(buffer);
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Não foi possível gerar o arquivo.");
    } finally {
      this.loading = false;
    }
  };

  private sendDownload = (buffer: Buffer) => {
    const suffix = this.endDate.format("YYYY-MM-DD");
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `conciliacao-convenio-${suffix}.xlsx`);
  };

  public render() {
    return (
      <Container>
        <Title>Conciliação</Title>
        <Subsection>
          <Subtitle>IS2B - Convênio</Subtitle>
          <Controls>
            <DateRangePicker
              startDate={this.startDate}
              startDateId="start_date_filter"
              endDate={this.endDate}
              endDateId="end_date_filter"
              onDatesChange={this.handleDates}
              focusedInput={this.focusedInput}
              onFocusChange={this.handleFocus}
              startDatePlaceholderText="Data Inicial"
              endDatePlaceholderText="Data Final"
              isOutsideRange={(date: moment.Moment) => date.isAfter(Date.now(), "days")}
              horizontalMargin={49}
              minimumNights={0}
              disabled={this.loading}
              hideKeyboardShortcutsPanel
              customArrowIcon={<SvgRightArrow color={colors.gray} width="7px" height="12px" />}
            />
            <StyledRaisedButton
              label="Baixar Excel"
              loading={this.loading}
              disabled={this.loading}
              onClick={this.handleDownload}
            />
          </Controls>
        </Subsection>
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  color: ${colors.black};
  font-family: Lato;
`;

const Subsection = styled.div`
  margin-top: 32px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;

  .DateInput {
    width: 100px;
    height: 40px;
    margin-left: 10px;
  }

  .DateInput_input {
    height: 40px;
    padding: 0px 0px 0px 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    color: ${colors.gray};
    border-bottom: none;
    ::placeholder {
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      color: ${colors.gray};
    }
  }

  .DateRangePickerInput {
    border: 1px solid ${colors.lightPink};
    border-radius: 4px;
    overflow: hidden;
  }

  .CalendarDay__selected {
    background: ${colors.violetRed};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected:hover {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected_span {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
    &:hover {
      background: ${colors.violetRedOpacity};
      border: ${colors.violetRed};
    }
  }
`;

const StyledRaisedButton = styled(RaisedButton)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  height: 40px;
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
`;
