import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./RootStore";
import { session } from "../utils";

export class LegalEntityStore {
  protected rootStore: RootStore;

  @observable public company: api.CompanyManagerV13 | null = null;

  @observable public usersByCompany: api.UserCompany[] = [];

  @observable public profiles: api.Profile[] = [];

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  public resetInnerState = () => {
    this.company = null;
    this.usersByCompany = [];
    this.profiles = [];
  };

  @session
  @action
  public getUsersByCompany = async (cnpj: string) => (this.usersByCompany = await api.getUsersByCompany(cnpj));

  @session
  @action
  public getCompany = async (cnpj: string) => (this.company = await api.getCompanyV13(cnpj));

  @session
  public setUserInCompany = async (cnpj: string, userCompany: api.UserCompany) => {
    let id = "";

    if (userCompany.id) {
      await api.setUser(userCompany.cpf, cnpj, userCompany.name, userCompany.email, userCompany.phone || "");
    } else {
      const userInCompany = await api.createUserInCompany(
        userCompany.name,
        userCompany.email,
        userCompany.phone || "",
        userCompany.cpf,
        cnpj,
      );

      ({ id } = userInCompany);
    }

    await Promise.all(
      userCompany.accounts.map(async ({ branch, account, maxAmountDaily, profile, permissions }) => {
        api.setPermission(
          userCompany.id || id,
          branch,
          account,
          maxAmountDaily || 0,
          {
            ...profile,
            permissions,
          },
          false,
        );
      }),
    );
  };

  @session
  @action
  public getProfiles = async (cnpj: string) => (this.profiles = await api.getProfiles(cnpj));

  @session
  public setProfile = async (profile: api.Profile) => api.createProfile(profile);
}
