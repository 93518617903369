import React from "react";
import classnames from "classnames";

import * as s from "./style.scss";

interface IProps {
  children: React.ReactNode;
  label?: string;
  htmlFor?: string;
  error?: string;
  className?: string;
}

export function InputLabel(props: IProps) {
  const className = [s.label_input, props.error ? s.label_input_has_error : null, props.className];

  return (
    <div className={classnames(className)}>
      <label className={s.label_input_label} htmlFor={props.htmlFor}>
        {props.label}
      </label>
      {props.children}
      {props.error && <span className={s.label_input_error}>{props.error}</span>}
    </div>
  );
}
