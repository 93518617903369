import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

export const SvgRightArrow = (props: IProps) => {
  const { color, viewBox, width, height, ...restProps } = props;
  return (
    <svg width={width} height={height} {...restProps}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M.233 11.825a.707.707 0 0 0 .932 0l5.449-4.959a1.125 1.125 0 0 0 0-1.696L1.125.175A.707.707 0 0 0 .2.17a.563.563 0 0 0-.007.855l5.023 4.57a.562.562 0 0 1 0 .849L.233 10.977a.562.562 0 0 0 0 .848"
      />
    </svg>
  );
};
