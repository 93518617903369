import React from "react";

import { ReactComponent as RectangleMini } from "assets/svgs/rectangle-mini.svg";

import { Dropdown } from "../Dropdown";
import { CheckboxGroup } from "../Checkbox";

import s from "./style.scss";

export interface IOption {
  label: string;
  value: string;
}

interface IProps {
  options: IOption[];
  value: string[];
  title?: string;
  placement?: "bottomLeft" | "bottomCenter" | "bottomRight" | "topLeft" | "topCenter" | "topRight";
  trigger?: "hover" | "click" | "focus";
  animation?: string;
  visible?: boolean;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  onChange: (value: string[]) => void;
}

export function FilterDropdown(props: IProps) {
  const [isVisible, setIsVisible] = React.useState(false);

  function toggleVisible() {
    setIsVisible(!isVisible);
  }

  return (
    <Dropdown
      placement={props.placement}
      trigger={props.trigger}
      getPopupContainer={props.getPopupContainer}
      animation={props.animation}
      visible={isVisible}
      onVisibleChange={toggleVisible}
      onOverlayClick={() => null}
      overlay={
        <div className={s.overlay_wrapper}>
          <CheckboxGroup onChange={props.onChange} value={props.value} options={props.options} align="block" />
        </div>
      }
    >
      <button className={s.filter_button}>
        <span>{props.title}</span>
        <RectangleMini />
      </button>
    </Dropdown>
  );
}
