import { createElement } from "react";
import { NavLink as NavLinkRouter } from "react-router-dom";
import styled from "styled-components";
import { colors } from "utils";
import * as api from "api/manager-api";

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 248px;
  height: 100vh;
  background: ${colors.darkPurple};
  overflow: hidden;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 33px 0 48px 24px;
`;

export const Menu = styled.nav`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 23px;
`;

export const Footer = styled.div`
  padding: 24px;
  text-align: left;
  box-shadow: 0 -11px 19px -5px rgba(0, 0, 0, 0.1);
  border-top: 1px solid ${colors.black};
`;

export const LogoutBnt = styled.button`
  display: inline-flex;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  color: ${colors.lightPink};
  transition: all ease-in-out 0.3s;
  align-items: center;

  &:hover,
  &:focus {
    color: ${colors.white};
  }
`;

export const Hat = styled.span`
  color: #b4b4b8;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 5px;
`;

export const BadgeContainer = styled.div`
  margin-right: 8px;
`;

export const NavLink = styled(({ isNestedLink, ...props }: any) => createElement(NavLinkRouter, props))`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 700;
  color: ${colors.lightPink};
  text-decoration: none;
  border-left: 1px solid #434356;
  transition: color ease-in-out 0.3s;
  padding-left: ${({ isNestedLink }) => (isNestedLink ? "32px" : "16px")};

  &:hover {
    color: ${colors.lightPink};
    border-radius: 4px;
    background-image: linear-gradient(to right, rgba(247, 83, 183, 0), ${colors.violetRedOpacity});
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: calc(100% + 2px);
    height: 100%;
    border-radius: 0 4px 4px 0;
    z-index: 2;
    opacity: 0;
    transition: all ease-in-out 0.3s;
  }

  & > div {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 3;

    & > span {
      display: flex;
      flex: 1;
      margin-right: 16px;
    }

    & > div {
      padding: 3px 6px;
      display: inline-block;
      line-height: 12px;
      font-size: 12px;
      margin-right: 0;
    }
  }
  & > div > div {
    color: ${colors.white};
    margin-right: 8px;
  }

  &.active {
    color: ${colors.white};
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    position: relative;
    &:before {
      content: "";
      border-radius: 3px;
      background-color: #ed2980;
      width: 3px;
      position: absolute;
      left: 0;
      transform: translateX(-1.5px);
      height: 100%;
    }

    &::after {
      opacity: 1;
    }
  }
`;
