import React from "react";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { partnerManagementPath } from "@manager/utils";

// Components
import { Button } from "components/Button";
import { Spin } from "components/Spin";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow-back.svg";
import { ITabInfo } from "components/Tabs";

import { IFields, PartnerForm } from "../PartnerForms";

// Style
import s from "./style.scss";

export function PartnerAdd() {
  const { routerStore, partnerStore, featureFlagStore, generalStore } = useStores();

  const [loading, setLoading] = React.useState(true);
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [tab, setTab] = React.useState<ITabInfo>({
    allTabKeys: ["registerData", "style", "cards", "settings"],
    index: 0,
    tabKey: "registerData",
  });

  function onBack() {
    routerStore.push(partnerManagementPath);
  }

  async function loadPartner() {
    setLoading(true);

    try {
      // Mock partnerID
      partnerStore.clearPartner();
      await featureFlagStore.getFeaturesPartner("00000000-0000-4917-aed1-000000000000");
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message || "Ocorreu um erro ao carregar dados.");
    }

    setLoading(false);
  }

  function onCancel() {
    routerStore.push(partnerManagementPath);
  }

  async function saveData(values: IFields) {
    setLoadingSend(true);

    const partner: api.Partner = {
      urlApp: {
        android: values.appAndroidUrl,
        ios: values.appIosUrl,
      },
      tokenUrlApp: {
        android: values.appTokenAndroidUrl,
        ios: values.appTokenIosUrl,
      },
      blocked: false,
      managerId: values.managerId,
      publicKey: values.publicKey,
      partnerSecret: "",
      partnerUserName: "",
      allowAnyPartnerLogin: values.allowAnyPartnerLogin,
      openAccountsAttempts: 0,
      id: "",
      name: values.name,
      logo: "",
      backgroundColor: values.colorBg,
      secondaryColor: values.colorSecondary,
      primaryColor: values.colorPrimary,
      font: null,
      fontHref: null,
      errorColor: values.colorError,
      phone: null,
      email: null,
      site: null,
      urlTerm: null,
      urlContract: null,
      urlTermPj: null,
      urlContractPj: null,
      urlFarePackages: null,
      urlIB: values.ibUrl,
      partnerHook: null,
      cobrander:
        values.cardBrand === "nenhum"
          ? null
          : {
              codeCobrander: values.cobranderCode,
              codeCardCategory: values.cobranderCodeCardCategory,
              autoEmitCard: values.cobranderAutoEmitCard,
              isActivate: false,
              username: values.cobranderUsername,
              password: values.cobranderPassword,
            },
      hibotlink: values.chatUrl,
      hibotmobilelink: values.chatUrlMobile,
      cnpj: values.cnpj,
      webhookUrl: null,
      emailResendTime: parseInt(values.emailResendTime, 10),
      emailResendTimeFrequency: parseInt(values.emailResendTimeFrequency, 10),
      emailFrom: values.senderEmail,
      accountOpeningAttemptLimit: parseInt(values.accountOpeningAttemptLimit, 10),
      allowPartnerLot: false,
      managerIdLot: values.managerIdLot,
      allowReduceJourneyWithoutLot: false,
      showWiz: null,
    };

    let cardData: api.CardConfig | null = null;

    if (values.broadcaster && values.multicards) {
      cardData = {
        broadcaster: values.broadcaster,
        brand: values.cardBrand as api.CardBrand,
        productId: values.visaIdProduct,
        plasticId: values.visaIdPlastic,
        activateCards: { physical: values.hasFisicCard, virtual: values.hasVirtualCard },
        commercialOrigin: values.visaIdComercial,
        cardTypeName: { name: values.hasName, noname: values.hasNoName },
        numberBin: values.numberBin,
        typeBin: values.typeBin,
        cardImage: [{ id: values.visaIdImage, descr: values.visaImageDescription }],
        multiCards: values.multicards,
      };
    }

    try {
      const newPartner = await partnerStore.setPartner(
        partner,
        values.logoBuffer,
        values.pfTermsOfUserBuffer,
        values.pfContractBuffer,
        values.pjTermsOfUserBuffer,
        values.pjContractBuffer,
        values.farePackagesBuffer,
        cardData,
      );

      await featureFlagStore.setFeaturesPartner(newPartner.id, values.featuresFlag);

      routerStore.push(`${partnerManagementPath}/${newPartner.id}`);

      return true;
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao salvar parceiro.");

      setLoadingSend(false);
      return false;
    }
  }

  React.useEffect(() => {
    loadPartner();
  }, []);

  return (
    <>
      <div className={s.title}>Adicionar parceiro</div>

      <div className={s.header}>
        <Button theme="neutral" borderType="semi-circle" onClick={onBack}>
          <ArrowIcon />
          <span>Voltar para a lista</span>
        </Button>
      </div>

      {loading && (
        <Spin spinning={loading}>
          <div className={s.loadingContent}></div>
        </Spin>
      )}

      {!loading && errorMessage && (
        <div className={s.errorContainer}>
          <div className={s.errorMessage}>{errorMessage}</div>

          <Button theme="secondary" type="button" onClick={loadPartner}>
            Tentar novamente
          </Button>
        </div>
      )}

      {!loading && !errorMessage && (
        <PartnerForm
          isSequencialTabs
          onSave={saveData}
          onCancel={onCancel}
          loading={loadingSend}
          initialValues={{
            featuresFlag: featureFlagStore.featuresPartner,
          }}
          tab={tab}
          setTab={setTab}
        />
      )}
    </>
  );
}
