import * as api from "api/manager-api";

import { RootStore } from "./RootStore";

export class InternalAPIsStore {
  protected rootStore: RootStore;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public addAccountBalance = async (account: string, branch: string, amount: number) => {
    await api.addAccountBalance(account, branch, amount);
  };

  public testFaceMatch = async (selfie: Buffer, document: Buffer) => {
    return api.testFaceMatch(selfie, document);
  };

  public deleteUserByCpf = async (cpf: string) => {
    await api.deleteUserByCpf(cpf);
  };

  public deleteMEIByCnpj = async (cnpj: string) => {
    await api.deleteMEIByCnpj(cnpj);
  };

  public deleteUserMEIByCnpj = async (cnpj: string) => {
    await api.internalTestDeleteUserMEIByCnpj(cnpj);
  };

  public deleteTokenByCpf = async (cpf: string) => {
    await api.internalTestRemoveToken(cpf);
  };

  public InternalTestCreateAccountPF = async (internalTestCreateAccountPF: api.InternalCreateAccountPF) => {
    await api.InternalTestCreateAccountPF(internalTestCreateAccountPF);
  };

  public internalTestCreateAccountMEI = async (internalTestCreateAccountMEI: api.InternalCreateAccountMEI) => {
    await api.internalTestCreateAccountMEI(internalTestCreateAccountMEI);
  };

  public internalTestPositivizeReducedJourney = async (
    internalTestPositivizeReducedJourney: api.InternalPositivizeReducedJourney,
  ) => {
    await api.internalTestPositivizeReducedJourney(internalTestPositivizeReducedJourney);
  };

  public internalTestUpdateAcertId = async (key: string) => {
    await api.internalTestUpdateAcertId(key);
  };

  public getWhitelistEmail = async () => {
    return api.getWhitelistEmail();
  };

  public getWhiteListSms = async () => {
    return api.getWhiteListSms();
  };

  public getWhitelistDomain = async () => {
    return api.getWhitelistDomain();
  };

  public getWhitelistLogin = async () => {
    return api.getWhitelistLogin();
  };

  public setWhitelistEmail = async (email: string, id: string | null, isDeleted: boolean) => {
    return api.setWhitelistEmail(email, id, isDeleted);
  };

  public setWhiteListSms = async (cellNumber: string, id: string | null, isDeleted: boolean) => {
    return api.setWhiteListSms(cellNumber, id, isDeleted);
  };

  public setWhitelistDomain = async (domain: string, id: string | null, isDeleted: boolean) => {
    return api.setWhitelistDomain(domain, id, isDeleted);
  };

  public setWhitelistLogin = async (cpf: string, id: string | null, isDeleted: boolean) => {
    return api.setWhitelistLogin(cpf, id, isDeleted);
  };

  public getCPFList = async () => {
    return api.getCPFList();
  };

  public addCPFList = async (cpf: string) => {
    return api.addCPFList(cpf);
  };

  public deleteCPFList = async (cpf: string) => {
    return api.deleteCPFList(cpf);
  };

  public downloadCPFList = async () => {
    return api.downloadCPFList();
  };

  public getAddressByCEP = async (cep: string) => {
    return api.getAddressByCep(cep);
  };
}
