import styled from "styled-components";
import { colors } from "utils";

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Name = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
`;

export const SelectWrapper = styled.div`
  margin-left: 60px;
  width: 150px;
`;
