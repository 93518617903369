import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  opacity?: string;
}

export const SvgEdit = (props: IProps) => {
  const { color, opacity, viewBox, ...restProps } = props;
  return (
    <svg style={{ width: "20px", height: "20px" }} {...restProps}>
      <path
        d="M17.3334 5.03331C17.3341 4.92363 17.313 4.81492 17.2716 4.71338C17.2301 4.61185 17.169 4.51951 17.0918 4.44164L13.5584 0.908307C13.4806 0.831072 13.3882 0.769968 13.2867 0.728497C13.1852 0.687027 13.0764 0.666006 12.9668 0.66664C12.8571 0.666006 12.7484 0.687027 12.6468 0.728497C12.5453 0.769968 12.453 0.831072 12.3751 0.908307L10.0168 3.26664L0.908429 12.375C0.831194 12.4528 0.77009 12.5452 0.728619 12.6467C0.687149 12.7482 0.666128 12.857 0.666762 12.9666V16.5C0.666762 16.721 0.754559 16.9329 0.91084 17.0892C1.06712 17.2455 1.27908 17.3333 1.5001 17.3333H5.03343C5.15003 17.3396 5.26667 17.3214 5.37578 17.2798C5.48488 17.2381 5.58402 17.174 5.66676 17.0916L14.7251 7.98331L17.0918 5.66664C17.1678 5.58587 17.2298 5.49292 17.2751 5.39164C17.2831 5.32521 17.2831 5.25806 17.2751 5.19164C17.279 5.15285 17.279 5.11376 17.2751 5.07497L17.3334 5.03331ZM4.69176 15.6666H2.33343V13.3083L10.6084 5.03331L12.9668 7.39164L4.69176 15.6666ZM14.1418 6.21664L11.7834 3.85831L12.9668 2.68331L15.3168 5.03331L14.1418 6.21664Z"
        fill={color || "#ED2980"}
      />
    </svg>
  );
};
