import * as React from "react";

import { Loader } from "../Loader";

import { InnerAlert } from "..";

import { Fetchable } from "../Fetchable";

interface IProps<T> {
  fetch: Promise<T> | null;
  errorMessage?: string;
  errorButtonLabel?: string;
  errorButtonClick?: () => void;
  children: (data: T) => JSX.Element;
}

export function FetchableItem<T>({
  fetch,
  errorMessage,
  errorButtonLabel,
  errorButtonClick,
  children,
}: IProps<T>): JSX.Element | null {
  const loading = () => <Loader />;

  const error = (err: Error) => (
    <InnerAlert
      message={errorMessage || err.message || "Erro ao obter os dados."}
      buttonLabel={errorButtonLabel}
      buttonClick={errorButtonClick}
    />
  );

  return (
    <Fetchable fetch={fetch} loading={loading} error={error}>
      {data => children(data)}
    </Fetchable>
  );
}
