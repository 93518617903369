/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { ToDoErros } from "api/manager-api";
import moment from "moment";

import { defaultMessage } from "..";

import { noop } from "rxjs";
import { truncateStr } from "utils";

import { Table } from "../../../components/Table";

interface IProps {
  items: ToDoErros[];
  onEdit?: (error: ToDoErros) => void;
  filter: string | null;
}

export function ErrorsList({ items, onEdit, filter }: IProps) {
  const titles = ["Data/Hora", "Erro", "Mensagem", "Origem"];

  function match(str: string) {
    return str.toLowerCase().includes(filter!.toLowerCase());
  }

  function handleFilter(item: ToDoErros) {
    return filter ? match(item.error) || match(item.message) : true;
  }

  function sortByDate(a: ToDoErros, b: ToDoErros) {
    return moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1;
  }

  function handleClick(strId: string) {
    // Tslint:disable-next-line: radix
    const item = items.find(({ id }) => id === parseInt(strId, 10));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return (onEdit || noop)(item!);
  }

  function capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  function verifyGuilty(value: string | null) {
    const defaultGuilty = "Não especificado";

    if (value && value !== "unspecified") {
      return capitalize(value);
    }

    return defaultGuilty;
  }

  const list = items.filter(handleFilter).sort(sortByDate);

  return (
    <Table
      titles={titles}
      ids={list.map(({ id }) => id.toString())}
      data={list.map(row => [
        moment(row.createdAt).format("L [às] HH:mm"),
        truncateStr(row.error, 100),
        truncateStr(row.message || defaultMessage, 100),
        truncateStr(verifyGuilty(row.guilty), 100),
      ])}
      onClickRow={handleClick}
    />
  );
}
