/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { AccountCompany, UserCompany } from "api/manager-api";
import { inject, observer } from "mobx-react";
import { action, observable, toJS } from "mobx";
import { colors } from "utils";

import { SaveButton, TabsContainer } from "../components";
import { UserData } from "./UserData";
import { UserAccounts } from "./UserAccounts";
import { GeneralStore, LegalEntityStore } from "../../../../stores";
import { TabNavigator } from "../../../../components/TabNavigator";

interface IProps {
  userCompany: UserCompany | null;
  legalEntityStore?: LegalEntityStore;
  generalStore?: GeneralStore;
  close: () => void;
}

@inject("legalEntityStore", "generalStore")
@observer
export class UserWizard extends React.Component<IProps> {
  @observable private userCompany: UserCompany = toJS(this.props.userCompany) || {
    accounts: [],
    blocked: null,
    cpf: "",
    email: "",
    id: "",
    name: "",
    phone: "",
  };

  @observable private loading = false;

  @observable private selectedTab = "userData";

  @action
  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: HTMLInputElement = event.currentTarget;

    this.userCompany[name as Exclude<keyof UserCompany, "blocked" | "accounts">] = value;
  };

  @action
  private handleAccounts = (accounts: AccountCompany[]) => {
    this.userCompany = {
      ...this.userCompany,
      accounts,
    };
  };

  @action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private save = async (event: any) => {
    event.preventDefault();

    const { company, setUserInCompany, getUsersByCompany } = this.props.legalEntityStore!;

    try {
      this.loading = true;
      await setUserInCompany(company!.documentNumber, this.userCompany);
      this.props.close();
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao salvar o usuário.");
    } finally {
      await getUsersByCompany(company!.documentNumber);
      this.loading = false;
    }
  };

  @action
  private onChangeTab = (value: string) => {
    this.selectedTab = value;
  };

  private get tabs() {
    return [
      {
        child: <UserData userCompany={this.userCompany} onChange={this.handleChange} />,
        label: "Dados cadastrais",
        value: "userData",
      },
      {
        child: <UserAccounts accounts={this.userCompany.accounts} onChange={this.handleAccounts} />,
        label: "Contas",
        value: "userAccounts",
      },
    ];
  }

  public render() {
    return (
      <TabsContainer>
        <TabNavigator onChangeTab={this.onChangeTab} selected={this.selectedTab} tabs={this.tabs} />
        <SaveButton
          label={this.props.userCompany ? "Editar usuário" : "Adicionar usuário"}
          onClick={this.save}
          customColor={colors.violetRed}
          loading={this.loading}
        />
      </TabsContainer>
    );
  }
}
