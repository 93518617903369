import React from "react";
import classNames from "classnames";

import s from "./style.scss";

export interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  value: string;
  id?: string;
  name?: string;
  positionLabel?: "top" | "right" | "bottom" | "left";
  disabled?: boolean;
}

export function Radio({ positionLabel, label, ...inputProps }: IProps) {
  return (
    <li className={classNames(s.radioGroup, positionLabel && positionLabel)}>
      <input type="radio" {...inputProps} />
      <label className={s.check} htmlFor={inputProps.id} />
      <label className={s.mainLabel} htmlFor={inputProps.id}>
        {label}
      </label>
    </li>
  );
}

Radio.defaultProps = {
  positionLabel: "right",
};
