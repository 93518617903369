import styled from "styled-components";

import { colors } from "../../../../../utils";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  color: ${colors.black};
  padding: 0;
  margin: 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;
