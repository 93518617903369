/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import styled from "styled-components";
import { formatCpf } from "@brazilian-utils/formatters";
import { AdminUserPermission, SubType, UserCompany } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { GeneralStore, LegalEntityStore } from "../../../stores";
import { userProfileManagerPath } from "../../../utils";
import { Dialog } from "../../../components/Dialog";
import { Table } from "../../../components/Table";
import { ActionsDropdown } from "../../../components/ActionsDropdown";
import { UserWizard } from "../DialogsContent";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  routerStore?: RouterStore;
  legalEntityStore?: LegalEntityStore;
  generalStore?: GeneralStore;
}

@inject("routerStore", "legalEntityStore", "generalStore")
@observer
export class LegalEntityMEIUsers extends React.Component<IProps> {
  @observable private isDialogOpen = false;

  @observable private currentUserCompany: UserCompany | null = null;

  @action
  private handleClose = () => {
    this.isDialogOpen = false;
  };

  @action
  private createUserCompany = () => {
    this.currentUserCompany = null;
    this.isDialogOpen = true;
  };

  @action
  private editUserCompany(userCompany: UserCompany) {
    this.currentUserCompany = userCompany;
    this.isDialogOpen = true;
  }

  public render() {
    const { company } = this.props.legalEntityStore!;
    const isMEI = company && company.isMEI;
    const displayAddButton =
      this.props.generalStore!.hasPermission(AdminUserPermission.editRepresentativeCompany) && !isMEI;

    return (
      <>
        <Dialog
          title={this.currentUserCompany ? "Editar usuário" : "Adicionar usuário"}
          isOpen={this.isDialogOpen}
          onClose={this.handleClose}
          padding={"0 20px 20px"}
          maxWidth={false}
          showCloseButton
        >
          <UserWizard userCompany={this.currentUserCompany} close={this.handleClose} />
        </Dialog>
        <Container>
          {displayAddButton && (
            <AddButtonWrapper
              type="button"
              label="Adicionar usuário"
              customColor={colors.violetRed}
              lowerCase
              onClick={this.createUserCompany}
            />
          )}

          {this.renderData()}
        </Container>
      </>
    );
  }

  private renderData() {
    const { usersByCompany } = this.props.legalEntityStore!;

    if (!usersByCompany.length) {
      return <InnerAlert compact message="Não há usuários cadastrados" />;
    }

    return (
      <Table
        titles={["Nome", "CPF", "E-mail", "Ações"]}
        ids={usersByCompany.map(e => e.id)}
        data={usersByCompany.map(userCompany => [
          userCompany.name,
          formatCpf(userCompany.cpf),
          userCompany.email,
          <ActionsDropdown key={userCompany.id} options={this.renderActions(userCompany)} />,
        ])}
      />
    );
  }

  private renderActions(userCompany: UserCompany) {
    const isSimple = userCompany.accounts.find(e => e.subType === SubType.simple);
    const actions = [
      {
        label: "Gerir usuário",
        onClick: () => {
          return this.props.routerStore!.push(userProfileManagerPath(userCompany.cpf, isSimple ? "simples" : "full"));
        },
      },
    ];

    if (this.props.generalStore!.hasPermission(AdminUserPermission.recoveryUser)) {
      actions.push({
        label: "Editar dados",
        onClick: () => this.editUserCompany(userCompany),
      });
    }

    return actions;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const AddButtonWrapper = styled(RaisedButton)`
  width: fit-content;
  font-weight: bold;
  font-family: Lato;
  background-color: ${colors.violetRed};
  margin: 10px;
  font-size: 14px;
  padding: 12px 20px;
`;
