/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { em } from "polished";
import { AdminUserPermission, Attached, PfPj } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { GeneralStore, OpeningAccountsStore } from "../../../stores";
import { FileAttachment } from "../../../components/FileAttachment";
import { SvgClip } from "../../../components/Icons/SvgClip";
import { Loader } from "../../../components/Loader";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  openingAccountsStore?: OpeningAccountsStore;
  generalStore?: GeneralStore;
}

@inject("openingAccountsStore", "generalStore")
@observer
export class Chat extends React.Component<IProps> {
  @observable private textInput = "";

  @observable private file: { name: string; data: Buffer } | null = null;

  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private loadingAction = false;

  private fileRef = React.createRef<HTMLInputElement>();

  public componentDidMount() {
    this.getData();
  }

  private getData = async () => {
    const { pendingUser, pendingCompany, getFeedbacksByTargetIdAndType } = this.props.openingAccountsStore!;

    try {
      this.loading = true;
      const companyType = pendingCompany && pendingCompany.isMEI ? PfPj.mei : PfPj.pj;
      const type = pendingUser ? PfPj.pf : companyType;
      const targetId = pendingUser ? pendingUser.workflowId : pendingCompany!.id;

      await getFeedbacksByTargetIdAndType(targetId, type);

      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter dados de feedback.";
    } finally {
      this.loading = false;
    }
  };

  @computed
  private get textInputStyle(): React.CSSProperties | undefined {
    return this.textInput
      ? {
          flex: "auto",
          marginBottom: "0.5rem",
        }
      : undefined;
  }

  @action
  private onChangeTextInput = ({ currentTarget: el }: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.textInput = el.value;
    el.style.height = "0px";
    el.style.height = `${el.scrollHeight}px`;
  };

  @action
  private handleFile = (name: string, data: Buffer) => {
    this.file = { data, name };
  };

  @action
  private onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    // eslint-disable-next-line no-negated-condition
    const file = files !== null ? files[0] : null;

    if (file) {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          if (reader.result && typeof reader.result !== "string") {
            this.handleFile(file.name, Buffer.from(reader.result));
          }
        },
        false,
      );
      reader.readAsArrayBuffer(file);
    }
  };

  private FileInput = () => (
    <input
      ref={this.fileRef}
      style={{ display: "none" }}
      type="file"
      onClick={({ currentTarget: el }) => (el.value = "")}
      onChange={this.onChangeFile}
      accept="application/pdf, application/vnd.ms-excel, image/png"
    />
  );

  private handleSend = async () => {
    const { createFeedback, pendingUser, pendingCompany } = this.props.openingAccountsStore!;
    const companyType = pendingCompany && pendingCompany.isMEI ? PfPj.mei : PfPj.pj;
    const type = pendingUser ? PfPj.pf : companyType;
    const targetId = pendingUser ? pendingUser.workflowId : pendingCompany!.id;

    try {
      this.loadingAction = true;
      await createFeedback(
        targetId,
        type,
        this.textInput,
        this.file ? this.file.data : null,
        this.file ? this.file.name : "",
      );
      this.textInput = "";
      this.file = null;
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao enviar sua mensagem.");
    } finally {
      this.loadingAction = false;
    }
  };

  private handleDownload = (attachment: Attached) => () => {
    window.open(attachment.url!);
  };

  public render() {
    const { feedbacks } = this.props.openingAccountsStore!;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={this.getData} />;
    }

    if (feedbacks.length === 0) {
      return (
        <Container>
          <Messages>
            <InnerAlert message="Não há histórico de mensagens." />
            {this.renderInput()}
          </Messages>
        </Container>
      );
    }

    return (
      <Container>
        <Messages>
          {feedbacks.map((feedback, i) => (
            <MessageHistory key={i}>
              <MessageAuthor>{feedback.adminUser.name}</MessageAuthor>
              <MessageCreatedAt>{moment(feedback.createdAt).format("L [às] LT")}</MessageCreatedAt>
              {feedback.message ? <MessageText>{feedback.message}</MessageText> : null}
              {feedback.attached
                ? feedback.attached.map((attachment, index) => (
                    <FileAttachment
                      key={index}
                      name={attachment.name}
                      downloadable
                      onClick={this.handleDownload(attachment)}
                      style={{
                        marginBottom: "18px",
                        width: "fit-content",
                      }}
                    />
                  ))
                : null}
            </MessageHistory>
          ))}
        </Messages>
        {this.renderInput()}
      </Container>
    );
  }

  private renderInput = () => {
    const { FileInput } = this;
    const { hasPermission } = this.props.generalStore!;

    if (!hasPermission(AdminUserPermission.sendFeedback)) {
      return null;
    }

    return (
      <MessageBox>
        <TextInputTitle>Enviar nova mensagem ou arquivo:</TextInputTitle>
        <FileInput />
        <InputBox>
          {Boolean(this.file) && (
            <FileAttachment name={this.file?.name ?? ""} onRemoveClick={action(() => (this.file = null))} removable />
          )}
          <InputMessage>
            <TextInput value={this.textInput} rows={1} onChange={this.onChangeTextInput} style={this.textInputStyle} />
            <InputBoxButtonsWrapper>
              <Clip onClick={() => this.fileRef.current!.click()} height={em(18)} width={em(16)} />
              <ButtonRaised label="Enviar" onClick={this.handleSend} loading={this.loadingAction} />
            </InputBoxButtonsWrapper>
          </InputMessage>
        </InputBox>
      </MessageBox>
    );
  };
}

const Container = styled.div`
  width: 100%;
`;

const Messages = styled.div`
  max-height: 600px;
  overflow-y: auto;
  margin: 0px 10px;
`;

const MessageHistory = styled.div`
  margin-bottom: 18px;
  border-bottom: 1px solid ${colors.borderGray};
`;

const MessageAuthor = styled.span`
  display: block;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.black};
`;

const MessageCreatedAt = styled.span`
  display: block;
  font-family: Lato;
  font-size: 12px;
  color: ${colors.gray};
  margin-bottom: 18px;
`;

const MessageText = styled.p`
  display: block;
  margin: 18px;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
`;

const MessageBox = styled.div`
  display: block;
  background-color: ${colors.borderGray};
  padding: 18px 18px 20px 18px;
  margin-right: 20px;
`;

const TextInputTitle = styled.span`
  font-family: Lato;
  display: block;
  font-size: 14px;
  color: ${colors.black};
  margin-bottom: 5px;
`;

const TextInput = styled.textarea`
  flex: 1;
  width: 100%;
  max-height: ${em(110)};
  resize: none;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 3px;
  font-family: Lato;
  color: ${colors.black};
  background-color: transparent;
`;

const InputBox = styled.label`
  display: grid;
  grid-template-rows: "auto auto";
  row-gap: 10px;
  width: 100%;
  padding: 0.5rem;
  border-radius: 3px;
  border: ${colors.gray} solid 0.7px;
  background-color: ${colors.white};
`;

const InputMessage = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;

const InputBoxButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`;

const Clip = styled(SvgClip)`
  cursor: pointer;
  color: ${colors.gray};
  margin-right: 18px;
`;

const ButtonRaised = styled(RaisedButton)`
  color: ${colors.white};
  background: ${colors.violetRed};
  padding: 15px;
  width: 90px;
  height: 25px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
