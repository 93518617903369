import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { ClientLimit } from "api/manager-api";
import { Loader } from "legacy/components/Loader";

import { searchMask } from "../../../../../utils";
import { Table } from "../../../../../components/Table";
import { Dialog } from "../../../../../components/Dialog";
import { DateRange, DateRangePicker } from "../../../../../components/DateRangePicker";
import { Switch } from "../../../../../components/Switch";
import {
  AlertModal,
  Container,
  ErrorText,
  Input,
  Right,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextArea,
  StyledTextInput,
  Subtitle,
  Text,
  Title,
} from "../style";
import {
  ContainerInput,
  ErrorMessage,
  FieldDateRangePicker,
  InfoData,
  InfoLabelWrapper,
  InfoRow,
  InfoTitle,
  Label,
  LoadingWrapper,
  Wrapper,
  WrapperSwitch,
  WrapperTable,
} from "./style";
import { currencyFormatter } from "../../../../../../../utils";
import { TransactionalBoundariesStore } from "../../../../../stores";
import { SvgError } from "../../../../../../../legacy/icons/SvgError";

interface IProps {
  transactionalBoundariesStore?: TransactionalBoundariesStore;
  createClientCustomization: (ref: boolean) => void;
  clientLimitEdit: ClientLimit | null;
}

const errorMessageDefault = "Não foi possível realizar a operação";

@inject("transactionalBoundariesStore")
@observer
export class ClientCustomizationWizard extends React.Component<IProps> {
  private MAXAMOUNT = 999999999;

  @observable private saveDialogOpen = false;

  @observable private ticketNumber = "";

  @observable private comment = "";

  @observable private documentNumber = "";

  @observable private errorMessage = "";

  @observable private errorModalMessage = errorMessageDefault;

  @observable private startDate: Date | null = null;

  @observable private endDate: Date | null = null;

  @observable private disabled = false;

  @observable private clientLimit: ClientLimit | null = this.props.clientLimitEdit;

  @observable private empty = false;

  @observable private notEdited = true;

  @observable private errorOpen = false;

  @observable private loading = false;

  @action
  public async componentDidMount() {
    if (this.props.clientLimitEdit === null) {
      try {
        await this.props.transactionalBoundariesStore!.formatTrasactionalOperations();
      } catch {
        this.errorOpen = true;
      }

      this.clientLimit = {
        account: "",
        cpfCnpj: "",
        finalDate: null,
        initialDate: new Date(),
        name: "",
        observation: "",
        ticket: "",
        transactionRequestDto: this.props.transactionalBoundariesStore!.clientLimitCreate!,
        type: null,
      };
    } else {
      this.documentNumber = searchMask(this.clientLimit!.cpfCnpj);
      this.loading = true;

      try {
        await this.props.transactionalBoundariesStore!.getClientLimitByCpfCnpj(this.documentNumber, true);
        [this.clientLimit] = this.props.transactionalBoundariesStore!.clientLimit;
        this.startDate = this.isPastDate(this.clientLimit.initialDate) ? new Date() : this.clientLimit.initialDate;
        this.endDate = this.clientLimit.finalDate;
        this.disabled = this.endDate === null;
      } catch {
        this.errorOpen = true;
      } finally {
        this.loading = false;
      }
    }
  }

  private checkNull = (e: ClientLimit | null) => {
    let count = 0;

    if (e) {
      e.transactionRequestDto!.forEach(lim => {
        if (lim.operation === 0 || lim.daily === 0) {
          count++;
        }
      });
      if (count > 0) {
        this.empty = true;
      } else {
        this.empty = e.account === "";
      }
    }
  };

  private saveClientLimit = async (clientLimit: ClientLimit) => {
    if (this.validateDateRange()) {
      return false;
    }

    try {
      if (this.props.clientLimitEdit) {
        const limits = {
          ...clientLimit,
          finalDate: this.endDate,
          initialDate: this.startDate ? this.startDate : new Date(),
        };

        await this.props.transactionalBoundariesStore!.updateClientLimit(limits);
        this.props.createClientCustomization(!this.notEdited);
      } else {
        this.saveDialogOpen = true;
      }
    } catch {
      this.errorOpen = true;
    }

    return true;
  };

  public getClientCustomization = async () => {
    this.loading = true;

    try {
      await this.props.transactionalBoundariesStore!.getClientLimitByCpfCnpj(this.documentNumber, false);
      [this.clientLimit] = this.props.transactionalBoundariesStore!.clientLimit;
    } catch {
      this.errorMessage = "Não foi encontrado cliente com esse número de documento";
    } finally {
      this.loading = false;
    }
  };

  @action
  private handleDates = ({ startDate, endDate }: DateRange) => {
    this.startDate = startDate;
    this.endDate = endDate;
    this.notEdited = false;
  };

  private handleSearch = () => {
    this.getClientCustomization();
  };

  private isPastDate = (receivedDate: Date) => {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return receivedDate < today;
  };

  private validateDateRange = () => {
    if ((this.startDate && this.isPastDate(this.startDate)) || (this.endDate && this.isPastDate(this.endDate))) {
      this.errorModalMessage = "O período informado é inválido";
      this.errorOpen = true;
      return true;
    }

    return false;
  };

  private handleCreate = async (clientLimit: ClientLimit) => {
    if (this.validateDateRange()) {
      return false;
    }

    this.saveDialogOpen = false;
    const limits = {
      ...clientLimit,
      finalDate: this.endDate,
      initialDate: this.startDate ? this.startDate : new Date(),
      observation: this.comment,
      ticket: this.ticketNumber,
    };

    try {
      await this.props.transactionalBoundariesStore!.createClientLimit(limits);
      this.props.createClientCustomization(!this.notEdited);
    } catch {
      this.errorOpen = true;
    }

    return true;
  };

  public render() {
    const columns = ["Transações", "Limite por transação*", "Limite Diário*", "Quantidade por dia"];

    return this.clientLimit ? (
      <>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"md"}
          title={"Salvar alterações?"}
          isOpen={this.saveDialogOpen}
          onClose={() => (this.saveDialogOpen = false)}
        >
          <Text>Nº de Ticket do atendimento (Não obrigatório)</Text>
          <StyledTextInput value={this.ticketNumber} onChange={e => (this.ticketNumber = e.target.value)} />
          <Text>Comentário (Não obrigatório)</Text>
          <StyledTextArea value={this.comment} onChange={e => (this.comment = e.target.value)} />
          <Right>
            <StyledOutlinedButton onClick={() => (this.saveDialogOpen = false)} label="Cancelar" type="submit" />
            <StyledRaisedButton
              onClick={async () => this.handleCreate(this.clientLimit!)}
              label="Salvar"
              type="submit"
            />
          </Right>
        </Dialog>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => {
            this.errorOpen = false;
            this.errorModalMessage = errorMessageDefault;
          }}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>{this.errorModalMessage}</ErrorText>
          </AlertModal>
        </Dialog>
        <Wrapper>
          <Container style={{ marginTop: "24px" }}>
            <Title>Localizar Cliente*</Title>
            <Input
              value={this.documentNumber}
              onChange={e => (this.documentNumber = searchMask(e.target.value))}
              disabled={this.props.clientLimitEdit !== null}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  this.handleSearch();
                }
              }}
            />
            {this.props.clientLimitEdit === null ? (
              <StyledRaisedButton
                onClick={async () => this.handleSearch()}
                label="Buscar"
                style={{ margin: "0 16px", width: "120px" }}
                type="button"
              />
            ) : null}
          </Container>
          <Subtitle>Informações principais</Subtitle>
          {this.loading ? (
            <LoadingWrapper>
              <Loader />
            </LoadingWrapper>
          ) : (
            <>
              <InfoLabelWrapper>
                <InfoRow>
                  <InfoTitle>Nome</InfoTitle>
                  <InfoData>{this.clientLimit.name ? this.clientLimit.name : "-"}</InfoData>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>CPF/CNPJ</InfoTitle>
                  <InfoData>{this.clientLimit.cpfCnpj ? searchMask(this.clientLimit.cpfCnpj) : "-"}</InfoData>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>Tipo de Pessoa</InfoTitle>
                  <InfoData>{this.clientLimit.type ? this.clientLimit.type.toUpperCase() : "-"}</InfoData>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>Número da conta</InfoTitle>
                  <InfoData>{this.clientLimit.account ? searchMask(this.clientLimit.account, true) : "-"}</InfoData>
                </InfoRow>
                <InfoRow>
                  <InfoTitle>Período da personalização*</InfoTitle>
                  <FieldDateRangePicker style={this.disabled ? { pointerEvents: "none" } : {}}>
                    <DateRangePicker
                      startDate={this.disabled ? null : this.startDate}
                      endDate={this.endDate}
                      onChange={this.handleDates}
                      startLabel="Início"
                      endLabel="Final"
                    />
                  </FieldDateRangePicker>
                  <WrapperSwitch>
                    <Switch
                      checked={this.disabled}
                      handleChange={() => {
                        this.startDate = this.disabled ? null : new Date();
                        this.disabled = !this.disabled;
                        this.endDate = null;
                        this.notEdited = false;
                      }}
                    />
                    Indeterminado
                  </WrapperSwitch>
                </InfoRow>
              </InfoLabelWrapper>
              <Subtitle>Limite de Operação</Subtitle>
              <WrapperTable>
                <Table
                  titles={columns}
                  ids={this.clientLimit.transactionRequestDto!.map(e => e.title)}
                  rowsPerPage={5}
                  data={this.clientLimit.transactionRequestDto!.map(limit => {
                    let operationFormat: string = limit.operation!.toString();
                    let dailyFormat: string = limit.daily!.toString();
                    const data: any = [
                      limit.title,
                      <Input
                        key={limit.title}
                        value={currencyFormatter(operationFormat)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          this.notEdited = false;
                          if (event.target.value === "") {
                            limit.operation = 0;
                          } else {
                            operationFormat = event.target.value;
                            limit.operation = parseFloat(operationFormat.replace(/,/gu, "").replace(/\./gu, ""));
                          }

                          if (limit.operation > limit.daily!) {
                            this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                          } else {
                            this.errorMessage = "";
                          }

                          this.checkNull(this.clientLimit);
                        }}
                      />,
                      <Input
                        key={limit.title}
                        value={currencyFormatter(dailyFormat)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          this.notEdited = false;
                          if (event.target.value === "") {
                            limit.daily = 0;
                          } else {
                            dailyFormat = event.target.value;
                            limit.daily = parseFloat(dailyFormat.replace(/,/gu, "").replace(/\./gu, ""));
                          }

                          if (limit.operation! > limit.daily) {
                            this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                          } else {
                            this.errorMessage = "";
                          }

                          this.checkNull(this.clientLimit);
                        }}
                      />,
                      <ContainerInput key={limit.title}>
                        <Input
                          disabled={limit.amountPerDay === this.MAXAMOUNT}
                          value={limit.amountPerDay === this.MAXAMOUNT ? " " : limit.amountPerDay}
                          placeholder="Indicar a quantidade"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.notEdited = false;
                            if (event.target.value === "") {
                              limit.amountPerDay = 0;
                            } else {
                              limit.amountPerDay = Number(event.target.value);
                            }

                            this.checkNull(this.clientLimit);
                          }}
                        />
                        <WrapperSwitch>
                          <Switch
                            checked={limit.amountPerDay === this.MAXAMOUNT}
                            handleChange={() => {
                              this.notEdited = false;
                              limit.amountPerDay = limit.amountPerDay === this.MAXAMOUNT ? 0 : this.MAXAMOUNT;
                              this.checkNull(this.clientLimit);
                            }}
                          />
                          Ilimitado
                        </WrapperSwitch>
                      </ContainerInput>,
                    ];

                    return data;
                  })}
                />
              </WrapperTable>
            </>
          )}
          {this.errorMessage && (
            <ErrorMessage>
              <SvgError
                viewBox="0 0 82 82"
                width="21"
                height="auto"
                style={{ margin: "0 8px -8px 0", position: "relative" }}
              />
              {this.errorMessage}
            </ErrorMessage>
          )}
          <Right>
            <Label>*Campos obrigatórios</Label>
            <StyledOutlinedButton
              label="Cancelar"
              onClick={() => this.props.createClientCustomization(false)}
              type="submit"
            />
            <StyledRaisedButton
              onClick={async () => this.saveClientLimit(this.clientLimit!)}
              disabled={this.notEdited || this.empty || Boolean(this.errorMessage)}
              label="Salvar"
              type="submit"
              style={{ width: "160px" }}
            />
          </Right>
        </Wrapper>
      </>
    ) : (
      <WrapperTable>
        <Loader />
      </WrapperTable>
    );
  }
}
