/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
import React from "react";
import styled from "styled-components";

import { Container, SmallTitle } from "../styled";
import { TextShow } from "../../../../../components/TextShow";
import { allCheckSanitizer } from "../sanitizer";

interface IAllCheckMapperProps {
  title: string;
  data:
    | {
        [key: string]: any;
      }
    | Array<{ [key: string]: any }>
    | null;
  dataKey: string;
  highlight?: boolean;
}

export function AllCheckMapper({ data, title, dataKey, highlight = false }: IAllCheckMapperProps) {
  if (!data) {
    return null;
  }

  return (
    <>
      {Array.isArray(data) ? (
        <>
          {data.map((d, i) => (
            <Container highlight={highlight}>
              <SmallTitle>{`${title} ${i ? i + 1 : ""}`}</SmallTitle>
              <Container paddingLeft="1rem">
                {Object.keys(d).map(bdKey => (
                  <StyledTextShow title={(allCheckSanitizer as any)[dataKey][0][bdKey]} text={d[bdKey]} />
                ))}
              </Container>
            </Container>
          ))}
        </>
      ) : (
        <Container highlight={highlight}>
          <SmallTitle>{title}</SmallTitle>
          <Container paddingLeft="1rem">
            {Object.keys(data).map(key => (
              <StyledTextShow title={(allCheckSanitizer as any)[dataKey][key] as string} text={data[key]} />
            ))}
          </Container>
        </Container>
      )}
    </>
  );
}

const StyledTextShow = styled(TextShow)`
  display: block;
  margin-bottom: 8px;
`;
