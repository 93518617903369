/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as api from "api/manager-api";
import { action, observable } from "mobx";
import { isValidCnpj, isValidCpf } from "@brazilian-utils/validators";

import { session } from "../utils";
import { RootStore } from "./RootStore";

export type IFilterAccountsPath =
  | "all"
  | "workflow"
  | "tipping"
  | "positivize"
  | "approved"
  | "approvedPending"
  | "approvePendingWithDocument"
  | "approvePendingWithoutDocument"
  | "pending"
  | "canceled"
  | "reproved"
  | "notFinished"
  | "processing"
  | "cpfList";

export class OpeningAccountsStore {
  protected rootStore: RootStore;

  @observable public pendingUsers: api.ManagerAccountReturn | null = null;

  @observable public pendingUser: api.RegisterUserFields | null = null;

  @observable public pendingCompany: api.RegisterCompanyV13 | null = null;

  @observable public analyzeCompany: api.CompanyAnalisys | null = null;

  @observable public countAccountManager: api.UsersByStatus[] = [];

  @observable public registerFields: api.RegisterFieldsPF | null = null;

  @observable public registerFieldsMEI: api.RegisterField | null = null;

  @observable public feedbacks: api.Feedback[] = [];

  @observable public historyWorkflow: api.History[] = [];

  @observable public historyPjWorkflow: api.HistoryAudit[] = [];

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getCompanyAnalisysById = async (companyId: string) => {
    return api.getCompanyAnalisysById(companyId);
  };

  @session
  @action
  public submitRevalidate = async (documentNumber: string, data: api.RegisterField[], obs: string) => {
    return api.setPendingRegister(documentNumber, data, obs);
  };

  @session
  @action
  public submitRevalidateMEI = async (id: string, data: api.RegisterField[], obs: string) => {
    return api.setPendingRegisterMEI(id, data, obs);
  };

  @session
  @action
  public createFeedback = async (
    targetID: string,
    type: api.PfPj,
    message: string,
    attached: Buffer | null,
    nameFile: string,
  ) => {
    const attachedData = [
      {
        buf: attached,
        name: nameFile,
        url: "",
      },
    ];

    const feedback = await api.createFeedback(targetID, type, message, attached ? attachedData : null);

    this.feedbacks.push(feedback);
    return feedback;
  };

  @session
  @action
  public sendLinkPendingRegister = async () => {
    if (this.pendingUser) {
      api.sendLinkPendingRegister(this.pendingUser.cpf, this.pendingUser.pendingFields || [], this.pendingUser.obs);
    } else if (this.pendingCompany) {
      api.sendMailPendingRegister(this.pendingCompany.id);
    }
  };

  @session
  @action
  public setBlockStatusAccount = async (blockStatus: api.BlockStatusAccount) => {
    await api.setBlockStatusAccount(blockStatus, this.pendingUser!.cpf);
    this.pendingUser!.blockStatus = blockStatus;
  };

  @session
  @action
  public getHistoryWorkflowByTargetId = async (targetID: string) => {
    return (this.historyWorkflow = await api.getHistoryWorkflowByTargetId(targetID));
  };

  @session
  @action
  public getFeedbacksByTargetIdAndType = async (targetID: string, type: api.PfPj) => {
    return (this.feedbacks = await api.getFeedbacksByTargetIdAndType(targetID, type));
  };

  @session
  @action
  public getRegisterFields = async () => (this.registerFields = await api.getRegisterFields());

  @session
  @action
  public setCompliance = async (cpfCnpj: string, type: api.PfPj, status: api.ComplianceStatus) => {
    await api.changeComplianceStatus(cpfCnpj, type, status);

    if (this.pendingUser) {
      this.pendingUser.complianceStatus = status;
    } else if (this.pendingCompany) {
      this.pendingCompany.complianceStatus = status;
    }
  };

  @session
  @action
  public changeCompanyCompliance = async (companyId: string, status: api.ChangeComplianceStatus) => {
    await api.changeCompanyCompliance(companyId, status);
  };

  @session
  @action
  public getAccountsByStatus = async (
    filter: api.ManagerAccountFilter,
    status: api.UserStatus | null,
    complianceStatus?: api.ComplianceStatus | null,
    simpleAccountSituation?: api.BlockStatusAccount | null,
  ) => {
    const result = await api.getAccountsByStatus(
      filter,
      status,
      complianceStatus || null,
      simpleAccountSituation || null,
    );

    this.pendingUsers = result;
    return result;
  };

  @session
  @action
  public setPendingCompanyAndUserToNull = () => {
    this.pendingCompany = null;
    this.pendingUser = null;
  };

  @session
  @action
  public getBadgeData = async () => (this.countAccountManager = await api.getUsersByStatus());

  @session
  @action
  public getPendingUser = async (userId: string) => {
    this.pendingUser = await api.getUserFieldsById(userId);
  };

  @session
  @action
  public getPendingCompany = async (companyId: string) => {
    return (this.pendingCompany = await api.getCompanyByIdV13(companyId));
  };

  @session
  @action
  public getAnalyzeCompany = async (companyId: string) => {
    return (this.analyzeCompany = await api.getCompanyAnalisysById(companyId));
  };

  @session
  @action
  public getPendingEntity = async (id: string, documentNumber: string) => {
    this.setPendingCompanyAndUserToNull();

    if (isValidCpf(documentNumber)) {
      await this.getPendingUser(id);
    } else if (isValidCnpj(documentNumber)) {
      // O método getAnalyzeCompany retorna apenas as PJ novas, retornando um erro nas PJ antigas e MEI. O try serve para impedir que esse erro seja lançado.
      try {
        await this.getAnalyzeCompany(id);
      } catch (error) {
        // Console.log(error);
      }

      await this.getPendingCompany(id);
    } else {
      throw new Error("CPF ou CNPJ inválido.");
    }
  };

  @session
  public getDocumentsCompanyDownloadLink = async (cnpj: string) => api.getDocumentsCompanyDownloadLink(cnpj);

  @session
  @action
  public changeStatusUser = async (docNumber: string, pfPj: api.PfPj, status: api.UserStatus) => {
    const isChanged = await api.changeStatusUser(docNumber, pfPj, status);

    if (isChanged) {
      if (this.pendingUser) {
        this.pendingUser.status = status;
      } else if (this.pendingCompany) {
        this.pendingCompany.status = status as any;
      }
    }
  };

  @session
  @action
  public resendRepresentativeProofOfLife = async (representativeId: string) => {
    return api.resendRepresentativeProofOfLife(representativeId);
  };

  public getUserComplianceData = () => {
    if (this.pendingUser) {
      return async () => api.getComplianceData(this.pendingUser!.cpf);
    }

    if (this.pendingCompany && this.pendingCompany.isMEI) {
      return [this.pendingCompany!.documentNumber, this.pendingCompany!.owner!.cpf].map(d => async () => {
        return api.getComplianceData(d);
      });
    }

    return async () => api.getComplianceData(this.pendingCompany!.documentNumber);
  };

  @session
  @action
  public getRepresentativesByCompanyId = async (companyId: string) => {
    return api.getRepresentativeAnalisysByCompanyId(companyId);
  };

  @session
  @action
  public getSubscribersByCompanyId = async (companyId: string) => {
    return api.getSubscriberAnalisysByCompanyId(companyId);
  };

  public getComplianceByCompanyId = (companyId: string) => {
    return async () => api.getBeneficiaryAnalisysByCompanyId(companyId);
  };

  @session
  @action
  public getHistoryWorkflowByCompanyId = async (companyId: string) => {
    return (this.historyPjWorkflow = await api.getHistoryWorkflowByCompanyId(companyId));
  };

  @session
  @action
  public changeStatusRepresentativeById = async (representativeId: string, status: api.StatusProofOfLife) => {
    return api.changeStatusRepresentativeById(representativeId, status);
  };

  @session
  @action
  public changeStatusSubscriberById = async (subscriberId: string, status: api.StatusProofOfLife) => {
    return api.changeStatusSubscriberById(subscriberId, status);
  };

  @session
  @action
  public resendSubscriberProofOfLife = async (subscriberId: string) => {
    return api.resendSubscriberProofOfLife(subscriberId);
  };

  @session
  @action
  public resolveRegisterConflicts = async (companyId: string) => {
    return api.resolveRegisterConflicts(companyId);
  };

  @observable
  public proofsOfLife: api.ProofOfLifeInfo[] | undefined;

  @session
  @action
  public getProofsOfLife = async () => {
    try {
      this.proofsOfLife = await api.filterProofOfLife("");
    } catch (error) {
      this.proofsOfLife = [];
    }
  };

  public getDetailOfProof = async (proofId: string) => {
    const res = await api.getDetailedProofOfLifeInfo(proofId);

    this.proofsOfLife = (this.proofsOfLife || []).map(proof => {
      return proof.id === proofId ? { ...proof, detailedProofOfLifeInfo: res } : proof;
    });
  };

  public approveProofOfLife = async (id: string) => {
    return api.approveProofOfLife(id);
  };

  public disapproveProofOfLife = async (id: string) => {
    return api.reproveProofOfLife(id);
  };
}
