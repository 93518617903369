import React from "react";
import classnames from "classnames";

import { Checkbox } from "./../";

import * as s from "./style.scss";

export interface ICheckboxGroupContext {
  value: string[];
  disabled: boolean;
  onChange: (option: string) => void;
}

export const CheckboxGroupContext = React.createContext<ICheckboxGroupContext | null>(null);

export interface ICheckboxItem {
  value: string;
  label: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export interface ICheckboxGroupProps {
  value?: string[];
  options?: ICheckboxItem[];
  disabled?: boolean;
  align?: "block" | "inline";
  children?: React.ReactNode;
  className?: string;
  onChange?: (value: string[]) => void;
}

export function CheckboxGroup(props: ICheckboxGroupProps) {
  const [value, setValue] = React.useState<string[]>([]);

  function onChange(option: string) {
    const oldValue = props.value ?? value;
    const isNewOption = !oldValue.includes(option);
    const newValue = isNewOption ? [...oldValue, option] : oldValue.filter(x => x !== option);

    if (props.onChange) {
      props.onChange(newValue);
    }

    if (!props.value) {
      setValue(newValue);
    }
  }

  return (
    <CheckboxGroupContext.Provider
      value={{
        disabled: Boolean(props.disabled),
        onChange,
        value: props.value ?? value,
      }}
    >
      <div className={classnames(s[`checkboxGroup_${props.align}`], props.className)} data-testid="checkgroup">
        {props.options?.length &&
          props.options.map(option => (
            <Checkbox key={option.value} value={option.value} {...option}>
              {option.label}
            </Checkbox>
          ))}

        {!props.onChange?.length && props.children}
      </div>
    </CheckboxGroupContext.Provider>
  );
}

CheckboxGroup.defaultProps = {
  align: "inline",
};
