import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { AdminUserPermission } from "api/manager-api";

import { GeneralStore, PlatformManagerStore } from "../../stores";
import { Header, Title, TitleContainer } from "./styled";
import { TabNavigator } from "../../components/TabNavigator";
import { Operations } from "./Operations";
import { Services } from "./Services";
import { SpecialDates } from "./SpecialDates";
import { Whitelist } from "./Whitelist";
import { CpfList } from "./CpfList";

type Tab = "services" | "operations" | "specialDates" | "whitelist";

interface IProps {
  generalStore: GeneralStore;
  platformManagerStore: PlatformManagerStore;
}

interface IState {
  selectedTab: Tab;
}

@inject("generalStore", "platformManagerStore")
@observer
export class PlatformManager extends React.Component<IProps, IState> {
  @observable private selectedTab: Tab = "operations";

  public componentDidMount() {
    this.getData();
  }

  private getData() {
    this.props.platformManagerStore.getServices();
    this.props.platformManagerStore.getSpecialDates();
  }

  @action
  private onChangeTab = (tabValue: Tab) => {
    this.selectedTab = tabValue;
  };

  public render() {
    return (
      <>
        <Header>
          <TitleContainer>
            <Title>Gestão da Plataforma</Title>
          </TitleContainer>
        </Header>
        <TabNavigator
          onChangeTab={tabValue => this.onChangeTab(tabValue as Tab)}
          selected={this.selectedTab}
          tabs={this.tabs()}
        />
      </>
    );
  }

  private tabs() {
    const { hasPermission } = this.props.generalStore!;

    const tabsData = [
      {
        child: <Services />,
        label: "Serviços",
        value: "services",
      },
      {
        child: <Operations />,
        label: "Operações",
        value: "operations",
      },
      {
        child: <SpecialDates />,
        label: "Datas Especiais",
        value: "specialDates",
      },
    ];

    if (hasPermission(AdminUserPermission.editWhitelist)) {
      tabsData.push({
        child: <Whitelist />,
        label: "Whitelist",
        value: "whitelist",
      });
    }

    if (hasPermission(AdminUserPermission.viewCPFList)) {
      tabsData.push({
        child: <CpfList />,
        label: "Lista CPF",
        value: "cpfList",
      });
    }

    return tabsData;
  }
}
