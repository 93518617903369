import styled from "styled-components";

import { colors } from "../../utils";
import { OutlinedButton } from "../../components/OutlinedButton";
import { Select } from "../../components/Select";
import { Radio, RadioGroup } from "@material-ui/core";

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: normal;
`;

export const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #ed2980;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 10px 31px;
  float: right;
`;

export const ButtonDefault = styled.button`
  background-color: #ed2980;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 10px 31px;
  float: right;
`;

export const Spacing = styled.div`
  margin-bottom: 15px;
`;

export const StyledSelect = styled(Select)`
  width: 320px;
  margin-right: 15px;
  div {
    background-color: #ffffff;
    color: #515251;
  }
  div {
    :hover {
      color: #333;
    }
  }
`;

export const StyledSelectDialog = styled(Select)`
  grid-area: select;
  width: 320px;
  margin-top: -17px;
  div {
    background-color: #ffffff;
    color: #515251;
  }
`;

export const TitleInputModal = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
`;
export const SubTitleInputModal = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: #a6a6a6;
  font-family: Lato;
  font-size: 14px;
  text-align: left;
  position: relative;
  top: -29px;
  width: 76%;
  display: block;
`;

export const DescriptionLabel = styled.label`
  padding: 0;
  color: ${colors.gray};
  font-family: Lato;
  font-size: 11px;
  text-align: right;
  position: relative;
  top: -10px;
  width: 100%;
  display: block;
`;

export const LabelInputFile = styled.label`
  background-color: #ededf2;
  border-radius: 5px;
  display: block;
  color: #fff;
  color: #8c8c8f;
  cursor: pointer;
  height: 40px;
  padding: 12px 93.15px;
  position: relative;
  border-radius: 0px 5px 5px 0px;
  span {
    background-color: #ed2980;
    color: #fff;
    padding: 10px 15px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 5px;
    padding-right: 15px;
  }
`;

export const InputFile = styled.input`
  display: none;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px 30px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RowFlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Line = styled.hr`
  width: 100%;
  border: 0.5px solid #e6e6e6;
  margin-top: 12px;
  opacity: 0.5;
`;

export const List = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  min-width: 430px;

  li {
    list-style: none;
    padding: 15px;
    background-color: #e3e4e8;
    border: 1px solid #dcdce0;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    display: flex;

    @media (max-width: 1500px) {
      max-width: 120px;
      display: block;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin: 0;
    div {
      flex-direction: row;
    }
  }
`;

export const RowTotalResults = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 24px 0px 24px;
`;

export const TotalResults = styled.h6`
  color: ${colors.black};
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
`;

export const Button = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

export const ClearOutlinedButton = styled(OutlinedButton)`
  margin: 0px 10px;
  width: auto;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid ${colors.platinum};
  background: ${colors.white};
`;

export const Input = styled.input`
  width: 400px;
  margin-right: 15px;
  background-color: transparent;
  border: 1px solid ${colors.platinum};
  border-radius: 5px;
  color: #515251;
  font-family: Lato;
  font-size: 15px;
  padding: 10px 0 10px 10px;
  height: 40px;
  outline: none;
  :focus {
    border-color: rgba(237, 41, 128, 0.4);
  }
`;

export const InputText = styled.input`
  width: 400px;
  margin-right: 15px;
  background-color: transparent;
  border: 1px solid ${colors.platinum};
  border-radius: 5px;
  color: #515251;
  font-family: Lato;
  font-size: 15px;
  padding: 10px 0 10px 10px;
  height: 40px;
  outline: none;
  :focus {
    border-color: rgba(237, 41, 128, 0.4);
  }
`;

export const SimpleList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ul {
    width: 100%;
    display: flex;
    list-style: none;
    padding: 0;
    color: ${colors.black};
    font-family: Lato;
    padding: 0;
    margin: 0;
    li {
      display: inline-table;
      width: 170px;
      padding: 15px;
      :last-child {
        width: 100%;
      }
    }
    :first-child {
      color: ${colors.gray};
      font-weight: bold;
    }
  }
`;

export const StyleRadioGroup = styled(RadioGroup)`
  label {
    margin-bottom: 10px;
  }
  span:nth-child(2) {
    font-weight: bold;
    color: ${colors.black};
  }
  font-size: 14px;
`;

export const ContentTable = styled.div`
  width: 100%;
  table {
    thead {
      tr {
        th {
          text-align: center;
          white-space: inherit;
          max-width: 100px;
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: center;
        }
      }
    }
  }
`;

export const Label = styled.label`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black};
`;

export const MergeRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
  text-align: start;
`;

export const MergeTitleRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpanTitle = styled.span`
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const SecondMergeSpan = styled.span`
  margin-top: 6px;
  color: ${colors.gray};
`;

export const LabelDefault = styled.span`
  color: #a6a6a6;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  vertical-align: super;
`;
