import * as React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { action, observable } from "mobx";

import { DefaultLimit } from "./DefaultLimit";
import { SegmentBoundaries } from "./SegmentBoundaries";
import { Segments } from "./Segments";
import { GeneralStore } from "../../../stores";
import { colors } from "../../../../../utils";
import { TabNavigator } from "../../../components/TabNavigator";

type Tab = "defaultLimit" | "segments" | "segmentBoundaries";

interface IProps {
  generalStore: GeneralStore;
}

interface IState {
  selectedTab: Tab;
}

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;

@inject("generalStore", "platformManagerStore")
@observer
export class GeneralParameters extends React.Component<IProps, IState> {
  @observable private selectedTab: Tab = "defaultLimit";

  public componentDidMount() {
    this.getData();
  }

  private getData() {
    /*
     * This.props.platformManagerStore.getServices();
     * this.props.platformManagerStore.getSpecialDates();
     */
  }

  @action
  private onChangeTab = (tabValue: Tab) => {
    this.selectedTab = tabValue;
  };

  public render() {
    return (
      <>
        <Header>
          <TitleContainer>
            <Title>Parâmetros Gerais</Title>
          </TitleContainer>
        </Header>
        <TabNavigator
          onChangeTab={tabValue => this.onChangeTab(tabValue as Tab)}
          selected={this.selectedTab}
          tabs={this.tabs()}
        />
      </>
    );
  }

  private tabs() {
    return [
      {
        child: <DefaultLimit />,
        label: "Limite Padrão",
        value: "defaultLimit",
      },
      {
        child: <Segments />,
        label: "Segmentos",
        value: "segments",
      },
      {
        child: <SegmentBoundaries />,
        label: "Limites dos Segmentos",
        value: "segmentBoundaries",
      },
    ];
  }
}
