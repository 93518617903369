import React, { useEffect, useState } from "react";
import moment from "moment";
import { ProofOfLifeInfo } from "api/manager-api";
import { useStores } from "apps/manager/stores/RootStore";
import { Loader } from "legacy/components/Loader";

import { ImageZoom } from "../../../../../components/ImageZoom";
import { ReactComponent as Success } from "../../../../../assets/svgs/success-feedback.svg";
import { Modal } from "../../../components/Modal";

import s from "./styles.scss";

interface IProps {
  proof?: ProofOfLifeInfo;
  approveProof: (id: ProofOfLifeInfo, approved: boolean) => void;
  feedback?: "approved" | "disapproved";
  showModal: boolean;
  onClose: () => void;
  errorMessage: string;
  permissions: {
    approve: boolean;
    reprove: boolean;
  };
}

export function ModalProof({ proof, onClose, feedback, approveProof, errorMessage, permissions, showModal }: IProps) {
  const [loadingBtns, setLoadingBtns] = useState<"approved" | "disapproved">();
  const { openingAccountsStore } = useStores();

  useEffect(() => {
    setLoadingBtns(undefined);
  }, [feedback, errorMessage]);

  useEffect(() => {
    if (proof && !proof.detailedProofOfLifeInfo) {
      openingAccountsStore.getDetailOfProof(proof.id);
    }
  }, [proof]);

  useEffect(() => {
    if (showModal && proof) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal]);

  if (!showModal || !proof) {
    return null;
  }

  const onApprove = () => {
    setLoadingBtns("approved");
    approveProof(proof, true);
  };

  const onDisapprove = () => {
    setLoadingBtns("disapproved");
    approveProof(proof, false);
  };

  const disableChangeStatus = proof.status === "APPROVED" || proof.status === "REFUSED";

  return (
    <Modal
      disabledOk={!permissions.approve}
      disabledCancel={!permissions.reprove}
      hideFooter={disableChangeStatus}
      onCancelText="Reprovar"
      onCancel={feedback ? undefined : onDisapprove}
      onClose={onClose}
      onOk={feedback ? onClose : onApprove}
      onOkText={feedback ? "Fechar" : "Aprovar Solicitação"}
      show
      loading={loadingBtns && loadingBtns === "approved"}
      loadingCancel={loadingBtns && loadingBtns === "disapproved"}
      footerAlign={feedback ? "center" : "right"}
    >
      {feedback ? <Feedback approved={feedback === "approved"} name={proof.name} /> : <ProofInfo proof={proof} />}
    </Modal>
  );
}

function ProofInfo({ proof }: { proof: ProofOfLifeInfo }) {
  const history = proof.detailedProofOfLifeInfo?.history || [];

  return (
    <>
      <div className={s.imagesProof}>
        <figure className={s.proofFigure}>
          {proof.detailedProofOfLifeInfo ? (
            <>
              <img src={proof.detailedProofOfLifeInfo.oldUrlSelfie} alt="Imagem anterior" />
              <figcaption>Prova Anterior</figcaption>
            </>
          ) : (
            <Loader compact />
          )}
        </figure>
        <figure className={s.proofFigure}>
          {proof.detailedProofOfLifeInfo ? (
            <>
              <img src={proof.detailedProofOfLifeInfo.newUrlSelfie} alt="Imagem nova" />
              <figcaption>Nova prova</figcaption>
            </>
          ) : (
            <Loader compact />
          )}
        </figure>
      </div>
      <div className={s.documents}>
        {proof.detailedProofOfLifeInfo?.images?.map(documentImage => (
          <ImageZoom key={documentImage.url} src={documentImage.url} alt={documentImage.name} />
        ))}
      </div>
      <div className={s.rowInfo}>
        <div className={s.userinfo}>
          <h4 className={s.title}>Nome:</h4>
          <p className={s.info}>{proof.name}</p>
        </div>
        <div className={s.userinfo}>
          <h4 className={s.title}>CPF:</h4>
          <p className={s.info}>{proof.documentNumber}</p>
        </div>
      </div>
      <div className={s.rowInfo}>
        <div className={s.userinfo}>
          <h4 className={s.title}>Data/Hora:</h4>
          <p className={s.info}>{moment(proof.createdAt).format("lll")}</p>
        </div>
        {history.length > 0 && (
          <div className={s.userinfo}>
            <h4 className={s.title}>{history[0].auditType} por:</h4>
            <p className={s.info}>{history[0].adminName}</p>
          </div>
        )}
      </div>
    </>
  );
}

interface IFeedbackProps {
  approved: boolean;
  name: string;
}

export function Feedback({ approved, name }: IFeedbackProps) {
  return (
    <div className={s.feedback}>
      <Success />
      <h3>Solicitação {approved ? "Aprovada" : "Reprovada"}</h3>
      <p>
        A solicitação de <strong>{name}</strong> foi {approved ? "aprovada" : "reprovada"} com Sucesso!
      </p>
    </div>
  );
}
