/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable sort-keys */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */

import * as React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import {
  AdminUserPermission,
  BeneficiaryAnalisys,
  closeAccount,
  ComplianceData,
  PfPj,
  toggleUserBlock,
} from "api/manager-api";
import { RouteComponentProps } from "react-router";
import { Dialog } from "@manager/components/Dialog";
import {
  CancelButton,
  Right,
  SaveButton,
  StyledTextArea,
} from "@manager/containers/TransactionalBoundaries/GeneralParameters/DefaultLimit/style";

import { GeneralStore, LegalEntityStore, OpeningAccountsStore, UsersStore } from "../../../stores";
import { Header, Name, SelectWrapper } from "./styled";
import { TabNavigator } from "../../../components/TabNavigator";
import { LegalEntityAccountList } from "../LegalEntityAccounts";
import { LegalEntityUsers } from "../LegalEntityUsers";
import { LegalEntityRegistrationData } from "../LegalEntityRegistrationData";
import { Loader } from "../../../components/Loader";
import { InnerAlert } from "../../../components/InnerAlert";
import { Select } from "../../../components/Select";
import { PartnerID } from "../ChangePartnerId/PartnerID";
import { Beneficiary } from "../../OpeningAccounts/OpeningAccountProfile/Beneficiary";
import { partnerIdValue } from "../../../utils/constants";
import { UserChangePass } from "../UserChangePass";
// Import { DialogProps } from "@material-ui/core/Dialog";

type Tab = "registrationData" | "accounts";

interface IProps extends RouteComponentProps<{ cnpj: string }> {
  legalEntityStore: LegalEntityStore;
  generalStore: GeneralStore;
  openingAccountsStore: OpeningAccountsStore;
  usersStore: UsersStore;
}

@inject("legalEntityStore", "generalStore", "openingAccountsStore", "usersStore")
@observer
export class LegalEntityProfile extends React.Component<IProps> {
  @observable private selectedTab = "registrationData";

  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private openDialog = false;

  @observable
  private fetchComplianceData = this.props.openingAccountsStore!.getUserComplianceData();

  @observable private fetchPjComplianceData: (() => Promise<BeneficiaryAnalisys[]>) | null = null;

  @observable private openCloseAccountDialog = false;

  @observable private comment = "";

  @observable private loadingSave = false;

  public componentDidMount() {
    this.getCompany();
  }

  private getCompany = async () => {
    const { cnpj } = this.props.match.params;
    const { getCompany, getProfiles, getUsersByCompany } = this.props.legalEntityStore!;

    try {
      this.loading = true;
      await Promise.all([getCompany(cnpj), getProfiles(cnpj), getUsersByCompany(cnpj)]);
      if (this.props.legalEntityStore!.company) {
        this.fetchPjComplianceData = this.props.openingAccountsStore!.getComplianceByCompanyId(
          this.props.legalEntityStore!.company.id,
        );
      }

      this.error = null;
    } catch (err) {
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  };

  @action
  private onChangeTab = (tabValue: Tab) => {
    this.selectedTab = tabValue;
  };

  @action
  private closeEdit = () => {
    this.openDialog = false;
  };

  @action
  private onAction = (value: "closeAccountPJ" | "updatePartnerId") => {
    if (value === "closeAccountPJ") {
      this.openCloseAccountDialog = true;
    }

    if (value === "updatePartnerId") {
      this.openDialog = true;
    }
  };

  private handleCloseAccount = async () => {
    const { company, usersByCompany } = this.props.legalEntityStore!;

    const users = usersByCompany;

    const targetId = company!.id;
    const type = PfPj.pj;
    const companyAccounts = company?.accounts;

    this.loadingSave = true;
    let message = "";

    if (company!.accounts.length === 0) {
      message = "Não há contas cadastradas";
      this.props.generalStore.showToast(message);
    }

    users.map(async u => {
      if (u.accounts.length === 1) {
        await toggleUserBlock(true, u.id);
      }
    });

    await closeAccount(companyAccounts![0].account, companyAccounts![0].branch, this.comment, targetId, type)
      .then(result => {
        message = result;
      })
      .catch(error => {
        message = error;
      });
    this.loadingSave = false;

    this.openCloseAccountDialog = false;

    this.props.generalStore.showToast(message!);
  };

  public render() {
    const { company } = this.props.legalEntityStore!;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} />;
    }

    if (!company) {
      return null;
    }

    return (
      <>
        <Header>
          <Name>{company.name}</Name>
          {this.props.legalEntityStore?.company && this.renderActions()}
        </Header>
        {this.renderPartnerIdDialog()}
        {this.renderCloseAccoutnDialog()}
        <TabNavigator
          onChangeTab={(tabValue: string) => this.onChangeTab(tabValue as Tab)}
          selected={this.selectedTab}
          tabs={this.tabs()}
        />
      </>
    );
  }

  private renderActions = () => {
    if (!this.props.generalStore.hasPermission(AdminUserPermission.recoveryUser)) {
      return;
    }

    if (this.loading) {
      return (
        <SelectWrapper>
          <Loader compact />
        </SelectWrapper>
      );
    }

    return (
      <SelectWrapper>
        <Select
          options={[
            ...partnerIdValue,
            {
              value: "closeAccountPJ",
              label: "Encerrar Conta PJ",
            },
          ]}
          placeholder="Ações"
          value={null}
          onChange={(e: any) => this.onAction(e!.value)}
        />
      </SelectWrapper>
    );
  };

  private tabs() {
    const data = [
      {
        label: "Dados cadastrais",
        value: "registrationData",
        child: <LegalEntityRegistrationData data={this.props.legalEntityStore!.company!} />,
      },
      {
        label: "Contas",
        value: "accounts",
        child: <LegalEntityAccountList />,
      },
    ];

    const { usersByCompany } = this.props.legalEntityStore!;

    if (usersByCompany.length > 0) {
      const [user] = usersByCompany;

      data.push({ label: "Recuperação de senha", value: "changepass", child: <UserChangePass userId={user.id} /> });
    }

    if (this.props.generalStore!.hasPermission(AdminUserPermission.viewUser) && this.props.legalEntityStore!.company) {
      data.push({
        label: "Representantes",
        value: "accountUsers",
        child: (
          <LegalEntityUsers
            companyId={this.props.legalEntityStore!.company.id}
            cnpj={this.props.match.params.cnpj}
            onSelected={this.fetchComplianceData as () => Promise<ComplianceData>}
            onUpdateAction={() => this.getCompany()}
          />
        ),
      });
    }

    if (this.fetchPjComplianceData) {
      data.push({
        label: "Beneficiários",
        value: "beneficiary",
        child: <Beneficiary onSelectedPj={this.fetchPjComplianceData} />,
      });
    }

    return data;
  }

  private renderPartnerIdDialog = () => {
    const { legalEntityStore } = this.props;

    if (!legalEntityStore?.company) {
      return null;
    }

    return (
      <PartnerID
        isOpen={this.openDialog}
        onClose={this.closeEdit}
        companyId={this.props.legalEntityStore!.company!.id}
        accountType={this.props.legalEntityStore!.company!.isMEI}
        showToast={this.props.generalStore!.showToast}
        reloadPage={this.getCompany}
      />
    );
  };

  private renderCloseAccoutnDialog = () => {
    return (
      <Dialog
        showCloseButton
        closeButtonSize={"30px"}
        maxWidth={"md"}
        title={`Inserir motivo de encerramento:`}
        isOpen={this.openCloseAccountDialog}
        onClose={() => (this.openCloseAccountDialog = false)}
      >
        <StyledTextArea value={this.comment} onChange={e => (this.comment = e.target.value)} />
        <Right>
          <CancelButton onClick={() => (this.openCloseAccountDialog = false)} label="Cancelar" type="submit" />
          <SaveButton onClick={this.handleCloseAccount} label="Salvar" type="submit" loading={this.loadingSave} />
        </Right>
      </Dialog>
    );
  };
}
