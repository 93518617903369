import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";

interface IProps {
  title: React.ReactNode;
  expandIcon?: React.ReactNode
  children?: React.ReactNode;
}

export const AccordionFC: React.SFC<IProps> = props => (
  <StyledExpansionPanel>
    <StyledExpansionPanelSummary expandIcon={props.expandIcon || <ArrowDown />}>
      <StyledExpansionPanelTitle>{props.title}</StyledExpansionPanelTitle>
    </StyledExpansionPanelSummary>
    <StyledExpansionPanelDetails>{props.children || ""}</StyledExpansionPanelDetails>
  </StyledExpansionPanel>
);

export const Accordion = React.memo(AccordionFC);

// Styles

const StyledExpansionPanel = styled(ExpansionPanel)`
  box-shadow: none !important;
  &:before {
    display: none;
  }
` as any;

const StyledExpansionPanelSummary = styled(ExpansionPanelSummary)`
  margin: 0 !important;

  > div {
    margin: 0 !important;
  }
  > div + div {
    /* left: -15px !important; */
  }
` as any;

const StyledExpansionPanelTitle = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525252;
  margin: 0;
` as any;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  padding-left: 0 !important;
  & label > span {
    width: 20px;
    height: 20px;
  }
  &:last-child {
    padding-bottom: 10px !important;
  }
` as any;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #a6a6a6;
`;
