import * as React from "react";
import styled from "styled-components";

interface IProps {
  text: string;
  backgroundColor: string;
  borderColor: string;
  color: string;
  style?: React.CSSProperties;
  className?: string;
}

const TagContainer = styled.span<{
  backgroundColor: string;
  borderColor: string;
  color: string;
}>`
  display: block;
  border-radius: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  width: 130px;
  height: 30px;
  font-family: Lato;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${({ color }) => color};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 2px;
`;

export const Tag = (props: IProps) => {
  return (
    <TagContainer
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      color={props.color}
      style={props.style}
      className={props.className}
    >
      {props.text}
    </TagContainer>
  );
};
