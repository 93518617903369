import * as React from "react";
import styled from "styled-components";
import { inject } from "mobx-react";
import { AdminUserPermission } from "api/manager-api";
import { colors } from "utils";

import { Chat } from "../Chat";
import { History } from "../History";
import { GeneralStore } from "../../../stores";

interface IProps {
  generalStore?: GeneralStore;
  isPjHistory?: boolean;
  companyId?: string;
}

function RawFeedback(props: IProps) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { hasPermission } = props.generalStore!;

  return (
    <Container>
      {hasPermission(AdminUserPermission.viewFeedback) ? (
        <Column>
          <Title>Comunicação e pareceres</Title>
          <Chat />
        </Column>
      ) : null}
      {hasPermission(AdminUserPermission.viewHistoryWorkflow) ? (
        <Column>
          <Title>Histórico</Title>
          <History isPjHistory={props.isPjHistory} companyId={props.companyId} />
        </Column>
      ) : null}
    </Container>
  );
}

export const Feedback = inject("generalStore")(RawFeedback);

const Container = styled.main`
  display: flex;
  margin: 0px 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child) {
    border-right: 1px solid ${colors.borderGray};
    margin-right: 12px;
    margin-left: -12px;
  }
`;

const Title = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
`;
