/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
/* eslint-disable require-unicode-regexp */
/* eslint-disable-next-line implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { formatDate, openingAccountsSectionPath, searchMask } from "@manager/utils";
import Add from "@material-ui/icons/Add";
import { SvgFileDownload } from "@manager/components/Icons/SvgFileDownload";
import { formatCpf } from "@brazilian-utils/formatters";
import { colors } from "utils";
import save from "save-file";
import { Checkbox, CircularProgress } from "@material-ui/core/";

import { ReactComponent as ArrowIcon } from "../../../../../assets/svgs/arrow-back.svg";
import { Button } from "../../../../../components/Button";
import { Loader } from "../../../components/Loader";
import { Table } from "../../../components/Table";
import { CheckboxList } from "../../../components/CheckboxList";
import {
  Box,
  ClearOutlinedButton,
  Content,
  Header,
  InputText,
  Label,
  Line,
  List,
  Row,
  Spacing,
  Title,
  TitleContainer,
} from "../../../assets/styles/global";
import { LabelDefault } from "../styled";
import { ModalDetails } from "../ModalDetails";
import { ModalCancel } from "./ModalCancel";

interface IFilter {
  cpfCnpjOrName: string;
  typesFilter: string[];
  onlyCheckeds: boolean;
}

interface IControlTable {
  offset: number;
  limit: number;
  page: number;
}

interface RouteInfo {
  id: string;
}

interface ProspectStatus {
  statusId: string;
  statusName: string;
}

interface ITypeDataLotDetails {
  id: number;
  date: Date;
  doc: string;
  birthdate: Date;
  name: string;
  mother: string;
  zipCode: string;
  address: string;
  addressNumber: string;
  complementAddress: string;
  neighborhood: string;
  city: string;
  state: string;
  DDD: string;
  cellNumber: string;
  email: string;
  gender: string;
  grossIncome: string;
  equityValue: string;
  obs1: string;
  obs2: string;
  partnerFileId: number;
  statusId: string;
  status: ProspectStatus;
  partnerId: string;
  partnerName: string;
  partnerFileStatusDescription: string;
  partnerFileDescription: string;
  partnerFileStatusId: string;
  partnerFileStatusName: string;
}

interface ITypeFilterOption {
  value: string | number | null;
  label: string | null;
}

const initialStateFilter: IFilter = {
  cpfCnpjOrName: "",
  typesFilter: [],
  onlyCheckeds: false,
};

const initialStateTable: IControlTable = {
  offset: 0,
  limit: 10,
  page: 0,
};

export const Details: React.FC = ({ match }: any) => {
  const { generalStore, partnerStore, routerStore } = useStores();
  const [filter, setFilter] = useState<IFilter>(initialStateFilter);
  const [checkedRowsIds, setCheckedRowsIds] = useState<number[]>([]);
  const [checkedRows, setCheckedRows] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [results, setResults] = useState<ITypeDataLotDetails[]>([]);
  const [historicDataModal, setHistoricDataModal] = useState<ITypeDataLotDetails>();
  const [partnerDataLotDetails, setPartnerDataLotDetails] = useState<ITypeDataLotDetails[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalVisibleCancel, setModalVisibleCancel] = useState<boolean>(false);
  const [controlTable, setControlTable] = useState<IControlTable>(initialStateTable);
  const [labelNamePartner, setLabelNamePartner] = useState<string>("");
  const [labelDescriptionPartner, setLabelDescriptionPartner] = useState<string>("");
  const [labelStatusPartner, setlabelStatusPartner] = useState<string>("");

  async function getDataLotDetails(partnerFileId: string) {
    try {
      setLoading(true);
      await partnerStore.getPartnerDataLotDetails(partnerFileId);
      const list: ITypeDataLotDetails[] = [];

      partnerStore.partnerDataLotDetails?.forEach(partnerDetails => {
        if (partnerDetails.id) {
          list.push({
            id: partnerDetails.id || 0,
            date: partnerDetails.date || new Date(),
            doc: partnerDetails.doc || "",
            birthdate: partnerDetails.birthdate || new Date(),
            name: partnerDetails.name || "",
            mother: partnerDetails.mother || "",
            zipCode: partnerDetails.zipCode || "",
            address: partnerDetails.address || "",
            addressNumber: partnerDetails.addressNumber || "",
            complementAddress: partnerDetails.complementAddress || "",
            neighborhood: partnerDetails.neighborhood || "",
            city: partnerDetails.city || "",
            state: partnerDetails.state || "",
            DDD: partnerDetails.DDD || "",
            cellNumber: partnerDetails.cellNumber || "",
            email: partnerDetails.email || "",
            gender: partnerDetails.gender || "",
            grossIncome: partnerDetails.grossIncome || "",
            equityValue: partnerDetails.equityValue || "",
            obs1: partnerDetails.obs1 || "",
            obs2: partnerDetails.obs2 || "",
            partnerFileId: partnerDetails.partnerFileId || 0,
            statusId: partnerDetails.statusId || "",
            status: {
              statusId: partnerDetails.status.statusId || "",
              statusName: partnerDetails.status.statusName || "",
            },
            partnerId: partnerDetails.partnerId || "",
            partnerName: partnerDetails.partnerName || "",
            partnerFileStatusDescription: partnerDetails.partnerFileStatusDescription || "",
            partnerFileDescription: partnerDetails.partnerFileDescription || "",
            partnerFileStatusId: partnerDetails.partnerFileStatusId || "",
            partnerFileStatusName: partnerDetails.partnerFileStatusName || "",
          });
        }
      });
      setPartnerDataLotDetails(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
      if (
        partnerStore.partnerDataLotDetails &&
        partnerStore.partnerDataLotDetails.length > 0 &&
        partnerStore.partnerDataLotDetails[0].partnerName &&
        partnerStore.partnerDataLotDetails[0].partnerFileDescription &&
        partnerStore.partnerDataLotDetails[0].partnerFileStatusName
      ) {
        setLabelNamePartner(partnerStore.partnerDataLotDetails[0].partnerName);
        setLabelDescriptionPartner(partnerStore.partnerDataLotDetails[0].partnerFileDescription);
        setlabelStatusPartner(partnerStore.partnerDataLotDetails[0].partnerFileStatusName);
      }
    }
  }

  useEffect(() => {
    let list: ITypeDataLotDetails[] = partnerDataLotDetails;

    if (filter.onlyCheckeds) {
      list = list.filter(row => checkedRowsIds.includes(row.id));
    }

    if (filter.cpfCnpjOrName) {
      const searchWord = filter.cpfCnpjOrName
        .toString()
        .replace("-", "")
        .replace(".", "")
        .replace(".", "")
        .replace("/", "")
        .toLowerCase();

      list = list.filter(
        rowFilt => rowFilt.name.toLowerCase().indexOf(searchWord) !== -1 || rowFilt.doc.indexOf(searchWord) !== -1,
      );
    }

    if (filter.typesFilter.length > 0) {
      list = list.filter(rowFilt => filter.typesFilter.includes(rowFilt.statusId));
    }

    setResults(list);
  }, [filter]);

  useEffect(() => {
    setResults(partnerDataLotDetails);
    setFilter({ ...filter, typesFilter: ["restriction", "briefing", "validated", "positive", "cancel"] });
  }, [partnerDataLotDetails]);

  useEffect(() => {
    setControlTable(controlTable);
  }, [controlTable]);

  useEffect(() => {
    getDataLotDetails(match.params.id);
  }, []);

  useEffect(() => {
    setFilter({ ...filter, cpfCnpjOrName: searchValue });
  }, [searchValue]);

  const cancelTipping = () => {
    setModalVisibleCancel(true);
  };

  function closeModal() {
    setModalVisible(false);
    setModalVisibleCancel(false);
  }

  function renderData() {
    if (loading) {
      return <Loader />;
    }

    return getTable();
  }

  const sendDownload = async (buffer: Buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `criticas-tombamento.xlsx`);
  };

  const handleDownload = async () => {
    setLoadingDownload(true);
    const buffer = await api.getDownloadProspectValidation(match.params.id);

    setLoadingDownload(false);
    await sendDownload(buffer);
  };

  function renderCpfFilter() {
    return (
      <InputText
        type="text"
        value={searchMask(searchValue)}
        // eslint-disable-next-line capitalized-comments
        // value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        placeholder="Pesquise por CPF ou Nome"
      />
    );
  }

  const countStatus = (status: string) => {
    let total = 0;

    partnerDataLotDetails.forEach(item => {
      if (status === "all") {
        return (total += 1);
      } else if (item.statusId === status) {
        return (total += 1);
      }

      return total;
    });
    return total;
  };

  function renderLabelInfo() {
    return (
      <List>
        <li title={labelNamePartner ? labelNamePartner : "Todos"}>
          <LabelDefault>{labelNamePartner ? labelNamePartner : "Todos"}</LabelDefault>
        </li>
        <li title={labelDescriptionPartner}>
          <LabelDefault>{labelDescriptionPartner}</LabelDefault>
        </li>
        <li title={labelStatusPartner}>
          <LabelDefault>{labelStatusPartner}</LabelDefault>
        </li>
        <li title={`Total de registros: ${countStatus("all")}`}>
          <LabelDefault>{countStatus("all")}</LabelDefault>
        </li>
      </List>
    );
  }

  const clearSelecteds = () => {
    setCheckedRows([]);
    setCheckedRowsIds([]);
    getDataLotDetails(match.params.id);
  };

  function handleSelectOption(typesFilter: string[]) {
    setFilter({ ...filter, typesFilter });
  }

  function handleOnlyChecked() {
    setFilter({ ...filter, onlyCheckeds: !filter.onlyCheckeds });
  }

  function renderCheckFilter() {
    const data = [
      {
        label: "Crítica restritiva",
        value: "restriction",
      },
      {
        label: "Crítica informativa",
        value: "briefing",
      },
      {
        label: "Válido",
        value: "validated",
      },
      {
        label: "Positivado",
        value: "positive",
      },
      {
        label: "Cancelados",
        value: "cancel",
      },
    ];

    const dataTotal = {
      restriction: countStatus("restriction"),
      briefing: countStatus("briefing"),
      pending: countStatus("pending"),
      validated: countStatus("validated"),
      cancel: countStatus("cancel"),
    };

    return (
      <>
        <CheckboxList items={data} selected={filter.typesFilter} onChange={handleSelectOption} total={dataTotal} />
      </>
    );
  }

  function renderOnlyCheckeds() {
    return (
      <div>
        <Checkbox checked={filter.onlyCheckeds} onChange={handleOnlyChecked} />
        <Label>Mostrar selecionados</Label>
      </div>
    );
  }

  function renderModal() {
    return modalVisible && <ModalDetails visible={modalVisible} closeModal={closeModal} data={historicDataModal} />;
  }

  function getTable() {
    const columns = [
      "",
      "Status",
      "CPF",
      "Data Nascimento",
      "Nome",
      "Nome mãe",
      "DDD",
      "Número celular",
      "Email",
      "Detalhes",
    ];

    const checkRow = (id: number) => {
      if (checkedRowsIds.length >= 50) {
        generalStore.showToast("Você pode selecionar no máximo 50 items.");
        return false;
      }

      let aux = [...checkedRowsIds];
      const auxRows: any = [];

      if (aux.includes(id)) {
        aux = aux.filter(item => item !== id);
      } else {
        aux.push(id);
      }

      aux.forEach(idx => {
        partnerDataLotDetails.forEach(item => {
          if (idx === item.id) {
            auxRows.push(item);
          }
        });
      });
      setCheckedRowsIds(aux);
      setCheckedRows(auxRows);
      return false;
    };

    const handleHistoric = (historicData: ITypeDataLotDetails) => {
      setHistoricDataModal(historicData);
      setModalVisible(true);
    };

    function handleChangePage(page: number) {
      setControlTable({ ...controlTable, offset: controlTable.limit * page, page });
    }

    const renderLabelDownload = () => {
      if (loadingDownload) {
        return (
          <>
            Preparando arquivo para baixar <CircularProgress size={15} style={{ marginLeft: 5 }} />
          </>
        );
      }

      return "Baixar críticas";
    };

    const renderCheckbox = (item: ITypeDataLotDetails) => {
      const isItemSelected = checkedRowsIds.includes(item.id);

      if (!["restriction", "positive", "cancel"].includes(item.status.statusId)) {
        return (
          <Checkbox
            key={item.id}
            checked={isItemSelected}
            onChange={() => checkRow(item.id)}
            // eslint-disable-next-line no-negated-condition
            style={{ color: !isItemSelected ? colors.platinum : colors.violetRed }}
          />
        );
      }

      return "";
    };

    const tdStyles: React.CSSProperties = {
      textAlign: "center",
    };

    const renderTableData = (): Array<Array<string | Date | JSX.Element>> => {
      return results.map((item, index) => {
        if (index >= controlTable.offset && index < controlTable.limit * (controlTable.page + 1)) {
          return [
            renderCheckbox(item),
            item.status.statusName,
            formatCpf(item.doc),
            formatDate(item.birthdate),
            item.name,
            item.mother,
            item.DDD,
            item.cellNumber,
            item.email,
            <Add style={{ fill: "red" }} key={item.id} onClick={() => handleHistoric({ ...item })} />,
          ];
        }

        return [];
      });
    };

    return (
      <>
        <Table
          tdStyles={tdStyles}
          titles={columns}
          ids={results.map(item => item.partnerId)}
          data={renderTableData()}
          isPagination
          totalRows={results.length}
          rowsPerPage={controlTable.limit}
          rowsPerPageOptions={[controlTable.limit]}
          page={controlTable.offset / controlTable.limit}
          handleChangePage={handleChangePage}
        />
        <ClearOutlinedButton
          iconComponent={<SvgFileDownload size={15} style={{ color: "#515251", marginRight: 10 }} />}
          label={renderLabelDownload()}
          onClick={handleDownload}
          style={{ position: "relative", bottom: 40 }}
        />
      </>
    );
  }

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>Tombamento de Base - Detalhes</Title>
        </TitleContainer>
        <Button
          theme="neutral"
          borderType="semi-circle"
          onClick={() => routerStore.push(openingAccountsSectionPath("tipping"))}
        >
          <ArrowIcon />
          <span>Voltar para a lista</span>
        </Button>
      </Header>
      <Box>
        <Row>
          <Row>{renderCpfFilter()}</Row>
          <Row>{partnerDataLotDetails.length > 0 ? renderLabelInfo() : ""}</Row>
        </Row>
        <Spacing />
        {checkedRows.length > 0 && (
          <>
            <Line />
            <Spacing />
            {renderOnlyCheckeds()}
          </>
        )}
        <Spacing />
        <Line />
        <Spacing />
        <Content style={{ maxWidth: "100%" }}>
          <>
            {renderCheckFilter()}
            {labelStatusPartner !== "Cancelado" && (
              <ClearOutlinedButton
                label="Cancelar"
                disabled={!generalStore.hasPermission(api.AdminUserPermission.cancelTipping)}
                onClick={() => cancelTipping()}
              />
            )}
          </>
        </Content>
      </Box>
      {renderData()}
      {renderModal()}
      <ModalCancel
        visible={modalVisibleCancel}
        closeModal={closeModal}
        id={parseInt(match.params.id, 10)}
        dataChecked={checkedRows}
        clearSelecteds={clearSelecteds}
      />
    </>
  );
};
