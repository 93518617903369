import * as React from "react";
import styled from "styled-components";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogComponent, { DialogProps } from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import { CloseIcon } from "../Icons";

interface IProps extends Partial<DialogProps> {
  title: string;
  isOpen: boolean;
  showDivider?: boolean;
  showCloseButton?: boolean;
  onClose: () => void;
  padding?: string;
  closeButtonSize?: string;
}

export const Dialog: React.FunctionComponent<IProps> = ({
  title,
  isOpen,
  showDivider,
  showCloseButton,
  onClose,
  padding,
  children,
  closeButtonSize,
  ...dialogProps
}) => (
  <DialogComponent open={isOpen} onClose={onClose} {...dialogProps}>
    <StyledDialogTitle disableTypography>
      {title}
      <StyledCloseButton onClick={onClose} hidden={!showCloseButton}>
        <CloseIcon size={closeButtonSize} />
      </StyledCloseButton>
    </StyledDialogTitle>
    <Divider hidden={!showDivider} />
    <Content padding={padding}>{children}</Content>
  </DialogComponent>
);

const Content = styled.div<{ padding?: string }>`
  padding: ${props => props.padding || "20px"};
`;

const CloseButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  float: right;
  &:focus {
    outline: 0;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-family: Lato !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #515251 !important;
` as any;

const StyledCloseButton = styled.button<{ width?: string }>`
  color: #a6a6a6;
  border: none;
  background: transparent;
  cursor: pointer;
  float: right;
  &:focus {
    outline: 0;
  }
`;
