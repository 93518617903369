/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { CompanyV13, Permission, Profile } from "api/manager-api";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { colors } from "utils";

import { SaveButton, TabsContainer } from "../components";
import { ProfileData } from "./ProfileData";
import { ProfilePermissions } from "./ProfilePermissions";
import { GeneralStore, LegalEntityStore } from "../../../../stores";
import { TabNavigator } from "../../../../components/TabNavigator";

interface IProps {
  profile: Profile | null;
  company: CompanyV13;
  close: () => void;
  generalStore?: GeneralStore;
  legalEntityStore?: LegalEntityStore;
}

@inject("generalStore", "legalEntityStore")
@observer
export class ProfileWizard extends React.Component<IProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable private profile: any = this.props.profile || {
    company: this.props.company,
    companyId: this.props.company.id,
    id: null,
    name: "",
    permissions: [],
  };

  @observable private loading = false;

  @observable private selectedTab = "profileData";

  @action
  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value }: HTMLInputElement = event.currentTarget;

    this.profile[name as keyof Profile] = value;
  };

  @action
  private handlePermissions = (permissions: Permission[]) => {
    this.profile = {
      ...this.profile,
      permissions,
    };
  };

  @action
  private onChangeTab = (value: string) => {
    this.selectedTab = value;
  };

  private get tabs() {
    return [
      {
        child: <ProfileData profile={this.profile} onChange={this.handleChange} />,
        label: "Perfil",
        value: "profileData",
      },
      {
        child: <ProfilePermissions profile={this.profile} onChange={this.handlePermissions} />,
        label: "Permissões",
        value: "profilePermissions",
      },
    ];
  }

  private save = async (event: React.MouseEvent) => {
    event.preventDefault();
    const { documentNumber } = this.props.company;
    const { setProfile, getProfiles, getUsersByCompany } = this.props.legalEntityStore!;

    try {
      this.loading = true;
      await setProfile(this.profile);
      this.props.close();
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao salvar o perfil.");
    } finally {
      try {
        await getProfiles(documentNumber);
        await getUsersByCompany(documentNumber);
      } catch (err) {
        this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao carregar dados atualizados.");
      }

      this.loading = false;
    }
  };

  public render() {
    return (
      <TabsContainer>
        <TabNavigator onChangeTab={this.onChangeTab} selected={this.selectedTab} tabs={this.tabs} />
        <SaveButton
          label={this.props.profile ? "Editar perfil" : "Adicionar perfil"}
          onClick={this.save}
          customColor={colors.violetRed}
          loading={this.loading}
        />
      </TabsContainer>
    );
  }
}
