import * as React from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { colors } from "../../../utils";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string | React.ReactElement;
  loading?: boolean;
  iconComponent?: any;
}

const Container = styled.button`
  padding: 10px;
  background-color: ${colors.lightGray};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: ${colors.black};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
`;

export class OutlinedButton extends React.Component<IProps> {
  public render() {
    const { label, loading, disabled, iconComponent, ...allProps } = this.props;

    return (
      <Container disabled={disabled || loading} {...allProps}>
        {loading ? (
          <CircularProgress size={15} thickness={7} style={{ color: colors.violetRed }} />
        ) : (
            <ButtonContent>
              {iconComponent}
              {this.props.label}
            </ButtonContent>
          )}
      </Container>
    );
  }
}
