/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as api from "api/manager-api";
import { action, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";
import { TextInput } from "legacy/components/TextInput";
import { colors } from "utils";

import { Checkbox } from "../../../../components/Checkbox";
import { Dialog } from "../../../../components/Dialog";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { TimeManager } from "../../../../components/TimeManager";
import { prettierOperation } from "../../../../utils";

interface IProps {
  onCancel: (oldOperations: api.Operations, oldObs: string) => void;
  onSubmit: () => void;
  handleActiveOperation: (operation: keyof api.Operations, value: boolean) => void;
  handleObservation: (value: string) => void;
  handleTimeOperationSpecialDate: (operation: keyof api.Operations, value: api.DatePeriod) => void;
  isLoading: boolean;
  specialDate: api.ExceptionOperationsDate | undefined;
  isDialogOpen: boolean;
}

interface IState {
  oldOperations: api.Operations;
  oldObs: string;
}

const Container = styled.div``;

const Title = styled.h3`
  margin: 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0;
`;

const TimeManagerContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 420px;
`;

const Row = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.borderGray};
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const OutlinedButtonWrapper = styled(OutlinedButton)`
  margin-right: 20px;
  width: 124px;
`;

const RaisedButtonWrapper = styled.div`
  width: 124px;
`;

const WrapperDisabledTimeManager = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleDisabledTimeManager = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
`;

const TextDisabledTimeManager = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
  margin-right: 4px;
  margin-bottom: 16px;
`;

const StyledBorderBottom = styled.div`
  margin-top: -15px;
  border: 0.031em solid ${colors.borderGray};
  border-radius: 6px;
`;

const operationKeys: Array<keyof api.Operations> = [
  "doc",
  "titleInclusion",
  "investment",
  "paymentBankSlip",
  "paymentAgreement",
  "ted",
  "internalTransfer",
  "recharge",
  "withdraw",
];

@observer
export class ConfigService extends React.Component<IProps, IState> {
  private validation = observable.map<keyof api.Operations, boolean>();

  public componentDidMount() {
    const oldOperations = this.props.specialDate
      ? toJS(this.props.specialDate.operations)
      : {
          doc: null,
          internalTransfer: null,
          investment: null,
          paymentAgreement: null,
          paymentBankSlip: null,
          recharge: null,
          ted: null,
          titleInclusion: null,
          withdraw: null,
        };

    const oldObs = this.props.specialDate ? this.props.specialDate.obs : "";

    this.setState({ oldObs, oldOperations });
  }

  private isEnabledOp = (opKey: keyof api.Operations) => {
    return this.props.specialDate?.operations ? Boolean(this.props.specialDate.operations[opKey]) : false;
  };

  @action
  private handleValidation = (key: keyof api.Operations) => (valid: boolean) => {
    this.validation.set(key, valid);
  };

  public render() {
    const hasEditingErrors = Array.from(this.validation.values()).some((value: boolean) => !value);

    return (
      <Dialog
        title={this.props.specialDate ? moment(this.props.specialDate.date).format("L") : ""}
        isOpen={this.props.isDialogOpen}
        onClose={() => this.props.onCancel(this.state.oldOperations, this.state.oldObs)}
      >
        <StyledBorderBottom />

        <Container>
          <Title>Observação</Title>
          <TextInput
            value={this.props.specialDate ? this.props.specialDate.obs : ""}
            onChange={e => this.props.handleObservation(e.target.value)}
          />
          <Title>Serviços</Title>
          <TimeManagerContainer>
            {operationKeys.map((opKey, idx) => (
              <Row key={idx}>
                <Checkbox
                  checked={this.isEnabledOp(opKey)}
                  handleChange={() => this.props.handleActiveOperation(opKey, !this.isEnabledOp(opKey))}
                />
                {this.isEnabledOp(opKey) ? (
                  <TimeManager
                    label={prettierOperation(opKey)}
                    disabled={!this.isEnabledOp(opKey)}
                    value={this.props.specialDate!.operations[opKey]!}
                    onChange={value => {
                      this.props.handleTimeOperationSpecialDate(opKey, value!);
                    }}
                    onValidate={this.handleValidation(opKey)}
                  />
                ) : (
                  <WrapperDisabledTimeManager>
                    <TitleDisabledTimeManager>{prettierOperation(opKey)}</TitleDisabledTimeManager>
                    <TextDisabledTimeManager>Desativado</TextDisabledTimeManager>
                  </WrapperDisabledTimeManager>
                )}
              </Row>
            ))}
            <ButtonRow>
              <OutlinedButtonWrapper
                onClick={() => this.props.onCancel(this.state.oldOperations, this.state.oldObs)}
                label="Cancelar"
                disabled={this.props.isLoading}
              />
              <RaisedButtonWrapper>
                <RaisedButton
                  onClick={this.props.onSubmit}
                  label="Salvar"
                  loading={this.props.isLoading}
                  disabled={hasEditingErrors}
                />
              </RaisedButtonWrapper>
            </ButtonRow>
          </TimeManagerContainer>
        </Container>
      </Dialog>
    );
  }
}
