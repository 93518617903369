import React, { useEffect, useState } from "react";
import { useStores } from "@manager/stores/RootStore";
import { FormikErrors, FormikTouched } from "formik";
import { UploadBox } from "@manager/components/UploadBox";

import { InputLabel } from "components/InputLabel";
import { Input } from "components/Input";
import { InputMask } from "components/InputMask";
import { TextArea } from "components/Textarea";

import { IFields } from "./";

import s from "./style.scss";

interface IProps {
  values: IFields;
  errors: FormikErrors<IFields>;
  touched: FormikTouched<IFields>;
  setValues: (values: IFields) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  disabled?: boolean;
}

export function FormRegisterData({ disabled, errors, touched, values, setValues, setFieldValue }: IProps) {
  const { partnerStore } = useStores();
  const [flagIdManagerLot, setFlagIdManagerLot] = useState<string>("");
  const [flagAllowPartnerLot, setFlagAllowPartnerLot] = useState<boolean>(false);

  useEffect(() => {
    if (partnerStore?.partner?.managerIdLot) {
      setFlagIdManagerLot(partnerStore.partner.managerIdLot);
    }

    if (partnerStore?.partner?.allowPartnerLot) {
      setFlagAllowPartnerLot(partnerStore.partner.allowPartnerLot);
    }
  }, [partnerStore]);
  return (
    <div className={s.formWrapper}>
      <div className={s.title}>Configurações gerais</div>

      <div className={s.formGroup}>
        <InputLabel label="Nome *" error={errors.name}>
          <Input
            value={values.name}
            onChange={event => setFieldValue("name", event.target.value)}
            error={touched.name && Boolean(errors.name)}
            disabled={disabled}
          />
        </InputLabel>

        <InputLabel label="CNPJ" error={errors.cnpj}>
          <InputMask
            value={values.cnpj}
            onChange={event => setFieldValue("cnpj", event.target.value.replace(/\D/gu, ""))}
            error={touched.cnpj && Boolean(errors.cnpj)}
            disabled={disabled}
            maskChar=""
            mask="99.999.999/9999-99"
          />
        </InputLabel>

        <InputLabel label="ID do Gerente *" error={errors.managerId}>
          <Input
            value={values.managerId}
            onChange={event => setFieldValue("managerId", event.target.value)}
            error={touched.managerId && Boolean(errors.managerId)}
            disabled={disabled}
          />
        </InputLabel>
      </div>

      <div className={s.formGroup}>
        <InputLabel label="URL Internet Banking *" error={errors.ibUrl}>
          <Input
            addonBefore="https://"
            value={values.ibUrl}
            onChange={event => setFieldValue("ibUrl", event.target.value)}
            error={touched.ibUrl && Boolean(errors.ibUrl)}
            disabled={disabled}
          />
        </InputLabel>

        <InputLabel label="URL App IOS" error={errors.appIosUrl}>
          <Input
            addonBefore="https://"
            value={values.appIosUrl}
            onChange={event => setFieldValue("appIosUrl", event.target.value)}
            error={touched.appIosUrl && Boolean(errors.appIosUrl)}
            disabled={disabled}
          />
        </InputLabel>

        <InputLabel label="URL App Android" error={errors.appAndroidUrl}>
          <Input
            addonBefore="https://"
            value={values.appAndroidUrl}
            onChange={event => setFieldValue("appAndroidUrl", event.target.value)}
            error={touched.appAndroidUrl && Boolean(errors.appAndroidUrl)}
            disabled={disabled}
          />
        </InputLabel>
      </div>

      <div className={s.formGroup}>
        <InputLabel label="URL App Token iOS" error={errors.appTokenIosUrl}>
          <Input
            addonBefore="https://"
            value={values.appTokenIosUrl}
            onChange={event => setFieldValue("appTokenIosUrl", event.target.value)}
            error={touched.appTokenIosUrl && Boolean(errors.appTokenIosUrl)}
            disabled={disabled}
          />
        </InputLabel>

        <InputLabel label="URL App Token Android" error={errors.appTokenAndroidUrl}>
          <Input
            addonBefore="https://"
            value={values.appTokenAndroidUrl}
            onChange={event => setFieldValue("appTokenAndroidUrl", event.target.value)}
            error={touched.appTokenAndroidUrl && Boolean(errors.appTokenAndroidUrl)}
            disabled={disabled}
          />
        </InputLabel>

        <InputLabel label="URL Chat" error={errors.chatUrl}>
          <Input
            addonBefore="https://"
            value={values.chatUrl}
            onChange={event => setFieldValue("chatUrl", event.target.value)}
            error={touched.chatUrl && Boolean(errors.chatUrl)}
            disabled={disabled}
          />
        </InputLabel>

        <InputLabel label="URL Chat Mobile" error={errors.chatUrl}>
          <Input
            addonBefore="https://"
            value={values.chatUrlMobile}
            onChange={event => setFieldValue("chatUrlMobile", event.target.value)}
            error={touched.chatUrlMobile && Boolean(errors.chatUrlMobile)}
            disabled={disabled}
          />
        </InputLabel>
      </div>

      <div className={s.formGroup}>
        <InputLabel label="Chave Pública *" error={errors.publicKey} className={s.textAreaContainer}>
          <TextArea
            value={values.publicKey}
            onChange={event => setFieldValue("publicKey", event.target.value)}
            disabled={disabled}
            className={s.textArea}
          />
        </InputLabel>

        <InputLabel label="E-mail Remetente" error={errors.senderEmail}>
          <Input
            value={values.senderEmail}
            onChange={event => setFieldValue("senderEmail", event.target.value)}
            error={touched.senderEmail && Boolean(errors.senderEmail)}
            disabled={disabled}
          />
        </InputLabel>
      </div>

      <div className={s.formGroup}>
        <InputLabel label="Tarifas" error={errors.farePackagesBuffer}>
          <UploadBox
            onLoader={value => setValues({ ...values, farePackagesBuffer: value, farePackagesURL: null })}
            onClear={() => setValues({ ...values, farePackagesBuffer: null, farePackagesURL: null })}
            disabled={disabled}
            fileViewUrl={values.farePackagesURL}
            fileViewTitle="Visualizar arquivo de tarífas"
            accept="application/pdf"
          />
        </InputLabel>
      </div>

      <div className={s.titleMargin}>Arquivos para conta PF</div>

      <div className={s.formGroup}>
        <InputLabel label="Termos de uso" error={errors.pfTermsOfUserBuffer}>
          <UploadBox
            onLoader={value => setValues({ ...values, pfTermsOfUserBuffer: value, pfTermsOfUserUrl: null })}
            onClear={() => setValues({ ...values, pfTermsOfUserBuffer: null, pfTermsOfUserUrl: null })}
            disabled={disabled}
            fileViewUrl={values.pfTermsOfUserUrl}
            fileViewTitle="Visualizar termos de uso"
            accept="application/pdf"
          />
        </InputLabel>

        <InputLabel label="Contrato" error={errors.pfContractBuffer}>
          <UploadBox
            onLoader={value => setValues({ ...values, pfContractBuffer: value, pfContractUrl: null })}
            onClear={() => setValues({ ...values, pfContractBuffer: null, pfContractUrl: null })}
            disabled={disabled}
            fileViewUrl={values.pfContractUrl}
            fileViewTitle="Visualizar contrato"
            accept="application/pdf"
          />
        </InputLabel>
      </div>

      <div className={s.titleMargin}>Arquivos para conta PJ</div>

      <div className={s.formGroup}>
        <InputLabel label="Termos de uso" error={errors.pjTermsOfUserBuffer}>
          <UploadBox
            onLoader={value => setValues({ ...values, pjTermsOfUserBuffer: value, pjTermsOfUserUrl: null })}
            onClear={() => setValues({ ...values, pjTermsOfUserBuffer: null, pjTermsOfUserUrl: null })}
            disabled={disabled}
            fileViewUrl={values.pjTermsOfUserUrl}
            fileViewTitle="Visualizar termos de uso"
            accept="application/pdf"
          />
        </InputLabel>

        <InputLabel label="Contrato" error={errors.pjContractBuffer}>
          <UploadBox
            onLoader={value => setValues({ ...values, pjContractBuffer: value, pjContractUrl: null })}
            onClear={() => setValues({ ...values, pjContractBuffer: null, pjContractUrl: null })}
            disabled={disabled}
            fileViewUrl={values.pjContractUrl}
            fileViewTitle="Visualizar contrato"
            accept="application/pdf"
          />
        </InputLabel>
      </div>
    </div>
  );
}
