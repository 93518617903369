import * as React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { action, observable } from "mobx";
import { AdminUserPermission, ToDoErros } from "api/manager-api";
import { colors } from "utils";

import { connect, ConnectedComponent } from "../../utils";
import { ErrorsStore, GeneralStore } from "../../stores";
import { ErrorEditAction } from "./ErrorEditAction";
import { Fetch } from "../../components/Fetchable";
import { ErrorsList } from "./ErrorsList";
import { Fetcher } from "../../components/Fetcher";
import { SearchBar } from "../../components/SearchBar";

interface IStores {
  generalStore: GeneralStore;
  errorsStore: ErrorsStore;
}

@connect("generalStore", "errorsStore")
@observer
export class ErrorsManager extends ConnectedComponent<{}, IStores> {
  @observable private editOpen = false;

  @observable private searchValue = "";

  @observable private searchFilter: string | null = null;

  @observable private errors: Fetch<ToDoErros[]> = null;

  public componentWillMount = () => {
    this.getErrors();
  };

  @action
  private getErrors = async () => {
    this.errors = this.stores.errorsStore.getErrors();
  };

  @action
  private handleEdit = (error: ToDoErros) => {
    this.stores.errorsStore.currentError = error;
    this.editOpen = true;
  };

  @action
  private handleSearchInput = (value: string) => {
    this.searchValue = value;
  };

  @action
  private handleSearch = () => {
    this.searchFilter = this.searchValue || null;
  };

  @action
  private closeEdit = () => {
    this.editOpen = false;
  };

  public render() {
    const { errors } = this.stores.errorsStore;
    const { hasPermission } = this.stores.generalStore;

    return (
      <Container>
        <ErrorEditAction isOpen={this.editOpen} onClose={this.closeEdit} refetch={this.getErrors} />
        <Title>Gestão de Erros</Title>
        <SearchBar
          value={this.searchValue}
          onChange={this.handleSearchInput}
          placeholder="Pesquisar"
          handleSearch={this.handleSearch}
          enabledWithoutInput
        />
        <Fetcher
          fetch={this.errors}
          component={ErrorsList}
          items={errors ?? []}
          onEdit={hasPermission(AdminUserPermission.editErrors) ? this.handleEdit : undefined}
          filter={this.searchFilter}
        />
      </Container>
    );
  }
}

export const defaultMessage = "Ocorreu um erro no sistema.";

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 34px;
`;
