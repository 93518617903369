import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { PfPj } from "api/manager-api";
import { intergerMask } from "utils";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import {
  Container,
  IncomeTextInput,
  Label,
  Left,
  Right,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextInput,
  Title,
} from "./style";
import { GeneralStore, IPartnerIdLimit, PartnerStore, TransactionalBoundariesStore } from "../../../../../stores";
import { PartnerSelect } from "../../DefaultLimit/style";

type FieldName = "nameInput" | "minInput" | "maxInput";

interface IProps {
  generalStore?: GeneralStore;
  partnerStore?: PartnerStore;
  transactionalBoundariesStore?: TransactionalBoundariesStore;
  segmentId?: number | null;
  selectedPartner: string | null;

  close: () => void;
  success: () => void;
  fail: () => void;
}

const defaultLocale = "pt-BR";

const innerBRLFormatter = Intl.NumberFormat(navigator.language || defaultLocale, {
  currency: "BRL",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  style: "currency",
  useGrouping: true,
});

@inject("generalStore", "transactionalBoundariesStore", "partnerStore")
@observer
export class SegmentWizard extends React.Component<IProps> {
  @observable private loading = false;

  @observable private segmentName = "";

  @observable private segmentPartner: string | null = null;

  @observable private valuePersonType: PfPj = PfPj.pf;

  @observable private valueMinIncome = "0";

  @observable private valueMaxIncome = "0";

  @observable private errors: FieldName[] = [];

  @observable private touched: FieldName | undefined;

  @observable private partners: IPartnerIdLimit[] = [];

  public componentDidMount() {
    if (this.props.segmentId) {
      this.getData();
    }

    if (this.props.partnerStore!.partners) {
      this.partners = this.props.partnerStore!.partners!.map(partner => ({ label: partner.name, value: partner.id }));
    }
  }

  @action
  private getData = async () => {
    try {
      this.loading = true;
      await this.props
        .transactionalBoundariesStore!.getSegmentTransactionalById(this.props.segmentId!.toString())
        .then(() => {
          this.segmentName = this.props.transactionalBoundariesStore!.segment!.name;
          this.segmentPartner = this.props.transactionalBoundariesStore!.segment!.partnerId;
          this.valuePersonType = this.props.transactionalBoundariesStore!.segment!.accountType;
          this.valueMinIncome = this.props.transactionalBoundariesStore!.segment!.minIncome.toString();
          this.valueMaxIncome = this.props.transactionalBoundariesStore!.segment!.maxIncome.toString();
        });
    } finally {
      this.loading = false;
    }
  };

  @action
  private handleClose = () => {
    this.props.close();
  };

  @action
  private isError = (key: FieldName) => {
    return Boolean(this.errors.find(e => e === key)) && this.touched !== key;
  };

  @action
  private checkEmpty = (field: FieldName) => {
    const { segment } = this.props.transactionalBoundariesStore!;
    const obj = {
      maxInput: () => this.valueMaxIncome === "0",
      minInput: () => this.valueMinIncome === "0",
      nameInput: (name: string) => (segment ? segment.name.length === 0 : name.length === 0),
    };

    return obj[field];
  };

  @action
  private onFocus = (event: React.FocusEvent<HTMLInputElement> | null, name?: FieldName) => {
    this.touched = event ? (event.target.name as FieldName) : name!;
  };

  @action
  private handlePost = () => {
    const { segment } = this.props.transactionalBoundariesStore!;

    this.onValidate("nameInput");
    this.onValidate("minInput");
    this.onValidate("maxInput");

    if (this.errors.length > 0) {
      this.forceUpdate();
      return;
    }

    if (this.props.segmentId === null || this.props.segmentId === undefined) {
      this.props
        .transactionalBoundariesStore!.createSegmentTransactional({
          accountType: this.valuePersonType!,
          id: null,
          maxIncome: parseFloat(this.valueMaxIncome.replace(/[^0-9 ]/gu, "")),
          minIncome: parseFloat(this.valueMinIncome.replace(/[^0-9 ]/gu, "")),
          name: this.segmentName,
          observation: null,
          partnerId: this.segmentPartner,
        })
        .then(() => {
          this.props.success();
          this.props.close();
        })
        .catch(() => {
          this.props.fail();
        });
    } else if (segment) {
      segment.minIncome = parseFloat(this.valueMinIncome.replace(/[^0-9 ]/gu, ""));
      segment.maxIncome = parseFloat(this.valueMaxIncome.replace(/[^0-9 ]/gu, ""));
      segment.name = this.segmentName;
      segment.accountType = this.valuePersonType!;
      segment.partnerId = this.segmentPartner;
      this.props
        .transactionalBoundariesStore!.updateSegmentTransactional(segment)
        .then(_ => {
          this.props.success();
          this.props.close();
        })
        .catch(_ => {
          this.props.fail();
        });
    }
  };

  @action
  private onBlur = (event: React.FocusEvent<HTMLInputElement> | null, name?: FieldName) => {
    const fieldName = event ? (event.target.name as FieldName) : name!;

    if (this.touched !== fieldName) {
      return;
    }

    this.onValidate(fieldName);
  };

  @action
  private onValidate = (field: FieldName) => {
    const newErrors = this.errors;
    const isEmpty = this.checkEmpty(field);
    let value: null | string;
    const idx = this.errors.findIndex(e => e === field);

    if (field === "nameInput") {
      value = this.segmentName;
    } else if (field === "minInput") {
      value = this.valueMinIncome.toString();
    } else {
      value = this.valueMaxIncome.toString();
    }

    if (isEmpty(value) && idx === -1) {
      newErrors.push(field);
      this.errors = newErrors;
    } else if (!isEmpty(value) && idx !== -1) {
      newErrors.splice(idx, 1);
      this.errors = newErrors;
    }

    this.touched = undefined;
  };

  private valueFormatter(value: any) {
    return innerBRLFormatter.format(typeof value === "string" ? parseInt(intergerMask(value), 10) / 100 : value);
  }

  public render() {
    return (
      <>
        <Container>
          <Left>
            <Title>Nome do Segmento</Title>
            <StyledTextInput
              errorImage
              value={this.segmentName}
              onChange={e => (this.segmentName = e.target.value)}
              onBlur={this.onBlur}
              error={this.isError("nameInput")}
              errorText="Campo não pode ser vazio"
              name="nameInput"
              onFocus={this.onFocus}
              maxLength={50}
            />
          </Left>
          <Left>
            <Title>Tipo de Pessoa</Title>
            <RadioGroup
              name="person-type"
              value={this.valuePersonType!}
              style={{ flexDirection: "row" }}
              onChange={(_, value) => (this.valuePersonType = PfPj[value as PfPj])}
            >
              <FormControlLabel value={"pf" as PfPj} control={<Radio />} label="PF" />
              <FormControlLabel value={"pj" as PfPj} control={<Radio />} label="PJ" />
            </RadioGroup>
          </Left>
        </Container>

        <Left>
          <Title>Parceiro</Title>
          <Container>
            <PartnerSelect
              isSearchable
              value={this.partners.filter(partner => partner.value === this.segmentPartner)}
              options={[{ label: "Todos os parceiros", value: null }, ...this.partners]}
              onChange={(e: IPartnerIdLimit) => (this.segmentPartner = e.value)}
              placeholder="Todos os parceiros"
            />
          </Container>
        </Left>
        <Left>
          <Title>Renda/Faturamento Mensal</Title>
          <Container>
            <IncomeTextInput
              errorImage
              error={this.isError("minInput")}
              errorText="Campo não pode ser vazio"
              name="minInput"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              value={this.valueFormatter(this.valueMinIncome)}
              onChange={e => (this.valueMinIncome = e.target.value)}
            />
            <Label>a</Label>
            <IncomeTextInput
              errorImage
              error={this.isError("maxInput")}
              errorText="Campo não pode ser vazio"
              name="maxInput"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              value={this.valueFormatter(this.valueMaxIncome)}
              onChange={e => (this.valueMaxIncome = e.target.value)}
            />
          </Container>
        </Left>

        <Right>
          <StyledOutlinedButton label="Cancelar" onClick={this.handleClose} />
          <StyledRaisedButton loading={this.loading} onClick={this.handlePost} label="Salvar" type="submit" />
        </Right>
      </>
    );
  }
}
