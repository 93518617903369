import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";

import { Header, Title, TitleContainer } from "./style";
import { ClientCustomization } from "./ClientCustomization";
import { GeneralStore } from "../../../stores";
import { TabNavigator } from "../../../components/TabNavigator";

type Tab = "clientCustomization";

interface IProps {
  generalStore: GeneralStore;
}

interface IState {
  selectedTab: Tab;
}

@inject("generalStore", "platformManagerStore")
@observer
export class LimitCustomization extends React.Component<IProps, IState> {
  @observable private selectedTab: Tab = "clientCustomization";

  @action
  private onChangeTab = (tabValue: Tab) => {
    this.selectedTab = tabValue;
  };

  public render() {
    return (
      <>
        <Header>
          <TitleContainer>
            <Title>Personalização de Limites</Title>
          </TitleContainer>
        </Header>
        <TabNavigator
          onChangeTab={tabValue => this.onChangeTab(tabValue as Tab)}
          selected={this.selectedTab}
          tabs={this.tabs()}
        />
      </>
    );
  }

  private tabs() {
    return [
      {
        child: <ClientCustomization />,
        label: "Personalização por Cliente",
        value: "clientCustomization",
      },
    ];
  }
}
