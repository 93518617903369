/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable no-negated-condition */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
/* eslint-disable require-unicode-regexp */
/* eslint-disable-next-line implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import {
  clearPlaceholderWhenStateChanges,
  formatType,
  openingAccountsSectionPath,
  renderCharacters,
} from "@manager/utils";
import moment from "moment";
import save from "save-file";
import { CircularProgress } from "@material-ui/core/";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { InputLabel } from "components/InputLabel";
import { TextArea } from "components/Textarea";

import { ReactComponent as ArrowIcon } from "../../../../../assets/svgs/arrow-back.svg";
import { Button } from "../../../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { TextInput } from "../../../components/TextInput";
import { Table } from "../../../components/Table";
import {
  BackButtonWrapper,
  Box,
  DescriptionLabel,
  Header,
  InputFile,
  LabelDefault,
  LabelInputFile,
  Line,
  List,
  Row,
  RowFlexEnd,
  Spacing,
  Title,
  TitleContainer,
  TitleInputModal,
} from "../../../assets/styles/global";

import "./style.scss";

interface IControlTable {
  offset: number;
  limit: number;
  page: number;
}

type ITypeDataLotLabel = api.Prospect;

type ITypeDataCommentsAttachment = api.PartnerFileComment;

type ITypeDataHistoric = api.PartnerNotification;

type ITypeDataLotAttachement = api.PartnerFileAttachment;

const initialStateTable: IControlTable = {
  offset: 0,
  limit: 10,
  page: 0,
};

export const Attachments: React.FC = ({ match }: any) => {
  const { generalStore, partnerStore, routerStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [partnerDataLotLabel, setPartnerDataLotLabel] = useState<ITypeDataLotLabel[]>([]);
  const [partnerDataLotAttachments, setPartnerDataLotAttachments] = useState<ITypeDataLotAttachement[]>([]);
  const [partnerDataCommentsAttachment, setPartnerDataCommentsAttachment] = useState<ITypeDataCommentsAttachment[]>([]);
  const [controlTable, setControlTable] = useState<IControlTable>(initialStateTable);
  const [labelNamePartner, setLabelNamePartner] = useState<string>("");
  const [labelDescriptionPartner, setLabelDescriptionPartner] = useState<string>("");
  const [labelStatusPartner, setlabelStatusPartner] = useState<string>("");
  const [description, setDescription] = useState("");
  const [descriptionFile, setDescriptionFile] = useState("");
  const [fileInputName, setFileInputName] = useState("");
  const [fileType, setFileType] = useState("");
  const [toedit, setToEdit] = useState(true);
  const [visibleBtn, setVisibleBtn] = useState(false);
  const [attachmentOpenFile, setAttachmentOpenFile] = useState(false);
  const [fileSelected, setFileSelected] = React.useState<Buffer | null>(null);

  async function getDataLotLabel(partnerFileId: string) {
    try {
      setLoading(true);
      await partnerStore.getPartnerDataLotDetails(partnerFileId);
      const list: ITypeDataLotLabel[] = [];

      partnerStore.partnerDataLotDetails?.forEach(partnerLabel => {
        if (partnerLabel.id) {
          list.push({ ...partnerLabel });
        }
      });
      setPartnerDataLotLabel(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
      if (
        partnerStore.partnerDataLotDetails &&
        partnerStore.partnerDataLotDetails.length > 0 &&
        partnerStore.partnerDataLotDetails[0].partnerName &&
        partnerStore.partnerDataLotDetails[0].partnerFileDescription &&
        partnerStore.partnerDataLotDetails[0].partnerFileStatusName
      ) {
        setLabelNamePartner(partnerStore.partnerDataLotDetails[0].partnerName);
        setLabelDescriptionPartner(partnerStore.partnerDataLotDetails[0].partnerFileDescription);
        setlabelStatusPartner(partnerStore.partnerDataLotDetails[0].partnerFileStatusName);
      }
    }
  }

  async function getPartnerDataLotAttachments(partnerFileId: number) {
    try {
      setLoading(true);
      await partnerStore.getPartnerDataAttachments(partnerFileId);
      const list: ITypeDataLotAttachement[] = [];

      partnerStore.partnerDataAttachments?.forEach(partnerDataAttachments => {
        if (partnerDataAttachments.partnerFileId) {
          list.push({ ...partnerDataAttachments });
        }
      });
      setPartnerDataLotAttachments(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function getParnerDataCommentsAttachament(partnerFileId: number) {
    try {
      setLoading(true);
      await partnerStore.getPartnerDataCommentsAttachment(partnerFileId);

      const list: ITypeDataCommentsAttachment[] = [];

      partnerStore.partnerDataCommentsAttachments?.forEach(partnerComment => {
        if (partnerComment.partnerFileId) {
          list.push({ ...partnerComment });
        }
      });
      setPartnerDataCommentsAttachment(list);
      setDescription("");
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDataLotLabel(match.params.id);
    getPartnerDataLotAttachments(parseInt(match.params.id, 10));
    getParnerDataCommentsAttachament(parseInt(match.params.id, 10));
  }, []);

  function toEditAttachment() {
    setToEdit(false);
    setVisibleBtn(true);
  }

  function cancelAttachment() {
    setToEdit(true);
    setVisibleBtn(false);
  }

  async function saveAttachment() {
    let partnerFileCommentId = 0;

    if (
      partnerDataCommentsAttachment.length !== 0 &&
      partnerDataCommentsAttachment[0].partnerFileCommentId !== undefined
    ) {
      partnerFileCommentId = partnerDataCommentsAttachment[0].partnerFileCommentId;
    }

    const partnerFilecommentObs = description;
    const partnerFileId = parseInt(match.params.id, 10);
    let response = null;

    try {
      setLoading(true);
      response = await api.insertPartnerFileComment(partnerFileCommentId, partnerFilecommentObs, partnerFileId);
    } catch (err) {
      const message = JSON.parse(err.message);

      generalStore.showToast(message.message);
    } finally {
      setLoading(false);
      getParnerDataCommentsAttachament(parseInt(match.params.id, 10));
      cancelAttachment();
    }
  }

  function sendAttachment() {
    setAttachmentOpenFile(true);
  }

  async function sendFileAttachment() {
    if (descriptionFile && fileSelected && !loading) {
      const partnerFileId = parseInt(match.params.id, 10);
      const partnerFileAttachmentDescription = descriptionFile;
      const partnerFileattAchmentFileType = formatType(fileType, "bufferType");
      const partnerFileAttachmentFile = fileSelected;
      const partnerFileAttachmentFileName = fileInputName;
      let response = null;

      try {
        setLoading(true);
        response = await api.insertPartnerFileAttachment(
          partnerFileId,
          partnerFileAttachmentDescription,
          partnerFileattAchmentFileType,
          partnerFileAttachmentFile,
          partnerFileAttachmentFileName,
        );
        setFileInputName("");
        setFileSelected(null);
        cancelAttachment();
      } catch (err) {
        if (err.message) {
          const message = JSON.parse(err.message);

          generalStore.showToast(message.message);
        }
      } finally {
        // eslint-disable-next-line capitalized-comments
        /*
         * Console.log("response:", response);
         * if (response !== null && response.ok) {
         *   if (response.message) {
         *     generalStore.showToast(response.message);
         *   }
         * }
         */

        setLoading(false);
        getPartnerDataLotAttachments(parseInt(match.params.id, 10));
        setAttachmentOpenFile(false);
        setFileSelected(null);
      }

      onClose();
    }
  }

  function onClose() {
    setDescriptionFile("");
    setAttachmentOpenFile(false);
  }

  function onChangeFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    const file = files !== null ? files[0] : null;
    const name = file !== null ? file.name : "";
    const type = file && file.type !== "" ? file.type : "xlsx";

    setFileInputName(name);

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (reader.result && typeof reader.result !== "string") {
          const fileBuffer = Buffer.from(reader.result);

          setFileSelected(fileBuffer);
          setFileInputName(name);
          setFileType(type);
        }
      });

      reader.readAsArrayBuffer(file);
    }
  }

  const countStatus = (status: string) => {
    let total = 0;

    partnerDataLotLabel.forEach(item => {
      if (status === "all") {
        return (total += 1);
      } else if (item.statusId === status) {
        return (total += 1);
      }

      return total;
    });
    return total;
  };

  const sendDownload = async (buffer: Buffer, fileName: string) => {
    const blob = new Blob([buffer]);

    return save(blob, fileName);
  };

  async function handleDownload(id: number, fileName: string | null) {
    setLoading(true);
    const partnerFileAttachmentId = id;

    try {
      const buffer = await api.getDownloadPartnerFileAttachment(partnerFileAttachmentId);

      setLoading(false);
      if (fileName !== null) {
        await sendDownload(buffer, fileName);
      }
    } catch (err) {
      const message = JSON.parse(err.message);

      generalStore.showToast(message.message);

      setLoading(false);
    }
  }

  function renderLabelInfo() {
    return (
      <List>
        <li title={labelNamePartner ? labelNamePartner : "Todos"}>
          <LabelDefault>{labelNamePartner ? labelNamePartner : "Todos"}</LabelDefault>
        </li>
        <li title={labelDescriptionPartner}>
          <LabelDefault>{labelDescriptionPartner}</LabelDefault>
        </li>
        <li title={labelStatusPartner}>
          <LabelDefault>{labelStatusPartner}</LabelDefault>
        </li>
        <li title={`Total de registros: ${countStatus("all")}`}>
          <LabelDefault>{countStatus("all")}</LabelDefault>
        </li>
      </List>
    );
  }

  function renderTextArea() {
    let placeholder: string[] = [];

    if (partnerDataCommentsAttachment.length > 0) {
      placeholder = partnerDataCommentsAttachment.map(partnerDataComment => {
        return `${moment(partnerDataComment.partnerFileCommentDateTime).format("l")}\n ${
          partnerDataComment.partnerFilecommentObs
        } \n ${renderCharacters("-", 135)} \n`;
      });
    }

    return (
      <TextArea
        style={{ minWidth: "670px", minHeight: "200px", maxWidth: "800px", maxHeight: "250px" }}
        value={description}
        placeholder={clearPlaceholderWhenStateChanges(placeholder, toedit)}
        onChange={e => setDescription(e.target.value)}
        readOnly={toedit}
      />
    );
  }

  function renderTable() {
    const columns = ["Data do Arquivo", "Descrição do Arquivo", "Nome do Arquivo", "Baixar"];

    return (
      <>
        <Table
          titles={columns}
          ids={partnerDataLotAttachments.map(item => item.partnerFileAttachmentId.toString())}
          data={partnerDataLotAttachments.map((item, index) => {
            if (index >= controlTable.offset && index < controlTable.limit * (controlTable.page + 1)) {
              return [
                item.partnerFileAttachmentDateTime,
                item.partnerFileAttachmentDescription,
                item.partnerFileAttachmentFileName,
                <MoreHorizIcon
                  style={{ border: "1px solid #DCDCE0", borderRadius: "4px", padding: "2px 2px" }}
                  key={item.partnerFileAttachmentId}
                  onClick={() => handleDownload(item.partnerFileAttachmentId, item.partnerFileAttachmentFileName)}
                />,
              ];
            }

            return [];
          })}
        />
      </>
    );
  }

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>Tombamento - Anexos</Title>
        </TitleContainer>
        <Button
          theme="neutral"
          borderType="semi-circle"
          onClick={() => routerStore.push(openingAccountsSectionPath("tipping"))}
        >
          <ArrowIcon />
          <span>Voltar para a lista</span>
        </Button>
      </Header>
      <Box>
        <Row>
          <Row>{partnerDataLotLabel.length > 0 ? renderLabelInfo() : ""}</Row>
        </Row>
        <Spacing />
        <Line />
        <Spacing />
        <Row>
          <div className="formGroup">
            <InputLabel label="Observação Geral dos Anexos">{renderTextArea()}</InputLabel>
          </div>
          <BackButtonWrapper
            style={{ padding: "15px 30px" }}
            label="Anexar"
            onClick={() => sendAttachment()}
            disabled={toedit}
          />
        </Row>
      </Box>
      {renderTable()}
      <Spacing />
      <Line />
      <Spacing />
      <RowFlexEnd>
        {!visibleBtn ? (
          <Button style={{ padding: "15px 30px" }} theme="secondary" onClick={() => toEditAttachment()}>
            Editar
          </Button>
        ) : (
          ""
        )}
        {visibleBtn ? (
          <Row>
            <Row>
              <Button
                style={{ padding: "15px 30px", marginRight: "30px" }}
                theme="secondary"
                type="button"
                onClick={() => cancelAttachment()}
              >
                Cancelar
              </Button>
            </Row>
            <Row>
              <Button style={{ padding: "15px 30px" }} onClick={() => saveAttachment()}>
                Salvar
              </Button>
            </Row>
          </Row>
        ) : (
          ""
        )}
      </RowFlexEnd>
      <Dialog showCloseButton title={"Anexar"} isOpen={attachmentOpenFile} onClose={onClose} padding="0 20px 20px">
        <Spacing />
        <TitleInputModal>Descrição do arquivo</TitleInputModal>
        <TextInput
          // Error={this.getError("managerId")}
          name="description"
          value={descriptionFile}
          onChange={e => setDescriptionFile(e.target.value)}
          maxLength={30}
        />
        <DescriptionLabel>max. 30 caracteres</DescriptionLabel>
        <TitleInputModal>Upload da arquivo</TitleInputModal>
        <LabelInputFile htmlFor="fileSelect">
          <span>Upload</span>
          {fileInputName ? fileInputName : "Selecione arquivo..."}
        </LabelInputFile>
        <InputFile type="file" id="fileSelect" name="fileTipping" onChange={e => onChangeFile(e)} />
        <Spacing />
        <Spacing />
        <BackButtonWrapper
          label={loading ? <CircularProgress size={15} style={{ color: "#FFF" }} /> : "Salvar"}
          onClick={sendFileAttachment}
        />
      </Dialog>
    </>
  );
};
