/* eslint-disable react/prop-types */
import { IFields } from "..";

import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import classnames from "classnames";
import * as api from "api/manager-api";
import { Select } from "legacy/components/Select";

import { InputLabel } from "@components/InputLabel";
import { Input } from "components/Input";
import { Checkbox } from "@components/Checkbox";
import { Button } from "components/Button";
/*
 * Implementação pendente de perfis
 * import { ReactComponent as DotsIcon } from "@assets/svgs/dots.svg";
 * import { Dropdown } from "@components/Dropdown";
 *
 * import { CardConfigDropdown } from "./CardConfigDropdowns";
 */

import s from "./style.scss";

interface IProps {
  values: IFields;
  errors: FormikErrors<IFields>;
  touched: FormikTouched<IFields>;
  blockFields: boolean;
  isEdit: boolean;
  disabled?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  onCancel: () => void;
  onSave: () => void;
  onEditFields: () => void;
}

export function ModalCard({
  disabled,
  errors,
  onCancel,
  touched,
  values,
  blockFields,
  isEdit,
  setFieldValue,
  onSave,
  onEditFields,
}: IProps) {
  const brandOptions = [
    {
      label: "Visa",
      value: api.CardBrand.visa,
    },
  ];

  const broadcasterOptions = [
    {
      label: "Dock",
      value: api.BroadCaster.dock,
    },
  ];

  const multicardOptions = [
    {
      label: "Sem multicartões",
      value: api.MultiCard.without,
    },
    {
      label: "Multicartões livre",
      value: api.MultiCard.free,
    },
    {
      label: "Multicartões restrito por CPF",
      value: api.MultiCard.restricted,
    },
  ];

  const binOptions = [
    {
      label: "PF",
      value: "pf",
    },
    {
      label: "PJ",
      value: "pj",
    },
  ];

  const handleOnChangeBroadcaster = (opt: any) => {
    if (opt && opt.value !== values.broadcaster) {
      setFieldValue("broadcaster", opt.value);
    } else {
      setFieldValue("broadcaster", "");
    }
  };

  const handleOnChangeBrand = (opt: any) => {
    if (opt && opt.value !== values.cardBrand) {
      setFieldValue("cardBrand", opt.value);
    } else {
      setFieldValue("cardBrand", "");
    }
  };

  const handleOnChangeMulticards = (opt: any) => {
    if (opt && opt.value !== values.multicards) {
      setFieldValue("multicards", opt.value);
    } else {
      setFieldValue("multicards", "");
    }
  };

  const handleOnChangeBin = (opt: any) => {
    if (opt && opt.value !== values.typeBin) {
      setFieldValue("typeBin", opt.value);
    } else {
      setFieldValue("typeBin", "");
    }
  };

  const disabledFormSaveButton = () => {
    const {
      visaIdProduct,
      numberBin,
      typeBin,
      visaIdPlastic,
      visaIdComercial,
      visaIdImage,
      visaImageDescription,
    } = values;

    if (
      visaIdProduct.length === 0 ||
      numberBin.length === 0 ||
      typeBin.length === 0 ||
      visaIdPlastic.length === 0 ||
      visaIdComercial.length === 0 ||
      visaIdImage.length === 0 ||
      visaImageDescription.length === 0
    ) {
      return true;
    }

    return false;
  };

  const renderFields = () => {
    if (values.cardBrand === "nenhum") {
      return <div />;
    }

    if (values.cardBrand === "visa") {
      return (
        <>
          <div className={s.section}>
            <div className={classnames(s.marginRight, s.flex, s.hasOptions)}>
              <div className={s.title}>Configurações do ID</div>
              <div className={classnames(s.title, s.pinkText)}>
                {blockFields ? `Configuração ID ${values.visaIdProduct}` : "Personalizada"}
              </div>
              {/* <Dropdown overlay={CardConfigDropdown} onOverlayClick={e => e.stopPropagation()}>
                <Button theme="neutral" className={s.btn_action} onClick={x => x.stopPropagation()}>
                  <DotsIcon />
                </Button>
              </Dropdown> */}
              <div />
            </div>
            <div className={s.formGroup}>
              <InputLabel label="ID Produto *" error={""}>
                <Input
                  value={values.visaIdProduct}
                  onChange={event => setFieldValue("visaIdProduct", event.target.value)}
                  error={touched.visaIdProduct && Boolean(errors.visaIdProduct)}
                  disabled={blockFields || disabled || Boolean(values.cardBrandDisabled)}
                  placeholder="Informe o ID"
                />
              </InputLabel>
              <InputLabel label="Número BIN *" error={""}>
                <Input
                  value={values.numberBin}
                  onChange={event => setFieldValue("numberBin", event.target.value)}
                  error={touched.numberBin && Boolean(errors.numberBin)}
                  disabled={blockFields || disabled}
                  placeholder="Informe o BIN"
                />
              </InputLabel>
              <div className={classnames(s.selectContainer, s.smallerInput)}>
                <div className={classnames(s.subtitle, s.groupedSubtitle)}>Tipo de BIN</div>
                <Select
                  isDisabled={blockFields || disabled}
                  placeholder="Selecione o BIN"
                  onChange={handleOnChangeBin}
                  value={binOptions.find(option => option.value === values.typeBin)}
                  options={binOptions}
                />
              </div>
              <InputLabel label="Tipo Plástico *" error={""}>
                <Input
                  value={values.visaIdPlastic}
                  onChange={event => setFieldValue("visaIdPlastic", event.target.value)}
                  error={touched.visaIdPlastic && Boolean(errors.visaIdPlastic)}
                  disabled={blockFields || disabled}
                  placeholder="Informe o tipo"
                />
              </InputLabel>
              <InputLabel label="Origem Comercial *" error={""}>
                <Input
                  value={values.visaIdComercial}
                  onChange={event => setFieldValue("visaIdComercial", event.target.value)}
                  error={touched.visaIdComercial && Boolean(errors.visaIdComercial)}
                  disabled={blockFields || disabled}
                  placeholder="Informe a origem"
                />
              </InputLabel>
            </div>
            <div className={s.formGroup}>
              <InputLabel label="Tipo de cartão" error={""}>
                <div className={s.checkContainer}>
                  <Checkbox
                    checked={values.hasName}
                    onChange={() => setFieldValue("hasName", !values.hasName)}
                    disabled={blockFields || disabled}
                  >
                    <span className={s.spanInfo}>Name</span>
                  </Checkbox>
                  <Checkbox
                    checked={values.hasNoName}
                    onChange={() => setFieldValue("hasNoName", !values.hasNoName)}
                    disabled={blockFields || disabled}
                  >
                    <span className={s.spanInfo}>No-Name</span>
                  </Checkbox>
                </div>
              </InputLabel>
              <InputLabel label="Tipo de função do cartão" error={""}>
                <div className={s.checkContainer}>
                  <Checkbox
                    checked={values.hasFisicCard}
                    onChange={() => setFieldValue("hasFisicCard", !values.hasFisicCard)}
                    disabled={blockFields || disabled}
                  >
                    <span className={s.spanInfo}>Crédito (Físico)</span>
                  </Checkbox>
                  <Checkbox
                    checked={values.hasVirtualCard}
                    onChange={() => setFieldValue("hasVirtualCard", !values.hasVirtualCard)}
                    disabled={blockFields || disabled}
                  >
                    <span className={s.spanInfo}>Débito (Virtual</span>
                  </Checkbox>
                </div>
              </InputLabel>
            </div>
          </div>
          <div className={s.section}>
            <div className={s.title}>Imagem</div>
            <div className={s.flex}>
              <div className={s.imageData}>
                <div className={classnames(s.formGroup, s.smallerInput)}>
                  <InputLabel label="ID Imagem *" error={""}>
                    <Input
                      value={values.visaIdImage}
                      onChange={event => setFieldValue("visaIdImage", event.target.value)}
                      error={touched.visaIdImage && Boolean(errors.visaIdImage)}
                      disabled={disabled || isEdit}
                      placeholder="Informe o ID"
                    />
                  </InputLabel>
                </div>
                <div className={classnames(s.formGroup, s.biggerInput)}>
                  <InputLabel label="Descrição da imagem *" error={""}>
                    <Input
                      value={values.visaImageDescription}
                      onChange={event => setFieldValue("visaImageDescription", event.target.value)}
                      error={touched.visaImageDescription && Boolean(errors.visaImageDescription)}
                      disabled={disabled}
                      placeholder="Descrição da imagem"
                    />
                  </InputLabel>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className={s.section}>
        <div className={s.title}>Cobrander</div>
        <div className={s.formGroup}>
          <InputLabel label="Username" error={errors.cobranderUsername}>
            <Input
              value={values.cobranderUsername}
              onChange={event => setFieldValue("cobranderUsername", event.target.value)}
              error={touched.cobranderUsername && Boolean(errors.cobranderUsername)}
              disabled={disabled}
            />
          </InputLabel>

          <InputLabel label="Senha" error={errors.cobranderPassword}>
            <Input
              value={values.cobranderPassword}
              onChange={event => setFieldValue("cobranderPassword", event.target.value)}
              error={touched.cobranderPassword && Boolean(errors.cobranderPassword)}
              disabled={disabled}
            />
          </InputLabel>
        </div>

        <div className={s.formGroup}>
          <InputLabel label="Código do Cobrander" error={errors.cobranderCode}>
            <Input
              value={values.cobranderCode}
              onChange={event => setFieldValue("cobranderCode", event.target.value)}
              error={touched.cobranderCode && Boolean(errors.cobranderCode)}
              disabled={disabled}
            />
          </InputLabel>

          <InputLabel label="Código da categoria do cartão" error={errors.cobranderCodeCardCategory}>
            <Input
              value={values.cobranderCodeCardCategory}
              onChange={event => setFieldValue("cobranderCodeCardCategory", event.target.value)}
              error={touched.cobranderCodeCardCategory && Boolean(errors.cobranderCodeCardCategory)}
              disabled={disabled}
            />
          </InputLabel>
        </div>

        <div className={s.formGroup}>
          <InputLabel label="Cartão ativado para o parceiro" error={errors.cobranderIsActivate}>
            <Checkbox
              checked={values.cobranderIsActivate}
              onChange={() => setFieldValue("cobranderIsActivate", !values.cobranderIsActivate)}
              disabled={disabled}
            />
          </InputLabel>
          <InputLabel label="Emitir cartão automaticamente" error={errors.cobranderAutoEmitCard}>
            <Checkbox
              checked={values.cobranderAutoEmitCard}
              onChange={() => setFieldValue("cobranderAutoEmitCard", !values.cobranderAutoEmitCard)}
              disabled={disabled}
            />
          </InputLabel>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={classnames(s.section, s.limitedSection)}>
        <div className={s.title}>Configurações do produto</div>
        <div className={s.flex}>
          <div className={classnames(s.selectContainer, s.biggerInput)}>
            <div className={s.subtitle}>Emissora do cartão</div>
            <Select
              isDisabled={blockFields || disabled || Boolean(values.cardBrandDisabled)}
              placeholder="Selecione a emissora..."
              onChange={handleOnChangeBroadcaster}
              value={broadcasterOptions.find(option => option.value === values.broadcaster)}
              options={broadcasterOptions}
            />
          </div>
          <div className={s.selectContainer}>
            <div className={s.subtitle}>Bandeira</div>
            <Select
              isDisabled={blockFields || disabled || Boolean(values.cardBrandDisabled)}
              placeholder="Selecione a bandeira..."
              onChange={handleOnChangeBrand}
              value={brandOptions.find(option => option.value === values.cardBrand) || { value: "", label: "Nenhuma" }}
              options={brandOptions}
            />
          </div>
          <div className={s.selectContainer}>
            <div className={s.subtitle}>Multicards</div>
            <Select
              isDisabled={blockFields || disabled}
              placeholder="Ativar multicartões?"
              onChange={handleOnChangeMulticards}
              value={multicardOptions.find(option => option.value === values.multicards)}
              options={multicardOptions}
            />
          </div>
        </div>
      </div>
      {renderFields()}
      <div className={s.footer}>
        {disabled ? (
          <Button theme="secondary" type="button" onClick={onEditFields}>
            Editar
          </Button>
        ) : (
          <>
            <Button theme="secondary" type="button" onClick={onCancel}>
              Cancelar
            </Button>

            <Button type="button" onClick={onSave} disabled={disabledFormSaveButton()}>
              Salvar
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
