/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
/* eslint-disable sort-keys */
import * as React from "react";
import styled from "styled-components";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import { RaisedButton } from "legacy/components/RaisedButton";
import { TextInput } from "legacy/components/TextInput";

import { colors } from "../../../utils";
import { passwordRegex } from "../../../utils/regex";

interface IProps {
  onSubmit: (data: { oldPassword: string; newPassword: string; newPasswordConfirm: string }) => void;
  isLoading: boolean;
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 530px;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 530px;
  width: 100%;
  height: 100%;
`;

const WrapperInput = styled.div`
  width: 100%;
  input {
    width: 100%;
  }
`;

const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 46px;
`;

const Title = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
`;

const StyledRaisedButton = styled(RaisedButton)`
  width: 120px;
  height: 50px;
`;

export class FormChangePassword extends React.Component<IProps> {
  private InnerForm = (
    props: FormikProps<{ oldPassword: string; newPassword: string; newPasswordConfirm: string }>,
  ) => {
    return (
      <Form onSubmit={props.handleSubmit}>
        <Content>
          <WrapperInput>
            <TextInput
              name="oldPassword"
              value={props.values.oldPassword}
              onChange={props.handleChange}
              label={<Title>Senha antiga</Title>}
              isSecret
              error={Boolean(props.errors.oldPassword)}
              errorText={props.errors.oldPassword}
            />
          </WrapperInput>
          <WrapperInput>
            <TextInput
              name="newPassword"
              value={props.values.newPassword}
              onChange={props.handleChange}
              label={<Title>Nova senha</Title>}
              isSecret
              error={Boolean(props.errors.newPassword)}
              errorText={props.errors.newPassword}
            />
          </WrapperInput>
          <WrapperInput>
            <TextInput
              name="newPasswordConfirm"
              value={props.values.newPasswordConfirm}
              onChange={props.handleChange}
              label={<Title>Confirme a nova senha</Title>}
              isSecret
              error={Boolean(props.errors.newPasswordConfirm)}
              errorText={props.errors.newPasswordConfirm}
            />
          </WrapperInput>

          <WrapperButton>
            <StyledRaisedButton type="submit" color="primary" label="Alterar" loading={this.props.isLoading} />
          </WrapperButton>
        </Content>
      </Form>
    );
  };

  public render() {
    return (
      <Formik<{ oldPassword: string; newPassword: string; newPasswordConfirm: string }>
        render={this.InnerForm}
        onSubmit={(values: { oldPassword: string; newPassword: string; newPasswordConfirm: string }) =>
          this.props.onSubmit(values)
        }
        initialValues={{
          oldPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        }}
        validationSchema={yup.object<{ oldPassword: string; newPassword: string; newPasswordConfirm: string }>().shape({
          oldPassword: yup.string().required("Campo obrigatório"),
          newPassword: yup
            .string()
            .required("Campo obrigatório")
            .min(10, "A senha deve conter ao menos 10 caracteres.")
            .matches(
              passwordRegex,
              "A senha deve conter letras maiúsculas, minúsculas, números e caracteres especiais.",
            ),
          newPasswordConfirm: yup
            .string()
            .required("Campo obrigatório")
            .test("test-pass", "As senhas não conferem.", function(v: any) {
              return this.parent.newPassword === v;
            }),
        })}
        validateOnBlur
        validateOnChange={false}
      />
    );
  }
}
