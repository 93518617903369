/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { inject, observer } from "mobx-react";

import { Table } from "../../../components/Table";
import { prettierAccountName, prettierBranchName } from "../../../utils";
import { LegalEntityStore } from "../../../stores";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  legalEntityStore?: LegalEntityStore;
}

@inject("legalEntityStore")
@observer
export class LegalEntityAccountList extends React.Component<IProps> {
  public render() {
    const { accounts } = this.props.legalEntityStore!.company!;

    if (!accounts.length) {
      return <InnerAlert compact message="Não há contas cadastradas" />;
    }

    return (
      <Table
        titles={["Agência", "Conta", "Status"]}
        ids={accounts.map((_, i) => `acc-${i}`)}
        data={accounts.map(row => [
          prettierBranchName(row.branch),
          prettierAccountName(row.account),
          row.status === "active" ? "Ativa" : "Encerrada",
        ])}
      />
    );
  }
}
