/* eslint-disable no-negated-condition */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import React, { useState } from "react";
import { colors } from "utils";
import styled from "styled-components";
import { EntryStatement, getAccountBalance, getAccountStatement } from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { Loader } from "legacy/components/Loader";
import { SvgArrowRepeat } from "legacy/icons/SvgArrowRepeat";

import { Balance } from "./components/balance";
import { Statement } from "./components/Statement";
import { DateRange, DateRangePicker } from "../../components/DateRangePicker";
import { SearchBar } from "../../components/SearchBar";
import { OutlinedButton } from "../../components/OutlinedButton";

export function StatementPage() {
  const [availableBalance, setAvailableBalance] = useState<number | null>(null);
  const [blockedBalance, setBlockedValue] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const [accountNumber, setAccountNumber] = useState("");
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [statement, setStatement] = useState<EntryStatement[]>([]);
  const { generalStore } = useStores();

  async function getBalance() {
    setLoading(true);

    try {
      const { available, blocked } = await getAccountBalance(accountNumber);

      setAvailableBalance(available);
      setBlockedValue(blocked);
    } catch (err) {
      console.log(err);
      generalStore.showToast("Falha ao obter o saldo.");
    } finally {
      setLoading(false);
    }
  }

  async function getStatement() {
    console.log(getStatement);
    setLoading(true);
    try {
      const response = await getAccountStatement(
        accountNumber,
        filterStartDate
          ? {
              end: filterEndDate,
              start: filterStartDate,
            }
          : null,
      );

      setStatement(response);
    } catch (err) {
      generalStore.showToast("Falha ao obter o extrato.");
    } finally {
      setLoading(false);
    }
  }

  async function handleSearchButton() {
    getBalance();
    getStatement();
  }

  function handleDates({ startDate, endDate }: DateRange): void {
    console.log(startDate, endDate);
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  }

  function cleanFilters() {
    setFilterStartDate(null);
    setFilterEndDate(null);
  }

  function renderFilter() {
    return (
      <>
        <RowSearch>
          <StyledSearchBar
            value={accountNumber}
            onChange={e => {
              setAccountNumber(e);
            }}
            placeholder="Pesquise pelo Nº da conta"
            handleSearch={handleSearchButton}
            isLoading={loading}
          />
        </RowSearch>
        <RowSearch>
          <Right>
            <DateRangePicker
              startDate={filterStartDate}
              endDate={filterEndDate}
              startLabel="Início"
              endLabel="Final"
              onChange={handleDates}
            />
            <StyledOutlinedButton
              label="Limpar filtros"
              onClick={cleanFilters}
              iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
            />
          </Right>
        </RowSearch>
      </>
    );
  }

  function renderLoading() {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  function renderEmptyList() {
    return (
      <InsertText>
        <p>Digite o número da conta para visualizar o extrato</p>
      </InsertText>
    );
  }

  return (
    <>
      {loading ? (
        renderLoading()
      ) : (
        <Container>
          <Title>Extrato</Title>
          <Subsection>
            <Subtitle>Visualize todas as movimentações bancárias</Subtitle>
          </Subsection>
          {renderFilter()}
          <Group>
            <Balance
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              available={availableBalance !== null ? availableBalance / 100 : null}
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              blocked={blockedBalance !== null ? blockedBalance / 100 : null}
            />
          </Group>

          {statement.length ? (
            <>
              <Statement statements={statement} accountNumber={accountNumber} />
            </>
          ) : (
            renderEmptyList()
          )}
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: -20px;
`;
const StyledOutlinedButton = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;
const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const InsertText = styled.div`
  margin-top: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${colors.gray};
  font-family: Lato;
`;
const Subsection = styled.div`
  padding-left: 4px;
  display: flex;
  flex-direction: column;
`;
const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: bold;
`;

const Subtitle = styled.h2`
  font-family: Lato;
  color: rgb(166, 166, 166);
  font-size: 17px;
  font-weight: 500;
`;

const StyledSearchBar = styled(SearchBar)`
  width: 400px;
  padding: 20px;
`;

const RowSearch = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const FieldDateRangePicker = styled.div`
  /* flex: 1 30px; */
  width: 350px;
  padding: 8px;

  .DateInput {
    width: 90px;
    height: 40px;
    margin-left: 10px;
  }
`;
