exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styled__box__3jqNP{height:100%;padding:16px 24px;overflow-y:auto}.styled__box__3jqNP .styled__inputArea__5q40r{display:flex;align-items:center;justify-content:flex-end}.styled__box__3jqNP .styled__inputArea__5q40r.styled__has-two__lbLE9{justify-content:space-between}.styled__labelDate__2QUiZ>div{min-width:300px}.styled__nextWrapperButton__2rGbr{min-width:145px;width:fit-content;font-family:Lato;margin:15px 0 15px 15px;text-transform:none;font-size:14px;font-weight:600;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;text-align:center}.styled__info__14D3L{margin:8px 0;font-weight:600;font-size:14px;color:#515251}", ""]);

// Exports
exports.locals = {
	"box": "styled__box__3jqNP",
	"inputArea": "styled__inputArea__5q40r",
	"has-two": "styled__has-two__lbLE9",
	"labelDate": "styled__labelDate__2QUiZ",
	"nextWrapperButton": "styled__nextWrapperButton__2rGbr",
	"info": "styled__info__14D3L"
};