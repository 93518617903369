import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
}

const Container = styled.button`
  background-color: ${colors.lightGray};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: ${colors.black};

  &:disabled {
    cursor: default;
  }
`;

export class TextButton extends React.Component<IProps, {}> {
  public render() {
    const { label, ...allProps } = this.props;
    return <Container {...allProps}>{this.props.label}</Container>;
  }
}
