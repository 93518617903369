import styled from "styled-components";
import { OutlinedButton } from "legacy/components/OutlinedButton";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { TextAreaInput } from "../../../../components/TextAreaInput";

export const Input = styled.input`
  max-width: 395px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background-color: ${colors.white};
  border: solid 1px ${colors.borderGray};
  padding: 10px;
  color: ${colors.black};
  font-family: lato;
  text-align: center;

  &:disabled {
    background-color: ${colors.borderGray};
  }
`;

export const ContainerInput = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;

export const WrapperSwitch = styled.div`
  display: flex;
  flex-direction: inherit;
  align-items: center;
  min-height: 51px;
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`;

export const TablesContainer = styled.div`
  overflow-x: auto;
`;

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
`;

export const ErrorText = styled.span`
  font-family: Lato;
  font-size: 16px;
  color: ${colors.violetRed};
  margin-bottom: 12px;
`;

export const Title = styled.span`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`;

export const Label = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.violetRed};
  margin: 12px 32px 0 0;
`;

export const ErrorMessage = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #e2574c;
  display: flex;
`;

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
`;

export const StyledRaisedButton = styled(RaisedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
`;

export const StyledTextArea = styled(TextAreaInput)`
  margin-top: 24px;
  height: 109px;
`;

export const FooterTable = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
`;
