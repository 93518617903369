import React from "react";
import classnames from "classnames";

import s from "./style.scss";

export type BtnThemes = "primary" | "secondary" | "link-icon" | "link" | "neutral" | "icon-only";
export type BtnBorder = "default" | "semi-circle";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: BtnThemes;
  borderType?: BtnBorder;
  removeSvgStyle?: boolean;
  disabled?: boolean;
}

export function Button({ borderType, children, className, removeSvgStyle, theme, ...rest }: IProps) {
  const classNames = [
    s.btn,
    s[`btn__border-type-${borderType}`],
    s[`btn_${theme}`],
    removeSvgStyle ? s.btn_removeSvgStyle : null,
    className,
  ];

  return (
    <button className={classnames(classNames)} data-testid="button" {...rest}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  borderType: "default",
  theme: "primary",
};
