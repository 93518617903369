let baseUrl = "";
let partnerId: string | null = null;
let useSessionStorage = false;

interface CustomWindow extends Window {
  SDKGEN_SESSION_ID: string | null;
  SDKGEN_DEVICE_ID: string | null;
}

declare var window: CustomWindow;

export function setUseSessionStorage(bool: boolean) {
  useSessionStorage = bool;
}

export function setUrl(url: string) {
  baseUrl = url;
}

export function setPartnerId(newPartnerId: string) {
  partnerId = newPartnerId;
}

export function setSessionId(newSessionId: string | null) {
  window.SDKGEN_SESSION_ID = newSessionId;
}

export interface DatePeriod {
    start: string | null;
    end: string | null;
}

export interface Operations {
    ted: DatePeriod | null;
    doc: DatePeriod | null;
    internalTransfer: DatePeriod | null;
    investment: DatePeriod | null;
    titleInclusion: DatePeriod | null;
    withdraw: DatePeriod | null;
    paymentBankSlip: DatePeriod | null;
    paymentAgreement: DatePeriod | null;
    recharge: DatePeriod | null;
}

export interface OperationsDays {
    days: Day[];
    operations: Operations;
}

export interface ExceptionOperationsDate {
    date: Date;
    operations: Operations;
    obs: string;
    isAutomatic: boolean;
}

export interface Service {
    key: ServiceNames;
    blocked: boolean;
    value: string;
}

export interface ServiceV13 {
    key: ServiceNamesV13;
    blocked: boolean;
    value: string;
}

export interface AvailableServices {
    operation: ServiceNames;
    available: boolean;
}

export interface Partner {
    urlApp: UrlApp | null;
    tokenUrlApp: UrlApp | null;
    blocked: boolean;
    managerId: string;
    publicKey: string;
    partnerSecret: string;
    partnerUserName: string | null;
    allowAnyPartnerLogin: boolean;
    openAccountsAttempts: number;
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
    managerIdLot: string | null;
    allowPartnerLot: boolean;
    allowReduceJourneyWithoutLot: boolean;
    showWiz: boolean | null;
}

export interface PartnerDetails {
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
    managerIdLot: string | null;
    allowPartnerLot: boolean;
    allowReduceJourneyWithoutLot: boolean;
    showWiz: boolean | null;
}

export interface PartnerV13 {
    urlApp: UrlApp | null;
    tokenUrlApp: UrlApp | null;
    blocked: boolean;
    managerId: string;
    publicKey: string;
    partnerSecret: string;
    partnerUserName: string | null;
    allowAnyPartnerLogin: boolean;
    openAccountsAttempts: number;
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
}

export interface PartnerDetailsV13 {
    id: string;
    name: string;
    logo: string;
    backgroundColor: string | null;
    secondaryColor: string | null;
    primaryColor: string | null;
    font: string | null;
    fontHref: string | null;
    errorColor: string | null;
    phone: string | null;
    email: string | null;
    site: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    urlFarePackages: string | null;
    urlIB: string;
    partnerHook: PartnerHookDetails | null;
    cobrander: Cobrander | null;
    hibotlink: string | null;
    hibotmobilelink: string | null;
    cnpj: string | null;
    webhookUrl: string | null;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    emailFrom: string | null;
    accountOpeningAttemptLimit: number | null;
}

export interface Cobrander {
    codeCobrander: string;
    codeCardCategory: string;
    autoEmitCard: boolean;
    isActivate: boolean;
    username: string | null;
    password: string | null;
}

export interface PartnerHookDetails {
    url: string;
    token: string;
}

export interface UrlApp {
    android: string;
    ios: string;
}

export interface AccountDetailsBank {
    code: string;
    name: string;
}

export interface AccountDetails {
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    createdAt: Date;
}

export interface Account {
    name: string;
    type: PfPj;
    documentNumber: string;
    permissions: Permission[];
    maxAmountDaily: number | null;
    yourNumber: number;
    subType: SubType | null;
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    createdAt: Date;
}

export interface AccountWithStatus {
    status: AccountStatusV13 | null;
    name: string;
    type: PfPj;
    documentNumber: string;
    permissions: Permission[];
    maxAmountDaily: number | null;
    yourNumber: number;
    subType: SubType | null;
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    createdAt: Date;
}

export interface AccountCompany {
    profile: Profile;
    name: string;
    type: PfPj;
    documentNumber: string;
    permissions: Permission[];
    maxAmountDaily: number | null;
    yourNumber: number;
    subType: SubType | null;
    bank: AccountDetailsBank;
    branch: string;
    account: string;
    createdAt: Date;
}

export interface Permission {
    permissionType: PermissionType;
    maxAmountDaily: number | null;
    maxAmountOperation: number | null;
    isSpecial: boolean;
}

export interface User {
    id: string;
    name: string;
    email: string;
    cpf: string;
    address: UserAddress | null;
    profilePhoto: string | null;
    status: UserStatus;
    publicKey: string | null;
    partnerId: string | null;
    accounts: Account[];
    companies: Company[];
    urlTerm: string | null;
    urlContract: string | null;
    socialName: string | null;
    phone: string | null;
    blocked: boolean | null;
    isMEI: boolean;
    showCard: boolean | null;
    help: string | null;
    mobileHelp: string | null;
    pendingDocument: boolean | null;
}

export interface UserAddress {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface UserV13 {
    id: string;
    name: string;
    email: string;
    cpf: string;
    cnpj: string | null;
    address: UserV13Address | null;
    profilePhoto: string | null;
    status: UserStatus;
    publicKey: string | null;
    partnerId: string | null;
    accounts: Account[];
    companies: CompanyV13[];
    urlTerm: string | null;
    urlContract: string | null;
    urlFarePackages: string | null;
    socialName: string | null;
    phone: string | null;
    blocked: boolean | null;
    isMEI: boolean;
    showCard: boolean | null;
    help: string | null;
    mobileHelp: string | null;
    pendingDocument: boolean | null;
    isMaster: boolean | null;
}

export interface UserV13Address {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface RepresentativeUser {
    id: string | null;
    name: string;
    email: string;
    cpf: string | null;
    cnpj: string | null;
    status: string;
    partnerId: string;
}

export interface Company {
    accounts: Account[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string;
    createdAt: Date;
    updatedAt: Date | null;
    status: Status;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoAddress | null;
}

export interface CompanyV13 {
    accounts: Account[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: StatusV13;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoV13Address | null;
    isPPE: boolean | null;
    mailingAddress: CompanyInfoV13MailingAddress | null;
    hasTaxDomain: boolean | null;
    taxDomainCountry: string | null;
    taxDomainNumber: string | null;
    isAmericanCitizen: boolean | null;
    americanCitizenNIF: string | null;
    isPPERelationship: boolean | null;
    giinNumber: string | null;
    ppe: PPEPayload[] | null;
    ppeRelationship: PPEPayload[] | null;
}

export interface RegisterUserFields {
    workflowId: string;
    name: string;
    cpf: string;
    createdAt: Date;
    updatedAt: Date | null;
    accountType: string;
    partner: RegisterUserFieldsPartner;
    blocked: boolean | null;
    complianceStatus: ComplianceStatus;
    status: UserStatus | null;
    fieldGroups: RegisterUserFieldsFieldGroups[];
    bureaus: RegisterUserFieldsBureaus[];
    documents: RegisterUserFieldsDocuments[];
    pendingFields: RegisterField[] | null;
    obs: string;
    blockStatus: BlockStatusAccount | null;
    subType: SubType | null;
    journeyType: JourneyType | null;
}

export interface RegisterUserFieldsPartner {
    id: string;
    name: string;
}

export interface RegisterUserFieldsFieldGroups {
    name: string;
    fields: RegisterUserFieldsFieldGroupsFields[];
}

export interface RegisterUserFieldsFieldGroupsFields {
    label: string;
    values: RegisterUserFieldsFieldGroupsFieldsValues[];
}

export interface RegisterUserFieldsFieldGroupsFieldsValues {
    source: string;
    value: string;
}

export interface RegisterUserFieldsBureaus {
    name: string;
    fields: RegisterUserFieldsBureausFields[];
}

export interface RegisterUserFieldsBureausFields {
    label: string;
    value: string;
    url: string | null;
}

export interface RegisterUserFieldsDocuments {
    name: string;
    url: string;
}

export interface RegisterCompany {
    partnerId: string | null;
    partnerName: string;
    owner: RegisterUserFields | null;
    complianceStatus: ComplianceStatus | null;
    accounts: Account[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string;
    createdAt: Date;
    updatedAt: Date | null;
    status: Status;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoAddress | null;
}

export interface RegisterCompanyV13 {
    partnerId: string | null;
    partnerName: string;
    owner: RegisterUserFields | null;
    complianceStatus: ComplianceStatus | null;
    accounts: Account[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: StatusV13;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoV13Address | null;
    isPPE: boolean | null;
    mailingAddress: CompanyInfoV13MailingAddress | null;
    hasTaxDomain: boolean | null;
    taxDomainCountry: string | null;
    taxDomainNumber: string | null;
    isAmericanCitizen: boolean | null;
    americanCitizenNIF: string | null;
    isPPERelationship: boolean | null;
    giinNumber: string | null;
    ppe: PPEPayload[] | null;
    ppeRelationship: PPEPayload[] | null;
}

export interface CompanyInfo {
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string;
    createdAt: Date;
    updatedAt: Date | null;
    status: Status;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoAddress | null;
}

export interface CompanyInfoAddress {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface PPEPayload {
    name: string;
    reason: string;
}

export interface CompanyManager {
    accounts: CompanyManagerAccounts[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string;
    createdAt: Date;
    updatedAt: Date | null;
    status: Status;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoAddress | null;
}

export interface CompanyManagerAccounts {
    branch: string;
    account: string;
}

export interface CompanyInfoV13 {
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: StatusV13;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoV13Address | null;
    isPPE: boolean | null;
    mailingAddress: CompanyInfoV13MailingAddress | null;
    hasTaxDomain: boolean | null;
    taxDomainCountry: string | null;
    taxDomainNumber: string | null;
    isAmericanCitizen: boolean | null;
    americanCitizenNIF: string | null;
    isPPERelationship: boolean | null;
    giinNumber: string | null;
    ppe: PPEPayload[] | null;
    ppeRelationship: PPEPayload[] | null;
}

export interface CompanyInfoV13Address {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface CompanyInfoV13MailingAddress {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface CompanyManagerV13 {
    accounts: CompanyManagerV13Accounts[];
    id: string;
    name: string;
    documentNumber: string;
    email: string;
    phone: string | null;
    createdAt: Date;
    updatedAt: Date | null;
    status: StatusV13;
    isMEI: boolean;
    fantasyName: string | null;
    foundationDate: Date | null;
    foundationAssertiva: string | null;
    socialCapital: string | null;
    mainActivity: string | null;
    address: CompanyInfoV13Address | null;
    isPPE: boolean | null;
    mailingAddress: CompanyInfoV13MailingAddress | null;
    hasTaxDomain: boolean | null;
    taxDomainCountry: string | null;
    taxDomainNumber: string | null;
    isAmericanCitizen: boolean | null;
    americanCitizenNIF: string | null;
    isPPERelationship: boolean | null;
    giinNumber: string | null;
    ppe: PPEPayload[] | null;
    ppeRelationship: PPEPayload[] | null;
}

export interface CompanyManagerV13Accounts {
    branch: string;
    account: string;
    status: AccountStatusV13;
}

export interface Profile {
    id: string | null;
    name: string;
    companyId: string;
    permissions: Permission[];
}

export interface UserCompany {
    id: string;
    name: string;
    email: string;
    phone: string | null;
    cpf: string;
    blocked: boolean | null;
    accounts: AccountCompany[];
}

export interface RepresentativeInfo {
    name: string;
    cpf: string;
    status: UserStatus;
    hasPendingInfo: boolean;
}

export interface Image {
    name: string;
    url: string;
}

export interface File {
    name: string;
    url: string;
}

export interface Document {
    type: DocumentType;
    number: string;
    dispatcher: DocumentDispatcher;
    state: string;
}

export interface AddressInfo {
    cep: string;
    street: string;
    neighborhood: string;
    number: string;
    complement: string | null;
    city: string;
    state: string;
    country: string;
}

export interface AddressInfoComercial {
    cep: string | null;
    street: string | null;
    neighborhood: string | null;
    number: string | null;
    complement: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

export interface CommercialInfo {
    address: AddressInfoComercial | null;
    cnpj: string;
    phone: string | null;
}

export interface Bank {
    code: string;
    name: string;
}

export interface AccountInfo {
    accountNumber: string;
    branch: string;
    bank: Bank;
}

export interface RegisterUser {
    userId: string;
    cpf: string;
    email: string;
    phone: string | null;
    name: string;
    images: Image[] | null;
    birthDate: Date | null;
    document: Document | null;
    gender: Gender | null;
    filiacao1: string | null;
    filiacao2: string | null;
    nacionalidade: string | null;
    createdAt: Date;
    address: AddressInfo | null;
    comercialInfo: CommercialInfo | null;
    socialName: string | null;
    accountInfo: AccountInfo | null;
    occupation: string | null;
    monthlyIncome: string | null;
    patrimony: string | null;
    civilStatus: CivilStatus | null;
    categoryAccount: CategoryAccount | null;
    partnerId: string | null;
    partnerName: string | null;
    complianceStatus: ComplianceStatus;
    status: UserStatus;
    naturalness: string | null;
    spouseName: string | null;
    weddingType: WeddingType | null;
    tokenNotification: string | null;
    isTerrorist: boolean;
    isMEI: boolean;
    cnpj: string | null;
    fantasyName: string | null;
    foundationDate: Date | null;
    mainActivity: string | null;
    codeSMS: string | null;
    companyName: string | null;
}

export interface ManagerAccount {
    id: string;
    cpfCnpj: string;
    name: string;
    accountType: PfPj;
    createdAt: Date;
    updatedAt: Date | null;
    categoryAccount: CategoryAccount;
    status: UserStatus | null;
    statusCompliance: ComplianceStatus | null;
    value: number | null;
    blockStatus: BlockStatusAccount | null;
    fepweb: boolean;
    journeyType: JourneyType | null;
    subType: SubType | null;
}

export interface RecoverUser {
    action: ActionRecoverUser;
    name: string;
    documentNumber: string;
    partner: Partner | null;
}

export interface PasswordLinkData {
    userId: string;
    createdAt: Date;
    expirationDate: Date;
    action: ActionRecoverUser;
    name: string;
    documentNumber: string;
    partner: Partner | null;
}

export interface PartnerUser {
    username: string;
    password: string;
}

export interface PartnerName {
    id: string;
    name: string;
}

export interface BeneficiaryAnalisys {
    id: string;
    cpfCnpj: string;
    name: string | null;
    email: string | null;
    percentage: string | null;
    phone: string | null;
    statusCompliance: ComplianceStatus;
    complianceData: ComplianceData;
}

export interface ComplianceData {
    clipLaundering: ClipLaunderingRequest | null;
    allCheck: AllCheckRequest | null;
    pepAml: PepAmlRequest | null;
    lsaAml: LsaAmlRequest | null;
    lrnAml: LrnAmlRequest | null;
}

export interface SubscriberAnalisys {
    id: string;
    cpf: string;
    name: string;
    status: StatusProofOfLife;
    blocked: boolean | null;
    documents: SubscriberAnalisysDocuments[] | null;
    fieldGroups: SubscriberAnalisysFieldGroups[];
    bureaus: SubscriberAnalisysBureaus[];
    historyImages: HistoryDocument[];
}

export interface SubscriberAnalisysDocuments {
    name: string;
    url: string;
}

export interface SubscriberAnalisysFieldGroups {
    name: string;
    fields: SubscriberAnalisysFieldGroupsFields[];
}

export interface SubscriberAnalisysFieldGroupsFields {
    label: string;
    values: SubscriberAnalisysFieldGroupsFieldsValues[];
}

export interface SubscriberAnalisysFieldGroupsFieldsValues {
    source: string;
    value: string;
}

export interface SubscriberAnalisysBureaus {
    name: string;
    fields: SubscriberAnalisysBureausFields[];
}

export interface SubscriberAnalisysBureausFields {
    label: string;
    value: string;
    url: string | null;
}

export interface HistoryDocument {
    documents: Image[] | null;
    result: HistoryDocumentResult[];
}

export interface HistoryDocumentResult {
    name: string;
    fields: HistoryDocumentResultFields[];
}

export interface HistoryDocumentResultFields {
    label: string;
    value: string;
    url: string | null;
}

export interface RepresentativeAnalisys {
    id: string;
    cpf: string;
    name: string;
    status: StatusProofOfLife;
    isMaster: boolean;
    blocked: boolean | null;
    documents: RepresentativeAnalisysDocuments[] | null;
    fieldGroups: RepresentativeAnalisysFieldGroups[];
    bureaus: RepresentativeAnalisysBureaus[];
    historyImages: HistoryDocument[];
}

export interface RepresentativeAnalisysDocuments {
    name: string;
    url: string;
}

export interface RepresentativeAnalisysFieldGroups {
    name: string;
    fields: RepresentativeAnalisysFieldGroupsFields[];
}

export interface RepresentativeAnalisysFieldGroupsFields {
    label: string;
    values: RepresentativeAnalisysFieldGroupsFieldsValues[];
}

export interface RepresentativeAnalisysFieldGroupsFieldsValues {
    source: string;
    value: string;
}

export interface RepresentativeAnalisysBureaus {
    name: string;
    fields: RepresentativeAnalisysBureausFields[];
}

export interface RepresentativeAnalisysBureausFields {
    label: string;
    value: string;
    url: string | null;
}

export interface HistoryWorkflow {
    name: string;
    dateTime: Date;
    resume: string | null;
}

export interface CPFList {
    cpf: string;
    attempts: number;
    createdAt: Date;
    updatedAt: Date | null;
    deletedAt: Date | null;
}

export interface AdminUser {
    id: string | null;
    cpf: string;
    name: string;
    email: string;
    password: string | null;
    blocked: boolean;
    adminUserPermissions: AdminUserPermission[];
}

export interface RegisterFieldsPF {
    informationFields: RegisterFieldsPFInformationFields[];
    documents: RegisterFieldsPFDocuments[];
}

export interface RegisterFieldsPFInformationFields {
    key: RegisterField;
    value: string;
}

export interface RegisterFieldsPFDocuments {
    key: RegisterField;
    value: string;
}

export interface UserManager {
    isRepresentative: boolean;
    isAccountant: boolean;
    journeyType: JourneyType | null;
    subType: SubType | null;
    id: string;
    name: string;
    email: string;
    cpf: string;
    address: UserAddress | null;
    profilePhoto: string | null;
    status: UserStatus;
    publicKey: string | null;
    partnerId: string | null;
    accounts: Account[];
    companies: Company[];
    urlTerm: string | null;
    urlContract: string | null;
    socialName: string | null;
    phone: string | null;
    blocked: boolean | null;
    isMEI: boolean;
    showCard: boolean | null;
    help: string | null;
    mobileHelp: string | null;
    pendingDocument: boolean | null;
}

export interface AccountsUsersCompanys {
    companys: CompanyV13[];
    accounts: AccountWithStatus[];
    users: UserManager[];
    fepwebCompanies: CompanyV13[];
}

export interface Attached {
    buf: Buffer | null;
    name: string;
    url: string | null;
}

export interface Feedback {
    id: string | null;
    targetId: string;
    adminUser: AdminUser;
    type: PfPj;
    message: string;
    attached: Attached[] | null;
    createdAt: Date;
}

export interface PendingUser {
    name: string;
    documentNumber: string;
    partner: Partner | null;
}

export interface RevalidationImages {
    name: string;
    url: string;
}

export interface RegisterFieldValues {
    name: string | null;
    email: string | null;
    phone: string | null;
    socialName: string | null;
    gender: string | null;
    filiacao1: string | null;
    filiacao2: string | null;
    nacionality: string | null;
    address: string | null;
    commercialAddress: string | null;
    patrimony: string | null;
    monthlyIncome: string | null;
    occupation: string | null;
    naturalness: string | null;
    birthDate: string | null;
    civilStatus: string | null;
    spouseName: string | null;
    weddingType: string | null;
    accountInfo: string | null;
    document: RevalidationImages[] | null;
    selfie: RevalidationImages[] | null;
    proofOfAddress: RevalidationImages[] | null;
    certificateMEI: RevalidationImages[] | null;
    foundationDate: string | null;
    fantasyName: string | null;
    companyName: string | null;
}

export interface PendingRegisterLinkData {
    id: string;
    userId: string;
    createdAt: Date;
    fields: RegisterField[];
    oldFieldsValues: RegisterFieldValues | null;
    obs: string;
    isDone: boolean;
    name: string;
    documentNumber: string;
    partner: Partner | null;
}

export interface ManagerAccountFilter {
    query: string;
    type: PfPj | null;
    approvation: ApprovationType | null;
    limit: number | null;
    offset: number;
}

export interface ManagerAccountReturn {
    count: number;
    data: ManagerAccount[];
}

export interface FilterFields {
    idPartner: string[] | null;
    startRegisterDate: Date | null;
    endRegisterDate: Date | null;
    typePerson: PfPj | null;
    accountType: CategoryAccount[] | null;
    status: UserStatus[] | null;
    gender: Gender | null;
    region: Region[] | null;
    startAge: number | null;
    endAge: number | null;
    approvation: ApprovationType | null;
    closedAt: Date | null;
    closingReason: string | null;
    subType: SubType | null;
    journeyType: JourneyType | null;
    accountStatus: boolean | null;
}

export interface FilterFieldsByPartner {
    idPartner: string[] | null;
    partnerName: string | null;
    type: string | null;
    userName: string | null;
    documentNumber: string | null;
    account: string | null;
    birthDate: Date | null;
    email: string | null;
    phone: string | null;
    address: AddressInfo | null;
    affiliation1: string | null;
    affiliation2: string | null;
    monthlyIncome: string | null;
}

export interface ReportFilter {
    users: FilteredUsers[];
    paged: number;
    offset: number;
    total: number;
}

export interface ReportFilterByPartner {
    users: FilteredUsersByPartner[];
    paged: number;
    offset: number;
    total: number;
}

export interface ManagerReportFilter {
    limit: number;
    offset: number;
}

export interface RegisterUserByFilter {
    id: string;
    name: string;
    status: UserStatus;
    createdAt: Date;
    partner: string | null;
    gender: Gender | null;
    documentNumber: string;
    categoryAccount: CategoryAccount | null;
    address: AddressInfo | null;
    age: number | null;
    approvedAt: Date | null;
    type: PfPj | null;
    closedAt: Date | null;
    closingReason: string | null;
    subType: SubType | null;
    journeyType: JourneyType | null;
    accountStatus: boolean | null;
}

export interface RegisterPartnerByFilter {
    partnerName: string | null;
    type: string | null;
    userName: string | null;
    documentNumber: string | null;
    account: string | null;
    birthDate: Date | null;
    email: string | null;
    phone: string | null;
    address: AddressInfo | null;
    affiliation1: string | null;
    affiliation2: string | null;
    monthlyIncome: string | null;
}

export interface FilteredUsers {
    id: string;
    name: string;
    status: UserStatus;
    complianceStatus: ComplianceStatus;
    createdAt: Date;
    closedAt: Date | null;
    closingReason: string | null;
    partner: string | null;
    gender: Gender | null;
    documentNumber: string;
    categoryAccount: CategoryAccount | null;
    region: Region | null;
    age: number | null;
    approvedAt: Date | null;
    type: PfPj | null;
    account: string | null;
    pendingFields: RegisterField[] | null;
    pastPendingActions: FilteredUsersPastPendingActions[];
    approvation: ApprovationType | null;
    subType: SubType | null;
    journeyType: JourneyType | null;
    accountStatus: boolean | null;
}

export interface FilteredUsersPastPendingActions {
    requestedAt: Date;
    answeredAt: Date;
    fields: RegisterField[];
}

export interface FilteredUsersByPartner {
    partnerName: string | null;
    type: string | null;
    userName: string | null;
    documentNumber: string | null;
    account: string | null;
    birthDate: Date | null;
    email: string | null;
    phone: string | null;
    address: AddressInfo | null;
    affiliation1: string | null;
    affiliation2: string | null;
    monthlyIncome: string | null;
}

export interface RevalidationLabels {
    data: Date;
    value: string | null;
    imageValue: RevalidationImages | null;
    type: RevalidationType;
}

export interface RevalidatioUserFields {
    name: RevalidationLabels[];
    email: RevalidationLabels[];
    phone: RevalidationLabels[];
    socialName: RevalidationLabels[];
    gender: RevalidationLabels[];
    filiacao1: RevalidationLabels[];
    filiacao2: RevalidationLabels[];
    nacionality: RevalidationLabels[];
    address: RevalidationLabels[];
    commercialAddress: RevalidationLabels[];
    patrimony: RevalidationLabels[];
    monthlyIncome: RevalidationLabels[];
    occupation: RevalidationLabels[];
    naturalness: RevalidationLabels[];
    birthDate: RevalidationLabels[];
    civilStatus: RevalidationLabels[];
    spouseName: RevalidationLabels[];
    weddingType: RevalidationLabels[];
    accountInfo: RevalidationLabels[];
    document: RevalidationLabels[];
    selfie: RevalidationLabels[];
    proofOfAddress: RevalidationLabels[];
    certificateMEI: RevalidationLabels[];
    foundationDate: RevalidationLabels[];
    companyName: RevalidationLabels[];
    fantasyName: RevalidationLabels[];
}

export interface UserInfo {
    userId: string;
    cpf: string;
    name: string;
    createdAt: Date | null;
    status: UserStatus;
    pendingInfo: string[] | null;
    birthday: Date | null;
    socialName: string;
    email: string;
    mobilePhone: string;
    gender: Gender;
    parentName1: string;
    parentName2: string;
    nationality: string;
    address: UserInfoAddress | null;
}

export interface UserInfoAddress {
    cep: string | null;
    street: string | null;
    number: string | null;
    complement: string | null;
    neighborhood: string | null;
    city: string | null;
    state: string | null;
}

export interface PartnerRules {
    permission: PermissionsPartner;
    inputs: PermissionsPartnerInput[];
}

export interface PartnerConfig {
    id: string;
    flag: CardBrand;
    rules: PartnerRules[];
    partnerId: string;
    productId: string | null;
    plasticId: string | null;
    imageId: string | null;
    imageDescription: string | null;
    commercialOrigin: string | null;
    showCard: boolean;
    virtualCardFlag: boolean;
    physicalCardFlag: boolean;
}

export interface PartnerRegisterData {
    partnerId: string;
    name: string;
    cnpj: string | null;
    managerId: string;
    urlIB: string;
    urlApp: UrlApp | null;
    tokenUrlApp: UrlApp | null;
    hibotlink: string | null;
    publicKey: string;
    emailFrom: string | null;
    urlFarePackages: string | null;
    urlTerm: string | null;
    urlContract: string | null;
    urlTermPj: string | null;
    urlContractPj: string | null;
    blocked: boolean;
    hibotmobilelink: string | null;
    partnerSecret: string;
    phone: string | null;
    email: string | null;
    site: string | null;
    partnerHook: PartnerHookDetails | null;
    partnerUserName: string | null;
    webhookUrl: string | null;
    term: Buffer | null;
    contract: Buffer | null;
    termPj: Buffer | null;
    contractPj: Buffer | null;
    farePackages: Buffer | null;
}

export interface PartnerStyles {
    partnerId: string;
    primaryColor: string | null;
    secondaryColor: string | null;
    backgroundColor: string | null;
    errorColor: string | null;
    logo: string;
    font: string | null;
    fontHref: string | null;
    buffer: Buffer | null;
}

export interface PartnerCard {
    partnerId: string;
    cardConfig: CardConfig[];
}

export interface UpdatePartnerCard {
    partnerId: string;
    productId: string;
    imageId: string;
    imageDescr: string;
    multiCard: MultiCard;
    bin: string;
    typeBin: string;
    cardTypeName: CardTypeName;
    activateCards: ActiveCards;
    plasticId: string;
    commercialOrigin: string;
}

export interface RemovePartnerCard {
    partnerId: string;
    productId: string;
    imageId: string;
}

export interface CustomButton {
    buffer: Buffer | null;
    image: string | null;
    url: string | null;
    text: string | null;
    show: boolean | null;
}

export interface CustomContact {
    show: boolean;
    phone: string | null;
    email: string | null;
}

export interface PartnerGeneralConfig {
    partnerId: string;
    allowAnyPartnerLogin: boolean;
    blocked: boolean;
    emailResendTime: number;
    emailResendTimeFrequency: number;
    accountOpeningAttemptLimit: number | null;
    openAccountsAttempts: number;
    showCard: boolean;
    allowPartnerLot: boolean | null;
    allowReduceJourneyWithoutLot: boolean | null;
    showWiz: boolean;
    customButton: CustomButton | null;
    customContact: CustomContact | null;
    managerIdLot: string | null;
    managerIdWithoutLot: string | null;
}

export interface InternalCreateAccountMEI {
    cpf: string;
    ownerBirthDate: Date | null;
    ownerName: string | null;
    ownerMotherName: string | null;
    email: string;
    phone: string | null;
    cep: string;
    addressNumber: string | null;
    cnpj: string;
    foundationDate: Date | null;
    companyName: string | null;
    fantasyName: string | null;
    partnerId: string | null;
    openAccount: boolean;
}

export interface InternalCreateAccountPF {
    cpf: string;
    birthDate: Date | null;
    name: string | null;
    motherName: string | null;
    email: string;
    phone: string | null;
    cep: string;
    addressNumber: string | null;
    partnerId: string | null;
    openAccount: boolean;
    monthlyIncome: string | null;
}

export interface CompanyAnalisys {
    id: string;
    complianceData: ComplianceData;
    fieldGroups: CompanyAnalisysFieldGroups[];
    bureaus: CompanyAnalisysBureaus[] | null;
}

export interface CompanyAnalisysFieldGroups {
    name: string;
    fields: CompanyAnalisysFieldGroupsFields[];
}

export interface CompanyAnalisysFieldGroupsFields {
    label: string;
    values: CompanyAnalisysFieldGroupsFieldsValues[];
}

export interface CompanyAnalisysFieldGroupsFieldsValues {
    source: string;
    value: string;
}

export interface CompanyAnalisysBureaus {
    name: string;
    fields: CompanyAnalisysBureausFields[];
}

export interface CompanyAnalisysBureausFields {
    label: string;
    value: string;
    url: string | null;
}

export interface CompanyInfoReturn {
    data: CompanyInfo[];
    total: number;
}

export interface WhitelistDomain {
    id: string;
    domain: string;
}

export interface WhitelistEmail {
    id: string;
    email: string;
}

export interface WhitelistLogin {
    id: string;
    cpf: string;
}

export interface DefaultLimitsFormated {
    title: string;
    operation: number;
    daily: number;
    amountPerDay: number;
    isActive: boolean | null;
}

export interface LimitsDefaultDTO {
    accountType: PfPj;
    observation: string | null;
    transactionRequestDto: DefaultLimitsFormated[];
}

export interface RespApiTransactional {
    statusCode: number;
    message: string;
}

export interface Segment {
    id: number | null;
    partnerId: string | null;
    name: string;
    accountType: PfPj;
    minIncome: number;
    maxIncome: number;
    observation: string | null;
}

export interface SegmentLimit {
    segment: Segment;
    parametersLimitsID: number | null;
    name: string;
    transactionRequestDto: DefaultLimitsFormated[];
}

export interface SegmentLimitCreate {
    segment: Segment[] | null;
    operation: DefaultLimitsFormated[];
}

export interface ClientLimit {
    name: string | null;
    account: string | null;
    cpfCnpj: string;
    type: PfPj | null;
    initialDate: Date;
    finalDate: Date | null;
    ticket: string;
    transactionRequestDto: DefaultLimitsFormated[] | null;
    observation: string | null;
}

export interface LogLimitsTransactional {
    id: number;
    cpfCnpj: string | null;
    ticket: string | null;
    name: string | null;
    date: Date;
    description: string[];
    user: string;
    observation: string | null;
}

export interface OperationsLimits {
    tedThird: boolean;
    internalTransfer: boolean;
    concessionaire: boolean;
    autoBankSlip: boolean;
    bankSlip: boolean;
}

export interface SecurityStatus {
    pf: OperationsLimits;
    pj: OperationsLimits;
    observation: string | null;
}

export interface Security {
    pf: DefaultLimitsFormated[];
    pj: DefaultLimitsFormated[];
    observation: string | null;
}

export interface LimitsPagination {
    page: number;
    offset: number;
    limit: number;
}

export interface LogsLimitReturn {
    logsLimits: LogLimitsTransactional[];
    count: number;
}

export interface ClientLimitReturn {
    clientLimit: ClientLimit[];
    count: number;
}

export interface SegmentLimitReturn {
    segmentsLimit: SegmentLimit[];
    count: number;
}

export interface SegmentReturn {
    segments: Segment[];
    count: number;
}

export interface VisaCardQueueUser {
    id: string;
    name: string;
    cpfCnpj: string;
}

export interface VisaCardQueue {
    id: string;
    partnerId: string;
    attempts: number;
    status: VisaCardQueueStatus;
    note: string;
    createdAt: Date;
    updatedAt: Date | null;
    user: VisaCardQueueUser;
}

export interface CardQueuePaginated {
    result: VisaCardQueue[];
    limit: number;
    offset: number;
    total: number;
}

export interface ActiveCards {
    physical: boolean;
    virtual: boolean;
}

export interface CardImage {
    id: string;
    descr: string;
}

export interface CardTypeName {
    name: boolean;
    noname: boolean;
}

export interface CardConfig {
    broadcaster: BroadCaster;
    brand: CardBrand;
    productId: string;
    plasticId: string;
    activateCards: ActiveCards;
    commercialOrigin: string;
    cardTypeName: CardTypeName;
    numberBin: string;
    typeBin: string;
    cardImage: CardImage[];
    multiCards: MultiCard;
}

export interface UsersByStatus {
    status: UserStatus;
    count: string;
}

export interface AuditInfo {
    id: string;
    cpf: string;
    name: string;
    userId: string;
    dateTime: Date;
    geoIp: boolean;
    partnerId: string | null;
    partnerName: string | null;
    ip: string;
    requestId: string;
    device: DeviceInfo;
    auditType: AuditAction;
    transfer: Transfer | null;
    bankSlip: BankSlip | null;
    covenant: Covenant | null;
    paymentSlip: PaymentSlip | null;
    genericBody: string | null;
    prevState: string | null;
    resume: string | null;
    permissionType: PermissionType | null;
    amount: number | null;
    requestedBy: string | null;
}

export interface AuditAdmin {
    id: string;
    cpf: string;
    name: string;
    userId: string;
    dateTime: Date;
    geoIp: boolean;
    ip: string;
    requestId: string;
    auditType: AuditActionsAdmin;
    genericBody: string | null;
    prevState: string | null;
    resume: string | null;
    device: DeviceInfo | null;
}

export interface History {
    id: string;
    name: string;
    resume: string | null;
    dateTime: Date;
}

export interface Covenant {
    number: string;
    value: number | null;
}

export interface Address {
    city: string;
    state: string;
    street: string;
    postalCode: string;
    neighborhood: string;
}

export interface FilterAudit {
    cpf: string | null;
    userId: string | null;
    start: Date | null;
    end: Date | null;
    auditAction: AuditAction | null;
    limit: number;
}

export interface FilterAuditAdmin {
    cpf: string | null;
    start: Date | null;
    end: Date | null;
    auditAction: AuditActionsAdmin | null;
    limit: number;
}

export interface AuditData {
    data: AuditInfo[];
    total: number;
}

export interface AuditDataAdmin {
    data: AuditAdmin[];
    total: number;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface DeviceInfo {
    deviceId: string;
    deviceType: DeviceInfoDeviceType;
    version: string | null;
    browserUserAgent: string | null;
    hardwareBrand: string | null;
    hardwareModel: string | null;
    location: LatLng | null;
    tokenNotification: string | null;
}

export interface PaymentSlip {
    branch: string;
    account: string;
    barcode: string;
    date: Date;
    dueDateTime: Date | null;
    description: string;
    type: PaymentType;
}

export interface Notification {
    id: string;
    userId: string;
    userName: string;
    title: string;
    message: string;
    date: Date;
    partnerIds: string[] | null;
}

export interface HistoryAudit {
    resume: string | null;
    name: string;
    dateTime: Date;
}

export interface NotificationInfo {
    id: string;
    userId: string;
    userName: string;
    title: string;
    message: string;
    date: Date;
    partnerNames: string[] | null;
}

export interface BankSlip {
    nameBank: string;
    codeBankDV: string;
    digitableLine: string;
    codigoDeBarras: string;
    paymentPlace: string;
    dueDate: Date;
    beneficiary: BankSlipBeneficiary;
    documentDate: Date;
    documentNumber: string;
    documentSpecies: string;
    accept: string;
    proccessDate: Date;
    ourNumber: string;
    walet: string;
    specie: string;
    documentValue: number;
    instructions: string;
    payer: BankSlipPayer;
    barCode: string;
    clientCode: string;
    yourNumber: string;
    typePerson: string;
    link: string;
}

export interface BankSlipBeneficiary {
    name: string;
    agencyCode: string;
}

export interface BankSlipPayer {
    name: string;
    documentNumber: string;
    address: Address;
}

export interface TransferTo {
    bank: string;
    branch: string;
    account: string;
    cpfCnpj: string | null;
    name: string;
    type: CounterpartTransferType;
}

export interface Transfer {
    date: Date;
    description: string | null;
    amount: number;
    finality: string | null;
    type: TransactionType;
    from: TransferFrom;
    to: TransferTo;
    partnerId: string;
    recurrent: number | null;
}

export interface TransferFrom {
    branch: string;
    account: string;
    cpfCnpj: string;
}

export interface Investment {
    amount: number;
    account: string;
    branch: string;
}

export interface EntryStatement {
    id: string;
    movementNumber: string;
    type: TransactionType;
    description: string;
    finality: string;
    nature: EntryStatementNature;
    prevBalance: number;
    currentBalance: number;
    movementDate: Date;
    amount: number;
    part: Part;
    counterPart: Part | null;
    devolutionReason: string | null;
    hasReceipt: boolean;
}

export interface CreateTransfer {
    status: CreateTransferStatus;
    number: string;
    type: CreateTransferType;
    entryStatement: EntryStatement | null;
}

export interface Part {
    name: string;
    cpfCnpj: string | null;
    branch: string;
    bank: string | null;
    account: string | null;
}

export interface Schedule {
    id: string;
    actionId: string;
    date: Date;
    recurrent: number | null;
    processed: boolean;
    type: ScheduleType;
}

export interface ActionSchedule {
    id: string;
    scheduleId: string;
    date: Date;
    processedDate: Date | null;
    count: number;
    status: ScheduleType;
}

export interface RGBA {
    r: number;
    g: number;
    b: number;
    a: number;
}

export interface Color {
    primary: RGBA;
    secondary: RGBA;
    textPrimary: RGBA;
    textSecondary: RGBA;
    backgroundPrimary: RGBA;
    backgroundSecondary: RGBA;
    detail: RGBA;
    creditLabel: RGBA;
    debitLabel: RGBA;
}

export interface Font {
    name: string;
    href: string;
}

export interface Customize {
    color: Color;
    font: Font;
}

export interface ScheduleRecurrency {
    id: string;
    idSchedule: string;
    date: Date;
    amount: number;
    status: ScheduleType;
}

export interface FilterVolumetry {
    type: TransactionType | null;
    partnerId: string;
    startDate: Date | null;
    endDate: Date | null;
    withError: boolean | null;
}

export interface VolumetryItem {
    date: Date;
    type: TransactionType;
    partnerId: string | null;
    request: string;
    response: string;
    requestIdentify: string;
    value: number;
    withError: boolean;
}

export interface Volumetry {
    partnerId: string | null;
    type: TransactionType;
    quantity: number;
    quantityWithErrors: number;
}

export interface Balance {
    available: number;
    blocked: number;
}

export interface ClipLaunderingDataDoc {
    assertiveness: string;
    number: string;
    situation: string | null;
}

export interface ClipLaunderingDataNews {
    title: string;
    reviewDate: string;
    content: string;
}

export interface ClipLaunderingData {
    id: string;
    name: string | null;
    type: string | null;
    doc: ClipLaunderingDataDoc;
    involvement: string | null;
    crimes: string[];
    news: ClipLaunderingDataNews[];
    pep: string | null;
}

export interface AllCheckRegistrationData {
    name: string | null;
    documentNumber: string | null;
    documentSource: string | null;
    rg: string | null;
    birthDate: string | null;
    age: string | null;
    sign: string | null;
    email: string | null;
    membership: string | null;
    pis: string | null;
    workPermit: string | null;
    cnpjEmployer: string | null;
    issueDate: string | null;
    activity: string | null;
    degreeOfEducation: string | null;
    gender: string | null;
    civilStatus: string | null;
    benefitNumber: string | null;
    benefitValue: string | null;
    benefitStart: string | null;
    titleNumber: string | null;
    nationality: string | null;
}

export interface AllCheckEmail {
    documentNumber: string | null;
    email: string | null;
}

export interface AllCheckDeath {
    name: string | null;
    motherName: string | null;
    deathDate: string | null;
    birthDate: string | null;
}

export interface AllCheckPpe {
    status: string | null;
    name: string | null;
    birthDate: string | null;
    titleNumber: string | null;
    degreeOfEducation: string | null;
    civilStatus: string | null;
    nationality: string | null;
    birthCounty: string | null;
    ufBirth: string | null;
    occupationDescription: string | null;
    nameUrna: string | null;
    accessionNumber: string | null;
    partyNumber: string | null;
    partyInitials: string | null;
    partyName: string | null;
    initialsUf: string | null;
    county: string | null;
    responsibility: string | null;
    power: string | null;
    institution: string | null;
}

export interface AllCheckAddress {
    address: string | null;
    number: string | null;
    complement: string | null;
    cep: string | null;
    neighborhood: string | null;
    city: string | null;
    state: string | null;
}

export interface AllCheckPhone {
    name: string | null;
    documentNumber: string | null;
    ddd: string | null;
    phone: string | null;
    phoneType: string | null;
    address: string | null;
    addressNumber: string | null;
    cep: string | null;
    complement: string | null;
    neighborhood: string | null;
    city: string | null;
    state: string | null;
    installationDate: string | null;
}

export interface AllCheckPartner {
    partner: string | null;
    cpf: string | null;
    rg: string | null;
    address: string | null;
    addressNumber: string | null;
    addressComplement: string | null;
    neighborhood: string | null;
    state: string | null;
    cep: string | null;
    cnpj: string | null;
    stateRegistration: string | null;
    participation: string | null;
    dateTicket: string | null;
    startDate: string | null;
    name: string | null;
    buyAddress: string | null;
    buyAddressNumber: string | null;
    companyComplement: string | null;
    companyNeighborhood: string | null;
    companyState: string | null;
    companyCep: string | null;
    companyPartner: string | null;
}

export interface AllCheckCompany {
    cnpj: string | null;
    name: string | null;
    fantasy: string | null;
    openingDate: string | null;
    businessActivity: string | null;
    cnae: string | null;
    cnae2: string | null;
    juridicalNature: string | null;
    address: string | null;
    number: string | null;
    complement: string | null;
    cep: string | null;
    neighborhood: string | null;
    city: string | null;
    state: string | null;
    cadastralSituation: string | null;
    porte: string | null;
    type: string | null;
    cadastralSituationDate: string | null;
    specialSituation: string | null;
    specialSituationDate: string | null;
}

export interface AllCheckVehicle {
    name: string | null;
    document: string | null;
    plaque: string | null;
    renavam: string | null;
    brand: string | null;
    fuel: string | null;
    manufacturingYear: string | null;
    chassis: string | null;
    address: string | null;
    number: string | null;
    cep: string | null;
    complement: string | null;
    neighborhood: string | null;
    city: string | null;
    uf: string | null;
    ddd: string | null;
    phone: string | null;
    ddd1: string | null;
    phone1: string | null;
    movingDate: string | null;
    baseYear: string | null;
}

export interface AllCheckBankData {
    bankName: string | null;
    agencyName: string | null;
    agencyAddress: string | null;
    city: string | null;
    state: string | null;
    cep: string | null;
    ddd: string | null;
    phone: string | null;
    fax: string | null;
    bank: string | null;
    agencia: string | null;
}

export interface AllCheckCcf {
    name: string | null;
    documentNumber: string | null;
    occurrenceAmount: string | null;
    bankName: string | null;
    agencyName: string | null;
    agencyAddress: string | null;
    city: string | null;
    state: string | null;
    cep: string | null;
    ddd: string | null;
    phone: string | null;
    fax: string | null;
    bank: string | null;
    agency: string | null;
    returnReason: string | null;
    lastOccurrenceDate: string | null;
}

export interface AllCheckWorkHistory {
    admissionDate: string | null;
    nome: string | null;
    cpf: string | null;
    fre: string | null;
    cnpj: string | null;
    nomeEmpresa: string | null;
}

export interface AllCheckWatchNetwork {
    dateConsultation: string | null;
    product: string | null;
    client: string | null;
    phone: string | null;
}

export interface AllCheckData {
    id: string;
    registrationData: AllCheckRegistrationData | null;
    emails: AllCheckEmail[];
    death: AllCheckDeath | null;
    ppe: AllCheckPpe | null;
    address: AllCheckAddress | null;
    phones: AllCheckPhone[];
    partners: AllCheckPartner[];
    companies: AllCheckCompany | null;
    vehicles: AllCheckVehicle[];
    ccf: AllCheckCcf[];
    BankData: AllCheckBankData[];
    workHistory: AllCheckWorkHistory[];
    watchNetwork: AllCheckWatchNetwork[];
}

export interface ClipLaunderingRequest {
    data: ClipLaunderingData | null;
    error: string | null;
    status: StatusMessage;
}

export interface AllCheckRequest {
    data: AllCheckData | null;
    error: string | null;
    status: StatusMessage;
}

export interface PepAmlRequest {
    data: PepAmlData | null;
    error: string | null;
    status: StatusMessage;
}

export interface LsaAmlRequest {
    data: LsaAmlData | null;
    error: string | null;
    status: StatusMessage;
}

export interface LrnAmlRequest {
    data: LrnAmlData | null;
    error: string | null;
    status: StatusMessage;
}

export interface PepAmlData {
    id: string | null;
    name: string;
    cpfOrCnpj: string;
    classification: string;
    description: string;
    startDate: string | null;
    endDate: string | null;
    deathDate: string | null;
    city: string | null;
    state: string | null;
    officialFlag: string;
    additionalInfo: string;
    score: number;
}

export interface LrnAmlData {
    id: string;
    name: string;
    cpfOrCnpj: string;
    envolvimento: string;
    crimes: string[];
    data: string | null;
    citadoMidia: boolean;
    score: number;
}

export interface LsaAmlData {
    id: string;
    name: string;
    document: string;
    pseudonyms: string;
    synthesis: string;
    reason: string;
    amountAbon: string;
    amountDesabon: string;
    dateModification: string | null;
}

export interface ToDoErros {
    id: number;
    error: string;
    message: string;
    guilty: string | null;
    createdAt: Date;
    default: boolean;
    updatedAt: Date | null;
}

export interface UserAccountInfo {
    partnerId: string;
    partnerName: string;
    accountNumber: string;
    birthDate: Date;
    email: string;
}

export interface UserBasicData {
    email: string;
    phone: string;
    filiacao1: string;
    monthlyIncome: string;
}

export interface ProofOfLifeInfoHistory {
    adminName: string;
    auditType: string;
}

export interface DetailedProofOfLifeInfo {
    selfieFilename: string;
    oldUrlSelfie: string;
    newUrlSelfie: string;
    acertIdKey: string | null;
    images: Image[] | null;
    history: ProofOfLifeInfoHistory[] | null;
}

export interface ProofOfLifeInfo {
    id: string;
    name: string;
    documentNumber: string;
    createdAt: Date;
    status: StatusUserProofOfLife;
    detailedProofOfLifeInfo: DetailedProofOfLifeInfo | null;
}

export interface UserProofOfLife {
    selfie: string;
    status: StatusUserProofOfLife;
    createdAt: Date;
    acertIdKey: string | null;
    statusAcertId: string | null;
    dateResponseAcertId: Date | null;
    reason: string | null;
}

export interface CardReportResult {
    cardId: string;
    name: string;
    account: string;
    cpf: string;
    requestedDate: Date;
    gatewayAccountId: string | null;
    gatewayCardId: string | null;
    attempts: number;
    partnerName: string;
    cardType: CardType;
    requestStatus: VisaCardQueueStatus;
    cardStatus: CardStatusReport;
}

export interface CardReport {
    result: CardReportResult[];
    limit: number;
    offset: number;
    total: number;
}

export interface RequestCardBody {
    cpf: string;
    oldCardId: string;
}

export interface LinkCardBody {
    cpf: string;
    oldCardId: string;
    newCardId: string;
}

export interface CardReportFilter {
    partnerId: string | null;
    cpf: string | null;
    limit: number;
    offset: number;
    includeVirtualCards: boolean;
}

export interface ExcelCardReportFilter {
    cpf: string | null;
    partnerId: string | null;
    includeVirtualCards: boolean;
}

export interface QueueError {
    message: string;
}

export interface FeaturesStatus {
    id: string;
    title: string;
    description: string;
    slug: FeatureSlug;
    isEnabledInPlatform: boolean;
    status: FeatureStatus;
}

export interface FeatureStatusSetup {
    id: string;
    status: FeatureStatus;
}

export interface PartnerFileStatus {
    statusId: string;
    statusName: string;
    total: number;
}

export interface PartnerLot {
    partnerId: string;
    partnerName: string | null;
}

export interface PartnerFileLot {
    partnerFileId: number;
    partnerId: string;
    statusId: string;
    statusName: string;
    auditDateTimeRegistration: Date;
    partnerFileStatusShortDescription: string;
    partnerFileShortDescription: string;
    listProspectStatus: PartnerFileStatus[];
    partnerName: string | null;
    messageDaysNotification: string | null;
    campaignPositiveDate: string | null;
}

export interface ProspectStatus {
    statusId: string | null;
    statusName: string | null;
}

export interface Prospect {
    id: number;
    date: Date | null;
    doc: string | null;
    birthdate: Date | null;
    name: string | null;
    mother: string | null;
    zipCode: string | null;
    address: string | null;
    addressNumber: string | null;
    complementAddress: string | null;
    neighborhood: string | null;
    city: string | null;
    state: string | null;
    DDD: string | null;
    cellNumber: string | null;
    email: string | null;
    gender: string | null;
    grossIncome: string | null;
    equityValue: string | null;
    obs1: string | null;
    obs2: string | null;
    partnerFileId: number;
    statusId: string | null;
    status: ProspectStatus;
    partnerId: string | null;
    partnerName: string | null;
    partnerFileStatusDescription: string | null;
    partnerFileDescription: string | null;
    partnerFileStatusId: string | null;
    partnerFileStatusName: string | null;
}

export interface PartnerNotification {
    campaignId: number;
    campaignDate: Date;
    campaignDescription: string;
    partnerFileId: number;
    campaignPositiveDate: Date;
    statusDetail: string | null;
    campaignPositiveStatusDescription: string | null;
}

export interface PartnerPositivize {
    partnerFileId: number;
    partnerFileShortDescription: string;
    partnerFileCreationDate: Date;
    partnerFileanAlysisDate: Date;
    partnerFilePositive: number;
    partnerFilePending: number;
    messageDaysNotification: number;
    partnerId: string;
    notifications: PartnerNotification[];
    status: PartnerFileStatus;
    partnerName: string | null;
}

export interface PartnerFileAttachment {
    partnerFileAttachmentId: number;
    partnerFileAttachmentDescription: string;
    partnerFileAttachmentFile: string | null;
    partnerFileAttachmentFileType: string;
    partnerFileId: number;
    partnerFileAttachmentDateTime: string;
    partnerFileAttachmentFileName: string | null;
}

export interface PartnerFileComment {
    partnerFileCommentId: number;
    partnerFilecommentObs: string;
    partnerFileId: number;
    partnerFileCommentDateTime: Date;
}

export interface WhiteListSms {
    id: string;
    cellNumber: string;
}

export interface CreateReducedJourneyAccount {
    partnerId: string;
    name: string;
    motherName: string;
    cpf: string;
    birthDate: Date;
    gender: Gender | null;
    income: number;
    equity: number;
    codeArea: string;
    phone: string;
    email: string;
    zipCode: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    addressNumber: string;
    addressComplement: string | null;
    selfie: Buffer;
    acceptTermsOfUse: boolean;
    acceptContract: boolean;
    acceptLGPD: boolean;
    password: string;
    document: DocumentImage;
    ppe: boolean | null;
    ppeInfo: PpeInfo | null;
    fatca: boolean | null;
}

export interface RelatedPpeInfo {
    name: string;
    relation: string;
}

export interface PpeInfo {
    relatedPpe: boolean;
    relatedPpeInfo: RelatedPpeInfo | null;
    start: Date;
    end: Date;
    role: string;
}

export interface DocumentImage {
    documentType: DocumentType;
    imageFront: Buffer;
    imageBack: Buffer | null;
}

export interface InternalPositivizeReducedJourney {
    cpf: string;
    birthday: Date;
    partnerId: string;
}

export interface ReducedJourneyAccountResponse {
    message: string;
    complienceResponse: string;
}

export interface City {
    code: string;
    name: string;
}

export interface GenericSuccesResponse {
    content: string;
}

export interface GroupChargeFile {
    id: string;
    partnerId: string;
    description: string;
    status: string;
    partnerAccount: string;
    userId: string;
    createdAt: Date;
    updatedAt: Date;
    convenantName: string;
    totalItems: number;
    totalProcessedItems: number;
}

export interface GroupChargeFileItem {
    id: string;
    groupChargeFileId: string;
    accountNumber: string;
    documentNumber: string;
    amount: number;
    status: string;
    validationDetails: string | null;
    transferReturn: string | null;
    processedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    reviews: GroupChargeItemReview[];
}

export interface GroupChargeItemReview {
    id: string;
    groupChargeItemId: string;
    message: string;
    reviewType: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface CreateGroupChargeFileParams {
    file: Buffer;
    partnerId: string;
    userId: string;
    description: string;
    partnerAccount: string;
    convenantName: string;
}

export interface CreateGroupChargeFileResponse {
    status: string;
    id: string;
}

export interface ProspectData {
    name: string;
    email: string;
    company: string;
    companyType: string;
    companySector: string;
    companyWebsite: string;
    companyEmployersQuantity: string;
    potentialAccountsNumber: string;
    prospectOrigin: string;
    message: string;
}

export interface InsertManualPJAccountRequest {
    name: string;
    cnpj: string;
    clientCode: string;
    email: string;
    phone: string;
    partnerId: string;
    branch: string;
    accountNumber: string;
}

export interface Period {
    start: Date | null;
    end: Date | null;
}

export interface Receipt {
    html: string;
    image: string;
}

export interface PendingApprovalsResponse {
    id: string;
    branchNumber: string;
    accountNumber: string;
    previousValue: string;
    changedValue: string;
    limitType: string;
    createdAt: string;
}

export interface ExportConciliation {
    format: string;
    data: string;
}

export enum Day {
    sunday = "sunday",
    monday = "monday",
    tuesday = "tuesday",
    wednesday = "wednesday",
    thursday = "thursday",
    friday = "friday",
    satuday = "satuday",
}

export enum ServiceNames {
    ted = "ted",
    doc = "doc",
    internalTransfer = "internalTransfer",
    scheduling = "scheduling",
    applyInvestment = "applyInvestment",
    viewInvestment = "viewInvestment",
    viewBalanceStatement = "viewBalanceStatement",
    generateBankslip = "generateBankslip",
    paymentBankSlip = "paymentBankSlip",
    paymentBankslip = "paymentBankslip",
    paymentAgreement = "paymentAgreement",
    accessInternetBanking = "accessInternetBanking",
    accessApps = "accessApps",
    openBanking = "openBanking",
    viewEditPackageService = "viewEditPackageService",
    createAccountPj = "createAccountPj",
    createAccountPf = "createAccountPf",
    createAccountPjPartner = "createAccountPjPartner",
    createAccountPfPartner = "createAccountPfPartner",
    revenueReportPf = "revenueReportPf",
    revenueReportPj = "revenueReportPj",
    withdraw = "withdraw",
    sendEmail = "sendEmail",
    sendNotification = "sendNotification",
    createAccountAuto = "createAccountAuto",
    createAccountAutoMEI = "createAccountAutoMEI",
    recharge = "recharge",
}

export enum ServiceNamesV13 {
    ted = "ted",
    doc = "doc",
    internalTransfer = "internalTransfer",
    scheduling = "scheduling",
    applyInvestment = "applyInvestment",
    viewInvestment = "viewInvestment",
    viewBalanceStatement = "viewBalanceStatement",
    generateBankslip = "generateBankslip",
    paymentBankSlip = "paymentBankSlip",
    paymentBankslip = "paymentBankslip",
    paymentAgreement = "paymentAgreement",
    accessInternetBanking = "accessInternetBanking",
    accessApps = "accessApps",
    openBanking = "openBanking",
    viewEditPackageService = "viewEditPackageService",
    createAccountPj = "createAccountPj",
    createAccountPf = "createAccountPf",
    createAccountPjPartner = "createAccountPjPartner",
    createAccountPfPartner = "createAccountPfPartner",
    revenueReportPf = "revenueReportPf",
    revenueReportPj = "revenueReportPj",
    withdraw = "withdraw",
    sendEmail = "sendEmail",
    sendNotification = "sendNotification",
    createAccountAuto = "createAccountAuto",
    createAccountAutoMEI = "createAccountAutoMEI",
    recharge = "recharge",
    fepwebLoginCheck = "fepwebLoginCheck",
    proofOfLifePJ = "proofOfLifePJ",
    insurance = "insurance",
}

export enum PfPj {
    pf = "pf",
    pj = "pj",
    mei = "mei",
}

export enum ApprovationType {
    manual = "manual",
    automatic = "automatic",
    unknown = "unknown",
}

export enum SubType {
    simple = "simple",
    full = "full",
}

export enum BlockStatusAccount {
    blocked = "blocked",
    unlocked = "unlocked",
    automatic = "automatic",
}

export enum PermissionType {
    admin = "admin",
    listUsers = "listUsers",
    connectPartners = "connectPartners",
    statement = "statement",
    investmentStatement = "investmentStatement",
    balance = "balance",
    requestTransferenceSameTitularity = "requestTransferenceSameTitularity",
    requestTransferenceAnyTitularity = "requestTransferenceAnyTitularity",
    approveTransferenceSameTitularity = "approveTransferenceSameTitularity",
    approveTransferenceAnyTitularity = "approveTransferenceAnyTitularity",
    requestPayment = "requestPayment",
    approvePayment = "approvePayment",
    requestInvestmentApplication = "requestInvestmentApplication",
    approveInvestmentApplication = "approveInvestmentApplication",
    requestIncludeInstructionTitle = "requestIncludeInstructionTitle",
    approveIncludeInstructionTitle = "approveIncludeInstructionTitle",
    requestBankslipPayment = "requestBankslipPayment",
    approveBankslipPayment = "approveBankslipPayment",
    requestAgreementPayment = "requestAgreementPayment",
    approveAgreementPayment = "approveAgreementPayment",
    registerUser = "registerUser",
    editProfiles = "editProfiles",
    changeUserPermissions = "changeUserPermissions",
    approveWithdraw = "approveWithdraw",
    requestWithdraw = "requestWithdraw",
    requestRecharge = "requestRecharge",
    approveRecharge = "approveRecharge",
    viewCard = "viewCard",
    managerCard = "managerCard",
    requestManagerCard = "requestManagerCard",
}

export enum UserStatus {
    pending = "pending",
    active = "active",
    repproved = "repproved",
    canceled = "canceled",
    finished = "finished",
    notFinished = "notFinished",
    processing = "processing",
    approvePending = "approvePending",
    approvePendingWithDocument = "approvePendingWithDocument",
    approvePendingWithoutDocument = "approvePendingWithoutDocument",
}

export enum Status {
    pending = "pending",
    active = "active",
    repproved = "repproved",
    canceled = "canceled",
    finished = "finished",
    notFinished = "notFinished",
    processing = "processing",
    approvePending = "approvePending",
    approvePendingWithDocument = "approvePendingWithDocument",
    approvePendingWithoutDocument = "approvePendingWithoutDocument",
}

export enum StatusV13 {
    pending = "pending",
    active = "active",
    repproved = "repproved",
    canceled = "canceled",
    finished = "finished",
    notFinished = "notFinished",
    processing = "processing",
    approvePending = "approvePending",
    approvePendingWithDocument = "approvePendingWithDocument",
    approvePendingWithoutDocument = "approvePendingWithoutDocument",
    waitingSignature = "waitingSignature",
    undefined = "undefined",
}

export enum ComplianceStatus {
    waiting = "waiting",
    approved = "approved",
    repproved = "repproved",
    manualAnalysis = "manualAnalysis",
    canceled = "canceled",
    processing = "processing",
}

export enum AccountStatusV13 {
    active = "active",
    closed = "closed",
}

export enum TermContractType {
    term = "term",
    contract = "contract",
    termPj = "termPj",
    contractPj = "contractPj",
    farePackages = "farePackages",
}

export enum DocumentType {
    CNH = "CNH",
    RG = "RG",
    RNE = "RNE",
}

export enum Gender {
    masculino = "masculino",
    feminino = "feminino",
    outro = "outro",
}

export enum DocumentDispatcher {
    DETRAN = "DETRAN",
    CRM = "CRM",
    CRO = "CRO",
    OAB = "OAB",
    SSP = "SSP",
    OUTROS = "OUTROS",
}

export enum WeddingType {
    partialCommunion = "partialCommunion",
    universalCommunion = "universalCommunion",
    totalSeparation = "totalSeparation",
    compulsorySeparation = "compulsorySeparation",
    finalParticipation = "finalParticipation",
}

export enum CategoryAccount {
    exchange = "exchange",
    credit = "credit",
    investment = "investment",
    payment = "payment",
    checking = "checking",
}

export enum CivilStatus {
    single = "single",
    married = "married",
    divorced = "divorced",
    separate = "separate",
    widower = "widower",
}

export enum JourneyType {
    preApproved = "preApproved",
    noRecord = "noRecord",
}

export enum ActionRecoverUser {
    blockPassword6 = "blockPassword6",
    resetPassword6 = "resetPassword6",
    createPassword6 = "createPassword6",
    recoverPassword6 = "recoverPassword6",
    recoverPassword4 = "recoverPassword4",
    removeToken = "removeToken",
    blockUser = "blockUser",
}

export enum StatusProofOfLife {
    blocked = "blocked",
    waitingEmail = "waitingEmail",
    sendingEmail = "sendingEmail",
    sendedEmail = "sendedEmail",
    waitingAcertId = "waitingAcertId",
    sendingAcertId = "sendingAcertId",
    sendedAcertId = "sendedAcertId",
    approved = "approved",
    invalid = "invalid",
    reproved = "reproved",
    approvedManual = "approvedManual",
    failed = "failed",
    pending = "pending",
}

export enum AdminUserPermission {
    viewAdminUser = "viewAdminUser",
    editAdminUser = "editAdminUser",
    viewPlatformManager = "viewPlatformManager",
    viewPartner = "viewPartner",
    editPartner = "editPartner",
    viewUser = "viewUser",
    viewCompany = "viewCompany",
    addFeedbackOpeningAccount = "addFeedbackOpeningAccount",
    blockUser = "blockUser",
    recoveryUser = "recoveryUser",
    blockCompany = "blockCompany",
    editRepresentativeCompany = "editRepresentativeCompany",
    viewOpeningAccountPF = "viewOpeningAccountPF",
    viewOpeningAccountPJ = "viewOpeningAccountPJ",
    viewOpeningAccountPJMEI = "viewOpeningAccountPJMEI",
    approveAccountPF = "approveAccountPF",
    approveAccountPJ = "approveAccountPJ",
    approveAccountPJMEI = "approveAccountPJMEI",
    validateComplianceAccountPF = "validateComplianceAccountPF",
    validateComplianceAccountPJ = "validateComplianceAccountPJ",
    validateComplianceAccountPJMEI = "validateComplianceAccountPJMEI",
    disapproveAccountPF = "disapproveAccountPF",
    disapproveAccountPJ = "disapproveAccountPJ",
    disapproveAccountPJMEI = "disapproveAccountPJMEI",
    revalidateAccountPF = "revalidateAccountPF",
    revalidateAccountPJ = "revalidateAccountPJ",
    revalidateAccountPJMEI = "revalidateAccountPJMEI",
    reintegratePendingAccount = "reintegratePendingAccount",
    addPartner = "addPartner",
    blockPartner = "blockPartner",
    addAdminUser = "addAdminUser",
    blockAdminUser = "blockAdminUser",
    viewAuditAdmin = "viewAuditAdmin",
    changeService = "changeService",
    editServiceHours = "editServiceHours",
    addExceptionDate = "addExceptionDate",
    editExceptionDate = "editExceptionDate",
    viewAuditUser = "viewAuditUser",
    viewErrors = "viewErrors",
    editErrors = "editErrors",
    editUser = "editUser",
    editCompany = "editCompany",
    canceledAccountPJ = "canceledAccountPJ",
    canceledAccountPJMEI = "canceledAccountPJMEI",
    canceledAccountPF = "canceledAccountPF",
    sendNotification = "sendNotification",
    assignWorkflow = "assignWorkflow",
    sendFeedback = "sendFeedback",
    viewFeedback = "viewFeedback",
    viewHistoryWorkflow = "viewHistoryWorkflow",
    viewConciliationAgreements = "viewConciliationAgreements",
    viewConciliationWithdraws = "viewConciliationWithdraws",
    filterUserAndReport = "filterUserAndReport",
    createTedHugpay = "createTedHugpay",
    viewCardConciliation = "viewCardConciliation",
    approvePendingWithDocumentAccountPF = "approvePendingWithDocumentAccountPF",
    approvePendingWithoutDocumentAccountPF = "approvePendingWithoutDocumentAccountPF",
    approvePendingAccountPF = "approvePendingAccountPF",
    viewGeneralParameters = "viewGeneralParameters",
    editDefaultLimit = "editDefaultLimit",
    addSegments = "addSegments",
    editSegments = "editSegments",
    addSegmentBoundaries = "addSegmentBoundaries",
    editSegmentBoundaries = "editSegmentBoundaries",
    viewCustomLimits = "viewCustomLimits",
    addCustomLimits = "addCustomLimits",
    editCustomLimits = "editCustomLimits",
    viewLogLimits = "viewLogLimits",
    viewSafetyLimit = "viewSafetyLimit",
    editSafetyLimit = "editSafetyLimit",
    visaCardManager = "visaCardManager",
    editWhitelist = "editWhitelist",
    viewTipping = "viewTipping",
    addTipping = "addTipping",
    cancelTipping = "cancelTipping",
    viewPositivize = "viewPositivize",
    sendMensPositivize = "sendMensPositivize",
    viewProofOfLife = "viewProofOfLife",
    reproveProofOfLife = "reproveProofOfLife",
    approveProofOfLife = "approveProofOfLife",
    filterUserAndReportByPartner = "filterUserAndReportByPartner",
    updateRegisterInfo = "updateRegisterInfo",
    generateReplacementCard = "generateReplacementCard",
    viewCPFList = "viewCPFList",
    editCPFList = "editCPFList",
    createBatchChargeDebit = "createBatchChargeDebit",
    viewBatchChargeDebit = "viewBatchChargeDebit",
    insertManualPJAccount = "insertManualPJAccount",
    viewPixAccountReport = "viewPixAccountReport",
    viewAccountBalanceAndStatement = "viewAccountBalanceAndStatement",
    viewPixLimits = "viewPixLimits",
    approvePixLimits = "approvePixLimits",
}

export enum ContaPagamentoPF {
    cpf = "cpf",
    name = "name",
    email = "email",
    phone = "phone",
    socialName = "socialName",
    gender = "gender",
    filiacao1 = "filiacao1",
    filiacao2 = "filiacao2",
    nacionality = "nacionality",
    address = "address",
    commercialAddress = "commercialAddress",
    patrimony = "patrimony",
    monthlyIncome = "monthlyIncome",
    documentPhotos = "documentPhotos",
    selfie = "selfie",
}

export enum RegisterField {
    name = "name",
    email = "email",
    phone = "phone",
    socialName = "socialName",
    gender = "gender",
    filiacao1 = "filiacao1",
    filiacao2 = "filiacao2",
    nacionality = "nacionality",
    address = "address",
    commercialAddress = "commercialAddress",
    patrimony = "patrimony",
    monthlyIncome = "monthlyIncome",
    occupation = "occupation",
    naturalness = "naturalness",
    birthDate = "birthDate",
    civilStatus = "civilStatus",
    spouseName = "spouseName",
    weddingType = "weddingType",
    accountInfo = "accountInfo",
    document = "document",
    selfie = "selfie",
    proofOfAddress = "proofOfAddress",
    certificateMEI = "certificateMEI",
    companyName = "companyName",
    fantasyName = "fantasyName",
    foundationDate = "foundationDate",
}

export enum Region {
    sul = "sul",
    norte = "norte",
    sudeste = "sudeste",
    nordeste = "nordeste",
    centroOeste = "centroOeste",
}

export enum RevalidationType {
    text = "text",
    image = "image",
}

export enum CardBrand {
    visa = "visa",
    mastercard = "mastercard",
}

export enum PermissionsPartner {
    request = "request",
    statement = "statement",
    cashback = "cashback",
    duplicate = "duplicate",
    lossOrTheft = "lossOrTheft",
    activate = "activate",
    cancel = "cancel",
    block = "block",
    unblock = "unblock",
    changePassword = "changePassword",
    virtualCard = "virtualCard",
    forgotPassword = "forgotPassword",
}

export enum PermissionsPartnerInput {
    password = "password",
    address = "address",
    last4Digits = "last4Digits",
    dueDate = "dueDate",
    userTerms = "userTerms",
}

export enum ChangeComplianceStatus {
    approved = "approved",
    reproved = "reproved",
}

export enum VisaCardQueueStatus {
    waiting = "waiting",
    processing = "processing",
    processed = "processed",
    failed = "failed",
}

export enum BroadCaster {
    dock = "dock",
}

export enum MultiCard {
    without = "without",
    free = "free",
    restricted = "restricted",
}

export enum AuditAction {
    login = "login",
    requestTransfer = "requestTransfer",
    requestPaymentBankSlip = "requestPaymentBankSlip",
    requestPaymantCovenant = "requestPaymantCovenant",
    requestInvestment = "requestInvestment",
    createTransfer = "createTransfer",
    createPaymentBankSlip = "createPaymentBankSlip",
    createPaymantCovenant = "createPaymantCovenant",
    createInvestment = "createInvestment",
    createSelfInvoice = "createSelfInvoice",
    approve = "approve",
    approveBankslipPayment = "approveBankslipPayment",
    approveCovenantPayment = "approveCovenantPayment",
    reprove = "reprove",
    reproveBankslipPayments = "reproveBankslipPayments",
    reproveCovenantPayment = "reproveCovenantPayment",
    deleteScheduling = "deleteScheduling",
    setPermission = "setPermission",
    setPasswordPublicKey = "setPasswordPublicKey",
    finishCreateAccount = "finishCreateAccount",
    finishCreateAccountPJ = "finishCreateAccountPJ",
    finishCreateAccountPJMEI = "finishCreateAccountPJMEI",
    resetByRecoveryToken = "resetByRecoveryToken",
    sendLinkToRecovery = "sendLinkToRecovery",
    createProfile = "createProfile",
    createCaixaWithdraw = "createCaixaWithdraw",
    requestCaixaWithdraw = "requestCaixaWithdraw",
    recharge = "recharge",
    requestRecharge = "requestRecharge",
    requestActivateCard = "requestActivateCard",
    requestBlockCard = "requestBlockCard",
    requestCreateAccount = "requestCreateAccount",
    requestUnblockCard = "requestUnblockCard",
    requestNewCard = "requestNewCard",
    requestLockCard = "requestLockCard",
    requestUnlockCard = "requestUnlockCard",
    requestChangePassword = "requestChangePassword",
    requestCreatePassword = "requestCreatePassword",
    requestResetPassword = "requestResetPassword",
    activateCard = "activateCard",
    blockCard = "blockCard",
    newCard = "newCard",
    lockCard = "lockCard",
    unlockCard = "unlockCard",
    cancelCard = "cancelCard",
    changePassword = "changePassword",
    changePassword6 = "changePassword6",
    createPassword = "createPassword",
    resetPassword = "resetPassword",
    userBodySignature = "userBodySignature",
    sendEmail = "sendEmail",
    sendSMS = "sendSMS",
    verifySMSCodeToChangePassword = "verifySMSCodeToChangePassword",
    updatePasswordByToken = "updatePasswordByToken",
    createPasswordByToken = "createPasswordByToken",
    setProfilePhoto = "setProfilePhoto",
    updateClient = "updateClient",
    includePartnerSingleAccountDDA = "includePartnerSingleAccountDDA",
    createCancellationDDAElectronicPayer = "createCancellationDDAElectronicPayer",
    requestIncludeDDAElectronicPayerAggregate = "requestIncludeDDAElectronicPayerAggregate",
    createCancellationDDAElectronicPayerAggregate = "createCancellationDDAElectronicPayerAggregate",
    applyDdaAggregateRequestApprove = "applyDdaAggregateRequestApprove",
    applyDdaAggregateRequestRepprove = "applyDdaAggregateRequestRepprove",
    removePassword4 = "removePassword4",
    sendNewProofOfLife = "sendNewProofOfLife",
    requestNewDevice = "requestNewDevice",
    approveNewDevice = "approveNewDevice",
    updateStatusReducedJourney = "updateStatusReducedJourney",
}

export enum AuditActionsAdmin {
    adminLogin = "adminLogin",
    changeStatusUser = "changeStatusUser",
    setOperations = "setOperations",
    setExceptionDate = "setExceptionDate",
    setAdminUser = "setAdminUser",
    manageServices = "manageServices",
    generate6Pass = "generate6Pass",
    resetUser4Pass = "resetUser4Pass",
    deleteAdminUser = "deleteAdminUser",
    deleteExceptionDay = "deleteExceptionDay",
    setPermission = "setPermission",
    setUser = "setUser",
    createUserInCompany = "createUserInCompany",
    setUserInCompany = "setUserInCompany",
    createProfile = "createProfile",
    setPartner = "setPartner",
    blockPartner = "blockPartner",
    sendLinkToRecovery = "sendLinkToRecovery",
    changeStatusWorkflowPF = "changeStatusWorkflowPF",
    changeStatusWorkflowPJ = "changeStatusWorkflowPJ",
    changeStatusWorkflowPJMEI = "changeStatusWorkflowPJMEI",
    changeErrorMessage = "changeErrorMessage",
    changePartnerId = "changePartnerId",
    changeUserAddress = "changeUserAddress",
    approveProofOfLife = "approveProofOfLife",
    reproveProofOfLife = "reproveProofOfLife",
    updateStatusReducedJourney = "updateStatusReducedJourney",
}

export enum DeviceInfoDeviceType {
    ios = "ios",
    android = "android",
    browser = "browser",
    api = "api",
}

export enum PaymentType {
    agreement = "agreement",
    bankSlip = "bankSlip",
}

export enum ExternalTransferType {
    ted = "ted",
    doc = "doc",
}

export enum CounterpartTransferType {
    cc = "cc",
    pp = "pp",
}

export enum CreateTransferType {
    nsu = "nsu",
    numeroMovimento = "numeroMovimento",
    numeroOperacao = "numeroOperacao",
}

export enum CreateTransferStatus {
    ok = "ok",
    error = "error",
}

export enum TransactionType {
    ted = "ted",
    doc = "doc",
    internalTransfer = "internalTransfer",
    bankSlip = "bankSlip",
    investment = "investment",
    bankRate = "bankRate",
    paymentBankSlip = "paymentBankSlip",
    paymentAgreement = "paymentAgreement",
    tax = "tax",
    statement = "statement",
    createAccount = "createAccount",
    activeAccount = "activeAccount",
    withdraw = "withdraw",
    transaction = "transaction",
    recharge = "recharge",
    card = "card",
    includeDda = "includeDda",
    excludeDda = "excludeDda",
    excludeDdaAggregate = "excludeDdaAggregate",
    includeDdaAggregate = "includeDdaAggregate",
    newDevice = "newDevice",
}

export enum EntryStatementNature {
    credit = "credit",
    debit = "debit",
}

export enum ScheduleType {
    pending = "pending",
    processing = "processing",
    finished = "finished",
    cancelled = "cancelled",
    notPermited = "notPermited",
    waiting = "waiting",
}

export enum StatusMessage {
    success = "success",
    error = "error",
}

export enum StatusUserProofOfLife {
    REFUSED = "REFUSED",
    APPROVED = "APPROVED",
    PROCESSING = "PROCESSING",
    REFUSEDACERTID = "REFUSEDACERTID",
    ANALYSISMANUALACERTID = "ANALYSISMANUALACERTID",
    CANCELED = "CANCELED",
}

export enum CardType {
    physical = "physical",
    virtual = "virtual",
}

export enum CardStatusReport {
    inProduction = "inProduction",
    inserted = "inserted",
    deactivated = "deactivated",
    activated = "activated",
    invalidated = "invalidated",
    blocked = "blocked",
    canceled = "canceled",
    expired = "expired",
    queue = "queue",
}

export enum FeatureSlug {
    CHARGE = "CHARGE",
    REGISTER = "REGISTER",
    NEWS = "NEWS",
    PIX = "PIX",
}

export enum FeatureStatus {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
    DEFAULT = "DEFAULT",
}

export enum FileStatus {
    processing = "processing",
    valid = "valid",
    invalid = "invalid",
    created = "created",
    waiting = "waiting",
}

export enum FileItemStatus {
    processing = "processing",
    valid = "valid",
    invalid = "invalid",
    created = "created",
    finished = "finished",
    witherror = "witherror",
}

export enum FileReviewType {
    informative = "informative",
    restrictive = "restrictive",
}

export enum ErrorType {
    WrongPassword = "WrongPassword",
    PasswordBlocked = "PasswordBlocked",
    ExpiredPassword = "ExpiredPassword",
    ExpiredPasswordToken = "ExpiredPasswordToken",
    MissingOldPassword = "MissingOldPassword",
    MissingNewPassword = "MissingNewPassword",
    SessionNotFound = "SessionNotFound",
    InvalidSession = "InvalidSession",
    ExpiredSession = "ExpiredSession",
    TerminateSession = "TerminateSession",
    InvalidPartner = "InvalidPartner",
    AuthTokenAlreadyUsed = "AuthTokenAlreadyUsed",
    AccessTokenNotFound = "AccessTokenNotFound",
    AuthTokenNotFound = "AuthTokenNotFound",
    InvalidAccessToken = "InvalidAccessToken",
    InvalidAuthToken = "InvalidAuthToken",
    ExpiredAuthToken = "ExpiredAuthToken",
    MissingPublicKey = "MissingPublicKey",
    MissingPermission = "MissingPermission",
    NotFound = "NotFound",
    Bridge = "Bridge",
    UnavailableService = "UnavailableService",
    PartnerNotFound = "PartnerNotFound",
    AccessNotAllowed = "AccessNotAllowed",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidSignature = "InvalidSignature",
    MissingSignature = "MissingSignature",
    AdminUserPermissionError = "AdminUserPermissionError",
    ExceptionAlreadyExists = "ExceptionAlreadyExists",
    MissingAdminPassword = "MissingAdminPassword",
    InsufficientFunds = "InsufficientFunds",
    NotPermited = "NotPermited",
    UnavailableDay = "UnavailableDay",
    PostponedSchedule = "PostponedSchedule",
    Timeout = "Timeout",
    PartnerBaseError = "PartnerBaseError",
    Fatal = "Fatal",
    Connection = "Connection",
}

export async function getOperationDate(date: Date): Promise<Operations> {
  const args = {
        date: date.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getOperationDate", args});
    return {
        ted: ret.ted === null || ret.ted === undefined ? null : {
            start: ret.ted.start === null || ret.ted.start === undefined ? null : ret.ted.start,
            end: ret.ted.end === null || ret.ted.end === undefined ? null : ret.ted.end,
        },
        doc: ret.doc === null || ret.doc === undefined ? null : {
            start: ret.doc.start === null || ret.doc.start === undefined ? null : ret.doc.start,
            end: ret.doc.end === null || ret.doc.end === undefined ? null : ret.doc.end,
        },
        internalTransfer: ret.internalTransfer === null || ret.internalTransfer === undefined ? null : {
            start: ret.internalTransfer.start === null || ret.internalTransfer.start === undefined ? null : ret.internalTransfer.start,
            end: ret.internalTransfer.end === null || ret.internalTransfer.end === undefined ? null : ret.internalTransfer.end,
        },
        investment: ret.investment === null || ret.investment === undefined ? null : {
            start: ret.investment.start === null || ret.investment.start === undefined ? null : ret.investment.start,
            end: ret.investment.end === null || ret.investment.end === undefined ? null : ret.investment.end,
        },
        titleInclusion: ret.titleInclusion === null || ret.titleInclusion === undefined ? null : {
            start: ret.titleInclusion.start === null || ret.titleInclusion.start === undefined ? null : ret.titleInclusion.start,
            end: ret.titleInclusion.end === null || ret.titleInclusion.end === undefined ? null : ret.titleInclusion.end,
        },
        withdraw: ret.withdraw === null || ret.withdraw === undefined ? null : {
            start: ret.withdraw.start === null || ret.withdraw.start === undefined ? null : ret.withdraw.start,
            end: ret.withdraw.end === null || ret.withdraw.end === undefined ? null : ret.withdraw.end,
        },
        paymentBankSlip: ret.paymentBankSlip === null || ret.paymentBankSlip === undefined ? null : {
            start: ret.paymentBankSlip.start === null || ret.paymentBankSlip.start === undefined ? null : ret.paymentBankSlip.start,
            end: ret.paymentBankSlip.end === null || ret.paymentBankSlip.end === undefined ? null : ret.paymentBankSlip.end,
        },
        paymentAgreement: ret.paymentAgreement === null || ret.paymentAgreement === undefined ? null : {
            start: ret.paymentAgreement.start === null || ret.paymentAgreement.start === undefined ? null : ret.paymentAgreement.start,
            end: ret.paymentAgreement.end === null || ret.paymentAgreement.end === undefined ? null : ret.paymentAgreement.end,
        },
        recharge: ret.recharge === null || ret.recharge === undefined ? null : {
            start: ret.recharge.start === null || ret.recharge.start === undefined ? null : ret.recharge.start,
            end: ret.recharge.end === null || ret.recharge.end === undefined ? null : ret.recharge.end,
        },
    };
}

export async function getExceptionDate(start: Date, end: Date): Promise<ExceptionOperationsDate[]> {
  const args = {
        start: start.toISOString().split("T")[0],
        end: end.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getExceptionDate", args});
    return ret.map((e: any) => ({
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        operations: {
            ted: e.operations.ted === null || e.operations.ted === undefined ? null : {
                start: e.operations.ted.start === null || e.operations.ted.start === undefined ? null : e.operations.ted.start,
                end: e.operations.ted.end === null || e.operations.ted.end === undefined ? null : e.operations.ted.end,
            },
            doc: e.operations.doc === null || e.operations.doc === undefined ? null : {
                start: e.operations.doc.start === null || e.operations.doc.start === undefined ? null : e.operations.doc.start,
                end: e.operations.doc.end === null || e.operations.doc.end === undefined ? null : e.operations.doc.end,
            },
            internalTransfer: e.operations.internalTransfer === null || e.operations.internalTransfer === undefined ? null : {
                start: e.operations.internalTransfer.start === null || e.operations.internalTransfer.start === undefined ? null : e.operations.internalTransfer.start,
                end: e.operations.internalTransfer.end === null || e.operations.internalTransfer.end === undefined ? null : e.operations.internalTransfer.end,
            },
            investment: e.operations.investment === null || e.operations.investment === undefined ? null : {
                start: e.operations.investment.start === null || e.operations.investment.start === undefined ? null : e.operations.investment.start,
                end: e.operations.investment.end === null || e.operations.investment.end === undefined ? null : e.operations.investment.end,
            },
            titleInclusion: e.operations.titleInclusion === null || e.operations.titleInclusion === undefined ? null : {
                start: e.operations.titleInclusion.start === null || e.operations.titleInclusion.start === undefined ? null : e.operations.titleInclusion.start,
                end: e.operations.titleInclusion.end === null || e.operations.titleInclusion.end === undefined ? null : e.operations.titleInclusion.end,
            },
            withdraw: e.operations.withdraw === null || e.operations.withdraw === undefined ? null : {
                start: e.operations.withdraw.start === null || e.operations.withdraw.start === undefined ? null : e.operations.withdraw.start,
                end: e.operations.withdraw.end === null || e.operations.withdraw.end === undefined ? null : e.operations.withdraw.end,
            },
            paymentBankSlip: e.operations.paymentBankSlip === null || e.operations.paymentBankSlip === undefined ? null : {
                start: e.operations.paymentBankSlip.start === null || e.operations.paymentBankSlip.start === undefined ? null : e.operations.paymentBankSlip.start,
                end: e.operations.paymentBankSlip.end === null || e.operations.paymentBankSlip.end === undefined ? null : e.operations.paymentBankSlip.end,
            },
            paymentAgreement: e.operations.paymentAgreement === null || e.operations.paymentAgreement === undefined ? null : {
                start: e.operations.paymentAgreement.start === null || e.operations.paymentAgreement.start === undefined ? null : e.operations.paymentAgreement.start,
                end: e.operations.paymentAgreement.end === null || e.operations.paymentAgreement.end === undefined ? null : e.operations.paymentAgreement.end,
            },
            recharge: e.operations.recharge === null || e.operations.recharge === undefined ? null : {
                start: e.operations.recharge.start === null || e.operations.recharge.start === undefined ? null : e.operations.recharge.start,
                end: e.operations.recharge.end === null || e.operations.recharge.end === undefined ? null : e.operations.recharge.end,
            },
        },
        obs: e.obs,
        isAutomatic: !!e.isAutomatic,
    }));
}

export async function getServices(): Promise<Service[]> {
  const ret = await makeRequest({name: "getServices", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function getServicesV13(): Promise<ServiceV13[]> {
  const ret = await makeRequest({name: "getServicesV13", args: {}});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function getOperations(): Promise<OperationsDays> {
  const ret = await makeRequest({name: "getOperations", args: {}});
    return {
        days: ret.days.map((e: any) => e),
        operations: {
            ted: ret.operations.ted === null || ret.operations.ted === undefined ? null : {
                start: ret.operations.ted.start === null || ret.operations.ted.start === undefined ? null : ret.operations.ted.start,
                end: ret.operations.ted.end === null || ret.operations.ted.end === undefined ? null : ret.operations.ted.end,
            },
            doc: ret.operations.doc === null || ret.operations.doc === undefined ? null : {
                start: ret.operations.doc.start === null || ret.operations.doc.start === undefined ? null : ret.operations.doc.start,
                end: ret.operations.doc.end === null || ret.operations.doc.end === undefined ? null : ret.operations.doc.end,
            },
            internalTransfer: ret.operations.internalTransfer === null || ret.operations.internalTransfer === undefined ? null : {
                start: ret.operations.internalTransfer.start === null || ret.operations.internalTransfer.start === undefined ? null : ret.operations.internalTransfer.start,
                end: ret.operations.internalTransfer.end === null || ret.operations.internalTransfer.end === undefined ? null : ret.operations.internalTransfer.end,
            },
            investment: ret.operations.investment === null || ret.operations.investment === undefined ? null : {
                start: ret.operations.investment.start === null || ret.operations.investment.start === undefined ? null : ret.operations.investment.start,
                end: ret.operations.investment.end === null || ret.operations.investment.end === undefined ? null : ret.operations.investment.end,
            },
            titleInclusion: ret.operations.titleInclusion === null || ret.operations.titleInclusion === undefined ? null : {
                start: ret.operations.titleInclusion.start === null || ret.operations.titleInclusion.start === undefined ? null : ret.operations.titleInclusion.start,
                end: ret.operations.titleInclusion.end === null || ret.operations.titleInclusion.end === undefined ? null : ret.operations.titleInclusion.end,
            },
            withdraw: ret.operations.withdraw === null || ret.operations.withdraw === undefined ? null : {
                start: ret.operations.withdraw.start === null || ret.operations.withdraw.start === undefined ? null : ret.operations.withdraw.start,
                end: ret.operations.withdraw.end === null || ret.operations.withdraw.end === undefined ? null : ret.operations.withdraw.end,
            },
            paymentBankSlip: ret.operations.paymentBankSlip === null || ret.operations.paymentBankSlip === undefined ? null : {
                start: ret.operations.paymentBankSlip.start === null || ret.operations.paymentBankSlip.start === undefined ? null : ret.operations.paymentBankSlip.start,
                end: ret.operations.paymentBankSlip.end === null || ret.operations.paymentBankSlip.end === undefined ? null : ret.operations.paymentBankSlip.end,
            },
            paymentAgreement: ret.operations.paymentAgreement === null || ret.operations.paymentAgreement === undefined ? null : {
                start: ret.operations.paymentAgreement.start === null || ret.operations.paymentAgreement.start === undefined ? null : ret.operations.paymentAgreement.start,
                end: ret.operations.paymentAgreement.end === null || ret.operations.paymentAgreement.end === undefined ? null : ret.operations.paymentAgreement.end,
            },
            recharge: ret.operations.recharge === null || ret.operations.recharge === undefined ? null : {
                start: ret.operations.recharge.start === null || ret.operations.recharge.start === undefined ? null : ret.operations.recharge.start,
                end: ret.operations.recharge.end === null || ret.operations.recharge.end === undefined ? null : ret.operations.recharge.end,
            },
        },
    };
}

export async function setOperations(operations: OperationsDays): Promise<OperationsDays> {
  const args = {
        operations: {
            days: operations.days.map(e => e),
            operations: {
                ted: operations.operations.ted === null || operations.operations.ted === undefined ? null : {
                    start: operations.operations.ted.start === null || operations.operations.ted.start === undefined ? null : operations.operations.ted.start,
                    end: operations.operations.ted.end === null || operations.operations.ted.end === undefined ? null : operations.operations.ted.end,
                },
                doc: operations.operations.doc === null || operations.operations.doc === undefined ? null : {
                    start: operations.operations.doc.start === null || operations.operations.doc.start === undefined ? null : operations.operations.doc.start,
                    end: operations.operations.doc.end === null || operations.operations.doc.end === undefined ? null : operations.operations.doc.end,
                },
                internalTransfer: operations.operations.internalTransfer === null || operations.operations.internalTransfer === undefined ? null : {
                    start: operations.operations.internalTransfer.start === null || operations.operations.internalTransfer.start === undefined ? null : operations.operations.internalTransfer.start,
                    end: operations.operations.internalTransfer.end === null || operations.operations.internalTransfer.end === undefined ? null : operations.operations.internalTransfer.end,
                },
                investment: operations.operations.investment === null || operations.operations.investment === undefined ? null : {
                    start: operations.operations.investment.start === null || operations.operations.investment.start === undefined ? null : operations.operations.investment.start,
                    end: operations.operations.investment.end === null || operations.operations.investment.end === undefined ? null : operations.operations.investment.end,
                },
                titleInclusion: operations.operations.titleInclusion === null || operations.operations.titleInclusion === undefined ? null : {
                    start: operations.operations.titleInclusion.start === null || operations.operations.titleInclusion.start === undefined ? null : operations.operations.titleInclusion.start,
                    end: operations.operations.titleInclusion.end === null || operations.operations.titleInclusion.end === undefined ? null : operations.operations.titleInclusion.end,
                },
                withdraw: operations.operations.withdraw === null || operations.operations.withdraw === undefined ? null : {
                    start: operations.operations.withdraw.start === null || operations.operations.withdraw.start === undefined ? null : operations.operations.withdraw.start,
                    end: operations.operations.withdraw.end === null || operations.operations.withdraw.end === undefined ? null : operations.operations.withdraw.end,
                },
                paymentBankSlip: operations.operations.paymentBankSlip === null || operations.operations.paymentBankSlip === undefined ? null : {
                    start: operations.operations.paymentBankSlip.start === null || operations.operations.paymentBankSlip.start === undefined ? null : operations.operations.paymentBankSlip.start,
                    end: operations.operations.paymentBankSlip.end === null || operations.operations.paymentBankSlip.end === undefined ? null : operations.operations.paymentBankSlip.end,
                },
                paymentAgreement: operations.operations.paymentAgreement === null || operations.operations.paymentAgreement === undefined ? null : {
                    start: operations.operations.paymentAgreement.start === null || operations.operations.paymentAgreement.start === undefined ? null : operations.operations.paymentAgreement.start,
                    end: operations.operations.paymentAgreement.end === null || operations.operations.paymentAgreement.end === undefined ? null : operations.operations.paymentAgreement.end,
                },
                recharge: operations.operations.recharge === null || operations.operations.recharge === undefined ? null : {
                    start: operations.operations.recharge.start === null || operations.operations.recharge.start === undefined ? null : operations.operations.recharge.start,
                    end: operations.operations.recharge.end === null || operations.operations.recharge.end === undefined ? null : operations.operations.recharge.end,
                },
            },
        },
  };
  const ret = await makeRequest({name: "setOperations", args});
    return {
        days: ret.days.map((e: any) => e),
        operations: {
            ted: ret.operations.ted === null || ret.operations.ted === undefined ? null : {
                start: ret.operations.ted.start === null || ret.operations.ted.start === undefined ? null : ret.operations.ted.start,
                end: ret.operations.ted.end === null || ret.operations.ted.end === undefined ? null : ret.operations.ted.end,
            },
            doc: ret.operations.doc === null || ret.operations.doc === undefined ? null : {
                start: ret.operations.doc.start === null || ret.operations.doc.start === undefined ? null : ret.operations.doc.start,
                end: ret.operations.doc.end === null || ret.operations.doc.end === undefined ? null : ret.operations.doc.end,
            },
            internalTransfer: ret.operations.internalTransfer === null || ret.operations.internalTransfer === undefined ? null : {
                start: ret.operations.internalTransfer.start === null || ret.operations.internalTransfer.start === undefined ? null : ret.operations.internalTransfer.start,
                end: ret.operations.internalTransfer.end === null || ret.operations.internalTransfer.end === undefined ? null : ret.operations.internalTransfer.end,
            },
            investment: ret.operations.investment === null || ret.operations.investment === undefined ? null : {
                start: ret.operations.investment.start === null || ret.operations.investment.start === undefined ? null : ret.operations.investment.start,
                end: ret.operations.investment.end === null || ret.operations.investment.end === undefined ? null : ret.operations.investment.end,
            },
            titleInclusion: ret.operations.titleInclusion === null || ret.operations.titleInclusion === undefined ? null : {
                start: ret.operations.titleInclusion.start === null || ret.operations.titleInclusion.start === undefined ? null : ret.operations.titleInclusion.start,
                end: ret.operations.titleInclusion.end === null || ret.operations.titleInclusion.end === undefined ? null : ret.operations.titleInclusion.end,
            },
            withdraw: ret.operations.withdraw === null || ret.operations.withdraw === undefined ? null : {
                start: ret.operations.withdraw.start === null || ret.operations.withdraw.start === undefined ? null : ret.operations.withdraw.start,
                end: ret.operations.withdraw.end === null || ret.operations.withdraw.end === undefined ? null : ret.operations.withdraw.end,
            },
            paymentBankSlip: ret.operations.paymentBankSlip === null || ret.operations.paymentBankSlip === undefined ? null : {
                start: ret.operations.paymentBankSlip.start === null || ret.operations.paymentBankSlip.start === undefined ? null : ret.operations.paymentBankSlip.start,
                end: ret.operations.paymentBankSlip.end === null || ret.operations.paymentBankSlip.end === undefined ? null : ret.operations.paymentBankSlip.end,
            },
            paymentAgreement: ret.operations.paymentAgreement === null || ret.operations.paymentAgreement === undefined ? null : {
                start: ret.operations.paymentAgreement.start === null || ret.operations.paymentAgreement.start === undefined ? null : ret.operations.paymentAgreement.start,
                end: ret.operations.paymentAgreement.end === null || ret.operations.paymentAgreement.end === undefined ? null : ret.operations.paymentAgreement.end,
            },
            recharge: ret.operations.recharge === null || ret.operations.recharge === undefined ? null : {
                start: ret.operations.recharge.start === null || ret.operations.recharge.start === undefined ? null : ret.operations.recharge.start,
                end: ret.operations.recharge.end === null || ret.operations.recharge.end === undefined ? null : ret.operations.recharge.end,
            },
        },
    };
}

export async function setExceptionDate(operations: ExceptionOperationsDate): Promise<ExceptionOperationsDate> {
  const args = {
        operations: {
            date: operations.date.toISOString().split("T")[0],
            operations: {
                ted: operations.operations.ted === null || operations.operations.ted === undefined ? null : {
                    start: operations.operations.ted.start === null || operations.operations.ted.start === undefined ? null : operations.operations.ted.start,
                    end: operations.operations.ted.end === null || operations.operations.ted.end === undefined ? null : operations.operations.ted.end,
                },
                doc: operations.operations.doc === null || operations.operations.doc === undefined ? null : {
                    start: operations.operations.doc.start === null || operations.operations.doc.start === undefined ? null : operations.operations.doc.start,
                    end: operations.operations.doc.end === null || operations.operations.doc.end === undefined ? null : operations.operations.doc.end,
                },
                internalTransfer: operations.operations.internalTransfer === null || operations.operations.internalTransfer === undefined ? null : {
                    start: operations.operations.internalTransfer.start === null || operations.operations.internalTransfer.start === undefined ? null : operations.operations.internalTransfer.start,
                    end: operations.operations.internalTransfer.end === null || operations.operations.internalTransfer.end === undefined ? null : operations.operations.internalTransfer.end,
                },
                investment: operations.operations.investment === null || operations.operations.investment === undefined ? null : {
                    start: operations.operations.investment.start === null || operations.operations.investment.start === undefined ? null : operations.operations.investment.start,
                    end: operations.operations.investment.end === null || operations.operations.investment.end === undefined ? null : operations.operations.investment.end,
                },
                titleInclusion: operations.operations.titleInclusion === null || operations.operations.titleInclusion === undefined ? null : {
                    start: operations.operations.titleInclusion.start === null || operations.operations.titleInclusion.start === undefined ? null : operations.operations.titleInclusion.start,
                    end: operations.operations.titleInclusion.end === null || operations.operations.titleInclusion.end === undefined ? null : operations.operations.titleInclusion.end,
                },
                withdraw: operations.operations.withdraw === null || operations.operations.withdraw === undefined ? null : {
                    start: operations.operations.withdraw.start === null || operations.operations.withdraw.start === undefined ? null : operations.operations.withdraw.start,
                    end: operations.operations.withdraw.end === null || operations.operations.withdraw.end === undefined ? null : operations.operations.withdraw.end,
                },
                paymentBankSlip: operations.operations.paymentBankSlip === null || operations.operations.paymentBankSlip === undefined ? null : {
                    start: operations.operations.paymentBankSlip.start === null || operations.operations.paymentBankSlip.start === undefined ? null : operations.operations.paymentBankSlip.start,
                    end: operations.operations.paymentBankSlip.end === null || operations.operations.paymentBankSlip.end === undefined ? null : operations.operations.paymentBankSlip.end,
                },
                paymentAgreement: operations.operations.paymentAgreement === null || operations.operations.paymentAgreement === undefined ? null : {
                    start: operations.operations.paymentAgreement.start === null || operations.operations.paymentAgreement.start === undefined ? null : operations.operations.paymentAgreement.start,
                    end: operations.operations.paymentAgreement.end === null || operations.operations.paymentAgreement.end === undefined ? null : operations.operations.paymentAgreement.end,
                },
                recharge: operations.operations.recharge === null || operations.operations.recharge === undefined ? null : {
                    start: operations.operations.recharge.start === null || operations.operations.recharge.start === undefined ? null : operations.operations.recharge.start,
                    end: operations.operations.recharge.end === null || operations.operations.recharge.end === undefined ? null : operations.operations.recharge.end,
                },
            },
            obs: operations.obs,
            isAutomatic: !!operations.isAutomatic,
        },
  };
  const ret = await makeRequest({name: "setExceptionDate", args});
    return {
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
        operations: {
            ted: ret.operations.ted === null || ret.operations.ted === undefined ? null : {
                start: ret.operations.ted.start === null || ret.operations.ted.start === undefined ? null : ret.operations.ted.start,
                end: ret.operations.ted.end === null || ret.operations.ted.end === undefined ? null : ret.operations.ted.end,
            },
            doc: ret.operations.doc === null || ret.operations.doc === undefined ? null : {
                start: ret.operations.doc.start === null || ret.operations.doc.start === undefined ? null : ret.operations.doc.start,
                end: ret.operations.doc.end === null || ret.operations.doc.end === undefined ? null : ret.operations.doc.end,
            },
            internalTransfer: ret.operations.internalTransfer === null || ret.operations.internalTransfer === undefined ? null : {
                start: ret.operations.internalTransfer.start === null || ret.operations.internalTransfer.start === undefined ? null : ret.operations.internalTransfer.start,
                end: ret.operations.internalTransfer.end === null || ret.operations.internalTransfer.end === undefined ? null : ret.operations.internalTransfer.end,
            },
            investment: ret.operations.investment === null || ret.operations.investment === undefined ? null : {
                start: ret.operations.investment.start === null || ret.operations.investment.start === undefined ? null : ret.operations.investment.start,
                end: ret.operations.investment.end === null || ret.operations.investment.end === undefined ? null : ret.operations.investment.end,
            },
            titleInclusion: ret.operations.titleInclusion === null || ret.operations.titleInclusion === undefined ? null : {
                start: ret.operations.titleInclusion.start === null || ret.operations.titleInclusion.start === undefined ? null : ret.operations.titleInclusion.start,
                end: ret.operations.titleInclusion.end === null || ret.operations.titleInclusion.end === undefined ? null : ret.operations.titleInclusion.end,
            },
            withdraw: ret.operations.withdraw === null || ret.operations.withdraw === undefined ? null : {
                start: ret.operations.withdraw.start === null || ret.operations.withdraw.start === undefined ? null : ret.operations.withdraw.start,
                end: ret.operations.withdraw.end === null || ret.operations.withdraw.end === undefined ? null : ret.operations.withdraw.end,
            },
            paymentBankSlip: ret.operations.paymentBankSlip === null || ret.operations.paymentBankSlip === undefined ? null : {
                start: ret.operations.paymentBankSlip.start === null || ret.operations.paymentBankSlip.start === undefined ? null : ret.operations.paymentBankSlip.start,
                end: ret.operations.paymentBankSlip.end === null || ret.operations.paymentBankSlip.end === undefined ? null : ret.operations.paymentBankSlip.end,
            },
            paymentAgreement: ret.operations.paymentAgreement === null || ret.operations.paymentAgreement === undefined ? null : {
                start: ret.operations.paymentAgreement.start === null || ret.operations.paymentAgreement.start === undefined ? null : ret.operations.paymentAgreement.start,
                end: ret.operations.paymentAgreement.end === null || ret.operations.paymentAgreement.end === undefined ? null : ret.operations.paymentAgreement.end,
            },
            recharge: ret.operations.recharge === null || ret.operations.recharge === undefined ? null : {
                start: ret.operations.recharge.start === null || ret.operations.recharge.start === undefined ? null : ret.operations.recharge.start,
                end: ret.operations.recharge.end === null || ret.operations.recharge.end === undefined ? null : ret.operations.recharge.end,
            },
        },
        obs: ret.obs,
        isAutomatic: !!ret.isAutomatic,
    };
}

export async function deleteExceptionDay(date: Date): Promise<boolean> {
  const args = {
        date: date.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "deleteExceptionDay", args});
    return !!ret;
}

export async function manageServices(services: Service[]): Promise<Service[]> {
  const args = {
        services: services.map(e => ({
            key: e.key,
            blocked: !!e.blocked,
            value: e.value,
        })),
  };
  const ret = await makeRequest({name: "manageServices", args});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function manageServicesV13(services: ServiceV13[]): Promise<ServiceV13[]> {
  const args = {
        services: services.map(e => ({
            key: e.key,
            blocked: !!e.blocked,
            value: e.value,
        })),
  };
  const ret = await makeRequest({name: "manageServicesV13", args});
    return ret.map((e: any) => ({
        key: e.key,
        blocked: !!e.blocked,
        value: e.value,
    }));
}

export async function sendLinkToRecovery(cpf: string, actionRecovery: ActionRecoverUser): Promise<string> {
  const args = {
        cpf: cpf,
        actionRecovery: actionRecovery,
  };
  const ret = await makeRequest({name: "sendLinkToRecovery", args});
    return ret;
}

export async function sendLinkToRecoveryV13(userId: string, actionRecovery: ActionRecoverUser): Promise<string> {
  const args = {
        userId: userId,
        actionRecovery: actionRecovery,
  };
  const ret = await makeRequest({name: "sendLinkToRecoveryV13", args});
    return ret;
}

export async function recoveryByLink(cpf: string, cnpj: string | null, isMei: boolean, actionRecovery: ActionRecoverUser): Promise<string> {
  const args = {
        cpf: cpf,
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
        isMei: !!isMei,
        actionRecovery: actionRecovery,
  };
  const ret = await makeRequest({name: "recoveryByLink", args});
    return ret;
}

export async function resetByRecoveryToken(tokenOnLink: string, oldPassword: string | null, password: string | null): Promise<void> {
  const args = {
        tokenOnLink: tokenOnLink,
        oldPassword: oldPassword === null || oldPassword === undefined ? null : oldPassword,
        password: password === null || password === undefined ? null : password,
  };
  await makeRequest({name: "resetByRecoveryToken", args});
    return undefined;
}

export async function verifyRecoveryToken(tokenOnLink: string): Promise<RecoverUser> {
  const args = {
        tokenOnLink: tokenOnLink,
  };
  const ret = await makeRequest({name: "verifyRecoveryToken", args});
    return {
        action: ret.action,
        name: ret.name,
        documentNumber: ret.documentNumber,
        partner: ret.partner === null || ret.partner === undefined ? null : {
            urlApp: ret.partner.urlApp === null || ret.partner.urlApp === undefined ? null : {
                android: ret.partner.urlApp.android,
                ios: ret.partner.urlApp.ios,
            },
            tokenUrlApp: ret.partner.tokenUrlApp === null || ret.partner.tokenUrlApp === undefined ? null : {
                android: ret.partner.tokenUrlApp.android,
                ios: ret.partner.tokenUrlApp.ios,
            },
            blocked: !!ret.partner.blocked,
            managerId: ret.partner.managerId,
            publicKey: ret.partner.publicKey,
            partnerSecret: ret.partner.partnerSecret,
            partnerUserName: ret.partner.partnerUserName === null || ret.partner.partnerUserName === undefined ? null : ret.partner.partnerUserName,
            allowAnyPartnerLogin: !!ret.partner.allowAnyPartnerLogin,
            openAccountsAttempts: ret.partner.openAccountsAttempts | 0,
            id: ret.partner.id,
            name: ret.partner.name,
            logo: ret.partner.logo,
            backgroundColor: ret.partner.backgroundColor === null || ret.partner.backgroundColor === undefined ? null : ret.partner.backgroundColor,
            secondaryColor: ret.partner.secondaryColor === null || ret.partner.secondaryColor === undefined ? null : ret.partner.secondaryColor,
            primaryColor: ret.partner.primaryColor === null || ret.partner.primaryColor === undefined ? null : ret.partner.primaryColor,
            font: ret.partner.font === null || ret.partner.font === undefined ? null : ret.partner.font,
            fontHref: ret.partner.fontHref === null || ret.partner.fontHref === undefined ? null : ret.partner.fontHref,
            errorColor: ret.partner.errorColor === null || ret.partner.errorColor === undefined ? null : ret.partner.errorColor,
            phone: ret.partner.phone === null || ret.partner.phone === undefined ? null : ret.partner.phone,
            email: ret.partner.email === null || ret.partner.email === undefined ? null : ret.partner.email,
            site: ret.partner.site === null || ret.partner.site === undefined ? null : ret.partner.site,
            urlTerm: ret.partner.urlTerm === null || ret.partner.urlTerm === undefined ? null : ret.partner.urlTerm,
            urlContract: ret.partner.urlContract === null || ret.partner.urlContract === undefined ? null : ret.partner.urlContract,
            urlTermPj: ret.partner.urlTermPj === null || ret.partner.urlTermPj === undefined ? null : ret.partner.urlTermPj,
            urlContractPj: ret.partner.urlContractPj === null || ret.partner.urlContractPj === undefined ? null : ret.partner.urlContractPj,
            urlFarePackages: ret.partner.urlFarePackages === null || ret.partner.urlFarePackages === undefined ? null : ret.partner.urlFarePackages,
            urlIB: ret.partner.urlIB,
            partnerHook: ret.partner.partnerHook === null || ret.partner.partnerHook === undefined ? null : {
                url: ret.partner.partnerHook.url,
                token: ret.partner.partnerHook.token,
            },
            cobrander: ret.partner.cobrander === null || ret.partner.cobrander === undefined ? null : {
                codeCobrander: ret.partner.cobrander.codeCobrander,
                codeCardCategory: ret.partner.cobrander.codeCardCategory,
                autoEmitCard: !!ret.partner.cobrander.autoEmitCard,
                isActivate: !!ret.partner.cobrander.isActivate,
                username: ret.partner.cobrander.username === null || ret.partner.cobrander.username === undefined ? null : ret.partner.cobrander.username,
                password: ret.partner.cobrander.password === null || ret.partner.cobrander.password === undefined ? null : ret.partner.cobrander.password,
            },
            hibotlink: ret.partner.hibotlink === null || ret.partner.hibotlink === undefined ? null : ret.partner.hibotlink,
            hibotmobilelink: ret.partner.hibotmobilelink === null || ret.partner.hibotmobilelink === undefined ? null : ret.partner.hibotmobilelink,
            cnpj: ret.partner.cnpj === null || ret.partner.cnpj === undefined ? null : ret.partner.cnpj,
            webhookUrl: ret.partner.webhookUrl === null || ret.partner.webhookUrl === undefined ? null : ret.partner.webhookUrl,
            emailResendTime: ret.partner.emailResendTime | 0,
            emailResendTimeFrequency: ret.partner.emailResendTimeFrequency | 0,
            emailFrom: ret.partner.emailFrom === null || ret.partner.emailFrom === undefined ? null : ret.partner.emailFrom,
            accountOpeningAttemptLimit: ret.partner.accountOpeningAttemptLimit === null || ret.partner.accountOpeningAttemptLimit === undefined ? null : ret.partner.accountOpeningAttemptLimit | 0,
            managerIdLot: ret.partner.managerIdLot === null || ret.partner.managerIdLot === undefined ? null : ret.partner.managerIdLot,
            allowPartnerLot: !!ret.partner.allowPartnerLot,
            allowReduceJourneyWithoutLot: !!ret.partner.allowReduceJourneyWithoutLot,
            showWiz: ret.partner.showWiz === null || ret.partner.showWiz === undefined ? null : !!ret.partner.showWiz,
        },
    };
}

export async function getProfiles(cnpj: string): Promise<Profile[]> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "getProfiles", args});
    return ret.map((e: any) => ({
        id: e.id === null || e.id === undefined ? null : e.id,
        name: e.name,
        companyId: e.companyId,
        permissions: e.permissions.map((e: any) => ({
            permissionType: e.permissionType,
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
            isSpecial: !!e.isSpecial,
        })),
    }));
}

export async function getUsersByCompany(cnpj: string): Promise<UserCompany[]> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "getUsersByCompany", args});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        cpf: e.cpf,
        blocked: e.blocked === null || e.blocked === undefined ? null : !!e.blocked,
        accounts: e.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    }));
}

export async function getRepresentativesByCompany(cnpj: string, partnerId: string): Promise<RepresentativeInfo[]> {
  const args = {
        cnpj: cnpj,
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getRepresentativesByCompany", args});
    return ret.map((e: any) => ({
        name: e.name,
        cpf: e.cpf,
        status: e.status,
        hasPendingInfo: !!e.hasPendingInfo,
    }));
}

export async function verifyUser(cpf: string, cnpj: string): Promise<UserCompany | null> {
  const args = {
        cpf: cpf,
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "verifyUser", args});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        accounts: ret.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    };
}

export async function setPermission(userId: string, branch: string, account: string, maxAmountDaily: number, profile: Profile, saveProfile: boolean): Promise<UserCompany> {
  const args = {
        userId: userId,
        branch: branch,
        account: account,
        maxAmountDaily: maxAmountDaily,
        profile: {
            id: profile.id === null || profile.id === undefined ? null : profile.id,
            name: profile.name,
            companyId: profile.companyId,
            permissions: profile.permissions.map(e => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
        },
        saveProfile: !!saveProfile,
  };
  const ret = await makeRequest({name: "setPermission", args});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        accounts: ret.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    };
}

export async function createUserInCompany(name: string, email: string, phone: string, cpf: string, cnpj: string): Promise<UserCompany> {
  const args = {
        name: name,
        email: email,
        phone: phone,
        cpf: cpf,
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "createUserInCompany", args});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        cpf: ret.cpf,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        accounts: ret.accounts.map((e: any) => ({
            profile: {
                id: e.profile.id === null || e.profile.id === undefined ? null : e.profile.id,
                name: e.profile.name,
                companyId: e.profile.companyId,
                permissions: e.profile.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
            },
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
    };
}

export async function removeUserInCompany(cpf: string, cnpj: string, removeInAll: boolean): Promise<boolean> {
  const args = {
        cpf: cpf,
        cnpj: cnpj,
        removeInAll: !!removeInAll,
  };
  const ret = await makeRequest({name: "removeUserInCompany", args});
    return !!ret;
}

export async function createProfile(profile: Profile): Promise<Profile> {
  const args = {
        profile: {
            id: profile.id === null || profile.id === undefined ? null : profile.id,
            name: profile.name,
            companyId: profile.companyId,
            permissions: profile.permissions.map(e => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
        },
  };
  const ret = await makeRequest({name: "createProfile", args});
    return {
        id: ret.id === null || ret.id === undefined ? null : ret.id,
        name: ret.name,
        companyId: ret.companyId,
        permissions: ret.permissions.map((e: any) => ({
            permissionType: e.permissionType,
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
            isSpecial: !!e.isSpecial,
        })),
    };
}

export async function filterAccounts(query: string, page: number, limit: number): Promise<Account[]> {
  const args = {
        query: query,
        page: page | 0,
        limit: limit | 0,
  };
  const ret = await makeRequest({name: "filterAccounts", args});
    return ret.map((e: any) => ({
        name: e.name,
        type: e.type,
        documentNumber: e.documentNumber,
        permissions: e.permissions.map((e: any) => ({
            permissionType: e.permissionType,
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
            isSpecial: !!e.isSpecial,
        })),
        maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
        yourNumber: e.yourNumber | 0,
        subType: e.subType === null || e.subType === undefined ? null : e.subType,
        bank: {
            code: e.bank.code,
            name: e.bank.name,
        },
        branch: e.branch,
        account: e.account,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getDocumentsCompanyDownloadLink(cnpj: string): Promise<string> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "getDocumentsCompanyDownloadLink", args});
    return ret;
}

export async function generatePartnerUser(partnerId: string): Promise<PartnerUser> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "generatePartnerUser", args});
    return {
        username: ret.username,
        password: ret.password,
    };
}

export async function createTedHugPay(): Promise<CreateTransfer> {
  const ret = await makeRequest({name: "createTedHugPay", args: {}});
    return {
        status: ret.status,
        number: ret.number,
        type: ret.type,
        entryStatement: ret.entryStatement === null || ret.entryStatement === undefined ? null : {
            id: ret.entryStatement.id,
            movementNumber: ret.entryStatement.movementNumber,
            type: ret.entryStatement.type,
            description: ret.entryStatement.description,
            finality: ret.entryStatement.finality,
            nature: ret.entryStatement.nature,
            prevBalance: ret.entryStatement.prevBalance,
            currentBalance: ret.entryStatement.currentBalance,
            movementDate: new Date(ret.entryStatement.movementDate + "Z"),
            amount: ret.entryStatement.amount,
            part: {
                name: ret.entryStatement.part.name,
                cpfCnpj: ret.entryStatement.part.cpfCnpj === null || ret.entryStatement.part.cpfCnpj === undefined ? null : ret.entryStatement.part.cpfCnpj,
                branch: ret.entryStatement.part.branch,
                bank: ret.entryStatement.part.bank === null || ret.entryStatement.part.bank === undefined ? null : ret.entryStatement.part.bank,
                account: ret.entryStatement.part.account === null || ret.entryStatement.part.account === undefined ? null : ret.entryStatement.part.account,
            },
            counterPart: ret.entryStatement.counterPart === null || ret.entryStatement.counterPart === undefined ? null : {
                name: ret.entryStatement.counterPart.name,
                cpfCnpj: ret.entryStatement.counterPart.cpfCnpj === null || ret.entryStatement.counterPart.cpfCnpj === undefined ? null : ret.entryStatement.counterPart.cpfCnpj,
                branch: ret.entryStatement.counterPart.branch,
                bank: ret.entryStatement.counterPart.bank === null || ret.entryStatement.counterPart.bank === undefined ? null : ret.entryStatement.counterPart.bank,
                account: ret.entryStatement.counterPart.account === null || ret.entryStatement.counterPart.account === undefined ? null : ret.entryStatement.counterPart.account,
            },
            devolutionReason: ret.entryStatement.devolutionReason === null || ret.entryStatement.devolutionReason === undefined ? null : ret.entryStatement.devolutionReason,
            hasReceipt: !!ret.entryStatement.hasReceipt,
        },
    };
}

export async function approveSimpleAccount(branch: string, account: string, documentNumber: string): Promise<void> {
  const args = {
        branch: branch,
        account: account,
        documentNumber: documentNumber,
  };
  await makeRequest({name: "approveSimpleAccount", args});
    return undefined;
}

export async function approveFullAccount(branch: string, account: string, documentNumber: string): Promise<void> {
  const args = {
        branch: branch,
        account: account,
        documentNumber: documentNumber,
  };
  await makeRequest({name: "approveFullAccount", args});
    return undefined;
}

export async function setBlockStatusAccount(status: BlockStatusAccount, documentNumber: string): Promise<void> {
  const args = {
        status: status,
        documentNumber: documentNumber,
  };
  await makeRequest({name: "setBlockStatusAccount", args});
    return undefined;
}

export async function getCPFList(): Promise<CPFList[]> {
  const ret = await makeRequest({name: "getCPFList", args: {}});
    return ret.map((e: any) => ({
        cpf: e.cpf,
        attempts: e.attempts | 0,
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function deleteCPFList(cpf: string): Promise<void> {
  const args = {
        cpf: cpf,
  };
  await makeRequest({name: "deleteCPFList", args});
    return undefined;
}

export async function addCPFList(cpf: string): Promise<void> {
  const args = {
        cpf: cpf,
  };
  await makeRequest({name: "addCPFList", args});
    return undefined;
}

export async function downloadCPFList(): Promise<Buffer> {
  const ret = await makeRequest({name: "downloadCPFList", args: {}});
    return Buffer.from(ret, "base64");
}

export async function getCardsInQueuePaginated(partnerId: string | null, limit: number, offset: number): Promise<CardQueuePaginated> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        limit: limit | 0,
        offset: offset | 0,
  };
  const ret = await makeRequest({name: "getCardsInQueuePaginated", args});
    return {
        result: ret.result.map((e: any) => ({
            id: e.id,
            partnerId: e.partnerId,
            attempts: e.attempts | 0,
            status: e.status,
            note: e.note,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            user: {
                id: e.user.id,
                name: e.user.name,
                cpfCnpj: e.user.cpfCnpj,
            },
        })),
        limit: ret.limit | 0,
        offset: ret.offset | 0,
        total: ret.total | 0,
    };
}

export async function resetVisaCardQueueStatus(partnerId: string, queueItemId: string): Promise<void> {
  const args = {
        partnerId: partnerId,
        queueItemId: queueItemId,
  };
  await makeRequest({name: "resetVisaCardQueueStatus", args});
    return undefined;
}

export async function removeVisaCardFromQueue(partnerId: string, queueItemId: string): Promise<void> {
  const args = {
        partnerId: partnerId,
        queueItemId: queueItemId,
  };
  await makeRequest({name: "removeVisaCardFromQueue", args});
    return undefined;
}

export async function getAdminUser(): Promise<AdminUser> {
  const ret = await makeRequest({name: "getAdminUser", args: {}});
    return {
        id: ret.id === null || ret.id === undefined ? null : ret.id,
        cpf: ret.cpf,
        name: ret.name,
        email: ret.email,
        password: ret.password === null || ret.password === undefined ? null : ret.password,
        blocked: !!ret.blocked,
        adminUserPermissions: ret.adminUserPermissions.map((e: any) => e),
    };
}

export async function getAdminUsers(): Promise<AdminUser[]> {
  const ret = await makeRequest({name: "getAdminUsers", args: {}});
    return ret.map((e: any) => ({
        id: e.id === null || e.id === undefined ? null : e.id,
        cpf: e.cpf,
        name: e.name,
        email: e.email,
        password: e.password === null || e.password === undefined ? null : e.password,
        blocked: !!e.blocked,
        adminUserPermissions: e.adminUserPermissions.map((e: any) => e),
    }));
}

export async function deleteAdminUser(adminUserId: string): Promise<boolean> {
  const args = {
        adminUserId: adminUserId,
  };
  const ret = await makeRequest({name: "deleteAdminUser", args});
    return !!ret;
}

export async function setAdminUser(adminUser: AdminUser): Promise<AdminUser> {
  const args = {
        adminUser: {
            id: adminUser.id === null || adminUser.id === undefined ? null : adminUser.id,
            cpf: adminUser.cpf,
            name: adminUser.name,
            email: adminUser.email,
            password: adminUser.password === null || adminUser.password === undefined ? null : adminUser.password,
            blocked: !!adminUser.blocked,
            adminUserPermissions: adminUser.adminUserPermissions.map(e => e),
        },
  };
  const ret = await makeRequest({name: "setAdminUser", args});
    return {
        id: ret.id === null || ret.id === undefined ? null : ret.id,
        cpf: ret.cpf,
        name: ret.name,
        email: ret.email,
        password: ret.password === null || ret.password === undefined ? null : ret.password,
        blocked: !!ret.blocked,
        adminUserPermissions: ret.adminUserPermissions.map((e: any) => e),
    };
}

export async function adminLogin(cpf: string, password: string): Promise<AdminUser> {
  const args = {
        cpf: cpf,
        password: password,
  };
  const ret = await makeRequest({name: "adminLogin", args});
    return {
        id: ret.id === null || ret.id === undefined ? null : ret.id,
        cpf: ret.cpf,
        name: ret.name,
        email: ret.email,
        password: ret.password === null || ret.password === undefined ? null : ret.password,
        blocked: !!ret.blocked,
        adminUserPermissions: ret.adminUserPermissions.map((e: any) => e),
    };
}

export async function adminLogout(): Promise<void> {
  await makeRequest({name: "adminLogout", args: {}});
    return undefined;
}

export async function adminRenewSession(): Promise<number> {
  const ret = await makeRequest({name: "adminRenewSession", args: {}});
    return ret;
}

export async function changeAdminPassword(lastPassword: string, newPassword: string, newPasswordConfirm: string): Promise<boolean> {
  const args = {
        lastPassword: lastPassword,
        newPassword: newPassword,
        newPasswordConfirm: newPasswordConfirm,
  };
  const ret = await makeRequest({name: "changeAdminPassword", args});
    return !!ret;
}

export async function getDefaultLimitDTO(partnerId: string | null, type: PfPj): Promise<DefaultLimitsFormated[]> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        type: type,
  };
  const ret = await makeRequest({name: "getDefaultLimitDTO", args});
    return ret.map((e: any) => ({
        title: e.title,
        operation: e.operation,
        daily: e.daily,
        amountPerDay: e.amountPerDay,
        isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
    }));
}

export async function updateDefaultLimitDTO(partnerId: string | null, parameters: LimitsDefaultDTO): Promise<RespApiTransactional> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        parameters: {
            accountType: parameters.accountType,
            observation: parameters.observation === null || parameters.observation === undefined ? null : parameters.observation,
            transactionRequestDto: parameters.transactionRequestDto.map(e => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
        },
  };
  const ret = await makeRequest({name: "updateDefaultLimitDTO", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function getConfigurationSecurity(partnerId: string | null): Promise<Security> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getConfigurationSecurity", args});
    return {
        pf: ret.pf.map((e: any) => ({
            title: e.title,
            operation: e.operation,
            daily: e.daily,
            amountPerDay: e.amountPerDay,
            isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
        })),
        pj: ret.pj.map((e: any) => ({
            title: e.title,
            operation: e.operation,
            daily: e.daily,
            amountPerDay: e.amountPerDay,
            isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
        })),
        observation: ret.observation === null || ret.observation === undefined ? null : ret.observation,
    };
}

export async function getConfigurationSecurityStatus(partnerId: string | null): Promise<SecurityStatus> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getConfigurationSecurityStatus", args});
    return {
        pf: {
            tedThird: !!ret.pf.tedThird,
            internalTransfer: !!ret.pf.internalTransfer,
            concessionaire: !!ret.pf.concessionaire,
            autoBankSlip: !!ret.pf.autoBankSlip,
            bankSlip: !!ret.pf.bankSlip,
        },
        pj: {
            tedThird: !!ret.pj.tedThird,
            internalTransfer: !!ret.pj.internalTransfer,
            concessionaire: !!ret.pj.concessionaire,
            autoBankSlip: !!ret.pj.autoBankSlip,
            bankSlip: !!ret.pj.bankSlip,
        },
        observation: ret.observation === null || ret.observation === undefined ? null : ret.observation,
    };
}

export async function updateSecurityStatus(partnerId: string | null, securityStatus: SecurityStatus): Promise<RespApiTransactional> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        securityStatus: {
            pf: {
                tedThird: !!securityStatus.pf.tedThird,
                internalTransfer: !!securityStatus.pf.internalTransfer,
                concessionaire: !!securityStatus.pf.concessionaire,
                autoBankSlip: !!securityStatus.pf.autoBankSlip,
                bankSlip: !!securityStatus.pf.bankSlip,
            },
            pj: {
                tedThird: !!securityStatus.pj.tedThird,
                internalTransfer: !!securityStatus.pj.internalTransfer,
                concessionaire: !!securityStatus.pj.concessionaire,
                autoBankSlip: !!securityStatus.pj.autoBankSlip,
                bankSlip: !!securityStatus.pj.bankSlip,
            },
            observation: securityStatus.observation === null || securityStatus.observation === undefined ? null : securityStatus.observation,
        },
  };
  const ret = await makeRequest({name: "updateSecurityStatus", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function updateSecurity(partnerId: string | null, security: Security, pfPj: PfPj): Promise<RespApiTransactional> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        security: {
            pf: security.pf.map(e => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
            pj: security.pj.map(e => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
            observation: security.observation === null || security.observation === undefined ? null : security.observation,
        },
        pfPj: pfPj,
  };
  const ret = await makeRequest({name: "updateSecurity", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function getSegmentTransactional(partnerId: string | null, type: PfPj | null, segmentName: string | null, pagination: LimitsPagination): Promise<SegmentReturn> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        type: type === null || type === undefined ? null : type,
        segmentName: segmentName === null || segmentName === undefined ? null : segmentName,
        pagination: {
            page: pagination.page | 0,
            offset: pagination.offset | 0,
            limit: pagination.limit | 0,
        },
  };
  const ret = await makeRequest({name: "getSegmentTransactional", args});
    return {
        segments: ret.segments.map((e: any) => ({
            id: e.id === null || e.id === undefined ? null : e.id | 0,
            partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
            name: e.name,
            accountType: e.accountType,
            minIncome: e.minIncome,
            maxIncome: e.maxIncome,
            observation: e.observation === null || e.observation === undefined ? null : e.observation,
        })),
        count: ret.count | 0,
    };
}

export async function getSegmentTransactionalById(id: string): Promise<Segment> {
  const args = {
        id: id,
  };
  const ret = await makeRequest({name: "getSegmentTransactionalById", args});
    return {
        id: ret.id === null || ret.id === undefined ? null : ret.id | 0,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        name: ret.name,
        accountType: ret.accountType,
        minIncome: ret.minIncome,
        maxIncome: ret.maxIncome,
        observation: ret.observation === null || ret.observation === undefined ? null : ret.observation,
    };
}

export async function deleteSegmentTransactional(id: string, observation: string | null): Promise<RespApiTransactional> {
  const args = {
        id: id,
        observation: observation === null || observation === undefined ? null : observation,
  };
  const ret = await makeRequest({name: "deleteSegmentTransactional", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function createSegmentTransactional(segment: Segment): Promise<RespApiTransactional> {
  const args = {
        segment: {
            id: segment.id === null || segment.id === undefined ? null : segment.id | 0,
            partnerId: segment.partnerId === null || segment.partnerId === undefined ? null : segment.partnerId,
            name: segment.name,
            accountType: segment.accountType,
            minIncome: segment.minIncome,
            maxIncome: segment.maxIncome,
            observation: segment.observation === null || segment.observation === undefined ? null : segment.observation,
        },
  };
  const ret = await makeRequest({name: "createSegmentTransactional", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function updateSegmentTransactional(segment: Segment): Promise<RespApiTransactional> {
  const args = {
        segment: {
            id: segment.id === null || segment.id === undefined ? null : segment.id | 0,
            partnerId: segment.partnerId === null || segment.partnerId === undefined ? null : segment.partnerId,
            name: segment.name,
            accountType: segment.accountType,
            minIncome: segment.minIncome,
            maxIncome: segment.maxIncome,
            observation: segment.observation === null || segment.observation === undefined ? null : segment.observation,
        },
  };
  const ret = await makeRequest({name: "updateSegmentTransactional", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function getSegmentLimitTransactional(partnerId: string | null, filter: PfPj | null, segmentName: string | null, pagination: LimitsPagination): Promise<SegmentLimitReturn> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        filter: filter === null || filter === undefined ? null : filter,
        segmentName: segmentName === null || segmentName === undefined ? null : segmentName,
        pagination: {
            page: pagination.page | 0,
            offset: pagination.offset | 0,
            limit: pagination.limit | 0,
        },
  };
  const ret = await makeRequest({name: "getSegmentLimitTransactional", args});
    return {
        segmentsLimit: ret.segmentsLimit.map((e: any) => ({
            segment: {
                id: e.segment.id === null || e.segment.id === undefined ? null : e.segment.id | 0,
                partnerId: e.segment.partnerId === null || e.segment.partnerId === undefined ? null : e.segment.partnerId,
                name: e.segment.name,
                accountType: e.segment.accountType,
                minIncome: e.segment.minIncome,
                maxIncome: e.segment.maxIncome,
                observation: e.segment.observation === null || e.segment.observation === undefined ? null : e.segment.observation,
            },
            parametersLimitsID: e.parametersLimitsID === null || e.parametersLimitsID === undefined ? null : e.parametersLimitsID | 0,
            name: e.name,
            transactionRequestDto: e.transactionRequestDto.map((e: any) => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
        })),
        count: ret.count | 0,
    };
}

export async function createSegmentLimitTransactional(segmentLimit: SegmentLimit): Promise<RespApiTransactional> {
  const args = {
        segmentLimit: {
            segment: {
                id: segmentLimit.segment.id === null || segmentLimit.segment.id === undefined ? null : segmentLimit.segment.id | 0,
                partnerId: segmentLimit.segment.partnerId === null || segmentLimit.segment.partnerId === undefined ? null : segmentLimit.segment.partnerId,
                name: segmentLimit.segment.name,
                accountType: segmentLimit.segment.accountType,
                minIncome: segmentLimit.segment.minIncome,
                maxIncome: segmentLimit.segment.maxIncome,
                observation: segmentLimit.segment.observation === null || segmentLimit.segment.observation === undefined ? null : segmentLimit.segment.observation,
            },
            parametersLimitsID: segmentLimit.parametersLimitsID === null || segmentLimit.parametersLimitsID === undefined ? null : segmentLimit.parametersLimitsID | 0,
            name: segmentLimit.name,
            transactionRequestDto: segmentLimit.transactionRequestDto.map(e => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
        },
  };
  const ret = await makeRequest({name: "createSegmentLimitTransactional", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function deleteSegmentLimitTransactional(id: string, observation: string | null): Promise<RespApiTransactional> {
  const args = {
        id: id,
        observation: observation === null || observation === undefined ? null : observation,
  };
  const ret = await makeRequest({name: "deleteSegmentLimitTransactional", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function updateSegmentLimitTransactional(segmentLimit: SegmentLimit): Promise<RespApiTransactional> {
  const args = {
        segmentLimit: {
            segment: {
                id: segmentLimit.segment.id === null || segmentLimit.segment.id === undefined ? null : segmentLimit.segment.id | 0,
                partnerId: segmentLimit.segment.partnerId === null || segmentLimit.segment.partnerId === undefined ? null : segmentLimit.segment.partnerId,
                name: segmentLimit.segment.name,
                accountType: segmentLimit.segment.accountType,
                minIncome: segmentLimit.segment.minIncome,
                maxIncome: segmentLimit.segment.maxIncome,
                observation: segmentLimit.segment.observation === null || segmentLimit.segment.observation === undefined ? null : segmentLimit.segment.observation,
            },
            parametersLimitsID: segmentLimit.parametersLimitsID === null || segmentLimit.parametersLimitsID === undefined ? null : segmentLimit.parametersLimitsID | 0,
            name: segmentLimit.name,
            transactionRequestDto: segmentLimit.transactionRequestDto.map(e => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
        },
  };
  const ret = await makeRequest({name: "updateSegmentLimitTransactional", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function filterSegmentLimitTransactional(): Promise<SegmentLimitCreate> {
  const ret = await makeRequest({name: "filterSegmentLimitTransactional", args: {}});
    return {
        segment: ret.segment === null || ret.segment === undefined ? null : ret.segment.map((e: any) => ({
            id: e.id === null || e.id === undefined ? null : e.id | 0,
            partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
            name: e.name,
            accountType: e.accountType,
            minIncome: e.minIncome,
            maxIncome: e.maxIncome,
            observation: e.observation === null || e.observation === undefined ? null : e.observation,
        })),
        operation: ret.operation.map((e: any) => ({
            title: e.title,
            operation: e.operation,
            daily: e.daily,
            amountPerDay: e.amountPerDay,
            isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
        })),
    };
}

export async function getClientLimit(partnerId: string | null, filter: string | null, pagination: LimitsPagination): Promise<ClientLimitReturn> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        filter: filter === null || filter === undefined ? null : filter,
        pagination: {
            page: pagination.page | 0,
            offset: pagination.offset | 0,
            limit: pagination.limit | 0,
        },
  };
  const ret = await makeRequest({name: "getClientLimit", args});
    return {
        clientLimit: ret.clientLimit.map((e: any) => ({
            name: e.name === null || e.name === undefined ? null : e.name,
            account: e.account === null || e.account === undefined ? null : e.account,
            cpfCnpj: e.cpfCnpj,
            type: e.type === null || e.type === undefined ? null : e.type,
            initialDate: new Date(parseInt(e.initialDate.split("-")[0], 10), parseInt(e.initialDate.split("-")[1], 10) - 1, parseInt(e.initialDate.split("-")[2], 10)),
            finalDate: e.finalDate === null || e.finalDate === undefined ? null : new Date(parseInt(e.finalDate.split("-")[0], 10), parseInt(e.finalDate.split("-")[1], 10) - 1, parseInt(e.finalDate.split("-")[2], 10)),
            ticket: e.ticket,
            transactionRequestDto: e.transactionRequestDto === null || e.transactionRequestDto === undefined ? null : e.transactionRequestDto.map((e: any) => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
            observation: e.observation === null || e.observation === undefined ? null : e.observation,
        })),
        count: ret.count | 0,
    };
}

export async function getClientLimitByCpfCnpj(cpfCnpj: string, registered: boolean): Promise<ClientLimit> {
  const args = {
        cpfCnpj: cpfCnpj,
        registered: !!registered,
  };
  const ret = await makeRequest({name: "getClientLimitByCpfCnpj", args});
    return {
        name: ret.name === null || ret.name === undefined ? null : ret.name,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        cpfCnpj: ret.cpfCnpj,
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        initialDate: new Date(parseInt(ret.initialDate.split("-")[0], 10), parseInt(ret.initialDate.split("-")[1], 10) - 1, parseInt(ret.initialDate.split("-")[2], 10)),
        finalDate: ret.finalDate === null || ret.finalDate === undefined ? null : new Date(parseInt(ret.finalDate.split("-")[0], 10), parseInt(ret.finalDate.split("-")[1], 10) - 1, parseInt(ret.finalDate.split("-")[2], 10)),
        ticket: ret.ticket,
        transactionRequestDto: ret.transactionRequestDto === null || ret.transactionRequestDto === undefined ? null : ret.transactionRequestDto.map((e: any) => ({
            title: e.title,
            operation: e.operation,
            daily: e.daily,
            amountPerDay: e.amountPerDay,
            isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
        })),
        observation: ret.observation === null || ret.observation === undefined ? null : ret.observation,
    };
}

export async function createClientLimit(clientLimit: ClientLimit): Promise<RespApiTransactional> {
  const args = {
        clientLimit: {
            name: clientLimit.name === null || clientLimit.name === undefined ? null : clientLimit.name,
            account: clientLimit.account === null || clientLimit.account === undefined ? null : clientLimit.account,
            cpfCnpj: clientLimit.cpfCnpj,
            type: clientLimit.type === null || clientLimit.type === undefined ? null : clientLimit.type,
            initialDate: clientLimit.initialDate.toISOString().split("T")[0],
            finalDate: clientLimit.finalDate === null || clientLimit.finalDate === undefined ? null : clientLimit.finalDate.toISOString().split("T")[0],
            ticket: clientLimit.ticket,
            transactionRequestDto: clientLimit.transactionRequestDto === null || clientLimit.transactionRequestDto === undefined ? null : clientLimit.transactionRequestDto.map(e => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
            observation: clientLimit.observation === null || clientLimit.observation === undefined ? null : clientLimit.observation,
        },
  };
  const ret = await makeRequest({name: "createClientLimit", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function updateClientLimit(clientLimit: ClientLimit): Promise<RespApiTransactional> {
  const args = {
        clientLimit: {
            name: clientLimit.name === null || clientLimit.name === undefined ? null : clientLimit.name,
            account: clientLimit.account === null || clientLimit.account === undefined ? null : clientLimit.account,
            cpfCnpj: clientLimit.cpfCnpj,
            type: clientLimit.type === null || clientLimit.type === undefined ? null : clientLimit.type,
            initialDate: clientLimit.initialDate.toISOString().split("T")[0],
            finalDate: clientLimit.finalDate === null || clientLimit.finalDate === undefined ? null : clientLimit.finalDate.toISOString().split("T")[0],
            ticket: clientLimit.ticket,
            transactionRequestDto: clientLimit.transactionRequestDto === null || clientLimit.transactionRequestDto === undefined ? null : clientLimit.transactionRequestDto.map(e => ({
                title: e.title,
                operation: e.operation,
                daily: e.daily,
                amountPerDay: e.amountPerDay,
                isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
            })),
            observation: clientLimit.observation === null || clientLimit.observation === undefined ? null : clientLimit.observation,
        },
  };
  const ret = await makeRequest({name: "updateClientLimit", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function deleteClientLimit(cpfCnpj: string, observation: string | null): Promise<RespApiTransactional> {
  const args = {
        cpfCnpj: cpfCnpj,
        observation: observation === null || observation === undefined ? null : observation,
  };
  const ret = await makeRequest({name: "deleteClientLimit", args});
    return {
        statusCode: ret.statusCode | 0,
        message: ret.message,
    };
}

export async function getLogsLimit(partnerId: string | null, email: string | null, startDate: Date | null, endDate: Date | null, pagination: LimitsPagination): Promise<LogsLimitReturn> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
        email: email === null || email === undefined ? null : email,
        startDate: startDate === null || startDate === undefined ? null : startDate.toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : endDate.toISOString().split("T")[0],
        pagination: {
            page: pagination.page | 0,
            offset: pagination.offset | 0,
            limit: pagination.limit | 0,
        },
  };
  const ret = await makeRequest({name: "getLogsLimit", args});
    return {
        logsLimits: ret.logsLimits.map((e: any) => ({
            id: e.id | 0,
            cpfCnpj: e.cpfCnpj === null || e.cpfCnpj === undefined ? null : e.cpfCnpj,
            ticket: e.ticket === null || e.ticket === undefined ? null : e.ticket,
            name: e.name === null || e.name === undefined ? null : e.name,
            date: new Date(e.date + "Z"),
            description: e.description.map((e: any) => e),
            user: e.user,
            observation: e.observation === null || e.observation === undefined ? null : e.observation,
        })),
        count: ret.count | 0,
    };
}

export async function getLogsLimitByClient(cpfCnpj: string | null, startDate: Date | null, endDate: Date | null, pagination: LimitsPagination): Promise<LogsLimitReturn> {
  const args = {
        cpfCnpj: cpfCnpj === null || cpfCnpj === undefined ? null : cpfCnpj,
        startDate: startDate === null || startDate === undefined ? null : startDate.toISOString().split("T")[0],
        endDate: endDate === null || endDate === undefined ? null : endDate.toISOString().split("T")[0],
        pagination: {
            page: pagination.page | 0,
            offset: pagination.offset | 0,
            limit: pagination.limit | 0,
        },
  };
  const ret = await makeRequest({name: "getLogsLimitByClient", args});
    return {
        logsLimits: ret.logsLimits.map((e: any) => ({
            id: e.id | 0,
            cpfCnpj: e.cpfCnpj === null || e.cpfCnpj === undefined ? null : e.cpfCnpj,
            ticket: e.ticket === null || e.ticket === undefined ? null : e.ticket,
            name: e.name === null || e.name === undefined ? null : e.name,
            date: new Date(e.date + "Z"),
            description: e.description.map((e: any) => e),
            user: e.user,
            observation: e.observation === null || e.observation === undefined ? null : e.observation,
        })),
        count: ret.count | 0,
    };
}

export async function formatTrasactionalOperations(): Promise<DefaultLimitsFormated[]> {
  const ret = await makeRequest({name: "formatTrasactionalOperations", args: {}});
    return ret.map((e: any) => ({
        title: e.title,
        operation: e.operation,
        daily: e.daily,
        amountPerDay: e.amountPerDay,
        isActive: e.isActive === null || e.isActive === undefined ? null : !!e.isActive,
    }));
}

export async function generate6Pass(userId: string): Promise<string> {
  const args = {
        userId: userId,
  };
  const ret = await makeRequest({name: "generate6Pass", args});
    return ret;
}

export async function resetUser4Pass(userId: string, password: string): Promise<void> {
  const args = {
        userId: userId,
        password: password,
  };
  await makeRequest({name: "resetUser4Pass", args});
    return undefined;
}

export async function resetUser6Pass(userId: string, password: string): Promise<void> {
  const args = {
        userId: userId,
        password: password,
  };
  await makeRequest({name: "resetUser6Pass", args});
    return undefined;
}

export async function getAccountsByStatus(filter: ManagerAccountFilter, status: UserStatus | null, statusCompliance: ComplianceStatus | null, simpleAccountSituation: BlockStatusAccount | null): Promise<ManagerAccountReturn> {
  const args = {
        filter: {
            query: filter.query,
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            approvation: filter.approvation === null || filter.approvation === undefined ? null : filter.approvation,
            limit: filter.limit === null || filter.limit === undefined ? null : filter.limit | 0,
            offset: filter.offset | 0,
        },
        status: status === null || status === undefined ? null : status,
        statusCompliance: statusCompliance === null || statusCompliance === undefined ? null : statusCompliance,
        simpleAccountSituation: simpleAccountSituation === null || simpleAccountSituation === undefined ? null : simpleAccountSituation,
  };
  const ret = await makeRequest({name: "getAccountsByStatus", args});
    return {
        count: ret.count | 0,
        data: ret.data.map((e: any) => ({
            id: e.id,
            cpfCnpj: e.cpfCnpj,
            name: e.name,
            accountType: e.accountType,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            categoryAccount: e.categoryAccount,
            status: e.status === null || e.status === undefined ? null : e.status,
            statusCompliance: e.statusCompliance === null || e.statusCompliance === undefined ? null : e.statusCompliance,
            value: e.value === null || e.value === undefined ? null : e.value,
            blockStatus: e.blockStatus === null || e.blockStatus === undefined ? null : e.blockStatus,
            fepweb: !!e.fepweb,
            journeyType: e.journeyType === null || e.journeyType === undefined ? null : e.journeyType,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
        })),
    };
}

export async function getUserFieldsByCpfAndStatus(cpf: string, status: UserStatus): Promise<RegisterUserFields | null> {
  const args = {
        cpf: cpf,
        status: status,
  };
  const ret = await makeRequest({name: "getUserFieldsByCpfAndStatus", args});
    return ret === null || ret === undefined ? null : {
        workflowId: ret.workflowId,
        name: ret.name,
        cpf: ret.cpf,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        accountType: ret.accountType,
        partner: {
            id: ret.partner.id,
            name: ret.partner.name,
        },
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        complianceStatus: ret.complianceStatus,
        status: ret.status === null || ret.status === undefined ? null : ret.status,
        fieldGroups: ret.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: ret.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
        documents: ret.documents.map((e: any) => ({
            name: e.name,
            url: e.url.normalize(),
        })),
        pendingFields: ret.pendingFields === null || ret.pendingFields === undefined ? null : ret.pendingFields.map((e: any) => e),
        obs: ret.obs,
        blockStatus: ret.blockStatus === null || ret.blockStatus === undefined ? null : ret.blockStatus,
        subType: ret.subType === null || ret.subType === undefined ? null : ret.subType,
        journeyType: ret.journeyType === null || ret.journeyType === undefined ? null : ret.journeyType,
    };
}

export async function getUserFieldsByIdAndStatus(id: string, status: UserStatus): Promise<RegisterUserFields | null> {
  const args = {
        id: id,
        status: status,
  };
  const ret = await makeRequest({name: "getUserFieldsByIdAndStatus", args});
    return ret === null || ret === undefined ? null : {
        workflowId: ret.workflowId,
        name: ret.name,
        cpf: ret.cpf,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        accountType: ret.accountType,
        partner: {
            id: ret.partner.id,
            name: ret.partner.name,
        },
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        complianceStatus: ret.complianceStatus,
        status: ret.status === null || ret.status === undefined ? null : ret.status,
        fieldGroups: ret.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: ret.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
        documents: ret.documents.map((e: any) => ({
            name: e.name,
            url: e.url.normalize(),
        })),
        pendingFields: ret.pendingFields === null || ret.pendingFields === undefined ? null : ret.pendingFields.map((e: any) => e),
        obs: ret.obs,
        blockStatus: ret.blockStatus === null || ret.blockStatus === undefined ? null : ret.blockStatus,
        subType: ret.subType === null || ret.subType === undefined ? null : ret.subType,
        journeyType: ret.journeyType === null || ret.journeyType === undefined ? null : ret.journeyType,
    };
}

export async function getUserFieldsById(id: string): Promise<RegisterUserFields | null> {
  const args = {
        id: id,
  };
  const ret = await makeRequest({name: "getUserFieldsById", args});
    return ret === null || ret === undefined ? null : {
        workflowId: ret.workflowId,
        name: ret.name,
        cpf: ret.cpf,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        accountType: ret.accountType,
        partner: {
            id: ret.partner.id,
            name: ret.partner.name,
        },
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        complianceStatus: ret.complianceStatus,
        status: ret.status === null || ret.status === undefined ? null : ret.status,
        fieldGroups: ret.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: ret.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
        documents: ret.documents.map((e: any) => ({
            name: e.name,
            url: e.url.normalize(),
        })),
        pendingFields: ret.pendingFields === null || ret.pendingFields === undefined ? null : ret.pendingFields.map((e: any) => e),
        obs: ret.obs,
        blockStatus: ret.blockStatus === null || ret.blockStatus === undefined ? null : ret.blockStatus,
        subType: ret.subType === null || ret.subType === undefined ? null : ret.subType,
        journeyType: ret.journeyType === null || ret.journeyType === undefined ? null : ret.journeyType,
    };
}

export async function getUserByCpf(cpf: string): Promise<RegisterUser | null> {
  const args = {
        cpf: cpf,
  };
  const ret = await makeRequest({name: "getUserByCpf", args});
    return ret === null || ret === undefined ? null : {
        userId: ret.userId,
        cpf: ret.cpf,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        name: ret.name,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        birthDate: ret.birthDate === null || ret.birthDate === undefined ? null : new Date(parseInt(ret.birthDate.split("-")[0], 10), parseInt(ret.birthDate.split("-")[1], 10) - 1, parseInt(ret.birthDate.split("-")[2], 10)),
        document: ret.document === null || ret.document === undefined ? null : {
            type: ret.document.type,
            number: ret.document.number,
            dispatcher: ret.document.dispatcher,
            state: ret.document.state,
        },
        gender: ret.gender === null || ret.gender === undefined ? null : ret.gender,
        filiacao1: ret.filiacao1 === null || ret.filiacao1 === undefined ? null : ret.filiacao1,
        filiacao2: ret.filiacao2 === null || ret.filiacao2 === undefined ? null : ret.filiacao2,
        nacionalidade: ret.nacionalidade === null || ret.nacionalidade === undefined ? null : ret.nacionalidade,
        createdAt: new Date(ret.createdAt + "Z"),
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            number: ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
        },
        comercialInfo: ret.comercialInfo === null || ret.comercialInfo === undefined ? null : {
            address: ret.comercialInfo.address === null || ret.comercialInfo.address === undefined ? null : {
                cep: ret.comercialInfo.address.cep === null || ret.comercialInfo.address.cep === undefined ? null : ret.comercialInfo.address.cep,
                street: ret.comercialInfo.address.street === null || ret.comercialInfo.address.street === undefined ? null : ret.comercialInfo.address.street,
                neighborhood: ret.comercialInfo.address.neighborhood === null || ret.comercialInfo.address.neighborhood === undefined ? null : ret.comercialInfo.address.neighborhood,
                number: ret.comercialInfo.address.number === null || ret.comercialInfo.address.number === undefined ? null : ret.comercialInfo.address.number,
                complement: ret.comercialInfo.address.complement === null || ret.comercialInfo.address.complement === undefined ? null : ret.comercialInfo.address.complement,
                city: ret.comercialInfo.address.city === null || ret.comercialInfo.address.city === undefined ? null : ret.comercialInfo.address.city,
                state: ret.comercialInfo.address.state === null || ret.comercialInfo.address.state === undefined ? null : ret.comercialInfo.address.state,
                country: ret.comercialInfo.address.country === null || ret.comercialInfo.address.country === undefined ? null : ret.comercialInfo.address.country,
            },
            cnpj: ret.comercialInfo.cnpj,
            phone: ret.comercialInfo.phone === null || ret.comercialInfo.phone === undefined ? null : ret.comercialInfo.phone,
        },
        socialName: ret.socialName === null || ret.socialName === undefined ? null : ret.socialName,
        accountInfo: ret.accountInfo === null || ret.accountInfo === undefined ? null : {
            accountNumber: ret.accountInfo.accountNumber,
            branch: ret.accountInfo.branch,
            bank: {
                code: ret.accountInfo.bank.code,
                name: ret.accountInfo.bank.name,
            },
        },
        occupation: ret.occupation === null || ret.occupation === undefined ? null : ret.occupation,
        monthlyIncome: ret.monthlyIncome === null || ret.monthlyIncome === undefined ? null : ret.monthlyIncome,
        patrimony: ret.patrimony === null || ret.patrimony === undefined ? null : ret.patrimony,
        civilStatus: ret.civilStatus === null || ret.civilStatus === undefined ? null : ret.civilStatus,
        categoryAccount: ret.categoryAccount === null || ret.categoryAccount === undefined ? null : ret.categoryAccount,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        partnerName: ret.partnerName === null || ret.partnerName === undefined ? null : ret.partnerName,
        complianceStatus: ret.complianceStatus,
        status: ret.status,
        naturalness: ret.naturalness === null || ret.naturalness === undefined ? null : ret.naturalness,
        spouseName: ret.spouseName === null || ret.spouseName === undefined ? null : ret.spouseName,
        weddingType: ret.weddingType === null || ret.weddingType === undefined ? null : ret.weddingType,
        tokenNotification: ret.tokenNotification === null || ret.tokenNotification === undefined ? null : ret.tokenNotification,
        isTerrorist: !!ret.isTerrorist,
        isMEI: !!ret.isMEI,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        codeSMS: ret.codeSMS === null || ret.codeSMS === undefined ? null : ret.codeSMS,
        companyName: ret.companyName === null || ret.companyName === undefined ? null : ret.companyName,
    };
}

export async function getMEIuserByCnpj(cnpj: string, status: UserStatus): Promise<RegisterUser | null> {
  const args = {
        cnpj: cnpj,
        status: status,
  };
  const ret = await makeRequest({name: "getMEIuserByCnpj", args});
    return ret === null || ret === undefined ? null : {
        userId: ret.userId,
        cpf: ret.cpf,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        name: ret.name,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        birthDate: ret.birthDate === null || ret.birthDate === undefined ? null : new Date(parseInt(ret.birthDate.split("-")[0], 10), parseInt(ret.birthDate.split("-")[1], 10) - 1, parseInt(ret.birthDate.split("-")[2], 10)),
        document: ret.document === null || ret.document === undefined ? null : {
            type: ret.document.type,
            number: ret.document.number,
            dispatcher: ret.document.dispatcher,
            state: ret.document.state,
        },
        gender: ret.gender === null || ret.gender === undefined ? null : ret.gender,
        filiacao1: ret.filiacao1 === null || ret.filiacao1 === undefined ? null : ret.filiacao1,
        filiacao2: ret.filiacao2 === null || ret.filiacao2 === undefined ? null : ret.filiacao2,
        nacionalidade: ret.nacionalidade === null || ret.nacionalidade === undefined ? null : ret.nacionalidade,
        createdAt: new Date(ret.createdAt + "Z"),
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep,
            street: ret.address.street,
            neighborhood: ret.address.neighborhood,
            number: ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
        },
        comercialInfo: ret.comercialInfo === null || ret.comercialInfo === undefined ? null : {
            address: ret.comercialInfo.address === null || ret.comercialInfo.address === undefined ? null : {
                cep: ret.comercialInfo.address.cep === null || ret.comercialInfo.address.cep === undefined ? null : ret.comercialInfo.address.cep,
                street: ret.comercialInfo.address.street === null || ret.comercialInfo.address.street === undefined ? null : ret.comercialInfo.address.street,
                neighborhood: ret.comercialInfo.address.neighborhood === null || ret.comercialInfo.address.neighborhood === undefined ? null : ret.comercialInfo.address.neighborhood,
                number: ret.comercialInfo.address.number === null || ret.comercialInfo.address.number === undefined ? null : ret.comercialInfo.address.number,
                complement: ret.comercialInfo.address.complement === null || ret.comercialInfo.address.complement === undefined ? null : ret.comercialInfo.address.complement,
                city: ret.comercialInfo.address.city === null || ret.comercialInfo.address.city === undefined ? null : ret.comercialInfo.address.city,
                state: ret.comercialInfo.address.state === null || ret.comercialInfo.address.state === undefined ? null : ret.comercialInfo.address.state,
                country: ret.comercialInfo.address.country === null || ret.comercialInfo.address.country === undefined ? null : ret.comercialInfo.address.country,
            },
            cnpj: ret.comercialInfo.cnpj,
            phone: ret.comercialInfo.phone === null || ret.comercialInfo.phone === undefined ? null : ret.comercialInfo.phone,
        },
        socialName: ret.socialName === null || ret.socialName === undefined ? null : ret.socialName,
        accountInfo: ret.accountInfo === null || ret.accountInfo === undefined ? null : {
            accountNumber: ret.accountInfo.accountNumber,
            branch: ret.accountInfo.branch,
            bank: {
                code: ret.accountInfo.bank.code,
                name: ret.accountInfo.bank.name,
            },
        },
        occupation: ret.occupation === null || ret.occupation === undefined ? null : ret.occupation,
        monthlyIncome: ret.monthlyIncome === null || ret.monthlyIncome === undefined ? null : ret.monthlyIncome,
        patrimony: ret.patrimony === null || ret.patrimony === undefined ? null : ret.patrimony,
        civilStatus: ret.civilStatus === null || ret.civilStatus === undefined ? null : ret.civilStatus,
        categoryAccount: ret.categoryAccount === null || ret.categoryAccount === undefined ? null : ret.categoryAccount,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        partnerName: ret.partnerName === null || ret.partnerName === undefined ? null : ret.partnerName,
        complianceStatus: ret.complianceStatus,
        status: ret.status,
        naturalness: ret.naturalness === null || ret.naturalness === undefined ? null : ret.naturalness,
        spouseName: ret.spouseName === null || ret.spouseName === undefined ? null : ret.spouseName,
        weddingType: ret.weddingType === null || ret.weddingType === undefined ? null : ret.weddingType,
        tokenNotification: ret.tokenNotification === null || ret.tokenNotification === undefined ? null : ret.tokenNotification,
        isTerrorist: !!ret.isTerrorist,
        isMEI: !!ret.isMEI,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        codeSMS: ret.codeSMS === null || ret.codeSMS === undefined ? null : ret.codeSMS,
        companyName: ret.companyName === null || ret.companyName === undefined ? null : ret.companyName,
    };
}

export async function getUserByCpfAndCnpj(cpf: string, cnpj: string | null, isMEI: boolean): Promise<User> {
  const args = {
        cpf: cpf,
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
        isMEI: !!isMEI,
  };
  const ret = await makeRequest({name: "getUserByCpfAndCnpj", args});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        cpf: ret.cpf,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
        profilePhoto: ret.profilePhoto === null || ret.profilePhoto === undefined ? null : ret.profilePhoto,
        status: ret.status,
        publicKey: ret.publicKey === null || ret.publicKey === undefined ? null : ret.publicKey,
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        companies: ret.companies.map((e: any) => ({
            accounts: e.accounts.map((e: any) => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: new Date(e.createdAt + "Z"),
            })),
            id: e.id,
            name: e.name,
            documentNumber: e.documentNumber,
            email: e.email,
            phone: e.phone,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            status: e.status,
            isMEI: !!e.isMEI,
            fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
            foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : new Date(parseInt(e.foundationDate.split("-")[0], 10), parseInt(e.foundationDate.split("-")[1], 10) - 1, parseInt(e.foundationDate.split("-")[2], 10)),
            foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
            socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
            mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
            },
        })),
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        socialName: ret.socialName === null || ret.socialName === undefined ? null : ret.socialName,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        isMEI: !!ret.isMEI,
        showCard: ret.showCard === null || ret.showCard === undefined ? null : !!ret.showCard,
        help: ret.help === null || ret.help === undefined ? null : ret.help,
        mobileHelp: ret.mobileHelp === null || ret.mobileHelp === undefined ? null : ret.mobileHelp,
        pendingDocument: ret.pendingDocument === null || ret.pendingDocument === undefined ? null : !!ret.pendingDocument,
    };
}

export async function getFilteredAccounts(filter: FilterFields, managerFilter: ManagerReportFilter): Promise<ReportFilter> {
  const args = {
        filter: {
            idPartner: filter.idPartner === null || filter.idPartner === undefined ? null : filter.idPartner.map(e => e),
            startRegisterDate: filter.startRegisterDate === null || filter.startRegisterDate === undefined ? null : (typeof filter.startRegisterDate === "string" && (filter.startRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.startRegisterDate as any).replace("Z", "") : filter.startRegisterDate.toISOString().replace("Z", "")),
            endRegisterDate: filter.endRegisterDate === null || filter.endRegisterDate === undefined ? null : (typeof filter.endRegisterDate === "string" && (filter.endRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.endRegisterDate as any).replace("Z", "") : filter.endRegisterDate.toISOString().replace("Z", "")),
            typePerson: filter.typePerson === null || filter.typePerson === undefined ? null : filter.typePerson,
            accountType: filter.accountType === null || filter.accountType === undefined ? null : filter.accountType.map(e => e),
            status: filter.status === null || filter.status === undefined ? null : filter.status.map(e => e),
            gender: filter.gender === null || filter.gender === undefined ? null : filter.gender,
            region: filter.region === null || filter.region === undefined ? null : filter.region.map(e => e),
            startAge: filter.startAge === null || filter.startAge === undefined ? null : filter.startAge | 0,
            endAge: filter.endAge === null || filter.endAge === undefined ? null : filter.endAge | 0,
            approvation: filter.approvation === null || filter.approvation === undefined ? null : filter.approvation,
            closedAt: filter.closedAt === null || filter.closedAt === undefined ? null : (typeof filter.closedAt === "string" && (filter.closedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.closedAt as any).replace("Z", "") : filter.closedAt.toISOString().replace("Z", "")),
            closingReason: filter.closingReason === null || filter.closingReason === undefined ? null : filter.closingReason,
            subType: filter.subType === null || filter.subType === undefined ? null : filter.subType,
            journeyType: filter.journeyType === null || filter.journeyType === undefined ? null : filter.journeyType,
            accountStatus: filter.accountStatus === null || filter.accountStatus === undefined ? null : !!filter.accountStatus,
        },
        managerFilter: {
            limit: managerFilter.limit | 0,
            offset: managerFilter.offset | 0,
        },
  };
  const ret = await makeRequest({name: "getFilteredAccounts", args});
    return {
        users: ret.users.map((e: any) => ({
            id: e.id,
            name: e.name,
            status: e.status,
            complianceStatus: e.complianceStatus,
            createdAt: new Date(e.createdAt + "Z"),
            closedAt: e.closedAt === null || e.closedAt === undefined ? null : new Date(e.closedAt + "Z"),
            closingReason: e.closingReason === null || e.closingReason === undefined ? null : e.closingReason,
            partner: e.partner === null || e.partner === undefined ? null : e.partner,
            gender: e.gender === null || e.gender === undefined ? null : e.gender,
            documentNumber: e.documentNumber,
            categoryAccount: e.categoryAccount === null || e.categoryAccount === undefined ? null : e.categoryAccount,
            region: e.region === null || e.region === undefined ? null : e.region,
            age: e.age === null || e.age === undefined ? null : e.age | 0,
            approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
            type: e.type === null || e.type === undefined ? null : e.type,
            account: e.account === null || e.account === undefined ? null : e.account,
            pendingFields: e.pendingFields === null || e.pendingFields === undefined ? null : e.pendingFields.map((e: any) => e),
            pastPendingActions: e.pastPendingActions.map((e: any) => ({
                requestedAt: new Date(e.requestedAt + "Z"),
                answeredAt: new Date(e.answeredAt + "Z"),
                fields: e.fields.map((e: any) => e),
            })),
            approvation: e.approvation === null || e.approvation === undefined ? null : e.approvation,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            journeyType: e.journeyType === null || e.journeyType === undefined ? null : e.journeyType,
            accountStatus: e.accountStatus === null || e.accountStatus === undefined ? null : !!e.accountStatus,
        })),
        paged: ret.paged | 0,
        offset: ret.offset | 0,
        total: ret.total | 0,
    };
}

export async function getFilteredAccountsByPartner(filter: FilterFieldsByPartner, managerFilter: ManagerReportFilter): Promise<ReportFilterByPartner> {
  const args = {
        filter: {
            idPartner: filter.idPartner === null || filter.idPartner === undefined ? null : filter.idPartner.map(e => e),
            partnerName: filter.partnerName === null || filter.partnerName === undefined ? null : filter.partnerName,
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            userName: filter.userName === null || filter.userName === undefined ? null : filter.userName,
            documentNumber: filter.documentNumber === null || filter.documentNumber === undefined ? null : filter.documentNumber,
            account: filter.account === null || filter.account === undefined ? null : filter.account,
            birthDate: filter.birthDate === null || filter.birthDate === undefined ? null : (typeof filter.birthDate === "string" && (filter.birthDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.birthDate as any).replace("Z", "") : filter.birthDate.toISOString().replace("Z", "")),
            email: filter.email === null || filter.email === undefined ? null : filter.email,
            phone: filter.phone === null || filter.phone === undefined ? null : filter.phone,
            address: filter.address === null || filter.address === undefined ? null : {
                cep: filter.address.cep,
                street: filter.address.street,
                neighborhood: filter.address.neighborhood,
                number: filter.address.number,
                complement: filter.address.complement === null || filter.address.complement === undefined ? null : filter.address.complement,
                city: filter.address.city,
                state: filter.address.state,
                country: filter.address.country,
            },
            affiliation1: filter.affiliation1 === null || filter.affiliation1 === undefined ? null : filter.affiliation1,
            affiliation2: filter.affiliation2 === null || filter.affiliation2 === undefined ? null : filter.affiliation2,
            monthlyIncome: filter.monthlyIncome === null || filter.monthlyIncome === undefined ? null : filter.monthlyIncome,
        },
        managerFilter: {
            limit: managerFilter.limit | 0,
            offset: managerFilter.offset | 0,
        },
  };
  const ret = await makeRequest({name: "getFilteredAccountsByPartner", args});
    return {
        users: ret.users.map((e: any) => ({
            partnerName: e.partnerName === null || e.partnerName === undefined ? null : e.partnerName,
            type: e.type === null || e.type === undefined ? null : e.type,
            userName: e.userName === null || e.userName === undefined ? null : e.userName,
            documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
            account: e.account === null || e.account === undefined ? null : e.account,
            birthDate: e.birthDate === null || e.birthDate === undefined ? null : new Date(e.birthDate + "Z"),
            email: e.email === null || e.email === undefined ? null : e.email,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep,
                street: e.address.street,
                neighborhood: e.address.neighborhood,
                number: e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city,
                state: e.address.state,
                country: e.address.country,
            },
            affiliation1: e.affiliation1 === null || e.affiliation1 === undefined ? null : e.affiliation1,
            affiliation2: e.affiliation2 === null || e.affiliation2 === undefined ? null : e.affiliation2,
            monthlyIncome: e.monthlyIncome === null || e.monthlyIncome === undefined ? null : e.monthlyIncome,
        })),
        paged: ret.paged | 0,
        offset: ret.offset | 0,
        total: ret.total | 0,
    };
}

export async function getExcelFromFilteredAccounts(filter: FilterFields): Promise<Buffer> {
  const args = {
        filter: {
            idPartner: filter.idPartner === null || filter.idPartner === undefined ? null : filter.idPartner.map(e => e),
            startRegisterDate: filter.startRegisterDate === null || filter.startRegisterDate === undefined ? null : (typeof filter.startRegisterDate === "string" && (filter.startRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.startRegisterDate as any).replace("Z", "") : filter.startRegisterDate.toISOString().replace("Z", "")),
            endRegisterDate: filter.endRegisterDate === null || filter.endRegisterDate === undefined ? null : (typeof filter.endRegisterDate === "string" && (filter.endRegisterDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.endRegisterDate as any).replace("Z", "") : filter.endRegisterDate.toISOString().replace("Z", "")),
            typePerson: filter.typePerson === null || filter.typePerson === undefined ? null : filter.typePerson,
            accountType: filter.accountType === null || filter.accountType === undefined ? null : filter.accountType.map(e => e),
            status: filter.status === null || filter.status === undefined ? null : filter.status.map(e => e),
            gender: filter.gender === null || filter.gender === undefined ? null : filter.gender,
            region: filter.region === null || filter.region === undefined ? null : filter.region.map(e => e),
            startAge: filter.startAge === null || filter.startAge === undefined ? null : filter.startAge | 0,
            endAge: filter.endAge === null || filter.endAge === undefined ? null : filter.endAge | 0,
            approvation: filter.approvation === null || filter.approvation === undefined ? null : filter.approvation,
            closedAt: filter.closedAt === null || filter.closedAt === undefined ? null : (typeof filter.closedAt === "string" && (filter.closedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.closedAt as any).replace("Z", "") : filter.closedAt.toISOString().replace("Z", "")),
            closingReason: filter.closingReason === null || filter.closingReason === undefined ? null : filter.closingReason,
            subType: filter.subType === null || filter.subType === undefined ? null : filter.subType,
            journeyType: filter.journeyType === null || filter.journeyType === undefined ? null : filter.journeyType,
            accountStatus: filter.accountStatus === null || filter.accountStatus === undefined ? null : !!filter.accountStatus,
        },
  };
  const ret = await makeRequest({name: "getExcelFromFilteredAccounts", args});
    return Buffer.from(ret, "base64");
}

export async function getExcelFromFilteredAccountsByPartner(filter: FilterFieldsByPartner): Promise<Buffer> {
  const args = {
        filter: {
            idPartner: filter.idPartner === null || filter.idPartner === undefined ? null : filter.idPartner.map(e => e),
            partnerName: filter.partnerName === null || filter.partnerName === undefined ? null : filter.partnerName,
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            userName: filter.userName === null || filter.userName === undefined ? null : filter.userName,
            documentNumber: filter.documentNumber === null || filter.documentNumber === undefined ? null : filter.documentNumber,
            account: filter.account === null || filter.account === undefined ? null : filter.account,
            birthDate: filter.birthDate === null || filter.birthDate === undefined ? null : (typeof filter.birthDate === "string" && (filter.birthDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.birthDate as any).replace("Z", "") : filter.birthDate.toISOString().replace("Z", "")),
            email: filter.email === null || filter.email === undefined ? null : filter.email,
            phone: filter.phone === null || filter.phone === undefined ? null : filter.phone,
            address: filter.address === null || filter.address === undefined ? null : {
                cep: filter.address.cep,
                street: filter.address.street,
                neighborhood: filter.address.neighborhood,
                number: filter.address.number,
                complement: filter.address.complement === null || filter.address.complement === undefined ? null : filter.address.complement,
                city: filter.address.city,
                state: filter.address.state,
                country: filter.address.country,
            },
            affiliation1: filter.affiliation1 === null || filter.affiliation1 === undefined ? null : filter.affiliation1,
            affiliation2: filter.affiliation2 === null || filter.affiliation2 === undefined ? null : filter.affiliation2,
            monthlyIncome: filter.monthlyIncome === null || filter.monthlyIncome === undefined ? null : filter.monthlyIncome,
        },
  };
  const ret = await makeRequest({name: "getExcelFromFilteredAccountsByPartner", args});
    return Buffer.from(ret, "base64");
}

export async function getExcelFromFilteredSimpleAccounts(filter: ManagerAccountFilter, status: UserStatus | null, simpleAccountSituation: BlockStatusAccount | null): Promise<Buffer> {
  const args = {
        filter: {
            query: filter.query,
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            approvation: filter.approvation === null || filter.approvation === undefined ? null : filter.approvation,
            limit: filter.limit === null || filter.limit === undefined ? null : filter.limit | 0,
            offset: filter.offset | 0,
        },
        status: status === null || status === undefined ? null : status,
        simpleAccountSituation: simpleAccountSituation === null || simpleAccountSituation === undefined ? null : simpleAccountSituation,
  };
  const ret = await makeRequest({name: "getExcelFromFilteredSimpleAccounts", args});
    return Buffer.from(ret, "base64");
}

export async function filterUsersAccounts(query: string): Promise<AccountsUsersCompanys> {
  const args = {
        query: query,
  };
  const ret = await makeRequest({name: "filterUsersAccounts", args});
    return {
        companys: ret.companys.map((e: any) => ({
            accounts: e.accounts.map((e: any) => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: new Date(e.createdAt + "Z"),
            })),
            id: e.id,
            name: e.name,
            documentNumber: e.documentNumber,
            email: e.email,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            status: e.status,
            isMEI: !!e.isMEI,
            fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
            foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : new Date(parseInt(e.foundationDate.split("-")[0], 10), parseInt(e.foundationDate.split("-")[1], 10) - 1, parseInt(e.foundationDate.split("-")[2], 10)),
            foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
            socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
            mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
            },
            isPPE: e.isPPE === null || e.isPPE === undefined ? null : !!e.isPPE,
            mailingAddress: e.mailingAddress === null || e.mailingAddress === undefined ? null : {
                cep: e.mailingAddress.cep === null || e.mailingAddress.cep === undefined ? null : e.mailingAddress.cep,
                street: e.mailingAddress.street === null || e.mailingAddress.street === undefined ? null : e.mailingAddress.street,
                neighborhood: e.mailingAddress.neighborhood === null || e.mailingAddress.neighborhood === undefined ? null : e.mailingAddress.neighborhood,
                number: e.mailingAddress.number === null || e.mailingAddress.number === undefined ? null : e.mailingAddress.number,
                complement: e.mailingAddress.complement === null || e.mailingAddress.complement === undefined ? null : e.mailingAddress.complement,
                city: e.mailingAddress.city === null || e.mailingAddress.city === undefined ? null : e.mailingAddress.city,
                state: e.mailingAddress.state === null || e.mailingAddress.state === undefined ? null : e.mailingAddress.state,
                country: e.mailingAddress.country === null || e.mailingAddress.country === undefined ? null : e.mailingAddress.country,
            },
            hasTaxDomain: e.hasTaxDomain === null || e.hasTaxDomain === undefined ? null : !!e.hasTaxDomain,
            taxDomainCountry: e.taxDomainCountry === null || e.taxDomainCountry === undefined ? null : e.taxDomainCountry,
            taxDomainNumber: e.taxDomainNumber === null || e.taxDomainNumber === undefined ? null : e.taxDomainNumber,
            isAmericanCitizen: e.isAmericanCitizen === null || e.isAmericanCitizen === undefined ? null : !!e.isAmericanCitizen,
            americanCitizenNIF: e.americanCitizenNIF === null || e.americanCitizenNIF === undefined ? null : e.americanCitizenNIF,
            isPPERelationship: e.isPPERelationship === null || e.isPPERelationship === undefined ? null : !!e.isPPERelationship,
            giinNumber: e.giinNumber === null || e.giinNumber === undefined ? null : e.giinNumber,
            ppe: e.ppe === null || e.ppe === undefined ? null : e.ppe.map((e: any) => ({
                name: e.name,
                reason: e.reason,
            })),
            ppeRelationship: e.ppeRelationship === null || e.ppeRelationship === undefined ? null : e.ppeRelationship.map((e: any) => ({
                name: e.name,
                reason: e.reason,
            })),
        })),
        accounts: ret.accounts.map((e: any) => ({
            status: e.status === null || e.status === undefined ? null : e.status,
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        users: ret.users.map((e: any) => ({
            isRepresentative: !!e.isRepresentative,
            isAccountant: !!e.isAccountant,
            journeyType: e.journeyType === null || e.journeyType === undefined ? null : e.journeyType,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            id: e.id,
            name: e.name,
            email: e.email,
            cpf: e.cpf,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
            },
            profilePhoto: e.profilePhoto === null || e.profilePhoto === undefined ? null : e.profilePhoto,
            status: e.status,
            publicKey: e.publicKey === null || e.publicKey === undefined ? null : e.publicKey,
            partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
            accounts: e.accounts.map((e: any) => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: new Date(e.createdAt + "Z"),
            })),
            companies: e.companies.map((e: any) => ({
                accounts: e.accounts.map((e: any) => ({
                    name: e.name,
                    type: e.type,
                    documentNumber: e.documentNumber,
                    permissions: e.permissions.map((e: any) => ({
                        permissionType: e.permissionType,
                        maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                        maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                        isSpecial: !!e.isSpecial,
                    })),
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    yourNumber: e.yourNumber | 0,
                    subType: e.subType === null || e.subType === undefined ? null : e.subType,
                    bank: {
                        code: e.bank.code,
                        name: e.bank.name,
                    },
                    branch: e.branch,
                    account: e.account,
                    createdAt: new Date(e.createdAt + "Z"),
                })),
                id: e.id,
                name: e.name,
                documentNumber: e.documentNumber,
                email: e.email,
                phone: e.phone,
                createdAt: new Date(e.createdAt + "Z"),
                updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
                status: e.status,
                isMEI: !!e.isMEI,
                fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
                foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : new Date(parseInt(e.foundationDate.split("-")[0], 10), parseInt(e.foundationDate.split("-")[1], 10) - 1, parseInt(e.foundationDate.split("-")[2], 10)),
                foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
                socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
                mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
                address: e.address === null || e.address === undefined ? null : {
                    cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                    street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                    neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                    number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                    complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                    city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                    state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                    country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
                },
            })),
            urlTerm: e.urlTerm === null || e.urlTerm === undefined ? null : e.urlTerm,
            urlContract: e.urlContract === null || e.urlContract === undefined ? null : e.urlContract,
            socialName: e.socialName === null || e.socialName === undefined ? null : e.socialName,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            blocked: e.blocked === null || e.blocked === undefined ? null : !!e.blocked,
            isMEI: !!e.isMEI,
            showCard: e.showCard === null || e.showCard === undefined ? null : !!e.showCard,
            help: e.help === null || e.help === undefined ? null : e.help,
            mobileHelp: e.mobileHelp === null || e.mobileHelp === undefined ? null : e.mobileHelp,
            pendingDocument: e.pendingDocument === null || e.pendingDocument === undefined ? null : !!e.pendingDocument,
        })),
        fepwebCompanies: ret.fepwebCompanies.map((e: any) => ({
            accounts: e.accounts.map((e: any) => ({
                name: e.name,
                type: e.type,
                documentNumber: e.documentNumber,
                permissions: e.permissions.map((e: any) => ({
                    permissionType: e.permissionType,
                    maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                    maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                    isSpecial: !!e.isSpecial,
                })),
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                yourNumber: e.yourNumber | 0,
                subType: e.subType === null || e.subType === undefined ? null : e.subType,
                bank: {
                    code: e.bank.code,
                    name: e.bank.name,
                },
                branch: e.branch,
                account: e.account,
                createdAt: new Date(e.createdAt + "Z"),
            })),
            id: e.id,
            name: e.name,
            documentNumber: e.documentNumber,
            email: e.email,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
            status: e.status,
            isMEI: !!e.isMEI,
            fantasyName: e.fantasyName === null || e.fantasyName === undefined ? null : e.fantasyName,
            foundationDate: e.foundationDate === null || e.foundationDate === undefined ? null : new Date(parseInt(e.foundationDate.split("-")[0], 10), parseInt(e.foundationDate.split("-")[1], 10) - 1, parseInt(e.foundationDate.split("-")[2], 10)),
            foundationAssertiva: e.foundationAssertiva === null || e.foundationAssertiva === undefined ? null : e.foundationAssertiva,
            socialCapital: e.socialCapital === null || e.socialCapital === undefined ? null : e.socialCapital,
            mainActivity: e.mainActivity === null || e.mainActivity === undefined ? null : e.mainActivity,
            address: e.address === null || e.address === undefined ? null : {
                cep: e.address.cep === null || e.address.cep === undefined ? null : e.address.cep,
                street: e.address.street === null || e.address.street === undefined ? null : e.address.street,
                neighborhood: e.address.neighborhood === null || e.address.neighborhood === undefined ? null : e.address.neighborhood,
                number: e.address.number === null || e.address.number === undefined ? null : e.address.number,
                complement: e.address.complement === null || e.address.complement === undefined ? null : e.address.complement,
                city: e.address.city === null || e.address.city === undefined ? null : e.address.city,
                state: e.address.state === null || e.address.state === undefined ? null : e.address.state,
                country: e.address.country === null || e.address.country === undefined ? null : e.address.country,
            },
            isPPE: e.isPPE === null || e.isPPE === undefined ? null : !!e.isPPE,
            mailingAddress: e.mailingAddress === null || e.mailingAddress === undefined ? null : {
                cep: e.mailingAddress.cep === null || e.mailingAddress.cep === undefined ? null : e.mailingAddress.cep,
                street: e.mailingAddress.street === null || e.mailingAddress.street === undefined ? null : e.mailingAddress.street,
                neighborhood: e.mailingAddress.neighborhood === null || e.mailingAddress.neighborhood === undefined ? null : e.mailingAddress.neighborhood,
                number: e.mailingAddress.number === null || e.mailingAddress.number === undefined ? null : e.mailingAddress.number,
                complement: e.mailingAddress.complement === null || e.mailingAddress.complement === undefined ? null : e.mailingAddress.complement,
                city: e.mailingAddress.city === null || e.mailingAddress.city === undefined ? null : e.mailingAddress.city,
                state: e.mailingAddress.state === null || e.mailingAddress.state === undefined ? null : e.mailingAddress.state,
                country: e.mailingAddress.country === null || e.mailingAddress.country === undefined ? null : e.mailingAddress.country,
            },
            hasTaxDomain: e.hasTaxDomain === null || e.hasTaxDomain === undefined ? null : !!e.hasTaxDomain,
            taxDomainCountry: e.taxDomainCountry === null || e.taxDomainCountry === undefined ? null : e.taxDomainCountry,
            taxDomainNumber: e.taxDomainNumber === null || e.taxDomainNumber === undefined ? null : e.taxDomainNumber,
            isAmericanCitizen: e.isAmericanCitizen === null || e.isAmericanCitizen === undefined ? null : !!e.isAmericanCitizen,
            americanCitizenNIF: e.americanCitizenNIF === null || e.americanCitizenNIF === undefined ? null : e.americanCitizenNIF,
            isPPERelationship: e.isPPERelationship === null || e.isPPERelationship === undefined ? null : !!e.isPPERelationship,
            giinNumber: e.giinNumber === null || e.giinNumber === undefined ? null : e.giinNumber,
            ppe: e.ppe === null || e.ppe === undefined ? null : e.ppe.map((e: any) => ({
                name: e.name,
                reason: e.reason,
            })),
            ppeRelationship: e.ppeRelationship === null || e.ppeRelationship === undefined ? null : e.ppeRelationship.map((e: any) => ({
                name: e.name,
                reason: e.reason,
            })),
        })),
    };
}

export async function changeComplianceStatus(cpfCnpj: string, type: PfPj, status: ComplianceStatus): Promise<void> {
  const args = {
        cpfCnpj: cpfCnpj,
        type: type,
        status: status,
  };
  await makeRequest({name: "changeComplianceStatus", args});
    return undefined;
}

export async function changeComplianceStatusAutomatic(cpfCnpj: string, type: PfPj, status: ComplianceStatus): Promise<void> {
  const args = {
        cpfCnpj: cpfCnpj,
        type: type,
        status: status,
  };
  await makeRequest({name: "changeComplianceStatusAutomatic", args});
    return undefined;
}

export async function changeStatusUserAutomatic(cpf: string, type: PfPj, status: UserStatus): Promise<boolean> {
  const args = {
        cpf: cpf,
        type: type,
        status: status,
  };
  const ret = await makeRequest({name: "changeStatusUserAutomatic", args});
    return !!ret;
}

export async function changeStatusUser(cpfCnpj: string, type: PfPj, status: UserStatus): Promise<boolean> {
  const args = {
        cpfCnpj: cpfCnpj,
        type: type,
        status: status,
  };
  const ret = await makeRequest({name: "changeStatusUser", args});
    return !!ret;
}

export async function getUsersByStatus(): Promise<UsersByStatus[]> {
  const ret = await makeRequest({name: "getUsersByStatus", args: {}});
    return ret.map((e: any) => ({
        status: e.status,
        count: e.count,
    }));
}

export async function setClient(user: RegisterUser): Promise<string | null> {
  const args = {
        user: {
            userId: user.userId,
            cpf: user.cpf,
            email: user.email,
            phone: user.phone === null || user.phone === undefined ? null : user.phone,
            name: user.name,
            images: user.images === null || user.images === undefined ? null : user.images.map(e => ({
                name: e.name,
                url: e.url,
            })),
            birthDate: user.birthDate === null || user.birthDate === undefined ? null : user.birthDate.toISOString().split("T")[0],
            document: user.document === null || user.document === undefined ? null : {
                type: user.document.type,
                number: user.document.number,
                dispatcher: user.document.dispatcher,
                state: user.document.state,
            },
            gender: user.gender === null || user.gender === undefined ? null : user.gender,
            filiacao1: user.filiacao1 === null || user.filiacao1 === undefined ? null : user.filiacao1,
            filiacao2: user.filiacao2 === null || user.filiacao2 === undefined ? null : user.filiacao2,
            nacionalidade: user.nacionalidade === null || user.nacionalidade === undefined ? null : user.nacionalidade,
            createdAt: (typeof user.createdAt === "string" && (user.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (user.createdAt as any).replace("Z", "") : user.createdAt.toISOString().replace("Z", "")),
            address: user.address === null || user.address === undefined ? null : {
                cep: user.address.cep,
                street: user.address.street,
                neighborhood: user.address.neighborhood,
                number: user.address.number,
                complement: user.address.complement === null || user.address.complement === undefined ? null : user.address.complement,
                city: user.address.city,
                state: user.address.state,
                country: user.address.country,
            },
            comercialInfo: user.comercialInfo === null || user.comercialInfo === undefined ? null : {
                address: user.comercialInfo.address === null || user.comercialInfo.address === undefined ? null : {
                    cep: user.comercialInfo.address.cep === null || user.comercialInfo.address.cep === undefined ? null : user.comercialInfo.address.cep,
                    street: user.comercialInfo.address.street === null || user.comercialInfo.address.street === undefined ? null : user.comercialInfo.address.street,
                    neighborhood: user.comercialInfo.address.neighborhood === null || user.comercialInfo.address.neighborhood === undefined ? null : user.comercialInfo.address.neighborhood,
                    number: user.comercialInfo.address.number === null || user.comercialInfo.address.number === undefined ? null : user.comercialInfo.address.number,
                    complement: user.comercialInfo.address.complement === null || user.comercialInfo.address.complement === undefined ? null : user.comercialInfo.address.complement,
                    city: user.comercialInfo.address.city === null || user.comercialInfo.address.city === undefined ? null : user.comercialInfo.address.city,
                    state: user.comercialInfo.address.state === null || user.comercialInfo.address.state === undefined ? null : user.comercialInfo.address.state,
                    country: user.comercialInfo.address.country === null || user.comercialInfo.address.country === undefined ? null : user.comercialInfo.address.country,
                },
                cnpj: user.comercialInfo.cnpj,
                phone: user.comercialInfo.phone === null || user.comercialInfo.phone === undefined ? null : user.comercialInfo.phone,
            },
            socialName: user.socialName === null || user.socialName === undefined ? null : user.socialName,
            accountInfo: user.accountInfo === null || user.accountInfo === undefined ? null : {
                accountNumber: user.accountInfo.accountNumber,
                branch: user.accountInfo.branch,
                bank: {
                    code: user.accountInfo.bank.code,
                    name: user.accountInfo.bank.name,
                },
            },
            occupation: user.occupation === null || user.occupation === undefined ? null : user.occupation,
            monthlyIncome: user.monthlyIncome === null || user.monthlyIncome === undefined ? null : user.monthlyIncome,
            patrimony: user.patrimony === null || user.patrimony === undefined ? null : user.patrimony,
            civilStatus: user.civilStatus === null || user.civilStatus === undefined ? null : user.civilStatus,
            categoryAccount: user.categoryAccount === null || user.categoryAccount === undefined ? null : user.categoryAccount,
            partnerId: user.partnerId === null || user.partnerId === undefined ? null : user.partnerId,
            partnerName: user.partnerName === null || user.partnerName === undefined ? null : user.partnerName,
            complianceStatus: user.complianceStatus,
            status: user.status,
            naturalness: user.naturalness === null || user.naturalness === undefined ? null : user.naturalness,
            spouseName: user.spouseName === null || user.spouseName === undefined ? null : user.spouseName,
            weddingType: user.weddingType === null || user.weddingType === undefined ? null : user.weddingType,
            tokenNotification: user.tokenNotification === null || user.tokenNotification === undefined ? null : user.tokenNotification,
            isTerrorist: !!user.isTerrorist,
            isMEI: !!user.isMEI,
            cnpj: user.cnpj === null || user.cnpj === undefined ? null : user.cnpj,
            fantasyName: user.fantasyName === null || user.fantasyName === undefined ? null : user.fantasyName,
            foundationDate: user.foundationDate === null || user.foundationDate === undefined ? null : user.foundationDate.toISOString().split("T")[0],
            mainActivity: user.mainActivity === null || user.mainActivity === undefined ? null : user.mainActivity,
            codeSMS: user.codeSMS === null || user.codeSMS === undefined ? null : user.codeSMS,
            companyName: user.companyName === null || user.companyName === undefined ? null : user.companyName,
        },
  };
  const ret = await makeRequest({name: "setClient", args});
    return ret === null || ret === undefined ? null : ret;
}

export async function saveClientId(cpf: string, codClient: string): Promise<boolean> {
  const args = {
        cpf: cpf,
        codClient: codClient,
  };
  const ret = await makeRequest({name: "saveClientId", args});
    return !!ret;
}

export async function saveAccountNumber(accountNumber: string, categoryAccount: CategoryAccount, cpfCnpj: string, accountType: PfPj, subType: SubType | null): Promise<void> {
  const args = {
        accountNumber: accountNumber,
        categoryAccount: categoryAccount,
        cpfCnpj: cpfCnpj,
        accountType: accountType,
        subType: subType === null || subType === undefined ? null : subType,
  };
  await makeRequest({name: "saveAccountNumber", args});
    return undefined;
}

export async function registerAccount(codCliente: string, nroConta: string, codGerente: string, registerAccount: RegisterUser | null): Promise<boolean> {
  const args = {
        codCliente: codCliente,
        nroConta: nroConta,
        codGerente: codGerente,
        registerAccount: registerAccount === null || registerAccount === undefined ? null : {
            userId: registerAccount.userId,
            cpf: registerAccount.cpf,
            email: registerAccount.email,
            phone: registerAccount.phone === null || registerAccount.phone === undefined ? null : registerAccount.phone,
            name: registerAccount.name,
            images: registerAccount.images === null || registerAccount.images === undefined ? null : registerAccount.images.map(e => ({
                name: e.name,
                url: e.url,
            })),
            birthDate: registerAccount.birthDate === null || registerAccount.birthDate === undefined ? null : registerAccount.birthDate.toISOString().split("T")[0],
            document: registerAccount.document === null || registerAccount.document === undefined ? null : {
                type: registerAccount.document.type,
                number: registerAccount.document.number,
                dispatcher: registerAccount.document.dispatcher,
                state: registerAccount.document.state,
            },
            gender: registerAccount.gender === null || registerAccount.gender === undefined ? null : registerAccount.gender,
            filiacao1: registerAccount.filiacao1 === null || registerAccount.filiacao1 === undefined ? null : registerAccount.filiacao1,
            filiacao2: registerAccount.filiacao2 === null || registerAccount.filiacao2 === undefined ? null : registerAccount.filiacao2,
            nacionalidade: registerAccount.nacionalidade === null || registerAccount.nacionalidade === undefined ? null : registerAccount.nacionalidade,
            createdAt: (typeof registerAccount.createdAt === "string" && (registerAccount.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (registerAccount.createdAt as any).replace("Z", "") : registerAccount.createdAt.toISOString().replace("Z", "")),
            address: registerAccount.address === null || registerAccount.address === undefined ? null : {
                cep: registerAccount.address.cep,
                street: registerAccount.address.street,
                neighborhood: registerAccount.address.neighborhood,
                number: registerAccount.address.number,
                complement: registerAccount.address.complement === null || registerAccount.address.complement === undefined ? null : registerAccount.address.complement,
                city: registerAccount.address.city,
                state: registerAccount.address.state,
                country: registerAccount.address.country,
            },
            comercialInfo: registerAccount.comercialInfo === null || registerAccount.comercialInfo === undefined ? null : {
                address: registerAccount.comercialInfo.address === null || registerAccount.comercialInfo.address === undefined ? null : {
                    cep: registerAccount.comercialInfo.address.cep === null || registerAccount.comercialInfo.address.cep === undefined ? null : registerAccount.comercialInfo.address.cep,
                    street: registerAccount.comercialInfo.address.street === null || registerAccount.comercialInfo.address.street === undefined ? null : registerAccount.comercialInfo.address.street,
                    neighborhood: registerAccount.comercialInfo.address.neighborhood === null || registerAccount.comercialInfo.address.neighborhood === undefined ? null : registerAccount.comercialInfo.address.neighborhood,
                    number: registerAccount.comercialInfo.address.number === null || registerAccount.comercialInfo.address.number === undefined ? null : registerAccount.comercialInfo.address.number,
                    complement: registerAccount.comercialInfo.address.complement === null || registerAccount.comercialInfo.address.complement === undefined ? null : registerAccount.comercialInfo.address.complement,
                    city: registerAccount.comercialInfo.address.city === null || registerAccount.comercialInfo.address.city === undefined ? null : registerAccount.comercialInfo.address.city,
                    state: registerAccount.comercialInfo.address.state === null || registerAccount.comercialInfo.address.state === undefined ? null : registerAccount.comercialInfo.address.state,
                    country: registerAccount.comercialInfo.address.country === null || registerAccount.comercialInfo.address.country === undefined ? null : registerAccount.comercialInfo.address.country,
                },
                cnpj: registerAccount.comercialInfo.cnpj,
                phone: registerAccount.comercialInfo.phone === null || registerAccount.comercialInfo.phone === undefined ? null : registerAccount.comercialInfo.phone,
            },
            socialName: registerAccount.socialName === null || registerAccount.socialName === undefined ? null : registerAccount.socialName,
            accountInfo: registerAccount.accountInfo === null || registerAccount.accountInfo === undefined ? null : {
                accountNumber: registerAccount.accountInfo.accountNumber,
                branch: registerAccount.accountInfo.branch,
                bank: {
                    code: registerAccount.accountInfo.bank.code,
                    name: registerAccount.accountInfo.bank.name,
                },
            },
            occupation: registerAccount.occupation === null || registerAccount.occupation === undefined ? null : registerAccount.occupation,
            monthlyIncome: registerAccount.monthlyIncome === null || registerAccount.monthlyIncome === undefined ? null : registerAccount.monthlyIncome,
            patrimony: registerAccount.patrimony === null || registerAccount.patrimony === undefined ? null : registerAccount.patrimony,
            civilStatus: registerAccount.civilStatus === null || registerAccount.civilStatus === undefined ? null : registerAccount.civilStatus,
            categoryAccount: registerAccount.categoryAccount === null || registerAccount.categoryAccount === undefined ? null : registerAccount.categoryAccount,
            partnerId: registerAccount.partnerId === null || registerAccount.partnerId === undefined ? null : registerAccount.partnerId,
            partnerName: registerAccount.partnerName === null || registerAccount.partnerName === undefined ? null : registerAccount.partnerName,
            complianceStatus: registerAccount.complianceStatus,
            status: registerAccount.status,
            naturalness: registerAccount.naturalness === null || registerAccount.naturalness === undefined ? null : registerAccount.naturalness,
            spouseName: registerAccount.spouseName === null || registerAccount.spouseName === undefined ? null : registerAccount.spouseName,
            weddingType: registerAccount.weddingType === null || registerAccount.weddingType === undefined ? null : registerAccount.weddingType,
            tokenNotification: registerAccount.tokenNotification === null || registerAccount.tokenNotification === undefined ? null : registerAccount.tokenNotification,
            isTerrorist: !!registerAccount.isTerrorist,
            isMEI: !!registerAccount.isMEI,
            cnpj: registerAccount.cnpj === null || registerAccount.cnpj === undefined ? null : registerAccount.cnpj,
            fantasyName: registerAccount.fantasyName === null || registerAccount.fantasyName === undefined ? null : registerAccount.fantasyName,
            foundationDate: registerAccount.foundationDate === null || registerAccount.foundationDate === undefined ? null : registerAccount.foundationDate.toISOString().split("T")[0],
            mainActivity: registerAccount.mainActivity === null || registerAccount.mainActivity === undefined ? null : registerAccount.mainActivity,
            codeSMS: registerAccount.codeSMS === null || registerAccount.codeSMS === undefined ? null : registerAccount.codeSMS,
            companyName: registerAccount.companyName === null || registerAccount.companyName === undefined ? null : registerAccount.companyName,
        },
  };
  const ret = await makeRequest({name: "registerAccount", args});
    return !!ret;
}

export async function registerAccountPJMEI(codCliente: string, nroConta: string, codGerente: string, registerAccount: RegisterUser | null): Promise<boolean> {
  const args = {
        codCliente: codCliente,
        nroConta: nroConta,
        codGerente: codGerente,
        registerAccount: registerAccount === null || registerAccount === undefined ? null : {
            userId: registerAccount.userId,
            cpf: registerAccount.cpf,
            email: registerAccount.email,
            phone: registerAccount.phone === null || registerAccount.phone === undefined ? null : registerAccount.phone,
            name: registerAccount.name,
            images: registerAccount.images === null || registerAccount.images === undefined ? null : registerAccount.images.map(e => ({
                name: e.name,
                url: e.url,
            })),
            birthDate: registerAccount.birthDate === null || registerAccount.birthDate === undefined ? null : registerAccount.birthDate.toISOString().split("T")[0],
            document: registerAccount.document === null || registerAccount.document === undefined ? null : {
                type: registerAccount.document.type,
                number: registerAccount.document.number,
                dispatcher: registerAccount.document.dispatcher,
                state: registerAccount.document.state,
            },
            gender: registerAccount.gender === null || registerAccount.gender === undefined ? null : registerAccount.gender,
            filiacao1: registerAccount.filiacao1 === null || registerAccount.filiacao1 === undefined ? null : registerAccount.filiacao1,
            filiacao2: registerAccount.filiacao2 === null || registerAccount.filiacao2 === undefined ? null : registerAccount.filiacao2,
            nacionalidade: registerAccount.nacionalidade === null || registerAccount.nacionalidade === undefined ? null : registerAccount.nacionalidade,
            createdAt: (typeof registerAccount.createdAt === "string" && (registerAccount.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (registerAccount.createdAt as any).replace("Z", "") : registerAccount.createdAt.toISOString().replace("Z", "")),
            address: registerAccount.address === null || registerAccount.address === undefined ? null : {
                cep: registerAccount.address.cep,
                street: registerAccount.address.street,
                neighborhood: registerAccount.address.neighborhood,
                number: registerAccount.address.number,
                complement: registerAccount.address.complement === null || registerAccount.address.complement === undefined ? null : registerAccount.address.complement,
                city: registerAccount.address.city,
                state: registerAccount.address.state,
                country: registerAccount.address.country,
            },
            comercialInfo: registerAccount.comercialInfo === null || registerAccount.comercialInfo === undefined ? null : {
                address: registerAccount.comercialInfo.address === null || registerAccount.comercialInfo.address === undefined ? null : {
                    cep: registerAccount.comercialInfo.address.cep === null || registerAccount.comercialInfo.address.cep === undefined ? null : registerAccount.comercialInfo.address.cep,
                    street: registerAccount.comercialInfo.address.street === null || registerAccount.comercialInfo.address.street === undefined ? null : registerAccount.comercialInfo.address.street,
                    neighborhood: registerAccount.comercialInfo.address.neighborhood === null || registerAccount.comercialInfo.address.neighborhood === undefined ? null : registerAccount.comercialInfo.address.neighborhood,
                    number: registerAccount.comercialInfo.address.number === null || registerAccount.comercialInfo.address.number === undefined ? null : registerAccount.comercialInfo.address.number,
                    complement: registerAccount.comercialInfo.address.complement === null || registerAccount.comercialInfo.address.complement === undefined ? null : registerAccount.comercialInfo.address.complement,
                    city: registerAccount.comercialInfo.address.city === null || registerAccount.comercialInfo.address.city === undefined ? null : registerAccount.comercialInfo.address.city,
                    state: registerAccount.comercialInfo.address.state === null || registerAccount.comercialInfo.address.state === undefined ? null : registerAccount.comercialInfo.address.state,
                    country: registerAccount.comercialInfo.address.country === null || registerAccount.comercialInfo.address.country === undefined ? null : registerAccount.comercialInfo.address.country,
                },
                cnpj: registerAccount.comercialInfo.cnpj,
                phone: registerAccount.comercialInfo.phone === null || registerAccount.comercialInfo.phone === undefined ? null : registerAccount.comercialInfo.phone,
            },
            socialName: registerAccount.socialName === null || registerAccount.socialName === undefined ? null : registerAccount.socialName,
            accountInfo: registerAccount.accountInfo === null || registerAccount.accountInfo === undefined ? null : {
                accountNumber: registerAccount.accountInfo.accountNumber,
                branch: registerAccount.accountInfo.branch,
                bank: {
                    code: registerAccount.accountInfo.bank.code,
                    name: registerAccount.accountInfo.bank.name,
                },
            },
            occupation: registerAccount.occupation === null || registerAccount.occupation === undefined ? null : registerAccount.occupation,
            monthlyIncome: registerAccount.monthlyIncome === null || registerAccount.monthlyIncome === undefined ? null : registerAccount.monthlyIncome,
            patrimony: registerAccount.patrimony === null || registerAccount.patrimony === undefined ? null : registerAccount.patrimony,
            civilStatus: registerAccount.civilStatus === null || registerAccount.civilStatus === undefined ? null : registerAccount.civilStatus,
            categoryAccount: registerAccount.categoryAccount === null || registerAccount.categoryAccount === undefined ? null : registerAccount.categoryAccount,
            partnerId: registerAccount.partnerId === null || registerAccount.partnerId === undefined ? null : registerAccount.partnerId,
            partnerName: registerAccount.partnerName === null || registerAccount.partnerName === undefined ? null : registerAccount.partnerName,
            complianceStatus: registerAccount.complianceStatus,
            status: registerAccount.status,
            naturalness: registerAccount.naturalness === null || registerAccount.naturalness === undefined ? null : registerAccount.naturalness,
            spouseName: registerAccount.spouseName === null || registerAccount.spouseName === undefined ? null : registerAccount.spouseName,
            weddingType: registerAccount.weddingType === null || registerAccount.weddingType === undefined ? null : registerAccount.weddingType,
            tokenNotification: registerAccount.tokenNotification === null || registerAccount.tokenNotification === undefined ? null : registerAccount.tokenNotification,
            isTerrorist: !!registerAccount.isTerrorist,
            isMEI: !!registerAccount.isMEI,
            cnpj: registerAccount.cnpj === null || registerAccount.cnpj === undefined ? null : registerAccount.cnpj,
            fantasyName: registerAccount.fantasyName === null || registerAccount.fantasyName === undefined ? null : registerAccount.fantasyName,
            foundationDate: registerAccount.foundationDate === null || registerAccount.foundationDate === undefined ? null : registerAccount.foundationDate.toISOString().split("T")[0],
            mainActivity: registerAccount.mainActivity === null || registerAccount.mainActivity === undefined ? null : registerAccount.mainActivity,
            codeSMS: registerAccount.codeSMS === null || registerAccount.codeSMS === undefined ? null : registerAccount.codeSMS,
            companyName: registerAccount.companyName === null || registerAccount.companyName === undefined ? null : registerAccount.companyName,
        },
  };
  const ret = await makeRequest({name: "registerAccountPJMEI", args});
    return !!ret;
}

export async function generateAccountNumber(): Promise<string> {
  const ret = await makeRequest({name: "generateAccountNumber", args: {}});
    return ret;
}

export async function setUser(cpf: string, cnpj: string | null, name: string, email: string, phone: string): Promise<boolean> {
  const args = {
        cpf: cpf,
        cnpj: cnpj === null || cnpj === undefined ? null : cnpj,
        name: name,
        email: email,
        phone: phone,
  };
  const ret = await makeRequest({name: "setUser", args});
    return !!ret;
}

export async function getRegisterFields(): Promise<RegisterFieldsPF> {
  const ret = await makeRequest({name: "getRegisterFields", args: {}});
    return {
        informationFields: ret.informationFields.map((e: any) => ({
            key: e.key,
            value: e.value,
        })),
        documents: ret.documents.map((e: any) => ({
            key: e.key,
            value: e.value,
        })),
    };
}

export async function getFeedbacksByTargetIdAndType(targetId: string, type: PfPj): Promise<Feedback[]> {
  const args = {
        targetId: targetId,
        type: type,
  };
  const ret = await makeRequest({name: "getFeedbacksByTargetIdAndType", args});
    return ret.map((e: any) => ({
        id: e.id === null || e.id === undefined ? null : e.id,
        targetId: e.targetId,
        adminUser: {
            id: e.adminUser.id === null || e.adminUser.id === undefined ? null : e.adminUser.id,
            cpf: e.adminUser.cpf,
            name: e.adminUser.name,
            email: e.adminUser.email,
            password: e.adminUser.password === null || e.adminUser.password === undefined ? null : e.adminUser.password,
            blocked: !!e.adminUser.blocked,
            adminUserPermissions: e.adminUser.adminUserPermissions.map((e: any) => e),
        },
        type: e.type,
        message: e.message,
        attached: e.attached === null || e.attached === undefined ? null : e.attached.map((e: any) => ({
            buf: e.buf === null || e.buf === undefined ? null : Buffer.from(e.buf, "base64"),
            name: e.name,
            url: e.url === null || e.url === undefined ? null : e.url,
        })),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function createFeedback(targetId: string, type: PfPj, message: string, attached: Attached[] | null): Promise<Feedback> {
  const args = {
        targetId: targetId,
        type: type,
        message: message,
        attached: attached === null || attached === undefined ? null : attached.map(e => ({
            buf: e.buf === null || e.buf === undefined ? null : e.buf.toString("base64"),
            name: e.name,
            url: e.url === null || e.url === undefined ? null : e.url,
        })),
  };
  const ret = await makeRequest({name: "createFeedback", args});
    return {
        id: ret.id === null || ret.id === undefined ? null : ret.id,
        targetId: ret.targetId,
        adminUser: {
            id: ret.adminUser.id === null || ret.adminUser.id === undefined ? null : ret.adminUser.id,
            cpf: ret.adminUser.cpf,
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            password: ret.adminUser.password === null || ret.adminUser.password === undefined ? null : ret.adminUser.password,
            blocked: !!ret.adminUser.blocked,
            adminUserPermissions: ret.adminUser.adminUserPermissions.map((e: any) => e),
        },
        type: ret.type,
        message: ret.message,
        attached: ret.attached === null || ret.attached === undefined ? null : ret.attached.map((e: any) => ({
            buf: e.buf === null || e.buf === undefined ? null : Buffer.from(e.buf, "base64"),
            name: e.name,
            url: e.url === null || e.url === undefined ? null : e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function filterWorkflowUsers(query: string): Promise<ManagerAccount[]> {
  const args = {
        query: query,
  };
  const ret = await makeRequest({name: "filterWorkflowUsers", args});
    return ret.map((e: any) => ({
        id: e.id,
        cpfCnpj: e.cpfCnpj,
        name: e.name,
        accountType: e.accountType,
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
        categoryAccount: e.categoryAccount,
        status: e.status === null || e.status === undefined ? null : e.status,
        statusCompliance: e.statusCompliance === null || e.statusCompliance === undefined ? null : e.statusCompliance,
        value: e.value === null || e.value === undefined ? null : e.value,
        blockStatus: e.blockStatus === null || e.blockStatus === undefined ? null : e.blockStatus,
        fepweb: !!e.fepweb,
        journeyType: e.journeyType === null || e.journeyType === undefined ? null : e.journeyType,
        subType: e.subType === null || e.subType === undefined ? null : e.subType,
    }));
}

export async function getPartners(): Promise<Partner[]> {
  const ret = await makeRequest({name: "getPartners", args: {}});
    return ret.map((e: any) => ({
        urlApp: e.urlApp === null || e.urlApp === undefined ? null : {
            android: e.urlApp.android,
            ios: e.urlApp.ios,
        },
        tokenUrlApp: e.tokenUrlApp === null || e.tokenUrlApp === undefined ? null : {
            android: e.tokenUrlApp.android,
            ios: e.tokenUrlApp.ios,
        },
        blocked: !!e.blocked,
        managerId: e.managerId,
        publicKey: e.publicKey,
        partnerSecret: e.partnerSecret,
        partnerUserName: e.partnerUserName === null || e.partnerUserName === undefined ? null : e.partnerUserName,
        allowAnyPartnerLogin: !!e.allowAnyPartnerLogin,
        openAccountsAttempts: e.openAccountsAttempts | 0,
        id: e.id,
        name: e.name,
        logo: e.logo,
        backgroundColor: e.backgroundColor === null || e.backgroundColor === undefined ? null : e.backgroundColor,
        secondaryColor: e.secondaryColor === null || e.secondaryColor === undefined ? null : e.secondaryColor,
        primaryColor: e.primaryColor === null || e.primaryColor === undefined ? null : e.primaryColor,
        font: e.font === null || e.font === undefined ? null : e.font,
        fontHref: e.fontHref === null || e.fontHref === undefined ? null : e.fontHref,
        errorColor: e.errorColor === null || e.errorColor === undefined ? null : e.errorColor,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        email: e.email === null || e.email === undefined ? null : e.email,
        site: e.site === null || e.site === undefined ? null : e.site,
        urlTerm: e.urlTerm === null || e.urlTerm === undefined ? null : e.urlTerm,
        urlContract: e.urlContract === null || e.urlContract === undefined ? null : e.urlContract,
        urlTermPj: e.urlTermPj === null || e.urlTermPj === undefined ? null : e.urlTermPj,
        urlContractPj: e.urlContractPj === null || e.urlContractPj === undefined ? null : e.urlContractPj,
        urlFarePackages: e.urlFarePackages === null || e.urlFarePackages === undefined ? null : e.urlFarePackages,
        urlIB: e.urlIB,
        partnerHook: e.partnerHook === null || e.partnerHook === undefined ? null : {
            url: e.partnerHook.url,
            token: e.partnerHook.token,
        },
        cobrander: e.cobrander === null || e.cobrander === undefined ? null : {
            codeCobrander: e.cobrander.codeCobrander,
            codeCardCategory: e.cobrander.codeCardCategory,
            autoEmitCard: !!e.cobrander.autoEmitCard,
            isActivate: !!e.cobrander.isActivate,
            username: e.cobrander.username === null || e.cobrander.username === undefined ? null : e.cobrander.username,
            password: e.cobrander.password === null || e.cobrander.password === undefined ? null : e.cobrander.password,
        },
        hibotlink: e.hibotlink === null || e.hibotlink === undefined ? null : e.hibotlink,
        hibotmobilelink: e.hibotmobilelink === null || e.hibotmobilelink === undefined ? null : e.hibotmobilelink,
        cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
        webhookUrl: e.webhookUrl === null || e.webhookUrl === undefined ? null : e.webhookUrl,
        emailResendTime: e.emailResendTime | 0,
        emailResendTimeFrequency: e.emailResendTimeFrequency | 0,
        emailFrom: e.emailFrom === null || e.emailFrom === undefined ? null : e.emailFrom,
        accountOpeningAttemptLimit: e.accountOpeningAttemptLimit === null || e.accountOpeningAttemptLimit === undefined ? null : e.accountOpeningAttemptLimit | 0,
        managerIdLot: e.managerIdLot === null || e.managerIdLot === undefined ? null : e.managerIdLot,
        allowPartnerLot: !!e.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!e.allowReduceJourneyWithoutLot,
        showWiz: e.showWiz === null || e.showWiz === undefined ? null : !!e.showWiz,
    }));
}

export async function getPartnersV13(): Promise<Partner[]> {
  const ret = await makeRequest({name: "getPartnersV13", args: {}});
    return ret.map((e: any) => ({
        urlApp: e.urlApp === null || e.urlApp === undefined ? null : {
            android: e.urlApp.android,
            ios: e.urlApp.ios,
        },
        tokenUrlApp: e.tokenUrlApp === null || e.tokenUrlApp === undefined ? null : {
            android: e.tokenUrlApp.android,
            ios: e.tokenUrlApp.ios,
        },
        blocked: !!e.blocked,
        managerId: e.managerId,
        publicKey: e.publicKey,
        partnerSecret: e.partnerSecret,
        partnerUserName: e.partnerUserName === null || e.partnerUserName === undefined ? null : e.partnerUserName,
        allowAnyPartnerLogin: !!e.allowAnyPartnerLogin,
        openAccountsAttempts: e.openAccountsAttempts | 0,
        id: e.id,
        name: e.name,
        logo: e.logo,
        backgroundColor: e.backgroundColor === null || e.backgroundColor === undefined ? null : e.backgroundColor,
        secondaryColor: e.secondaryColor === null || e.secondaryColor === undefined ? null : e.secondaryColor,
        primaryColor: e.primaryColor === null || e.primaryColor === undefined ? null : e.primaryColor,
        font: e.font === null || e.font === undefined ? null : e.font,
        fontHref: e.fontHref === null || e.fontHref === undefined ? null : e.fontHref,
        errorColor: e.errorColor === null || e.errorColor === undefined ? null : e.errorColor,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        email: e.email === null || e.email === undefined ? null : e.email,
        site: e.site === null || e.site === undefined ? null : e.site,
        urlTerm: e.urlTerm === null || e.urlTerm === undefined ? null : e.urlTerm,
        urlContract: e.urlContract === null || e.urlContract === undefined ? null : e.urlContract,
        urlTermPj: e.urlTermPj === null || e.urlTermPj === undefined ? null : e.urlTermPj,
        urlContractPj: e.urlContractPj === null || e.urlContractPj === undefined ? null : e.urlContractPj,
        urlFarePackages: e.urlFarePackages === null || e.urlFarePackages === undefined ? null : e.urlFarePackages,
        urlIB: e.urlIB,
        partnerHook: e.partnerHook === null || e.partnerHook === undefined ? null : {
            url: e.partnerHook.url,
            token: e.partnerHook.token,
        },
        cobrander: e.cobrander === null || e.cobrander === undefined ? null : {
            codeCobrander: e.cobrander.codeCobrander,
            codeCardCategory: e.cobrander.codeCardCategory,
            autoEmitCard: !!e.cobrander.autoEmitCard,
            isActivate: !!e.cobrander.isActivate,
            username: e.cobrander.username === null || e.cobrander.username === undefined ? null : e.cobrander.username,
            password: e.cobrander.password === null || e.cobrander.password === undefined ? null : e.cobrander.password,
        },
        hibotlink: e.hibotlink === null || e.hibotlink === undefined ? null : e.hibotlink,
        hibotmobilelink: e.hibotmobilelink === null || e.hibotmobilelink === undefined ? null : e.hibotmobilelink,
        cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
        webhookUrl: e.webhookUrl === null || e.webhookUrl === undefined ? null : e.webhookUrl,
        emailResendTime: e.emailResendTime | 0,
        emailResendTimeFrequency: e.emailResendTimeFrequency | 0,
        emailFrom: e.emailFrom === null || e.emailFrom === undefined ? null : e.emailFrom,
        accountOpeningAttemptLimit: e.accountOpeningAttemptLimit === null || e.accountOpeningAttemptLimit === undefined ? null : e.accountOpeningAttemptLimit | 0,
        managerIdLot: e.managerIdLot === null || e.managerIdLot === undefined ? null : e.managerIdLot,
        allowPartnerLot: !!e.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!e.allowReduceJourneyWithoutLot,
        showWiz: e.showWiz === null || e.showWiz === undefined ? null : !!e.showWiz,
    }));
}

export async function getPartner(partnerId: string | null): Promise<Partner> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getPartner", args});
    return {
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        blocked: !!ret.blocked,
        managerId: ret.managerId,
        publicKey: ret.publicKey,
        partnerSecret: ret.partnerSecret,
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        id: ret.id,
        name: ret.name,
        logo: ret.logo,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlIB: ret.urlIB,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        cobrander: ret.cobrander === null || ret.cobrander === undefined ? null : {
            codeCobrander: ret.cobrander.codeCobrander,
            codeCardCategory: ret.cobrander.codeCardCategory,
            autoEmitCard: !!ret.cobrander.autoEmitCard,
            isActivate: !!ret.cobrander.isActivate,
            username: ret.cobrander.username === null || ret.cobrander.username === undefined ? null : ret.cobrander.username,
            password: ret.cobrander.password === null || ret.cobrander.password === undefined ? null : ret.cobrander.password,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        allowPartnerLot: !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!ret.allowReduceJourneyWithoutLot,
        showWiz: ret.showWiz === null || ret.showWiz === undefined ? null : !!ret.showWiz,
    };
}

export async function getPartnerV13(partnerId: string | null): Promise<Partner> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getPartnerV13", args});
    return {
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        blocked: !!ret.blocked,
        managerId: ret.managerId,
        publicKey: ret.publicKey,
        partnerSecret: ret.partnerSecret,
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        id: ret.id,
        name: ret.name,
        logo: ret.logo,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlIB: ret.urlIB,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        cobrander: ret.cobrander === null || ret.cobrander === undefined ? null : {
            codeCobrander: ret.cobrander.codeCobrander,
            codeCardCategory: ret.cobrander.codeCardCategory,
            autoEmitCard: !!ret.cobrander.autoEmitCard,
            isActivate: !!ret.cobrander.isActivate,
            username: ret.cobrander.username === null || ret.cobrander.username === undefined ? null : ret.cobrander.username,
            password: ret.cobrander.password === null || ret.cobrander.password === undefined ? null : ret.cobrander.password,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        allowPartnerLot: !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!ret.allowReduceJourneyWithoutLot,
        showWiz: ret.showWiz === null || ret.showWiz === undefined ? null : !!ret.showWiz,
    };
}

export async function getPartnersNames(): Promise<PartnerName[]> {
  const ret = await makeRequest({name: "getPartnersNames", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function getManagerByPartnerId(partnerId: string): Promise<Partner> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getManagerByPartnerId", args});
    return {
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        blocked: !!ret.blocked,
        managerId: ret.managerId,
        publicKey: ret.publicKey,
        partnerSecret: ret.partnerSecret,
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        id: ret.id,
        name: ret.name,
        logo: ret.logo,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlIB: ret.urlIB,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        cobrander: ret.cobrander === null || ret.cobrander === undefined ? null : {
            codeCobrander: ret.cobrander.codeCobrander,
            codeCardCategory: ret.cobrander.codeCardCategory,
            autoEmitCard: !!ret.cobrander.autoEmitCard,
            isActivate: !!ret.cobrander.isActivate,
            username: ret.cobrander.username === null || ret.cobrander.username === undefined ? null : ret.cobrander.username,
            password: ret.cobrander.password === null || ret.cobrander.password === undefined ? null : ret.cobrander.password,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        allowPartnerLot: !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!ret.allowReduceJourneyWithoutLot,
        showWiz: ret.showWiz === null || ret.showWiz === undefined ? null : !!ret.showWiz,
    };
}

export async function getTermContractByPartnerId(partnerId: string, type: TermContractType): Promise<File[]> {
  const args = {
        partnerId: partnerId,
        type: type,
  };
  const ret = await makeRequest({name: "getTermContractByPartnerId", args});
    return ret.map((e: any) => ({
        name: e.name,
        url: e.url,
    }));
}

export async function getPartnersNamesLot(): Promise<PartnerName[]> {
  const ret = await makeRequest({name: "getPartnersNamesLot", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function setPartner(partner: Partner, logo: Buffer | null, term: Buffer | null, contract: Buffer | null, termPj: Buffer | null, contractPj: Buffer | null, farePackages: Buffer | null, card: CardConfig | null): Promise<Partner> {
  const args = {
        partner: {
            urlApp: partner.urlApp === null || partner.urlApp === undefined ? null : {
                android: partner.urlApp.android,
                ios: partner.urlApp.ios,
            },
            tokenUrlApp: partner.tokenUrlApp === null || partner.tokenUrlApp === undefined ? null : {
                android: partner.tokenUrlApp.android,
                ios: partner.tokenUrlApp.ios,
            },
            blocked: !!partner.blocked,
            managerId: partner.managerId,
            publicKey: partner.publicKey,
            partnerSecret: partner.partnerSecret,
            partnerUserName: partner.partnerUserName === null || partner.partnerUserName === undefined ? null : partner.partnerUserName,
            allowAnyPartnerLogin: !!partner.allowAnyPartnerLogin,
            openAccountsAttempts: partner.openAccountsAttempts | 0,
            id: partner.id,
            name: partner.name,
            logo: partner.logo,
            backgroundColor: partner.backgroundColor === null || partner.backgroundColor === undefined ? null : partner.backgroundColor,
            secondaryColor: partner.secondaryColor === null || partner.secondaryColor === undefined ? null : partner.secondaryColor,
            primaryColor: partner.primaryColor === null || partner.primaryColor === undefined ? null : partner.primaryColor,
            font: partner.font === null || partner.font === undefined ? null : partner.font,
            fontHref: partner.fontHref === null || partner.fontHref === undefined ? null : partner.fontHref,
            errorColor: partner.errorColor === null || partner.errorColor === undefined ? null : partner.errorColor,
            phone: partner.phone === null || partner.phone === undefined ? null : partner.phone,
            email: partner.email === null || partner.email === undefined ? null : partner.email,
            site: partner.site === null || partner.site === undefined ? null : partner.site,
            urlTerm: partner.urlTerm === null || partner.urlTerm === undefined ? null : partner.urlTerm,
            urlContract: partner.urlContract === null || partner.urlContract === undefined ? null : partner.urlContract,
            urlTermPj: partner.urlTermPj === null || partner.urlTermPj === undefined ? null : partner.urlTermPj,
            urlContractPj: partner.urlContractPj === null || partner.urlContractPj === undefined ? null : partner.urlContractPj,
            urlFarePackages: partner.urlFarePackages === null || partner.urlFarePackages === undefined ? null : partner.urlFarePackages,
            urlIB: partner.urlIB,
            partnerHook: partner.partnerHook === null || partner.partnerHook === undefined ? null : {
                url: partner.partnerHook.url,
                token: partner.partnerHook.token,
            },
            cobrander: partner.cobrander === null || partner.cobrander === undefined ? null : {
                codeCobrander: partner.cobrander.codeCobrander,
                codeCardCategory: partner.cobrander.codeCardCategory,
                autoEmitCard: !!partner.cobrander.autoEmitCard,
                isActivate: !!partner.cobrander.isActivate,
                username: partner.cobrander.username === null || partner.cobrander.username === undefined ? null : partner.cobrander.username,
                password: partner.cobrander.password === null || partner.cobrander.password === undefined ? null : partner.cobrander.password,
            },
            hibotlink: partner.hibotlink === null || partner.hibotlink === undefined ? null : partner.hibotlink,
            hibotmobilelink: partner.hibotmobilelink === null || partner.hibotmobilelink === undefined ? null : partner.hibotmobilelink,
            cnpj: partner.cnpj === null || partner.cnpj === undefined ? null : partner.cnpj,
            webhookUrl: partner.webhookUrl === null || partner.webhookUrl === undefined ? null : partner.webhookUrl,
            emailResendTime: partner.emailResendTime | 0,
            emailResendTimeFrequency: partner.emailResendTimeFrequency | 0,
            emailFrom: partner.emailFrom === null || partner.emailFrom === undefined ? null : partner.emailFrom,
            accountOpeningAttemptLimit: partner.accountOpeningAttemptLimit === null || partner.accountOpeningAttemptLimit === undefined ? null : partner.accountOpeningAttemptLimit | 0,
            managerIdLot: partner.managerIdLot === null || partner.managerIdLot === undefined ? null : partner.managerIdLot,
            allowPartnerLot: !!partner.allowPartnerLot,
            allowReduceJourneyWithoutLot: !!partner.allowReduceJourneyWithoutLot,
            showWiz: partner.showWiz === null || partner.showWiz === undefined ? null : !!partner.showWiz,
        },
        logo: logo === null || logo === undefined ? null : logo.toString("base64"),
        term: term === null || term === undefined ? null : term.toString("base64"),
        contract: contract === null || contract === undefined ? null : contract.toString("base64"),
        termPj: termPj === null || termPj === undefined ? null : termPj.toString("base64"),
        contractPj: contractPj === null || contractPj === undefined ? null : contractPj.toString("base64"),
        farePackages: farePackages === null || farePackages === undefined ? null : farePackages.toString("base64"),
        card: card === null || card === undefined ? null : {
            broadcaster: card.broadcaster,
            brand: card.brand,
            productId: card.productId,
            plasticId: card.plasticId,
            activateCards: {
                physical: !!card.activateCards.physical,
                virtual: !!card.activateCards.virtual,
            },
            commercialOrigin: card.commercialOrigin,
            cardTypeName: {
                name: !!card.cardTypeName.name,
                noname: !!card.cardTypeName.noname,
            },
            numberBin: card.numberBin,
            typeBin: card.typeBin,
            cardImage: card.cardImage.map(e => ({
                id: e.id,
                descr: e.descr,
            })),
            multiCards: card.multiCards,
        },
  };
  const ret = await makeRequest({name: "setPartner", args});
    return {
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        blocked: !!ret.blocked,
        managerId: ret.managerId,
        publicKey: ret.publicKey,
        partnerSecret: ret.partnerSecret,
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        id: ret.id,
        name: ret.name,
        logo: ret.logo,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlIB: ret.urlIB,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        cobrander: ret.cobrander === null || ret.cobrander === undefined ? null : {
            codeCobrander: ret.cobrander.codeCobrander,
            codeCardCategory: ret.cobrander.codeCardCategory,
            autoEmitCard: !!ret.cobrander.autoEmitCard,
            isActivate: !!ret.cobrander.isActivate,
            username: ret.cobrander.username === null || ret.cobrander.username === undefined ? null : ret.cobrander.username,
            password: ret.cobrander.password === null || ret.cobrander.password === undefined ? null : ret.cobrander.password,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        allowPartnerLot: !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!ret.allowReduceJourneyWithoutLot,
        showWiz: ret.showWiz === null || ret.showWiz === undefined ? null : !!ret.showWiz,
    };
}

export async function blockPartner(partnerId: string): Promise<Partner> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "blockPartner", args});
    return {
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        blocked: !!ret.blocked,
        managerId: ret.managerId,
        publicKey: ret.publicKey,
        partnerSecret: ret.partnerSecret,
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        id: ret.id,
        name: ret.name,
        logo: ret.logo,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlIB: ret.urlIB,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        cobrander: ret.cobrander === null || ret.cobrander === undefined ? null : {
            codeCobrander: ret.cobrander.codeCobrander,
            codeCardCategory: ret.cobrander.codeCardCategory,
            autoEmitCard: !!ret.cobrander.autoEmitCard,
            isActivate: !!ret.cobrander.isActivate,
            username: ret.cobrander.username === null || ret.cobrander.username === undefined ? null : ret.cobrander.username,
            password: ret.cobrander.password === null || ret.cobrander.password === undefined ? null : ret.cobrander.password,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        allowPartnerLot: !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: !!ret.allowReduceJourneyWithoutLot,
        showWiz: ret.showWiz === null || ret.showWiz === undefined ? null : !!ret.showWiz,
    };
}

export async function getPartnerConfig(partnerId: string | null): Promise<PartnerConfig | null> {
  const args = {
        partnerId: partnerId === null || partnerId === undefined ? null : partnerId,
  };
  const ret = await makeRequest({name: "getPartnerConfig", args});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        flag: ret.flag,
        rules: ret.rules.map((e: any) => ({
            permission: e.permission,
            inputs: e.inputs.map((e: any) => e),
        })),
        partnerId: ret.partnerId,
        productId: ret.productId === null || ret.productId === undefined ? null : ret.productId,
        plasticId: ret.plasticId === null || ret.plasticId === undefined ? null : ret.plasticId,
        imageId: ret.imageId === null || ret.imageId === undefined ? null : ret.imageId,
        imageDescription: ret.imageDescription === null || ret.imageDescription === undefined ? null : ret.imageDescription,
        commercialOrigin: ret.commercialOrigin === null || ret.commercialOrigin === undefined ? null : ret.commercialOrigin,
        showCard: !!ret.showCard,
        virtualCardFlag: !!ret.virtualCardFlag,
        physicalCardFlag: !!ret.physicalCardFlag,
    };
}

export async function getPartnerRegisterData(partnerId: string): Promise<PartnerRegisterData | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerRegisterData", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        name: ret.name,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
        managerId: ret.managerId,
        urlIB: ret.urlIB,
        urlApp: ret.urlApp === null || ret.urlApp === undefined ? null : {
            android: ret.urlApp.android,
            ios: ret.urlApp.ios,
        },
        tokenUrlApp: ret.tokenUrlApp === null || ret.tokenUrlApp === undefined ? null : {
            android: ret.tokenUrlApp.android,
            ios: ret.tokenUrlApp.ios,
        },
        hibotlink: ret.hibotlink === null || ret.hibotlink === undefined ? null : ret.hibotlink,
        publicKey: ret.publicKey,
        emailFrom: ret.emailFrom === null || ret.emailFrom === undefined ? null : ret.emailFrom,
        urlFarePackages: ret.urlFarePackages === null || ret.urlFarePackages === undefined ? null : ret.urlFarePackages,
        urlTerm: ret.urlTerm === null || ret.urlTerm === undefined ? null : ret.urlTerm,
        urlContract: ret.urlContract === null || ret.urlContract === undefined ? null : ret.urlContract,
        urlTermPj: ret.urlTermPj === null || ret.urlTermPj === undefined ? null : ret.urlTermPj,
        urlContractPj: ret.urlContractPj === null || ret.urlContractPj === undefined ? null : ret.urlContractPj,
        blocked: !!ret.blocked,
        hibotmobilelink: ret.hibotmobilelink === null || ret.hibotmobilelink === undefined ? null : ret.hibotmobilelink,
        partnerSecret: ret.partnerSecret,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        email: ret.email === null || ret.email === undefined ? null : ret.email,
        site: ret.site === null || ret.site === undefined ? null : ret.site,
        partnerHook: ret.partnerHook === null || ret.partnerHook === undefined ? null : {
            url: ret.partnerHook.url,
            token: ret.partnerHook.token,
        },
        partnerUserName: ret.partnerUserName === null || ret.partnerUserName === undefined ? null : ret.partnerUserName,
        webhookUrl: ret.webhookUrl === null || ret.webhookUrl === undefined ? null : ret.webhookUrl,
        term: ret.term === null || ret.term === undefined ? null : Buffer.from(ret.term, "base64"),
        contract: ret.contract === null || ret.contract === undefined ? null : Buffer.from(ret.contract, "base64"),
        termPj: ret.termPj === null || ret.termPj === undefined ? null : Buffer.from(ret.termPj, "base64"),
        contractPj: ret.contractPj === null || ret.contractPj === undefined ? null : Buffer.from(ret.contractPj, "base64"),
        farePackages: ret.farePackages === null || ret.farePackages === undefined ? null : Buffer.from(ret.farePackages, "base64"),
    };
}

export async function getPartnerStyles(partnerId: string): Promise<PartnerStyles | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerStyles", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        primaryColor: ret.primaryColor === null || ret.primaryColor === undefined ? null : ret.primaryColor,
        secondaryColor: ret.secondaryColor === null || ret.secondaryColor === undefined ? null : ret.secondaryColor,
        backgroundColor: ret.backgroundColor === null || ret.backgroundColor === undefined ? null : ret.backgroundColor,
        errorColor: ret.errorColor === null || ret.errorColor === undefined ? null : ret.errorColor,
        logo: ret.logo,
        font: ret.font === null || ret.font === undefined ? null : ret.font,
        fontHref: ret.fontHref === null || ret.fontHref === undefined ? null : ret.fontHref,
        buffer: ret.buffer === null || ret.buffer === undefined ? null : Buffer.from(ret.buffer, "base64"),
    };
}

export async function getPartnerCards(partnerId: string): Promise<PartnerCard | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerCards", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        cardConfig: ret.cardConfig.map((e: any) => ({
            broadcaster: e.broadcaster,
            brand: e.brand,
            productId: e.productId,
            plasticId: e.plasticId,
            activateCards: {
                physical: !!e.activateCards.physical,
                virtual: !!e.activateCards.virtual,
            },
            commercialOrigin: e.commercialOrigin,
            cardTypeName: {
                name: !!e.cardTypeName.name,
                noname: !!e.cardTypeName.noname,
            },
            numberBin: e.numberBin,
            typeBin: e.typeBin,
            cardImage: e.cardImage.map((e: any) => ({
                id: e.id,
                descr: e.descr,
            })),
            multiCards: e.multiCards,
        })),
    };
}

export async function getPartnerGeneralConfig(partnerId: string): Promise<PartnerGeneralConfig | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerGeneralConfig", args});
    return ret === null || ret === undefined ? null : {
        partnerId: ret.partnerId,
        allowAnyPartnerLogin: !!ret.allowAnyPartnerLogin,
        blocked: !!ret.blocked,
        emailResendTime: ret.emailResendTime | 0,
        emailResendTimeFrequency: ret.emailResendTimeFrequency | 0,
        accountOpeningAttemptLimit: ret.accountOpeningAttemptLimit === null || ret.accountOpeningAttemptLimit === undefined ? null : ret.accountOpeningAttemptLimit | 0,
        openAccountsAttempts: ret.openAccountsAttempts | 0,
        showCard: !!ret.showCard,
        allowPartnerLot: ret.allowPartnerLot === null || ret.allowPartnerLot === undefined ? null : !!ret.allowPartnerLot,
        allowReduceJourneyWithoutLot: ret.allowReduceJourneyWithoutLot === null || ret.allowReduceJourneyWithoutLot === undefined ? null : !!ret.allowReduceJourneyWithoutLot,
        showWiz: !!ret.showWiz,
        customButton: ret.customButton === null || ret.customButton === undefined ? null : {
            buffer: ret.customButton.buffer === null || ret.customButton.buffer === undefined ? null : Buffer.from(ret.customButton.buffer, "base64"),
            image: ret.customButton.image === null || ret.customButton.image === undefined ? null : ret.customButton.image,
            url: ret.customButton.url === null || ret.customButton.url === undefined ? null : ret.customButton.url,
            text: ret.customButton.text === null || ret.customButton.text === undefined ? null : ret.customButton.text,
            show: ret.customButton.show === null || ret.customButton.show === undefined ? null : !!ret.customButton.show,
        },
        customContact: ret.customContact === null || ret.customContact === undefined ? null : {
            show: !!ret.customContact.show,
            phone: ret.customContact.phone === null || ret.customContact.phone === undefined ? null : ret.customContact.phone,
            email: ret.customContact.email === null || ret.customContact.email === undefined ? null : ret.customContact.email,
        },
        managerIdLot: ret.managerIdLot === null || ret.managerIdLot === undefined ? null : ret.managerIdLot,
        managerIdWithoutLot: ret.managerIdWithoutLot === null || ret.managerIdWithoutLot === undefined ? null : ret.managerIdWithoutLot,
    };
}

export async function setPartnerRegisterData(partnerRegisterData: PartnerRegisterData): Promise<void> {
  const args = {
        partnerRegisterData: {
            partnerId: partnerRegisterData.partnerId,
            name: partnerRegisterData.name,
            cnpj: partnerRegisterData.cnpj === null || partnerRegisterData.cnpj === undefined ? null : partnerRegisterData.cnpj,
            managerId: partnerRegisterData.managerId,
            urlIB: partnerRegisterData.urlIB,
            urlApp: partnerRegisterData.urlApp === null || partnerRegisterData.urlApp === undefined ? null : {
                android: partnerRegisterData.urlApp.android,
                ios: partnerRegisterData.urlApp.ios,
            },
            tokenUrlApp: partnerRegisterData.tokenUrlApp === null || partnerRegisterData.tokenUrlApp === undefined ? null : {
                android: partnerRegisterData.tokenUrlApp.android,
                ios: partnerRegisterData.tokenUrlApp.ios,
            },
            hibotlink: partnerRegisterData.hibotlink === null || partnerRegisterData.hibotlink === undefined ? null : partnerRegisterData.hibotlink,
            publicKey: partnerRegisterData.publicKey,
            emailFrom: partnerRegisterData.emailFrom === null || partnerRegisterData.emailFrom === undefined ? null : partnerRegisterData.emailFrom,
            urlFarePackages: partnerRegisterData.urlFarePackages === null || partnerRegisterData.urlFarePackages === undefined ? null : partnerRegisterData.urlFarePackages,
            urlTerm: partnerRegisterData.urlTerm === null || partnerRegisterData.urlTerm === undefined ? null : partnerRegisterData.urlTerm,
            urlContract: partnerRegisterData.urlContract === null || partnerRegisterData.urlContract === undefined ? null : partnerRegisterData.urlContract,
            urlTermPj: partnerRegisterData.urlTermPj === null || partnerRegisterData.urlTermPj === undefined ? null : partnerRegisterData.urlTermPj,
            urlContractPj: partnerRegisterData.urlContractPj === null || partnerRegisterData.urlContractPj === undefined ? null : partnerRegisterData.urlContractPj,
            blocked: !!partnerRegisterData.blocked,
            hibotmobilelink: partnerRegisterData.hibotmobilelink === null || partnerRegisterData.hibotmobilelink === undefined ? null : partnerRegisterData.hibotmobilelink,
            partnerSecret: partnerRegisterData.partnerSecret,
            phone: partnerRegisterData.phone === null || partnerRegisterData.phone === undefined ? null : partnerRegisterData.phone,
            email: partnerRegisterData.email === null || partnerRegisterData.email === undefined ? null : partnerRegisterData.email,
            site: partnerRegisterData.site === null || partnerRegisterData.site === undefined ? null : partnerRegisterData.site,
            partnerHook: partnerRegisterData.partnerHook === null || partnerRegisterData.partnerHook === undefined ? null : {
                url: partnerRegisterData.partnerHook.url,
                token: partnerRegisterData.partnerHook.token,
            },
            partnerUserName: partnerRegisterData.partnerUserName === null || partnerRegisterData.partnerUserName === undefined ? null : partnerRegisterData.partnerUserName,
            webhookUrl: partnerRegisterData.webhookUrl === null || partnerRegisterData.webhookUrl === undefined ? null : partnerRegisterData.webhookUrl,
            term: partnerRegisterData.term === null || partnerRegisterData.term === undefined ? null : partnerRegisterData.term.toString("base64"),
            contract: partnerRegisterData.contract === null || partnerRegisterData.contract === undefined ? null : partnerRegisterData.contract.toString("base64"),
            termPj: partnerRegisterData.termPj === null || partnerRegisterData.termPj === undefined ? null : partnerRegisterData.termPj.toString("base64"),
            contractPj: partnerRegisterData.contractPj === null || partnerRegisterData.contractPj === undefined ? null : partnerRegisterData.contractPj.toString("base64"),
            farePackages: partnerRegisterData.farePackages === null || partnerRegisterData.farePackages === undefined ? null : partnerRegisterData.farePackages.toString("base64"),
        },
  };
  await makeRequest({name: "setPartnerRegisterData", args});
    return undefined;
}

export async function setPartnerStyles(partnerStyles: PartnerStyles): Promise<void> {
  const args = {
        partnerStyles: {
            partnerId: partnerStyles.partnerId,
            primaryColor: partnerStyles.primaryColor === null || partnerStyles.primaryColor === undefined ? null : partnerStyles.primaryColor,
            secondaryColor: partnerStyles.secondaryColor === null || partnerStyles.secondaryColor === undefined ? null : partnerStyles.secondaryColor,
            backgroundColor: partnerStyles.backgroundColor === null || partnerStyles.backgroundColor === undefined ? null : partnerStyles.backgroundColor,
            errorColor: partnerStyles.errorColor === null || partnerStyles.errorColor === undefined ? null : partnerStyles.errorColor,
            logo: partnerStyles.logo,
            font: partnerStyles.font === null || partnerStyles.font === undefined ? null : partnerStyles.font,
            fontHref: partnerStyles.fontHref === null || partnerStyles.fontHref === undefined ? null : partnerStyles.fontHref,
            buffer: partnerStyles.buffer === null || partnerStyles.buffer === undefined ? null : partnerStyles.buffer.toString("base64"),
        },
  };
  await makeRequest({name: "setPartnerStyles", args});
    return undefined;
}

export async function setPartnerCards(partnerCard: PartnerCard): Promise<void> {
  const args = {
        partnerCard: {
            partnerId: partnerCard.partnerId,
            cardConfig: partnerCard.cardConfig.map(e => ({
                broadcaster: e.broadcaster,
                brand: e.brand,
                productId: e.productId,
                plasticId: e.plasticId,
                activateCards: {
                    physical: !!e.activateCards.physical,
                    virtual: !!e.activateCards.virtual,
                },
                commercialOrigin: e.commercialOrigin,
                cardTypeName: {
                    name: !!e.cardTypeName.name,
                    noname: !!e.cardTypeName.noname,
                },
                numberBin: e.numberBin,
                typeBin: e.typeBin,
                cardImage: e.cardImage.map(e => ({
                    id: e.id,
                    descr: e.descr,
                })),
                multiCards: e.multiCards,
            })),
        },
  };
  await makeRequest({name: "setPartnerCards", args});
    return undefined;
}

export async function setPartnerGeneralConfig(partnerGeneralConfig: PartnerGeneralConfig): Promise<void> {
  const args = {
        partnerGeneralConfig: {
            partnerId: partnerGeneralConfig.partnerId,
            allowAnyPartnerLogin: !!partnerGeneralConfig.allowAnyPartnerLogin,
            blocked: !!partnerGeneralConfig.blocked,
            emailResendTime: partnerGeneralConfig.emailResendTime | 0,
            emailResendTimeFrequency: partnerGeneralConfig.emailResendTimeFrequency | 0,
            accountOpeningAttemptLimit: partnerGeneralConfig.accountOpeningAttemptLimit === null || partnerGeneralConfig.accountOpeningAttemptLimit === undefined ? null : partnerGeneralConfig.accountOpeningAttemptLimit | 0,
            openAccountsAttempts: partnerGeneralConfig.openAccountsAttempts | 0,
            showCard: !!partnerGeneralConfig.showCard,
            allowPartnerLot: partnerGeneralConfig.allowPartnerLot === null || partnerGeneralConfig.allowPartnerLot === undefined ? null : !!partnerGeneralConfig.allowPartnerLot,
            allowReduceJourneyWithoutLot: partnerGeneralConfig.allowReduceJourneyWithoutLot === null || partnerGeneralConfig.allowReduceJourneyWithoutLot === undefined ? null : !!partnerGeneralConfig.allowReduceJourneyWithoutLot,
            showWiz: !!partnerGeneralConfig.showWiz,
            customButton: partnerGeneralConfig.customButton === null || partnerGeneralConfig.customButton === undefined ? null : {
                buffer: partnerGeneralConfig.customButton.buffer === null || partnerGeneralConfig.customButton.buffer === undefined ? null : partnerGeneralConfig.customButton.buffer.toString("base64"),
                image: partnerGeneralConfig.customButton.image === null || partnerGeneralConfig.customButton.image === undefined ? null : partnerGeneralConfig.customButton.image,
                url: partnerGeneralConfig.customButton.url === null || partnerGeneralConfig.customButton.url === undefined ? null : partnerGeneralConfig.customButton.url,
                text: partnerGeneralConfig.customButton.text === null || partnerGeneralConfig.customButton.text === undefined ? null : partnerGeneralConfig.customButton.text,
                show: partnerGeneralConfig.customButton.show === null || partnerGeneralConfig.customButton.show === undefined ? null : !!partnerGeneralConfig.customButton.show,
            },
            customContact: partnerGeneralConfig.customContact === null || partnerGeneralConfig.customContact === undefined ? null : {
                show: !!partnerGeneralConfig.customContact.show,
                phone: partnerGeneralConfig.customContact.phone === null || partnerGeneralConfig.customContact.phone === undefined ? null : partnerGeneralConfig.customContact.phone,
                email: partnerGeneralConfig.customContact.email === null || partnerGeneralConfig.customContact.email === undefined ? null : partnerGeneralConfig.customContact.email,
            },
            managerIdLot: partnerGeneralConfig.managerIdLot === null || partnerGeneralConfig.managerIdLot === undefined ? null : partnerGeneralConfig.managerIdLot,
            managerIdWithoutLot: partnerGeneralConfig.managerIdWithoutLot === null || partnerGeneralConfig.managerIdWithoutLot === undefined ? null : partnerGeneralConfig.managerIdWithoutLot,
        },
  };
  await makeRequest({name: "setPartnerGeneralConfig", args});
    return undefined;
}

export async function updatePartnerCard(updatePartnerCard: UpdatePartnerCard): Promise<void> {
  const args = {
        updatePartnerCard: {
            partnerId: updatePartnerCard.partnerId,
            productId: updatePartnerCard.productId,
            imageId: updatePartnerCard.imageId,
            imageDescr: updatePartnerCard.imageDescr,
            multiCard: updatePartnerCard.multiCard,
            bin: updatePartnerCard.bin,
            typeBin: updatePartnerCard.typeBin,
            cardTypeName: {
                name: !!updatePartnerCard.cardTypeName.name,
                noname: !!updatePartnerCard.cardTypeName.noname,
            },
            activateCards: {
                physical: !!updatePartnerCard.activateCards.physical,
                virtual: !!updatePartnerCard.activateCards.virtual,
            },
            plasticId: updatePartnerCard.plasticId,
            commercialOrigin: updatePartnerCard.commercialOrigin,
        },
  };
  await makeRequest({name: "updatePartnerCard", args});
    return undefined;
}

export async function removePartnerCard(removePartnerCard: RemovePartnerCard): Promise<void> {
  const args = {
        removePartnerCard: {
            partnerId: removePartnerCard.partnerId,
            productId: removePartnerCard.productId,
            imageId: removePartnerCard.imageId,
        },
  };
  await makeRequest({name: "removePartnerCard", args});
    return undefined;
}

export async function getCompany(cnpj: string): Promise<CompanyManager> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "getCompany", args});
    return {
        accounts: ret.accounts.map((e: any) => ({
            branch: e.branch,
            account: e.account,
        })),
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        phone: ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        status: ret.status,
        isMEI: !!ret.isMEI,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        foundationAssertiva: ret.foundationAssertiva === null || ret.foundationAssertiva === undefined ? null : ret.foundationAssertiva,
        socialCapital: ret.socialCapital === null || ret.socialCapital === undefined ? null : ret.socialCapital,
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
    };
}

export async function getCompanyV13(cnpj: string): Promise<CompanyManagerV13> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "getCompanyV13", args});
    return {
        accounts: ret.accounts.map((e: any) => ({
            branch: e.branch,
            account: e.account,
            status: e.status,
        })),
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        status: ret.status,
        isMEI: !!ret.isMEI,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        foundationAssertiva: ret.foundationAssertiva === null || ret.foundationAssertiva === undefined ? null : ret.foundationAssertiva,
        socialCapital: ret.socialCapital === null || ret.socialCapital === undefined ? null : ret.socialCapital,
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
        isPPE: ret.isPPE === null || ret.isPPE === undefined ? null : !!ret.isPPE,
        mailingAddress: ret.mailingAddress === null || ret.mailingAddress === undefined ? null : {
            cep: ret.mailingAddress.cep === null || ret.mailingAddress.cep === undefined ? null : ret.mailingAddress.cep,
            street: ret.mailingAddress.street === null || ret.mailingAddress.street === undefined ? null : ret.mailingAddress.street,
            neighborhood: ret.mailingAddress.neighborhood === null || ret.mailingAddress.neighborhood === undefined ? null : ret.mailingAddress.neighborhood,
            number: ret.mailingAddress.number === null || ret.mailingAddress.number === undefined ? null : ret.mailingAddress.number,
            complement: ret.mailingAddress.complement === null || ret.mailingAddress.complement === undefined ? null : ret.mailingAddress.complement,
            city: ret.mailingAddress.city === null || ret.mailingAddress.city === undefined ? null : ret.mailingAddress.city,
            state: ret.mailingAddress.state === null || ret.mailingAddress.state === undefined ? null : ret.mailingAddress.state,
            country: ret.mailingAddress.country === null || ret.mailingAddress.country === undefined ? null : ret.mailingAddress.country,
        },
        hasTaxDomain: ret.hasTaxDomain === null || ret.hasTaxDomain === undefined ? null : !!ret.hasTaxDomain,
        taxDomainCountry: ret.taxDomainCountry === null || ret.taxDomainCountry === undefined ? null : ret.taxDomainCountry,
        taxDomainNumber: ret.taxDomainNumber === null || ret.taxDomainNumber === undefined ? null : ret.taxDomainNumber,
        isAmericanCitizen: ret.isAmericanCitizen === null || ret.isAmericanCitizen === undefined ? null : !!ret.isAmericanCitizen,
        americanCitizenNIF: ret.americanCitizenNIF === null || ret.americanCitizenNIF === undefined ? null : ret.americanCitizenNIF,
        isPPERelationship: ret.isPPERelationship === null || ret.isPPERelationship === undefined ? null : !!ret.isPPERelationship,
        giinNumber: ret.giinNumber === null || ret.giinNumber === undefined ? null : ret.giinNumber,
        ppe: ret.ppe === null || ret.ppe === undefined ? null : ret.ppe.map((e: any) => ({
            name: e.name,
            reason: e.reason,
        })),
        ppeRelationship: ret.ppeRelationship === null || ret.ppeRelationship === undefined ? null : ret.ppeRelationship.map((e: any) => ({
            name: e.name,
            reason: e.reason,
        })),
    };
}

export async function getCompanyByCnpjAndStatus(cnpj: string, status: Status): Promise<RegisterCompany> {
  const args = {
        cnpj: cnpj,
        status: status,
  };
  const ret = await makeRequest({name: "getCompanyByCnpjAndStatus", args});
    return {
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        partnerName: ret.partnerName,
        owner: ret.owner === null || ret.owner === undefined ? null : {
            workflowId: ret.owner.workflowId,
            name: ret.owner.name,
            cpf: ret.owner.cpf,
            createdAt: new Date(ret.owner.createdAt + "Z"),
            updatedAt: ret.owner.updatedAt === null || ret.owner.updatedAt === undefined ? null : new Date(ret.owner.updatedAt + "Z"),
            accountType: ret.owner.accountType,
            partner: {
                id: ret.owner.partner.id,
                name: ret.owner.partner.name,
            },
            blocked: ret.owner.blocked === null || ret.owner.blocked === undefined ? null : !!ret.owner.blocked,
            complianceStatus: ret.owner.complianceStatus,
            status: ret.owner.status === null || ret.owner.status === undefined ? null : ret.owner.status,
            fieldGroups: ret.owner.fieldGroups.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    values: e.values.map((e: any) => ({
                        source: e.source,
                        value: e.value,
                    })),
                })),
            })),
            bureaus: ret.owner.bureaus.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
            documents: ret.owner.documents.map((e: any) => ({
                name: e.name,
                url: e.url.normalize(),
            })),
            pendingFields: ret.owner.pendingFields === null || ret.owner.pendingFields === undefined ? null : ret.owner.pendingFields.map((e: any) => e),
            obs: ret.owner.obs,
            blockStatus: ret.owner.blockStatus === null || ret.owner.blockStatus === undefined ? null : ret.owner.blockStatus,
            subType: ret.owner.subType === null || ret.owner.subType === undefined ? null : ret.owner.subType,
            journeyType: ret.owner.journeyType === null || ret.owner.journeyType === undefined ? null : ret.owner.journeyType,
        },
        complianceStatus: ret.complianceStatus === null || ret.complianceStatus === undefined ? null : ret.complianceStatus,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        phone: ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        status: ret.status,
        isMEI: !!ret.isMEI,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        foundationAssertiva: ret.foundationAssertiva === null || ret.foundationAssertiva === undefined ? null : ret.foundationAssertiva,
        socialCapital: ret.socialCapital === null || ret.socialCapital === undefined ? null : ret.socialCapital,
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
    };
}

export async function getCompanyByIdAndStatus(id: string, status: Status): Promise<RegisterCompany> {
  const args = {
        id: id,
        status: status,
  };
  const ret = await makeRequest({name: "getCompanyByIdAndStatus", args});
    return {
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        partnerName: ret.partnerName,
        owner: ret.owner === null || ret.owner === undefined ? null : {
            workflowId: ret.owner.workflowId,
            name: ret.owner.name,
            cpf: ret.owner.cpf,
            createdAt: new Date(ret.owner.createdAt + "Z"),
            updatedAt: ret.owner.updatedAt === null || ret.owner.updatedAt === undefined ? null : new Date(ret.owner.updatedAt + "Z"),
            accountType: ret.owner.accountType,
            partner: {
                id: ret.owner.partner.id,
                name: ret.owner.partner.name,
            },
            blocked: ret.owner.blocked === null || ret.owner.blocked === undefined ? null : !!ret.owner.blocked,
            complianceStatus: ret.owner.complianceStatus,
            status: ret.owner.status === null || ret.owner.status === undefined ? null : ret.owner.status,
            fieldGroups: ret.owner.fieldGroups.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    values: e.values.map((e: any) => ({
                        source: e.source,
                        value: e.value,
                    })),
                })),
            })),
            bureaus: ret.owner.bureaus.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
            documents: ret.owner.documents.map((e: any) => ({
                name: e.name,
                url: e.url.normalize(),
            })),
            pendingFields: ret.owner.pendingFields === null || ret.owner.pendingFields === undefined ? null : ret.owner.pendingFields.map((e: any) => e),
            obs: ret.owner.obs,
            blockStatus: ret.owner.blockStatus === null || ret.owner.blockStatus === undefined ? null : ret.owner.blockStatus,
            subType: ret.owner.subType === null || ret.owner.subType === undefined ? null : ret.owner.subType,
            journeyType: ret.owner.journeyType === null || ret.owner.journeyType === undefined ? null : ret.owner.journeyType,
        },
        complianceStatus: ret.complianceStatus === null || ret.complianceStatus === undefined ? null : ret.complianceStatus,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        phone: ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        status: ret.status,
        isMEI: !!ret.isMEI,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        foundationAssertiva: ret.foundationAssertiva === null || ret.foundationAssertiva === undefined ? null : ret.foundationAssertiva,
        socialCapital: ret.socialCapital === null || ret.socialCapital === undefined ? null : ret.socialCapital,
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
    };
}

export async function getCompanyById(id: string): Promise<RegisterCompany> {
  const args = {
        id: id,
  };
  const ret = await makeRequest({name: "getCompanyById", args});
    return {
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        partnerName: ret.partnerName,
        owner: ret.owner === null || ret.owner === undefined ? null : {
            workflowId: ret.owner.workflowId,
            name: ret.owner.name,
            cpf: ret.owner.cpf,
            createdAt: new Date(ret.owner.createdAt + "Z"),
            updatedAt: ret.owner.updatedAt === null || ret.owner.updatedAt === undefined ? null : new Date(ret.owner.updatedAt + "Z"),
            accountType: ret.owner.accountType,
            partner: {
                id: ret.owner.partner.id,
                name: ret.owner.partner.name,
            },
            blocked: ret.owner.blocked === null || ret.owner.blocked === undefined ? null : !!ret.owner.blocked,
            complianceStatus: ret.owner.complianceStatus,
            status: ret.owner.status === null || ret.owner.status === undefined ? null : ret.owner.status,
            fieldGroups: ret.owner.fieldGroups.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    values: e.values.map((e: any) => ({
                        source: e.source,
                        value: e.value,
                    })),
                })),
            })),
            bureaus: ret.owner.bureaus.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
            documents: ret.owner.documents.map((e: any) => ({
                name: e.name,
                url: e.url.normalize(),
            })),
            pendingFields: ret.owner.pendingFields === null || ret.owner.pendingFields === undefined ? null : ret.owner.pendingFields.map((e: any) => e),
            obs: ret.owner.obs,
            blockStatus: ret.owner.blockStatus === null || ret.owner.blockStatus === undefined ? null : ret.owner.blockStatus,
            subType: ret.owner.subType === null || ret.owner.subType === undefined ? null : ret.owner.subType,
            journeyType: ret.owner.journeyType === null || ret.owner.journeyType === undefined ? null : ret.owner.journeyType,
        },
        complianceStatus: ret.complianceStatus === null || ret.complianceStatus === undefined ? null : ret.complianceStatus,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        phone: ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        status: ret.status,
        isMEI: !!ret.isMEI,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        foundationAssertiva: ret.foundationAssertiva === null || ret.foundationAssertiva === undefined ? null : ret.foundationAssertiva,
        socialCapital: ret.socialCapital === null || ret.socialCapital === undefined ? null : ret.socialCapital,
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
    };
}

export async function getCompanyByIdV13(id: string): Promise<RegisterCompanyV13> {
  const args = {
        id: id,
  };
  const ret = await makeRequest({name: "getCompanyByIdV13", args});
    return {
        partnerId: ret.partnerId === null || ret.partnerId === undefined ? null : ret.partnerId,
        partnerName: ret.partnerName,
        owner: ret.owner === null || ret.owner === undefined ? null : {
            workflowId: ret.owner.workflowId,
            name: ret.owner.name,
            cpf: ret.owner.cpf,
            createdAt: new Date(ret.owner.createdAt + "Z"),
            updatedAt: ret.owner.updatedAt === null || ret.owner.updatedAt === undefined ? null : new Date(ret.owner.updatedAt + "Z"),
            accountType: ret.owner.accountType,
            partner: {
                id: ret.owner.partner.id,
                name: ret.owner.partner.name,
            },
            blocked: ret.owner.blocked === null || ret.owner.blocked === undefined ? null : !!ret.owner.blocked,
            complianceStatus: ret.owner.complianceStatus,
            status: ret.owner.status === null || ret.owner.status === undefined ? null : ret.owner.status,
            fieldGroups: ret.owner.fieldGroups.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    values: e.values.map((e: any) => ({
                        source: e.source,
                        value: e.value,
                    })),
                })),
            })),
            bureaus: ret.owner.bureaus.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
            documents: ret.owner.documents.map((e: any) => ({
                name: e.name,
                url: e.url.normalize(),
            })),
            pendingFields: ret.owner.pendingFields === null || ret.owner.pendingFields === undefined ? null : ret.owner.pendingFields.map((e: any) => e),
            obs: ret.owner.obs,
            blockStatus: ret.owner.blockStatus === null || ret.owner.blockStatus === undefined ? null : ret.owner.blockStatus,
            subType: ret.owner.subType === null || ret.owner.subType === undefined ? null : ret.owner.subType,
            journeyType: ret.owner.journeyType === null || ret.owner.journeyType === undefined ? null : ret.owner.journeyType,
        },
        complianceStatus: ret.complianceStatus === null || ret.complianceStatus === undefined ? null : ret.complianceStatus,
        accounts: ret.accounts.map((e: any) => ({
            name: e.name,
            type: e.type,
            documentNumber: e.documentNumber,
            permissions: e.permissions.map((e: any) => ({
                permissionType: e.permissionType,
                maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
                maxAmountOperation: e.maxAmountOperation === null || e.maxAmountOperation === undefined ? null : e.maxAmountOperation,
                isSpecial: !!e.isSpecial,
            })),
            maxAmountDaily: e.maxAmountDaily === null || e.maxAmountDaily === undefined ? null : e.maxAmountDaily,
            yourNumber: e.yourNumber | 0,
            subType: e.subType === null || e.subType === undefined ? null : e.subType,
            bank: {
                code: e.bank.code,
                name: e.bank.name,
            },
            branch: e.branch,
            account: e.account,
            createdAt: new Date(e.createdAt + "Z"),
        })),
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        email: ret.email,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
        status: ret.status,
        isMEI: !!ret.isMEI,
        fantasyName: ret.fantasyName === null || ret.fantasyName === undefined ? null : ret.fantasyName,
        foundationDate: ret.foundationDate === null || ret.foundationDate === undefined ? null : new Date(parseInt(ret.foundationDate.split("-")[0], 10), parseInt(ret.foundationDate.split("-")[1], 10) - 1, parseInt(ret.foundationDate.split("-")[2], 10)),
        foundationAssertiva: ret.foundationAssertiva === null || ret.foundationAssertiva === undefined ? null : ret.foundationAssertiva,
        socialCapital: ret.socialCapital === null || ret.socialCapital === undefined ? null : ret.socialCapital,
        mainActivity: ret.mainActivity === null || ret.mainActivity === undefined ? null : ret.mainActivity,
        address: ret.address === null || ret.address === undefined ? null : {
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            street: ret.address.street === null || ret.address.street === undefined ? null : ret.address.street,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
            country: ret.address.country === null || ret.address.country === undefined ? null : ret.address.country,
        },
        isPPE: ret.isPPE === null || ret.isPPE === undefined ? null : !!ret.isPPE,
        mailingAddress: ret.mailingAddress === null || ret.mailingAddress === undefined ? null : {
            cep: ret.mailingAddress.cep === null || ret.mailingAddress.cep === undefined ? null : ret.mailingAddress.cep,
            street: ret.mailingAddress.street === null || ret.mailingAddress.street === undefined ? null : ret.mailingAddress.street,
            neighborhood: ret.mailingAddress.neighborhood === null || ret.mailingAddress.neighborhood === undefined ? null : ret.mailingAddress.neighborhood,
            number: ret.mailingAddress.number === null || ret.mailingAddress.number === undefined ? null : ret.mailingAddress.number,
            complement: ret.mailingAddress.complement === null || ret.mailingAddress.complement === undefined ? null : ret.mailingAddress.complement,
            city: ret.mailingAddress.city === null || ret.mailingAddress.city === undefined ? null : ret.mailingAddress.city,
            state: ret.mailingAddress.state === null || ret.mailingAddress.state === undefined ? null : ret.mailingAddress.state,
            country: ret.mailingAddress.country === null || ret.mailingAddress.country === undefined ? null : ret.mailingAddress.country,
        },
        hasTaxDomain: ret.hasTaxDomain === null || ret.hasTaxDomain === undefined ? null : !!ret.hasTaxDomain,
        taxDomainCountry: ret.taxDomainCountry === null || ret.taxDomainCountry === undefined ? null : ret.taxDomainCountry,
        taxDomainNumber: ret.taxDomainNumber === null || ret.taxDomainNumber === undefined ? null : ret.taxDomainNumber,
        isAmericanCitizen: ret.isAmericanCitizen === null || ret.isAmericanCitizen === undefined ? null : !!ret.isAmericanCitizen,
        americanCitizenNIF: ret.americanCitizenNIF === null || ret.americanCitizenNIF === undefined ? null : ret.americanCitizenNIF,
        isPPERelationship: ret.isPPERelationship === null || ret.isPPERelationship === undefined ? null : !!ret.isPPERelationship,
        giinNumber: ret.giinNumber === null || ret.giinNumber === undefined ? null : ret.giinNumber,
        ppe: ret.ppe === null || ret.ppe === undefined ? null : ret.ppe.map((e: any) => ({
            name: e.name,
            reason: e.reason,
        })),
        ppeRelationship: ret.ppeRelationship === null || ret.ppeRelationship === undefined ? null : ret.ppeRelationship.map((e: any) => ({
            name: e.name,
            reason: e.reason,
        })),
    };
}

export async function setPendingRegister(cpfCnpj: string, fields: RegisterField[], obs: string): Promise<boolean> {
  const args = {
        cpfCnpj: cpfCnpj,
        fields: fields.map(e => e),
        obs: obs,
  };
  const ret = await makeRequest({name: "setPendingRegister", args});
    return !!ret;
}

export async function setPendingRegisterMEI(id: string, fields: RegisterField[], obs: string): Promise<boolean> {
  const args = {
        id: id,
        fields: fields.map(e => e),
        obs: obs,
  };
  const ret = await makeRequest({name: "setPendingRegisterMEI", args});
    return !!ret;
}

export async function setPendingAutomaticRegister(cpfCnpj: string, fields: RegisterField[], obs: string, type: PfPj): Promise<boolean> {
  const args = {
        cpfCnpj: cpfCnpj,
        fields: fields.map(e => e),
        obs: obs,
        type: type,
  };
  const ret = await makeRequest({name: "setPendingAutomaticRegister", args});
    return !!ret;
}

export async function setPendingAutomaticRegisterMEI(id: string, fields: RegisterField[], obs: string, type: PfPj): Promise<boolean> {
  const args = {
        id: id,
        fields: fields.map(e => e),
        obs: obs,
        type: type,
  };
  const ret = await makeRequest({name: "setPendingAutomaticRegisterMEI", args});
    return !!ret;
}

export async function getRevalidationHistory(userIdOrCpf: string): Promise<PendingRegisterLinkData[]> {
  const args = {
        userIdOrCpf: userIdOrCpf,
  };
  const ret = await makeRequest({name: "getRevalidationHistory", args});
    return ret.map((e: any) => ({
        id: e.id,
        userId: e.userId,
        createdAt: new Date(e.createdAt + "Z"),
        fields: e.fields.map((e: any) => e),
        oldFieldsValues: e.oldFieldsValues === null || e.oldFieldsValues === undefined ? null : {
            name: e.oldFieldsValues.name === null || e.oldFieldsValues.name === undefined ? null : e.oldFieldsValues.name,
            email: e.oldFieldsValues.email === null || e.oldFieldsValues.email === undefined ? null : e.oldFieldsValues.email,
            phone: e.oldFieldsValues.phone === null || e.oldFieldsValues.phone === undefined ? null : e.oldFieldsValues.phone,
            socialName: e.oldFieldsValues.socialName === null || e.oldFieldsValues.socialName === undefined ? null : e.oldFieldsValues.socialName,
            gender: e.oldFieldsValues.gender === null || e.oldFieldsValues.gender === undefined ? null : e.oldFieldsValues.gender,
            filiacao1: e.oldFieldsValues.filiacao1 === null || e.oldFieldsValues.filiacao1 === undefined ? null : e.oldFieldsValues.filiacao1,
            filiacao2: e.oldFieldsValues.filiacao2 === null || e.oldFieldsValues.filiacao2 === undefined ? null : e.oldFieldsValues.filiacao2,
            nacionality: e.oldFieldsValues.nacionality === null || e.oldFieldsValues.nacionality === undefined ? null : e.oldFieldsValues.nacionality,
            address: e.oldFieldsValues.address === null || e.oldFieldsValues.address === undefined ? null : e.oldFieldsValues.address,
            commercialAddress: e.oldFieldsValues.commercialAddress === null || e.oldFieldsValues.commercialAddress === undefined ? null : e.oldFieldsValues.commercialAddress,
            patrimony: e.oldFieldsValues.patrimony === null || e.oldFieldsValues.patrimony === undefined ? null : e.oldFieldsValues.patrimony,
            monthlyIncome: e.oldFieldsValues.monthlyIncome === null || e.oldFieldsValues.monthlyIncome === undefined ? null : e.oldFieldsValues.monthlyIncome,
            occupation: e.oldFieldsValues.occupation === null || e.oldFieldsValues.occupation === undefined ? null : e.oldFieldsValues.occupation,
            naturalness: e.oldFieldsValues.naturalness === null || e.oldFieldsValues.naturalness === undefined ? null : e.oldFieldsValues.naturalness,
            birthDate: e.oldFieldsValues.birthDate === null || e.oldFieldsValues.birthDate === undefined ? null : e.oldFieldsValues.birthDate,
            civilStatus: e.oldFieldsValues.civilStatus === null || e.oldFieldsValues.civilStatus === undefined ? null : e.oldFieldsValues.civilStatus,
            spouseName: e.oldFieldsValues.spouseName === null || e.oldFieldsValues.spouseName === undefined ? null : e.oldFieldsValues.spouseName,
            weddingType: e.oldFieldsValues.weddingType === null || e.oldFieldsValues.weddingType === undefined ? null : e.oldFieldsValues.weddingType,
            accountInfo: e.oldFieldsValues.accountInfo === null || e.oldFieldsValues.accountInfo === undefined ? null : e.oldFieldsValues.accountInfo,
            document: e.oldFieldsValues.document === null || e.oldFieldsValues.document === undefined ? null : e.oldFieldsValues.document.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            selfie: e.oldFieldsValues.selfie === null || e.oldFieldsValues.selfie === undefined ? null : e.oldFieldsValues.selfie.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            proofOfAddress: e.oldFieldsValues.proofOfAddress === null || e.oldFieldsValues.proofOfAddress === undefined ? null : e.oldFieldsValues.proofOfAddress.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            certificateMEI: e.oldFieldsValues.certificateMEI === null || e.oldFieldsValues.certificateMEI === undefined ? null : e.oldFieldsValues.certificateMEI.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            foundationDate: e.oldFieldsValues.foundationDate === null || e.oldFieldsValues.foundationDate === undefined ? null : e.oldFieldsValues.foundationDate,
            fantasyName: e.oldFieldsValues.fantasyName === null || e.oldFieldsValues.fantasyName === undefined ? null : e.oldFieldsValues.fantasyName,
            companyName: e.oldFieldsValues.companyName === null || e.oldFieldsValues.companyName === undefined ? null : e.oldFieldsValues.companyName,
        },
        obs: e.obs,
        isDone: !!e.isDone,
        name: e.name,
        documentNumber: e.documentNumber,
        partner: e.partner === null || e.partner === undefined ? null : {
            urlApp: e.partner.urlApp === null || e.partner.urlApp === undefined ? null : {
                android: e.partner.urlApp.android,
                ios: e.partner.urlApp.ios,
            },
            tokenUrlApp: e.partner.tokenUrlApp === null || e.partner.tokenUrlApp === undefined ? null : {
                android: e.partner.tokenUrlApp.android,
                ios: e.partner.tokenUrlApp.ios,
            },
            blocked: !!e.partner.blocked,
            managerId: e.partner.managerId,
            publicKey: e.partner.publicKey,
            partnerSecret: e.partner.partnerSecret,
            partnerUserName: e.partner.partnerUserName === null || e.partner.partnerUserName === undefined ? null : e.partner.partnerUserName,
            allowAnyPartnerLogin: !!e.partner.allowAnyPartnerLogin,
            openAccountsAttempts: e.partner.openAccountsAttempts | 0,
            id: e.partner.id,
            name: e.partner.name,
            logo: e.partner.logo,
            backgroundColor: e.partner.backgroundColor === null || e.partner.backgroundColor === undefined ? null : e.partner.backgroundColor,
            secondaryColor: e.partner.secondaryColor === null || e.partner.secondaryColor === undefined ? null : e.partner.secondaryColor,
            primaryColor: e.partner.primaryColor === null || e.partner.primaryColor === undefined ? null : e.partner.primaryColor,
            font: e.partner.font === null || e.partner.font === undefined ? null : e.partner.font,
            fontHref: e.partner.fontHref === null || e.partner.fontHref === undefined ? null : e.partner.fontHref,
            errorColor: e.partner.errorColor === null || e.partner.errorColor === undefined ? null : e.partner.errorColor,
            phone: e.partner.phone === null || e.partner.phone === undefined ? null : e.partner.phone,
            email: e.partner.email === null || e.partner.email === undefined ? null : e.partner.email,
            site: e.partner.site === null || e.partner.site === undefined ? null : e.partner.site,
            urlTerm: e.partner.urlTerm === null || e.partner.urlTerm === undefined ? null : e.partner.urlTerm,
            urlContract: e.partner.urlContract === null || e.partner.urlContract === undefined ? null : e.partner.urlContract,
            urlTermPj: e.partner.urlTermPj === null || e.partner.urlTermPj === undefined ? null : e.partner.urlTermPj,
            urlContractPj: e.partner.urlContractPj === null || e.partner.urlContractPj === undefined ? null : e.partner.urlContractPj,
            urlFarePackages: e.partner.urlFarePackages === null || e.partner.urlFarePackages === undefined ? null : e.partner.urlFarePackages,
            urlIB: e.partner.urlIB,
            partnerHook: e.partner.partnerHook === null || e.partner.partnerHook === undefined ? null : {
                url: e.partner.partnerHook.url,
                token: e.partner.partnerHook.token,
            },
            cobrander: e.partner.cobrander === null || e.partner.cobrander === undefined ? null : {
                codeCobrander: e.partner.cobrander.codeCobrander,
                codeCardCategory: e.partner.cobrander.codeCardCategory,
                autoEmitCard: !!e.partner.cobrander.autoEmitCard,
                isActivate: !!e.partner.cobrander.isActivate,
                username: e.partner.cobrander.username === null || e.partner.cobrander.username === undefined ? null : e.partner.cobrander.username,
                password: e.partner.cobrander.password === null || e.partner.cobrander.password === undefined ? null : e.partner.cobrander.password,
            },
            hibotlink: e.partner.hibotlink === null || e.partner.hibotlink === undefined ? null : e.partner.hibotlink,
            hibotmobilelink: e.partner.hibotmobilelink === null || e.partner.hibotmobilelink === undefined ? null : e.partner.hibotmobilelink,
            cnpj: e.partner.cnpj === null || e.partner.cnpj === undefined ? null : e.partner.cnpj,
            webhookUrl: e.partner.webhookUrl === null || e.partner.webhookUrl === undefined ? null : e.partner.webhookUrl,
            emailResendTime: e.partner.emailResendTime | 0,
            emailResendTimeFrequency: e.partner.emailResendTimeFrequency | 0,
            emailFrom: e.partner.emailFrom === null || e.partner.emailFrom === undefined ? null : e.partner.emailFrom,
            accountOpeningAttemptLimit: e.partner.accountOpeningAttemptLimit === null || e.partner.accountOpeningAttemptLimit === undefined ? null : e.partner.accountOpeningAttemptLimit | 0,
            managerIdLot: e.partner.managerIdLot === null || e.partner.managerIdLot === undefined ? null : e.partner.managerIdLot,
            allowPartnerLot: !!e.partner.allowPartnerLot,
            allowReduceJourneyWithoutLot: !!e.partner.allowReduceJourneyWithoutLot,
            showWiz: e.partner.showWiz === null || e.partner.showWiz === undefined ? null : !!e.partner.showWiz,
        },
    }));
}

export async function getCompanyAnalisysById(companyId: string): Promise<CompanyAnalisys> {
  const args = {
        companyId: companyId,
  };
  const ret = await makeRequest({name: "getCompanyAnalisysById", args});
    return {
        id: ret.id,
        complianceData: {
            clipLaundering: ret.complianceData.clipLaundering === null || ret.complianceData.clipLaundering === undefined ? null : {
                data: ret.complianceData.clipLaundering.data === null || ret.complianceData.clipLaundering.data === undefined ? null : {
                    id: ret.complianceData.clipLaundering.data.id,
                    name: ret.complianceData.clipLaundering.data.name === null || ret.complianceData.clipLaundering.data.name === undefined ? null : ret.complianceData.clipLaundering.data.name,
                    type: ret.complianceData.clipLaundering.data.type === null || ret.complianceData.clipLaundering.data.type === undefined ? null : ret.complianceData.clipLaundering.data.type,
                    doc: {
                        assertiveness: ret.complianceData.clipLaundering.data.doc.assertiveness,
                        number: ret.complianceData.clipLaundering.data.doc.number,
                        situation: ret.complianceData.clipLaundering.data.doc.situation === null || ret.complianceData.clipLaundering.data.doc.situation === undefined ? null : ret.complianceData.clipLaundering.data.doc.situation,
                    },
                    involvement: ret.complianceData.clipLaundering.data.involvement === null || ret.complianceData.clipLaundering.data.involvement === undefined ? null : ret.complianceData.clipLaundering.data.involvement,
                    crimes: ret.complianceData.clipLaundering.data.crimes.map((e: any) => e),
                    news: ret.complianceData.clipLaundering.data.news.map((e: any) => ({
                        title: e.title,
                        reviewDate: e.reviewDate,
                        content: e.content,
                    })),
                    pep: ret.complianceData.clipLaundering.data.pep === null || ret.complianceData.clipLaundering.data.pep === undefined ? null : ret.complianceData.clipLaundering.data.pep,
                },
                error: ret.complianceData.clipLaundering.error === null || ret.complianceData.clipLaundering.error === undefined ? null : ret.complianceData.clipLaundering.error,
                status: ret.complianceData.clipLaundering.status,
            },
            allCheck: ret.complianceData.allCheck === null || ret.complianceData.allCheck === undefined ? null : {
                data: ret.complianceData.allCheck.data === null || ret.complianceData.allCheck.data === undefined ? null : {
                    id: ret.complianceData.allCheck.data.id,
                    registrationData: ret.complianceData.allCheck.data.registrationData === null || ret.complianceData.allCheck.data.registrationData === undefined ? null : {
                        name: ret.complianceData.allCheck.data.registrationData.name === null || ret.complianceData.allCheck.data.registrationData.name === undefined ? null : ret.complianceData.allCheck.data.registrationData.name,
                        documentNumber: ret.complianceData.allCheck.data.registrationData.documentNumber === null || ret.complianceData.allCheck.data.registrationData.documentNumber === undefined ? null : ret.complianceData.allCheck.data.registrationData.documentNumber,
                        documentSource: ret.complianceData.allCheck.data.registrationData.documentSource === null || ret.complianceData.allCheck.data.registrationData.documentSource === undefined ? null : ret.complianceData.allCheck.data.registrationData.documentSource,
                        rg: ret.complianceData.allCheck.data.registrationData.rg === null || ret.complianceData.allCheck.data.registrationData.rg === undefined ? null : ret.complianceData.allCheck.data.registrationData.rg,
                        birthDate: ret.complianceData.allCheck.data.registrationData.birthDate === null || ret.complianceData.allCheck.data.registrationData.birthDate === undefined ? null : ret.complianceData.allCheck.data.registrationData.birthDate,
                        age: ret.complianceData.allCheck.data.registrationData.age === null || ret.complianceData.allCheck.data.registrationData.age === undefined ? null : ret.complianceData.allCheck.data.registrationData.age,
                        sign: ret.complianceData.allCheck.data.registrationData.sign === null || ret.complianceData.allCheck.data.registrationData.sign === undefined ? null : ret.complianceData.allCheck.data.registrationData.sign,
                        email: ret.complianceData.allCheck.data.registrationData.email === null || ret.complianceData.allCheck.data.registrationData.email === undefined ? null : ret.complianceData.allCheck.data.registrationData.email,
                        membership: ret.complianceData.allCheck.data.registrationData.membership === null || ret.complianceData.allCheck.data.registrationData.membership === undefined ? null : ret.complianceData.allCheck.data.registrationData.membership,
                        pis: ret.complianceData.allCheck.data.registrationData.pis === null || ret.complianceData.allCheck.data.registrationData.pis === undefined ? null : ret.complianceData.allCheck.data.registrationData.pis,
                        workPermit: ret.complianceData.allCheck.data.registrationData.workPermit === null || ret.complianceData.allCheck.data.registrationData.workPermit === undefined ? null : ret.complianceData.allCheck.data.registrationData.workPermit,
                        cnpjEmployer: ret.complianceData.allCheck.data.registrationData.cnpjEmployer === null || ret.complianceData.allCheck.data.registrationData.cnpjEmployer === undefined ? null : ret.complianceData.allCheck.data.registrationData.cnpjEmployer,
                        issueDate: ret.complianceData.allCheck.data.registrationData.issueDate === null || ret.complianceData.allCheck.data.registrationData.issueDate === undefined ? null : ret.complianceData.allCheck.data.registrationData.issueDate,
                        activity: ret.complianceData.allCheck.data.registrationData.activity === null || ret.complianceData.allCheck.data.registrationData.activity === undefined ? null : ret.complianceData.allCheck.data.registrationData.activity,
                        degreeOfEducation: ret.complianceData.allCheck.data.registrationData.degreeOfEducation === null || ret.complianceData.allCheck.data.registrationData.degreeOfEducation === undefined ? null : ret.complianceData.allCheck.data.registrationData.degreeOfEducation,
                        gender: ret.complianceData.allCheck.data.registrationData.gender === null || ret.complianceData.allCheck.data.registrationData.gender === undefined ? null : ret.complianceData.allCheck.data.registrationData.gender,
                        civilStatus: ret.complianceData.allCheck.data.registrationData.civilStatus === null || ret.complianceData.allCheck.data.registrationData.civilStatus === undefined ? null : ret.complianceData.allCheck.data.registrationData.civilStatus,
                        benefitNumber: ret.complianceData.allCheck.data.registrationData.benefitNumber === null || ret.complianceData.allCheck.data.registrationData.benefitNumber === undefined ? null : ret.complianceData.allCheck.data.registrationData.benefitNumber,
                        benefitValue: ret.complianceData.allCheck.data.registrationData.benefitValue === null || ret.complianceData.allCheck.data.registrationData.benefitValue === undefined ? null : ret.complianceData.allCheck.data.registrationData.benefitValue,
                        benefitStart: ret.complianceData.allCheck.data.registrationData.benefitStart === null || ret.complianceData.allCheck.data.registrationData.benefitStart === undefined ? null : ret.complianceData.allCheck.data.registrationData.benefitStart,
                        titleNumber: ret.complianceData.allCheck.data.registrationData.titleNumber === null || ret.complianceData.allCheck.data.registrationData.titleNumber === undefined ? null : ret.complianceData.allCheck.data.registrationData.titleNumber,
                        nationality: ret.complianceData.allCheck.data.registrationData.nationality === null || ret.complianceData.allCheck.data.registrationData.nationality === undefined ? null : ret.complianceData.allCheck.data.registrationData.nationality,
                    },
                    emails: ret.complianceData.allCheck.data.emails.map((e: any) => ({
                        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                        email: e.email === null || e.email === undefined ? null : e.email,
                    })),
                    death: ret.complianceData.allCheck.data.death === null || ret.complianceData.allCheck.data.death === undefined ? null : {
                        name: ret.complianceData.allCheck.data.death.name === null || ret.complianceData.allCheck.data.death.name === undefined ? null : ret.complianceData.allCheck.data.death.name,
                        motherName: ret.complianceData.allCheck.data.death.motherName === null || ret.complianceData.allCheck.data.death.motherName === undefined ? null : ret.complianceData.allCheck.data.death.motherName,
                        deathDate: ret.complianceData.allCheck.data.death.deathDate === null || ret.complianceData.allCheck.data.death.deathDate === undefined ? null : ret.complianceData.allCheck.data.death.deathDate,
                        birthDate: ret.complianceData.allCheck.data.death.birthDate === null || ret.complianceData.allCheck.data.death.birthDate === undefined ? null : ret.complianceData.allCheck.data.death.birthDate,
                    },
                    ppe: ret.complianceData.allCheck.data.ppe === null || ret.complianceData.allCheck.data.ppe === undefined ? null : {
                        status: ret.complianceData.allCheck.data.ppe.status === null || ret.complianceData.allCheck.data.ppe.status === undefined ? null : ret.complianceData.allCheck.data.ppe.status,
                        name: ret.complianceData.allCheck.data.ppe.name === null || ret.complianceData.allCheck.data.ppe.name === undefined ? null : ret.complianceData.allCheck.data.ppe.name,
                        birthDate: ret.complianceData.allCheck.data.ppe.birthDate === null || ret.complianceData.allCheck.data.ppe.birthDate === undefined ? null : ret.complianceData.allCheck.data.ppe.birthDate,
                        titleNumber: ret.complianceData.allCheck.data.ppe.titleNumber === null || ret.complianceData.allCheck.data.ppe.titleNumber === undefined ? null : ret.complianceData.allCheck.data.ppe.titleNumber,
                        degreeOfEducation: ret.complianceData.allCheck.data.ppe.degreeOfEducation === null || ret.complianceData.allCheck.data.ppe.degreeOfEducation === undefined ? null : ret.complianceData.allCheck.data.ppe.degreeOfEducation,
                        civilStatus: ret.complianceData.allCheck.data.ppe.civilStatus === null || ret.complianceData.allCheck.data.ppe.civilStatus === undefined ? null : ret.complianceData.allCheck.data.ppe.civilStatus,
                        nationality: ret.complianceData.allCheck.data.ppe.nationality === null || ret.complianceData.allCheck.data.ppe.nationality === undefined ? null : ret.complianceData.allCheck.data.ppe.nationality,
                        birthCounty: ret.complianceData.allCheck.data.ppe.birthCounty === null || ret.complianceData.allCheck.data.ppe.birthCounty === undefined ? null : ret.complianceData.allCheck.data.ppe.birthCounty,
                        ufBirth: ret.complianceData.allCheck.data.ppe.ufBirth === null || ret.complianceData.allCheck.data.ppe.ufBirth === undefined ? null : ret.complianceData.allCheck.data.ppe.ufBirth,
                        occupationDescription: ret.complianceData.allCheck.data.ppe.occupationDescription === null || ret.complianceData.allCheck.data.ppe.occupationDescription === undefined ? null : ret.complianceData.allCheck.data.ppe.occupationDescription,
                        nameUrna: ret.complianceData.allCheck.data.ppe.nameUrna === null || ret.complianceData.allCheck.data.ppe.nameUrna === undefined ? null : ret.complianceData.allCheck.data.ppe.nameUrna,
                        accessionNumber: ret.complianceData.allCheck.data.ppe.accessionNumber === null || ret.complianceData.allCheck.data.ppe.accessionNumber === undefined ? null : ret.complianceData.allCheck.data.ppe.accessionNumber,
                        partyNumber: ret.complianceData.allCheck.data.ppe.partyNumber === null || ret.complianceData.allCheck.data.ppe.partyNumber === undefined ? null : ret.complianceData.allCheck.data.ppe.partyNumber,
                        partyInitials: ret.complianceData.allCheck.data.ppe.partyInitials === null || ret.complianceData.allCheck.data.ppe.partyInitials === undefined ? null : ret.complianceData.allCheck.data.ppe.partyInitials,
                        partyName: ret.complianceData.allCheck.data.ppe.partyName === null || ret.complianceData.allCheck.data.ppe.partyName === undefined ? null : ret.complianceData.allCheck.data.ppe.partyName,
                        initialsUf: ret.complianceData.allCheck.data.ppe.initialsUf === null || ret.complianceData.allCheck.data.ppe.initialsUf === undefined ? null : ret.complianceData.allCheck.data.ppe.initialsUf,
                        county: ret.complianceData.allCheck.data.ppe.county === null || ret.complianceData.allCheck.data.ppe.county === undefined ? null : ret.complianceData.allCheck.data.ppe.county,
                        responsibility: ret.complianceData.allCheck.data.ppe.responsibility === null || ret.complianceData.allCheck.data.ppe.responsibility === undefined ? null : ret.complianceData.allCheck.data.ppe.responsibility,
                        power: ret.complianceData.allCheck.data.ppe.power === null || ret.complianceData.allCheck.data.ppe.power === undefined ? null : ret.complianceData.allCheck.data.ppe.power,
                        institution: ret.complianceData.allCheck.data.ppe.institution === null || ret.complianceData.allCheck.data.ppe.institution === undefined ? null : ret.complianceData.allCheck.data.ppe.institution,
                    },
                    address: ret.complianceData.allCheck.data.address === null || ret.complianceData.allCheck.data.address === undefined ? null : {
                        address: ret.complianceData.allCheck.data.address.address === null || ret.complianceData.allCheck.data.address.address === undefined ? null : ret.complianceData.allCheck.data.address.address,
                        number: ret.complianceData.allCheck.data.address.number === null || ret.complianceData.allCheck.data.address.number === undefined ? null : ret.complianceData.allCheck.data.address.number,
                        complement: ret.complianceData.allCheck.data.address.complement === null || ret.complianceData.allCheck.data.address.complement === undefined ? null : ret.complianceData.allCheck.data.address.complement,
                        cep: ret.complianceData.allCheck.data.address.cep === null || ret.complianceData.allCheck.data.address.cep === undefined ? null : ret.complianceData.allCheck.data.address.cep,
                        neighborhood: ret.complianceData.allCheck.data.address.neighborhood === null || ret.complianceData.allCheck.data.address.neighborhood === undefined ? null : ret.complianceData.allCheck.data.address.neighborhood,
                        city: ret.complianceData.allCheck.data.address.city === null || ret.complianceData.allCheck.data.address.city === undefined ? null : ret.complianceData.allCheck.data.address.city,
                        state: ret.complianceData.allCheck.data.address.state === null || ret.complianceData.allCheck.data.address.state === undefined ? null : ret.complianceData.allCheck.data.address.state,
                    },
                    phones: ret.complianceData.allCheck.data.phones.map((e: any) => ({
                        name: e.name === null || e.name === undefined ? null : e.name,
                        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        phoneType: e.phoneType === null || e.phoneType === undefined ? null : e.phoneType,
                        address: e.address === null || e.address === undefined ? null : e.address,
                        addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        complement: e.complement === null || e.complement === undefined ? null : e.complement,
                        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        installationDate: e.installationDate === null || e.installationDate === undefined ? null : e.installationDate,
                    })),
                    partners: ret.complianceData.allCheck.data.partners.map((e: any) => ({
                        partner: e.partner === null || e.partner === undefined ? null : e.partner,
                        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
                        rg: e.rg === null || e.rg === undefined ? null : e.rg,
                        address: e.address === null || e.address === undefined ? null : e.address,
                        addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
                        addressComplement: e.addressComplement === null || e.addressComplement === undefined ? null : e.addressComplement,
                        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
                        stateRegistration: e.stateRegistration === null || e.stateRegistration === undefined ? null : e.stateRegistration,
                        participation: e.participation === null || e.participation === undefined ? null : e.participation,
                        dateTicket: e.dateTicket === null || e.dateTicket === undefined ? null : e.dateTicket,
                        startDate: e.startDate === null || e.startDate === undefined ? null : e.startDate,
                        name: e.name === null || e.name === undefined ? null : e.name,
                        buyAddress: e.buyAddress === null || e.buyAddress === undefined ? null : e.buyAddress,
                        buyAddressNumber: e.buyAddressNumber === null || e.buyAddressNumber === undefined ? null : e.buyAddressNumber,
                        companyComplement: e.companyComplement === null || e.companyComplement === undefined ? null : e.companyComplement,
                        companyNeighborhood: e.companyNeighborhood === null || e.companyNeighborhood === undefined ? null : e.companyNeighborhood,
                        companyState: e.companyState === null || e.companyState === undefined ? null : e.companyState,
                        companyCep: e.companyCep === null || e.companyCep === undefined ? null : e.companyCep,
                        companyPartner: e.companyPartner === null || e.companyPartner === undefined ? null : e.companyPartner,
                    })),
                    companies: ret.complianceData.allCheck.data.companies === null || ret.complianceData.allCheck.data.companies === undefined ? null : {
                        cnpj: ret.complianceData.allCheck.data.companies.cnpj === null || ret.complianceData.allCheck.data.companies.cnpj === undefined ? null : ret.complianceData.allCheck.data.companies.cnpj,
                        name: ret.complianceData.allCheck.data.companies.name === null || ret.complianceData.allCheck.data.companies.name === undefined ? null : ret.complianceData.allCheck.data.companies.name,
                        fantasy: ret.complianceData.allCheck.data.companies.fantasy === null || ret.complianceData.allCheck.data.companies.fantasy === undefined ? null : ret.complianceData.allCheck.data.companies.fantasy,
                        openingDate: ret.complianceData.allCheck.data.companies.openingDate === null || ret.complianceData.allCheck.data.companies.openingDate === undefined ? null : ret.complianceData.allCheck.data.companies.openingDate,
                        businessActivity: ret.complianceData.allCheck.data.companies.businessActivity === null || ret.complianceData.allCheck.data.companies.businessActivity === undefined ? null : ret.complianceData.allCheck.data.companies.businessActivity,
                        cnae: ret.complianceData.allCheck.data.companies.cnae === null || ret.complianceData.allCheck.data.companies.cnae === undefined ? null : ret.complianceData.allCheck.data.companies.cnae,
                        cnae2: ret.complianceData.allCheck.data.companies.cnae2 === null || ret.complianceData.allCheck.data.companies.cnae2 === undefined ? null : ret.complianceData.allCheck.data.companies.cnae2,
                        juridicalNature: ret.complianceData.allCheck.data.companies.juridicalNature === null || ret.complianceData.allCheck.data.companies.juridicalNature === undefined ? null : ret.complianceData.allCheck.data.companies.juridicalNature,
                        address: ret.complianceData.allCheck.data.companies.address === null || ret.complianceData.allCheck.data.companies.address === undefined ? null : ret.complianceData.allCheck.data.companies.address,
                        number: ret.complianceData.allCheck.data.companies.number === null || ret.complianceData.allCheck.data.companies.number === undefined ? null : ret.complianceData.allCheck.data.companies.number,
                        complement: ret.complianceData.allCheck.data.companies.complement === null || ret.complianceData.allCheck.data.companies.complement === undefined ? null : ret.complianceData.allCheck.data.companies.complement,
                        cep: ret.complianceData.allCheck.data.companies.cep === null || ret.complianceData.allCheck.data.companies.cep === undefined ? null : ret.complianceData.allCheck.data.companies.cep,
                        neighborhood: ret.complianceData.allCheck.data.companies.neighborhood === null || ret.complianceData.allCheck.data.companies.neighborhood === undefined ? null : ret.complianceData.allCheck.data.companies.neighborhood,
                        city: ret.complianceData.allCheck.data.companies.city === null || ret.complianceData.allCheck.data.companies.city === undefined ? null : ret.complianceData.allCheck.data.companies.city,
                        state: ret.complianceData.allCheck.data.companies.state === null || ret.complianceData.allCheck.data.companies.state === undefined ? null : ret.complianceData.allCheck.data.companies.state,
                        cadastralSituation: ret.complianceData.allCheck.data.companies.cadastralSituation === null || ret.complianceData.allCheck.data.companies.cadastralSituation === undefined ? null : ret.complianceData.allCheck.data.companies.cadastralSituation,
                        porte: ret.complianceData.allCheck.data.companies.porte === null || ret.complianceData.allCheck.data.companies.porte === undefined ? null : ret.complianceData.allCheck.data.companies.porte,
                        type: ret.complianceData.allCheck.data.companies.type === null || ret.complianceData.allCheck.data.companies.type === undefined ? null : ret.complianceData.allCheck.data.companies.type,
                        cadastralSituationDate: ret.complianceData.allCheck.data.companies.cadastralSituationDate === null || ret.complianceData.allCheck.data.companies.cadastralSituationDate === undefined ? null : ret.complianceData.allCheck.data.companies.cadastralSituationDate,
                        specialSituation: ret.complianceData.allCheck.data.companies.specialSituation === null || ret.complianceData.allCheck.data.companies.specialSituation === undefined ? null : ret.complianceData.allCheck.data.companies.specialSituation,
                        specialSituationDate: ret.complianceData.allCheck.data.companies.specialSituationDate === null || ret.complianceData.allCheck.data.companies.specialSituationDate === undefined ? null : ret.complianceData.allCheck.data.companies.specialSituationDate,
                    },
                    vehicles: ret.complianceData.allCheck.data.vehicles.map((e: any) => ({
                        name: e.name === null || e.name === undefined ? null : e.name,
                        document: e.document === null || e.document === undefined ? null : e.document,
                        plaque: e.plaque === null || e.plaque === undefined ? null : e.plaque,
                        renavam: e.renavam === null || e.renavam === undefined ? null : e.renavam,
                        brand: e.brand === null || e.brand === undefined ? null : e.brand,
                        fuel: e.fuel === null || e.fuel === undefined ? null : e.fuel,
                        manufacturingYear: e.manufacturingYear === null || e.manufacturingYear === undefined ? null : e.manufacturingYear,
                        chassis: e.chassis === null || e.chassis === undefined ? null : e.chassis,
                        address: e.address === null || e.address === undefined ? null : e.address,
                        number: e.number === null || e.number === undefined ? null : e.number,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        complement: e.complement === null || e.complement === undefined ? null : e.complement,
                        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        uf: e.uf === null || e.uf === undefined ? null : e.uf,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        ddd1: e.ddd1 === null || e.ddd1 === undefined ? null : e.ddd1,
                        phone1: e.phone1 === null || e.phone1 === undefined ? null : e.phone1,
                        movingDate: e.movingDate === null || e.movingDate === undefined ? null : e.movingDate,
                        baseYear: e.baseYear === null || e.baseYear === undefined ? null : e.baseYear,
                    })),
                    ccf: ret.complianceData.allCheck.data.ccf.map((e: any) => ({
                        name: e.name === null || e.name === undefined ? null : e.name,
                        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                        occurrenceAmount: e.occurrenceAmount === null || e.occurrenceAmount === undefined ? null : e.occurrenceAmount,
                        bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
                        agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
                        agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        fax: e.fax === null || e.fax === undefined ? null : e.fax,
                        bank: e.bank === null || e.bank === undefined ? null : e.bank,
                        agency: e.agency === null || e.agency === undefined ? null : e.agency,
                        returnReason: e.returnReason === null || e.returnReason === undefined ? null : e.returnReason,
                        lastOccurrenceDate: e.lastOccurrenceDate === null || e.lastOccurrenceDate === undefined ? null : e.lastOccurrenceDate,
                    })),
                    BankData: ret.complianceData.allCheck.data.BankData.map((e: any) => ({
                        bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
                        agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
                        agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        fax: e.fax === null || e.fax === undefined ? null : e.fax,
                        bank: e.bank === null || e.bank === undefined ? null : e.bank,
                        agencia: e.agencia === null || e.agencia === undefined ? null : e.agencia,
                    })),
                    workHistory: ret.complianceData.allCheck.data.workHistory.map((e: any) => ({
                        admissionDate: e.admissionDate === null || e.admissionDate === undefined ? null : e.admissionDate,
                        nome: e.nome === null || e.nome === undefined ? null : e.nome,
                        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
                        fre: e.fre === null || e.fre === undefined ? null : e.fre,
                        cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
                        nomeEmpresa: e.nomeEmpresa === null || e.nomeEmpresa === undefined ? null : e.nomeEmpresa,
                    })),
                    watchNetwork: ret.complianceData.allCheck.data.watchNetwork.map((e: any) => ({
                        dateConsultation: e.dateConsultation === null || e.dateConsultation === undefined ? null : e.dateConsultation,
                        product: e.product === null || e.product === undefined ? null : e.product,
                        client: e.client === null || e.client === undefined ? null : e.client,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                    })),
                },
                error: ret.complianceData.allCheck.error === null || ret.complianceData.allCheck.error === undefined ? null : ret.complianceData.allCheck.error,
                status: ret.complianceData.allCheck.status,
            },
            pepAml: ret.complianceData.pepAml === null || ret.complianceData.pepAml === undefined ? null : {
                data: ret.complianceData.pepAml.data === null || ret.complianceData.pepAml.data === undefined ? null : {
                    id: ret.complianceData.pepAml.data.id === null || ret.complianceData.pepAml.data.id === undefined ? null : ret.complianceData.pepAml.data.id,
                    name: ret.complianceData.pepAml.data.name,
                    cpfOrCnpj: ret.complianceData.pepAml.data.cpfOrCnpj,
                    classification: ret.complianceData.pepAml.data.classification,
                    description: ret.complianceData.pepAml.data.description,
                    startDate: ret.complianceData.pepAml.data.startDate === null || ret.complianceData.pepAml.data.startDate === undefined ? null : ret.complianceData.pepAml.data.startDate,
                    endDate: ret.complianceData.pepAml.data.endDate === null || ret.complianceData.pepAml.data.endDate === undefined ? null : ret.complianceData.pepAml.data.endDate,
                    deathDate: ret.complianceData.pepAml.data.deathDate === null || ret.complianceData.pepAml.data.deathDate === undefined ? null : ret.complianceData.pepAml.data.deathDate,
                    city: ret.complianceData.pepAml.data.city === null || ret.complianceData.pepAml.data.city === undefined ? null : ret.complianceData.pepAml.data.city,
                    state: ret.complianceData.pepAml.data.state === null || ret.complianceData.pepAml.data.state === undefined ? null : ret.complianceData.pepAml.data.state,
                    officialFlag: ret.complianceData.pepAml.data.officialFlag,
                    additionalInfo: ret.complianceData.pepAml.data.additionalInfo,
                    score: ret.complianceData.pepAml.data.score | 0,
                },
                error: ret.complianceData.pepAml.error === null || ret.complianceData.pepAml.error === undefined ? null : ret.complianceData.pepAml.error,
                status: ret.complianceData.pepAml.status,
            },
            lsaAml: ret.complianceData.lsaAml === null || ret.complianceData.lsaAml === undefined ? null : {
                data: ret.complianceData.lsaAml.data === null || ret.complianceData.lsaAml.data === undefined ? null : {
                    id: ret.complianceData.lsaAml.data.id,
                    name: ret.complianceData.lsaAml.data.name,
                    document: ret.complianceData.lsaAml.data.document,
                    pseudonyms: ret.complianceData.lsaAml.data.pseudonyms,
                    synthesis: ret.complianceData.lsaAml.data.synthesis,
                    reason: ret.complianceData.lsaAml.data.reason,
                    amountAbon: ret.complianceData.lsaAml.data.amountAbon,
                    amountDesabon: ret.complianceData.lsaAml.data.amountDesabon,
                    dateModification: ret.complianceData.lsaAml.data.dateModification === null || ret.complianceData.lsaAml.data.dateModification === undefined ? null : ret.complianceData.lsaAml.data.dateModification,
                },
                error: ret.complianceData.lsaAml.error === null || ret.complianceData.lsaAml.error === undefined ? null : ret.complianceData.lsaAml.error,
                status: ret.complianceData.lsaAml.status,
            },
            lrnAml: ret.complianceData.lrnAml === null || ret.complianceData.lrnAml === undefined ? null : {
                data: ret.complianceData.lrnAml.data === null || ret.complianceData.lrnAml.data === undefined ? null : {
                    id: ret.complianceData.lrnAml.data.id,
                    name: ret.complianceData.lrnAml.data.name,
                    cpfOrCnpj: ret.complianceData.lrnAml.data.cpfOrCnpj,
                    envolvimento: ret.complianceData.lrnAml.data.envolvimento,
                    crimes: ret.complianceData.lrnAml.data.crimes.map((e: any) => e),
                    data: ret.complianceData.lrnAml.data.data === null || ret.complianceData.lrnAml.data.data === undefined ? null : ret.complianceData.lrnAml.data.data,
                    citadoMidia: !!ret.complianceData.lrnAml.data.citadoMidia,
                    score: ret.complianceData.lrnAml.data.score | 0,
                },
                error: ret.complianceData.lrnAml.error === null || ret.complianceData.lrnAml.error === undefined ? null : ret.complianceData.lrnAml.error,
                status: ret.complianceData.lrnAml.status,
            },
        },
        fieldGroups: ret.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: ret.bureaus === null || ret.bureaus === undefined ? null : ret.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
    };
}

export async function resolveRegisterConflicts(companyId: string): Promise<boolean> {
  const args = {
        companyId: companyId,
  };
  const ret = await makeRequest({name: "resolveRegisterConflicts", args});
    return !!ret;
}

export async function toggleUserBlock(state: boolean, userId: string): Promise<boolean> {
  const args = {
        state: !!state,
        userId: userId,
  };
  const ret = await makeRequest({name: "toggleUserBlock", args});
    return !!ret;
}

export async function sendLinkPendingRegister(cpfCnpj: string, fields: RegisterField[], obs: string): Promise<boolean> {
  const args = {
        cpfCnpj: cpfCnpj,
        fields: fields.map(e => e),
        obs: obs,
  };
  const ret = await makeRequest({name: "sendLinkPendingRegister", args});
    return !!ret;
}

export async function sendLinkPendingRegisterMEI(id: string, fields: RegisterField[], obs: string): Promise<boolean> {
  const args = {
        id: id,
        fields: fields.map(e => e),
        obs: obs,
  };
  const ret = await makeRequest({name: "sendLinkPendingRegisterMEI", args});
    return !!ret;
}

export async function sendMailPendingRegister(userId: string): Promise<void> {
  const args = {
        userId: userId,
  };
  await makeRequest({name: "sendMailPendingRegister", args});
    return undefined;
}

export async function changeCompanyCompliance(companyId: string, status: ChangeComplianceStatus): Promise<void> {
  const args = {
        companyId: companyId,
        status: status,
  };
  await makeRequest({name: "changeCompanyCompliance", args});
    return undefined;
}

export async function getRepresentativeAnalisysByCompanyId(companyId: string): Promise<RepresentativeAnalisys[]> {
  const args = {
        companyId: companyId,
  };
  const ret = await makeRequest({name: "getRepresentativeAnalisysByCompanyId", args});
    return ret.map((e: any) => ({
        id: e.id,
        cpf: e.cpf,
        name: e.name,
        status: e.status,
        isMaster: !!e.isMaster,
        blocked: e.blocked === null || e.blocked === undefined ? null : !!e.blocked,
        documents: e.documents === null || e.documents === undefined ? null : e.documents.map((e: any) => ({
            name: e.name,
            url: e.url.normalize(),
        })),
        fieldGroups: e.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: e.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
        historyImages: e.historyImages.map((e: any) => ({
            documents: e.documents === null || e.documents === undefined ? null : e.documents.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            result: e.result.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
        })),
    }));
}

export async function changeStatusRepresentativeById(representativeId: string, status: StatusProofOfLife): Promise<RepresentativeAnalisys> {
  const args = {
        representativeId: representativeId,
        status: status,
  };
  const ret = await makeRequest({name: "changeStatusRepresentativeById", args});
    return {
        id: ret.id,
        cpf: ret.cpf,
        name: ret.name,
        status: ret.status,
        isMaster: !!ret.isMaster,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        documents: ret.documents === null || ret.documents === undefined ? null : ret.documents.map((e: any) => ({
            name: e.name,
            url: e.url.normalize(),
        })),
        fieldGroups: ret.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: ret.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
        historyImages: ret.historyImages.map((e: any) => ({
            documents: e.documents === null || e.documents === undefined ? null : e.documents.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            result: e.result.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
        })),
    };
}

export async function resendRepresentativeProofOfLife(representativeId: string): Promise<boolean> {
  const args = {
        representativeId: representativeId,
  };
  const ret = await makeRequest({name: "resendRepresentativeProofOfLife", args});
    return !!ret;
}

export async function getSubscriberAnalisysByCompanyId(companyId: string): Promise<SubscriberAnalisys[]> {
  const args = {
        companyId: companyId,
  };
  const ret = await makeRequest({name: "getSubscriberAnalisysByCompanyId", args});
    return ret.map((e: any) => ({
        id: e.id,
        cpf: e.cpf,
        name: e.name,
        status: e.status,
        blocked: e.blocked === null || e.blocked === undefined ? null : !!e.blocked,
        documents: e.documents === null || e.documents === undefined ? null : e.documents.map((e: any) => ({
            name: e.name,
            url: e.url.normalize(),
        })),
        fieldGroups: e.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: e.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
        historyImages: e.historyImages.map((e: any) => ({
            documents: e.documents === null || e.documents === undefined ? null : e.documents.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            result: e.result.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
        })),
    }));
}

export async function resendSubscriberProofOfLife(subscriberId: string): Promise<boolean> {
  const args = {
        subscriberId: subscriberId,
  };
  const ret = await makeRequest({name: "resendSubscriberProofOfLife", args});
    return !!ret;
}

export async function changeStatusSubscriberById(subscriberId: string, status: StatusProofOfLife): Promise<SubscriberAnalisys> {
  const args = {
        subscriberId: subscriberId,
        status: status,
  };
  const ret = await makeRequest({name: "changeStatusSubscriberById", args});
    return {
        id: ret.id,
        cpf: ret.cpf,
        name: ret.name,
        status: ret.status,
        blocked: ret.blocked === null || ret.blocked === undefined ? null : !!ret.blocked,
        documents: ret.documents === null || ret.documents === undefined ? null : ret.documents.map((e: any) => ({
            name: e.name,
            url: e.url.normalize(),
        })),
        fieldGroups: ret.fieldGroups.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                values: e.values.map((e: any) => ({
                    source: e.source,
                    value: e.value,
                })),
            })),
        })),
        bureaus: ret.bureaus.map((e: any) => ({
            name: e.name,
            fields: e.fields.map((e: any) => ({
                label: e.label,
                value: e.value,
                url: e.url === null || e.url === undefined ? null : e.url.normalize(),
            })),
        })),
        historyImages: ret.historyImages.map((e: any) => ({
            documents: e.documents === null || e.documents === undefined ? null : e.documents.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            result: e.result.map((e: any) => ({
                name: e.name,
                fields: e.fields.map((e: any) => ({
                    label: e.label,
                    value: e.value,
                    url: e.url === null || e.url === undefined ? null : e.url.normalize(),
                })),
            })),
        })),
    };
}

export async function getBeneficiaryAnalisysByCompanyId(companyId: string): Promise<BeneficiaryAnalisys[]> {
  const args = {
        companyId: companyId,
  };
  const ret = await makeRequest({name: "getBeneficiaryAnalisysByCompanyId", args});
    return ret.map((e: any) => ({
        id: e.id,
        cpfCnpj: e.cpfCnpj,
        name: e.name === null || e.name === undefined ? null : e.name,
        email: e.email === null || e.email === undefined ? null : e.email,
        percentage: e.percentage === null || e.percentage === undefined ? null : e.percentage,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        statusCompliance: e.statusCompliance,
        complianceData: {
            clipLaundering: e.complianceData.clipLaundering === null || e.complianceData.clipLaundering === undefined ? null : {
                data: e.complianceData.clipLaundering.data === null || e.complianceData.clipLaundering.data === undefined ? null : {
                    id: e.complianceData.clipLaundering.data.id,
                    name: e.complianceData.clipLaundering.data.name === null || e.complianceData.clipLaundering.data.name === undefined ? null : e.complianceData.clipLaundering.data.name,
                    type: e.complianceData.clipLaundering.data.type === null || e.complianceData.clipLaundering.data.type === undefined ? null : e.complianceData.clipLaundering.data.type,
                    doc: {
                        assertiveness: e.complianceData.clipLaundering.data.doc.assertiveness,
                        number: e.complianceData.clipLaundering.data.doc.number,
                        situation: e.complianceData.clipLaundering.data.doc.situation === null || e.complianceData.clipLaundering.data.doc.situation === undefined ? null : e.complianceData.clipLaundering.data.doc.situation,
                    },
                    involvement: e.complianceData.clipLaundering.data.involvement === null || e.complianceData.clipLaundering.data.involvement === undefined ? null : e.complianceData.clipLaundering.data.involvement,
                    crimes: e.complianceData.clipLaundering.data.crimes.map((e: any) => e),
                    news: e.complianceData.clipLaundering.data.news.map((e: any) => ({
                        title: e.title,
                        reviewDate: e.reviewDate,
                        content: e.content,
                    })),
                    pep: e.complianceData.clipLaundering.data.pep === null || e.complianceData.clipLaundering.data.pep === undefined ? null : e.complianceData.clipLaundering.data.pep,
                },
                error: e.complianceData.clipLaundering.error === null || e.complianceData.clipLaundering.error === undefined ? null : e.complianceData.clipLaundering.error,
                status: e.complianceData.clipLaundering.status,
            },
            allCheck: e.complianceData.allCheck === null || e.complianceData.allCheck === undefined ? null : {
                data: e.complianceData.allCheck.data === null || e.complianceData.allCheck.data === undefined ? null : {
                    id: e.complianceData.allCheck.data.id,
                    registrationData: e.complianceData.allCheck.data.registrationData === null || e.complianceData.allCheck.data.registrationData === undefined ? null : {
                        name: e.complianceData.allCheck.data.registrationData.name === null || e.complianceData.allCheck.data.registrationData.name === undefined ? null : e.complianceData.allCheck.data.registrationData.name,
                        documentNumber: e.complianceData.allCheck.data.registrationData.documentNumber === null || e.complianceData.allCheck.data.registrationData.documentNumber === undefined ? null : e.complianceData.allCheck.data.registrationData.documentNumber,
                        documentSource: e.complianceData.allCheck.data.registrationData.documentSource === null || e.complianceData.allCheck.data.registrationData.documentSource === undefined ? null : e.complianceData.allCheck.data.registrationData.documentSource,
                        rg: e.complianceData.allCheck.data.registrationData.rg === null || e.complianceData.allCheck.data.registrationData.rg === undefined ? null : e.complianceData.allCheck.data.registrationData.rg,
                        birthDate: e.complianceData.allCheck.data.registrationData.birthDate === null || e.complianceData.allCheck.data.registrationData.birthDate === undefined ? null : e.complianceData.allCheck.data.registrationData.birthDate,
                        age: e.complianceData.allCheck.data.registrationData.age === null || e.complianceData.allCheck.data.registrationData.age === undefined ? null : e.complianceData.allCheck.data.registrationData.age,
                        sign: e.complianceData.allCheck.data.registrationData.sign === null || e.complianceData.allCheck.data.registrationData.sign === undefined ? null : e.complianceData.allCheck.data.registrationData.sign,
                        email: e.complianceData.allCheck.data.registrationData.email === null || e.complianceData.allCheck.data.registrationData.email === undefined ? null : e.complianceData.allCheck.data.registrationData.email,
                        membership: e.complianceData.allCheck.data.registrationData.membership === null || e.complianceData.allCheck.data.registrationData.membership === undefined ? null : e.complianceData.allCheck.data.registrationData.membership,
                        pis: e.complianceData.allCheck.data.registrationData.pis === null || e.complianceData.allCheck.data.registrationData.pis === undefined ? null : e.complianceData.allCheck.data.registrationData.pis,
                        workPermit: e.complianceData.allCheck.data.registrationData.workPermit === null || e.complianceData.allCheck.data.registrationData.workPermit === undefined ? null : e.complianceData.allCheck.data.registrationData.workPermit,
                        cnpjEmployer: e.complianceData.allCheck.data.registrationData.cnpjEmployer === null || e.complianceData.allCheck.data.registrationData.cnpjEmployer === undefined ? null : e.complianceData.allCheck.data.registrationData.cnpjEmployer,
                        issueDate: e.complianceData.allCheck.data.registrationData.issueDate === null || e.complianceData.allCheck.data.registrationData.issueDate === undefined ? null : e.complianceData.allCheck.data.registrationData.issueDate,
                        activity: e.complianceData.allCheck.data.registrationData.activity === null || e.complianceData.allCheck.data.registrationData.activity === undefined ? null : e.complianceData.allCheck.data.registrationData.activity,
                        degreeOfEducation: e.complianceData.allCheck.data.registrationData.degreeOfEducation === null || e.complianceData.allCheck.data.registrationData.degreeOfEducation === undefined ? null : e.complianceData.allCheck.data.registrationData.degreeOfEducation,
                        gender: e.complianceData.allCheck.data.registrationData.gender === null || e.complianceData.allCheck.data.registrationData.gender === undefined ? null : e.complianceData.allCheck.data.registrationData.gender,
                        civilStatus: e.complianceData.allCheck.data.registrationData.civilStatus === null || e.complianceData.allCheck.data.registrationData.civilStatus === undefined ? null : e.complianceData.allCheck.data.registrationData.civilStatus,
                        benefitNumber: e.complianceData.allCheck.data.registrationData.benefitNumber === null || e.complianceData.allCheck.data.registrationData.benefitNumber === undefined ? null : e.complianceData.allCheck.data.registrationData.benefitNumber,
                        benefitValue: e.complianceData.allCheck.data.registrationData.benefitValue === null || e.complianceData.allCheck.data.registrationData.benefitValue === undefined ? null : e.complianceData.allCheck.data.registrationData.benefitValue,
                        benefitStart: e.complianceData.allCheck.data.registrationData.benefitStart === null || e.complianceData.allCheck.data.registrationData.benefitStart === undefined ? null : e.complianceData.allCheck.data.registrationData.benefitStart,
                        titleNumber: e.complianceData.allCheck.data.registrationData.titleNumber === null || e.complianceData.allCheck.data.registrationData.titleNumber === undefined ? null : e.complianceData.allCheck.data.registrationData.titleNumber,
                        nationality: e.complianceData.allCheck.data.registrationData.nationality === null || e.complianceData.allCheck.data.registrationData.nationality === undefined ? null : e.complianceData.allCheck.data.registrationData.nationality,
                    },
                    emails: e.complianceData.allCheck.data.emails.map((e: any) => ({
                        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                        email: e.email === null || e.email === undefined ? null : e.email,
                    })),
                    death: e.complianceData.allCheck.data.death === null || e.complianceData.allCheck.data.death === undefined ? null : {
                        name: e.complianceData.allCheck.data.death.name === null || e.complianceData.allCheck.data.death.name === undefined ? null : e.complianceData.allCheck.data.death.name,
                        motherName: e.complianceData.allCheck.data.death.motherName === null || e.complianceData.allCheck.data.death.motherName === undefined ? null : e.complianceData.allCheck.data.death.motherName,
                        deathDate: e.complianceData.allCheck.data.death.deathDate === null || e.complianceData.allCheck.data.death.deathDate === undefined ? null : e.complianceData.allCheck.data.death.deathDate,
                        birthDate: e.complianceData.allCheck.data.death.birthDate === null || e.complianceData.allCheck.data.death.birthDate === undefined ? null : e.complianceData.allCheck.data.death.birthDate,
                    },
                    ppe: e.complianceData.allCheck.data.ppe === null || e.complianceData.allCheck.data.ppe === undefined ? null : {
                        status: e.complianceData.allCheck.data.ppe.status === null || e.complianceData.allCheck.data.ppe.status === undefined ? null : e.complianceData.allCheck.data.ppe.status,
                        name: e.complianceData.allCheck.data.ppe.name === null || e.complianceData.allCheck.data.ppe.name === undefined ? null : e.complianceData.allCheck.data.ppe.name,
                        birthDate: e.complianceData.allCheck.data.ppe.birthDate === null || e.complianceData.allCheck.data.ppe.birthDate === undefined ? null : e.complianceData.allCheck.data.ppe.birthDate,
                        titleNumber: e.complianceData.allCheck.data.ppe.titleNumber === null || e.complianceData.allCheck.data.ppe.titleNumber === undefined ? null : e.complianceData.allCheck.data.ppe.titleNumber,
                        degreeOfEducation: e.complianceData.allCheck.data.ppe.degreeOfEducation === null || e.complianceData.allCheck.data.ppe.degreeOfEducation === undefined ? null : e.complianceData.allCheck.data.ppe.degreeOfEducation,
                        civilStatus: e.complianceData.allCheck.data.ppe.civilStatus === null || e.complianceData.allCheck.data.ppe.civilStatus === undefined ? null : e.complianceData.allCheck.data.ppe.civilStatus,
                        nationality: e.complianceData.allCheck.data.ppe.nationality === null || e.complianceData.allCheck.data.ppe.nationality === undefined ? null : e.complianceData.allCheck.data.ppe.nationality,
                        birthCounty: e.complianceData.allCheck.data.ppe.birthCounty === null || e.complianceData.allCheck.data.ppe.birthCounty === undefined ? null : e.complianceData.allCheck.data.ppe.birthCounty,
                        ufBirth: e.complianceData.allCheck.data.ppe.ufBirth === null || e.complianceData.allCheck.data.ppe.ufBirth === undefined ? null : e.complianceData.allCheck.data.ppe.ufBirth,
                        occupationDescription: e.complianceData.allCheck.data.ppe.occupationDescription === null || e.complianceData.allCheck.data.ppe.occupationDescription === undefined ? null : e.complianceData.allCheck.data.ppe.occupationDescription,
                        nameUrna: e.complianceData.allCheck.data.ppe.nameUrna === null || e.complianceData.allCheck.data.ppe.nameUrna === undefined ? null : e.complianceData.allCheck.data.ppe.nameUrna,
                        accessionNumber: e.complianceData.allCheck.data.ppe.accessionNumber === null || e.complianceData.allCheck.data.ppe.accessionNumber === undefined ? null : e.complianceData.allCheck.data.ppe.accessionNumber,
                        partyNumber: e.complianceData.allCheck.data.ppe.partyNumber === null || e.complianceData.allCheck.data.ppe.partyNumber === undefined ? null : e.complianceData.allCheck.data.ppe.partyNumber,
                        partyInitials: e.complianceData.allCheck.data.ppe.partyInitials === null || e.complianceData.allCheck.data.ppe.partyInitials === undefined ? null : e.complianceData.allCheck.data.ppe.partyInitials,
                        partyName: e.complianceData.allCheck.data.ppe.partyName === null || e.complianceData.allCheck.data.ppe.partyName === undefined ? null : e.complianceData.allCheck.data.ppe.partyName,
                        initialsUf: e.complianceData.allCheck.data.ppe.initialsUf === null || e.complianceData.allCheck.data.ppe.initialsUf === undefined ? null : e.complianceData.allCheck.data.ppe.initialsUf,
                        county: e.complianceData.allCheck.data.ppe.county === null || e.complianceData.allCheck.data.ppe.county === undefined ? null : e.complianceData.allCheck.data.ppe.county,
                        responsibility: e.complianceData.allCheck.data.ppe.responsibility === null || e.complianceData.allCheck.data.ppe.responsibility === undefined ? null : e.complianceData.allCheck.data.ppe.responsibility,
                        power: e.complianceData.allCheck.data.ppe.power === null || e.complianceData.allCheck.data.ppe.power === undefined ? null : e.complianceData.allCheck.data.ppe.power,
                        institution: e.complianceData.allCheck.data.ppe.institution === null || e.complianceData.allCheck.data.ppe.institution === undefined ? null : e.complianceData.allCheck.data.ppe.institution,
                    },
                    address: e.complianceData.allCheck.data.address === null || e.complianceData.allCheck.data.address === undefined ? null : {
                        address: e.complianceData.allCheck.data.address.address === null || e.complianceData.allCheck.data.address.address === undefined ? null : e.complianceData.allCheck.data.address.address,
                        number: e.complianceData.allCheck.data.address.number === null || e.complianceData.allCheck.data.address.number === undefined ? null : e.complianceData.allCheck.data.address.number,
                        complement: e.complianceData.allCheck.data.address.complement === null || e.complianceData.allCheck.data.address.complement === undefined ? null : e.complianceData.allCheck.data.address.complement,
                        cep: e.complianceData.allCheck.data.address.cep === null || e.complianceData.allCheck.data.address.cep === undefined ? null : e.complianceData.allCheck.data.address.cep,
                        neighborhood: e.complianceData.allCheck.data.address.neighborhood === null || e.complianceData.allCheck.data.address.neighborhood === undefined ? null : e.complianceData.allCheck.data.address.neighborhood,
                        city: e.complianceData.allCheck.data.address.city === null || e.complianceData.allCheck.data.address.city === undefined ? null : e.complianceData.allCheck.data.address.city,
                        state: e.complianceData.allCheck.data.address.state === null || e.complianceData.allCheck.data.address.state === undefined ? null : e.complianceData.allCheck.data.address.state,
                    },
                    phones: e.complianceData.allCheck.data.phones.map((e: any) => ({
                        name: e.name === null || e.name === undefined ? null : e.name,
                        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        phoneType: e.phoneType === null || e.phoneType === undefined ? null : e.phoneType,
                        address: e.address === null || e.address === undefined ? null : e.address,
                        addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        complement: e.complement === null || e.complement === undefined ? null : e.complement,
                        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        installationDate: e.installationDate === null || e.installationDate === undefined ? null : e.installationDate,
                    })),
                    partners: e.complianceData.allCheck.data.partners.map((e: any) => ({
                        partner: e.partner === null || e.partner === undefined ? null : e.partner,
                        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
                        rg: e.rg === null || e.rg === undefined ? null : e.rg,
                        address: e.address === null || e.address === undefined ? null : e.address,
                        addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
                        addressComplement: e.addressComplement === null || e.addressComplement === undefined ? null : e.addressComplement,
                        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
                        stateRegistration: e.stateRegistration === null || e.stateRegistration === undefined ? null : e.stateRegistration,
                        participation: e.participation === null || e.participation === undefined ? null : e.participation,
                        dateTicket: e.dateTicket === null || e.dateTicket === undefined ? null : e.dateTicket,
                        startDate: e.startDate === null || e.startDate === undefined ? null : e.startDate,
                        name: e.name === null || e.name === undefined ? null : e.name,
                        buyAddress: e.buyAddress === null || e.buyAddress === undefined ? null : e.buyAddress,
                        buyAddressNumber: e.buyAddressNumber === null || e.buyAddressNumber === undefined ? null : e.buyAddressNumber,
                        companyComplement: e.companyComplement === null || e.companyComplement === undefined ? null : e.companyComplement,
                        companyNeighborhood: e.companyNeighborhood === null || e.companyNeighborhood === undefined ? null : e.companyNeighborhood,
                        companyState: e.companyState === null || e.companyState === undefined ? null : e.companyState,
                        companyCep: e.companyCep === null || e.companyCep === undefined ? null : e.companyCep,
                        companyPartner: e.companyPartner === null || e.companyPartner === undefined ? null : e.companyPartner,
                    })),
                    companies: e.complianceData.allCheck.data.companies === null || e.complianceData.allCheck.data.companies === undefined ? null : {
                        cnpj: e.complianceData.allCheck.data.companies.cnpj === null || e.complianceData.allCheck.data.companies.cnpj === undefined ? null : e.complianceData.allCheck.data.companies.cnpj,
                        name: e.complianceData.allCheck.data.companies.name === null || e.complianceData.allCheck.data.companies.name === undefined ? null : e.complianceData.allCheck.data.companies.name,
                        fantasy: e.complianceData.allCheck.data.companies.fantasy === null || e.complianceData.allCheck.data.companies.fantasy === undefined ? null : e.complianceData.allCheck.data.companies.fantasy,
                        openingDate: e.complianceData.allCheck.data.companies.openingDate === null || e.complianceData.allCheck.data.companies.openingDate === undefined ? null : e.complianceData.allCheck.data.companies.openingDate,
                        businessActivity: e.complianceData.allCheck.data.companies.businessActivity === null || e.complianceData.allCheck.data.companies.businessActivity === undefined ? null : e.complianceData.allCheck.data.companies.businessActivity,
                        cnae: e.complianceData.allCheck.data.companies.cnae === null || e.complianceData.allCheck.data.companies.cnae === undefined ? null : e.complianceData.allCheck.data.companies.cnae,
                        cnae2: e.complianceData.allCheck.data.companies.cnae2 === null || e.complianceData.allCheck.data.companies.cnae2 === undefined ? null : e.complianceData.allCheck.data.companies.cnae2,
                        juridicalNature: e.complianceData.allCheck.data.companies.juridicalNature === null || e.complianceData.allCheck.data.companies.juridicalNature === undefined ? null : e.complianceData.allCheck.data.companies.juridicalNature,
                        address: e.complianceData.allCheck.data.companies.address === null || e.complianceData.allCheck.data.companies.address === undefined ? null : e.complianceData.allCheck.data.companies.address,
                        number: e.complianceData.allCheck.data.companies.number === null || e.complianceData.allCheck.data.companies.number === undefined ? null : e.complianceData.allCheck.data.companies.number,
                        complement: e.complianceData.allCheck.data.companies.complement === null || e.complianceData.allCheck.data.companies.complement === undefined ? null : e.complianceData.allCheck.data.companies.complement,
                        cep: e.complianceData.allCheck.data.companies.cep === null || e.complianceData.allCheck.data.companies.cep === undefined ? null : e.complianceData.allCheck.data.companies.cep,
                        neighborhood: e.complianceData.allCheck.data.companies.neighborhood === null || e.complianceData.allCheck.data.companies.neighborhood === undefined ? null : e.complianceData.allCheck.data.companies.neighborhood,
                        city: e.complianceData.allCheck.data.companies.city === null || e.complianceData.allCheck.data.companies.city === undefined ? null : e.complianceData.allCheck.data.companies.city,
                        state: e.complianceData.allCheck.data.companies.state === null || e.complianceData.allCheck.data.companies.state === undefined ? null : e.complianceData.allCheck.data.companies.state,
                        cadastralSituation: e.complianceData.allCheck.data.companies.cadastralSituation === null || e.complianceData.allCheck.data.companies.cadastralSituation === undefined ? null : e.complianceData.allCheck.data.companies.cadastralSituation,
                        porte: e.complianceData.allCheck.data.companies.porte === null || e.complianceData.allCheck.data.companies.porte === undefined ? null : e.complianceData.allCheck.data.companies.porte,
                        type: e.complianceData.allCheck.data.companies.type === null || e.complianceData.allCheck.data.companies.type === undefined ? null : e.complianceData.allCheck.data.companies.type,
                        cadastralSituationDate: e.complianceData.allCheck.data.companies.cadastralSituationDate === null || e.complianceData.allCheck.data.companies.cadastralSituationDate === undefined ? null : e.complianceData.allCheck.data.companies.cadastralSituationDate,
                        specialSituation: e.complianceData.allCheck.data.companies.specialSituation === null || e.complianceData.allCheck.data.companies.specialSituation === undefined ? null : e.complianceData.allCheck.data.companies.specialSituation,
                        specialSituationDate: e.complianceData.allCheck.data.companies.specialSituationDate === null || e.complianceData.allCheck.data.companies.specialSituationDate === undefined ? null : e.complianceData.allCheck.data.companies.specialSituationDate,
                    },
                    vehicles: e.complianceData.allCheck.data.vehicles.map((e: any) => ({
                        name: e.name === null || e.name === undefined ? null : e.name,
                        document: e.document === null || e.document === undefined ? null : e.document,
                        plaque: e.plaque === null || e.plaque === undefined ? null : e.plaque,
                        renavam: e.renavam === null || e.renavam === undefined ? null : e.renavam,
                        brand: e.brand === null || e.brand === undefined ? null : e.brand,
                        fuel: e.fuel === null || e.fuel === undefined ? null : e.fuel,
                        manufacturingYear: e.manufacturingYear === null || e.manufacturingYear === undefined ? null : e.manufacturingYear,
                        chassis: e.chassis === null || e.chassis === undefined ? null : e.chassis,
                        address: e.address === null || e.address === undefined ? null : e.address,
                        number: e.number === null || e.number === undefined ? null : e.number,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        complement: e.complement === null || e.complement === undefined ? null : e.complement,
                        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        uf: e.uf === null || e.uf === undefined ? null : e.uf,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        ddd1: e.ddd1 === null || e.ddd1 === undefined ? null : e.ddd1,
                        phone1: e.phone1 === null || e.phone1 === undefined ? null : e.phone1,
                        movingDate: e.movingDate === null || e.movingDate === undefined ? null : e.movingDate,
                        baseYear: e.baseYear === null || e.baseYear === undefined ? null : e.baseYear,
                    })),
                    ccf: e.complianceData.allCheck.data.ccf.map((e: any) => ({
                        name: e.name === null || e.name === undefined ? null : e.name,
                        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                        occurrenceAmount: e.occurrenceAmount === null || e.occurrenceAmount === undefined ? null : e.occurrenceAmount,
                        bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
                        agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
                        agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        fax: e.fax === null || e.fax === undefined ? null : e.fax,
                        bank: e.bank === null || e.bank === undefined ? null : e.bank,
                        agency: e.agency === null || e.agency === undefined ? null : e.agency,
                        returnReason: e.returnReason === null || e.returnReason === undefined ? null : e.returnReason,
                        lastOccurrenceDate: e.lastOccurrenceDate === null || e.lastOccurrenceDate === undefined ? null : e.lastOccurrenceDate,
                    })),
                    BankData: e.complianceData.allCheck.data.BankData.map((e: any) => ({
                        bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
                        agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
                        agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
                        city: e.city === null || e.city === undefined ? null : e.city,
                        state: e.state === null || e.state === undefined ? null : e.state,
                        cep: e.cep === null || e.cep === undefined ? null : e.cep,
                        ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                        fax: e.fax === null || e.fax === undefined ? null : e.fax,
                        bank: e.bank === null || e.bank === undefined ? null : e.bank,
                        agencia: e.agencia === null || e.agencia === undefined ? null : e.agencia,
                    })),
                    workHistory: e.complianceData.allCheck.data.workHistory.map((e: any) => ({
                        admissionDate: e.admissionDate === null || e.admissionDate === undefined ? null : e.admissionDate,
                        nome: e.nome === null || e.nome === undefined ? null : e.nome,
                        cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
                        fre: e.fre === null || e.fre === undefined ? null : e.fre,
                        cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
                        nomeEmpresa: e.nomeEmpresa === null || e.nomeEmpresa === undefined ? null : e.nomeEmpresa,
                    })),
                    watchNetwork: e.complianceData.allCheck.data.watchNetwork.map((e: any) => ({
                        dateConsultation: e.dateConsultation === null || e.dateConsultation === undefined ? null : e.dateConsultation,
                        product: e.product === null || e.product === undefined ? null : e.product,
                        client: e.client === null || e.client === undefined ? null : e.client,
                        phone: e.phone === null || e.phone === undefined ? null : e.phone,
                    })),
                },
                error: e.complianceData.allCheck.error === null || e.complianceData.allCheck.error === undefined ? null : e.complianceData.allCheck.error,
                status: e.complianceData.allCheck.status,
            },
            pepAml: e.complianceData.pepAml === null || e.complianceData.pepAml === undefined ? null : {
                data: e.complianceData.pepAml.data === null || e.complianceData.pepAml.data === undefined ? null : {
                    id: e.complianceData.pepAml.data.id === null || e.complianceData.pepAml.data.id === undefined ? null : e.complianceData.pepAml.data.id,
                    name: e.complianceData.pepAml.data.name,
                    cpfOrCnpj: e.complianceData.pepAml.data.cpfOrCnpj,
                    classification: e.complianceData.pepAml.data.classification,
                    description: e.complianceData.pepAml.data.description,
                    startDate: e.complianceData.pepAml.data.startDate === null || e.complianceData.pepAml.data.startDate === undefined ? null : e.complianceData.pepAml.data.startDate,
                    endDate: e.complianceData.pepAml.data.endDate === null || e.complianceData.pepAml.data.endDate === undefined ? null : e.complianceData.pepAml.data.endDate,
                    deathDate: e.complianceData.pepAml.data.deathDate === null || e.complianceData.pepAml.data.deathDate === undefined ? null : e.complianceData.pepAml.data.deathDate,
                    city: e.complianceData.pepAml.data.city === null || e.complianceData.pepAml.data.city === undefined ? null : e.complianceData.pepAml.data.city,
                    state: e.complianceData.pepAml.data.state === null || e.complianceData.pepAml.data.state === undefined ? null : e.complianceData.pepAml.data.state,
                    officialFlag: e.complianceData.pepAml.data.officialFlag,
                    additionalInfo: e.complianceData.pepAml.data.additionalInfo,
                    score: e.complianceData.pepAml.data.score | 0,
                },
                error: e.complianceData.pepAml.error === null || e.complianceData.pepAml.error === undefined ? null : e.complianceData.pepAml.error,
                status: e.complianceData.pepAml.status,
            },
            lsaAml: e.complianceData.lsaAml === null || e.complianceData.lsaAml === undefined ? null : {
                data: e.complianceData.lsaAml.data === null || e.complianceData.lsaAml.data === undefined ? null : {
                    id: e.complianceData.lsaAml.data.id,
                    name: e.complianceData.lsaAml.data.name,
                    document: e.complianceData.lsaAml.data.document,
                    pseudonyms: e.complianceData.lsaAml.data.pseudonyms,
                    synthesis: e.complianceData.lsaAml.data.synthesis,
                    reason: e.complianceData.lsaAml.data.reason,
                    amountAbon: e.complianceData.lsaAml.data.amountAbon,
                    amountDesabon: e.complianceData.lsaAml.data.amountDesabon,
                    dateModification: e.complianceData.lsaAml.data.dateModification === null || e.complianceData.lsaAml.data.dateModification === undefined ? null : e.complianceData.lsaAml.data.dateModification,
                },
                error: e.complianceData.lsaAml.error === null || e.complianceData.lsaAml.error === undefined ? null : e.complianceData.lsaAml.error,
                status: e.complianceData.lsaAml.status,
            },
            lrnAml: e.complianceData.lrnAml === null || e.complianceData.lrnAml === undefined ? null : {
                data: e.complianceData.lrnAml.data === null || e.complianceData.lrnAml.data === undefined ? null : {
                    id: e.complianceData.lrnAml.data.id,
                    name: e.complianceData.lrnAml.data.name,
                    cpfOrCnpj: e.complianceData.lrnAml.data.cpfOrCnpj,
                    envolvimento: e.complianceData.lrnAml.data.envolvimento,
                    crimes: e.complianceData.lrnAml.data.crimes.map((e: any) => e),
                    data: e.complianceData.lrnAml.data.data === null || e.complianceData.lrnAml.data.data === undefined ? null : e.complianceData.lrnAml.data.data,
                    citadoMidia: !!e.complianceData.lrnAml.data.citadoMidia,
                    score: e.complianceData.lrnAml.data.score | 0,
                },
                error: e.complianceData.lrnAml.error === null || e.complianceData.lrnAml.error === undefined ? null : e.complianceData.lrnAml.error,
                status: e.complianceData.lrnAml.status,
            },
        },
    }));
}

export async function checkProofOfLifePJService(): Promise<boolean> {
  const ret = await makeRequest({name: "checkProofOfLifePJService", args: {}});
    return !!ret;
}

export async function deleteUserByCpf(cpf: string): Promise<boolean> {
  const args = {
        cpf: cpf,
  };
  const ret = await makeRequest({name: "deleteUserByCpf", args});
    return !!ret;
}

export async function deleteMEIByCnpj(cnpj: string): Promise<boolean> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "deleteMEIByCnpj", args});
    return !!ret;
}

export async function addAccountBalance(account: string, branch: string, amount: number): Promise<boolean> {
  const args = {
        account: account,
        branch: branch,
        amount: amount,
  };
  const ret = await makeRequest({name: "addAccountBalance", args});
    return !!ret;
}

export async function InternalTestCreateAccountPF(data: InternalCreateAccountPF): Promise<boolean> {
  const args = {
        data: {
            cpf: data.cpf,
            birthDate: data.birthDate === null || data.birthDate === undefined ? null : data.birthDate.toISOString().split("T")[0],
            name: data.name === null || data.name === undefined ? null : data.name,
            motherName: data.motherName === null || data.motherName === undefined ? null : data.motherName,
            email: data.email,
            phone: data.phone === null || data.phone === undefined ? null : data.phone,
            cep: data.cep,
            addressNumber: data.addressNumber === null || data.addressNumber === undefined ? null : data.addressNumber,
            partnerId: data.partnerId === null || data.partnerId === undefined ? null : data.partnerId,
            openAccount: !!data.openAccount,
            monthlyIncome: data.monthlyIncome === null || data.monthlyIncome === undefined ? null : data.monthlyIncome,
        },
  };
  const ret = await makeRequest({name: "InternalTestCreateAccountPF", args});
    return !!ret;
}

export async function internalTestCreateAccountMEI(data: InternalCreateAccountMEI): Promise<boolean> {
  const args = {
        data: {
            cpf: data.cpf,
            ownerBirthDate: data.ownerBirthDate === null || data.ownerBirthDate === undefined ? null : data.ownerBirthDate.toISOString().split("T")[0],
            ownerName: data.ownerName === null || data.ownerName === undefined ? null : data.ownerName,
            ownerMotherName: data.ownerMotherName === null || data.ownerMotherName === undefined ? null : data.ownerMotherName,
            email: data.email,
            phone: data.phone === null || data.phone === undefined ? null : data.phone,
            cep: data.cep,
            addressNumber: data.addressNumber === null || data.addressNumber === undefined ? null : data.addressNumber,
            cnpj: data.cnpj,
            foundationDate: data.foundationDate === null || data.foundationDate === undefined ? null : data.foundationDate.toISOString().split("T")[0],
            companyName: data.companyName === null || data.companyName === undefined ? null : data.companyName,
            fantasyName: data.fantasyName === null || data.fantasyName === undefined ? null : data.fantasyName,
            partnerId: data.partnerId === null || data.partnerId === undefined ? null : data.partnerId,
            openAccount: !!data.openAccount,
        },
  };
  const ret = await makeRequest({name: "internalTestCreateAccountMEI", args});
    return !!ret;
}

export async function internalTestRemoveToken(cpf: string): Promise<boolean> {
  const args = {
        cpf: cpf,
  };
  const ret = await makeRequest({name: "internalTestRemoveToken", args});
    return !!ret;
}

export async function internalTestUpdateAcertId(key: string): Promise<boolean> {
  const args = {
        key: key,
  };
  const ret = await makeRequest({name: "internalTestUpdateAcertId", args});
    return !!ret;
}

export async function internalTestDeleteUserMEIByCnpj(cnpj: string): Promise<boolean> {
  const args = {
        cnpj: cnpj,
  };
  const ret = await makeRequest({name: "internalTestDeleteUserMEIByCnpj", args});
    return !!ret;
}

export async function testFaceMatch(selfie: Buffer, document: Buffer): Promise<boolean> {
  const args = {
        selfie: selfie.toString("base64"),
        document: document.toString("base64"),
  };
  const ret = await makeRequest({name: "testFaceMatch", args});
    return !!ret;
}

export async function getMaximaId(): Promise<string> {
  const ret = await makeRequest({name: "getMaximaId", args: {}});
    return ret;
}

export async function sendNotification(title: string, message: string): Promise<Notification> {
  const args = {
        title: title,
        message: message,
  };
  const ret = await makeRequest({name: "sendNotification", args});
    return {
        id: ret.id,
        userId: ret.userId,
        userName: ret.userName,
        title: ret.title,
        message: ret.message,
        date: new Date(ret.date + "Z"),
        partnerIds: ret.partnerIds === null || ret.partnerIds === undefined ? null : ret.partnerIds.map((e: any) => e),
    };
}

export async function listNotification(): Promise<NotificationInfo[]> {
  const ret = await makeRequest({name: "listNotification", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        userId: e.userId,
        userName: e.userName,
        title: e.title,
        message: e.message,
        date: new Date(e.date + "Z"),
        partnerNames: e.partnerNames === null || e.partnerNames === undefined ? null : e.partnerNames.map((e: any) => e),
    }));
}

export async function getAudit(filterAudit: FilterAudit): Promise<AuditData> {
  const args = {
        filterAudit: {
            cpf: filterAudit.cpf === null || filterAudit.cpf === undefined ? null : filterAudit.cpf,
            userId: filterAudit.userId === null || filterAudit.userId === undefined ? null : filterAudit.userId,
            start: filterAudit.start === null || filterAudit.start === undefined ? null : (typeof filterAudit.start === "string" && (filterAudit.start as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterAudit.start as any).replace("Z", "") : filterAudit.start.toISOString().replace("Z", "")),
            end: filterAudit.end === null || filterAudit.end === undefined ? null : (typeof filterAudit.end === "string" && (filterAudit.end as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterAudit.end as any).replace("Z", "") : filterAudit.end.toISOString().replace("Z", "")),
            auditAction: filterAudit.auditAction === null || filterAudit.auditAction === undefined ? null : filterAudit.auditAction,
            limit: filterAudit.limit | 0,
        },
  };
  const ret = await makeRequest({name: "getAudit", args});
    return {
        data: ret.data.map((e: any) => ({
            id: e.id,
            cpf: e.cpf,
            name: e.name,
            userId: e.userId,
            dateTime: new Date(e.dateTime + "Z"),
            geoIp: !!e.geoIp,
            partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
            partnerName: e.partnerName === null || e.partnerName === undefined ? null : e.partnerName,
            ip: e.ip,
            requestId: e.requestId,
            device: {
                deviceId: e.device.deviceId,
                deviceType: e.device.deviceType,
                version: e.device.version === null || e.device.version === undefined ? null : e.device.version,
                browserUserAgent: e.device.browserUserAgent === null || e.device.browserUserAgent === undefined ? null : e.device.browserUserAgent,
                hardwareBrand: e.device.hardwareBrand === null || e.device.hardwareBrand === undefined ? null : e.device.hardwareBrand,
                hardwareModel: e.device.hardwareModel === null || e.device.hardwareModel === undefined ? null : e.device.hardwareModel,
                location: e.device.location === null || e.device.location === undefined ? null : {
                    lat: e.device.location.lat,
                    lng: e.device.location.lng,
                },
                tokenNotification: e.device.tokenNotification === null || e.device.tokenNotification === undefined ? null : e.device.tokenNotification,
            },
            auditType: e.auditType,
            transfer: e.transfer === null || e.transfer === undefined ? null : {
                date: new Date(e.transfer.date + "Z"),
                description: e.transfer.description === null || e.transfer.description === undefined ? null : e.transfer.description,
                amount: e.transfer.amount,
                finality: e.transfer.finality === null || e.transfer.finality === undefined ? null : e.transfer.finality,
                type: e.transfer.type,
                from: {
                    branch: e.transfer.from.branch,
                    account: e.transfer.from.account,
                    cpfCnpj: e.transfer.from.cpfCnpj,
                },
                to: {
                    bank: e.transfer.to.bank,
                    branch: e.transfer.to.branch,
                    account: e.transfer.to.account,
                    cpfCnpj: e.transfer.to.cpfCnpj === null || e.transfer.to.cpfCnpj === undefined ? null : e.transfer.to.cpfCnpj,
                    name: e.transfer.to.name,
                    type: e.transfer.to.type,
                },
                partnerId: e.transfer.partnerId,
                recurrent: e.transfer.recurrent === null || e.transfer.recurrent === undefined ? null : e.transfer.recurrent | 0,
            },
            bankSlip: e.bankSlip === null || e.bankSlip === undefined ? null : {
                nameBank: e.bankSlip.nameBank,
                codeBankDV: e.bankSlip.codeBankDV,
                digitableLine: e.bankSlip.digitableLine,
                codigoDeBarras: e.bankSlip.codigoDeBarras,
                paymentPlace: e.bankSlip.paymentPlace,
                dueDate: new Date(e.bankSlip.dueDate + "Z"),
                beneficiary: {
                    name: e.bankSlip.beneficiary.name,
                    agencyCode: e.bankSlip.beneficiary.agencyCode,
                },
                documentDate: new Date(e.bankSlip.documentDate + "Z"),
                documentNumber: e.bankSlip.documentNumber,
                documentSpecies: e.bankSlip.documentSpecies,
                accept: e.bankSlip.accept,
                proccessDate: new Date(e.bankSlip.proccessDate + "Z"),
                ourNumber: e.bankSlip.ourNumber,
                walet: e.bankSlip.walet,
                specie: e.bankSlip.specie,
                documentValue: e.bankSlip.documentValue,
                instructions: e.bankSlip.instructions,
                payer: {
                    name: e.bankSlip.payer.name,
                    documentNumber: e.bankSlip.payer.documentNumber,
                    address: {
                        city: e.bankSlip.payer.address.city,
                        state: e.bankSlip.payer.address.state,
                        street: e.bankSlip.payer.address.street,
                        postalCode: e.bankSlip.payer.address.postalCode,
                        neighborhood: e.bankSlip.payer.address.neighborhood,
                    },
                },
                barCode: e.bankSlip.barCode,
                clientCode: e.bankSlip.clientCode,
                yourNumber: e.bankSlip.yourNumber,
                typePerson: e.bankSlip.typePerson,
                link: e.bankSlip.link,
            },
            covenant: e.covenant === null || e.covenant === undefined ? null : {
                number: e.covenant.number,
                value: e.covenant.value === null || e.covenant.value === undefined ? null : e.covenant.value,
            },
            paymentSlip: e.paymentSlip === null || e.paymentSlip === undefined ? null : {
                branch: e.paymentSlip.branch,
                account: e.paymentSlip.account,
                barcode: e.paymentSlip.barcode,
                date: new Date(e.paymentSlip.date + "Z"),
                dueDateTime: e.paymentSlip.dueDateTime === null || e.paymentSlip.dueDateTime === undefined ? null : new Date(e.paymentSlip.dueDateTime + "Z"),
                description: e.paymentSlip.description,
                type: e.paymentSlip.type,
            },
            genericBody: e.genericBody === null || e.genericBody === undefined ? null : e.genericBody,
            prevState: e.prevState === null || e.prevState === undefined ? null : e.prevState,
            resume: e.resume === null || e.resume === undefined ? null : e.resume,
            permissionType: e.permissionType === null || e.permissionType === undefined ? null : e.permissionType,
            amount: e.amount === null || e.amount === undefined ? null : e.amount,
            requestedBy: e.requestedBy === null || e.requestedBy === undefined ? null : e.requestedBy,
        })),
        total: ret.total | 0,
    };
}

export async function getAuditAdmin(filterAudit: FilterAuditAdmin): Promise<AuditDataAdmin> {
  const args = {
        filterAudit: {
            cpf: filterAudit.cpf === null || filterAudit.cpf === undefined ? null : filterAudit.cpf,
            start: filterAudit.start === null || filterAudit.start === undefined ? null : (typeof filterAudit.start === "string" && (filterAudit.start as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterAudit.start as any).replace("Z", "") : filterAudit.start.toISOString().replace("Z", "")),
            end: filterAudit.end === null || filterAudit.end === undefined ? null : (typeof filterAudit.end === "string" && (filterAudit.end as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterAudit.end as any).replace("Z", "") : filterAudit.end.toISOString().replace("Z", "")),
            auditAction: filterAudit.auditAction === null || filterAudit.auditAction === undefined ? null : filterAudit.auditAction,
            limit: filterAudit.limit | 0,
        },
  };
  const ret = await makeRequest({name: "getAuditAdmin", args});
    return {
        data: ret.data.map((e: any) => ({
            id: e.id,
            cpf: e.cpf,
            name: e.name,
            userId: e.userId,
            dateTime: new Date(e.dateTime + "Z"),
            geoIp: !!e.geoIp,
            ip: e.ip,
            requestId: e.requestId,
            auditType: e.auditType,
            genericBody: e.genericBody === null || e.genericBody === undefined ? null : e.genericBody,
            prevState: e.prevState === null || e.prevState === undefined ? null : e.prevState,
            resume: e.resume === null || e.resume === undefined ? null : e.resume,
            device: e.device === null || e.device === undefined ? null : {
                deviceId: e.device.deviceId,
                deviceType: e.device.deviceType,
                version: e.device.version === null || e.device.version === undefined ? null : e.device.version,
                browserUserAgent: e.device.browserUserAgent === null || e.device.browserUserAgent === undefined ? null : e.device.browserUserAgent,
                hardwareBrand: e.device.hardwareBrand === null || e.device.hardwareBrand === undefined ? null : e.device.hardwareBrand,
                hardwareModel: e.device.hardwareModel === null || e.device.hardwareModel === undefined ? null : e.device.hardwareModel,
                location: e.device.location === null || e.device.location === undefined ? null : {
                    lat: e.device.location.lat,
                    lng: e.device.location.lng,
                },
                tokenNotification: e.device.tokenNotification === null || e.device.tokenNotification === undefined ? null : e.device.tokenNotification,
            },
        })),
        total: ret.total | 0,
    };
}

export async function exportConvenantConciliation(start: Date | null, end: Date): Promise<Buffer> {
  const args = {
        start: start === null || start === undefined ? null : start.toISOString().split("T")[0],
        end: end.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "exportConvenantConciliation", args});
    return Buffer.from(ret, "base64");
}

export async function exportWithdrawConciliation(start: Date | null, end: Date): Promise<Buffer> {
  const args = {
        start: start === null || start === undefined ? null : start.toISOString().split("T")[0],
        end: end.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "exportWithdrawConciliation", args});
    return Buffer.from(ret, "base64");
}

export async function getHistoryWorkflowByTargetId(targetId: string): Promise<History[]> {
  const args = {
        targetId: targetId,
  };
  const ret = await makeRequest({name: "getHistoryWorkflowByTargetId", args});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        resume: e.resume === null || e.resume === undefined ? null : e.resume,
        dateTime: new Date(e.dateTime + "Z"),
    }));
}

export async function getHistoryWorkflowByCompanyId(companyId: string): Promise<HistoryAudit[]> {
  const args = {
        companyId: companyId,
  };
  const ret = await makeRequest({name: "getHistoryWorkflowByCompanyId", args});
    return ret.map((e: any) => ({
        resume: e.resume === null || e.resume === undefined ? null : e.resume,
        name: e.name,
        dateTime: new Date(e.dateTime + "Z"),
    }));
}

export async function getVolumetry(filter: FilterVolumetry): Promise<Volumetry[]> {
  const args = {
        filter: {
            type: filter.type === null || filter.type === undefined ? null : filter.type,
            partnerId: filter.partnerId,
            startDate: filter.startDate === null || filter.startDate === undefined ? null : (typeof filter.startDate === "string" && (filter.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.startDate as any).replace("Z", "") : filter.startDate.toISOString().replace("Z", "")),
            endDate: filter.endDate === null || filter.endDate === undefined ? null : (typeof filter.endDate === "string" && (filter.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filter.endDate as any).replace("Z", "") : filter.endDate.toISOString().replace("Z", "")),
            withError: filter.withError === null || filter.withError === undefined ? null : !!filter.withError,
        },
  };
  const ret = await makeRequest({name: "getVolumetry", args});
    return ret.map((e: any) => ({
        partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
        type: e.type,
        quantity: e.quantity,
        quantityWithErrors: e.quantityWithErrors | 0,
    }));
}

export async function getBalanceWithDraw(): Promise<number> {
  const ret = await makeRequest({name: "getBalanceWithDraw", args: {}});
    return ret;
}

export async function getBalance(branch: string, account: string): Promise<Balance> {
  const args = {
        branch: branch,
        account: account,
  };
  const ret = await makeRequest({name: "getBalance", args});
    return {
        available: ret.available,
        blocked: ret.blocked,
    };
}

export async function getClipLaunderingData(document: string): Promise<ClipLaunderingData | null> {
  const args = {
        document: document,
  };
  const ret = await makeRequest({name: "getClipLaunderingData", args});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        name: ret.name === null || ret.name === undefined ? null : ret.name,
        type: ret.type === null || ret.type === undefined ? null : ret.type,
        doc: {
            assertiveness: ret.doc.assertiveness,
            number: ret.doc.number,
            situation: ret.doc.situation === null || ret.doc.situation === undefined ? null : ret.doc.situation,
        },
        involvement: ret.involvement === null || ret.involvement === undefined ? null : ret.involvement,
        crimes: ret.crimes.map((e: any) => e),
        news: ret.news.map((e: any) => ({
            title: e.title,
            reviewDate: e.reviewDate,
            content: e.content,
        })),
        pep: ret.pep === null || ret.pep === undefined ? null : ret.pep,
    };
}

export async function getAllCheckData(document: string): Promise<AllCheckData | null> {
  const args = {
        document: document,
  };
  const ret = await makeRequest({name: "getAllCheckData", args});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        registrationData: ret.registrationData === null || ret.registrationData === undefined ? null : {
            name: ret.registrationData.name === null || ret.registrationData.name === undefined ? null : ret.registrationData.name,
            documentNumber: ret.registrationData.documentNumber === null || ret.registrationData.documentNumber === undefined ? null : ret.registrationData.documentNumber,
            documentSource: ret.registrationData.documentSource === null || ret.registrationData.documentSource === undefined ? null : ret.registrationData.documentSource,
            rg: ret.registrationData.rg === null || ret.registrationData.rg === undefined ? null : ret.registrationData.rg,
            birthDate: ret.registrationData.birthDate === null || ret.registrationData.birthDate === undefined ? null : ret.registrationData.birthDate,
            age: ret.registrationData.age === null || ret.registrationData.age === undefined ? null : ret.registrationData.age,
            sign: ret.registrationData.sign === null || ret.registrationData.sign === undefined ? null : ret.registrationData.sign,
            email: ret.registrationData.email === null || ret.registrationData.email === undefined ? null : ret.registrationData.email,
            membership: ret.registrationData.membership === null || ret.registrationData.membership === undefined ? null : ret.registrationData.membership,
            pis: ret.registrationData.pis === null || ret.registrationData.pis === undefined ? null : ret.registrationData.pis,
            workPermit: ret.registrationData.workPermit === null || ret.registrationData.workPermit === undefined ? null : ret.registrationData.workPermit,
            cnpjEmployer: ret.registrationData.cnpjEmployer === null || ret.registrationData.cnpjEmployer === undefined ? null : ret.registrationData.cnpjEmployer,
            issueDate: ret.registrationData.issueDate === null || ret.registrationData.issueDate === undefined ? null : ret.registrationData.issueDate,
            activity: ret.registrationData.activity === null || ret.registrationData.activity === undefined ? null : ret.registrationData.activity,
            degreeOfEducation: ret.registrationData.degreeOfEducation === null || ret.registrationData.degreeOfEducation === undefined ? null : ret.registrationData.degreeOfEducation,
            gender: ret.registrationData.gender === null || ret.registrationData.gender === undefined ? null : ret.registrationData.gender,
            civilStatus: ret.registrationData.civilStatus === null || ret.registrationData.civilStatus === undefined ? null : ret.registrationData.civilStatus,
            benefitNumber: ret.registrationData.benefitNumber === null || ret.registrationData.benefitNumber === undefined ? null : ret.registrationData.benefitNumber,
            benefitValue: ret.registrationData.benefitValue === null || ret.registrationData.benefitValue === undefined ? null : ret.registrationData.benefitValue,
            benefitStart: ret.registrationData.benefitStart === null || ret.registrationData.benefitStart === undefined ? null : ret.registrationData.benefitStart,
            titleNumber: ret.registrationData.titleNumber === null || ret.registrationData.titleNumber === undefined ? null : ret.registrationData.titleNumber,
            nationality: ret.registrationData.nationality === null || ret.registrationData.nationality === undefined ? null : ret.registrationData.nationality,
        },
        emails: ret.emails.map((e: any) => ({
            documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
            email: e.email === null || e.email === undefined ? null : e.email,
        })),
        death: ret.death === null || ret.death === undefined ? null : {
            name: ret.death.name === null || ret.death.name === undefined ? null : ret.death.name,
            motherName: ret.death.motherName === null || ret.death.motherName === undefined ? null : ret.death.motherName,
            deathDate: ret.death.deathDate === null || ret.death.deathDate === undefined ? null : ret.death.deathDate,
            birthDate: ret.death.birthDate === null || ret.death.birthDate === undefined ? null : ret.death.birthDate,
        },
        ppe: ret.ppe === null || ret.ppe === undefined ? null : {
            status: ret.ppe.status === null || ret.ppe.status === undefined ? null : ret.ppe.status,
            name: ret.ppe.name === null || ret.ppe.name === undefined ? null : ret.ppe.name,
            birthDate: ret.ppe.birthDate === null || ret.ppe.birthDate === undefined ? null : ret.ppe.birthDate,
            titleNumber: ret.ppe.titleNumber === null || ret.ppe.titleNumber === undefined ? null : ret.ppe.titleNumber,
            degreeOfEducation: ret.ppe.degreeOfEducation === null || ret.ppe.degreeOfEducation === undefined ? null : ret.ppe.degreeOfEducation,
            civilStatus: ret.ppe.civilStatus === null || ret.ppe.civilStatus === undefined ? null : ret.ppe.civilStatus,
            nationality: ret.ppe.nationality === null || ret.ppe.nationality === undefined ? null : ret.ppe.nationality,
            birthCounty: ret.ppe.birthCounty === null || ret.ppe.birthCounty === undefined ? null : ret.ppe.birthCounty,
            ufBirth: ret.ppe.ufBirth === null || ret.ppe.ufBirth === undefined ? null : ret.ppe.ufBirth,
            occupationDescription: ret.ppe.occupationDescription === null || ret.ppe.occupationDescription === undefined ? null : ret.ppe.occupationDescription,
            nameUrna: ret.ppe.nameUrna === null || ret.ppe.nameUrna === undefined ? null : ret.ppe.nameUrna,
            accessionNumber: ret.ppe.accessionNumber === null || ret.ppe.accessionNumber === undefined ? null : ret.ppe.accessionNumber,
            partyNumber: ret.ppe.partyNumber === null || ret.ppe.partyNumber === undefined ? null : ret.ppe.partyNumber,
            partyInitials: ret.ppe.partyInitials === null || ret.ppe.partyInitials === undefined ? null : ret.ppe.partyInitials,
            partyName: ret.ppe.partyName === null || ret.ppe.partyName === undefined ? null : ret.ppe.partyName,
            initialsUf: ret.ppe.initialsUf === null || ret.ppe.initialsUf === undefined ? null : ret.ppe.initialsUf,
            county: ret.ppe.county === null || ret.ppe.county === undefined ? null : ret.ppe.county,
            responsibility: ret.ppe.responsibility === null || ret.ppe.responsibility === undefined ? null : ret.ppe.responsibility,
            power: ret.ppe.power === null || ret.ppe.power === undefined ? null : ret.ppe.power,
            institution: ret.ppe.institution === null || ret.ppe.institution === undefined ? null : ret.ppe.institution,
        },
        address: ret.address === null || ret.address === undefined ? null : {
            address: ret.address.address === null || ret.address.address === undefined ? null : ret.address.address,
            number: ret.address.number === null || ret.address.number === undefined ? null : ret.address.number,
            complement: ret.address.complement === null || ret.address.complement === undefined ? null : ret.address.complement,
            cep: ret.address.cep === null || ret.address.cep === undefined ? null : ret.address.cep,
            neighborhood: ret.address.neighborhood === null || ret.address.neighborhood === undefined ? null : ret.address.neighborhood,
            city: ret.address.city === null || ret.address.city === undefined ? null : ret.address.city,
            state: ret.address.state === null || ret.address.state === undefined ? null : ret.address.state,
        },
        phones: ret.phones.map((e: any) => ({
            name: e.name === null || e.name === undefined ? null : e.name,
            documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
            ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            phoneType: e.phoneType === null || e.phoneType === undefined ? null : e.phoneType,
            address: e.address === null || e.address === undefined ? null : e.address,
            addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
            cep: e.cep === null || e.cep === undefined ? null : e.cep,
            complement: e.complement === null || e.complement === undefined ? null : e.complement,
            neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
            city: e.city === null || e.city === undefined ? null : e.city,
            state: e.state === null || e.state === undefined ? null : e.state,
            installationDate: e.installationDate === null || e.installationDate === undefined ? null : e.installationDate,
        })),
        partners: ret.partners.map((e: any) => ({
            partner: e.partner === null || e.partner === undefined ? null : e.partner,
            cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
            rg: e.rg === null || e.rg === undefined ? null : e.rg,
            address: e.address === null || e.address === undefined ? null : e.address,
            addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
            addressComplement: e.addressComplement === null || e.addressComplement === undefined ? null : e.addressComplement,
            neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
            state: e.state === null || e.state === undefined ? null : e.state,
            cep: e.cep === null || e.cep === undefined ? null : e.cep,
            cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
            stateRegistration: e.stateRegistration === null || e.stateRegistration === undefined ? null : e.stateRegistration,
            participation: e.participation === null || e.participation === undefined ? null : e.participation,
            dateTicket: e.dateTicket === null || e.dateTicket === undefined ? null : e.dateTicket,
            startDate: e.startDate === null || e.startDate === undefined ? null : e.startDate,
            name: e.name === null || e.name === undefined ? null : e.name,
            buyAddress: e.buyAddress === null || e.buyAddress === undefined ? null : e.buyAddress,
            buyAddressNumber: e.buyAddressNumber === null || e.buyAddressNumber === undefined ? null : e.buyAddressNumber,
            companyComplement: e.companyComplement === null || e.companyComplement === undefined ? null : e.companyComplement,
            companyNeighborhood: e.companyNeighborhood === null || e.companyNeighborhood === undefined ? null : e.companyNeighborhood,
            companyState: e.companyState === null || e.companyState === undefined ? null : e.companyState,
            companyCep: e.companyCep === null || e.companyCep === undefined ? null : e.companyCep,
            companyPartner: e.companyPartner === null || e.companyPartner === undefined ? null : e.companyPartner,
        })),
        companies: ret.companies === null || ret.companies === undefined ? null : {
            cnpj: ret.companies.cnpj === null || ret.companies.cnpj === undefined ? null : ret.companies.cnpj,
            name: ret.companies.name === null || ret.companies.name === undefined ? null : ret.companies.name,
            fantasy: ret.companies.fantasy === null || ret.companies.fantasy === undefined ? null : ret.companies.fantasy,
            openingDate: ret.companies.openingDate === null || ret.companies.openingDate === undefined ? null : ret.companies.openingDate,
            businessActivity: ret.companies.businessActivity === null || ret.companies.businessActivity === undefined ? null : ret.companies.businessActivity,
            cnae: ret.companies.cnae === null || ret.companies.cnae === undefined ? null : ret.companies.cnae,
            cnae2: ret.companies.cnae2 === null || ret.companies.cnae2 === undefined ? null : ret.companies.cnae2,
            juridicalNature: ret.companies.juridicalNature === null || ret.companies.juridicalNature === undefined ? null : ret.companies.juridicalNature,
            address: ret.companies.address === null || ret.companies.address === undefined ? null : ret.companies.address,
            number: ret.companies.number === null || ret.companies.number === undefined ? null : ret.companies.number,
            complement: ret.companies.complement === null || ret.companies.complement === undefined ? null : ret.companies.complement,
            cep: ret.companies.cep === null || ret.companies.cep === undefined ? null : ret.companies.cep,
            neighborhood: ret.companies.neighborhood === null || ret.companies.neighborhood === undefined ? null : ret.companies.neighborhood,
            city: ret.companies.city === null || ret.companies.city === undefined ? null : ret.companies.city,
            state: ret.companies.state === null || ret.companies.state === undefined ? null : ret.companies.state,
            cadastralSituation: ret.companies.cadastralSituation === null || ret.companies.cadastralSituation === undefined ? null : ret.companies.cadastralSituation,
            porte: ret.companies.porte === null || ret.companies.porte === undefined ? null : ret.companies.porte,
            type: ret.companies.type === null || ret.companies.type === undefined ? null : ret.companies.type,
            cadastralSituationDate: ret.companies.cadastralSituationDate === null || ret.companies.cadastralSituationDate === undefined ? null : ret.companies.cadastralSituationDate,
            specialSituation: ret.companies.specialSituation === null || ret.companies.specialSituation === undefined ? null : ret.companies.specialSituation,
            specialSituationDate: ret.companies.specialSituationDate === null || ret.companies.specialSituationDate === undefined ? null : ret.companies.specialSituationDate,
        },
        vehicles: ret.vehicles.map((e: any) => ({
            name: e.name === null || e.name === undefined ? null : e.name,
            document: e.document === null || e.document === undefined ? null : e.document,
            plaque: e.plaque === null || e.plaque === undefined ? null : e.plaque,
            renavam: e.renavam === null || e.renavam === undefined ? null : e.renavam,
            brand: e.brand === null || e.brand === undefined ? null : e.brand,
            fuel: e.fuel === null || e.fuel === undefined ? null : e.fuel,
            manufacturingYear: e.manufacturingYear === null || e.manufacturingYear === undefined ? null : e.manufacturingYear,
            chassis: e.chassis === null || e.chassis === undefined ? null : e.chassis,
            address: e.address === null || e.address === undefined ? null : e.address,
            number: e.number === null || e.number === undefined ? null : e.number,
            cep: e.cep === null || e.cep === undefined ? null : e.cep,
            complement: e.complement === null || e.complement === undefined ? null : e.complement,
            neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
            city: e.city === null || e.city === undefined ? null : e.city,
            uf: e.uf === null || e.uf === undefined ? null : e.uf,
            ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            ddd1: e.ddd1 === null || e.ddd1 === undefined ? null : e.ddd1,
            phone1: e.phone1 === null || e.phone1 === undefined ? null : e.phone1,
            movingDate: e.movingDate === null || e.movingDate === undefined ? null : e.movingDate,
            baseYear: e.baseYear === null || e.baseYear === undefined ? null : e.baseYear,
        })),
        ccf: ret.ccf.map((e: any) => ({
            name: e.name === null || e.name === undefined ? null : e.name,
            documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
            occurrenceAmount: e.occurrenceAmount === null || e.occurrenceAmount === undefined ? null : e.occurrenceAmount,
            bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
            agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
            agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
            city: e.city === null || e.city === undefined ? null : e.city,
            state: e.state === null || e.state === undefined ? null : e.state,
            cep: e.cep === null || e.cep === undefined ? null : e.cep,
            ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            fax: e.fax === null || e.fax === undefined ? null : e.fax,
            bank: e.bank === null || e.bank === undefined ? null : e.bank,
            agency: e.agency === null || e.agency === undefined ? null : e.agency,
            returnReason: e.returnReason === null || e.returnReason === undefined ? null : e.returnReason,
            lastOccurrenceDate: e.lastOccurrenceDate === null || e.lastOccurrenceDate === undefined ? null : e.lastOccurrenceDate,
        })),
        BankData: ret.BankData.map((e: any) => ({
            bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
            agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
            agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
            city: e.city === null || e.city === undefined ? null : e.city,
            state: e.state === null || e.state === undefined ? null : e.state,
            cep: e.cep === null || e.cep === undefined ? null : e.cep,
            ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
            fax: e.fax === null || e.fax === undefined ? null : e.fax,
            bank: e.bank === null || e.bank === undefined ? null : e.bank,
            agencia: e.agencia === null || e.agencia === undefined ? null : e.agencia,
        })),
        workHistory: ret.workHistory.map((e: any) => ({
            admissionDate: e.admissionDate === null || e.admissionDate === undefined ? null : e.admissionDate,
            nome: e.nome === null || e.nome === undefined ? null : e.nome,
            cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
            fre: e.fre === null || e.fre === undefined ? null : e.fre,
            cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
            nomeEmpresa: e.nomeEmpresa === null || e.nomeEmpresa === undefined ? null : e.nomeEmpresa,
        })),
        watchNetwork: ret.watchNetwork.map((e: any) => ({
            dateConsultation: e.dateConsultation === null || e.dateConsultation === undefined ? null : e.dateConsultation,
            product: e.product === null || e.product === undefined ? null : e.product,
            client: e.client === null || e.client === undefined ? null : e.client,
            phone: e.phone === null || e.phone === undefined ? null : e.phone,
        })),
    };
}

export async function getComplianceData(document: string): Promise<ComplianceData> {
  const args = {
        document: document,
  };
  const ret = await makeRequest({name: "getComplianceData", args});
    return {
        clipLaundering: ret.clipLaundering === null || ret.clipLaundering === undefined ? null : {
            data: ret.clipLaundering.data === null || ret.clipLaundering.data === undefined ? null : {
                id: ret.clipLaundering.data.id,
                name: ret.clipLaundering.data.name === null || ret.clipLaundering.data.name === undefined ? null : ret.clipLaundering.data.name,
                type: ret.clipLaundering.data.type === null || ret.clipLaundering.data.type === undefined ? null : ret.clipLaundering.data.type,
                doc: {
                    assertiveness: ret.clipLaundering.data.doc.assertiveness,
                    number: ret.clipLaundering.data.doc.number,
                    situation: ret.clipLaundering.data.doc.situation === null || ret.clipLaundering.data.doc.situation === undefined ? null : ret.clipLaundering.data.doc.situation,
                },
                involvement: ret.clipLaundering.data.involvement === null || ret.clipLaundering.data.involvement === undefined ? null : ret.clipLaundering.data.involvement,
                crimes: ret.clipLaundering.data.crimes.map((e: any) => e),
                news: ret.clipLaundering.data.news.map((e: any) => ({
                    title: e.title,
                    reviewDate: e.reviewDate,
                    content: e.content,
                })),
                pep: ret.clipLaundering.data.pep === null || ret.clipLaundering.data.pep === undefined ? null : ret.clipLaundering.data.pep,
            },
            error: ret.clipLaundering.error === null || ret.clipLaundering.error === undefined ? null : ret.clipLaundering.error,
            status: ret.clipLaundering.status,
        },
        allCheck: ret.allCheck === null || ret.allCheck === undefined ? null : {
            data: ret.allCheck.data === null || ret.allCheck.data === undefined ? null : {
                id: ret.allCheck.data.id,
                registrationData: ret.allCheck.data.registrationData === null || ret.allCheck.data.registrationData === undefined ? null : {
                    name: ret.allCheck.data.registrationData.name === null || ret.allCheck.data.registrationData.name === undefined ? null : ret.allCheck.data.registrationData.name,
                    documentNumber: ret.allCheck.data.registrationData.documentNumber === null || ret.allCheck.data.registrationData.documentNumber === undefined ? null : ret.allCheck.data.registrationData.documentNumber,
                    documentSource: ret.allCheck.data.registrationData.documentSource === null || ret.allCheck.data.registrationData.documentSource === undefined ? null : ret.allCheck.data.registrationData.documentSource,
                    rg: ret.allCheck.data.registrationData.rg === null || ret.allCheck.data.registrationData.rg === undefined ? null : ret.allCheck.data.registrationData.rg,
                    birthDate: ret.allCheck.data.registrationData.birthDate === null || ret.allCheck.data.registrationData.birthDate === undefined ? null : ret.allCheck.data.registrationData.birthDate,
                    age: ret.allCheck.data.registrationData.age === null || ret.allCheck.data.registrationData.age === undefined ? null : ret.allCheck.data.registrationData.age,
                    sign: ret.allCheck.data.registrationData.sign === null || ret.allCheck.data.registrationData.sign === undefined ? null : ret.allCheck.data.registrationData.sign,
                    email: ret.allCheck.data.registrationData.email === null || ret.allCheck.data.registrationData.email === undefined ? null : ret.allCheck.data.registrationData.email,
                    membership: ret.allCheck.data.registrationData.membership === null || ret.allCheck.data.registrationData.membership === undefined ? null : ret.allCheck.data.registrationData.membership,
                    pis: ret.allCheck.data.registrationData.pis === null || ret.allCheck.data.registrationData.pis === undefined ? null : ret.allCheck.data.registrationData.pis,
                    workPermit: ret.allCheck.data.registrationData.workPermit === null || ret.allCheck.data.registrationData.workPermit === undefined ? null : ret.allCheck.data.registrationData.workPermit,
                    cnpjEmployer: ret.allCheck.data.registrationData.cnpjEmployer === null || ret.allCheck.data.registrationData.cnpjEmployer === undefined ? null : ret.allCheck.data.registrationData.cnpjEmployer,
                    issueDate: ret.allCheck.data.registrationData.issueDate === null || ret.allCheck.data.registrationData.issueDate === undefined ? null : ret.allCheck.data.registrationData.issueDate,
                    activity: ret.allCheck.data.registrationData.activity === null || ret.allCheck.data.registrationData.activity === undefined ? null : ret.allCheck.data.registrationData.activity,
                    degreeOfEducation: ret.allCheck.data.registrationData.degreeOfEducation === null || ret.allCheck.data.registrationData.degreeOfEducation === undefined ? null : ret.allCheck.data.registrationData.degreeOfEducation,
                    gender: ret.allCheck.data.registrationData.gender === null || ret.allCheck.data.registrationData.gender === undefined ? null : ret.allCheck.data.registrationData.gender,
                    civilStatus: ret.allCheck.data.registrationData.civilStatus === null || ret.allCheck.data.registrationData.civilStatus === undefined ? null : ret.allCheck.data.registrationData.civilStatus,
                    benefitNumber: ret.allCheck.data.registrationData.benefitNumber === null || ret.allCheck.data.registrationData.benefitNumber === undefined ? null : ret.allCheck.data.registrationData.benefitNumber,
                    benefitValue: ret.allCheck.data.registrationData.benefitValue === null || ret.allCheck.data.registrationData.benefitValue === undefined ? null : ret.allCheck.data.registrationData.benefitValue,
                    benefitStart: ret.allCheck.data.registrationData.benefitStart === null || ret.allCheck.data.registrationData.benefitStart === undefined ? null : ret.allCheck.data.registrationData.benefitStart,
                    titleNumber: ret.allCheck.data.registrationData.titleNumber === null || ret.allCheck.data.registrationData.titleNumber === undefined ? null : ret.allCheck.data.registrationData.titleNumber,
                    nationality: ret.allCheck.data.registrationData.nationality === null || ret.allCheck.data.registrationData.nationality === undefined ? null : ret.allCheck.data.registrationData.nationality,
                },
                emails: ret.allCheck.data.emails.map((e: any) => ({
                    documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                    email: e.email === null || e.email === undefined ? null : e.email,
                })),
                death: ret.allCheck.data.death === null || ret.allCheck.data.death === undefined ? null : {
                    name: ret.allCheck.data.death.name === null || ret.allCheck.data.death.name === undefined ? null : ret.allCheck.data.death.name,
                    motherName: ret.allCheck.data.death.motherName === null || ret.allCheck.data.death.motherName === undefined ? null : ret.allCheck.data.death.motherName,
                    deathDate: ret.allCheck.data.death.deathDate === null || ret.allCheck.data.death.deathDate === undefined ? null : ret.allCheck.data.death.deathDate,
                    birthDate: ret.allCheck.data.death.birthDate === null || ret.allCheck.data.death.birthDate === undefined ? null : ret.allCheck.data.death.birthDate,
                },
                ppe: ret.allCheck.data.ppe === null || ret.allCheck.data.ppe === undefined ? null : {
                    status: ret.allCheck.data.ppe.status === null || ret.allCheck.data.ppe.status === undefined ? null : ret.allCheck.data.ppe.status,
                    name: ret.allCheck.data.ppe.name === null || ret.allCheck.data.ppe.name === undefined ? null : ret.allCheck.data.ppe.name,
                    birthDate: ret.allCheck.data.ppe.birthDate === null || ret.allCheck.data.ppe.birthDate === undefined ? null : ret.allCheck.data.ppe.birthDate,
                    titleNumber: ret.allCheck.data.ppe.titleNumber === null || ret.allCheck.data.ppe.titleNumber === undefined ? null : ret.allCheck.data.ppe.titleNumber,
                    degreeOfEducation: ret.allCheck.data.ppe.degreeOfEducation === null || ret.allCheck.data.ppe.degreeOfEducation === undefined ? null : ret.allCheck.data.ppe.degreeOfEducation,
                    civilStatus: ret.allCheck.data.ppe.civilStatus === null || ret.allCheck.data.ppe.civilStatus === undefined ? null : ret.allCheck.data.ppe.civilStatus,
                    nationality: ret.allCheck.data.ppe.nationality === null || ret.allCheck.data.ppe.nationality === undefined ? null : ret.allCheck.data.ppe.nationality,
                    birthCounty: ret.allCheck.data.ppe.birthCounty === null || ret.allCheck.data.ppe.birthCounty === undefined ? null : ret.allCheck.data.ppe.birthCounty,
                    ufBirth: ret.allCheck.data.ppe.ufBirth === null || ret.allCheck.data.ppe.ufBirth === undefined ? null : ret.allCheck.data.ppe.ufBirth,
                    occupationDescription: ret.allCheck.data.ppe.occupationDescription === null || ret.allCheck.data.ppe.occupationDescription === undefined ? null : ret.allCheck.data.ppe.occupationDescription,
                    nameUrna: ret.allCheck.data.ppe.nameUrna === null || ret.allCheck.data.ppe.nameUrna === undefined ? null : ret.allCheck.data.ppe.nameUrna,
                    accessionNumber: ret.allCheck.data.ppe.accessionNumber === null || ret.allCheck.data.ppe.accessionNumber === undefined ? null : ret.allCheck.data.ppe.accessionNumber,
                    partyNumber: ret.allCheck.data.ppe.partyNumber === null || ret.allCheck.data.ppe.partyNumber === undefined ? null : ret.allCheck.data.ppe.partyNumber,
                    partyInitials: ret.allCheck.data.ppe.partyInitials === null || ret.allCheck.data.ppe.partyInitials === undefined ? null : ret.allCheck.data.ppe.partyInitials,
                    partyName: ret.allCheck.data.ppe.partyName === null || ret.allCheck.data.ppe.partyName === undefined ? null : ret.allCheck.data.ppe.partyName,
                    initialsUf: ret.allCheck.data.ppe.initialsUf === null || ret.allCheck.data.ppe.initialsUf === undefined ? null : ret.allCheck.data.ppe.initialsUf,
                    county: ret.allCheck.data.ppe.county === null || ret.allCheck.data.ppe.county === undefined ? null : ret.allCheck.data.ppe.county,
                    responsibility: ret.allCheck.data.ppe.responsibility === null || ret.allCheck.data.ppe.responsibility === undefined ? null : ret.allCheck.data.ppe.responsibility,
                    power: ret.allCheck.data.ppe.power === null || ret.allCheck.data.ppe.power === undefined ? null : ret.allCheck.data.ppe.power,
                    institution: ret.allCheck.data.ppe.institution === null || ret.allCheck.data.ppe.institution === undefined ? null : ret.allCheck.data.ppe.institution,
                },
                address: ret.allCheck.data.address === null || ret.allCheck.data.address === undefined ? null : {
                    address: ret.allCheck.data.address.address === null || ret.allCheck.data.address.address === undefined ? null : ret.allCheck.data.address.address,
                    number: ret.allCheck.data.address.number === null || ret.allCheck.data.address.number === undefined ? null : ret.allCheck.data.address.number,
                    complement: ret.allCheck.data.address.complement === null || ret.allCheck.data.address.complement === undefined ? null : ret.allCheck.data.address.complement,
                    cep: ret.allCheck.data.address.cep === null || ret.allCheck.data.address.cep === undefined ? null : ret.allCheck.data.address.cep,
                    neighborhood: ret.allCheck.data.address.neighborhood === null || ret.allCheck.data.address.neighborhood === undefined ? null : ret.allCheck.data.address.neighborhood,
                    city: ret.allCheck.data.address.city === null || ret.allCheck.data.address.city === undefined ? null : ret.allCheck.data.address.city,
                    state: ret.allCheck.data.address.state === null || ret.allCheck.data.address.state === undefined ? null : ret.allCheck.data.address.state,
                },
                phones: ret.allCheck.data.phones.map((e: any) => ({
                    name: e.name === null || e.name === undefined ? null : e.name,
                    documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                    ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                    phone: e.phone === null || e.phone === undefined ? null : e.phone,
                    phoneType: e.phoneType === null || e.phoneType === undefined ? null : e.phoneType,
                    address: e.address === null || e.address === undefined ? null : e.address,
                    addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
                    cep: e.cep === null || e.cep === undefined ? null : e.cep,
                    complement: e.complement === null || e.complement === undefined ? null : e.complement,
                    neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                    city: e.city === null || e.city === undefined ? null : e.city,
                    state: e.state === null || e.state === undefined ? null : e.state,
                    installationDate: e.installationDate === null || e.installationDate === undefined ? null : e.installationDate,
                })),
                partners: ret.allCheck.data.partners.map((e: any) => ({
                    partner: e.partner === null || e.partner === undefined ? null : e.partner,
                    cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
                    rg: e.rg === null || e.rg === undefined ? null : e.rg,
                    address: e.address === null || e.address === undefined ? null : e.address,
                    addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
                    addressComplement: e.addressComplement === null || e.addressComplement === undefined ? null : e.addressComplement,
                    neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                    state: e.state === null || e.state === undefined ? null : e.state,
                    cep: e.cep === null || e.cep === undefined ? null : e.cep,
                    cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
                    stateRegistration: e.stateRegistration === null || e.stateRegistration === undefined ? null : e.stateRegistration,
                    participation: e.participation === null || e.participation === undefined ? null : e.participation,
                    dateTicket: e.dateTicket === null || e.dateTicket === undefined ? null : e.dateTicket,
                    startDate: e.startDate === null || e.startDate === undefined ? null : e.startDate,
                    name: e.name === null || e.name === undefined ? null : e.name,
                    buyAddress: e.buyAddress === null || e.buyAddress === undefined ? null : e.buyAddress,
                    buyAddressNumber: e.buyAddressNumber === null || e.buyAddressNumber === undefined ? null : e.buyAddressNumber,
                    companyComplement: e.companyComplement === null || e.companyComplement === undefined ? null : e.companyComplement,
                    companyNeighborhood: e.companyNeighborhood === null || e.companyNeighborhood === undefined ? null : e.companyNeighborhood,
                    companyState: e.companyState === null || e.companyState === undefined ? null : e.companyState,
                    companyCep: e.companyCep === null || e.companyCep === undefined ? null : e.companyCep,
                    companyPartner: e.companyPartner === null || e.companyPartner === undefined ? null : e.companyPartner,
                })),
                companies: ret.allCheck.data.companies === null || ret.allCheck.data.companies === undefined ? null : {
                    cnpj: ret.allCheck.data.companies.cnpj === null || ret.allCheck.data.companies.cnpj === undefined ? null : ret.allCheck.data.companies.cnpj,
                    name: ret.allCheck.data.companies.name === null || ret.allCheck.data.companies.name === undefined ? null : ret.allCheck.data.companies.name,
                    fantasy: ret.allCheck.data.companies.fantasy === null || ret.allCheck.data.companies.fantasy === undefined ? null : ret.allCheck.data.companies.fantasy,
                    openingDate: ret.allCheck.data.companies.openingDate === null || ret.allCheck.data.companies.openingDate === undefined ? null : ret.allCheck.data.companies.openingDate,
                    businessActivity: ret.allCheck.data.companies.businessActivity === null || ret.allCheck.data.companies.businessActivity === undefined ? null : ret.allCheck.data.companies.businessActivity,
                    cnae: ret.allCheck.data.companies.cnae === null || ret.allCheck.data.companies.cnae === undefined ? null : ret.allCheck.data.companies.cnae,
                    cnae2: ret.allCheck.data.companies.cnae2 === null || ret.allCheck.data.companies.cnae2 === undefined ? null : ret.allCheck.data.companies.cnae2,
                    juridicalNature: ret.allCheck.data.companies.juridicalNature === null || ret.allCheck.data.companies.juridicalNature === undefined ? null : ret.allCheck.data.companies.juridicalNature,
                    address: ret.allCheck.data.companies.address === null || ret.allCheck.data.companies.address === undefined ? null : ret.allCheck.data.companies.address,
                    number: ret.allCheck.data.companies.number === null || ret.allCheck.data.companies.number === undefined ? null : ret.allCheck.data.companies.number,
                    complement: ret.allCheck.data.companies.complement === null || ret.allCheck.data.companies.complement === undefined ? null : ret.allCheck.data.companies.complement,
                    cep: ret.allCheck.data.companies.cep === null || ret.allCheck.data.companies.cep === undefined ? null : ret.allCheck.data.companies.cep,
                    neighborhood: ret.allCheck.data.companies.neighborhood === null || ret.allCheck.data.companies.neighborhood === undefined ? null : ret.allCheck.data.companies.neighborhood,
                    city: ret.allCheck.data.companies.city === null || ret.allCheck.data.companies.city === undefined ? null : ret.allCheck.data.companies.city,
                    state: ret.allCheck.data.companies.state === null || ret.allCheck.data.companies.state === undefined ? null : ret.allCheck.data.companies.state,
                    cadastralSituation: ret.allCheck.data.companies.cadastralSituation === null || ret.allCheck.data.companies.cadastralSituation === undefined ? null : ret.allCheck.data.companies.cadastralSituation,
                    porte: ret.allCheck.data.companies.porte === null || ret.allCheck.data.companies.porte === undefined ? null : ret.allCheck.data.companies.porte,
                    type: ret.allCheck.data.companies.type === null || ret.allCheck.data.companies.type === undefined ? null : ret.allCheck.data.companies.type,
                    cadastralSituationDate: ret.allCheck.data.companies.cadastralSituationDate === null || ret.allCheck.data.companies.cadastralSituationDate === undefined ? null : ret.allCheck.data.companies.cadastralSituationDate,
                    specialSituation: ret.allCheck.data.companies.specialSituation === null || ret.allCheck.data.companies.specialSituation === undefined ? null : ret.allCheck.data.companies.specialSituation,
                    specialSituationDate: ret.allCheck.data.companies.specialSituationDate === null || ret.allCheck.data.companies.specialSituationDate === undefined ? null : ret.allCheck.data.companies.specialSituationDate,
                },
                vehicles: ret.allCheck.data.vehicles.map((e: any) => ({
                    name: e.name === null || e.name === undefined ? null : e.name,
                    document: e.document === null || e.document === undefined ? null : e.document,
                    plaque: e.plaque === null || e.plaque === undefined ? null : e.plaque,
                    renavam: e.renavam === null || e.renavam === undefined ? null : e.renavam,
                    brand: e.brand === null || e.brand === undefined ? null : e.brand,
                    fuel: e.fuel === null || e.fuel === undefined ? null : e.fuel,
                    manufacturingYear: e.manufacturingYear === null || e.manufacturingYear === undefined ? null : e.manufacturingYear,
                    chassis: e.chassis === null || e.chassis === undefined ? null : e.chassis,
                    address: e.address === null || e.address === undefined ? null : e.address,
                    number: e.number === null || e.number === undefined ? null : e.number,
                    cep: e.cep === null || e.cep === undefined ? null : e.cep,
                    complement: e.complement === null || e.complement === undefined ? null : e.complement,
                    neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
                    city: e.city === null || e.city === undefined ? null : e.city,
                    uf: e.uf === null || e.uf === undefined ? null : e.uf,
                    ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                    phone: e.phone === null || e.phone === undefined ? null : e.phone,
                    ddd1: e.ddd1 === null || e.ddd1 === undefined ? null : e.ddd1,
                    phone1: e.phone1 === null || e.phone1 === undefined ? null : e.phone1,
                    movingDate: e.movingDate === null || e.movingDate === undefined ? null : e.movingDate,
                    baseYear: e.baseYear === null || e.baseYear === undefined ? null : e.baseYear,
                })),
                ccf: ret.allCheck.data.ccf.map((e: any) => ({
                    name: e.name === null || e.name === undefined ? null : e.name,
                    documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
                    occurrenceAmount: e.occurrenceAmount === null || e.occurrenceAmount === undefined ? null : e.occurrenceAmount,
                    bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
                    agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
                    agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
                    city: e.city === null || e.city === undefined ? null : e.city,
                    state: e.state === null || e.state === undefined ? null : e.state,
                    cep: e.cep === null || e.cep === undefined ? null : e.cep,
                    ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                    phone: e.phone === null || e.phone === undefined ? null : e.phone,
                    fax: e.fax === null || e.fax === undefined ? null : e.fax,
                    bank: e.bank === null || e.bank === undefined ? null : e.bank,
                    agency: e.agency === null || e.agency === undefined ? null : e.agency,
                    returnReason: e.returnReason === null || e.returnReason === undefined ? null : e.returnReason,
                    lastOccurrenceDate: e.lastOccurrenceDate === null || e.lastOccurrenceDate === undefined ? null : e.lastOccurrenceDate,
                })),
                BankData: ret.allCheck.data.BankData.map((e: any) => ({
                    bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
                    agencyName: e.agencyName === null || e.agencyName === undefined ? null : e.agencyName,
                    agencyAddress: e.agencyAddress === null || e.agencyAddress === undefined ? null : e.agencyAddress,
                    city: e.city === null || e.city === undefined ? null : e.city,
                    state: e.state === null || e.state === undefined ? null : e.state,
                    cep: e.cep === null || e.cep === undefined ? null : e.cep,
                    ddd: e.ddd === null || e.ddd === undefined ? null : e.ddd,
                    phone: e.phone === null || e.phone === undefined ? null : e.phone,
                    fax: e.fax === null || e.fax === undefined ? null : e.fax,
                    bank: e.bank === null || e.bank === undefined ? null : e.bank,
                    agencia: e.agencia === null || e.agencia === undefined ? null : e.agencia,
                })),
                workHistory: ret.allCheck.data.workHistory.map((e: any) => ({
                    admissionDate: e.admissionDate === null || e.admissionDate === undefined ? null : e.admissionDate,
                    nome: e.nome === null || e.nome === undefined ? null : e.nome,
                    cpf: e.cpf === null || e.cpf === undefined ? null : e.cpf,
                    fre: e.fre === null || e.fre === undefined ? null : e.fre,
                    cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
                    nomeEmpresa: e.nomeEmpresa === null || e.nomeEmpresa === undefined ? null : e.nomeEmpresa,
                })),
                watchNetwork: ret.allCheck.data.watchNetwork.map((e: any) => ({
                    dateConsultation: e.dateConsultation === null || e.dateConsultation === undefined ? null : e.dateConsultation,
                    product: e.product === null || e.product === undefined ? null : e.product,
                    client: e.client === null || e.client === undefined ? null : e.client,
                    phone: e.phone === null || e.phone === undefined ? null : e.phone,
                })),
            },
            error: ret.allCheck.error === null || ret.allCheck.error === undefined ? null : ret.allCheck.error,
            status: ret.allCheck.status,
        },
        pepAml: ret.pepAml === null || ret.pepAml === undefined ? null : {
            data: ret.pepAml.data === null || ret.pepAml.data === undefined ? null : {
                id: ret.pepAml.data.id === null || ret.pepAml.data.id === undefined ? null : ret.pepAml.data.id,
                name: ret.pepAml.data.name,
                cpfOrCnpj: ret.pepAml.data.cpfOrCnpj,
                classification: ret.pepAml.data.classification,
                description: ret.pepAml.data.description,
                startDate: ret.pepAml.data.startDate === null || ret.pepAml.data.startDate === undefined ? null : ret.pepAml.data.startDate,
                endDate: ret.pepAml.data.endDate === null || ret.pepAml.data.endDate === undefined ? null : ret.pepAml.data.endDate,
                deathDate: ret.pepAml.data.deathDate === null || ret.pepAml.data.deathDate === undefined ? null : ret.pepAml.data.deathDate,
                city: ret.pepAml.data.city === null || ret.pepAml.data.city === undefined ? null : ret.pepAml.data.city,
                state: ret.pepAml.data.state === null || ret.pepAml.data.state === undefined ? null : ret.pepAml.data.state,
                officialFlag: ret.pepAml.data.officialFlag,
                additionalInfo: ret.pepAml.data.additionalInfo,
                score: ret.pepAml.data.score | 0,
            },
            error: ret.pepAml.error === null || ret.pepAml.error === undefined ? null : ret.pepAml.error,
            status: ret.pepAml.status,
        },
        lsaAml: ret.lsaAml === null || ret.lsaAml === undefined ? null : {
            data: ret.lsaAml.data === null || ret.lsaAml.data === undefined ? null : {
                id: ret.lsaAml.data.id,
                name: ret.lsaAml.data.name,
                document: ret.lsaAml.data.document,
                pseudonyms: ret.lsaAml.data.pseudonyms,
                synthesis: ret.lsaAml.data.synthesis,
                reason: ret.lsaAml.data.reason,
                amountAbon: ret.lsaAml.data.amountAbon,
                amountDesabon: ret.lsaAml.data.amountDesabon,
                dateModification: ret.lsaAml.data.dateModification === null || ret.lsaAml.data.dateModification === undefined ? null : ret.lsaAml.data.dateModification,
            },
            error: ret.lsaAml.error === null || ret.lsaAml.error === undefined ? null : ret.lsaAml.error,
            status: ret.lsaAml.status,
        },
        lrnAml: ret.lrnAml === null || ret.lrnAml === undefined ? null : {
            data: ret.lrnAml.data === null || ret.lrnAml.data === undefined ? null : {
                id: ret.lrnAml.data.id,
                name: ret.lrnAml.data.name,
                cpfOrCnpj: ret.lrnAml.data.cpfOrCnpj,
                envolvimento: ret.lrnAml.data.envolvimento,
                crimes: ret.lrnAml.data.crimes.map((e: any) => e),
                data: ret.lrnAml.data.data === null || ret.lrnAml.data.data === undefined ? null : ret.lrnAml.data.data,
                citadoMidia: !!ret.lrnAml.data.citadoMidia,
                score: ret.lrnAml.data.score | 0,
            },
            error: ret.lrnAml.error === null || ret.lrnAml.error === undefined ? null : ret.lrnAml.error,
            status: ret.lrnAml.status,
        },
    };
}

export async function getToDoErros(): Promise<ToDoErros[]> {
  const ret = await makeRequest({name: "getToDoErros", args: {}});
    return ret.map((e: any) => ({
        id: e.id | 0,
        error: e.error,
        message: e.message,
        guilty: e.guilty === null || e.guilty === undefined ? null : e.guilty,
        createdAt: new Date(e.createdAt + "Z"),
        default: !!e.default,
        updatedAt: e.updatedAt === null || e.updatedAt === undefined ? null : new Date(e.updatedAt + "Z"),
    }));
}

export async function editMessageError(idError: number, message: string): Promise<ToDoErros> {
  const args = {
        idError: idError | 0,
        message: message,
  };
  const ret = await makeRequest({name: "editMessageError", args});
    return {
        id: ret.id | 0,
        error: ret.error,
        message: ret.message,
        guilty: ret.guilty === null || ret.guilty === undefined ? null : ret.guilty,
        createdAt: new Date(ret.createdAt + "Z"),
        default: !!ret.default,
        updatedAt: ret.updatedAt === null || ret.updatedAt === undefined ? null : new Date(ret.updatedAt + "Z"),
    };
}

export async function closeAccount(account: string, branch: string, comments: string, targetId: string, type: PfPj): Promise<string> {
  const args = {
        account: account,
        branch: branch,
        comments: comments,
        targetId: targetId,
        type: type,
  };
  const ret = await makeRequest({name: "closeAccount", args});
    return ret;
}

export async function changeUserPartnerId(id: string, type: PfPj, partnerId: string): Promise<void> {
  const args = {
        id: id,
        type: type,
        partnerId: partnerId,
  };
  await makeRequest({name: "changeUserPartnerId", args});
    return undefined;
}

export async function updateAccountAddress(id: string, fields: AddressInfo): Promise<void> {
  const args = {
        id: id,
        fields: {
            cep: fields.cep,
            street: fields.street,
            neighborhood: fields.neighborhood,
            number: fields.number,
            complement: fields.complement === null || fields.complement === undefined ? null : fields.complement,
            city: fields.city,
            state: fields.state,
            country: fields.country,
        },
  };
  await makeRequest({name: "updateAccountAddress", args});
    return undefined;
}

export async function updateUserBasicData(id: string, fields: UserBasicData): Promise<void> {
  const args = {
        id: id,
        fields: {
            email: fields.email,
            phone: fields.phone,
            filiacao1: fields.filiacao1,
            monthlyIncome: fields.monthlyIncome,
        },
  };
  await makeRequest({name: "updateUserBasicData", args});
    return undefined;
}

export async function getAccountInfoByCpf(cpf: string): Promise<UserAccountInfo> {
  const args = {
        cpf: cpf,
  };
  const ret = await makeRequest({name: "getAccountInfoByCpf", args});
    return {
        partnerId: ret.partnerId,
        partnerName: ret.partnerName,
        accountNumber: ret.accountNumber,
        birthDate: new Date(ret.birthDate + "Z"),
        email: ret.email,
    };
}

export async function filterProofOfLife(query: string | null): Promise<ProofOfLifeInfo[]> {
  const args = {
        query: query === null || query === undefined ? null : query,
  };
  const ret = await makeRequest({name: "filterProofOfLife", args});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        documentNumber: e.documentNumber,
        createdAt: new Date(e.createdAt + "Z"),
        status: e.status,
        detailedProofOfLifeInfo: e.detailedProofOfLifeInfo === null || e.detailedProofOfLifeInfo === undefined ? null : {
            selfieFilename: e.detailedProofOfLifeInfo.selfieFilename,
            oldUrlSelfie: e.detailedProofOfLifeInfo.oldUrlSelfie,
            newUrlSelfie: e.detailedProofOfLifeInfo.newUrlSelfie,
            acertIdKey: e.detailedProofOfLifeInfo.acertIdKey === null || e.detailedProofOfLifeInfo.acertIdKey === undefined ? null : e.detailedProofOfLifeInfo.acertIdKey,
            images: e.detailedProofOfLifeInfo.images === null || e.detailedProofOfLifeInfo.images === undefined ? null : e.detailedProofOfLifeInfo.images.map((e: any) => ({
                name: e.name,
                url: e.url,
            })),
            history: e.detailedProofOfLifeInfo.history === null || e.detailedProofOfLifeInfo.history === undefined ? null : e.detailedProofOfLifeInfo.history.map((e: any) => ({
                adminName: e.adminName,
                auditType: e.auditType,
            })),
        },
    }));
}

export async function getUserProofOfLife(userId: string): Promise<UserProofOfLife[]> {
  const args = {
        userId: userId,
  };
  const ret = await makeRequest({name: "getUserProofOfLife", args});
    return ret.map((e: any) => ({
        selfie: e.selfie,
        status: e.status,
        createdAt: new Date(e.createdAt + "Z"),
        acertIdKey: e.acertIdKey === null || e.acertIdKey === undefined ? null : e.acertIdKey,
        statusAcertId: e.statusAcertId === null || e.statusAcertId === undefined ? null : e.statusAcertId,
        dateResponseAcertId: e.dateResponseAcertId === null || e.dateResponseAcertId === undefined ? null : new Date(parseInt(e.dateResponseAcertId.split("-")[0], 10), parseInt(e.dateResponseAcertId.split("-")[1], 10) - 1, parseInt(e.dateResponseAcertId.split("-")[2], 10)),
        reason: e.reason === null || e.reason === undefined ? null : e.reason,
    }));
}

export async function approveProofOfLife(id: string): Promise<void> {
  const args = {
        id: id,
  };
  await makeRequest({name: "approveProofOfLife", args});
    return undefined;
}

export async function reproveProofOfLife(id: string): Promise<void> {
  const args = {
        id: id,
  };
  await makeRequest({name: "reproveProofOfLife", args});
    return undefined;
}

export async function getDetailedProofOfLifeInfo(id: string): Promise<DetailedProofOfLifeInfo> {
  const args = {
        id: id,
  };
  const ret = await makeRequest({name: "getDetailedProofOfLifeInfo", args});
    return {
        selfieFilename: ret.selfieFilename,
        oldUrlSelfie: ret.oldUrlSelfie,
        newUrlSelfie: ret.newUrlSelfie,
        acertIdKey: ret.acertIdKey === null || ret.acertIdKey === undefined ? null : ret.acertIdKey,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            name: e.name,
            url: e.url,
        })),
        history: ret.history === null || ret.history === undefined ? null : ret.history.map((e: any) => ({
            adminName: e.adminName,
            auditType: e.auditType,
        })),
    };
}

export async function requestReplacementCard(requestInfo: RequestCardBody): Promise<void> {
  const args = {
        requestInfo: {
            cpf: requestInfo.cpf,
            oldCardId: requestInfo.oldCardId,
        },
  };
  await makeRequest({name: "requestReplacementCard", args});
    return undefined;
}

export async function linkReplacedCard(linkInfo: LinkCardBody): Promise<void> {
  const args = {
        linkInfo: {
            cpf: linkInfo.cpf,
            oldCardId: linkInfo.oldCardId,
            newCardId: linkInfo.newCardId,
        },
  };
  await makeRequest({name: "linkReplacedCard", args});
    return undefined;
}

export async function getExcelCardReport(filter: ExcelCardReportFilter): Promise<Buffer> {
  const args = {
        filter: {
            cpf: filter.cpf === null || filter.cpf === undefined ? null : filter.cpf,
            partnerId: filter.partnerId === null || filter.partnerId === undefined ? null : filter.partnerId,
            includeVirtualCards: !!filter.includeVirtualCards,
        },
  };
  const ret = await makeRequest({name: "getExcelCardReport", args});
    return Buffer.from(ret, "base64");
}

export async function getCardReport(filter: CardReportFilter): Promise<CardReport> {
  const args = {
        filter: {
            partnerId: filter.partnerId === null || filter.partnerId === undefined ? null : filter.partnerId,
            cpf: filter.cpf === null || filter.cpf === undefined ? null : filter.cpf,
            limit: filter.limit | 0,
            offset: filter.offset | 0,
            includeVirtualCards: !!filter.includeVirtualCards,
        },
  };
  const ret = await makeRequest({name: "getCardReport", args});
    return {
        result: ret.result.map((e: any) => ({
            cardId: e.cardId,
            name: e.name,
            account: e.account,
            cpf: e.cpf,
            requestedDate: new Date(parseInt(e.requestedDate.split("-")[0], 10), parseInt(e.requestedDate.split("-")[1], 10) - 1, parseInt(e.requestedDate.split("-")[2], 10)),
            gatewayAccountId: e.gatewayAccountId === null || e.gatewayAccountId === undefined ? null : e.gatewayAccountId,
            gatewayCardId: e.gatewayCardId === null || e.gatewayCardId === undefined ? null : e.gatewayCardId,
            attempts: e.attempts | 0,
            partnerName: e.partnerName,
            cardType: e.cardType,
            requestStatus: e.requestStatus,
            cardStatus: e.cardStatus,
        })),
        limit: ret.limit | 0,
        offset: ret.offset | 0,
        total: ret.total | 0,
    };
}

export async function getQueueError(queueId: string): Promise<QueueError[]> {
  const args = {
        queueId: queueId,
  };
  const ret = await makeRequest({name: "getQueueError", args});
    return ret.map((e: any) => ({
        message: e.message,
    }));
}

export async function cancelCard(requestInfo: RequestCardBody): Promise<void> {
  const args = {
        requestInfo: {
            cpf: requestInfo.cpf,
            oldCardId: requestInfo.oldCardId,
        },
  };
  await makeRequest({name: "cancelCard", args});
    return undefined;
}

export async function sendNotificationByPartner(title: string, message: string, partnerIds: string[]): Promise<void> {
  const args = {
        title: title,
        message: message,
        partnerIds: partnerIds.map(e => e),
  };
  await makeRequest({name: "sendNotificationByPartner", args});
    return undefined;
}

export async function getFeaturesStatusByPartner(partnerId: string): Promise<FeaturesStatus[]> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getFeaturesStatusByPartner", args});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        description: e.description,
        slug: e.slug,
        isEnabledInPlatform: !!e.isEnabledInPlatform,
        status: e.status,
    }));
}

export async function getFeaturesStatusByUser(userId: string): Promise<FeaturesStatus[]> {
  const args = {
        userId: userId,
  };
  const ret = await makeRequest({name: "getFeaturesStatusByUser", args});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        description: e.description,
        slug: e.slug,
        isEnabledInPlatform: !!e.isEnabledInPlatform,
        status: e.status,
    }));
}

export async function setFeaturesToPartner(partnerId: string, features: FeatureStatusSetup[]): Promise<FeaturesStatus[]> {
  const args = {
        partnerId: partnerId,
        features: features.map(e => ({
            id: e.id,
            status: e.status,
        })),
  };
  const ret = await makeRequest({name: "setFeaturesToPartner", args});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        description: e.description,
        slug: e.slug,
        isEnabledInPlatform: !!e.isEnabledInPlatform,
        status: e.status,
    }));
}

export async function setFeaturesToUser(userId: string, features: FeatureStatusSetup[]): Promise<FeaturesStatus[]> {
  const args = {
        userId: userId,
        features: features.map(e => ({
            id: e.id,
            status: e.status,
        })),
  };
  const ret = await makeRequest({name: "setFeaturesToUser", args});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        description: e.description,
        slug: e.slug,
        isEnabledInPlatform: !!e.isEnabledInPlatform,
        status: e.status,
    }));
}

export async function getPushConfig(partnerId: string): Promise<string | null> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPushConfig", args});
    return ret === null || ret === undefined ? null : ret;
}

export async function setPushConfig(partnerid: string, apiKey: string | null): Promise<void> {
  const args = {
        partnerid: partnerid,
        apiKey: apiKey === null || apiKey === undefined ? null : apiKey,
  };
  await makeRequest({name: "setPushConfig", args});
    return undefined;
}

export async function getPartnerIdLot(): Promise<PartnerLot[]> {
  const ret = await makeRequest({name: "getPartnerIdLot", args: {}});
    return ret.map((e: any) => ({
        partnerId: e.partnerId,
        partnerName: e.partnerName === null || e.partnerName === undefined ? null : e.partnerName,
    }));
}

export async function getPartnerFilesLot(partnerId: string): Promise<PartnerFileLot[]> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getPartnerFilesLot", args});
    return ret.map((e: any) => ({
        partnerFileId: e.partnerFileId | 0,
        partnerId: e.partnerId,
        statusId: e.statusId,
        statusName: e.statusName,
        auditDateTimeRegistration: new Date(e.auditDateTimeRegistration + "Z"),
        partnerFileStatusShortDescription: e.partnerFileStatusShortDescription,
        partnerFileShortDescription: e.partnerFileShortDescription,
        listProspectStatus: e.listProspectStatus.map((e: any) => ({
            statusId: e.statusId,
            statusName: e.statusName,
            total: e.total | 0,
        })),
        partnerName: e.partnerName === null || e.partnerName === undefined ? null : e.partnerName,
        messageDaysNotification: e.messageDaysNotification === null || e.messageDaysNotification === undefined ? null : e.messageDaysNotification,
        campaignPositiveDate: e.campaignPositiveDate === null || e.campaignPositiveDate === undefined ? null : e.campaignPositiveDate,
    }));
}

export async function uploadPartnerFile(partnerId: string, fileDescription: string, fileName: string, fileType: string, dataFile: Buffer): Promise<void> {
  const args = {
        partnerId: partnerId,
        fileDescription: fileDescription,
        fileName: fileName,
        fileType: fileType,
        dataFile: dataFile.toString("base64"),
  };
  await makeRequest({name: "uploadPartnerFile", args});
    return undefined;
}

export async function getProspectByPartnerFileId(partnerFileId: string): Promise<Prospect[]> {
  const args = {
        partnerFileId: partnerFileId,
  };
  const ret = await makeRequest({name: "getProspectByPartnerFileId", args});
    return ret.map((e: any) => ({
        id: e.id | 0,
        date: e.date === null || e.date === undefined ? null : new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        doc: e.doc === null || e.doc === undefined ? null : e.doc,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        name: e.name === null || e.name === undefined ? null : e.name,
        mother: e.mother === null || e.mother === undefined ? null : e.mother,
        zipCode: e.zipCode === null || e.zipCode === undefined ? null : e.zipCode,
        address: e.address === null || e.address === undefined ? null : e.address,
        addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
        complementAddress: e.complementAddress === null || e.complementAddress === undefined ? null : e.complementAddress,
        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
        city: e.city === null || e.city === undefined ? null : e.city,
        state: e.state === null || e.state === undefined ? null : e.state,
        DDD: e.DDD === null || e.DDD === undefined ? null : e.DDD,
        cellNumber: e.cellNumber === null || e.cellNumber === undefined ? null : e.cellNumber,
        email: e.email === null || e.email === undefined ? null : e.email,
        gender: e.gender === null || e.gender === undefined ? null : e.gender,
        grossIncome: e.grossIncome === null || e.grossIncome === undefined ? null : e.grossIncome,
        equityValue: e.equityValue === null || e.equityValue === undefined ? null : e.equityValue,
        obs1: e.obs1 === null || e.obs1 === undefined ? null : e.obs1,
        obs2: e.obs2 === null || e.obs2 === undefined ? null : e.obs2,
        partnerFileId: e.partnerFileId | 0,
        statusId: e.statusId === null || e.statusId === undefined ? null : e.statusId,
        status: {
            statusId: e.status.statusId === null || e.status.statusId === undefined ? null : e.status.statusId,
            statusName: e.status.statusName === null || e.status.statusName === undefined ? null : e.status.statusName,
        },
        partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
        partnerName: e.partnerName === null || e.partnerName === undefined ? null : e.partnerName,
        partnerFileStatusDescription: e.partnerFileStatusDescription === null || e.partnerFileStatusDescription === undefined ? null : e.partnerFileStatusDescription,
        partnerFileDescription: e.partnerFileDescription === null || e.partnerFileDescription === undefined ? null : e.partnerFileDescription,
        partnerFileStatusId: e.partnerFileStatusId === null || e.partnerFileStatusId === undefined ? null : e.partnerFileStatusId,
        partnerFileStatusName: e.partnerFileStatusName === null || e.partnerFileStatusName === undefined ? null : e.partnerFileStatusName,
    }));
}

export async function changeStatusPartnerFile(partnerFileId: number, statusId: string, partnerFileDescription: string): Promise<void> {
  const args = {
        partnerFileId: partnerFileId | 0,
        statusId: statusId,
        partnerFileDescription: partnerFileDescription,
  };
  await makeRequest({name: "changeStatusPartnerFile", args});
    return undefined;
}

export async function changeStatusProspect(partnerFileId: number, prospectIds: number[], statusId: string, prospectStatusComment: string): Promise<void> {
  const args = {
        partnerFileId: partnerFileId | 0,
        prospectIds: prospectIds.map(e => e | 0),
        statusId: statusId,
        prospectStatusComment: prospectStatusComment,
  };
  await makeRequest({name: "changeStatusProspect", args});
    return undefined;
}

export async function sendMessagePositivize(partnerFilesId: number[], fileDescription: string): Promise<boolean> {
  const args = {
        partnerFilesId: partnerFilesId.map(e => e | 0),
        fileDescription: fileDescription,
  };
  const ret = await makeRequest({name: "sendMessagePositivize", args});
    return !!ret;
}

export async function prospectClearData(prospect: Prospect): Promise<Date> {
  const args = {
        prospect: {
            id: prospect.id | 0,
            date: prospect.date === null || prospect.date === undefined ? null : prospect.date.toISOString().split("T")[0],
            doc: prospect.doc === null || prospect.doc === undefined ? null : prospect.doc,
            birthdate: prospect.birthdate === null || prospect.birthdate === undefined ? null : prospect.birthdate.toISOString().split("T")[0],
            name: prospect.name === null || prospect.name === undefined ? null : prospect.name,
            mother: prospect.mother === null || prospect.mother === undefined ? null : prospect.mother,
            zipCode: prospect.zipCode === null || prospect.zipCode === undefined ? null : prospect.zipCode,
            address: prospect.address === null || prospect.address === undefined ? null : prospect.address,
            addressNumber: prospect.addressNumber === null || prospect.addressNumber === undefined ? null : prospect.addressNumber,
            complementAddress: prospect.complementAddress === null || prospect.complementAddress === undefined ? null : prospect.complementAddress,
            neighborhood: prospect.neighborhood === null || prospect.neighborhood === undefined ? null : prospect.neighborhood,
            city: prospect.city === null || prospect.city === undefined ? null : prospect.city,
            state: prospect.state === null || prospect.state === undefined ? null : prospect.state,
            DDD: prospect.DDD === null || prospect.DDD === undefined ? null : prospect.DDD,
            cellNumber: prospect.cellNumber === null || prospect.cellNumber === undefined ? null : prospect.cellNumber,
            email: prospect.email === null || prospect.email === undefined ? null : prospect.email,
            gender: prospect.gender === null || prospect.gender === undefined ? null : prospect.gender,
            grossIncome: prospect.grossIncome === null || prospect.grossIncome === undefined ? null : prospect.grossIncome,
            equityValue: prospect.equityValue === null || prospect.equityValue === undefined ? null : prospect.equityValue,
            obs1: prospect.obs1 === null || prospect.obs1 === undefined ? null : prospect.obs1,
            obs2: prospect.obs2 === null || prospect.obs2 === undefined ? null : prospect.obs2,
            partnerFileId: prospect.partnerFileId | 0,
            statusId: prospect.statusId === null || prospect.statusId === undefined ? null : prospect.statusId,
            status: {
                statusId: prospect.status.statusId === null || prospect.status.statusId === undefined ? null : prospect.status.statusId,
                statusName: prospect.status.statusName === null || prospect.status.statusName === undefined ? null : prospect.status.statusName,
            },
            partnerId: prospect.partnerId === null || prospect.partnerId === undefined ? null : prospect.partnerId,
            partnerName: prospect.partnerName === null || prospect.partnerName === undefined ? null : prospect.partnerName,
            partnerFileStatusDescription: prospect.partnerFileStatusDescription === null || prospect.partnerFileStatusDescription === undefined ? null : prospect.partnerFileStatusDescription,
            partnerFileDescription: prospect.partnerFileDescription === null || prospect.partnerFileDescription === undefined ? null : prospect.partnerFileDescription,
            partnerFileStatusId: prospect.partnerFileStatusId === null || prospect.partnerFileStatusId === undefined ? null : prospect.partnerFileStatusId,
            partnerFileStatusName: prospect.partnerFileStatusName === null || prospect.partnerFileStatusName === undefined ? null : prospect.partnerFileStatusName,
        },
  };
  const ret = await makeRequest({name: "prospectClearData", args});
    return new Date(parseInt(ret.split("-")[0], 10), parseInt(ret.split("-")[1], 10) - 1, parseInt(ret.split("-")[2], 10));
}

export async function prospectClearText(input: string): Promise<string> {
  const args = {
        input: input,
  };
  const ret = await makeRequest({name: "prospectClearText", args});
    return ret;
}

export async function getDownloadProspectValidation(partnerFilesId: number): Promise<Buffer> {
  const args = {
        partnerFilesId: partnerFilesId | 0,
  };
  const ret = await makeRequest({name: "getDownloadProspectValidation", args});
    return Buffer.from(ret, "base64");
}

export async function getProspectPositive(positiveDoc: string, positiveBirthDate: Date, partnerId: string): Promise<Prospect[]> {
  const args = {
        positiveDoc: positiveDoc,
        positiveBirthDate: (typeof positiveBirthDate === "string" && (positiveBirthDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (positiveBirthDate as any).replace("Z", "") : positiveBirthDate.toISOString().replace("Z", "")),
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getProspectPositive", args});
    return ret.map((e: any) => ({
        id: e.id | 0,
        date: e.date === null || e.date === undefined ? null : new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
        doc: e.doc === null || e.doc === undefined ? null : e.doc,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(parseInt(e.birthdate.split("-")[0], 10), parseInt(e.birthdate.split("-")[1], 10) - 1, parseInt(e.birthdate.split("-")[2], 10)),
        name: e.name === null || e.name === undefined ? null : e.name,
        mother: e.mother === null || e.mother === undefined ? null : e.mother,
        zipCode: e.zipCode === null || e.zipCode === undefined ? null : e.zipCode,
        address: e.address === null || e.address === undefined ? null : e.address,
        addressNumber: e.addressNumber === null || e.addressNumber === undefined ? null : e.addressNumber,
        complementAddress: e.complementAddress === null || e.complementAddress === undefined ? null : e.complementAddress,
        neighborhood: e.neighborhood === null || e.neighborhood === undefined ? null : e.neighborhood,
        city: e.city === null || e.city === undefined ? null : e.city,
        state: e.state === null || e.state === undefined ? null : e.state,
        DDD: e.DDD === null || e.DDD === undefined ? null : e.DDD,
        cellNumber: e.cellNumber === null || e.cellNumber === undefined ? null : e.cellNumber,
        email: e.email === null || e.email === undefined ? null : e.email,
        gender: e.gender === null || e.gender === undefined ? null : e.gender,
        grossIncome: e.grossIncome === null || e.grossIncome === undefined ? null : e.grossIncome,
        equityValue: e.equityValue === null || e.equityValue === undefined ? null : e.equityValue,
        obs1: e.obs1 === null || e.obs1 === undefined ? null : e.obs1,
        obs2: e.obs2 === null || e.obs2 === undefined ? null : e.obs2,
        partnerFileId: e.partnerFileId | 0,
        statusId: e.statusId === null || e.statusId === undefined ? null : e.statusId,
        status: {
            statusId: e.status.statusId === null || e.status.statusId === undefined ? null : e.status.statusId,
            statusName: e.status.statusName === null || e.status.statusName === undefined ? null : e.status.statusName,
        },
        partnerId: e.partnerId === null || e.partnerId === undefined ? null : e.partnerId,
        partnerName: e.partnerName === null || e.partnerName === undefined ? null : e.partnerName,
        partnerFileStatusDescription: e.partnerFileStatusDescription === null || e.partnerFileStatusDescription === undefined ? null : e.partnerFileStatusDescription,
        partnerFileDescription: e.partnerFileDescription === null || e.partnerFileDescription === undefined ? null : e.partnerFileDescription,
        partnerFileStatusId: e.partnerFileStatusId === null || e.partnerFileStatusId === undefined ? null : e.partnerFileStatusId,
        partnerFileStatusName: e.partnerFileStatusName === null || e.partnerFileStatusName === undefined ? null : e.partnerFileStatusName,
    }));
}

export async function changePositiveStatusProspect(positiveDoc: string, positiveBirthDate: Date, partnerId: string): Promise<void> {
  const args = {
        positiveDoc: positiveDoc,
        positiveBirthDate: (typeof positiveBirthDate === "string" && (positiveBirthDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (positiveBirthDate as any).replace("Z", "") : positiveBirthDate.toISOString().replace("Z", "")),
        partnerId: partnerId,
  };
  await makeRequest({name: "changePositiveStatusProspect", args});
    return undefined;
}

export async function getHistoricByPartnerFileId(partnerFileId: number): Promise<PartnerNotification[]> {
  const args = {
        partnerFileId: partnerFileId | 0,
  };
  const ret = await makeRequest({name: "getHistoricByPartnerFileId", args});
    return ret.map((e: any) => ({
        campaignId: e.campaignId | 0,
        campaignDate: new Date(parseInt(e.campaignDate.split("-")[0], 10), parseInt(e.campaignDate.split("-")[1], 10) - 1, parseInt(e.campaignDate.split("-")[2], 10)),
        campaignDescription: e.campaignDescription,
        partnerFileId: e.partnerFileId | 0,
        campaignPositiveDate: new Date(e.campaignPositiveDate + "Z"),
        statusDetail: e.statusDetail === null || e.statusDetail === undefined ? null : e.statusDetail,
        campaignPositiveStatusDescription: e.campaignPositiveStatusDescription === null || e.campaignPositiveStatusDescription === undefined ? null : e.campaignPositiveStatusDescription,
    }));
}

export async function createHistoricByPartnerFileId(partnerFileId: number, campaignDescription: string, partnerId: string): Promise<string> {
  const args = {
        partnerFileId: partnerFileId | 0,
        campaignDescription: campaignDescription,
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "createHistoricByPartnerFileId", args});
    return ret;
}

export async function getDownloadListForNotificationByPartnerFileId(partnerFileId: number): Promise<Buffer> {
  const args = {
        partnerFileId: partnerFileId | 0,
  };
  const ret = await makeRequest({name: "getDownloadListForNotificationByPartnerFileId", args});
    return Buffer.from(ret, "base64");
}

export async function getJourneyInfoByPartnerAndCPFAndBirthday(cpf: string, birthday: Date, partnerId: string): Promise<string> {
  const args = {
        cpf: cpf,
        birthday: birthday.toISOString().split("T")[0],
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getJourneyInfoByPartnerAndCPFAndBirthday", args});
    return ret;
}

export async function getJourneyInfoByPartner(partnerId: string): Promise<boolean> {
  const args = {
        partnerId: partnerId,
  };
  const ret = await makeRequest({name: "getJourneyInfoByPartner", args});
    return !!ret;
}

export async function insertPartnerFileAttachment(partnerFileId: number, partnerFileAttachmentDescription: string, partnerFileAttachmentFileType: string, partnerFileAttachmentFile: Buffer, partnerFileAttachmentFileName: string): Promise<void> {
  const args = {
        partnerFileId: partnerFileId | 0,
        partnerFileAttachmentDescription: partnerFileAttachmentDescription,
        partnerFileAttachmentFileType: partnerFileAttachmentFileType,
        partnerFileAttachmentFile: partnerFileAttachmentFile.toString("base64"),
        partnerFileAttachmentFileName: partnerFileAttachmentFileName,
  };
  await makeRequest({name: "insertPartnerFileAttachment", args});
    return undefined;
}

export async function insertPartnerFileComment(partnerFileCommentId: number, partnerFileCommentObs: string, partnerFileId: number): Promise<void> {
  const args = {
        partnerFileCommentId: partnerFileCommentId | 0,
        partnerFileCommentObs: partnerFileCommentObs,
        partnerFileId: partnerFileId | 0,
  };
  await makeRequest({name: "insertPartnerFileComment", args});
    return undefined;
}

export async function getDownloadPartnerFileAttachment(partnerFileAttachmentId: number): Promise<Buffer> {
  const args = {
        partnerFileAttachmentId: partnerFileAttachmentId | 0,
  };
  const ret = await makeRequest({name: "getDownloadPartnerFileAttachment", args});
    return Buffer.from(ret, "base64");
}

export async function getAttachmentByPartnerFileId(partnerFileId: number): Promise<PartnerFileAttachment[]> {
  const args = {
        partnerFileId: partnerFileId | 0,
  };
  const ret = await makeRequest({name: "getAttachmentByPartnerFileId", args});
    return ret.map((e: any) => ({
        partnerFileAttachmentId: e.partnerFileAttachmentId | 0,
        partnerFileAttachmentDescription: e.partnerFileAttachmentDescription,
        partnerFileAttachmentFile: e.partnerFileAttachmentFile === null || e.partnerFileAttachmentFile === undefined ? null : e.partnerFileAttachmentFile,
        partnerFileAttachmentFileType: e.partnerFileAttachmentFileType,
        partnerFileId: e.partnerFileId | 0,
        partnerFileAttachmentDateTime: e.partnerFileAttachmentDateTime,
        partnerFileAttachmentFileName: e.partnerFileAttachmentFileName === null || e.partnerFileAttachmentFileName === undefined ? null : e.partnerFileAttachmentFileName,
    }));
}

export async function getCommentsByPartnerFileId(partnerFileId: number): Promise<PartnerFileComment[]> {
  const args = {
        partnerFileId: partnerFileId | 0,
  };
  const ret = await makeRequest({name: "getCommentsByPartnerFileId", args});
    return ret.map((e: any) => ({
        partnerFileCommentId: e.partnerFileCommentId | 0,
        partnerFilecommentObs: e.partnerFilecommentObs,
        partnerFileId: e.partnerFileId | 0,
        partnerFileCommentDateTime: new Date(e.partnerFileCommentDateTime + "Z"),
    }));
}

export async function getPartnerFileStatusDescription(partnerFileId: number): Promise<string | null> {
  const args = {
        partnerFileId: partnerFileId | 0,
  };
  const ret = await makeRequest({name: "getPartnerFileStatusDescription", args});
    return ret === null || ret === undefined ? null : ret;
}

export async function getWhiteListSms(): Promise<WhiteListSms[]> {
  const ret = await makeRequest({name: "getWhiteListSms", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        cellNumber: e.cellNumber,
    }));
}

export async function setWhiteListSms(celNumber: string, id: string | null, isDeleted: boolean): Promise<WhiteListSms[]> {
  const args = {
        celNumber: celNumber,
        id: id === null || id === undefined ? null : id,
        isDeleted: !!isDeleted,
  };
  const ret = await makeRequest({name: "setWhiteListSms", args});
    return ret.map((e: any) => ({
        id: e.id,
        cellNumber: e.cellNumber,
    }));
}

export async function synchronizeSmsSending(partnerFileId: number, campaignId: string, messageSms: string): Promise<string> {
  const args = {
        partnerFileId: partnerFileId | 0,
        campaignId: campaignId,
        messageSms: messageSms,
  };
  const ret = await makeRequest({name: "synchronizeSmsSending", args});
    return ret;
}

export async function createReducedJourneyAccount(info: CreateReducedJourneyAccount, journeyType: JourneyType, deviceInfo: DeviceInfo): Promise<ReducedJourneyAccountResponse> {
  const args = {
        info: {
            partnerId: info.partnerId,
            name: info.name,
            motherName: info.motherName,
            cpf: info.cpf,
            birthDate: info.birthDate.toISOString().split("T")[0],
            gender: info.gender === null || info.gender === undefined ? null : info.gender,
            income: info.income,
            equity: info.equity,
            codeArea: info.codeArea,
            phone: info.phone,
            email: info.email,
            zipCode: info.zipCode,
            state: info.state,
            city: info.city,
            neighborhood: info.neighborhood,
            street: info.street,
            addressNumber: info.addressNumber,
            addressComplement: info.addressComplement === null || info.addressComplement === undefined ? null : info.addressComplement,
            selfie: info.selfie.toString("base64"),
            acceptTermsOfUse: !!info.acceptTermsOfUse,
            acceptContract: !!info.acceptContract,
            acceptLGPD: !!info.acceptLGPD,
            password: info.password,
            document: {
                documentType: info.document.documentType,
                imageFront: info.document.imageFront.toString("base64"),
                imageBack: info.document.imageBack === null || info.document.imageBack === undefined ? null : info.document.imageBack.toString("base64"),
            },
            ppe: info.ppe === null || info.ppe === undefined ? null : !!info.ppe,
            ppeInfo: info.ppeInfo === null || info.ppeInfo === undefined ? null : {
                relatedPpe: !!info.ppeInfo.relatedPpe,
                relatedPpeInfo: info.ppeInfo.relatedPpeInfo === null || info.ppeInfo.relatedPpeInfo === undefined ? null : {
                    name: info.ppeInfo.relatedPpeInfo.name,
                    relation: info.ppeInfo.relatedPpeInfo.relation,
                },
                start: info.ppeInfo.start.toISOString().split("T")[0],
                end: info.ppeInfo.end.toISOString().split("T")[0],
                role: info.ppeInfo.role,
            },
            fatca: info.fatca === null || info.fatca === undefined ? null : !!info.fatca,
        },
        journeyType: journeyType,
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
  };
  const ret = await makeRequest({name: "createReducedJourneyAccount", args});
    return {
        message: ret.message,
        complienceResponse: ret.complienceResponse,
    };
}

export async function createReducedJourneyAccountIOS(info: CreateReducedJourneyAccount, journeyType: JourneyType, deviceInfo: DeviceInfo): Promise<ReducedJourneyAccountResponse> {
  const args = {
        info: {
            partnerId: info.partnerId,
            name: info.name,
            motherName: info.motherName,
            cpf: info.cpf,
            birthDate: info.birthDate.toISOString().split("T")[0],
            gender: info.gender === null || info.gender === undefined ? null : info.gender,
            income: info.income,
            equity: info.equity,
            codeArea: info.codeArea,
            phone: info.phone,
            email: info.email,
            zipCode: info.zipCode,
            state: info.state,
            city: info.city,
            neighborhood: info.neighborhood,
            street: info.street,
            addressNumber: info.addressNumber,
            addressComplement: info.addressComplement === null || info.addressComplement === undefined ? null : info.addressComplement,
            selfie: info.selfie.toString("base64"),
            acceptTermsOfUse: !!info.acceptTermsOfUse,
            acceptContract: !!info.acceptContract,
            acceptLGPD: !!info.acceptLGPD,
            password: info.password,
            document: {
                documentType: info.document.documentType,
                imageFront: info.document.imageFront.toString("base64"),
                imageBack: info.document.imageBack === null || info.document.imageBack === undefined ? null : info.document.imageBack.toString("base64"),
            },
            ppe: info.ppe === null || info.ppe === undefined ? null : !!info.ppe,
            ppeInfo: info.ppeInfo === null || info.ppeInfo === undefined ? null : {
                relatedPpe: !!info.ppeInfo.relatedPpe,
                relatedPpeInfo: info.ppeInfo.relatedPpeInfo === null || info.ppeInfo.relatedPpeInfo === undefined ? null : {
                    name: info.ppeInfo.relatedPpeInfo.name,
                    relation: info.ppeInfo.relatedPpeInfo.relation,
                },
                start: info.ppeInfo.start.toISOString().split("T")[0],
                end: info.ppeInfo.end.toISOString().split("T")[0],
                role: info.ppeInfo.role,
            },
            fatca: info.fatca === null || info.fatca === undefined ? null : !!info.fatca,
        },
        journeyType: journeyType,
        deviceInfo: {
            deviceId: deviceInfo.deviceId,
            deviceType: deviceInfo.deviceType,
            version: deviceInfo.version === null || deviceInfo.version === undefined ? null : deviceInfo.version,
            browserUserAgent: deviceInfo.browserUserAgent === null || deviceInfo.browserUserAgent === undefined ? null : deviceInfo.browserUserAgent,
            hardwareBrand: deviceInfo.hardwareBrand === null || deviceInfo.hardwareBrand === undefined ? null : deviceInfo.hardwareBrand,
            hardwareModel: deviceInfo.hardwareModel === null || deviceInfo.hardwareModel === undefined ? null : deviceInfo.hardwareModel,
            location: deviceInfo.location === null || deviceInfo.location === undefined ? null : {
                lat: deviceInfo.location.lat,
                lng: deviceInfo.location.lng,
            },
            tokenNotification: deviceInfo.tokenNotification === null || deviceInfo.tokenNotification === undefined ? null : deviceInfo.tokenNotification,
        },
  };
  const ret = await makeRequest({name: "createReducedJourneyAccountIOS", args});
    return {
        message: ret.message,
        complienceResponse: ret.complienceResponse,
    };
}

export async function internalTestPositivizeReducedJourney(data: InternalPositivizeReducedJourney): Promise<boolean> {
  const args = {
        data: {
            cpf: data.cpf,
            birthday: data.birthday.toISOString().split("T")[0],
            partnerId: data.partnerId,
        },
  };
  const ret = await makeRequest({name: "internalTestPositivizeReducedJourney", args});
    return !!ret;
}

export async function getCities(uf: string): Promise<City[]> {
  const args = {
        uf: uf,
  };
  const ret = await makeRequest({name: "getCities", args});
    return ret.map((e: any) => ({
        code: e.code,
        name: e.name,
    }));
}

export async function getAddressByCep(zipcode: string): Promise<AddressInfo> {
  const args = {
        zipcode: zipcode,
  };
  const ret = await makeRequest({name: "getAddressByCep", args});
    return {
        cep: ret.cep,
        street: ret.street,
        neighborhood: ret.neighborhood,
        number: ret.number,
        complement: ret.complement === null || ret.complement === undefined ? null : ret.complement,
        city: ret.city,
        state: ret.state,
        country: ret.country,
    };
}

export async function getGroupChargeFiles(): Promise<GroupChargeFile[]> {
  const ret = await makeRequest({name: "getGroupChargeFiles", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        partnerId: e.partnerId,
        description: e.description,
        status: e.status,
        partnerAccount: e.partnerAccount,
        userId: e.userId,
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        convenantName: e.convenantName,
        totalItems: e.totalItems | 0,
        totalProcessedItems: e.totalProcessedItems | 0,
    }));
}

export async function getGroupChargeFileItemByFileId(id: string): Promise<GroupChargeFileItem[]> {
  const args = {
        id: id,
  };
  const ret = await makeRequest({name: "getGroupChargeFileItemByFileId", args});
    return ret.map((e: any) => ({
        id: e.id,
        groupChargeFileId: e.groupChargeFileId,
        accountNumber: e.accountNumber,
        documentNumber: e.documentNumber,
        amount: e.amount,
        status: e.status,
        validationDetails: e.validationDetails === null || e.validationDetails === undefined ? null : e.validationDetails,
        transferReturn: e.transferReturn === null || e.transferReturn === undefined ? null : e.transferReturn,
        processedAt: e.processedAt === null || e.processedAt === undefined ? null : new Date(e.processedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        reviews: e.reviews.map((e: any) => ({
            id: e.id,
            groupChargeItemId: e.groupChargeItemId,
            message: e.message,
            reviewType: e.reviewType,
            createdAt: new Date(e.createdAt + "Z"),
            updatedAt: new Date(e.updatedAt + "Z"),
        })),
    }));
}

export async function createGroupChargeFile(data: CreateGroupChargeFileParams): Promise<CreateGroupChargeFileResponse> {
  const args = {
        data: {
            file: data.file.toString("base64"),
            partnerId: data.partnerId,
            userId: data.userId,
            description: data.description,
            partnerAccount: data.partnerAccount,
            convenantName: data.convenantName,
        },
  };
  const ret = await makeRequest({name: "createGroupChargeFile", args});
    return {
        status: ret.status,
        id: ret.id,
    };
}

export async function getGroupChargeFileReport(id: string): Promise<Buffer> {
  const args = {
        id: id,
  };
  const ret = await makeRequest({name: "getGroupChargeFileReport", args});
    return Buffer.from(ret, "base64");
}

export async function createProspectPartner(data: ProspectData): Promise<void> {
  const args = {
        data: {
            name: data.name,
            email: data.email,
            company: data.company,
            companyType: data.companyType,
            companySector: data.companySector,
            companyWebsite: data.companyWebsite,
            companyEmployersQuantity: data.companyEmployersQuantity,
            potentialAccountsNumber: data.potentialAccountsNumber,
            prospectOrigin: data.prospectOrigin,
            message: data.message,
        },
  };
  await makeRequest({name: "createProspectPartner", args});
    return undefined;
}

export async function insertManualPJAccount(data: InsertManualPJAccountRequest): Promise<boolean> {
  const args = {
        data: {
            name: data.name,
            cnpj: data.cnpj,
            clientCode: data.clientCode,
            email: data.email,
            phone: data.phone,
            partnerId: data.partnerId,
            branch: data.branch,
            accountNumber: data.accountNumber,
        },
  };
  const ret = await makeRequest({name: "insertManualPJAccount", args});
    return !!ret;
}

export async function getAccountBalance(accountNumber: string): Promise<Balance> {
  const args = {
        accountNumber: accountNumber,
  };
  const ret = await makeRequest({name: "getAccountBalance", args});
    return {
        available: ret.available,
        blocked: ret.blocked,
    };
}

export async function getAccountStatement(accountNumber: string, period: Period | null): Promise<EntryStatement[]> {
  const args = {
        accountNumber: accountNumber,
        period: period === null || period === undefined ? null : {
            start: period.start === null || period.start === undefined ? null : period.start.toISOString().split("T")[0],
            end: period.end === null || period.end === undefined ? null : period.end.toISOString().split("T")[0],
        },
  };
  const ret = await makeRequest({name: "getAccountStatement", args});
    return ret.map((e: any) => ({
        id: e.id,
        movementNumber: e.movementNumber,
        type: e.type,
        description: e.description,
        finality: e.finality,
        nature: e.nature,
        prevBalance: e.prevBalance,
        currentBalance: e.currentBalance,
        movementDate: new Date(e.movementDate + "Z"),
        amount: e.amount,
        part: {
            name: e.part.name,
            cpfCnpj: e.part.cpfCnpj === null || e.part.cpfCnpj === undefined ? null : e.part.cpfCnpj,
            branch: e.part.branch,
            bank: e.part.bank === null || e.part.bank === undefined ? null : e.part.bank,
            account: e.part.account === null || e.part.account === undefined ? null : e.part.account,
        },
        counterPart: e.counterPart === null || e.counterPart === undefined ? null : {
            name: e.counterPart.name,
            cpfCnpj: e.counterPart.cpfCnpj === null || e.counterPart.cpfCnpj === undefined ? null : e.counterPart.cpfCnpj,
            branch: e.counterPart.branch,
            bank: e.counterPart.bank === null || e.counterPart.bank === undefined ? null : e.counterPart.bank,
            account: e.counterPart.account === null || e.counterPart.account === undefined ? null : e.counterPart.account,
        },
        devolutionReason: e.devolutionReason === null || e.devolutionReason === undefined ? null : e.devolutionReason,
        hasReceipt: !!e.hasReceipt,
    }));
}

export async function getPixAccountBalance(): Promise<Balance> {
  const ret = await makeRequest({name: "getPixAccountBalance", args: {}});
    return {
        available: ret.available,
        blocked: ret.blocked,
    };
}

export async function getPixAccountStatement(period: Period | null): Promise<EntryStatement[]> {
  const args = {
        period: period === null || period === undefined ? null : {
            start: period.start === null || period.start === undefined ? null : period.start.toISOString().split("T")[0],
            end: period.end === null || period.end === undefined ? null : period.end.toISOString().split("T")[0],
        },
  };
  const ret = await makeRequest({name: "getPixAccountStatement", args});
    return ret.map((e: any) => ({
        id: e.id,
        movementNumber: e.movementNumber,
        type: e.type,
        description: e.description,
        finality: e.finality,
        nature: e.nature,
        prevBalance: e.prevBalance,
        currentBalance: e.currentBalance,
        movementDate: new Date(e.movementDate + "Z"),
        amount: e.amount,
        part: {
            name: e.part.name,
            cpfCnpj: e.part.cpfCnpj === null || e.part.cpfCnpj === undefined ? null : e.part.cpfCnpj,
            branch: e.part.branch,
            bank: e.part.bank === null || e.part.bank === undefined ? null : e.part.bank,
            account: e.part.account === null || e.part.account === undefined ? null : e.part.account,
        },
        counterPart: e.counterPart === null || e.counterPart === undefined ? null : {
            name: e.counterPart.name,
            cpfCnpj: e.counterPart.cpfCnpj === null || e.counterPart.cpfCnpj === undefined ? null : e.counterPart.cpfCnpj,
            branch: e.counterPart.branch,
            bank: e.counterPart.bank === null || e.counterPart.bank === undefined ? null : e.counterPart.bank,
            account: e.counterPart.account === null || e.counterPart.account === undefined ? null : e.counterPart.account,
        },
        devolutionReason: e.devolutionReason === null || e.devolutionReason === undefined ? null : e.devolutionReason,
        hasReceipt: !!e.hasReceipt,
    }));
}

export async function getReceipt(account: string, movementNumber: string, date: Date): Promise<Receipt | null> {
  const args = {
        account: account,
        movementNumber: movementNumber,
        date: date.toISOString().split("T")[0],
  };
  const ret = await makeRequest({name: "getReceipt", args});
    return ret === null || ret === undefined ? null : {
        html: ret.html,
        image: ret.image,
    };
}

export async function getPendingApprovalsAsync(): Promise<PendingApprovalsResponse[]> {
  const ret = await makeRequest({name: "getPendingApprovalsAsync", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        branchNumber: e.branchNumber,
        accountNumber: e.accountNumber,
        previousValue: e.previousValue,
        changedValue: e.changedValue,
        limitType: e.limitType,
        createdAt: e.createdAt,
    }));
}

export async function exportCardConciliation(documentNumber: string | null, cardId: string | null, start: Date, end: Date, forced: boolean | null): Promise<Buffer> {
  const args = {
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
        cardId: cardId === null || cardId === undefined ? null : cardId,
        start: (typeof start === "string" && (start as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (start as any).replace("Z", "") : start.toISOString().replace("Z", "")),
        end: (typeof end === "string" && (end as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (end as any).replace("Z", "") : end.toISOString().replace("Z", "")),
        forced: forced === null || forced === undefined ? null : !!forced,
  };
  const ret = await makeRequest({name: "exportCardConciliation", args});
    return Buffer.from(ret, "base64");
}

export async function getWhitelistDomain(): Promise<WhitelistDomain[]> {
  const ret = await makeRequest({name: "getWhitelistDomain", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        domain: e.domain,
    }));
}

export async function getWhitelistEmail(): Promise<WhitelistEmail[]> {
  const ret = await makeRequest({name: "getWhitelistEmail", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
    }));
}

export async function getWhitelistLogin(): Promise<WhitelistLogin[]> {
  const ret = await makeRequest({name: "getWhitelistLogin", args: {}});
    return ret.map((e: any) => ({
        id: e.id,
        cpf: e.cpf,
    }));
}

export async function setWhitelistDomain(domain: string, id: string | null, deleted: boolean): Promise<WhitelistDomain[]> {
  const args = {
        domain: domain,
        id: id === null || id === undefined ? null : id,
        deleted: !!deleted,
  };
  const ret = await makeRequest({name: "setWhitelistDomain", args});
    return ret.map((e: any) => ({
        id: e.id,
        domain: e.domain,
    }));
}

export async function setWhitelistEmail(email: string, id: string | null, deleted: boolean): Promise<WhitelistEmail[]> {
  const args = {
        email: email,
        id: id === null || id === undefined ? null : id,
        deleted: !!deleted,
  };
  const ret = await makeRequest({name: "setWhitelistEmail", args});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
    }));
}

export async function setWhitelistLogin(cpf: string, id: string | null, deleted: boolean): Promise<WhitelistLogin[]> {
  const args = {
        cpf: cpf,
        id: id === null || id === undefined ? null : id,
        deleted: !!deleted,
  };
  const ret = await makeRequest({name: "setWhitelistLogin", args});
    return ret.map((e: any) => ({
        id: e.id,
        cpf: e.cpf,
    }));
}

export async function ping(): Promise<string> {
  const ret = await makeRequest({name: "ping", args: {}});
    return ret;
}

//////////////////////////////////////////////////////

function setDeviceId(deviceId: string) {
  try {
    localStorage.setItem("deviceId", deviceId);
    window.SDKGEN_DEVICE_ID = deviceId;
  } catch (e) {}
}

function getDeviceId() {
  try {
    return window.SDKGEN_DEVICE_ID || localStorage.getItem("deviceId");
  } catch (e) {}
  return randomBytesHex(16);
}

function randomBytesHex(len: number) {
  let hex = "";
  for (let i = 0; i < 2 * len; ++i)
    hex += "0123456789abcdef"[Math.floor(Math.random()*16)];
  return hex;
}

try {
  window.SDKGEN_DEVICE_ID = getDeviceId();
} catch (e) {}

if (!window.SDKGEN_DEVICE_ID) {
  window.SDKGEN_DEVICE_ID = randomBytesHex(16);
}

try {
  if (useSessionStorage) {
    window.SDKGEN_SESSION_ID = sessionStorage.getItem("sessionId") || null;
  } else {
    window.SDKGEN_SESSION_ID = localStorage.getItem("sessionId") || null;
  }
} catch (e) {}

export type ApiError = Error & { type: ErrorType };

export interface ListenerTypes {
  fail: (e: ApiError, name: string, args: any) => void;
  fatal: (e: Error, name: string, args: any) => void;
  success: (res: string, name: string, args: any) => void;
}

type HookArray = Array<Function>;
export type Listenables = keyof ListenerTypes;
type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
  fail: [],
  fatal: [],
  success: [],
};

export function addEventListener<K extends Listenables>(listenable: K, hook: ListenerTypes[K]) {
  const listeners: HookArray = listenersDict[listenable];
  listeners.push(hook);
}

export function removeEventListener<K extends Listenables>(listenable: K, hook: ListenerTypes[K]) {
  const listeners: HookArray = listenersDict[listenable];
  listenersDict[listenable] = listeners.filter(h => h !== hook) as any;
}

async function makeRequest({name, args}: {name: string, args: any}) {
  return new Promise<any>((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open("POST", baseUrl + "/" + name);

    const body = {
      deviceId: window.SDKGEN_DEVICE_ID,
      requestId: randomBytesHex(8),
      deviceFingerprint: null,
      sessionId: window.SDKGEN_SESSION_ID,
      partnerId: partnerId,
      name: name,
      args: args,
      info: {
        type: "browser",
        browserUserAgent: navigator.userAgent,
        language: navigator.language
      }
    };

    req.onreadystatechange = () => {
      if (req.readyState !== 4) return;
      try {
        const response = JSON.parse(req.responseText);

        try {
          window.SDKGEN_SESSION_ID = response.sessionId;
          setDeviceId(response.deviceId);

          try {
            if (useSessionStorage) {
              sessionStorage.setItem("sessionId", window.SDKGEN_SESSION_ID || "");
            } else {
              localStorage.setItem("sessionId", window.SDKGEN_SESSION_ID || "");
            }
          } catch (e) {}

          if (response.ok) {
            resolve(response.result);
            listenersDict["success"].forEach(hook => hook(response.result, name, args));
          } else {
            reject(response.error);
            listenersDict["fail"].forEach(hook => hook(response.error, name, args));
          }
        } catch (e) {
          console.error(e);
          reject({type: "Fatal", message: e.toString()});
          listenersDict["fatal"].forEach(hook => hook(e, name, args));
        }
      } catch (e) {
        console.error(e);
        if (!req.responseText) {
          reject({type: "NotConnect", message: `Você não está conectado ou o servidor não responde`});
        } else {
          reject({type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}`});
        }
        listenersDict["fatal"].forEach(hook => hook(e, name, args));
      }
    };
    req.send(JSON.stringify(body));
  });
}
