import React from "react";
import classNames from "classnames";
import RCTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

import s from "./style.scss";

type TooltipProps = React.ComponentProps<typeof RCTooltip>;

interface IProps extends TooltipProps {
  children: React.ReactElement;
}

export function Tooltip({ children, ...rest }: IProps) {
  return (
    <RCTooltip {...rest} overlayClassName={classNames(s.toolTipOverlay, rest.overlayClassName)}>
      {children}
    </RCTooltip>
  );
}
