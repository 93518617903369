/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import { inject } from "mobx-react";
import * as api from "api/manager-api";
import { DialogActions } from "@material-ui/core";
import { RaisedButton } from "legacy/components/RaisedButton";
import { TextInput } from "legacy/components/TextInput";
import { colors } from "utils";

import { Accordion } from "../../../components/Accordion";
import { Checkbox } from "../../../components/Checkbox";
import { GeneralStore, OpeningAccountsStore } from "../../../stores";
import { Loader } from "../../../components/Loader";
import { ConfirmDialog } from "../../../components/ConfirmDialog";

interface IProps {
  openingAccountsStore?: OpeningAccountsStore;
  generalStore?: GeneralStore;
  documentNumber: string;
  onConfirm: () => void;
}

interface IState {
  requestRevalidate: api.RegisterField[];
  loadingGetRegisterFields: boolean;
  loadingSubmitRevalidate: boolean;
  confirmDialog: boolean;
  obs: string;
  informationFields: api.RegisterFieldsPFInformationFields[];
  documentFields: api.RegisterFieldsPFDocuments[];
}

@inject("openingAccountsStore", "generalStore")
export class RevalidateDialog extends React.Component<IProps, IState> {
  public state: IState = {
    confirmDialog: false,
    documentFields: [],
    informationFields: [],
    loadingGetRegisterFields: true,
    loadingSubmitRevalidate: false,
    obs: "",
    requestRevalidate: [],
  };

  public componentDidMount() {
    this.getRegisterFields();
  }

  private getRegisterFields = async () => {
    this.setState({ loadingGetRegisterFields: true });

    await this.props.openingAccountsStore!.getRegisterFields();
    this.setState({
      documentFields: this.props.openingAccountsStore!.registerFields!.documents,
      informationFields: this.props.openingAccountsStore!.registerFields!.informationFields,
    });
    this.setState({ loadingGetRegisterFields: false });
  };

  private handleChange = (value: api.RegisterField) => {
    const idx = this.state.requestRevalidate.findIndex(e => e === value);
    const newRequestRevalidate = this.state.requestRevalidate.slice();

    if (idx === -1) {
      newRequestRevalidate.push(value);
    } else {
      newRequestRevalidate.splice(idx, 1);
    }

    this.setState({ requestRevalidate: newRequestRevalidate });
  };

  private onSubmit = () => {
    this.setState({ confirmDialog: true });
  };

  private onSetRevalidate = async () => {
    try {
      this.setState({
        confirmDialog: false,
        loadingSubmitRevalidate: true,
      });

      await this.props.openingAccountsStore!.submitRevalidate(
        this.props.documentNumber,
        this.state.requestRevalidate,
        this.state.obs,
      );

      this.setState({
        documentFields: this.props.openingAccountsStore!.registerFields!.documents,
        informationFields: this.props.openingAccountsStore!.registerFields!.informationFields,
      });
      this.props.onConfirm();
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao revalidar conta.");
    } finally {
      this.setState({ loadingSubmitRevalidate: false });
    }
  };

  public render() {
    const { requestRevalidate } = this.state;

    if (this.state.loadingGetRegisterFields) {
      return (
        <LoaderContainer>
          <Loader compact />
        </LoaderContainer>
      );
    }

    return (
      <>
        <ConfirmDialog
          title="Atenção"
          text="Você tem certeza que deseja fazer essas revalidações?"
          isOpen={this.state.confirmDialog}
          onCancel={() => this.setState({ confirmDialog: false })}
          onConfirm={this.onSetRevalidate}
        />
        <Container>
          <Accordion title="Informações">
            <Column>
              {this.state.informationFields.map(field => (
                <Row key={field.key}>
                  <Checkbox
                    checked={Boolean(requestRevalidate.find(e => e === field.key))}
                    handleChange={() => this.handleChange(field.key as api.RegisterField)}
                  />
                  <Text>{field.value}</Text>
                </Row>
              ))}
            </Column>
          </Accordion>
          <Accordion title="Documentos">
            <Column>
              {this.state.documentFields.map(field => (
                <Row key={field.key}>
                  <Checkbox
                    checked={Boolean(requestRevalidate.find(e => e === field.key))}
                    handleChange={() => this.handleChange(field.key as api.RegisterField)}
                  />
                  <Text>{field.value}</Text>
                </Row>
              ))}
            </Column>
          </Accordion>
          <Column style={{ marginBottom: "20px" }}>
            <Title>Observação</Title>
            <TextInput
              value={this.state.obs}
              onChange={e => {
                this.setState({ obs: e.target.value });
              }}
              multiline
            />
          </Column>
          <DialogActions>
            <StyledRaisedButton
              color="primary"
              onClick={this.onSubmit}
              label="Confirmar"
              loading={this.state.loadingSubmitRevalidate}
              disabled={requestRevalidate.length === 0}
            />
          </DialogActions>
        </Container>
      </>
    );
  }
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Container = styled.div`
  width: 400px;
  margin: 5px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

const Row = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  padding: 0;
  margin: 0;
  margin-left: 5px;
  width: 100% !important;
`;

const Title = styled.h5`
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
`;

const StyledRaisedButton = styled(RaisedButton)`
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
