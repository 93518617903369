import * as React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import { colors, coalesce } from "utils";
import { TextShow } from "../../TextShow";
import { Dialog } from "../../Dialog";
import * as api from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";
import { formatCnpj } from "@brazilian-utils/formatters";
import { OpeningAccountsStore, GeneralStore } from "../../../stores";

interface IProps {
  company: api.RegisterCompanyV13 | null;
  openingAccountsStore?: OpeningAccountsStore;
  generalStore?: GeneralStore;
}

interface IState {
  imageToDialog: any;
  imageDialogTitle: string;
  isLoadingGetLink: boolean;
  link: string;
}

@inject("openingAccountsStore", "generalStore")
@observer
export class PjOld extends React.Component<IProps, IState> {
  public state: IState = {
    imageToDialog: null,
    imageDialogTitle: "",
    isLoadingGetLink: false,
    link: "",
  };

  private getDownloadLink = async () => {
    try {
      this.setState({ isLoadingGetLink: true });
      const link = await this.props.openingAccountsStore!.getDocumentsCompanyDownloadLink(
        this.props.company!.documentNumber,
      );
      this.setState({ isLoadingGetLink: false, link }, () => {
        window.document.getElementById("targetDownloadLink")!.click();
      });
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro.");
    }
  };

  public render() {
    return (
      <>
        <a id="targetDownloadLink" href={this.state.link} download={this.props.company!.documentNumber} />
        <Dialog
          title={this.state.imageDialogTitle}
          isOpen={!!this.state.imageToDialog}
          onClose={() => this.setState({ imageToDialog: null })}
        >
          <Image url={this.state.imageToDialog} />
        </Dialog>
        <Container>
          <Column>{this.renderCompany()}</Column>
          <Column>
            <StyledRaisedButton
              onClick={this.getDownloadLink}
              label="Baixar arquivos"
              loading={this.state.isLoadingGetLink}
              width="180px"
              height="40px"
            />
          </Column>
        </Container>
      </>
    );
  }

  private renderCompany() {
    const { company } = this.props;

    if (!company) {
      return null;
    }

    return (
      <>
        <Title>Informações principais</Title>
        <WrapperTextShowMainInfo title="Razão social" text={company.name} />
        <WrapperTextShowMainInfo
          title="CNPJ"
          text={coalesce(company.documentNumber)((documentNumber: string) => formatCnpj(documentNumber))}
        />
        <WrapperTextShowMainInfo title="E-mail" text={company.email} />
        <WrapperTextShowMainInfo title="Telefone" text={company.phone} />
      </>
    );
  }
}

const Container = styled.main`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child) {
    border-right: 1px solid ${colors.borderGray};
  }
`;

const Title = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
  /* margin: 20px 0px 30px 0px; */
`;

const StyledRaisedButton = styled(RaisedButton)`
  margin: 20px;
`;

const WrapperTextShowMainInfo = styled(TextShow)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const Image = styled.div<{ url: any }>`
  width: 400px;
  height: 400px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
