import React from "react";
import ReactInputMask, { InputState, MaskOptions } from "react-input-mask";

import { IProps as IInputProps, Input } from "../Input";

export interface IInputMaskProps extends IInputProps {
  mask: string;
  maskChar?: string | null;
  placeholder?: string;
  beforeMaskedValueChange?:
    | ((newState: InputState, oldState: InputState, userInput: string, maskOptions: MaskOptions) => InputState)
    | undefined;
}

export function InputMask(props: IInputMaskProps) {
  const { addonAfter, addonBefore, prefix, suffix, error, ...rest } = props;

  return (
    <ReactInputMask {...rest}>
      {(inputProps: React.InputHTMLAttributes<HTMLInputElement>) => {
        return (
          <Input
            disabled={props.disabled}
            addonAfter={addonAfter}
            addonBefore={addonAfter}
            prefix={prefix}
            suffix={suffix}
            error={error}
            {...inputProps}
          />
        );
      }}
    </ReactInputMask>
  );
}
