import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";
import MaskedInput from "react-text-mask";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder: string;
  className?: string;
}

interface IState {
  prevValue: string;
}

const Input = styled(MaskedInput)`
  width: 63px;
  height: 32px;
  text-align: center;
  letter-spacing: 0.7px;
  color: ${colors.black};
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px ${colors.platinum};
  background-color: ${colors.white};
  outline: none;

  &:disabled {
    background-color: ${colors.extraLightGray};
  }
`;

export class TimeInput extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      prevValue: "",
    };
  }

  private onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ prevValue: this.props.value });
    e.target.setSelectionRange(0, 1);
  };

  private onBlur = () => {
    const regex = /^([0-2]\d):([0-5]\d)$/;
    if (this.props.value && !regex.test(this.props.value)) {
      this.props.onChange(this.state.prevValue);
    }
  };

  public render() {
    return (
      <Input
        value={this.props.value}
        onChange={(e: any) => this.props.onChange(e.target.value)}
        disabled={this.props.disabled}
        mask={[/[0-2]/, /\d/, ":", /[0-5]/, /\d/]}
        guide={false}
        placeholder={this.props.placeholder}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        className={this.props.className}
      />
    );
  }
}
