import { OutlinedButton } from "legacy/components/OutlinedButton";
import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;
export const ButtonDownload = styled(OutlinedButton)`
  margin: 0px 10px;
  width: auto;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;
