import React from "react";
import classNames from "classnames";

import s from "./style.scss";

interface IProps {
  children?: React.ReactNode;
  count: React.ReactNode;
  showZero?: boolean;
  dot?: boolean;
  overflowCount?: number;
  status?: StatusType;
  className?: string;
}

type StatusType = "success" | "processing" | "error" | "warning";

export function Badge(props: IProps) {
  function showBadge() {
    if (props.showZero && props.count !== undefined) {
      return true;
    }

    if (!props.showZero && props.count) {
      return true;
    }

    if (props.dot) {
      return true;
    }

    return false;
  }

  function showCount() {
    return !props.dot;
  }

  const isMultiple = (props.count as number) >= 10;
  const conditionalCount =
    (props.count as number) >= (props.overflowCount as number) ? `${props.overflowCount}+` : props.count;

  const badgeBallClassNames = classNames(
    s.badgeBall,
    s[`badgeBall-${props.status}`],
    { [s.dot]: props.dot },
    { [s["badgeBall-multiple"]]: isMultiple },
  );

  return (
    <span className={classNames(s.badge, props.className)}>
      {props.children}
      {showBadge() && <div className={badgeBallClassNames}>{showCount() && conditionalCount}</div>}
    </span>
  );
}

Badge.defaultProps = {
  overflowCount: 99,
  status: "error",
};
