import styled from "styled-components";
import { OutlinedButton } from "legacy/components/OutlinedButton";
import { RaisedButton } from "legacy/components/RaisedButton";

import { colors } from "../../../../../../../utils";
import { TextInput } from "../../../../../components/TextInput";

export const Left = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: flex-end;
`;

export const Title = styled.span`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 600px;
`;

export const Label = styled.span`
  display: flex;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
`;

export const StyledTextInput = styled(TextInput)`
  width: 275px;
  margin-top: 12px;
  font-size: 14px;
`;

export const IncomeTextInput = styled(TextInput)`
  width: 275px;
  margin-top: 12px;
  font-size: 14px;
`;

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 130px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
`;

export const StyledRaisedButton = styled(RaisedButton)`
  width: 130px;
  height: 40px;
  align-self: center;
  margin-top: 12px;
`;
