import * as React from "react";
import { SvgSuspensionPoints } from "../Icons/SvgSuspensionPoints";
import { Menu, MenuItem, withStyles } from "@material-ui/core";
import { noop } from "rxjs";

export interface IActionDropdownOptions {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IProps {
  options: IActionDropdownOptions[];
  classes?: any;
}

const styles = {
  root: { fontFamily: "Lato" },
};

const RawActionsDropdown: React.SFC<IProps> = props => {
  const button = React.createRef<HTMLDivElement>();
  const [anchor, setAnchor] = React.useState<HTMLDivElement | null>(null);
  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchor(button.current);
  };
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchor(null);
  };
  const handleItemClick = (o: IActionDropdownOptions) => (event: React.MouseEvent<HTMLElement>) => {
    (o.onClick || noop)(event);
    handleClose(event);
    event.stopPropagation();
  };

  return (
    <>
      <div data-button ref={button} onClick={handleOpen}>
        <SvgSuspensionPoints />
      </div>
      <Menu data-dropdown open={!!anchor} onClose={handleClose} anchorEl={anchor}>
        {props.options.map((o, i) => (
          <MenuItem classes={props.classes} key={i} onClick={handleItemClick(o)}>
            {o.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const ActionsDropdown = withStyles(styles)(RawActionsDropdown);
