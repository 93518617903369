import React, { forwardRef, RefObject } from "react";
import classnames from "classnames";

import s from "./style/style.scss";

export interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix"> {
  addonAfter?: React.ReactNode;
  addonBefore?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  error?: boolean;
}

function InputComp(
  props: IProps,
  ref: string | ((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement> | null | undefined,
) {
  const [isFocused, setIsFocused] = React.useState(false);
  const { addonAfter, addonBefore, className, prefix, suffix, onFocus, onBlur, error, ...rest } = props;

  function toggleFocus(event: React.FocusEvent<HTMLInputElement>) {
    if (isFocused && onBlur) {
      onBlur(event);
    } else if (onFocus) {
      onFocus(event);
    }

    setIsFocused(!isFocused);
  }

  const classNames = [
    s.input_wrapper,
    isFocused ? s.input_isFocused : null,
    props.disabled ? s.input_disabled : null,
    error ? s.input_error : null,
    className,
  ];
  const inputClassNames = [
    s.input,
    addonBefore || prefix ? s.input_has_before_element : null,
    addonAfter || suffix ? s.input_has_after_element : null,
  ];

  return (
    <div className={classnames(classNames)} data-testid="input">
      {addonBefore && <span className={s.input_addonBefore}>{addonBefore}</span>}
      {prefix && <span className={s.input_prefix}>{prefix}</span>}

      <input ref={ref} className={classnames(inputClassNames)} onFocus={toggleFocus} onBlur={toggleFocus} {...rest} />

      {suffix && <span className={s.input_suffix}>{suffix}</span>}
      {addonAfter && <span className={s.input_addonAfter}>{addonAfter}</span>}
    </div>
  );
}

export const Input = forwardRef(InputComp);
