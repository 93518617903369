import styled from "styled-components";
import { colors } from "utils";
import { RaisedButton } from "legacy/components/RaisedButton";

import { Select } from "./../../components/Select";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto auto 24px;
`;

export const ErrorMessage = styled.span`
  color: #f44336;
  font-size: 0.75rem;
`;
export const CustomSelect = styled(Select)<{
  hasError: boolean;
}>`
  ${props => {
    return props.hasError && `border: 1px solid red;`;
  }}
`;

export const InputContainer = styled.div`
  p {
    margin-top: 0;
  }
`;

export const CheckboxContainer = styled.div`
  width: 300px;
  margin-bottom: 17px;
  label {
    display: block;
    margin-bottom: 8px;
  }
  > span {
    padding: 0;
    margin-bottom: 8px;
  }
`;
export const DialogContent = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.black};

  span {
    font-size: 14px;
  }
`;

export const Title = styled.h2`
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.black};
  margin: 0px;
`;

export const FormContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-content: space-between;
  margin: 64px auto auto 8px;
`;

export const InputLabel = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

export const RaisedButtonWrapper = styled(RaisedButton)`
  width: 114px;
  height: 40px;
  border-radius: 4px;
`;

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: space-between;
  margin-top: 24px;
`;

export const HistoryTitle = styled.h2`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.black};
`;
