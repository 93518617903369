import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

interface IProps {
  title: string;
  text?: string | null;
  className?: string;
  invertColor?: boolean;
}

const Container = styled.div`
  display: flex;
`;

const Title = styled.span<{ invert?: boolean }>`
  min-width: 160px;
  padding-right: 20px;
  font-family: Lato;
  font-size: 14px;
  color: ${props => (props.invert ? colors.black : colors.gray)};
`;

const Text = styled.span<{ set?: boolean; invert?: boolean }>`
  font-family: Lato;
  font-size: 14px;
  color: ${props => (props.invert ? colors.gray : colors.black)};
  font-style: ${({ set }) => (set ? "inherit" : "italic")};
`;

export const TextShow = (props: IProps) => {
  return (
    <Container className={props.className}>
      <Title invert={props.invertColor}>{props.title}</Title>
      <Text set={!!props.text} invert={props.invertColor}>
        {props.text || "Não informado"}
      </Text>
    </Container>
  );
};
