exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "@keyframes styles__fadeInBg__20g_L{from{background:rgba(40,40,51,0);backdrop-filter:blur(0)}to{background:rgba(40,40,51,.4);backdrop-filter:blur(1px)}}@keyframes styles__fadeInContainer__1onCc{from{opacity:0;transform:scale(0.8) translate3d(0, 100px, 0)}}.styles__modal__2rFRd{position:fixed;top:0;left:0;width:100vw;height:100vh;animation:styles__fadeInBg__20g_L ease-out .3s forwards;animation-iteration-count:1;display:flex;padding:8px;overflow:auto}.styles__modalContainer__19Hak{background-color:#fff;animation:styles__fadeInContainer__1onCc ease-out .3s;box-shadow:0px 2px 15px rgba(0,0,0,.1);border-radius:8px;position:relative;margin:auto}.styles__modalContainer__19Hak .styles__leave__3Z4bk{animation:styles__fadeInContainer__1onCc reverse ease-out .3s forwards}.styles__close__TNlGg{position:absolute;right:16px;top:16px;cursor:pointer;transition:all ease-in-out .1s}.styles__close__TNlGg:hover{transform:scale(1.06)}.styles__close__TNlGg:active{transform:scale(0.94)}.styles__topContent__34TAH{padding:32px 32px 24px}.styles__titlemodal__2JJpD{font-style:normal;font-weight:bold;font-size:18px;line-height:22px;color:#515251;margin:0 40px 24px 0;user-select:none}.styles__modalfooter__zpqto{border-top:1px solid #ebebeb;padding:16px 32px;display:flex;justify-content:flex-end}.styles__modalfooter__zpqto[data-align=center]{justify-content:center}.styles__modalfooter__zpqto[data-align=left]{justify-content:flex-start}.styles__modalfooter__zpqto button{white-space:nowrap;width:auto;margin-left:16px}", ""]);

// Exports
exports.locals = {
	"modal": "styles__modal__2rFRd",
	"fadeInBg": "styles__fadeInBg__20g_L",
	"modalContainer": "styles__modalContainer__19Hak",
	"fadeInContainer": "styles__fadeInContainer__1onCc",
	"leave": "styles__leave__3Z4bk",
	"close": "styles__close__TNlGg",
	"topContent": "styles__topContent__34TAH",
	"titlemodal": "styles__titlemodal__2JJpD",
	"modalfooter": "styles__modalfooter__zpqto"
};