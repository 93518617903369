/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import { HistoryWorkflow } from "api/manager-api";
import { colors } from "utils";

import { OpeningAccountsStore } from "../../../stores";
import { Loader } from "../../../components/Loader";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  openingAccountsStore?: OpeningAccountsStore;
  isPjHistory?: boolean;
  representativeHistory?: HistoryWorkflow[];
  companyId?: string;
}

@inject("openingAccountsStore")
@observer
export class History extends React.Component<IProps> {
  @observable private loading = false;

  @observable private error: string | null = null;

  public componentDidMount() {
    if (!this.props.representativeHistory) {
      this.getData();
    }
  }

  private getData = async () => {
    const {
      pendingUser,
      pendingCompany,
      getHistoryWorkflowByTargetId,
      getHistoryWorkflowByCompanyId,
    } = this.props.openingAccountsStore!;

    if (this.props.isPjHistory && this.props.companyId) {
      try {
        this.loading = true;

        await getHistoryWorkflowByCompanyId(this.props.companyId);

        this.error = null;
      } catch (err) {
        this.error = err.message || "Ocorreu um erro ao obter os dados de histórico.";
      } finally {
        this.loading = false;
      }
    } else {
      try {
        this.loading = true;

        const targetId = pendingUser ? pendingUser.workflowId : pendingCompany!.id;

        await getHistoryWorkflowByTargetId(targetId);

        this.error = null;
      } catch (err) {
        this.error = err.message || "Ocorreu um erro ao obter os dados de histórico.";
      } finally {
        this.loading = false;
      }
    }
  };

  public render() {
    const { historyWorkflow, historyPjWorkflow } = this.props.openingAccountsStore!;
    const { representativeHistory } = this.props;

    if (this.loading) {
      return <Loader />;
    }

    if (historyWorkflow.length <= 0 && historyPjWorkflow.length <= 0 && !representativeHistory) {
      return <InnerAlert message="Não há informações para serem exibidas." />;
    }

    if (representativeHistory && representativeHistory.length <= 0) {
      return <InnerAlert message="Não há informações para serem exibidas." />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={this.getData} />;
    }

    if (representativeHistory && representativeHistory.length > 0) {
      return (
        <Container>
          {representativeHistory
            .sort((h1, h2) => h2.dateTime.getTime() - h1.dateTime.getTime())
            .map((action, i) => (
              <HistoryItem key={i}>
                <Date>{`${action.name}, ${moment(action.dateTime).format("l [às] LT")}`}</Date>
                <Text>{action.resume}</Text>
              </HistoryItem>
            ))}
        </Container>
      );
    }

    if (historyPjWorkflow.length > 0 && this.props.isPjHistory && this.props.companyId) {
      return (
        <Container>
          {historyPjWorkflow
            .sort((h1, h2) => h2.dateTime.getTime() - h1.dateTime.getTime())
            .map((action, i) => {
              const resume = action.resume ? action.resume.split("<$>") : [];

              return (
                <HistoryItem key={i}>
                  <Date>{`${action.name}, ${moment(action.dateTime).format("l [às] LT")}`}</Date>
                  {resume.map((data, key) => (
                    <Text key={key}>{data}</Text>
                  ))}
                </HistoryItem>
              );
            })}
        </Container>
      );
    }

    return (
      <Container>
        {historyWorkflow
          .sort((h1, h2) => h2.dateTime.getTime() - h1.dateTime.getTime())
          .map((action, i) => (
            <HistoryItem key={i}>
              <Date>{`${action.name}, ${moment(action.dateTime).format("l [às] LT")}`}</Date>
              <Text>{action.resume}</Text>
            </HistoryItem>
          ))}
      </Container>
    );
  }
}

const Container = styled.div`
  padding: 10px;
`;

const HistoryItem = styled.div`
  margin-bottom: 20px;
`;

const Date = styled.span`
  display: block;
  font-family: Lato;
  font-size: 12px;
  color: ${colors.gray};
  margin-bottom: 10px;
`;

const Text = styled.p`
  display: block;
  margin: 0;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
`;
