import * as React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router";
import { AccountsUsersCompanys } from "api/manager-api";

import { AccountsStore, GeneralStore, RouterStore } from "../../../stores";
import { SearchBar } from "../../../components/SearchBar";
import { accountsSearchPath, colors, searchDecode, searchEncode, searchMask } from "../../../utils";
import { Loader } from "../../../components/Loader";
import { InnerAlert } from "../../../components/InnerAlert";
import { ResultsList } from "../ResultsList";

interface IProps extends RouteComponentProps<{ query: string }> {
  generalStore: GeneralStore;
  routerStore: RouterStore;
  accountsStore: AccountsStore;
}

@inject("generalStore", "routerStore", "accountsStore")
@observer
export class AccountsSearch extends React.Component<IProps> {
  @observable private searchValue: string = searchDecode(this.props.match.params.query);

  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private results: AccountsUsersCompanys | null = null;

  public componentDidMount() {
    if (this.props.match.params.query) {
      this.performSearch();
    }
  }

  @action
  private clearSearch = () => {
    this.error = null;
    this.results = null;
    this.props.routerStore.push(accountsSearchPath(""));
  };

  private performSearch = async () => {
    try {
      this.loading = true;
      this.results = await this.props.accountsStore.filterUsersAccounts(this.searchValue);
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao buscar as contas.";
    } finally {
      this.loading = false;
    }
  };

  private handleSearchChange = (value: string) => {
    this.searchValue = searchMask(value);
  };

  private handleSearchButton = () => {
    const encoded = searchEncode(this.searchValue);

    this.props.routerStore.push(accountsSearchPath(encoded));
    this.performSearch();
  };

  public render() {
    return (
      <>
        <Row>
          <StyledSearchBar
            value={this.searchValue}
            onChange={this.handleSearchChange}
            placeholder="Pesquise por CPF, CNPJ, Nº da conta ou Nome"
            handleSearch={this.handleSearchButton}
            isLoading={this.loading}
          />
        </Row>
        {this.renderData()}
      </>
    );
  }

  private renderData = () => {
    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={this.performSearch} />;
    }

    if (!this.results) {
      return <Line />;
    }

    const { accounts, companys, users, fepwebCompanies } = this.results;

    if (!(accounts.length + companys.length + users.length + fepwebCompanies.length)) {
      return (
        <InnerAlert
          message="Nenhuma conta ou usuário foi encontrado com essas informações."
          buttonLabel="Limpar pesquisa"
          buttonClick={this.clearSearch}
        />
      );
    }

    return <ResultsList results={this.results} />;
  };
}

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`;

const StyledSearchBar = styled(SearchBar)`
  width: 400px;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderGray};
`;
