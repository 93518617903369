import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

interface IProps {
  value: number | string;
}

export class Badge extends React.Component<IProps> {
  public render() {
    return <BadgeSpan className="badge">{this.props.value}</BadgeSpan>;
  }
}

const BadgeSpan = styled.div`
  background-color: ${colors.white};
  line-height: 20px;
  min-width: 20px;
  border-radius: 10px;
  padding: 0 5px;
  margin-right: 16px;
  color: ${colors.darkPurple} !important;
  font-size: 9pt;
  font-weight: bold;
  text-align: center;
`;
