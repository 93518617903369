import * as api from "api/manager-api";
import { action } from "mobx";

import { RootStore } from "./RootStore";
import { session } from "../utils";

export class AuditStore {
  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getAudit = async (filterAudit: api.FilterAudit) => api.getAudit(filterAudit);
}
