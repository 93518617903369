import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";
import DatePickerComponent from "react-datepicker";
import moment from "moment";
import "./custom-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  disabled?: boolean;
  selected: moment.Moment | null;
  onChange: (data: moment.Moment | null) => void;
  isError?: boolean;
  minDate?: moment.Moment;
}

export const DatePicker: React.SFC<IProps> = props => {
  const customInput = (
    <Input isError={props.isError} disabled={!!props.disabled}>
      {props.selected ? props.selected.format("L") : "/⠀⠀⠀/ "}
    </Input>
  );

  return (
    <DatePickerComponent
      customInput={customInput}
      selected={props.selected ? props.selected.toDate() : null}
      onChange={data => props.onChange(data ? moment(data) : null)}
      disabled={props.disabled}
      locale="pt-br"
      minDate={props.minDate ? props.minDate.toDate() : undefined}
    />
  );
};

const Input = styled.div<{ disabled: boolean; isError?: boolean }>`
  width: 205px;
  height: 40px;
  border-radius: 4px;
  background-color: ${props => (props.disabled ? colors.paleGrey : colors.white)};
  border: solid 1px ${({ isError }) => (isError ? colors.red : colors.platinum)};
  color: ${colors.black};
  font-family: lato;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? "default" : "text")};

  &:disabled {
    background-color: ${colors.lightGray};
  }
`;
