import * as React from "react";
import { Permission, Profile } from "api/manager-api";

import { PermissionsManager } from "../PermissionsManager";

interface IProps {
  profile: Profile;
  onChange: (permissions: Permission[]) => void;
}

export function ProfilePermissions({ profile: { permissions }, onChange }: IProps) {
  return (
    <div style={{ width: "650px" }}>
      <PermissionsManager data={permissions} onChange={onChange} />
    </div>
  );
}
