import React from "react";

import { Button } from "../Button";
import { ReactComponent as EmptyIcon } from "../../assets/svgs/empty.svg";

import s from "./style.scss";

export interface IEmptyDataProps {
  hideIcon?: boolean;
  message?: string;
  retryTitle?: string;
  onRetry?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  render?: React.ReactNode;
}

export function EmptyData(props: IEmptyDataProps) {
  if (props.render) {
    return <>{props.render}</>;
  }

  return (
    <div className={s.emptyData}>
      {!props.hideIcon && <EmptyIcon />}

      <p>{props.message}</p>

      {props.onRetry && (
        <Button theme="link" onClick={props.onRetry}>
          {props.retryTitle}
        </Button>
      )}
    </div>
  );
}

EmptyData.defaultProps = {
  hideIcon: false,
  message: "Nenhum dado encontrado!",
  retryTitle: "Buscar novamente",
};
