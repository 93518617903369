/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-duplicate-imports */
import * as React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { action, observable } from "mobx";
import { AdminUser, AdminUserPermission } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { connect, ConnectedComponent } from "../../utils";
import { EmployeeManagerStore, GeneralStore } from "../../stores";
import { EmployeesBlockAction } from "./EmployeesBlockAction";
import { EmployeesEditAction } from "./EmployeesEditAction";
import { Fetch } from "../../components/Fetchable";
import { EmployeesList } from "./EmployeesList";
import {} from "../../utils";
import { Fetcher } from "../../components/Fetcher";

interface IStores {
  generalStore: GeneralStore;
  employeeManagerStore: EmployeeManagerStore;
}

@connect("generalStore", "employeeManagerStore")
@observer
export class EmployeesManager extends ConnectedComponent<{}, IStores> {
  @observable private blockOpen = false;

  @observable private editOpen = false;

  @observable private adminUsers: Fetch<AdminUser[]> = null;

  public componentWillMount = () => {
    this.getAdminUsers();
  };

  @action
  private getAdminUsers = async () => {
    this.adminUsers = this.stores.employeeManagerStore.getAdminUsers();
  };

  @action
  private handleBlock = (adminUser: AdminUser) => {
    this.stores.employeeManagerStore.currentAdminUser = adminUser;
    this.blockOpen = true;
  };

  @action
  private handleCreate = () => {
    this.stores.employeeManagerStore.currentAdminUser = null;
    this.editOpen = true;
  };

  @action
  private handleEdit = (adminUser: AdminUser) => {
    this.stores.employeeManagerStore.currentAdminUser = adminUser;
    this.editOpen = true;
  };

  @action
  private closeEdit = () => {
    this.editOpen = false;
  };

  @action
  private closeBlock = () => {
    this.blockOpen = false;
  };

  public render() {
    const AddAction = this.renderAdd;
    const { hasPermission } = this.stores.generalStore;
    const { adminUserList } = this.stores.employeeManagerStore;

    return (
      <Container>
        <EmployeesBlockAction isOpen={this.blockOpen} onClose={this.closeBlock} refetch={this.getAdminUsers} />
        <EmployeesEditAction isOpen={this.editOpen} onClose={this.closeEdit} refetch={this.getAdminUsers} />
        <Title>Gestão de Funcionários</Title>
        <AddAction />
        <Fetcher
          fetch={this.adminUsers}
          component={EmployeesList}
          items={adminUserList!}
          onEdit={hasPermission(AdminUserPermission.editAdminUser) ? this.handleEdit : undefined}
          onBlock={hasPermission(AdminUserPermission.blockAdminUser) ? this.handleBlock : undefined}
        />
      </Container>
    );
  }

  private renderAdd = () => {
    const { hasPermission } = this.stores.generalStore;

    if (!hasPermission(AdminUserPermission.addAdminUser)) {
      return null;
    }

    return (
      <AddButton
        type="button"
        label="Adicionar Funcionário"
        lowerCase
        customColor={colors.themeBrown}
        onClick={this.handleCreate}
      />
    );
  };
}

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;

const AddButton = styled(RaisedButton)`
  width: fit-content;
  font-weight: bold;
  font-family: Lato;
  font-size: 14px;
  padding: 12px 20px;
  margin-top: 20px;
  background-color: ${colors.violetRed};
`;
