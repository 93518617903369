import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { Loader } from "legacy/components/Loader";

import { Table } from "../../../../components/Table";
import { Dialog } from "../../../../components/Dialog";
import { TransactionalBoundariesStore } from "../../../../stores";
import {
  AlertModal,
  ErrorText,
  FieldDateRangePicker,
  FilterContainer,
  FilterWrapper,
  Label,
  Left,
  PartnerSelect,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextInput,
  TableContainer,
  TableLabel,
  Title,
  WrapperTable,
} from "./style";
import { SvgArrowRepeat } from "../../../../../../legacy/icons/SvgArrowRepeat";
import { SvgError } from "../../../../../../legacy/icons/SvgError";
import { DateRange, DateRangePicker } from "../../../../components/DateRangePicker";
import { searchMask } from "../../../../utils";

interface IProps {
  transactionalBoundariesStore?: TransactionalBoundariesStore;
}

interface ISearch {
  initialDate: Date | null;
  finalDate: Date | null;
  cpfCnpj: string;
}

interface IPagination {
  page: number;
  offset: number;
  limit: number;
}

@inject("transactionalBoundariesStore")
@observer
export class LogsByClient extends React.Component<IProps> {
  @observable private loading = true;

  @observable private initialDate: Date | null = null;

  @observable private finalDate: Date | null = null;

  @observable private cpfCnpj = "";

  @observable private errorOpen = false;

  @observable private search: ISearch = {
    cpfCnpj: "",
    finalDate: null,
    initialDate: null,
  };

  @observable private pagination: IPagination = {
    limit: 5,
    offset: 0,
    page: 1,
  };

  @observable private partners = [
    { label: "Partner 1", value: 1 },
    { label: "Partner 2", value: 2 },
  ];

  @observable private selectedPartner: number | null = null;

  public async componentDidMount() {
    this.getLogsByClient();
  }

  public getLogsByClient = async () => {
    this.initialDate = this.search.initialDate;
    this.finalDate = this.search.finalDate;
    this.cpfCnpj = this.search.cpfCnpj;

    this.loading = true;
    await this.props
      .transactionalBoundariesStore!.getLogsLimitByClient(
        this.search.cpfCnpj,
        this.search.initialDate,
        this.search.finalDate,
        this.pagination,
      )
      .catch(() => (this.errorOpen = true));

    this.loading = false;
  };

  public getPage = async () => {
    this.loading = true;
    await this.props
      .transactionalBoundariesStore!.getLogsLimitByClient(
        this.cpfCnpj,
        this.initialDate,
        this.finalDate,
        this.pagination,
      )
      .catch(() => (this.errorOpen = true));

    this.search = {
      cpfCnpj: this.cpfCnpj,
      finalDate: this.finalDate,
      initialDate: this.initialDate,
    };

    this.loading = false;
  };

  @action
  private handleClear = () => {
    this.search = {
      cpfCnpj: "",
      finalDate: null,
      initialDate: null,
    };
  };

  @action
  private handleSearch = () => {
    this.pagination = { limit: 5, offset: 0, page: 1 };
    this.getLogsByClient();
  };

  @action
  private handleDates = ({ startDate, endDate }: DateRange) => {
    this.search.initialDate = startDate;
    this.search.finalDate = endDate;
  };

  @action
  private handleChangePage = (page: number) => {
    this.pagination.offset = this.pagination.limit * page;
    this.pagination.page = page;
    this.getPage();
  };

  private renderData = () => {
    const { logsLimits } = this.props.transactionalBoundariesStore!.logLimits;

    if (this.loading) {
      return <Loader />;
    }

    const columns = [
      "CPF/CNPJ",
      "Nº Ticket",
      "Nome",
      "Data",
      "Hora",
      "Descrição da Alteração",
      "Alterado por",
      "Observação",
    ];

    return (
      <WrapperTable>
        {logsLimits.length > 0 ? (
          <Table
            titles={columns}
            ids={logsLimits.map(e => e.id.toString())}
            isPagination
            totalRows={this.props.transactionalBoundariesStore!.logLimits.count}
            rowsPerPage={this.pagination.limit}
            rowsPerPageOptions={[5, 10, 15, 20]}
            page={this.pagination.offset / this.pagination.limit}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={e => {
              this.pagination.limit = Number(e);
              this.handleChangePage(0);
            }}
            data={logsLimits.map(limit => {
              const data: any = [
                limit.cpfCnpj ? searchMask(limit.cpfCnpj) : "",
                limit.ticket ? limit.ticket : "",
                limit.name ? limit.name : "",
                limit.date.toLocaleDateString(),
                limit.date.toLocaleTimeString(),
                limit.description.map((des, index) => <p key={index}>{des}</p>),
                limit.user,
                limit.observation ? limit.observation : "",
              ];

              return data;
            })}
          />
        ) : (
          <TableLabel>Não existem dados a serem exibidos</TableLabel>
        )}
      </WrapperTable>
    );
  };

  public render() {
    return (
      <>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"sm"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-50px 0 12px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>Não foi possível obter o histórico</ErrorText>
          </AlertModal>
        </Dialog>
        <FilterWrapper>
          <Title>Filtros de pesquisa</Title>
          <Left>
            <FilterContainer>
              <Label>Pesquisar por Data</Label>
              <FieldDateRangePicker>
                <DateRangePicker
                  startDate={this.search.initialDate}
                  endDate={this.search.finalDate}
                  onChange={this.handleDates}
                  startLabel="De"
                  endLabel="Até"
                />
              </FieldDateRangePicker>
            </FilterContainer>
            <FilterContainer>
              <Label>CPF/CNPJ</Label>
              <StyledTextInput
                placeholder="Pesquisar por CPF/CNPJ"
                value={this.search.cpfCnpj}
                onChange={e => (this.search.cpfCnpj = searchMask(e.target.value))}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    this.handleSearch();
                  }
                }}
              />
            </FilterContainer>
            <StyledOutlinedButton
              label="Limpar Filtros"
              onClick={this.handleClear}
              iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
            />
            <StyledRaisedButton onClick={this.handleSearch} label="Buscar" type="submit" />
          </Left>
        </FilterWrapper>
        <Title>Alteração por CPF/CNPJ</Title>
        <TableContainer>{this.renderData()}</TableContainer>
      </>
    );
  }
}
