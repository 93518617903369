/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-require-imports */
import * as React from "react";
import moment from "moment";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Redirect, Route, Switch } from "react-router-dom";
import { normalize } from "polished";
import { hot } from "react-hot-loader/root";
import "react-dates/initialize";
import { createGlobalStyle } from "styled-components";
import { colors } from "utils";
import { ToastContainer } from "react-toastify";

import { AuthStore, GeneralStore } from "../../stores";
import { Toast } from "../../components/Toast";
import { withRouter } from "../../components/WithRouterHook";
import { dashboardPath, imagePath, loginPath } from "../../utils";
import { Dashboard } from "../Dashboard";
import { Login } from "../Login";
import { ImageRotate } from "../../components/ImageRotate";

import "react-toastify/dist/ReactToastify.css";

require("moment/locale/pt-br");
moment.locale("pt-br");

interface IProps {
  authStore?: AuthStore;
  generalStore?: GeneralStore;
}

@inject("authStore", "generalStore")
@observer
class RawApp extends React.Component<IProps> {
  @observable private loading = true;

  public async componentDidMount() {
    await this.props.authStore!.getCurrentUser();

    if (this.props.authStore!.adminUser) {
      await this.props.authStore!.getAdminUser();
    }

    this.loading = false;
  }

  public async componentDidUpdate() {
    if (!this.loading) {
      window.document.querySelector("body")?.classList.remove("app-loading");
    }
  }

  public render() {
    const { generalStore } = this.props;

    if (this.loading) {
      return null;
    }

    return (
      <>
        <GlobalStyle />
        <Toast
          isOpen={generalStore!.toastIsOpen}
          msg={generalStore!.msgToast}
          handleClose={() => generalStore!.setOpenToast(false)}
        />

        <ToastContainer />

        <Switch>
          <Route exact path="/" render={() => <Redirect to={dashboardPath} />} />
          <Route path={dashboardPath} component={Dashboard} />
          <Route path={imagePath} component={ImageRotate} />
          <Route exact path={loginPath} component={Login} />
          <Route render={() => <Redirect to={loginPath} />} />
        </Switch>
      </>
    );
  }
}

export const App = hot(withRouter(RawApp));

const GlobalStyle = createGlobalStyle`
	* {
		box-sizing: border-box;
		font-family: Lato;
	}

	body,
	html {
		margin: 0;
		padding: 0;
		background-color: ${colors.lightGray};
	}

	@import url('https://fonts.googleapis.com/css?family=Abel|Lato:100,300,400,600,700,900');

	${normalize() as any};
`;
