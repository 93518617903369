import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  opacity?: string;
}

export const SvgExit = (props: IProps) => {
  const { color, opacity, viewBox, ...restProps } = props;
  return (
    <svg {...restProps}>
      <path
        fill={color || "#fff"}
        fillRule="evenodd"
        d="M13.327 14.274l.004-.003a.995.995 0 0 0 .049-1.408l-.957-1.021a.5.5 0 0 1 .365-.842H19a1 1 0 1 0 0-2h-6.197a.5.5 0 0 1-.361-.845l.928-.974a.994.994 0 0 0-1.441-1.367L9.24 8.662a2 2 0 0 0 .007 2.753l2.686 2.816a.993.993 0 0 0 1.394.043M1.778 20h6.445C9.204 20 10 19.204 10 18.222v-1.229A.993.993 0 0 0 9.007 16h-.013a.994.994 0 0 0-.994.993v.014a.993.993 0 0 1-.993.993H2.994A.994.994 0 0 1 2 17.007V2.993C2 2.445 2.445 2 2.994 2h4.013c.549 0 .993.445.993.993v.014c0 .548.445.993.994.993h.013A.993.993 0 0 0 10 3.007V1.778C10 .796 9.204 0 8.223 0H1.778C.796 0 0 .796 0 1.778v16.444C0 19.204.796 20 1.778 20"
        opacity={opacity || "1"}
      />
    </svg>
  );
};
