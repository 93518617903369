import { IFilterAccountsPath } from "../stores";

export type IConciliationSection = "convenant" | "withdrawal-lottery" | "cards";

// Login
export const loginPath = "/login";

// Home - Dashboard
export const dashboardPath = "/dashboard";

// Pages - Dashboard
export const page404Path = `${dashboardPath}/404`;
export const accountsManagerPath = `${dashboardPath}/gestao-de-contas`;
export const openingAccountsPath = `${dashboardPath}/abertura-de-contas`;
export const platformManagerPath = `${dashboardPath}/gestao-da-plataforma`;
export const errorsManagerPath = `${dashboardPath}/gestao-de-erros`;
export const partnerManagementPath = `${dashboardPath}/gestao-de-parceiros`;
export const authorizedDebitPath = `${dashboardPath}/debito-autorizado`;
export const employeesManagerPath = `${dashboardPath}/gestao-de-funcionarios`;
export const auditManagerPath = `${dashboardPath}/auditoria`;
export const conciliationManagerPath = `${dashboardPath}/conciliacao`;
export const notificationManagerPath = `${dashboardPath}/notificacao`;
export const RegistrationPathPj = `${dashboardPath}/abertura-de-conta-pj-manual`;
export const extract = `${dashboardPath}/relatorios/extrato`;
export const internalAPIsPath = `${dashboardPath}/ferramentas-de-homologacao`;
export const openingAccountsReports = `${dashboardPath}/relatorios/abertura-de-contas`;
export const activeAccountsReports = `${dashboardPath}/relatorios/contas-ativas`;
export const generalParameters = `${dashboardPath}/parametros-gerais`;
export const limitCustomization = `${dashboardPath}/personalizacao-de-limites`;
export const historyChange = `${dashboardPath}/historico-de-alteracoes`;
export const safetyLimits = `${dashboardPath}/limites-de-seguranca`;
export const imagePath = `/image-preview`;
export const cardsmanagerPath = `${dashboardPath}/gestao-de-cartoes`;
// export const cpfListPath = `${openingAccountsPath}/lista-cpf`;

export const imagePathWithUrl = (url = ":url") => `${imagePath}/${url}`;

// Specifics Pages

export const accountsSearchPath = (query = ":query?") => `${accountsManagerPath}/busca/${query}`;
export const userProfileManagerPath = (cpf = ":cpf", isSimpleAccount = ":isSimpleAccount") =>
  `${accountsManagerPath}/pf/${cpf}/${isSimpleAccount}`;
export const legalEntityMEIProfileManagerPath = (cnpj = ":cnpj") => `${accountsManagerPath}/mei/${cnpj}`;
export const legalEntityProfileManagerPath = (cnpj = ":cnpj") => `${accountsManagerPath}/pj/${cnpj}`;
export const legalEntityPJOldProfileManagerPath = (cnpj = ":cnpj") => `${accountsManagerPath}/pjOld/${cnpj}`;

export const openingAccountsSectionPath = (section?: IFilterAccountsPath) => {
  switch (section) {
    case "all":
      return `${openingAccountsPath}/todas-as-contas`;
    case "tipping":
      return `${openingAccountsPath}/tombamento`;
    case "positivize":
      return `${openingAccountsPath}/promocao`;
    case "approved":
      return `${openingAccountsPath}/aprovados`;
    case "pending":
      return `${openingAccountsPath}/pendentes`;
    case "canceled":
      return `${openingAccountsPath}/cancelados`;
    case "reproved":
      return `${openingAccountsPath}/reprovados`;
    case "notFinished":
      return `${openingAccountsPath}/nao-finalizadas`;
    case "workflow":
      return `${openingAccountsPath}/workflow`;
    case "approvedPending":
      return `${openingAccountsPath}/contas-simples`;
    case "processing":
      return `${openingAccountsPath}/em-processamento`;
    default:
      return `${openingAccountsPath}/:section`;
  }
};

export const proofOfLifePath = `${openingAccountsPath}/prova-de-vida`;

export const openingAccountsSectionWithId = (section?: IFilterAccountsPath) => {
  return `${openingAccountsSectionPath(section)}`;
};

export const openingAccountSectionWithSearchPath = (section?: IFilterAccountsPath, search?: string) => {
  return `${openingAccountsSectionPath(section)}/busca/${typeof search === "string" ? search : ":search?"}`;
};

export const openingAccountsProfilePath = (
  section?: string,
  documentNumber = ":documentNumber",
  userId = ":userId",
  oldPJ?: boolean,
) =>
  `${openingAccountsPath}/${section || ":section"}/${documentNumber}/${userId}/${
    oldPJ !== undefined ? String(oldPJ) : ":oldPj?"
  }`;

export const conciliationManagerSectionPath = (section?: IConciliationSection) => {
  switch (section) {
    case "convenant":
      return `${conciliationManagerPath}/convenio`;
    case "withdrawal-lottery":
      return `${conciliationManagerPath}/saque-loterica`;
    case "cards":
      return `${conciliationManagerPath}/cartoes`;
    default:
      return `${conciliationManagerPath}/:section`;
  }
};
