import React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { partnerManagementPath } from "@manager/utils";

// Components
import { Button } from "components/Button";
import { ReactComponent as ArrowIcon } from "assets/svgs/arrow-back.svg";
import { Spin } from "@components/Spin";
import { ITabInfo } from "components/Tabs";

import { IFields, PartnerForm } from "../PartnerForms";

// Style
import s from "./style.scss";

interface IMatchParams {
  partnerId: string;
}

export const PartnerSingle = observer((props: RouteComponentProps<IMatchParams>) => {
  const { routerStore, partnerStore, featureFlagStore, generalStore } = useStores();

  const [loading, setLoading] = React.useState(true);
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [tab, setTab] = React.useState<ITabInfo>({
    allTabKeys: ["registerData", "style", "cards", "settings"],
    index: 0,
    tabKey: "registerData",
  });
  const [initialValues, setInitialValues] = React.useState<IFields | Partial<IFields>>();

  function onBack() {
    routerStore.push(partnerManagementPath);
  }

  function registerDataConvert(values?: IFields, setValues?: (values: IFields) => void): Partial<IFields> {
    if (!partnerStore.partnerRegisterData) {
      return {};
    }

    const parsedData: Partial<IFields> = {
      name: partnerStore.partnerRegisterData.name,
      cnpj: partnerStore.partnerRegisterData.cnpj || "",
      managerId: partnerStore.partnerRegisterData.managerId,
      ibUrl: partnerStore.partnerRegisterData.urlIB,
      appIosUrl: partnerStore.partnerRegisterData.urlApp?.ios,
      appAndroidUrl: partnerStore.partnerRegisterData.urlApp?.android,
      appTokenIosUrl: partnerStore.partnerRegisterData.tokenUrlApp?.ios,
      appTokenAndroidUrl: partnerStore.partnerRegisterData.tokenUrlApp?.android,
      chatUrl: partnerStore.partnerRegisterData.hibotlink || "",
      chatUrlMobile: partnerStore.partnerRegisterData.hibotmobilelink || "",
      publicKey: partnerStore.partnerRegisterData.publicKey,
      senderEmail: partnerStore.partnerRegisterData.emailFrom || "",
      farePackagesURL: partnerStore.partnerRegisterData.urlFarePackages,
      pfTermsOfUserUrl: partnerStore.partnerRegisterData.urlTerm,
      pfContractUrl: partnerStore.partnerRegisterData.urlContract,
      pjTermsOfUserUrl: partnerStore.partnerRegisterData.urlTermPj,
      pjContractUrl: partnerStore.partnerRegisterData.urlContractPj,
      // ShowWiz: partnerStore.partnerRegisterData.showWiz,
    };

    if (values && setValues) {
      setInitialValues({ ...values, ...parsedData });
    } else {
      setInitialValues(parsedData);
    }

    return parsedData;
  }

  function styleDataConvert(values?: IFields): Partial<IFields> {
    if (!partnerStore.partnerStyleData) {
      return {};
    }

    const parsedData: Partial<IFields> = {
      colorPrimary: partnerStore.partnerStyleData.primaryColor || "",
      colorSecondary: partnerStore.partnerStyleData.secondaryColor || "",
      colorBg: partnerStore.partnerStyleData.backgroundColor || "",
      colorError: partnerStore.partnerStyleData.errorColor || "",
      logoUrl: partnerStore.partnerStyleData.logo,
      logoBuffer: null,
    };

    if (values) {
      setInitialValues({ ...values, ...parsedData });
    }

    return parsedData;
  }

  function generalConfigDataConvert(values?: IFields): Partial<IFields> {
    if (!partnerStore.partnerGeneralConfigData) {
      return {};
    }

    const parsedData: Partial<IFields> = {
      allowAnyPartnerLogin: partnerStore.partnerGeneralConfigData.allowAnyPartnerLogin,
      allowPartnerLot: partnerStore.partnerGeneralConfigData.allowPartnerLot || false,
      allowReduceJourneyWithoutLot: partnerStore.partnerGeneralConfigData.allowReduceJourneyWithoutLot || false,
      accountOpeningAttemptLimit: partnerStore.partnerGeneralConfigData.accountOpeningAttemptLimit?.toString(),
      emailResendTimeFrequency: partnerStore.partnerGeneralConfigData.emailResendTimeFrequency.toString(),
      emailResendTime: partnerStore.partnerGeneralConfigData.emailResendTime.toString(),
      featuresFlag: featureFlagStore.featuresPartner,
      showCardModule: partnerStore.partnerGeneralConfigData.showCard,
      showWiz: partnerStore.partnerGeneralConfigData.showWiz,
      managerIdLot: partnerStore.partnerGeneralConfigData.managerIdLot,
      managerIdWithoutLot: partnerStore.partnerGeneralConfigData.managerIdWithoutLot,
      customButton: partnerStore.partnerGeneralConfigData.customButton,
      customContact: partnerStore.partnerGeneralConfigData.customContact,
    };

    if (values) {
      setInitialValues({ ...values, ...parsedData });
    }

    return parsedData;
  }

  async function loadPartnerData(values?: IFields) {
    try {
      if (!partnerStore.partner) {
        await partnerStore.getPartner(props.match.params.partnerId);
      }

      switch (tab.tabKey) {
        case "registerData":
          if (!partnerStore.partnerRegisterData) {
            setLoading(true);
            await partnerStore.getPartnerRegisterData(props.match.params.partnerId);
            registerDataConvert(values);
          }

          break;
        case "style":
          if (!partnerStore.partnerStyleData) {
            setLoading(true);
            await partnerStore.getPartnerStyles(props.match.params.partnerId);
            styleDataConvert(values);
          }

          break;
        case "cards":
          if (!partnerStore.partnerCardData) {
            setLoading(true);
            await partnerStore.getPartnerCards(props.match.params.partnerId);
          }

          break;
        case "settings":
          if (!partnerStore.partnerGeneralConfigData) {
            setLoading(true);
            await partnerStore.getPartnerGeneralConfig(props.match.params.partnerId);
            await featureFlagStore.getFeaturesPartner(props.match.params.partnerId);
            generalConfigDataConvert(values);
          }

          break;
        default:
          break;
      }

      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message || "Ocorreu um erro ao buscar pelo parceiro.");
    }

    setLoading(false);
  }

  async function setRegisterData(values: IFields, setValues: (values: IFields) => void) {
    if (!partnerStore.partnerRegisterData) {
      return false;
    }

    setLoadingSend(true);
    const registerData: api.PartnerRegisterData = {
      partnerId: props.match.params.partnerId,
      name: values.name,
      cnpj: values.cnpj,
      managerId: values.managerId,
      // ManagerIdLot: values.managerIdLot,
      urlApp: {
        android: values.appAndroidUrl,
        ios: values.appIosUrl,
      },
      tokenUrlApp: {
        android: values.appTokenAndroidUrl,
        ios: values.appTokenIosUrl,
      },
      hibotlink: values.chatUrl,
      publicKey: values.publicKey,
      emailFrom: values.senderEmail,
      urlTerm: partnerStore.partnerRegisterData.urlTerm,
      urlContract: partnerStore.partnerRegisterData.urlContract,
      urlTermPj: partnerStore.partnerRegisterData.urlTermPj,
      urlContractPj: partnerStore.partnerRegisterData.urlContractPj,
      urlFarePackages: partnerStore.partnerRegisterData.urlFarePackages,
      urlIB: values.ibUrl,
      blocked: partnerStore.partnerRegisterData.blocked,
      hibotmobilelink: values.chatUrlMobile,
      partnerSecret: partnerStore.partnerRegisterData.partnerSecret,
      phone: partnerStore.partnerRegisterData.phone,
      email: partnerStore.partnerRegisterData.email,
      site: partnerStore.partnerRegisterData.site,
      partnerHook: partnerStore.partnerRegisterData.partnerHook,
      partnerUserName: partnerStore.partnerRegisterData.partnerUserName,
      webhookUrl: partnerStore.partnerRegisterData.webhookUrl,
      term: values.pfTermsOfUserBuffer,
      contract: values.pfContractBuffer,
      termPj: values.pjTermsOfUserBuffer,
      contractPj: values.pjContractBuffer,
      farePackages: values.farePackagesBuffer,
      // ShowWiz: values.showWiz,
    };

    try {
      await partnerStore.setPartnerRegisterData(registerData);
      await partnerStore.getPartnerRegisterData(props.match.params.partnerId);

      setValues({ ...values, ...registerDataConvert() });
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao salvar parceiro.");
    } finally {
      setLoadingSend(false);
    }

    return true;
  }

  async function setStyleData(values: IFields, setValues: (values: IFields) => void) {
    if (!partnerStore.partnerStyleData) {
      return false;
    }

    setLoadingSend(true);
    const styleData: api.PartnerStyles = {
      partnerId: props.match.params.partnerId,
      primaryColor: values.colorPrimary,
      secondaryColor: values.colorSecondary,
      backgroundColor: values.colorBg,
      errorColor: values.colorError,
      logo: partnerStore.partnerStyleData.logo,
      font: partnerStore.partnerStyleData.font,
      fontHref: partnerStore.partnerStyleData.fontHref,
      buffer: values.logoBuffer,
    };

    try {
      await partnerStore.setPartnerStyles(styleData);
      await partnerStore.getPartnerStyles(props.match.params.partnerId);

      setValues({ ...values, ...styleDataConvert() });
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao salvar parceiro.");
    } finally {
      setLoadingSend(false);
    }

    return true;
  }

  async function setPartnerCards(values: IFields) {
    if (!partnerStore.partnerCardData || !values.multicards || !values.broadcaster || values.cardBrand === "nenhum") {
      return false;
    }

    setLoadingSend(true);
    const cardData: api.PartnerCard = {
      partnerId: props.match.params.partnerId,
      cardConfig: [
        {
          broadcaster: values.broadcaster,
          brand: values.cardBrand,
          productId: values.visaIdProduct,
          plasticId: values.visaIdPlastic,
          activateCards: {
            physical: values.hasFisicCard,
            virtual: values.hasVirtualCard,
          },
          commercialOrigin: values.visaIdComercial,
          cardTypeName: { name: values.hasName, noname: values.hasNoName },
          numberBin: values.numberBin,
          typeBin: values.typeBin,
          cardImage: [{ id: values.visaIdImage, descr: values.visaImageDescription }],
          multiCards: values.multicards,
        },
      ],
    };

    try {
      await partnerStore.setPartnerCards(cardData);
      await partnerStore.getPartnerCards(props.match.params.partnerId);
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao salvar parceiro.");
    } finally {
      setLoadingSend(false);
    }

    return true;
  }

  async function setGeneralConfigData(values: IFields, setValues: (values: IFields) => void) {
    if (!partnerStore.partnerGeneralConfigData) {
      return false;
    }

    setLoadingSend(true);
    const generalConfigData: api.PartnerGeneralConfig = {
      partnerId: props.match.params.partnerId,
      allowAnyPartnerLogin: values.allowAnyPartnerLogin,
      blocked: partnerStore.partnerGeneralConfigData.blocked,
      emailResendTime: parseInt(values.emailResendTime, 10),
      emailResendTimeFrequency: parseInt(values.emailResendTimeFrequency, 10),
      accountOpeningAttemptLimit: parseInt(values.accountOpeningAttemptLimit, 10),
      openAccountsAttempts: partnerStore.partnerGeneralConfigData.openAccountsAttempts,
      showCard: values.showCardModule,
      allowPartnerLot: values.allowPartnerLot,
      allowReduceJourneyWithoutLot: values.allowReduceJourneyWithoutLot,
      showWiz: values.showWiz,
      managerIdLot: values.managerIdLot,
      managerIdWithoutLot: values.managerIdWithoutLot,
      customButton: values.customButton,
      customContact: values.customContact,
    };

    try {
      await partnerStore.setPartnerGeneralConfig(generalConfigData);
      await featureFlagStore.setFeaturesPartner(props.match.params.partnerId, values.featuresFlag);
      await partnerStore.getPartnerGeneralConfig(props.match.params.partnerId);
      await featureFlagStore.getFeaturesPartner(props.match.params.partnerId);

      setValues({ ...values, ...generalConfigDataConvert() });
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao salvar parceiro.");
    } finally {
      setLoadingSend(false);
    }

    return true;
  }

  async function updatePartnerCards(values: IFields) {
    if (!partnerStore.partnerCardData || !values.multicards) {
      return false;
    }

    setLoadingSend(true);
    const cardData: api.UpdatePartnerCard = {
      partnerId: props.match.params.partnerId,
      productId: values.visaIdProduct,
      plasticId: values.visaIdPlastic,
      activateCards: {
        physical: values.hasFisicCard,
        virtual: values.hasVirtualCard,
      },
      commercialOrigin: values.visaIdComercial,
      cardTypeName: { name: values.hasName, noname: values.hasNoName },
      bin: values.numberBin,
      typeBin: values.typeBin,
      imageId: values.visaIdImage,
      imageDescr: values.visaImageDescription,
      multiCard: values.multicards,
    };

    try {
      await partnerStore.updatePartnerCard(cardData);
      await partnerStore.getPartnerCards(props.match.params.partnerId);
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao salvar parceiro.");
    } finally {
      setLoadingSend(false);
    }

    return true;
  }

  async function saveData(values: IFields, setValues: (values: IFields) => void, isUpdateCard?: boolean) {
    switch (tab.tabKey) {
      case "registerData":
        setRegisterData(values, setValues);
        break;
      case "style":
        setStyleData(values, setValues);
        break;
      case "cards":
        if (isUpdateCard) {
          updatePartnerCards(values);
        } else {
          setPartnerCards(values);
        }

        break;
      case "settings":
        setGeneralConfigData(values, setValues);
        break;
      default:
        break;
    }

    setLoadingSend(false);
    return true;
  }

  React.useEffect(() => {
    partnerStore.clearCacheData();
    loadPartnerData();
  }, []);

  return (
    <>
      <div className={s.title}>Editar parceiro</div>

      <div className={s.header}>
        <Button theme="neutral" borderType="semi-circle" onClick={onBack}>
          <ArrowIcon />
          <span>Voltar para a lista</span>
        </Button>
      </div>

      {loading && (
        <Spin spinning={loading}>
          <div className={s.loadingContent}></div>
        </Spin>
      )}

      {!loading && errorMessage && (
        <div className={s.errorContainer}>
          <div className={s.errorMessage}>{errorMessage}</div>

          <Button theme="secondary" type="button" onClick={async () => loadPartnerData()}>
            Tentar novamente
          </Button>
        </div>
      )}

      {!loading && !errorMessage && (
        <PartnerForm
          initialValues={initialValues}
          onSave={saveData}
          loading={loadingSend}
          toggleDisabledFields
          tab={tab}
          setTab={setTab}
          onChangeTab={loadPartnerData}
          partnerId={props.match.params.partnerId}
          openAccountsAttempts={partnerStore.partner?.openAccountsAttempts}
        />
      )}
    </>
  );
});
