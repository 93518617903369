/* eslint-disable func-style */
/* eslint-disable no-tabs */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { AuditInfo } from "api/manager-api";
import { formatCpf } from "@brazilian-utils/formatters";
import { colors } from "utils";

interface IProps {
  data: AuditInfo;
}

export class AuditDialog extends React.Component<IProps> {
  public render() {
    const {
      id,
      ip,
      cpf,
      name,
      geoIp,
      device: { location, deviceType, browserUserAgent, hardwareBrand, hardwareModel, version, deviceId },
      dateTime,
      auditType,
      resume,
    } = this.props.data;

    const locationString = location ? `lat: ${location.lat}, long: ${location.lng}` : "Não informado";

    const deviceString =
      deviceType || browserUserAgent || hardwareBrand || hardwareModel || version
        ? `
					${deviceType ? deviceType : ""}
					${version ? version : ""}
				   	${browserUserAgent ? browserUserAgent : ""}
				   	${hardwareBrand ? hardwareBrand : ""}
					${hardwareModel ? hardwareModel : ""}
					   `
        : "Não informado";

    return (
      <Container>
        <Description>{resume || auditType}</Description>
        <DataInfo title="CPF" text={formatCpf(cpf)} />
        <DataInfo title="Nome" text={name} />
        <DataInfo title="ID do usuário" text={id} />
        <DataInfo title="Data" text={moment(dateTime).format("LLL")} />
        <DataInfo title="GeoIP" text={geoIp ? "Sim" : "Não"} />
        <DataInfo title="IP" text={ip} />
        <DataInfo title="Localização" text={locationString} />
        <DataInfo title="Dispositivo" text={deviceString} />
        <DataInfo title="DeviceId" text={deviceId} />
      </Container>
    );
  }
}

const DataInfo = ({ title, text }: { title: string; text: string }) => (
  <Info>
    <Normal>{`${title}: `}</Normal>
    <Bold>{text}</Bold>
  </Info>
);

const Description = styled.div`
  margin-bottom: 20px;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
`;

const Container = styled.div`
  min-width: 400px;
  padding: 20px;
`;

const Info = styled.div`
  margin: 0 5px;
`;

const Normal = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
`;
const Bold = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  font-weight: bold;
`;
