import * as React from "react";
import { CategoryAccount, Gender, PfPj, Region, ReportFilter, UserStatus } from "api/manager-api";
import { RouterStore } from "mobx-react-router";

import { OpeningActiveAccountsReportList } from "../OpeningActiveAccountsReportList";

interface IProps {
  report: any | null;
  offset: number;
  limit: number;
  onChangePage: (page: number) => void;
  onClickDownloadExcel?: any;
  routerStore: RouterStore;
  onClickRow?: (e: string) => void;
}

export const OpeningActiveAccountsReport: React.FunctionComponent<IProps> = ({
  report,
  offset,
  limit,
  onChangePage,
  onClickDownloadExcel,
  routerStore,
  onClickRow,
}) => {
  if (!report) {
    return null;
  }

  return (
    <OpeningActiveAccountsReportList
      items={report.users}
      limit={limit}
      offset={offset}
      totalRows={report.total}
      onChangePage={onChangePage}
      onClickDownloadExcel={onClickDownloadExcel}
      routerStore={routerStore}
      onClickRow={onClickRow}
    />
  );
};