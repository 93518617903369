import React from "react";
import moment from "moment";
import { StatusUserProofOfLife, UserProofOfLife } from "api/manager-api";

import { StatusLabel } from "components/StatusLabel";
import { ImageZoom } from "components/ImageZoom";

import s from "./styles.scss";

interface IProps {
  self: UserProofOfLife;
}

export function SelfContainer({ self }: IProps) {
  return (
    <div className={s.selfContainer}>
      <ImageZoom
        className={s.image}
        src={self.selfie}
        alt={`self enviada ${moment(self.createdAt).format("DD/MM/YY")}`}
      />
      <div className={s.selfRightInfo}>
        <div className={s.selfInfo}>
          <h4>Data/Hora</h4>
          <p>{moment(self.createdAt).format("DD/MM/YY [às] HH:mm")}</p>
        </div>
        <div className={s.selfInfo}>
          <h4>Resultado</h4>
          <p>
            <StatusProof receivedStatus={self.status} />
          </p>
        </div>
        {self.acertIdKey && (
          <div className={s.selfInfo}>
            <h4>Chave acertId</h4>
            <p>{self.acertIdKey}</p>
          </div>
        )}
        {self.statusAcertId && (
          <div className={s.selfInfo}>
            <h4>Status acertId</h4>
            <p>{self.statusAcertId}</p>
          </div>
        )}

        {self.dateResponseAcertId && (
          <div className={s.selfInfo}>
            <h4>Data da resposta</h4>
            <p>{moment(self.dateResponseAcertId).format("DD/MM/YY [às] HH:mm")}</p>
          </div>
        )}
        {self.reason && (
          <div className={s.selfInfo}>
            <h4>Motivo</h4>
            <p>{self.reason}</p>
          </div>
        )}
      </div>
    </div>
  );
}

interface IStatusProof {
  receivedStatus: StatusUserProofOfLife;
}

function StatusProof({ receivedStatus }: IStatusProof): JSX.Element {
  const allStatus = {
    [StatusUserProofOfLife.APPROVED]: <StatusLabel type="positive" text="Aprovado" />,
    [StatusUserProofOfLife.PROCESSING]: <StatusLabel type="neutral" text="Pendente" />,
    [StatusUserProofOfLife.REFUSED]: <StatusLabel type="negative" text="Reprovado" />,
    [StatusUserProofOfLife.REFUSEDACERTID]: <StatusLabel type="negative" text="Reprovado pelo acertid" />,
    [StatusUserProofOfLife.ANALYSISMANUALACERTID]: <StatusLabel type="neutral" text="Aguardando Análise" />,
    [StatusUserProofOfLife.CANCELED]: <StatusLabel type="negative" text="Cancelado" />,
  };

  return allStatus[receivedStatus];
}
