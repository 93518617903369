/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";

import { EmployeeManagerStore, GeneralStore } from "../../../stores";
import { EmployeesBlockDialog } from "../EmployeesBlockDialog";
import { connect, ConnectedComponent } from "../../../utils";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

interface IStores {
  generalStore: GeneralStore;
  employeeManagerStore: EmployeeManagerStore;
}

@connect("generalStore", "employeeManagerStore")
@observer
export class EmployeesBlockAction extends ConnectedComponent<IProps, IStores> {
  @observable private loading = false;

  @action
  private handleSubmit = (blocked: boolean) => async () => {
    const { setAdminUser, currentAdminUser } = this.stores.employeeManagerStore;
    const { showToast } = this.stores.generalStore;

    try {
      this.loading = true;

      await setAdminUser({
        ...currentAdminUser!,
        blocked,
      });
      currentAdminUser!.blocked = blocked;
      this.props.onClose();
      this.props.refetch();

      showToast("Funcionário alterado com sucesso.");
    } catch (err) {
      showToast(err.message || "Ocorreu um erro ao salvar funcionário.");
    } finally {
      this.loading = false;
    }
  };

  public render() {
    const { currentAdminUser } = this.stores.employeeManagerStore;

    if (!currentAdminUser || !this.props.isOpen) {
      return null;
    }

    return (
      <EmployeesBlockDialog
        blocked={currentAdminUser.blocked}
        loading={this.loading}
        onClose={this.props.onClose}
        onSubmit={this.handleSubmit(!currentAdminUser.blocked)}
      />
    );
  }
}
