import * as React from "react";
import styled, { css } from "styled-components";
import { colors } from "utils";
import MaskedInput from "react-text-mask";
import { FormHelperText } from "@material-ui/core";
import { SvgError } from "legacy/icons/SvgError";

export interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  error?: boolean;
  errorText?: string;
  errorImage?: any;
  isSecret?: boolean;
  mask?: Array<string | RegExp> | ((value: string) => Array<string | RegExp>) | undefined;
  icon?: any;
  isChat?: boolean;
  multiline?: boolean;
  multilineRows?: number;
  disabled?: boolean;
}

interface IInputProps {
  styled: { error: boolean; hasicon: boolean };
}

export const TextInput: React.FunctionComponent<ITextInputProps> = ({
  type,
  mask,
  icon,
  label,
  isSecret,
  unselectable,
  error,
  errorText,
  errorImage,
  isChat,
  multiline,
  multilineRows,
  disabled,
  ...allProps
}) => (
  <Container isChat={isChat}>
    {label ? typeof label === "string" ? <Label>{label}</Label> : label : null}
    <WrapperImage icon={icon}>
      {mask ? (
        <Input
          styled={{
            hasicon: !!icon,
            error: !!error,
          }}
          mask={mask}
          type={isSecret ? "password" : "text"}
          guide={mask ? false : undefined}
          {...allProps}
        />
      ) : multiline ? (
        <TextArea
          styled={{
            hasicon: !!icon,
            error: !!error,
          }}
          rows={multilineRows || 5}
          {...(allProps as any)}
        />
      ) : (
        <InputWithoutMask
          styled={{
            hasicon: !!icon,
            error: !!error,
          }}
          type={isSecret ? "password" : "text"}
          disabled={disabled}
          {...allProps}
        />
      )}
      {!!error && !!errorText ? (
        <ErrorTextWrapper>
          {errorImage && (
            <SvgError
              viewBox="0 0 82 82"
              width="12"
              height="auto"
              style={{ margin: "0 8px -8px 0", position: "relative" }}
            />
          )}
          <FormHelperText error>{errorText}</FormHelperText>
        </ErrorTextWrapper>
      ) : (
        <Spacing />
      )}
    </WrapperImage>
  </Container>
);

const inputStyle = css<IInputProps>`
  background-color: transparent;
  border: 1px solid ${({ styled: { error } }) => (error ? colors.red : colors.platinum)};
  border-radius: 5px;
  color: ${colors.black};
  font-family: Lato;
  font-size: 15px;
  padding: ${({ styled: { hasicon } }) => (hasicon ? "10px 0 10px 40px" : "10px 0 10px 10px")};
  width: 320px;
  height: 40px;
  outline: none;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    border-color: ${colors.violetRedOpacity};
  }

  @mixin placeholder {
    font-size: 15px;
    color: ${colors.gray};
  }

  ::placeholder {
    font-size: 15px;
    color: ${colors.gray};
  }

  ::-webkit-input-placeholder {
    font-size: 15px;
    color: ${colors.gray};
  }
`;

const Input = styled(MaskedInput)<IInputProps>`
  ${inputStyle};
`;

const InputWithoutMask = styled.input<IInputProps>`
  ${inputStyle};
`;

const TextArea = styled.textarea<IInputProps>`
  ${inputStyle};
  min-width: 100%;
  max-width: 100%;
`;

const WrapperImage = styled.span<{ icon?: any }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ icon }) =>
    icon
      ? `
	&:before {
		content: "";
		position: absolute;
		top: 8px;
		left: 8px;
		background-image: url(${icon});
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 25px;
		width: 25px;
	}
	`
      : ""};
`;

const ErrorTextWrapper = styled.div`
  margin-top: 5px !important;
  margin-left: 3px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Spacing = styled.div`
  height: 17px;
`;

const Container = styled.div<{ isChat?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isChat }) => (isChat ? "65%" : "none")};
`;

const Label = styled.label`
  font-family: Lato;
  color: ${colors.gray};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.1px;
  margin-bottom: 9px;
`;
