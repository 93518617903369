import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { observer } from "mobx-react";
import { RaisedButton } from "legacy/components/RaisedButton";
import { Loader } from "legacy/components/Loader";
import { InnerAlert } from "legacy/components";
import { TextInput } from "legacy/components/TextInput";
import { SvgSpreadsheet } from "legacy/icons/SvgSpreadsheet";
import save from "save-file";
import { intergerMask, maskValidateCpf, validateCpf } from "utils";

import { ActionsDropdown } from "../../../components/ActionsDropdown";
import { Table } from "../../../components/Table";
import { Button, Container, Header, HeaderWrapper, Label, TitleContainer } from "./styled";

export const CpfList = observer(() => {
  const { internalAPIsStore, generalStore } = useStores();

  const [cpfList, setCPFList] = useState<api.CPFList[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cpfListData, setCpfListData] = useState("");

  const { hasPermission } = generalStore;

  const getCPFList = async () => {
    setLoading(true);
    try {
      const result = await internalAPIsStore.getCPFList();

      setCPFList(result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCPFList = async () => {
    setLoading(true);
    try {
      const normalizedCpf = intergerMask(cpfListData);

      await internalAPIsStore.addCPFList(normalizedCpf);
      getCPFList();
      setCpfListData("");
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const sendDownload = async (buffer: Buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `lista-cpf.xlsx`);
  };

  const handleDeleteCPFInList = async (cpf: string) => {
    setLoading(true);
    try {
      await internalAPIsStore.deleteCPFList(cpf);
      getCPFList();
      setLoading(false);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCPFList = async () => {
    setLoading(true);

    try {
      const result = await internalAPIsStore.downloadCPFList();

      sendDownload(result);

      setLoading(false);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCPFList();
  }, []);

  const RenderHeader = () => {
    return (
      <HeaderWrapper>
        <Label>Digite um dado para inserção na lista</Label>
        <Header>
          <TextInput mask={maskValidateCpf} value={cpfListData} onChange={e => setCpfListData(e.currentTarget.value)} />
          <RaisedButton
            disabled={!validateCpf(cpfListData)}
            label={"Salvar"}
            onClick={async () => handleCPFList()}
            style={{ marginRight: "10px" }}
          />
        </Header>
      </HeaderWrapper>
    );
  };

  const RenderOptions = (cpf: string) => {
    return [
      {
        label: "Remover",
        onClick: async () => handleDeleteCPFInList(cpf),
      },
    ];
  };

  const RenderTable = () => {
    return (
      <Table
        titles={["CPF", "Tentativas", hasPermission(api.AdminUserPermission.editCPFList) ? "Ação" : null]}
        ids={cpfList.map(info => info.cpf)}
        data={cpfList.map((info, index) => [
          info.cpf,
          info.attempts,
          hasPermission(api.AdminUserPermission.editCPFList) && (
            <ActionsDropdown key={index} options={RenderOptions(info.cpf)} />
          ),
        ])}
        trStyles={[
          {
            boxSizing: "content-box",
            minWidth: "111px",
          },
        ]}
      />
    );
  };

  const RenderData = () => {
    if (loading) {
      return <Loader />;
    }

    if (cpfList.length === 0) {
      return (
        <InnerAlert
          customMarginTop={"25px"}
          message="Nenhum dado para mostrar"
          buttonLabel="Buscar novamente"
          buttonClick={getCPFList}
        />
      );
    }

    if (error) {
      return (
        <InnerAlert
          customMarginTop={"25px"}
          message="Ocorreu um erro, tente novamente"
          buttonLabel="Tentar novamente"
          buttonClick={getCPFList}
        />
      );
    }

    return (
      <Container>
        <RenderTable />
      </Container>
    );
  };

  return (
    <Container>
      <TitleContainer>
        <Button
          disabled={cpfList.length === 0}
          label="Baixar Planilha"
          iconComponent={<SvgSpreadsheet width="50px" height="20px" />}
          onClick={handleDownloadCPFList}
        />
      </TitleContainer>
      {!loading && hasPermission(api.AdminUserPermission.editCPFList) && RenderHeader()}
      <RenderData />
    </Container>
  );
});
