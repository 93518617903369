import React from "react";
import classnames from "classnames";

import { statusLabelIcon } from "./StatusLabelIcon";

import s from "./styles.scss";

export type ILabelStatus = "negative" | "neutral" | "positive";
interface IProps {
  className?: string;
  text: string;
  type?: ILabelStatus;
}

export function StatusLabel({ className, type, text }: IProps) {
  return (
    <span className={classnames(s.statuslabel, className)} data-color={type}>
      {statusLabelIcon(type)} {text}
    </span>
  );
}
