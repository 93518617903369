/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import * as React from "react";
import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";

import { OutlinedButton } from "../../../components/OutlinedButton";
import { colors, maskValidateCnpj, validateCnpj } from "../../../utils";
import { TextInput } from "../../../components/TextInput";

import { IRemoveUserMEI, Option } from "..";

type FieldError = "cnpj";

interface IProps {
  onClose: () => void;
  onSubmit: (option: Option, data: IRemoveUserMEI) => Promise<void>;
}

export function RemoveUserMeiCnpjDialog({ onClose, onSubmit }: IProps) {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [cnpj, setCnpj] = React.useState("");

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const newErrors: FieldError[] = [];

    if (!validateCnpj(cnpj)) {
      newErrors.push("cnpj");
    }

    setErrors(newErrors);
    if (newErrors.length > 0) {
      setLoading(false);
      return;
    }

    await onSubmit("deleteUserMeiByCnpj" as any, { cnpj });

    setLoading(false);

    onClose();
  }

  return (
    <Box onSubmit={handleSubmit}>
      <Info>
        <TextInput
          placeholder="CNPJ"
          mask={maskValidateCnpj}
          value={cnpj}
          error={Boolean(errors.find(e => e === "cnpj"))}
          onChange={e => setCnpj(e.target.value)}
        />
      </Info>
      <InputArea>
        <BackButtonWrapper disabled={loading} type="button" label="Voltar" onClick={onClose} />
        <NextButtonWrapper loading={loading} type="submit" label="Remover" />
      </InputArea>
    </Box>
  );
}

const Box = styled.form`
  padding: 16px 24px;
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

const NextButtonWrapper = styled(RaisedButton)`
  min-width: 145px;
  width: fit-content;
  font-family: Lato;
  margin: 15px 0 15px 15px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;

const Info = styled.div`
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.black};
`;
