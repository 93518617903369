import styled from "styled-components";
import { colors } from "utils";

import { TabNavigator as RawTabNavigator } from "../../../components/TabNavigator";

export const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 4px 0;
`;

export const Label = styled.label`
  font-size: 16px;
  color: ${colors.gray};
  font-family: Lato;
`;

export const TabNavigator = styled(RawTabNavigator)`
  & > section {
    min-height: 200px;
  }
`;

export const HeaderWrapper = styled.header`
  padding: 12px 0;
`;
