export const validateCpf = (value: string) => {
  const cpf = value.replace(/\D/g, "");

  let sum;
  let rest;
  sum = 0;
  if (cpf === "00000000000") {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }
  return true;
};

export const validateCnpj = (value: string) => {
  value = value.replace(/[^\d]+/g, "");

  if (value === "") {
    return false;
  }

  if (value.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    value === "00000000000000" ||
    value === "11111111111111" ||
    value === "22222222222222" ||
    value === "33333333333333" ||
    value === "44444444444444" ||
    value === "55555555555555" ||
    value === "66666666666666" ||
    value === "77777777777777" ||
    value === "88888888888888" ||
    value === "99999999999999"
  ) {
    return false;
  }

  // Valida DVs
  let tamanho = value.length - 2;
  let numeros = value.substring(0, tamanho);
  const digitos = value.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseFloat(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseFloat(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = value.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseFloat(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseFloat(digitos.charAt(1))) {
    return false;
  }

  return true;
};
