import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { CloseIcon } from "../Icons";

interface IProps {
  handleClose: () => void;
  isOpen: boolean;
  msg: string;
}

export const Toast: React.SFC<IProps> = props => (
  <Snackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    open={props.isOpen}
    autoHideDuration={5000}
    onClose={props.handleClose}
    message={<span>{props.msg}</span>}
    action={[
      <IconButton key="close" aria-label="Close" color="inherit" onClick={props.handleClose}>
        <CloseIcon />
      </IconButton>,
    ]}
  />
);
