exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__title__DJjaV{font-weight:bold;font-size:18px;line-height:22px;color:#515251}.style__header__jRvn5{display:flex;justify-content:space-between;align-items:center;margin-top:48px;margin-bottom:40px}.style__searchContainer__XEyf1{display:flex;align-items:center;margin-right:24px}.style__searchInput__1Ix91{width:260px;margin-right:16px}.style__searchButton__3VvoM{font-size:18px;padding:0 15px}.style__cardLabelModalAuth__o7m8Z{margin-bottom:10px}.style__labelModalAuth__3nv-9{margin-right:24px;font-size:16px;color:#a6a6a6}.style__valueModalAuth__3lLTj{font-size:14px}.style__footer__bFPFc{display:flex;justify-content:flex-end;align-items:center;border-top:1px solid #ebebeb;width:calc(100% + 48px);padding:24px 24px 0;margin:48px 0 0 -24px}.style__footer__bFPFc>button:not(:last-child){margin-right:16px}.style__inputWithSecret__1xqne{display:flex;justify-content:space-between;align-items:center}.style__inputWithSecret__1xqne div{max-width:288px}.style__inputWithSecret__1xqne svg{cursor:pointer}", ""]);

// Exports
exports.locals = {
	"title": "style__title__DJjaV",
	"header": "style__header__jRvn5",
	"searchContainer": "style__searchContainer__XEyf1",
	"searchInput": "style__searchInput__1Ix91",
	"searchButton": "style__searchButton__3VvoM",
	"cardLabelModalAuth": "style__cardLabelModalAuth__o7m8Z",
	"labelModalAuth": "style__labelModalAuth__3nv-9",
	"valueModalAuth": "style__valueModalAuth__3lLTj",
	"footer": "style__footer__bFPFc",
	"inputWithSecret": "style__inputWithSecret__1xqne"
};