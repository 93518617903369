exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styled__box__3m7YE{height:100%;padding:16px 24px;overflow-y:auto}.styled__box__3m7YE .styled__inputArea__2Oxoh{display:flex;align-items:center;justify-content:flex-end}.styled__box__3m7YE .styled__inputArea__2Oxoh.styled__has-two__3FLie{justify-content:space-between}.styled__nextWrapperButton__3ns0-{min-width:145px;width:fit-content;font-family:Lato;margin:15px 0 15px 15px;text-transform:none;font-size:14px;font-weight:600;text-align:center}.styled__info__BbY7U{margin:8px 0;font-weight:600;font-size:14px;color:#515251}.styled__warning__2s3kA{background-color:#fffbe6;border-color:#ffe58f;border-style:solid;padding:5px 20px;margin-bottom:10px;line-height:1.5}.styled__warning__2s3kA p{color:#515251;font-size:14px}", ""]);

// Exports
exports.locals = {
	"box": "styled__box__3m7YE",
	"inputArea": "styled__inputArea__2Oxoh",
	"has-two": "styled__has-two__3FLie",
	"nextWrapperButton": "styled__nextWrapperButton__3ns0-",
	"info": "styled__info__BbY7U",
	"warning": "styled__warning__2s3kA"
};