/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-duplicate-imports */
import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import moment from "moment";
import { Observer } from "mobx-react";
import { LsaAmlData } from "api/manager-api";

import { Loader } from "../../../../components/Loader";
import { Title } from "../../../../components/PageTitle";
import { ServiceData } from "./ServiceData";
import { AllCheckMapper } from "./Mapper";
import { Centralizer, Container, Separator, SmallTitle, TextShow, Typography } from "./styled";

interface IComplianceProps {
  beneficiaryData?: api.ComplianceData;
  onSelected?: () => Promise<api.ComplianceData>;
}

export function Compliance({ onSelected: onChoose, beneficiaryData: complianceData }: IComplianceProps) {
  const [data, setComplianceData] = useState<api.ComplianceData | null>(null);

  useEffect(() => {
    setComplianceData(null);
    if (onChoose) {
      onChoose().then(setComplianceData);
    }
  }, [onChoose]);

  useEffect(() => {
    setComplianceData(null);
    if (complianceData) {
      setComplianceData(complianceData);
    }
  }, [complianceData]);

  return (
    <Observer>
      {() => {
        if (data) {
          return (
            <>
              <Title style={{ fontWeight: 600, marginBottom: "1.5rem" }}>
                Resultados da busca em serviços de compliance
              </Title>
              {Object.keys(data).map((service: string) => {
                if (service === "clipLaundering") {
                  return (
                    <ServiceData<api.ClipLaunderingData>
                      title="Clip Laundering"
                      content={data[service as "clipLaundering"]}
                    >
                      {clipLaundering => (
                        <>
                          <TextShow title="Crimes" text={clipLaundering.crimes.join(", ")} />
                          <TextShow title="Envolvimento" text={clipLaundering.involvement} />
                          <TextShow title="PPE" text={clipLaundering.pep} />
                          <TextShow title="Tipo" text={clipLaundering.type} />
                          <SmallTitle>Noticias</SmallTitle>
                          {clipLaundering.news.map((n, i) => (
                            <>
                              <Container paddingLeft="1rem">
                                <TextShow title="Título" text={n.title} />
                                <TextShow title="Data de revisão" text={n.reviewDate} />
                                <TextShow title="Conteúdo" text={n.content} />
                                {i < clipLaundering.news.length - 1 && <Separator />}
                              </Container>
                            </>
                          ))}
                        </>
                      )}
                    </ServiceData>
                  );
                }

                if (service === "allCheck") {
                  return (
                    <ServiceData<api.AllCheckData>
                      title="All Check"
                      content={
                        data[service] && (data[service] as any).data && (data[service] as any).data.registrationData
                          ? data[service]
                          : null
                      }
                    >
                      {allCheck => (
                        <>
                          <AllCheckMapper title="PPE" data={allCheck.ppe} dataKey="ppe" highlight />
                          <AllCheckMapper title="Dados de óbito" data={allCheck.death} dataKey="death" highlight />
                          <AllCheckMapper
                            title="Dados de registro"
                            data={allCheck.registrationData}
                            dataKey="registrationData"
                          />
                          <AllCheckMapper title="Dados Bancários" data={allCheck.BankData} dataKey="BankData" />
                          <AllCheckMapper title="Dados de endereço" data={allCheck.address} dataKey="address" />
                          <AllCheckMapper title="Cadastro de cheques sem fundo" data={allCheck.ccf} dataKey="ccf" />
                          <AllCheckMapper title="Empresa" data={allCheck.companies} dataKey="companies" />
                          <AllCheckMapper title="Emails" data={allCheck.emails} dataKey="emails" />
                          <AllCheckMapper title="Parceiros" data={allCheck.partners} dataKey="partners" />
                          <AllCheckMapper title="Dados dos telefones" data={allCheck.phones} dataKey="phones" />
                          <AllCheckMapper title="Veículos" data={allCheck.vehicles} dataKey="vehicles" />
                          <AllCheckMapper
                            title="Rede de contatos"
                            data={allCheck.watchNetwork}
                            dataKey="watchNetwork"
                          />
                          <AllCheckMapper
                            title="Histórico de trabalho"
                            data={allCheck.workHistory}
                            dataKey="workHistory"
                          />
                        </>
                      )}
                    </ServiceData>
                  );
                }

                if (service === "pepAml") {
                  return (
                    <ServiceData<api.PepAmlData>
                      key="aml"
                      title="AML - Pessoa Politicamente Exposta"
                      content={data[service as "pepAml"]}
                    >
                      {aml => (
                        <>
                          <TextShow title="Score" text={String(aml.score)} />
                          <TextShow title="Cidade" text={aml.city} />
                          <TextShow title="Informações Adicionais" text={aml.additionalInfo} />
                          <TextShow title="Classificação" text={aml.classification} />
                          <TextShow title="Documento" text={aml.cpfOrCnpj} />
                          <TextShow title="Descrição" text={aml.description} />
                          <TextShow
                            title="Período"
                            text={`${moment(aml.startDate || undefined).format("DD/MM/YYYY")} - ${moment(
                              aml.endDate || undefined,
                            ).format("DD/MM/YYYY")}`}
                          />
                          <TextShow title="PPE Oficial" text={aml.officialFlag} />
                          <TextShow title="Estado" text={aml.state} />
                        </>
                      )}
                    </ServiceData>
                  );
                }

                if (service === "lsaAml") {
                  return (
                    <ServiceData<LsaAmlData>
                      key="aml"
                      title="AML - Lista Socioambiental"
                      content={data[service as "lsaAml"]}
                    >
                      {lsaAml => (
                        <>
                          <TextShow title="Pseudônimos" text={lsaAml.pseudonyms} />
                          <TextShow title="Razão" text={lsaAml.reason} />
                          <TextShow title="Síntese" text={lsaAml.synthesis} />
                          <TextShow title="Qtd. de informações abonadas" text={lsaAml.amountAbon} />
                          <TextShow title="Qtd. de informações desabonadas" text={lsaAml.amountDesabon} />
                          <TextShow title="Data de modificação" text={lsaAml.dateModification} />
                        </>
                      )}
                    </ServiceData>
                  );
                }

                if (service === "lrnAml") {
                  return (
                    <ServiceData<api.LrnAmlData>
                      key="aml"
                      title="AML - Listas Restritivas Nacionais"
                      content={data[service as "lrnAml"]}
                    >
                      {lrnAml => (
                        <>
                          <TextShow title="Score" text={String(lrnAml.score)} />
                          <TextShow title="Citado na mídia" text={lrnAml.citadoMidia ? "Sim" : "Não"} />
                          <TextShow title="Crimes" text={lrnAml.crimes.join(", ")} />
                          <TextShow title="Data" text={lrnAml.data} />
                          <TextShow title="Envolvimento" text={lrnAml.envolvimento} />
                        </>
                      )}
                    </ServiceData>
                  );
                }

                return null;
              })}
            </>
          );
        }

        return (
          <Centralizer>
            <Loader />
            <Typography>Aguarde, a resposta dos serviços pode demorar.</Typography>
          </Centralizer>
        );
      }}
    </Observer>
  );
}
