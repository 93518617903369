/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { RouterStore } from "mobx-react-router";
import { action, observable } from "mobx";
import { Alert } from "legacy/components/Alert";
import { colors } from "utils";

import { AuthStore, GeneralStore } from "../../stores";
import { dashboardPath } from "../../utils";
import { FormLogin, IFields } from "./FormLogin";
import { requireNotAuth } from "../../components/PrivateRouteHook";
import { SvgLogoLogin } from "../../assets/img/SvgLogoLogin";

interface IProps {
  authStore?: AuthStore;
  routerStore?: RouterStore;
  generalStore?: GeneralStore;
}

@inject("authStore", "routerStore", "generalStore")
@observer
class RawLogin extends React.Component<IProps> {
  @observable public loading = false;

  @observable public loginError: string | null = null;

  @observable public urlToRedirect: string | null = null;

  constructor(props: IProps) {
    super(props);

    this.saveUrlToRedirect();
  }

  @action
  private saveUrlToRedirect() {
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get("path");

    this.urlToRedirect = path;
  }

  @action
  private toggleLoginError = (msg?: string) => {
    this.loginError = msg || null;
  };

  private onSubmit = async (values: IFields) => {
    this.loading = true;

    const resp = await this.props.authStore!.onLogin(values.cpf.replace(/\D/gu, ""), values.password);

    this.loading = false;

    if (resp.status === "error") {
      this.toggleLoginError(resp.message || "Ocorreu um erro ao realizar o login.");
      return;
    }

    this.props.routerStore!.push(this.urlToRedirect || dashboardPath);
  };

  public render() {
    return (
      <StyledWrapper>
        <Alert
          onClose={() => this.toggleLoginError()}
          isOpen={Boolean(this.loginError)}
          title="Um problema ocorreu"
          text={this.loginError || ""}
        />

        <Container>
          <StyledLogoContainer>
            <Title>Dashboard</Title>
            <Logo />
          </StyledLogoContainer>
          <FormWrapper>
            <FormLogin isLoading={this.loading} onSubmit={async values => this.onSubmit(values)} />
          </FormWrapper>
        </Container>
      </StyledWrapper>
    );
  }
}

export const Login = requireNotAuth(RawLogin);

const StyledWrapper = styled.div`
  background-color: ${colors.lightGray};
  height: 100%;
  padding: 5px;
`;

const Container = styled.main`
  display: flex;
  background-color: ${colors.white};
  flex-direction: column;
  justify-content: start;
  align-items: center;
  max-width: 575.5px;
  min-height: 92vh;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.1);
`;

const StyledLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 72px;
`;

const Title = styled.h3`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: ${colors.lightPink};
`;

const Logo = styled(SvgLogoLogin)`
  width: 140px;
  height: 67px;
`;

const FormWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
`;
