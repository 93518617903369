import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { AdminUserPermission, PfPj, SecurityStatus } from "api/manager-api";
import { SvgError } from "legacy/icons/SvgError";
import { SvgEdit } from "legacy/icons/SvgEdit";
import { SvgConfirm } from "legacy/icons/SvgConfirm";
import { InnerAlert } from "legacy/components";
import { Loader } from "legacy/components/Loader";

import { GeneralStore, IPartnerIdLimit, PartnerStore, TransactionalBoundariesStore } from "../../../stores";
import { Dialog } from "../../../components/Dialog";
import { Switch } from "../../../components/Switch";
import { Table } from "../../../components/Table";
import {
  AlertModal,
  ContainerActions,
  ErrorText,
  FilterTitle,
  Header,
  Link,
  PartnerSelect,
  Right,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextArea,
  Title,
  TitleContainer,
  WrapperSwitch,
  WrapperTable,
} from "./style";
import { SafetyLimitsConfiguration } from "./SafetyLimitsConfiguration";

interface IProps {
  generalStore?: GeneralStore;
  partnerStore?: PartnerStore;
  transactionalBoundariesStore?: TransactionalBoundariesStore;
}

interface IOption<T> {
  value: T;
  label: string;
}

type OptionList<T> = Array<IOption<T>>;

@inject("transactionalBoundariesStore", "generalStore", "partnerStore")
@observer
export class SafetyLimits extends React.Component<IProps> {
  @observable private loading = true;

  @observable private error: string | null = null;

  @observable private errorOpen = false;

  @observable private saveDialogOpen = false;

  @observable private securityStatus: SecurityStatus | null = null;

  @observable private configuration = false;

  @observable private personType: PfPj = PfPj.pf;

  @observable private partners: IPartnerIdLimit[] = [];

  @observable private selectedPartner: IPartnerIdLimit | null = null;

  @observable private editSuccessOpen = false;

  private personTypes: OptionList<PfPj> = [
    {
      label: "PF - Pessoa Física",
      value: PfPj.pf,
    },
    {
      label: "PJ - Pessoa Jurídica",
      value: PfPj.pj,
    },
  ];

  public async componentDidMount() {
    this.getPartners();
    this.getData();
  }

  @action
  private getData = async () => {
    try {
      this.loading = true;
      await this.props.transactionalBoundariesStore!.getConfigurationSecurityStatus(
        this.selectedPartner?.value || null,
      );
      this.securityStatus = this.props.transactionalBoundariesStore!.securityStatus!;
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter os serviços.";
    } finally {
      this.loading = false;
    }
  };

  @action
  private getPartners = async () => {
    try {
      this.loading = true;
      await this.props.partnerStore!.getPartners();
      const noPartner = { label: "Todos os parceiros", value: null };

      this.partners = [
        noPartner,
        ...this.props.partnerStore!.partners!.map(partner => ({ label: partner.name, value: partner.id })),
      ];
      this.error = "";
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter os serviços.";
      this.errorOpen = true;
    } finally {
      this.loading = false;
    }
  };

  private updateSecurityStatus = async () => {
    try {
      await this.props.transactionalBoundariesStore!.updateSecurityStatus(
        this.selectedPartner?.value || null,
        this.securityStatus!,
      );
    } catch {
      this.errorOpen = true;
    } finally {
      this.loading = false;
    }
  };

  @action
  private handleConfiguration = (ref: boolean) => {
    this.configuration = !this.configuration;
    if (ref) {
      this.editSuccessOpen = true;
      this.getData();
    }
  };

  @action
  public handleEdit = (person: PfPj) => {
    this.personType = person;
    this.handleConfiguration(false);
  };

  public handleConfigLimit = () => {
    return (
      <SafetyLimitsConfiguration
        selectedPartner={this.selectedPartner}
        createConfiguration={this.handleConfiguration}
        personType={this.personType}
      />
    );
  };

  private renderData = () => {
    const { hasPermission } = this.props.generalStore!;
    const securityStatus = this.securityStatus ? [this.securityStatus.pf, this.securityStatus.pj] : [];

    if (this.error) {
      return (
        <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={async () => this.getData()} />
      );
    }

    const columns = [
      "Tipo de Pessoa",
      "TED/DOC",
      "Transferência Interna",
      "Pagamentos de Convênios",
      "Pagamentos de Boletos",
      "Auto Boleto",
      "Ações",
    ];

    return (
      <>
        <div>
          <FilterTitle>Parceiros</FilterTitle>
          <PartnerSelect
            isSearchable
            value={this.partners.filter(
              partner => this.selectedPartner && this.selectedPartner.value === partner.value,
            )}
            options={this.partners}
            onChange={(e: IPartnerIdLimit) => {
              this.selectedPartner = e;
              this.getData();
            }}
            placeholder="Todos os parceiros"
          />
        </div>

        {!this.loading && (
          <WrapperTable>
            <Table
              titles={columns}
              ids={[PfPj.pf, PfPj.pj]}
              data={securityStatus.map((status, index) => {
                const data = [
                  this.personTypes[index].label,
                  <WrapperSwitch key={"tedThird"}>
                    <Switch
                      checked={status.tedThird}
                      handleChange={() => {
                        status.tedThird = !status.tedThird!;
                        this.saveDialogOpen = true;
                      }}
                      disabled={!hasPermission(AdminUserPermission.editSafetyLimit)}
                    />
                    {status.tedThird ? "Ativado" : "Desativado"}
                  </WrapperSwitch>,
                  <WrapperSwitch key={"internalTransfer"}>
                    <Switch
                      checked={status.internalTransfer}
                      handleChange={() => {
                        status.internalTransfer = !status.internalTransfer;
                        this.saveDialogOpen = true;
                      }}
                      disabled={!hasPermission(AdminUserPermission.editSafetyLimit)}
                    />
                    {status.internalTransfer ? "Ativado" : "Desativado"}
                  </WrapperSwitch>,
                  <WrapperSwitch key={"concessionaire"}>
                    <Switch
                      checked={status.concessionaire}
                      handleChange={() => {
                        status.concessionaire = !status.concessionaire;
                        this.saveDialogOpen = true;
                      }}
                      disabled={!hasPermission(AdminUserPermission.editSafetyLimit)}
                    />
                    {status.concessionaire ? "Ativado" : "Desativado"}
                  </WrapperSwitch>,
                  <WrapperSwitch key={"bankSlip"}>
                    <Switch
                      checked={status.bankSlip}
                      handleChange={() => {
                        status.bankSlip = !status.bankSlip;
                        this.saveDialogOpen = true;
                      }}
                      disabled={!hasPermission(AdminUserPermission.editSafetyLimit)}
                    />
                    {status.bankSlip ? "Ativado" : "Desativado"}
                  </WrapperSwitch>,
                  <WrapperSwitch key={"autoBankSlip"}>
                    <Switch
                      checked={status.autoBankSlip}
                      handleChange={() => {
                        status.autoBankSlip = !status.autoBankSlip;
                        this.saveDialogOpen = true;
                      }}
                      disabled={!hasPermission(AdminUserPermission.editSafetyLimit)}
                    />
                    {status.autoBankSlip ? "Ativado" : "Desativado"}
                  </WrapperSwitch>,
                  <Link
                    onClick={() => this.handleEdit(this.personTypes[index].value)}
                    type="button"
                    key={"Editar"}
                    disabled={!hasPermission(AdminUserPermission.editSafetyLimit)}
                  >
                    <ContainerActions>
                      <SvgEdit />
                      Editar
                    </ContainerActions>
                  </Link>,
                ];

                return data;
              })}
            />
          </WrapperTable>
        )}

        {this.loading && <Loader />}
      </>
    );
  };

  public render() {
    return (
      <>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal>
            <SvgError
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>{`Erro ao salvar o limite padrão`}</ErrorText>
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.editSuccessOpen}
          onClose={() => (this.editSuccessOpen = false)}
        >
          <AlertModal style={{ textAlign: "center" }}>
            <SvgConfirm
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            {`Configuração editada com sucesso!`}
          </AlertModal>
        </Dialog>
        {this.securityStatus && (
          <Dialog
            showCloseButton
            closeButtonSize={"30px"}
            maxWidth={"md"}
            title={`Deseja salvar as alterações para o Limite de Segurança?`}
            isOpen={this.saveDialogOpen}
            onClose={() => {
              this.saveDialogOpen = false;
              this.getData();
            }}
          >
            <StyledTextArea
              value={this.securityStatus.observation ? this.securityStatus.observation : ""}
              onChange={e => (this.securityStatus!.observation = e.target.value)}
            />
            <Right>
              <StyledOutlinedButton
                onClick={() => {
                  this.saveDialogOpen = false;
                  this.getData();
                }}
                label="Cancelar"
                type="submit"
              />
              <StyledRaisedButton
                onClick={async () => {
                  this.loading = true;
                  this.saveDialogOpen = false;
                  this.updateSecurityStatus();
                }}
                label="Salvar"
                type="submit"
              />
            </Right>
          </Dialog>
        )}
        <Header>
          <TitleContainer>
            <Title>Limites de Segurança</Title>
          </TitleContainer>
        </Header>
        {this.configuration ? this.handleConfigLimit() : this.renderData()}
      </>
    );
  }
}
