/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable sort-keys */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { FilterFields, PartnerName } from "api/manager-api";
import styled from "styled-components";
import save from "save-file";
import { OpeningAccountsReport } from "legacy/components/OpeningAccountsReport";
import { OpeningAccountsReportsFilter, skeleton } from "legacy/components/OpeningAccountsReportsFilter";

import { colors, connect, ConnectedComponent, openingAccountsProfilePath, redirectStatusToSection } from "../../utils";
import { PartnerStore, ReportStore, RouterStore } from "../../stores";
import { Fetch } from "../../components/Fetchable";
import { FetchableItem } from "../../components/FetchableItem";
import { GeneralStore } from "../../../manager/stores/GeneralStore";
import { Loader } from "../../components/Loader";

interface IStores {
  reportStore: ReportStore;
  partnerStore: PartnerStore;
  generalStore: GeneralStore;
  routerStore: RouterStore;
}

@connect("reportStore", "partnerStore", "generalStore", "routerStore")
@observer
export class OpeningAccountsReports extends ConnectedComponent<{}, IStores> {
  @observable private fetchPartners: Fetch<PartnerName[]> = null;

  @observable private limit = 30;

  @observable private offset = 0;

  @observable private newFilter: FilterFields = skeleton;

  @observable private loadingChangePage = false;

  public componentDidMount() {
    this.stores.reportStore.accountsReport = null;
    if (this.stores.reportStore.lastFilter) {
      this.newFilter = this.stores.reportStore.lastFilter;
    }

    this.getPartners();
  }

  @action
  public getReport = async (filter: FilterFields) => {
    this.loadingChangePage = true;

    try {
      await this.stores.reportStore.getFilteredAccounts(filter, {
        limit: this.limit,
        offset: this.offset,
      });
    } catch (err) {
      this.stores.generalStore!.showToast(err.message || "Não foi possível fazer a pesquisa.");
      this.stores.reportStore.accountsReport = null;
    } finally {
      this.loadingChangePage = false;
    }
  };

  private getPartners = async () => {
    this.fetchPartners = this.stores.partnerStore.getPartners();
  };

  private handleFilter = (filter?: FilterFields, isPagination = false) => {
    this.stores.reportStore.clearLastSearch();
    if (!isPagination) {
      this.offset = 0;
    }

    if (filter) {
      this.newFilter = {
        ...filter,
        typePerson: filter.typePerson ? (filter.typePerson as any).value : null,
        gender: filter.gender ? (filter.gender as any).value : null,
        approvation: filter.approvation ? (filter.approvation as any).value : null,
        subType: filter.subType ? (filter.subType as any).value : null,
        journeyType: filter.journeyType ? (filter.journeyType as any).value : null,
      };
    }

    this.getReport(this.newFilter);
  };

  @action
  private handleDownloadExcel = async () => {
    try {
      const buffer = await this.stores.reportStore.getFilteredExcel(this.newFilter);

      await this.sendDownload(buffer);
    } catch (error) {
      this.stores.generalStore!.showToast(error.message || "Não foi possível gerar a planilha.");
    }
  };

  private sendDownload = (buffer: Buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `relatorio-abertura-de-contas.xlsx`);
  };

  @action
  private onChangePage = (page: number) => {
    if (this.loadingChangePage) {
      return;
    }

    this.offset = this.limit * page;
    this.handleFilter(undefined, true);
  };

  private onClickRow = (e: string) => {
    const { accountsReport, lastAccountsReport } = this.stores.reportStore;
    let section = this.stores.routerStore.history.location.toString();
    const idx = parseInt(e.split("-")[1], 10);
    const report = (accountsReport || lastAccountsReport)!.users[idx];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (report.status && report.complianceStatus) {
      if (report.subType) {
        section = redirectStatusToSection(report.status, report.complianceStatus, report.subType);
      } else {
        section = redirectStatusToSection(report.status, report.complianceStatus, null);
      }
    }

    if (report.complianceStatus !== "approved") {
      section = "workflow";
    }

    this.stores.routerStore.push(openingAccountsProfilePath(section, report.documentNumber, report.id));
    this.stores.reportStore.saveLastSearch(
      this.newFilter,
      accountsReport || lastAccountsReport,
      this.limit,
      this.offset,
    );
  };

  public render() {
    const { accountsReport, lastFilter, lastAccountsReport, lastLimit, lastOffset } = this.stores.reportStore;

    return (
      <>
        <Header>
          <TitleContainer>
            <Title>Relatório de Abertura de Contas</Title>
          </TitleContainer>
        </Header>
        <Row>
          <FetchableItem fetch={this.fetchPartners}>
            {partners => (
              <OpeningAccountsReportsFilter
                partners={partners}
                onSubmit={this.handleFilter}
                lastFilter={lastFilter || undefined}
              />
            )}
          </FetchableItem>
        </Row>
        {this.loadingChangePage ? (
          <Flex>
            <Loader compact />
          </Flex>
        ) : (
          <Row style={{ overflowX: "auto" }}>
            {(accountsReport || lastAccountsReport) && (
              <OpeningAccountsReport
                onClickRow={this.onClickRow}
                report={lastAccountsReport || accountsReport}
                limit={lastLimit || this.limit}
                offset={lastOffset || this.offset}
                onChangePage={this.onChangePage}
                onClickDownloadExcel={this.handleDownloadExcel}
                routerStore={this.stores.routerStore}
              />
            )}
          </Row>
        )}
      </>
    );
  }
}

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: normal;
`;

const Row = styled.div`
  display: grid;
  grid-template-areas: "search select";
  grid-template-columns: auto min-content;
  align-items: center;
`;

const Flex = styled.div`
  width: 100%;
  margin: 20% 0;
  display: flex;
  justify-content: center;
`;
