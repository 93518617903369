import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import { RaisedButton } from "legacy/components/RaisedButton";
import { OutlinedButton } from "../OutlinedButton";
import styled from "styled-components";

interface IProps {
  title: string;
  text: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const ConfirmDialog: React.SFC<IProps> = props => {
  const onCancel = () => {
    if (!props.isLoading) {
      props.onCancel();
    }
  };

  return (
    <Dialog open={props.isOpen} onClose={onCancel}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledOutlinedButton color="secundary" onClick={props.onCancel} label="Cancelar" disabled={props.isLoading} />
        <StyledRaisedButton color="primary" onClick={props.onConfirm} label="Confirmar" loading={props.isLoading} />
      </DialogActions>
    </Dialog>
  );
};

const StyledOutlinedButton = styled(OutlinedButton)`
  width: 106px;
  height: 50px;
`;
const StyledRaisedButton = styled(RaisedButton)`
  width: 106px;
  height: 50px;
  text-align: center;
  padding: 0 !important;
`;
