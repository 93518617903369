import * as React from "react";
import classNames from "classnames";

import * as s from "./style.scss";

export interface ITextArea extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  errorText?: string;
  resize?: boolean;
}

export function TextArea({ errorText, resize, className, ...props }: ITextArea) {
  return (
    <div className={s.container}>
      <textarea
        className={classNames(s.textarea, className, {
          [s.resize]: resize,
          [s.errorMessage]: errorText,
        })}
        {...props}
      />
      {errorText && <div className={s.errorTextWrapper}>{errorText}</div>}
    </div>
  );
}
