import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { AdminUserPermission, PfPj, SegmentLimit } from "api/manager-api";
import { Loader } from "legacy/components/Loader";
import { SvgEdit } from "legacy/icons/SvgEdit";
import { SvgDelete } from "legacy/icons/SvgDelete";
import { SvgConfirm } from "legacy/icons/SvgConfirm";
import { SvgError } from "legacy/icons/SvgError";

import {
  AlertModal,
  Container,
  ContainerActions,
  ContainerTable,
  ErrorText,
  FilterContainer,
  FilterWrapper,
  Label,
  Left,
  Link,
  PartnerSelect,
  PersonSelect,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextArea,
  StyledTextInput,
  TableLabel,
  Text,
  Title,
  WrapperTable,
} from "./style";
import { SvgArrowRepeat } from "../../../../../../legacy/icons/SvgArrowRepeat";
import {
  GeneralStore,
  IPartnerIdLimit,
  IPersonTypes,
  PartnerStore,
  TransactionalBoundariesStore,
} from "../../../../stores";
import { Table } from "../../../../components/Table";
import { Dialog } from "../../../../components/Dialog";
import { SegmentBoundariesWizard } from "./SegmentBoundariesWizard";

interface IProps {
  generalStore?: GeneralStore;
  partnerStore?: PartnerStore;
  transactionalBoundariesStore?: TransactionalBoundariesStore;
}

interface IOption<T> {
  value: T;
  label: string;
}

export interface ISearch {
  personType: PfPj | null;
  segment: string;
}

interface IPagination {
  page: number;
  offset: number;
  limit: number;
}

type OptionList<T> = Array<IOption<T>>;

@inject("transactionalBoundariesStore", "generalStore", "partnerStore")
@observer
export class SegmentBoundaries extends React.Component<IProps> {
  @observable private loading = true;

  @observable private segmentLimit: SegmentLimit | null = null;

  @observable private segmentWizard = false;

  @observable private deleteDialogOpen = false;

  @observable private observation = "";

  @observable private successOpen = false;

  @observable private errorOpen = false;

  @observable private personType: PfPj | null = null;

  @observable private segment = "";

  @observable private search: ISearch = {
    personType: null,
    segment: "",
  };

  @observable private pagination: IPagination = {
    limit: 5,
    offset: 0,
    page: 1,
  };

  @observable private partners: IPartnerIdLimit[] = [];

  @observable private selectedPartner: string | null = null;

  public async componentDidMount() {
    this.getPartners();
    this.getSegmentLimitTransactional();
  }

  public getSegmentLimitTransactional = async () => {
    this.personType = this.search.personType;
    this.segment = this.search.segment;

    this.loading = true;
    try {
      await this.props.transactionalBoundariesStore!.getSegmentLimitTransactional(
        this.selectedPartner,
        this.search.personType,
        this.search.segment,
        this.pagination,
      );
    } catch {
      this.errorOpen = true;
    }

    this.loading = false;
  };

  @action
  private getPartners = async () => {
    try {
      this.loading = true;
      await this.props.partnerStore!.getPartners();
      this.partners = this.props.partnerStore!.partners!.map(partner => ({ label: partner.name, value: partner.id }));
    } catch (err) {
      this.errorOpen = true;
    } finally {
      this.loading = false;
    }
  };

  public getPage = async () => {
    this.loading = true;
    try {
      await this.props.transactionalBoundariesStore!.getSegmentLimitTransactional(
        this.selectedPartner,
        this.personType,
        this.segment,
        this.pagination,
      );
    } catch {
      this.errorOpen = true;
    }

    this.search = {
      personType: this.personType,
      segment: this.segment,
    };

    this.loading = false;
  };

  private personTypes: OptionList<PfPj | null> = [
    {
      label: "Tipo de pessoa",
      value: null,
    },
    {
      label: "PF - Pessoa Física",
      value: PfPj.pf,
    },
    {
      label: "PJ - Pessoa Jurídica",
      value: PfPj.pj,
    },
  ];

  @action
  private handleClear = () => {
    this.search = {
      personType: null,
      segment: "",
    };
  };

  @action
  private handleSearch = () => {
    this.pagination = { limit: 5, offset: 0, page: 1 };
    this.getSegmentLimitTransactional();
  };

  @action
  private handleSegmentWizard = (ref: boolean) => {
    this.segmentWizard = !this.segmentWizard;
    if (ref) {
      this.handleSearch();
    }
  };

  @action
  private handleChangePage = (page: number) => {
    this.pagination.offset = this.pagination.limit * page;
    this.pagination.page = page;
    this.getPage();
  };

  private renderData = () => {
    const { segmentsLimit } = this.props.transactionalBoundariesStore!.segmentLimitReturn!;
    const { hasPermission } = this.props.generalStore!;

    if (this.loading) {
      return <Loader />;
    }

    const columns = ["Nome do limite", "Tipo de Pessoa", "Segmento do limite", "Ações"];

    return (
      <WrapperTable>
        {segmentsLimit.length > 0 ? (
          <Table
            titles={columns}
            ids={segmentsLimit.map(e => e.name)}
            isPagination
            totalRows={this.props.transactionalBoundariesStore!.segmentLimitReturn.count}
            rowsPerPage={this.pagination.limit}
            rowsPerPageOptions={[5, 10, 15, 20]}
            page={this.pagination.offset / this.pagination.limit}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={e => {
              this.pagination.limit = Number(e);
              this.handleChangePage(0);
            }}
            data={segmentsLimit.map(limit => {
              const data = [
                limit.name,
                limit.segment.accountType === PfPj.pf ? "PF - Pessoa Física" : "PJ - Pessoa Jurídica",
                limit.segment.name,
                <Container key={limit.name}>
                  <Link
                    onClick={() => this.handleEdit(limit)}
                    type="button"
                    disabled={!hasPermission(AdminUserPermission.editSegmentBoundaries)}
                  >
                    <ContainerActions>
                      <SvgEdit />
                      Editar
                    </ContainerActions>
                  </Link>
                  <Link
                    onClick={() => {
                      this.segmentLimit = limit;
                      this.deleteDialogOpen = true;
                    }}
                    type="button"
                    disabled={!hasPermission(AdminUserPermission.editSegmentBoundaries)}
                  >
                    <ContainerActions>
                      <SvgDelete />
                      Excluir
                    </ContainerActions>
                  </Link>
                </Container>,
              ];

              return data;
            })}
          />
        ) : (
          <TableLabel>Não existe limite criado </TableLabel>
        )}
        <StyledRaisedButton
          onClick={() => {
            this.handleSegmentWizard(false);
            this.segmentLimit = null;
          }}
          label="Criar Limite"
          type="button"
          disabled={!hasPermission(AdminUserPermission.addSegmentBoundaries)}
        />
      </WrapperTable>
    );
  };

  @action
  private handleDelete = async (id: string, observation: string) => {
    try {
      await this.props.transactionalBoundariesStore!.deleteSegmentLimitTransactional(id, observation);
    } catch {
      this.errorOpen = true;
    } finally {
      this.getSegmentLimitTransactional();
      this.deleteDialogOpen = false;
      this.successOpen = true;
    }
  };

  @action
  public handleEdit = (segment: SegmentLimit) => {
    this.segmentLimit = segment;
    this.handleSegmentWizard(false);
  };

  public renderCreateOrEditSegment = () => {
    return (
      <SegmentBoundariesWizard createSegmentLimit={this.handleSegmentWizard} segmentLimitEdit={this.segmentLimit} />
    );
  };

  public render() {
    return (
      <>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.successOpen}
          onClose={() => (this.successOpen = false)}
        >
          <AlertModal style={{ textAlign: "center" }}>
            <SvgConfirm
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            Limite &apos;{this.segmentLimit ? this.segmentLimit.name : ""}&apos; excluído com sucesso!
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"sm"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-50px 0 12px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>Não foi possível realizar a operação</ErrorText>
          </AlertModal>
        </Dialog>
        {this.segmentLimit && (
          <Dialog
            showCloseButton
            closeButtonSize={"30px"}
            maxWidth={"md"}
            title={`Excluir limite '${this.segmentLimit.name}'?`}
            isOpen={this.deleteDialogOpen}
            onClose={() => (this.deleteDialogOpen = false)}
          >
            <Text>Ao excluir esse limite, todos os clientes vinculados a ele serão vinculados ao Limite Padrão.</Text>
            <StyledTextArea
              placeholder="Obs.:"
              value={this.observation}
              onChange={e => (this.observation = e.target.value)}
              maxLength={200}
            />
            <StyledRaisedButton
              onClick={async () => this.handleDelete(this.segmentLimit!.segment.id!.toString(), this.observation)}
              label="Excluir"
              type="submit"
            />
          </Dialog>
        )}
        {this.segmentWizard ? (
          this.renderCreateOrEditSegment()
        ) : (
          <>
            <FilterWrapper>
              <Title>Filtros de Pesquisa</Title>
              <Left>
                <FilterContainer>
                  <Label>Parceiros</Label>
                  <PartnerSelect
                    isSearchable
                    value={this.partners.filter(partner => partner.value === this.selectedPartner)}
                    options={[{ label: "Todos os parceiros", value: null }, ...this.partners]}
                    onChange={(e: IPartnerIdLimit) => (this.selectedPartner = e.value)}
                    placeholder="Todos os parceiros"
                  />
                </FilterContainer>
                <FilterContainer>
                  <Label>Tipo de Pessoa</Label>
                  <PersonSelect
                    value={this.personTypes.filter(type => type.value === this.search.personType)}
                    options={this.personTypes}
                    onChange={(e: IPersonTypes) => (this.search.personType = e.value)}
                    placeholder="Selecione o Tipo de Pessoa"
                  />
                </FilterContainer>
                <FilterContainer>
                  <Label>Limite do Segmento</Label>
                  <StyledTextInput
                    placeholder="Pesquisar o Limite do Segmento"
                    value={this.search.segment}
                    onChange={e => (this.search.segment = e.target.value)}
                    onKeyPress={e => {
                      if (e.key === "Enter") {
                        this.handleSearch();
                      }
                    }}
                  />
                </FilterContainer>
                <StyledOutlinedButton
                  label="Limpar Filtros"
                  onClick={this.handleClear}
                  iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
                />
                <StyledRaisedButton onClick={this.handleSearch} label="Buscar" type="submit" />
              </Left>
            </FilterWrapper>
            <Title>Limites dos Segmentos</Title>
            <ContainerTable>{this.renderData()}</ContainerTable>
          </>
        )}
      </>
    );
  }
}
