import * as React from "react";
import styled from "styled-components";
import { TextInput } from "legacy/components/TextInput";
import { RaisedButton } from "legacy/components/RaisedButton";
import { noop } from "rxjs";
import { Loader } from "../Loader";
import searchIcon from "../../assets/icons/svg/search-alt.svg";
interface IProps {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  isLoading?: boolean;
  handleSearch?: () => void;
  minSizeToSearch?: number;
  placeholder?: string;
  withoutButton?: boolean;
  className?: string;
  isError?: boolean;
  errorText?: string;
  enabledWithoutInput?: boolean;
  mask?: Array<string | RegExp>;
}

export const SearchBar: React.SFC<IProps> = props => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    (props.handleSearch || noop)();
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => (props.onChange || noop)(e.currentTarget.value);
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => (props.onBlur || noop)(e.currentTarget.value);
  const disabled = !props.enabledWithoutInput && props.value.length < (props.minSizeToSearch || 1);

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        value={props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={props.isLoading}
        // error={props.isError}
        errorText={props.errorText}
        label={props.label}
        placeholder={props.placeholder || ""}
        mask={props.mask}
        className={props.className}
      />
      <Companion>
        {!props.withoutButton ? (
          <RaisedButton label="pesquisar" type="submit" loading={props.isLoading} disabled={disabled}>
            <img src={searchIcon} alt="Search" />
          </RaisedButton>
        ) : props.isLoading ? (
          <Loader compact />
        ) : null}
      </Companion>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: row;
`;

const Companion = styled.div`
  flex: 0 0 50px;
  margin-left: 16px;
`;
