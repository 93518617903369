import * as api from "api/manager-api";
import { IFilterAccountsPath } from "../stores";
import { ADMIN_USER_TOKEN } from "./storageTokens";
import { formatCnpj, formatCpf } from "@brazilian-utils/formatters";
import { prettierAccountName } from "./prettier";
import { identity } from "rxjs";

export const uppercaseFirst = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const listToMatrix = <T>(list: T[], elementsPerSubArray: number) => {
  const matrix: T[][] = [];
  let i: number;
  let k: number;

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }

  return matrix;
};

export const range = (length: number, init: number = 0): number[] => {
  return Array.apply(null, { length } as any[]).map((_: any, i: number) => init + i);
};

export const tableFilter = (filter: any) => (row: any) => {
  for (const field in filter) {
    if (filter[field] !== null && filter[field] !== row[field]) {
      return null;
    }
  }

  return row;
};

export const userStatusToEnum = (status: IFilterAccountsPath) => {
  switch (status) {
    case "all":
      return null;
    case "approved":
      return api.UserStatus.active;
    case "tipping":
      return api.UserStatus.active;
    case "positivize":
      return api.UserStatus.active;
    case "pending":
      return api.UserStatus.pending;
    case "cpfList":
      return api.UserStatus.active;
    case "canceled":
      return api.UserStatus.canceled;
    case "reproved":
      return api.UserStatus.repproved;
    case "notFinished":
      return api.UserStatus.notFinished;
    case "workflow":
      return api.UserStatus.finished;
    case "processing":
      return api.UserStatus.processing;
    case "approvedPending":
      return api.UserStatus.approvePending;
    case "approvePendingWithoutDocument":
      return api.UserStatus.approvePending;
    case "approvePendingWithDocument":
      return api.UserStatus.approvePending;
    default:
      return status;
  }
};

export const convertStatusTyping = (status: api.UserStatus) => {
  switch (status) {
    case api.UserStatus.active:
      return api.Status.active;
    case api.UserStatus.canceled:
      return api.Status.canceled;
    case api.UserStatus.finished:
      return api.Status.finished;
    case api.UserStatus.notFinished:
      return api.Status.notFinished;
    case api.UserStatus.pending:
      return api.Status.pending;
    case api.UserStatus.repproved:
      return api.Status.repproved;
    case api.UserStatus.processing:
      return api.Status.processing;
    case api.UserStatus.approvePending:
      return api.Status.approvePending;
    case "approvePendingWithDocument":
      return api.Status.approvePendingWithDocument;
    case "approvePendingWithoutDocument":
      return api.Status.approvePendingWithoutDocument;
    default:
      return status;
  }
};

export const revertSectionToId = (section: string): IFilterAccountsPath => {
  switch (section) {
    case "todas-as-contas":
      return "all";
    case "workflow":
      return "workflow";
    case "aprovados":
      return "approved";
    case "pendentes":
      return "pending";
    case "cancelados":
      return "canceled";
    case "reprovados":
      return "reproved";
    case "nao-finalizadas":
      return "notFinished";
    case "lista-cpf":
      return "cpfList";
    case "em-processamento":
      return "processing";
    case "contas-simples":
      return "approvedPending";
    default:
      return "workflow";
  }
};

export const redirectStatusToSection = (
  userStatus: api.UserStatus | null,
  comlianceStatus: api.ComplianceStatus | null,
  userStatusSubType: api.SubType | null,
): string => {
  switch (comlianceStatus) {
    case api.ComplianceStatus.waiting:
      switch (userStatus || null) {
        case api.UserStatus.approvePendingWithoutDocument:
          return "workflow";
        case api.UserStatus.approvePendingWithDocument:
          return "workflow";
      }
    case api.ComplianceStatus.manualAnalysis:
      switch (userStatus || null) {
        case null:
          return "todas-as-contas";
        case api.UserStatus.active:
          switch (userStatusSubType) {
            case api.SubType.full:
              return "aprovados";
            case api.SubType.simple:
              return "contas-simples";
          }
          return "aprovados";
        case api.UserStatus.canceled:
          return "cancelados";
        case api.UserStatus.repproved:
          return "reprovados";
        case api.UserStatus.notFinished:
          return "nao-finalizadas";
        case api.UserStatus.finished:
          return "workflow";
        case api.UserStatus.approvePending:
          return "workflow";
        case api.UserStatus.approvePendingWithoutDocument:
          return "workflow";
        case api.UserStatus.approvePendingWithDocument:
          return "workflow";
        default:
          return status;
      }
    case api.ComplianceStatus.approved:
    case api.ComplianceStatus.waiting:
      switch (userStatus || null) {
        case null:
          return "todas-as-contas";
        case api.UserStatus.active:
          switch (userStatusSubType) {
            case api.SubType.full:
              return "aprovados";
            case api.SubType.simple:
              return "contas-simples";
          }
          return "aprovados";
        case api.UserStatus.pending:
          return "pendentes";
        case api.UserStatus.canceled:
          return "cancelados";
        case api.UserStatus.repproved:
          return "reprovados";
        case api.UserStatus.notFinished:
          return "nao-finalizadas";
        case api.UserStatus.finished:
          return "workflow";
        case api.UserStatus.processing:
          return "em-processamento";
        case api.UserStatus.approvePending:
          return "workflow";
        case api.UserStatus.approvePendingWithoutDocument:
          return "contas-simples";
        case api.UserStatus.approvePendingWithDocument:
          return "contas-simples";
        default:
          return status;
      }

    case api.ComplianceStatus.repproved:
      return "reprovados";
      
    // case api.ComplianceStatus.manualAnalysis:
    //   return "workflow";


    case api.ComplianceStatus.processing:
      return "em-processamento";

    default:
      return status;
  }
};

export const RequestFn = async <T, S = any>(request: () => Promise<T>): Promise<IResponse<T, S>> => {
  const resp: IResponse<T, S> = {
    status: "success",
    message: null,
    data: null,
    errorType: null,
  };

  try {
    resp.data = await request();
  } catch (error) {
    resp.status = "error";
    resp.message = error.message;
    resp.errorType = error.type;
  }

  return resp;
};

export const requestWithAuth = async <T>(request: () => Promise<T>): Promise<IResponse<T, api.ErrorType>> => {
  const res = await RequestFn<T, api.ErrorType>(request);

  if (res.errorType === api.ErrorType.TerminateSession) {
    localStorage.removeItem("adminUserToken");
    location.reload();
    return res;
  } else {
    return res;
  }
};

export const coalesce = <T extends {}>(firstValue: T, ...values: T[]) => (fn?: (value: T) => any) => {
  for (const value of [firstValue, ...values]) {
    if (value) {
      return fn ? fn(value) : value;
    }
  }
  return null;
};

const sessionCheck = (target: object, fn: any) => async (...args: any[]) => {
  try {
    return await fn.call(target, ...args);
  } catch (err) {
    if (err.type === api.ErrorType.TerminateSession) {
      localStorage.removeItem(ADMIN_USER_TOKEN);
      location.reload();
    }
    throw err;
  }
};

export function session(
  _: object,
  __: string | symbol,
  descriptor?: TypedPropertyDescriptor<(...params: any[]) => Promise<any>>,
): any {
  let fn: any;
  let safeFn: any;

  if (descriptor) {
    fn = descriptor.value;
  }

  return {
    configurable: true,
    enumerable: false,
    get() {
      if (!safeFn) {
        safeFn = sessionCheck(this, fn);
      }
      return safeFn;
    },
    set(newFn: any) {
      safeFn = undefined;
      fn = newFn;
    },
  };
}

export const truncateStr = (str?: string, maxChar?: number) => {
  if (str!.length <= maxChar!) {
    return str;
  }

  const strSliced = str!.slice(0, maxChar);

  if (strSliced.length <= 3) {
    return `${strSliced}...`;
  }

  const stringTruncate = `${strSliced.slice(0, -3)}...`;

  return stringTruncate;
};

export const searchEncode = (query = "") => query.replace(/ /g, "+").replace(/[^\w\+]/g, "");

export const searchDecode = (query = "") => searchMask(query.replace(/\+/g, " "));

export const searchMask = (query: string, accountMask: boolean = true) => {
  if (query.match(/^[0-9-./]+$/)) {
    const unmasked = query.replace(/[^0-9]/g, "");
    const { length } = unmasked;

    let mask: (value: string) => string = identity;

    if (length > 11) {
      mask = formatCnpj;
    } else if (length > 10) {
      mask = formatCpf;
    } else if (length > 1) {
      mask = accountMask ? prettierAccountName : formatCpf;
    }

    return mask(unmasked);
  }

  return query;
};

export const pluralFormCheck = (length: number): string => {
  if (length !== 1) {
    return "resultados";
  }
  return "resultado";
};

export const formatDateToBRLDate = (date: string) => {
  if (!date) {
    return "-";
  }

  const [year, month, day] = date.split("-");

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const formatDate = (unformattedDate: any): string => {
  const date = new Date(unformattedDate);

  const dia = date.getDate().toString();
  const diaF = dia.length == 1 ? "0" + dia : dia;
  const mes = (date.getMonth() + 1).toString();
  const mesF = mes.length == 1 ? "0" + mes : mes;
  const anoF = date.getFullYear();

  const birthdate = `${diaF}/${mesF}/${anoF}`;

  return birthdate;
};

function getKeyObjectByValue(object: any, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

const fileTypes: { bufferType: string; extensionType: string }[] = [
  { bufferType: "image/png", extensionType: "png" },
  { bufferType: "image/jpeg", extensionType: "jpg" },
  { bufferType: "application/vnd.oasis.opendocument.spreadsheet", extensionType: "ods" },
  { bufferType: "text/rtf", extensionType: "txt" },
  { bufferType: "application/msword", extensionType: "doc" },
  { bufferType: "application/msword", extensionType: "dot" },
  { bufferType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", extensionType: "docx" },
  { bufferType: "application/vnd.openxmlformats-officedocument.wordprocessingml.template", extensionType: "dotx" },
  { bufferType: "application/vnd.ms-word.document.macroEnabled.12", extensionType: "docm" },
  { bufferType: "application/vnd.ms-word.template.macroEnabled.12", extensionType: "dotm" },
  { bufferType: "application/vnd.ms-excel", extensionType: "xls" },
  { bufferType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", extensionType: "xlsx" },
  { bufferType: "application/vnd.openxmlformats-officedocument.spreadsheetml.template", extensionType: "xltx" },
  { bufferType: "application/vnd.ms-excel.sheet.macroEnabled.12", extensionType: "xlsm" },
  { bufferType: "application/vnd.ms-excel.template.macroEnabled.12", extensionType: "xltm" },
  { bufferType: "application/vnd.ms-excel.addin.macroEnabled.12", extensionType: "xlam" },
  { bufferType: "application/vnd.ms-excel.sheet.binary.macroEnabled.12", extensionType: "xlsb" },
  { bufferType: "application/vnd.ms-powerpoint", extensionType: "ppt" },
];

export const formatType = (unformattedFileType: string, type: "extensionType" | "bufferType"): string => {
  const [possibleTypes] = fileTypes.filter(type => {
    return getKeyObjectByValue(type, unformattedFileType);
  });

  if (!possibleTypes) {
    console.log("Tipo de Arquivo não definido no objeto fileTypes (helpers.ts linha 328)");
    return unformattedFileType;
  }

  return possibleTypes[type];
};

export const renderCharacters = (character: string, times: number): string => {
  let concatenatedCharacters = "";
  for (let i = 0; i < times; i++) {
    concatenatedCharacters += character;
  }
  return concatenatedCharacters;
};

export const clearPlaceholderWhenStateChanges = (placeholder: string[], state: boolean) => {
  return state ? placeholder.join("").toString() : "";
};
