import React, { SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import { ProofOfLifeInfo } from "api/manager-api";
import { Loader } from "legacy/components/Loader";
import { compareString } from "utils";
import { profile } from "console";

import { IStatusOptions } from "../SelectStatus";
import { ActionsDropdown } from "../../../components/ActionsDropdown";
import { Table } from "../../../components/Table";
import { StatusLabel } from "../../../../../components/StatusLabel";

import s from "../styles.scss";

interface IProps {
  proofs: ProofOfLifeInfo[];
  setSelectedProof: React.Dispatch<SetStateAction<ProofOfLifeInfo | undefined>>;
  approveProof: (id: ProofOfLifeInfo, approved: boolean) => void;
  search: string;
  errorMessage: string;
  selectedStatus: IStatusOptions;
  permissions: {
    approve: boolean;
    reprove: boolean;
  };
}

const limit = 10;

export function ProofOfLifeTable({
  proofs,
  setSelectedProof,
  approveProof,
  search,
  errorMessage,
  permissions,
  selectedStatus,
}: IProps) {
  const [page, setPage] = useState(0);
  const [loadingRow, setLoadingRow] = useState("");
  const [filteredProofs, setFilteredProofs] = React.useState<ProofOfLifeInfo[]>([]);
  const offset = page * limit;
  const pageProofs = filteredProofs.slice(offset, offset + limit);

  useEffect(() => {
    const allStatus = selectedStatus.map(status => status.key);

    setFilteredProofs(
      proofs.filter(proof => {
        const cpfNumbers = proof.documentNumber.replace(/\D/gu, "");

        return (
          (compareString(proof.name, search) ||
            compareString(cpfNumbers, search) ||
            compareString(proof.documentNumber, search)) &&
          allStatus.includes(proof.status)
        );
      }),
    );
  }, [search, proofs, selectedStatus]);

  useEffect(() => {
    setPage(0);
  }, [search, selectedStatus]);

  useEffect(() => {
    setLoadingRow("");
  }, [proofs, errorMessage]);

  const dropdownActions = (proof: ProofOfLifeInfo) => {
    const actions = [
      {
        label: "Ver Solicitante",
        onClick: () => {
          setSelectedProof(proof);
        },
      },
    ];

    const canChangeStatus = proof.status !== "APPROVED" && proof.status !== "REFUSED";

    if (permissions.approve && canChangeStatus) {
      actions.push({
        label: "Aprovar solicitação",
        onClick: () => {
          approveProof(proof, true);
          setLoadingRow(proof.id);
        },
      });
    }

    if (permissions.reprove && canChangeStatus) {
      actions.push({
        label: "Reprovar solicitação",
        onClick: () => {
          approveProof(proof, false);
          setLoadingRow(proof.id);
        },
      });
    }

    return actions;
  };

  return (
    <section className={s.wrapperTable}>
      <Table
        titles={["Nome", "CPF", "Data e hora", "Status", "Opções"]}
        ids={pageProofs.map(proof => proof.id)}
        data={pageProofs.map(proof => [
          proof.name,
          proof.documentNumber,
          moment(proof.createdAt).format("L [às] HH:mm"),
          <StatusProof key={proof.id} receivedStatus={proof.status} />,
          loadingRow === proof.id ? (
            <div className={s.loadrow}>
              <Loader compact />
            </div>
          ) : (
            <ActionsDropdown key={proof.id} options={dropdownActions(proof)} />
          ),
        ])}
        isPagination
        totalRows={filteredProofs.length}
        rowsPerPage={limit}
        page={offset / limit}
        rowsPerPageOptions={[limit]}
        handleChangePage={setPage}
      />
    </section>
  );
}

interface IStatusProof {
  receivedStatus: string;
}

function StatusProof({ receivedStatus }: IStatusProof): JSX.Element {
  switch (receivedStatus) {
    case "APPROVED":
      return <StatusLabel type="positive" text="Aprovado" />;
    case "PROCESSING":
      return <StatusLabel type="neutral" text="Pendente" />;
    case "CANCELED":
      return <StatusLabel type="negative" text="Cancelado" />;
    case "REFUSED":
      return <StatusLabel type="negative" text="Reprovado" />;
    case "REFUSEDACERTID":
      return <StatusLabel type="negative" text="Reprovado pelo acertid" />;
    case "ANALYSISMANUALACERTID":
      return <StatusLabel type="neutral" text="Aguardando Análise" />;
    default:
      return <>receivedStatus</>;
  }
}
