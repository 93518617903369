import { action, observable } from "mobx";
import * as api from "api/manager-api";

export interface IPersonTypes {
  label: string;
  value: api.PfPj;
}
import { RootStore } from "./RootStore";
import { session } from "../utils";

export class TransactionalBoundariesStore {
  protected rootStore: RootStore;

  @observable public limitsDefault: api.DefaultLimitsFormated[] = [];

  @observable public segmentLimitReturn: api.SegmentLimitReturn = { count: 0, segmentsLimit: [] };

  @observable public segmentReturn: api.SegmentReturn = { count: 0, segments: [] };

  @observable public segment: api.Segment | null = null;

  @observable public segmentLimitCreate: api.SegmentLimitCreate | null = null;

  @observable public logLimits: api.LogsLimitReturn = { count: 0, logsLimits: [] };

  @observable public clientLimits: api.ClientLimitReturn = { clientLimit: [], count: 0 };

  @observable public clientLimit: api.ClientLimit[] = [];

  @observable public clientLimitCreate: api.DefaultLimitsFormated[] = [];

  @observable public securityStatus: api.SecurityStatus | null = null;

  @observable public securityResponse: api.Security | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // LIMITE PADRÃO
  @session
  @action
  public getLimitsDefault = async (partnerId: string | null, filter: api.PfPj) => {
    this.limitsDefault = await api.getDefaultLimitDTO(partnerId, filter);
  };

  @session
  @action
  public saveLimitDefault = async (partnerId: string | null, limitsDefault: api.LimitsDefaultDTO) => {
    await api.updateDefaultLimitDTO(partnerId, limitsDefault);
  };

  // SEGMENTOS
  @session
  @action
  public getSegmentTransactionalById = async (id: string) => {
    this.segment = await api.getSegmentTransactionalById(id);
  };

  @session
  @action
  public getSegmentTransactional = async (
    partnerId: string | null,
    filter: api.PfPj | null,
    segmentName: string | null,
    pagination: api.LimitsPagination,
  ) => {
    this.segmentReturn = await api.getSegmentTransactional(partnerId, filter, segmentName, pagination);
  };

  @session
  @action
  public deleteSegmentTransactional = async (idSegment: number, observation: string | null = null) => {
    await api.deleteSegmentTransactional(idSegment.toString(), observation);
  };

  @session
  @action
  public createSegmentTransactional = async (segment: api.Segment) => {
    await api.createSegmentTransactional(segment);
  };

  @session
  @action
  public updateSegmentTransactional = async (segment: api.Segment) => {
    await api.updateSegmentTransactional(segment);
  };

  // LIMITE DOS SEGMENTOS
  @session
  @action
  public getSegmentLimitTransactional = async (
    partnerId: string | null,
    filter: api.PfPj | null,
    segmentName: string | null,
    pagination: api.LimitsPagination,
  ) => {
    this.segmentLimitReturn = await api.getSegmentLimitTransactional(partnerId, filter, segmentName, pagination);
  };

  @session
  @action
  public createSegmentLimitTransactional = async (segmentLimit: api.SegmentLimit) => {
    await api.createSegmentLimitTransactional(segmentLimit);
  };

  @session
  @action
  public updateSegmentLimitTransactional = async (segmentLimit: api.SegmentLimit) => {
    await api.updateSegmentLimitTransactional(segmentLimit);
  };

  @session
  @action
  public deleteSegmentLimitTransactional = async (id: string, observation: string | null) => {
    await api.deleteSegmentLimitTransactional(id, observation);
  };

  @session
  @action
  public filterSegmentLimitTransactional = async () => {
    this.segmentLimitCreate = await api.filterSegmentLimitTransactional();
  };

  // PERSONALIZAÇÃO DE LIMITES
  @session
  @action
  public getClientLimit = async (partnerId: string, filter: string, pagination: api.LimitsPagination) => {
    this.clientLimits = await api.getClientLimit(partnerId, filter, pagination);
  };

  @session
  @action
  public getClientLimitByCpfCnpj = async (cpfCnpj: string, registered: boolean) => {
    const limit = await api.getClientLimitByCpfCnpj(cpfCnpj, registered);

    this.clientLimit = [limit];
  };

  @session
  @action
  public createClientLimit = async (clientLimit: api.ClientLimit) => {
    await api.createClientLimit(clientLimit);
  };

  @session
  @action
  public updateClientLimit = async (clientLimit: api.ClientLimit) => {
    await api.updateClientLimit(clientLimit);
  };

  @session
  @action
  public deleteClientLimit = async (cpfCnpj: string, observation: string) => {
    await api.deleteClientLimit(cpfCnpj, observation);
  };

  @session
  @action
  public formatTrasactionalOperations = async () => {
    this.clientLimitCreate = await api.formatTrasactionalOperations();
  };

  // HISTÓRICO
  @session
  @action
  public getLogsLimit = async (
    partnerId: string | null,
    email: string | null,
    dataInicio: Date | null,
    datafim: Date | null,
    pagination: api.LimitsPagination,
  ) => {
    this.logLimits = await api.getLogsLimit(partnerId, email, dataInicio, datafim, pagination);
  };

  @session
  @action
  public getLogsLimitByClient = async (
    cpfCnpj: string | null,
    dataInicio: Date | null,
    datafim: Date | null,
    pagination: api.LimitsPagination,
  ) => {
    this.logLimits = await api.getLogsLimitByClient(cpfCnpj, dataInicio, datafim, pagination);
  };

  // LIMITES DE SEGURANÇA
  @session
  @action
  public getConfigurationSecurityStatus = async (partnerId: string | null) => {
    this.securityStatus = await api.getConfigurationSecurityStatus(partnerId);
  };

  @session
  @action
  public getConfigurationSecurity = async (partnerId: string | null) => {
    this.securityResponse = await api.getConfigurationSecurity(partnerId);
  };

  @session
  public updateSecurity = async (partnerId: string | null, security: api.Security, pfPj: api.PfPj) => {
    await api.updateSecurity(partnerId, security, pfPj);
  };

  @session
  public updateSecurityStatus = async (partnerId: string | null, securityStatus: api.SecurityStatus) => {
    await api.updateSecurityStatus(partnerId, securityStatus);
  };
}
