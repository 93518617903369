import * as api from "api/manager-api";
import { observer } from "mobx-react";
import * as React from "react";
import { IFilterAccountsPath } from "../../stores";
import { openingAccountsSectionPath } from "../../utils";
import { InnerAlert } from "../InnerAlert";
import { Pf } from "./Pf";
import { Pj } from "./Pj";
import { Mei } from "./Mei";
import { PjOld } from "./PjOld";

interface IProps {
  pfPj: api.PfPj | null;
  user: api.RegisterUserFields | null;
  company: api.RegisterCompanyV13 | null;
  analyzeCompany: api.CompanyAnalisys | null;
  filterAccountPath?: IFilterAccountsPath;
  isOldPj?: boolean;
  push?: (url: string) => void;
}

interface IState {}

@observer
export class RegisterData extends React.Component<IProps, IState> {
  public render() {
    const { pfPj, push, filterAccountPath, user, analyzeCompany, company } = this.props;

    if (pfPj === api.PfPj.pf) {
      return <Pf user={user} />;
    }

    if (pfPj === api.PfPj.pj && this.props.isOldPj) {
      return <PjOld company={company} />;
    }

    if (pfPj === api.PfPj.pj) {
      return <Pj company={analyzeCompany} />;
    }

    if (pfPj === api.PfPj.mei) {
      return <Mei company={company} />;
    }

    if (push && filterAccountPath) {
      return (
        <InnerAlert
          message="Número de documento inválido"
          buttonLabel="Voltar para a lista"
          buttonClick={() => push!(openingAccountsSectionPath(filterAccountPath))}
        />
      );
    }

    return <div />;
  }
}
