import React, { useState } from "react";
import { StatusUserProofOfLife } from "api/manager-api";

import { IOption, SelecMultiple } from "shared/components/SelectMultiple";

import { allStatus } from "./allStatus";

export type IStatusOptions = Array<IOption<StatusUserProofOfLife>>;

interface IProps {
  status: IStatusOptions;
  setStatus: React.Dispatch<React.SetStateAction<IStatusOptions>>;
}

export function SelectStatus({ status, setStatus }: IProps) {
  return (
    <SelecMultiple<StatusUserProofOfLife>
      label="Status da solicitação"
      selectAll
      selectAllText="Todos os status"
      options={allStatus}
      values={status}
      onToggle={setStatus}
    />
  );
}

export const statusHandler = () => useState<IStatusOptions>(allStatus);
