import React from "react";
import { Route, Switch } from "react-router-dom";
import { partnerManagementPath } from "@manager/utils";

import { PartnerList } from "./PartnerList";
import { PartnerSingle } from "./PartnerSingle";
import { PartnerAdd } from "./PartnerAdd";

export function PartnerManagement() {
  return (
    <Switch>
      <Route exact path={`${partnerManagementPath}/adicionar-parceiro`} component={PartnerAdd} />
      <Route exact path={`${partnerManagementPath}/:partnerId`} component={PartnerSingle} />
      <Route exact path={partnerManagementPath} component={PartnerList} />
    </Switch>
  );
}
