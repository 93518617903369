import styled from "styled-components";
import { OutlinedButton } from "legacy/components/OutlinedButton";
import { RaisedButton } from "legacy/components/RaisedButton";

import { colors } from "../../../../../utils";
import { TextAreaInput } from "../../../components/TextAreaInput";
import { Select } from "../../../../manager/components/Select";

export const WrapperTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
  overflow: auto;
`;

export const WrapperSwitch = styled.div`
  display: flex;
  flex-direction: inherit;
  align-items: center;
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`;

export const ErrorText = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const Link = styled.button`
  font-size: 14px;
  color: ${colors.violetRed};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const Right = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const FilterTitle = styled.span`
  color: ${colors.gray};
  font-size: 14px;
`;

export const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
`;

export const StyledRaisedButton = styled(RaisedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
`;

export const StyledTextArea = styled(TextAreaInput)`
  margin-top: 24px;
  height: 109px;
`;

export const PartnerSelect = styled(Select)`
  margin-top: 16px;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darkGray};
  width: 180px;
`;
