import styled from "styled-components";
import { colors } from "utils";
import * as api from "api/manager-api";

interface ReviewCardContainerProps {
  reviewType: api.FileReviewType | "none";
}

export const Container = styled.div`
  padding: 20px;

  p {
    color: ${colors.black};
  }
`;

export const Title = styled.span`
  color: ${colors.darkGray};
  font-weight: 600;
`;

const containerTypeVariation = {
  informative: "#a0fbaf",
  restrictive: colors.lightVioletRed,
  none: colors.lightGray,
};

export const ReviewCardContainer = styled.div<ReviewCardContainerProps>`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  background-color: ${props => containerTypeVariation[props.reviewType]};
  border-radius: 4px;
  padding: 10px;
`;
