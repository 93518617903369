import * as React from "react";
import { UserCompany } from "api/manager-api";
import { observer } from "mobx-react";
import { TextInput } from "legacy/components/TextInput";

import { Spacing, Title } from "../components";
import { maskValidateCel, maskValidateCpf } from "../../../../utils";

interface IProps {
  userCompany: UserCompany;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

@observer
export class UserData extends React.Component<IProps> {
  public render() {
    const { userCompany, onChange } = this.props;

    return (
      <>
        <Spacing />
        <TextInput name="name" value={userCompany.name} onChange={onChange} label={<Title>Nome</Title>} />
        <Spacing />
        <TextInput
          name="cpf"
          value={userCompany.cpf}
          onChange={onChange}
          style={{ width: "198px" }}
          label={<Title>CPF</Title>}
          mask={maskValidateCpf}
        />
        <Spacing />
        <TextInput
          name="email"
          value={userCompany.email}
          onChange={onChange}
          style={{ width: "412px" }}
          label={<Title>E-mail</Title>}
        />
        <Spacing />
        <TextInput
          name="phone"
          value={userCompany.phone || ""}
          onChange={onChange}
          style={{ width: "412px" }}
          label={<Title>Telefone</Title>}
          mask={maskValidateCel}
        />
      </>
    );
  }
}
