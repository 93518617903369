import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  size?: string;
}

export const CloseIcon = (props: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "40px"}
    height={props.size || "40px"}
    viewBox="0 0 40 40"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="20" cy="20" r="20" fill="#F2F2F2" />
      <g fill="#A6A6A6" transform="rotate(45 -.485 28.485)">
        <rect width="16" height="2" y="7" rx="1" />
        <rect width="16" height="2" y="7" rx="1" transform="rotate(90 8 8)" />
      </g>
    </g>
  </svg>
);
