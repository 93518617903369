import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { ValueType } from "react-select";
import { InternalCreateAccountMEI } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";

import { OutlinedButton } from "../../../components/OutlinedButton";
import {
  colors,
  maskValidateCel,
  maskValidateCep,
  maskValidateCnpj,
  maskValidateCpf,
  maskValidateDate,
  validateCnpj,
  validateCpf,
} from "../../../utils";
import { TextInput } from "../../../components/TextInput";

import { Option } from "..";

import { PartnerStore } from "../../../stores";
import { Select } from "../../../components/Select";
import { Checkbox } from "../../../components/Checkbox";

type FieldError = "cpf" | "email" | "cnpj" | "cep";

interface IProps {
  onClose: () => void;
  onSubmit: (option: Option, data: InternalCreateAccountMEI) => Promise<void>;
  partnerStore?: PartnerStore;
}

interface IPartnerOption {
  value: string | null;
  label: string | null;
}

export function CreateMEIDialog({ onClose, onSubmit, partnerStore }: IProps) {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [cpf, setCpf] = React.useState("");
  const [cnpj, setCnpj] = React.useState("");
  const [cep, setCep] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");
  const [ownerMotherName, setOwnerMotherName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [addressNumber, setAddressNumber] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [fantasyName, setFantasyName] = React.useState("");
  const [foundDate, setFoundDate] = React.useState("");
  const [partner, setPartner] = React.useState<IPartnerOption>();
  const [openAccount, setOpenAccount] = React.useState(false);
  const [optionsPartners, setOptionsPartners] = React.useState<IPartnerOption[]>();

  function getPartners() {
    partnerStore?.getPartners().then(() => {
      const newPartnersOptions: IPartnerOption[] = [];

      partnerStore?.partners?.forEach(p => {
        newPartnersOptions.push({
          label: p.name,
          value: p.id,
        });
      });
      setOptionsPartners(newPartnersOptions);
    });
  }

  React.useEffect(() => getPartners(), []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const newErrors: FieldError[] = [];

    if (!validateCpf(cpf)) {
      newErrors.push("cpf");
    }

    if (!validateCnpj(cnpj)) {
      newErrors.push("cnpj");
    }

    if (!email) {
      newErrors.push("email");
    }

    setErrors(newErrors);
    if (newErrors.length > 0) {
      setLoading(false);
      return;
    }

    await onSubmit("createMEI", {
      addressNumber,
      cep,
      cnpj,
      companyName,
      cpf,
      email,
      fantasyName,
      foundationDate: foundDate ? moment(foundDate, "DD/MM/YYYY").toDate() : null,
      openAccount,
      ownerBirthDate: birthday ? moment(birthday, "DD/MM/YYYY").toDate() : null,
      ownerMotherName,
      ownerName,
      partnerId: partner ? partner.value : null,
      phone,
    });

    setLoading(false);

    onClose();
  }

  function handlePartner(option: ValueType<IPartnerOption>) {
    const partnerOption = option as IPartnerOption;

    setPartner(partnerOption);
  }

  function handleOpenAccount() {
    return setOpenAccount(!openAccount);
  }

  return (
    <Box onSubmit={handleSubmit}>
      <Info>
        <TextInput
          placeholder="CPF *"
          mask={maskValidateCpf}
          value={cpf}
          error={Boolean(errors.find(e => e === "cpf"))}
          onChange={e => setCpf(e.target.value)}
        />
        <TextInput
          placeholder="CNPJ *"
          mask={maskValidateCnpj}
          value={cnpj}
          error={Boolean(errors.find(e => e === "cnpj"))}
          onChange={e => setCnpj(e.target.value)}
        />
        <TextInput
          placeholder="Email *"
          value={email}
          error={Boolean(errors.find(e => e === "email"))}
          onChange={e => setEmail(e.target.value)}
        />
        <TextInput
          placeholder="CEP *"
          mask={maskValidateCep}
          value={cep}
          error={Boolean(errors.find(e => e === "cep"))}
          onChange={e => setCep(e.target.value)}
        />

        <TextInput placeholder="Número" value={addressNumber} onChange={e => setAddressNumber(e.target.value)} />

        <TextInput
          placeholder="Data de nascimento"
          mask={maskValidateDate}
          value={birthday}
          onChange={e => setBirthday(e.target.value)}
        />

        <TextInput
          placeholder="Nome da mãe"
          value={ownerMotherName}
          onChange={e => setOwnerMotherName(e.target.value)}
        />

        <TextInput
          placeholder="Telefone"
          mask={maskValidateCel}
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />

        <TextInput placeholder="Nome do empresário" onChange={e => setOwnerName(e.target.value)} value={ownerName} />

        <TextInput placeholder="Nome da empresa" value={companyName} onChange={e => setCompanyName(e.target.value)} />

        <TextInput placeholder="Nome fantasia" value={fantasyName} onChange={e => setFantasyName(e.target.value)} />

        <TextInput
          placeholder="Data de fundação"
          mask={maskValidateDate}
          value={foundDate}
          onChange={e => setFoundDate(e.target.value)}
        />

        <Select
          isDisabled={loading}
          options={optionsPartners}
          onChange={handlePartner}
          placeholder="Parceiros"
          value={partner}
        />
        <Row>
          <Checkbox checked={openAccount} handleChange={handleOpenAccount} />
          <Text>Abrir conta</Text>
        </Row>
      </Info>
      <InputArea>
        <BackButtonWrapper disabled={loading} type="button" label="Voltar" onClick={onClose} />
        <NextButtonWrapper loading={loading} type="submit" label="Criar" />
      </InputArea>
    </Box>
  );
}

const Box = styled.form`
  padding: 16px 24px;
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

const NextButtonWrapper = styled(RaisedButton)`
  min-width: 145px;
  width: fit-content;
  font-family: Lato;
  margin: 15px 0 15px 15px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;

const Info = styled.div`
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.black};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  padding: 0;
  margin: 0;
`;
