import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { getPartners } from "api/manager-api";

import { Loader } from "../../../../components/Loader";
import { Table } from "../../../../components/Table";
import { Dialog } from "../../../../components/Dialog";
import { IPartnerId, PartnerStore, TransactionalBoundariesStore } from "../../../../stores";
import {
  AlertModal,
  ErrorText,
  FieldDateRangePicker,
  FilterContainer,
  FilterWrapper,
  Label,
  Left,
  PartnerSelect,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextInput,
  TableContainer,
  Title,
  WrapperTable,
} from "./style";
import { SvgArrowRepeat } from "../../../../../../legacy/icons/SvgArrowRepeat";
import { SvgError } from "../../../../../../legacy/icons/SvgError";
import { DateRange, DateRangePicker } from "../../../../components/DateRangePicker";

interface IProps {
  partnerStore?: PartnerStore;
  transactionalBoundariesStore?: TransactionalBoundariesStore;
}

interface ISearch {
  initialDate: Date | null;
  finalDate: Date | null;
  email: string;
}

interface IPagination {
  page: number;
  offset: number;
  limit: number;
}

@inject("transactionalBoundariesStore", "partnerStore")
@observer
export class InternalLogs extends React.Component<IProps> {
  @observable private loading = true;

  @observable private email = "";

  @observable private initialDate: Date | null = null;

  @observable private finalDate: Date | null = null;

  @observable private errorOpen = false;

  @observable private search: ISearch = {
    email: "",
    finalDate: null,
    initialDate: null,
  };

  @observable private pagination: IPagination = {
    limit: 5,
    offset: 0,
    page: 1,
  };

  @observable private partners: IPartnerId[] = [];

  @observable private selectedPartner: string | null = null;

  public async componentDidMount() {
    this.getPartners();
    this.getLogsLimit();
  }

  public getLogsLimit = async () => {
    this.initialDate = this.search.initialDate;
    this.finalDate = this.search.finalDate;
    this.email = this.search.email;

    this.loading = true;
    await this.props
      .transactionalBoundariesStore!.getLogsLimit(
        this.selectedPartner,
        this.search.email,
        this.search.initialDate,
        this.search.finalDate,
        this.pagination,
      )
      .catch(() => (this.errorOpen = true));

    this.loading = false;
  };

  public getPage = async () => {
    this.loading = true;
    await this.props
      .transactionalBoundariesStore!.getLogsLimit(
        this.selectedPartner,
        this.email,
        this.initialDate,
        this.finalDate,
        this.pagination,
      )
      .catch(() => (this.errorOpen = true));

    this.search = {
      email: this.email,
      finalDate: this.finalDate,
      initialDate: this.initialDate,
    };
    this.loading = false;
  };

  @action
  private getPartners = async () => {
    try {
      this.loading = true;
      await this.props.partnerStore!.getPartners();
      this.partners = this.props.partnerStore!.partners!.map(partner => ({ label: partner.name, value: partner.id }));
    } catch (err) {
      this.errorOpen = true;
    } finally {
      this.loading = false;
    }
  };

  @action
  private handleClear = () => {
    this.search = {
      email: "",
      finalDate: null,
      initialDate: null,
    };
  };

  @action
  private handleSearch = () => {
    this.pagination = { limit: 5, offset: 0, page: 1 };
    this.getLogsLimit();
  };

  @action
  private handleDates = ({ startDate, endDate }: DateRange) => {
    this.search.initialDate = startDate;
    this.search.finalDate = endDate;
  };

  @action
  private handleChangePage = (page: number) => {
    this.pagination.offset = this.pagination.limit * page;
    this.pagination.page = page;
    this.getPage();
  };

  private renderData = () => {
    const { logsLimits } = this.props.transactionalBoundariesStore!.logLimits;

    if (this.loading) {
      return <Loader />;
    }

    const columns = ["Data", "Hora", "Descrição da Alteração", "Alterado por", "Observação"];

    return (
      <WrapperTable>
        <Table
          titles={columns}
          ids={logsLimits.map(e => e.id.toString())}
          isPagination
          totalRows={this.props.transactionalBoundariesStore!.logLimits.count}
          rowsPerPage={this.pagination.limit}
          rowsPerPageOptions={[5, 10, 15, 20]}
          handleChangeRowsPerPage={e => {
            this.pagination.limit = Number(e);
            this.handleChangePage(0);
          }}
          page={this.pagination.offset / this.pagination.limit}
          handleChangePage={this.handleChangePage}
          data={logsLimits.map(limit => {
            const data = [
              limit.date.toLocaleDateString(),
              limit.date.toLocaleTimeString(),
              limit.description.length > 0 ? limit.description : "",
              limit.user,
              limit.observation ? limit.observation : "",
            ];

            return data;
          })}
        />
      </WrapperTable>
    );
  };

  public render() {
    return (
      <>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"sm"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-50px 0 12px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>Não foi possível obter o histórico</ErrorText>
          </AlertModal>
        </Dialog>
        <FilterWrapper>
          <Title>Filtros de pesquisa</Title>
          <Left>
            <FilterContainer>
              <Label>Parceiros</Label>
              <PartnerSelect
                isSearchable
                value={this.partners.filter(partner => partner.value === this.selectedPartner)}
                options={[{ label: "Todos os parceiros", value: null }, ...this.partners]}
                onChange={(e: IPartnerId) => (this.selectedPartner = e.value)}
                placeholder="Todos os parceiros"
              />
            </FilterContainer>
            <FilterContainer>
              <Label>Pesquisar por Data</Label>
              <FieldDateRangePicker>
                <DateRangePicker
                  startDate={this.search.initialDate}
                  endDate={this.search.finalDate}
                  onChange={this.handleDates}
                  startLabel="De"
                  endLabel="Até"
                />
              </FieldDateRangePicker>
            </FilterContainer>
            <FilterContainer>
              <Label>Email do Usuário</Label>
              <StyledTextInput
                placeholder="Pesquisar por email"
                value={this.search.email}
                onChange={e => (this.search.email = e.target.value)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    this.handleSearch();
                  }
                }}
              />
            </FilterContainer>
            <StyledOutlinedButton
              label="Limpar Filtros"
              onClick={this.handleClear}
              iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
            />
            <StyledRaisedButton onClick={this.handleSearch} label="Buscar" type="submit" />
          </Left>
        </FilterWrapper>
        <Title>Logs Internos</Title>
        <TableContainer>{this.renderData()}</TableContainer>
      </>
    );
  }
}
