import { Profile } from "api/manager-api";
import { observer } from "mobx-react";
import * as React from "react";
import { TextInput } from "legacy/components/TextInput";

import { Spacing, Title } from "../components";

interface IProps {
  profile: Profile;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

@observer
export class ProfileData extends React.Component<IProps> {
  public render() {
    return (
      <div style={{ width: "400px" }}>
        <Spacing />
        <TextInput
          name="name"
          value={this.props.profile.name}
          onChange={this.props.onChange}
          label={<Title>Nome</Title>}
        />
      </div>
    );
  }
}
