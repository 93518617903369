import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { Router } from "react-router";
import { setUrl } from "api/manager-api";

import { App } from "./containers/App";
import { rootStore } from "./stores/RootStore";

setUrl(process.env.API_ADMIN_URL ?? "");

ReactDOM.render(
  <Provider {...rootStore}>
    <Router history={rootStore.history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root"),
);
