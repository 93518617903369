/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { EntryStatement, EntryStatementNature, getReceipt, Receipt } from "api/manager-api";
import moment from "moment";
import { useStores } from "@manager/stores/RootStore";

import { Container } from "./styles";
import { Table } from "../../../components/Table";
import { StatementReceipt } from "./receipt";
import { getAmount, prettierNature } from "../utils";

interface StatementProps {
  statements: EntryStatement[];
  accountNumber: string;
}

export function Statement({ statements, accountNumber }: StatementProps) {
  const [selectedEntry, setSelectedEntry] = useState<EntryStatement | null>(null);
  const [showEntry, setShowEntry] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receipt, setReceipt] = useState<Receipt | null>(null);

  const { generalStore } = useStores();

  useEffect(() => {
    if (selectedEntry) {
      getReceiptData(selectedEntry);
    }
  }, [selectedEntry]);

  async function getReceiptData(entry: EntryStatement) {
    setLoading(true);

    try {
      const response = await getReceipt(accountNumber, entry.movementNumber, entry.movementDate);

      setReceipt(response);
    } catch (error) {
      generalStore.showToast("Falha ao obter o comprovante.");
    } finally {
      setLoading(false);
    }
  }

  function handleSelectEntry(id: string) {
    const entry = statements.find(e => e.id === id);

    if (entry) {
      setSelectedEntry(entry);
      setShowEntry(true);
    }
  }

  function renderTable() {
    const columnNames: string[] = ["Data", "Valor", "Descrição", "Finalidade", "Saldo do dia"];

    return (
      <Table
        titles={columnNames}
        ids={statements.map(entry => entry.id)}
        data={statements.map(entry => [
          moment(entry.movementDate).format("DD/MM/YYYY"),
          <p key={entry.id} style={{ color: entry.nature === EntryStatementNature.debit ? "#E71A46" : "#2ECC71" }}>
            {getAmount(entry)}
          </p>,
          entry.description,
          entry.finality,
          (entry.currentBalance / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
        ])}
        trStyles={[
          {
            minWidth: "111px",
            boxSizing: "content-box",
          },
        ]}
        onClickRow={handleSelectEntry}
      />
    );
  }

  return (
    <Container>
      {renderTable()}
      {selectedEntry && (
        <StatementReceipt
          onClose={() => setShowEntry(false)}
          show={showEntry}
          selectedEntry={selectedEntry}
          receiptData={receipt}
          loading={loading}
        />
      )}
    </Container>
  );
}
