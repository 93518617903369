import * as React from "react";
import { AdminUser } from "api/manager-api";

import { Table } from "../../../components/Table";
import { ActionsDropdown, IActionDropdownOptions } from "../../../components/ActionsDropdown";

interface IProps {
  items: AdminUser[];
  onEdit?: (adminUser: AdminUser) => void;
  onBlock?: (adminUser: AdminUser) => void;
}

export function EmployeesList({ items, onEdit, onBlock }: IProps) {
  const hasOptions = Boolean(onBlock) && Boolean(onEdit);
  const titles = ["Nome", "CPF", "E-mail", ...(hasOptions ? ["Ações"] : [])];

  function renderOptions(row: AdminUser) {
    const options: IActionDropdownOptions[] = [];

    if (onEdit) {
      options.push({
        label: "Editar dados",
        onClick: () => onEdit(row),
      });
    }

    if (onBlock) {
      options.push({
        label: row.blocked ? "Desbloquear" : "Bloquear",
        onClick: () => onBlock(row),
      });
    }

    return options.length ? [<ActionsDropdown key={row.cpf} options={options} />] : [];
  }

  return (
    <Table
      titles={titles}
      ids={items.map((_, i) => `emp-${i}`)}
      data={items.map(row => [row.name, row.cpf, row.email, ...renderOptions(row)])}
    />
  );
}
