/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { action, computed, observable } from "mobx";
import { InternalCreateAccountMEI, InternalCreateAccountPF, InternalPositivizeReducedJourney } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { GeneralStore, InternalAPIsStore, PartnerStore, RouterStore } from "../../stores";
import { Dialog } from "../../components/Dialog";
import { RemoveUserDialog } from "./RemoveUserDialog";
import { AddMoneyDialog } from "./AddMoneyDialog";
import { TestFaceMatchDialog } from "./TestFaceMatchDialog";
import { RemoveUserMeiDialog } from "./RemoveUserMeiDialog";
import { CreatePfDialog } from "./CreatePf";
import { CreatePfReducedDialog } from "./CreatePfReduced";
import { RemoveTokenDialog } from "./RemoveTokenDialog";
import { UpdateAcertIdDialog } from "./UpdateAcertId";
import { CreateMEIDialog } from "./CreateMEI";
import { RemoveUserMeiCnpjDialog } from "./RemoveUserMeiCnpjDialog";
import { InnerAlert } from "../../components/InnerAlert";
import { accountsManagerPath } from "../../utils";
import { PositivizeReducedDialog } from "./PositivizeReducedJourney";
import { ActivationCodeGenerationDialog } from "./ActivationCodeGenerationDialog";

export type Option =
  | "deleteUser"
  | "addMoney"
  | "deleteMeiByCnpj"
  | "deleteUserMeiByCnpj"
  | "deleteToken"
  | "createPf"
  | "updateAcertId"
  | "createMEI"
  | "createReducedJourney"
  | "positivizeReducedJourney"
  | "activationCodeGeneration"
  | "testFaceMatch";

export interface IAddMoney {
  branch: string;
  account: string;
  amount: string;
}

export interface IRemoveUser {
  cpf: string;
}

export interface IRemoveUserMEI {
  cnpj: string;
}

export interface ICreatePf {
  cpf: string;
  name: string | null;
  email: string;
  cep: string | null;
  partnerId: string | null;
  openAccount: boolean;
}

export interface ICreateMEI {
  cpf: string;
  cnpj: string | null;
  email: string;
  cep: string | null;
  partnerId: string | null;
  openAccount: boolean;
}

export interface IUpdateAcertId {
  key: string;
}

interface IProps {
  internalAPIsStore?: InternalAPIsStore;
  generalStore?: GeneralStore;
  partnerStore?: PartnerStore;
  routerStore?: RouterStore;
}

export interface IActivationCodeGeneration {
  ddi: string;
  phone: string;
  ddd: string;
  sms: boolean;
  partnerId: string;
}

export interface ITestFaceMatch {
  selfie: Buffer;
  document: Buffer;
}

@inject("internalAPIsStore", "generalStore", "routerStore", "partnerStore")
@observer
export class InternalAPIs extends React.Component<IProps> {
  @observable private isDialogOpen = false;

  @observable private option: Option | null = null;

  @action
  private handleOpenButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const option: Option = event.currentTarget.name as any;

    this.option = option;
    this.isDialogOpen = true;
  };

  @action
  private onCloseDialog = () => {
    this.isDialogOpen = false;
    this.option = null;
  };

  private testFaceMatch = async (data: ITestFaceMatch) => {
    try {
      const match = await this.props.internalAPIsStore!.testFaceMatch(data.selfie, data.document);

      this.props.generalStore!.showToast(match ? "Imagens válidas" : "Imagens não coincidentes");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro, tente novamente mais tarde.");
    }
  };

  private addAccountBalance = async (data: IAddMoney) => {
    try {
      const amount = parseInt(data.amount.replace(/\D*/gu, ""), 10);

      await this.props.internalAPIsStore!.addAccountBalance(data.account, data.branch, amount);
      this.props.generalStore!.showToast("Valor adicionado com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private deleteUserByCpf = async (data: IRemoveUser) => {
    try {
      await this.props.internalAPIsStore!.deleteUserByCpf(data.cpf);
      this.props.generalStore!.showToast("Conta removida com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private deleteMEIByCnpj = async (data: IRemoveUserMEI) => {
    try {
      await this.props.internalAPIsStore!.deleteMEIByCnpj(data.cnpj);
      this.props.generalStore!.showToast("Conta removida com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private deleteUserMeiByCnpj = async (data: IRemoveUserMEI) => {
    try {
      await this.props.internalAPIsStore!.deleteUserMEIByCnpj(data.cnpj);
      this.props.generalStore!.showToast("Conta removida com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private deleteTokenByCpf = async (data: IRemoveUser) => {
    try {
      await this.props.internalAPIsStore!.deleteTokenByCpf(data.cpf);
      this.props.generalStore!.showToast("Token removido com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private InternalTestCreateAccountPF = async (data: InternalCreateAccountPF) => {
    try {
      await this.props.internalAPIsStore!.InternalTestCreateAccountPF(data);
      this.props.generalStore!.showToast("Conta criada com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private internalTestCreateAccountMEI = async (data: InternalCreateAccountMEI) => {
    try {
      await this.props.internalAPIsStore!.internalTestCreateAccountMEI(data);
      this.props.generalStore!.showToast("Conta criada com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private internalPositivizeReducedJourney = async (data: InternalPositivizeReducedJourney) => {
    try {
      await this.props.internalAPIsStore!.internalTestPositivizeReducedJourney(data);
      this.props.generalStore!.showToast("Positivada com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private internalTestUpdateAcertId = async (data: IUpdateAcertId) => {
    try {
      await this.props.internalAPIsStore!.internalTestUpdateAcertId(data.key);
      this.props.generalStore!.showToast("AcertId atualizado com sucesso!");
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Um problema ocorreu");
    }
  };

  private onSubmit = async (option: Option, data: any) => {
    switch (option) {
      case "addMoney":
        await this.addAccountBalance(data);
        break;
      case "deleteUser":
        await this.deleteUserByCpf(data);
        break;
      case "deleteMeiByCnpj":
        await this.deleteMEIByCnpj(data);
        break;
      case "deleteUserMeiByCnpj":
        await this.deleteUserMeiByCnpj(data);
        break;
      case "deleteToken":
        await this.deleteTokenByCpf(data);
        break;
      case "createPf":
        await this.InternalTestCreateAccountPF(data);
        break;
      case "updateAcertId":
        await this.internalTestUpdateAcertId(data);
        break;
      case "createMEI":
        await this.internalTestCreateAccountMEI(data);
        break;
      case "positivizeReducedJourney":
        await this.internalPositivizeReducedJourney(data);
        break;
      case "testFaceMatch":
        await this.testFaceMatch(data);
        break;
      default:
        break;
    }
  };

  @computed
  private get dialog() {
    switch (this.option) {
      case "testFaceMatch":
        return {
          component: <TestFaceMatchDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Comprovação de imagens",
        };
      case "addMoney":
        return {
          component: <AddMoneyDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Adicionar dinheiro em conta",
        };
      case "deleteUser":
        return {
          component: <RemoveUserDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Deletar usuário por CPF",
        };
      case "deleteMeiByCnpj":
        return {
          component: <RemoveUserMeiDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Deletar MEI por CNPJ",
        };
      case "deleteUserMeiByCnpj":
        return {
          component: <RemoveUserMeiCnpjDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Deletar MEI por CNPJ",
        };
      case "deleteToken":
        return {
          component: <RemoveTokenDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Remover Token",
        };
      case "createPf":
        return {
          component: (
            <CreatePfDialog
              onClose={this.onCloseDialog}
              onSubmit={this.onSubmit}
              partnerStore={this.props.partnerStore}
            />
          ),
          title: "Criar cadastro PF",
        };
      case "updateAcertId":
        return {
          component: <UpdateAcertIdDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Atualizar AcertId",
        };
      case "createMEI":
        return {
          component: (
            <CreateMEIDialog
              onClose={this.onCloseDialog}
              onSubmit={this.onSubmit}
              partnerStore={this.props.partnerStore}
            />
          ),
          title: "Criar cadastro MEI",
        };
      case "createReducedJourney":
        return {
          component: (
            <CreatePfReducedDialog
              onClose={this.onCloseDialog}
              onSubmit={this.onSubmit}
              partnerStore={this.props.partnerStore}
            />
          ),
          title: "Criar cadastro PF - Jornada Reduzida",
        };
      case "positivizeReducedJourney":
        return {
          component: <PositivizeReducedDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Positivar cadastro - Jornada Reduzida",
        };
      case "activationCodeGeneration":
        return {
          component: <ActivationCodeGenerationDialog onClose={this.onCloseDialog} onSubmit={this.onSubmit} />,
          title: "Geração de código de ativação - Jornada Reduzida",
        };
      default:
        return null;
    }
  }

  public render() {
    const displayHMLTool =
      process.env.BRANCH &&
      (process.env.BRANCH.startsWith("hml") ||
        process.env.BRANCH.startsWith("dev") ||
        process.env.BRANCH === "homolog");

    return (
      <>
        {this.dialog && (
          <Dialog isOpen={this.isDialogOpen} title={this.dialog.title} onClose={this.onCloseDialog} maxWidth={"xl"}>
            {this.dialog.component}
          </Dialog>
        )}
        {displayHMLTool ? (
          <Container>
            <Title>Ferramentas de Homologação</Title>
            <Table>
              <Item>
                <Label>Deletar usuário por CPF</Label>
                <RaisedButton name="deleteUser" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Deletar MEI por CNPJ</Label>
                <RaisedButton name="deleteUserMeiByCnpj" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Remover Token</Label>
                <RaisedButton name="deleteToken" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Adicionar dinheiro em conta</Label>
                <RaisedButton name="addMoney" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Test FaceMatch</Label>
                <RaisedButton name="testFaceMatch" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Criar cadastro PF</Label>
                <RaisedButton name="createPf" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Criar cadastro MEI</Label>
                <RaisedButton name="createMEI" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Atualizar AcertId</Label>
                <RaisedButton name="updateAcertId" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              {/* <Item>
                <Label>Criar cadastro PF - Jornada Reduzida</Label>
                <RaisedButton name="createReducedJourney" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Positivar cadastro - Jornada Reduzida</Label>
                <RaisedButton name="positivizeReducedJourney" onClick={this.handleOpenButton} label="Abrir" />
              </Item>
              <Item>
                <Label>Geração de código de ativação - Jornada Reduzida</Label>
                <RaisedButton name="activationCodeGeneration" onClick={this.handleOpenButton} label="Abrir" />
              </Item> */}
            </Table>
          </Container>
        ) : (
          <InnerAlert
            message="Página não encontrada"
            buttonLabel="Voltar para o inicio"
            buttonClick={() => this.props.routerStore!.push(accountsManagerPath)}
          />
        )}
      </>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 34px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Item = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.borderGray};
  }
`;

const Label = styled.span`
  display: flex;
  flex: 1;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
`;
