import React from "react";
import classnames from "classnames";

import { ReactComponent as SpinIcon } from "assets/svgs/spin.svg";

import s from "./style.scss";

interface IProps {
  children?: React.ReactNode;
  spinning?: boolean;
  className?: string;
}

export function Spin(props: IProps) {
  if (!props.children) {
    return <SpinIcon className={classnames([s.spin_icon, props.className])} />;
  }

  return (
    <div className={classnames([s.spin, props.className])}>
      {props.children}

      <div className={classnames(s.spin_wrapper, props.spinning ? s.spinning : null)}>
        <SpinIcon className={s.spin_icon} />
      </div>
    </div>
  );
}
