import React from "react";
import classNames from "classnames";

import { IRadioProps, Radio } from "../";

import s from "./style.scss";

type CustomOption = Omit<IRadioProps, "name">;

export interface IProps {
  alignment?: "horizontal" | "vertical";
  nameGroup: string;
  options: CustomOption[];
}

export function RadioGroup(props: IProps) {
  return (
    <ul className={classNames(s.container, s[props.alignment || "vertical"])}>
      {props.options.map(option => (
        <Radio name={props.nameGroup} key={option.value || option.id} {...option} />
      ))}
    </ul>
  );
}
