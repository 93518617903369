import { intergerMask } from "./";
import moment from "moment-timezone";

const defaultLocale = "pt-BR";

export const innerCurrencyFormatter = Intl.NumberFormat(navigator.language || defaultLocale, {
  currency: "BRL",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  useGrouping: true,
});

const dateOptions: Intl.DateTimeFormatOptions = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
};

function toTimeZone(utc_date: Date, format: string) {
  const zone = "America/Sao_Paulo";
  return moment(utc_date)
    .tz(zone)
    .format(format);
}

export function getDateTime(utc_date: Date) {
  const dateTransform = new Date(utc_date);
  let timeformatter = new Date(dateTransform.valueOf() + dateTransform.getTimezoneOffset() * 60000);
  return toTimeZone(timeformatter, "LT");
}

export const dateFormatter = Intl.DateTimeFormat(navigator.language || defaultLocale, dateOptions);

export const timeFormatter = Intl.DateTimeFormat(navigator.language || defaultLocale, timeOptions);

export const timeFormatterOriginal = Intl.DateTimeFormat("default", timeOptions);

export const dateTimeFormatter = Intl.DateTimeFormat(navigator.language || defaultLocale, {
  ...dateOptions,
  ...timeOptions,
});

export const formtDateFromYYYYMMDD = (date: string) => {
  if (date.length <= 0) {
    return "";
  }
  let newDate = "";
  newDate = date.substr(6, 2) + "/";
  newDate += date.substr(4, 2) + "/";
  newDate += date.substr(0, 4);
  return newDate;
};

export function currencyFormatter(value: string | number) {
  return innerCurrencyFormatter.format(typeof value === "string" ? parseInt(intergerMask(value), 10) / 100 : value);
}
