/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./RootStore";
import { session } from "../utils";

export interface IPartnerVolumetryData {
  type: api.TransactionType | null;
  partnerId: string | null;
  startDate: Date | null;
  endDate: Date | null;
  withError: boolean | null;
}

export interface IPartnerId {
  label: string;
  value: string;
}

export interface IPartnerIdLimit {
  label: string;
  value: string | null;
}

export interface ICardParsedData {
  id: number;
  broadcaster: api.BroadCaster;
  brand: api.CardBrand;
  productId: string;
  plasticId: string;
  activateCards: api.ActiveCards;
  commercialOrigin: string;
  cardTypeName: api.CardTypeName;
  numberBin: string;
  typeBin: string;
  imageId: string;
  imageDescription: string;
  multicards: api.MultiCard;
}

export class PartnerStore {
  protected rootStore: RootStore;

  @observable public selectedPartner: api.Partner | null = null;

  @observable public partners: api.Partner[] | null = null;

  @observable public partner: api.Partner | null = null;

  @observable public partnerRegisterData: api.PartnerRegisterData | null = null;

  @observable public partnerStyleData: api.PartnerStyles | null = null;

  @observable public partnerCardData: api.PartnerCard | null = null;

  @observable public parsedPartnerCardData: ICardParsedData[] = [];

  @observable public partnerGeneralConfigData: api.PartnerGeneralConfig | null = null;

  @observable public volumetry: api.Volumetry[] = [];

  @observable public blockFlagCard = false;

  @observable public partnerConfig: api.PartnerConfig | null = null;

  @observable public partnersNames: api.PartnerName[] | null = null;

  @observable public partnersNamesLot: api.PartnerName[] | null = null;

  @observable public partnersIdLot: api.PartnerLot[] | null = null;

  @observable public partnersDataLot: api.PartnerFileLot[] | null = null;

  @observable public partnerDataLotDetails: api.Prospect[] | null = null;

  @observable public partnersDataPositivize: api.PartnerFileLot[] | null = null;

  @observable public partnerPositivizeHistoric: api.PartnerNotification[] | null = null;

  @observable public partnerDataAttachments: api.PartnerFileAttachment[] | null = null;

  @observable public partnerDataCommentsAttachments: api.PartnerFileComment[] | null = null;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getPartner = async (partnerId: string) => (this.partner = await api.getPartner(partnerId));

  @session
  @action
  public getPartners = async () => {
    this.partners = await api.getPartnersV13();

    if (this.partners !== null) {
      this.partners = this.partners.sort((a, b) => a.name.localeCompare(b.name));
    }

    return this.partners;
  };

  @session
  @action
  public clearPartner = async () => {
    this.partner = null;
  };

  @session
  @action
  public getPartnerConfig = async (partnerId: string) => {
    this.partnerConfig = await api.getPartnerConfig(partnerId);
  };

  @session
  @action
  public getPushConfig = async (partnerId: string) => {
    return api.getPushConfig(partnerId);
  };

  @session
  @action
  public setPushConfig = async (partnerId: string, apiKey: string | null) => {
    return api.setPushConfig(partnerId, apiKey);
  };

  @session
  @action
  public getPartnersNames = async () => (this.partnersNames = await api.getPartnersNames());

  @session
  @action
  public getPartnersNamesLot = async () => (this.partnersNamesLot = await api.getPartnersNamesLot());

  @session
  @action
  public getPartnerIdLot = async () => (this.partnersIdLot = await api.getPartnerIdLot());

  @session
  @action
  public getPartnerDataLot = async (partineId: string) => {
    return (this.partnersDataLot = await api.getPartnerFilesLot(partineId));
  };

  @session
  @action
  public getPartnerDataLotDetails = async (partnerFileId: string) => {
    return (this.partnerDataLotDetails = await api.getProspectByPartnerFileId(partnerFileId));
  };

  @session
  @action
  public getPartnerPositivize = async (partineId: string) => {
    return (this.partnersDataPositivize = await api.getPartnerFilesLot(partineId));
  };

  @session
  @action
  public getPartnerPositivizeHistoric = async (partnerFileId: number) => {
    return (this.partnerPositivizeHistoric = await api.getHistoricByPartnerFileId(partnerFileId));
  };

  @session
  @action
  public getPartnerDataAttachments = async (partnerFileId: number) => {
    return (this.partnerDataAttachments = await api.getAttachmentByPartnerFileId(partnerFileId));
  };

  @session
  @action
  public getPartnerDataCommentsAttachment = async (partnerFileId: number) => {
    return (this.partnerDataCommentsAttachments = await api.getCommentsByPartnerFileId(partnerFileId));
  };

  @session
  @action
  public sendMessagePositivize = async (data: number[], description: string) => {
    const newPartner = await api.sendMessagePositivize(data, description);

    return newPartner;
  };

  @session
  @action
  public generateCredentials = async (partinerId: string) => {
    return api.generatePartnerUser(partinerId);
  };

  @session
  @action
  public getMaximaId = async () => api.getMaximaId();

  @session
  @action
  public setPartner = async (
    partner: api.Partner,
    logo: Buffer | null,
    term: Buffer | null,
    contract: Buffer | null,
    termPj: Buffer | null,
    contractPj: Buffer | null,
    farePackages: Buffer | null,
    cardConfig: api.CardConfig | null,
    isUpdate?: boolean,
  ) => {
    const newPartner = await api.setPartner(
      partner,
      logo,
      term,
      contract,
      termPj,
      contractPj,
      farePackages,
      cardConfig,
    );

    await this.getPartners();

    return newPartner;
  };

  @session
  public getVolumetry = async (filter: api.FilterVolumetry) => (this.volumetry = await api.getVolumetry(filter));

  @session
  public changeUserPartnerId = async (companyId: string, type: api.PfPj, partnerId: string) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    api.changeUserPartnerId(companyId, type, partnerId);

  @session
  @action
  public getPartnerRegisterData = async (partnerId: string) => {
    this.partnerRegisterData = await api.getPartnerRegisterData(partnerId);
  };

  @session
  public setPartnerRegisterData = async (partnerRegisterData: api.PartnerRegisterData) => {
    return api.setPartnerRegisterData(partnerRegisterData);
  };

  @session
  @action
  public getPartnerStyles = async (partnerId: string) => {
    this.partnerStyleData = await api.getPartnerStyles(partnerId);
  };

  @session
  public setPartnerStyles = async (partnerStyleData: api.PartnerStyles) => {
    return api.setPartnerStyles(partnerStyleData);
  };

  @session
  @action
  public getPartnerCards = async (partnerId: string) => {
    this.partnerCardData = await api.getPartnerCards(partnerId);
    this.parseCardData();
  };

  @session
  public setPartnerCards = async (partnerCardData: api.PartnerCard) => {
    return api.setPartnerCards(partnerCardData);
  };

  @session
  @action
  public getPartnerGeneralConfig = async (partnerId: string) => {
    this.partnerGeneralConfigData = await api.getPartnerGeneralConfig(partnerId);
  };

  @session
  public setPartnerGeneralConfig = async (partnerGeneralConfigData: api.PartnerGeneralConfig) => {
    return api.setPartnerGeneralConfig(partnerGeneralConfigData);
  };

  @session
  @action
  public clearCacheData = () => {
    this.partnerRegisterData = null;
    this.partnerStyleData = null;
    this.partnerCardData = null;
    this.partnerGeneralConfigData = null;
  };

  @session
  public removePartnerCard = async (removePartnerData: api.RemovePartnerCard) => {
    return api.removePartnerCard(removePartnerData);
  };

  @session
  public updatePartnerCard = async (updatePartnerData: api.UpdatePartnerCard) => {
    return api.updatePartnerCard(updatePartnerData);
  };

  @session
  @action
  public parseCardData = () => {
    const parserArray: ICardParsedData[] = [];

    if (!this.partnerCardData) {
      return;
    }

    this.partnerCardData.cardConfig.map((card: api.CardConfig) => {
      return card.cardImage.map((image: api.CardImage) => {
        return parserArray.push({
          id: parseInt(`${card.productId}${image.id}`, 0),
          broadcaster: card.broadcaster,
          brand: card.brand,
          productId: card.productId,
          plasticId: card.plasticId,
          activateCards: card.activateCards,
          commercialOrigin: card.commercialOrigin,
          cardTypeName: card.cardTypeName,
          numberBin: card.numberBin,
          typeBin: card.typeBin,
          imageId: image.id,
          imageDescription: image.descr,
          multicards: card.multiCards,
        });
      });
    });

    this.parsedPartnerCardData = parserArray;
  };
}
