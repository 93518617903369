import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  opacity?: string;
}

export const SvgError = (props: IProps) => {
  const { color, opacity, ...restProps } = props;
  return (
    <svg {...restProps}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 41C0 18.3577 18.3577 0 41 0C63.6446 0 82 18.3577 82 41C82 63.6475 63.6449 82 41 82C18.3577 82 0 63.6449 0 41ZM7.68763 41C7.68763 59.3986 22.6014 74.3124 41 74.3124C59.3986 74.3124 74.3124 59.3986 74.3124 41C74.3124 22.6014 59.3986 7.68763 41 7.68763C22.6014 7.68763 7.68763 22.6014 7.68763 41ZM41.0026 17.981C38.066 17.981 35.8441 19.5135 35.8441 21.9888V44.6925C35.8441 47.1703 38.0658 48.6977 41.0026 48.6977C43.8675 48.6977 46.161 47.1064 46.161 44.6925V21.9888C46.1607 19.5722 43.8675 17.981 41.0026 17.981ZM35.8851 58.9323C35.8851 56.1087 38.1813 53.8125 41.0026 53.8125C43.8239 53.8125 46.1172 56.1084 46.1174 58.9323C46.1174 61.751 43.8239 64.0471 41.0026 64.0471C38.1813 64.0471 35.8851 61.751 35.8851 58.9323Z"
        fill={color || "#E2574C"}
      />
    </svg>
  );
};
