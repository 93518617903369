import styled from "styled-components";
import { OutlinedButton } from "legacy/components/OutlinedButton";

import { colors } from "../../../../../../utils";
import { TextInput } from "../../../../components/TextInput";
import { Select } from "../../../../../manager/components/Select";
import { RaisedButton } from "../../../../../../legacy/components/RaisedButton";
import { TextAreaInput } from "../../../../components/TextAreaInput";

export const Title = styled.span`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

export const ContainerActions = styled(Container)`
  alignt-items: center;
`;

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  min-height: 500px;
`;

export const WrapperTable = styled.div`
  height: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`;

export const Label = styled.span`
  font-size: 14px;
  color: ${colors.gray};
`;

export const Placeholder = styled(Label)`
  margin-top: 16px;
`;

export const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
`;

export const ErrorText = styled.span`
  font-family: Lato;
  font-size: 16px;
  color: ${colors.violetRed};
  margin-bottom: 12px;
`;

export const Link = styled.button`
  font-size: 14px;
  color: ${colors.violetRed};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 32px;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 280px;
  margin: 12px 0 10px 0;
`;

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 160px;
  height: 40px;
  font-size: 14px;
  align-self: center;
  margin-right: 20px;
`;

export const PersonSelect = styled(Select)`
  width: 200px;
  margin: 16px 0 26px 0;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darkGray};
`;

export const PartnerSelect = styled(Select)`
  width: 175px;
  margin: 16px 0 26px 0;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darkGray};
`;

export const StyledRaisedButton = styled(RaisedButton)`
  width: 160px;
  height: 40px;
  font-size: 14px;
  align-self: center;
  margin: 20px;
`;

export const StyledTextArea = styled(TextAreaInput)`
  margin-top: 24px;
  height: 109px;
`;

export const FilterWrapper = styled.div`
  margin-top: 28px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const FilterContainer = styled.div`
  margin-right: 24px;
`;
