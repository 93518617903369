/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import * as api from "api/manager-api";
import { colors } from "utils";

import { YearPicker } from "../../../../components/YearPicker";
import { ConfirmDialog } from "../../../../components/ConfirmDialog";
import { Loader } from "../../../../components/Loader";
import { InnerAlert } from "../../../../components/InnerAlert";

interface IProps {
  disabled?: boolean;
  data: api.ExceptionOperationsDate[];
  onClickConfig: (dateString: string) => void;
  yearToViewSpecialDates: number;
  setYearToViewSpecialDates: (value: number) => void;
  isLoadindDeleteSpecialDate: boolean;
  onRemoveSpecialDate: (date: Date) => Promise<void>;
  isLoadingGetData: boolean;
}

interface IState {
  isDialogOpen: boolean;
  idSpecialDateToDelete: Date | null;
}

export class DateWithObservationList extends React.Component<IProps, IState> {
  public state: IState = {
    idSpecialDateToDelete: null,
    isDialogOpen: false,
  };

  private onCancel = () => {
    this.setState({ idSpecialDateToDelete: null, isDialogOpen: false });
  };

  private onConfirm = async () => {
    await this.props.onRemoveSpecialDate(this.state.idSpecialDateToDelete!);
    this.setState({ idSpecialDateToDelete: null, isDialogOpen: false });
  };

  private onDeleteSpecialDate = (date: Date) => {
    this.setState({
      idSpecialDateToDelete: date,
      isDialogOpen: true,
    });
  };

  public render() {
    return (
      <>
        <ConfirmDialog
          title="Atenção"
          text="Você tem certeza que deseja deletar essa data?"
          isOpen={this.state.isDialogOpen}
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          isLoading={this.props.isLoadindDeleteSpecialDate}
        />
        <Container>
          <Title>Adicionados</Title>
          <YearPickerWrapper>
            <YearPicker
              value={this.props.yearToViewSpecialDates}
              onChange={value => this.props.setYearToViewSpecialDates(value)}
              interval={7}
            />
          </YearPickerWrapper>
          <Content>{this.renderData()}</Content>
        </Container>
      </>
    );
  }

  private renderData() {
    const { data, isLoadingGetData } = this.props;

    if (isLoadingGetData) {
      return <Loader />;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (data && !data.length) {
      return <InnerAlert compact message="Não existem datas especiais nesse ano." />;
    }

    return (
      <>
        {data.map(item => (
          <Row key={item.date.toString()}>
            <Block>
              <Date>
                {moment(item.date).format("L")} {item.isAutomatic ? "(Gerado automaticamente)" : ""}
              </Date>
              <Text>{item.obs}</Text>
            </Block>
            <Button
              onClick={() => this.props.onClickConfig(item.date.toString())}
              editableMode={!this.props.disabled}
              disabled={this.props.disabled}
            >
              Configurar serviços
            </Button>
            <Button
              onClick={() => this.onDeleteSpecialDate(item.date)}
              editableMode={!this.props.disabled}
              disabled={this.props.disabled}
            >
              Excluir
            </Button>
          </Row>
        ))}
      </>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h5`
  text-transform: uppercase;
  color: ${colors.gray};
  font-family: Lato;
  font-size: 12px;
  padding-bottom: 0;
  margin-bottom: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.borderGray};
  padding: 16px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Date = styled.span`
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: ${colors.black};
`;

const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: ${colors.gray};
`;

const Button = styled.button<{ editableMode: boolean }>`
  border: none;
  background-color: transparent;
  cursor: ${props => (props.editableMode ? "pointer" : "default")};
  font-family: Lato;
  color: ${colors.violetRed};
  font-size: 14px;
  opacity: ${props => (props.editableMode ? 1 : 0.5)};
`;

const YearPickerWrapper = styled.div`
  margin: 16px 0;
`;
