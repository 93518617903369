exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styled__box__25c2D{height:100%;padding:16px 24px;overflow-y:auto}.styled__box__25c2D .styled__inputArea__1M8sS{display:flex;align-items:center;justify-content:flex-end}.styled__box__25c2D .styled__inputArea__1M8sS.styled__has-two__2pa0y{justify-content:space-between}.styled__labelDate__ykKI8>div{min-width:300px}.styled__nextWrapperButton__1jgpe{min-width:145px;width:fit-content;font-family:Lato;margin:15px 0 15px 15px;text-transform:none;font-size:14px;font-weight:600;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:normal;text-align:center}.styled__info__1Lwwd{margin:8px 0;font-weight:600;font-size:14px;color:#515251}", ""]);

// Exports
exports.locals = {
	"box": "styled__box__25c2D",
	"inputArea": "styled__inputArea__1M8sS",
	"has-two": "styled__has-two__2pa0y",
	"labelDate": "styled__labelDate__ykKI8",
	"nextWrapperButton": "styled__nextWrapperButton__1jgpe",
	"info": "styled__info__1Lwwd"
};