import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  size: number;
}

export const SvgFile = (props: IProps) => {
  const { color, size, viewBox, width, height, fill, ...restProps } = props;
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" {...restProps}>
      <path
        fill={color || "currentColor"}
        fillRule="evenodd"
        d="M16 15a1 1 0 0 1-1 1H5a1 1 0 0 1 0-2h10a1 1 0 0 1 1 1zM5 10h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm13 7a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h9v4a2 2 0 0 0 2 2h4v9zm1.707-11.293L14.293.293A1 1 0 0 0 13.586 0H2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6.414a1 1 0 0 0-.293-.707z"
      />
    </svg>
  );
};
