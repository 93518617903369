import { colors } from "utils";
import { StylesConfig } from "react-select";

export const partnerIdValue = [
         {
           value: "updatePartnerId",
           label: "Alterar parceiro vinculado",
         },
       ];

export const customStylesReactSelect: StylesConfig = {
  container: base => ({ ...base, width: "100%" }),
  indicatorSeparator: base => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isSelected ? colors.white : colors.black,
    backgroundColor: state.isSelected ? colors.violetRed : colors.white,
    ":hover": {
      color: colors.white,
      backgroundColor: state.isSelected ? "" : colors.violetRedOpacity,
    },
  }),
  control: provided => ({
    ...provided,
    borderColor: colors.platinum,
    boxShadow: "none",
    backgroundColor: colors.lightGray,
    ":hover": {
      cursor: "pointer",
      borderColor: colors.violetRedOpacity,
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.black,
    backgroundColor: state.isFocused ? colors.violetRed : "",
  }),
};
