import * as React from "react";

export const ArrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10 20l1.435-1.393L3.828 11H20V9H3.828l7.586-7.586L10 0 0 10l10 10"
    />
  </svg>
);

export const ArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12">
    <g fill="currentColor" fillRule="nonzero" transform="rotate(-90 6.5 6.5)">
      <rect width="2" height="14" x="6" rx="1" />
      <path d="M12.269 9.339l-4.51 4.51a1 1 0 0 1-1.415 0l-.067-.067a1 1 0 0 1 0-1.414l4.51-4.51a1 1 0 0 1 1.415 0l.067.067a1 1 0 0 1 0 1.414z" />
      <path d="M3.213 7.858l4.51 4.51a1 1 0 0 1 0 1.414l-.066.067a1 1 0 0 1-1.414 0l-4.51-4.51a1 1 0 0 1 0-1.414l.066-.067a1 1 0 0 1 1.414 0z" />
    </g>
  </svg>
);
