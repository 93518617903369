import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {}

export const SvgArrowRepeat = (props: IProps) => {
  return (
    <svg {...props}>
      <path
        fill="#A6A6A6"
        fillRule="evenodd"
        d="M14.074 10.859c-.48 3.309-3.688 5.754-7.278 5.005-2.28-.476-4.133-2.307-4.612-4.571C1.366 7.422 4.051 4 8.077 4v2l5.033-3-5.033-3v2C3.045 2-.809 6.483.192 11.605c.61 3.119 3.145 5.633 6.284 6.239 4.758.918 8.97-2.328 9.591-6.718.085-.596-.4-1.126-1.004-1.126a1 1 0 0 0-.989.859"
      />
    </svg>
  );
};
