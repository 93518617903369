import * as React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import { colors } from "utils";
import { Dialog } from "../../Dialog";
import * as api from "api/manager-api";
import { OpeningAccountsStore, GeneralStore } from "../../../stores";
import { Compliance } from "../../../containers/OpeningAccounts/OpeningAccountProfile/Compliance";
import { WrapperTextShowWithBureauMainInfo } from "../../../containers/OpeningAccounts/OpeningAccountProfile/Representatives/styled";

interface IProps {
  company: api.CompanyAnalisys | null;
  openingAccountsStore?: OpeningAccountsStore;
  generalStore?: GeneralStore;
}

interface IState {
  imageToDialog: any;
  imageDialogTitle: string;
  isLoadingGetLink: boolean;
  link: string;
  companyAnalisys: api.CompanyAnalisys | null;
  loadingGetCompanyAnalisys: boolean;
}

@inject("openingAccountsStore", "generalStore")
@observer
export class Pj extends React.Component<IProps, IState> {
  public state: IState = {
    imageToDialog: null,
    imageDialogTitle: "",
    isLoadingGetLink: false,
    link: "",
    companyAnalisys: null,
    loadingGetCompanyAnalisys: true,
  };

  public render() {
    return (
      <>
        <a
          id="targetDownloadLink"
          href={this.state.link}
          // download={this.props.company!.fieldGroups}
        />
        <Dialog
          title={this.state.imageDialogTitle}
          isOpen={!!this.state.imageToDialog}
          onClose={() => this.setState({ imageToDialog: null })}
        >
          <Image url={this.state.imageToDialog} />
        </Dialog>
        <Container>
          <Column>{this.renderCompany()}</Column>
          <Column>
            <Compliance beneficiaryData={this.props.company ? this.props.company.complianceData : undefined} />
          </Column>
        </Container>
      </>
    );
  }

  private renderCompany() {
    const { company } = this.props;

    if (!company) {
      return null;
    }

    return company.fieldGroups.map((group, index) => (
      <React.Fragment key={index}>
        <Title>{group.name}</Title>
        {group.fields.map((field, index2) => (
          <WrapperTextShowWithBureauMainInfo
            key={index2}
            title={field.label}
            content={field.values.map(value => ({
              data: value.value,
              source: value.source,
            }))}
          />
        ))}
      </React.Fragment>
    ));
  }
}

const Container = styled.main`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;

  &:not(:last-child) {
    border-right: 1px solid ${colors.borderGray};
  }
`;

const Title = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
  /* margin: 20px 0px 30px 0px; */
`;

const Image = styled.div<{ url: any }>`
  width: 400px;
  height: 400px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
