import * as React from "react";
import styled from "styled-components";
import { TextButton } from "../TextButton";
import { RaisedButton } from "legacy/components/RaisedButton";
import { OutlinedButton } from "../OutlinedButton";
import { colors } from "utils";

interface IProps {
  editableMode: boolean;
  onChangeToEditableMode: () => void;
  onCancel: () => void;
  onSave: () => void;
  isLoading: boolean;
  disabledSaveButton?: boolean;
}

const Row = styled.div`
  display: flex;
  margin: 5px;
`;

const TextButtonWrapper = styled(TextButton)`
  margin-right: 16px;
  width: 124px;
`;

const OutlinedButtonWrapper = styled(OutlinedButton)`
  width: 124px;
`;

const RaisedButtonWrapper = styled.div`
  width: 124px;
`;

export class ToggleEditMode extends React.Component<IProps, {}> {
  public render() {
    if (this.props.editableMode) {
      return (
        <Row>
          <TextButtonWrapper
            onClick={this.props.onCancel}
            type="button"
            label="Cancelar"
            disabled={this.props.isLoading}
          />
          <RaisedButtonWrapper>
            <RaisedButton
              type="button"
              onClick={this.props.onSave}
              label="Salvar"
              loading={this.props.isLoading}
              disabled={this.props.disabledSaveButton}
              customColor={colors.violetRed}
              lowerCase
            />
          </RaisedButtonWrapper>
        </Row>
      );
    } else {
      return (
        <Row>
          <OutlinedButtonWrapper type="button" onClick={this.props.onChangeToEditableMode} label="Editar" />
        </Row>
      );
    }
  }
}
