/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-negated-condition */
/* eslint-disable sort-keys */
/* eslint-disable no-duplicate-imports */
/* eslint-disable func-style */
import * as React from "react";
import { action, computed, observable, reaction } from "mobx";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import * as api from "api/manager-api";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import { RouterStore } from "mobx-react-router";
import { AdminUserPermission, ManagerAccountReturn, UserStatus } from "api/manager-api";
import save from "save-file";
import { SvgSpreadsheet } from "legacy/icons/SvgSpreadsheet";
import { innerCurrencyFormatter } from "utils";

import { GeneralStore, IFilterAccountsPath, OpeningAccountsStore, ReportStore } from "../../stores";
import {
  colors,
  openingAccountSectionWithSearchPath,
  openingAccountsProfilePath,
  prettierAbvAccountType,
  prettierBlockStatusAccount,
  prettierCategoryAccount,
  prettierDocumentNumber,
  prettierUserStatus,
  redirectStatusToSection,
  revertSectionToId,
  searchDecode,
  searchEncode,
  searchMask,
  userStatusToEnum,
} from "../../utils";
import { SearchBar } from "../../components/SearchBar";
import { Table } from "../../components/Table";
import { Tag } from "../../components/Tag";
import { Select } from "../../components/Select";
import { Loader } from "../../components/Loader";
import { InnerAlert } from "../../components/InnerAlert";
import { OutlinedButton } from "../../components/OutlinedButton";

interface IProps extends RouteComponentProps<{ section: string; search?: string }> {
  generalStore: GeneralStore;
  openingAccountsStore: OpeningAccountsStore;
  routerStore: RouterStore;
  reportStore: ReportStore;
}

type AccountType = api.PfPj | "simpleWithDocument" | "simpleWithoutDocument" | null;

export interface IFilter {
  accountType: AccountType;
  complianceStatus: api.ComplianceStatus | null;
  documentStatus: "approvePendingWithDocument" | "approvePendingWithoutDocument" | null;
  simpleAccountSituation: api.BlockStatusAccount | null;
  offset: number;
  limit: number;
}

interface ITypeFilterOption {
  value: AccountType;
  label: string;
}
interface IComplianceStatusFilterOption {
  value: api.ComplianceStatus | null;
  label: string;
}

interface ISituationFilterOption {
  value: api.BlockStatusAccount | null;
  label: string;
}

const typeFilterOptions: ITypeFilterOption[] = [
  { value: null, label: "Tipo de conta" },
  { value: api.PfPj.pf, label: "Pessoa Física" },
  { value: api.PfPj.pj, label: "Pessoa Jurídica" },
  { value: api.PfPj.mei, label: "MEI" },
  { value: "simpleWithDocument", label: "Simples c/ documento" },
  { value: "simpleWithoutDocument", label: "Simples s/ documento" },
];

@inject("generalStore", "openingAccountsStore", "routerStore", "reportStore")
@observer
export class OpeningAccounts extends React.Component<IProps> {
  private autoRefresh = 0;

  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private searchValue = "";

  @observable private searchLoading = false;

  @observable private filter: IFilter = {
    accountType: null,
    offset: 0,
    limit: 30,
    complianceStatus: null,
    documentStatus: null,
    simpleAccountSituation: null,
  };

  private complianceStatusFilterOptions: IComplianceStatusFilterOption[] = [
    { value: null, label: "Status do compliance" },
    { value: api.ComplianceStatus.waiting, label: "Pendente" },
    { value: api.ComplianceStatus.approved, label: "Aprovado" },
    { value: api.ComplianceStatus.processing, label: "Processando" },
    ...(!this.userStatus ? [{ value: api.ComplianceStatus.repproved, label: "Reprovado" }] : []),
  ];

  private situationFilterOptions: ISituationFilterOption[] = [
    { value: null, label: "Situação" },
    { value: api.BlockStatusAccount.blocked, label: "Bloqueada" },
    { value: api.BlockStatusAccount.unlocked, label: "Desbloqueada" },
    { value: api.BlockStatusAccount.automatic, label: "Automática" },
  ];

  @observable private results: ManagerAccountReturn | null = null;

  @computed get section(): IFilterAccountsPath {
    return revertSectionToId(this.props.match.params.section);
  }

  @computed get userStatus(): UserStatus | null {
    if (this.section === "all") {
      return null;
    }

    return userStatusToEnum(this.section);
  }

  // Compliance filters should only appear on Workflow and All Accounts.
  @computed
  get complianceFilterCondition() {
    return this.userStatus === "finished" || !this.userStatus;
  }

  constructor(props: IProps) {
    super(props);
    reaction(
      () => this.props.match.params.section,
      () => {
        this.searchValue = "";
        this.filter.accountType = null;
        this.filter.complianceStatus = null;
        this.getPendingUsers();
      },
    );
  }

  public componentDidMount() {
    this.getPendingUsers();
    this.autoRefresh = window.setInterval(async () => {
      this.results = await this.props.openingAccountsStore.getAccountsByStatus(
        {
          limit: this.filter.limit,
          offset: this.filter.offset,
          query: this.searchValue,
          type: this.getAccountType(this.filter.accountType),
          approvation: null,
        },
        this.getUserStatusByAccountType(this.filter.accountType) as any,
        this.filter.complianceStatus,
        this.filter.simpleAccountSituation,
      );
    }, 30e3);

    /*
     *Resetting filter when changing section
     */
    reaction(
      () => this.section,
      () => {
        this.filter = {
          accountType: null,
          offset: 0,
          limit: 30,
          complianceStatus: null,
          documentStatus: null,
          simpleAccountSituation: null,
        };
        this.searchValue = "";
        this.results = null;
      },
    );
  }

  public componentWillUnmount() {
    window.clearInterval(this.autoRefresh);
  }

  private getPendingUsers = async () => {
    try {
      this.loading = true;
      this.filter.offset = 0;
      this.searchValue = searchDecode(this.props.match.params.search);
      this.results = await this.props.openingAccountsStore.getAccountsByStatus(
        {
          limit: this.filter.limit,
          offset: this.filter.offset,
          query: this.searchValue,
          type: this.getAccountType(this.filter.accountType),
          approvation: null,
        },
        this.getUserStatusByAccountType(this.filter.accountType) as any,
        this.filter.complianceStatus,
        this.filter.simpleAccountSituation,
      );
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter lista de usuários.";
    } finally {
      this.loading = false;
    }
  };

  private getAccountType = (filter: AccountType): api.PfPj | null => {
    if (filter) {
      return {
        pf: "pf",
        pj: "pj",
        mei: "mei",
        simpleWithDocument: "pf",
        simpleWithoutDocument: "pf",
      }[filter] as api.PfPj;
    }

    return null;
  };

  private getUserStatusByAccountType = (filter: AccountType) => {
    if (filter === "simpleWithDocument") {
      return "approvePendingWithDocument";
    }

    if (filter === "simpleWithoutDocument") {
      return "approvePendingWithoutDocument";
    }

    return this.userStatus;
  };

  @action
  private changePage = async () => {
    try {
      this.searchLoading = true;
      this.results = (await this.props.openingAccountsStore.getAccountsByStatus(
        {
          limit: this.filter.limit,
          offset: this.filter.offset,
          query: this.searchValue,
          type: this.getAccountType(this.filter.accountType),
          approvation: null,
        },
        this.getUserStatusByAccountType(this.filter.accountType) as any,
        this.complianceFilterCondition ? this.filter.complianceStatus : undefined,
        this.filter.simpleAccountSituation,
      )) as any;
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao buscar as contas.";
    } finally {
      this.searchLoading = false;
    }
  };

  @action
  private performSearch = async () => {
    try {
      this.searchLoading = true;
      this.results = await this.props.openingAccountsStore.getAccountsByStatus(
        {
          limit: this.filter.limit,
          offset: this.filter.accountType || this.filter.complianceStatus ? 0 : this.filter.offset,
          query: this.searchValue,
          type: this.getAccountType(this.filter.accountType),
          approvation: null,
        },
        this.getUserStatusByAccountType(this.filter.accountType) as any,
        this.complianceFilterCondition ? this.filter.complianceStatus : undefined,
        this.filter.simpleAccountSituation,
      );
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao buscar as contas.";
    } finally {
      this.searchLoading = false;
    }
  };

  @action
  private handleSearch = async () => {
    const encoded = searchEncode(this.searchValue);

    this.props.routerStore.push(openingAccountSectionWithSearchPath(this.section, encoded));
    this.performSearch();
  };

  private onClickRow = (id: string) => {
    const item = this.results!.data.find(row => row.id === id);
    let { section } = this.props.match.params;

    if (item!.status && item!.statusCompliance && (this.section === "all" || this.section === "workflow")) {
      if (item!.subType) {
        section = redirectStatusToSection(item!.status, item!.statusCompliance, item!.subType);
      } else {
        section = redirectStatusToSection(item!.status, item!.statusCompliance, null);
      }
    }

    if (section === "aprovados" && item!.statusCompliance !== "approved") {
      section = "workflow";
    }

    if (item!.subType) {
      section = redirectStatusToSection(item!.status, item!.statusCompliance, item!.subType);
    }

    const path = openingAccountsProfilePath(section, item!.cpfCnpj, id, !item!.fepweb);

    this.props.routerStore.push(path);
  };

  @action
  private handleTypeFilter = ({ value }: ITypeFilterOption) => {
    this.filter.accountType = value;
    this.performSearch();
  };

  @action
  private handleComplianceFilter = ({ value }: IComplianceStatusFilterOption) => {
    this.filter.complianceStatus = value;
    this.performSearch();
  };

  @action
  private handleSituationFilter = ({ value }: ISituationFilterOption) => {
    this.filter.simpleAccountSituation = value;
    this.performSearch();
  };

  @action
  private onChangSearchValue = (value: string) => {
    this.searchValue = searchMask(value, false);
  };

  public render() {
    return (
      <>
        <Header>
          <TitleContainer>
            <Title>Abertura de Contas</Title>
          </TitleContainer>
        </Header>
        <Row>
          <StyledSearchBar
            value={this.searchValue}
            onChange={this.onChangSearchValue}
            placeholder="Pesquise por CPF, CNPJ, nome ou email"
            handleSearch={this.handleSearch}
            isLoading={this.searchLoading}
            enabledWithoutInput
          />
          {this.renderTypeFilter()}
          {this.renderSituationFilter()}
          {this.complianceFilterCondition && this.renderComplianceFilter()}
        </Row>
        {this.renderData()}
      </>
    );
  }

  private renderTypeFilter = () => {
    const { hasPermission } = this.props.generalStore;
    const permissions = [AdminUserPermission.approveAccountPF, AdminUserPermission.approveAccountPJ];

    if (!permissions.every(permission => hasPermission(permission))) {
      return;
    }

    return (
      <StyledSelect
        onChange={this.handleTypeFilter}
        value={typeFilterOptions.find(option => option.value === this.filter.accountType)}
        options={typeFilterOptions.filter(opt => {
          if (this.section === "all" || this.section === "processing") {
            return true;
          }

          if (this.section === "approvedPending") {
            return opt.value === "simpleWithDocument" || opt.value === "simpleWithoutDocument" || !opt.value;
          }

          return !(opt.value === "simpleWithDocument" || opt.value === "simpleWithoutDocument");
        })}
      />
    );
  };

  private renderComplianceFilter = () => (
    <ComplianceSelect
      onChange={this.handleComplianceFilter}
      value={this.complianceStatusFilterOptions.find(option => option.value === this.filter.complianceStatus)}
      options={this.complianceStatusFilterOptions}
    />
  );

  private renderSituationFilter = () =>
    this.section === "approvedPending" ? (
      <SituationSelect
        onChange={this.handleSituationFilter}
        value={this.situationFilterOptions.find(option => option.value === this.filter.simpleAccountSituation)}
        options={this.situationFilterOptions}
      />
    ) : null;

  private renderData = () => {
    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return (
        <InnerAlert message={this.error!} buttonLabel="Tentar novamente" buttonClick={() => this.getPendingUsers()} />
      );
    }

    if ((this.results ? this.results.data : []).length === 0) {
      return (
        <InnerAlert
          message="Não existem usuários para serem listados no momento."
          buttonLabel="Buscar novamente"
          buttonClick={() => this.getPendingUsers()}
        />
      );
    }

    return this.getTable();
  };

  @action
  private handleChangePage = (page: number) => {
    this.filter.offset = this.filter.limit * page;
    this.changePage();
  };

  @action
  private onClickDownloadExcel = async () => {
    try {
      const buffer = await this.props.reportStore.getExcelFromFilteredSimpleAccounts(
        {
          limit: null,
          offset: this.filter.offset,
          query: this.searchValue,
          type: this.getAccountType(this.filter.accountType),
          approvation: null,
        },
        this.getUserStatusByAccountType(this.filter.accountType) as any,
        this.filter.simpleAccountSituation,
      );

      this.sendDownload(buffer);
    } catch (error) {
      this.props.generalStore!.showToast(error.message || "Não foi possível gerar a planilha.");
    }
  };

  private sendDownload = (buffer: Buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `relatorio-contas-simples.xlsx`);
  };

  private pluralFormCheck = (length: number): string => {
    if (length !== 1) {
      return "resultados";
    }

    return "resultado";
  };

  private getTable = () => {
    const columns = [
      "Data",
      "Nome",
      "CPF/CNPJ",
      "Tipo de conta",
      ...(this.section === "all" ? ["Status"] : []),
      ...(this.complianceFilterCondition ? ["Status do compliance"] : []),
      ...(this.section === "approvedPending" ? ["Movimentação"] : []),
      ...(this.section === "approvedPending" ? ["Situação"] : []),
    ];

    return (
      <>
        <RowTotalResults>
          {this.section === "approvedPending" && (
            <>
              <TotalResults>
                {this.results!.count} {this.pluralFormCheck(this.results!.count)}
              </TotalResults>
              <Button
                label="Baixar planilha"
                onClick={this.onClickDownloadExcel}
                iconComponent={<SvgSpreadsheet width="50px" height="20px" />}
              />
            </>
          )}
        </RowTotalResults>
        <Table
          titles={columns}
          ids={this.filteredIds}
          data={this.filteredRows}
          onClickRow={this.onClickRow}
          isPagination
          totalRows={this.results!.count}
          rowsPerPage={this.filter.limit}
          page={this.filter.offset / this.filter.limit}
          rowsPerPageOptions={[this.filter.limit]}
          handleChangePage={this.handleChangePage}
        />
      </>
    );
  };

  @computed
  private get filteredIds() {
    const rawIds = this.results ? this.results.data : [];

    return this.section === "approvedPending"
      ? rawIds.sort((a, b) => (b.value || 0) - (a.value || 0)).map(r => r.id)
      : rawIds.map(r => r.id);
  }

  @computed
  private get filteredRows() {
    const rawRows = this.results ? this.results.data : [];

    return this.section === "approvedPending"
      ? rawRows.sort((a, b) => (b.value || 0) - (a.value || 0)).map(this.renderRow)
      : rawRows.map(this.renderRow);
  }

  private renderRow = (row: api.ManagerAccount) => {
    const complianceBackgroundColor = {
      waiting: colors.mediumGray,
      approved: "#d8f1dc",
      repproved: "#ffd9d9",
      manualAnalysis: colors.mediumGray,
      canceled: "#ffd9d9",
      processing: colors.mediumGray,
      preApproved: "#d8f1dc",
    };
    const complianceColor = {
      waiting: colors.black,
      approved: "#329945",
      repproved: "rgb(231, 76, 60)",
      manualAnalysis: colors.black,
      canceled: "rgb(231, 76, 60)",
      processing: colors.black,
      preApproved: "#329945",
    };
    const complianceText = {
      waiting: "Pendente",
      approved: "Aprovado",
      repproved: "Reprovado",
      manualAnalysis: "Análise Manual",
      canceled: "Cancelado",
      processing: "Processando",
      preApproved: "Pré-Aprovado",
    };

    const columns: React.ReactNode[] = [
      this.section === "workflow" ? `${moment(row.createdAt).fromNow()}` : `${moment(row.createdAt).format("L")}`,
      row.name,
      prettierDocumentNumber(row.cpfCnpj),
    ];

    const getAccountTypeText = () => {
      if (row.accountType === "pf" && row.journeyType === "noRecord" && row.subType !== api.SubType.full) {
        return "PF - PAGAMENTO SIMPLES S. PRE-CADASTRO";
      } else if (row.accountType === "pf" && row.journeyType === "preApproved" && row.subType !== api.SubType.full) {
        return "PF - PAGAMENTO SIMPLES C. PRE-CADASTRO";
      } else if (row.accountType === "pf" && row.status === "approvePendingWithDocument") {
        return "Conta simples - c/ doc";
      } else if (row.accountType === "pf" && row.status === "approvePendingWithoutDocument") {
        return "Conta simples - s/ doc";
      }

      return `${prettierAbvAccountType(row.accountType)} - ${prettierCategoryAccount(row.categoryAccount)}`;
    };

    columns.push(
      <AccountTypeTag
        key={row.id}
        text={getAccountTypeText()}
        backgroundColor={
          row.accountType === "pf"
            ? colors.violetRedOpacity
            : row.accountType === "pj"
            ? colors.mediumGray
            : colors.brownGrayOp
        }
        borderColor={
          row.accountType === "pf" ? colors.violetRed : row.accountType === "pj" ? colors.black : colors.brownGray
        }
        color={row.accountType === "pf" ? colors.violetRed : row.accountType === "pj" ? colors.black : colors.black2}
      />,
    );

    if (this.section === "all") {
      columns.push(prettierUserStatus(row.status!, row.journeyType));
    }

    if (this.complianceFilterCondition && row.statusCompliance) {
      columns.push(
        <Tag
          key="1"
          backgroundColor={complianceBackgroundColor[row.statusCompliance]}
          borderColor={complianceColor[row.statusCompliance]}
          color={complianceColor[row.statusCompliance]}
          text={
            row.status === "active" && row.journeyType !== null
              ? complianceText.preApproved
              : complianceText[row.statusCompliance]
          }
        />,
      );
    }

    /*
     *Usuário é visto como bloqueado quando essa opção é setada manualmente, ou,
     *quando o seu status é automático e a movimentação de sua conta é maior que R$ 5000,00
     */
    if (this.section === "approvedPending") {
      columns.push(row.value ? `"R$ " ${innerCurrencyFormatter.format(row.value / 100).toString()}` : "-");
      columns.push(
        row.blockStatus
          ? row.blockStatus === "automatic" && row.value && row.value > 500000
            ? "Bloqueado (automaticamente)"
            : prettierBlockStatusAccount(row.blockStatus)
          : "-",
      );
    }

    return columns;
  };
}

const StyledSearchBar = styled(SearchBar)`
  width: 300px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: normal;
`;

const Row = styled.div`
  display: grid;
  grid-template-areas: "search select situation compliance";
  grid-template-columns: auto min-content min-content;
  grid-column-gap: 16px;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  grid-area: select;
  width: 180px;
  margin-top: -17px;
`;

const SituationSelect = styled(Select)`
  grid-area: situation;
  width: 150px;
  margin-top: -17px;
`;

const ComplianceSelect = styled(Select)`
  grid-area: compliance;
  width: 210px;
  margin-top: -17px;
`;

const AccountTypeTag = styled(Tag)`
  width: 200px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
`;

const Button = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

const TotalResults = styled.h6`
  color: ${colors.black};
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
`;

const RowTotalResults = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 24px 0px 24px;
`;
