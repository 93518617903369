import * as React from "react";
import SelectComponent from "react-select";
import { Props, StylesConfig } from "react-select";
import { colors } from "utils";

const customStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isSelected ? colors.white : colors.black,
    backgroundColor: state.isSelected ? colors.violetRed : colors.white,
    ":hover": {
      color: colors.white,
      backgroundColor: state.isSelected ? "" : colors.violetRedOpacity,
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  control: provided => ({
    ...provided,
    borderColor: colors.platinum,
    boxShadow: "none",
    backgroundColor: colors.lightGray,
    ":hover": {
      cursor: "pointer",
      borderColor: colors.violetRedOpacity,
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.black,
    backgroundColor: state.isFocused ? colors.violetRed : "",
  }),
};

export const Select = <T extends any = { label: string; value: string }>({
  isSearchable = false,
  ...props
}: Props<T>) => {
  const { noOptionsMessage, styles, loadingMessage, ...restProps } = props;

  return (
    <SelectComponent
      isSearchable={isSearchable}
      styles={{ ...customStyles, ...styles }}
      noOptionsMessage={() => "Sem opções"}
      loadingMessage={() => "Carregando..."}
      {...restProps}
    />
  );
};
