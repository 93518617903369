import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

export interface ITab {
  value: string;
  label: string;
  hide?: boolean;
  child: React.ReactNode;
}

interface IProps {
  tabs: ITab[];
  selected: string;
  onChangeTab: (tabValue: string) => void;
  className?: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TabHeader = styled.nav`
  display: flex;
  border-bottom: 1px solid ${colors.borderGray};
`;

const ItemHeader = styled.button<{ isSelected: boolean }>`
  background-color: transparent;
  border: none;
  border-radius: 1px;
  border-bottom: ${props => (props.isSelected ? "2px solid " + colors.violetRed : "none")};
  margin: 0;
  padding: 16px;
  color: ${props => (props.isSelected ? colors.black : colors.gray)};
  cursor: pointer;
  outline: 0;
  font-family: Lato;
  font-weight: ${props => (props.isSelected ? "600" : "normal")};
  font-size: 14px;
  &:not(:last-child) {
    margin-right: 48px;
  }

  &:hover {
    color: ${colors.black};
  }
`;

const ChildContainer = styled.section`
  flex: 1;
  overflow: auto;
  height: 100%;
`;

export class TabNavigator extends React.Component<IProps, {}> {
  public render() {
    const { tabs, selected, onChangeTab, className } = this.props;
    return (
      <Container className={className}>
        <TabHeader>
          {tabs.map(tab => {
            if (tab.hide) {
              return;
            } else {
              return (
                <ItemHeader
                  type="button"
                  onClick={() => onChangeTab(tab.value)}
                  isSelected={tab.value === selected}
                  key={tab.value}
                >
                  {tab.label}
                </ItemHeader>
              );
            }
          })}
        </TabHeader>
        <ChildContainer>{tabs.find(tab => tab.value === selected)!.child}</ChildContainer>
      </Container>
    );
  }
}
