import * as React from "react";
import { CategoryAccount, Gender, PfPj, Region, ReportFilter, UserStatus } from "api/manager-api";
import { RouterStore } from "mobx-react-router";

import { OpeningAccountsReportsList } from "../OpeningAccountsReportsList";

interface IProps {
  report: any | null;
  offset: number;
  limit: number;
  onChangePage: (page: number) => void;
  onClickDownloadExcel?: any;
  routerStore: RouterStore;
  onClickRow?: (e: string) => void;
  showCloseReason?: boolean;

}

export const OpeningAccountsReport: React.FunctionComponent<IProps> = ({
         report,
         offset,
         limit,
         onChangePage,
         onClickDownloadExcel,
         routerStore,
         onClickRow,
         showCloseReason = true,
       }) => {
         if (!report) {
           return null;
         }

         return (
           <OpeningAccountsReportsList
             items={report.users}
             limit={limit}
             offset={offset}
             totalRows={report.total}
             onChangePage={onChangePage}
             onClickDownloadExcel={onClickDownloadExcel}
             routerStore={routerStore}
             onClickRow={onClickRow}
             showCloseReason={showCloseReason}
           />
         );
       };

export const skeleton: any = {
  id: "",
  name: "",
  age: 0,
  approvedAt: new Date(),
  categoryAccount: CategoryAccount.checking,
  createdAt: new Date(),
  documentNumber: "",
  gender: Gender.masculino,
  partner: "",
  region: Region.sul,
  status: UserStatus.active,
  type: PfPj.pf,
};
