import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  size: number;
}

export const SvgPlayButton = (props: IProps) => {
  const { size, viewBox, width, height, ...restProps } = props;
  return (
    <svg viewBox="0 0 41.999 41.999" width={size} height={size} {...restProps}>
      <path
        d="M36.068 20.176l-29-20A1 1 0 1 0 5.5.999v40a1 1 0 0 0 1.568.823l29-20a.999.999 0 0 0 0-1.646z"
        fill={"currentColor"}
      />
    </svg>
  );
};
