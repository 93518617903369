import * as React from "react";
import styled, { css } from "styled-components";
import { colors } from "utils";
import { FormHelperText } from "@material-ui/core";

export interface ITextInputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string | JSX.Element;
  error?: boolean;
  errorText?: string;
}

const Container = styled.div<{ isChat?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isChat }) => (isChat ? "65%" : "none")};
`;

const Label = styled.label`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
  margin-bottom: 9px;
`;

interface IInputProps {
  error: boolean;
}

const inputStyle = css<IInputProps>`
  background-color: transparent;
  border: 1px solid ${({ error }) => (error ? colors.red : colors.platinum)};
  border-radius: 3px;
  color: ${colors.black};
  font-family: Lato;
  padding: 10px 0 10px 10px;
  width: 100%;
  max-width: 100%;
  outline: none;

  &:disabled {
    background-color: ${colors.lightGray};
  }

  @mixin placeholder {
    font-size: 10pt;
    color: ${colors.gray};
  }

  ::placeholder {
    font-size: 10pt;
    color: ${colors.gray};
  }

  ::-webkit-input-placeholder {
    font-size: 10pt;
    color: ${colors.gray};
  }

  &:focus {
    border-color: ${colors.violetRedOpacity};
  }
`;

const Input = styled.textarea<IInputProps>`
  ${inputStyle};
`;

const ErrorTextWrapper = styled.div`
  margin-top: 5px !important;
  margin-left: 3px !important;
`;

const Spacing = styled.div`
  height: 17px;
`;

export class TextAreaInput extends React.Component<ITextInputProps> {
  public render() {
    const { type, label, unselectable, error, errorText, ...allProps } = this.props;
    return (
      <Container>
        {label ? typeof label === "string" ? <Label>{label}</Label> : label : null}
        <Input error={!!error} {...allProps} />
        {!!error && !!errorText ? (
          <ErrorTextWrapper>
            <FormHelperText error>{errorText}</FormHelperText>
          </ErrorTextWrapper>
        ) : (
          <Spacing />
        )}
      </Container>
    );
  }
}
