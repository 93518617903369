import React from "react";
import { Moment } from "moment";
import classnames from "classnames";
import { RangePicker, RangePickerProps } from "rc-picker";
import config from "rc-picker/lib/generate/moment";
import ptBR from "rc-picker/lib/locale/pt_BR";

import "rc-picker/assets/index.css";
import { ReactComponent as CalendarIcon } from "assets/svgs/calendar.svg";
import { ReactComponent as Arrow } from "assets/svgs/calendar-arrow.svg";

import s from "./style.scss";

type Props = Partial<RangePickerProps<Moment>> & {
  error?: boolean;
};

export function DateRangePicker({ className, error, placeholder, ...rest }: Props) {
  return (
    <RangePicker<Moment>
      {...rest}
      format="DD/MM/YYYY"
      inputReadOnly
      allowClear
      picker="date"
      suffixIcon={<CalendarIcon />}
      placeholder={placeholder || ["Data inicial", "Data final"]}
      locale={ptBR}
      generateConfig={config}
      separator={<span>a</span>}
      className={classnames(s.DatePickerContainer, className, error && s.error)}
      dropdownClassName={s.panel}
      prevIcon={<Arrow />}
      nextIcon={<Arrow className={s.rotate} />}
    />
  );
}
