import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./RootStore";
import { session } from "../utils";

export class CardStore {
  @observable public visaQueueList: api.CardReport | null = null;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getCardReport = async (
    partnerId: string | null,
    cpf: string | null,
    limit: number,
    offset: number,
    includeVirtualCards: boolean,
  ) => {
    this.visaQueueList = await api.getCardReport({
      partnerId,
      cpf: cpf?.replace(/\D/gu, "") || null,
      limit,
      offset,
      includeVirtualCards,
    });
  };

  @session
  public resetVisaCardQueueStatus = async (partnerId: string, queuItemId: string) => {
    return api.resetVisaCardQueueStatus(partnerId, queuItemId);
  };

  @session
  public removeVisaCardFromQueue = async (partnerId: string, queuItemId: string) => {
    return api.removeVisaCardFromQueue(partnerId, queuItemId);
  };

  @session
  public getQueueError = async (queuItemId: string) => {
    return api.getQueueError(queuItemId);
  };

  public requestReplacementCard = async (cardBody: api.RequestCardBody) => {
    return api.requestReplacementCard(cardBody);
  };

  public cancelCard = async (cardBody: api.RequestCardBody) => {
    return api.cancelCard(cardBody);
  };

  public linkReplacedCard = async (cardBody: api.LinkCardBody) => {
    return api.linkReplacedCard(cardBody);
  };
}
