import * as React from "react";

export const ExclamationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="132" height="132" viewBox="0 0 132 132">
      <g fill="none" fillRule="evenodd" transform="translate(2 2)">
        <circle cx="64" cy="64" r="64" stroke="#ed2980" strokeWidth="4" />
        <path
          d="M68 92.5c0 1.928-1.572 3.5-3.5 3.5a3.506 3.506 0 0 1-3.5-3.5c0-1.928 1.572-3.5 3.5-3.5s3.5 1.572 3.5 3.5zm-7-17.682V35.166C61 33.403 62.33 32 64 32s3 1.403 3 3.166v39.652c0 1.762-1.33 3.166-3 3.166-1.67.196-3-1.404-3-3.166z"
          fill="#ed2980"
        />
      </g>
    </svg>
  );
};
