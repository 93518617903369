exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__badge__2X6MP{position:relative;display:inline-flex}.style__badge__2X6MP .style__badgeBall__rhedO{display:flex;align-items:center;justify-content:center;font-size:12px;position:relative;min-width:20px;height:20px;border-radius:15px;color:#fff;position:absolute;right:0;transform:translate(50%, -50%)}.style__badge__2X6MP .style__badgeBall-multiple__3yv8w{padding:0 6px}.style__badge__2X6MP .style__badgeBall-success__21Uuc{background-color:#87d068}.style__badge__2X6MP .style__badgeBall-error__r8gxS{background-color:#f50}.style__badge__2X6MP .style__badgeBall-processing__f55kY{background-color:#108ee9}.style__badge__2X6MP .style__badgeBall-warning__2y4s3{background-color:#faad14}.style__badge__2X6MP .style__dot__eAMJD{min-width:9px;height:9px}", ""]);

// Exports
exports.locals = {
	"badge": "style__badge__2X6MP",
	"badgeBall": "style__badgeBall__rhedO",
	"badgeBall-multiple": "style__badgeBall-multiple__3yv8w",
	"badgeBall-success": "style__badgeBall-success__21Uuc",
	"badgeBall-error": "style__badgeBall-error__r8gxS",
	"badgeBall-processing": "style__badgeBall-processing__f55kY",
	"badgeBall-warning": "style__badgeBall-warning__2y4s3",
	"dot": "style__dot__eAMJD"
};