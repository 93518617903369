import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
  opacity?: string;
}

export const SvgConfirm = (props: IProps) => {
  const { color, opacity, viewBox, ...restProps } = props;
  return (
    <>
      <svg {...restProps}>
        <path
          opacity="0.2"
          d="M41 82C63.6437 82 82 63.6437 82 41C82 18.3563 63.6437 0 41 0C18.3563 0 0 18.3563 0 41C0 63.6437 18.3563 82 41 82Z"
          fill={color || "#ED2980"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58 32.4L37.6951 54L37.6918 53.9966V54L25 40.5L30.0754 35.1L37.6918 43.2L52.9246 27L58 32.4Z"
          fill={color || "#ED2980"}
        />
      </svg>
    </>
  );
};
