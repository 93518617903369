import styled from "styled-components";
import { colors } from "utils";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  margin-right: 10px;
  color: ${colors.gray};
  font-size: 14px;
`;
