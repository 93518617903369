/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import * as api from "api/manager-api";
import { action, observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { colors } from "utils";

import { prettierOperation } from "../../../utils";
import { DaysSelector } from "./DaysSelector";
import { TimeManager } from "../../../components/TimeManager";
import { ToggleEditMode } from "../../../components/ToggleEditMode";
import { GeneralStore, PlatformManagerStore } from "../../../stores";
import { Loader } from "../../../components/Loader";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  platformManagerStore?: PlatformManagerStore;
  generalStore?: GeneralStore;
}

export const skeleton: api.DatePeriod = {
  end: "17:00",
  start: "07:00",
};

@inject("platformManagerStore", "generalStore")
@observer
export class Operations extends React.Component<IProps> {
  @observable private editableMode = false;

  @observable private oldOperations: api.OperationsDays | null = null;

  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private loadingEditing = false;

  private validation = observable.map<keyof api.Operations, boolean>();

  public componentDidMount() {
    this.getData();
  }

  @action
  private getData = async () => {
    try {
      this.loading = true;
      this.props.platformManagerStore!.getOperations();
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter as operações.";
    } finally {
      this.loading = false;
    }
  };

  @action
  private enterEditing = () => {
    this.oldOperations = toJS(this.props.platformManagerStore!.operations);
    this.editableMode = true;
  };

  @action
  private cancelEditing = () => {
    this.props.platformManagerStore!.operations = toJS(this.oldOperations);
    this.editableMode = false;
  };

  @action
  private save = async () => {
    try {
      this.loadingEditing = true;
      await this.props.platformManagerStore!.setOperations(this.props.platformManagerStore!.operations!);
      this.editableMode = false;
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao editar as operações.");
    } finally {
      this.loadingEditing = false;
    }
  };

  @action
  public handleDays = (days: api.Day[]) => {
    this.props.platformManagerStore!.operations!.days = days;
  };

  @action
  private handleTime = (key: keyof api.Operations) => (value: api.DatePeriod | null) => {
    this.props.platformManagerStore!.operations!.operations[key] = value;
  };

  @action
  private handleValidation = (key: keyof api.Operations) => (valid: boolean) => {
    this.validation.set(key, valid);
  };

  public render() {
    return (
      <Container>
        {this.renderEdit()}
        {this.renderData()}
      </Container>
    );
  }

  private renderEdit = () => {
    if (!this.props.generalStore!.hasPermission(api.AdminUserPermission.editServiceHours)) {
      return null;
    }

    const hasErrors = Array.from(this.validation.values()).some((value: boolean) => !value);

    return (
      <ToggleEditModeWrapper>
        <ToggleEditMode
          editableMode={this.editableMode}
          onChangeToEditableMode={this.enterEditing}
          onCancel={this.cancelEditing}
          onSave={this.save}
          isLoading={this.loadingEditing}
          disabledSaveButton={hasErrors}
        />
      </ToggleEditModeWrapper>
    );
  };

  private renderData = () => {
    const { operations, getOperations } = this.props.platformManagerStore!;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={getOperations} />;
    }

    if (!operations) {
      return null;
    }

    const { days, operations: types } = operations;
    const keys = Object.keys(types) as Array<keyof api.Operations>;

    return (
      <>
        <Title>Dias de Funcionamento dos Serviços</Title>
        <DaysSelector
          selectedDays={days}
          onChange={this.handleDays}
          disabled={!(this.editableMode || this.loadingEditing)}
        />
        <Title>Gestão dos Horários</Title>
        <TimeManagerContainer>{keys.map(this.renderOperation)}</TimeManagerContainer>
      </>
    );
  };

  private renderOperation = (key: keyof api.Operations) => {
    const operation = this.props.platformManagerStore!.operations!.operations[key] || skeleton;

    return (
      <TimeManager
        key={key}
        label={prettierOperation(key)}
        disabled={!this.editableMode || this.loadingEditing}
        value={operation}
        onChange={this.handleTime(key)}
        onValidate={this.handleValidation(key)}
      />
    );
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  margin: 30px 0 0 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0;
`;

const TimeManagerContainer = styled.div`
  margin-top: 38px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px 140px;
  justify-content: flex-start;
`;

const ToggleEditModeWrapper = styled.div`
  margin: 20px 0;
`;
