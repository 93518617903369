import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {}

export const SvgSpreadsheet = (props: IProps) => {
  return (
    <svg {...props}>
      <path
        fill="#A6A6A6"
        fillRule="evenodd"
        d="M20.725 6.116a.99.99 0 0 1 .275.686v2.255c0 .549-.447.995-1 .995-.552 0-1-.446-1-.995V7.959h-4c-1.104 0-2-.787-2-1.887V1.99H4c-.552 0-1 .549-1 1.098v5.969c0 .549-.447.995-1 .995-.552 0-1-.446-1-.995V2.093C1 .995 1.896 0 3 0h11.55c.274 0 .536.163.725.361l5.45 5.755zM8.65 20H6.792a.465.465 0 0 1-.294-.088.67.67 0 0 1-.178-.209l-1.607-2.805c-.029.08-.062.15-.099.209l-1.496 2.596a1.184 1.184 0 0 1-.181.204.389.389 0 0 1-.27.093H.928l2.58-4.125-2.48-3.889h1.859c.12 0 .21.016.266.047a.416.416 0 0 1 .152.157l1.594 2.7a1.17 1.17 0 0 1 .064-.137c.024-.044.05-.09.08-.137l1.396-2.398a.566.566 0 0 1 .163-.177.383.383 0 0 1 .212-.055h1.782l-2.514 3.812L8.651 20zm6.085-1.485V20h-4.83v-8.014h1.86v6.529h2.97zm5.913-4.9a.698.698 0 0 1-.173.197.388.388 0 0 1-.234.066.572.572 0 0 1-.272-.08 49.762 49.762 0 0 0-.33-.178 2.738 2.738 0 0 0-.421-.179 1.683 1.683 0 0 0-.539-.08c-.348 0-.608.075-.778.223a.758.758 0 0 0-.256.602c0 .162.051.296.154.402.103.106.237.198.404.275.167.077.358.148.572.212.215.064.434.135.657.214.224.08.443.172.658.278.214.106.405.242.572.407a1.9 1.9 0 0 1 .404.602c.103.237.154.522.154.855 0 .37-.064.717-.192 1.04a2.434 2.434 0 0 1-.559.844c-.244.24-.544.43-.902.567a3.375 3.375 0 0 1-1.218.206c-.25 0-.503-.026-.762-.077a4.524 4.524 0 0 1-1.433-.55 2.898 2.898 0 0 1-.555-.429l.55-.869a.55.55 0 0 1 .173-.157.452.452 0 0 1 .234-.063c.11 0 .22.035.333.104.112.07.238.147.38.232.14.084.303.16.486.23s.4.105.649.105c.337 0 .6-.074.787-.223.187-.148.28-.384.28-.706a.668.668 0 0 0-.154-.457 1.19 1.19 0 0 0-.404-.292 3.725 3.725 0 0 0-.57-.203c-.212-.059-.43-.124-.654-.195a4.788 4.788 0 0 1-.654-.264 1.93 1.93 0 0 1-.974-1.05c-.103-.256-.154-.57-.154-.944a2.272 2.272 0 0 1 .715-1.645c.235-.223.522-.402.864-.536a3.19 3.19 0 0 1 1.171-.2c.246 0 .485.019.718.057a3.773 3.773 0 0 1 1.248.44 2.7 2.7 0 0 1 .487.355l-.462.864z"
      />
    </svg>
  );
};
