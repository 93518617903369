import styled from "styled-components";
import { colors } from "utils";

interface IProps {
  url: any;
  onClick: () => void;
}

const searchIcon = require("../../assets/icons/svg/search.svg");

export const ImagePreview = styled.div<IProps>`
  width: 100%;
  height: 98px;
  background-color: ${colors.borderGray};
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-image: url(${searchIcon}), ${({ url }) => `url(${url})`};
    background-size: 30px 30px, contain;
    background-position: center, center;
    opacity: 0.7;
  }
`;
