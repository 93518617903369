/* eslint-disable no-negated-condition */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import * as React from "react";
import styled from "styled-components";
import { Lock } from "@material-ui/icons";

interface BalanceProps {
  available: number | null;
  blocked: number | null;
}
export function Balance({ available, blocked }: BalanceProps) {
  return (
    <Wrapper>
      <Group>
        <Label>Saldo disponível</Label>
        <Value>
          {available === null
            ? "-"
            : available.toLocaleString("pt-BR", {
                currency: "BRL",
                style: "currency",
              })}
        </Value>
      </Group>

      <Group>
        <Label>Saldo bloqueado</Label>
        <LockValue>
          <LockIcon />
          {blocked === null
            ? "-"
            : blocked.toLocaleString("pt-BR", {
                currency: "BRL",
                style: "currency",
              })}
          <LockMoney sizeValue={18} value={blocked} />
        </LockValue>
      </Group>
    </Wrapper>
  );
}

/**
 * Styled Components
 */
const Wrapper = styled.div`
  display: flex;
  margin: 0 auto 0px;
`;

const Group = styled.div`
  display: inline-block;
  padding: 0 24px;
  margin: 24px 0;

  &:not(:last-child) {
    border-right: 1px solid #cccccc;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #a6a6a6;
  display: block;
  margin-bottom: 16px;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #a5a5a5;
`;
const MoneyComponent = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  color: #a5a5a5;
`;
const LockValue = styled(Value as any)`
  color: #ee5b43;
`;

const LockIcon = styled(Lock as any)`
  margin-right: 16px;
`;

const LockMoney = styled(MoneyComponent as any)`
  color: #ee5b43;
`;
