/* eslint-disable consistent-return */
import * as React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Redirect, Route, Switch } from "react-router";

import { AccountsStore, GeneralStore, RouterStore } from "../../stores";
import {
  accountsManagerPath,
  accountsSearchPath,
  colors,
  legalEntityMEIProfileManagerPath,
  legalEntityPJOldProfileManagerPath,
  legalEntityProfileManagerPath,
  userProfileManagerPath,
} from "../../utils";
import { UserProfile } from "./UserProfile";
import { LegalEntityProfile } from "./LegalEntityProfile";
import { AccountsSearch } from "./AccountsSearch";
import { BackButton } from "../../components/BackButton";
import { LegalEntityMEIProfile } from "./LegalEntityMEIProfile";

interface IProps {
  generalStore: GeneralStore;
  routerStore: RouterStore;
  accountsStore: AccountsStore;
}

@inject("generalStore", "routerStore", "accountsStore")
@observer
export class AccountsManager extends React.Component<IProps> {
  public render() {
    return (
      <Container>
        <Title>Gestão de contas</Title>
        {this.renderBackButton()}
        <Switch>
          <Route exact path={accountsManagerPath}>
            <Redirect to={accountsSearchPath("")} />
          </Route>
          <Route path={accountsSearchPath()} component={AccountsSearch} />
          <Route path={userProfileManagerPath()} component={UserProfile} />
          <Route path={legalEntityMEIProfileManagerPath()} component={LegalEntityMEIProfile} />
          <Route path={legalEntityPJOldProfileManagerPath()} component={LegalEntityMEIProfile} />
          <Route path={legalEntityProfileManagerPath()} component={LegalEntityProfile} />
        </Switch>
      </Container>
    );
  }

  private renderBackButton = () => {
    if (this.props.routerStore.location.pathname.startsWith(accountsSearchPath(""))) {
      return;
    }

    return <StyledBackButton onClick={this.props.routerStore.goBack} label={"Voltar para a busca"} />;
  };
}

const StyledBackButton = styled(BackButton)`
  align-self: flex-start;
  width: 210px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

const Container = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;
