import * as React from "react";
import { ComplianceStatus, FilteredUsers, Region } from "api/manager-api";
import { RegisterField } from "api/partner-api";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { RouterStore } from "mobx-react-router";

import { Table } from "../../../apps/manager/components/Table";
import {
  prettierAbvAccountType,
  prettierDate,
  prettierCategoryAccount,
  // PrettierGender,
  prettierRegion,
  prettierDocumentNumber,
  prettierUserStatus,
  prettierComplianceStatus,
} from "../../../apps/manager/utils";
import { colors } from "../../../utils";
import { OutlinedButton } from "../OutlinedButton";
import { SvgSpreadsheet } from "../../icons/SvgSpreadsheet";
import { Dialog } from "../../../apps/manager/components/Dialog";
import { Approvation, getFieldTextByField } from "./utils";

interface IProps {
  items: FilteredUsers[];
  totalRows: number;
  onChangePage: (page: number) => void;
  limit: number;
  offset: number;
  onClickDownloadExcel?: () => any;
  routerStore: RouterStore;
  onClickRow?: (e: string) => void;
  showCloseReason?: boolean;
}

const pluralFormCheck = (length: number): string => {
  if (length !== 1) {
    return "resultados";
  }

  return "resultado";
};

export const OpeningAccountsReportsList: React.FunctionComponent<IProps> = ({
         items,
         totalRows,
         onChangePage,
         offset,
         limit,
         onClickDownloadExcel,
         onClickRow,
         showCloseReason = true,
       }) => {
         const [pastPendingRows, setPastPendingRows] = React.useState<IPastPendingFields[]>([]);
         const isModalOpen = Boolean(pastPendingRows.length);

         const titles = [
           // Tslint:disable-next-line:jsx-wrap-multiline
           <MergeTitleRow key={"1"}>
             <SpanTitle>Nome</SpanTitle>
             <SpanTitle>CPF/CNPJ</SpanTitle>
           </MergeTitleRow>,
           "PARCEIRO",
           "CONTA",
           // Tslint:disable-next-line:jsx-wrap-multiline
           <MergeTitleRow key={"1"}>
             <SpanTitle>Tipo de pessoa</SpanTitle>
             <SpanTitle>Tipo da conta</SpanTitle>
           </MergeTitleRow>,
           "TIPO DE APROVAÇÃO",
           "DATA DE CADASTRO",
           "STATUS",
           "STATUS DO COMPLIANCE",
           // "Sexo",
           "REGIÃO",
           "DATA DE ENCERRAMENTO",
           showCloseReason ? "MOTIVO DE ENCERRAMENTO" : null,
           "AÇÕES",
           // "Idade",
         ];

         return (
           <StyledContainer>
             <Row>
               <TotalResults>
                 {totalRows} {pluralFormCheck(totalRows)}
               </TotalResults>
               <Button
                 label="Baixar planilha"
                 onClick={onClickDownloadExcel}
                 iconComponent={<SvgSpreadsheet width="50px" height="20px" />}
               />
             </Row>
             <TableContainer>
               <Table
                 titles={titles}
                 ids={items.map((_, i) => `emp-${i}`)}
                 data={items.map((row, idx) => [
                   <MergeRow key={`data=${idx}`}>
                     <span>{row.name}</span>
                     <SecondMergeSpan>{prettierDocumentNumber(row.documentNumber)}</SecondMergeSpan>
                   </MergeRow>,
                   row.partner,
                   (row as any).account || "Não definida.",
                   <MergeRow key={`data=${idx}`}>
                     <span>{prettierAbvAccountType(row.type!)}</span>
                     <SecondMergeSpan>{prettierCategoryAccount(row.categoryAccount)}</SecondMergeSpan>
                   </MergeRow>,
                   <Approvation key={`approvation=${idx}`} approvation={(row as any).approvation} />,
                   prettierDate(row.createdAt),
                   <MergeRow key={`data=${idx}`}>
                     {row.status === "pending" && (row as any).pendingFields && (row as any).pendingFields.length ? (
                       <Tooltip
                         title={
                           <>
                             <div style={{ fontSize: 16 }}>Pendências: </div>
                             <br />
                             {(row as any).pendingFields.map((p: RegisterField) => (
                               <div style={{ fontSize: 14 }}>{getFieldTextByField(p)}</div>
                             ))}
                           </>
                         }
                       >
                         <span style={{ color: colors.violetRed }}>
                           {prettierUserStatus(row.status, row.journeyType)}
                         </span>
                       </Tooltip>
                     ) : (
                       <span>{prettierUserStatus(row.status, row.journeyType)}</span>
                     )}

                     <SecondMergeSpan>{row.approvedAt ? prettierDate(row.approvedAt) : "-"}</SecondMergeSpan>
                   </MergeRow>,

                   (row as any).complianceStatus
                     ? prettierComplianceStatus(row.complianceStatus as ComplianceStatus)
                     : "-",
                   // PrettierGender(row.gender!),
                   row.region ? prettierRegion(row.region as Region) : "-",
                   row.closedAt ? prettierDate(row.closedAt) : "-",
                       showCloseReason ? (row.closingReason || "-") : null,
                   // Tslint:disable-next-line:jsx-wrap-multiline
                   !(row as any).pastPendingActions || !(row as any).pastPendingActions.length ? (
                     "-"
                   ) : (
                     <OutlinedButton
                       onClick={() => setPastPendingRows((row as any).pastPendingActions)}
                       key={`data=${idx}-history`}
                       color="primary"
                       label="Histórico de pendências"
                     />
                   ),
                   // row.age,
                 ])}
                 isPagination
                 totalRows={totalRows}
                 rowsPerPage={limit}
                 page={offset / limit}
                 rowsPerPageOptions={[limit]}
                 handleChangePage={onChangePage}
                 onClickRow={e => onClickRow!(e)}
               />
             </TableContainer>
             {isModalOpen && (
               <PendingHistoryModal
                 isOpen={isModalOpen}
                 onClose={() => setPastPendingRows([])}
                 pendingRequests={pastPendingRows}
               />
             )}
           </StyledContainer>
         );
       };

interface IPendingHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  pendingRequests: IPastPendingFields[];
}

interface IPastPendingFields {
  answeredAt: Date;
  fields: RegisterField[];
  requestedAt: Date;
}

function PendingHistoryModal({ isOpen, onClose, pendingRequests }: IPendingHistoryModalProps) {
  return (
    <Dialog showCloseButton maxWidth={false} title="Histórico de pendências" isOpen={isOpen} onClose={onClose}>
      <div style={{ marginTop: "-2rem" }}>
        {pendingRequests.map(p => (
          <PendingInfo data={p} />
        ))}
      </div>
    </Dialog>
  );
}

export function PendingInfo({ data }: { data: IPastPendingFields }) {
  return (
    <PendingInfoContainer>
      <dl>
        <PendingInfoRow>
          <dt>Revalidação requisitada em:</dt>
          <dd>
            {moment(data.requestedAt).format("DD/MM/YYYY")} - {data.requestedAt.getHours()}:
            {data.requestedAt.getMinutes()}
          </dd>
        </PendingInfoRow>
        <PendingInfoRow>
          <dt>Respondida em: </dt>
          <dd>
            {moment(data.answeredAt).format("DD/MM/YYYY")} - {data.answeredAt.getHours()}:{data.answeredAt.getMinutes()}
          </dd>
        </PendingInfoRow>
        <PendingInfoRow>
          <dt>Campos do cadastro: </dt>
          <dd>{data.fields.map(getFieldTextByField).join(", ")}</dd>
        </PendingInfoRow>
      </dl>
      <Separator />
    </PendingInfoContainer>
  );
}

const PendingInfoContainer = styled.div`
  width: 500px;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
`;

const Separator = styled.hr`
  display: block;
  width: 100%;
  border: solid 0.5px ${colors.gray};
  margin-bottom: 1rem;
  margin-top: 0.2rem;
`;

const PendingInfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;

  dd {
    font-weight: bold;
  }

  dt,
  dd {
    display: inline;
  }
`;

const MergeTitleRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpanTitle = styled.span`
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const MergeRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
`;

const SecondMergeSpan = styled.span`
  margin-top: 6px;
  color: ${colors.gray};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const TableContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
`;

const Button = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

const TotalResults = styled.h6`
  color: ${colors.black};
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 24px 0px 24px;
`;
