import styled from "styled-components";
import { colors } from "utils";

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;
