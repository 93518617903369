/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { StatusMessage } from "api/manager-api";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { Centralizer } from "../styled";

interface IServiceDataProps<T> {
  title: string;
  content: {
    data: T | null;
    status: StatusMessage;
    error: string | null;
  } | null;
  children: (d: T) => React.ReactNode;
}

export function ServiceData<T>({ title, content, children }: IServiceDataProps<T>) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Title>{title}</Title>
      <AnimateHeight expanded={expanded}>
        {content && content.data ? (
          <ExpansibleContainer>
            <Container expanded={expanded}>{children(content.data)}</Container>
            <ExpandButton
              label={expanded ? "Reduzir" : "Expandir"}
              onClick={() => setExpanded(ex => !ex)}
              customColor={colors.violetRed}
              textColor={colors.lightVioletRed}
              loadingColor={colors.violetRed}
              removeDisabledOpacity
            />
          </ExpansibleContainer>
        ) : (
          <Centralizer>
            <ServiceText>
              {(content && content.error) || "O serviço não retornou resultados para essa busca."}
            </ServiceText>
          </Centralizer>
        )}
      </AnimateHeight>
    </>
  );
}

const Container = styled.div<{ expanded: boolean }>`
  width: 100%;
  overflow: hidden;
  margin-bottom: ${props => (props.expanded ? "20px" : "0px")};
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 600;
  font-family: "Lato";
  margin-bottom: 1rem;
  color: ${colors.black};
`;

const AnimateHeight = styled.div<{ expanded: boolean }>`
  max-width: 805px;
  border-radius: 6px;
  border: solid 1px ${colors.mediumGray};
  height: 5rem;
  max-height: 5rem;
  margin-bottom: 2rem;
  padding: 1rem;
  position: relative;
  transition: max-height 0.6s ease;
  font-family: "Lato";
  color: ${colors.black};

  ${props =>
    props.expanded &&
    css`
      max-height: 100%;
      height: fit-content;
    `};
`;

const ExpansibleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;

const ExpandButton = styled(RaisedButton)`
  position: absolute;
  width: 112px;
  height: 40px;
  text-transform: capitalize;
  left: calc(50% - 50px);
  bottom: -20px;
  padding: 0;
  z-index: 200;
  font-family: "Lato";
  color: ${colors.black};
  background-color: ${colors.lightGray};
`;

const ServiceText = styled.h4`
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato";
  margin-bottom: 1rem;
  color: ${colors.gray};
`;
