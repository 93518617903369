import React from "react";
import classNames from "classnames";

import * as s from "./style.scss";

export interface IProps {
  tabKey: string;
  className?: string;
  children: React.ReactNode;
  index?: number;
  isActive?: boolean;
  actualSelectedTab?: string;
  title: string;
}

export function TabPane(props: IProps) {
  return (
    <div className={classNames(s.tabPane, props.isActive ? s.tabPane_isActive : s.tabPane_isInactive, props.className)}>
      {props.children}
    </div>
  );
}
