import * as React from "react";
import CheckboxComponent from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "utils";

interface IStyleMaterialUi {
  classes?: any;
  style?: any;
}

interface IProps extends IStyleMaterialUi {
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  color?: string;
  name?: string;
}

const styles = () => ({
  root: {
    color: "rgba(237, 41, 128, 0.54)",
    "&$checked": {
      color: colors.violetRed,
      "&$disabled": {
        color: "rgba(237, 41, 128, 0.5)",
      },
    },
  },
  checked: {},
  disabled: {},
});

const CheckBoxStyled: React.SFC<IProps> = props => {
  const { style, checked, handleChange, disabled, name, classes } = props;
  return (
    <CheckboxComponent
      style={style}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      name={name}
      classes={{
        root: classes.root,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
    />
  );
};

export const Checkbox = withStyles(styles)(CheckBoxStyled);
