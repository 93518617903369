/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sort-keys */
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { formatCpf } from "@brazilian-utils/formatters";
import { AuditData, AuditInfo } from "api/manager-api";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { Dialog } from "../../components/Dialog";
import { InnerAlert } from "../../components/InnerAlert";
import { Loader } from "../../components/Loader";
import { SearchBar } from "../../components/SearchBar";
import { Table } from "../../components/Table";
import { AuditStore, GeneralStore } from "../../stores";
import { maskValidateCpf } from "../../utils";
import { truncateStr } from "../../utils/helpers";
import { AuditDialog } from "./AuditDialog";
import { SvgRightArrow } from "../../assets/icons/SvgRightArrow";

interface IProps {
  auditStore: AuditStore;
  generalStore: GeneralStore;
}

const rowsLimit = 30;

@inject("auditStore", "generalStore")
@observer
export class AuditManager extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.getAudit();
  }

  @observable private searchValue = "";

  @observable private loading = false;

  @observable private loadingMore = false;

  @observable private isDialogOpen = false;

  @observable private error: string | null = null;

  @observable private auditData: AuditData | null = null;

  @observable private auditInfo: AuditInfo | null = null;

  @observable private startDate: moment.Moment | null = null;

  @observable private endDate: moment.Moment | null = null;

  @observable private focusedInput: "startDate" | "endDate" | null = null;

  @observable private hasMoreToShow = false;

  private showingRows = 0;

  @action
  private handleClose = () => {
    this.isDialogOpen = false;
    this.auditInfo = null;
  };

  @action
  private onChange = (value: string) => {
    this.searchValue = value;
  };

  @action
  private getAudit = async () => {
    try {
      this.loading = true;
      this.auditData = await this.props.auditStore.getAudit({
        auditAction: null,
        end: this.endDate ? this.endDate.toDate() : null,
        limit: rowsLimit,
        start: this.startDate ? this.startDate.toDate() : null,
        cpf: this.searchValue ? this.searchValue : null,
        userId: null,
      });
      this.showingRows = rowsLimit;
      this.error = null;

      this.hasMoreToShow = this.auditData && this.auditData.total > this.showingRows;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao carregar a auditoria.";
    } finally {
      this.loading = false;
    }
  };

  @action
  private showMore = async () => {
    try {
      this.loadingMore = true;

      const { data } = this.auditData!;
      const lastItem = data.slice().pop();

      const { data: moreData, total } = await this.props.auditStore.getAudit({
        auditAction: null,
        end: lastItem!.dateTime,
        limit: rowsLimit,
        start: this.startDate ? this.startDate.toDate() : null,
        cpf: this.searchValue ? this.searchValue : null,
        userId: null,
      });

      this.auditData = {
        data: [...data, ...moreData],
        total,
      };
      this.showingRows += rowsLimit;

      this.hasMoreToShow = Boolean(moreData.length) && this.auditData && this.auditData.total > this.showingRows;
    } catch (err) {
      this.props.generalStore.showToast(err.message || "Ocorreu um erro ao carregar mais dados.");
    } finally {
      this.loadingMore = false;
    }
  };

  @action
  private onClickRow = (id: string) => {
    const info = this.auditData!.data.find(data => data.id === id);

    this.auditInfo = info!;
    this.isDialogOpen = true;
  };

  @action
  private onDatesChange = (arg: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    this.startDate = arg.startDate;
    this.endDate = arg.endDate;
    this.getAudit();
  };

  @action
  private onFocusChange = (arg: "startDate" | "endDate" | null) => {
    this.focusedInput = arg;
  };

  public render() {
    return (
      <Container>
        {this.renderDialog()}
        <Title>Auditoria</Title>
        <Row>
          <SearchBar
            value={this.searchValue}
            onChange={this.onChange}
            placeholder="Filtrar por CPF"
            handleSearch={this.getAudit}
            mask={maskValidateCpf}
            enabledWithoutInput
          />
          <WrapperDatePicker>
            <DateRangePicker
              startDate={this.startDate}
              startDateId="start_date_filter"
              endDate={this.endDate}
              endDateId="end_date_filter"
              onDatesChange={({ startDate: start, endDate: end }) => {
                const startDate = start
                  ? start
                      .hour(0)
                      .minute(0)
                      .second(0)
                  : null;
                const endDate = end
                  ? end
                      .hour(23)
                      .minute(59)
                      .second(59)
                  : null;

                this.onDatesChange({ startDate, endDate });
              }}
              focusedInput={this.focusedInput}
              onFocusChange={focusedInput => this.onFocusChange(focusedInput)}
              startDatePlaceholderText="Data Inicial"
              endDatePlaceholderText="Data Final"
              isOutsideRange={() => false}
              horizontalMargin={49}
              minimumNights={0}
              customArrowIcon={<SvgRightArrow color={colors.gray} width="7px" height="12px" />}
              hideKeyboardShortcutsPanel
            />
          </WrapperDatePicker>
        </Row>
        {this.renderData()}
      </Container>
    );
  }

  private renderDialog = () => {
    if (!this.auditInfo) {
      return;
    }

    return (
      <Dialog
        title="Auditoria"
        isOpen={this.isDialogOpen}
        showCloseButton
        onClose={this.handleClose}
        padding={"0 24px 24px 24px"}
        maxWidth={false}
      >
        <AuditDialog data={this.auditInfo} />
      </Dialog>
    );
  };

  private renderData = () => {
    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={() => this.getAudit()} />;
    }

    const { data } = this.auditData!;

    if (!data.length) {
      return (
        <InnerAlert
          message="Nenhum dado para mostrar"
          buttonLabel="Buscar novamente"
          buttonClick={() => this.getAudit()}
        />
      );
    }

    return (
      <>
        <Table
          titles={["CPF", "Nome", "Data/Hora", "Descrição"]}
          ids={data.map(info => info.id)}
          data={data.map(info => [
            formatCpf(info.cpf),
            info.name,
            moment(info.dateTime).format("L [às] HH:mm"),
            truncateStr(info.resume!, 45) || info.auditType,
          ])}
          trStyles={[
            {
              minWidth: "111px",
              boxSizing: "content-box",
            },
          ]}
          onClickRow={this.onClickRow}
        />
        <ShowMoreContainer>
          <Line />
          <ShowMoreButton
            label={this.hasMoreToShow ? "Mostrar mais" : "Fim"}
            disabled={!this.hasMoreToShow}
            customColor={colors.lightVioletRed}
            textColor={colors.violetRed}
            loadingColor={colors.violetRed}
            onClick={this.showMore}
            loading={this.loadingMore}
            removeDisabledOpacity
          />
        </ShowMoreContainer>
      </>
    );
  };
}

const WrapperDatePicker = styled.div`
  margin-left: 50px;

  .DateInput {
    width: 100px;
    height: 40px;
    margin-left: 10px;
  }

  .DateInput_input {
    height: 40px;
    padding: 0px 0px 0px 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    color: ${colors.gray};
    border-bottom: none;
    ::placeholder {
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      color: ${colors.gray};
    }
  }

  .DateRangePickerInput {
    border: 1px solid ${colors.lightPink};
    border-radius: 4px;
    overflow: hidden;
  }

  .CalendarDay__selected {
    background: ${colors.violetRed};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected:hover {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected_span {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
    &:hover {
      background: ${colors.violetRedOpacity};
      border: ${colors.violetRed};
    }
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRedOpacity};
  }
`;

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;

  /* justify-content: space-between; */
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
`;

const ShowMoreContainer = styled.div`
  position: relative;
  height: 70px;
`;

const Line = styled.div`
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: ${colors.borderGray};
  top: 50%;
`;

const ShowMoreButton = styled(RaisedButton)`
  position: absolute;
  width: fit-content;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
