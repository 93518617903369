import * as React from "react";
import { ComplianceStatus, FilteredUsers, FilteredUsersByPartner, Region } from "api/manager-api";
import { RegisterField } from "api/partner-api";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import { RouterStore } from "mobx-react-router";

import { Table } from "../../../apps/manager/components/Table";
import {
  prettierDate,
  // PrettierGender,
  prettierDocumentNumber,
  prettierPhone,
} from "../../../apps/manager/utils";
import { colors, currencyFormatter, currencyParser } from "../../../utils";
import { OutlinedButton } from "../OutlinedButton";
import { SvgSpreadsheet } from "../../icons/SvgSpreadsheet";
import { Dialog } from "../../../apps/manager/components/Dialog";
import { getFieldTextByField } from "./utils";

interface IProps {
  items: FilteredUsersByPartner[];
  totalRows: number;
  onChangePage: (page: number) => void;
  limit: number;
  offset: number;
  onClickDownloadExcel?: () => any;
  routerStore: RouterStore;
  onClickRow?: (e: string) => void;
}

const pluralFormCheck = (length: number): string => {
  if (length !== 1) {
    return "resultados";
  }

  return "resultado";
};

export const OpeningActiveAccountsReportList: React.FunctionComponent<IProps> = ({
  items,
  totalRows,
  onChangePage,
  offset,
  limit,
  onClickDownloadExcel,
  onClickRow,
}) => {
  const [pastPendingRows, setPastPendingRows] = React.useState<IPastPendingFields[]>([]);
  const isModalOpen = Boolean(pastPendingRows.length);

  const titles = [
    "PARCEIRO",
    "CPF/CNPJ",
    "CONTA",
    "TIPO DE CONTA",
    "NOME COMPLETO",
    "DATA DE NASCIMENTO",
    "E-MAIL",
    "TELEFONE",
    "FILIAÇÃO 1",
    "FILIAÇÃO 2",
    "RENDA"
  ];

  return (
    <StyledContainer>
      <Row>
        <TotalResults>
          {totalRows} {pluralFormCheck(totalRows)}
        </TotalResults>
        <Button
          label="Baixar planilha"
          onClick={onClickDownloadExcel}
          iconComponent={<SvgSpreadsheet width="50px" height="20px" />}
        />
      </Row>
      <TableContainer>
        <Table
          titles={titles}
          ids={items.map((_, i) => `emp-${i}`)}
          data={items.map((row, idx) => [
            row.partnerName,
            row.documentNumber ? prettierDocumentNumber(row.documentNumber!) : "",
            row.account,
            row.type,
            row.userName,
            row.birthDate ? prettierDate(row.birthDate) : null,
            row.email,
            row.phone ? prettierPhone(row.phone) : "",
            row.affiliation1,
            row.affiliation2,
            `R$ ${currencyFormatter(row.monthlyIncome!)}`
          ])}
          isPagination
          totalRows={totalRows}
          rowsPerPage={limit}
          page={offset / limit}
          rowsPerPageOptions={[limit]}
          handleChangePage={onChangePage}
          onClickRow={e => onClickRow!(e)}
        />
      </TableContainer>
      {isModalOpen && (
        <PendingHistoryModal
          isOpen={isModalOpen}
          onClose={() => setPastPendingRows([])}
          pendingRequests={pastPendingRows}
        />
      )}
    </StyledContainer>
  );
};

interface IPendingHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  pendingRequests: IPastPendingFields[];
}

interface IPastPendingFields {
  answeredAt: Date;
  fields: RegisterField[];
  requestedAt: Date;
}

function PendingHistoryModal({ isOpen, onClose, pendingRequests }: IPendingHistoryModalProps) {
  return (
    <Dialog showCloseButton maxWidth={false} title="Histórico de pendências" isOpen={isOpen} onClose={onClose}>
      <div style={{ marginTop: "-2rem" }}>
        {pendingRequests.map(p => (
          <PendingInfo data={p} />
        ))}
      </div>
    </Dialog>
  );
}

export function PendingInfo({ data }: { data: IPastPendingFields }) {
  return (
    <PendingInfoContainer>
      <dl>
        <PendingInfoRow>
          <dt>Revalidação requisitada em:</dt>
          <dd>
            {moment(data.requestedAt).format("DD/MM/YYYY")} - {data.requestedAt.getHours()}:
            {data.requestedAt.getMinutes()}
          </dd>
        </PendingInfoRow>
        <PendingInfoRow>
          <dt>Respondida em: </dt>
          <dd>
            {moment(data.answeredAt).format("DD/MM/YYYY")} - {data.answeredAt.getHours()}:{data.answeredAt.getMinutes()}
          </dd>
        </PendingInfoRow>
        <PendingInfoRow>
          <dt>Campos do cadastro: </dt>
          <dd>{data.fields.map(getFieldTextByField).join(", ")}</dd>
        </PendingInfoRow>
      </dl>
      <Separator />
    </PendingInfoContainer>
  );
}

const PendingInfoContainer = styled.div`
  width: 500px;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
`;

const Separator = styled.hr`
  display: block;
  width: 100%;
  border: solid 0.5px ${colors.gray};
  margin-bottom: 1rem;
  margin-top: 0.2rem;
`;

const PendingInfoRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;

  dd {
    font-weight: bold;
  }

  dt,
  dd {
    display: inline;
  }
`;

const MergeTitleRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpanTitle = styled.span`
  text-transform: uppercase;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const MergeRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0;
`;

const SecondMergeSpan = styled.span`
  margin-top: 6px;
  color: ${colors.gray};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const TableContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
`;

const Button = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

const TotalResults = styled.h6`
  color: ${colors.black};
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 24px 24px 0px 24px;
`;
