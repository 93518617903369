exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__search__3kIBc{margin:24px 0}.styles__wrapperTable__aUPkJ{width:100%;overflow:auto}.styles__loadrow__1UTvU{transform:scale(0.3);transform-origin:center left;padding-left:16px}.styles__header__39O-n{display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap}", ""]);

// Exports
exports.locals = {
	"search": "styles__search__3kIBc",
	"wrapperTable": "styles__wrapperTable__aUPkJ",
	"loadrow": "styles__loadrow__1UTvU",
	"header": "styles__header__39O-n"
};