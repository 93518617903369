/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-non-null-assertion*/

import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { RaisedButton } from "legacy/components/RaisedButton";
import { currencyFormatter } from "utils";
import { useStores } from "@manager/stores/RootStore";

import { Input, InputLabel, InputMask, Modal } from "@components/";

import s from "./styled.scss";

interface IProps {
  user: api.RegisterUser | null;
  isOpen: boolean;
  onClose: () => void;
  reloadPage: () => void;
  showToast: (msg: string) => void;
}

interface UserFields {
  email: string;
  filiacao1: string;
  phone: string;
  monthlyIncome: string;
}

export const EditUserDataDialog = observer(({ isOpen, onClose, reloadPage, showToast, user }: IProps) => {
  const [loading, setLoading] = useState(false);
  const { usersStore } = useStores();

  useEffect(() => {
    loadUserFields();
  }, []);

  const loadUserFields = async () => {
    if (user) {
      formik.setValues({
        email: user.email,
        filiacao1: user.filiacao1 || "",
        monthlyIncome: user.monthlyIncome ? user.monthlyIncome : "0",
        phone: user.phone || "",
      });
    }
  };

  const initialValues: UserFields = {
    email: "",
    filiacao1: "",
    monthlyIncome: "",
    phone: "",
  };

  const formik = useFormik({
    validateOnBlur: true,
    initialValues,
    onSubmit: async values => {
      try {
        setLoading(true);

        console.log(values);

        const data: api.UserBasicData = {
          email: formik.values.email,
          filiacao1: formik.values.filiacao1,
          monthlyIncome: formik.values.monthlyIncome.replace(".", "").replace(",", ""),
          phone: formik.values.phone,
        };

        await usersStore.updateUserData(user!.userId, data);

        onClose();

        showToast("Dados cadastrais alterados com sucesso.");

        reloadPage();
      } catch (err) {
        showToast(err.message || "Ocorreu um erro ao salvar a mensagem.");
      } finally {
        setLoading(false);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("O campo email é um campo obrigatório.")
        .email("Por favor digite um email válido."),
      filiacao1: Yup.string().required("O campo filiação é um campo obrigatório."),
      monthlyIncome: Yup.string().required("O campo Renda Mensal é um campo obrigatório."),
      phone: Yup.string().required("O campo telefone é um campo obrigatório."),
    }),
  });

  return (
    <Modal visible={isOpen} title="Atualizar dados cadastrais" onClose={onClose}>
      <div className={s.box}>
        <form onSubmit={formik.handleSubmit}>
          <div className={s.info}>
            <InputLabel label="*Email" htmlFor="email" error={formik.errors.email || undefined}>
              <Input
                error={Boolean(formik.errors.email && formik.touched.email)}
                name="email"
                type="email"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </InputLabel>
            <InputLabel label="*Filiação" htmlFor="filiacao1" error={formik.errors.filiacao1 || undefined}>
              <Input
                error={Boolean(formik.errors.filiacao1 && formik.touched.filiacao1)}
                name="filiacao1"
                id="filiacao1"
                onChange={formik.handleChange}
                value={formik.values.filiacao1}
              />
            </InputLabel>
            <InputLabel label="*Telefone" htmlFor="phone" error={formik.errors.phone || undefined}>
              <InputMask
                placeholder="(99) 99999-9999"
                mask={formik.values.phone.length <= 10 ? "(99) 9999-9999" : "(99) 9 9999-9999"}
                error={Boolean(formik.errors.phone && formik.touched.phone)}
                name="phone"
                id="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
            </InputLabel>
            <InputLabel label="*Renda Mensal" htmlFor="monthlyIncome" error={formik.errors.monthlyIncome || undefined}>
              <Input
                error={Boolean(formik.errors.monthlyIncome && formik.touched.monthlyIncome)}
                name="monthlyIncome"
                id="monthlyIncome"
                onChange={formik.handleChange}
                value={currencyFormatter(formik.values.monthlyIncome)}
              />
            </InputLabel>
          </div>

          <div className={s.warning}>
            <p>
              Essa funcionalidade altera o cadastro do cliente apenas no dashboard, não sincronizando com os demais
              sistemas.
            </p>
          </div>

          <div className={s.inputArea}>
            <RaisedButton type="submit" className={s.NextWrapperButton} loading={loading} label="Salvar" />
          </div>
        </form>
      </div>
    </Modal>
  );
});
