import { useStores } from "@manager/stores/RootStore";
import { InternalPositivizeReducedJourney, PartnerFileLot } from "api/manager-api";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";

import { InputLabel } from "components/InputLabel";

import { Option } from "..";

import { TextInput } from "../../../components/TextInput";
import { maskValidateCpf, maskValidateDate, validateCpf } from "../../../utils";
import { BackButtonWrapper, Box, Info, InputArea, NextButtonWrapper, StyledSelect } from "./styles";

type FieldError = "cpf" | "birthday" | "partner";

interface IProps {
  onClose: () => void;
  onSubmit: (option: Option, data: InternalPositivizeReducedJourney) => Promise<void>;
}

interface IPartnerOption {
  value: string | null;
  label: string | null;
}

export const PositivizeReducedDialog = observer(({ onClose, onSubmit }: IProps) => {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [cpf, setCpf] = React.useState("");
  const [partner, setPartner] = React.useState<IPartnerOption>({ label: "Parceiro", value: "" });
  const [optionsPartners, setOptionsPartners] = React.useState<IPartnerOption[]>([]);
  const { partnerStore } = useStores();

  const [birthday, setBirthday] = React.useState("");

  async function getDataLot(partnerId: string) {
    await partnerStore.getPartnerPositivize(partnerId);
    if (partnerStore.partnersDataPositivize) {
      const partners: PartnerFileLot[] = partnerStore.partnersDataPositivize;

      let list: IPartnerOption[] = [];

      partners.forEach(item => {
        list.push({ label: item.partnerName, value: item.partnerId });
      });

      list = list.filter(
        (item, index, self) => index === self.findIndex(t => t.label === item.label && t.value === item.value),
      );

      setOptionsPartners(list);
    }
  }

  React.useEffect(() => {
    getDataLot("all");
  }, []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const newErrors: FieldError[] = [];

    if (!validateCpf(cpf)) {
      newErrors.push("cpf");
    }

    if (!moment(birthday, "DD/MM/YYYY").isValid()) {
      newErrors.push("birthday");
    }

    if (!partner.value) {
      newErrors.push("partner");
    }

    setErrors(newErrors);
    if (newErrors.length > 0) {
      setLoading(false);
      return;
    }

    setLoading(false);
    if (partner.value) {
      const birthdayFormat = new Date(moment(birthday, "DD-MM-YYYY").format("YYYY-MM-DD"));

      console.log(birthdayFormat);
      onSubmit("positivizeReducedJourney", { cpf, birthday: birthdayFormat, partnerId: partner.value });
    }

    onClose();
  }

  function changePartner(event: any) {
    let auxValue = event;

    if (event.target) {
      auxValue = event.target.value;
    }

    setPartner(auxValue);
  }

  function onChangeCPF(value: string) {
    const newErrors = errors.filter(v => v !== "cpf");

    setErrors(newErrors);
    setCpf(value);
  }

  function onChangeDate(value: string) {
    const newErrors = errors.filter(v => v !== "birthday");

    setErrors(newErrors);
    setBirthday(value);
  }

  return (
    <Box onSubmit={handleSubmit}>
      <Info>
        <label>Parceiro *</label>
        <StyledSelect
          onChange={(e: any) => changePartner(e)}
          placeholder="Parceiro"
          value={partner}
          options={optionsPartners}
          error={Boolean(errors.find(e => e === "partner"))}
        />
        <InputLabel label="CPF *">
          <TextInput
            placeholder="999.999.999.99"
            mask={maskValidateCpf}
            value={cpf}
            error={Boolean(errors.find(e => e === "cpf"))}
            onChange={e => onChangeCPF(e.target.value)}
            errorText={cpf && "CPF inválido"}
          />
        </InputLabel>

        <InputLabel label="Data de nascimento">
          <TextInput
            type="date"
            mask={maskValidateDate}
            error={Boolean(errors.find(e => e === "birthday"))}
            placeholder="DD/MM/YYYY"
            value={birthday}
            onChange={e => onChangeDate(e.target.value)}
            errorText={birthday && "Data inválida"}
          />
        </InputLabel>
      </Info>
      <InputArea>
        <BackButtonWrapper disabled={loading} type="button" label="Voltar" onClick={onClose} />
        <NextButtonWrapper loading={loading} type="submit" label="Confirmar" />
      </InputArea>
    </Box>
  );
});
