import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";
import { OutlinedButton } from "legacy/components/OutlinedButton";

import { colors } from "../../../../../../utils";
import { TextAreaInput } from "../../../../components/TextAreaInput";
import { Select as RawSelect } from "../../../../../manager/components/Select";

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const FilterContainer = styled.div`
  margin-right: 24px;
`;

export const Title = styled.span`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 600;
`;

export const FilterTitle = styled.span`
  color: ${colors.gray};
  font-size: 14px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.violetRed};
  margin: 12px 32px 0 0;
`;

export const Input = styled.input`
  max-width: 395px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background-color: ${colors.white};
  border: solid 1px ${colors.borderGray};
  padding: 10px;
  color: ${colors.black};
  font-family: lato;
  text-align: center;

  &:disabled {
    background-color: ${colors.borderGray};
  }
`;

export const PartnerSelect = styled(RawSelect)`
  margin-top: 16px;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darkGray};
  width: 180px;
`;

export const PersonSelect = styled(RawSelect)`
  margin-top: 16px;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darkGray};
  width: 250px;
`;

export const ContainerInput = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;

export const SaveButton = styled(RaisedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
`;

export const SearchButton = styled(RaisedButton)`
  width: 120px;
  height: 40px;
  align-self: flex-end;
  font-size: 14px;
`;

export const ClearButton = styled(OutlinedButton)`
  width: 146px;
  height: 40px;
  margin-right: 16px;
  align-self: flex-end;
  font-size: 14px;
`;

export const CancelButton = styled(OutlinedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
  font-size: 14px;
`;

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
`;

export const StyledTextArea = styled(TextAreaInput)`
  margin-top: 24px;
  height: 150px;
  width: 600px;
`;

export const Text = styled.span`
  color: #515251;
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0px;
`;

export const TextConfirm = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const ErrorMessage = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #e2574c;
  display: flex;
`;

export const WrapperSwitch = styled.div`
  display: flex;
  flex-direction: inherit;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  margin-top: 28px;
`;
