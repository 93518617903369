import styled from "styled-components";
import { Button } from "components/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const InputWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 380px;
  border: 1px solid #e3e4e8;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 16px;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f3f5;
  border-radius: 4px;
  border: 0;
  width: 150px;
  height: 40px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #515251;
`;

export const UploadInput = styled.input`
  flex: 1;
  background: #ffffff;
  padding: 10px 24px 13px 16px;
  height: 40px;
  border: 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  font-size: 14px;
  line-height: 17px;
  color: #525051;

  &::placeholder {
    color: #a6a6a6;
  }
`;

export const UploadInputFile = styled.input`
  display: none;
`;

export const ViewFileButton = styled(Button)`
  margin-top: 10px !important;

  & > svg {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
`;
