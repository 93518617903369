exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__label_input__3BjIh{position:relative;display:flex;flex-direction:column;align-items:flex-start;margin-bottom:16px;width:100%}.style__label_input_label__2SNOY{display:inline-block;font-size:14px;font-weight:500;line-height:17px;color:#8c8c8f;margin-bottom:8px}.style__label_input_has_error__3VJOc .style__label_input_label__2SNOY{color:#e74c3c}.style__label_input_error__1w57M{font-size:12px;font-weight:500;line-height:17px;color:#e74c3c;margin-top:4px}", ""]);

// Exports
exports.locals = {
	"label_input": "style__label_input__3BjIh",
	"label_input_label": "style__label_input_label__2SNOY",
	"label_input_has_error": "style__label_input_has_error__3VJOc",
	"label_input_error": "style__label_input_error__1w57M"
};