export class StorageFn<T> {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  public save = (data: T) => {
    localStorage.setItem(this.key, JSON.stringify(data));
  };

  public load = (): T | null => {
    const item = localStorage.getItem(this.key);

    if (item) {
      return JSON.parse(item);
    } else {
      return null;
    }
  };

  public remove = () => {
    localStorage.removeItem(this.key);
  };
}
