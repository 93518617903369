exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__rc-switch__1ilqf{position:relative;display:inline-block;box-sizing:border-box;width:44px;height:22px;line-height:20px;padding:0;vertical-align:middle;border-radius:20px 20px;border:1px solid #ccc;background-color:#ccc;cursor:pointer;transition:all .3s cubic-bezier(0.35, 0, 0.25, 1)}.style__rc-switch__1ilqf [class~=rc-switch-inner]{color:#fff;font-size:12px;position:absolute;left:24px;top:0}.style__rc-switch__1ilqf:after{position:absolute;width:18px;height:18px;left:2px;top:1px;border-radius:50% 50%;background-color:#fff;content:\" \";cursor:pointer;box-shadow:0 2px 5px rgba(0,0,0,.26);transform:scale(1);transition:left .3s cubic-bezier(0.35, 0, 0.25, 1);animation-timing-function:cubic-bezier(0.35, 0, 0.25, 1);animation-duration:.3s;animation-name:style__rcSwitchOff__1cfm6}.style__rc-switch__1ilqf:hover:after{transform:scale(1.1);animation-name:style__rcSwitchOn__38MAn}.style__rc-switch__1ilqf:focus{box-shadow:0 0 0 2px tint(#2db7f5, 80%);outline:none}.style__rc-switch__1ilqf [class~=rc-switch-disabled]{cursor:no-drop;background:#ccc;border-color:#ccc}.style__rc-switch__1ilqf [class~=rc-switch-disabled]:after{background:#9e9e9e;animation-name:style__none__2cqFF;cursor:no-drop}.style__rc-switch__1ilqf [class~=rc-switch-disabled]:hover:after{transform:scale(1);animation-name:style__none__2cqFF}.style__rc-switch__1ilqf [class~=rc-switch-label]{display:inline-block;line-height:20px;font-size:14px;padding-left:10px;vertical-align:middle;white-space:normal;pointer-events:none;user-select:text}[class~=rc-switch-checked]{border:1px solid #87d068;background-color:#87d068}[class~=rc-switch-checked] [class~=rc-switch-inner]{left:6px}[class~=rc-switch-checked]:after{left:22px}@keyframes style__rcSwitchOn__38MAn{0%{transform:scale(1)}50%{transform:scale(1.25)}100%{transform:scale(1.1)}}@keyframes style__rcSwitchOff__1cfm6{0%{transform:scale(1.1)}100%{transform:scale(1)}}", ""]);

// Exports
exports.locals = {
	"rc-switch": "style__rc-switch__1ilqf",
	"rcSwitchOff": "style__rcSwitchOff__1cfm6",
	"rcSwitchOn": "style__rcSwitchOn__38MAn",
	"none": "style__none__2cqFF"
};