import React from "react";
// import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

interface IProps {
  items: Array<{ value: string; onClick: () => void }>;
  style?: React.CSSProperties;
}

interface IState {
  anchorEl: (EventTarget & HTMLElement) | null;
}

export class SimpleMenu extends React.Component<IProps, IState> {
  public state: IState = {
    anchorEl: null,
  };

  public handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  public handleClose = () => {
    this.setState({ anchorEl: null });
  };

  public render() {
    const { anchorEl } = this.state;

    return (
      <div style={{ padding: 0, margin: 0 }}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          style={{ marginTop: "-3rem" }}
        >
          {this.props.items.map((item, idx) => (
            <MenuItem style={{ paddingLeft: "50px", paddingRight: "50px" }} onClick={item.onClick} key={idx}>
              {item.value}
            </MenuItem>
          ))}
        </Menu>
        <div
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ padding: 0, marginTop: "2rem", ...this.props.style }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}