/* eslint-disable react/prop-types */

import React from "react";
import { mask } from "utils";
import { formatDate } from "@manager/utils";
import * as api from "api/manager-api";

import { Modal } from "components/Modal";

import { Container, ReviewCardContainer, Title } from "./styles";

interface AuthorizedDebitModalDetailsProps {
  visible: boolean;
  item: api.GroupChargeFileItem;
  closeModal: () => void;
}
export function AuthorizedDebitModalDetails({ visible, closeModal, item }: AuthorizedDebitModalDetailsProps) {
  function prettierReviewStatus(status: string) {
    if (status === "informative") {
      return "Informativa";
    }

    return "Restritiva";
  }

  function renderReviewTable() {
    if (item.reviews.length > 0) {
      return item.reviews.map(i => (
        <ReviewCardContainer key={i.id} reviewType={i.reviewType as api.FileReviewType}>
          <Title>Data:</Title>
          <p>{i.createdAt.toLocaleDateString("pt-BR", { hour: "2-digit", minute: "2-digit" })}</p>
          <Title>Tipo:</Title>
          <p>{prettierReviewStatus(i.reviewType)}</p>
          <Title>Descrição:</Title>
          <p>{i.message}</p>
        </ReviewCardContainer>
      ));
    }

    return (
      <ReviewCardContainer reviewType="none">
        <p>Ainda não há críticas para este item.</p>
      </ReviewCardContainer>
    );
  }

  return (
    <Modal visible={visible} title="Detalhes" width={600} onClose={closeModal}>
      <Container>
        <Title>Data de criação:</Title>
        <p>{formatDate(item.createdAt)}</p>
        <Title>CPF/CNPJ</Title>
        <p>{mask(item.documentNumber, item.documentNumber.length === 11 ? "###.###.###-##" : "##.###.###/####-##")}</p>
        <Title>Conta:</Title>
        <p>{mask(item.accountNumber, "#########-#")}</p>
        <Title>Valor</Title>
        <p>{(item.amount / 100).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
        <Title>Status:</Title>
        <p>{item.status}</p>
        <Title>Críticas:</Title>
        {renderReviewTable()}
      </Container>
    </Modal>
  );
}
