exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__pagination__1DEpf{display:flex;align-items:center;position:relative}.style__pagination_page_size__B8Wqi{display:flex;align-items:center;font-size:14px;line-height:14px;color:#515251;margin-right:32px}.style__pagination_page_size__B8Wqi>span{margin-right:20px}.style__pagination_page_size__B8Wqi .bbnk-select__control{display:inline-flex;border:none;box-shadow:none}.style__pagination_page_size__B8Wqi .bbnk-select__single-value{position:relative;max-width:none;transform:translateY(0);font-size:14px;line-height:14px;color:#515251}.style__pagination_page_size__B8Wqi .bbnk-select__indicator{padding:0}.style__pagination_page_size__B8Wqi .bbnk-select__menu{width:unset}.style__pagination_page_size__B8Wqi .bbnk-select__menu span>svg{display:none}.style__pagination_page_size__B8Wqi .bbnk-select__menu .bbnk-select__option{display:flex;justify-content:center;padding:6px 8px}.style__pagination_current__32097{font-size:14px;line-height:18px;letter-spacing:.2px;color:#515251}.style__pagination_actions__z52D-{display:flex;align-items:center;margin-left:40px}.style__pagination_actions__z52D->button{display:flex;justify-content:center;align-items:center;width:32px;height:32px;border-radius:100%;border:0;background:transparent;transition:all ease-in-out .3s;outline:none;cursor:pointer}.style__pagination_actions__z52D->button:not(:disabled):hover{background:#f0f0f0}.style__pagination_actions__z52D->button:disabled{cursor:not-allowed}.style__pagination_actions__z52D->button:last-child{margin-left:8px}", ""]);

// Exports
exports.locals = {
	"pagination": "style__pagination__1DEpf",
	"pagination_page_size": "style__pagination_page_size__B8Wqi",
	"pagination_current": "style__pagination_current__32097",
	"pagination_actions": "style__pagination_actions__z52D-"
};