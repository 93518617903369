import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";
import { SvgLeftArrow } from "../Icons/SvgLeftArrow";

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  label: string;
}

export const BackButton: React.SFC<IProps> = ({ label, ...props }) => (
  <BackButtonContainer {...props}>
    <SvgLeftArrow color={colors.gray} size={20} />
    <TextBackButton>{label}</TextBackButton>
  </BackButtonContainer>
);

const BackButtonContainer = styled.a`
  display: flex;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  height: 40px;
  background-color: ${colors.lightGray};
  text-decoration: none;
  justify-content: space-between;
  padding: 10px 23px 10px 16px;
  align-items: center;
  margin-bottom: 40px;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0.6px;
  color: #515251;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    opacity: 0.7;
  }
`;

const TextBackButton = styled.span`
  margin-left: 16px;
  color: ${colors.black};
`;
