import * as React from "react";
import { observer } from "mobx-react";
import { ValueType } from "react-select";
import { InternalCreateAccountPF } from "api/manager-api";

import { InputLabel } from "components/InputLabel";

import { maskValidateCel, maskValidateCpf, validateCpf } from "../../../utils";
import { TextInput } from "../../../components/TextInput";

import { Option } from "..";

import { PartnerStore } from "../../../stores";
import { Select } from "../../../components/Select";
import { Checkbox } from "../../../components/Checkbox";
import { BackButtonWrapper, Box, Info, InputArea, NextButtonWrapper, Row, Text } from "./styles";

type FieldError = "cpf" | "email" | "name" | "cep";

interface IProps {
  onClose: () => void;
  onSubmit: (option: Option, data: InternalCreateAccountPF) => Promise<void>;
  partnerStore?: PartnerStore;
}

interface IPartnerOption {
  value: string | null;
  label: string | null;
}

export const CreatePfReducedDialog = observer(({ onClose, onSubmit, partnerStore }: IProps) => {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [cpf, setCpf] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const [birthday, setBirthday] = React.useState("20/05/1995");
  const [cep, setCep] = React.useState("01000-000");
  const [addressNumber, setAddressNumber] = React.useState("123");
  const [state, setState] = React.useState("SP");
  const [city, setCity] = React.useState("SP");
  const [neighboorhood, setNeighboorhood] = React.useState("Centro");
  const [street, setStreet] = React.useState("Boa Vista");
  const [complement, setComplement] = React.useState("Apto 10");
  const [password, setPassword] = React.useState("232454");

  const [terms, setTerms] = React.useState(true);
  const [contracts, setContracts] = React.useState(true);
  const [cpfValidation, setCpfValidation] = React.useState(true);

  const [partner, setPartner] = React.useState<IPartnerOption>();
  const [openAccount, setOpenAccount] = React.useState(true);
  const [optionsPartners, setOptionsPartners] = React.useState<IPartnerOption[]>();

  function getPartners() {
    partnerStore?.getPartners().then(() => {
      const newPartnersOptions: IPartnerOption[] = [];

      partnerStore?.partners?.forEach(p => {
        newPartnersOptions.push({
          label: p.name,
          value: p.id,
        });
      });
      setOptionsPartners(newPartnersOptions);
    });
  }

  React.useEffect(() => getPartners(), []);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const newErrors: FieldError[] = [];

    if (!validateCpf(cpf)) {
      newErrors.push("cpf");
    }

    if (!email) {
      newErrors.push("email");
    }

    setErrors(newErrors);
    if (newErrors.length > 0) {
      setLoading(false);
      return;
    }

    setLoading(false);
    onClose();
  }

  function handlePartner(option: ValueType<IPartnerOption>) {
    const partnerOption = option as IPartnerOption;

    setPartner(partnerOption);
  }

  function handleOpenAccount() {
    return setOpenAccount(!openAccount);
  }

  return (
    <Box onSubmit={handleSubmit}>
      <Info>
        <InputLabel label="Nome *">
          <TextInput
            placeholder="Fulano de Tal"
            value={name}
            error={Boolean(errors.find(e => e === "name"))}
            onChange={e => setName(e.target.value)}
          />
        </InputLabel>

        <InputLabel label="CPF *">
          <TextInput
            placeholder="123.456.789-11"
            mask={maskValidateCpf}
            value={cpf}
            error={Boolean(errors.find(e => e === "cpf"))}
            onChange={e => setCpf(e.target.value)}
          />
        </InputLabel>

        <InputLabel label="Data de nascimento">
          <TextInput
            placeholder="Data de nascimento"
            value={birthday}
            onChange={e => setBirthday(e.target.value)}
            disabled={true}
          />
        </InputLabel>

        <InputLabel label="Telefone">
          <TextInput
            placeholder="(99) 99999-9999"
            mask={maskValidateCel}
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </InputLabel>

        <InputLabel label="Email *">
          <TextInput
            placeholder="example@gmail.com"
            value={email}
            error={Boolean(errors.find(e => e === "email"))}
            onChange={e => setEmail(e.target.value)}
          />
        </InputLabel>

        <InputLabel label="CEP *">
          <TextInput
            value={cep}
            error={Boolean(errors.find(e => e === "cep"))}
            onChange={e => setCep(e.target.value)}
            disabled={true}
          />
        </InputLabel>

        <InputLabel label="Estado">
          <TextInput value={state} onChange={e => setState(e.target.value)} disabled={true} />
        </InputLabel>

        <InputLabel label="Cidade">
          <TextInput value={city} onChange={e => setCity(e.target.value)} disabled={true} />
        </InputLabel>

        <InputLabel label="Bairro">
          <TextInput value={neighboorhood} onChange={e => setNeighboorhood(e.target.value)} disabled={true} />
        </InputLabel>

        <InputLabel label="Rua">
          <TextInput value={street} onChange={e => setStreet(e.target.value)} disabled={true} />
        </InputLabel>

        <InputLabel label="Número">
          <TextInput value={addressNumber} onChange={e => setAddressNumber(e.target.value)} disabled={true} />
        </InputLabel>

        <InputLabel label="Complemento">
          <TextInput value={complement} onChange={e => setComplement(e.target.value)} disabled={true} />
        </InputLabel>

        <Row>
          <Checkbox checked={terms} handleChange={() => setTerms(!terms)} disabled={true} />
          <Text>Aceite Termos</Text>
        </Row>

        <Row>
          <Checkbox checked={contracts} handleChange={() => setContracts(!contracts)} disabled={true} />
          <Text>Aceite Contratos</Text>
        </Row>

        <Row>
          <Checkbox checked={cpfValidation} handleChange={() => setCpfValidation(!cpfValidation)} />
          <Text>Validar CPF (SERPRO)</Text>
        </Row>

        <InputLabel label="Senha">
          <TextInput value={password} onChange={e => setPassword(e.target.value)} disabled={true} />
        </InputLabel>

        <Select
          isDisabled={loading}
          options={optionsPartners}
          onChange={handlePartner}
          placeholder="Parceiros"
          value={partner}
        />

        <Row>
          <Checkbox checked={openAccount} handleChange={handleOpenAccount} />
          <Text>Abrir conta</Text>
        </Row>
      </Info>
      <InputArea>
        <BackButtonWrapper disabled={loading} type="button" label="Voltar" onClick={onClose} />
        <NextButtonWrapper loading={loading} type="submit" label="Criar" />
      </InputArea>
    </Box>
  );
});
