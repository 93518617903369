import { useState } from "react";

export type Fetch<T> = Promise<T> | null;

interface IProps<T> {
  fetch: Promise<T> | null;
  loading: () => JSX.Element;
  error: (err: Error) => JSX.Element;
  children: (data: T) => JSX.Element;
}

export function Fetchable<T>({
  fetch,
  loading: loadingTemplate,
  error: errorTemplate,
  children: successTemplate,
}: IProps<T>): JSX.Element | null {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  if (!fetch) {
    return null;
  }

  fetch
    .then(_ => setData(_))
    .catch(_ => setError(_))
    .finally(() => setLoading(false));

  if (loading) {
    return loadingTemplate();
  }

  if (error) {
    return errorTemplate(error);
  }

  return successTemplate(data!);
}
