import { action, observable } from "mobx";
import { AdminUserPermission } from "api/manager-api";

import { RootStore } from "./RootStore";

export class GeneralStore {
  protected rootStore: RootStore;

  @observable public msgToast = "";

  @observable public toastIsOpen = false;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  public setOpenToast = (val: boolean) => {
    this.toastIsOpen = val;
  };

  @action
  public setMsgToast = (val: string) => {
    this.msgToast = val;
  };

  @action
  public showToast = (msg: string) => {
    this.setMsgToast(msg);
    this.setOpenToast(true);
  };

  public hasPermission = (permission: AdminUserPermission | AdminUserPermission[]) => {
    const { adminUser } = this.rootStore.authStore;
    const adminPermissions = adminUser ? adminUser.adminUserPermissions : [];
    const permissions = Array.isArray(permission) ? permission : [permission];

    return permissions.some(p => adminPermissions.includes(p));
  };
}
