/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import { colors } from "utils";

import { TextShowWithBureau } from "../../../../components/TextShowWithBureau";
import { TextShow } from "../../../../components/TextShow";

export const Centralizer = styled.div<{ highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
`;

export const Container = styled.div<{ highlight?: boolean; paddingLeft?: string | number }>`
  display: flex;
`;

export const SmallTitle = styled.h4`
  font-size: 14px;
  font-weight: bold;
`;

export const StyledLink = styled.a`
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.black};
  padding-left: 3px;
`;

export const ContainerBureau = styled.div``;

export const RowBureau = styled.div`
  display: flex;
  margin-left: 30px;
  border: solid 1px ${colors.borderGray};
  border-radius: 6px;
  width: 400px;
  height: 48px;
  padding: 20px;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const TitleBureau = styled.span`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray};
  margin-right: 5px;
`;

export const MsgBureau = styled.span`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  color: ${colors.black};
`;

export const Column = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: ${props => props.width || "50%"};

  &:not(:last-child) {
    border-right: 1px solid ${colors.borderGray};
    margin-right: 12px;
  }
`;

export const Title = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
`;

export const SubTitle = styled.h4`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.black};
`;

export const WrapperTextShowWithBureauMainInfo = styled(TextShowWithBureau)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const Block = styled.div`
  display: flex;
  border-left: 3px solid ${colors.lightGray};
  padding-left: 20px;
  /* flex: 1; */
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ContentImage = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1.5;
  max-width: 50%;
  flex-wrap: wrap;
  padding: 5px;
`;

export const RowContentImage = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContentImage = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.black};
  margin-right: 40px;
  margin-bottom: 10px;
  overflow: hidden;
`;

export const ImageComponent = styled.div<{ url: any }>`
  width: 400px;
  height: 400px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Row = styled.div`
  margin-top: 24px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const Text = styled.span<{ color?: string; marginLeft?: string; width?: string }>`
  font-family: Lato;
  font-size: 14px;
  color: ${props => (props.color ? props.color : colors.black)};
  overflow: hidden;
  display: inline-block;
  position: absolute;
  word-wrap: break-word;
  width: ${props => (props.width ? props.width : "240px")};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "0")};
`;

export const Label = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
  padding-right: 20px;
  min-width: 90px;
  display: inline-block;
`;

export const Spacing = styled.div`
  height: 1px;
  margin: 24px 0;
`;

export const Typography = styled.div<{ marginVertical?: string }>`
  font-size: 1rem;
  color: ${colors.gray};
  margin-top: ${props => props.marginVertical || "1rem"};
  margin-bottom: ${props => props.marginVertical || "0"};
`;

export const RepresentativeDataWrapper = styled.div`
  padding: 0 18px 18px 18px;
`;

export const WhiteCell = styled.div`
  width: 20px;
  height: 20px;
`;

export const WrapperTextShowMainInfo = styled(TextShow)`
  &:not(:last-child) {
    margin-top: 24px;
    margin-bottom: 32px;
  }
`;
