import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {}

export const SvgClose = (props: IProps) => {
  const { color, viewBox, width, height, fill, ...restProps } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={11} height={11} viewBox="0 0 11 11" {...restProps}>
      <path
        fill={color || "#C9C9C9"}
        d="M6.295 5.5L11 10.205l-.796.795L5.5 6.295.795 11 0 10.205 4.704 5.5 0 .795.795 0 5.5 4.705 10.204 0 11 .795z"
      />
    </svg>
  );
};
