import React, { useState } from "react";
import classnames from "classnames";

import { ReactComponent as CloseIcon } from "assets/svgs/close.svg";

import s from "./styles.scss";

interface IProps {
  src: string;
  alt?: string;
  className?: string;
}

export function ImageZoom({ src, alt, className }: IProps) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <figure className={classnames(s.figure, className)} onClick={() => setShowModal(true)}>
        <img src={src} alt={alt} />
      </figure>
      {showModal && (
        <div className={s.modal} onClick={() => setShowModal(false)} data-testid="imageModal">
          <div className={s.closeIcon} data-testid="closeButton">
            <CloseIcon />
          </div>
          <img src={src} alt={alt} />
        </div>
      )}
    </>
  );
}
