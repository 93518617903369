import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { AdminUserPermission, LimitsDefaultDTO, PfPj } from "api/manager-api";
import { InnerAlert } from "legacy/components";
import { SvgError } from "legacy/icons/SvgError";
import { SvgArrowRepeat } from "legacy/icons/SvgArrowRepeat";

import {
  CancelButton,
  ClearButton,
  Container,
  ContainerInput,
  ErrorMessage,
  FilterContainer,
  FilterTitle,
  FilterWrapper,
  Info,
  Input,
  Left,
  PartnerSelect,
  PersonSelect,
  Right,
  SaveButton,
  SearchButton,
  StyledTextArea,
  Text,
  TextConfirm,
  Title,
  WrapperSwitch,
} from "./style";
import { Loader } from "../../../../../../legacy/components/Loader/index";
import { Table } from "../../../../components/Table";
import { currencyFormatter } from "../../../../../../utils";
import { Dialog } from "../../../../components/Dialog";
import { AlertModal } from "../Segments/style";
import { SvgConfirm } from "../../../../../../legacy/icons/SvgConfirm";
import { Switch } from "../../../../components/Switch";
import {
  GeneralStore,
  IPartnerIdLimit,
  IPersonTypes,
  PartnerStore,
  TransactionalBoundariesStore,
} from "../../../../stores";
import { FooterTable } from "../../SafetyLimits/SafetyLimitsConfiguration/style";

interface IProps {
  transactionalBoundariesStore?: TransactionalBoundariesStore;
  generalStore?: GeneralStore;
  partnerStore?: PartnerStore;
}

@inject("transactionalBoundariesStore", "generalStore", "partnerStore")
@observer
export class DefaultLimit extends React.Component<IProps> {
  private MAXAMOUNT = 999999999;

  @observable private loading = false;

  @observable private error = "";

  @observable private saveDialogOpen = false;

  @observable private successOpen = false;

  @observable private errorOpen = false;

  @observable private filter: PfPj = PfPj.pf;

  @observable private notEdited = true;

  @observable private comments = "";

  @observable private errorMessage = "";

  @observable private loadingSave = false;

  @observable private partners: IPartnerIdLimit[] = [];

  @observable private selectedPartner: string | null = null;

  private personTypes: IPersonTypes[] = [
    {
      label: "PF - Pessoa Física",
      value: PfPj.pf,
    },
    {
      label: "PJ - Pessoa Jurídica",
      value: PfPj.pj,
    },
  ];

  public componentDidMount() {
    this.getData();
    this.getPartners();
  }

  @action
  private getData = async () => {
    try {
      this.loading = true;
      await this.props.transactionalBoundariesStore!.getLimitsDefault(this.selectedPartner, this.filter);
      this.error = "";
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter os serviços.";
    } finally {
      this.loading = false;
    }
  };

  @action
  private getPartners = async () => {
    try {
      this.loading = true;
      await this.props.partnerStore!.getPartners();
      this.partners = this.props.partnerStore!.partners!.map(partner => ({ label: partner.name, value: partner.id }));
      this.error = "";
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter os serviços.";
    } finally {
      this.loading = false;
    }
  };

  private saveLimitDefault = () => {
    const { limitsDefault } = this.props.transactionalBoundariesStore!;

    this.loadingSave = true;
    const limitsDefaultDTO: LimitsDefaultDTO = {
      accountType: this.filter,
      observation: this.comments,
      transactionRequestDto: limitsDefault,
    };

    this.props
      .transactionalBoundariesStore!.saveLimitDefault(this.selectedPartner, limitsDefaultDTO)
      .then(() => {
        this.successOpen = true;
      })
      .catch(() => {
        this.errorOpen = true;
      })
      .finally(() => {
        this.saveDialogOpen = false;
        this.loadingSave = false;
        this.comments = "";
      });
  };

  private inputLimitValue = (value: string) => {
    return currencyFormatter(value);
  };

  private renderData = () => {
    const { limitsDefault, getLimitsDefault } = this.props.transactionalBoundariesStore!;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return (
        <InnerAlert
          message={this.error}
          buttonLabel="Tentar novamente"
          buttonClick={async () => getLimitsDefault(this.selectedPartner, this.filter)}
        />
      );
    }

    const columns = ["Transação", "Limite por transação*", "Limite diário*", "Quantidade por dia"];

    return (
      <Table
        titles={columns}
        ids={limitsDefault.map(e => e.title)}
        data={limitsDefault.map(limit => {
          let operationFormat: string = limit.operation!.toString();
          let dailyFormat: string = limit.daily!.toString();
          const data: any = [
            limit.title,
            <Input
              key={limit.title}
              value={this.inputLimitValue(operationFormat)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.notEdited = false;
                if (event.target.value === "") {
                  limit.operation = 0;
                } else {
                  operationFormat = event.target.value;
                  limit.operation = parseFloat(operationFormat.replace(/,/gu, "").replace(/\./gu, ""));
                }

                if (limit.operation > limit.daily!) {
                  this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                } else {
                  this.errorMessage = "";
                }
              }}
            />,
            <Input
              key={limit.title}
              value={this.inputLimitValue(dailyFormat)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                this.notEdited = false;
                if (event.target.value === "") {
                  limit.daily = 0;
                } else {
                  dailyFormat = event.target.value;
                  limit.daily = parseFloat(dailyFormat.replace(/,/gu, "").replace(/\./gu, ""));
                }

                if (limit.operation! > limit.daily) {
                  this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                } else {
                  this.errorMessage = "";
                }
              }}
            />,
            <ContainerInput key={limit.title}>
              <Input
                disabled={limit.amountPerDay === this.MAXAMOUNT}
                value={limit.amountPerDay === this.MAXAMOUNT ? " " : limit.amountPerDay}
                placeholder="Indicar a quantidade"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.notEdited = false;
                  if (event.target.value === "") {
                    limit.amountPerDay = 0;
                  } else {
                    limit.amountPerDay = Number(event.target.value);
                  }
                }}
              />
              <WrapperSwitch>
                <Switch
                  checked={limit.amountPerDay === this.MAXAMOUNT}
                  handleChange={() => {
                    this.notEdited = false;
                    limit.amountPerDay = limit.amountPerDay === this.MAXAMOUNT ? 0 : this.MAXAMOUNT;
                  }}
                />
                Ilimitado
              </WrapperSwitch>
            </ContainerInput>,
          ];

          return data;
        })}
      />
    );
  };

  public render() {
    const { hasPermission } = this.props.generalStore!;

    return (
      <>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.successOpen}
          onClose={() => (this.successOpen = false)}
        >
          <AlertModal>
            <SvgConfirm
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <TextConfirm>
              {`Limite padrão '${this.personTypes.filter(type => type.value === this.filter)[0].label}${
                this.selectedPartner
                  ? ` - ${this.partners.filter(partner => partner.value === this.selectedPartner)[0].label}`
                  : ""
              }' salvo com sucesso!`}
            </TextConfirm>
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal>
            <SvgError
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <TextConfirm>Erro ao salvar o limite padrão</TextConfirm>
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"md"}
          title={`Salvar alterações${
            this.selectedPartner
              ? ` do ${this.partners.filter(partner => partner.value === this.selectedPartner)[0].label}`
              : ""
          }?`}
          isOpen={this.saveDialogOpen}
          onClose={() => (this.saveDialogOpen = false)}
        >
          <Text>Comentário (Não obrigatório)</Text>
          <StyledTextArea value={this.comments} onChange={e => (this.comments = e.target.value)} />
          <Right>
            <CancelButton onClick={() => (this.saveDialogOpen = false)} label="Cancelar" type="submit" />
            <SaveButton
              onClick={() => this.saveLimitDefault()}
              label="Salvar"
              type="submit"
              loading={this.loadingSave}
            />
          </Right>
        </Dialog>
        <FilterWrapper>
          <Title>Filtros de Pesquisa</Title>
          <Left>
            <FilterContainer>
              <FilterTitle>Parceiros</FilterTitle>
              <PartnerSelect
                isSearchable
                value={this.partners.filter(partner => partner.value === this.selectedPartner)}
                options={[{ label: "Todos os parceiros", value: null }, ...this.partners]}
                onChange={(e: IPartnerIdLimit) => {
                  this.selectedPartner = e.value;
                }}
                placeholder="Todos os parceiros"
              />
            </FilterContainer>
            <FilterContainer>
              <FilterTitle>Tipo de Pessoa</FilterTitle>
              <PersonSelect
                value={this.personTypes.filter(type => type.value === this.filter)}
                options={this.personTypes}
                onChange={(e: IPersonTypes) => (this.filter = e.value)}
              />
            </FilterContainer>
            <ClearButton
              onClick={() => {
                this.selectedPartner = "";
                this.filter = PfPj.pf;
              }}
              label="Limpar Filtros"
              type="submit"
              iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
            />
            <SearchButton onClick={async () => this.getData()} label="Buscar" type="submit" />
          </Left>
        </FilterWrapper>
        <Container>{this.renderData()}</Container>
        <FooterTable>
          {this.errorMessage && (
            <ErrorMessage>
              <SvgError
                viewBox="0 0 82 82"
                width="21"
                height="auto"
                style={{ margin: "0 8px -8px 0", position: "relative" }}
              />{" "}
              {this.errorMessage}
            </ErrorMessage>
          )}
          <Right>
            <Info>*Campos obrigatórios</Info>
            <SaveButton
              onClick={() => {
                if (!this.errorMessage && !this.notEdited) {
                  this.saveDialogOpen = true;
                }
              }}
              label="Salvar"
              type="button"
              disabled={
                Boolean(this.errorMessage) || this.notEdited || !hasPermission(AdminUserPermission.editDefaultLimit)
              }
            />
          </Right>
        </FooterTable>
      </>
    );
  }
}
