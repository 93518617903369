import * as React from "react";
import { createMemoryHistory, MemoryHistory } from "history";
import { render as testingLibraryRender } from "@testing-library/react";
import { Provider } from "mobx-react";
import { Router } from "react-router";

export const render = (
  ui: React.ReactElement,
  {
    route = "/",
    history = createMemoryHistory({ initialEntries: [route] }),
  }: { route?: string; history?: MemoryHistory<any> } = {},
) => {
  return {
    ...testingLibraryRender(
      <Provider>
        <Router history={history}>{ui}</Router>
      </Provider>,
    ),
    history,
  };
};
