import React, { useEffect, useState } from "react";
import { AdminUserPermission } from "api/manager-api";
import { Scrollbars } from "react-custom-scrollbars";
import { useStores } from "@manager/stores/RootStore";
import { observer } from "mobx-react";
import {
  accountsManagerPath,
  activeAccountsReports,
  auditManagerPath,
  authorizedDebitPath,
  cardsmanagerPath,
  conciliationManagerSectionPath,
  employeesManagerPath,
  errorsManagerPath,
  extract,
  generalParameters,
  historyChange,
  internalAPIsPath,
  limitCustomization,
  loginPath,
  notificationManagerPath,
  openingAccountsReports,
  openingAccountsSectionPath,
  partnerManagementPath,
  platformManagerPath,
  proofOfLifePath,
  RegistrationPathPj,
  safetyLimits,
} from "@manager/utils";
import { Badge } from "@manager/components/Badge";
import { SvgLogoDrawer } from "@manager/assets/img/SvgLogoDrawer";
import { SimpleMenu } from "@manager/components/SimpleMenu";
import { SvgConfig } from "@manager/assets/icons/SvgConfig";
import { SvgExit } from "@manager/assets/icons/SvgExit";
import { Dialog } from "@manager/components/Dialog";
import { ChangePassword } from "@manager/containers/ChangePassword";
import { colors } from "utils";

import { MenuCollapse } from "./MenuCollapse";
import { Container, Footer, Hat, Logo, LogoutBnt, Menu, NavLink } from "./styled";

interface IMenuLink {
  to: string;
  children: React.ReactNode;
  count?: number;
  isNestedLink?: boolean;
}

export const Navbar = observer(() => {
  const [dialogChangePassword, setDialogChangePassword] = useState(false);
  const { authStore, routerStore, openingAccountsStore, generalStore } = useStores();
  const { hasPermission } = generalStore;

  useEffect(() => {
    if (
      generalStore.hasPermission([
        AdminUserPermission.viewOpeningAccountPF,
        AdminUserPermission.viewOpeningAccountPJ,
        AdminUserPermission.viewOpeningAccountPJMEI,
      ])
    ) {
      openingAccountsStore.getBadgeData();
    }
  }, []);

  function onLogout() {
    authStore.onLogout();
    routerStore.push(loginPath);
  }

  function countAccountManager(): { [key: string]: number } {
    return openingAccountsStore.countAccountManager.reduce(
      (acc, cur) => ({ ...acc, [cur.status]: Number(cur.count) }),
      {},
    );
  }

  return (
    <>
      <Dialog
        showCloseButton
        title="Alterar senha"
        isOpen={dialogChangePassword}
        onClose={() => setDialogChangePassword(false)}
      >
        <ChangePassword requestCloseDialog={() => setDialogChangePassword(false)} />
      </Dialog>
      <Container>
        <Logo>
          <Hat>Dashboard</Hat>
          <SvgLogoDrawer width="166px" height="67px" />
        </Logo>
        <Scrollbars
          style={{ width: 240 }}
          autoHide
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: colors.white,
                borderRadius: "4px",
                cursor: "pointer",
                opacity: "0.2",
                width: "8px",
                zIndex: "1001",
              }}
            />
          )}
        >
          <Menu>
            {hasPermission([AdminUserPermission.viewUser, AdminUserPermission.viewCompany]) && (
              <MenuLink to={accountsManagerPath}>Gestão de contas</MenuLink>
            )}

            {hasPermission([
              AdminUserPermission.viewOpeningAccountPF,
              AdminUserPermission.viewOpeningAccountPJ,
              AdminUserPermission.viewOpeningAccountPJMEI,
            ]) && (
              <MenuCollapse title="Abertura de contas">
                {hasPermission(AdminUserPermission.filterUserAndReport) && (
                  <MenuLink to={openingAccountsReports} count={undefined} isNestedLink>
                    Relatórios
                  </MenuLink>
                )}

                {hasPermission(AdminUserPermission.viewProofOfLife) && (
                  <MenuLink to={proofOfLifePath} isNestedLink>
                    Processamento de Prova de Vida
                  </MenuLink>
                )}

                <MenuLink to={openingAccountsSectionPath("all")} count={undefined} isNestedLink>
                  Todas as contas
                </MenuLink>
                {hasPermission(AdminUserPermission.viewTipping) && (
                  <MenuLink to={openingAccountsSectionPath("tipping")} count={undefined} isNestedLink>
                    Tombamento
                  </MenuLink>
                )}
                <MenuLink to={openingAccountsSectionPath("processing")} count={undefined} isNestedLink>
                  Processamento
                </MenuLink>
                <MenuLink
                  to={openingAccountsSectionPath("workflow")}
                  count={countAccountManager().finished || undefined}
                  isNestedLink
                >
                  Workflow
                </MenuLink>
                {hasPermission(AdminUserPermission.viewPositivize) && (
                  <MenuLink to={openingAccountsSectionPath("positivize")} count={undefined} isNestedLink>
                    Promoção
                  </MenuLink>
                )}
                <MenuLink to={openingAccountsSectionPath("approvedPending")} isNestedLink>
                  Contas simples
                </MenuLink>
                <MenuLink to={openingAccountsSectionPath("approved")} count={undefined} isNestedLink>
                  Aprovadas
                </MenuLink>
                <MenuLink to={openingAccountsSectionPath("canceled")} count={undefined} isNestedLink>
                  Canceladas
                </MenuLink>
                <MenuLink
                  to={openingAccountsSectionPath("reproved")}
                  count={countAccountManager().reproved || undefined}
                  isNestedLink
                >
                  Reprovadas
                </MenuLink>
                <MenuLink to={openingAccountsSectionPath("pending")} count={undefined} isNestedLink>
                  Pendentes
                </MenuLink>
                <MenuLink to={openingAccountsSectionPath("notFinished")} count={undefined} isNestedLink>
                  Não finalizadas
                </MenuLink>
              </MenuCollapse>
            )}

            {hasPermission(AdminUserPermission.viewPartner) && (
              <MenuLink to={partnerManagementPath}>Gestão de parceiros</MenuLink>
            )}

            {hasPermission(AdminUserPermission.viewBatchChargeDebit) && (
              <MenuLink to={authorizedDebitPath}>Débito autorizado</MenuLink>
            )}

            {hasPermission(AdminUserPermission.viewAdminUser) && (
              <MenuLink to={employeesManagerPath}>Gestão de funcionários</MenuLink>
            )}

            {hasPermission(AdminUserPermission.viewPlatformManager) && (
              <MenuLink to={platformManagerPath}>Gestão da plataforma</MenuLink>
            )}
            {hasPermission([
              AdminUserPermission.viewGeneralParameters,
              AdminUserPermission.viewCustomLimits,
              AdminUserPermission.viewLogLimits,
              AdminUserPermission.viewSafetyLimit,
            ]) && (
              <MenuCollapse title="Limites Transacionais">
                {hasPermission(AdminUserPermission.viewGeneralParameters) && (
                  <MenuLink to={generalParameters} count={undefined} isNestedLink>
                    Parâmetros Gerais
                  </MenuLink>
                )}
                {hasPermission(AdminUserPermission.viewCustomLimits) && (
                  <MenuLink to={limitCustomization} count={undefined} isNestedLink>
                    Personalização de Limites
                  </MenuLink>
                )}
                {hasPermission(AdminUserPermission.viewLogLimits) && (
                  <MenuLink to={historyChange} count={undefined} isNestedLink>
                    Histórico de Alterações
                  </MenuLink>
                )}
                {hasPermission(AdminUserPermission.viewSafetyLimit) && (
                  <MenuLink to={safetyLimits} count={undefined} isNestedLink>
                    Limite de Segurança
                  </MenuLink>
                )}
              </MenuCollapse>
            )}
            {hasPermission(AdminUserPermission.viewErrors) && (
              <MenuLink to={errorsManagerPath}>Gestão de erros</MenuLink>
            )}

            {hasPermission(AdminUserPermission.visaCardManager) && (
              <MenuLink to={cardsmanagerPath}>Gestão das solicitações de cartão</MenuLink>
            )}

            {hasPermission([
              AdminUserPermission.viewOpeningAccountPF,
              AdminUserPermission.viewOpeningAccountPJ,
              AdminUserPermission.viewOpeningAccountPJMEI,
            ]) && (
              <MenuCollapse title="Relatórios">
                {hasPermission(AdminUserPermission.filterUserAndReportByPartner) && (
                  <MenuLink to={activeAccountsReports} count={undefined} isNestedLink>
                    Contas Ativas
                  </MenuLink>
                )}
                {hasPermission(AdminUserPermission.viewAccountBalanceAndStatement) && (
                  <MenuLink to={extract} count={undefined} isNestedLink>
                    Extrato
                  </MenuLink>
                )}
              </MenuCollapse>
            )}

            {hasPermission(AdminUserPermission.viewAuditAdmin) && <MenuLink to={auditManagerPath}>Auditoria</MenuLink>}
            {(hasPermission(AdminUserPermission.viewConciliationAgreements) ||
              hasPermission(AdminUserPermission.createTedHugpay) ||
              hasPermission(AdminUserPermission.viewConciliationWithdraws)) && (
              <MenuCollapse title="Conciliação">
                {hasPermission(AdminUserPermission.viewConciliationAgreements) && (
                  <MenuLink to={conciliationManagerSectionPath("convenant")} isNestedLink>
                    IS2B - Convênio
                  </MenuLink>
                )}
                {(hasPermission(AdminUserPermission.createTedHugpay) ||
                  hasPermission(AdminUserPermission.viewConciliationWithdraws)) && (
                  <MenuLink to={conciliationManagerSectionPath("withdrawal-lottery")} isNestedLink>
                    Saque lotérica
                  </MenuLink>
                )}
                {hasPermission(AdminUserPermission.viewCardConciliation) && (
                  <MenuLink to={conciliationManagerSectionPath("cards")} isNestedLink>
                    Cartões
                  </MenuLink>
                )}
              </MenuCollapse>
            )}

            {hasPermission(AdminUserPermission.sendNotification) && (
              <MenuLink to={notificationManagerPath}>Notificações APP</MenuLink>
            )}

            {hasPermission(AdminUserPermission.insertManualPJAccount) && (
              <MenuLink to={RegistrationPathPj}>Abertura de Conta PJ Manual</MenuLink>
            )}

            {process.env.BRANCH &&
              (process.env.BRANCH.startsWith("hml") ||
                process.env.BRANCH.startsWith("dev") ||
                process.env.BRANCH === "homolog") && (
                <MenuLink to={internalAPIsPath}>Ferramentas de homologação</MenuLink>
              )}
          </Menu>
        </Scrollbars>
        <Footer>
          <SimpleMenu
            style={{ marginBottom: "24px" }}
            items={[{ onClick: () => setDialogChangePassword(true), value: "Alterar senha" }]}
          >
            <LogoutBnt>
              <SvgConfig style={{ marginRight: 5 }} width={20} height={20} />
              Preferências
            </LogoutBnt>
          </SimpleMenu>
          <LogoutBnt style={{ marginTop: 10 }} onClick={onLogout}>
            <SvgExit style={{ marginRight: 5 }} width={20} height={20} />
            Sair
          </LogoutBnt>
        </Footer>
      </Container>
    </>
  );
});

function MenuLink(props: IMenuLink) {
  return (
    <NavLink isNestedLink={props.isNestedLink} to={props.to}>
      <div>
        <span>{props.children}</span>
        {props.count && <Badge value={props.count} />}
      </div>
    </NavLink>
  );
}
