import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

interface IProps {
  title: string;
  content: Array<{ data: string | null; source: string }> | null;
  className?: string;
}

const Container = styled.div`
  display: flex;
  padding-right: 50px;
`;

const Block = styled.div``;

const Row = styled.div`
  display: flex;
  margin-top: 12px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const Title = styled.span`
  max-width: 180px;
  min-width: 135px;
  flex: 1;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
`;

const SubTitle = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
  padding-right: 20px;
  width: 90px;
  display: inline-block;
`;

const Text = styled.span<{ set?: boolean }>`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  font-style: ${({ set }) => (set ? "inherit" : "italic")};
  overflow: hidden;
  display: inline-block;
  word-wrap: break-word;
  width: 240px;
`;

export class TextShowWithBureau extends React.Component<IProps> {
  public render() {
    return (
      <Container className={this.props.className}>
        <Title>{this.props.title}</Title>
        <Block>
          {this.props.content && this.props.content.length > 0 ? (
            this.props.content.map((e, idx) => (
              <Row key={idx}>
                <SubTitle>{e.source}</SubTitle>
                <Text set={!!e.data}>{e.data || "Não informado"}</Text>
              </Row>
            ))
          ) : (
            <Text>Não informado</Text>
          )}
        </Block>
      </Container>
    );
  }
}
