import * as React from "react";
import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";

import { OutlinedButton } from "../../../components/OutlinedButton";
import { Dialog } from "../../../components/Dialog";

interface IProps {
  blocked: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export function EmployeesBlockDialog({ blocked, loading, onClose, onSubmit }: IProps) {
  return (
    <Dialog
      title={`Deseja realmente ${blocked ? "desbloquear" : "bloquear"} este funcionário?`}
      isOpen
      onClose={onClose}
      padding={"0 20px 20px"}
    >
      <InputArea>
        <BackButtonWrapper label="Cancelar" onClick={onClose} />
        <NextButtonWrapper loading={loading} label={blocked ? "Desbloquear" : "Bloquear"} onClick={onSubmit} />
      </InputArea>
    </Dialog>
  );
}

const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

const NextButtonWrapper = styled(RaisedButton)`
  min-width: 145px;
  width: fit-content;
  font-family: Lato;
  margin: 15px 0 15px 15px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;
