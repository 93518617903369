import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { SwitchCard } from "@manager/components/SwitchCard";
import { FeatureSlug } from "api/manager-api";

import { InputLabel } from "components/InputLabel";
import { Input } from "components/Input";
import { Button } from "components/Button";

import { IFields } from "./";

import s from "./style.scss";

interface IProps {
  values: IFields;
  errors: FormikErrors<IFields>;
  touched: FormikTouched<IFields>;
  setValues: (values: IFields) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  disabled?: boolean;
  openAccountsAttempts?: number;
}

export function FormSettings({ disabled, errors, touched, values, setFieldValue, openAccountsAttempts }: IProps) {
  const refInputFile = React.useRef<HTMLInputElement>(null);

  function changeFeatureInput(featureId: string) {
    const features = values.featuresFlag.map(feature => {
      const isEnabled = feature.status === "ENABLED" || (feature.status === "DEFAULT" && feature.isEnabledInPlatform);

      if (feature.id === featureId) {
        return {
          ...feature,
          status: isEnabled ? "DISABLED" : "ENABLED",
        };
      }

      return feature;
    });

    setFieldValue("featuresFlag", features);
  }

  function handleChangeImage(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.addEventListener(
      "loadend",
      () => {
        if (!(reader.result && typeof reader.result === "string")) {
          return;
        }

        const MAX_WIDTH = 300;
        const MAX_HEIGHT = 300;
        const image = new Image();

        image.src = reader.result;

        image.onload = () => {
          let { width, height } = image;

          if (width / MAX_WIDTH > height / MAX_HEIGHT && width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          } else if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }

          const canvas = document.createElement("canvas");

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");

          ctx!.drawImage(image, 0, 0, width, height);

          const dataURL = canvas.toDataURL(file.type);
          const imageBuffer = Buffer.from(dataURL.split(",")[1], "base64");

          setFieldValue("customButton.buffer", imageBuffer);
          setFieldValue("customButton.image", dataURL);
        };
      },
      false,
    );

    reader.readAsDataURL(file);
  }

  return (
    <div className={s.formWrapper}>
      <div className={s.title}>Configurações gerais</div>

      <div className={s.formGroup}>
        <SwitchCard
          checked={!values.allowAnyPartnerLogin}
          title="Permite login somente de meus usuários"
          description="Usuários vinculados a outros parceiros não poderão efetuar o login."
          onChange={value => setFieldValue("allowAnyPartnerLogin", !value)}
          disabled={disabled}
        />

        <div style={{ width: "100%" }}></div>
      </div>

      <div className={s.formGroup}>
        <SwitchCard
          checked={values.allowPartnerLot}
          title="Permite jornada reduzida com pré-cadastro"
          description="Utilização de cadastros pré-aprovados pelo parceiro."
          onChange={value => setFieldValue("allowPartnerLot", value)}
          disabled={disabled}
        />

        <div style={{ width: "100%" }}>
          <InputLabel label="ID de gerente - jornada reduzida com pré-cadastro" error={errors.managerIdLot}>
            <Input
              value={values.managerIdLot || ""}
              onChange={event => setFieldValue("managerIdLot", event.target.value.replace(/\D/gu, ""))}
              error={touched.allowPartnerLot && Boolean(errors.allowPartnerLot)}
              disabled={disabled}
            />
          </InputLabel>
        </div>
      </div>
      <div className={s.formGroup}>
        <SwitchCard
          checked={values.allowReduceJourneyWithoutLot}
          title="Permite jornada reduzida sem pré-cadastro"
          description="Abertura de conta ocorrerá solicitando o mínimo de dados."
          onChange={value => setFieldValue("allowReduceJourneyWithoutLot", value)}
          disabled={disabled}
        />

        <div style={{ width: "100%" }}>
          <InputLabel label="ID de gerente - jornada reduzida sem pré-cadastro" error={errors.managerIdWithoutLot}>
            <Input
              value={values.managerIdWithoutLot || ""}
              onChange={event => setFieldValue("managerIdWithoutLot", event.target.value.replace(/\D/gu, ""))}
              error={touched.managerIdWithoutLot && Boolean(errors.managerIdWithoutLot)}
              disabled={disabled}
            />
          </InputLabel>
        </div>
      </div>

      <div className={s.formGroup}>
        <InputLabel label="Limite de abertura de contas por dia" error={errors.accountOpeningAttemptLimit}>
          <Input
            value={values.accountOpeningAttemptLimit}
            onChange={event => setFieldValue("accountOpeningAttemptLimit", event.target.value.replace(/\D/gu, ""))}
            error={touched.accountOpeningAttemptLimit && Boolean(errors.accountOpeningAttemptLimit)}
            disabled={disabled}
          />
          {openAccountsAttempts !== undefined && (
            <span className={s.spanInfo}>Tentativas únicas hoje: {openAccountsAttempts}</span>
          )}
        </InputLabel>

        <InputLabel label="Reenvio de emails de pendenciamento (dias)" error={errors.emailResendTimeFrequency}>
          <Input
            value={values.emailResendTimeFrequency}
            onChange={event => setFieldValue("emailResendTimeFrequency", event.target.value.replace(/\D/gu, ""))}
            error={touched.emailResendTimeFrequency && Boolean(errors.emailResendTimeFrequency)}
            disabled={disabled}
          />
        </InputLabel>

        <InputLabel label="Limite de reenvio de emails (dias)" error={errors.emailResendTime}>
          <Input
            value={values.emailResendTime}
            onChange={event => setFieldValue("emailResendTime", event.target.value.replace(/\D/gu, ""))}
            error={touched.emailResendTime && Boolean(errors.emailResendTime)}
            disabled={disabled}
          />
        </InputLabel>
      </div>

      <div className={s.title}>Configurações de cartões</div>
      <div className={s.formGroupFeatures}>
        <SwitchCard
          checked={values.showCardModule}
          title={"Exibir módulo de cartões"}
          description={"Exibir opção de cartões nos aplicativos para acesso do usuário."}
          onChange={() => setFieldValue("showCardModule", !values.showCardModule)}
          disabled={disabled}
        />
      </div>

      {values.featuresFlag.length && (
        <>
          <div className={s.title}>Configurações de serviços</div>
          <div className={s.formGroupFeatures}>
            {values.featuresFlag.map(feature => (
              <SwitchCard
                checked={
                  feature.status === "ENABLED" ||
                  (feature.status === "DEFAULT" && feature.isEnabledInPlatform && feature.slug !== FeatureSlug.NEWS)
                }
                title={feature.title}
                description={feature.description}
                onChange={() => changeFeatureInput(feature.id)}
                disabled={disabled}
                key={feature.id}
              />
            ))}
          </div>
          <div className={s.formGroupFeatures}>
            <SwitchCard
              checked={values.showWiz}
              title={"Exibir Seguros"}
              description={"Exibe o módulo de seguro para os clientes"}
              onChange={() => setFieldValue("showWiz", !values.showWiz)}
              disabled={disabled}
            />
          </div>
        </>
      )}

      <div className={s.title}>Link Externo</div>
      <div className={s.formGroupFeatures}>
        <SwitchCard
          checked={values.customButton ? Boolean(values.customButton.show) : false}
          title={"Permite link externo"}
          description={"Parceiro pode criar botão personalizado."}
          onChange={() => setFieldValue("customButton.show", !values.customButton?.show)}
          disabled={disabled}
        />
      </div>
      <div className={s.formGroupFeatures}>
        <InputLabel label="Título do botão" error={errors.customButton}>
          <Input
            value={values.customButton && values.customButton.text ? values.customButton.text : ""}
            onChange={event => setFieldValue("customButton.text", event.target.value)}
            error={touched.customButton && Boolean(errors.customButton)}
            disabled={disabled}
          />
        </InputLabel>
        <InputLabel label="Link Externo" error={errors.customButton}>
          <Input
            value={values.customButton && values.customButton.url ? values.customButton.url : ""}
            onChange={event => setFieldValue("customButton.url", event.target.value)}
            error={touched.customButton && Boolean(errors.customButton)}
            disabled={disabled}
          />
        </InputLabel>
      </div>
      <div className={s.formGroupFeatures}>
        <InputLabel label="Upload de Ícone (formato .svg)" error={errors.customButton}>
          <div className={s.uploadContent}>
            <input
              onChange={handleChangeImage}
              className={s.inputFile}
              disabled={disabled}
              type="file"
              accept=".svg"
              ref={refInputFile}
              multiple
            />
            <Button type="button" disabled={disabled} onClick={() => refInputFile.current?.click()}>
              Upload
            </Button>
          </div>
        </InputLabel>
      </div>
      <div className={s.formGroupFeatures}>{values.customButton?.image && <img src={values.customButton.image} />}</div>
      <div style={{ padding: 5 }}></div>
      <div className={s.title}>Contato personalizado</div>
      <div className={s.formGroupFeatures}>
        <SwitchCard
          checked={values.customContact ? Boolean(values.customContact.show) : false}
          title={"Contato Personalizado"}
          description={"Parceiro pode incluir contatos personalizados."}
          onChange={() => setFieldValue("customContact.show", !values.customContact?.show)}
          disabled={disabled}
        />
      </div>
      <div className={s.formGroupFeatures}>
        <InputLabel label="Email" error={errors.customContact}>
          <Input
            value={values.customContact && values.customContact.email ? values.customContact.email : ""}
            onChange={event => setFieldValue("customContact.email", event.target.value)}
            error={touched.customContact && Boolean(errors.customContact)}
            disabled={disabled}
          />
        </InputLabel>
        <InputLabel label="Telefone" error={errors.customContact}>
          <Input
            value={values.customContact && values.customContact.phone ? values.customContact.phone : ""}
            onChange={event => setFieldValue("customContact.phone", event.target.value)}
            error={touched.customContact && Boolean(errors.customContact)}
            disabled={disabled}
          />
        </InputLabel>
      </div>
    </div>
  );
}
