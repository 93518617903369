/* eslint-disable sort-imports */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useState } from "react";
import { authorizedDebitPath, formatDate } from "@manager/utils";
import { useStores } from "@manager/stores/RootStore";
import { Loader } from "legacy/components/Loader";
import * as api from "api/manager-api";
import { mask } from "utils";
import { SvgSpreadsheet } from "legacy/icons/SvgSpreadsheet";
import save from "save-file";

import { DownloadButton } from "./style";
import { Table } from "../../../components/Table";
import { ReactComponent as ArrowIcon } from "../../../../../assets/svgs/arrow-back.svg";
import { Title, TitleContainer, Header } from "../../../assets/styles/global";
import { Button } from "../../../../../components/Button";
import { AuthorizedDebitModalDetails } from "./ModalDetails";

interface IControlTable {
  offset: number;
  limit: number;
  page: number;
}

export const initialStateTable: IControlTable = {
  offset: 0,
  limit: 10,
  page: 0,
};

export function AuthorizedDebitDetails({ match }: any) {
  const { routerStore, generalStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<api.GroupChargeFileItem[]>([]);
  const [currentItem, setCurrentItem] = useState<api.GroupChargeFileItem | null>();
  const [showModal, setShowModal] = useState(false);
  const [controlTable, setControlTable] = useState<IControlTable>(initialStateTable);

  useEffect(() => {
    getItems();
  }, []);

  async function getItems() {
    setLoading(true);
    const fileId = match.params.id;

    const response = await api.getGroupChargeFileItemByFileId(fileId);

    setResults(response);

    setLoading(false);

    console.log(fileId);
  }

  function renderData() {
    if (loading) {
      return <Loader />;
    }

    return getTable();
  }

  function prettierStatus(status: string) {
    switch (status) {
      case "processing":
        return "Em processamento";
      case "valid":
        return "Válido";
      case "invalid":
        return "Inválido";
      case "created":
        return "Criado";
      case "finished":
        return "Finalizado";
      case "witherror":
        return "Com erros";
      default:
        return "Status desconhecido";
    }
  }

  function handleChangePage(page: number) {
    setControlTable({ ...controlTable, offset: controlTable.limit * page, page });
  }

  function renderTableData() {
    return results.map((item, index) => {
      if (index >= controlTable.offset && index < controlTable.limit * (controlTable.page + 1)) {
        return [
          formatDate(item.createdAt),
          mask(item.accountNumber, "#########-#"),
          item.documentNumber.length === 11
            ? mask(item.documentNumber, "###.###.###-##")
            : mask(item.documentNumber, "##.###.###/####-##"),
          (item.amount / 100).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
          prettierStatus(item.status),
        ];
      }

      return [];
    });
  }

  function onClickRow(id: string) {
    const item = results.find(i => i.id === id);

    setShowModal(true);

    if (item) {
      setCurrentItem({
        ...item,
        status: prettierStatus(item.status),
      });
    }
  }

  function onCloseModal() {
    setCurrentItem(null);
    setShowModal(false);
  }

  async function sendDowload(buffer: Buffer) {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `relatorio-debito-autorizado.xlsx`);
  }

  async function handleGetReport() {
    try {
      const file = await api.getGroupChargeFileReport(match.params.id);

      await sendDowload(file);
    } catch (err) {
      generalStore.showToast("Erro ao gerar o arquivo");
    }
  }

  function getTable() {
    const columns = ["Data da Criação", "Conta Origem", "CPF/CNPJ", "Valor", "Status"];

    return (
      <>
        <Table
          customPadding="4px 33px 4px 33px"
          titles={columns}
          ids={results.map(item => item.id)}
          data={renderTableData()}
          onClickRow={onClickRow}
          isPagination
          totalRows={results.length}
          rowsPerPage={controlTable.limit}
          rowsPerPageOptions={[controlTable.limit]}
          page={controlTable.offset / controlTable.limit}
          handleChangePage={handleChangePage}
        />
      </>
    );
  }

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>Débito autorizado - Detalhes</Title>
        </TitleContainer>
        <Button
          theme="neutral"
          borderType="semi-circle"
          onClick={() => {
            routerStore.push(authorizedDebitPath);
          }}
        >
          <ArrowIcon />
          <span>Voltar para a lista</span>
        </Button>
      </Header>
      {renderData()}
      {currentItem && <AuthorizedDebitModalDetails item={currentItem} visible={showModal} closeModal={onCloseModal} />}
      <DownloadButton
        label="Baixar relatório"
        onClick={handleGetReport}
        iconComponent={<SvgSpreadsheet width="50px" height="20px" />}
      />
    </>
  );
}
