import * as React from "react";
import styled from "styled-components";
import { ToDoErros } from "api/manager-api";
import moment from "moment";
import { RaisedButton } from "legacy/components/RaisedButton";

import { OutlinedButton } from "../../../components/OutlinedButton";
import { Dialog } from "../../../components/Dialog";
import { TextAreaInput } from "../../../components/TextAreaInput";

import { defaultMessage } from "..";

import { colors } from "../../../utils";
const { useState } = React;

interface IProps {
  error: ToDoErros;
  loading: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
}

export function ErrorEditDialog({ error, loading, onClose, onSubmit }: IProps) {
  const [message, setMessage] = useState(error.message === defaultMessage ? "" : error.message);

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    return setMessage(event.target.value);
  }

  function handleClear() {
    return setMessage("");
  }

  function handleSubmit() {
    return onSubmit(message || defaultMessage);
  }

  return (
    <Dialog title={`Gestão de erros`} isOpen onClose={onClose} padding={"0 20px 20px"}>
      <Box>
        <Info>
          <Label>Data/Hora: </Label>
          {moment(error.createdAt).format("L [às] HH:mm")}
          <Margin />
          <Label>Erro: </Label>
          {error.error}
          <Margin />
          <Margin />
          <Label>Mensagem</Label>
          <Margin />
          <TextAreaInput onChange={handleChange} value={message} placeholder={defaultMessage} />
        </Info>
        <InputArea>
          <BackButtonWrapper label="Utilizar mensagem padrão" onClick={handleClear} />
          <NextButtonWrapper loading={loading} label="Salvar" onClick={handleSubmit} />
        </InputArea>
      </Box>
    </Dialog>
  );
}

const Box = styled.div`
  padding: 16px 24px;
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

const NextButtonWrapper = styled(RaisedButton)`
  min-width: 145px;
  width: fit-content;
  font-family: Lato;
  margin: 15px 0 15px 15px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;

const Info = styled.div`
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.black};
`;

const Label = styled.span`
  font-weight: 400;
`;

const Margin = styled.div`
  height: 8px;
`;
