/* eslint-disable sort-keys */
interface IAllCheckSanitizer {
  id: string;
  registrationData: { [key: string]: string } | null;
  emails: Array<{ [key: string]: string }> | null;
  death: { [key: string]: string } | null;
  ppe: { [key: string]: string } | null;
  address: { [key: string]: string } | null;
  phones: Array<{ [key: string]: string }>;
  partners: Array<{ [key: string]: string }>;
  companies: { [key: string]: string } | null;
  vehicles: Array<{ [key: string]: string }>;
  ccf: Array<{ [key: string]: string }>;
  BankData: Array<{ [key: string]: string }>;
  workHistory: Array<{ [key: string]: string }>;
  watchNetwork: Array<{ [key: string]: string }>;
}

export const allCheckSanitizer: IAllCheckSanitizer = {
  BankData: [
    {
      agencia: "Agência",
      agencyAddress: "Endereço da agência",
      agencyName: "Nome da agência",
      bank: "Banco",
      bankName: "Nome do banco",
      cep: "CEP",
      city: "Cidade",
      ddd: "DDD",
      fax: "Fax",
      phone: "Telefone",
      state: "Estado",
    },
  ],
  address: {
    address: "Rua",
    cep: "CEP",
    city: "Cidade",
    complement: "Complemento",
    neighborhood: "Bairro",
    number: "Número",
    state: "Estado",
  },
  ccf: [
    {
      agency: "Agência",
      agencyAddress: "Endereço da agência",
      agencyName: "Nome da agência",
      bank: "Banco",
      bankName: "Nome do banco",
      cep: "CEP",
      city: "Cidade",
      ddd: "DDD",
      fax: "Fax",
      phone: "Telefone",
      state: "Estado",
      documentNumber: "Número do documento",
      lastOccurrenceDate: "Última ocorrência",
      name: "Nome",
      occurrenceAmount: "Valor na ultima ocorrência",
      returnReason: "Razão do retorno",
    },
  ],
  companies: {
    address: "Endereço",
    businessActivity: "Atividade",
    cadastralSituation: "Situação cadastral",
    cadastralSituationDate: "Data da situação",
    cep: "CEP",
    city: "Cidade",
    cnae: "CNAE",
    cnae2: "CNAE 2",
    cnpj: "CNPJ",
    complement: "Complemento",
    fantasy: "Nome fantasia",
    juridicalNature: "Natureza jurídica",
    name: "Nome",
    neighborhood: "Bairro",
    number: "Número",
    openingDate: "Data de abertura",
    porte: "Porte",
    specialSituation: "Situação especial",
    specialSituationDate: "Data da situação especial",
    state: "Estado",
    type: "Tipo",
  },
  death: {
    birthDate: "Data de nascimento",
    deathDate: "Data de óbito",
    motherName: "Nome da mãe",
    name: "Nome",
  },
  emails: [
    {
      documentNumber: "Documento",
      email: "E-mail",
    },
  ],
  partners: [
    {
      address: "Endereço",
      addressComplement: "Complemento",
      addressNumber: "Número",
      buyAddress: "Endereço de compra",
      buyAddressNumber: "Número do endereço de compra",
      cep: "CEP",
      cnpj: "CNPJ",
      cpf: "CPF",
      companyCep: "CEP da empresa",
      companyComplement: "Complemento da empresa",
      companyNeighborhood: "Bairro da empresa",
      companyPartner: "Parceiro da empresa",
      companyState: "Estado da empresa",
      dateTicket: "Data do ticket",
      name: "Nome",
      neighborhood: "Bairro",
      participation: "Participação",
      partner: "Parceiro",
      rg: "RG",
      startDate: "Data de início",
      state: "Estado",
      stateRegistration: "Registro do estado",
    },
  ],
  id: "Id",
  phones: [
    {
      address: "Endereço",
      addressNumber: "Número",
      documentNumber: "Número do documento",
      cep: "CEP",
      city: "Cidade",
      complement: "Complemento",
      ddd: "DDD",
      installationDate: "Data da instalação",
      name: "Nome",
      neighborhood: "Bairro",
      phone: "Número do telefone",
      phoneType: "Tipo",
      state: "Estado",
    },
  ],
  ppe: {
    accessionNumber: "Número da adesão",
    birthCounty: "Município de nascimento",
    birthDate: "Data de nascimento",
    civilStatus: "Estado civil",
    county: "Município",
    degreeOfEducation: "Grau de educação",
    initialsUf: "Iniciais do estado",
    institution: "Instituição",
    name: "Nome",
    nameUrna: "Nome na urna",
    nationality: "Nacionalidade",
    occupationDescription: "Descrição da ocupação",
    partyInitials: "Iniciais do partido",
    partyName: "Nome do partido",
    partyNumber: "Número do partido",
    power: "Poder",
    responsibility: "Responsabilidade",
    status: "Status",
    titleNumber: "Número do título",
    ufBirth: "Estado de nascimento",
  },
  registrationData: {
    activity: "Atividade",
    age: "Idade",
    benefitNumber: "Número do benefício",
    benefitStart: "Início do benefício",
    benefitValue: "Valor do benefício",
    birthDate: "Data de nascimento",
    civilStatus: "Estado civil",
    cnpjEmployer: "CNPJ do empregador",
    degreeOfEducation: "Grau de educação",
    documentNumber: "Número do documento",
    documentSource: "Origem do documento",
    email: "Email",
    gender: "Gênero",
    issueDate: "Data de emissão",
    membership: "Filiação",
    name: "Nome",
    nationality: "Nacionalidade",
    pis: "PIS",
    rg: "RG",
    sign: "Signo",
    titleNumber: "Número do título",
    workPermit: "Visto de trabalho",
  },
  vehicles: [
    {
      address: "Endereço",
      baseYear: "Ano base",
      brand: "Marca",
      cep: "CEP",
      chassis: "Chassi",
      city: "Cidade",
      ddd: "DDD",
      ddd1: "DDD 2",
      complement: "Complemento",
      document: "Documento",
      fuel: "Combustível",
      manufacturingYear: "Ano de fabricação",
      movingDate: "Data de mudança",
      name: "Nome",
      neighborhood: "Bairro",
      number: "Número",
      phone: "Telefone",
      phone1: "Telefone 2",
      plaque: "Placa",
      renavam: "Renavam",
      uf: "Estado",
    },
  ],
  watchNetwork: [
    {
      client: "Cliente",
      dateConsultation: "Data de consulta",
      phone: "Telefone",
      product: "Produto",
    },
  ],
  workHistory: [
    {
      admissionDate: "Data de admissão",
      cnpj: "CNPJ",
      cpf: "CPF",
      fre: "FRE",
      nome: "Nome",
      nomeEmpresa: "Nome da empresa",
    },
  ],
};
