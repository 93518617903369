import React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {}
export const SvgClip = (props: IProps) => {
  const { color, viewBox, width, height, fill, ...restProps } = props;
  return (
    <svg viewBox={viewBox || "0 0 351.136 351.136"} width={width || "1rem"} height={height || "1rem"} {...restProps}>
      <path
        fill="currentColor"
        d="M324.572 42.699c-35.419-35.419-92.855-35.419-128.273 0L19.931 219.066c-26.575 26.575-26.575 69.635 0 96.211 21.904 21.904 54.942 25.441 80.769 11.224 2.698-.136 5.351-1.156 7.415-3.197l176.367-176.367c17.709-17.709 17.709-46.416 0-64.125s-46.416-17.709-64.125 0L76.052 227.116c-4.422 4.422-4.422 11.61 0 16.031 4.422 4.422 11.61 4.422 16.031 0L236.388 98.843c8.866-8.866 23.219-8.866 32.063 0 8.866 8.866 8.866 23.219 0 32.063L100.088 299.268c-17.709 17.709-46.416 17.709-64.125 0s-17.709-46.416 0-64.125L212.33 58.73c26.575-26.575 69.635-26.575 96.211 0 26.575 26.575 26.575 69.635 0 96.211L148.205 315.277c-4.422 4.422-4.422 11.61 0 16.031 4.422 4.422 11.61 4.422 16.031 0l160.336-160.336c35.419-35.418 35.419-92.854 0-128.273z"
      />
    </svg>
  );
};
