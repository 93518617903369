exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__dropdown__OFnm8{background:#fff;box-shadow:0px 3px 10px rgba(0,0,0,.15);border-radius:6px;padding:24px;max-height:200px;overflow:auto}.style__all__1Hr3s{margin-bottom:16px}.style__button__1WQfr{text-transform:none !important;position:relative}.style__button__1WQfr:hover{color:#ed2980;background-color:transparent}.style__button__1WQfr:hover svg{color:#ed2980}.style__button__1WQfr svg{color:#a6a6a6}.style__button__1WQfr .style__count__18-bd{font-weight:600;font-size:9px;line-height:11px;color:#fff;background:#ed2980;border-radius:10px;padding:1px 5px;position:absolute;top:-4px;left:50%;transform:translateX(-50%)}", ""]);

// Exports
exports.locals = {
	"dropdown": "style__dropdown__OFnm8",
	"all": "style__all__1Hr3s",
	"button": "style__button__1WQfr",
	"count": "style__count__18-bd"
};