/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import { toJS } from "mobx";
import * as api from "api/manager-api";
import { CircularProgress } from "@material-ui/core";
import { colors, listToMatrix } from "utils";
import {
  CancelButton,
  Right,
  SaveButton,
  StyledTextArea,
} from "@manager/containers/TransactionalBoundaries/GeneralParameters/DefaultLimit/style";

import { imagePathWithUrl } from "../../../utils";
import { Dialog } from "../../../components/Dialog";
import { Table } from "../../../components/Table";
// Import { RaisedButton } from "@shared/components/RaisedButton";
import { ActionsDropdown } from "../../../components/ActionsDropdown";
// Import { UserWizard } from "../DialogsContent";
import { InnerAlert } from "../../../components/InnerAlert";
import { useStores } from "../../../stores/RootStore";
import {
  Block,
  Centralizer,
  Column,
  Container,
  ContentImage,
  ImageComponent,
  RepresentativeDataWrapper,
  RowContentImage,
  SubTitle,
  Title,
  TitleContentImage,
  Typography,
  WhiteCell,
  WrapperTextShowMainInfo,
  WrapperTextShowWithBureauMainInfo,
} from "./styled";
import { ImagePreview } from "../../../components/ImagePreview";
import { Loader } from "../../../components/Loader";
import { StatusBuilder } from "./utils";

interface IProps {
  companyId: string;
  cnpj: string;
  onSelected: () => Promise<api.ComplianceData>;
  onUpdateAction: () => void;
}

export function LegalEntityUsers(props: IProps) {
  const { openingAccountsStore, generalStore, usersStore, legalEntityStore } = useStores();
  const [imageToDialog, setImageToDialog] = useState(null);
  const [imageDialogTitle] = useState("");
  const [openCommentsDialog, setOpenCommentsDialog] = useState<boolean>(false);
  const [comments, setComments] = useState<string>("");
  const [companyID, setCompanyID] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState("");
  const [representativeList, setRepresentativeList] = useState<api.RepresentativeAnalisys[] | null>(null);
  const [rowData, setRowData] = useState<api.RepresentativeAnalisys | null>(null);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState("");

  // Use Effects

  useEffect(() => {
    getRepresentativesData();
  }, []);

  useEffect(() => {
    if (selectedRow) {
      const result = representativeList!.find(element => {
        return element.cpf === selectedRow;
      });

      if (result) {
        setRowData(result);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    if (representativeList) {
      setLoading(false);
    }
  }, [representativeList]);

  // Functions

  async function onAction(
    cpf: string,
    value: "changePass4" | "changePass6" | "removeToken" | "closeAccount" | "blockUser" | "unblockUser",
    id?: string,
  ) {
    const { sendLinkToRecovery, toggleUserBlock } = usersStore;

    const actions = {
      changePass4: api.ActionRecoverUser.recoverPassword4,
      changePass6: api.ActionRecoverUser.recoverPassword6,
      removeToken: api.ActionRecoverUser.removeToken,
      closeAccount: api.ActionRecoverUser.removeToken,
    };
    let message: string;

    try {
      setLoading(true);
      if (value === "blockUser") {
        await toggleUserBlock(true, id!);
        message = "Usuário bloqueado.";
      } else if (value === "closeAccount") {
        await onShowTipping(id!);
      } else if (value === "unblockUser") {
        await toggleUserBlock(false, id!);
        message = "Usuário desbloqueado.";
      } else {
        const findUser = legalEntityStore.usersByCompany.find(user => user.cpf === cpf);

        if (findUser) {
          const result = await sendLinkToRecovery(findUser.id, actions[value]);

          if (actions[value] === api.ActionRecoverUser.removeToken) {
            message = "Token removido com sucesso.";
          } else {
            message = `Um e-mail foi enviado para ${result} com as instruções para concluir o processo.`;
          }
        } else {
          message = "Usuário não encontrado.";
        }
      }
    } catch (err) {
      // eslint-disable-next-line prefer-destructuring
      message = err.message;
    } finally {
      setLoading(false);
      if (value !== "closeAccount") {
        generalStore.showToast(message!);
        props.onUpdateAction();
      }
    }
  }

  function onShowTipping(id: string) {
    setCompanyID(id);
    setOpenCommentsDialog(true);
  }

  const onCloseModal = () => {
    setCompanyID("");
    setComments("");
    setOpenCommentsDialog(!openCommentsDialog);
  };

  const newComment = (comm: string) => {
    setComments(comm);
  };

  function closeAccounts() {
    closeAccountWithComments();
  }

  const closeAccountWithComments = async () => {
    let message = "";
    const { accounts } = legalEntityStore!.company!;
    const type = api.PfPj.pj;
    const targetId = companyID;

    if (!accounts.length) {
      message = "Não há contas cadastradas";
      generalStore.showToast(message!);
      onCloseModal();
      return;
    }

    if (accounts.length === 1) {
      await api.toggleUserBlock(true, companyID);
      await api.closeAccount(accounts![0].account, accounts![0].branch, comments, targetId, type).then(result => {
        message = result;
      });
    } else {
      await api.closeAccount(accounts![0].account, accounts![0].branch, comments, targetId, type).then(result => {
        message = result;
      });
    }

    generalStore.showToast(message!);
    onCloseModal();
  };

  async function getRepresentativesData() {
    setApiError("");
    try {
      const response = await openingAccountsStore.getRepresentativesByCompanyId(props.companyId);

      setRepresentativeList(response);
    } catch (err) {
      setLoading(false);
      setApiError(err.message);
    }
  }

  function renderActionsLoader() {
    if (!rowData || !loading) {
      return null;
    }

    return <CircularProgress thickness={3} size={20} style={{ color: colors.violetRed }} />;
  }

  function openPopup(image: any, title: string) {
    const img = new Image();

    img.src = image;

    const width = img.width / 2;
    const height = img.height / 2;

    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;

    const urlInBase64 = btoa(image);

    window.open(
      imagePathWithUrl(`#${urlInBase64}`),
      title,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
  }

  function renderActions(cpf: string, isBlocked: boolean | null, id: string) {
    const actions = [];

    if (generalStore.hasPermission(api.AdminUserPermission.recoveryUser)) {
      actions.push(
        {
          label: "Enviar email de recuperação de senha de 4 dígitos",
          onClick: async () => onAction(cpf, "changePass4"),
        },
        {
          label: "Enviar email de recuperação de senha de 6 dígitos",
          onClick: async () => onAction(cpf, "changePass6"),
        },
        {
          label: "Remover token",
          onClick: async () => onAction(cpf, "removeToken"),
        },
        /*
         * {
         *   label: "Encerrar Conta PJ",
         *   onClick: async () => onAction(cpf, "closeAccount", id),
         * },
         */
      );
    }

    if (isBlocked) {
      actions.push({
        label: "Desbloquear usuário",
        onClick: async () => onAction(cpf, "unblockUser", id),
      });
    } else {
      actions.push({
        label: "Bloquear usuário",
        onClick: async () => onAction(cpf, "blockUser", id),
      });
    }

    return actions;
  }

  // Render

  function renderData() {
    if (loading) {
      return (
        <Centralizer>
          <Loader />
          <Typography>Aguarde, a resposta dos serviços pode demorar.</Typography>
        </Centralizer>
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!representativeList || (representativeList && representativeList.length < 1)) {
      return <InnerAlert compact message="Não há usuários cadastrados" />;
    }

    if (apiError) {
      return <InnerAlert message={apiError} buttonLabel="Tentar novamente" buttonClick={getRepresentativesData} />;
    }

    return (
      <>
        <Table
          customPadding={"4px 15px 4px 24px"}
          titles={["Nome", "Status", "Ações"]}
          ids={representativeList.map(e => e.cpf)}
          onClickRow={e => setSelectedRow(e)}
          selectedRow={selectedRow}
          data={representativeList.map(userCompany => {
            const status = StatusBuilder(userCompany.status);
            const tableData: any = [userCompany.name, status];

            if (
              userCompany.status === api.StatusProofOfLife.approved ||
              userCompany.status === api.StatusProofOfLife.approvedManual
            ) {
              tableData.push(
                <ActionsDropdown
                  key={userCompany.id}
                  options={renderActions(userCompany.cpf, userCompany.blocked, userCompany.id)}
                />,
              );
            } else {
              tableData.push(<WhiteCell />);
            }

            return tableData;
          })}
        />
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"md"}
          title={`Inserir motivo de encerramento:`}
          isOpen={openCommentsDialog}
          onClose={() => onCloseModal()}
        >
          <StyledTextArea value={comments} onChange={e => newComment(e.target.value)} />
          <Right>
            <CancelButton onClick={() => onCloseModal()} label="Cancelar" type="submit" />
            <SaveButton onClick={() => closeAccounts()} label="Salvar" type="submit" loading={false} />
          </Right>
        </Dialog>
      </>
    );
  }

  function renderRepresentativeData() {
    if (!rowData) {
      return null;
    }

    return (
      <RepresentativeDataWrapper>
        {rowData.fieldGroups.map((group, index) => (
          <React.Fragment key={index}>
            <Title>{group.name}</Title>
            {index === 0 && rowData.isMaster && (
              <WrapperTextShowMainInfo title="Representante" text={"Master"} invertColor />
            )}
            {group.fields.map((field, index2) => (
              <WrapperTextShowWithBureauMainInfo
                key={index2}
                title={field.label}
                content={field.values.map(value => ({
                  data: value.value,
                  source: value.source,
                }))}
              />
            ))}
          </React.Fragment>
        ))}
      </RepresentativeDataWrapper>
    );
  }

  function renderImages() {
    if (!rowData) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const imagesToMap = rowData && rowData.documents ? listToMatrix(toJS(rowData.documents), 2) : [];

    if (!imagesToMap.length) {
      return <SubTitle>Não há documentos para serem mostrados</SubTitle>;
    }

    return (
      <>
        <Title>Prova de vida</Title>
        {imagesToMap.map((row, idx) => (
          <Block key={idx}>
            {row.map(image => (
              <ContentImage key={image.name}>
                <RowContentImage>
                  <TitleContentImage>{image.name}</TitleContentImage>
                </RowContentImage>
                <ImagePreview url={image.url} onClick={() => openPopup(image.url, image.name)} />
              </ContentImage>
            ))}
          </Block>
        ))}
      </>
    );
  }

  return (
    <>
      <Dialog title={imageDialogTitle} isOpen={Boolean(imageToDialog)} onClose={() => setImageToDialog(null)}>
        <ImageComponent url={imageToDialog} />
      </Dialog>
      <Container>
        <Column width={"60%"}>{renderData()}</Column>
        <Column width={"40%"}>
          {renderActionsLoader()}
          {renderRepresentativeData()}
          {renderImages()}
        </Column>
      </Container>
    </>
  );
}
