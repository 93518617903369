/* eslint-disable consistent-return */
/* eslint-disable sort-keys */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import styled from "styled-components";
import save from "save-file";
import { Formik } from "formik";
import { Switch } from "@material-ui/core";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors, maskValidateCpf, validateCpf } from "utils";

import { TextInput } from "../../components/TextInput";
import { SvgRightArrow } from "../../assets/icons/SvgRightArrow";
import { ConciliationStore, GeneralStore } from "../../stores";

interface IProps {
  generalStore: GeneralStore;
  conciliationStore: ConciliationStore;
}

interface IConciliationCardsForm {
  startDate: moment.Moment;
  endDate: moment.Moment;
  documentNumber: string;
}

type IFocus = "startDate" | "endDate" | null;

@inject("generalStore", "conciliationStore")
@observer
export class ConciliationCards extends React.Component<IProps> {
  @observable private loading = false;

  @observable private focusedInput: IFocus = null;

  @observable private filterChecked = false;

  @action
  private handleFocus = (focus: IFocus) => {
    this.focusedInput = focus;
  };

  @action
  private handleDownload = async (values: IConciliationCardsForm) => {
    try {
      this.loading = true;
      const buffer = await this.props.conciliationStore!.exportCardConciliation(
        values.documentNumber && this.filterChecked ? values.documentNumber : null,
        values.startDate.toDate(),
        values.endDate.toDate(),
      );

      this.loading = false;
      await this.sendDownload(values.endDate, buffer);
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Não foi possível gerar o arquivo.");
      this.loading = false;
    }
  };

  private sendDownload = (endDate: moment.Moment, buffer: Buffer) => {
    const suffix = endDate.format("YYYY-MM-DD");
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `conciliacao-cartoes-${suffix}.csv`);
  };

  public render() {
    return (
      <Container>
        <Title>Conciliação - Cartões</Title>
        <Subsection>
          <Subtitle>Relatório</Subtitle>
          <div style={{ marginLeft: "1rem" }}>
            <Label>Filtrar por CPF</Label>
            <Switch
              onChange={() => {
                this.filterChecked = !this.filterChecked;
              }}
              checked={this.filterChecked}
              title="Filtrar por CPF"
            />
            <Formik<IConciliationCardsForm>
              initialValues={{
                startDate: moment()
                  .subtract(1, "days")
                  .startOf("day"),
                endDate: moment(),
                documentNumber: "",
              }}
              onSubmit={this.handleDownload}
              validate={values => {
                if (values.documentNumber && !validateCpf(values.documentNumber)) {
                  return {
                    documentNumber: "Digite um CPF válido",
                  };
                }

                return;
              }}
            >
              {({ values, setFieldValue, handleChange, errors, handleSubmit }) => (
                <Container>
                  {this.filterChecked && (
                    <>
                      <Label style={{ marginBottom: "1rem" }}>CPF</Label>
                      <TextInput
                        name="documentNumber"
                        value={values.documentNumber}
                        onChange={handleChange}
                        mask={maskValidateCpf}
                        error={Boolean(errors.documentNumber)}
                        errorText={errors.documentNumber}
                      />
                    </>
                  )}
                  <Label style={{ marginBottom: "1rem" }}>Intervalo</Label>
                  <Controls>
                    <DateRangePicker
                      startDate={values.startDate}
                      startDateId="start_date_filter"
                      endDate={values.endDate}
                      endDateId="end_date_filter"
                      minimumNights={0}
                      onDatesChange={arg => {
                        setFieldValue("startDate", arg.startDate || values.startDate);
                        setFieldValue("endDate", arg.endDate || values.endDate);
                      }}
                      focusedInput={this.focusedInput}
                      onFocusChange={this.handleFocus}
                      isOutsideRange={(date: moment.Moment) => date.isAfter(Date.now(), "days")}
                      horizontalMargin={49}
                      disabled={this.loading}
                      customArrowIcon={
                        <SvgRightArrow color={colors.gray} width="7px" height="12px" />
                        // Tslint:disable-next-line: jsx-curly-spacing
                      }
                    />
                  </Controls>
                  <StyledRaisedButton
                    label="Baixar Excel"
                    loading={this.loading}
                    disabled={this.loading}
                    onClick={() => handleSubmit()}
                  />
                </Container>
              )}
            </Formik>
          </div>
        </Subsection>
      </Container>
    );
  }
}

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.black};
  font-family: Lato;
`;

const Subsection = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;

  .DateInput {
    width: 100px;
    height: 40px;
    margin-left: 10px;
  }
  .DateInput_input {
    height: 40px;
    padding: 0px 0px 0px 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    color: ${colors.gray};
    border-bottom: none;
    ::placeholder {
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      color: ${colors.gray};
    }
  }

  .DateRangePickerInput {
    border: 1px solid ${colors.lightPink};
    border-radius: 4px;
    overflow: hidden;
  }

  .CalendarDay__selected {
    background: ${colors.violetRed};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected:hover {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected_span {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
    &:hover {
      background: ${colors.violetRedOpacity};
      border: ${colors.violetRed};
    }
  }
`;

const StyledRaisedButton = styled(RaisedButton)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  height: 40px;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black};
`;
