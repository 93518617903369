import styled from "styled-components";
import { colors } from "utils";

import { TextShowWithBureau } from "../../../../components/TextShowWithBureau";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child) {
    border-right: 1px solid ${colors.borderGray};
    margin-right: 12px;
  }
`;

export const DoubleRowContainer = styled.div<{
  highlight?: boolean;
  paddingLeft?: string | number;
}>`
  display: flex;
`;

export const Text = styled.span<{ color?: string; marginLeft?: string; width?: string }>`
  font-family: Lato;
  font-size: 14px;
  color: ${props => (props.color ? props.color : colors.black)};
  overflow: hidden;
  display: inline-block;
  position: absolute;
  word-wrap: break-word;
  width: ${props => (props.width ? props.width : "240px")};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "0")};
`;

export const Centralizer = styled.div<{ highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
`;

export const Typography = styled.div`
  font-size: 1rem;
  color: ${colors.gray};
  margin-top: 1rem;
`;

export const Title = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
`;

export const WrapperTextShowWithBureauMainInfo = styled(TextShowWithBureau)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const BeneficiaryDataWrapper = styled.div`
  padding: 0 18px 18px 18px;
`;
