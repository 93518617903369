import React from "react";
import BaseSwitch from "rc-switch";
import classNames from "classnames";

import * as s from "./style.scss";

type Props = React.ComponentProps<typeof BaseSwitch>;

export function Switch(props: Props) {
  return <BaseSwitch {...props} className={classNames(s["rc-switch"], props.className)} />;
}
