/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable sort-keys */
import * as React from "react";
import { AdminUserPermission } from "api/manager-api";

import { Checkbox } from "../../../components/Checkbox";
import { Accordion } from "../../../components/Accordion";
import { Column, Container, Row, Text } from "./styled";

interface IProps {
  data: AdminUserPermission[];
  onChange: (value: AdminUserPermission[]) => void;
}

export class EmployeeWizardPermissions extends React.Component<IProps> {
  private data = new Set<AdminUserPermission>(this.props.data);

  private update = () => {
    this.props.onChange(Array.from(this.data));
  };

  private cascadeUp = (parents: AdminUserPermission[]) => {
    parents.forEach(permission => {
      if (!this.data.has(permission)) {
        this.data.add(permission);
      }
    });
    this.update();
  };

  private cascadeDown = ({ permission, dependents = [] }: IPermissionItem) => {
    if (this.data.has(permission)) {
      this.data.delete(permission);
    }

    if (dependents.length) {
      dependents.forEach(this.cascadeDown);
    } else {
      this.update();
    }
  };

  public render() {
    return (
      <Container>
        {groups.map(({ title, items }) => (
          <Accordion key={title} title={title}>
            <Column>{items.map(this.renderItem())}</Column>
          </Accordion>
        ))}
      </Container>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private renderItem = (parents: AdminUserPermission[] = []) => (item: IPermissionItem, index: number): any => {
    const { title, permission, dependents = [] } = item;
    const tree = [permission, ...parents];
    const checked = this.data.has(permission);
    const handleChange = () => (checked ? this.cascadeDown(item) : this.cascadeUp(tree));
    const element = (
      <Row key={`item-${index}`}>
        <Checkbox checked={checked} handleChange={handleChange} />
        <Text>{title}</Text>
      </Row>
    );

    return [element, ...dependents.map(this.renderItem(tree))];
  };
}

interface IPermissionGroup {
  title: string;
  items: IPermissionItem[];
}

interface IPermissionItem {
  title: string;
  permission: AdminUserPermission;
  dependents?: IPermissionItem[];
}

const groups: IPermissionGroup[] = [
  {
    title: "Gestão de usuários",
    items: [
      {
        title: "Visualizar usuários",
        permission: AdminUserPermission.viewUser,
        dependents: [
          {
            title: "Enviar email de recuperação de senha de 4 dígitos, 6 dígitos e token",
            permission: AdminUserPermission.recoveryUser,
          },
          {
            title: "Alterar informações cadastrais",
            permission: AdminUserPermission.updateRegisterInfo,
          },
        ],
      },
    ],
  },
  {
    title: "Gestão de empresas",
    items: [
      {
        title: "Visualizar empresas",
        permission: AdminUserPermission.viewCompany,
        dependents: [
          {
            title: "Editar empresa",
            permission: AdminUserPermission.editCompany,
          },
          {
            title: "Bloquear conta",
            permission: AdminUserPermission.blockCompany,
          },
          {
            title: "Adicionar, editar e excluir representante de conta",
            permission: AdminUserPermission.editRepresentativeCompany,
          },
        ],
      },
    ],
  },
  {
    title: "Aprovação de conta",
    items: [
      {
        title: "Visualizar solicitações de abertura de conta PF",
        permission: AdminUserPermission.viewOpeningAccountPF,
        dependents: [
          {
            title: "Aprovar abertura de conta PF",
            permission: AdminUserPermission.approveAccountPF,
          },
          {
            title: "Aprovar abertura de conta simples",
            permission: AdminUserPermission.approvePendingAccountPF,
          },
          {
            title: "Reprovar abertura de conta PF",
            permission: AdminUserPermission.disapproveAccountPF,
          },
          {
            title: "Revalidar abertura de conta PF",
            permission: AdminUserPermission.revalidateAccountPF,
          },
          {
            title: "Cancelar abertura de conta PF",
            permission: AdminUserPermission.canceledAccountPF,
          },
          {
            title: "Validar compliance PF",
            permission: AdminUserPermission.validateComplianceAccountPF,
          },
          {
            title: "Visualizar pareceres",
            permission: AdminUserPermission.viewFeedback,
            dependents: [
              {
                title: "Gerenciar pareceres",
                permission: AdminUserPermission.sendFeedback,
              },
            ],
          },
        ],
      },
      {
        title: "Visualizar solicitações de abertura de conta PJ",
        permission: AdminUserPermission.viewOpeningAccountPJ,
        dependents: [
          {
            title: "Aprovar abertura de conta PJ",
            permission: AdminUserPermission.approveAccountPJ,
          },
          {
            title: "Reprovar abertura de conta PJ",
            permission: AdminUserPermission.disapproveAccountPJ,
          },
          {
            title: "Revalidar abertura de conta PJ",
            permission: AdminUserPermission.revalidateAccountPJ,
          },
          {
            title: "Cancelar abertura de conta PJ",
            permission: AdminUserPermission.canceledAccountPJ,
          },
          {
            title: "Validar compliance PJ",
            permission: AdminUserPermission.validateComplianceAccountPJ,
          },
          {
            title: "Ações em Representante / Signatário",
            permission: AdminUserPermission.editUser,
          },
        ],
      },
      {
        title: "Visualizar solicitações de abertura de conta MEI",
        permission: AdminUserPermission.viewOpeningAccountPJMEI,
        dependents: [
          {
            title: "Aprovar abertura de conta MEI",
            permission: AdminUserPermission.approveAccountPJMEI,
          },
          {
            title: "Reprovar abertura de conta MEI",
            permission: AdminUserPermission.disapproveAccountPJMEI,
          },
          {
            title: "Revalidar abertura de conta MEI",
            permission: AdminUserPermission.revalidateAccountPJMEI,
          },
          {
            title: "Cancelar abertura de conta MEI",
            permission: AdminUserPermission.canceledAccountPJMEI,
          },
          {
            title: "Validar compliance MEI",
            permission: AdminUserPermission.validateComplianceAccountPJMEI,
          },
          {
            title: "Visualizar histórico",
            permission: AdminUserPermission.viewHistoryWorkflow,
          },
        ],
      },
      {
        title: "Visualizar provas de vida",
        permission: AdminUserPermission.viewProofOfLife,
        dependents: [
          {
            title: "Aprovar prova de vida",
            permission: AdminUserPermission.approveProofOfLife,
          },
          {
            title: "Reprovar prova de vida",
            permission: AdminUserPermission.reproveProofOfLife,
          },
        ],
      },
    ],
  },
  {
    title: "Gestão de parceiros",
    items: [
      {
        title: "Visualizar parceiros",
        permission: AdminUserPermission.viewPartner,
        dependents: [
          {
            title: "Adicionar parceiro",
            permission: AdminUserPermission.addPartner,
          },
          {
            title: "Editar parceiro",
            permission: AdminUserPermission.editPartner,
          },
        ],
      },
    ],
  },
  {
    title: "Gestão de funcionários",
    items: [
      {
        title: "Visualizar funcionários",
        permission: AdminUserPermission.viewAdminUser,
        dependents: [
          {
            title: "Adicionar funcionários",
            permission: AdminUserPermission.addAdminUser,
          },
          {
            title: "Editar funcionários",
            permission: AdminUserPermission.editAdminUser,
          },
          {
            title: "Bloquear funcionários",
            permission: AdminUserPermission.blockAdminUser,
          },
        ],
      },
    ],
  },
  {
    title: "Gestão da plataforma",
    items: [
      {
        title: "Visualizar gestão da plataforma",
        permission: AdminUserPermission.viewPlatformManager,
        dependents: [
          {
            title: "Desligar/ligar serviços",
            permission: AdminUserPermission.changeService,
          },
          {
            title: "Editar horários de serviços",
            permission: AdminUserPermission.editServiceHours,
          },
          {
            title: "Adicionar datas especiais",
            permission: AdminUserPermission.addExceptionDate,
          },
          {
            title: "Editar e excluir datas especiais",
            permission: AdminUserPermission.editExceptionDate,
          },
          {
            title: "Manipular Whitelists",
            permission: AdminUserPermission.editWhitelist,
          },
          {
            title: "Visualizar lista CPF",
            permission: AdminUserPermission.viewCPFList,
          },
          {
            title: "Editar lista CPF",
            permission: AdminUserPermission.editCPFList,
          },
        ],
      },
    ],
  },
  {
    title: "Limites Transacionais",
    items: [
      {
        title: "Parâmetros Gerais",
        permission: AdminUserPermission.viewGeneralParameters,
        dependents: [
          {
            title: "Editar Limite Padrão",
            permission: AdminUserPermission.editDefaultLimit,
          },
          {
            title: "Adicionar Segmentos",
            permission: AdminUserPermission.addSegments,
          },
          {
            title: "Editar Segmentos",
            permission: AdminUserPermission.editSegments,
          },
          {
            title: "Adicionar Limites de Segmentos",
            permission: AdminUserPermission.addSegmentBoundaries,
          },
          {
            title: "Editar Limites de Segmentos",
            permission: AdminUserPermission.editSegmentBoundaries,
          },
        ],
      },
      {
        title: "Personalização de Limites",
        permission: AdminUserPermission.viewCustomLimits,
        dependents: [
          {
            title: "Adicionar Personalização de Limites",
            permission: AdminUserPermission.addCustomLimits,
          },
          {
            title: "Editar Personalização de Limites",
            permission: AdminUserPermission.editCustomLimits,
          },
        ],
      },
      {
        title: "Histórico de Alterações",
        permission: AdminUserPermission.viewLogLimits,
      },
      {
        title: "Limite de Segurança",
        permission: AdminUserPermission.viewSafetyLimit,
        dependents: [
          {
            title: "Editar Limite de Segurança",
            permission: AdminUserPermission.editSafetyLimit,
          },
        ],
      },
    ],
  },
  {
    title: "Gestão de erros",
    items: [
      {
        title: "Visualizar gestão de erros",
        permission: AdminUserPermission.viewErrors,
        dependents: [
          {
            title: "Editar mensagens de erro",
            permission: AdminUserPermission.editErrors,
          },
        ],
      },
    ],
  },
  {
    title: "Gestão de solicitações de cartão",
    items: [
      {
        title: "Visualizar e executar ações de gestão das solicitações de cartão",
        permission: AdminUserPermission.visaCardManager,
        dependents: [
          {
            title: "Solicitar segunda via",
            permission: AdminUserPermission.generateReplacementCard,
          },
        ],
      },
    ],
  },
  {
    title: "Auditoria",
    items: [
      {
        title: "Visualizar dados de funcionário",
        permission: AdminUserPermission.viewAuditAdmin,
      },
      {
        title: "Visualizar dados de usuário",
        permission: AdminUserPermission.viewAuditUser,
      },
    ],
  },
  {
    title: "Conciliação",
    items: [
      {
        title: "Visualizar conciliação",
        permission: AdminUserPermission.viewConciliationAgreements,
      },
      {
        title: "Visualizar relatório de saques",
        permission: AdminUserPermission.viewConciliationWithdraws,
      },
      {
        title: "Transferir para Hugpay",
        permission: AdminUserPermission.createTedHugpay,
      },
      {
        title: "Visualizar relatório de cartões",
        permission: AdminUserPermission.viewCardConciliation,
      },
    ],
  },
  {
    title: "Relatórios",
    items: [
      {
        title: "Gerar relatório de abertura de contas",
        permission: AdminUserPermission.filterUserAndReport,
      },
      {
        title: "Gerar relatório de Contas Ativas",
        permission: AdminUserPermission.filterUserAndReportByPartner,
      },
      {
        title: "Gerar relatório movimentação Bancária",
        permission: AdminUserPermission.viewAccountBalanceAndStatement,
      },
    ],
  },
  {
    title: "Notificação Push",
    items: [
      {
        title: "Notificação Push Geral",
        permission: AdminUserPermission.sendNotification,
      },
    ],
  },
  {
    title: "Tombamento",
    items: [
      {
        title: "Visualizar",
        permission: AdminUserPermission.viewTipping,
      },
      {
        title: "Adicionar",
        permission: AdminUserPermission.addTipping,
      },
    ],
  },
  {
    title: "Tombamento Detalhes",
    items: [
      {
        title: "Cancelar",
        permission: AdminUserPermission.cancelTipping,
      },
    ],
  },
  {
    title: "Promoção",
    items: [
      {
        title: "Visualizar",
        permission: AdminUserPermission.viewPositivize,
      },
      {
        title: "Enviar mensagem",
        permission: AdminUserPermission.sendMensPositivize,
      },
    ],
  },
  {
    title: "Débito autorizado",
    items: [
      {
        title: "Fazer upload de arquivo de débito autorizado",
        permission: AdminUserPermission.createBatchChargeDebit,
      },
      {
        title: "Ver lista de arquivos de débito autorizado",
        permission: AdminUserPermission.viewBatchChargeDebit,
      },
    ],
  },

  {
    title: "Abertura de Conta PJ Manual",
    items: [
      {
        title: "Abertura de conta PJ Manual",
        permission: AdminUserPermission.insertManualPJAccount,
      },
    ],
  },
];
