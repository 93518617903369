import React, { useState } from "react";
import { observer } from "mobx-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useStores } from "@manager/stores/RootStore";
import { RaisedButton } from "legacy/components/RaisedButton";
import { validateCpf } from "utils";

import { Input, InputLabel, InputMask, Modal } from "@components/";

import s from "./styled.scss";

interface IProps {
  isOpen: boolean;
  onReload: () => void;
  onClose: () => void;
}

interface IField {
  cpf: string;
  newCardId: string;
  oldCardId: string;
}

export const SyncTwoRequestCardDialog = observer((props: IProps) => {
  const { cardStore, generalStore } = useStores();
  const [loading, setLoading] = useState(false);

  const formik = useFormik<IField>({
    initialValues: {
      cpf: "",
      oldCardId: "",
      newCardId: "",
    },
    validationSchema: Yup.object({
      cpf: Yup.string()
        .test("cpfValidator", "Formato inválido de CPF", (value: string) => {
          if (value) {
            return validateCpf(value);
          }

          return false;
        })
        .required("Este campo é obrigatório."),
      newCardId: Yup.string().required("Este campo é obrigatório."),
      oldCardId: Yup.string().required("Este campo é obrigatório."),
    }),
    onSubmit: async values => {
      setLoading(true);

      try {
        await cardStore.linkReplacedCard(values);

        props.onReload();
      } catch (error) {
        generalStore.showToast(error.message || "Erro ao solicitar segunda via do cartão");
      }

      formik.resetForm();
      setLoading(false);
      props.onClose();
    },
  });

  return (
    <Modal visible={props.isOpen} title="Vincular 2ª via de cartão existente" onClose={props.onClose} destroyOnClose>
      <div className={s.box}>
        <form onSubmit={formik.handleSubmit}>
          <div className={s.info}>
            <InputLabel
              label="CPF *"
              htmlFor="cpf"
              error={formik.touched.cpf && formik.errors.cpf ? formik.errors.cpf : undefined}
            >
              <InputMask
                mask="999.999.999-99"
                maskChar=""
                name="cpf"
                onChange={formik.handleChange}
                value={formik.values.cpf}
                error={formik.touched.cpf && formik.errors.cpf ? true : undefined}
                disabled={loading}
              />
            </InputLabel>

            <InputLabel
              label="Card ID Atual *"
              htmlFor="oldCardId"
              error={formik.touched.oldCardId && formik.errors.oldCardId ? formik.errors.oldCardId : undefined}
            >
              <Input
                name="oldCardId"
                onChange={formik.handleChange}
                value={formik.values.oldCardId}
                error={formik.touched.oldCardId && formik.errors.oldCardId ? true : undefined}
                disabled={loading}
              />
            </InputLabel>

            <InputLabel
              label="Card ID Novo *"
              htmlFor="newCardId"
              error={formik.touched.newCardId && formik.errors.newCardId ? formik.errors.newCardId : undefined}
            >
              <Input
                name="newCardId"
                onChange={formik.handleChange}
                value={formik.values.newCardId}
                error={formik.touched.newCardId && formik.errors.newCardId ? true : undefined}
                disabled={loading}
              />
            </InputLabel>
          </div>
          <div className={s.inputArea}>
            <RaisedButton type="submit" className={s.NextWrapperButton} loading={loading} label="Salvar" />
          </div>
        </form>
      </div>
    </Modal>
  );
});
