import React from "react";

import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import { Button } from "components/Button";
import { Wrapper, InputWrapper, UploadButton, UploadInput, UploadInputFile, ViewFileButton } from "./style";

interface IProps {
  onLoader: (file: Buffer, filename: string) => void;
  onClear?: () => void;
  accept?: string;
  hasFileSelected?: boolean;
  disabled?: boolean;
  fileViewUrl?: string | null;
  fileViewTitle?: string;
}

export function UploadBox(props: IProps) {
  const [filename, setFilename] = React.useState("");
  const [fileSelected, setFileSelected] = React.useState<Buffer | null>(null);

  const inputFileRef = React.useRef<HTMLInputElement>(null);

  function inputFileClick() {
    if (inputFileRef.current && !props.disabled) {
      inputFileRef.current.click();
    }
  }

  function onChangeFile(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    const file = files ? files[0] : null;

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (reader.result && typeof reader.result !== "string") {
          const fileBuffer = Buffer.from(reader.result);

          props.onLoader(fileBuffer, filename);
          setFileSelected(fileBuffer);
          setFilename(file.name);
        }
      });

      reader.readAsArrayBuffer(file);
    }
  }

  function onClear() {
    setFilename("");
    setFileSelected(null);

    if (props.onClear) {
      props.onClear();
    }
  }

  function onView() {
    if (props.fileViewUrl) {
      window.open(props.fileViewUrl, "__blank");
    }
  }

  return (
    <>
      <Wrapper>
        <InputWrapper disabled={props.disabled}>
          <UploadButton onClick={inputFileClick} disabled={props.disabled} type="button">
            Escolher arquivo
          </UploadButton>
          <UploadInput
            placeholder="Nenhum arquivo selecionado"
            onClick={inputFileClick}
            value={filename}
            disabled={props.disabled}
          />
          <UploadInputFile
            type="file"
            ref={inputFileRef}
            onChange={onChangeFile}
            accept={props.accept}
            onClick={e => (e.currentTarget.value = "")}
          />
        </InputWrapper>

        <Button
          theme="link"
          onClick={onClear}
          disabled={!!props.disabled || !!props.hasFileSelected || !fileSelected}
          type="button"
        >
          Limpar
        </Button>
      </Wrapper>

      {props.fileViewUrl && (
        <ViewFileButton theme="link" onClick={onView} type="button">
          <SearchIcon />
          <span>{props.fileViewTitle}</span>
        </ViewFileButton>
      )}
    </>
  );
}
