import React from "react";
import { FormikErrors, FormikTouched } from "formik";

import { InputLabel } from "components/InputLabel";
import { Input } from "components/Input";
import { Button } from "components/Button";

import { IFields } from "./";

import s from "./style.scss";

interface IProps {
  values: IFields;
  errors: FormikErrors<IFields>;
  touched: FormikTouched<IFields>;
  setValues: (values: IFields) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  disabled?: boolean;
}

export function FormStyle({ disabled, errors, touched, values, setFieldValue }: IProps) {
  const refInputFile = React.useRef<HTMLInputElement>(null);

  function handleChangeImage(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.addEventListener(
      "loadend",
      () => {
        if (!(reader.result && typeof reader.result === "string")) {
          return;
        }

        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 768;
        const image = new Image();

        image.src = reader.result;

        image.onload = () => {
          let { width, height } = image;

          if (width / MAX_WIDTH > height / MAX_HEIGHT && width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          } else if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }

          const canvas = document.createElement("canvas");

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");

          ctx!.drawImage(image, 0, 0, width, height);

          const dataURL = canvas.toDataURL(file.type);
          const imageBuffer = Buffer.from(dataURL.split(",")[1], "base64");

          setFieldValue("logoBuffer", imageBuffer);
          setFieldValue("logoUrl", dataURL);
        };
      },
      false,
    );

    reader.readAsDataURL(file);
  }

  return (
    <div className={s.formWrapper}>
      <div className={s.title}>Definições de estilo</div>

      <div className={s.formGroup}>
        <InputLabel label="Cor Primária" error={errors.colorPrimary}>
          <Input
            value={values.colorPrimary}
            onChange={event => setFieldValue("colorPrimary", event.target.value)}
            error={touched.colorPrimary && Boolean(errors.colorPrimary)}
            disabled={disabled}
            type="color"
          />
        </InputLabel>

        <InputLabel label="Cor Secundária" error={errors.colorSecondary}>
          <Input
            value={values.colorSecondary}
            onChange={event => setFieldValue("colorSecondary", event.target.value)}
            error={touched.colorSecondary && Boolean(errors.colorSecondary)}
            disabled={disabled}
            type="color"
          />
        </InputLabel>

        <InputLabel label="Cor de fundo" error={errors.colorBg}>
          <Input
            value={values.colorBg}
            onChange={event => setFieldValue("colorBg", event.target.value)}
            error={touched.colorBg && Boolean(errors.colorBg)}
            disabled={disabled}
            type="color"
          />
        </InputLabel>

        <InputLabel label="Cor de erro" error={errors.colorError}>
          <Input
            value={values.colorError}
            onChange={event => setFieldValue("colorError", event.target.value)}
            error={touched.colorError && Boolean(errors.colorError)}
            disabled={disabled}
            type="color"
          />
        </InputLabel>
      </div>

      <InputLabel label="Logotipo *" error={errors.logoBuffer}>
        <div className={s.uploadContent}>
          <input
            onChange={handleChangeImage}
            className={s.inputFile}
            disabled={disabled}
            type="file"
            accept=".png"
            ref={refInputFile}
            multiple
          />

          <Button type="button" disabled={disabled} onClick={() => refInputFile.current?.click()}>
            {values.logoUrl ? "Alterar" : "Adicionar"}
          </Button>
        </div>
      </InputLabel>

      {values.logoUrl && <img src={values.logoUrl} />}
    </div>
  );
}
