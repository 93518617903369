import React, { useEffect, useState } from "react";
import { EntryStatement, EntryStatementNature } from "api/manager-api";
import moment from "moment";
import save from "save-file";
import { Loader } from "legacy/components/Loader";
import { ClearOutlinedButton } from "@manager/assets/styles/global";
import { SvgFileDownload } from "@manager/components/Icons/SvgFileDownload";

import { Modal } from "@components/";

import { ButtonDownload, Container, Row } from "./styles";
import { Receipt } from "../../../../../api/partner-api";
import { getAmount } from "../utils";

interface ReceiptProps {
  show: boolean;
  onClose: () => void;
  selectedEntry: EntryStatement;
  receiptData: Receipt | null;
  loading: boolean;
}

export function StatementReceipt({ show, onClose, selectedEntry, receiptData, loading }: ReceiptProps) {
  function renderLoading() {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  function downloadImage() {
    if (receiptData && receiptData.image) {
      const options: RequestInit = {
        method: "GET",
        mode: "cors",
        cache: "default",
      };
      const request = new Request(receiptData.image);

      fetch(request, options).then(response => {
        response.arrayBuffer().then(buffer => {
          saveFile(
            buffer,
            `Comprovante ${selectedEntry.movementNumber} ${moment(selectedEntry.movementDate).format("LL")}.png`,
          );
        });
      });
    }

    function saveFile(data: Buffer | ArrayBuffer | string, name: string) {
      save(data, name);
    }
  }

  return (
    <Modal visible={show} title="Detalhes da transação" onClose={onClose}>
      {loading ? (
        renderLoading()
      ) : (
        <Container>
          {receiptData && receiptData.html && (
            <>
              <div>
                <button style={{ position: "relative", bottom: 30, left: 360 }} onClick={downloadImage}>
                  <SvgFileDownload size={15} style={{ color: "#fffff", marginRight: 10, cursor: "pointer" }} />
                </button>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: receiptData.html,
                }}
              />
            </>
          )}
        </Container>
      )}
    </Modal>
  );
}
