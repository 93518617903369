/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
/* eslint-disable require-unicode-regexp */
/* eslint-disable-next-line implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { openingAccountsSectionPath } from "@manager/utils";
import moment from "moment-timezone";
import { SvgFileDownload } from "@manager/components/Icons/SvgFileDownload";
import { getDateTime } from "utils";
import save from "save-file";
import { CircularProgress, DialogContent } from "@material-ui/core/";

import { ReactComponent as ArrowIcon } from "../../../../../assets/svgs/arrow-back.svg";
import { ReactComponent as Sync } from "../../../../../assets/svgs/sync.svg";
import { Button } from "../../../../../components/Button";
import { Dialog } from "../../../components/Dialog";
import { Loader } from "../../../components/Loader";
import { Table } from "../../../components/Table";
import { TextInput } from "../../../components/TextInput";
import {
  BackButtonWrapper,
  Box,
  ClearOutlinedButton,
  Content,
  DescriptionLabel,
  Header,
  LabelDefault,
  Line,
  List,
  MergeRow,
  Row,
  SecondMergeSpan,
  Spacing,
  SubTitleInputModal,
  Title,
  TitleContainer,
  TitleInputModal,
} from "../../../assets/styles/global";

interface IControlTable {
  offset: number;
  limit: number;
  page: number;
}

interface ITypeDataLotDetails {
  id: number;
  partnerFileId: number;
  statusId: string | null;
  status: api.ProspectStatus;
  partnerId: string | null;
  partnerName: string | null;
  partnerFileStatusDescription: string | null;
  partnerFileStatusName: string | null;
  partnerFileDescription: string | null;
}

type ITypeDataHistoric = api.PartnerNotification;

const initialStateTable: IControlTable = {
  offset: 0,
  limit: 10,
  page: 0,
};

export const Historic: React.FC = ({ match }: any) => {
  const { generalStore, partnerStore, routerStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [partnerIdForReq, setPartnerIdForReq] = useState("");
  const [addHistoric, setAddHistoric] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [resultsHistoric, setResultsHistoric] = useState<ITypeDataHistoric[]>([]);
  const [partnerDataLotDetails, setPartnerDataLotDetails] = useState<ITypeDataLotDetails[]>([]);
  const [historicData, setHistoricData] = useState<ITypeDataHistoric[]>([]);
  const [controlTable, setControlTable] = useState<IControlTable>(initialStateTable);
  const [labelNamePartner, setLabelNamePartner] = useState<string>("");
  const [labelDescriptionPartner, setLabelDescriptionPartner] = useState<string>("");
  const [labelStatusPartner, setlabelStatusPartner] = useState<string>("");
  const [controlDownload, setControlDownload] = useState(false);

  async function getDataHistoric(partnerFileId: number) {
    try {
      setLoading(true);
      await partnerStore.getPartnerPositivizeHistoric(partnerFileId);
      const list: ITypeDataHistoric[] = [];

      partnerStore.partnerPositivizeHistoric?.forEach(historic => {
        if (historic.campaignId) {
          list.push({
            campaignId: historic.campaignId,
            campaignDate: historic.campaignDate,
            campaignDescription: historic.campaignDescription,
            partnerFileId: historic.partnerFileId,
            campaignPositiveDate: historic.campaignPositiveDate,
            statusDetail: historic.statusDetail,
            campaignPositiveStatusDescription: historic.campaignPositiveStatusDescription,
          });
        }
      });
      setHistoricData(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function getDataLotDetails(partnerFileId: string) {
    try {
      setLoading(true);
      await partnerStore.getPartnerDataLotDetails(partnerFileId);
      const list: ITypeDataLotDetails[] = [];

      partnerStore.partnerDataLotDetails?.forEach(partnerDetails => {
        if (partnerDetails.id) {
          list.push({
            id: partnerDetails.id,
            partnerFileId: partnerDetails.partnerFileId,
            statusId: partnerDetails.statusId,
            status: partnerDetails.status,
            partnerId: partnerDetails.partnerId,
            partnerName: partnerDetails.partnerName,
            partnerFileStatusDescription: partnerDetails.partnerFileStatusDescription,
            partnerFileStatusName: partnerDetails.partnerFileStatusName,
            partnerFileDescription: partnerDetails.partnerFileDescription,
          });
        }
      });
      setPartnerDataLotDetails(list);
      if (list.length > 0 && list[0].partnerId !== null) {
        setPartnerIdForReq(list[0].partnerId);
      }
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
      if (
        partnerStore.partnerDataLotDetails &&
        partnerStore.partnerDataLotDetails.length > 0 &&
        partnerStore.partnerDataLotDetails[0].partnerName &&
        partnerStore.partnerDataLotDetails[0].partnerFileDescription &&
        partnerStore.partnerDataLotDetails[0].partnerFileStatusName
      ) {
        setLabelNamePartner(partnerStore.partnerDataLotDetails[0].partnerName);
        setLabelDescriptionPartner(partnerStore.partnerDataLotDetails[0].partnerFileDescription);
        setlabelStatusPartner(partnerStore.partnerDataLotDetails[0].partnerFileStatusName);
      }

      if (partnerStore.partnerDataLotDetails && partnerStore.partnerDataLotDetails.length > 0) {
        if (partnerStore.partnerDataLotDetails[0].statusId === "cancel") {
          setControlDownload(true);
        } else {
          setControlDownload(false);
        }
      }
    }
  }

  useEffect(() => {
    getDataHistoric(parseInt(match.params.id, 10));
  }, [addHistoric]);

  useEffect(() => {
    setResultsHistoric(historicData);
  }, [historicData]);

  useEffect(() => {
    setControlTable(controlTable);
  }, [controlTable]);

  useEffect(() => {
    getDataLotDetails(match.params.id);
    getDataHistoric(parseInt(match.params.id, 10));
  }, []);

  function onCloseAdd() {
    setDescription("");
    setAddHistoric(false);
  }

  async function sendHistoric() {
    if (description) {
      const partnerFileId = parseInt(match.params.id, 10);
      const campaignDescription = description;
      const partnerId = partnerIdForReq;

      try {
        setLoading(true);
        await api.createHistoricByPartnerFileId(partnerFileId, campaignDescription, partnerId);
        setDescription("");
      } catch (err) {
        if (err.message) {
          const message = JSON.parse(err.message);

          generalStore.showToast(message.message);
        }
      } finally {
        setLoading(false);
        setAddHistoric(false);
      }
    }
  }

  function onShowTipping() {
    setAddHistoric(true);
  }

  function renderData() {
    if (loading) {
      return <Loader />;
    }

    return getTable();
  }

  const sendDownload = async (buffer: Buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `histórico-de-campanha.xlsx`);
  };

  const handleDownload = async () => {
    setLoadingDownload(true);
    const partnerFileId = parseInt(match.params.id, 10);
    const buffer = await api.getDownloadListForNotificationByPartnerFileId(partnerFileId);

    setLoadingDownload(false);
    await sendDownload(buffer);
  };

  function renderLabelInfo() {
    return (
      <List>
        <li title={labelNamePartner ? labelNamePartner : "Todos"}>
          <LabelDefault>{labelNamePartner ? labelNamePartner : "Todos"}</LabelDefault>
        </li>
        <li title={labelDescriptionPartner}>
          <LabelDefault>{labelDescriptionPartner}</LabelDefault>
        </li>
        <li title={labelStatusPartner}>
          <LabelDefault>{labelStatusPartner}</LabelDefault>
        </li>
      </List>
    );
  }

  function renderAdd() {
    return (
      <BackButtonWrapper
        label="Adicionar"
        onClick={onShowTipping}
        disabled={
          !generalStore.hasPermission(api.AdminUserPermission.sendMensPositivize) || labelStatusPartner === "Cancelado"
        }
      />
    );
  }

  function reloadData() {
    getDataHistoric(parseInt(match.params.id, 10));
  }

  function getTable() {
    const columns = ["Data Campanha", "Status Campanha", "Detalhe Status", "Descrição"];

    function handleChangePage(page: number) {
      setControlTable({ ...controlTable, offset: controlTable.limit * page, page });
    }

    const renderLabelDownload = () => {
      if (loadingDownload) {
        return (
          <>
            Preparando arquivo para baixar <CircularProgress size={15} style={{ marginLeft: 5 }} />
          </>
        );
      }

      return "Baixar cadastros";
    };

    return (
      <>
        <Table
          titles={columns}
          ids={resultsHistoric.map(item => item.campaignId.toString())}
          data={resultsHistoric.map((item, index) => {
            if (index >= controlTable.offset && index < controlTable.limit * (controlTable.page + 1)) {
              return [
                <MergeRow key={index}>
                  <span>{moment(item.campaignPositiveDate).format("l")}</span>
                  <SecondMergeSpan>{getDateTime(item.campaignPositiveDate)}</SecondMergeSpan>
                </MergeRow>,
                item.statusDetail ? item.statusDetail : "Sem status",
                item.campaignPositiveStatusDescription ? item.campaignPositiveStatusDescription : "Sem detalhes",
                item.campaignDescription,
              ];
            }

            return [];
          })}
          isPagination
          totalRows={resultsHistoric.length}
          rowsPerPage={controlTable.limit}
          rowsPerPageOptions={[controlTable.limit]}
          page={controlTable.offset / controlTable.limit}
          handleChangePage={handleChangePage}
        />
        <ClearOutlinedButton
          iconComponent={<SvgFileDownload size={15} style={{ color: "#515251", marginRight: 10 }} />}
          label={renderLabelDownload()}
          onClick={handleDownload}
          style={{ position: "relative", bottom: 40 }}
          disabled={historicData.length === 0 || controlDownload}
        />
      </>
    );
  }

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>Promoção de positivação - Histórico de campanhas</Title>
        </TitleContainer>
        <Button
          theme="neutral"
          borderType="semi-circle"
          onClick={() => routerStore.push(openingAccountsSectionPath("positivize"))}
        >
          <ArrowIcon />
          <span>Voltar para a lista</span>
        </Button>
      </Header>
      <Box>
        <Row>
          <Row></Row>
          <Row>{partnerDataLotDetails.length > 0 ? renderLabelInfo() : ""}</Row>
        </Row>
        <Line />
        <Spacing />
        <Row>
          <ClearOutlinedButton
            label="Recarregar Tabela"
            onClick={() => reloadData()}
            iconComponent={<Sync width="30px" height="20px" />}
          />
          <Content style={{ maxWidth: "100%", justifyContent: "flex-end" }}>{renderAdd()}</Content>
        </Row>
      </Box>
      {renderData()}
      <Dialog
        showCloseButton
        title={"Adicionar Campanha"}
        isOpen={addHistoric}
        onClose={onCloseAdd}
        padding="0 20px 20px"
      >
        <Spacing />
        <SubTitleInputModal>Serão enviados SMSs para todos os cadastros ainda não positivados.</SubTitleInputModal>
        <TitleInputModal>Descrição</TitleInputModal>
        <TextInput
          // Error={this.getError("managerId")}
          name="description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          maxLength={30}
        />
        <DescriptionLabel>max. 30 caracteres</DescriptionLabel>
        <Spacing />
        <BackButtonWrapper
          label={loading ? <CircularProgress size={15} style={{ color: "#FFF" }} /> : "Confirmar"}
          onClick={sendHistoric}
        />
      </Dialog>
    </>
  );
};
