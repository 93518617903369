import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { DefaultLimitsFormated, PfPj } from "api/manager-api";
import { IPartnerIdLimit } from "apps/manager/stores";
import { SvgError } from "legacy/icons/SvgError";

import { Loader } from "../../../../../../legacy/components/Loader";
import { TransactionalBoundariesStore } from "../../../../stores/TransactionalBoundariesStore";
import { Table } from "../../../../components/Table";
import {
  AlertModal,
  ContainerInput,
  ErrorMessage,
  ErrorText,
  FooterTable,
  Input,
  Label,
  Right,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextArea,
  TablesContainer,
  Title,
  WrapperSwitch,
} from "./style";
import { currencyFormatter } from "../../../../../../utils";
import { Switch } from "../../../../components/Switch";
import { Dialog } from "../../../../components/Dialog";

interface IProps {
  personType: PfPj;
  createConfiguration: (ref: boolean) => void;
  selectedPartner: IPartnerIdLimit | null;
  transactionalBoundariesStore?: TransactionalBoundariesStore;
}

@inject("transactionalBoundariesStore")
@observer
export class SafetyLimitsConfiguration extends React.Component<IProps> {
  private MAXAMOUNT = 999999999;

  @observable private notEdited = true;

  @observable private empty = false;

  @observable private errorOpen = false;

  @observable private errorMessage = "";

  @observable private observation = "";

  @observable private saveDialogOpen = false;

  @observable private loading = true;

  @observable private securityLimits: DefaultLimitsFormated[] = [];

  public componentDidMount = () => {
    this.getData();
  };

  @action
  private getData = async () => {
    try {
      this.loading = true;
      await this.props.transactionalBoundariesStore!.getConfigurationSecurity(
        this.props.selectedPartner?.value || null,
      );
      this.securityLimits =
        this.props.personType === PfPj.pf
          ? this.props.transactionalBoundariesStore!.securityResponse!.pf
          : this.props.transactionalBoundariesStore!.securityResponse!.pj;
    } catch (err) {
      this.errorMessage = err.message || "Ocorreu um erro ao obter as informações";
    } finally {
      this.loading = false;
    }
  };

  private checkNull = (e: DefaultLimitsFormated[]) => {
    let count = 0;

    e.forEach(lim => {
      if (lim.operation === 0 || lim.daily === 0) {
        count++;
      }
    });
    if (count > 0) {
      this.empty = true;
    } else {
      this.empty = false;
    }
  };

  private saveSafetyLimit = async () => {
    let success = true;
    const security =
      this.props.personType === PfPj.pf
        ? {
            ...this.props.transactionalBoundariesStore!.securityResponse!,
            observation: this.observation,
            pf: this.securityLimits,
          }
        : {
            ...this.props.transactionalBoundariesStore!.securityResponse!,
            observation: this.observation,
            pj: this.securityLimits,
          };

    try {
      await this.props.transactionalBoundariesStore!.updateSecurity(
        this.props.selectedPartner?.value || null,
        security,
        this.props.personType,
      );
    } catch {
      this.errorOpen = true;
      success = false;
    } finally {
      this.loading = false;
      this.props.createConfiguration(success);
    }
  };

  public render() {
    const columns = ["Transação", "Limite por transação*", "Limite diário*", "Quantidade por dia", "Status"];

    return this.securityLimits.length > 0 ? (
      <>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"sm"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => {
            this.errorOpen = false;
            this.loading = false;
          }}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-50px 0 12px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>Não foi possível realizar a operação</ErrorText>
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"md"}
          title={`Deseja salvar as alterações para o Limite de Segurança - ${
            this.props.personType === PfPj.pf ? "PF" : "PJ"
          }?`}
          isOpen={this.saveDialogOpen}
          onClose={() => {
            this.saveDialogOpen = false;
            this.getData();
          }}
        >
          <StyledTextArea value={this.observation} onChange={e => (this.observation = e.target.value)} />
          <Right>
            <StyledOutlinedButton onClick={() => (this.saveDialogOpen = false)} label="Cancelar" type="submit" />
            <StyledRaisedButton
              onClick={async () => {
                this.loading = true;
                this.saveDialogOpen = false;
                this.saveSafetyLimit();
              }}
              label="Salvar"
              type="submit"
            />
          </Right>
        </Dialog>
        <Title>
          {this.props.selectedPartner ? `${this.props.selectedPartner.label} - ` : ""}
          {this.props.personType === PfPj.pf ? "Pessoa Física" : "Pessoa Jurídica"}
        </Title>
        {this.loading ? (
          <Loader />
        ) : (
          <>
            <TablesContainer>
              <Table
                titles={columns}
                ids={this.securityLimits!.map(e => e.title)}
                data={this.securityLimits.map((limit: any) => {
                  let operationFormat: string = limit.operation!.toString();
                  let dailyFormat: string = limit.daily!.toString();
                  const data = [
                    limit.title,
                    <Input
                      key={limit.title}
                      value={currencyFormatter(operationFormat)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        this.notEdited = false;
                        if (event.target.value === "") {
                          limit.operation = 0;
                        } else {
                          operationFormat = event.target.value;
                          limit.operation = parseFloat(operationFormat.replace(/,/gu, "").replace(/\./gu, ""));
                        }

                        if (limit.operation > limit.daily!) {
                          this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                        } else {
                          this.errorMessage = "";
                        }

                        this.checkNull(this.securityLimits);
                      }}
                    />,
                    <Input
                      key={limit.title}
                      value={currencyFormatter(dailyFormat)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        this.notEdited = false;
                        if (event.target.value === "") {
                          limit.daily = 0;
                        } else {
                          dailyFormat = event.target.value;
                          limit.daily = parseFloat(dailyFormat.replace(/,/gu, "").replace(/\./gu, ""));
                        }

                        if (limit.operation > limit.daily) {
                          this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                        } else {
                          this.errorMessage = "";
                        }

                        this.checkNull(this.securityLimits);
                      }}
                    />,
                    <ContainerInput key={limit.title}>
                      <Input
                        disabled={limit.amountPerDay === this.MAXAMOUNT}
                        value={limit.amountPerDay === this.MAXAMOUNT ? " " : limit.amountPerDay}
                        placeholder="Indicar a quantidade"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          this.notEdited = false;
                          if (event.target.value === "") {
                            limit.amountPerDay = 0;
                          } else {
                            limit.amountPerDay = Number(event.target.value);
                          }

                          this.checkNull(this.securityLimits);
                        }}
                      />
                      <WrapperSwitch>
                        <Switch
                          checked={limit.amountPerDay === this.MAXAMOUNT}
                          handleChange={() => {
                            this.notEdited = false;
                            limit.amountPerDay = limit.amountPerDay === this.MAXAMOUNT ? 0 : this.MAXAMOUNT;
                            this.checkNull(this.securityLimits);
                          }}
                        />
                        Ilimitado
                      </WrapperSwitch>
                    </ContainerInput>,
                    <WrapperSwitch key={limit.title}>
                      <Switch
                        checked={limit.isActive}
                        handleChange={() => {
                          this.notEdited = false;
                          limit.isActive = !limit.isActive;
                          this.checkNull(this.securityLimits);
                        }}
                      />
                      {limit.isActive ? "Ativado" : "Desativado"}
                    </WrapperSwitch>,
                  ];

                  return data;
                })}
              />
            </TablesContainer>
            <FooterTable>
              {this.errorMessage && (
                <ErrorMessage>
                  <SvgError
                    viewBox="0 0 82 82"
                    width="21"
                    height="auto"
                    style={{ margin: "0 8px -8px 0", position: "relative" }}
                  />
                  {this.errorMessage}
                </ErrorMessage>
              )}
              <Right>
                <Label>*Campos obrigatórios</Label>
                <StyledOutlinedButton
                  onClick={() => this.props.createConfiguration(false)}
                  label="Cancelar"
                  type="submit"
                />
                <StyledRaisedButton
                  onClick={() => (this.saveDialogOpen = true)}
                  disabled={this.notEdited || this.empty || Boolean(this.errorMessage)}
                  label="Salvar"
                  type="submit"
                />
              </Right>
            </FooterTable>
          </>
        )}
      </>
    ) : (
      <Loader />
    );
  }
}
