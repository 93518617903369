import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {}

export const SvgLogoDrawer = (props: IProps) => {
  return (
    <svg {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M101.722013,1.344 L101.722013,40.194 C101.722013,40.9362707 101.118301,41.538 100.373585,41.538 L94.9967296,41.538 C94.5486088,41.5385112 94.1291346,41.3184602 93.8758491,40.95 L67.1685535,2.1084 C66.8847201,1.69932881 66.8507475,1.167467 67.0802415,0.725822061 C67.3097354,0.284177125 67.7651718,0.00496173628 68.2641509,5.59638056e-16 L72.4779874,5.59638056e-16 C72.9284379,-0.00161511518 73.3495633,0.222458013 73.5988679,0.5964 L93.5471698,30.1224 C93.8187846,30.5147772 94.3143761,30.6861962 94.7714631,30.545868 C95.2285502,30.4055398 95.5414854,29.9858986 95.5445283,29.5092 L95.5445283,1.344 C95.5444647,0.983139586 95.6899961,0.637412766 95.9483702,0.38462679 C96.2067443,0.131840814 96.5562534,-0.00676613844 96.918239,2.61211408e-16 L100.382013,2.61211408e-16 C101.126729,3.0669369e-16 101.73044,0.601729296 101.73044,1.344 M68.1040252,16.0524 L71.0284277,16.0524 C71.7731437,16.0524 72.3768553,16.6541293 72.3768553,17.3964 L72.3768553,40.194 C72.3768553,40.9362707 71.7731437,41.538 71.0284277,41.538 L68.0955975,41.538 C67.3508814,41.538 66.7471698,40.9362707 66.7471698,40.194 L66.7471698,17.4048 C66.7471698,16.6578901 67.3546546,16.0524 68.1040252,16.0524"
          fill="#FFFFFF"
          fillRule="nonzero"
        />

        <path
          d="M126.634214,9.54839913e-16 L131.850943,9.54839913e-16 C132.369124,-0.00120814741 132.832531,0.321290404 133.009919,0.80656582 C133.187308,1.29184123 133.040656,1.83587936 132.643145,2.1672 L115.678239,16.38 C115.427471,16.5891365 115.27053,16.8891565 115.242068,17.213819 C115.213606,17.5384814 115.315963,17.8610842 115.526541,18.1104 L133.536478,39.522 C133.84483,39.8872551 133.912528,40.3976567 133.709996,40.8302291 C133.507464,41.2628015 133.071507,41.5389361 132.592579,41.538 L127.207296,41.538 C126.837605,41.5370737 126.487924,41.3705182 126.254969,41.0844 L107.242138,17.7072 C106.843366,17.192535 106.913395,16.4578159 107.402264,16.0272 L125.833585,0.294 C126.057116,0.104601729 126.34083,0.000418680909 126.634214,1.0919305e-15"
          fill="#FFFFFF"
        />

        <path
          d="M23.4542138,18.2196 C25.4843885,16.1704974 26.6202724,13.4050222 26.6145912,10.5252 C26.6145912,4.7628 22.1142138,0 16.5013836,0 L1.11245283,0 C0.498062097,-3.7497052e-17 -7.27602572e-16,0.496426669 -8.02843662e-16,1.1088 L-8.02843662e-16,3.99 C-7.27602572e-16,4.60237333 0.498062097,5.0988 1.11245283,5.0988 L16.5013836,5.0988 C18.6672956,5.0988 20.4455346,7.6188 20.4455346,10.5252 C20.4455346,13.4316 18.76,15.8928 16.6699371,16.0608 L10.770566,16.0608 C10.1561753,16.0608 9.65811321,16.5572267 9.65811321,17.1696 L9.65811321,20.0508 C9.65811321,20.6631733 10.1561753,21.1596 10.770566,21.1596 L16.7373585,21.1596 C20.0157233,21.3276 22.6872956,24.7044 22.6872956,28.8036 C22.6872956,32.9028 19.9061635,36.4392 16.4592453,36.4392 L6.84327044,36.4392 C6.1937952,36.4395958 5.66082201,35.9269469 5.63811321,35.28 L5.63811321,17.4132 C5.6335758,16.6714373 5.03384922,16.0699439 4.28968553,16.0608 L1.35685535,16.0608 C0.999227994,16.0585652 0.655358182,16.198026 0.400896921,16.4485011 C0.146435659,16.6989761 0.00222818631,17.0399469 -6.53138212e-16,17.3964 L-6.53138212e-16,40.4292 C-0.000731372859,40.7256077 0.117636403,41.0099676 0.328679245,41.2188 C0.534924858,41.4227349 0.813506908,41.5374236 1.10402516,41.538 L16.5013836,41.538 C23.4488711,41.42751 28.993266,35.7283923 28.8900629,28.8036 C28.9271781,24.6010387 26.8963899,20.6469439 23.4542138,18.2196"
          fill="#AEADB3"
        />

        <path
          d="M57.0216352,18.2196 C59.0518098,16.1704974 60.1876938,13.4050222 60.1820126,10.5252 C60.1820126,4.7628 55.6816352,0 50.068805,0 L34.6798742,0 C34.0654835,-3.7497052e-17 33.5674214,0.496426669 33.5674214,1.1088 L33.5674214,3.99 C33.5674214,4.60237333 34.0654835,5.0988 34.6798742,5.0988 L50.068805,5.0988 C52.234717,5.0988 54.012956,7.6188 54.012956,10.5252 C54.012956,13.4316 52.3274214,15.8928 50.2373585,16.0608 L44.3379874,16.0608 C43.7235967,16.0608 43.2255346,16.5572267 43.2255346,17.1696 L43.2255346,20.0508 C43.2255346,20.6631733 43.7235967,21.1596 44.3379874,21.1596 L50.3047799,21.1596 C53.5831447,21.3276 56.254717,24.7044 56.254717,28.8036 C56.254717,32.9028 53.4735849,36.4392 50.0266667,36.4392 L40.4528302,36.4392 C40.1331496,36.450536 39.8220622,36.3347307 39.588105,36.1172978 C39.3541479,35.8998648 39.2165171,35.5986445 39.2055346,35.28 L39.2055346,17.4132 C39.2009972,16.6714373 38.6012706,16.0699439 37.8571069,16.0608 L34.9242767,16.0608 C34.5645093,16.0562819 34.218033,16.1961571 33.9628275,16.4489447 C33.707622,16.7017323 33.56513,17.0461927 33.5674214,17.4048 L33.5674214,40.4292 C33.56669,40.7256077 33.6850578,41.0099676 33.8961006,41.2188 C34.1023462,41.4227349 34.3809283,41.5374236 34.6714465,41.538 L50.068805,41.538 C57.0162925,41.42751 62.5606873,35.7283923 62.4574843,28.8036 C62.4945995,24.6010387 60.4638113,20.6469439 57.0216352,18.2196"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
};
