/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-negated-condition */
/* eslint@typescript-eslint/promise-function-async */
/* eslint-disable-next-line implicit-arrow-linebreak */

import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { SvgArrowRepeat } from "legacy/icons/SvgArrowRepeat";
import { formatDate, openingAccountsSectionWithId } from "@manager/utils";
import { dateFormatter, timeFormatterOriginal } from "utils";
import { LoadingIndicator } from "react-select/src/components/indicators";
import { CircularProgress } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { Modal } from "components/Modal";

import { Dialog } from "../../components/Dialog";
import { TextInput } from "../../components/TextInput";
import { CheckboxList } from "../../components/CheckboxList";
import { Loader } from "../../components/Loader";
import { Table } from "../../components/Table";
import { Container, ListModal } from "./styled";
import {
  BackButtonWrapper,
  Box,
  ClearOutlinedButton,
  Content,
  ContentTable,
  DescriptionLabel,
  Header,
  InputFile,
  LabelInputFile,
  Line,
  MergeRow,
  Row,
  SecondMergeSpan,
  Spacing,
  StyledSelect,
  StyledSelectDialog,
  Title,
  TitleContainer,
  TitleInputModal,
} from "../../assets/styles/global";

interface IFilter {
  partnerActiveLot: ITypeFilterOptionActive | null;
  partnerLot: ITypeFilterOption | null;
  typesFilter: string[];
}

interface IControlTable {
  offset: number;
  limit: number;
  page: number;
}

interface ITypeDataLot {
  auditDateTimeRegistration: Date;
  partnerFileStatusShortDescription: string;
  partnerFileShortDescription: string;
  partnerFileId: number;
  partnerId: string;
  statusId: string;
  statusName: string;
  listProspectStatus: api.PartnerFileStatus[];
  partnerName: string | null;
}

interface ITypeFilterOption {
  value: string | null;
  label: string | null;
}

interface ITypeFilterOptionActive {
  value: string | null;
  label: string | null;
}

export const initialStateFilter: IFilter = {
  partnerActiveLot: null,
  partnerLot: { label: "Todos", value: "all" },
  typesFilter: ["criticized", "proc", "pending", "loading"],
};

export const initialStateTable: IControlTable = {
  offset: 0,
  limit: 10,
  page: 0,
};

export const TippingAcconts: React.FC = () => {
  const { generalStore, partnerStore, routerStore } = useStores();
  const [filter, setFilter] = useState<IFilter>(initialStateFilter);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [description, setDescription] = useState("");
  const [fileInputName, setFileInputName] = useState("");
  const [controClickOnRow, setControClickOnRow] = useState<boolean>(true);
  const [showDetailStatusProgress, setShowDetailStatusProgress] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [partnerFileId, setPartnerFileId] = useState<number>(0);
  const [detailStatusProgressDescription, setDetailStatusProgressDescription] = useState<string>("");
  const [defaultDescription, setDefaultDescription] = useState<boolean>(false);
  const [controlReq, setControlReq] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<number[]>([]);
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<ITypeDataLot[]>([]);
  const [partnerOptionsActive, setPartnerOptionsActive] = useState<ITypeFilterOptionActive[]>([]);
  const [partnerOption, setPartnerOption] = useState<ITypeFilterOption[]>([]);
  const [partnerDataLot, setPartnerDataLot] = useState<ITypeDataLot[]>([]);
  const [fileSelected, setFileSelected] = React.useState<Buffer | null>(null);
  const [controlTable, setControlTable] = useState<IControlTable>(initialStateTable);

  async function getPartnersActive() {
    try {
      setLoading(true);
      await partnerStore.getPartnersNamesLot();

      const list: ITypeFilterOptionActive[] = [];

      partnerStore.partnersNamesLot?.forEach(partner => {
        if (partner.id) {
          list.push({ label: partner.name, value: partner.id });
        }
      });
      setPartnerOptionsActive(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (partnerOptionsActive.length > 0) {
      setFilter({ ...filter, partnerActiveLot: partnerOptionsActive[0] });
    }
  }, [partnerOptionsActive]);

  async function getPartners() {
    try {
      setLoading(true);
      await partnerStore.getPartnerIdLot();

      const list: ITypeFilterOption[] = [];

      list.push({ label: "Todos", value: "all" });

      partnerStore.partnersIdLot?.forEach(partner => {
        list.push({ label: partner.partnerName, value: partner.partnerId });
      });

      setPartnerOption(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function getDataLot(partnerIdDataLot: string) {
    try {
      setLoading(true);
      await partnerStore.getPartnerDataLot(partnerIdDataLot);
      const list: ITypeDataLot[] = [];

      partnerStore.partnersDataLot?.forEach(partner => {
        if (partner.partnerFileId) {
          list.push({
            auditDateTimeRegistration: partner.auditDateTimeRegistration,
            partnerFileStatusShortDescription: partner.partnerFileStatusShortDescription,
            partnerFileShortDescription: partner.partnerFileShortDescription,
            partnerFileId: partner.partnerFileId,
            partnerId: partner.partnerId,
            statusId: partner.statusId,
            statusName: partner.statusName,
            listProspectStatus: partner.listProspectStatus,
            partnerName: partner.partnerName,
          });
        }
      });
      setPartnerDataLot(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function renderDetailStatusDescription(id: number) {
    try {
      if (!defaultDescription) {
        const response = await api.getPartnerFileStatusDescription(id);

        if (response) {
          setDetailStatusProgressDescription(response);
        }
      }
    } catch (error) {
      setDetailStatusProgressDescription(error.message);
    }
  }

  const changeControl = () => {
    let aux = 0;

    const id = setInterval(() => {
      setControlReq(aux++);
    }, 2000);

    setIntervalId([...intervalId, id]);
  };

  useEffect(() => {
    let list: ITypeDataLot[] = partnerDataLot;

    if (filter.partnerLot && filter.partnerLot.value && filter.partnerLot.value !== "all") {
      list = list.filter(rowFilt => rowFilt.partnerId === filter.partnerLot?.value);
    }

    if (filter.typesFilter.length > 0) {
      list = list.filter(rowFilt => filter.typesFilter.includes(rowFilt.statusId));
    }

    setResults(list);
  }, [filter]);

  useEffect(() => {
    setResults(partnerDataLot);
    setFilter(initialStateFilter);
    getPartners();
  }, [partnerDataLot]);

  // UseEffect(() => {}, [fileSelected]);
  useEffect(() => {
    setVisible(!visible);
    if (!visible) {
      getPartnersActive();
    }

    if (showDetailStatusProgress && !defaultDescription && detailStatusProgressDescription !== "Criticado") {
      changeControl();
    }
  }, [showDetailStatusProgress]);
  useEffect(() => {
    if (partnerFileId !== 0) {
      renderDetailStatusDescription(partnerFileId);
    }
  }, [partnerFileId]);
  useEffect(() => {
    if (showDetailStatusProgress) {
      renderDetailStatusDescription(partnerFileId);
    } else {
      intervalId.forEach(clearInterval);
    }
  }, [controlReq]);

  useEffect(() => {
    setControlTable(controlTable);
  }, [controlTable]);

  useEffect(() => {
    getPartnersActive();
    getDataLot("all");
  }, []);

  useEffect(() => {
    if (detailStatusProgressDescription === "Processamento Finalizado com Sucesso.") {
      getDataLot("all");
      setShowDetailStatusProgress(!showDetailStatusProgress);
    }
  }, [detailStatusProgressDescription]);

  const clearFilter = () => {
    setFilter(initialStateFilter);
  };

  function renderClear() {
    return (
      <ClearOutlinedButton
        label="Limpar"
        onClick={() => clearFilter()}
        iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
      />
    );
  }

  function onShowTipping() {
    setDialogOpen(true);
  }

  function onCloseTipping() {
    setDescription("");
    getPartnersActive();
    setDialogOpen(false);
  }

  function renderAdd() {
    return (
      <BackButtonWrapper
        label="Adicionar"
        onClick={onShowTipping}
        disabled={!generalStore.hasPermission(api.AdminUserPermission.addTipping)}
      />
    );
  }

  function handleSetPartner(partner: ITypeFilterOption) {
    setFilter({ ...filter, partnerLot: partner });
  }

  function handleSetPartnerActive(partner: ITypeFilterOptionActive) {
    setFilter({ ...filter, partnerActiveLot: partner });
  }

  function handleChangePage(page: number) {
    setControlTable({ ...controlTable, offset: controlTable.limit * page, page });
  }

  function renderPartnerFilter() {
    return (
      <StyledSelect
        onChange={handleSetPartner}
        placeholder="Parceiro"
        value={filter.partnerLot}
        options={partnerOption}
      />
    );
  }

  function renderPartnerActiveFilter() {
    return (
      <StyledSelectDialog
        name="partner"
        placeholder="Parceiro"
        onChange={handleSetPartnerActive}
        value={filter.partnerActiveLot}
        options={partnerOptionsActive}
      />
    );
  }

  function handleSelectOption(typesFilter: string[]) {
    setFilter({ ...filter, typesFilter });
  }

  function renderCheckFilter() {
    const data = [
      {
        label: "Criticado",
        value: "criticized",
      },
      {
        label: "Em processamento",
        value: "proc" || "loading",
      },
      {
        label: "Cancelado",
        value: "canceled",
      },
    ];

    return <CheckboxList items={data} selected={filter.typesFilter} onChange={handleSelectOption} />;
  }

  function renderData() {
    if (loading) {
      return <Loader />;
    }

    return getTable();
  }

  const handleHistoric = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    routerStore.push(`${openingAccountsSectionWithId("tipping")}/anexos/${id}`);
  };

  function onClickRow(id: string) {
    let control = false;

    if (results.length > 0 && controClickOnRow) {
      results.forEach(item => {
        if (item.partnerFileId === parseInt(id, 10) && item.statusId !== "proc" && item.statusId !== "loading") {
          control = true;
        }
      });

      if (control && controClickOnRow) {
        routerStore.push(`${openingAccountsSectionWithId("tipping")}/detalhes/${id}`);
      }
    }
  }

  function onChangeFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    const file = files !== null ? files[0] : null;
    const name = file !== null ? file.name : "";
    const type = file && file.type !== "" ? file.type : "xlsx";

    setFileInputName(name);

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (reader.result && typeof reader.result !== "string") {
          const fileBuffer = Buffer.from(reader.result);

          setFileSelected(fileBuffer);
          setFileInputName(name);
          setFileType(type);
        }
      });

      reader.readAsArrayBuffer(file);
    }
  }

  async function sendDataReq() {
    if (filter.partnerActiveLot && filter.partnerActiveLot.value && fileSelected && !loading) {
      const idPartner = filter.partnerActiveLot.value.toString();
      const txtDescription = description;
      const file = fileSelected;
      let response = null;

      try {
        setLoading(true);
        response = await api.uploadPartnerFile(idPartner, txtDescription, fileInputName, fileType, file);
        setFileInputName("");
        setFileSelected(null);
      } catch (err) {
        generalStore.showToast(err.message);
      } finally {
        setLoading(false);
        getDataLot("all");
        setDialogOpen(false);
      }

      onCloseTipping();
    }
  }

  const getStatusFromArray = (type: string, listProspectStatus: api.PartnerFileStatus[]) => {
    let qty = 0;

    listProspectStatus.forEach(item => {
      if (item.statusId === type) {
        qty = item.total;
      }
    });

    return qty;
  };

  const getTotal = (listProspectStatus: api.PartnerFileStatus[]) => {
    let qty = 0;

    listProspectStatus.forEach(item => {
      qty += item.total;
    });

    return qty;
  };

  function getTable() {
    const columns = [
      "Data Tombamento",
      "Descrição Tombamento",
      "Parceiro",
      "Status",
      "Detalhe Status",
      "Total Registros",
      "Críticas Restritivas",
      "Críticas Informativas",
      "Válidos",
      "Positivados",
      "Cancelados",
      "Anexo",
    ];

    const onCloseModal = () => {
      getDataLot("all");
      // SetDetailStatusProgressDescription("");
      setDefaultDescription(!defaultDescription);
      setShowDetailStatusProgress(!showDetailStatusProgress);
    };

    const renderDialogDetail = () => {
      return (
        <Dialog
          showCloseButton
          title={"Detalhe Status"}
          isOpen={showDetailStatusProgress}
          onClose={onCloseModal}
          padding="0 20px 20px"
        >
          <Spacing />
          <Box>
            <Row>
              Status:
              {detailStatusProgressDescription === "" ? (
                <CircularProgress size={15} style={{ color: "#ed2980" }} />
              ) : (
                detailStatusProgressDescription
              )}
            </Row>
          </Box>
        </Dialog>
      );
    };

    const renderSetModal = (id: number) => {
      setShowDetailStatusProgress(!showDetailStatusProgress);
      setPartnerFileId(id);
    };

    const renderDetailStatus = (item: ITypeDataLot) => {
      return (
        <div onClick={e => e.stopPropagation()}>
          <MoreHorizIcon
            style={{ border: "1px solid #DCDCE0", borderRadius: "4px", padding: "2px 2px" }}
            key={item.partnerFileId}
            onClick={() => {
              if (item.statusName === "Criticado" || item.statusName === "Cancelado") {
                setDefaultDescription(!detailStatusProgressDescription);
                setDetailStatusProgressDescription(item.statusName);
              }

              renderSetModal(item.partnerFileId);
            }}
          />
        </div>
      );
    };

    const renderTableData = (): Array<Array<string | number | JSX.Element | null>> => {
      return results.map((item, index) => {
        if (index >= controlTable.offset && index < controlTable.limit * (controlTable.page + 1)) {
          return [
            <MergeRow key={index}>
              <span>{item.auditDateTimeRegistration && formatDate(item.auditDateTimeRegistration)}</span>
              <SecondMergeSpan>
                {item.auditDateTimeRegistration &&
                  timeFormatterOriginal.format(Date.parse((item.auditDateTimeRegistration as any) as string))}
              </SecondMergeSpan>
            </MergeRow>,
            item.partnerFileShortDescription,
            item.partnerName,
            item.statusName,
            renderDetailStatus(item),
            getTotal(item.listProspectStatus),
            getStatusFromArray("restriction", item.listProspectStatus),
            getStatusFromArray("briefing", item.listProspectStatus),
            getStatusFromArray("validated", item.listProspectStatus),
            getStatusFromArray("positive", item.listProspectStatus),
            getStatusFromArray("cancel", item.listProspectStatus),
            <MoreHorizIcon
              style={{ border: "1px solid #DCDCE0", borderRadius: "4px", padding: "2px 2px" }}
              key={item.partnerFileId}
              onClick={e => handleHistoric(e, item.partnerFileId.toString())}
            />,
          ];
        }

        return [];
      });
    };

    return (
      <>
        <Table
          customPadding="4px 33px 4px 33px"
          titles={columns}
          ids={results.map(item => item.partnerFileId.toString())}
          data={renderTableData()}
          isPagination
          onClickRow={onClickRow}
          totalRows={results.length}
          rowsPerPage={controlTable.limit}
          rowsPerPageOptions={[controlTable.limit]}
          page={controlTable.offset / controlTable.limit}
          handleChangePage={handleChangePage}
        />
        {renderDialogDetail()}
      </>
    );
  }

  return (
    <>
      <Header>
        <TitleContainer>
          <Title>Tombamento de Base</Title>
        </TitleContainer>
        {renderAdd()}
        <Dialog showCloseButton title={"Adicionar"} isOpen={dialogOpen} onClose={onCloseTipping} padding="0 20px 20px">
          <Spacing />
          {renderPartnerActiveFilter()}
          <Spacing />
          <TitleInputModal>Descrição tombamento</TitleInputModal>
          <TextInput
            // Error={this.getError("managerId")}
            name="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            maxLength={30}
          />
          <DescriptionLabel>max. 30 caracteres</DescriptionLabel>
          <TitleInputModal>Upload da planilha</TitleInputModal>
          <LabelInputFile htmlFor="fileSelect">
            <span>Upload</span>
            {fileInputName ? fileInputName : "Selecione arquivo..."}
          </LabelInputFile>
          <InputFile type="file" id="fileSelect" name="fileTipping" onChange={e => onChangeFile(e)} accept=".xlsx" />
          <Spacing />
          <BackButtonWrapper
            label={loading ? <CircularProgress size={15} style={{ color: "#FFF" }} /> : "Salvar"}
            onClick={sendDataReq}
          />
        </Dialog>
      </Header>
      <Box>
        <Row>
          {renderPartnerFilter()}
          {renderClear()}
        </Row>

        <Spacing />
        <Line />
        <Spacing />
        <Content>{renderCheckFilter()}</Content>
      </Box>
      <ContentTable>{renderData()}</ContentTable>
    </>
  );
};
