import * as React from "react";
import TableContainer from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter, TablePagination, Checkbox } from "@material-ui/core";
import { noop } from "rxjs";
import styled from "styled-components";
import { colors } from "../../utils/style";

interface IProps {
  titles: React.ReactNode[];
  data: React.ReactNode[][];
  ids: string[];
  onClickRow?: (id: string) => void;
  selectedRow?: string;
  trStyles?: React.CSSProperties[];
  tdStyles?: React.CSSProperties;
  page?: number;
  rowsPerPage?: number;
  isPagination?: boolean;
  handleChangePage?: (value: number) => void;
  handleChangeRowsPerPage?: (value: string) => void;
  totalRows?: number;
  rowsPerPageOptions?: number[];
  customPadding?: string;
}

const StyledTableHead = styled(TableHead)`
  padding-bottom: 8px !important;
  border: 0 !important;
` as any;

interface StyledTableCellProps {
  header?: boolean;
  customPadding?: string;
  textAlign?: string;
}

const StyledTableCell = styled.td<StyledTableCellProps>`
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: ${props => (props.header ? "600" : "normal")} !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: normal !important;
  letter-spacing: 0.6px !important;
  color: ${props => (props.header ? colors.gray : colors.black)} !important;
  padding: ${props =>
    props.customPadding
      ? `${props.customPadding} !important`
      : `${props.header ? "24px" : 0} 42px
    0 24px !important`};
  border-bottom: ${props => (props.header ? "#ebebeb" : "#f0f0f0")} 1px solid !important;
  min-width: 140px;
`;

export class Table extends React.Component<IProps, {}> {
  public render() {
    const { titles, data, onClickRow, ids, trStyles, tdStyles, customPadding } = this.props;

    return (
      <TableContainer>
        <StyledTableHead>
          <TableRow>
            {titles.map((e, i) => (
              <StyledTableCell header={true} key={i} style={trStyles && trStyles[i]} customPadding={customPadding}>
                {e}
              </StyledTableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {data.map((row: any, idx: number) => {
            if (Object.keys(row).length > 0) {
              return (
                <TableRow
                  style={{ cursor: "pointer" }}
                  selected={this.props.selectedRow === ids[idx]}
                  hover
                  key={idx}
                  onClick={() => {
                    (onClickRow || noop)(ids[idx]);
                  }}
                >
                  {row.map((e: any, i: number) => (
                    <StyledTableCell style={tdStyles} key={i} customPadding={customPadding}>
                      {e}
                    </StyledTableCell>
                  ))}
                </TableRow>
              );
            }
          })}
        </TableBody>
        {this.props.isPagination ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage="Resultados por página"
                rowsPerPageOptions={this.props.rowsPerPageOptions}
                count={this.props.totalRows || 0}
                rowsPerPage={this.props.rowsPerPage!}
                page={this.props.page!}
                SelectProps={{
                  native: true,
                }}
                onChangePage={(_, page) => this.props.handleChangePage && this.props.handleChangePage(page)}
                onChangeRowsPerPage={e =>
                  this.props.handleChangeRowsPerPage && this.props.handleChangeRowsPerPage(e.target.value)
                }
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </TableContainer>
    );
  }
}
