import React, { SetStateAction, useEffect } from "react";
import { Snackbar } from "@material-ui/core";

import { ReactComponent as SuccessIcon } from "@assets/svgs/success.svg";
import { ReactComponent as ErrorIcon } from "@assets/svgs/error.svg";
import { ReactComponent as AlertIcon } from "@assets/svgs/alert.svg";
import { ReactComponent as CloseIcon } from "@assets/svgs/close.svg";

import s from "./styles.scss";

export type FeedbackType = "success" | "error" | "alert";

interface IProps {
  open: boolean;
  message?: string;
  handleClose: React.Dispatch<SetStateAction<boolean>>;
  type?: FeedbackType;
}

export function Feedback({ open, handleClose, message, type }: IProps) {
  const [lastType, setLastType] = React.useState<FeedbackType>("success");

  useEffect(() => {
    if (type) {
      setLastType(type);
    }
  }, [type]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={() => handleClose(false)}
    >
      <div className={s.feedback} data-type={lastType}>
        {feedbackIcon(lastType)}
        {message || feedbackDefaultMessage(lastType)}

        <CloseIcon onClick={() => handleClose(false)} className={s.closeButton} />
      </div>
    </Snackbar>
  );
}

function feedbackIcon(type: FeedbackType) {
  const types = {
    success: <SuccessIcon />,
    error: <ErrorIcon />,
    alert: <AlertIcon />,
  };

  return types[type];
}

function feedbackDefaultMessage(type: FeedbackType) {
  const types = {
    success: "Operação realizada com sucesso!",
    error: "Algo de errado ocorreu!",
    alert: "Algo de errado ocorreu!",
  };

  return types[type];
}
