import React from "react";
import classnames from "classnames";
import RcDialog from "rc-dialog";
import IDialogPropTypes from "rc-dialog/lib/IDialogPropTypes";

import { ReactComponent as CloseIcon } from "@assets/svgs/close.svg";

import { Button } from "../Button";

import "rc-dialog/assets/index.css";
import s from "./style.scss";

import { Spin } from "@components/Spin";

type IRcDialogProps = Omit<IDialogPropTypes, "closable" | "title" | "bodyProps" | "maskProps" | "onClose">;

interface IProps extends IRcDialogProps {
  title?: string;
  centerContent?: boolean;
  hideHeader?: boolean;
  loading?: boolean;
  onClose?: () => void;
}

export function Modal(props: IProps) {
  const { title, centerContent, wrapClassName, children, hideHeader, loading, ...rest } = props;

  React.useEffect(() => {
    const bodyEl = document.querySelector("body");

    if (props.visible) {
      bodyEl?.classList.add("modal-visible");
    } else {
      bodyEl?.classList.remove("modal-visible");
    }

    return () => {
      bodyEl?.classList.remove("modal-visible");
    };
  }, [props.visible]);

  return (
    <RcDialog
      {...rest}
      closable={false}
      wrapClassName={classnames([centerContent ? s.modal_center : null, wrapClassName])}
      bodyProps={{ className: s.modal }}
      maskProps={{ className: classnames(["rc-dialog-mask", s.modal_mask]) }}
    >
      {!hideHeader && (
        <div className={s.header}>
          <h2 className={s.title}>{title}</h2>

          <Button theme="neutral" type="button" className={s.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </Button>
        </div>
      )}

      {props.loading ? (
        <div className={s.loading_wrapper}>
          <Spin />
        </div>
      ) : (
        children
      )}
    </RcDialog>
  );
}

Modal.defaultProps = {
  animation: "zoom",
  centerContent: true,
  hideHeader: false,
  maskAnimation: "fade",
};
