exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__title__3Kt2P{font-weight:bold;font-size:18px;line-height:22px;color:#515251}.style__header__ToylW{margin:32px 0 24px}.style__loadingContent__2_FPQ{display:block;width:100%;height:300px}.style__errorContainer__2uA8P{display:flex;flex-direction:column;justify-content:center;align-items:center;min-height:250px}.style__errorMessage__2M98w{font-size:16px;color:#a6a6a6;margin-bottom:16px}", ""]);

// Exports
exports.locals = {
	"title": "style__title__3Kt2P",
	"header": "style__header__ToylW",
	"loadingContent": "style__loadingContent__2_FPQ",
	"errorContainer": "style__errorContainer__2uA8P",
	"errorMessage": "style__errorMessage__2M98w"
};