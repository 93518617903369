import React from "react";
import s from "./styles.scss";
import { OutlinedButton } from "../OutlinedButton";
import { ReactComponent as CloseIcon } from "../../../../assets/svgs/close-modal.svg";
import { RaisedButton } from "legacy/components/RaisedButton";

interface IProps {
  show: boolean;
  loading?: boolean;
  loadingCancel?: boolean;
  children?: React.ReactNode;
  disabledOk?: boolean;
  disabledCancel?: boolean;
  hideFooter?: boolean;
  onOk: () => void;
  onOkText?: string;
  onCancel?: () => void;
  onCancelText?: string;
  onClose: () => void;
  footerAlign?: "center" | "right" | "left";
}

export function Modal(props: IProps) {
  if (!props.show) return null;

  return (
    <section className={s.modal}>
      <div className={s.modalContainer}>
        <div className={s.topContent}>
          <CloseIcon className={s.close} onClick={props.onClose} />
          <h2 className={s.titlemodal}>Solicitação de prova de vida</h2>
          {props.children}
        </div>

        {!props.hideFooter && (
          <footer data-align={props.footerAlign} className={s.modalfooter}>
            {props.onCancel && (
              <OutlinedButton
                onClick={props.onCancel}
                loading={!!props.loadingCancel}
                label={props.onCancelText || "Cancelar"}
                disabled={props.disabledCancel}
              />
            )}
            <RaisedButton
              disabled={props.disabledOk}
              onClick={props.onOk}
              label={props.onOkText || "Confirmar"}
              loading={!!props.loading}
            />
          </footer>
        )}
      </div>
    </section>
  );
}
