import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { session } from "../utils";
import { RootStore } from "./RootStore";

export interface IMockSelfs {
  acertId: {
    answerDate: Date;
    key: string;
    reason: string;
    status: string;
  };
  image: string;
  name: string;
  sendDate: Date;
  status: "approved" | "pendent" | "reproved";
}

export class UsersStore {
  protected rootStore: RootStore;

  @observable public user: api.RegisterUser | null = null;

  @observable public userFields: api.RegisterUserFields | null = null;

  @observable public userAccounts: api.AccountWithStatus[] | null = null;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getUserComplianceData = async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const document = this.userFields!.cpf;

    return api.getComplianceData(document);
  };

  @action
  public updateAccountAddress = async (id: string, address: api.AddressInfo) => {
    return api.updateAccountAddress(id, address);
  };

  @action
  public updateUserData = async (id: string, fields: api.UserBasicData) => {
    return api.updateUserBasicData(id, fields);
  };

  @session
  public getUser = async (cpf: string) => (this.user = await api.getUserByCpf(cpf));

  @session
  public getUserFields = async (cpf: string, isPendingUser = false) => {
    const userFields = await api.getUserFieldsByCpfAndStatus(
      cpf,
      isPendingUser ? api.UserStatus.approvePending : api.UserStatus.active,
    );

    this.userFields = userFields;

    return userFields;
  };

  @session
  public getUserAccounts = async (cpf: string) => {
    const { accounts } = await api.filterUsersAccounts(cpf);

    return (this.userAccounts = accounts);
  };

  @session
  public sendLinkToRecovery = async (userId: string, actionRecovery: api.ActionRecoverUser): Promise<string> => {
    return api.sendLinkToRecoveryV13(userId, actionRecovery);
  };

  @session
  public recoveryByLink = async (cpf: string, cnpj: string, actionRecovery: api.ActionRecoverUser) => {
    return api.recoveryByLink(cpf, cnpj, false, actionRecovery);
  };

  @session
  public toggleUserBlock = async (state: boolean, userId: string): Promise<boolean> => {
    return api.toggleUserBlock(state, userId);
  };

  @session
  public closeAccount = async (
    account: string,
    branch: string,
    comments: string,
    targetId: string,
    type: api.PfPj,
  ): Promise<string> => {
    return api.closeAccount(account, branch, comments, targetId, type);
  };

  @session
  public getUserPassChangesSelfs = async (userid: string): Promise<api.UserProofOfLife[]> => {
    return api.getUserProofOfLife(userid);
  };

  @action
  public getUserInfoByCPF = async (cpf: string): Promise<api.RegisterUser | null> => {
    const user = await api.getUserByCpf(cpf);

    return user;
  };
}
