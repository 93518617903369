import React, { Children, cloneElement, useCallback, useState } from "react";
import classNames from "classnames";

import { ITabPaneProps } from "../";

import * as s from "./style.scss";

export interface ITabInfo {
  tabKey: string;
  allTabKeys?: string[];
  index?: number;
}

interface IProps {
  className?: string;
  children: Array<React.ReactElement<ITabPaneProps>>;
  value?: string;
  defaultActiveKey?: string;
  mountOnDemand?: boolean;
  enableTransitions?: boolean;
  shouldFollowSequence?: boolean;
  currentIndex?: number;
  onChange?(activeTab: NonNullable<ITabInfo>): void;
}

export function Tabs(props: IProps) {
  const allTabKeys = Children.map(props.children, (child): string => child.props.tabKey);
  const initialValue = props.value || props.defaultActiveKey || allTabKeys[0];

  const [currentKey, setCurrentKey] = useState<string>(initialValue);
  const [ref, setRef] = useState<HTMLButtonElement | null>(null);
  const [KeyMountOnDemand, setKeyMountOnDemand] = useState<string[]>([initialValue]);

  const controlledOrUncontrolledTabKey = props.value || currentKey;
  const selectedTabIndex = allTabKeys.findIndex(tabKey => tabKey === controlledOrUncontrolledTabKey);

  const onRefChange = useCallback(node => {
    setRef(node);
  }, []);

  function isDisabledTab(tabProps: ITabPaneProps) {
    if (
      props.shouldFollowSequence &&
      props.currentIndex !== undefined &&
      tabProps.index !== undefined &&
      props.currentIndex >= tabProps.index
    ) {
      return true;
    }

    return false;
  }

  function onChangeKey(tabProps: ITabPaneProps) {
    setKeyMountOnDemand(state => state.concat(tabProps.tabKey));

    // Controlled tabs
    if ((!props.shouldFollowSequence && props.value) || (props.value && isDisabledTab(tabProps))) {
      props.onChange?.({
        allTabKeys,
        index: allTabKeys.findIndex(key => key === tabProps.tabKey),
        tabKey: tabProps.tabKey,
      });

      return;
    }

    // Uncontrolled tabs
    setCurrentKey(tabProps.tabKey);
  }

  const sliderStyleSheet: React.CSSProperties = {
    transform: `translateX(-${selectedTabIndex * 100}%)`,
  };
  const borderStyleSheet: React.CSSProperties = {
    transform: `translateX(${ref?.offsetLeft}px)`,
    width: `${ref?.offsetWidth}px`,
  };

  return (
    <div className={classNames(s.tabOverflow, props.className)}>
      <div className={s.tabList}>
        {Children.map(props.children, child => {
          const isSelectedTab = child.props.tabKey === controlledOrUncontrolledTabKey;

          return (
            <button
              ref={isSelectedTab ? onRefChange : null}
              key={child.props.tabKey}
              className={classNames(
                s.tab,
                isSelectedTab && s.tab_active,
                props.shouldFollowSequence && !isDisabledTab(child.props) && s.tab_disabled,
              )}
              onClick={() => onChangeKey(child.props)}
              type="button"
            >
              {child.props.title}
            </button>
          );
        })}
        <div className={s.borderSelected} style={borderStyleSheet} />
      </div>

      {props.enableTransitions ? (
        <div className={s.tabsContainer} style={sliderStyleSheet}>
          {Children.map(props.children, child => {
            return cloneElement(child, {
              actualSelectedTab: controlledOrUncontrolledTabKey,
              isActive: child.props.tabKey === currentKey,
              key: child.props.tabKey,
              tabKey: child.props.tabKey,
            });
          }).filter(child => (props.mountOnDemand ? KeyMountOnDemand.includes(child.props.tabKey) : true))}
        </div>
      ) : (
        <div>
          {Children.map(props.children, child => {
            return cloneElement(child, {
              actualSelectedTab: controlledOrUncontrolledTabKey,
              isActive: child.props.tabKey === currentKey,
              key: child.props.tabKey,
              tabKey: child.props.tabKey,
            });
          }).filter(child => child.props.tabKey === controlledOrUncontrolledTabKey)}
        </div>
      )}
    </div>
  );
}
