import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { Segment, SegmentLimit } from "api/manager-api";
import { Loader } from "legacy/components/Loader";

import {
  AlertModal,
  Container,
  ContainerTable,
  ErrorMessage,
  ErrorText,
  Filter,
  Info,
  Label,
  Left,
  Right,
  SegmentSelect,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextInput,
} from "../style";
import { TransactionalBoundariesStore } from "../../../../../stores";
import { Table } from "../../../../../components/Table";
import { ContainerInput, Input, WrapperSwitch } from "../../DefaultLimit/style";
import { currencyFormatter } from "../../../../../../../utils";
import { Switch } from "../../../../../components/Switch";
import { SvgError } from "../../../../../../../legacy/icons/SvgError";
import { Dialog } from "../../../../../components/Dialog";
import { FooterTable } from "../../../SafetyLimits/SafetyLimitsConfiguration/style";

interface IProps {
  transactionalBoundariesStore?: TransactionalBoundariesStore;
  createSegmentLimit: (ref: boolean) => void;
  segmentLimitEdit: SegmentLimit | null;
}

@inject("transactionalBoundariesStore")
@observer
export class SegmentBoundariesWizard extends React.Component<IProps> {
  private MAXAMOUNT = 999999999;

  @observable private segmentOperation: Segment | null = null;

  @observable private notEdited = true;

  @observable private empty = false;

  @observable private errorMessage: string | null = null;

  @observable private errorOpen = false;

  @observable private segmentLimit: SegmentLimit | null = this.props.segmentLimitEdit;

  @observable private optionsSegment: Array<{ label: string; value: Segment }> = [];

  @action
  public async componentDidMount() {
    if (this.props.segmentLimitEdit === null) {
      await this.props.transactionalBoundariesStore!.filterSegmentLimitTransactional();
      this.props.transactionalBoundariesStore!.segmentLimitCreate!.segment!.forEach(seg => {
        this.optionsSegment.push({
          label: seg.name,
          value: seg,
        });
      });
      this.segmentLimit = {
        name: "",
        parametersLimitsID: null,
        segment: this.props.transactionalBoundariesStore!.segmentLimitCreate!.segment![0],
        transactionRequestDto: this.props.transactionalBoundariesStore!.segmentLimitCreate!.operation!,
      };
    } else {
      this.segmentLimit = {
        name: this.segmentLimit!.name,
        parametersLimitsID: this.segmentLimit!.parametersLimitsID,
        segment: this.segmentLimit!.segment,
        transactionRequestDto: this.segmentLimit!.transactionRequestDto,
      };
      this.optionsSegment.push({
        label: this.segmentLimit!.segment.name,
        value: this.segmentLimit!.segment,
      });

      this.segmentOperation = this.segmentLimit!.segment;
    }
  }

  private checkNull = (e: SegmentLimit | null) => {
    let count = 0;

    if (e) {
      e.transactionRequestDto.forEach(lim => {
        if (lim.operation === 0 || lim.daily === 0) {
          count++;
        }
      });
      if (count > 0) {
        this.empty = true;
      } else {
        this.empty = e.name === "" || this.segmentOperation === null;
      }
    }
  };

  private saveSegmentLimit = async (segmentLimit: SegmentLimit) => {
    try {
      if (this.props.segmentLimitEdit) {
        await this.props.transactionalBoundariesStore!.updateSegmentLimitTransactional(segmentLimit);
      } else {
        await this.props.transactionalBoundariesStore!.createSegmentLimitTransactional(segmentLimit);
      }

      this.props.createSegmentLimit(!this.notEdited);
    } catch {
      this.errorOpen = true;
    }
  };

  public render() {
    const columns = ["Transação", "Limite por transação*", "Limite diário*", "Quantidade por dia"];

    return this.segmentLimit ? (
      <>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"sm"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-50px 0 12px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>Não foi possível realizar a operação</ErrorText>
          </AlertModal>
        </Dialog>
        <Left>
          <Container>
            <Filter>
              <Label>Nome do Limite*</Label>
              <StyledTextInput
                placeholder="Ex: Limite Ouro"
                value={this.segmentLimit!.name}
                onChange={e => {
                  this.segmentLimit!.name = e.target.value;
                  this.notEdited = false;
                  this.checkNull(this.segmentLimit);
                }}
                maxLength={50}
              />
            </Filter>

            <Filter>
              <Label>Segmento*</Label>
              <SegmentSelect
                value={this.optionsSegment.filter(seg => seg.value === this.segmentOperation)}
                options={this.optionsSegment}
                onChange={(e: any) => {
                  this.segmentOperation = e.value;
                  if (this.segmentOperation) {
                    this.segmentLimit!.segment = this.segmentOperation;
                  }

                  this.checkNull(this.segmentLimit);
                }}
                placeholder="Selecione o Segmento"
                isDisabled={this.props.segmentLimitEdit}
              />
            </Filter>
          </Container>
        </Left>

        <Table
          titles={columns}
          ids={this.segmentLimit!.transactionRequestDto!.map((e: any) => e.title)}
          data={this.segmentLimit!.transactionRequestDto!.map(limit => {
            let operationFormat: string = limit.operation!.toString();
            let dailyFormat: string = limit.daily!.toString();
            const data: any = [
              limit.title,
              <Input
                key={limit.title}
                value={currencyFormatter(operationFormat)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.notEdited = false;
                  if (event.target.value === "") {
                    limit.operation = 0;
                  } else {
                    operationFormat = event.target.value;
                    limit.operation = parseFloat(operationFormat.replace(/,/gu, "").replace(/\./gu, ""));
                  }

                  if (limit.operation > limit.daily!) {
                    this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                  } else {
                    this.errorMessage = null;
                  }

                  this.checkNull(this.segmentLimit);
                }}
              />,
              <Input
                key={limit.title}
                value={currencyFormatter(dailyFormat)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.notEdited = false;
                  if (event.target.value === "") {
                    limit.daily = 0;
                  } else {
                    dailyFormat = event.target.value;
                    limit.daily = parseFloat(dailyFormat.replace(/,/gu, "").replace(/\./gu, ""));
                  }

                  if (limit.operation! > limit.daily) {
                    this.errorMessage = `O campo Limite por Transação de ${limit.title} está maior que o Limite Diário`;
                  } else {
                    this.errorMessage = null;
                  }

                  this.checkNull(this.segmentLimit);
                }}
              />,
              <ContainerInput key={limit.title}>
                <Input
                  disabled={limit.amountPerDay === this.MAXAMOUNT}
                  value={limit.amountPerDay === this.MAXAMOUNT ? " " : limit.amountPerDay}
                  placeholder="Indicar a quantidade"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.notEdited = false;
                    if (event.target.value === "") {
                      limit.amountPerDay = 0;
                    } else {
                      limit.amountPerDay = Number(event.target.value);
                    }

                    this.checkNull(this.segmentLimit);
                  }}
                />
                <WrapperSwitch>
                  <Switch
                    checked={limit.amountPerDay === this.MAXAMOUNT}
                    handleChange={() => {
                      this.notEdited = false;
                      limit.amountPerDay = limit.amountPerDay === this.MAXAMOUNT ? 0 : this.MAXAMOUNT;
                      this.checkNull(this.segmentLimit);
                    }}
                  />
                  Ilimitado
                </WrapperSwitch>
              </ContainerInput>,
            ];

            return data;
          })}
        />
        <FooterTable>
          {this.errorMessage && (
            <ErrorMessage>
              <SvgError
                viewBox="0 0 82 82"
                width="21"
                height="auto"
                style={{ margin: "0 8px -8px 0", position: "relative" }}
              />
              {this.errorMessage}
            </ErrorMessage>
          )}
          <Right>
            <Info>*Campos obrigatórios</Info>
            <StyledOutlinedButton onClick={() => this.props.createSegmentLimit(false)} label="Cancelar" type="submit" />
            <StyledRaisedButton
              onClick={async () => this.saveSegmentLimit(this.segmentLimit!)}
              disabled={this.notEdited || this.empty || Boolean(this.errorMessage)}
              label="Salvar"
              type="submit"
            />
          </Right>
        </FooterTable>
      </>
    ) : (
      <ContainerTable>
        <Loader />
      </ContainerTable>
    );
  }
}
