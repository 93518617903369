import styled from "styled-components";
import { colors } from "utils";

import { TextShow as DefaultTextShow } from "../../../../components/TextShow";

export const Centralizer = styled.div<{ highlight?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
`;

export const Container = styled.div<{ highlight?: boolean; paddingLeft?: string | number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: ${props => props.paddingLeft || "unset"};
  background-color: ${props => (props.highlight ? "rgb(240, 240, 240)" : "unset")};
`;

export const SmallTitle = styled.h4`
  font-size: 14px;
  font-weight: bold;
`;

export const TextShow = styled(DefaultTextShow)`
  display: block;
  margin-bottom: 1rem;
`;

export const Typography = styled.div`
  font-size: 1rem;
  color: ${colors.gray};
  margin-top: 1rem;
`;

export const Separator = styled.hr`
  display: block;
  width: 100%;
  border: solid 0.5px ${colors.gray};
  margin-bottom: 1rem;
  margin-top: 0.2rem;
`;
