import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import * as React from "react";
import { DateRangePicker as Picker } from "react-dates";
import { Moment } from "moment";
import { SvgRightArrow } from "../../assets/icons/SvgRightArrow";
import { colors } from "../../utils";
import styled from "styled-components";
import moment from "moment";

const { useState } = React;

type Focused = "startDate" | "endDate" | null;
export type DateRange = IRange;

interface IMomentRange {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface IRange {
  startDate: Date | null;
  endDate: Date | null;
}

interface IProps extends IRange {
  onChange: (range: DateRange) => void;
  startLabel?: string;
  endLabel?: string;
  minimumDate?: Date | null;
  maximumDate?: Date | null;
}

export const DateRangePicker: React.FunctionComponent<IProps> = ({
  startDate,
  endDate,
  onChange,
  startLabel,
  endLabel,
  minimumDate,
  maximumDate,
}) => {
  const [focused, setFocused] = useState<Focused>(null);

  const handleOutsideRange = () => {
    const exceedsMinimum = minimumDate && startDate && startDate < minimumDate;
    const exceedsMaximum = maximumDate && endDate && endDate > maximumDate;
    return !!(exceedsMinimum && exceedsMaximum);
  };

  const handleChange = ({ startDate: start, endDate: end }: IMomentRange) => {
    onChange({
      startDate: toDate(start),
      endDate: toDate(end),
    });
  };

  return (
    <Wrapper>
      <Picker
        displayFormat="DD/MM/YYYY"
        startDate={toMoment(startDate)}
        startDateId="start_date_filter"
        endDate={toMoment(endDate)}
        endDateId="end_date_filter"
        onDatesChange={handleChange}
        startDatePlaceholderText={startLabel || "Data Inicial"}
        endDatePlaceholderText={endLabel || "Data Final"}
        isOutsideRange={handleOutsideRange}
        focusedInput={focused}
        onFocusChange={setFocused}
        horizontalMargin={0}
        verticalSpacing={0}
        minimumNights={0}
        customArrowIcon={<SvgRightArrow color={colors.gray} width="7px" height="12px" />}
        readOnly
        hideKeyboardShortcutsPanel
      />
    </Wrapper>
  );
};

const toMoment = (date: Date | null) => (date ? moment(date) : null);
const toDate = (mom: Moment | null) => (mom ? mom.toDate() : null);

const Wrapper = styled.div`
  .DateRangePicker {
    display: block;
  }

  .DateRangePickerInput {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.lightPink};
    border-radius: 4px;
    overflow: hidden;
  }

  .DateInput {
    display: block;
    flex: 1 0 0;
    height: 40px;
  }

  .DateInput_input {
    height: 40px;
    padding: 0px 0px 0px 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    color: ${colors.gray};
    border-bottom: none;
    ::placeholder {
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      color: ${colors.gray};
    }
  }

  .CalendarDay__selected {
    background: ${colors.violetRed};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected:hover {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
  }

  .CalendarDay__selected_span {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRed};
    &:hover {
      background: ${colors.violetRedOpacity};
      border: ${colors.violetRed};
    }
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${colors.violetRedOpacity};
    color: ${colors.white};
    border: ${colors.violetRedOpacity};
  }
`;
