import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./";

export class FeatureFlagStore {
  constructor(public rootStore: RootStore) {}

  @observable
  public featuresPartner: api.FeaturesStatus[] = [];

  @observable
  public async getFeaturesPartner(partnerId: string) {
    this.featuresPartner = await api.getFeaturesStatusByPartner(partnerId);
  }

  @action
  public async setFeaturesPartner(partnerId: string, features: api.FeatureStatusSetup[]) {
    this.featuresPartner = await api.setFeaturesToPartner(partnerId, features);
  }
}
