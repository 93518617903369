import * as React from "react";
import SwitchComponent, { SwitchProps } from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { colors } from "utils";

interface IStyleMaterialUi extends SwitchProps {
  classes?: any;
}

interface IProps extends IStyleMaterialUi {
  checked: boolean;
  handleChange?: () => void;
  disabled?: boolean;
}

const styles = () => ({
  colorSwitchBase: {
    color: colors.white,
    "&$colorBar": {
      opacity: 1,
      backgroundColor: colors.gray,
    },
    "&$colorChecked": {
      color: colors.white,
      "& + $colorBar": {
        opacity: 1,
        backgroundColor: colors.violetRed,
      },
    },
    "&$disabled": {
      color: colors.white,
      "&$colorBar": {
        opacity: 1,
        backgroundColor: colors.gray,
      },
      "&$colorChecked": {
        color: colors.white,
        "& + $colorBar": {
          opacity: 1,
          backgroundColor: colors.violetRedOpacity,
        },
      },
    },
  },
  colorBar: {},
  colorChecked: {},
  disabled: {},
});

class SwitchStyled extends React.Component<IProps, {}> {
  public render() {
    const { handleChange, classes, ...rest } = this.props;
    return (
      <SwitchComponent
        onChange={this.props.handleChange}
        classes={{
          switchBase: classes.colorSwitchBase,
          checked: classes.colorChecked,
          bar: classes.colorBar,
          disabled: classes.disabled,
        }}
        {...rest}
      />
    );
  }
}

export const Switch = withStyles(styles)(SwitchStyled);
