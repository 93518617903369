/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import * as React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouterStore } from "mobx-react-router";
import { AdminUserPermission } from "api/manager-api";

import {
  accountsManagerPath,
  activeAccountsReports,
  auditManagerPath,
  authorizedDebitPath,
  cardsmanagerPath,
  colors,
  conciliationManagerSectionPath,
  dashboardPath,
  employeesManagerPath,
  errorsManagerPath,
  extract,
  generalParameters,
  historyChange,
  internalAPIsPath,
  legalEntityProfileManagerPath,
  limitCustomization,
  notificationManagerPath,
  openingAccountSectionWithSearchPath,
  openingAccountsProfilePath,
  openingAccountsReports,
  openingAccountsSectionPath,
  openingAccountsSectionWithId,
  page404Path,
  partnerManagementPath,
  platformManagerPath,
  proofOfLifePath,
  RegistrationPathPj,
  safetyLimits,
} from "../../utils";
import { AuthStore, GeneralStore, OpeningAccountsStore } from "../../stores";
import { PlatformManager } from "../PlatformManager";
import { requireAuth } from "../../components/PrivateRouteHook";
import { withRouter } from "../../components/WithRouterHook";
import { PartnerManagement } from "../PartnerManagement";
import { EmployeesManager } from "../EmployeesManager";
import { OpeningAccounts } from "../OpeningAccounts";
import { TippingAcconts } from "../TippingAcconts";
import { Details } from "../TippingAcconts/Details";
import { AuthorizedDebitDetails } from "../AuthorizedDebit/Details";
import { Positivize } from "../Positivize";
import { Historic } from "../Positivize/Historic";
import { OpeningAccountProfile } from "../OpeningAccounts/OpeningAccountProfile";
import { Page404 } from "../Page404";
import { Navbar } from "./Navbar";
import { AuditManager } from "../AuditManager";
import { Attachments } from "../TippingAcconts/Attachments";
import { ConciliationManager } from "../ConciliationManager";
import { NotificationManager } from "../NotificationManager";
import { PjRegistration } from "../PjRegistration";
import { AccountsManager } from "../AccountsManager";
import { OpeningAccountsReports } from "../OpeningAccountsReports";
import { ErrorsManager } from "../ErrorsManager";
import { ConciliationCards } from "../ConciliationCards";
import { InternalAPIs } from "../InternalAPIs";
import { ConciliationWithdrawalLotteryManager } from "../ConciliationWithdrawalLotteryManager";
import { CardsManager } from "../CardsManager";
// Import { ChangePassword } from "../../../manager/containers/ChangePassword";
import { GeneralParameters } from "./../TransactionalBoundaries/GeneralParameters/index";
import { History } from "./../TransactionalBoundaries/History/index";
import { LimitCustomization } from "./../TransactionalBoundaries/LimitCustomization/index";
import { SafetyLimits } from "../TransactionalBoundaries/SafetyLimits/index";
import { ProofOfLife } from "../ProofOfLife";
import { ActiveAccountsReports } from "../ActiveAccountsReport";
import { AuthorizedDebit } from "../AuthorizedDebit";
import { StatementPage } from "../Extract";

interface IProps {
  authStore?: AuthStore;
  routerStore?: RouterStore;
  generalStore?: GeneralStore;
  openingAccountsStore?: OpeningAccountsStore;
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Content = styled.main`
  margin: 24px 24px 24px 262px;
  padding: 24px;
  border-radius: 6px;
  background-color: ${colors.white};
  width: 100%;
  overflow: auto;
  flex: 1;
`;

@inject("authStore", "routerStore", "generalStore", "openingAccountsStore")
@observer
class RawDashboard extends React.Component<IProps> {
  private idSetAliveTimeInterval: number | null = null;

  constructor(props: IProps) {
    super(props);
  }

  public componentWillUnmount() {
    if (this.idSetAliveTimeInterval) {
      clearInterval(this.idSetAliveTimeInterval);
    }
  }

  private routeLock = (permission: AdminUserPermission | AdminUserPermission[], component: any) => {
    if (!this.props.generalStore!.hasPermission(permission)) {
      return () => <Redirect to={page404Path} />;
    }

    return component;
  };

  private getFirstValidPath = () => {
    const paths: Array<{ path: string; permission: AdminUserPermission }> = [
      {
        path: accountsManagerPath,
        permission: AdminUserPermission.viewUser,
      },
      {
        path: legalEntityProfileManagerPath(),
        permission: AdminUserPermission.viewCompany,
      },
      {
        path: employeesManagerPath,
        permission: AdminUserPermission.viewAdminUser,
      },
      {
        path: platformManagerPath,
        permission: AdminUserPermission.viewPlatformManager,
      },
      {
        path: openingAccountsSectionPath(),
        permission: AdminUserPermission.viewOpeningAccountPF,
      },
      {
        path: openingAccountsProfilePath(),
        permission: AdminUserPermission.viewOpeningAccountPF,
      },
    ];

    const validPath = paths.find(path => this.props.generalStore!.hasPermission(path.permission));

    return validPath ? validPath.path : "";
  };

  public render() {
    return (
      <Container>
        <Navbar />

        <Content>
          <Switch>
            <Route path={page404Path} component={Page404} />
            <Route exact path={dashboardPath} component={() => <Redirect to={this.getFirstValidPath()} />} />
            <Route
              path={accountsManagerPath}
              component={this.routeLock(
                [AdminUserPermission.viewUser, AdminUserPermission.viewCompany],
                AccountsManager,
              )}
            />
            <Route
              path={proofOfLifePath}
              component={this.routeLock(AdminUserPermission.viewProofOfLife, ProofOfLife)}
            />
            <Route
              path={partnerManagementPath}
              component={this.routeLock(AdminUserPermission.viewPartner, PartnerManagement)}
            />
            <Route
              exact
              path={authorizedDebitPath}
              component={this.routeLock(AdminUserPermission.viewBatchChargeDebit, AuthorizedDebit)}
            />
            <Route
              exact
              path={`${authorizedDebitPath}/:id/detalhes`}
              component={this.routeLock([AdminUserPermission.viewBatchChargeDebit], AuthorizedDebitDetails)}
            />
            <Route
              exact
              path={employeesManagerPath}
              component={this.routeLock(AdminUserPermission.viewAdminUser, EmployeesManager)}
            />
            <Route
              exact
              path={platformManagerPath}
              component={this.routeLock(AdminUserPermission.viewPlatformManager, PlatformManager)}
            />
            <Route
              exact
              path={errorsManagerPath}
              component={this.routeLock(AdminUserPermission.viewErrors, ErrorsManager)}
            />
            <Route
              exact
              path={openingAccountsSectionPath("tipping")}
              component={this.routeLock(
                [AdminUserPermission.viewTipping, AdminUserPermission.addTipping],
                TippingAcconts,
              )}
            />
            <Route
              exact
              path={`${openingAccountsSectionWithId("tipping")}/detalhes/:id`}
              component={this.routeLock([AdminUserPermission.viewTipping, AdminUserPermission.cancelTipping], Details)}
            />
            <Route
              exact
              path={`${openingAccountsSectionWithId("tipping")}/anexos/:id`}
              component={this.routeLock(
                [AdminUserPermission.viewTipping, AdminUserPermission.cancelTipping],
                Attachments,
              )}
            />
            <Route
              exact
              path={openingAccountsSectionPath("positivize")}
              component={this.routeLock(
                [AdminUserPermission.viewPositivize, AdminUserPermission.sendMensPositivize],
                Positivize,
              )}
            />
            <Route
              exact
              path={`${openingAccountsSectionWithId("positivize")}/historico/:id`}
              component={this.routeLock([AdminUserPermission.viewPositivize], Historic)}
            />
            <Route
              exact
              path={openingAccountsSectionPath()}
              component={this.routeLock(
                [
                  AdminUserPermission.viewOpeningAccountPF,
                  AdminUserPermission.viewOpeningAccountPJ,
                  AdminUserPermission.viewOpeningAccountPJMEI,
                ],
                OpeningAccounts,
              )}
            />
            <Route
              exact
              path={openingAccountSectionWithSearchPath()}
              component={this.routeLock(
                [
                  AdminUserPermission.viewOpeningAccountPF,
                  AdminUserPermission.viewOpeningAccountPJ,
                  AdminUserPermission.viewOpeningAccountPJMEI,
                ],
                OpeningAccounts,
              )}
            />
            <Route
              exact
              path={openingAccountsProfilePath()}
              component={this.routeLock(
                [
                  AdminUserPermission.viewOpeningAccountPF,
                  AdminUserPermission.viewOpeningAccountPJ,
                  AdminUserPermission.viewOpeningAccountPJMEI,
                ],
                OpeningAccountProfile,
              )}
            />
            <Route exact path={auditManagerPath} component={AuditManager} />
            <Route exact path={conciliationManagerSectionPath("convenant")} component={ConciliationManager} />
            <Route
              exact
              path={conciliationManagerSectionPath("withdrawal-lottery")}
              component={ConciliationWithdrawalLotteryManager}
            />
            <Route exact path={conciliationManagerSectionPath("cards")} component={ConciliationCards} />
            <Route exact path={generalParameters} component={GeneralParameters} />
            <Route exact path={limitCustomization} component={LimitCustomization} />
            <Route exact path={historyChange} component={History} />
            <Route exact path={safetyLimits} component={SafetyLimits} />
            <Route exact path={notificationManagerPath} component={NotificationManager} />
            <Route
              exact
              path={RegistrationPathPj}
              component={this.routeLock([AdminUserPermission.insertManualPJAccount], PjRegistration)}
            />
            <Route exact path={openingAccountsReports} component={OpeningAccountsReports} />
            <Route exact path={activeAccountsReports} component={ActiveAccountsReports} />
            <Route exact path={extract} component={StatementPage} />
            <Route exact path={internalAPIsPath} component={InternalAPIs} />
            <Route exact path={cardsmanagerPath} component={CardsManager} />
            <Route path="/*" render={() => <Redirect to={page404Path} />} />
          </Switch>
        </Content>
      </Container>
    );
  }
}

export const Dashboard = requireAuth(withRouter(RawDashboard));
