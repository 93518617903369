import * as React from "react";
import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";

import { OutlinedButton } from "../../../components/OutlinedButton";
import { colors } from "../../../utils";

import { ITestFaceMatch, Option } from "..";

interface IProps {
  onClose: () => void;
  onSubmit: (option: Option, data: ITestFaceMatch) => Promise<void>;
}

type FieldError = "selfie" | "document";

export function TestFaceMatchDialog({ onClose, onSubmit }: IProps) {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FieldError[]>([]);
  const [selfie, setSelfie] = React.useState(Buffer.from(""));
  const [document, setDocument] = React.useState(Buffer.from(""));

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);
    const newErrors: FieldError[] = [];

    if (selfie.length === 0) {
      newErrors.push("selfie");
    }

    if (document.length === 0) {
      newErrors.push("document");
    }

    setErrors(newErrors);
    if (newErrors.length > 0) {
      setLoading(false);
      return;
    }

    await onSubmit("testFaceMatch", {
      selfie,
      document,
    });
    setLoading(false);

    onClose();
  }

  function readFileAsBuffer(e: React.ChangeEvent<HTMLInputElement>, type: string) {
    const { files } = e.target;
    const file = files ? files[0] : null;

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (reader.result && typeof reader.result !== "string") {
          const fileBuffer = Buffer.from(reader.result);

          if (type === "selfie") {
            setSelfie(fileBuffer);
          } else {
            setDocument(fileBuffer);
          }
        }
      });
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <Box onSubmit={handleSubmit}>
      <Info>
        <TextInputTitle>Selfie:</TextInputTitle>
        <input type="file" onChange={e => readFileAsBuffer(e, "selfie")} />
        <TextInputTitle>Documento:</TextInputTitle>
        <input type="file" onChange={e => readFileAsBuffer(e, "document")} />
      </Info>
      {errors.map((e, index) => (
        <p key={index}>{e}</p>
      ))}
      <InputArea>
        <BackButtonWrapper disabled={loading} type="button" label="Voltar" onClick={onClose} />
        <NextButtonWrapper loading={loading} type="submit" label="Enviar" />
      </InputArea>
    </Box>
  );
}

const TextInputTitle = styled.span`
  font-family: Lato;
  display: block;
  font-size: 14px;
  color: ${colors.black};
  margin-bottom: 5px;
`;

const Box = styled.form`
  padding: 16px 24px;
`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

const NextButtonWrapper = styled(RaisedButton)`
  min-width: 145px;
  width: fit-content;
  font-family: Lato;
  margin: 15px 0 15px 15px;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;

const Info = styled.div`
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.black};
`;
