import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { observer } from "mobx-react";
import { FormikErrors, useFormik } from "formik";
import { TextInput } from "legacy/components/TextInput";
import { Alert } from "legacy/components/Alert";

import { InputMask } from "@components/";

import { useStores } from "../../stores/RootStore";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import {
  Container,
  CustomSelect,
  ErrorMessage,
  FormContainer,
  InputContainer,
  InputLabel,
  RaisedButtonWrapper,
  SelectContainer,
  Title,
} from "./styled";

interface IForm {
  name: string;
  cnpj: string;
  email: string;
  phone: string;
  accountNumber: string;
  clientCode: string;
  partnerId: string;
  branch: string;
  sendToAllPartners: boolean;
}

interface IFormErrors {
  name: string;
  cnpj: string;
  email: string;
  phone: string;
  accountNumber: string;
  clientCode: string;
  partnerId: string;
  branch: string;
  sendToAllPartners: boolean;
}

export const PjRegistration = observer(() => {
  const { partnerStore, generalStore } = useStores();
  const [loadingSendPjRegistration, setLoadingSendPjRegistration] = useState<boolean>(false);
  const [loadingPartners, setLoadingPartners] = useState<boolean>(false);
  const [alertSendPjRegistration, setAlertSendPjRegistration] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<string>("");
  const { partners } = partnerStore;

  const getParnerInfo = (partnerId: string) => {
    if (partners) {
      const partner = partners.find(p => p.id === partnerId);

      if (partner) {
        return {
          value: partner.id || "",
          label: partner.name || "",
        };
      }
    }

    return {
      value: "",
      label: "",
    };
  };

  const getAllPartnersOptions = () => {
    if (partners) {
      return partners.map(partner => ({ label: partner.name, value: partner.id }));
    }

    return [];
  };

  const fmk = useFormik<IForm>({
    initialValues: {
      name: "",
      cnpj: "",
      email: "",
      phone: "",
      accountNumber: "",
      clientCode: "",
      partnerId: "",
      branch: "0001-9",
      sendToAllPartners: true,
    },
    onSubmit: sendPjRegister,
    validate: values => {
      const errors: FormikErrors<IForm> = {};
      const messageError = "Campo obrigatório";

      if (values.name.length === 0) {
        errors.name = messageError;
      }

      if (values.cnpj.length === 0) {
        errors.cnpj = messageError;
      }

      if (values.email.length === 0) {
        errors.email = messageError;
      }

      if (values.phone.length === 0) {
        errors.phone = messageError;
      }

      if (values.accountNumber.length === 0) {
        errors.accountNumber = messageError;
      }

      if (values.clientCode.length === 0) {
        errors.clientCode = messageError;
      }

      if (values.partnerId.length === 0) {
        errors.partnerId = messageError;
      }

      return errors;
    },
  });

  useEffect(() => {
    getPartners();
  }, []);

  async function onSubmiting() {
    const errors = await fmk.validateForm(fmk.values);

    if (!Object.keys(errors).length) {
      setAlertSendPjRegistration(true);
    }

    const objTouched = Object.keys(fmk.values).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: true,
      };
    }, {});

    fmk.setTouched(objTouched);
  }

  async function getPartners() {
    try {
      setLoadingPartners(true);

      await partnerStore.getPartners();
    } catch (err) {
      setAlertError(err);
    } finally {
      setLoadingPartners(false);
    }
  }

  async function sendPjRegister(values: IForm) {
    try {
      setLoadingSendPjRegistration(true);

      const data: api.InsertManualPJAccountRequest = {
        name: values.name,
        cnpj: values.cnpj,
        email: values.email,
        phone: values.phone,
        accountNumber: values.accountNumber,
        clientCode: values.clientCode,
        partnerId: values.partnerId,
        branch: values.branch,
      };

      await api.insertManualPJAccount(data);
      fmk.resetForm();
      generalStore.showToast("Cadastro realizado com sucesso");
    } catch (err) {
      generalStore.showToast(err.message || "Não foi possível gerar o cadastro.");
      console.log(err);
      setAlertError(err);
    } finally {
      setLoadingSendPjRegistration(false);
      setAlertSendPjRegistration(false);
    }
  }

  function handleChangeForm(key: keyof IForm, value: any) {
    fmk.setFieldValue(key, value);
  }

  return (
    <Container>
      <Alert
        title="Um problema ocorreu"
        text={alertError}
        isOpen={alertError.length > 0}
        onClose={() => setAlertError("")}
      />
      <ConfirmDialog
        onCancel={() => setAlertSendPjRegistration(false)}
        onConfirm={fmk.submitForm}
        isOpen={alertSendPjRegistration}
        title="Atenção"
        text="Você tem certeza que deseja continuar com cadastro?"
        isLoading={loadingSendPjRegistration}
      />
      <Title>Abertura de Conta PJ Manual</Title>
      <FormContainer>
        <InputContainer>
          <InputLabel>Nome da Empresa</InputLabel>
          <TextInput
            placeholder="Digite o nome da empresa"
            error={Boolean(fmk.errors.name && fmk.touched.name)}
            errorText="Campo obrigatório"
            onChange={e => handleChangeForm("name", e.target.value)}
            value={fmk.values.name}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>CNPJ da Empresa</InputLabel>
          <InputMask
            placeholder="Digite o CNPJ da empresa"
            error={Boolean(fmk.errors.cnpj && fmk.touched.cnpj)}
            onChange={e => handleChangeForm("cnpj", e.target.value.replace(/\D/gu, ""))}
            value={fmk.values.cnpj}
            mask="99.999.999/9999-99"
          />
          {Boolean(fmk.errors.cnpj && fmk.touched.cnpj) && <ErrorMessage>Campo obrigatório</ErrorMessage>}
        </InputContainer>
        <InputContainer>
          <InputLabel>Email da empresa ou do dono</InputLabel>
          <TextInput
            placeholder="Digite o email"
            error={Boolean(fmk.errors.email && fmk.touched.email)}
            errorText="Campo obrigatório"
            onChange={e => handleChangeForm("email", e.target.value)}
            value={fmk.values.email}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Telefone da pessoa responsável ou do dono</InputLabel>
          <InputMask
            placeholder="Digite o telefone"
            onChange={e => handleChangeForm("phone", e.target.value)}
            value={fmk.values.phone}
            error={Boolean(fmk.errors.phone && fmk.touched.phone)}
            mask="(99)99999-9999"
          />
          {Boolean(fmk.errors.phone && fmk.touched.phone) && <ErrorMessage>Campo obrigatório</ErrorMessage>}
        </InputContainer>
        <InputContainer>
          <InputLabel>Agência</InputLabel>
          <InputMask
            placeholder="Digite a agência"
            onChange={e => handleChangeForm("branch", e.target.value)}
            value={fmk.values.branch}
            error={Boolean(fmk.errors.branch && fmk.touched.branch)}
            mask="9999-9"
          />
          {Boolean(fmk.errors.branch && fmk.touched.branch) && <ErrorMessage>Campo obrigatório</ErrorMessage>}
        </InputContainer>
        <InputContainer>
          <InputLabel>Conta</InputLabel>
          <InputMask
            placeholder="Digite o número da conta"
            onChange={e => handleChangeForm("accountNumber", e.target.value)}
            value={fmk.values.accountNumber}
            error={Boolean(fmk.errors.accountNumber && fmk.touched.accountNumber)}
            mask="999999999-9"
          />
          {Boolean(fmk.errors.accountNumber && fmk.touched.accountNumber) && (
            <ErrorMessage>Campo obrigatório</ErrorMessage>
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>Código Cliente</InputLabel>
          <TextInput
            placeholder="Digite o código de cliente"
            onChange={e => handleChangeForm("clientCode", e.target.value)}
            value={fmk.values.clientCode}
            error={Boolean(fmk.errors.clientCode && fmk.touched.clientCode)}
            errorText="Campo obrigatório"
          />
        </InputContainer>

        <SelectContainer>
          <InputLabel>Parceiro</InputLabel>

          <CustomSelect
            isLoading={loadingPartners}
            placeholder="Selecionar parceiro"
            options={getAllPartnersOptions()}
            onChange={(e: any) => {
              console.log(e);
              handleChangeForm("partnerId", e.value);
              console.log(fmk.values);
            }}
            value={getParnerInfo(fmk.values.partnerId)}
            hasError={Boolean(fmk.errors.partnerId && fmk.touched.partnerId)}
          />
          {Boolean(fmk.errors.partnerId && fmk.touched.partnerId) && <ErrorMessage>Campo obrigatório</ErrorMessage>}
        </SelectContainer>

        <RaisedButtonWrapper onClick={onSubmiting} label="Enviar" loading={loadingSendPjRegistration} />
      </FormContainer>
    </Container>
  );
});
