import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./RootStore";

export class NotificationManagerStore {
  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable public notifications: api.NotificationInfo[] = [];

  @action
  public getNotifications = async () => {
    try {
      this.notifications = await api.listNotification();
    } catch (err) {
      throw err.message || "Não foi possível recuperar a lista de notificação";
    }
  };

  public sendNotification = async (title: string, mensage: string) => {
    try {
      const { partnerIds, ...rest } = await api.sendNotification(title, mensage);

      this.notifications.push({ ...rest, partnerNames: [] });
    } catch (err) {
      throw err.message || "Não foi possível enviar a notificação";
    }
  };

  public async sendNotificationByPartner(title: string, message: string, partnerIds: string[]) {
    try {
      return api.sendNotificationByPartner(title, message, partnerIds);
    } catch (e) {
      throw e.message || "Não foi possível enviar a notificação";
    }
  }
}
