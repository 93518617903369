import * as api from "api/manager-api";
import moment from "moment";
import { action, observable } from "mobx";

import { RootStore } from "./RootStore";
import { session } from "../utils";

export class PlatformManagerStore {
  protected rootStore: RootStore;

  @observable public services: api.ServiceV13[] | null = null;

  @observable public operations: api.OperationsDays | null = null;

  @observable public specialDates: api.ExceptionOperationsDate[] | null = null;

  @observable public specialDatesYear: number = moment().year();

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getServices = async () => (this.services = await api.getServicesV13());

  @session
  @action
  public getOperations = async () => (this.operations = await api.getOperations());

  @session
  @action
  public getSpecialDates = async () => {
    this.specialDates = await api.getExceptionDate(
      moment()
        .year(this.specialDatesYear)
        .startOf("year")
        .toDate(),
      moment()
        .year(this.specialDatesYear)
        .endOf("year")
        .toDate(),
    );
  };

  @session
  @action
  public setServices = async (services: api.ServiceV13[]) => api.manageServicesV13(services);

  @session
  @action
  public setOperations = async (operations: api.OperationsDays) => api.setOperations(operations);

  @session
  @action
  public setSpecialDate = async (specialDate: api.ExceptionOperationsDate) => api.setExceptionDate(specialDate);

  @action
  public deleteSpecialDate = async (date: Date) => api.deleteExceptionDay(date);

  @action
  public checkProofOfLifePJService = async () => api.checkProofOfLifePJService();
}
