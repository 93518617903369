/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-template */
/* eslint-disable padding-line-between-statements */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable func-style */
/* eslint-disable no-duplicate-imports */
import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { CircularProgress } from "@material-ui/core";
import { toJS } from "mobx";
import { colors, listToMatrix } from "utils";

import { imagePathWithUrl } from "../../../../utils";
import { Dialog } from "../../../../components/Dialog";
import { ImagePreview } from "../../../../components/ImagePreview";
import {
  Block,
  Centralizer,
  Column,
  Container,
  ContentImage,
  ImageComponent,
  RepresentativeDataWrapper,
  RowContentImage,
  SubTitle,
  Title,
  TitleContentImage,
  Typography,
  WhiteCell,
  WrapperTextShowWithBureauMainInfo,
} from "./styled";
import { Table } from "../../../../components/Table";
import { useStores } from "../../../../stores/RootStore";
import { InnerAlert } from "../../../../components/InnerAlert";
import { Loader } from "../../../../components/Loader";
import { ActionsDropdown } from "../../../../components/ActionsDropdown";
import { ConfirmDialog } from "../../../../components/ConfirmDialog";
import { GeneralStore } from "../../../../stores";
import { StatusBuilder } from "./utils";

interface ISubscribersProps {
  companyId: string;
  onSelected: () => Promise<api.ComplianceData>;
  generalStore: GeneralStore;
}

export const Subscribers = (props: ISubscribersProps) => {
  const { openingAccountsStore, platformManagerStore, generalStore } = useStores();
  const [imageToDialog, setImageToDialog] = useState(null);
  const [imageDialogTitle] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [subscribersList, setSubscribersList] = useState<api.SubscriberAnalisys[] | null>(null);
  const [rowData, setRowData] = useState<api.SubscriberAnalisys | null>(null);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmTextIncrement, setConfirmTextIncrement] = useState("");
  const [changeStatus, setChangeStatus] = useState<api.StatusProofOfLife | null>(null);
  const [subscriberId, setRepresentativeId] = useState("");
  const [enableProofOfLifeService, setEnableProofOfLifeService] = useState(false);

  useEffect(() => {
    getSubscribersData();
  }, []);

  useEffect(() => {
    if (selectedRow) {
      const result = subscribersList!.find(element => {
        return element.id === selectedRow;
      });

      if (result) {
        setRowData(result);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    if (subscribersList) {
      setLoading(false);
    }
  }, [subscribersList]);

  const getSubscribersData = async () => {
    setApiError("");
    try {
      const response = await openingAccountsStore.getSubscribersByCompanyId(props.companyId);
      const hasProofOfLifeService = await platformManagerStore.checkProofOfLifePJService();

      setEnableProofOfLifeService(hasProofOfLifeService);
      setSubscribersList(response);
    } catch (err) {
      setLoading(false);
      setApiError(err.message);
    }
  };

  const renderActionsLoader = () => {
    if (!loading || !subscribersList) {
      return;
    }
    return <CircularProgress thickness={3} size={20} style={{ color: colors.violetRed }} />;
  };

  const openPopup = (image: any, title: string) => {
    const img = new Image();
    img.src = image;

    const width = img.width / 2;
    const height = img.height / 2;

    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;

    const urlInBase64 = btoa(image);

    window.open(
      imagePathWithUrl(`#${urlInBase64}`),
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left,
    );
  };

  const resendSubscriberProofOfLife = async () => {
    const index = subscribersList!.map(e => e.id).indexOf(subscriberId);

    setActionLoading(true);
    try {
      await openingAccountsStore.resendSubscriberProofOfLife(subscriberId);

      subscribersList![index].status = api.StatusProofOfLife.sendedEmail;
      generalStore.showToast("Email reenviado com sucesso.");
    } catch (err) {
      generalStore.showToast(err ? err.message : "Ocorreu um erro ao reenviar a prova de vida.");
    } finally {
      setActionLoading(false);
      setChangeStatus(null);
    }
  };

  const changeSubscriberStatus = async () => {
    const index = subscribersList!.map(e => e.id).indexOf(subscriberId);

    setActionLoading(true);
    try {
      const result = await openingAccountsStore.changeStatusSubscriberById(subscriberId, changeStatus!);

      subscribersList![index] = result;
      generalStore.showToast("Status da conta alterado com sucesso.");
    } catch (err) {
      generalStore.showToast(err ? err.message : "Ocorreu um erro ao alterar o status da conta.");
    } finally {
      setActionLoading(false);
      setChangeStatus(null);
    }
  };

  const buildActionMenu = (result: api.SubscriberAnalisys, options: any) => {
    const validStatus = ["waitingAcertId", "sendingAcertId", "sendedAcertId", "invalid", "failed", "pending"];

    if (result.status === "pending" || (validStatus.includes(result.status) && enableProofOfLifeService)) {
      if (generalStore.hasPermission(api.AdminUserPermission.approveAccountPJ)) {
        options.push({
          label: "Aprovar",
          onClick: () => {
            setConfirmTextIncrement("aprovar este signatário");
            setChangeStatus(api.StatusProofOfLife.approvedManual);
            setRepresentativeId(result.id);
          },
        });
      }

      if (generalStore.hasPermission(api.AdminUserPermission.disapproveAccountPJ)) {
        options.push({
          label: "Reprovar",
          onClick: () => {
            setConfirmTextIncrement("reprovar este signatário");
            setChangeStatus(api.StatusProofOfLife.reproved);
            setRepresentativeId(result.id);
          },
        });
      }
    }

    const validStatusToSendEmail = ["sendedEmail", "waitingEmail", "invalid", "pending"];
    if (
      validStatusToSendEmail.includes(result.status) &&
      generalStore.hasPermission(api.AdminUserPermission.revalidateAccountPJ)
    ) {
      options.push({
        label: "Reenviar prova de vida",
        onClick: () => {
          setConfirmTextIncrement("enviar o e-mail de prova de vida");
          setChangeStatus(api.StatusProofOfLife.sendingEmail);
          setRepresentativeId(result.id);
        },
      });
    }
  };

  const renderUser = () => {
    const titles = ["Nome", "Status", "Ações"];

    if (apiError) {
      return <InnerAlert message={apiError} buttonLabel="Tentar novamente" buttonClick={getSubscribersData} />;
    }

    if (loading) {
      return (
        <Centralizer>
          <Loader />
          <Typography>Aguarde, a resposta dos serviços pode demorar.</Typography>
        </Centralizer>
      );
    }

    if (!subscribersList || (subscribersList && subscribersList.length <= 0)) {
      return (
        <Centralizer>
          <Typography marginVertical={"5em"}>Esta conta não possui nenhum assinante.</Typography>
        </Centralizer>
      );
    }

    return (
      <React.Fragment>
        <Title>Signatários</Title>
        <Table
          customPadding={"4px 15px 4px 24px"}
          titles={titles}
          ids={subscribersList.map(e => e.id)}
          onClickRow={e => setSelectedRow(e)}
          selectedRow={selectedRow}
          data={subscribersList!.map(result => {
            const status = StatusBuilder(result.status);
            const tableData: any = [result.name, status];

            const options: any = [];
            buildActionMenu(result, options);

            if (options.length > 0) {
              tableData.push(<ActionsDropdown key={result.id} options={options} />);
            } else {
              tableData.push(<WhiteCell />);
            }

            return tableData;
          })}
        />
        <ConfirmDialog
          title="Atenção"
          text={`Você tem certeza que deseja ${confirmTextIncrement}?`}
          isOpen={Boolean(changeStatus)}
          isLoading={actionLoading}
          onCancel={() => setChangeStatus(null)}
          onConfirm={
            changeStatus === api.StatusProofOfLife.sendingEmail ? resendSubscriberProofOfLife : changeSubscriberStatus
          }
        />
      </React.Fragment>
    );
  };

  const renderImages = () => {
    if (!rowData) {
      return;
    }

    const imagesToMap = rowData && rowData.documents ? listToMatrix(toJS(rowData.documents), 2) : [];

    if (!imagesToMap.length) {
      return <SubTitle>Não há documentos para serem mostrados</SubTitle>;
    }

    return (
      <RepresentativeDataWrapper>
        <Title>Prova de vida</Title>
        {imagesToMap.map((row, idx) => (
          <Block key={idx}>
            {row.map(image => (
              <ContentImage key={image.name}>
                <RowContentImage>
                  <TitleContentImage>{image.name}</TitleContentImage>
                </RowContentImage>
                <ImagePreview url={image.url} onClick={() => openPopup(image.url, image.name)} />
              </ContentImage>
            ))}
          </Block>
        ))}
      </RepresentativeDataWrapper>
    );
  };

  const renderRepresentativeData = () => {
    if (!rowData) {
      return null;
    }

    return (
      <RepresentativeDataWrapper>
        {rowData.fieldGroups.map((group, index) => (
          <React.Fragment key={index}>
            <Title>{group.name}</Title>
            {group.fields.map((field, index2) => (
              <WrapperTextShowWithBureauMainInfo
                key={index2}
                title={field.label}
                content={field.values.map(value => ({
                  data: value.value,
                  source: value.source,
                }))}
              />
            ))}
          </React.Fragment>
        ))}
      </RepresentativeDataWrapper>
    );
  };

  return (
    <>
      <Dialog title={imageDialogTitle} isOpen={Boolean(imageToDialog)} onClose={() => setImageToDialog(null)}>
        <ImageComponent url={imageToDialog} />
      </Dialog>
      <Container>
        <Column width={"60%"}>{renderUser()}</Column>
        <Column width={"40%"}>
          {renderActionsLoader()}
          {renderRepresentativeData()}
          {renderImages()}
        </Column>
      </Container>
    </>
  );
};
