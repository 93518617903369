import React from "react";
import classnames from "classnames";

import { Select } from "components/Select";
import { ReactComponent as ArrowLeftIcon } from "assets/svgs/arrow-left-mini.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svgs/arrow-right-mini.svg";

import s from "./style.scss";

export interface IProps {
  current: number;
  pageSize: number;
  hideOnSinglePage?: boolean;
  pageSizeOptions?: number[];
  showSizeChanger?: boolean;
  total?: number;
  className?: string;
  onChange?: (page: number, pageSize: number) => void;
}

export function Pagination(props: IProps) {
  const [pageInfo, setPageInfo] = React.useState({ end: 0, start: 0 });

  React.useEffect(() => {
    if (!props.total) {
      setPageInfo({ end: 0, start: 0 });

      return;
    }

    if (props.current === 1) {
      setPageInfo({ end: props.pageSize > props.total ? props.total : props.pageSize, start: 1 });

      return;
    }

    const endLine = props.current * props.pageSize;

    setPageInfo({
      end: endLine > props.total ? props.total : endLine,
      start: endLine - props.pageSize + 1,
    });
  }, [props.current, props.pageSize, props.total]);

  function onNextPage() {
    if (props.onChange) {
      props.onChange(props.current + 1, props.pageSize);
    }
  }

  function onPrevPage() {
    if (props.onChange) {
      props.onChange(props.current - 1, props.pageSize);
    }
  }

  function onChangePageSize(pageSize: string) {
    const pageSizeNum = parseInt(pageSize, 10);

    if (props.onChange) {
      props.onChange(Math.ceil(pageInfo.start / pageSizeNum), pageSizeNum);
    }
  }

  if (props.hideOnSinglePage && props.total && props.total < props.pageSize) {
    return null;
  }

  return (
    <div className={classnames([s.pagination, props.className])} data-testid="pagination">
      {props.showSizeChanger && props.pageSizeOptions && (
        <div className={s.pagination_page_size}>
          <span>Linhas por página:</span>

          <Select
            options={props.pageSizeOptions.map(x => ({ label: `${x}`, value: `${x}` }))}
            value={{ label: `${props.pageSize}`, value: `${props.pageSize}` }}
            onChange={x => onChangePageSize(x.value)}
            isSearchable={false}
          />
        </div>
      )}

      <div className={s.pagination_current}>
        <span>{`${pageInfo.start}-${pageInfo.end} de ${props.total}`}</span>
      </div>

      <div className={s.pagination_actions}>
        <button disabled={props.current === 1} onClick={onPrevPage}>
          <ArrowLeftIcon />
        </button>

        <button disabled={pageInfo.end === props.total} onClick={onNextPage}>
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  );
}

Pagination.defaultProps = {
  hideOnSinglePage: true,
  pageSize: 20,
  pageSizeOptions: [20, 40, 60, 80],
  showSizeChanger: true,
  total: 0,
};
