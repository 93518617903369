import { observable } from "mobx";
import * as api from "api/manager-api";

import { RootStore } from "./RootStore";

export class ReportStore {
  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable public accountsReport: api.ReportFilter | null = null;

  @observable public accountsByPartnerReport: api.ReportFilterByPartner | null = null;

  @observable public filteredAccountsExcel: Buffer | null = null;

  @observable public filteredSimpleAccountsExcel: Buffer | null = null;

  @observable public partnerNames: api.PartnerName[] = [];

  @observable public lastFilter: api.FilterFields | null = null;

  @observable public lastFilterByPartner: api.FilterFieldsByPartner | null = null;

  @observable public lastAccountsReport: api.ReportFilter | null = null;

  @observable public lastAccountsReportByPartner: api.ReportFilterByPartner | null = null;

  @observable public lastLimit: number | null = null;

  @observable public lastOffset: number | null = null;

  public getFilteredAccounts = async (filter: api.FilterFields, managerFilter: api.ManagerReportFilter) => {
    const res = await api.getFilteredAccounts(filter, managerFilter);

    this.accountsReport = {
      ...res,
      total: res.total,
      users: res.users,
    };
  };

  public getFilteredAccountsByPartner = async (
    filter: api.FilterFieldsByPartner,
    managerFilter: api.ManagerReportFilter,
  ) => {
    const res = await api.getFilteredAccountsByPartner(filter, managerFilter);

    this.accountsByPartnerReport = {
      ...res,
      total: res.total,
      users: res.users,
    };
  };

  public getFilteredExcel = async (filter: api.FilterFields) => {
    return api.getExcelFromFilteredAccounts(filter);
  };

  public getCardFilteredExcel = async (cpf: string | null, partnerId: string | null, includeVirtualCards: boolean) => {
    return api.getExcelCardReport({
      cpf: cpf?.replace(/\D/gu, "") || null,
      partnerId,
      includeVirtualCards,
    });
  };

  public getFilteredExcelByPartner = async (filter: api.FilterFieldsByPartner) => {
    return api.getExcelFromFilteredAccountsByPartner(filter);
  };

  public getPartnersName = async () => (this.partnerNames = await api.getPartners());

  public getExcelFromFilteredSimpleAccounts = async (
    filter: api.ManagerAccountFilter,
    status: api.UserStatus | null,
    simpleAccountSituation: api.BlockStatusAccount | null,
  ) => {
    const accounts = await api.getExcelFromFilteredSimpleAccounts(filter, status, simpleAccountSituation);

    this.filteredSimpleAccountsExcel = accounts;
    return accounts;
  };

  public saveLastSearch = (
    lastFilter: api.FilterFields | null,
    accountsReport: api.ReportFilter | null,
    limit: number | null,
    offset: number | null,
  ) => {
    this.lastFilter = lastFilter;
    this.lastAccountsReport = accountsReport;
    this.lastLimit = limit;
    this.lastOffset = offset;
  };

  public saveLastSearchByPartner = (
    lastFilterByPartner: api.FilterFieldsByPartner | null,
    accountsReportByPartner: api.ReportFilterByPartner | null,
    limit: number | null,
    offset: number | null,
  ) => {
    this.lastFilterByPartner = lastFilterByPartner;
    this.lastAccountsReportByPartner = accountsReportByPartner;
    this.lastLimit = limit;
    this.lastOffset = offset;
  };

  public clearLastSearch = () => {
    this.lastFilter = null;
    this.lastAccountsReport = null;
    this.lastLimit = null;
    this.lastOffset = null;
    this.lastFilterByPartner = null;
    this.lastAccountsReportByPartner = null;
  };
}
