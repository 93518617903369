import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";
import { colors } from "utils";

import { OutlinedButton } from "../../../components/OutlinedButton";

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const SaveButton = styled(RaisedButton)`
  width: auto;
  font-family: Lato;
  margin: 15px 0;
  text-transform: none;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

export const BackButtonWrapper = styled(OutlinedButton)`
  background-color: #f2f3f5;
  border: none;
  margin-right: 20px;
  width: auto;
  text-transform: none;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #515251;
  padding: 10px 31px;
`;

export const Title = styled.h3`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
`;

export const TabsContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Spacing = styled.div`
  margin-bottom: 15px;
`;

export const WizardButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.has-two {
    justify-content: space-between;
  }
`;

export const Paragraph = styled.p`
  margin: 10px 40px 10px 0;
  font-weight: bold;
  font-size: 12px;
  color: ${colors.gray};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.borderGray};
`;

export const TitleWrapper = styled.div`
  max-width: 350px;
`;

export const Label = styled.label<{ marginRight?: string; isClickable?: boolean }>`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  margin-right: ${props => props.marginRight || "0"};
  cursor: ${props => (props.isClickable ? "pointer" : "default")};
`;
