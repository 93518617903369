export const range = (length: number, init = 0): number[] => {
  return Array.apply(null, { length } as any[]).map((_: any, i: number) => init + i);
};

export const tableFilter = (filter: any) => (row: any) => {
  for (const field in filter) {
    if (filter[field] !== null && filter[field] !== row[field]) {
      return null;
    }
  }

  return row;
};

export const removeSpecialChar = (value: string) => {
  return value.replace(/[^\d]+/g, "");
};

export const currencyParser = (formattedCurrency: string) => parseInt(formattedCurrency.replace(/\D/g, ""), 10) / 100;

export const uppercaseFirst = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const listToMatrix = <T>(list: T[], elementsPerSubArray: number) => {
  const matrix: T[][] = [];
  let i: number;
  let k: number;

  for (i = 0, k = -1; i < list.length; i++) {
    if (i % elementsPerSubArray === 0) {
      k++;
      matrix[k] = [];
    }

    matrix[k].push(list[i]);
  }

  return matrix;
};

export const RequestFn = async <T, S = any>(request: () => Promise<T>): Promise<IResponse<T, S>> => {
  const resp: IResponse<T, S> = {
    status: "success",
    message: null,
    data: null,
    errorType: null,
  };

  try {
    resp.data = await request();
  } catch (error) {
    resp.status = "error";
    resp.message = error.message;
    resp.errorType = error.type;
  }

  return resp;
};

export const coalesce = <T extends {}>(firstValue: T, ...values: T[]) => (fn?: (value: T) => any) => {
  for (const value of [firstValue, ...values]) {
    if (value) {
      return fn ? fn(value) : value;
    }
  }

  return null;
};

export const truncateStr = (str?: string, maxChar?: number) => {
  if (str!.length <= maxChar!) {
    return str;
  }

  const strSliced = str!.slice(0, maxChar);

  if (strSliced.length <= 3) {
    return `${strSliced}...`;
  }

  const stringTruncate = `${strSliced.slice(0, -3)}...`;

  return stringTruncate;
};

export const searchEncode = (query = "") => query.replace(/ /g, "+").replace(/[^\w\+]/g, "");

export const compareString = (a: string, b: string) => {
  return a
    .trim()
    .toLowerCase()
    .includes(b.trim().toLocaleLowerCase());
};

export const validateCpf = (value: string) => {
  const cpf = value.replace(/\D/g, "");

  let sum;
  let rest;

  sum = 0;
  if (cpf === "00000000000") {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
};

export function intergerMask(num: string): string {
  return num.replace(/\D/g, "");
}

export const maskValidateCpf = [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/];

export const maskValidateCnpj = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];

export function mask(value: string, mask: string) {
  let newValue = value.replace(/\-/gi, "").replace(/\./gi, "");
  let newMask = mask.replace(/\-/gi, "").replace(/\./gi, "");

  if (newValue.length !== newMask.length) {
    return value;
  }
  let newText = "";
  let countSeparator = 0;
  for (var i = 0; i <= mask.length; i++) {
    if (mask.charAt(i).toString() === "#") {
      newText += newValue.charAt(i - countSeparator).toString();
    } else {
      newText += mask.charAt(i).toString();
      countSeparator++;
    }
  }
  return newText;
}
