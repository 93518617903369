/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import classnames from "classnames";
import RcTable from "rc-table";
import { TableProps as RcTableProps } from "rc-table/lib/Table";

import { IProps as IPaginationProps, Pagination } from "../Pagination";
import { Spin } from "../Spin";
import { EmptyData, IEmptyDataProps } from "./EmptyData";

import "rc-table/assets/index.css";
import s from "./style.scss";

interface IProps extends Omit<RcTableProps<any>, "emptyText"> {
  pagination?: IPaginationProps;
  disablePagination?: boolean;
  loading?: boolean;
  empty?: IEmptyDataProps;
  rowClickableStyle?: boolean;
}

export function Table(props: IProps) {
  const { className, data, pagination, disablePagination, loading, empty, rowClickableStyle, ...rest } = props;

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(20);
  const [slicedData, setSlicedData] = React.useState<any[]>([]);

  function onChangePagination(pageNumber: number, size: number) {
    setPage(pageNumber);
    setPageSize(size);
  }

  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (page === 1) {
      setSlicedData(data.slice(0, pageSize));
      return;
    }

    const endLine = page * pageSize;
    const lastItemPage = endLine > data.length ? data.length : endLine;

    setSlicedData(data.slice(endLine - pageSize, lastItemPage));
  }, [page, pageSize, data]);

  return (
    <div className={className}>
      <Spin spinning={loading}>
        <RcTable
          className={classnames(s.table, rowClickableStyle && s.table_clickable)}
          data={props.pagination || disablePagination ? props.data : slicedData}
          emptyText={props.loading ? <div className={s.loading_wrapper}></div> : <EmptyData {...empty} />}
          data-testid="table"
          {...rest}
        />

        {!disablePagination && (
          <div className={s.table_pagination}>
            {pagination ? (
              <Pagination {...pagination} />
            ) : (
              <Pagination current={page} total={data?.length} pageSize={pageSize} onChange={onChangePagination} />
            )}
          </div>
        )}
      </Spin>
    </div>
  );
}
