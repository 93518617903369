import React from "react";
import { observer } from "mobx-react";
import * as api from "api/manager-api";

import { Modal } from "@components/";

import s from "./styled.scss";

interface IProps {
  isOpen: boolean;
  errorLists: api.QueueError[];
  onClose: () => void;
}

export const ErrorRequestCardDialog = observer((props: IProps) => {
  return (
    <Modal
      visible={props.isOpen}
      title="Motivo de falha do processamento da solicitação"
      onClose={props.onClose}
      destroyOnClose
    >
      <div className={s.box}>
        {props.errorLists.map(error => (
          <>
            <ul>
              <li>{error.message}</li>
            </ul>
          </>
        ))}
      </div>
    </Modal>
  );
});
