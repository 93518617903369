import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { FormikErrors, useFormik } from "formik";
import { TextInput } from "legacy/components/TextInput";
import { Alert } from "legacy/components/Alert";

import { useStores } from "../../stores/RootStore";
import { TextAreaInput } from "../../components/TextAreaInput";
import { Dialog } from "../../components/Dialog";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { Loader } from "../../components/Loader";
import { HistoryTable } from "./HistoryTable";
import { Checkbox } from "./../../components/Checkbox";
import {
  CheckboxContainer,
  Container,
  CustomSelect,
  DialogContent,
  ErrorMessage,
  FormContainer,
  HistoryTitle,
  InputContainer,
  InputLabel,
  RaisedButtonWrapper,
  TableContainer,
  Title,
} from "./styled";

export interface ITableData {
  id: string;
  author: string;
  time: Date;
  title: string;
  message: string;
}

interface IOption {
  label: string;
  value: string;
}
interface IInfo {
  historyEntryId: string | null;
  isOpen: boolean;
}

interface IForm {
  message: string;
  title: string;
  sendToAllPartners: boolean;
  selectedPartners: IOption[];
}

export const NotificationManager = observer(() => {
  const { notificationManagerStore, partnerStore } = useStores();
  const [dialogData, setDialogData] = useState<IInfo>({
    historyEntryId: null,
    isOpen: false,
  });
  const [loadingSendNotification, setLoadingSendNotification] = useState<boolean>(false);
  const [loadingGetNotifications, setLoadingGetNotifications] = useState<boolean>(false);
  const [loadingPartners, setLoadingPartners] = useState<boolean>(false);
  const [alertSendNotification, setAlertSendNotification] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<string>("");

  const fmk = useFormik<IForm>({
    initialValues: {
      message: "",
      selectedPartners: [],
      sendToAllPartners: true,
      title: "",
    },
    onSubmit: sendNotification,
    validate: values => {
      const errors: FormikErrors<IForm> = {};
      const message = "Campo obrigatório";

      if (values.title.length === 0) {
        errors.title = message;
      }

      if (values.message.length === 0) {
        errors.message = message;
      }

      if (values.selectedPartners.length === 0 && values.sendToAllPartners === false) {
        errors.selectedPartners = message;
      }

      return errors;
    },
  });

  useEffect(() => {
    getNotifications();
    getPartners();
  }, []);

  async function onSubmiting() {
    const errors = await fmk.validateForm(fmk.values);

    if (!Object.keys(errors).length) {
      setAlertSendNotification(true);
    }

    const objTouched = Object.keys(fmk.values).reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: true,
      };
    }, {});

    fmk.setTouched(objTouched);
  }

  async function getPartners() {
    try {
      setLoadingPartners(true);

      await partnerStore.getPartners();
    } catch (err) {
      setAlertError(err);
    } finally {
      setLoadingPartners(false);
    }
  }

  async function getNotifications() {
    try {
      setLoadingGetNotifications(true);
      await notificationManagerStore.getNotifications();
    } catch (err) {
      setAlertError(err);
    } finally {
      setLoadingGetNotifications(false);
    }
  }

  async function sendNotification(values: IForm) {
    try {
      setLoadingSendNotification(true);
      if (fmk.values.sendToAllPartners) {
        await notificationManagerStore.sendNotification(values.title, values.message);
      } else {
        await notificationManagerStore.sendNotificationByPartner(
          values.title,
          values.message,
          values.selectedPartners.map(partners => partners.value),
        );
      }

      await getNotifications();
      fmk.resetForm();
    } catch (err) {
      setAlertError(err.message);
    } finally {
      setLoadingSendNotification(false);
      setAlertSendNotification(false);
    }
  }

  function handleChangeForm(key: keyof IForm, value: any) {
    fmk.setFieldValue(key, value);
  }

  function showMessage(id: string) {
    setDialogData({
      historyEntryId: id,
      isOpen: true,
    });
  }

  const selectedEntry = notificationManagerStore.notifications.find(entry => entry.id === dialogData?.historyEntryId);

  return (
    <Container>
      <Alert
        title="Um problema ocorreu"
        text={alertError}
        isOpen={alertError.length > 0}
        onClose={() => setAlertError("")}
      />
      <ConfirmDialog
        onCancel={() => setAlertSendNotification(false)}
        onConfirm={fmk.submitForm}
        isOpen={alertSendNotification}
        title="Atenção"
        text="Você tem certeza que deseja enviar essa notificação para todos os usuários do aplicativo?"
        isLoading={loadingSendNotification}
      />
      <Dialog
        isOpen={dialogData.isOpen || false}
        title="Mensagem"
        onClose={() => setDialogData(state => ({ ...state, isOpen: false }))}
        showCloseButton
      >
        <DialogContent>
          <strong>
            <span>{selectedEntry?.title}</span>
          </strong>
          <p>{selectedEntry?.message}</p>
        </DialogContent>
      </Dialog>
      <Title>Gestão de Notificações</Title>
      <FormContainer>
        <InputLabel>Título</InputLabel>
        <TextInput
          error={Boolean(fmk.errors.title && fmk.touched.title)}
          errorText="Campo obrigatório"
          onChange={e => handleChangeForm("title", e.target.value)}
          value={fmk.values.title}
        />
        <InputLabel>Mensagem</InputLabel>
        <InputContainer>
          <TextAreaInput
            onChange={e => handleChangeForm("message", e.target.value)}
            value={fmk.values.message}
            error={Boolean(fmk.errors.message && fmk.touched.message)}
            errorText="Campo obrigatório"
          />
        </InputContainer>
        <CheckboxContainer>
          <label htmlFor="sendToAllPartners">Enviar para todos os parceiros?</label>
          <Checkbox
            name="sendToAllPartners"
            checked={fmk.values.sendToAllPartners}
            handleChange={e => handleChangeForm("sendToAllPartners", e.target.checked)}
          />
          {!fmk.values.sendToAllPartners && (
            <>
              <CustomSelect
                isLoading={loadingPartners}
                placeholder="Selecionar parceiros"
                options={partnerStore.partners?.map(partner => ({ label: partner.name, value: partner.id }))}
                onChange={(values: IOption[]) => handleChangeForm("selectedPartners", values)}
                value={fmk.values.selectedPartners}
                isMulti
                hasError={Boolean(fmk.errors.selectedPartners && fmk.touched.selectedPartners)}
              />
              {Boolean(fmk.errors.selectedPartners && fmk.touched.selectedPartners) && (
                <ErrorMessage>Campo obrigatório</ErrorMessage>
              )}
            </>
          )}
        </CheckboxContainer>
        <RaisedButtonWrapper onClick={onSubmiting} label="Enviar" loading={loadingSendNotification} />
      </FormContainer>
      <TableContainer>
        <HistoryTitle>Histórico</HistoryTitle>

        {loadingGetNotifications ? (
          <Loader />
        ) : (
          <HistoryTable
            data={notificationManagerStore.notifications.map(notification => ({
              author: notification.userName || "Não especificado",
              id: notification.id,
              message: notification.message,
              time: notification.date,
              title: notification.title,
            }))}
            messageClickHandler={showMessage}
          />
        )}
      </TableContainer>
    </Container>
  );
});
