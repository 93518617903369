import styled from "styled-components";
import { SvgPlayButton } from "@manager/components/Icons/SvgPlayButton";
import { colors } from "utils";

export const Container = styled.div`
  border: 0;
  border-left: 1px solid #434356;
`;

export const Button = styled.button<{
  isOpened: boolean;
}>`
  display: flex;
  border: 0;
  align-items: center;
  width: 100%;
  padding: ${({ isOpened }) => (isOpened ? "16px 24px 12px" : "17px")};
  font-size: 14px;
  font-weight: 700;
  color: ${colors.lightPink};
  background: transparent;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  outline: none;

  &:hover,
  &:focus {
    color: ${colors.white};

    & > svg {
      color: ${colors.white};
    }
  }

  & > svg {
    ${({ isOpened }) => (isOpened ? "transform: rotate(90deg);" : "")}
  }

  & > div {
    flex: 1;
    text-align: left;
  }
`;

export const Content = styled.div<{
  isOpened: boolean;
  offsetHeight: number;
}>`
  width: calc(100% + 2px);
  left: 23px;
  position: relative;
  overflow: hidden;
  transition: all ease-in-out 0.3s;

  ${({ isOpened, offsetHeight }) => `
    height: ${isOpened ? `${offsetHeight}px` : "0"}
  `}

  & > div > a {
    padding-left: 40px;
    border-radius: 0 4px 4px 0;
    overflow: hidden;

    &:not(.active) {
      font-weight: 400;
    }
  }
`;

export const PlayButton = styled(SvgPlayButton)`
  width: 8px;
  margin: 0 10px;
  transition: transform 0.3s;
  color: ${colors.gray};
`;
