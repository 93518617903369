import * as React from "react";
import { colors } from "utils";
import { Checkbox } from "../Checkbox";
import styled from "styled-components";

export interface ICheckboxListItem<T> {
  label: string;
  value: T;
}

interface IProps<T> {
  items: Array<ICheckboxListItem<T>>;
  selected: T[];
  onChange: (value: T[]) => void;
  disabled?: boolean;
  total?: any;
}

export function CheckboxList<T>({ items, selected, onChange, disabled, total = null }: IProps<T>) {
  const has = (value: T) => selected.indexOf(value) !== -1;
  const toggle = (value: T) => () =>
    onChange(has(value) ? selected.filter(current => value !== current) : [...selected, value]);

  return (
    <Container>
      {items.map(({ value, label }, index) => (
        <Block key={index}>
          <Label>{label}</Label>
          <Checkbox disabled={disabled} checked={has(value)} handleChange={toggle(value)} />
          {total && total[value] >= 0 && <LabelTotal>{total[value]}</LabelTotal>}
        </Block>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: "space-between";
  align-items: "center";
  margin: 16px auto;
  flex-wrap: wrap;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-child) {
    margin-right: 32px;
  }
`;

const Label = styled.label`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black};
`;

const LabelTotal = styled.span`
  padding: 1px 20px;
  text-align: center;
  background-color: #e3e4e8;
  border: 1px solid #dcdce0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #a6a6a6;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  vertical-align: super;
`;
