import * as React from "react";
import styled, { css } from "styled-components";
import MaskedInput from "react-text-mask";
import { FormHelperText } from "@material-ui/core";

import { colors } from "../../../utils";

export interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  error?: boolean;
  errorText?: string;
  isSecret?: boolean;
  mask?: Array<RegExp | string>;
  icon?: any;
  isChat?: boolean;
  multiline?: boolean;
  multilineRows?: number;
}

interface IInputProps {
  styled: { error: boolean; hasicon: boolean };
}

export const TextInput: React.FunctionComponent<ITextInputProps> = ({
  type = "text",
  mask,
  icon,
  label,
  isSecret,
  unselectable,
  error,
  errorText,
  isChat,
  multiline,
  multilineRows,
  ...allProps
}) => (
  <Container isChat={isChat}>
    {label ? typeof label === "string" ? <Label>{label}</Label> : label : null}
    <WrapperImage icon={icon}>
      {mask ? (
        <Input
          styled={{
            hasicon: Boolean(icon),
            error: Boolean(error),
          }}
          mask={mask}
          type={isSecret ? "password" : type}
          guide={mask ? false : undefined}
          {...allProps}
        />
      ) : multiline ? (
        <TextArea
          styled={{
            hasicon: Boolean(icon),
            error: Boolean(error),
          }}
          rows={multilineRows || 5}
          {...(allProps as any)}
        />
      ) : (
        <InputWithoutMask
          styled={{
            hasicon: Boolean(icon),
            error: Boolean(error),
          }}
          type={isSecret ? "password" : type}
          {...allProps}
        />
      )}
      {Boolean(error) && Boolean(errorText) ? (
        <ErrorTextWrapper>
          <FormHelperText error>{errorText}</FormHelperText>
        </ErrorTextWrapper>
      ) : (
        <Spacing />
      )}
    </WrapperImage>
  </Container>
);

const inputStyle = css<IInputProps>`
  background-color: transparent;
  border: 1px solid ${({ styled: { error } }) => (error ? colors.red : colors.platinum)};
  border-radius: 5px;
  color: ${colors.black};
  font-family: Lato;
  font-size: 15px;
  padding: ${({ styled: { hasicon } }) => (hasicon ? "10px 0 10px 40px" : "10px 0 10px 10px")};
  width: 320px;
  height: 40px;
  outline: none;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    border-color: ${colors.violetRedOpacity};
  }

  @mixin placeholder {
    font-size: 15px;
    color: ${colors.gray};
  }

  ::placeholder {
    font-size: 15px;
    color: ${colors.gray};
  }

  ::-webkit-input-placeholder {
    font-size: 15px;
    color: ${colors.gray};
  }
`;

const Input = styled(MaskedInput)<IInputProps>`
  ${inputStyle};
`;

const InputWithoutMask = styled.input<IInputProps>`
  ${inputStyle};
`;

const TextArea = styled.textarea<IInputProps>`
  ${inputStyle};
  min-width: 100%;
  max-width: 100%;
`;

const WrapperImage = styled.span<{ icon?: any }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ icon }) => {
    return icon
      ? `
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 8px;
      background-image: url(${icon});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 25px;
      width: 25px;
    }
    `
      : "";
  }};
`;

const ErrorTextWrapper = styled.div`
  margin-left: 3px !important;
  p {
    margin-top: 4px !important;
    margin-bottom: 4px;
  }
`;

const Spacing = styled.div`
  height: 17px;
`;

const Container = styled.div<{ isChat?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isChat }) => (isChat ? "65%" : "none")};
`;

const Label = styled.label`
  font-family: Lato;
  color: ${colors.gray};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.1px;
  margin-bottom: 9px;
`;
