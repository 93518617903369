import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./RootStore";
import { session } from "../utils";

export class ConciliationStore {
  protected rootStore: RootStore;

  @observable public accountBalance = 0;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public exportConvenantConciliation = async (start: Date | null, end: Date) => {
    return api.exportConvenantConciliation(start, end);
  };

  @session
  @action
  public exportCardConciliation = async (documentNumber: string | null, start: Date, end: Date) => {
    return api.exportCardConciliation(documentNumber, null, start, end, null);
  };

  @session
  @action
  public exportWithdrawConciliation = async (start: Date | null, end: Date) => {
    return api.exportWithdrawConciliation(start, end);
  };

  @session
  @action
  public createTedHugPay = async () => api.createTedHugPay();
}
