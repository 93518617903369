import * as React from "react";
import {
  PartnerName,
  PfPj,
  FilterFields,
  CategoryAccount,
  UserStatus,
  Gender,
  ApprovationType,
  SubType,
  JourneyType,
  // StatusAccount,
} from "api/manager-api";
import { SelectSoccer } from "../../../apps/soccer/components/Select";
import compose from "ramda/es/compose";
import map from "ramda/es/map";
import find from "ramda/es/find";
import styled from "styled-components";
import { ValueType } from "react-select";
import propEq from "ramda/es/propEq";
import prop from "ramda/es/prop";

import { NumberRange, RangeInput } from "../../../apps/manager/components/RangeInput";
import { DateRange, DateRangePicker } from "../../../apps/manager/components/DateRangePicker";
import { RaisedButton } from "../RaisedButton";
import { Select } from "../../../apps/manager/components/Select";
import { OutlinedButton } from "../../components/OutlinedButton";
import { colors } from "../../../utils";
const { useState } = React;

import { SvgArrowRepeat } from "../../icons/SvgArrowRepeat";
import { Checkbox } from "../Checkbox";
import color from "@material-ui/core/colors/amber";

interface IProps {
  partners?: PartnerName[];
  onSubmit: (filter: FilterFields) => void;
  lastFilter?: FilterFields;
  isSoccer?: boolean;
}

interface IOption<T> {
  value: T;
  label: string;
}

type OptionList<T> = Array<IOption<T>>;
type Optionifier<T> = (array: T[]) => OptionList<T>;
type MultiFilters = Pick<
  FilterFields,
  | "accountType"
  | "status"
  | "region"
  | "idPartner"
  | "startRegisterDate"
  | "endRegisterDate"
  | "typePerson"
  | "gender"
  | "startAge"
  | "endAge"
  | "approvation"
  | "closedAt"
  | "closingReason"
  | "subType"
  | "journeyType"
  | "accountStatus"
>;

export const OpeningAccountsReportsFilter: React.FunctionComponent<IProps> = ({
  partners,
  onSubmit,
  lastFilter,
  isSoccer,
}) => {
  // State
  const [state, setState] = useState<FilterFields>(skeleton);
  const [statusAccount, setStatusAccount] = React.useState(false);
  const handleClear = () => setState(skeleton);
  const handleOk = () => onSubmit(state);

  React.useEffect(() => setState(lastFilter ? lastFilter : state), []);

  // Fields
  const handleDates = ({ startDate, endDate }: DateRange) =>
    setState({
      ...state,
      startRegisterDate: startDate,
      endRegisterDate: endDate,
    });
  const handleAges = ({ start, end }: NumberRange) =>
    setState({
      ...state,
      startAge: start,
      endAge: end,
    });

  // Mixins
  function selectMixin<T>(key: keyof FilterFields) {
    return (options: OptionList<T>) => ({
      options,
      value: find<IOption<T>>(propEq<any>("value", state[key]))(options),
      onChange: (value: ValueType<IOption<T>>) => setState({ ...state, [key]: value }),
    });
  }

  function handleStatusAccount() {
    setState({
      ...state,
      accountStatus: !statusAccount,
    });
    return setStatusAccount(!statusAccount);
  }

  function multiSelectMixin<T>(key: keyof MultiFilters) {
    const list = state[key] as T[] | null;

    return (options: OptionList<T>) => ({
      isMulti: true,
      options,
      value: list
        ? map<T, IOption<T>>(
          value => ({
            label: find<IOption<T>>(propEq("value", value))(options)!.label,
            value,
          }),
          list,
        )
        : [],
      onChange: (input: ValueType<IOption<T>>) => {
        const values = (input as any) as Array<IOption<T>>;

        setState({
          ...state,
          [key]: values.length ? map(prop("value"), values) : null,
        });
      },
    });
  }

  return (
    <Wrapper>
      {typeof partners !== "undefined" ? (
        <Field>
          {isSoccer ? (
            <SelectSoccer
              placeholder="Parceiros"
              {...compose(multiSelectMixin<PartnerName>("idPartner"), byProp<PartnerName>("name"))(partners)}
            />
          ) : (
              <Select
                placeholder="Parceiros"
                {...compose(multiSelectMixin<PartnerName>("idPartner"), byProp<PartnerName>("name"))(partners)}
              />
            )}
        </Field>
      ) : null}

      <Field>
        {isSoccer ? (
          <SelectSoccer placeholder="Tipo de pessoa" {...compose(selectMixin("typePerson"))(personTypes as any)} />
        ) : (
            <Select placeholder="Tipo de pessoa" {...compose(selectMixin("typePerson"))(personTypes as any)} />
          )}
      </Field>

      <Field>
        {isSoccer ? (
          <SelectSoccer placeholder="Tipos de conta" {...compose(multiSelectMixin("accountType"))(accountTypes)} />
        ) : (
            <Select placeholder="Tipos de conta" {...compose(multiSelectMixin("accountType"))(accountTypes)} />
          )}
      </Field>

      <Field>
        {isSoccer ? (
          <SelectSoccer placeholder="Tipo da aprovação" {...compose(selectMixin("approvation"))(approvation as any)} />
        ) : (
            <Select placeholder="Tipo da aprovação" {...compose(selectMixin("approvation"))(approvation as any)} />
          )}
      </Field>

      <Field>
        {isSoccer ? (
          <SelectSoccer placeholder="Status" {...compose(multiSelectMixin("status"))(statuses)} />
        ) : (
            <Select placeholder="Status" {...compose(multiSelectMixin("status"))(statuses)} />
          )}
      </Field>

      <Field>
        {isSoccer ? (
          <SelectSoccer placeholder="Sexo" {...compose(selectMixin("gender"))(genders as any)} />
        ) : (
            <Select placeholder="Sexo" {...compose(selectMixin("gender"))(genders as any)} />
          )}
      </Field>

      <Field>
        {isSoccer ? (
          <SelectSoccer placeholder="SubTipo" {...compose(selectMixin("subType"))(subTypes as any)} />
        ) : (
            <Select placeholder="SubTipo" {...compose(selectMixin("subType"))(subTypes as any)} />
          )}
      </Field>

      <Field>
        {isSoccer ? (
          <SelectSoccer placeholder="Tipo Jornada" {...compose(selectMixin("journeyType"))(journeyTypes as any)} />
        ) : (
            <Select placeholder="Tipo Jornada" {...compose(selectMixin("journeyType"))(journeyTypes as any)} />
          )}
      </Field>

      <Row>
        <Checkbox style={{ color: "#ed2980" }} disabled={false} checked={statusAccount} handleChange={handleStatusAccount} />
        <Text>Contas Encerradas</Text>
      </Row>

      <CustomBorder />

      <SecondRowContainer>
        <Left>
          <Title>Período</Title>
          <FieldDateRangePicker>
            <DateRangePicker
              startDate={state.startRegisterDate}
              endDate={state.endRegisterDate}
              onChange={handleDates}
              startLabel="Início"
              endLabel="Final"
            />
          </FieldDateRangePicker>

          <Title>Idade</Title>
          <RangeInputField>
            <RangeInput
              start={state.startAge}
              end={state.endAge}
              onChange={handleAges}
              placeholderStart="Min"
              placeholderEnd="Max"
            />
          </RangeInputField>
        </Left>

        <Right>
          <StyledOutlinedButton
            label="Limpar filtros"
            onClick={handleClear}
            iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
          />
          <StyledRaisedButton label="Buscar" onClick={handleOk} customColor={isSoccer ? colors.darkGreen : undefined} />
        </Right>
      </SecondRowContainer>
    </Wrapper>
  );
};

export const skeleton: FilterFields = {
  idPartner: null,
  startRegisterDate: null,
  endRegisterDate: null,
  typePerson: null,
  accountType: null,
  status: null,
  gender: null,
  region: null,
  startAge: null,
  endAge: null,
  approvation: null,
  subType: null,
  journeyType: null,
  closedAt: null,
  closingReason: null,
  accountStatus: null
};

const personTypes: OptionList<PfPj | null> = [
  {
    value: null,
    label: "Tipo de Pessoa",
  },
  {
    value: PfPj.pf,
    label: "PF",
  },
  {
    value: PfPj.pj,
    label: "PJ",
  },
  {
    value: PfPj.mei,
    label: "MEI",
  },
];

const accountTypes: OptionList<CategoryAccount> = [
  {
    value: CategoryAccount.checking,
    label: "Corrente",
  },
  {
    value: CategoryAccount.payment,
    label: "Pagamento",
  },
  {
    value: CategoryAccount.credit,
    label: "Crédito",
  },
  {
    value: CategoryAccount.investment,
    label: "Investimentos",
  },
  {
    value: CategoryAccount.exchange,
    label: "Câmbio",
  },
];

const statuses: OptionList<UserStatus> = [
  {
    value: UserStatus.active,
    label: "Aprovada",
  },
  {
    value: UserStatus.canceled,
    label: "Cancelada",
  },
  {
    value: UserStatus.repproved,
    label: "Reprovada",
  },
  {
    value: UserStatus.notFinished,
    label: "Não-finalizada",
  },
  {
    value: UserStatus.pending,
    label: "Pendente",
  },
  {
    value: UserStatus.finished,
    label: "Em análise",
  },
  {
    value: UserStatus.approvePendingWithDocument,
    label: "Simples c/ documento",
  },
  {
    value: UserStatus.approvePendingWithoutDocument,
    label: "Simples s/ documento",
  },
];

const genders: OptionList<Gender | null> = [
  {
    label: "Sexo",
    value: null,
  },
  {
    label: "Masculino",
    value: Gender.masculino,
  },
  {
    label: "Feminino",
    value: Gender.feminino,
  },
  {
    label: "Não informado",
    value: Gender.outro,
  },
];

const subTypes: OptionList<SubType | null> = [
  {
    label: "SubTipo",
    value: null,
  },
  {
    label: "Simples",
    value: SubType.simple,
  },
  {
    label: "Full",
    value: SubType.full,
  },
];

const journeyTypes: OptionList<JourneyType | null> = [
  {
    label: "Tipo Jornada",
    value: null,
  },
  {
    label: "Reduzida C. Pre-cadastro",
    value: JourneyType.preApproved,
  },
  {
    label: "Reduzida S. Pre-cadastro",
    value: JourneyType.noRecord,
  },
];

const approvation: OptionList<ApprovationType | null> = [
  {
    label: "Tipo de aprovação",
    value: null,
  },
  {
    label: "Aprovação automática",
    value: ApprovationType.automatic,
  },
  {
    label: "Aprovação manual",
    value: ApprovationType.manual,
  },
  {
    label: "Não especificada",
    value: ApprovationType.unknown,
  },
];

/*
 * Const regions: OptionList<Region> = [
 *   {
 *     label: "Norte",
 *     value: Region.norte,
 *   },
 *   {
 *     label: "Nordeste",
 *     value: Region.nordeste,
 *   },
 *   {
 *     label: "Centro-Oeste",
 *     value: Region.centroOeste,
 *   },
 *   {
 *     label: "Sudeste",
 *     value: Region.sudeste,
 *   },
 *   {
 *     label: "Sul",
 *     value: Region.sul,
 *   },
 * ];
 */

function byProp<T>(key: keyof T): Optionifier<T> {
  return map((item: any) => ({
    value: item.id,
    label: item[key],
  }));
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -8px;
`;

const Title = styled.span`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;

const Field = styled.div`
  flex: 1 0 170px;
  padding: 8px;
  max-width: 182px;
`;

const RangeInputField = styled.div`
  padding: 8px;
  width: 130px;
`;

const FieldDateRangePicker = styled.div`
  flex: 1 0 160px;
  padding: 8px;

  .DateInput {
    width: 90px;
    height: 40px;
    margin-left: 10px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledRaisedButton = styled(RaisedButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
  height: 40px;
  margin-right: 10px;
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

const CustomBorder = styled.span`
  border-top: 1px solid ${colors.lightGray};
  width: 100%;
  margin: 24px 0px 32px 0px;
`;

const SecondRowContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  margin-top: 10px;
`;

const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.black};
  padding: 0;
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
