import { syncHistoryWithStore } from "mobx-react-router";
import { createBrowserHistory } from "history";
import { createContext, useContext } from "react";

import * as stores from "./";

export class RootStore {
  public routerStore = new stores.RouterStore();

  public history = syncHistoryWithStore(createBrowserHistory(), this.routerStore);

  public authStore = new stores.AuthStore(this);

  public employeeManagerStore = new stores.EmployeeManagerStore(this);

  public generalStore = new stores.GeneralStore(this);

  public legalEntityStore = new stores.LegalEntityStore(this);

  public openingAccountsStore = new stores.OpeningAccountsStore(this);

  public platformManagerStore = new stores.PlatformManagerStore(this);

  public partnerStore = new stores.PartnerStore(this);

  public usersStore = new stores.UsersStore(this);

  public auditStore = new stores.AuditStore(this);

  public conciliationStore = new stores.ConciliationStore(this);

  public notificationManagerStore = new stores.NotificationManagerStore(this);

  public accountsStore = new stores.AccountsStore(this);

  public reportStore = new stores.ReportStore(this);

  public errorsStore = new stores.ErrorsStore(this);

  public internalAPIsStore = new stores.InternalAPIsStore(this);

  public transactionalBoundariesStore = new stores.TransactionalBoundariesStore(this);

  public cardStore = new stores.CardStore(this);

  public featureFlagStore = new stores.FeatureFlagStore(this);
}

export const rootStore = new RootStore();

const rootStoreCtx = createContext(rootStore);

export function useStores() {
  return useContext(rootStoreCtx);
}
