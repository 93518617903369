/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable func-style */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import { PfPj } from "api/manager-api";
import { inject, observer } from "mobx-react";

import { colors, prettierAbvAccountType, prettierAccountName, prettierBranchName } from "../../../utils";
import { Table } from "../../../components/Table";
import { UsersStore } from "../../../stores";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  usersStore?: UsersStore;
}

@inject("usersStore")
@observer
export class UserAccounts extends React.Component<IProps> {
  public render() {
    const { userAccounts } = this.props.usersStore!;

    if (!userAccounts) {
      return <InnerAlert message={"Não há informações para serem mostradas."} />;
    }

    return (
      <Table
        titles={["Tipo", "Agência", "Conta", "Status"]}
        ids={userAccounts!.map((_, i) => `acc-${i}`)}
        data={userAccounts!.map((row, i) => [
          <Tag key={`typeTag-${i}`} type={row.type} />,
          prettierBranchName(row.branch),
          prettierAccountName(row.account),
          row.status === "active" ? "Ativa" : "Encerrada",
        ])}
      />
    );
  }
}

const RawTag: React.SFC<{ type: PfPj; className?: any }> = ({ type, className }) => (
  <div className={className}>{prettierAbvAccountType(type)}</div>
);

const Tag = styled(RawTag)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  font-family: Lato;
  color: ${({ type }) => (type === "pf" ? colors.violetRed : colors.black)};
  background-color: ${({ type }) => (type === "pf" ? colors.violetRedOpacity : colors.mediumGray)};
  height: 24px;
  width: 26px;
  border: 1px solid ${({ type }) => (type === "pf" ? colors.violetRed : colors.black)};
  border-radius: 5px;
`;
