exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__spin__2l7V6{position:relative}.style__spin_wrapper__aHlEp{position:absolute;width:100%;height:100%;background:rgba(255,255,255,.7);z-index:100;top:0;left:0;display:flex;align-items:center;justify-content:center;opacity:0;transition:all ease-in-out .3s;pointer-events:none}.style__spin_wrapper__aHlEp.style__spinning__VsMqj{opacity:1;pointer-events:all}.style__spin_icon__yqWv_{font-size:42px;color:#ed2980}", ""]);

// Exports
exports.locals = {
	"spin": "style__spin__2l7V6",
	"spin_wrapper": "style__spin_wrapper__aHlEp",
	"spinning": "style__spinning__VsMqj",
	"spin_icon": "style__spin_icon__yqWv_"
};