/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from "react";
import { PfPj } from "api/manager-api";

import { PartnerIdDialog } from "../PartnerIdDialog";
import { useStores } from "../../../../stores/RootStore";

interface IProps {
  isOpen: boolean;
  companyId: string;
  accountType: boolean | string;
  onClose: () => void;
  showToast: (msg: string) => void;
  reloadPage: () => void;
}

export function PartnerID({ isOpen, companyId, accountType, onClose, showToast, reloadPage }: IProps) {
  const [errorGetPartner, setErrorGetPartner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partnerSelectedValue, setPartnerSelectedValue] = useState("");
  const [pfPjAccount, setPfPjAccount] = useState<PfPj>(PfPj.pj);
  const { partnerStore } = useStores();

  useEffect(() => {
    if (accountType === true) {
      setPfPjAccount(PfPj.mei);
    } else if (accountType === false) {
      setPfPjAccount(PfPj.pj);
    } else {
      setPfPjAccount(PfPj.pf);
    }
  }, []);

  const fetchPartners = async () => {
    try {
      await partnerStore.getPartners();
      setErrorGetPartner(false);
    } catch (err) {
      showToast(err.message || "Ocorreu um erro ao obter a lista de parceiros.");
      setErrorGetPartner(true);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (companyId && pfPjAccount && partnerSelectedValue) {
        await partnerStore.changeUserPartnerId(companyId, pfPjAccount, partnerSelectedValue);
      } else {
        console.log(`${companyId} - ${accountType} - ${partnerSelectedValue}`);
        showToast("Ocorreu um erro ao salvar a mensagem.");
        return;
      }

      onClose();

      showToast("Parceiro atualizado com sucesso.");

      setTimeout(() => {
        reloadPage();
      }, 1000);
    } catch (err) {
      showToast(err.message || "Ocorreu um erro ao salvar a mensagem.");
    } finally {
      setLoading(false);
    }
  };

  const partnerSelected = (message: string) => {
    setPartnerSelectedValue(message);
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  if (errorGetPartner || !isOpen) {
    return null;
  }

  return (
    <PartnerIdDialog
      partner={partnerStore.partners}
      loading={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      partnerSelected={partnerSelected}
    />
  );
}
