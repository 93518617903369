import * as api from "api/manager-api";
import { action } from "mobx";

import { session } from "../utils";
import { RootStore } from "./RootStore";

export class AccountsStore {
  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public filterUsersAccounts = async (query: string) => api.filterUsersAccounts(query);
}
