import * as React from "react";
import { observer } from "mobx-react";

import { EmployeeManagerStore } from "../../../stores";
import { EmployeeWizard } from "../EmployeeWizard";
import { Dialog } from "../../../components/Dialog";
import { connect, ConnectedComponent } from "../../../utils";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

interface IStores {
  employeeManagerStore: EmployeeManagerStore;
}

@connect("employeeManagerStore")
@observer
export class EmployeesEditAction extends ConnectedComponent<IProps, IStores> {
  public render() {
    const { currentAdminUser } = this.stores.employeeManagerStore;

    if (!this.props.isOpen) {
      return null;
    }

    return (
      <Dialog
        title={`${currentAdminUser ? "Editar" : "Adicionar"} funcionário`}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <EmployeeWizard adminUser={currentAdminUser} getAdminUsers={this.props.refetch} close={this.props.onClose} />
      </Dialog>
    );
  }
}
