import React from "react";
import classnames from "classnames";
import RcDropdown from "rc-dropdown";

import * as s from "./style.scss";
import "rc-dropdown/assets/index.css";

interface IProps {
  overlayClassName?: string;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  placement?: "bottomLeft" | "bottomCenter" | "bottomRight" | "topLeft" | "topCenter" | "topRight";
  trigger?: "hover" | "click" | "focus";
  animation?: string;
  visible?: boolean;
  overlay: (() => React.ReactElement) | React.ReactElement;
  children: React.ReactElement;
  onOverlayClick?: (e: Event) => void;
  onVisibleChange?: (visible: boolean) => void;
}

export function Dropdown({ overlayClassName, ...props }: IProps) {
  return <RcDropdown overlayClassName={classnames([s.dropdown], overlayClassName)} {...props} />;
}

Dropdown.defaultProps = {
  animation: "slide-up",
  trigger: "click",
};
