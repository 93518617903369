import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { AdminUserPermission, ClientLimit } from "api/manager-api";
import { Loader } from "legacy/components/Loader";

import { GeneralStore, IPartnerId, PartnerStore, TransactionalBoundariesStore } from "../../../../stores";
import { Dialog } from "../../../../components/Dialog";
import { Table } from "../../../../components/Table";
import {
  AlertModal,
  Container,
  ErrorText,
  FilterWrapper,
  Label,
  Left,
  Link,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextArea,
  StyledTextInput,
  Text,
  Title,
} from "./style";
import { SvgArrowRepeat } from "../../../../../../legacy/icons/SvgArrowRepeat";
import { SvgEdit } from "../../../../../../legacy/icons/SvgEdit";
import { SvgDelete } from "../../../../../../legacy/icons/SvgDelete";
import { SvgConfirm } from "../../../../../../legacy/icons/SvgConfirm";
import { SvgError } from "../../../../../../legacy/icons/SvgError";
import { WrapperTable } from "../../GeneralParameters/SegmentBoundaries/style";
import { ClientCustomizationWizard } from "./ClientCustomizationWizard";
import { searchMask } from "../../../../utils";

interface IProps {
  generalStore?: GeneralStore;
  partnerStore?: PartnerStore;
  transactionalBoundariesStore?: TransactionalBoundariesStore;
}

export interface ISearch {
  name: string;
  registry: string;
}

interface IPagination {
  page: number;
  offset: number;
  limit: number;
}

@inject("transactionalBoundariesStore", "generalStore", "partnerStore")
@observer
export class ClientCustomization extends React.Component<IProps> {
  @observable private customizationWizard = false;

  @observable private loading = false;

  @observable private clientLimit: ClientLimit | null = null;

  @observable private searchValue = "";

  @observable private deleteDialogOpen = false;

  @observable private observation = "";

  @observable private successOpen = false;

  @observable private editSuccessOpen = false;

  @observable private errorOpen = false;

  @observable private error = "";

  @observable private filter = false;

  @observable private pagination: IPagination = {
    limit: 5,
    offset: 0,
    page: 1,
  };

  @observable private partners: IPartnerId[] = [];

  @observable private selectedPartner = "";

  public async componentDidMount() {
    this.getPartners();
    this.getPage();
  }

  public getPage = async () => {
    this.loading = true;

    try {
      await this.props.transactionalBoundariesStore!.getClientLimit(this.selectedPartner, "", this.pagination);
    } catch (err) {
      this.error = err.message || "Não foi possível realizar a operação";
      this.errorOpen = true;
    }

    this.loading = false;
  };

  @action
  private getPartners = async () => {
    try {
      this.loading = true;
      await this.props.partnerStore!.getPartners();
      this.partners = this.props.partnerStore!.partners!.map(partner => ({ label: partner.name, value: partner.id }));
    } catch (err) {
      this.errorOpen = true;
    } finally {
      this.loading = false;
    }
  };

  private handleClear = () => {
    this.searchValue = "";
  };

  private handleSearch = async () => {
    this.loading = true;

    if (this.searchValue === "") {
      this.filter = false;

      try {
        await this.props.transactionalBoundariesStore!.getClientLimit(this.selectedPartner, "", this.pagination);
      } catch (err) {
        this.error = err.message || "Não foi possível realizar a operação";
        this.errorOpen = true;
      }

      this.loading = false;
    } else {
      this.filter = true;

      try {
        await this.props.transactionalBoundariesStore!.getClientLimitByCpfCnpj(this.searchValue, true);
      } catch (err) {
        this.error = err.message || "Não foi possível realizar a operação";
        this.errorOpen = true;
      }

      this.loading = false;
    }
  };

  @action
  public handleEdit = (client: ClientLimit) => {
    this.clientLimit = client;
    this.handleCustomizationWizard(false);
  };

  @action
  private handleDelete = async (cpfCnpj: string, observation: string) => {
    try {
      await this.props.transactionalBoundariesStore!.deleteClientLimit(cpfCnpj, observation);
    } catch (err) {
      this.error = err.message || "Não foi possível realizar a operação";
      this.errorOpen = true;
    } finally {
      this.getPage();
      this.errorOpen = false;
      this.deleteDialogOpen = false;
      this.successOpen = true;
    }
  };

  private handleCustomizationWizard = (ref: boolean) => {
    this.customizationWizard = !this.customizationWizard;
    if (ref) {
      this.editSuccessOpen = true;
      this.handleSearch();
    }
  };

  public renderCreateOrEditCustomization = () => {
    return (
      <ClientCustomizationWizard
        createClientCustomization={this.handleCustomizationWizard}
        clientLimitEdit={this.clientLimit}
      />
    );
  };

  @action
  private handleChangePage = (page: number) => {
    this.pagination.offset = this.pagination.limit * page;
    this.pagination.page = page;
    this.getPage();
  };

  private renderData = () => {
    const { hasPermission } = this.props.generalStore!;

    const clientLimit = this.filter
      ? this.props.transactionalBoundariesStore!.clientLimit!
      : this.props.transactionalBoundariesStore!.clientLimits!.clientLimit!;
    const columns = ["Nome do Cliente", "CPF/CNPJ", "Conta", "Ações"];

    if (this.loading) {
      return <Loader />;
    }

    return (
      <WrapperTable>
        {clientLimit.length > 0 ? (
          <Table
            titles={columns}
            ids={clientLimit.map(e => e.cpfCnpj)}
            isPagination
            totalRows={this.props.transactionalBoundariesStore!.clientLimits.count}
            rowsPerPage={this.pagination.limit}
            rowsPerPageOptions={[5, 10, 15, 20]}
            page={this.pagination.offset / this.pagination.limit}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={e => {
              this.pagination.limit = Number(e);
              this.handleChangePage(0);
            }}
            data={clientLimit.map(limit => {
              const data = [
                limit.name,
                searchMask(limit.cpfCnpj),
                searchMask(limit.account ? limit.account : "", true),
                <Container key={limit.cpfCnpj}>
                  <Link
                    onClick={() => this.handleEdit(limit)}
                    type="button"
                    disabled={!hasPermission(AdminUserPermission.editCustomLimits)}
                  >
                    <Container>
                      <SvgEdit />
                      Editar
                    </Container>
                  </Link>
                  <Link
                    onClick={() => {
                      this.clientLimit = limit;
                      this.deleteDialogOpen = true;
                    }}
                    type="button"
                    disabled={!hasPermission(AdminUserPermission.editCustomLimits)}
                  >
                    <Container>
                      <SvgDelete />
                      Excluir
                    </Container>
                  </Link>
                </Container>,
              ];

              return data;
            })}
          />
        ) : (
          <Label>Não existe personalização criada</Label>
        )}
        <StyledRaisedButton
          onClick={() => {
            this.handleCustomizationWizard(false);
            this.clientLimit = null;
          }}
          label={"Criar Personalização"}
          type="button"
          style={{ marginTop: "20px" }}
          disabled={!hasPermission(AdminUserPermission.addCustomLimits)}
        />
      </WrapperTable>
    );
  };

  public render() {
    return (
      <>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.successOpen}
          onClose={() => (this.successOpen = false)}
        >
          <AlertModal style={{ textAlign: "center" }}>
            <SvgConfirm
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            Personalização excluída com sucesso!
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.editSuccessOpen}
          onClose={() => (this.editSuccessOpen = false)}
        >
          <AlertModal style={{ textAlign: "center" }}>
            <SvgConfirm
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            {`Personalização ${this.clientLimit ? "editada" : "criada"} com sucesso!`}
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"sm"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-50px 0 12px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>{this.error}</ErrorText>
          </AlertModal>
        </Dialog>
        {this.clientLimit && (
          <Dialog
            showCloseButton
            closeButtonSize={"30px"}
            maxWidth={"md"}
            title={"Deseja excluir essa personalização?"}
            isOpen={this.deleteDialogOpen}
            onClose={() => (this.deleteDialogOpen = false)}
          >
            <Text>Ao excluir a personalização, o cliente será vinculado ao Limite Padrão.</Text>
            <StyledTextArea
              placeholder="Obs:"
              value={this.observation}
              onChange={e => (this.observation = e.target.value)}
              maxLength={200}
            />
            <StyledRaisedButton
              onClick={async () => this.handleDelete(this.clientLimit!.cpfCnpj!, this.observation)}
              label="Excluir"
              type="submit"
            />
          </Dialog>
        )}
        {this.customizationWizard ? (
          this.renderCreateOrEditCustomization()
        ) : (
          <>
            <FilterWrapper>
              <Title>Filtros de pesquisa</Title>
              <Left>
                <StyledTextInput
                  value={searchMask(this.searchValue)}
                  onChange={e => (this.searchValue = e.target.value)}
                  placeholder="Pesquisa por CPF ou CNPJ"
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      this.handleSearch();
                    }
                  }}
                />
                <StyledOutlinedButton
                  label="Limpar Filtros"
                  onClick={this.handleClear}
                  iconComponent={SvgArrowRepeat}
                />
                <StyledRaisedButton onClick={this.handleSearch} label="Buscar" type="submit" />
              </Left>
            </FilterWrapper>
            <Title>Personalizações criadas</Title>
            <WrapperTable>{this.renderData()}</WrapperTable>
          </>
        )}
      </>
    );
  }
}
