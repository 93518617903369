import * as React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  rectColor?: string;
  pointColor?: string;
}

export const SvgSuspensionPoints = (props: IProps) => {
  const { size, viewBox, width, height, rectColor, pointColor, ...restProps } = props;
  return (
    <svg viewBox={viewBox || "0 0 21 21"} width={size || "21"} height={size || "21"} {...restProps}>
      <g fill="none" fillRule="nonzero">
        <rect width={size || "21"} height={size || "21"} fill={rectColor || "#F2F3F5"} rx="3" />
        <g fill={pointColor || "#515251"} transform="translate(4 9)">
          <circle cx="1.5" cy="1.5" r="1.5" />
          <circle cx="6.5" cy="1.5" r="1.5" />
          <circle cx="11.5" cy="1.5" r="1.5" />
        </g>
      </g>
    </svg>
  );
};
