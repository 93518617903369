import React, { useEffect } from "react";
import { useObserver } from "mobx-react";
import { AdminUserPermission, ProofOfLifeInfo } from "api/manager-api";
import { Loader } from "legacy/components/Loader";

import { Feedback } from "shared/components/Feedback";

import { searchMask } from "../../utils";
import { PageTitle } from "../../components/PageTitle";
import { SearchBar } from "../../components/SearchBar";
import { ProofOfLifeTable } from "./Table";
import { ModalProof } from "./Modal";
import { useStores } from "../../stores/RootStore";
import { SelectStatus, statusHandler } from "./SelectStatus";

import s from "./styles.scss";

export function ProofOfLife() {
  const [search, setSearch] = React.useState("");
  const [selectedProof, setSelectedProof] = React.useState<ProofOfLifeInfo | undefined>();
  const [feedback, setFeedback] = React.useState<"approved" | "disapproved">();
  const [errorMessage, setErrorMessage] = React.useState("");
  const { openingAccountsStore, generalStore } = useStores();
  const [permissions] = React.useState({
    approve: generalStore.hasPermission(AdminUserPermission.approveProofOfLife),
    reprove: generalStore.hasPermission(AdminUserPermission.reproveProofOfLife),
  });
  const [status, setStatus] = statusHandler();

  useEffect(() => {
    openingAccountsStore.getProofsOfLife();
  }, []);

  const updateSelectedProofWhenStoreUpdates = () => {
    if (selectedProof) {
      setSelectedProof(openingAccountsStore.proofsOfLife?.find(proof => proof.id === selectedProof.id));
    }
  };

  useEffect(() => {
    updateSelectedProofWhenStoreUpdates();
  }, [useObserver(() => openingAccountsStore.proofsOfLife)]);

  const approveProof = async (proof: ProofOfLifeInfo, approved: boolean) => {
    try {
      if (approved) {
        await openingAccountsStore.approveProofOfLife(proof.id);
        setFeedback("approved");
      } else {
        await openingAccountsStore.disapproveProofOfLife(proof.id);
        setFeedback("disapproved");
      }

      setSelectedProof(proof);
      openingAccountsStore.getProofsOfLife();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const cleanModal = () => {
    setSelectedProof(undefined);
    setFeedback(undefined);
    setErrorMessage("");
  };

  function onChange(e: string) {
    setSearch(searchMask(e, false));
  }

  return useObserver(() => (
    <>
      <PageTitle>Processamento de Prova de Vida</PageTitle>

      <div className={s.search}>
        <header className={s.header}>
          <SearchBar value={search} onChange={onChange} placeholder="Pesquise por Nome ou CPF" enabledWithoutInput />
          <SelectStatus status={status} setStatus={setStatus} />
        </header>

        {openingAccountsStore.proofsOfLife ? (
          <ProofOfLifeTable
            search={search}
            permissions={permissions}
            approveProof={approveProof}
            errorMessage={errorMessage}
            setSelectedProof={setSelectedProof}
            selectedStatus={status}
            proofs={openingAccountsStore.proofsOfLife}
          />
        ) : (
          <Loader />
        )}

        <ModalProof
          permissions={permissions}
          errorMessage={errorMessage}
          approveProof={approveProof}
          showModal={Boolean(selectedProof)}
          feedback={feedback}
          onClose={cleanModal}
          proof={selectedProof}
        />

        <Feedback
          type="error"
          open={Boolean(errorMessage)}
          message={errorMessage}
          handleClose={() => setErrorMessage("")}
        />
      </div>
    </>
  ));
}
