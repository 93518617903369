/* eslint-disable react/display-name */
import React from "react";
import { toJS } from "mobx";
import { AdminUserPermission, Partner, PartnerUser } from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { partnerManagementPath } from "@manager/utils";

import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Table } from "components/Table";
import { DropdownActions } from "components/DropdownActions";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
import { ReactComponent as OpenedEye } from "assets/svgs/opened-eye.svg";
import { ReactComponent as ClosedEye } from "assets/svgs/closed-eye.svg";

// Style
import { InputLabel } from "@components/";

import s from "./style.scss";

export function PartnerList() {
  const { partnerStore, routerStore, generalStore } = useStores();

  const [partners, setPartners] = React.useState<Partner[]>([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  // Generate Auth
  const [partnerUser, setPartnerUser] = React.useState<PartnerUser | null>(null);
  const [loadingAuth, setLoadingAuth] = React.useState(false);
  const [visibleModalAuth, setVisibleModalAuth] = React.useState(false);

  // Display partner details
  const [partnerDetails, setPartnerDetails] = React.useState<Partner | null>(null);
  const [visibleModalDetails, setVisibleModalDetails] = React.useState(false);

  // Display push config
  const [firebaseKey, setFirebaseKey] = React.useState("");
  const [keyVisibility, setKeyVisibility] = React.useState(false);
  const [enableFields, setEnableFields] = React.useState(true);
  const [visibleModalPush, setVisibleModalPush] = React.useState(false);
  const [loadingPush, setLoadingPush] = React.useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = React.useState("");

  // Display partner details

  function onSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const dataFilter = toJS(partnerStore.partners)?.filter(partner => {
      return partner.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    });

    setPartners(dataFilter || []);
  }

  function onAddPartner() {
    routerStore.push(`${partnerManagementPath}/adicionar-parceiro`);
  }

  async function getPartners() {
    try {
      setLoading(true);
      await partnerStore.getPartners();

      setPartners(toJS(partnerStore.partners) || []);
      setSearchValue("");
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message ?? "Não possível conectar ao servidor.");
    } finally {
      setLoading(false);
    }
  }

  async function getPushConfig() {
    try {
      setLoading(true);
      const result = await partnerStore.getPushConfig(selectedPartnerId);

      if (result) {
        setFirebaseKey(result);
        setEnableFields(false);
      }

      setVisibleModalPush(true);
    } catch (error) {
      generalStore.showToast(error.message ?? "Não possível conectar ao servidor.");
    } finally {
      setLoading(false);
    }
  }

  function onPartnerClick(id: string) {
    routerStore.push(`${partnerManagementPath}/${id}`);
  }

  async function onGenerateAuth(partnerId: string) {
    setLoadingAuth(true);
    setVisibleModalAuth(true);

    try {
      setPartnerUser(await partnerStore.generateCredentials(partnerId));
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao gerar as credenciais.");
      setVisibleModalAuth(false);
    }

    setLoadingAuth(false);
  }

  function onPartnerDetails(partner: Partner) {
    setPartnerDetails(partner);
    setVisibleModalDetails(true);
  }

  function onPushConfig(partnerId: string) {
    setSelectedPartnerId(partnerId);
  }

  async function handlePushConfigSubmit() {
    try {
      setLoadingPush(true);
      await partnerStore.setPushConfig(selectedPartnerId, firebaseKey || null);

      handleClosePushModal();
    } catch (error) {
      generalStore.showToast(error.message || "Ocorreu um erro ao gerar as credenciais.");
    } finally {
      setLoadingPush(false);
    }
  }

  function handleClosePushModal() {
    setVisibleModalPush(false);
    setKeyVisibility(false);
    setEnableFields(true);
    setSelectedPartnerId("");
  }

  function showSecretButton() {
    if (!enableFields) {
      return null;
    }

    if (keyVisibility) {
      return <ClosedEye onClick={() => setKeyVisibility(false)} />;
    }

    return <OpenedEye onClick={() => setKeyVisibility(true)} />;
  }

  React.useEffect(() => {
    if (selectedPartnerId) {
      getPushConfig();
    }
  }, [selectedPartnerId]);

  React.useEffect(() => {
    getPartners();
  }, []);

  return (
    <>
      <div className={s.title}>Gestão de Parceiros</div>

      <div className={s.header}>
        <form className={s.searchContainer} onSubmit={onSearch}>
          <Input
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            className={s.searchInput}
            placeholder="Pesquise pelo nome do parceiro"
          />

          <Button theme="primary" removeSvgStyle className={s.searchButton} type="submit">
            <SearchIcon />
          </Button>
        </form>

        {generalStore.hasPermission(AdminUserPermission.addPartner) && (
          <Button theme="primary" onClick={onAddPartner}>
            Adicionar parceiro
          </Button>
        )}
      </div>

      <Table
        columns={[
          { align: "left", dataIndex: "name", title: "Nome" },
          { align: "center", dataIndex: "status", title: "Status" },
          { align: "center", dataIndex: "username", title: "Username" },
          {
            align: "center",
            dataIndex: "accountOpeningAttemptLimit",
            title: "Limite C. por dia",
          },
          {
            align: "center",
            dataIndex: "options",
            render: partner => (
              <DropdownActions
                options={[
                  { label: "Gerar credenciais", onClick: async () => onGenerateAuth(partner.id) },
                  { label: "Detalhes", onClick: async () => onPartnerDetails(partner) },
                  { label: "Configurar Push", onClick: async () => onPushConfig(partner.id) },
                ]}
              />
            ),
            title: "Opções",
            width: "80px",
          },
        ]}
        data={partners.map(partner => ({
          accountOpeningAttemptLimit: partner.accountOpeningAttemptLimit,
          key: partner.id,
          name: partner.name,
          options: partner,
          status: partner.blocked ? "Inativo" : "Ativo",
          username: partner.partnerUserName,
        }))}
        empty={{
          hideIcon: false,
          message: errorMessage || "Nenhum parceiro encontrado.",
          onRetry: getPartners,
        }}
        onRow={record => ({
          onClick: () => onPartnerClick(record.key),
        })}
        loading={loading}
        disablePagination
        rowClickableStyle
      />

      <Modal
        visible={visibleModalDetails}
        title="Detalhes"
        width={700}
        onClose={() => {
          setVisibleModalDetails(false);
          setPartnerDetails(null);
        }}
      >
        <div>
          <div className={s.cardLabelModalAuth}>
            <span className={s.labelModalAuth}>Username:</span>
            <span className={s.valueModalAuth}>{partnerDetails?.partnerUserName || "-"}</span>
          </div>

          <div className={s.cardLabelModalAuth}>
            <span className={s.labelModalAuth}>PartnerId:</span>
            <span className={s.valueModalAuth}>{partnerDetails?.id || "-"}</span>
          </div>

          <div className={s.cardLabelModalAuth}>
            <span className={s.labelModalAuth}>PartnerSecret:</span>
            <span className={s.valueModalAuth}>{partnerDetails?.partnerSecret || "-"}</span>
          </div>
        </div>
      </Modal>

      <Modal
        visible={visibleModalAuth}
        title="Credenciais"
        width={380}
        loading={loadingAuth}
        onClose={() => {
          setVisibleModalAuth(false);
          setPartnerUser(null);
        }}
      >
        <>
          <div className={s.cardLabelModalAuth}>
            <span className={s.labelModalAuth}>Login:</span>
            <span className={s.valueModalAuth}>{partnerUser?.username || "-"}</span>
          </div>

          <div className={s.cardLabelModalAuth}>
            <span className={s.labelModalAuth}>Senha:</span>
            <span className={s.valueModalAuth}>{partnerUser?.password || "-"}</span>
          </div>
        </>
      </Modal>

      <Modal
        visible={visibleModalPush}
        title="Configurações de Push"
        width={380}
        loading={loadingPush}
        onClose={handleClosePushModal}
      >
        <div className={s.inputWithSecret}>
          <InputLabel label="Chave do Firebase">
            <Input
              type={keyVisibility ? "text" : "password"}
              value={firebaseKey}
              onChange={event => setFirebaseKey(event.target.value)}
              disabled={!enableFields}
            />
          </InputLabel>
          {showSecretButton()}
        </div>

        <div className={s.footer}>
          {enableFields || loadingPush ? (
            <>
              <Button theme="secondary" type="button" onClick={handleClosePushModal} disabled={loadingPush}>
                Cancelar
              </Button>

              <Button type="button" onClick={handlePushConfigSubmit} disabled={loadingPush}>
                Salvar
              </Button>
            </>
          ) : (
            <Button theme="secondary" type="button" onClick={() => setEnableFields(true)}>
              Editar
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
}
