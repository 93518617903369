exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__imagesProof__1-eyn{display:flex}.styles__proofFigure__18UkI{margin:2px;border:2px solid #dcdce0;border-radius:8px;width:328px;text-align:center;background:#dcdce0;height:285px;display:flex;align-items:center;justify-content:center;flex-wrap:wrap}.styles__proofFigure__18UkI:last-child{border:2px solid #ed2980}.styles__proofFigure__18UkI img{height:240px;max-width:100%;object-fit:cover;object-position:center;border-radius:6px 6px 0 0}.styles__proofFigure__18UkI figcaption{font-style:normal;font-weight:normal;font-size:14px;line-height:17px;text-align:center;color:#515251;text-align:center;padding:9px;background:#fff;border-radius:0 0 6px 6px;width:100%;margin-top:auto}.styles__documents__1w1tU{margin:10px 0 16px;display:flex}.styles__documents__1w1tU figure{margin-right:22px}.styles__rowInfo__2UgrD{display:flex;justify-content:flex-start}.styles__userinfo__22IR7{width:42%;margin-right:24px;margin-bottom:16px}.styles__title__n7My5{font-size:14px;line-height:17px;color:#a6a6a6;margin:0 0 8px 0}.styles__info__2nBYe{font-size:14px;line-height:17px;color:#525051;margin:0}.styles__feedback__4Xgtl{text-align:center}.styles__feedback__4Xgtl svg{margin:24px 0}.styles__feedback__4Xgtl h3{font-weight:bold;font-size:16px;line-height:19px;color:#515251;margin-bottom:12px}.styles__feedback__4Xgtl p{font-size:14px;line-height:17px;color:#828282;margin-bottom:32px}", ""]);

// Exports
exports.locals = {
	"imagesProof": "styles__imagesProof__1-eyn",
	"proofFigure": "styles__proofFigure__18UkI",
	"documents": "styles__documents__1w1tU",
	"rowInfo": "styles__rowInfo__2UgrD",
	"userinfo": "styles__userinfo__22IR7",
	"title": "styles__title__n7My5",
	"info": "styles__info__2nBYe",
	"feedback": "styles__feedback__4Xgtl"
};