/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { isValidCnpj } from "@brazilian-utils/validators";
import { observer } from "mobx-react";
import { useStores } from "@manager/stores/RootStore";
import * as api from "api/manager-api";

import { ITabInfo, TabPane, Tabs } from "components/Tabs";
import { Button } from "components/Button";
import { Spin } from "components/Spin";

import { FormRegisterData } from "./FormRegisterData";
import { FormStyle } from "./FormStyle";
import { FormSettings } from "./FormSettings";
import { ListCardsConfigs } from "./FormCard/index";

import s from "./style.scss";

interface IProps {
  tab: ITabInfo;
  initialValues?: Partial<IFields>;
  toggleDisabledFields?: boolean;
  isSequencialTabs?: boolean;
  openAccountsAttempts?: number;
  loading?: boolean;
  partnerId?: string;
  onSave: (values: IFields, setValues: (values: IFields) => void, updateCard?: boolean) => Promise<boolean>;
  setTab: (value: ITabInfo) => void;
  onCancel?: () => void;
  onChangeTab?: (values: IFields, setValues: (values: IFields) => void) => void;
}

export type CustomCardType = "nenhum" | "mastercard" | "visa";

export interface IFields {
  // RegisterData step
  name: string;
  cnpj: string;
  managerId: string;
  ibUrl: string;
  appIosUrl: string;
  appAndroidUrl: string;
  appTokenIosUrl: string;
  appTokenAndroidUrl: string;
  chatUrl: string;
  chatUrlMobile: string;
  publicKey: string;
  senderEmail: string;
  farePackagesURL: string | null;
  farePackagesBuffer: Buffer | null;
  pfTermsOfUserUrl: string | null;
  pfTermsOfUserBuffer: Buffer | null;
  pfContractUrl: string | null;
  pfContractBuffer: Buffer | null;
  pjTermsOfUserUrl: string | null;
  pjTermsOfUserBuffer: Buffer | null;
  pjContractUrl: string | null;
  pjContractBuffer: Buffer | null;
  allowPartnerLot: boolean;
  allowReduceJourneyWithoutLot: boolean;

  // Style step
  colorPrimary: string;
  colorSecondary: string;
  colorBg: string;
  colorError: string;
  logoUrl: string;
  logoBuffer: Buffer | null;

  // Card
  cardBrandDisabled: boolean;
  broadcaster: api.BroadCaster | null;
  cardBrand: api.CardBrand | "nenhum";
  visaIdProduct: string;
  visaIdPlastic: string;
  visaIdComercial: string;
  visaIdImage: string;
  visaImageDescription: string;
  typeBin: string;
  numberBin: string;
  hasName: boolean;
  hasNoName: boolean;
  visaIsActiveVisa: boolean;
  hasVirtualCard: boolean;
  hasFisicCard: boolean;
  multicards: api.MultiCard | null;
  cobranderCode: string;
  cobranderCodeCardCategory: string;
  cobranderAutoEmitCard: boolean;
  cobranderIsActivate: boolean;
  cobranderUsername: string;
  cobranderPassword: string;

  // Settings
  allowAnyPartnerLogin: boolean;
  accountOpeningAttemptLimit: string;
  emailResendTimeFrequency: string;
  emailResendTime: string;
  featuresFlag: api.FeaturesStatus[];
  showCardModule: boolean;
  showWiz: boolean;
  managerIdLot: string | null;
  managerIdWithoutLot: string | null;
  customButton: api.CustomButton | null;
  customContact: api.CustomContact | null;
}

const initialValues: IFields = {
  // RegisterData step
  name: "",
  cnpj: "",
  managerId: "",
  managerIdLot: "",
  managerIdWithoutLot: "",
  ibUrl: "",
  appIosUrl: "",
  appAndroidUrl: "",
  appTokenIosUrl: "",
  appTokenAndroidUrl: "",
  chatUrl: "",
  chatUrlMobile: "",
  publicKey: "",
  senderEmail: "",
  farePackagesURL: null,
  farePackagesBuffer: null,
  pfTermsOfUserUrl: null,
  pfTermsOfUserBuffer: null,
  pfContractUrl: null,
  pfContractBuffer: null,
  pjTermsOfUserUrl: null,
  pjTermsOfUserBuffer: null,
  pjContractUrl: null,
  pjContractBuffer: null,

  // Style
  colorPrimary: "",
  colorSecondary: "",
  colorBg: "",
  colorError: "",
  logoUrl: "",
  logoBuffer: null,

  // Card
  cardBrandDisabled: false,
  broadcaster: null,
  cardBrand: "nenhum",
  visaIdProduct: "",
  visaIdPlastic: "",
  visaIdComercial: "",
  visaIdImage: "",
  visaImageDescription: "",
  visaIsActiveVisa: false,
  hasName: false,
  hasNoName: false,
  typeBin: "",
  numberBin: "",
  hasFisicCard: false,
  hasVirtualCard: false,
  multicards: null,
  cobranderCode: "",
  cobranderCodeCardCategory: "",
  cobranderAutoEmitCard: false,
  cobranderIsActivate: false,
  cobranderUsername: "",
  cobranderPassword: "",

  // Settings
  allowAnyPartnerLogin: false,
  accountOpeningAttemptLimit: "",
  emailResendTimeFrequency: "2",
  emailResendTime: "30",
  featuresFlag: [],
  showCardModule: false,
  allowPartnerLot: false,
  allowReduceJourneyWithoutLot: false,
  showWiz: false,
  customButton: {
    buffer: null,
    image: "",
    url: "",
    text: "",
    show: false,
  },
  customContact: {
    email: "",
    phone: "",
    show: false,
  },
};

export const PartnerForm = observer((props: IProps) => {
  const { partnerStore } = useStores();
  const [flagAllowPartnerLot, setFlagAllowPartnerLot] = React.useState<boolean>(false);
  const [enableFields, setEnableFields] = React.useState(!props.toggleDisabledFields);
  const [isUpdateCard, setIsUpdateCard] = React.useState(false);

  function isLastTab() {
    if (!props.tab.allTabKeys || props.tab.index === undefined) {
      return true;
    }

    return props.tab.allTabKeys.length - 1 === props.tab.index;
  }

  React.useEffect(() => {
    if (partnerStore?.partner?.managerIdLot) {
      setFlagAllowPartnerLot(Boolean(partnerStore.partner.managerIdLot));
    }
  }, [partnerStore]);

  React.useEffect(() => {
    console.log();
  }, [flagAllowPartnerLot]);

  const changeTabOnError = (key: string, index: number) => {
    props.setTab({
      allTabKeys: ["registerData", "style", "cards", "settings"],
      index,
      tabKey: key,
    });
  };

  const formik = useFormik<IFields>({
    initialValues: { ...initialValues, ...props.initialValues },
    onSubmit: async values => {
      setEnableFields(false);

      const result = await props.onSave(values, formik.setValues, isUpdateCard);

      setEnableFields(!result);
    },
    validate: values => {
      const errors: { [key: string]: string } = {};
      const emailResendTimeFrequency = parseInt(values.emailResendTimeFrequency, 10);
      const emailResendTime = parseInt(values.emailResendTime, 10);
      const cobranderFields = [
        values.cobranderCode,
        values.cobranderCodeCardCategory,
        values.cobranderUsername,
        values.cobranderPassword,
      ];
      const allowOnlyNumbersRegex = /^[0-9]+$/u;

      if (props.tab.tabKey === "registerData" && !values.name.trim()) {
        errors.name = "Informe um nome para o parceiro.";
        changeTabOnError("registerData", 0);
      }

      if (props.tab.tabKey === "registerData" && values.cnpj && !isValidCnpj(values.cnpj)) {
        errors.cnpj = "Informe um CNPJ válido.";
        changeTabOnError("registerData", 0);
      }

      if (props.tab.tabKey === "registerData" && values.allowPartnerLot && !values.cnpj) {
        errors.cnpj = "Informe um CNPJ.";
        changeTabOnError("registerData", 0);
      }

      if (props.tab.tabKey === "registerData" && !values.managerId.trim()) {
        errors.managerId = "Informe o ID do gerente.";
        changeTabOnError("registerData", 0);
      }

      if (
        props.tab.tabKey === "registerData" &&
        !allowOnlyNumbersRegex.test(values.managerId) &&
        values.managerId !== ""
      ) {
        errors.managerId = "Caracteres não permitidos.";
        changeTabOnError("registerData", 0);
      }

      if (props.tab.tabKey === "registerData" && !values.ibUrl.trim()) {
        errors.ibUrl = "Informe a URL do Internet Banking.";
        changeTabOnError("registerData", 0);
      }

      if (props.tab.tabKey === "registerData" && !values.publicKey.trim()) {
        errors.publicKey = "Informe uma chave pública.";
        changeTabOnError("registerData", 0);
      }

      if (props.tab.tabKey === "settings" && (!emailResendTimeFrequency || emailResendTimeFrequency > 30)) {
        errors.emailResendTimeFrequency = "Valor inválido (mínimo de 1, máximo de 30).";
        changeTabOnError("settings", 3);
      }

      if (props.tab.tabKey === "settings" && (!emailResendTime || emailResendTime > 100)) {
        errors.emailResendTime = "Valor inválido (mínimo de 1, máximo de 100)";
        changeTabOnError("settings", 3);
      }

      // Validação Jornada Reduzida com pré cadastro
      if (props.tab.tabKey === "settings" && (flagAllowPartnerLot || values.allowPartnerLot) && !values.managerIdLot) {
        errors.managerIdLot = "Informe o ID de gerente - jornada reduzida com pré-cadastro";
        changeTabOnError("settings", 3);
      }
      // Validação Jornada Reduzida sem pré cadastro

      if (props.tab.tabKey === "settings" && values.allowReduceJourneyWithoutLot && !values.managerIdWithoutLot) {
        errors.managerIdWithoutLot = "Informe o ID de gerente - jornada reduzida sem pré-cadastro";
        changeTabOnError("settings", 3);
      }

      // Regra removida conforme instrução da Solange e Willian.
      /*
       * If (
       *   props.tab.tabKey === "settings" &&
       *   values.managerIdLot &&
       *   values.managerId.trim() === values.managerIdLot.trim()
       * ) {
       *   errors.managerIdLot = "O ID gerente Lote deve ser diferente do ID gerente.";
       *   changeTabOnError("settings", 0);
       * }
       */

      /*
       * If (
       *   props.tab.tabKey === "settings" &&
       *   values.managerIdWithoutLot &&
       *   values.managerId.trim() === values.managerIdWithoutLot.trim()
       * ) {
       *   errors.managerIdLot = "O ID gerente Jornada Reduzida sem Lote deve ser diferente do ID gerente.";
       *   changeTabOnError("settings", 0);
       * }
       */

      /*
       * If (
       *   props.tab.tabKey === "settings" &&
       *   values.managerIdLot &&
       *   values.managerIdWithoutLot &&
       *   values.managerIdLot.trim() === values.managerIdWithoutLot.trim()
       * ) {
       *   errors.managerIdLot = "O ID gerente Lote deve ser diferente do ID gerente sem Lote.";
       *   changeTabOnError("settings", 0);
       * }
       */

      if (
        props.tab.tabKey === "settings" &&
        values.customButton?.show &&
        (!values.customButton.text || !values.customButton.url || !values.customButton.image)
      ) {
        errors.customButton = "Todos os campos devem estar preenchidos";
        changeTabOnError("settings", 3);
      }

      if (props.tab.tabKey === "cards" && values.visaIdProduct.trim() && !values.visaIdPlastic.trim()) {
        errors.visaIdPlastic = "O preenchimento do campo obrigatório.";
        changeTabOnError("cards", 2);
      }

      if (props.tab.tabKey === "cards" && values.visaIdProduct && !values.visaIdComercial) {
        errors.visaIdComercial = "O preenchimento do campo obrigatório.";
        changeTabOnError("cards", 2);
      }

      if (props.tab.tabKey === "cards" && values.visaIdProduct && !values.visaIdImage.trim()) {
        errors.visaIdImage = "O preenchimento do campo obrigatório";
        changeTabOnError("cards", 2);
      }

      if (
        props.tab.tabKey === "cards" &&
        values.cardBrand === "visa" &&
        values.visaIsActiveVisa &&
        values.hasVirtualCard === false &&
        values.hasFisicCard === false
      ) {
        errors.hasFisicCard =
          "Para ativar o cartão para o parceiro é necessário selecionar pelo menos um tipo de cartão.";
        errors.hasVirtualCard =
          "Para ativar o cartão para o parceiro é necessário selecionar pelo menos um tipo de cartão.";
      }

      if (props.tab.tabKey === "cards" && values.cardBrand !== "nenhum" && cobranderFields.some(x => Boolean(x))) {
        if (!values.cobranderCode.trim()) {
          errors.cobranderCode = "O preenchimento do campo obrigatório.";
        }

        if (!values.cobranderCodeCardCategory.trim()) {
          errors.cobranderCodeCardCategory = "O preenchimento do campo obrigatório.";
        }

        if (!values.cobranderUsername.trim()) {
          errors.cobranderUsername = "O preenchimento do campo obrigatório.";
        }

        if (!values.cobranderPassword.trim()) {
          errors.cobranderPassword = "O preenchimento do campo obrigatório.";
        }
      }

      return errors;
    },
  });

  function handleSubmitButton(updateCard?: boolean) {
    setIsUpdateCard(updateCard || false);

    if (!props.isSequencialTabs || isLastTab()) {
      formik.submitForm();

      return;
    }

    const newTabIndex = props.tab.index ? props.tab.index + 1 : 1;

    props.setTab({
      allTabKeys: props.tab.allTabKeys,
      index: newTabIndex,
      tabKey: props.tab.allTabKeys ? props.tab.allTabKeys[newTabIndex] : "registerData",
    });
  }

  function handleCancel() {
    if (!props.toggleDisabledFields) {
      if (props.onCancel) {
        props.onCancel();
      }

      return;
    }

    if (!enableFields) {
      setEnableFields(true);

      return;
    }

    formik.resetForm();
    formik.setValues({ ...initialValues, ...props.initialValues });
    setEnableFields(false);
  }

  function renderFooter() {
    if (props.tab.tabKey === "cards" && !props.isSequencialTabs) {
      return null;
    }

    return (
      <>
        {enableFields || props.loading ? (
          <>
            <Button theme="secondary" type="button" onClick={handleCancel} disabled={props.loading}>
              Cancelar
            </Button>

            <Button type="button" onClick={() => handleSubmitButton()} disabled={props.loading}>
              {props.isSequencialTabs && !isLastTab() ? "Próximo" : "Salvar"}
            </Button>
          </>
        ) : (
          <Button theme="secondary" type="button" onClick={() => setEnableFields(true)}>
            Editar
          </Button>
        )}
      </>
    );
  }

  useEffect(() => {
    if (props.onChangeTab) {
      props.onChangeTab(formik.values, formik.setValues);
    }
  }, [props.tab]);

  function buildTab() {
    const tabData: JSX.Element[] = [];

    tabData.push(
      <TabPane tabKey="registerData" title="Dados cadastrais" index={0}>
        <FormRegisterData
          values={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          setFieldValue={formik.setFieldValue}
          setValues={formik.setValues}
          disabled={!enableFields}
        />
      </TabPane>,
      <TabPane tabKey="style" title="Estilo" index={1}>
        <FormStyle
          values={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          setFieldValue={formik.setFieldValue}
          setValues={formik.setValues}
          disabled={!enableFields}
        />
      </TabPane>,
    );

    if (!props.isSequencialTabs) {
      tabData.push(
        <TabPane tabKey="cards" title="Cartão" index={2}>
          <ListCardsConfigs
            values={formik.values}
            touched={formik.touched}
            errors={formik.errors}
            setFieldValue={formik.setFieldValue}
            setValues={formik.setValues}
            onSubmit={handleSubmitButton}
            partnerId={props.partnerId}
          />
        </TabPane>,
      );
    }

    tabData.push(
      <TabPane tabKey="settings" title="Configurações" index={3}>
        <FormSettings
          values={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          setFieldValue={formik.setFieldValue}
          setValues={formik.setValues}
          openAccountsAttempts={props.openAccountsAttempts}
          disabled={!enableFields}
        />
      </TabPane>,
    );

    return tabData;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Spin spinning={props.loading}>
        <Tabs value={props.tab.tabKey} onChange={props.setTab} currentIndex={props.tab.index}>
          {buildTab()}
        </Tabs>

        <div className={s.footer}>{renderFooter()}</div>
      </Spin>
    </form>
  );
});
