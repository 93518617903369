import * as React from "react";
import SelectComponent, { Props } from "react-select";

interface IProps extends Props {}

export const Select = (props: IProps) => {
  const { noOptionsMessage, loadingMessage, ...restProps } = props;

  return (
    <SelectComponent noOptionsMessage={() => "Sem opções"} loadingMessage={() => "Carregando..."} {...restProps} />
  );
};
