/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as api from "api/manager-api";
import { CircularProgress } from "@material-ui/core";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { RouterStore } from "mobx-react-router";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ValueType } from "react-select";
import { UserStatus } from "api/partner-api";
import { RaisedButton } from "legacy/components/RaisedButton";

import { Feedback } from "../Feedback";
import { BackButton } from "../../../components/BackButton";
import { ConfirmDialog } from "../../../components/ConfirmDialog";
import { InnerAlert } from "../../../components/InnerAlert";
import { Loader } from "../../../components/Loader";
import { RegisterData } from "../../../components/RegisterData";
import { Select } from "../../../components/Select";
import { TabNavigator } from "../../../components/TabNavigator";
import { GeneralStore, OpeningAccountsStore, PartnerStore } from "../../../stores";
import { colors, openingAccountsSectionPath, prettierBlockStatusAccount, revertSectionToId } from "../../../utils";
import { Dialog } from "../../../components/Dialog";
import { RevalidateDialog } from "../RevalidateDialog";
import { SvgApprovedCompliance } from "../../../assets/img/SvgApprovedCompliance";
import { SvgDisapprovedCompliance } from "../../../assets/img/SvgDisapprovedCompliance";
import { SvgWaitingCompliance } from "../../../assets/img/SvgWaitingCompliance";
import { Compliance } from "./Compliance";
import { Representatives } from "./Representatives";
import { Beneficiary } from "./Beneficiary";
import { Subscribers } from "./Subscribers";

interface IProps
  extends RouteComponentProps<{
    section: string;
    userId: string;
    documentNumber: string;
    oldPj: string;
  }> {
  generalStore: GeneralStore;
  routerStore: RouterStore;
  openingAccountsStore: OpeningAccountsStore;
  partnerStore: PartnerStore;
}

interface IActionOption {
  value:
    | "approve"
    | "approveSimple"
    | "disapprove"
    | "cancel"
    | "revalidate"
    | "revalidateAddress"
    | "approvedCompliance"
    | "refusedCompliance"
    | "lockedAccount"
    | "unlockedAccount"
    | "automaticMethod";
  label: string;
}

type Tab = "registerData" | "bureaus" | "history" | "compliance" | "compliance2";

@inject("generalStore", "routerStore", "openingAccountsStore", "partnerStore")
@observer
export class OpeningAccountProfile extends React.Component<IProps> {
  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private loadingAction = false;

  @observable private selectedTab = "registerData";

  @observable private filterAccountPath = revertSectionToId(this.props.match.params.section);

  @observable private revalidateDialogOpen = false;

  @observable private confirmDialogOpen = false;

  @observable private changeCompanyInfoSerpro = false;

  @observable private loadingChangeCompanyInfoSerpro = false;

  @observable private selectedAction: IActionOption | null = null;

  @observable private resendEmailDialogOpen = false;

  @observable private loadingResendEmail = false;

  @observable private accountType: api.PfPj | null = null;

  @observable private maximaId = "";

  @observable private showChangeSerproInfoAlert = true;

  public componentDidMount() {
    this.getData();
    this.getMaximaId();
  }

  @action
  private getData = async () => {
    const { getPendingEntity } = this.props.openingAccountsStore;
    const id = this.props.match.params.userId;
    const { documentNumber } = this.props.match.params;

    try {
      this.loading = true;
      await getPendingEntity(id, documentNumber);

      if (this.props.openingAccountsStore.pendingUser) {
        this.accountType = api.PfPj.pf;
      } else if (this.props.openingAccountsStore.pendingCompany!.isMEI) {
        this.accountType = api.PfPj.mei;
      } else {
        this.accountType = api.PfPj.pj;
      }

      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter as informações.";
      this.props.openingAccountsStore.setPendingCompanyAndUserToNull();
    } finally {
      this.loading = false;
    }
  };

  @action
  private onChangeTab = (selectedTab: Tab) => {
    this.selectedTab = selectedTab;
  };

  private handleBack = () => {
    this.props.routerStore.history.goBack();
  };

  @action
  private handleAction = (option: ValueType<IActionOption>) => {
    const optionAction = option as IActionOption;

    if (optionAction.value === "revalidate") {
      this.revalidateDialogOpen = true;
    } else {
      this.selectedAction = optionAction;
      this.confirmDialogOpen = true;
    }
  };

  @computed
  private get confirmTextIncrement() {
    if (this.selectedAction) {
      return this.selectedAction.label.toLowerCase();
    }

    return "";
  }

  @action
  private cancelAction = () => {
    this.confirmDialogOpen = false;
    this.selectedAction = null;
  };

  @action
  private confirmAction = async () => {
    const { pendingUser: pu, pendingCompany: pc } = this.props.openingAccountsStore;
    const entity = (pu || pc)!;

    const PF = "bureaus" in entity;
    const isMEI = "isMEI" in entity && entity.isMEI;
    const PJ = !PF && !isMEI;

    const { value } = this.selectedAction!;

    this.confirmDialogOpen = false;
    this.selectedAction = null;

    if (["lockedAccount", "unlockedAccount", "automaticMethod"].includes(value)) {
      try {
        this.loadingAction = true;

        let blockStatus: api.BlockStatusAccount = api.BlockStatusAccount.blocked;

        if (value === "lockedAccount") {
          blockStatus = api.BlockStatusAccount.blocked;
        } else if (value === "unlockedAccount") {
          blockStatus = api.BlockStatusAccount.unlocked;
        } else {
          blockStatus = api.BlockStatusAccount.automatic;
        }

        await this.props.openingAccountsStore.setBlockStatusAccount(blockStatus);
        this.props.generalStore.showToast("Status da conta simples alterado.");
      } catch (err) {
        this.props.generalStore.showToast(err ? err.message : "Ocorreu um erro ao alterar o status da conta.");
      } finally {
        this.loadingAction = false;
      }
    }

    if (["revalidateAddress", "approvedCompliance", "refusedCompliance"].includes(value)) {
      const {
        pendingUser,
        pendingCompany,
        submitRevalidate,
        setCompliance,
        changeCompanyCompliance,
      } = this.props.openingAccountsStore;

      try {
        this.loadingAction = true;
        const documentNumber = pendingUser ? pendingUser.cpf : pendingCompany!.documentNumber;

        if (value === "revalidateAddress") {
          await submitRevalidate(
            documentNumber,
            [api.RegisterField.address, api.RegisterField.proofOfAddress],
            "Para concluir as pendências e continuar utilizando a sua conta, atualize o seu comprovante de residência.",
          );
          this.props.generalStore.showToast("Revalidação de endereço enviada.");
        } else if (value === "approvedCompliance") {
          if (PJ) {
            await changeCompanyCompliance(pendingCompany!.id, api.ChangeComplianceStatus.approved);
            this.props.routerStore.push(openingAccountsSectionPath(this.filterAccountPath));
          } else {
            await setCompliance(
              documentNumber,
              // eslint-disable-next-line no-nested-ternary
              pendingUser ? api.PfPj.pf : pendingCompany!.isMEI ? api.PfPj.mei : api.PfPj.pj,
              api.ComplianceStatus.approved,
            );

            if (
              (pendingUser &&
                (pendingUser.status === "active" ||
                  pendingUser.status === "approvePendingWithDocument" ||
                  pendingUser.status === "approvePendingWithoutDocument")) ||
              (pendingCompany &&
                (pendingCompany.status === "active" ||
                  pendingCompany.status === "approvePendingWithDocument" ||
                  pendingCompany.status === "approvePendingWithoutDocument"))
            ) {
              this.props.routerStore.push(openingAccountsSectionPath(this.filterAccountPath));
            }
          }
        } else if (value === "refusedCompliance") {
          if (PJ) {
            await changeCompanyCompliance(pendingCompany!.id, api.ChangeComplianceStatus.reproved);
          } else {
            await setCompliance(
              documentNumber,
              // eslint-disable-next-line no-nested-ternary
              pendingUser ? api.PfPj.pf : pendingCompany!.isMEI ? api.PfPj.mei : api.PfPj.pj,
              api.ComplianceStatus.repproved,
            );
          }

          this.props.routerStore.push(openingAccountsSectionPath(this.filterAccountPath));
          this.props.generalStore.showToast("Conta reprovada com sucesso.");
        }
      } catch (err) {
        this.props.generalStore.showToast(err ? err.message : "Ocorreu um erro ao alterar o status da conta.");
      } finally {
        this.loadingAction = false;
      }
    }

    const status: api.UserStatus | null = {
      approve: api.UserStatus.active,
      approveSimple: api.UserStatus.approvePending,
      approvedCompliance: null,
      automaticMethod: null,
      cancel: api.UserStatus.canceled,
      disapprove: api.UserStatus.repproved,
      lockedAccount: null,
      refusedCompliance: null,
      revalidate: null,
      revalidateAddress: null,
      unlockedAccount: null,
    }[value];

    if (status) {
      this.setStatus(status);
    }
  };

  private cancelRevalidation = () => {
    this.revalidateDialogOpen = false;
  };

  private confirmRevalidation = () => {
    this.props.generalStore.showToast("Conta revalidada com sucesso.");
    this.props.routerStore.push(openingAccountsSectionPath("workflow"));
  };

  @action
  private setStatus = async (status: api.UserStatus) => {
    const { changeStatusUser, pendingUser, getBadgeData, pendingCompany } = this.props.openingAccountsStore;

    const docNumber = this.props.match.params.documentNumber;

    try {
      this.confirmDialogOpen = false;
      this.loadingAction = true;
      await changeStatusUser(
        docNumber,
        pendingUser ? api.PfPj.pf : pendingCompany!.isMEI ? api.PfPj.mei : api.PfPj.pj,
        status!,
      );

      if (
        (this.props.openingAccountsStore.pendingUser &&
          this.props.openingAccountsStore.pendingUser.complianceStatus === api.ComplianceStatus.approved) ||
        (this.props.openingAccountsStore.pendingCompany &&
          this.props.openingAccountsStore.pendingCompany.complianceStatus === api.ComplianceStatus.approved) ||
        status === api.UserStatus.canceled ||
        status === api.UserStatus.repproved
      ) {
        this.props.routerStore.push(openingAccountsSectionPath(this.filterAccountPath));
      }

      this.props.generalStore.showToast("Status alterado com sucesso.");
    } catch (err) {
      this.props.generalStore.showToast(err ? err.message : "Ocorreu um erro ao alterar o status da conta.");
    } finally {
      this.loadingAction = false;
      this.selectedAction = null;
      getBadgeData();
    }
  };

  private getComplianceStatus = () => {
    const data = this.props.openingAccountsStore.pendingUser || this.props.openingAccountsStore.pendingCompany;

    switch (data!.complianceStatus) {
      case api.ComplianceStatus.approved:
        return (
          <StyledComplianceStatus style={{ color: colors.lightGreen }}>
            <SvgApprovedCompliance width="18" height="18" color={colors.lightGreen} />
            <p>Aprovado pelo compliance</p>
          </StyledComplianceStatus>
        );
      case api.ComplianceStatus.repproved:
        return (
          <StyledComplianceStatus style={{ color: colors.darkRed }}>
            <SvgDisapprovedCompliance width="18" height="18" color={colors.darkRed} />
            <p>Recusado pelo compliance</p>
          </StyledComplianceStatus>
        );
      case api.ComplianceStatus.waiting:
        return (
          <StyledComplianceStatus style={{ color: colors.gray }}>
            <SvgWaitingCompliance width="18" height="18" color={colors.gray} />
            <p>Aguardando análise de compliance</p>
          </StyledComplianceStatus>
        );
      default:
        return (
          <StyledComplianceStatus style={{ color: colors.gray }}>
            <SvgWaitingCompliance width="18" height="18" color={colors.gray} />
            <p>Aguardando análise de compliance</p>
          </StyledComplianceStatus>
        );
    }
  };

  private getAccountStatus = () => {
    const data = this.props.openingAccountsStore.pendingUser || this.props.openingAccountsStore.pendingCompany;

    switch (data!.status) {
      case "approvePendingWithDocument":
        return (
          <StyledComplianceStatus style={{ color: colors.lightGreen }}>
            <SvgApprovedCompliance width="18" height="18" color={colors.lightGreen} />
            <p>Aprovada a criação de conta simples</p>
          </StyledComplianceStatus>
        );
      case "approvePendingWithoutDocument":
        return (
          <StyledComplianceStatus style={{ color: colors.lightGreen }}>
            <SvgApprovedCompliance width="18" height="18" color={colors.lightGreen} />
            <p>Aprovada a criação de conta simples</p>
          </StyledComplianceStatus>
        );
      case "approvePending":
        return (
          <StyledComplianceStatus style={{ color: colors.lightGreen }}>
            <SvgApprovedCompliance width="18" height="18" color={colors.lightGreen} />
            <p>Aprovada a criação de conta simples</p>
          </StyledComplianceStatus>
        );
      case "active":
        return (
          <StyledComplianceStatus style={{ color: colors.lightGreen }}>
            <SvgApprovedCompliance width="18" height="18" color={colors.lightGreen} />
            <p>Aprovada a criação de conta full</p>
          </StyledComplianceStatus>
        );
      default:
        return (
          <StyledComplianceStatus style={{ color: colors.gray }}>
            <SvgWaitingCompliance width="18" height="18" color={colors.gray} />
            <p>Aguardando análise de conta</p>
          </StyledComplianceStatus>
        );
    }
  };

  private getTypeAccountTitle = () => {
    const { pendingUser, pendingCompany } = this.props.openingAccountsStore;

    if (
      pendingUser &&
      (pendingUser.status === "approvePendingWithDocument" || pendingUser.status === "approvePendingWithoutDocument")
    ) {
      return "PF - Conta Simples";
    } else if (pendingUser && pendingUser.subType === api.SubType.simple && pendingUser.journeyType === null) {
      return "PF - Conta Simples";
    } else if (pendingUser) {
      return pendingUser.accountType;
    } else if (pendingCompany && pendingCompany.isMEI) {
      return "MEI";
    }

    return "PJ - Pagamento";
  };

  private isValidUrlAndStatus = (pendingEntity: api.RegisterUserFields | api.RegisterCompanyV13) => {
    if (
      pendingEntity!.status === UserStatus.active &&
      pendingEntity!.complianceStatus === api.ComplianceStatus.approved &&
      this.props.match.params.section !== "aprovados" &&
      pendingEntity!.subType === api.SubType.full
    ) {
      return false;
    } else if (pendingEntity!.status === UserStatus.pending && this.props.match.params.section !== "pendentes") {
      return false;
    } else if (pendingEntity!.status === UserStatus.canceled && this.props.match.params.section !== "cancelados") {
      return false;
    } else if (pendingEntity!.status === UserStatus.repproved && this.props.match.params.section !== "reprovados") {
      return false;
    } else if (
      pendingEntity!.status === UserStatus.notFinished &&
      this.props.match.params.section !== "nao-finalizadas"
    ) {
      return false;
    } else if (pendingEntity!.status === UserStatus.finished && this.props.match.params.section !== "workflow") {
      return false;
    } else if (
      pendingEntity!.status === UserStatus.processing &&
      this.props.match.params.section !== "em-processamento"
    ) {
      return false;
    } else if (
      pendingEntity!.status === UserStatus.approvePendingWithoutDocument &&
      pendingEntity!.complianceStatus === api.ComplianceStatus.approved &&
      this.props.match.params.section !== "contas-simples"
    ) {
      return false;
    } else if (
      pendingEntity!.status === UserStatus.approvePendingWithDocument &&
      pendingEntity!.complianceStatus === api.ComplianceStatus.approved &&
      this.props.match.params.section !== "contas-simples"
    ) {
      return false;
    } else if (
      pendingEntity!.complianceStatus === api.ComplianceStatus.manualAnalysis &&
      pendingEntity!.status !== UserStatus.repproved &&
      this.props.match.params.section !== "workflow"
    ) {
      return false;
    }

    return true;
  };

  private displayChangeSERPROInfo = () => {
    const { pendingCompany } = this.props.openingAccountsStore;

    if (
      this.filterAccountPath === "workflow" &&
      this.props.generalStore!.hasPermission(api.AdminUserPermission.editCompany) &&
      pendingCompany &&
      pendingCompany.status === "finished" &&
      pendingCompany.complianceStatus === "approved" &&
      this.showChangeSerproInfoAlert &&
      this.accountType === "pj"
    ) {
      return (
        <Flex>
          <StyledInfoBar>
            Algumas informações declaradas na conta PJ estão divergentes da SERPRO,{" "}
            <a href="#" onClick={() => (this.changeCompanyInfoSerpro = true)}>
              clique aqui
            </a>{" "}
            para corrigir as informações.
          </StyledInfoBar>
        </Flex>
      );
    }

    return null;
  };

  public render() {
    return (
      <Container>
        <TitleContainer>
          <Title>Solicitação de abertura de conta</Title>
        </TitleContainer>
        {this.renderData()}
      </Container>
    );
  }

  private renderData = () => {
    const { pendingUser, pendingCompany } = this.props.openingAccountsStore;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return (
        <InnerAlert
          message={this.error!}
          buttonLabel="Tentar novamente"
          buttonClick={async () => {
            return this.getData();
          }}
        />
      );
    }

    const pendingEntity = pendingUser || pendingCompany;

    if (!pendingEntity || !this.isValidUrlAndStatus(pendingEntity)) {
      return (
        <InnerAlert message="Conta não existente" buttonLabel="Voltar para a lista" buttonClick={this.handleBack} />
      );
    }

    return (
      <>
        <ConfirmDialog
          title="Atenção"
          text={`Você tem certeza que deseja ${this.confirmTextIncrement}?`}
          isOpen={this.confirmDialogOpen}
          onCancel={this.cancelAction}
          onConfirm={this.confirmAction}
        />
        <ConfirmDialog
          title="Atenção"
          text="Você tem certeza que deseja reenviar o e-mail de pendência?"
          isOpen={this.resendEmailDialogOpen}
          onCancel={() => this.handleResendEmailDialog(false)}
          isLoading={this.loadingResendEmail}
          onConfirm={this.handleResendEmail}
        />
        <ConfirmDialog
          title="Atenção"
          text={`Tem certeza que deseja atualizar as informações declaradas pelas informações retornadas pela SERPRO?`}
          isOpen={this.changeCompanyInfoSerpro}
          onCancel={() => (this.changeCompanyInfoSerpro = false)}
          isLoading={this.loadingChangeCompanyInfoSerpro}
          onConfirm={async () => this.changeCompanyInfoBySerpro(this.props.match.params.userId)}
        />
        <Dialog title="Revalidar" isOpen={this.revalidateDialogOpen} onClose={this.cancelRevalidation} showCloseButton>
          <RevalidateDialog
            documentNumber={pendingUser ? pendingUser.cpf : pendingCompany!.documentNumber}
            onConfirm={this.confirmRevalidation}
          />
        </Dialog>
        <BackButton onClick={this.handleBack} label="Voltar para a lista" />
        <Header>
          <Flex>
            <TitleBlock>
              <TypeAccountTitle>{this.getTypeAccountTitle()}</TypeAccountTitle>
              <TypeAccountSubtitle>
                Origem: {pendingUser ? pendingUser.partner.name : pendingCompany!.partnerName}
              </TypeAccountSubtitle>
              {this.props.openingAccountsStore.pendingUser && this.isSimpleAccount && (
                <TypeAccountSubtitle>
                  Status do bloqueio{" "}
                  {prettierBlockStatusAccount(this.props.openingAccountsStore.pendingUser.blockStatus!)}
                </TypeAccountSubtitle>
              )}
            </TitleBlock>
            <Flex style={{ flexWrap: "wrap", marginLeft: "20px" }}>
              {this.renderActions(pendingEntity)}
              {this.renderComplianceActions(pendingEntity)}
              {this.renderBlockedActions}
              {this.renderActionsLoader()}
              {this.renderResendEmailButton()}
            </Flex>
          </Flex>
          {this.filterAccountPath === "workflow" ? (
            <Text>
              {this.accountType !== "pj" && this.getComplianceStatus()}
              {this.accountType === "pj" ? (
                <StyledComplianceStatus style={{ color: colors.gray }}>
                  <SvgWaitingCompliance width="18" height="18" color={colors.gray} />
                  {pendingCompany &&
                  pendingCompany.status === "finished" &&
                  pendingCompany.complianceStatus === "approved" ? (
                    <p>Aguardando aprovação do cadastro</p>
                  ) : (
                    <p>Aguardando aprovação do compliance</p>
                  )}
                </StyledComplianceStatus>
              ) : (
                this.getAccountStatus()
              )}
            </Text>
          ) : null}
        </Header>
        {this.displayChangeSERPROInfo()}
        <TabNavigator
          onChangeTab={(tabValue: any) => this.onChangeTab(tabValue as Tab)}
          selected={this.selectedTab}
          tabs={this.renderTabs()}
        />
      </>
    );
  };

  @action
  private getMaximaId = () => {
    this.props.partnerStore.getMaximaId().then(maximaId => {
      this.maximaId = maximaId;
    });
  };

  private renderActions = (entity: api.RegisterUserFields | api.RegisterCompanyV13) => {
    const isOldPJ = this.props.match.params.oldPj === "true";
    const { pendingUser, pendingCompany } = this.props.openingAccountsStore;

    if (this.accountType === "pj" && !isOldPJ) {
      return null;
    }

    if (!["workflow", "pending", "approvedPending", "approved"].includes(this.filterAccountPath)) {
      return null;
    }

    if (this.filterAccountPath === "approved" && pendingUser?.journeyType === null) {
      return null;
    }

    const { hasPermission } = this.props.generalStore;
    const PF = "bureaus" in entity;
    const actions: IActionOption[] = [];

    const permissions = {
      approve: PF ? api.AdminUserPermission.approveAccountPF : api.AdminUserPermission.approveAccountPJ,
      approvePending: api.AdminUserPermission.approvePendingAccountPF,
      cancel: PF ? api.AdminUserPermission.canceledAccountPF : api.AdminUserPermission.canceledAccountPJ,
      disapprove: PF ? api.AdminUserPermission.disapproveAccountPF : api.AdminUserPermission.disapproveAccountPJ,
      revalidate: PF ? api.AdminUserPermission.revalidateAccountPF : api.AdminUserPermission.revalidateAccountPJ,
    };

    if (
      hasPermission(permissions.approve) &&
      entity.status === "active" &&
      pendingUser?.journeyType !== null &&
      pendingUser?.subType === api.SubType.simple
    ) {
      actions.push({
        label: "Aprovar abertura de conta full",
        value: "approve",
      });
    }

    if ((PF ? hasPermission(permissions.approve) : hasPermission(permissions.approve)) && entity.status !== "active") {
      actions.push({
        label: "Aprovar abertura de conta full",
        value: "approve",
      });
    }

    if (
      (PF ? hasPermission(permissions.approvePending) : false) &&
      (pendingUser ? pendingUser.partner.id : pendingCompany!.partnerId) !== this.maximaId &&
      entity.status !== "approvePendingWithDocument" &&
      entity.status !== "approvePendingWithoutDocument" &&
      entity.status !== "active"
    ) {
      actions.push({
        label: "Aprovar abertura de conta simples",
        value: "approveSimple",
      });
    }

    if (hasPermission(permissions.disapprove) && (entity.status === "finished" || entity.status === "pending")) {
      actions.push({ label: "Reprovar", value: "disapprove" });
    }

    if (hasPermission(permissions.cancel) && (entity.status === "finished" || entity.status === "pending")) {
      actions.push({ label: "Cancelar", value: "cancel" });
    }

    if (
      hasPermission(permissions.revalidate) &&
      entity.status !== "pending" &&
      (!pendingUser || pendingUser?.journeyType === null)
    ) {
      if (entity.status === "approvePendingWithDocument" || entity.status === "approvePendingWithoutDocument") {
        actions.push({
          label: "Revalidar endereço",
          value: "revalidateAddress",
        });
      } else if (entity.status !== "active") {
        actions.push({ label: "Revalidar", value: "revalidate" });
      }
    }

    if (!actions.length) {
      return null;
    }

    return (
      <SelectWrapper>
        <Select<IActionOption>
          isDisabled={this.loadingAction}
          options={actions}
          onChange={this.handleAction}
          placeholder="Conta"
          isSearchable={false}
          value={null}
        />
      </SelectWrapper>
    );
  };

  private renderComplianceActions = (entity: api.RegisterUserFields | api.RegisterCompanyV13) => {
    const { hasPermission } = this.props.generalStore;
    const PF = "bureaus" in entity;
    const isMEI = "isMEI" in entity && entity.isMEI;
    const PJ = !PF && !isMEI;

    if (
      !hasPermission(
        isMEI
          ? api.AdminUserPermission.validateComplianceAccountPJMEI
          : PF
          ? api.AdminUserPermission.validateComplianceAccountPF
          : api.AdminUserPermission.validateComplianceAccountPJ,
      ) ||
      !(this.filterAccountPath === "workflow")
    ) {
      return null;
    }

    let complianceStatus = null;

    if (this.props.openingAccountsStore.pendingUser) {
      // eslint-disable-next-line prefer-destructuring
      complianceStatus = this.props.openingAccountsStore.pendingUser.complianceStatus;
    } else if (this.props.openingAccountsStore.pendingCompany) {
      // eslint-disable-next-line prefer-destructuring
      complianceStatus = this.props.openingAccountsStore.pendingCompany.complianceStatus;
    }

    const actions: IActionOption[] = [];

    if (
      complianceStatus === api.ComplianceStatus.waiting ||
      complianceStatus === api.ComplianceStatus.repproved ||
      complianceStatus === api.ComplianceStatus.manualAnalysis ||
      PJ
    ) {
      actions.push({
        label: "Aprovar compliance",
        value: "approvedCompliance",
      });
    }

    if (
      complianceStatus === api.ComplianceStatus.waiting ||
      complianceStatus === api.ComplianceStatus.approved ||
      complianceStatus === api.ComplianceStatus.manualAnalysis ||
      PJ
    ) {
      actions.push({
        label: "Reprovar compliance",
        value: "refusedCompliance",
      });
    }

    if (
      (entity.status === "approvePendingWithoutDocument" || entity.status === "approvePendingWithDocument") &&
      complianceStatus === "approved"
    ) {
      return null;
    }

    if (entity.status === "finished" && complianceStatus === "approved") {
      return null;
    }

    if (!actions.length) {
      return null;
    }

    return (
      <ComplianceSelectWrapper>
        <Select<IActionOption>
          isDisabled={this.loadingAction}
          options={actions}
          onChange={this.handleAction}
          placeholder="Compliance"
          isSearchable={false}
          value={null}
        />
      </ComplianceSelectWrapper>
    );
  };

  @computed
  private get isSimpleAccount() {
    const user = this.props.openingAccountsStore.pendingUser;

    if (
      user &&
      user!.status !== "approvePending" &&
      user!.status !== "approvePendingWithDocument" &&
      user!.status !== "approvePendingWithoutDocument"
    ) {
      return false;
    }

    return true;
  }

  @computed
  private get renderBlockedActions() {
    const isOldPJ = this.props.match.params.oldPj === "true";

    if (this.accountType === "pj" && !isOldPJ) {
      return null;
    }

    const { hasPermission } = this.props.generalStore;
    const user = this.props.openingAccountsStore.pendingUser;

    if (
      !user ||
      !hasPermission(api.AdminUserPermission.approveAccountPF) ||
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (user && user!.complianceStatus === "waiting")
    ) {
      return null;
    }

    if (!this.isSimpleAccount) {
      return null;
    }

    const actions: IActionOption[] = [];

    if (user!.blockStatus !== api.BlockStatusAccount.blocked) {
      actions.push({
        label: "Bloquear Conta",
        value: "lockedAccount",
      });
    }

    if (user!.blockStatus !== api.BlockStatusAccount.unlocked) {
      actions.push({
        label: "Desbloquear Conta",
        value: "unlockedAccount",
      });
    }

    if (user!.blockStatus !== api.BlockStatusAccount.automatic) {
      actions.push({
        label: "Modo Automático",
        value: "automaticMethod",
      });
    }

    if (!actions.length) {
      return null;
    }

    return (
      <ComplianceSelectWrapper>
        <Select<IActionOption>
          isDisabled={this.loadingAction}
          options={actions}
          onChange={this.handleAction}
          placeholder="Status de bloqueio"
          isSearchable={false}
          value={null}
        />
      </ComplianceSelectWrapper>
    );
  }

  private renderResendEmailButton = () => {
    const isOldPJ = this.props.match.params.oldPj === "true";

    if (this.accountType === "pj" && !isOldPJ) {
      return null;
    }

    if (this.filterAccountPath === "pending") {
      return (
        <ResendEmailButton label="Reenviar e-mail de pendência" onClick={() => this.handleResendEmailDialog(true)} />
      );
    }

    return null;
  };

  @action
  private handleResendEmailDialog = (value: boolean) => {
    this.resendEmailDialogOpen = value;
  };

  @action
  private changeCompanyInfoBySerpro = async (companyId: string) => {
    try {
      this.loadingChangeCompanyInfoSerpro = true;
      await this.props.openingAccountsStore.resolveRegisterConflicts(companyId);
      this.changeCompanyInfoSerpro = false;
      this.showChangeSerproInfoAlert = false;
      this.props.generalStore!.showToast("Informações cadastrais corrigidas com sucesso!");
      this.props.routerStore.push(openingAccountsSectionPath(this.filterAccountPath));
    } catch (error) {
      this.changeCompanyInfoSerpro = false;
      this.props.generalStore!.showToast(error.message || "Não foi possível corrigir informações cadastrais.");
    }
  };

  @action
  private handleResendEmail = async () => {
    try {
      this.loadingResendEmail = true;
      await this.props.openingAccountsStore.sendLinkPendingRegister();
      this.handleResendEmailDialog(false);
      this.props.generalStore!.showToast("E-mail enviado com sucesso!");
    } catch (error) {
      this.handleResendEmailDialog(false);
      this.props.generalStore!.showToast(error.message || "Não foi possível realizar o envio do e-mail.");
    } finally {
      this.loadingResendEmail = false;
    }
  };

  private renderActionsLoader = () => {
    const isOldPJ = this.props.match.params.oldPj === "true";

    if (!this.loadingAction || (this.accountType === "pj" && !isOldPJ)) {
      return null;
    }

    return <CircularProgress thickness={3} size={20} style={{ color: colors.violetRed }} />;
  };

  private renderTabs = () => {
    const { pendingUser, pendingCompany, analyzeCompany } = this.props.openingAccountsStore;
    const { hasPermission } = this.props.generalStore!;
    const fetchComplianceData = this.props.openingAccountsStore.getUserComplianceData();
    const fetchPjComplianceData = this.props.openingAccountsStore.getComplianceByCompanyId(
      this.props.match.params.userId,
    );
    const isOldPJ = this.props.match.params.oldPj === "true";

    const tabs = [
      {
        child: (
          <RegisterData
            user={pendingUser}
            company={pendingCompany}
            analyzeCompany={analyzeCompany}
            filterAccountPath={this.filterAccountPath}
            pfPj={this.accountType}
            push={this.props.routerStore.push}
            isOldPj={isOldPJ}
          />
        ),
        label: "Dados cadastrais",
        value: "registerData",
      },
    ];

    if (this.accountType === "pj" && !isOldPJ) {
      tabs.push({
        child: (
          <Representatives
            companyId={this.props.match.params.userId}
            generalStore={this.props.generalStore}
            onSelected={fetchComplianceData as () => Promise<api.ComplianceData>}
          />
        ),
        label: "Representantes",
        value: "representatives",
      });
    }

    if (this.accountType === "pj" && !isOldPJ) {
      tabs.push({
        child: (
          <Subscribers
            companyId={this.props.match.params.userId}
            generalStore={this.props.generalStore}
            onSelected={fetchComplianceData as () => Promise<api.ComplianceData>}
          />
        ),
        label: "Signatários",
        value: "subscribers",
      });
    }

    if (Array.isArray(fetchComplianceData) && this.accountType === "mei") {
      tabs.push(
        {
          child: <Compliance onSelected={fetchComplianceData[0]} />,
          label: "Compliance - CNPJ",
          value: "compliance",
        },
        {
          child: <Compliance onSelected={fetchComplianceData[1]} />,
          label: "Compliance - CPF",
          value: "compliance2",
        },
      );
    } else if (this.accountType === "pj" && !isOldPJ) {
      tabs.push({
        child: <Beneficiary onSelectedPj={fetchPjComplianceData} />,
        label: "Beneficiários",
        value: "beneficiary",
      });
    } else {
      tabs.push({
        child: <Compliance onSelected={fetchComplianceData as () => Promise<api.ComplianceData>} />,
        label: "Compliance",
        value: "compliance",
      });
    }

    if (
      hasPermission(api.AdminUserPermission.viewFeedback) ||
      hasPermission(api.AdminUserPermission.viewHistoryWorkflow)
    ) {
      tabs.push({
        child: (
          <Feedback isPjHistory={this.accountType === "pj" && !isOldPJ} companyId={this.props.match.params.userId} />
        ),
        label: "Pareceres e histórico",
        value: "feedback",
      });
    }

    return tabs;
  };
}

const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.6px;
  margin-left: 5px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const Header = styled.header`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 40px;
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-left: solid 4px ${colors.lightGray};
  padding: 10px 0 10px 10px;
`;

const TypeAccountTitle = styled.h4`
  font-family: Lato;
  color: ${colors.black};
  font-size: 16px;
  letter-spacing: 0.7px;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
`;

const TypeAccountSubtitle = styled.h4`
  font-family: Lato;
  color: ${colors.gray};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  margin: 0;
`;

const SelectWrapper = styled.div`
  margin: 10px 20px;
  width: 150px;
`;

const ComplianceSelectWrapper = styled.div`
  margin: 10px 20px;
  width: 180px;
`;

const StyledComplianceStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 5px;
  }
`;

const ResendEmailButton = styled(RaisedButton)`
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px 0px 50px;
  width: 180px;
  height: 55px;
`;

const StyledInfoBar = styled.div`
  border-radius: 8px;
  color: #525051;
  padding: 12px 18px;
  width: 500px;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  background-color: rgba(237, 41, 128, 0.1);
  a {
    color: rgba(237, 41, 128);
  }
`;
