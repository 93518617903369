import React from "react";
import { currencyFormatter, mask } from "utils";
import { formatDate } from "@manager/utils";
import { formatCpf } from "@brazilian-utils/formatters";

import { Modal } from "components/Modal";

import { Container, ListModal } from "../styled";

interface IProps {
  visible: boolean;
  closeModal: () => void;
  data: any;
}

export const ModalDetails = ({ visible, closeModal, data }: IProps) => {
  const firstColumns: any = {
    date: "Data geração",
    doc: "CPF",
    name: "Nome completo",
    birthdate: "Data de nascimento",
    mother: "Nome mãe",
    gender: "Gênero",
    grossIncome: "Patrimônio",
    equityValue: "Renda",
  };

  const secondColumns: any = {
    zipCode: "CEP",
    state: "Estado",
    city: "Cidade",
    address: "logradouro",
    addressNumber: "Número",
    complementAddress: "Complemento",
    neighborhood: "Bairro",
    email: "Email",
    DDD: "DDD",
    cellNumber: "Celular",
  };

  Object.keys(data).forEach(item => {
    if (item === "zipCode" && data[item] !== "") {
      data[item] = mask(data[item], "#####-###");
    }

    if (item === "doc" && data[item] !== "") {
      data[item] = formatCpf(data[item]);
    }

    if ((item === "grossIncome" || item === "equityValue") && data[item] !== "") {
      data[item] = `R$ ${currencyFormatter(data[item])}`;
    }

    if (item === "birthdate" && data[item] !== "") {
      data[item] = formatDate(data[item]);
    }

    if (item === "date" && data[item] !== "") {
      data[item] = formatDate(data[item]);
    }

    if (data[item] === "") {
      data[item] = "-";
    }
  });

  return (
    <Modal visible={visible} title="Detalhes" width={960} onClose={closeModal}>
      <Container>
        <ListModal>
          {Object.keys(firstColumns).map((item: string, index) => (
            <li key={index}>
              <span>{firstColumns[item]} </span>
              <span>{data[item]}</span>
            </li>
          ))}
        </ListModal>
        <ListModal>
          {Object.keys(secondColumns).map((item: string, index) => (
            <li key={index}>
              <span>{secondColumns[item]} </span>
              <span>{data[item]}</span>
            </li>
          ))}
        </ListModal>
      </Container>
    </Modal>
  );
};
