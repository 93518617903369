import React from "react";
import RcDropdown from "rc-dropdown";

import "rc-dropdown/assets/index.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button } from "@material-ui/core";

import { Checkbox, CheckboxGroup } from "components/Checkbox";

import s from "./style.scss";

export interface IOption<Valtype> {
  label: string;
  key: string;
  value: Valtype;
}

interface IProps<Valtype> {
  placement?: "bottomLeft" | "bottomCenter" | "bottomRight" | "topLeft" | "topCenter" | "topRight";
  visible?: boolean;
  options: Array<IOption<Valtype>>;
  values: Array<IOption<Valtype>>;
  label: string;
  selectAll?: boolean;
  selectAllText?: string;
  onToggle: (newArr: Array<IOption<Valtype>>) => void;
}

export function SelecMultiple<Valtype>({
  label,
  values,
  options,
  onToggle,
  selectAll,
  selectAllText,
  ...restProps
}: IProps<Valtype>) {
  const handleChange = (keys: string[]) => {
    onToggle(options.filter(option => keys.includes(option.key)));
  };

  const handleSelectOrRemoveAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.checked;

    if (newValue) {
      handleChange(options.map(option => option.key));
    } else {
      handleChange([]);
    }
  };

  const countSelected = values.length;

  return (
    <RcDropdown
      {...restProps}
      overlayClassName={s.dropdown}
      trigger="click"
      overlay={
        <>
          {selectAll && (
            <Checkbox
              checked={values.length === options.length}
              onChange={handleSelectOrRemoveAll}
              className={s.all}
              key="selectAll"
              value={"selectAll"}
            >
              {selectAllText ? selectAllText : "Selecionar todos"}
            </Checkbox>
          )}

          <CheckboxGroup
            align="block"
            value={values.map(val => val.key)}
            options={options.map(opt => ({ label: opt.label, value: opt.key }))}
            onChange={handleChange}
          />
        </>
      }
    >
      <Button className={s.button}>
        {countSelected ? <div className={s.count}>{countSelected}</div> : null}
        {label} <ArrowDropDownIcon />
      </Button>
    </RcDropdown>
  );
}
