import React from "react";
import classnames from "classnames";

import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";

import * as s from "./style.scss";

export interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  type?: "default" | "dark" | "primary";
  radius?: "default" | "big";
  onCancel?: () => void;
}

export function Tag({ children, type, radius, className, onCancel, ...spanProps }: IProps) {
  return (
    <span
      data-type={type}
      data-radius={radius}
      className={classnames(s.tag, className)}
      data-testid="tagComponent"
      {...spanProps}
    >
      {children}
      {onCancel && <CloseIcon data-testid="closeTag" onClick={onCancel} />}
    </span>
  );
}
