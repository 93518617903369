import * as React from "react";
import SelectComponent, { Props, StylesConfig } from "react-select";
import { colors } from "utils";

const customStyles: StylesConfig = {
  control: provided => ({
    ...provided,
    ":hover": {
      borderColor: colors.darkGreenOpacity,
      cursor: "pointer",
    },
    backgroundColor: colors.lightGray,
    borderColor: colors.platinum,
    boxShadow: "none",
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    ":hover": {
      backgroundColor: state.isSelected ? "" : colors.darkGreenOpacity,
      color: colors.white,
    },
    backgroundColor: state.isSelected ? colors.darkGreen : colors.white,
    color: state.isSelected ? colors.white : colors.black,
    cursor: "pointer",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? colors.darkGreen : "",
    color: colors.black,
  }),
};

export const SelectSoccer = <T extends any = { label: string; value: string }>({
  isSearchable = false,
  ...props
}: Props<T>) => {
  const { styles, ...restProps } = props;

  return (
    <SelectComponent
      isSearchable={isSearchable}
      styles={{ ...customStyles, ...styles }}
      noOptionsMessage={() => "Sem opções"}
      loadingMessage={() => "Carregando..."}
      {...restProps}
    />
  );
};
