/* eslint-disable func-style */
/* eslint-disable sort-keys */
/* eslint-disable react/no-deprecated */
import * as React from "react";
import { Permission, PermissionType } from "api/manager-api";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { noop } from "rxjs";
import { currencyFormatter, currencyParser } from "utils";

import { SwitchWithTextInput } from "../../../../components/SwitchWithTextInput";
import { Switch } from "../../../../components/Switch";
import { Label, Line, Row } from "../components";

interface IProps {
  data: Permission[];
  onChange: (permissions: Permission[]) => void;
}

interface IPermissionInput {
  label: string;
  permissions: PermissionType[];
}

@observer
export class PermissionsManager extends React.Component<IProps> {
  private permissions = observable.map<PermissionType, string | null>();

  private disposer = noop;

  constructor(props: IProps) {
    super(props);
    this.getPermissions();
  }

  public componentWillReceiveProps(newProps: IProps) {
    this.getPermissions(newProps);
  }

  @action
  private getPermissions = ({ data } = this.props) => {
    this.disposer();
    this.permissions.clear();

    for (const { permissionType, maxAmountDaily } of data) {
      this.permissions.set(permissionType, maxAmountDaily ? currencyFormatter(maxAmountDaily) : null);
    }

    this.disposer = this.permissions.observe(this.setPermissions);
  };

  private setPermissions = () => {
    const permissions: Permission[] = [];

    for (const [permissionType, formattedAmount] of this.permissions.entries()) {
      permissions.push({
        permissionType,
        maxAmountDaily: formattedAmount ? currencyParser(formattedAmount) : null,
        maxAmountOperation: null,
        isSpecial: false,
      });
    }

    this.props.onChange(permissions);
  };

  @action
  private toggleSwitch = (permissionTypes: PermissionType[]) => () => {
    if (this.permissions.has(permissionTypes[0])) {
      for (const permissionType of permissionTypes) {
        this.permissions.delete(permissionType);
      }
    } else {
      for (const permissionType of permissionTypes) {
        this.permissions.set(permissionType, null);
      }
    }
  };

  @action
  private setPermissionAmount = (permissionTypes: PermissionType[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    for (const permissionType of permissionTypes) {
      this.permissions.set(permissionType, currencyParser(value) ? currencyFormatter(value) : null);
    }
  };

  public render() {
    const BooleanPermission = this.renderBooleanPermission;
    const NumberPermission = this.renderNumberPermission;

    return (
      <>
        <BooleanPermission label="Listar usuários" permissions={[PermissionType.listUsers]} />
        <BooleanPermission label="Registrar usuários" permissions={[PermissionType.registerUser]} />
        <BooleanPermission label="Editar perfis" permissions={[PermissionType.editProfiles]} />
        <BooleanPermission label="Editar permissões de usuário" permissions={[PermissionType.changeUserPermissions]} />
        <BooleanPermission label="Conectar a parceiros" permissions={[PermissionType.connectPartners]} />
        <BooleanPermission label="Ver saldo" permissions={[PermissionType.balance]} />
        <BooleanPermission label="Ver extrato" permissions={[PermissionType.statement]} />
        <NumberPermission
          label="Solicitar transferência mesma titularidade"
          permissions={[PermissionType.requestTransferenceSameTitularity]}
        />
        <NumberPermission
          label="Aprovar transferência mesma titularidade"
          permissions={[PermissionType.approveTransferenceSameTitularity]}
        />
        <NumberPermission
          label="Solicitar transferência qualquer titularidade"
          permissions={[PermissionType.requestTransferenceAnyTitularity]}
        />
        <NumberPermission
          label="Aprovar transferência qualquer titularidade"
          permissions={[PermissionType.approveTransferenceAnyTitularity]}
        />
        <NumberPermission
          label="Solicitar aplicação financeira"
          permissions={[PermissionType.requestInvestmentApplication]}
        />
        <NumberPermission
          label="Aprovar aplicação financeira"
          permissions={[PermissionType.approveInvestmentApplication]}
        />
        <NumberPermission label="Solicitar pagamento de boleto" permissions={[PermissionType.requestBankslipPayment]} />
        <NumberPermission label="Aprovar pagamento de boleto" permissions={[PermissionType.approveBankslipPayment]} />
        <NumberPermission
          label="Solicitar pagamento de convênio"
          permissions={[PermissionType.requestAgreementPayment]}
        />
        <NumberPermission
          label="Aprovar pagamento de convênio"
          permissions={[PermissionType.approveAgreementPayment]}
        />
        <NumberPermission label="Solicitar recarga de celular" permissions={[PermissionType.requestRecharge]} />
        <NumberPermission label="Aprovar recarga de celular" permissions={[PermissionType.approveRecharge]} />
      </>
    );
  }

  private renderBooleanPermission = ({ label, permissions }: IPermissionInput) => (
    <InputRow>
      <Label>{label}</Label>
      <Switch checked={this.permissions.has(permissions[0])} handleChange={this.toggleSwitch(permissions)} />
    </InputRow>
  );

  private renderNumberPermission = ({ label, permissions }: IPermissionInput) => {
    const checked = this.permissions.has(permissions[0]);

    return (
      <InputRow>
        <Label>{label}</Label>
        <SwitchWithTextInput
          label="Limite diário"
          checked={checked}
          switchChange={this.toggleSwitch(permissions)}
          value={this.permissions.get(permissions[0]) || ""}
          inputChange={this.setPermissionAmount(permissions)}
          placeholder={checked ? "Ilimitado" : ""}
        />
      </InputRow>
    );
  };
}

const InputRow = ({ children }: { children: JSX.Element[] }) => (
  <>
    <Row>{children}</Row>
    <Line />
  </>
);
