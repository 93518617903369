import React, { useEffect, useState } from "react";
import { Partner } from "api/manager-api";
import Select from "react-select";
import { makeStyles } from "@material-ui/styles";

import { Dialog } from "../../../../components/Dialog";
import { Box, Info, InputArea, NextButtonWrapper } from "./styled";
import { customStylesReactSelect } from "../../../../utils/constants";

interface IProps {
  partner: Partner[] | null;
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  partnerSelected: (message: string) => void;
}

interface IPartnerOptions {
  label: string | null;
  value: string | null;
}

const useStyles = makeStyles({
  root: {
    overflowY: "initial",
  },
});

export function PartnerIdDialog({ partner, loading, onClose, onSubmit, partnerSelected }: IProps) {
  const [partnerList, setPartnerList] = useState<IPartnerOptions[]>([]);
  const [partnerValue, setPartnerValue] = useState<IPartnerOptions | null>(null);

  const classes = useStyles();

  const filterPartnerList = () => {
    setPartnerList(partner?.map(list => ({ label: list.name, value: list.id })) || []);
  };

  const handleSubmit = () => {
    return onSubmit();
  };

  const handleSelectPartner = (opt: any) => {
    setPartnerValue(opt);
    partnerSelected(opt.value);
  };

  useEffect(() => {
    filterPartnerList();
  }, []);

  return (
    <Dialog
      title="Atualizar parceiro vinculado"
      isOpen
      onClose={onClose}
      padding="0 20px 20px"
      classes={{ paperScrollPaper: classes.root }}
    >
      <Box>
        <Info>
          <Select
            isClearable={false}
            options={partnerList}
            isSearchable
            styles={customStylesReactSelect}
            placeholder="Selecionar parceiro"
            noOptionsMessage={() => "Parceiro não encontrado"}
            onChange={(opt: any) => handleSelectPartner(opt)}
            value={partnerValue}
          />
        </Info>
        <InputArea>
          <NextButtonWrapper loading={loading} label="Salvar" onClick={handleSubmit} />
        </InputArea>
      </Box>
    </Dialog>
  );
}
