import styled from "styled-components";
import { colors } from "utils";

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-left: solid 4px ${colors.gray};
  padding-left: 10px;
`;

export const Name = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: ${colors.black};
  padding: 0;
  margin: 0 0 7px 0;
`;

export const TypeAccount = styled.h4`
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.7px;
  color: ${colors.gray};
  padding: 0;
  margin: 0;
`;

export const SelectWrapper = styled.div`
  margin-left: 60px;
  width: 150px;
`;
