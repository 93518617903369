/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import * as React from "react";
import moment from "moment";
import styled from "styled-components";

import { Table } from "../../../components/Table";

import { colors } from "utils";

import { ITableData } from "..";

interface IProps {
  data: ITableData[];
  messageClickHandler: (id: string) => void;
}

export function HistoryTable({ data, messageClickHandler }: IProps) {
  const titles = ["Nome", "Data/Hora", "Título", "Mensagem"];

  return (
    <Table
      titles={titles}
      ids={data.map(entry => `history-${entry.id}`)}
      data={data.map(row => [
        row.author,
        moment(row.time).format("L"),
        row.title,
        <MessageWrapper id={row.id} message={row.message} clickHandler={messageClickHandler} key={`msg-${row.id}`} />,
      ])}
    />
  );
}

const MessageWrapper: React.FunctionComponent<{
  message: string;
  id: string;
  clickHandler: (id: string) => void;
}> = React.memo(({ message, id, clickHandler }) => {
  if (message.length === 0) {
    return null;
  } else if (message.length <= 30) {
    return <>{message}</>;
  }

  return (
    <>
      {message.slice(0, 30)}... <ReadMoreWrapper onClick={() => clickHandler(id)}>Ver mais</ReadMoreWrapper>
    </>
  );
});

const ReadMoreWrapper = styled.span`
  color: ${colors.violetRed};
  cursor: pointer;
`;
