export enum colors {
  gray = "#a6a6a6",
  lightGray = "#f2f3f5",
  mediumGray = "#dcdcdc",
  white = "#ffffff",
  borderGray = "#f2f2f2",
  black = "#515251",
  darkGold = "#c7ab71",
  gold = "rgba(227, 174, 41, 0.3)",
  darkBlue = "#21566e",
  blue = "#3775db",
  green = "#68ba79",
  red = "#e74c3c",
  darkRed = "#c0392b",
  themeBrown = "#ba9c56",
  darkPurple = "#282833",
  darkGray = "#282833",
  lightPink = "#c9c9c9",
  violetRed = "#ed2980",
  violetRedOpacity = "rgba(237, 41, 128, 0.4)",
  lightVioletRed = "#fbd4e6",
  extraLightGray = "#f5f5f5",
  platinum = "#e6e6e6",
  paleGrey = "#e3e4e6",
  lightGreen = "#27ae60",
  darkGreen = "#005d51",
  darkGreenOpacity = "rgba(0, 93, 81, 0.4)",
}

// export type Colors =
//   | "#a6a6a6"
//   | "#f2f3f5"
//   | "#ffffff"
//   | "#f2f2f2"
//   | "#dcdcdc"
//   | "#515251"
//   | "#c7ab71"
//   | "rgba(227, 174, 41, 0.3)"
//   | "#21566e"
//   | "#3775db"
//   | "#68ba79"
//   | "#e74c3c"
//   | "#282833"
//   | "#ba9c56"
//   | "#c9c9c9"
//   | "#ed2980"
//   | "rgba(237, 41, 128, 0.4)"
//   | "#fbd4e6"
//   | "#f5f5f5"
//   | "#e6e6e6"
//   | "#e3e4e6"
//   | "#c0392b"
//   | "#ba9c56"
//   | "rgba(0, 93, 81, 0.4)"
//   | "#27ae60"
//   | "#005d51";
