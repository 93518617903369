import * as React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import * as api from "api/manager-api";
import { formatCnpj } from "@brazilian-utils/formatters";
import moment from "moment";
import { coalesce, colors } from "utils";

import { TextShow } from "../../../components/TextShow";

interface IProps {
  data: api.CompanyManagerV13;
}

@observer
export class LegalEntityRegistrationData extends React.Component<IProps> {
  public render() {
    const { data } = this.props;

    return (
      <Container>
        <Title>Informações principais</Title>
        <WrapperTextShowMainInfo title="Razão social" text={data.name} />
        <WrapperTextShowMainInfo
          title="CNPJ"
          text={coalesce(data.documentNumber)(documentNumber => formatCnpj(documentNumber))}
        />
        {!data.isMEI && (
          <>
            <WrapperTextShowMainInfo title="E-mail" text={data.email} />
            <WrapperTextShowMainInfo title="Nome fantasia" text={data.fantasyName} />
            <WrapperTextShowMainInfo
              title="Fundação"
              text={data.foundationDate ? moment(data.foundationDate).format("L") : "Não informado"}
            />
            <WrapperTextShowMainInfo title="Telefone" text={data.phone} />
            <WrapperTextShowMainInfo title="Atividade principal" text={data.mainActivity} />

            {data.address && (
              <>
                <Title>Endereço</Title>
                <WrapperTextShowMainInfo title="Rua" text={data.address.street} />
                <WrapperTextShowMainInfo title="Complemento" text={data.address.complement || "Não informado"} />
                <WrapperTextShowMainInfo title="Número" text={data.address.number} />
                <WrapperTextShowMainInfo title="Bairro" text={data.address.neighborhood} />
                <WrapperTextShowMainInfo title="CEP" text={data.address.cep} />
                <WrapperTextShowMainInfo title="Cidade" text={data.address.city} />
                <WrapperTextShowMainInfo title="Estado" text={data.address.state} />
                <WrapperTextShowMainInfo title="País" text={data.address.country} />
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

const Title = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
`;

const WrapperTextShowMainInfo = styled(TextShow)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;
