/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable sort-keys */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as api from "api/manager-api";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { AuthStore } from "@partner/stores";
import { Dialog } from "@manager/components/Dialog";
import { DialogProps } from "@material-ui/core/Dialog";
import {
  CancelButton,
  Right,
  SaveButton,
  StyledTextArea,
} from "@manager/containers/TransactionalBoundaries/GeneralParameters/DefaultLimit/style";

import { GeneralStore, OpeningAccountsStore, UsersStore } from "../../../stores";
import { Loader } from "../../../components/Loader";
import { RegisterData } from "../../../components/RegisterData";
import { Select } from "../../../components/Select";
import { TabNavigator } from "../../../components/TabNavigator";
import { InnerAlert } from "../../../components/InnerAlert";
import { UserAccounts } from "../UserAccounts";
import { Fetch } from "../../../components/Fetchable";
import { PartnerID } from "../ChangePartnerId/PartnerID";
import { AddressDialog } from "../ChangeAddress/AddressDialog";
import { Compliance } from "../../OpeningAccounts/OpeningAccountProfile/Compliance";
import { partnerIdValue } from "../../../utils/constants";
import { UserChangePass } from "../UserChangePass";
import { Header, Name, SelectWrapper, TitleBlock, TypeAccount } from "./styled";
import { EditUserDataDialog } from "../ChangeUserData/EditUserDataDialog";

type Tab = "registerData" | "accounts" | "compliance";

interface IProps extends RouteComponentProps<{ cpf: string; isSimpleAccount: "simples" | "full" }> {
  usersStore: UsersStore;
  generalStore: GeneralStore;
  authStore: AuthStore;
  openingAccountsStore?: OpeningAccountsStore;
}

interface IProps extends Partial<DialogProps> {
  title: string;
  isOpen: boolean;
  showDivider?: boolean;
  showCloseButton?: boolean;
  onClose: () => void;
  padding?: string;
  closeButtonSize?: string;
}

@inject("openingAccountsStore", "generalStore", "usersStore")
@observer
export class UserProfile extends React.Component<IProps> {
  @observable private selectedTab = "registerData";

  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private loadingAction = false;

  @observable private editOpen = false;

  @observable private editAddressOpen = false;

  @observable private editUserDataOpen = false;

  @observable private errors: Fetch<api.ToDoErros[]> = null;

  @observable private saveDialogOpen = false;

  @observable private loadingSave = false;

  @observable private comment = "";

  @observable private userAddress: api.AddressInfo | null = null;

  @observable private userData: api.RegisterUser | null = null;

  public componentDidMount() {
    this.getUser();

    this.getUserAddress();
  }

  private getUser = async (loading = true) => {
    const { cpf, isSimpleAccount } = this.props.match.params;
    const { getUserFields, getUserAccounts, getUser } = this.props.usersStore;
    const UserJourneyType = await getUserFields(cpf);

    try {
      this.loading = loading;
      if (isSimpleAccount === "simples" && UserJourneyType?.journeyType === null) {
        await Promise.all([getUserFields(cpf, true), getUserAccounts(cpf)]);
      } else {
        await Promise.all([getUserFields(cpf), getUserAccounts(cpf)]);
      }

      const userResponse = await getUser(cpf);

      this.userData = userResponse;

      this.error = null;
    } catch (err) {
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  };

  private getUserAddress = async () => {
    const { cpf } = this.props.match.params;

    const { getUserInfoByCPF } = this.props.usersStore;

    const user = await getUserInfoByCPF(cpf);

    this.userAddress = user ? user.address : null;
  };

  @action
  private onChangeTab = (tabValue: Tab) => {
    this.selectedTab = tabValue;
  };

  private handleOpenDialog = () => {
    this.editOpen = true;
  };

  @action
  private handleToggleAddressDialog = (open: boolean) => {
    this.editAddressOpen = open;
  };

  private handleToggleEditUserDataDialog = (open: boolean) => {
    this.editUserDataOpen = open;
  };

  @action
  private onAction = async (
    value:
      | "changePass4"
      | "changePass6"
      | "removeToken"
      | "closeAccount"
      | "blockUser"
      | "unblockUser"
      | "updatePartnerId"
      | "changeAddress"
      | "changeUserData",
  ) => {
    const { userFields, sendLinkToRecovery, toggleUserBlock } = this.props.usersStore;
    const actions = {
      changePass4: api.ActionRecoverUser.recoverPassword4,
      changePass6: api.ActionRecoverUser.recoverPassword6,
      removeToken: api.ActionRecoverUser.removeToken,
      closeAccount: api.ActionRecoverUser.removeToken,
    };
    let message: string;

    if (value === "updatePartnerId") {
      this.handleOpenDialog();
      return;
    }

    if (value === "changeAddress") {
      this.handleToggleAddressDialog(true);
      return;
    }

    if (value === "changeUserData") {
      this.handleToggleEditUserDataDialog(true);
      return;
    }

    try {
      this.loadingAction = true;
      if (value === "blockUser") {
        await toggleUserBlock(true, userFields!.workflowId);
        message = "Usuário bloqueado.";
      } else if (value === "closeAccount") {
        this.saveDialogOpen = true;
      } else if (value === "unblockUser") {
        await toggleUserBlock(false, userFields!.workflowId);
        message = "Usuário desbloqueado.";
      } else {
        const result = await sendLinkToRecovery(userFields!.workflowId, actions[value]);

        if (actions[value] === api.ActionRecoverUser.removeToken) {
          message = "Token removido com sucesso.";
        } else {
          message = `Um e-mail foi enviado para ${result} com as instruções para concluir o processo.`;
        }
      }
    } catch (err) {
      message = err.message;
    } finally {
      this.loadingAction = false;

      if (value !== "closeAccount") {
        this.props.generalStore.showToast(message!);
      }

      this.getUser(false);
    }
  };

  @action
  private closeEdit = () => {
    this.editOpen = false;
  };

  private getTypeAccountTitle = () => {
    const { userFields } = this.props.usersStore;

    if (
      userFields &&
      this.props.match.params.isSimpleAccount === "simples" &&
      userFields.journeyType === api.JourneyType.noRecord
    ) {
      return "Conta de Pagamento Simples Sem Pre-cadastro";
    } else if (
      userFields &&
      this.props.match.params.isSimpleAccount === "simples" &&
      userFields.journeyType === api.JourneyType.preApproved
    ) {
      return "Conta de Pagamento Simples Com Pre-cadastro";
    } else if (userFields && this.props.match.params.isSimpleAccount === "simples") {
      return "PF - Conta Simples";
    } else if (userFields) {
      return userFields.accountType;
    }

    return "PJ - Pagamento";
  };

  public render() {
    const { userFields } = this.props.usersStore;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} />;
    }

    return (
      <>
        <Header>
          <TitleBlock>
            <Name>{userFields ? userFields.name : ""}</Name>
            <TypeAccount>{this.getTypeAccountTitle()}</TypeAccount>
          </TitleBlock>
          {this.renderActions()}
          {this.renderPartnerIdDialog()}
          <AddressDialog
            user={this.props.usersStore.userFields}
            isOpen={this.editAddressOpen}
            onClose={() => this.handleToggleAddressDialog(false)}
            reloadPage={this.getUser}
            showToast={this.props.generalStore.showToast}
            userAdress={this.userAddress}
          />
          <EditUserDataDialog
            user={this.userData}
            isOpen={this.editUserDataOpen}
            onClose={() => this.handleToggleEditUserDataDialog(false)}
            reloadPage={this.getUser}
            showToast={this.props.generalStore.showToast}
          />
        </Header>
        <TabNavigator
          tabs={this.tabs()}
          selected={this.selectedTab}
          onChangeTab={(tabValue: any) => this.onChangeTab(tabValue as Tab)}
        />

        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"md"}
          title={`Inserir motivo de encerramento:`}
          isOpen={this.saveDialogOpen}
          onClose={() => (this.saveDialogOpen = false)}
        >
          <StyledTextArea value={this.comment} onChange={e => (this.comment = e.target.value)} />
          <Right>
            <CancelButton onClick={() => (this.saveDialogOpen = false)} label="Cancelar" type="submit" />
            <SaveButton onClick={() => this.getComment()} label="Salvar" type="submit" loading={this.loadingSave} />
          </Right>
        </Dialog>
      </>
    );
  }

  private renderActions = () => {
    if (!this.props.generalStore.hasPermission(api.AdminUserPermission.recoveryUser)) {
      return;
    }

    if (this.loadingAction) {
      return (
        <SelectWrapper>
          <Loader compact />
        </SelectWrapper>
      );
    }

    return (
      <SelectWrapper>
        <Select
          options={this.dropOptions}
          placeholder="Ações"
          value={null}
          onChange={(e: any) => this.onAction(e.value)}
          isDisabled={this.loadingAction}
        />
      </SelectWrapper>
    );
  };

  private renderPartnerIdDialog = () => {
    const { userFields, userAccounts } = this.props.usersStore!;

    if (!userFields || !userAccounts) {
      return;
    }

    return (
      <PartnerID
        isOpen={this.editOpen}
        onClose={this.closeEdit}
        companyId={userFields.workflowId}
        accountType={userAccounts[0].type}
        showToast={this.props.generalStore.showToast}
        reloadPage={this.getUser}
      />
    );
  };

  private renderRegisterData = () => (
    <RegisterData pfPj={api.PfPj.pf} user={this.props.usersStore.userFields} company={null} analyzeCompany={null} />
  );

  private renderNoInfo = () => <InnerAlert message="Não há informações para serem mostradas." />;

  private tabs() {
    const childContent = this.props.usersStore.userFields ? this.renderRegisterData() : this.renderNoInfo();

    return [
      {
        label: "Dados cadastrais",
        value: "registerData",
        child: childContent,
      },
      {
        label: "Compliance",
        value: "compliance",
        child: <Compliance onSelected={this.props.usersStore.getUserComplianceData} />,
      },
      { label: "Contas", value: "accounts", child: <UserAccounts /> },
      { label: "Recuperação de senha", value: "changepass", child: <UserChangePass /> },
    ];
  }

  private getComment = async () => {
    const { userFields, userAccounts } = this.props.usersStore;

    const type = api.PfPj.pf;
    const targetId = userFields!.workflowId;

    this.loadingSave = true;
    let message = "";

    const targetAccount = userAccounts?.find(account => account.type === "pf");

    if (this.props.usersStore.userAccounts!.length === 1) {
      await api.toggleUserBlock(true, userFields!.workflowId);
      await api
        .closeAccount(targetAccount!.account, targetAccount!.branch, this.comment, targetId, type)
        .then(result => {
          message = result;
        });
      // Await api.toggleUserBlock(false, userFields!.workflowId);
    } else {
      await api
        .closeAccount(targetAccount!.account, targetAccount!.branch, this.comment, targetId, type)
        .then(result => {
          message = result;
        });
    }

    this.saveDialogOpen = false;
    this.loadingSave = false;
    this.comment = "";
    this.props.generalStore.showToast(message!);
  };

  private get dropOptions() {
    const { userFields } = this.props.usersStore;

    if (!userFields) {
      return [];
    }

    return [
      ...dropOptions,
      ...(this.props.generalStore.hasPermission(api.AdminUserPermission.updateRegisterInfo)
        ? [
            {
              value: "changeUserData",
              label: "Alterar dados cadastrais",
            },
            {
              value: "changeAddress",
              label: "Alterar endereço vinculado",
            },
          ]
        : []),
      ...partnerIdValue,
      ...(userFields!.blocked
        ? [
            {
              value: "unblockUser",
              label: "Desbloquear usuário",
            },
          ]
        : [
            {
              value: "blockUser",
              label: "Bloquear usuário",
            },
          ]),
    ];
  }
}

const dropOptions = [
  {
    value: "changePass4",
    label: "Enviar email de recuperação de senha de 4 dígitos",
  },
  {
    value: "changePass6",
    label: "Enviar email de recuperação de senha de 6 dígitos",
  },
  {
    value: "removeToken",
    label: "Remover token",
  },
  {
    value: "closeAccount",
    label: "Encerrar Conta Pessoa Física",
  },
];
