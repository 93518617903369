import React from "react";
import { RegisterField } from "api/partner-api";
import { ApprovationType } from "api/manager-api";

export function getFieldTextByField(field: RegisterField) {
  switch (field) {
    case RegisterField.name:
      return "Nome";
    case RegisterField.email:
      return "Email";
    case RegisterField.phone:
      return "Telefone";
    case RegisterField.socialName:
      return "Nome Social";
    case RegisterField.gender:
      return "Gênero";
    case RegisterField.filiacao1:
      return "Filiação 1";
    case RegisterField.filiacao2:
      return "Filiação 2";
    case RegisterField.nacionality:
      return "Nacionalidade";
    case RegisterField.address:
      return "Endereço";
    case RegisterField.commercialAddress:
      return "Endereço Comercial";
    case RegisterField.patrimony:
      return "Patrimônio";
    case RegisterField.monthlyIncome:
      return "Renda";
    case RegisterField.occupation:
      return "Ocupação";
    case RegisterField.naturalness:
      return "Naturalidade";
    case RegisterField.birthDate:
      return "Data de nascimento";
    case RegisterField.civilStatus:
      return "Estado civil";
    case RegisterField.spouseName:
      return "Nome do cônjuge";
    case RegisterField.weddingType:
      return "Regime de casamento";
    case RegisterField.accountInfo:
      return "Conta alternativa";
    case RegisterField.document:
      return "Documento";
    case RegisterField.selfie:
      return "Prova de vida (selfie)";
    case RegisterField.proofOfAddress:
      return "Comprovante de endereço";
    default:
      return "";
  }
}

export const prettifyApprovation = (approvation: ApprovationType | null) => {
  switch (approvation) {
    case ApprovationType.manual:
      return "Manual";
    case ApprovationType.automatic:
      return "Automática";
    case ApprovationType.unknown:
      return "Não especificada";
    default:
      return "-";
  }
};

export const Approvation = (props: { approvation: ApprovationType | null }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>{prettifyApprovation(props.approvation)}</div>
);
