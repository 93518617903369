import React, { useEffect } from "react";
import moment, { Moment } from "moment";
import classnames from "classnames";
import Picker from "rc-picker";

import "rc-picker/assets/index.css";
import { ReactComponent as CalendarIcon } from "assets/svgs/calendar.svg";
import { ReactComponent as Arrow } from "assets/svgs/calendar-arrow.svg";

import { defaultProps } from "./defaultProps";

import s from "./style.scss";

interface IProps {
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  value?: Date | Moment;
  error?: boolean;
  format?: string | string[];
  onChange?: (val: Moment | null) => void;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
}

export function DatePicker({ className, value, onChange, placeholder, error, ...othersProps }: IProps) {
  const [valueInput, setValueInput] = React.useState<Moment | null>(null);

  useEffect(() => {
    if (value) {
      setValueInput(moment(value));
    }
  }, [value]);

  function handleChange(newValue: Moment | null) {
    if (onChange) {
      onChange(newValue);
    } else {
      setValueInput(newValue);
    }
  }

  return (
    <Picker<Moment>
      {...defaultProps}
      {...othersProps}
      picker="date"
      suffixIcon={<CalendarIcon />}
      prevIcon={<Arrow />}
      nextIcon={<Arrow className={s.rotate} />}
      placeholder={placeholder || "Selecione uma data"}
      className={classnames(s.DatePickerContainer, className, error && s.error)}
      dropdownClassName={s.dropDown}
      value={valueInput}
      onChange={handleChange}
    />
  );
}
