/* eslint-disable sort-keys */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { action, observable, reaction } from "mobx";
import { AccountCompany, Permission, Profile } from "api/manager-api";
import { TextInput } from "legacy/components/TextInput";
import { currencyFormatter, currencyParser, intergerMask } from "utils";

import { LegalEntityStore } from "../../../../stores";
import { PermissionsManager } from "../PermissionsManager";
import { Select } from "../../../../components/Select";
import { Label, Line, Paragraph, Row, Title, TitleWrapper } from "../components";

interface IProps {
  legalEntityStore?: LegalEntityStore;
  account: AccountCompany | undefined;
  onChange: (account: AccountCompany) => void;
}

@inject("legalEntityStore")
@observer
export class UserPermissions extends React.Component<IProps> {
  @observable private maxAmountDaily: string | null = null;

  constructor(props: IProps) {
    super(props);
    reaction(() => this.maxAmountDaily, this.reactionAmount);

    if (props.account) {
      const { maxAmountDaily } = props.account;

      this.maxAmountDaily = maxAmountDaily ? currencyFormatter(maxAmountDaily) : null;
    }
  }

  private reactionAmount = () => {
    this.props.onChange({
      ...this.props.account!,
      maxAmountDaily: this.maxAmountDaily ? parseInt(intergerMask(this.maxAmountDaily), 10) / 100 : null,
    });
  };

  private handleProfile = ({ value }: { value: Profile }) => {
    this.props.onChange({
      ...this.props.account!,
      profile: { ...value },
      permissions: [...value.permissions],
    });
  };

  @action
  private handleAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    this.maxAmountDaily = currencyParser(value) ? currencyFormatter(value) : null;
  };

  private handlePermissions = (permissions: Permission[]) => {
    this.props.onChange({
      ...this.props.account!,
      permissions,
      profile: {
        ...this.props.account!.profile,
        id: "",
        name: "",
      },
    });
  };

  public render() {
    const { account } = this.props;

    if (account) {
      return (
        <>
          <Line />
          <Row>{this.renderProfile()}</Row>
          <Line />
          <Row style={{ marginBottom: "0px" }}>
            <Title>Permissões individuais</Title>
          </Row>
          <Row>
            <Label>Limite diário total</Label>
            <TextInput placeholder="Ilimitado" value={this.maxAmountDaily || ""} onChange={this.handleAmount} />
          </Row>
          <Line />
          <PermissionsManager data={account.permissions} onChange={this.handlePermissions} />
        </>
      );
    }

    return null;
  }

  private renderProfile = () => {
    const { account, legalEntityStore } = this.props;
    const options = legalEntityStore!.profiles
      .map(profile => ({
        label: profile.name,
        value: profile,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
    const value = options.find(option => option.value.id === account!.profile.id) || null;

    return (
      <>
        <TitleWrapper>
          <Title>Atribuir perfil</Title>
          <Paragraph>
            Quando atribuído um perfil, o usuário terá as permissões desse perfil mais as concedidas individualmente.
          </Paragraph>
        </TitleWrapper>
        <StyledSelect placeholder="Personalizado" value={value} options={options} onChange={this.handleProfile} />
      </>
    );
  };
}

export const StyledSelect = styled(Select)`
  min-width: 180px;
`;
