import * as React from "react";
import { colors } from "utils";
import { TimeInput } from "../TimeInput";
import { observer } from "mobx-react";
import moment from "moment";
import { DatePeriod } from "api/manager-api";
import { Checkbox } from "../Checkbox";
import { observable, action, computed } from "mobx";
import { noop } from "rxjs";
import styled from "styled-components";

interface IProps {
  label: string;
  disabled?: boolean;
  onChange: (value: DatePeriod | null) => void;
  onValidate?: (valid: boolean) => void;
  value: DatePeriod | null;
}

@observer
export class TimeManager extends React.Component<IProps, {}> {
  @observable public isValid: boolean = this.validate(this.props.value);

  private validate(value: DatePeriod | null) {
    if (value) {
      try {
        const { start, end } = value;

        if (this.is24h) {
          return true;
        }

        const startTime = moment(start || undefined, "HH:mm");
        const endTime = moment(end || undefined, "HH:mm");

        if (!startTime.isValid() || !endTime.isValid()) {
          return false;
        }

        return startTime.isBefore(endTime);
      } catch (err) {
        return false;
      }
    }
    return true;
  }

  @computed
  private get is24h() {
    if (this.props.value) {
      const { start, end } = this.props.value;
      return start === null && end === null;
    }
    return false;
  }

  @action
  private handleChange = (type: "start" | "end") => (value: string) => {
    const { onValidate } = this.props;
    let newValue: DatePeriod | null = {
      ...this.props.value!,
      [type]: value === "" ? null : value,
    };

    if (newValue.start === null && newValue.end === null) {
      newValue = null;
    }

    (onValidate || noop)((this.isValid = this.validate(newValue)));
    this.props.onChange(newValue);
  };

  private handle24h = (event: React.ChangeEvent<HTMLInputElement>) => {
    (this.props.onValidate || noop)((this.isValid = true));
    this.props.onChange(
      event.currentTarget.checked
        ? {
            start: null,
            end: null,
          }
        : {
            start: "07:00",
            end: "17:00",
          },
    );
  };

  public render() {
    const { label, value, disabled } = this.props;
    return (
      <Container>
        <Title>{label}</Title>
        <Row>
          <Block>
            <Text>Início</Text>
            <WrapperTimeInput
              disabled={disabled}
              onChange={this.handleChange("start")}
              value={(value && value.start) || ""}
              isError={!this.isValid}
              placeholder="00:00"
            />
          </Block>
          <Block>
            <Text>Fim</Text>
            <WrapperTimeInput
              onChange={this.handleChange("end")}
              disabled={disabled}
              value={(value && value.end) || ""}
              isError={!this.isValid}
              placeholder={value ? "23:59" : "00:00"}
            />
          </Block>
          <Block>
            <Checkbox disabled={disabled} checked={this.is24h} handleChange={this.handle24h} />
            <Label>24h</Label>
          </Block>
        </Row>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 25px;
  }
`;

const Text = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  margin-right: 4px;
`;

const WrapperTimeInput = styled(TimeInput)<{ isError?: boolean }>`
  ${props => (props.isError ? `border: solid 1px ${colors.red};` : "")};
`;

const LastBlock = styled.div`
  margin-left: -24px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 25px;
  }
`;

const Label = styled.span`
  margin-left: -10px;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
  margin-right: 4px;
`;
