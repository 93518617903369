/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { observer } from "mobx-react";
import { action, observable } from "mobx";

import { ErrorsStore, GeneralStore } from "../../../stores";
import { ErrorEditDialog } from "../ErrorEditDialog";
import { connect, ConnectedComponent } from "../../../utils";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

interface IStores {
  generalStore: GeneralStore;
  errorsStore: ErrorsStore;
}

@connect("generalStore", "errorsStore")
@observer
export class ErrorEditAction extends ConnectedComponent<IProps, IStores> {
  @observable private loading = false;

  @action
  private handleSubmit = async (message: string) => {
    const { currentError, setErrorMessage } = this.stores.errorsStore;
    const { showToast } = this.stores.generalStore;

    try {
      this.loading = true;

      await setErrorMessage(currentError!.id, message);
      this.props.onClose();
      this.props.refetch();

      showToast("Mensagem personalizada com sucesso.");
    } catch (err) {
      showToast(err.message || "Ocorreu um erro ao salvar a mensagem.");
    } finally {
      this.loading = false;
    }
  };

  public render() {
    const { currentError } = this.stores.errorsStore;

    if (!currentError || !this.props.isOpen) {
      return null;
    }

    return (
      <ErrorEditDialog
        error={currentError}
        loading={this.loading}
        onClose={this.props.onClose}
        onSubmit={this.handleSubmit}
      />
    );
  }
}
