exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__tabOverflow__Npznu{width:100%;min-height:100%;overflow:hidden;position:relative}.style__tabOverflow__Npznu .style__tabList__DXHAg{position:relative}.style__tabOverflow__Npznu .style__tabList__DXHAg::after{content:\"\";position:absolute;width:100%;height:1px;background:#ebebeb;left:0;bottom:1px;z-index:1}.style__tabOverflow__Npznu .style__tabList__DXHAg .style__borderSelected__Xqfw-{position:absolute;bottom:0px;left:0px;width:30px;height:3px;background-color:#ed2980;border-radius:1.5px;transition:all ease .3s;z-index:3}.style__tabOverflow__Npznu .style__tabList__DXHAg .style__tab__mwNwT{background:transparent;padding:12px;margin:0 8px;border:0;outline:0;transition:all ease-in-out .3s;cursor:pointer;font-size:14px;font-weight:bold;line-height:17px;color:#a6a6a6}.style__tabOverflow__Npznu .style__tabList__DXHAg .style__tab__mwNwT:hover{color:#515251}.style__tabOverflow__Npznu .style__tabList__DXHAg .style__tab__mwNwT:first-child{margin-left:0}.style__tabOverflow__Npznu .style__tabList__DXHAg .style__tab__mwNwT.style__tab_active__23q2p{color:#515251}.style__tabOverflow__Npznu .style__tab_disabled__1w3K4{cursor:not-allowed !important}.style__tabOverflow__Npznu .style__tab_disabled__1w3K4:hover{color:#a6a6a6 !important}.style__tabOverflow__Npznu .style__tab_disabled__1w3K4.style__tab_active__23q2p{color:#a6a6a6 !important}.style__tabOverflow__Npznu .style__tabsContainer__l0IY7{position:relative;transition:all ease .7s;display:flex;width:100%}", ""]);

// Exports
exports.locals = {
	"tabOverflow": "style__tabOverflow__Npznu",
	"tabList": "style__tabList__DXHAg",
	"borderSelected": "style__borderSelected__Xqfw-",
	"tab": "style__tab__mwNwT",
	"tab_active": "style__tab_active__23q2p",
	"tab_disabled": "style__tab_disabled__1w3K4",
	"tabsContainer": "style__tabsContainer__l0IY7"
};