/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from "react";

import { Button, Container, Content, PlayButton } from "./styled";

interface IProps {
  title: string;
  children: React.ReactNode;
}

export function MenuCollapse(props: IProps) {
  const [isOpened, setIsOpened] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const content = useRef<HTMLDivElement | null>(null);

  function toggleOpened() {
    setIsOpened(state => !state);

    if (content.current) {
      setContentHeight(content.current.offsetHeight);
    }
  }

  useEffect(() => {
    const hasChildActive = content!.current?.querySelector(".active");

    if (hasChildActive && isOpened) {
      toggleOpened();
    }
  }, []);

  return (
    <Container>
      <Button isOpened={isOpened} onClick={toggleOpened}>
        <div>{props.title}</div>
        <PlayButton size={10} />
      </Button>
      <Content isOpened={isOpened} offsetHeight={contentHeight}>
        <div ref={content}>{props.children}</div>
      </Content>
    </Container>
  );
}
