import styled from "styled-components";
import { RaisedButton } from "legacy/components/RaisedButton";

import { colors } from "../../../../../../utils";
import { TextInput } from "../../../../components/TextInput";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { Select } from "../../../../../manager/components/Select";

export const WrapperTable = styled.div`
  height: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`;

export const Label = styled.span`
  display: flex;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
  margin-top: 16px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  min-height: 500px;
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`;

export const ErrorText = styled.span`
  font-family: Lato;
  font-size: 16px;
  color: ${colors.violetRed};
  margin-bottom: 12px;
`;

export const FieldDateRangePicker = styled.div`
  flex: 1 0;
  padding-top: 9px;
  margin-bottom: 24px;

  .DateInput {
    width: 90px;
    height: 38px;
    margin-left: 10px;
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 400px;
  margin: 12px 0 10px 0;
`;

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 10px 20px 0 0;
`;

export const StyledRaisedButton = styled(RaisedButton)`
  width: 180px;
  height: 40px;
  align-self: center;
  margin: 12px 20px 0 0;
`;

export const PartnerSelect = styled(Select)`
  margin: 16px 0 26px 0;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darkGray};
  width: 180px;
`;

export const FilterWrapper = styled.div`
  margin-top: 28px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const FilterContainer = styled.div`
  margin-right: 24px;
`;
