import styled from "styled-components";

import { colors } from "../../../../../../utils";
import { TextInput } from "../../../../components/TextInput";
import { TextAreaInput } from "../../../../components/TextAreaInput";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { RaisedButton } from "../../../../../../legacy/components/RaisedButton";
import { Select } from "../../../../components/Select";

export const Right = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const FilterTitle = styled.span`
  color: ${colors.gray};
  font-size: 14px;
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`;

export const ErrorText = styled.span`
  font-family: Lato;
  font-size: 16px;
  color: ${colors.violetRed};
  margin-bottom: 12px;
`;

export const Title = styled.span`
  color: ${colors.black};
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
`;

export const Text = styled.span`
  color: #515251;
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0px;
`;

export const Label = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: ${colors.gray};
`;

export const Subtitle = styled.span`
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.violetRed};
  margin: 30px 0 10px 0;
`;

export const Input = styled.input`
  max-width: 395px;
  height: 40px;
  outline: none;
  border-radius: 4px;
  background-color: ${colors.white};
  border: solid 1px ${colors.borderGray};
  padding: 10px;
  color: ${colors.black};
  font-family: lato;
  text-align: center;

  &:disabled {
    background-color: ${colors.borderGray};
  }
`;

export const Link = styled.button`
  font-size: 14px;
  color: ${colors.violetRed};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-right: 32px;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 362px;
  margin: 32px 20px 10px 0;
`;

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 160px;
  height: 40px;
  font-size: 14px;
  align-self: center;
  margin-right: 20px;
`;

export const StyledRaisedButton = styled(RaisedButton)`
  width: 192px;
  height: 40px;
  font-size: 14px;
  align-self: center;
  margin-right: 20px;
`;

export const StyledTextArea = styled(TextAreaInput)`
  margin-top: 24px;
  height: 109px;
`;

export const PartnerSelect = styled(Select)`
  margin: 16px 0 26px 0;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darkGray};
  width: 180px;
`;

export const FilterWrapper = styled.div`
  margin-top: 28px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const FilterContainer = styled.div`
  margin-right: 24px;
`;
