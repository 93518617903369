import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { colors } from "utils";

import { OutlinedButton } from "../../../../components/OutlinedButton";
import { DatePicker } from "../../../../components/DatePicker";

interface IProps {
  disabled?: boolean;
  data: moment.Moment | null;
  onChangeData: (data: moment.Moment | null) => void;
  obs: string;
  onChangeObs: (obs: string) => void;
  onSubmit: () => void;
  isLoading: boolean;
}

interface IState {
  errors: Array<"data" | "obs">;
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 25px;
  }
`;

const Title = styled.h5`
  margin: 0 0 8px 0;
  padding: 0;
  color: ${colors.black};
  font-family: Lato;
  font-size: 14px;
`;

const Input = styled.input<{ isError?: boolean; disabled?: boolean }>`
  max-width: 205px;
  width: 100%;
  border-radius: 4px;
  background-color: ${props => (props.disabled ? colors.paleGrey : colors.white)};
  border: solid 1px ${({ isError }) => (isError ? colors.red : colors.platinum)};
  padding: 10px;
  color: ${colors.black};
  font-family: lato;
`;

const WrapperButton = styled.div`
  margin-top: 20px;
  width: 155px;
`;

export class DateWithObservationPicker extends React.Component<IProps, IState> {
  public state: IState = {
    errors: [],
  };

  private onSubmit = () => {
    const errors: Array<"data" | "obs"> = [];

    if (!this.props.data) {
      errors.push("data");
    }

    if (this.props.obs.length === 0) {
      errors.push("obs");
    }

    this.setState({ errors });
    if (errors.length === 0) {
      this.props.onSubmit();
    }
  };

  public render() {
    return (
      <Container>
        <Block>
          <Title>Data*</Title>
          <DatePicker
            onChange={data => this.props.onChangeData(data)}
            selected={this.props.data}
            disabled={this.props.disabled}
            isError={Boolean(this.state.errors.find(e => e === "data"))}
            minDate={moment()}
          />
        </Block>
        <Block>
          <Title>Observação*</Title>
          <Input
            value={this.props.obs}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.onChangeObs(e.target.value)}
            maxLength={60}
            disabled={this.props.disabled}
            isError={Boolean(this.state.errors.find(e => e === "obs"))}
          />
        </Block>
        <Block>
          <WrapperButton>
            <OutlinedButton
              label="Adicionar"
              disabled={this.props.disabled}
              onClick={this.onSubmit}
              loading={this.props.isLoading}
            />
          </WrapperButton>
        </Block>
      </Container>
    );
  }
}
