exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__title__zvgch{font-weight:bold;font-size:18px;line-height:22px;color:#515251}.style__header__1p22r{margin:32px 0 24px}.style__loadingContent__2apKa{display:block;width:100%;height:300px}.style__errorContainer__392x0{display:flex;flex-direction:column;justify-content:center;align-items:center;min-height:250px}.style__errorMessage__19L_H{font-size:16px;color:#a6a6a6;margin-bottom:16px}", ""]);

// Exports
exports.locals = {
	"title": "style__title__zvgch",
	"header": "style__header__1p22r",
	"loadingContent": "style__loadingContent__2apKa",
	"errorContainer": "style__errorContainer__392x0",
	"errorMessage": "style__errorMessage__19L_H"
};