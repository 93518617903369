import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

import { ExclamationIcon } from "../../components/Icons/exclamation";

export function Page404() {
  return (
    <Container>
      <Content>
        <ExclamationIcon />
        <Title>Página não encontrada</Title>
        <Description>A página que você está procurando não existe ou você não tem permissão.</Description>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 320px;
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.gray};
  text-align: center;
  letter-spacing: 0.7px;
  margin: 32px 0 12px;
`;

const Description = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${colors.gray};
  text-align: center;
  letter-spacing: 0.7px;
`;
