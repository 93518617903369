exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".styles__sectionTitle__3FQcO{margin:24px 0 24px;font-weight:600;font-size:16px;line-height:19px;color:#515251}.styles__loaderContainer__1CSIi{height:400px}.styles__selfTitle__3OKpw{margin:32px 0 24px;font-weight:600;font-size:14px;line-height:17px;color:#515251}.styles__selfContainer__2f3XA{display:flex;padding-left:28px;position:relative}.styles__selfContainer__2f3XA:before{content:\"\";position:absolute;background:#e3e4e8;border-radius:2px;width:4px;height:100%;left:0;top:0}.styles__selfContainer__2f3XA .styles__image__5aMxL{border-radius:8px;width:144px;min-width:144px;height:110px;margin-right:48px}.styles__selfRightInfo__1Lo5E{display:flex;flex-wrap:wrap}.styles__selfInfo__p4Qjw{margin:0 40px 24px 0}.styles__selfInfo__p4Qjw h4{margin:0 0 12px 0;font-size:14px;line-height:17px;color:#a6a6a6}.styles__selfInfo__p4Qjw p{margin:0;font-size:14px;line-height:17px;color:#515251}", ""]);

// Exports
exports.locals = {
	"sectionTitle": "styles__sectionTitle__3FQcO",
	"loaderContainer": "styles__loaderContainer__1CSIi",
	"selfTitle": "styles__selfTitle__3OKpw",
	"selfContainer": "styles__selfContainer__2f3XA",
	"image": "styles__image__5aMxL",
	"selfRightInfo": "styles__selfRightInfo__1Lo5E",
	"selfInfo": "styles__selfInfo__p4Qjw"
};