import * as React from "react";
import styled from "styled-components";
import { SvgRightArrow } from "../../assets/icons/SvgRightArrow";
import { TextInput } from "legacy/components/TextInput";
import { colors } from "../../utils";

export type NumberRange = IValue;

interface IValue {
  start: number | null;
  end: number | null;
}

interface IProps extends IValue {
  onChange: (range: NumberRange) => void;
  placeholderStart?: string;
  placeholderEnd?: string;
}

export const RangeInput: React.FunctionComponent<IProps> = ({
  start,
  end,
  onChange,
  placeholderStart,
  placeholderEnd,
}) => {
  const handleChange = (key: keyof NumberRange) => (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange({
      start,
      end,
      [key]: numberify(event.target.value),
    });

  return (
    <Wrapper>
      <Input value={stringify(start)} onChange={handleChange("start")} placeholder={placeholderStart || "Inicial"} />
      <SvgRightArrow color={colors.gray} width="7px" height="12px" />
      <Input value={stringify(end)} onChange={handleChange("end")} placeholder={placeholderEnd || "Final"} />
    </Wrapper>
  );
};

const stringify = (num: number | null) => (num === null ? "" : String(num));
const numberify = (str: string) => {
  const stripped = str.replace(/[^\d]/g, "");
  return stripped ? Number(stripped) : null;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.lightPink};
  border-radius: 4px;
  overflow: hidden;

  & > div {
    display: block;
    flex: 1 0 0;
  }
`;

const Input = styled(TextInput)`
  border: 0;
  width: 100%;

  & + div {
    display: none;
  }
`;
