exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__container__8Q1Cw{display:flex;flex-direction:column}.style__textarea__1Ye9K{background-color:transparent;border:1px solid #dcdce0;border-radius:3px;border:1px solid #dcdce0;color:#000;font-family:Lato;padding:10px 0 10px 10px;width:100%;max-width:100%;outline:none;white-space:pre-wrap}.style__textarea__1Ye9K:disabled{background-color:#f0f0f5}.style__textarea__1Ye9K::placeholder{font-size:10pt;color:#8c8c8f}.style__textarea__1Ye9K:focus{border-color:#ed2980}.style__resize__2Z8g0{resize:none}.style__errorMessage__2LXhF{border:1px solid #e74c3c}.style__errorTextWrapper__3VAOI{margin-top:5px !important;margin-left:3px !important;color:#e74c3c;font-size:14px;font-weight:500}", ""]);

// Exports
exports.locals = {
	"container": "style__container__8Q1Cw",
	"textarea": "style__textarea__1Ye9K",
	"resize": "style__resize__2Z8g0",
	"errorMessage": "style__errorMessage__2LXhF",
	"errorTextWrapper": "style__errorTextWrapper__3VAOI"
};