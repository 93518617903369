import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogContentText } from "@material-ui/core";

import { RaisedButton } from "../RaisedButton";

interface IProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
}

export const Alert: React.SFC<IProps> = props => (
  <Dialog open={props.isOpen} onClose={props.onClose} data-testid="AlertContainer">
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{props.text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <RaisedButton color="secundary" onClick={props.onClose} label="Ok" />
    </DialogActions>
  </Dialog>
);
