import styled from "styled-components";

export const ButtonBackToPage = styled.h1`
  min-height: 41px;
  border: 1px solid #dcdce0;
  padding: 0 24px;
  transition: all ease-in-out 0.3s;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #525051;

  &:not(:disabled):hover {
    color: #ed2980;
    border-color: #ed2980;
  }

  &:not(:disabled):active {
    color: #d42572;
    border-color: #d42572;
  }

  &:disabled {
    background: #f0f0f5;
    color: #b4b4b8;
    cursor: not-allowed;
  }
`;

export const LabelDefault = styled.span`
  color: #a6a6a6;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  vertical-align: super;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ListModal = styled.ul`
  color: #a6a6a6;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  vertical-align: super;
  padding: 0;
  &:first-child {
    margin-right: 300px;
  }
  li {
    list-style: none;
    margin-bottom: 16px;
    &:last-child {
      margin: 0;
    }
    span {
      font-family: Lato;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      display: block;

      &:first-child {
        font-weight: bold;
        color: #515251;
      }

      &:last-child {
        font-weight: normal;
        color: #8c8c8f;
      }
    }
  }
`;
