/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { TextInput } from "legacy/components/TextInput";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import * as api from "api/manager-api";
import { useStores } from "@manager/stores/RootStore";
import { CircularProgress } from "@material-ui/core";
import { SvgArrowRepeat } from "legacy/icons/SvgArrowRepeat";
import { formatDate, maskValidateAccount } from "@manager/utils";
import { mask } from "utils";
import { InnerAlert } from "legacy/components";
import { async } from "rxjs/internal/scheduler/async";

import { Loader } from "../../components/Loader";
import { Table } from "../../components/Table";
import {
  BackButtonWrapper,
  Box,
  ClearOutlinedButton,
  ContentTable,
  Header,
  InputFile,
  LabelInputFile,
  Line,
  Row,
  Spacing,
  StyledSelect,
  Title,
  TitleContainer,
  TitleInputModal,
} from "../../assets/styles/global";
import { Dialog } from "../../components/Dialog";
import { ErrorMessage } from "../TransactionalBoundaries/GeneralParameters/DefaultLimit/style";

interface IPartner {
  label: string;
  value: string;
}
interface IControlTable {
  offset: number;
  limit: number;
  page: number;
}

interface IErrors {
  field: string;
  message: string;
}
export const initialStateTable: IControlTable = {
  offset: 0,
  limit: 10,
  page: 0,
};

export const AuthorizedDebit = observer(() => {
  const { generalStore, partnerStore, authStore, routerStore } = useStores();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [convenantName, setConvenantName] = useState("");
  const [partnerAccount, setPartnerAccouunt] = useState("");
  const [partner, setPartner] = useState({
    label: "",
    value: "",
  });
  const [fileInputName, setFileInputName] = useState("");
  const [fileSelected, setFileSelected] = useState<Buffer | null>(null);
  const [results, setResults] = useState<api.GroupChargeFile[]>([]);
  const [filesList, setFilesList] = useState<api.GroupChargeFile[]>([]);
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [partnerOption, setPartnerOption] = useState<IPartner[]>([]);
  const [filterPartner, setFilterPartner] = useState({
    value: "all",
    label: "Todos",
  });
  const [formErrors, setFormErrors] = useState<IErrors[]>([]);
  const [controlTable, setControlTable] = useState<IControlTable>(initialStateTable);

  useEffect(() => {
    getPartnersActive();
    getAllFiles();
  }, []);

  async function getPartnersActive() {
    try {
      setLoading(true);
      const partners = await partnerStore.getPartners();
      const list: IPartner[] = [
        {
          label: "Todos",
          value: "all",
        },
      ];

      partners.forEach(p => {
        list.push({
          label: p.name,
          value: p.id,
        });
      });

      setPartnerOption(list);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setLoading(false);
    }
  }

  function onCloseTipping() {
    setDialogOpen(false);
  }

  function onShowTipping() {
    setDialogOpen(true);
  }

  function onClickRow(id: string) {
    routerStore.push(`debito-autorizado/${id}/detalhes`);
  }

  async function getAllFiles() {
    const files = await api.getGroupChargeFiles();

    setFilesList(files);

    if (filterPartner.value === "all") {
      setResults(files);
    } else {
      const filteredFiles = files.filter(file => file.partnerId === filterPartner.value);

      setResults(filteredFiles);
    }
  }

  function handleClearFilter() {
    setFilterPartner({
      label: "Todos",
      value: "all",
    });
    setResults(filesList);
  }

  async function handleUploadAuthorizedDebitFile() {
    setLoading(true);

    const account = partnerAccount.replace(/\D/gu, "");

    if (validateForm().length) {
      setLoading(false);
      return;
    }

    try {
      await api.createGroupChargeFile({
        convenantName,
        description,
        file: fileSelected!,
        partnerAccount: account,
        partnerId: partner.value,
        userId: authStore.adminUser!.id!,
      });

      generalStore.showToast("Arquivo criado com sucesso");
      getAllFiles();
    } catch (err) {
      generalStore.showToast("Ocorreu um erro ao gravar o arquivo");
    }

    setLoading(false);
    setDialogOpen(false);
    clearForm();
  }

  function onChangeFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    const file = files ? files[0] : null;
    const name = file ? file.name : "";
    const type = file && file.type !== "" ? file.type : "xlsx";

    setFileInputName(name);

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (reader.result && typeof reader.result !== "string") {
          const fileBuffer = Buffer.from(reader.result);

          setFileSelected(fileBuffer);
          setFileInputName(name);
          setFileType(type);
        }
      });

      reader.readAsArrayBuffer(file);
    }
  }

  function clearForm() {
    setPartner({
      label: "",
      value: "",
    });
    setDescription("");
    setFileSelected(null);
    setConvenantName("");
    setPartnerAccouunt("");
    setFileInputName("");
  }

  function validateForm() {
    const errors = [];

    if (description.length < 3) {
      errors.push({
        field: "description",
        message: "Descrição é um campo obrigatório",
      });
    }

    if (convenantName.length < 3) {
      errors.push({
        field: "convenantName",
        message: "Convênio é um campo obrigatório",
      });
    }

    if (partnerAccount.length < 10) {
      errors.push({
        field: "partnerAccount",
        message: "Conta do parceiro é um campo obrigatório.",
      });
    }

    if (!partner.value.length) {
      errors.push({
        field: "partner",
        message: "Parceiro é um campo obrigatório.",
      });
    }

    setFormErrors(errors);

    return errors;
  }

  function getErrors(fieldName: string) {
    const errors = formErrors.filter(e => e.field === fieldName);

    if (errors.length) {
      return {
        error: true,
        message: errors[0].message,
      };
    }

    return {
      error: false,
      message: null,
    };
  }

  function renderDialog() {
    return (
      <Dialog showCloseButton title={"Adicionar"} isOpen={dialogOpen} onClose={onCloseTipping} padding="0 20px 20px">
        <Spacing />
        <div>
          <TitleInputModal>Parceiro</TitleInputModal>
          <Spacing />
          <StyledSelect
            onChange={(e: HTMLOptionElement) => {
              setPartner({
                label: e.label,
                value: e.value,
              });
            }}
            placeholder="Escolha o parceiro"
            value={partner}
            options={partnerOption.filter(el => el.value !== "all")}
          />
          {getErrors("partner").error && <ErrorMessage>{getErrors("partner").message}</ErrorMessage>}
        </div>
        <Spacing />
        <TitleInputModal>Descrição</TitleInputModal>
        <TextInput
          error={getErrors("description").error}
          errorText={getErrors("description").message || ""}
          name="description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          maxLength={30}
        />
        <TitleInputModal>Convênio</TitleInputModal>
        <TextInput
          error={getErrors("convenantName").error}
          errorText={getErrors("convenantName").message || ""}
          name="convenantName"
          value={convenantName}
          onChange={e => setConvenantName(e.target.value)}
          maxLength={30}
        />
        <TitleInputModal>Conta do parceiro</TitleInputModal>
        <TextInput
          error={getErrors("partnerAccount").error}
          errorText={getErrors("partnerAccount").message || ""}
          name="partnerAccount"
          value={partnerAccount}
          mask={maskValidateAccount}
          onChange={e => setPartnerAccouunt(e.target.value)}
          maxLength={30}
        />
        <TitleInputModal>Upload da planilha (.xlsx)</TitleInputModal>
        <LabelInputFile htmlFor="fileSelect">
          <span>Upload</span>
          {fileInputName ? fileInputName : "Selecione arquivo..."}
        </LabelInputFile>
        <InputFile type="file" id="fileSelect" name="fileTipping" onChange={e => onChangeFile(e)} accept=".xlsx" />

        <Spacing />

        <BackButtonWrapper
          label={loading ? <CircularProgress size={15} style={{ color: "red" }} /> : "Salvar"}
          onClick={handleUploadAuthorizedDebitFile}
        />
      </Dialog>
    );
  }

  function handleFilterResultsByPartner(e: HTMLOptionElement) {
    setLoading(true);
    setFilterPartner({
      value: e.value,
      label: e.label,
    });

    if (e.value === "all") {
      setResults(filesList);
    } else {
      const files = filesList.filter(file => file.partnerId === e.value);

      setResults(files);
    }

    setLoading(false);
  }

  function renderPartnerFilter() {
    return (
      <div>
        <TitleInputModal>Filtrar por parceiro</TitleInputModal>
        <Spacing />
        <StyledSelect
          onChange={(e: HTMLOptionElement) => {
            handleFilterResultsByPartner(e);
          }}
          placeholder="Filtrar por parceiro"
          value={filterPartner}
          options={partnerOption}
        />
      </div>
    );
  }

  function renderAdd() {
    return (
      <BackButtonWrapper
        label="Adicionar"
        onClick={onShowTipping}
        disabled={!generalStore.hasPermission(api.AdminUserPermission.createBatchChargeDebit)}
      />
    );
  }

  function renderClear() {
    return (
      <ClearOutlinedButton
        label="Limpar filtro"
        onClick={handleClearFilter}
        iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
      />
    );
  }

  function getPartnerName(partnerId: string) {
    const currentPartner = partnerOption.find(p => p.value === partnerId);

    if (!currentPartner) {
      return "";
    }

    return currentPartner.label;
  }

  function prettierStatus(status: string) {
    switch (status) {
      case "waiting":
        return "Aguardando";
      case "processing":
        return "Em processamento";
      case "valid":
        return "Válido";
      case "created":
        return "Criado";
      default:
        return "Status desconhecido";
    }
  }

  function renderTableData() {
    return results.map((file, index) => {
      if (index >= controlTable.offset && index < controlTable.limit * (controlTable.page + 1)) {
        return [
          formatDate(file.createdAt),
          file.description,
          file.convenantName,
          getPartnerName(file.partnerId),
          mask(file.partnerAccount, "#########-#"),
          prettierStatus(file.status),
          file.totalProcessedItems,
          file.totalItems,
        ];
      }

      return [];
    });
  }

  function handleChangePage(page: number) {
    setControlTable({ ...controlTable, offset: controlTable.limit * page, page });
  }

  function getTable() {
    const columns = [
      "Data Criação",
      "Descrição",
      "Convênio",
      "Parceiro",
      "Conta crédito",
      "Status",
      "Items processados",
      "Total de registros",
    ];

    return (
      <>
        <Table
          tdStyles={{
            minWidth: "60px",
          }}
          customPadding="4px 20px 4px 20px"
          titles={columns}
          ids={results.map(file => file.id)}
          data={renderTableData()}
          onClickRow={onClickRow}
          isPagination
          totalRows={results.length}
          rowsPerPage={controlTable.limit}
          rowsPerPageOptions={[controlTable.limit]}
          page={controlTable.offset / controlTable.limit}
          handleChangePage={handleChangePage}
        />
      </>
    );
  }

  function renderData() {
    if (loading) {
      return <Loader />;
    }

    if (results.length === 0) {
      return (
        <InnerAlert
          message="Nenhum dado para mostrar"
          buttonLabel="Buscar novamente"
          buttonClick={async () => {
            setLoading(true);
            await getAllFiles();
            setLoading(false);
          }}
        />
      );
    }

    return getTable();
  }

  return (
    <div>
      <Header>
        <TitleContainer>
          <Title>Débito autorizado</Title>
        </TitleContainer>
        {renderAdd()}
        {renderDialog()}
      </Header>
      <Box>
        <Row>
          {renderPartnerFilter()}
          {renderClear()}
        </Row>
        <Spacing />
        <Line />
        <Spacing />
      </Box>
      <ContentTable>{renderData()}</ContentTable>
    </div>
  );
});
