import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";

interface IProps {
  children: React.ReactNode;
}

export const PageTitle = ({ children }: IProps) => {
  return <Title>{children}</Title>;
};

export const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
`;
