import * as React from "react";
import styled from "styled-components";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import { RaisedButton } from "legacy/components/RaisedButton";
import { TextInput } from "legacy/components/TextInput";
import { colors } from "utils";

import { maskValidateCpf, validateCpf } from "../../../utils";

export interface IFields {
  cpf: string;
  password: string;
}

interface IProps {
  onSubmit: (values: IFields) => void;
  isLoading: boolean;
}

export class FormLogin extends React.Component<IProps> {
  private InnerForm = (props: FormikProps<IFields>) => {
    return (
      <Form onSubmit={props.handleSubmit}>
        <Title>Entrar</Title>
        <Content>
          <WrapperInput>
            <TextInput
              name="cpf"
              value={props.values.cpf}
              onChange={props.handleChange}
              mask={maskValidateCpf}
              label="CPF"
              error={Boolean(props.errors.cpf)}
              errorText={props.errors.cpf}
            />
            <Spacing />
            <TextInput
              name="password"
              value={props.values.password}
              onChange={props.handleChange}
              isSecret
              label="Senha"
              error={Boolean(props.errors.password)}
              errorText={props.errors.password}
            />
          </WrapperInput>
          <WrapperButton>
            <StyledRaisedButton type="submit" color="primary" label="Entrar" loading={this.props.isLoading} />
          </WrapperButton>
        </Content>
      </Form>
    );
  };

  public render() {
    return (
      <Formik<IFields>
        render={this.InnerForm}
        onSubmit={(values: IFields) => this.props.onSubmit(values)}
        initialValues={{
          cpf: "",
          password: "",
        }}
        validationSchema={yup.object<IFields>().shape({
          cpf: yup
            .string()
            .required("Campo obrigatório")
            .test("is-cpf", "CPF inválido", value => (value ? validateCpf(value) : false)),
          password: yup.string().required("Campo obrigatório"),
        })}
        validateOnBlur={false}
        validateOnChange={false}
      />
    );
  }
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Title = styled.h3`
  font-family: Lato;
  font-size: 20px;
  color: ${colors.black};
  text-align: center;
  font-weight: 300;
  line-height: 1.6;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 312px;
  width: 100%;
  height: 100%;
`;

const WrapperInput = styled.div`
  width: 100%;
`;

const Spacing = styled.div`
  padding-bottom: 20px;
`;

const WrapperButton = styled.div`
  margin-top: 25px;
`;

const StyledRaisedButton = styled(RaisedButton)`
  width: 320px;
  height: 50px;
`;
