import * as React from "react";

export const StatusRefused = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="#C0392B" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M17.282 5.495C16.332 3.28 14.72 1.67 12.489.719A8.842 8.842 0 0 0 8.985 0c-1.22 0-2.38.24-3.493.719-2.213.95-3.833 2.558-4.783 4.776A8.767 8.767 0 0 0 0 8.99c0 1.221.232 2.395.709 3.506.95 2.217 2.57 3.836 4.783 4.785A8.751 8.751 0 0 0 8.985 18c1.22 0 2.389-.243 3.504-.719 2.23-.952 3.843-2.572 4.793-4.785A8.813 8.813 0 0 0 18 8.99c0-1.22-.24-2.38-.718-3.495zm-1.437 7.473a7.898 7.898 0 0 1-2.894 2.886 7.748 7.748 0 0 1-3.966 1.063 7.618 7.618 0 0 1-3.07-.63c-.975-.424-1.816-.98-2.52-1.684-.703-.704-1.264-1.554-1.682-2.53a7.756 7.756 0 0 1-.62-3.083c0-1.428.35-2.75 1.053-3.958a7.902 7.902 0 0 1 2.883-2.885 7.688 7.688 0 0 1 3.956-1.064c1.427 0 2.755.357 3.966 1.064a7.922 7.922 0 0 1 2.894 2.885 7.7 7.7 0 0 1 1.062 3.958 7.774 7.774 0 0 1-1.062 3.978z"
      />
      <path d="M6.37 6.357a1.06 1.06 0 0 1 1.503 0l3.757 3.776a1.07 1.07 0 0 1 0 1.51 1.06 1.06 0 0 1-1.503 0L6.37 7.867a1.07 1.07 0 0 1 0-1.51z" />
      <path d="M11.63 6.357a1.06 1.06 0 0 0-1.503 0L6.37 10.133a1.07 1.07 0 0 0 0 1.51 1.06 1.06 0 0 0 1.503 0l3.757-3.776a1.07 1.07 0 0 0 0-1.51z" />
    </g>
  </svg>
);

export const StatusAproved = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="#27AE60" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M17.282 5.495C16.332 3.28 14.72 1.67 12.489.719A8.842 8.842 0 0 0 8.985 0c-1.22 0-2.38.24-3.493.719-2.213.95-3.833 2.558-4.783 4.776A8.767 8.767 0 0 0 0 8.99c0 1.221.232 2.395.709 3.506.95 2.217 2.57 3.836 4.783 4.785A8.751 8.751 0 0 0 8.985 18c1.22 0 2.389-.243 3.504-.719 2.23-.952 3.843-2.572 4.793-4.785A8.813 8.813 0 0 0 18 8.99c0-1.22-.24-2.38-.718-3.495zm-1.437 7.473a7.898 7.898 0 0 1-2.894 2.886 7.748 7.748 0 0 1-3.966 1.063 7.618 7.618 0 0 1-3.07-.63c-.975-.424-1.816-.98-2.52-1.684-.703-.704-1.264-1.554-1.682-2.53a7.756 7.756 0 0 1-.62-3.083c0-1.428.35-2.75 1.053-3.958a7.902 7.902 0 0 1 2.883-2.885 7.688 7.688 0 0 1 3.956-1.064c1.427 0 2.755.357 3.966 1.064a7.922 7.922 0 0 1 2.894 2.885 7.7 7.7 0 0 1 1.062 3.958 7.774 7.774 0 0 1-1.062 3.978z"
      />
      <path d="M13.072 6.293a.999.999 0 0 1 0 1.414l-3.888 3.888a1.503 1.503 0 0 1-2.124 0L5.293 9.828a1 1 0 0 1 1.413-1.414l.706.706a1.004 1.004 0 0 0 1.419 0l2.827-2.827a.999.999 0 0 1 1.414 0z" />
    </g>
  </svg>
);

export const StatusPending = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="#A6A6A6" fillRule="nonzero">
      <path d="M17.282 5.495C16.332 3.28 14.72 1.67 12.489.719A8.842 8.842 0 0 0 8.985 0c-1.22 0-2.38.24-3.493.719-2.213.95-3.833 2.558-4.783 4.776A8.767 8.767 0 0 0 0 8.99c0 1.221.232 2.395.709 3.506.95 2.217 2.57 3.836 4.783 4.785A8.751 8.751 0 0 0 8.985 18c1.22 0 2.389-.243 3.504-.719 2.23-.952 3.843-2.572 4.793-4.785A8.813 8.813 0 0 0 18 8.99c0-1.22-.24-2.38-.718-3.495zm-1.437 7.473a7.898 7.898 0 0 1-2.894 2.886 7.748 7.748 0 0 1-3.966 1.063 7.618 7.618 0 0 1-3.07-.63c-.975-.424-1.816-.98-2.52-1.684-.703-.704-1.264-1.554-1.682-2.53a7.756 7.756 0 0 1-.62-3.083c0-1.428.35-2.75 1.053-3.958a7.902 7.902 0 0 1 2.883-2.885 7.688 7.688 0 0 1 3.956-1.064c1.427 0 2.755.357 3.966 1.064a7.922 7.922 0 0 1 2.894 2.885 7.7 7.7 0 0 1 1.062 3.958 7.774 7.774 0 0 1-1.062 3.978z" />
      <path d="M9.77 8.979V3.884a.565.565 0 0 0-.566-.55.552.552 0 0 0-.537.55v5.173c0 .01.02.05.02.079-.02.177.029.324.146.443l2.92 2.94a.552.552 0 0 0 .75 0 .533.533 0 0 0 0-.767L9.77 8.979z" />
    </g>
  </svg>
);
