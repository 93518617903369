import * as React from "react";
import {
  PartnerName,
  PfPj,
  FilterFields,
  CategoryAccount,
  UserStatus,
  Gender,
  ApprovationType,
  SubType,
  JourneyType,
  FilterFieldsByPartner,
} from "api/manager-api";
import { SelectSoccer } from "../../../apps/soccer/components/Select";
import compose from "ramda/es/compose";
import map from "ramda/es/map";
import find from "ramda/es/find";
import styled from "styled-components";
import { ValueType } from "react-select";
import propEq from "ramda/es/propEq";
import prop from "ramda/es/prop";

import { RaisedButton } from "../RaisedButton";
import { Select } from "../../../apps/manager/components/Select";
import { OutlinedButton } from "../../components/OutlinedButton";
import { colors } from "../../../utils";
const { useState } = React;

interface IProps {
  partners?: PartnerName[];
  onSubmit: (filter: FilterFieldsByPartner) => void;
  lastFilter?: FilterFieldsByPartner;
  isSoccer?: boolean;
}

interface IOption<T> {
  value: T;
  label: string;
}

type OptionList<T> = Array<IOption<T>>;
type Optionifier<T> = (array: T[]) => OptionList<T>;
type MultiFilters = Pick<
  FilterFieldsByPartner,
  | "idPartner"
  | "partnerName"
  | "type"
  | "userName"
  | "documentNumber"
  | "birthDate"
  | "email"
  | "phone"
  | "address"
  | "affiliation1"
  | "affiliation2"
  | "monthlyIncome"
>;

export const OpeningActiveAccountsReportFilter: React.FunctionComponent<IProps> = ({
  partners,
  onSubmit,
  lastFilter,
  isSoccer,
}) => {
  // State
  const [state, setState] = useState<FilterFieldsByPartner>(skeletonByPartner);
  const handleOk = () => onSubmit(state);

  React.useEffect(() => setState(lastFilter ? lastFilter : state), []);

  // Mixins
  function multiSelectMixin<T>(key: keyof MultiFilters) {
    const list = state[key] as T[] | null;

    return (options: OptionList<T>) => ({
      isMulti: true,
      options,
      value: list
        ? map<T, IOption<T>>(
          value => ({
            label: find<IOption<T>>(propEq("value", value))(options)!.label,
            value,
          }),
          list,
        )
        : [],
      onChange: (input: ValueType<IOption<T>>) => {
        const values = (input as any) as Array<IOption<T>>;

        setState({
          ...state,
          [key]: values.length ? map(prop("value"), values) : null,
        });
      },
    });
  }

  return (
    <Wrapper>
      {typeof partners !== "undefined" ? (
        <Field>
          {isSoccer ? (
            <SelectSoccer
              placeholder="Parceiros"
              {...compose(multiSelectMixin<PartnerName>("idPartner"), byProp<PartnerName>("name"))(partners)}
            />
          ) : (
              <Select
                placeholder="Parceiros"
                {...compose(multiSelectMixin<PartnerName>("idPartner"), byProp<PartnerName>("name"))(partners)}
              />
            )}
        </Field>
      ) : null}

      <CustomBorder />
      <Center>
        <StyledRaisedButton label="Buscar" onClick={handleOk} customColor={isSoccer ? colors.darkGreen : undefined} />
      </Center>
    </Wrapper>
  );
};

export const skeletonByPartner: FilterFieldsByPartner = {
  idPartner: null,
  partnerName: null,
  type: null,
  userName: null,
  documentNumber: null,
  account: null,
  birthDate: null,
  email: null,
  phone: null,
  address: null,
  affiliation1: null,
  affiliation2: null,
  monthlyIncome: null,
};

function byProp<T>(key: keyof T): Optionifier<T> {
  return map((item: any) => ({
    value: item.id,
    label: item[key],
  }));
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: -8px;
`;

const Title = styled.span`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
`;

const Field = styled.div`
  flex: 1 0 170px;
  padding: 8px;
  max-width: 182px;
`;

const RangeInputField = styled.div`
  padding: 8px;
  width: 130px;
`;

const FieldDateRangePicker = styled.div`
  flex: 1 0 160px;
  padding: 8px;

  .DateInput {
    width: 90px;
    height: 40px;
    margin-left: 10px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const StyledRaisedButton = styled(RaisedButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  width: 120px;
  height: 40px;
  margin-right: 10px;
`;

const StyledOutlinedButton = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

const CustomBorder = styled.span`
  border-top: 1px solid ${colors.lightGray};
  width: 100%;
  margin: 24px 0px 32px 0px;
`;

const SecondRowContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  margin-top: 10px;
`;
