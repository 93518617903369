import * as React from "react";
import styled from "styled-components";
import { colors } from "utils";
import { SvgFile } from "../Icons/SvgFile";
import { SvgClose } from "../Icons/SvgClose";

interface IProps {
  name: string;
  downloadable?: boolean;
  removable?: boolean;
  onClick?: () => void;
  onRemoveClick?: () => void;
  style?: React.CSSProperties;
}

export const FileAttachment: React.SFC<IProps> = props => {
  return (
    <MessageAttach style={props.style} onClick={props.onClick} hasClick={!!props.onClick}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SvgFile color={colors.gray} size={20} />
        <MessageAttachText downloadable={props.downloadable}>{props.name}</MessageAttachText>
      </div>
      {props.removable && <Remove onClick={props.onRemoveClick} />}
    </MessageAttach>
  );
};

const MessageAttach = styled.div<{ hasClick: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${colors.borderGray};
  width: 50%;
  flex-wrap: wrap;
  ${({ hasClick }) => (hasClick ? "cursor: pointer;" : "")}
`;

const MessageAttachText = styled.p<{ downloadable?: boolean }>`
  display: block;
  padding: 0;
  margin: 0 0 0 10px;
  font-family: Lato;
  font-size: 14px;
  color: ${({ downloadable }) => (downloadable ? colors.blue : colors.black)};
`;

const Remove = styled(SvgClose)`
  margin-left: 10px;
  cursor: pointer;
`;
