import * as React from "react";
import { observer, inject } from "mobx-react";
import { AuthStore } from "../../stores";
import { loginPath, dashboardPath } from "../../utils";
import { Redirect } from "react-router-dom";

const redirectIfAuthEquals = (shouldBeSigned: boolean, redirectedPath: string) => (
  Comp: React.ComponentType,
  closeBrowser?: boolean,
) => {
  const RequireAuthHOC = inject("authStore")(
    observer(({ authStore: { adminUser } }: { authStore: AuthStore }) => {
      if (shouldBeSigned === !!adminUser) {
        return <Comp />;
      } else if (closeBrowser) {
        window.close();
        return null;
      } else {
        return <Redirect to={`${redirectedPath}?path=${window.location.pathname}`} />;
      }
    }),
  );

  return RequireAuthHOC;
};

export const requireAuth = redirectIfAuthEquals(true, loginPath);
export const requireNotAuth = redirectIfAuthEquals(
  false,
  window.location.pathname === loginPath ? dashboardPath : window.location.pathname,
);
