import styled from "styled-components";
import { colors } from "utils";
import { OutlinedButton } from "legacy/components/OutlinedButton";

export const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 4px 0;
`;

export const Label = styled.label`
  font-size: 16px;
  color: ${colors.gray};
  font-family: Lato;
`;

export const HeaderWrapper = styled.header`
  padding: 12px 0;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

export const Title = styled.h1`
  font-family: Lato;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
`;

export const Button = styled(OutlinedButton)`
  margin: 0px 10px;
  width: 160px;
  height: 40px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
