/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import * as api from "api/manager-api";
import { action, computed, observable, toJS } from "mobx";
import { colors } from "utils";

import { Switch } from "../../../components/Switch";
import { GeneralStore, PlatformManagerStore } from "../../../stores";
import { ToggleEditMode } from "../../../components/ToggleEditMode";
import { Loader } from "../../../components/Loader";
import { InnerAlert } from "../../../components/InnerAlert";

interface IProps {
  platformManagerStore?: PlatformManagerStore;
  generalStore?: GeneralStore;
}

@inject("platformManagerStore", "generalStore")
@observer
export class Services extends React.Component<IProps> {
  @observable private editableMode = false;

  @observable private oldServices: api.ServiceV13[] | null = null;

  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private loadingEditing = false;

  public componentDidMount() {
    this.getData();
  }

  @action
  private getData = async () => {
    try {
      this.loading = true;
      await this.props.platformManagerStore!.getServices();
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter os serviços.";
    } finally {
      this.loading = false;
    }
  };

  @action
  private enterEditing = () => {
    this.oldServices = toJS(this.props.platformManagerStore!.services);
    this.editableMode = true;
  };

  @action
  private cancelEditing = () => {
    this.props.platformManagerStore!.services = toJS(this.oldServices!);
    this.editableMode = false;
  };

  @action
  private disabledAll = () => {
    this.props.platformManagerStore!.services!.forEach(service => (service.blocked = true));
  };

  @action
  private enabledAll = () => {
    this.props.platformManagerStore!.services!.forEach(service => (service.blocked = false));
  };

  @computed
  private get isAllDisabled() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.props.platformManagerStore!.services!
      ? this.props.platformManagerStore!.services!.every(service => service.blocked === true)
      : false;
  }

  @action
  private handleToggle = (index: number) => () => {
    const service = this.props.platformManagerStore!.services![index];

    service.blocked = !service.blocked;
  };

  @action
  private save = async () => {
    try {
      this.loadingEditing = true;
      await this.props.platformManagerStore!.setServices(this.props.platformManagerStore!.services!);
      this.editableMode = false;
    } catch (err) {
      this.props.generalStore!.showToast(err.message || "Ocorreu um erro ao editar os serviços.");
    } finally {
      this.loadingEditing = false;
    }
  };

  public render() {
    return (
      <Container>
        {this.renderEdit()}
        {this.renderData()}
      </Container>
    );
  }

  private renderEdit = () => {
    if (!this.props.generalStore!.hasPermission(api.AdminUserPermission.editServiceHours)) {
      return null;
    }

    return (
      <TopRow>
        <ToggleEditMode
          editableMode={this.editableMode}
          onChangeToEditableMode={this.enterEditing}
          onCancel={this.cancelEditing}
          onSave={this.save}
          isLoading={this.loadingEditing}
        />
        <DisableAllButton
          disabled={!this.editableMode || this.loadingEditing}
          editableMode={this.editableMode}
          onClick={this.isAllDisabled ? this.enabledAll : this.disabledAll}
        >
          {this.isAllDisabled ? "Ativar Todos" : "Desativar Todos"}
        </DisableAllButton>
      </TopRow>
    );
  };

  private renderData = () => {
    const { services, getServices } = this.props.platformManagerStore!;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return <InnerAlert message={this.error} buttonLabel="Tentar novamente" buttonClick={getServices} />;
    }

    if (!services || !services.length) {
      return (
        <InnerAlert
          message="Não existem serviços para serem listados no momento."
          buttonLabel="Buscar novamente"
          buttonClick={getServices}
        />
      );
    }

    return (
      <>
        <Table>{services.map(this.renderService)}</Table>
      </>
    );
  };

  private renderService = (service: api.ServiceV13, index: number) => {
    return (
      <Item key={service.key}>
        <Label>{service.value}</Label>
        <Switch
          disabled={!this.editableMode || this.loading}
          checked={!service.blocked}
          handleChange={this.handleToggle(index)}
        />
      </Item>
    );
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
`;

const DisableAllButton = styled.button<{ editableMode: boolean }>`
  border: 1px solid rgba(227, 228, 230, 0.5);
  background-color: ${colors.lightGray};
  border-radius: 4px;
  cursor: ${props => (props.editableMode ? "pointer" : "default")};
  font-family: Lato;
  font-weight: 600;
  color: ${colors.black};
  font-size: 14px;
  opacity: ${props => (props.editableMode ? 1 : 0.5)};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Item = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.borderGray};
  }
`;

const Label = styled.span`
  display: flex;
  flex: 1;
  font-family: Lato;
  font-size: 14px;
  color: ${colors.black};
`;
