import React from "react";

import { ReactComponent as Success } from "@assets/svgs/status-success.svg";
import { ReactComponent as Neutral } from "@assets/svgs/status-neutral.svg";
import { ReactComponent as Error } from "@assets/svgs/status-error.svg";

import { ILabelStatus } from ".";

export function statusLabelIcon(status: ILabelStatus | undefined) {
  if (!status) {
    return null;
  }

  const allStatus = {
    negative: <Error />,
    neutral: <Neutral />,
    positive: <Success />,
  };

  return allStatus[status];
}
