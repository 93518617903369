exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".style__footer__3mp1-{display:flex;justify-content:flex-end;align-items:center;border-top:1px solid #ebebeb;width:calc(100% + 48px);padding:24px 24px 0;margin:48px 0 0 -24px}.style__footer__3mp1->button:not(:last-child){margin-right:16px}.style__checkContainer__3ZlFu{display:flex}.style__checkContainer__3ZlFu>label:not(:first-child){margin-left:8px}.style__formWrapper__2L35m{margin-top:24px}.style__title__1-hIX{font-size:14px;font-weight:600;line-height:17px;color:#515251;margin-bottom:16px}.style__titleMargin__3nyVN{font-size:14px;font-weight:600;line-height:17px;color:#515251;margin-top:16px;margin-bottom:16px}.style__formGroup__VvHtk{display:flex;align-items:flex-start}.style__formGroup__VvHtk>div:not(:last-child){margin-right:16px}.style__formGroupFeatures__t30by{display:flex;flex-wrap:wrap;align-items:flex-start}.style__formGroupFeatures__t30by>div{width:calc(50% - 8px)}.style__formGroupFeatures__t30by>div:not(:nth-child(2n+2)){margin-right:16px}.style__inputFile__2UfvI{display:none}.style__textAreaContainer__1-kfe{width:100%}.style__textAreaContainer__1-kfe>div{width:100%}.style__textAreaContainer__1-kfe .style__textArea__27zwE{min-width:732px;max-width:732px;min-height:131px;max-height:200px}.style__spanInfo__2a1Nc{color:#a6a6a6;font-size:12px;margin:8px 0}", ""]);

// Exports
exports.locals = {
	"footer": "style__footer__3mp1-",
	"checkContainer": "style__checkContainer__3ZlFu",
	"formWrapper": "style__formWrapper__2L35m",
	"title": "style__title__1-hIX",
	"titleMargin": "style__titleMargin__3nyVN",
	"formGroup": "style__formGroup__VvHtk",
	"formGroupFeatures": "style__formGroupFeatures__t30by",
	"inputFile": "style__inputFile__2UfvI",
	"textAreaContainer": "style__textAreaContainer__1-kfe",
	"textArea": "style__textArea__27zwE",
	"spanInfo": "style__spanInfo__2a1Nc"
};