import * as React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

import { colors } from "../../../utils";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  color?: "primary" | "secundary";
  customColor?: colors;
  textColor?: colors;
  loading?: boolean;
  loadingColor?: colors;
  lowerCase?: boolean;
  removeDisabledOpacity?: boolean;
  width?: string;
  height?: string;
}

const Container = styled.button<{
  color: "primary" | "secundary";
  customColor?: string;
  textColor?: string;
  lowerCase?: boolean;
  removeDisabledOpacity?: boolean;
  width?: string;
  height?: string;
}>`
  padding: 10px 31px;
  background-color: ${props =>
    props.customColor ? props.customColor : props.color === "primary" ? colors.violetRed : colors.darkBlue};
  border: solid 1px ${colors.borderGray};
  border-radius: 4px;
  color: ${props => (props.textColor ? props.textColor : colors.white)};
  cursor: pointer;
  width: ${props => (props.width ? props.width : "114px")};
  height: ${props => (props.height ? props.height : "40px")};
  text-align: "center";

  &:disabled {
    cursor: default;
    ${props => !props.removeDisabledOpacity && "opacity: 0.6;"}
  }
`;

export class RaisedButton extends React.Component<IProps> {
  public render() {
    const { label, color, disabled, loading, ...allProps } = this.props;
    // Tslint:disable-next-line:prefer-const
    const { customColor, textColor, loadingColor } = this.props;

    return (
      <Container
        color={color || "primary"}
        customColor={customColor}
        textColor={textColor}
        disabled={disabled || loading}
        {...allProps}
      >
        {loading ? (
          <CircularProgress
            size={15}
            thickness={7}
            style={{
              color: loadingColor || colors.white,
            }}
          />
        ) : (
          this.props.children || this.props.label
        )}
      </Container>
    );
  }
}
