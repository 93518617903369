import * as React from "react";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { AdminUserPermission, PfPj, Segment } from "api/manager-api";
import { InnerAlert } from "legacy/components";
import { SvgArrowRepeat } from "legacy/icons/SvgArrowRepeat";
import { Loader } from "legacy/components/Loader/index";
import { SvgConfirm } from "legacy/icons/SvgConfirm";
import { SvgError } from "legacy/icons/SvgError";
import { SvgEdit } from "legacy/icons/SvgEdit";
import { SvgDelete } from "legacy/icons/SvgDelete";
import { currencyFormatter } from "utils";

import {
  GeneralStore,
  IPartnerIdLimit,
  IPersonTypes,
  PartnerStore,
  TransactionalBoundariesStore,
} from "../../../../stores";
import { Dialog } from "../../../../components/Dialog";
import { SegmentWizard } from "./SegmentWizard";
import { Table } from "../../../../components/Table";
import {
  AlertModal,
  Container,
  ContainerActions,
  ContainerTable,
  ErrorText,
  FilterContainer,
  FilterWrapper,
  Label,
  Left,
  Link,
  PartnerSelect,
  PersonSelect,
  Placeholder,
  StyledOutlinedButton,
  StyledRaisedButton,
  StyledTextArea,
  StyledTextInput,
  Text,
  Title,
  WrapperTable,
} from "./style";

interface IProps {
  generalStore?: GeneralStore;
  partnerStore?: PartnerStore;
  transactionalBoundariesStore?: TransactionalBoundariesStore;
}

interface IOption<T> {
  value: T;
  label: string;
}

interface ISearch {
  query: string;
  personType: PfPj | null;
}

interface IPagination {
  page: number;
  offset: number;
  limit: number;
}

type OptionList<T> = Array<IOption<T>>;

@inject("transactionalBoundariesStore", "generalStore", "partnerStore")
@observer
export class Segments extends React.Component<IProps> {
  @observable private loading = false;

  @observable private error: string | null = null;

  @observable private errorMsg: string | null = null;

  @observable private currentSegment: Segment | null = null;

  @observable private dialogOpen = false;

  @observable private deleteDialogOpen = false;

  @observable private successOpen = false;

  @observable private errorOpen = false;

  @observable private observation = "";

  @observable private personType: PfPj | null = null;

  @observable private query = "";

  @observable private search: ISearch = {
    personType: null,
    query: "",
  };

  @observable private pagination: IPagination = {
    limit: 5,
    offset: 0,
    page: 1,
  };

  @observable private partners: IPartnerIdLimit[] = [];

  @observable private selectedPartner: string | null = null;

  private segmentId: number | null = null;

  private personTypes: OptionList<PfPj | null> = [
    {
      label: "Selecione o Tipo de Pessoa",
      value: null,
    },
    {
      label: "PF - Pessoa Física",
      value: PfPj.pf,
    },
    {
      label: "PJ - Pessoa Jurídica",
      value: PfPj.pj,
    },
  ];

  public componentDidMount() {
    this.getPartners();
    this.getData();
  }

  private getData = async () => {
    this.personType = this.search.personType;
    this.query = this.search.query;

    try {
      this.loading = true;
      await this.props.transactionalBoundariesStore!.getSegmentTransactional(
        this.selectedPartner,
        this.search.personType,
        this.search.query,
        this.pagination,
      );
      this.error = null;
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter os serviços.";
    } finally {
      this.loading = false;
    }
  };

  @action
  private getPartners = async () => {
    try {
      this.loading = true;
      await this.props.partnerStore!.getPartners();
      this.partners = this.props.partnerStore!.partners!.map(partner => ({ label: partner.name, value: partner.id }));
      this.error = "";
    } catch (err) {
      this.error = err.message || "Ocorreu um erro ao obter os serviços.";
    } finally {
      this.loading = false;
    }
  };

  private getPage = async () => {
    this.loading = true;

    try {
      await this.props.transactionalBoundariesStore!.getSegmentTransactional(
        this.selectedPartner,
        this.personType,
        this.query,
        this.pagination,
      );
    } catch {
      this.errorOpen = true;
    }

    this.search = { personType: this.personType, query: this.query };
    this.loading = false;
  };

  @action
  private handleSearch = async () => {
    this.pagination = { limit: 5, offset: 0, page: 1 };
    this.getData();
  };

  @action
  private handleClear = () => {
    this.search.query = "";
    this.search.personType = null;
  };

  @action
  private handleCreate = () => {
    this.segmentId = null;
    this.dialogOpen = true;
  };

  @action
  private handleEdit = (id: number | null, segment: Segment) => {
    this.segmentId = id;
    this.dialogOpen = true;
    this.currentSegment = segment;
  };

  @action
  private handleDelete = (segment: Segment) => {
    this.currentSegment = segment;
    this.deleteDialogOpen = true;
  };

  @action
  private deleteSegment = async () => {
    try {
      await this.props.transactionalBoundariesStore!.deleteSegmentTransactional(
        this.currentSegment!.id!,
        this.observation,
      );
      this.errorMsg = null;
    } catch (err) {
      this.errorMsg = err.message;
      this.deleteDialogOpen = false;
      this.errorOpen = true;
      this.observation = "";
    } finally {
      this.deleteDialogOpen = false;
      this.observation = "";
      this.getData();
    }
  };

  @action
  private successDialog = () => {
    this.getData();
    this.successOpen = true;
  };

  @action
  private errorDialog = () => {
    this.errorOpen = true;
  };

  @action
  private handleChangePage = (page: number) => {
    this.pagination.offset = this.pagination.limit * page;
    this.pagination.page = page;
    this.getPage();
  };

  private renderData = () => {
    const { getSegmentTransactional } = this.props.transactionalBoundariesStore!;
    const { segments } = this.props.transactionalBoundariesStore!.segmentReturn!;
    const { hasPermission } = this.props.generalStore!;

    if (this.loading) {
      return <Loader />;
    }

    if (this.error) {
      return (
        <InnerAlert
          message={this.error}
          buttonLabel="Tentar novamente"
          buttonClick={async () => {
            getSegmentTransactional(this.selectedPartner, this.search.personType, this.search.query, this.pagination);
          }}
        />
      );
    }

    const columns = ["Nome do segmento", "Tipo de Pessoa", "Renda/Faturamento Mensal", "Ações"];

    return (
      <WrapperTable>
        {segments.length > 0 ? (
          <Table
            titles={columns}
            ids={segments.map((e: any) => e.id)}
            isPagination
            totalRows={this.props.transactionalBoundariesStore!.segmentReturn.count}
            rowsPerPage={this.pagination.limit}
            rowsPerPageOptions={[5, 10, 15, 20]}
            page={this.pagination.offset / this.pagination.limit}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={e => {
              this.pagination.limit = Number(e);
              this.handleChangePage(0);
            }}
            data={segments.map(segment => {
              const data = [
                segment.name,
                segment.accountType === PfPj.pf ? "PF - Pessoa Física" : "PJ - Pessoa Jurídica",
                `R$ ${currencyFormatter(segment.minIncome / 100)} - R$ ${currencyFormatter(segment.maxIncome / 100)}`,
                <Container key={segment.name}>
                  <Link
                    onClick={() => this.handleEdit(segment.id, segment)}
                    type="button"
                    disabled={!hasPermission(AdminUserPermission.editSegments)}
                  >
                    <ContainerActions>
                      <SvgEdit />
                      Editar
                    </ContainerActions>
                  </Link>
                  <Link
                    onClick={() => this.handleDelete(segment)}
                    type="button"
                    disabled={!hasPermission(AdminUserPermission.editSegments)}
                  >
                    <ContainerActions>
                      <SvgDelete />
                      Excluir
                    </ContainerActions>
                  </Link>
                </Container>,
              ];

              return data;
            })}
          />
        ) : (
          <Placeholder>Não existe segmento criado</Placeholder>
        )}
        <StyledRaisedButton
          onClick={this.handleCreate}
          label="Criar Segmento"
          type="button"
          disabled={!hasPermission(AdminUserPermission.addSegments)}
        />
      </WrapperTable>
    );
  };

  public render() {
    return (
      <>
        <FilterWrapper>
          <Title>Filtros de Pesquisa</Title>
          <Left>
            <FilterContainer>
              <Label>Parceiros</Label>
              <PartnerSelect
                isSearchable
                value={this.partners.filter(partner => partner.value === this.selectedPartner)}
                options={[{ label: "Todos os parceiros", value: null }, ...this.partners]}
                onChange={(e: IPartnerIdLimit) => (this.selectedPartner = e.value)}
                placeholder="Todos os parceiros"
              />
            </FilterContainer>
            <FilterContainer>
              <Label>Tipo de Pessoa</Label>
              <PersonSelect
                value={this.personTypes.filter(type => type.value === this.search.personType)}
                options={this.personTypes}
                onChange={(e: IPersonTypes) => {
                  this.search.personType = e.value;
                }}
                placeholder="Selecione o Tipo de Pessoa"
              />
            </FilterContainer>
            <FilterContainer>
              <Label>Segmento</Label>
              <StyledTextInput
                placeholder="Pesquisar o Segmento"
                value={this.search.query}
                onChange={e => (this.search.query = e.target.value)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    this.handleSearch();
                  }
                }}
              />
            </FilterContainer>
            <StyledOutlinedButton
              label="Limpar Filtros"
              onClick={this.handleClear}
              iconComponent={<SvgArrowRepeat width="30px" height="20px" />}
            />
            <StyledRaisedButton onClick={this.handleSearch} label="Buscar" type="submit" />
          </Left>
        </FilterWrapper>
        <Title>Segmentos</Title>
        <ContainerTable>{this.renderData()}</ContainerTable>
        <Dialog
          showCloseButton
          maxWidth={"md"}
          title={this.segmentId ? "Editar Segmento" : "Criar Segmento"}
          isOpen={this.dialogOpen}
          onClose={() => (this.dialogOpen = false)}
        >
          <SegmentWizard
            close={() => (this.dialogOpen = false)}
            selectedPartner={this.selectedPartner}
            success={() => this.successDialog()}
            segmentId={this.segmentId}
            fail={() => this.errorDialog()}
          />
        </Dialog>
        <Dialog
          showCloseButton
          maxWidth={"sm"}
          closeButtonSize={"30px"}
          title=""
          isOpen={this.successOpen}
          onClose={() => (this.successOpen = false)}
        >
          <AlertModal>
            <SvgConfirm
              style={{
                height: "82px",
                margin: "-40px 0 20px 0",
                position: "relative",
                width: "82px",
              }}
            />
            Segmento {this.segmentId ? "editado" : "criado"} com sucesso!
          </AlertModal>
        </Dialog>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"md"}
          title={`Excluir Segmento '${this.currentSegment ? this.currentSegment.name : ""}'?`}
          isOpen={this.deleteDialogOpen}
          onClose={() => {
            this.deleteDialogOpen = false;
            this.observation = "";
          }}
        >
          <Text>Ao excluir esse limite, todos os clientes vinculados a ele serão vinculados ao Limite Padrão.</Text>
          <StyledTextArea
            placeholder="Obs.:"
            onChange={e => (this.observation = e.target.value)}
            value={this.observation}
            maxLength={200}
          />
          <StyledRaisedButton onClick={async () => this.deleteSegment()} label="Excluir" type="submit" />
        </Dialog>
        <Dialog
          showCloseButton
          closeButtonSize={"30px"}
          maxWidth={"sm"}
          title=""
          isOpen={this.errorOpen}
          onClose={() => (this.errorOpen = false)}
        >
          <AlertModal style={{ fontSize: "16px", textAlign: "center" }}>
            <SvgError
              style={{
                height: "82px",
                margin: "-50px 0 12px 0",
                position: "relative",
                width: "82px",
              }}
            />
            <ErrorText>Não foi possível realizar a operação</ErrorText>
            {this.errorMsg}
          </AlertModal>
        </Dialog>
      </>
    );
  }
}
