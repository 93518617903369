/* eslint-disable func-style */
import * as React from "react";
import * as api from "api/manager-api";
import moment from "moment";
import { formatCpf } from "@brazilian-utils/formatters";
import { observer } from "mobx-react";
import { SvgSpreadsheet } from "legacy/icons/SvgSpreadsheet";
import { Select } from "legacy/components/Select";
import save from "save-file";
import { SearchBar } from "@manager/components/SearchBar";
import { maskValidateCpf } from "utils";

import { Button as ButtonComp, DropdownActions } from "@components/";

import { InnerAlert } from "../../components/InnerAlert";
import { Loader } from "../../components/Loader";
import { Table } from "../../components/Table";
import { Button, Container, HeaderWrapper, Row, Title, TotalResults, VerticalFlex, WhiteCell } from "./styled";
import { ActionsDropdown } from "../../components/ActionsDropdown";
import { useStores } from "../../stores/RootStore";
import { pluralFormCheck, prettifyCardStatus, prettifyCardType, prettifyRequestStatus } from "../../../manager/utils";
import { TwoRequestCardDialog } from "./TwoRequestCardDialog";
import { SyncTwoRequestCardDialog } from "./SyncTwoRequestCardDialog";
import { ErrorRequestCardDialog } from "./ErrorRequestCardDialog";
import { RequestCancelCardDialog } from "./RequestCancelCardDialog";

interface IPartnerOptions {
  value: string;
  label: string;
}

interface IFilter {
  limit: number;
  offset: number;
}

export const CardsManager = observer(() => {
  const { cardStore, generalStore, partnerStore, reportStore } = useStores();
  const [twoRequestCardDialog, setTwoRequestCardDialog] = React.useState(false);
  const [requestCancelCardDialog, setRequestCancelCardDialog] = React.useState(false);
  const [syncTwoRequestCardDialog, setSyncTwoRequestCardDialog] = React.useState(false);
  const [errorRequestCardDialog, setErrorRequestCardDialog] = React.useState(false);
  const [errorList, setErrorList] = React.useState<api.QueueError[]>([]);
  const [data, setData] = React.useState<api.CardReport | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [partnerLoading, setPartnerLoading] = React.useState(true);
  const [operationLoading, setOperationLoading] = React.useState(false);
  const [apiError, setApiError] = React.useState("");
  const [partnerId, setPartnerId] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [partnerOptions, setPartnerOptions] = React.useState<IPartnerOptions[]>([]);
  const [filter] = React.useState<IFilter>({
    limit: 10,
    offset: 0,
  });

  const sendDownload = async (buffer: Buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    return save(blob, `relatorio-abertura-de-contas.xlsx`);
  };

  const handleDownloadExcel = async () => {
    setOperationLoading(true);
    try {
      const buffer = await reportStore.getCardFilteredExcel(searchValue, partnerId || null, true);

      setOperationLoading(false);
      await sendDownload(buffer);
    } catch (error) {
      generalStore.showToast(error.message || "Não foi possível gerar a planilha.");
      setOperationLoading(false);
    }
  };

  const getPartnersNames = async () => {
    let options: IPartnerOptions[] = [];

    try {
      await partnerStore.getPartnersNames();
      options = partnerStore.partnersNames
        ? partnerStore.partnersNames.map(partner => ({ label: partner.name, value: partner.id }))
        : [];
      options.unshift({ label: "Listar todos", value: "" });
      setPartnerOptions(options);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setPartnerLoading(false);
    }
  };

  const getCardsInQueuePaginated = async (hasLoading: boolean) => {
    try {
      setLoading(hasLoading);
      setApiError("");

      await cardStore.getCardReport(partnerId || null, searchValue, filter.limit, filter.offset, true);

      setData(cardStore.visaQueueList);
      if (cardStore.visaQueueList) {
        setTotalRows(cardStore.visaQueueList.total);
      }
    } catch (err) {
      setApiError(err.message || "Ocorreu um erro ao carregar a lista de gerenciamento de cartões.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page: number) => {
    filter.offset = filter.limit * page;
    getCardsInQueuePaginated(false);
  };

  const isLoading = () => {
    if (loading || partnerLoading || operationLoading) {
      return true;
    }

    return false;
  };

  React.useEffect(() => {
    getPartnersNames();
  }, []);

  React.useEffect(() => {
    getCardsInQueuePaginated(true);
  }, [partnerId]);

  const resetVisaCardQueueStatus = async (queueId: string) => {
    if (!partnerId) {
      generalStore.showToast("Por favor, selecione o parceiro no select localizado na parte superior da tela.");
      return;
    }

    try {
      setOperationLoading(true);
      await cardStore.resetVisaCardQueueStatus(partnerId, queueId);
      getCardsInQueuePaginated(true);
      generalStore.showToast("Reset realizado com sucesso.");
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setOperationLoading(false);
    }
  };

  const removeVisaCardFromQueue = async (queueId: string) => {
    if (!partnerId) {
      generalStore.showToast("Por favor, selecione o parceiro no select localizado na parte superior da tela.");
      return;
    }

    try {
      setOperationLoading(true);
      await cardStore.removeVisaCardFromQueue(partnerId, queueId);
      getCardsInQueuePaginated(true);
      generalStore.showToast("Remoção realizada com sucesso.");
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setOperationLoading(false);
    }
  };

  const getQueueError = async (queueId: string) => {
    try {
      setErrorRequestCardDialog(true);
      const error = await cardStore.getQueueError(queueId);

      setErrorList(error);
    } catch (err) {
      generalStore.showToast(err.message);
    } finally {
      setOperationLoading(false);
    }
  };

  const handleOnChange = (opt: any) => {
    if (opt && opt.value !== partnerId) {
      setLoading(true);
      setPartnerId(opt.value);
    } else {
      setPartnerId("");
    }
  };

  const handleOnChangeSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const buildActions = (queueId: string) => {
    return [
      { label: "Resetar", onClick: async () => resetVisaCardQueueStatus(queueId) },
      { label: "Remover", onClick: async () => removeVisaCardFromQueue(queueId) },
      { label: "Ver motivo da falha", onClick: async () => getQueueError(queueId) },
    ];
  };

  const renderData = () => {
    if (isLoading()) {
      return <Loader />;
    }

    if (apiError) {
      return (
        <InnerAlert
          message={apiError}
          buttonLabel="Tentar novamente"
          buttonClick={async () => getCardsInQueuePaginated(true)}
        />
      );
    }

    if (!data) {
      return (
        <InnerAlert
          message="Nenhum dado para mostrar"
          buttonLabel="Buscar novamente"
          buttonClick={async () => getCardsInQueuePaginated(true)}
        />
      );
    }

    return (
      <Table
        titles={[
          "Cliente",
          "Conta",
          "CPF",
          "Data",
          "Conta Dock",
          "ID Cartão",
          "Tentativas",
          "Parceiro",
          "Tipo",
          "Status solicitação",
          "Status cartão",
          "Ações  ",
        ]}
        ids={data.result.map(info => info.cardId)}
        data={data.result.map(info => {
          const tableData: React.ReactNode[] = [
            info.name,
            info.account,
            formatCpf(info.cpf),
            moment(info.requestedDate).format("L"),
            info.gatewayAccountId,
            info.gatewayCardId,
            info.attempts,
            info.partnerName,
            prettifyCardType(info.cardType),
            prettifyRequestStatus(info.requestStatus),
            prettifyCardStatus(info.cardStatus),
          ];

          if (info.requestStatus === "failed") {
            tableData.push(<ActionsDropdown key={info.cardId} options={buildActions(info.cardId)} />);
          } else {
            tableData.push(<WhiteCell />);
          }

          return tableData;
        })}
        isPagination
        totalRows={data.total}
        rowsPerPage={filter.limit}
        page={filter.offset / filter.limit}
        rowsPerPageOptions={[filter.limit]}
        handleChangePage={handleChangePage}
        trStyles={[
          {
            boxSizing: "content-box",
            minWidth: "111px",
          },
        ]}
      />
    );
  };

  return (
    <Container>
      <Title>Gestão das solicitações de cartão</Title>
      {!isLoading() && (
        <>
          <HeaderWrapper>
            <Row>
              <SearchBar
                value={searchValue}
                onChange={handleOnChangeSearchValue}
                placeholder="Filtrar por CPF"
                handleSearch={() => {
                  getCardsInQueuePaginated(true);
                }}
                mask={maskValidateCpf}
                enabledWithoutInput
              />
            </Row>
            <Row>
              <Select
                placeholder="Selecione um parceiro..."
                onChange={handleOnChange}
                value={partnerOptions.find(option => option.value === partnerId)}
                options={partnerOptions}
              />
            </Row>

            {generalStore.hasPermission(api.AdminUserPermission.generateReplacementCard) && (
              <DropdownActions
                options={[
                  { label: "Solicitar 2º via de cartão", onClick: () => setTwoRequestCardDialog(true) },
                  { label: "Cancelar via de cartão", onClick: () => setRequestCancelCardDialog(true) },
                  { label: "Vincular 2ª via existente", onClick: () => setSyncTwoRequestCardDialog(true) },
                ]}
              >
                <ButtonComp theme="secondary">Ações</ButtonComp>
              </DropdownActions>
            )}
          </HeaderWrapper>

          <VerticalFlex>
            <TotalResults>
              {totalRows} {pluralFormCheck(totalRows)}
            </TotalResults>
            <Button
              label="Baixar planilha"
              onClick={handleDownloadExcel}
              iconComponent={<SvgSpreadsheet width="50px" height="20px" />}
            />
          </VerticalFlex>
          <SyncTwoRequestCardDialog
            isOpen={syncTwoRequestCardDialog}
            onClose={() => setSyncTwoRequestCardDialog(false)}
            onReload={async () => getCardsInQueuePaginated(true)}
          />
          <RequestCancelCardDialog
            isOpen={requestCancelCardDialog}
            onClose={() => setRequestCancelCardDialog(false)}
            onReload={async () => getCardsInQueuePaginated(true)}
          />
          <TwoRequestCardDialog
            isOpen={twoRequestCardDialog}
            onClose={() => setTwoRequestCardDialog(false)}
            onReload={async () => getCardsInQueuePaginated(true)}
          />
          <ErrorRequestCardDialog
            errorLists={errorList}
            isOpen={errorRequestCardDialog}
            onClose={() => setErrorRequestCardDialog(false)}
          />
        </>
      )}
      {renderData()}
    </Container>
  );
});
