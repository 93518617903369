import React, { useState } from "react";
import * as api from "api/manager-api";
import { FormControlLabel, Radio } from "@material-ui/core";
import { useStores } from "@manager/stores/RootStore";

import {
  BackButtonWrapper,
  DescriptionLabel,
  Line,
  StyleRadioGroup,
  TitleInputModal,
} from "../../../../assets/styles/global";
import { Dialog } from "../../../../components/Dialog";
import { TextInput } from "../../../../components/TextInput";

interface IProps {
  visible: boolean;
  closeModal: () => void;
  clearSelecteds: () => void;
  id: number;
  dataChecked: api.Prospect[];
}

export const ModalCancel = ({ visible, closeModal, id, dataChecked, clearSelecteds }: IProps) => {
  const { generalStore } = useStores();
  const [description, setDescription] = useState("");
  const [sendTo, setSendTo] = useState<string | undefined>();

  const handleCancel = async () => {
    if (!sendTo) {
      generalStore.showToast("Obrigatório selecionar uma das opções.");
      return false;
    }

    if (sendTo === "selecionados" && dataChecked && dataChecked.length <= 0) {
      generalStore.showToast("Obrigatório selecionar pelo menos um item.");
      return false;
    }

    if (!description || description === "") {
      generalStore.showToast("A descrição é obrigatória.");
      return false;
    }

    let status = "cancel";

    if (sendTo === "completo") {
      await api.changeStatusPartnerFile(id, "canceled", description);
      status = "canceled";
    } else {
      if (dataChecked.length === 0) {
        generalStore.showToast("Nenhum item selecionado.");
        return false;
      }

      const arrayIds: number[] = [];

      dataChecked.forEach((item: api.Prospect) => {
        arrayIds.push(item.id);
      });

      await api.changeStatusProspect(id, arrayIds, status, description);
    }

    setSendTo("");
    setDescription("");
    clearSelecteds();
    closeModal();

    return false;
  };

  return (
    <Dialog showCloseButton title={"Cancelar"} isOpen={visible} onClose={closeModal} padding="0 15px 15px">
      <StyleRadioGroup
        name="brand-type"
        value={sendTo}
        onChange={(_, value) => setSendTo(value)}
        style={{ paddingBottom: 15 }}
      >
        <FormControlLabel
          value={"completo"}
          control={<Radio style={{ padding: "0 15px" }} />}
          label="Tombamento completo"
        />
        <FormControlLabel
          value={"selecionados"}
          control={<Radio style={{ padding: "0 15px" }} />}
          label="Cancelar registros selecionados"
        />
        <DescriptionLabel>max. 50 registros por seleção</DescriptionLabel>
      </StyleRadioGroup>
      <TitleInputModal>Descrição</TitleInputModal>
      <TextInput name="description" value={description} onChange={e => setDescription(e.target.value)} maxLength={30} />
      <DescriptionLabel>max. 30 caracteres</DescriptionLabel>
      <Line />
      <BackButtonWrapper label="Confirmar" onClick={handleCancel} />
    </Dialog>
  );
};
