import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./";
import { session } from "../utils";

export class EmployeeManagerStore {
  protected rootStore: RootStore;

  @observable public adminUserList: api.AdminUser[] | null = null;

  @observable public currentAdminUser: api.AdminUser | null = null;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getAdminUsers = async () => (this.adminUserList = await api.getAdminUsers());

  @session
  public setAdminUser = async (adminUser: api.AdminUser) => api.setAdminUser(adminUser);

  @session
  public deleteAdminUser = async (adminUserId: string) => api.deleteAdminUser(adminUserId);
}
