import * as React from "react";
import * as api from "api/manager-api";

import { CheckboxList, ICheckboxListItem } from "../../../../components/CheckboxList";

interface IProps {
  selectedDays: api.Day[];
  onChange: (days: api.Day[]) => void;
  disabled?: boolean;
}

const days: Array<ICheckboxListItem<api.Day>> = [
  {
    label: "Domingo",
    value: api.Day.sunday,
  },
  {
    label: "Segunda",
    value: api.Day.monday,
  },
  {
    label: "Terça",
    value: api.Day.tuesday,
  },
  {
    label: "Quarta",
    value: api.Day.wednesday,
  },
  {
    label: "Quinta",
    value: api.Day.thursday,
  },
  {
    label: "Sexta",
    value: api.Day.friday,
  },
  {
    label: "Sábado",
    value: api.Day.satuday,
  },
];

export function DaysSelector({ selectedDays, onChange, disabled }: IProps) {
  return <CheckboxList items={days} selected={selectedDays} onChange={onChange} disabled={disabled} />;
}
