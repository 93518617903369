import * as React from "react";
import { range } from "utils";
import moment from "moment";
import Select from "react-select";
import { customStylesReactSelect } from "../../utils/constants";
import { Container, Label } from "./styled";

import "react-datepicker/dist/react-datepicker.css";

interface IProps {
  interval: number;
  value: number;
  onChange: (value: number) => void;
}

export class YearPicker extends React.Component<IProps, {}> {
  private get years(): Array<{ label: string; value: number }> {
    return range(this.props.interval, moment().year() - 3)
      .map(e => ({
        value: e,
        label: e.toString(),
      }))
      .reverse();
  }

  public render() {
    return (
      <Container>
        <Label>Ano</Label>
        <Select
          isClearable={false}
          options={this.years}
          isSearchable={false}
          styles={customStylesReactSelect}
          onChange={(e: any) => this.props.onChange(e.value)}
          value={this.years.find(e => e.value === this.props.value)}
        />
      </Container>
    );
  }
}
