import { EntryStatement, EntryStatementNature } from "../../../../api/manager-api";

export function prettierNature(nature: EntryStatementNature): string {
  if (nature === EntryStatementNature.debit) {
    return "Débito";
  }

  return "Crédito";
}

export function getAmount(entry: EntryStatement): string {
  const amount = entry.amount / 100;

  if (entry.nature === EntryStatementNature.debit) {
    return (amount * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  return amount.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
}
