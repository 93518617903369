import * as api from "api/manager-api";
import { action, observable } from "mobx";

import { RootStore } from "./";
import { session } from "../utils";

export class ErrorsStore {
  protected rootStore: RootStore;

  @observable public errors: api.ToDoErros[] | null = null;

  @observable public currentError: api.ToDoErros | null = null;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @session
  @action
  public getErrors = async () => {
    const errorsList = await api.getToDoErros();

    return (this.errors = errorsList.filter(err => err.guilty !== "investimentoExtrato"));
  };

  @session
  public setErrorMessage = async (idError: number, message: string) => api.editMessageError(idError, message);
}
